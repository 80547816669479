import { FC, useEffect, useState } from "react";
import {
  AuditDocumentsCommandsProps,
  AuditDocumentsCommandsUIProps,
} from "../AuditForm/AuditTypes";
import { Button, Input, Switch } from "../../../TrueUI";
import style from "../AuditForm/Audit.module.css";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { useFormRequest } from "../../../../hooks";
import { ActivityCategoryEnums } from "../../../../dtos/activity-category-enums";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const ERROR_TEMPLATE_NAME = "_ERROR_TEMPLATE_NO_FOUND_";

const defaultAuditDocumentsCommandsUI = {
  saveFile: false,
  fileName: "",
} as AuditDocumentsCommandsUIProps;

const AuditDocumentsCommands: FC<AuditDocumentsCommandsProps> = ({
  tabKey,
  selectedPackets,
  selectedPacketDocuments,
  selectedDocuments,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [auditDocumentsCommandsUI, setAuditDocumentsCommandsUI] =
    useState<AuditDocumentsCommandsUIProps>(defaultAuditDocumentsCommandsUI);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { sendMergeFormRequest } = useFormRequest();

  const getDefaultFileName = (
    selectedPackets: ProgramPacketDto[],
    selectedDocuments: ProgramPolicyDocumentDto[]
  ) => {
    const insuredName =
      getAtom()?.policyQuoteInformation?.policyQuote?.insured?.insuredName ??
      "";

    if (selectedPackets.length === 1 && selectedDocuments.length === 0) {
      const packetName = selectedPackets[0].packetName;

      return `${insuredName} ${packetName}`;
    } else if (selectedPackets.length === 0 && selectedDocuments.length === 1) {
      const documentName = selectedDocuments[0].docName;

      return `${insuredName} ${documentName}`;
    }
    return `${insuredName} Audit Documents`;
  };

  const getTemplateNamesToPrint = () => {
    const templateNamesFromPackets = selectedPacketDocuments.flatMap(
      (selectedPacketDocument) =>
        selectedPacketDocument.templateName ?? ERROR_TEMPLATE_NAME
    );

    const templateNamesFromDocuments = selectedDocuments.flatMap(
      (selectedPacketDocument) =>
        selectedPacketDocument.templateName ?? ERROR_TEMPLATE_NAME
    );

    const allTemplateNamesToPrint = templateNamesFromPackets.concat(
      templateNamesFromDocuments
    );

    const hasInvalidTemplateNames =
      allTemplateNamesToPrint.includes(ERROR_TEMPLATE_NAME) ||
      allTemplateNamesToPrint.includes("");

    return { hasInvalidTemplateNames, allTemplateNamesToPrint };
  };

  const printDocuments = (downloadOrMail: "download" | "mail") => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNamesToPrint } =
        getTemplateNamesToPrint();

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: allTemplateNamesToPrint,
          customFileName: auditDocumentsCommandsUI.fileName,
          printOrDownload: downloadOrMail,
          configurationToSaveFile: {
            saveFileToInsured: auditDocumentsCommandsUI.saveFile,
            fileCategory: "Audit Documents",
            insuredId: policyMergeFields.insured?.insuredID ?? 0,
            claimId: null,
            policyId: policyMergeFields.policyID ?? -1,
            activityLogCategory: ActivityCategoryEnums.AUDIT_DOCUMENTS_PRINTED,
            activityLogDescription: !isEmptyValue(
              policyMergeFields.policyNumber
            )
              ? `The audit documents for policy number ${policyMergeFields.policyNumber} were generated`
              : `The audit documents for policy ID ${policyMergeFields.policyID} were generated`,
          },
        });
      }
    }
  };

  useEffect(() => {
    setAuditDocumentsCommandsUI({
      ...auditDocumentsCommandsUI,
      fileName: getDefaultFileName(selectedPackets, selectedDocuments),
    });
  }, [selectedPackets, selectedDocuments]);

  return (
    <div className={style.audit_documents_commands_container}>
      <Switch
        id="save-file-id"
        control="checkbox"
        label="Save File"
        labelPlacement={"end"}
        labelFontType={"BODY"}
        isChecked={auditDocumentsCommandsUI.saveFile}
        onChangeIsChecked={(checked) =>
          setAuditDocumentsCommandsUI({
            ...auditDocumentsCommandsUI,
            saveFile: checked,
          })
        }
      />
      <Input
        id="file-name-id"
        name="file-name"
        label="File Name"
        labelFontType={"BOLD_CAPTION"}
        labelPosition="start"
        variant="filled"
        fileNameValidation
        value={auditDocumentsCommandsUI.fileName}
        onChangeRawValue={(value) =>
          setAuditDocumentsCommandsUI({
            ...auditDocumentsCommandsUI,
            fileName: value,
          })
        }
        inputWidth="70%"
      />
      <div className={style.audit_documents_commands_buttons_container}>
        <Button
          id="policy-doc-email"
          name="email"
          sx={{ marginRight: "15px" }}
          onClick={() => printDocuments("mail")}
        >
          EMAIL
        </Button>
        <Button
          id="audit-documents-print-id"
          name="audit-documents-print"
          onClick={() => printDocuments("download")}
        >
          PRINT
        </Button>
      </div>
      <DialogConfirmation
        id="no-template-message-id"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </div>
  );
};

export default AuditDocumentsCommands;
