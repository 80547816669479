import { forwardRef, useEffect, useState } from "react";
import { IMaskInput } from "react-imask";

// This file is only to support the Input component.
// Any changes here need to be tested by Input.
// In general we should not try to use this component as a standalone component.

const InputMaskSupport = forwardRef<HTMLElement, any>(function TextMaskCustom(
  props,
  ref
) {
  const [hasMounted, setHasMounted] = useState<boolean>(false);

  const getFormat = (type: string) => {
    switch (type) {
      case "internalPhone":
      case "phone":
        return "(000) 000-0000";
      case "riskid":
        return /^[a-zA-Z0-9]{0,20}$/;
      case "postalCode":
        return /(^\d{0,5}(?:-\d{0,4})?$)|(^[a-zA-Z0-9]{0,3}(?:\s[a-zA-Z0-9]{0,3})?$)/;
      case "stringNumber":
        return props.stringNumberMask ?? "0000000000";
      case "internalPhoneExt":
      case "phoneExt":
        return "00000";
      case "stringDecimal":
        const integerPart = `\\d{0,${props?.integerScale ?? 10}}`;
        const decimalPart = `(\\.\\d{0,${props?.decimalScale ?? 2}})?`;
        const negativePart = props?.allowNegatives ? "-?" : "";
        return new RegExp(`^${negativePart}${integerPart}${decimalPart}$`);

      default:
        return "";
    }
  };

  useEffect(() => {
    // Doing the mount update in the onAccept function causes a bug that ignores the first typed value
    setHasMounted(true);
    return () => setHasMounted(false);
  }, []);

  return (
    <>
      <IMaskInput
        {...props}
        mask={getFormat(props.type)}
        unmask={props?.unmask}
        value={props.value?.toString()}
        inputRef={ref}
        onAccept={(value: any) => {
          // We only want to execute this once, just to render the value on load but we don't want to trigger our version of the
          // `onChange` event since it's not a change but rather a mount-render.
          /*if (!hasMounted) {
            // Note: "onMount" seems to produce the following error - "Unknown event handler property `onMount`. It will be ignored.".
            // This will be addressed at a later date as it doesn't break anything at the moment. (this is because onMount is not a real prop for IMaskInput, I made it up).
            props.onMount?.({ target: { name: props.name, value: value } });
            setHasMounted(true);
          }*/
          // We don't want to trigger the onChange trigger when this component mounts. This is an issue with IMaskInput since their
          // native `onChange` hook does not provide a pure value (does provide a value with the masking already applied however).
          if (hasMounted) {
            props.onChange({ target: { name: props.name, value: value } });
          }
        }}
        onChange={undefined}
        overwrite
        autoComplete={props.isAutoComplete ? "on" : "off"}
      />
    </>
  );
});

export default InputMaskSupport;
