import { FC, useEffect, useState } from "react";
import { FormMovePaymentProps } from "../FormComponents/MovePaymentTypes";
import { Col, Input, Row } from "../../../../../../TrueUI";
import SearchText from "../../../../../../TrueUI/Search/SearchText";
import { rowWithNoHorizontalMarginAndGutter } from "../../../../../../TrueUI/Grids/Row";
import { inputConfiguration } from "../../../../../../../utilities/inputPropsConfigurationFunctions";
import { parenthesesCurrencyFormat } from "../../../../../../../utilities/stringFunctions";

const FormMovePayment: FC<FormMovePaymentProps> = ({
  paymentFormData,
  claimReserve,
  setSaveButtonDisabled,
  setSearchedClaimId,
}) => {
  const [isClaimValid, setIsClaimValid] = useState<boolean>(false);
  const [dataToShowOnSearch, setDataToShowOnSearch] = useState({
    textClaim: "",
  });

  useEffect(() => {
    setSaveButtonDisabled(
      (claimReserve?.reserveAmount ?? 0) < (paymentFormData?.paidAmount ?? 0)
    );
  }, []);

  useEffect(() => {
    if (dataToShowOnSearch?.textClaim !== "" && isClaimValid) {
      setSaveButtonDisabled(
        (claimReserve?.reserveAmount ?? 0) < (paymentFormData?.paidAmount ?? 0)
          ? true
          : false
      );
    }
  }, [isClaimValid, claimReserve]);

  return (
    <Row {...rowWithNoHorizontalMarginAndGutter} allowWrap>
      <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
        <Input
          {...inputConfiguration(
            "id-paid-amount",
            "paymentFormReadOnlyPaidAmount",
            "Paid Amount",
            20
          )}
          type={
            (paymentFormData?.paidAmount ?? 0) < 0 ? "text" : "fixedCurrency"
          }
          prefix={""}
          align={"left"}
          value={
            (paymentFormData?.paidAmount ?? 0) < 0
              ? parenthesesCurrencyFormat(paymentFormData?.paidAmount ?? 0)
              : paymentFormData?.paidAmount ?? 0
          }
          labelFontType={"BOLD_CAPTION"}
          readOnly
        />
      </Col>
      <Col displayFlex={false} breakpoints={{ md: 12, lg: 12, xl: 12 }}>
        <SearchText
          saveSelectedResult={(value) => setSearchedClaimId(value?.claimId)}
          url={"api/QuickSearchInsuredClaim/SearchClaimText?text="}
          labelText={"Claim"}
          searchTextKeys={[
            "claimNumber",
            "claimantName",
            "claimStatus",
            "dateOfIncident",
            "dateOfBirth",
            "assigneeName",
          ]}
          placeholderText={"Search for a claim"}
          labelFontType={"BOLD_CAPTION"}
          value={dataToShowOnSearch.textClaim ?? ""}
          focus={false}
          minCharacters={4}
          setValidSelection={(isValid) => {
            setIsClaimValid(isValid);
          }}
          onRawChange={(value) =>
            setDataToShowOnSearch({
              ...dataToShowOnSearch,
              textClaim: value,
            })
          }
          searchOnEnterOnly
        />
      </Col>
      <Col>
        <Input
          {...inputConfiguration(
            "id-paid-amount",
            "paymentFormReadOnlyPaidAmount",
            "Outstanding Reserves:",
            20
          )}
          type={
            (claimReserve?.reserveAmount ?? 0) < 0 ? "text" : "fixedCurrency"
          }
          prefix={""}
          align={"left"}
          value={
            (claimReserve?.reserveAmount ?? 0) < 0
              ? parenthesesCurrencyFormat(claimReserve?.reserveAmount ?? 0)
              : claimReserve?.reserveAmount ?? 0
          }
          labelFontType={"BOLD_CAPTION"}
          readOnly
          labelPosition={"start"}
        />
      </Col>
    </Row>
  );
};

export default FormMovePayment;
