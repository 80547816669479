import { FC, useEffect, useState } from "react";
import { Col, Row, TabPanel, Tabs } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import AddPaymentForm, {
  payeeSearchTextKeys,
  payeeSearchTextKeysToShowExtraDataIntoInput,
} from "./AddPaymentForm";
import {
  API_PATH,
  TabsClaimFinancialProps,
  defaultPaymentData,
} from "./typesAndConstants";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import AddPayeeForm from "./AddPayeeForm";
import PaymentStatusHistory from "./PaymentStatusHistory";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { getFormattedValue } from "../../../../../TrueUI/Search/SearchTextUtil";
import { checkInitialReadOnly, getPayeeData } from "./ClaimFinancialUtil";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { getPayeeDataByPayToClaimant } from "./AddPaymentFormUtils";

const TabsClaimFinancial: FC<TabsClaimFinancialProps> = ({
  claimId,
  closeModal,
  paymentFormSelectsData,
  paymentId,
  paymentType,
  setNewPaymentRowData,
  setUpdatedPaymentRowData,
  setDeletedPaymentRowData,
  rowKey,
  setIsVoidedRefundOrMoveModalConfig,
  claimLandingClaimant,
  tabKey,
  paymentModalProps,
  setPaymentModalProps,
}) => {
  const getDefaultBankAccountId = () => {
    const defaultBankAccount =
      paymentFormSelectsData?.bankAccountValues?.find((x) => x.isDefault)
        ?.intValue ?? paymentFormSelectsData?.bankAccountValues?.[0]?.intValue;

    return defaultBankAccount ?? -1;
  };
  
  const getDefaultPaymentData = () => {
    const defaultBankAccount = getDefaultBankAccountId(); 
    return defaultPaymentData(defaultBankAccount);
  };

  const [paymentData, setPaymentData] = useState<
    PaymentDto | Partial<PaymentDto> | null
  >(getDefaultPaymentData());

  const [currentTab, setCurrentTab] = useState<number>(0);

  const [externalSave, setExternalSave] = useState<boolean>(false);

  const [externalErrorDetails, setExternalErrorDetails] = useState<any>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const { responseGet: paymentDetailsRes, dispatchGet: getPaymentDetails } =
    useApiGet<PaymentDto>(
      `api/Payment/GetPaymentDetails?paymentId=${paymentId}`
    );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    PaymentDto[] | Partial<PaymentDto>[]
  >(`${API_PATH}/SavePayment`, { ...paymentData, claimId: claimId });

  const [paymentResponse, setPaymentResponse] = useState<
    PaymentDto[] | Partial<PaymentDto>[] | null
  >(null);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setComponentTriggers(["claimBanner"]);
      setPaymentResponse(responsePost?.responseData ?? null);
      setExternalErrorDetails(undefined);
      setPaymentData?.(getDefaultPaymentData());
      closeModal(false);
    }
    setExternalSave(false);
  }, [responsePost]);

  useEffect(() => {
    if (externalSave) dispatchPost();
  }, [externalSave]);

  useEffect(() => {
    setExternalErrorDetails(validatorErrorResponse?.errorDetails);
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (paymentId) {
      getPaymentDetails();
    }
  }, [paymentId]);

  const closeModalHandleFromTabs = (e, refreshTable = true) => {
    setPaymentData(getDefaultPaymentData());
    closeModal(e, refreshTable);
  };

  useEffect(() => {
    if (isAPITotallyComplete(paymentDetailsRes)) {
      if (conditionHasValue(paymentDetailsRes.responseData)) {
        const paymentDataResponse = paymentDetailsRes.responseData;
        const payeeUpdatedData = getPayeeDataByPayToClaimant(
          paymentDataResponse,
          claimLandingClaimant
        );
        setPaymentData({
          ...payeeUpdatedData,
          payeeNameAndAddress: paymentDataResponse?.payToClaimant
            ? getPayeeData(claimLandingClaimant)
            : getFormattedValue(
                paymentDetailsRes.responseData?.payeeData,
                payeeSearchTextKeys,
                payeeSearchTextKeysToShowExtraDataIntoInput
              ),
        });
      }
    }
  }, [paymentDetailsRes]);

  useEffect(() => {
    if (currentTab === 0 && paymentId) {
      setPaymentData({ ...paymentData, payeeId: null });
      getPaymentDetails();
    }
  }, [currentTab]);

  useEffect(() => {
    if (
      paymentData?.paymentId !== 0 &&
      paymentData?.outstandingReserves !== null
    )
      setReadOnly(checkInitialReadOnly(paymentData, paymentType));
  }, [paymentData?.outstandingReserves]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowHeight={paymentModalProps?.isCopying === true ? "auto" : "760px"}
    >
      <Col {...colWithNoMarginNorGutter} displayFlex={false}>
        <Tabs
          name={"claims-financial-payments-tabs"}
          selected={currentTab}
          onSelect={(e) => setCurrentTab(e.selected)}
          stateControlled
        >
          <TabPanel title="Payment Detail" key={"payment-detail"}>
            {!paymentId || isAPITotallyComplete(paymentDetailsRes) ? (
              <AddPaymentForm
                paymentFormSelectsData={paymentFormSelectsData}
                claimId={claimId}
                closeModal={closeModalHandleFromTabs}
                paymentType={paymentType}
                setNewPaymentRowData={setNewPaymentRowData}
                setUpdatedPaymentRowData={setUpdatedPaymentRowData}
                setDeletedPaymentRowData={setDeletedPaymentRowData}
                rowKey={rowKey}
                setIsVoidedRefundOrMoveModalConfig={
                  setIsVoidedRefundOrMoveModalConfig
                }
                setPaymentFormData={setPaymentData ?? null}
                paymentFormData={paymentData}
                claimLandingClaimant={claimLandingClaimant}
                setExternalSave={setExternalSave}
                externalErrorDetails={externalErrorDetails}
                paymentResponse={paymentResponse}
                isReadOnly={readOnly}
                currentPaymentStatus={paymentData?.paymentStatus}
                paymentModalProps={paymentModalProps}
                setPaymentModalProps={setPaymentModalProps}
              />
            ) : (
              <>{"Loading..."}</>
            )}
          </TabPanel>
          <TabPanel title="Payee/Provider" key={"payee-provider"}>
            <AddPayeeForm
              paymentFormData={paymentData}
              setPaymentFormData={setPaymentData ?? null}
              closeModal={closeModal}
              setCurrentTab={setCurrentTab}
              setExternalSave={setExternalSave}
              externalErrorDetails={externalErrorDetails}
              claimLandingClaimant={claimLandingClaimant}
            />
          </TabPanel>
          {paymentId && setIsVoidedRefundOrMoveModalConfig !== undefined && (
            <TabPanel title="Status History" key={"payment-status-history"}>
              <PaymentStatusHistory
                paymentId={paymentId}
                setIsVoidedRefundOrMoveModalConfig={
                  setIsVoidedRefundOrMoveModalConfig
                }
                closePaymentModal={closeModal}
              />
            </TabPanel>
          )}
        </Tabs>
      </Col>
    </Row>
  );
};

export default TabsClaimFinancial;
