import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../../../../dtos/data-type-enums";

export const getAgencyInformationTableColumns = (
  agencyOptions: BaseTableDisplayOptions[],
  readOnly: boolean = false
): BaseTableColumn[] => [
  {
    _columnIndex: 0,
    _visibleColumnIndex: 0,
    displayName: "UniqueKey",
    fieldName: "UniqueKey",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 1,
    _visibleColumnIndex: 1,
    displayName: "PolicyAgencyId",
    fieldName: "PolicyAgencyId",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.INT,
    isHidden: true,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 2,
    _visibleColumnIndex: 2,
    displayName: "Agency Name",
    fieldName: "AgencyName",
    cellVariant: CellVariantEnums.FILLED,
    type: readOnly ? BaseTableInputType.READONLY : BaseTableInputType.SELECT,
    displayOptions: agencyOptions,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 3,
    _visibleColumnIndex: 3,
    displayName: "Location",
    fieldName: "Location",
    cellVariant: CellVariantEnums.FILLED,
    type: readOnly ? BaseTableInputType.READONLY : BaseTableInputType.SELECT,
    displayOptions: [],
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 4,
    _visibleColumnIndex: 4,
    displayName: "Agent",
    fieldName: "Agent",
    cellVariant: CellVariantEnums.FILLED,
    type: readOnly ? BaseTableInputType.READONLY : BaseTableInputType.SELECT,
    displayOptions: [],
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 5,
    _visibleColumnIndex: 5,
    displayName: "Primary",
    fieldName: "Primary",
    cellVariant: CellVariantEnums.FILLED,
    type: readOnly
      ? BaseTableInputType.READONLY_CHECKBOX
      : BaseTableInputType.CHECKBOX,
    displayOptions: null,
    defaultValue: "false",
    defaultValueType: DataTypeEnums.NULLABLE_BOOLEAN,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 6,
    _visibleColumnIndex: 6,
    displayName: "Commission Override",
    fieldName: "CommissionOverride",
    cellVariant: CellVariantEnums.FILLED,
    type: readOnly ? BaseTableInputType.READONLY : BaseTableInputType.CURRENCY,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.DECIMAL,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
];

export const agencyInformationTableDefaultData = [
  [crypto.randomUUID(), "0", "0", "0", "0", "true", "0"],
];
