import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useApiGet, useApiPost } from "../../../../hooks";
import { useSetRecoilState } from "recoil";
import { TabPanel, Tabs } from "../../../TrueUI";
import ProgramInformation from "./ProgramInformation";
import ProgramPolicyPeriod from "./ProgramPolicyPeriod";
import { ProgramInformationTabDto } from "../../../../dtos/program-information-tab-dto";
import { PROGRAM_ADMIN_INFORMATION_TABS } from "../../../../utilities/queryStringsHash";
import { AdminProgramErrorAtom } from "../ProgramAtoms";
import Header from "../../../TrueUI/Headers/Header";
import { ClaimSourceEnums } from "../../../../dtos/claim-source-enums";
import PortalSettings from "./PortalSettings/PortalSettings";
import { PortalAdminTabsEnum } from "./PortalSettings/PortalSettingUtils";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import ProgramBottomButtons from "./ProgramBottomButtons";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import CustomFieldsWrapper from "./CustomFields/CustomFieldsWrapper";

type InformationProps = {
  isNewProgram: boolean;
};

type InformationHeaderProps = {
  title: string;
  hasPortal: boolean;
  hasClaim: boolean;
};

const BASE_TITLE = "Program Admin - ";

const Information: FC<InformationProps> = ({ isNewProgram }) => {
  const { programId } = useParams();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<{
    open: boolean;
    newProgramId: any;
  }>({
    open: false,
    newProgramId: "",
  });
  const [programData, setProgramData] = useState<ProgramInformationTabDto>();
  const [informationHeader, setInformationHeader] =
    useState<InformationHeaderProps>();
  const selectedTab = getQueryValue(PROGRAM_ADMIN_INFORMATION_TABS);
  const [selectTab, setSelectTab] = useState<number>(
    selectedTab !== "" ? parseInt(selectedTab) : PortalAdminTabsEnum.INFORMATION
  );
  const setProgramErrorAtom = useSetRecoilState(AdminProgramErrorAtom);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    isNewProgram
      ? "api/AdminTools/Program/InsertProgram"
      : "api/AdminTools/Program/UpdateProgram",
    programData
  );

  const { responseGet, dispatchGet } = useApiGet<ProgramInformationTabDto>(
    `api/AdminTools/Program/GetProgramInformationPage?programId=${
      isNewProgram ? "" : programId
    }`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.responseData) {
      const responseData = responseGet.responseData;
      setProgramData(responseData);
      setInformationHeader({
        title: `${BASE_TITLE}${
          isNewProgram
            ? "New Program"
            : responseData?.programInformation?.programName
        }`,
        hasPortal: isNewProgram
          ? false
          : responseData?.programOption?.hasPortal ?? false,
        hasClaim: isNewProgram
          ? false
          : (responseData?.programOption?.hasClaims ?? false) &&
            responseData?.programOption?.claimSource ===
              ClaimSourceEnums.INTERNAL,
      });
    }
  }, [responseGet.responseData]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      setInformationHeader({
        title: `${BASE_TITLE}${programData?.programInformation?.programName}`,
        hasPortal: programData?.programOption?.hasPortal ?? false,
        hasClaim:
          (programData?.programOption?.hasClaims ?? false) &&
          programData?.programOption?.claimSource === ClaimSourceEnums.INTERNAL,
      });
      setIsDialogOpen({
        open: true,
        newProgramId: responsePost.responseData,
      });
    }
    setProgramErrorAtom({ errorDetails: validatorErrorResponse?.errorDetails });
  }, [responsePost]);

  useEffect(() => {
    if (isDialogOpen.open === false && isDialogOpen.newProgramId !== "") {
      window.open(`${isDialogOpen.newProgramId}`, "_self");
    }
  }, [isDialogOpen]);

  const save = () => dispatchPost();

  const cancel = () => navigate("/admin-tools/program");
  const excludedTabs = [
    PortalAdminTabsEnum.PORTAL_SETTINGS,
    PortalAdminTabsEnum.CUSTOM_FIELDS,
  ];
  return (
    <>
      {programData ? (
        <>
          <Header>{informationHeader?.title ?? ""}</Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Tabs
              name={PROGRAM_ADMIN_INFORMATION_TABS}
              themeOverride={"material"}
              onSelect={(selected) => {
                setSelectTab(selected.selected);
              }}
              selected={selectTab}
            >
              <TabPanel title={"Information"} key={"program_information_tab"}>
                <ProgramInformation
                  programInformationTabData={programData}
                  setProgramInformationTabData={setProgramData}
                />
              </TabPanel>
              <TabPanel title={"Policy Periods"} key={"program_policy_tab"}>
                <ProgramPolicyPeriod />
              </TabPanel>
              <TabPanel title={"Pay Plans"} key={"program_pay_plan_tab"}>
                Pay Plans
              </TabPanel>
              <TabPanel
                title={"Custom Fields"}
                key={"program_custom_fields_tab"}
              >
                <CustomFieldsWrapper
                  programId={programData?.programInformation?.programId}
                />
              </TabPanel>
              {informationHeader?.hasPortal && (
                <TabPanel
                  title={"Portal Settings"}
                  key={"program_portal_settings_tab"}
                >
                  <PortalSettings
                    programId={programData?.programInformation?.programId}
                    setSelectTab={setSelectTab}
                  />
                </TabPanel>
              )}
              {informationHeader?.hasClaim && (
                <TabPanel
                  title={"Claim Settings"}
                  key={"program_claim_settings_tab"}
                >
                  Claim Settings
                </TabPanel>
              )}
              <TabPanel
                title={"Additional Contacts"}
                key={"program_additional_contacts_tab"}
              >
                Additional Contacts
              </TabPanel>
            </Tabs>
            <ProgramBottomButtons
              saveFunction={save}
              cancelFunction={cancel}
              showBottomButtons={!excludedTabs.includes(selectTab)}
            />
            <DialogConfirmation
              open={isDialogOpen.open}
              dialogDescriptionText="This program has been saved successfully"
              onOptionYesEvent={() =>
                setIsDialogOpen({ ...isDialogOpen, open: false })
              }
              optionYesOverrideLabel="OK"
            />
          </div>
        </>
      ) : (
        <div> Loading ...</div>
      )}
    </>
  );
};

export default Information;
