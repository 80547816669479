export const AccountAndBalanceTableStyles = () => ({
  marginTop: "20px",
  display: "flex",
  height: "100%",
  overflow: "hidden",
  "#AccountAndBalanceTable .true_ui_base_table_container .true_table_wrapper": {
    maxHeight: "168px",
    overflowY: "auto",
  },
});
