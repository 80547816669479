import { FC, useEffect, useState } from "react";
import { Col, FontBold, InputMemo, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

const InsuredInformationRemarks: FC = () => {
  const readOnly = false;
  const [remarksText, setRemarksText] = useState<string | null>();

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(atomValue?.remarks)) {
      setRemarksText(atomValue?.remarks);
    }
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(remarksText))
      setAtom({
        ...atomValue,
        remarks: remarksText,
      });
  }, [remarksText]);

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold>REMARKS</FontBold>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <InputMemo
          tabIndex={34}
          id="id-remarks-input"
          name="name-remarks-input"
          inputFontType="BODY"
          labelFontType="CAPTION"
          variant="filled"
          value={remarksText}
          onChangeRawValue={setRemarksText}
          rows={5}
          resize={true}
          readOnly={readOnly}
        />
      </Col>
    </Row>
  );
};

export default InsuredInformationRemarks;
