import { FC, useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import BaseGridSearchFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { globalOptions } from "../../../GlobalAtoms";
import { ClaimsIncidentsFiltersOptionsProps } from "./TopClaimsAndIncidentsTabs";
import { ClaimStatusEnums } from "../../../dtos/claim-status-enums";
import { formattingKey } from "../../../utilities/enumFunctions";
import { IncidentStatusEnums } from "../../../dtos/incident-status-enums";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { IncidentPropsForContextMenu } from "../../Insured/Claim/Incident/Incident";
import ReassignClaimsModal from "./ReassignClaimsModal/ReassignClaimsModal";
// import { usePermissions } from "../../../hooks";
// import { PermissionsEnums } from "../../../dtos/permissions-enums";

type TopClaimsGridProperties = {
  searchFilters: ClaimsIncidentsFiltersOptionsProps;
  executeClaimsTableAction: "update" | "delete" | null;
  incidentContextMenuValues: IncidentPropsForContextMenu | undefined;
};

const tableClaimsName = "claims_body_table";

const TopClaimsGrid: FC<TopClaimsGridProperties> = ({
  searchFilters,
  executeClaimsTableAction,
  incidentContextMenuValues,
}) => {
  const [isReassignClaimsModalOpen, setIsReassignClaimsModalOpen] =
    useState(false);
  const { getAtom } = useAtomFamily(globalOptions);
  const currentUserId = getAtom()?.userId;

  /*const hasReassignClaimsPermission = usePermissions([
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]).hasPermission;*/

  const getDefaultAssignedValue = () => {
    if (searchFilters?.assigneesFilter?.length > 0) {
      const selected = searchFilters?.assigneesFilter.find(
        (assigned) => assigned?.intValue === currentUserId
      );
      return selected ? currentUserId : "all";
    }
    return "all";
  };

  const tableConfigurationClaimsTabOptions = useBaseGrid({
    name: tableClaimsName,
    getURL: "api/Claims/GetClaimsIncidentsTableData?tabType=claims",
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "ClaimNumberLink", width: 6 },
      { fieldName: "ProgramCode", width: 6 },
      { fieldName: "Jurisdiction", width: 4 },
      { fieldName: "InsuredName", width: 12 },
      { fieldName: "Claimant", width: 12 },
      { fieldName: "DOB", width: 7 },
      { fieldName: "InjuryDescription", width: 12 },
      { fieldName: "ClaimType", width: 8 },
      { fieldName: "DateOfIncident", width: 7 },
      { fieldName: "AssignedDate", width: 7 },
      { fieldName: "DateClosed", width: 7 },
      { fieldName: "ClaimStatus", width: 5 },
      { fieldName: "Assignee", width: 7 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "claim-type-filter",
          columnNames: ["ClaimType"],
          optionsOverride: searchFilters.claimTypes,
          labelText: "Claim Type:",
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "status-filter",
          columnNames: ["HiddenClaimStatus"],
          optionsOverride: searchFilters.claimStatuses,
          labelText: "Status:",
          defaultValue: formattingKey(ClaimStatusEnums[ClaimStatusEnums.OPEN]),
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "assigned-to-filter",
          columnNames: ["AssignedUserId"],
          optionsOverride: searchFilters.assigneesFilter,
          labelText: "Assigned To:",
          defaultValue: getDefaultAssignedValue(),
        }),
      (options) =>
        BaseGridSearchFilter({
          options,
          id: "top_claims_table_filter_search",
          columnNames: ["ClaimNumber", "Claimant"],
          columnDisplayNames: ["claim no.", "claimant"],
        }),
    ],
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showImportButton: false,
      // showAddButton: hasReassignClaimsPermission,
      showAddButton: false,
      addButtonText: "Reassign",
    },
    events: {
      addEventOverrideCallback: () => setIsReassignClaimsModalOpen(true),
    },
  });

  useEffect(() => {
    if (executeClaimsTableAction !== null) {
      if (executeClaimsTableAction === "update") {
        tableConfigurationClaimsTabOptions.updateRow({
          rowKey:
            incidentContextMenuValues?.row?.rowKey ??
            incidentContextMenuValues?.row?._row_key,
          hydratedData: {
            ...incidentContextMenuValues?.row,
            DateOfLoss: FormattingDate(
              incidentContextMenuValues?.row?.DateOfLoss
            ),
            IncidentStatus: formattingKey(
              IncidentStatusEnums[
                incidentContextMenuValues?.incidentStatus ?? 0
              ]
            ),
          },
        });
      } else {
        tableConfigurationClaimsTabOptions.deleteRow({
          rowKey:
            incidentContextMenuValues?.row?.rowKey ??
            incidentContextMenuValues?.row?._row_key,
          hydratedData: incidentContextMenuValues?.row,
          deleteType: "hidden",
        });
      }
    }
  }, [executeClaimsTableAction]);

  const onClaimReassignment = () => {
    tableConfigurationClaimsTabOptions.manuallyReloadParameters();
  };

  return (
    <>
      <BaseGrid name={tableClaimsName} />
      <ReassignClaimsModal
        isOpen={isReassignClaimsModalOpen}
        closeModal={() => setIsReassignClaimsModalOpen(false)}
        onClaimReassignment={onClaimReassignment}
      />
    </>
  );
};

export default TopClaimsGrid;
