import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";
import { Theme } from "../../../../media/themeTypes";

export const splitterStyles = (theme: Theme) => {
  return {
    height: "100%",
    ".k-splitter": {
      backgroundColor: "transparent",
      height: "100%",
      borderColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      ".k-splitbar": {
        backgroundColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      },
      ".k-splitbar:focus": {
        background: theme.PRIMARY,
      },
    },
  };
};

export const documentsGroupRowStyles = () => {
  return {
    minWidth: "-webkit-fill-available",
    ".true_col_grid_wrapper": {
      ".MuiCheckbox-root": {
        padding: "0px 9px",
      },
    },
  };
};

export const buttonStyles = () => {
  return {
    marginLeft: "16px",
  };
};
