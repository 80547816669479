import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { Select } from "../../../TrueUI";
import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet } from "../../../../hooks";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

type TaskCategoryDropdownProps = {
  type: number;
  value: any;
  onChange?: (value: number) => void;
  readOnly?: boolean;
};

const TaskCategoryDropdown: FC<TaskCategoryDropdownProps> = ({
  type,
  value,
  onChange,
  readOnly,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Task/GetCategoryOptions?type=${type}`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCategoryOptions(responseGet.responseData ?? []);
    }
  }, [responseGet]);

  return (
    <Select
      {...selectConfiguration(
        "id-task-category",
        "task-category",
        "Category",
        readOnly
      )}
      labelFontType="BOLD_CAPTION"
      optionsMaxHeight={"400px"}
      firstOptionAsDefault={false}
      options={categoryOptions}
      value={isEmptyValue(categoryOptions) ? null : value}
      onChange={(value) => {
        onChange?.(value);
      }}
    />
  );
};

export default TaskCategoryDropdown;
