import { FC, useEffect, useState } from "react";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiPost, usePermissions } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { phoneMask } from "../../../../utilities/stringFunctions";
import {
  Col,
  Input,
  InputAddress,
  InputDate,
  InputPhone,
  Modal,
  Row,
  Select,
} from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { groupsAndContent } from "./NameNamesAndAddressesTable";
import {
  areObjectsEqual,
  removeFalsyValues,
  removeObjectProperty,
} from "../../../../utilities/objectFunctions";

export type ModalAddressInformationProps = {
  rowKey?: number;
  parentKey?: number;
  address?: PolicyInsuredAddressBlob | null;
  isNewAddress?: boolean;
  isOpen?: boolean;
};

type ModalAddressProps = {
  addressTypes?: SelectOptions[];
  modalInformation: ModalAddressInformationProps;
  onCancelEvent?: () => void;
  onSaveEvent?: (
    parentKey: number,
    location?: PolicyInsuredAddressBlob,
    isNewAddress?: boolean
  ) => void;
  onDeleteAddressEvent?: (parentKey?: number, rowKey?: number) => void;
  groupsAndContent: groupsAndContent[];
  effectiveDate: Date | null;
};

type DialogConfirmationConfigurationProps = {
  isOpen: boolean;
  text: string;
  option: "save" | "delete";
};

const textFieldVariant = "standard";
const primaryType = "Primary";
const statusTypes = [
  {
    displayName: "Active",
    intValue: 1,
  },
  {
    displayName: "Inactive",
    intValue: 2,
  },
];

const permissions = [5, 6, 7];

const ModalAcord130Address: FC<ModalAddressProps> = ({
  addressTypes,
  modalInformation,
  onSaveEvent,
  onCancelEvent,
  onDeleteAddressEvent,
  groupsAndContent,
  effectiveDate,
}) => {
  const [locationData, setLocationData] = useState<PolicyInsuredAddressBlob>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [dialogConfirmationOpen, setDialogConfirmationOpen] =
    useState<DialogConfirmationConfigurationProps>({
      isOpen: false,
      text: "",
      option: "save",
    });

  const { responsePost, validatorErrorResponse, dispatchPost } =
    useApiPost<any>("api/QuotePolicy/SaveInsuredLocation", locationData);

  const { isNewAddress, isOpen, address, rowKey, parentKey } = modalInformation;
  const hasPermission = usePermissions(permissions);

  const getAddressIndex = () => {
    const groupIndex = (parentKey ?? 0) - 1;
    const groupLength = groupsAndContent[groupIndex]?.locations?.length ?? 0;
    return groupLength + 1;
  };

  const onSaveModal = () => {
    dispatchPost();
  };

  const onCancelModal = () => {
    setErrorDetails(null);
    onCancelEvent?.();
    setDialogConfirmationOpen({ ...dialogConfirmationOpen, isOpen: false });
  };

  const onDeleteModal = () => {
    if (
      !hasPermission ||
      isNewAddress ||
      locationData?.addressType === primaryType
    ) {
      return undefined;
    } else {
      return () =>
        setDialogConfirmationOpen({
          isOpen: true,
          text: "Are you sure you want to delete the address?",
          option: "delete",
        });
    }
  };

  const getDataWithoutIndex = (data?: PolicyInsuredAddressBlob | null) => {
    const dataWithoutRowIndex = removeObjectProperty(data, "rowIndex");
    const dataWithoutParentIndex = removeObjectProperty(
      dataWithoutRowIndex,
      "parentRowIndex"
    );
    return dataWithoutParentIndex;
  };

  const checkPendingChangesToSave = () => {
    const addressData = isNewAddress
      ? { effectiveDate: effectiveDate, locationStatus: 1 }
      : modalInformation.address;
    const addressDataWithoutIndex = getDataWithoutIndex(addressData);
    const locationDataWithoutIndex = getDataWithoutIndex(locationData);
    if (
      !areObjectsEqual(
        removeFalsyValues(addressDataWithoutIndex),
        removeFalsyValues(locationDataWithoutIndex)
      )
    ) {
      setDialogConfirmationOpen({
        isOpen: true,
        text: "Are you sure you want to close before saving the changes?",
        option: "save",
      });
    } else {
      onCancelModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLocationData(
        isNewAddress ?? true
          ? {
              addressID: null,
              nameID: null,
              rowIndex: getAddressIndex(),
              parentRowIndex: parentKey,
              effectiveDate: effectiveDate,
            }
          : address ?? {}
      );
    } else {
      setLocationData({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponse)
    ) {
      onSaveEvent?.(parentKey ?? 0, locationData, isNewAddress);
      setErrorDetails(null);
    }
    if (conditionHasValue(validatorErrorResponse)) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <>
      {conditionHasValue(locationData) && (
        <Modal
          id={"new_insured_location_modal"}
          title={"Address"}
          open={isOpen}
          permissionsToSaveButton={permissions}
          cancelEvent={checkPendingChangesToSave}
          saveEvent={onSaveModal}
          deleteEvent={onDeleteModal}
          showCloseButton
          showCancelTextButton
        >
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Input
                  tabIndex={40}
                  id="id-insured-location-name-input"
                  name="name-insured-location-name-input"
                  label="Name or DBA"
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.locationName}
                  value={locationData?.locationName}
                  maxLength={300}
                  onChangeRawValue={(value) => {
                    setLocationData({
                      ...locationData,
                      locationName: value,
                    });
                  }}
                  readOnly={!hasPermission}
                />
              </Col>
              <Col {...colWithNoMarginNorGutter}>
                <InputAddress
                  tabIndex={41}
                  id="id-insured-location-address-input"
                  name="name-insured-location-address-input"
                  label="Address"
                  labelFontType="BOLD_CAPTION"
                  variant={textFieldVariant}
                  readOnly={!hasPermission}
                  address1ErrorMessage={errorDetails?.address1}
                  address2ErrorMessage={errorDetails?.address2}
                  cityErrorMessage={errorDetails?.addressCity}
                  postCodeErrorMessage={errorDetails?.addressPostalCode}
                  stateCodeErrorMessage={errorDetails?.addressState}
                  initValueAddress1={locationData?.address1 ?? ""}
                  initValueAddress2={locationData?.address2 ?? ""}
                  initValueCity={locationData?.addressCity ?? ""}
                  initValueStateCode={locationData?.addressState ?? ""}
                  initValuePostCode={locationData?.addressPostalCode ?? ""}
                  isFirstStateCodeAsDefault={false}
                  onChangeValueAddress1={(value) =>
                    setLocationData({
                      ...locationData,
                      address1: value,
                    })
                  }
                  onChangeValueAddress2={(value) =>
                    setLocationData({
                      ...locationData,
                      address2: value,
                    })
                  }
                  onChangeValueCity={(value) =>
                    setLocationData({
                      ...locationData,
                      addressCity: value,
                    })
                  }
                  onChangeStateCode={(value) => {
                    setLocationData({
                      ...locationData,
                      addressState: value,
                    });
                  }}
                  onChangeValuePostCode={(value) =>
                    setLocationData({
                      ...locationData,
                      addressPostalCode: value,
                    })
                  }
                  showSingleLabel={false}
                />
              </Col>
              <Row {...rowWithNoMarginNorGutter}>
                <Col>
                  <InputPhone
                    tabIndex={46}
                    id="name-insured-location-phone-input"
                    name="name-insured-location-phone-input"
                    phoneLabel="Phone Number"
                    variant={textFieldVariant}
                    phoneValue={locationData?.addressPhoneNumber ?? ""}
                    readOnly={!hasPermission}
                    errorMessagePhone={errorDetails?.addressPhoneNumber}
                    onChangePhoneValue={(value) => {
                      setLocationData({
                        ...locationData,
                        addressPhoneNumber: phoneMask(value),
                        addressPhoneNumberNoFormat: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <InputPhone
                    tabIndex={47}
                    id="id-insured-location-fax-input"
                    name="name-insured-location-fax-input"
                    phoneLabel="Fax Number"
                    variant={textFieldVariant}
                    phoneValue={locationData?.addressFaxNumber ?? ""}
                    readOnly={!hasPermission}
                    errorMessagePhone={errorDetails?.addressFaxNumber}
                    onChangePhoneValue={(value) => {
                      setLocationData({
                        ...locationData,
                        addressFaxNumber: phoneMask(value),
                        addressFaxNumberNoFormat: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row {...rowWithNoMarginNorGutter}>
                <Col>
                  <Input
                    tabIndex={48}
                    id="id-insured-location-number-employees-input"
                    name="name-insured-location-number-employees-input"
                    label="Number of Full Time Employees"
                    variant={textFieldVariant}
                    readOnly={!hasPermission}
                    type={"number"}
                    maxLength={9}
                    errorMessage={errorDetails?.fullTimeEE}
                    value={locationData?.fullTimeEE ?? null}
                    onChangeRawValue={(value) => {
                      setLocationData({
                        ...locationData,
                        fullTimeEE: value ?? null,
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Input
                    tabIndex={49}
                    id="id-insured-location-number-part-time-input"
                    name="name-insured-location-number-part-time-input"
                    label="Number of Part Time Employees"
                    variant={textFieldVariant}
                    readOnly={!hasPermission}
                    type={"number"}
                    maxLength={9}
                    errorMessage={errorDetails?.partTimeEE}
                    value={locationData?.partTimeEE ?? null}
                    onChangeRawValue={(value) => {
                      setLocationData({
                        ...locationData,
                        partTimeEE: value ?? null,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Row>
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Select
                  tabIndex={50}
                  id="id-insured-address-type-select"
                  name="name-insured-address-type-select"
                  label={"Address Type"}
                  labelFontType="BOLD_CAPTION"
                  options={addressTypes ?? []}
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.addressType}
                  value={locationData?.addressType}
                  readOnly={!hasPermission}
                  onChange={(value) =>
                    setLocationData({
                      ...locationData,
                      addressType: value,
                    })
                  }
                  firstOptionAsDefault={false}
                />
              </Col>
              <Col>
                <Input
                  tabIndex={51}
                  id="id-insured-location-number-input"
                  name="name-insured-location-number-part-time-input"
                  label="Location Number"
                  variant={textFieldVariant}
                  readOnly={!hasPermission}
                  type={"number"}
                  maxLength={9}
                  errorMessage={errorDetails?.locationNumber}
                  value={locationData?.locationNumber}
                  onChangeRawValue={(e) =>
                    setLocationData({
                      ...locationData,
                      locationNumber: e,
                    })
                  }
                />
              </Col>
              <Col>
                <Select
                  tabIndex={52}
                  id="id-insured-location-status-select"
                  name="name-insured-location-status-select"
                  label={"Status"}
                  labelFontType="BOLD_CAPTION"
                  options={statusTypes}
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.locationStatus}
                  value={locationData?.locationStatus}
                  readOnly={!hasPermission}
                  onChange={(value) =>
                    setLocationData({
                      ...locationData,
                      locationStatus: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={53}
                  id="id-insured-location-effective-date-input"
                  name="name-insured-location-effective-date-input"
                  label="Effective Date"
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.effectiveDate}
                  value={locationData?.effectiveDate}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setLocationData({
                      ...locationData,
                      effectiveDate: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={54}
                  id="id-insured-location-expiration-date-input"
                  name="name-insured-location-expiration-date-input"
                  label="Expiration Date"
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.expirationDate}
                  value={locationData?.expirationDate}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setLocationData({
                      ...locationData,
                      expirationDate: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  tabIndex={55}
                  id="id-insured-location-store-number-input"
                  name="name-insured-location-store-number-input"
                  label="Store Number"
                  variant={textFieldVariant}
                  readOnly={!hasPermission}
                  maxLength={20}
                  errorMessage={errorDetails?.storeNumber}
                  value={locationData?.storeNumber}
                  onChangeRawValue={(e) =>
                    setLocationData({
                      ...locationData,
                      storeNumber: e,
                    })
                  }
                />
              </Col>
            </Row>
          </Row>
        </Modal>
      )}
      <DialogConfirmation
        name="insured-location-dialog"
        id="modalLocationDialogConfirmation"
        open={dialogConfirmationOpen.isOpen}
        dialogDescriptionText={dialogConfirmationOpen.text}
        onCloseEvent={(close) =>
          setDialogConfirmationOpen({
            ...dialogConfirmationOpen,
            isOpen: close,
          })
        }
        onOptionNoEvent={(close) =>
          setDialogConfirmationOpen({
            ...dialogConfirmationOpen,
            isOpen: close,
          })
        }
        onOptionYesEvent={() => {
          onDeleteAddressEvent?.(parentKey, (rowKey ?? 0) + 1);
          onCancelModal();
        }}
      />
    </>
  );
};

export default ModalAcord130Address;
