import { Logout } from "@mui/icons-material";
import {
  Divider,
  Menu as MaMenu,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Box,
  ListItem,
} from "@mui/material";
import { FC, useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { Link } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useSecurity } from "../../../hooks";
import themes from "../../../media/TrueTheme";
import { dividerStyles, menuPaperStyles, menuStyles } from "./TopNavStyles";
import { removeSystemLocalStorage } from "../../../utilities/localStorageFunctions";

type MenuProperties = {
  open: boolean;
  onTopNavClose?: () => void;
};

const Menu: FC<MenuProperties> = ({ open, onTopNavClose }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const menuAnchorRef = useRef(null);
  const [menuPositionAnchor, setMenuPositionAnchor] =
    useState<null | HTMLElement>(menuAnchorRef.current);
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const { logout } = useSecurity();

  const LogoutHandler = () => {
    try {
      logout();
    } catch (err) {
      //TODO Catch handling
    }
  };

  const switchToAnotherProgram = () => {
    removeSystemLocalStorage();
    window.location.reload();
  };

  useEffect(() => {
    setMenuPositionAnchor(menuAnchorRef.current);
  }, []);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Box ref={menuAnchorRef}>
      <MaMenu
        anchorEl={menuPositionAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={"primary-search-account-menu"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (onTopNavClose !== undefined) onTopNavClose();
        }}
        sx={menuStyles(theme)}
        PaperProps={{
          sx: menuPaperStyles(theme),
        }}
      >
        <MenuList dense>
          <ListItem>
            <ListItemText true-element={`true-element-button-userName`}>
              {`${localOptions?.firstName ?? ""} ${
                localOptions?.lastName ?? ""
              }`}
            </ListItemText>
          </ListItem>
          <Divider sx={dividerStyles(theme)} />
          <MenuItem onClick={switchToAnotherProgram}>
            {localOptions?.availablePrograms.length > 1 && (
              <SwapHorizIcon sx={{ mr: 1 }} />
            )}
            <ListItemText true-element={`true-element-button-program`}>
              {localOptions?.selectedProgram.programName}
            </ListItemText>
          </MenuItem>
          <Divider sx={dividerStyles(theme)} />
          <MenuItem component={Link} to={"/user-preferences"}>
            <ListItemText true-element={`true-element-button-userPreferences`}>
              Preferences
            </ListItemText>
          </MenuItem>
          <MenuItem component={Link} to={"/user-permissions"}>
            <ListItemText true-element={`true-element-button-viewPermissions`}>
              View Permissions
            </ListItemText>
          </MenuItem>
          <Divider sx={dividerStyles(theme)} />
          <MenuItem
            onClick={() => LogoutHandler()}
            true-element={`true-element-button-logOut`}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </MenuList>
      </MaMenu>
    </Box>
  );
};

export default Menu;
