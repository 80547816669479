import { FC } from "react";
import { PortalSettings } from "./PortalSettings";
import {
  AlignedInputWithLabel,
  onChangeSettingField,
} from "./PortalSettingUtils";

const PolicyEmailAddresses: FC<PortalSettings> = ({
  settings,
  setPortalSettings,
  errorDetails,
}) => {
  const commonFunction = (value, target) => {
    onChangeSettingField(settings, target, value, setPortalSettings);
  };

  return (
    <>
      <div style={{ margin: "0px 20px" }}>
        <AlignedInputWithLabel
          label={"Policy"}
          inputId={"policy"}
          inputValue={settings?.emailPolicy}
          onChangeRawValue={(value) => commonFunction(value, "emailPolicy")}
          errorMessage={errorDetails?.emailPolicy}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Audit"}
          inputId={"audit"}
          inputValue={settings?.emailAudit}
          onChangeRawValue={(value) => commonFunction(value, "emailAudit")}
          errorMessage={errorDetails?.emailAudit}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Training"}
          inputId={"training"}
          inputValue={settings?.emailTraining}
          onChangeRawValue={(value) => commonFunction(value, "emailTraining")}
          errorMessage={errorDetails?.emailTraining}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Help"}
          inputId={"help"}
          inputValue={settings?.emailHelp}
          onChangeRawValue={(value) => commonFunction(value, "emailHelp")}
          errorMessage={errorDetails?.emailHelp}
          maxLength={100}
        />
      </div>
    </>
  );
};

export default PolicyEmailAddresses;
