import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Font from "../Typography/Font";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import "./collapse.module.css";

type CollapseProps = {
  id?: string;
  name?: string;
  className?: string;
  title?: string | ReactNode;
  fontType?: KeysAsType<FontsType>;
  content?: string | ReactNode;
  expanded?: boolean;
  hasBottomMargin?: boolean;
  hasContentOnReadOnly?: boolean;
  disableGutters?: boolean;
  collapsedHeight?: string;
  square?: boolean;
  shadowContainer?: boolean;
  titleHeightAuto?: boolean;
  primaryColor?: boolean;
  setExpanded?: (expanded: boolean) => void;
  onChange?: (e: any) => void;
  isHeaderCompact?: boolean;
};
const Collapse: FC<CollapseProps> = ({
  id = "",
  name = "",
  className = "",
  title,
  fontType = "BOLD_BODY",
  content,
  expanded = true,
  hasBottomMargin = true,
  hasContentOnReadOnly = false,
  disableGutters = false,
  onChange,
  setExpanded,
  collapsedHeight = "",
  square = false,
  shadowContainer = false,
  titleHeightAuto = false,
  primaryColor = true,
  isHeaderCompact = false,
}) => {
  const [expandedCollapse, setExpandedCollapse] = useState<boolean>(expanded);

  const handleChange = () => {
    setExpanded?.(!expandedCollapse);
    onChange?.(!expandedCollapse);
    setExpandedCollapse(!expandedCollapse);
  };

  useEffect(() => {
    setExpandedCollapse(expanded);
  }, [expanded]);

  return (
    <Box
      className={`true_collapse_wrapper ${
        expanded ? "expanded" : "collapsed"
      } ${className} ${hasBottomMargin ? "margin_bottom" : ""} ${
        shadowContainer ? "shadow_container" : ""
      } ${titleHeightAuto ? "height_auto" : ""} ${
        hasContentOnReadOnly ? "has_read_only_content" : ""
      } ${isHeaderCompact ? "compact_header" : ""}`}
      sx={{
        "& .MuiAccordion-root": {
          height: collapsedHeight === "" ? "100%" : collapsedHeight,
        },
      }}
      true-element={`true-element-collapse-${name}`}
    >
      <Accordion
        className={"true_collapse"}
        onChange={handleChange}
        expanded={expandedCollapse}
        elevation={0}
        disableGutters={disableGutters ?? false}
        square={square ?? false}
      >
        <AccordionSummary
          expandIcon={<ChevronRight true-element={`arrow-collapse-${id}`} />}
          aria-controls={`panel${id}-content`}
          id={id}
          className="true_collapse_title_accordion"
        >
          {React.isValidElement(title) ? (
            title
          ) : (
            <>
              <Font fontType={fontType} primaryColor={primaryColor}>
                {title}
              </Font>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails className="collapse-content-accordion">
          <>{content}</>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Collapse;
