import { FC } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import EndorsementScheduleMultiTableParentComponent from "./EndorsementScheduleMultiTableParentComponent";
import EndorsementScheduleMultiTableChildComponent from "./EndorsementScheduleMultiTableChildComponent";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";
import { getChildrenData } from "./EndorsementBindInstructionsUtils";
import EndorsementScheduleMultiTableFooter from "./EndorsementScheduleMultiTableFooter";

const EndorsementScheduleMultiTable: FC<EndorsementGeneralProps> = (props) => {
  const tableName = `endorsement_schedule_multi_table_${props.insuredId}_${props.policyId}`;
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const atomValue = getAtom();
  const columnsAndGroups = {
    columns:
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.scheduleMultiTable?.columns ?? [],
    groups:
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.scheduleMultiTable?.groups ?? [],
  };
  const childrenData = getChildrenData(
    columnsAndGroups.columns,
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.multiTableRowChildren ?? []
  );

  const multiTableConfiguration: BaseTable2Properties = {
    name: tableName,
    tableType: "multi",
    columnsAndGroups,
    advancedOptions: {
      headerExportType: "aligned",
      paginate: false,
      disableOrderBy: true,
      multiTableProperties: {
        MultiTableHeaderPatternComponent: (params, metaData) => (
          <EndorsementScheduleMultiTableParentComponent
            tabKey={props.tabKey}
            insuredId={props.insuredId}
            policyId={props.policyId ?? -1}
            metaData={metaData}
            headerParams={params ?? []}
            readonly={props.readonly ?? false}
          />
        ),
        MultiTablePatternComponent: (parentMetaData) => (
          <EndorsementScheduleMultiTableChildComponent
            tabKey={props.tabKey}
            insuredId={props.insuredId}
            policyId={props.policyId ?? -1}
            metaDataFromParent={parentMetaData}
          />
        ),
      },
    },
    footerOptions: {
      CustomFooterComponent: () => (
        <EndorsementScheduleMultiTableFooter {...props} />
      ),
    },
    childrenData,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showExcelButton: props.readonly,
      showPDFButton: props.readonly,
    },
  };

  useBaseTable(multiTableConfiguration);

  return (
    <div className={style.endorsement_schedule_bind_instructions_table}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default EndorsementScheduleMultiTable;
