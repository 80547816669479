import { FC } from "react";
import { Box } from "@mui/material";
import {
  Col,
  Input,
  InputAddress,
  InputPhone,
  Row,
  Switch,
  TitleBold,
} from "../../../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import InputMemo from "../../../../../TrueUI/Inputs/InputMemo";
import { SpecificWaiverFormProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { setSpecificWaiverTarget } from "./ModalSpecificWaiverFormUtils";
import { modalSpecificWaiverInputAddressStyles } from "./ModalSpecificWaiverStyles";

const SpecificWaiverForm: FC<SpecificWaiverFormProps> = ({
  specificWaiverUI,
  setSpecificWaiverUI,
  errorDetails,
}) => {
  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col
        {...colWithNoMarginNorGutter}
        verticalGutter="5px"
        horizontalAlign="flex-start"
      >
        <TitleBold>CERTIFICATE HOLDER</TitleBold>
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <Input
          tabIndex={1}
          id="id-waiver-company"
          name="name-waiver-company"
          type={"text"}
          maxLength={200}
          label="Company"
          variant="filled"
          readOnly={false}
          labelFontType="BOLD_CAPTION"
          value={specificWaiverUI?.companyName}
          errorMessage={errorDetails?.companyName}
          onChangeRawValue={(value) =>
            setSpecificWaiverTarget(
              "companyName",
              value,
              specificWaiverUI,
              setSpecificWaiverUI
            )
          }
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <Box sx={modalSpecificWaiverInputAddressStyles()}>
          <InputAddress
            tabIndex={2}
            id="id-waiver-address"
            name="name-waiver-address"
            showAddress2={true}
            readOnly={false}
            labelFontType="BOLD_CAPTION"
            showSingleLabel={false}
            variant="filled"
            initValueAddress1={specificWaiverUI?.address ?? ""}
            initValueAddress2={specificWaiverUI?.address2 ?? ""}
            initValueCity={specificWaiverUI?.city ?? ""}
            initValueStateCode={specificWaiverUI?.state ?? ""}
            initValuePostCode={specificWaiverUI?.postalCode ?? ""}
            onChangeValueAddress1={(value) =>
              setSpecificWaiverTarget(
                "address",
                value,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
            onChangeValueAddress2={(value) =>
              setSpecificWaiverTarget(
                "address2",
                value,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
            onChangeValueCity={(value) =>
              setSpecificWaiverTarget(
                "city",
                value,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
            onChangeStateCode={(value) => {
              if (value != null)
                setSpecificWaiverTarget(
                  "state",
                  value,
                  specificWaiverUI,
                  setSpecificWaiverUI
                );
            }}
            onChangeValuePostCode={(value) =>
              setSpecificWaiverTarget(
                "postalCode",
                value,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
            cityErrorMessage={errorDetails?.city}
          />
        </Box>
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <Input
          tabIndex={7}
          id="id-waiver-contact"
          name="name-waiver-contact"
          label="Contact Name"
          readOnly={false}
          value={specificWaiverUI?.contactName}
          errorMessage={errorDetails?.contactName}
          type={"text"}
          variant="filled"
          maxLength={100}
          labelFontType="BOLD_CAPTION"
          onChangeRawValue={(value) =>
            setSpecificWaiverTarget(
              "contactName",
              value,
              specificWaiverUI,
              setSpecificWaiverUI
            )
          }
        />
      </Col>
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colWithNoMarginNorGutter} verticalGutter="5px">
          <InputPhone
            tabIndex={8}
            id="id-waiver-phone"
            name="name-waiver-phone"
            labelFontType="BOLD_CAPTION"
            phoneLabel="Phone"
            readOnly={false}
            variant="filled"
            phoneValue={specificWaiverUI?.phoneNumber ?? ""}
            errorMessagePhone={errorDetails?.phoneNumber}
            onChangePhoneValue={(value) =>
              setSpecificWaiverTarget(
                "phoneNumber",
                value,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
          />
        </Col>
        <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Switch
            tabIndex={9}
            id="id-waiver-issue"
            name="name-waiver-issue"
            label={"Issue Certificate?"}
            labelFontType="BOLD_CAPTION"
            control="checkbox"
            readOnly={false}
            isChecked={(specificWaiverUI?.issueCert ?? 0) === 1 ? true : false}
            onChangeIsChecked={(value) =>
              setSpecificWaiverTarget(
                "issueCert",
                value ? 1 : 0,
                specificWaiverUI,
                setSpecificWaiverUI
              )
            }
          />
        </Col>
      </Row>
      <Col {...colWithNoMarginNorGutter}>
        <Input
          tabIndex={10}
          id="id-waiver-job"
          name="name-waiver-job"
          label="Job Name"
          labelFontType="BOLD_CAPTION"
          readOnly={false}
          maxLength={200}
          variant="filled"
          value={specificWaiverUI?.jobName}
          errorMessage={errorDetails?.jobName}
          onChangeRawValue={(value) =>
            setSpecificWaiverTarget(
              "jobName",
              value,
              specificWaiverUI,
              setSpecificWaiverUI
            )
          }
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <Input
          tabIndex={11}
          id="id-waiver-location"
          name="name-waiver-location"
          label="Location"
          labelFontType="BOLD_CAPTION"
          readOnly={false}
          maxLength={200}
          variant="filled"
          value={specificWaiverUI?.location}
          errorMessage={errorDetails?.location}
          onChangeRawValue={(value) =>
            setSpecificWaiverTarget(
              "location",
              value,
              specificWaiverUI,
              setSpecificWaiverUI
            )
          }
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <InputMemo
          tabIndex={12}
          id="id-waiver-description"
          name="name-waiver-description"
          label="Description"
          inputFontType="BODY"
          labelFontType="BOLD_CAPTION"
          readOnly={false}
          maxLength={500}
          variant="filled"
          rows={4}
          value={specificWaiverUI?.description}
          errorMessage={errorDetails?.description}
          onChangeRawValue={(value) =>
            setSpecificWaiverTarget(
              "description",
              value,
              specificWaiverUI,
              setSpecificWaiverUI
            )
          }
        />
      </Col>
    </Row>
  );
};

export default SpecificWaiverForm;
