import { FC, useEffect } from "react";
import { PaymentBatchSectionEnums } from "../../../../dtos/payment-batch-section-enums";
import {
  addQueryStrings,
  removeQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { PAYMENT_BATCH_SECTION } from "../../../../utilities/queryStringsHash";
import { getCastedQueryValue } from "../../../../utilities/queryStringsHashFunctions";
import PaymentBatchDetail from "./PaymentBatchDetail";
import PaymentBatchHistory from "./PaymentBatchHistory";
import PaymentBatchPreview from "./PaymentBatchPreview";

const renderPaymentBatchBody = (param: number | undefined) => {
  switch (param) {
    case PaymentBatchSectionEnums.PAYMENT_BATCH_HISTORY:
      return <PaymentBatchHistory />;
    case PaymentBatchSectionEnums.PAYMENT_BATCH_DETAILS:
      return <PaymentBatchDetail />;
    case PaymentBatchSectionEnums.PAYMENT_BATCH_PREVIEW:
      return <PaymentBatchPreview />;
    default:
      return <PaymentBatchHistory />;
  }
};

const PaymentBatchBodyWrapper: FC = () => {
  const paymentBatchSection = getCastedQueryValue(PAYMENT_BATCH_SECTION);

  useEffect(() => {
    addQueryStrings([
      {
        nameOfHash: PAYMENT_BATCH_SECTION,
        valueOfHash: paymentBatchSection,
      },
    ]);
    return () => {
      removeQueryStrings([PAYMENT_BATCH_SECTION]);
    };
  }, []);

  return renderPaymentBatchBody(paymentBatchSection);
};

export default PaymentBatchBodyWrapper;
