import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../../TrueUI";
import { ModalMovePaymentProps } from "../FormComponents/MovePaymentTypes";
import { ReservesInformationDto } from "../../../../../../../dtos/reserves-information-dto";
import { useApiGet, useApiPost } from "../../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import FormMovePayment from "./FormMovePayment";
import { MovePaymentDto } from "../../../../../../../dtos/move-payment-dto";

const ModalMovePayment: FC<ModalMovePaymentProps> = ({
  isVoidedRefundOrMoveModalConfig,
  setIsVoidedRefundOrMoveModalConfig,
  claimId,
}) => {
  const [reservesInformation, setReservesInformation] =
    useState<ReservesInformationDto | null>(null);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [searchedClaimId, setSearchedClaimId] = useState<number | null>(
    isVoidedRefundOrMoveModalConfig?.movePaymentData?.claimId ?? claimId ?? null
  );
  const { responseGet, dispatchGet } = useApiGet<ReservesInformationDto>(
    `api/Payment/GetReservesInformation?claimId=${searchedClaimId ?? 0}`
  );
  const { responsePost, dispatchPost } = useApiPost<MovePaymentDto>(
    `api/Payment/MovePayment`,
    {
      claimId: searchedClaimId,
      paymentId:
        isVoidedRefundOrMoveModalConfig?.movePaymentData?.paymentId ?? 0,
    }
  );

  useEffect(() => {
    dispatchGet();
  }, [searchedClaimId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setReservesInformation(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setIsVoidedRefundOrMoveModalConfig?.({
        voided: false,
        refund: true,
        refundPaymentData: null,
        reserveTypes: [],
        paymentId: 0,
        refreshTable: true,
        movePayment: false,
        movePaymentData: null,
      });
    }
  }, [responsePost]);

  const getReserve = (reserveTypeId) => {
    return reservesInformation?.reserves?.find(
      (x) => x?.reserveTypeId === reserveTypeId
    );
  };
  const savePayment = () => {
    if (
      isVoidedRefundOrMoveModalConfig?.movePaymentData?.claimId !==
      searchedClaimId
    ) {
      dispatchPost();
    }
  };

  return (
    <Modal
      id={"move_payment_modal"}
      title={"Move Payment"}
      open={isVoidedRefundOrMoveModalConfig?.movePayment ?? false}
      closeEvent={() =>
        setIsVoidedRefundOrMoveModalConfig?.({
          ...isVoidedRefundOrMoveModalConfig,
          movePayment: false,
          movePaymentData: null,
        })
      }
      showCloseButton
      cancelEvent={() =>
        setIsVoidedRefundOrMoveModalConfig?.({
          ...isVoidedRefundOrMoveModalConfig,
          movePayment: false,
          movePaymentData: null,
        })
      }
      saveEvent={savePayment}
      saveDisabled={saveButtonDisabled}
      cancelButtonWithConfirmation={true}
    >
      {reservesInformation !== null && (
        <FormMovePayment
          paymentFormData={
            isVoidedRefundOrMoveModalConfig?.movePaymentData ?? null
          }
          claimReserve={
            getReserve(
              isVoidedRefundOrMoveModalConfig?.movePaymentData?.reserveTypeId
            ) ?? null
          }
          setSaveButtonDisabled={setSaveButtonDisabled}
          setSearchedClaimId={setSearchedClaimId}
        />
      )}
    </Modal>
  );
};

export default ModalMovePayment;
