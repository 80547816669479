import { FC, useEffect, useState } from "react";
import {
  EndorsementGeneralProps,
  EndorsementInformationUIProps,
} from "../EndorsementForm/EndorsementTypes";
import { Col, Font, Input, InputDate, InputMemo, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  INSURED_ATOM_KEY,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import {
  CONTINUE_ACTION,
  ERROR_DATE,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
  hasExposurePremiumRelatedChecked,
} from "../EndorsementForm/EndorsementUtils";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import EndorsementTypeOptions, {
  SpecialRuleOnChangeSelection,
} from "./EndorsementTypeOptions";
import {
  ENDORSEMENT_COMMENTS,
  POLICY_CHANGE_EFFECTIVE_DATE,
  QUOTE_EFFECTIVE_DATE,
  QUOTE_EXPIRATION_DATE,
  QUOTE_NAME,
  getAtomUpdatedByChangedValues,
  getAtomUpdatedByEndorsementTypeListChange,
  getUpdatedEndorsementSelectedList,
} from "./EndorsementInformationUtils";
import { getDateObject } from "../../../../utilities/dateFunctions";
import style from "../EndorsementForm/Endorsement.module.css";
import { executeEndorsementEngine } from "./EndorsementEngineUtils";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { EndorsementConfigurationDto } from "../../../../dtos/endorsement-configuration-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  SaveQuoteAtom,
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import { EndorsementCommentBlob } from "../../../../dtos/endorsement-comment-blob";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";

const EndorsementInformation: FC<EndorsementGeneralProps> = ({
  tabKey,
  insuredId,
  policyId,
  previousQuoteId,
  readonly,
  setProducerModalProps,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [endorsementInformationUI, setEndorsementInformationUI] =
    useState<EndorsementInformationUIProps>();
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const {
    responsePost: responseSaveEndorsement,
    dispatchPost: dispatchSaveEndorsement,
  } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SavePolicyEndorsement?endorsementSection=${EndorsementSectionEnum.ENDORSEMENT_INFORMATION}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const {
    responseGet: responseEndorsementConfiguration,
    dispatchGet: dispatchEndorsementConfiguration,
  } = useApiGet<EndorsementConfigurationDto>(
    `api/QuotePolicy/GetEndorsementConfiguration?insuredId=${insuredId}&policyId=${policyId}&previousQuoteId=${previousQuoteId}`
  );
  const recheckListener = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementInformationComponent")
  );

  const updateEndorsementTypeSelectedList = (
    isChecked: boolean,
    endorsementType: PolicyEnumValueDescriptionAndBooleanBlob,
    endorsementTypeSelectedList: PolicyEnumValueDescriptionAndBooleanBlob[],
    specialRuleOnChangeSelection?: SpecialRuleOnChangeSelection
  ) => {
    const atomValue = getAtom();

    if (endorsementInformationUI !== undefined) {
      const newEndorsementTypeSelectedList = getUpdatedEndorsementSelectedList(
        isChecked,
        endorsementType,
        endorsementTypeSelectedList
      );

      const newAtomValue = getAtomUpdatedByEndorsementTypeListChange(
        newEndorsementTypeSelectedList,
        atomValue,
        specialRuleOnChangeSelection
      ) as GlobalInsuredAtomProperties;

      const endorsementCommentsUpdated =
        newAtomValue?.policyQuoteInformation?.policyQuote?.quote
          ?.endorsementComments;

      setAtom(newAtomValue);

      setEndorsementInformationUI({
        ...endorsementInformationUI,
        endorsementTypeSelectedList: newEndorsementTypeSelectedList ?? [],
        endorsementComments: endorsementCommentsUpdated ?? [],
      });
      setPolicyQuoteTriggers(["endorsementCheckItem"]);

      if (specialRuleOnChangeSelection === "open-producer-change-modal")
        setProducerModalProps?.(newEndorsementTypeSelectedList);
    }
  };

  const updateAtomAndLocalHookByChange = (
    targetValue: any,
    targetName: string,
    newEndorsementInformationUI: EndorsementInformationUIProps
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByChangedValues(
      targetValue,
      targetName,
      atomValue,
      newEndorsementInformationUI.endorsementNamePrefix
    );

    setAtom(newAtomValue);
    setEndorsementInformationUI(newEndorsementInformationUI);
  };

  const getInitialPolicyJSONAndUpdateAtom = (
    policyJSON: PolicyBlob,
    atomValue: GlobalInsuredAtomProperties | null
  ) => {
    if (policyJSON.quote?.quoteID !== 0) {
      setAtom(atomValue);

      return policyJSON;
    }
    const { policyJSONUpdated, newAtomValue } =
      executeEndorsementEngine(atomValue);
    setAtom(newAtomValue);

    return policyJSONUpdated;
  };

  const setIncomingValuesToLocalHook = () => {
    const atomValue = getAtom();
    const policyJSONInAtom = atomValue?.policyQuoteInformation?.policyQuote;
    const quoteJSONInAtom = policyJSONInAtom?.quote;
    if (
      conditionHasValue(policyJSONInAtom) &&
      conditionHasValue(quoteJSONInAtom)
    ) {
      const policyJSON = getInitialPolicyJSONAndUpdateAtom(
        policyJSONInAtom,
        atomValue
      );
      const quoteJSON = policyJSON.quote;

      setEndorsementInformationUI({
        quoteEffectiveDate: quoteJSON?.effectiveDate ?? ERROR_DATE,
        quoteExpirationDate: quoteJSON?.expirationDate ?? ERROR_DATE,
        endorsementEffectiveDate:
          policyJSON.policyChangeEffectiveDate ?? ERROR_DATE,
        endorsementSequenceNumber: quoteJSON?.sequenceNumber ?? -1,
        endorsementNamePrefix:
          policyJSON.endorsementConfiguration?.endorsementPrefixName ?? "",
        endorsementName:
          policyJSON.endorsementConfiguration?.endorsementName ?? "",
        endorsementComments: quoteJSON?.endorsementComments ?? [],
        endorsementTypeSelectedList: quoteJSON?.endorsementTypeList ?? [],
        endorsementTypeConfigList:
          policyJSON.endorsementConfiguration?.endorsementTypeList ?? [],
      });
    }
  };

  useEffect(() => {
    setIncomingValuesToLocalHook();
    setPolicyQuoteTriggers(["endorsementCheckItem"]);
  }, []);

  const listenerPolicyKeyFieldsSave = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementPolicyKeyFieldsSave")
  );
  const listenerProducerChangeSave = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementProducerChangeSave")
  );
  useEffect(() => {
    if (listenerPolicyKeyFieldsSave !== null) {
      if (conditionHasValue(endorsementInformationUI)) {
        const atomValue = getAtom();
        const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
        const newComments = policyJSON?.quote?.endorsementComments ?? [];
        setEndorsementInformationUI({
          ...endorsementInformationUI,
          endorsementComments: newComments ?? [],
          endorsementTypeSelectedList:
            policyJSON?.quote?.endorsementTypeList ?? [],
        });
      }
      clearPolicyQuoteTriggers(["endorsementPolicyKeyFieldsSave"]);
      setPolicyQuoteTriggers(["endorsementCheckItem"]);
    }
  }, [listenerPolicyKeyFieldsSave]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (!isFirstRender) {
      if (!isEmptyValue(recheckListener)) {
        dispatchEndorsementConfiguration();
      }
    }
  }, [recheckListener]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseEndorsementConfiguration) &&
      conditionHasValue(endorsementInformationUI)
    ) {
      const atomValue = getAtom();
      const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
      const userComments =
        endorsementInformationUI?.endorsementComments?.filter(
          (c) => c.isUserEdited
        ) ?? [];

      const endorsementConfigResponseData =
        responseEndorsementConfiguration.responseData;
      const updatedPolicyJSON = {
        ...policyJSON,
        endorsementConfiguration: {
          ...endorsementConfigResponseData,
          endorsementTypeList:
            endorsementInformationUI.endorsementTypeSelectedList,
        },
        insured: endorsementConfigResponseData?.oldPolicyJSON?.insured,
        quote: {
          ...policyJSON?.quote,
          endorsementComments: userComments,
        },
      } as PolicyBlob;
      const updatedAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        updatedPolicyJSON
      );
      const { newAtomValue, policyJSONUpdated } =
        executeEndorsementEngine(updatedAtomValue);
      setAtom(newAtomValue);

      const newComments = policyJSONUpdated?.quote?.endorsementComments ?? [];
      setEndorsementInformationUI({
        ...endorsementInformationUI,
        endorsementComments: newComments ?? [],
        endorsementTypeSelectedList:
          policyJSONUpdated?.quote?.endorsementTypeList ?? [],
        endorsementTypeConfigList:
          policyJSON?.endorsementConfiguration?.endorsementTypeList ?? [],
      });
    }
  }, [responseEndorsementConfiguration]);

  useEffect(() => {
    if (listenerProducerChangeSave !== null) {
      if (
        endorsementInformationUI !== null &&
        endorsementInformationUI !== undefined
      ) {
        const atomValue = getAtom();
        const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
        const newComments = policyJSON?.quote?.endorsementComments ?? [];
        setEndorsementInformationUI({
          ...endorsementInformationUI,
          endorsementComments: newComments ?? [],
          endorsementTypeSelectedList:
            policyJSON?.quote?.endorsementTypeList ?? [],
          endorsementTypeConfigList:
            policyJSON?.endorsementConfiguration?.endorsementTypeList ?? [],
        });
      }
      clearPolicyQuoteTriggers(["endorsementProducerChangeSave"]);
      setPolicyQuoteTriggers(["endorsementCheckItem"]);
    }
  }, [listenerProducerChangeSave]);

  const joinedEndorsementComments =
    endorsementInformationUI?.endorsementComments
      ?.map((comment) => comment?.textComment ?? "")
      .join("\n");

  const getEndorsementCommentsFromText = (inputValue: string) => {
    const allTextComments = inputValue.split("\n");
    const systemComments = endorsementInformationUI?.endorsementComments.filter(
      (c) => c.isUserEdited === false
    );
    const allEndorsementComments = allTextComments.map(
      (comment) =>
        ({
          textComment: comment,
          isUserEdited:
            systemComments?.map((c) => c.textComment).includes(comment) ===
            false,
        } as EndorsementCommentBlob)
    );
    return allEndorsementComments;
  };

  //#region Save Logic
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveEndorsement = (
    policyJSON: PolicyBlob,
    saveAction: string
  ) => {
    const atomValue = getAtom();
    const activeSection = hasExposurePremiumRelatedChecked(
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.endorsementTypeList
    )
      ? EndorsementSectionEnum.EXPOSURE_AND_PREMIUM
      : EndorsementSectionEnum.BIND_INSTRUCTIONS;

    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      saveAction,
      atomValue,
      policyJSON,
      activeSection
    );
    setAtom(newAtomValue);

    addQueryStrings([
      {
        nameOfHash: QUOTE_ID,
        valueOfHash: policyJSON.quote?.quoteID?.toString() ?? "-1",
      },
    ]);

    if (saveAction === CONTINUE_ACTION)
      setPolicyQuoteTriggers(["endorsementComponent"]);

    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      dispatchSaveEndorsement();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseSaveEndorsement) &&
      conditionHasValue(responseSaveEndorsement.axiosResponse) &&
      (saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
        saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION)
    ) {
      updateAtomAfterSaveEndorsement(
        responseSaveEndorsement.axiosResponse.data,
        saveQuoteAtom.saveAction
      );
    }
  }, [responseSaveEndorsement]);
  //#endregion

  return endorsementInformationUI ? (
    <div
      id="endorsement-information-container"
      className={style.endorsement_scrollable_body_container}
    >
      <Row {...rowWithNoMarginNorGutter} rowWidth="80%">
        <Col>
          <InputDate
            id="quote-effective-date-id"
            name="quote-effective-date"
            label="Effective Date"
            value={endorsementInformationUI.quoteEffectiveDate}
            readOnly={readonly}
            onChangeRawValue={(value) =>
              updateAtomAndLocalHookByChange(value, QUOTE_EFFECTIVE_DATE, {
                ...endorsementInformationUI,
                quoteEffectiveDate: getDateObject(value),
              })
            }
          />
        </Col>
        <Col>
          <InputDate
            id="quote-expiration-date-id"
            name="quote-expiration-date"
            label="Expiration Date"
            value={endorsementInformationUI.quoteExpirationDate}
            readOnly={readonly}
            onChangeRawValue={(value) =>
              updateAtomAndLocalHookByChange(value, QUOTE_EXPIRATION_DATE, {
                ...endorsementInformationUI,
                quoteExpirationDate: getDateObject(value),
              })
            }
          />
        </Col>
        <Col>
          <InputDate
            id="endorsement-effective-date-id"
            name="endorsement-effective-date"
            label="Endorsement Effective Date"
            value={endorsementInformationUI.endorsementEffectiveDate}
            readOnly={readonly}
            onChangeRawValue={(value) =>
              updateAtomAndLocalHookByChange(
                value,
                POLICY_CHANGE_EFFECTIVE_DATE,
                {
                  ...endorsementInformationUI,
                  endorsementEffectiveDate: getDateObject(value),
                }
              )
            }
          />
        </Col>
        <Col>
          <Input
            id="endorsement-sequence-number"
            name="endorsement-sequence-number"
            label="Sequence Number"
            value={endorsementInformationUI.endorsementSequenceNumber}
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start">
          <Font>
            Changing the effective date of the endorsement will prorate any
            changes to the policy
          </Font>
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        rowWidth="80%"
      >
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Input
            id="endorsement-name-prefix"
            name="endorsement-name-prefix"
            label="Endorsement Prefix"
            value={endorsementInformationUI.endorsementNamePrefix}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 7, sm: 7, md: 7, lg: 7, xl: 7 }}>
          <Input
            id="endorsement-name"
            name="endorsement-name"
            label="Endorsement Name"
            value={endorsementInformationUI.endorsementName}
            readOnly={readonly}
            onChangeRawValue={(value) =>
              updateAtomAndLocalHookByChange(value, QUOTE_NAME, {
                ...endorsementInformationUI,
                endorsementName: value,
              })
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start">
          <Font>The following item(s)</Font>
        </Col>
      </Row>
      <EndorsementTypeOptions
        endorsementTypeSelectedList={
          endorsementInformationUI.endorsementTypeSelectedList
        }
        readonly={readonly ?? false}
        updateEndorsementTypeSelectedList={updateEndorsementTypeSelectedList}
      />
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start">
          <Font>is changed to read:</Font>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalGutter="15px">
          <InputMemo
            id="endorsement-comments-id"
            name="endorsement-comments"
            inputFontType="BODY"
            labelFontType="CAPTION"
            variant="filled"
            rows={12}
            value={joinedEndorsementComments}
            readOnly={readonly}
            onChangeRawValue={(value: string) => {
              const allEndorsementComments =
                getEndorsementCommentsFromText(value);
              updateAtomAndLocalHookByChange(
                allEndorsementComments,
                ENDORSEMENT_COMMENTS,
                {
                  ...endorsementInformationUI,
                  endorsementComments: allEndorsementComments,
                }
              );
            }}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );
};

export default EndorsementInformation;
