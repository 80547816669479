import { FC } from "react";
import { Box } from "@mui/material";
import { BaseTable } from "../../../dtos/base-table";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2, Col, Font, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import style from "./quickActions.module.css";

type NewSubmissionTableProps = {
  newSubmissionTableUI?: BaseTable | null;
};

const NewSubmissionTable: FC<NewSubmissionTableProps> = ({
  newSubmissionTableUI,
}) => {
  const TABLE_NAME = `new_submission_table`;

  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    columnsAndData: {
      columns: newSubmissionTableUI?.columns ?? [],
      data: newSubmissionTableUI?.data ?? [[]],
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
      },
    },
    columnOptions: [
      {
        fieldName: "CompanyName",
        width: "60",
      },
      {
        fieldName: "TaxId",
        width: "40",
      },
      {
        fieldName: "EffectiveDate",
        align: "right",
        width: "35",
      },
    ],
    toolbarOptions: { hideToolbar: true },
  };

  useBaseTable(tableConfiguration);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        className={style.new_submission_table_title}
      >
        <Col {...colWithNoMarginNorGutter}>
          <Font whiteSpace="normal" textAlign="start">
            The company you entered currently exists in the system. Here's what
            we found.
            <br />
            You can choose to continue and add the company again or select from
            below and start a new quote.
          </Font>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colWithNoMarginNorGutter} displayFlex={false}>
          <Box>
            <BaseTable2 name={TABLE_NAME} />
          </Box>
        </Col>
      </Row>
    </>
  );
};

export default NewSubmissionTable;
