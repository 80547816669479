import { FC, useEffect, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { useApiPost, useFileRequestInstance } from "../../hooks";
import { cellManagerCells } from "../TrueUI/Tables/TableAtoms";
import {
  DELETED_COLUMN_FIELD_NAME,
  NO_URL_PROVIDED_ERROR,
  ROW_KEY_COLUMN_FIELD_NAME,
} from "../TrueUI/Tables/TableConstants";
import {
  addMultiple,
  addRowKeysWithOutRepeat,
  addRowKeyWithOutRepeat,
  castToRequestedInternalColumnsDataType,
  findRowByKey,
  flagRowAsDeleted,
  flagRowsAsDeleted,
  getColumnByColumnIndex,
  getDataOfRowKeys,
  getDataWithoutDeletedRows,
  getSafeRowKey,
  hydrateDataSingle,
  hydrateDataWithRequestedInternalColumns,
  OverridableInternalColumnValueProps,
  stringifyAndDehydrateDataObject,
  stringifyHydratedDataObject,
  updateDataByRowKey,
  updateInternalColumnValuesByConfiguration,
  upsertDataRows,
} from "../TrueUI/Tables/tableFunctions";
import {
  HydratedData,
  TableDataItem,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { BaseTableInputType } from "../../dtos/base-table-input-type";
import { useGridInstance } from "./Hooks/useGridInstance";
import {
  AddRowParameters,
  BaseGridInternalProperties,
  BaseGridProperties,
} from "./BaseGridProperties";
import {
  add,
  getGridDataItemByRowKey,
  updateGridDataItemWithNewValue,
  upsertRowData,
} from "./SupportFunctions/baseGridFunctions";
import {
  ChangedSingleGridCellInstanceCellKeysAtom,
  GridCellManagerInstanceAtomFamily,
  SingleGridCellInstanceAtomFamily,
} from "./BaseGridAtoms";
import {
  castToDataType,
  hydrateData2,
} from "./SupportFunctions/OLD_baseGridFunctions";
import { useGridCellManager } from "./Hooks/useGridCellManager";

type BaseGridEventListenerProperties = {
  uiid?: string;
};

const BaseGridEventListener: FC<BaseGridEventListenerProperties> = ({
  uiid,
}) => {
  // TODO - move listener events here

  // Note - This is sort of confusing because the name has the word "row" in it, but we're actually setting the entire table instance further down the code?
  // TODO - rename newRowData to something better
  const [newRowData, setNewRowData] = useState<{
    BaseGridProperties: Partial<BaseGridProperties>;
    BaseGridInternalProperties: Partial<BaseGridInternalProperties>;
  } | null>(null);

  const {
    instanceSelector,
    instanceInternalSelector,
    // setInstance,
    setEntireInstance,
  } = useGridInstance(uiid ?? "NO_UIID_FOUND", "BaseGridEventListener");

  const { updateAllCellInstancesWithSameValues } = useGridCellManager({ uiid });

  const [selectedRowByRowKey, setSelectedRowByRowKey] = useState<{
    rowKey: string | null;
    state: "delete" | "is_add_row" | "is_update_row";
    row?: string[];
  } | null>(null);

  const [cellAtomInstance, setCellAtomInstance] = useRecoilState(
    GridCellManagerInstanceAtomFamily(
      selectedRowByRowKey?.rowKey ?? "NO_ROW_KEY_FOUND"
    )
  );

  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const { downloadMultipleFiles } = useFileRequestInstance(true);

  const postData = useApiPost(instance?.postURL ?? NO_URL_PROVIDED_ERROR, {
    [instance?.postTarget ?? "changedTableData"]:
      instanceInternal?.dataToBeSaved,
  });

  const save = () => {
    if (instance?.changedData?.length ?? 0 > 0) {
      const castedUpdatedData = castToDataType(
        instance?.columns ?? [],
        instance?.changedData ?? []
      );

      const dataToSave = hydrateData2(
        instance?.columns ?? [],
        castedUpdatedData
      ).map((row) => {
        return { ...row };
      });

      setEntireInstance({
        // ...(tableInstance as TableInstanceType2),
        BaseGridProperties: {
          ...instance,
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          dataToBeSaved: dataToSave,
        },
      });
    }
    instance?.methods?.onSaveClick?.();
  };

  useEffect(() => {
    if (instance?.hasToggledSave) {
      save();
    }
  }, [instance?.hasToggledSave]);

  useEffect(() => {
    if (
      (instanceInternal?.dataToBeSaved?.length ?? 0) > 0 &&
      instance?.postURL !== undefined &&
      instance?.postURL !== null &&
      instance?.postURL !== ""
    ) {
      postData.dispatchPost();
    }
  }, [instanceInternal?.dataToBeSaved]);

  useEffect(() => {
    if (postData.responsePost?.errorResponse) {
      setEntireInstance({
        BaseGridProperties: {
          hasToggledSave: false,
        },
        // ...(tableInstance as TableInstanceType2),
        // validationErrors: postData.responsePost.errorResponse.errorDetails,
        // isSave: false,
      });
    }
    if (postData.responsePost?.requestInstanceSuccessful) {
      setEntireInstance({
        // ...tableInstance,
        BaseGridProperties: {
          changedData: [],
          hasToggledSave: false,
          hasToggledEdit: false,
        },
        BaseGridInternalProperties: {
          dataToBeSaved: [],
          recentlyAddedRowKey: [],
        },

        // isSave: false,
        // isEdit: false,
        // toggleEditModeState: false,
        // validationErrors: null,
      });
    }
  }, [postData.responsePost]);

  useEffect(() => {
    if (((instance?.changedData ?? []).length ?? 0) > 0) {
      const castedData = castToRequestedInternalColumnsDataType(
        instance?.columns ?? [],
        [DELETED_COLUMN_FIELD_NAME, ROW_KEY_COLUMN_FIELD_NAME],
        instance?.changedData ?? []
      );

      const hydratedChangedData = hydrateDataWithRequestedInternalColumns(
        instance?.columns ?? [],
        [DELETED_COLUMN_FIELD_NAME, ROW_KEY_COLUMN_FIELD_NAME],
        castedData ?? []
      );

      // the onChangeDataListener is also configured for multi table data since that data is different in structure. see MultiCollapse for implementation details.
      if (instance?.tableType !== "multi") {
        instance?.events?.onChangeDataListener?.(hydratedChangedData);
      }
    }
  }, [instance?.changedData]);

  const [currentUpdateRow, setCurrentUpdateRow] = useState<any>(null);

  const [cells, setCells] = useRecoilState(
    cellManagerCells(currentUpdateRow?.rowKey ?? "")
  );

  useEffect(() => {
    if (currentUpdateRow !== null && cells !== null) {
      const updatedCells = cells.map((c) => {
        return {
          ...c,
          currentValue:
            currentUpdateRow.hydratedData[c.column.fieldName ?? ""] ?? "",
        };
      });

      setCells(updatedCells);
    }
  }, [currentUpdateRow]);

  const addRowInternal = (newRow: any) => {
    const rowKey = getSafeRowKey(newRow.defaults);

    const dataCopy = [...(instance?.data ?? [])];
    dataCopy.splice(0, 0, newRow?.defaults ?? []);
    const changedDataCopy = [...(instance?.changedData ?? [])];
    changedDataCopy.splice(0, 0, newRow?.defaults ?? []);
    const sortedAndFilteredDataCopy = [
      ...(instanceInternal?.sortedAndFilteredData ?? [] ?? []),
    ];
    sortedAndFilteredDataCopy.splice(0, 0, newRow?.defaults ?? []);

    setEntireInstance({
      BaseGridProperties: {
        ...instance,
        data: dataCopy,
        changedData: changedDataCopy,
        hasToggledEdit: true,
        toolbarOptions: {
          ...instance.toolbarOptions,
          showEditButton: true,
          showSaveButton: true,
        },
      },
      BaseGridInternalProperties: {
        ...instanceInternal,
        sortedAndFilteredData: sortedAndFilteredDataCopy,
        refreshAfterAddRow: true,
        newestRowKey: rowKey ?? "NO_ROW_KEY_FOUND",
        rowKeys: instanceInternal.rowKeys.concat(rowKey),
      },
    });

    if (newRow.isEditableAfterAdd) {
      setSelectedRowByRowKey({ rowKey, state: "is_add_row" });
    }
  };

  const addRowExternal = (addRowParams: AddRowParameters) => {
    if (addRowParams) {
      const hydratedAddedRowData = addRowParams.hydratedData;

      const dehydratedNewRow = conditionHasValue(hydratedAddedRowData)
        ? stringifyAndDehydrateDataObject(hydratedAddedRowData)
        : [];

      const clonedNewRow = [...dehydratedNewRow];

      clonedNewRow.unshift(
        crypto.randomUUID(),
        instance?.advancedOptions?.indicationColumnConfiguration
          ?.indicationType ?? "none"
      );
      clonedNewRow.push(
        instance?.advancedOptions?.optionsColumnConfiguration?.optionType ??
          "none",
        "true",
        "false"
      );

      const cloneData = [...(instance?.data ?? [])];
      const cloneChangedData = [...(instance?.changedData ?? [])];
      cloneData.unshift(clonedNewRow);
      cloneChangedData.unshift(clonedNewRow);

      const newRow = add(
        instance?.columns ?? [],
        instance?.data ?? [],
        instance?.changedData ?? [],
        dehydratedNewRow
      );

      // TODO - this needs to review and a proper fix

      // const overridableConfiguration = {
      //   indicationType:
      //     instance?.advancedOptions?.indicationColumnConfiguration
      //       ?.indicationType,
      //   optionType:
      //     instance?.advancedOptions?.optionsColumnConfiguration?.optionType,
      // } as OverridableInternalColumnValueProps;

      // const updateInternalOverridableColumnValues =
      //   updateInternalColumnValuesByConfiguration(
      //     instance?.columns ?? [],
      //     newRow.updatedTableData,
      //     overridableConfiguration
      //   );

      const allUpdatedRowKeys =
        newRow.updatedTableData.map((d) => getSafeRowKey(d)) ?? [];

      // console.log("addExternalRow", {
      //   x1,
      //   newRow,
      //   updatedTableData: newRow.updatedTableData,
      //   data: updateInternalOverridableColumnValues,
      //   changedData: newRow?.updatedTableChangedData,
      //   allUpdatedRowKeys,
      //   newRowKey: newRow.newRowKey,
      //   columns: instance?.columns,
      //   addRowParams,
      //   hydratedAddedRowData,
      //   dehydratedNewRow,
      // });

      setNewRowData({
        BaseGridProperties: {
          // data: updateInternalOverridableColumnValues ?? [],
          // changedData: newRow?.updatedTableChangedData ?? [],
          data: cloneData ?? [],
          changedData: cloneChangedData ?? [],
          allRowKeys: allUpdatedRowKeys,
          selectedEditRows:
            addRowParams?.isEditableAfterAdd === true
              ? addRowKeyWithOutRepeat(
                  instanceInternal?.selectedEditRows ?? [],
                  newRow.newRowKey
                )
              : null,
          toggleEditModeState: true,
        } as BaseGridProperties,
        BaseGridInternalProperties: {
          newestRowKey: newRow.newRowKey,
          requestingCellManagerRowKeys: addRowKeyWithOutRepeat(
            instanceInternal?.requestingCellManagerRowKeys ?? [],
            newRow.newRowKey
          ),
          recentlyAddedRowKey: addRowKeyWithOutRepeat(
            instanceInternal?.recentlyAddedRowKey ?? [],
            newRow.newRowKey
          ),
          accessors: {
            _addRowInternal: null,
            _addRowExternal: null,
          },
        } as BaseGridInternalProperties,
      });

      setEntireInstance({
        BaseGridProperties: {
          ...instance,
          hasToggledEdit: true,
          toolbarOptions: {
            ...instance.toolbarOptions,
            showEditButton: true,
            showSaveButton: true,
          },
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
        },
      });

      if (addRowParams.isEditableAfterAdd) {
        setSelectedRowByRowKey({
          rowKey: newRow.newRowKey,
          state: "is_add_row",
        });
      }
    }
  };

  useEffect(() => {
    if (
      instanceInternal?.accessors?._addRowInternal !== undefined &&
      instanceInternal?.accessors?._addRowInternal !== null
    ) {
      addRowInternal(instanceInternal?.accessors?._addRowInternal);
    }

    if (
      instanceInternal?.accessors?._addRowExternal !== undefined &&
      instanceInternal?.accessors?._addRowExternal !== null
    ) {
      addRowExternal(instanceInternal?.accessors?._addRowExternal);
    }
  }, [
    instanceInternal?.accessors?._addRowExternal,
    instanceInternal?.accessors?._addRowInternal,
  ]);

  useEffect(() => {
    if (newRowData !== null) {
      setEntireInstance(newRowData);
      setNewRowData(null);
    }
  }, [newRowData]);

  useEffect(() => {
    if (
      instanceInternal?.accessors?._updateRowExternal !== undefined &&
      instanceInternal?.accessors?._updateRowExternal !== null
    ) {
      // TODO - create a pipeline that executes onComputeInit values againest updated values

      const safeString = stringifyHydratedDataObject(
        instanceInternal?.accessors?._updateRowExternal.hydratedData
      );

      const updatedData = updateDataByRowKey(
        instanceInternal?.accessors?._updateRowExternal.rowKey,
        instance?.columns ?? [],
        instance?.data ?? [],
        safeString
      );

      const updatedRow = findRowByKey(
        instanceInternal?.accessors?._updateRowExternal?.rowKey ?? "",
        updatedData
      );

      const updatedChangedData = upsertRowData(
        updatedRow,
        instance?.changedData ?? []
      );

      const sortedAndFitleredChangedData = upsertRowData(
        updatedRow,
        instanceInternal?.sortedAndFilteredData
      );

      // TODO - add support for 'allRowKeys' prop

      setEntireInstance({
        BaseGridProperties: {
          ...instance,
          data: updatedData,
          changedData: updatedChangedData ?? [],
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          sortedAndFilteredData: sortedAndFitleredChangedData,
          recentlyUpdatedData: [updatedRow], // Currently we only allow one update at a time but I made this an array in the event we update this to support multiple updates (hope not).
          accessors: {
            ...instanceInternal.accessors,
          },
        },
      });

      setSelectedRowByRowKey({
        rowKey: instanceInternal?.accessors?._updateRowExternal.rowKey,
        state: "is_update_row",
        row: updatedRow ?? [],
      });

      instance.events?.onUpdateRow?.(
        instanceInternal?.accessors?._updateRowExternal.rowKey
      );
      setCurrentUpdateRow(instanceInternal?.accessors?._updateRowExternal);
    }
  }, [instanceInternal?.accessors?._updateRowExternal]);

  useEffect(() => {
    if (
      instanceInternal?.accessors?._updateRowInternal !== null &&
      instanceInternal?.accessors?._updateRowInternal !== undefined
    ) {
      const updateRowInternal =
        instanceInternal?.accessors?._updateRowInternal ?? null;

      const recentRow = getGridDataItemByRowKey(
        updateRowInternal?.rowKey ?? "NO_ROWKEY_FOUND",
        instance?.data ?? []
      );

      const updatedRow = updateGridDataItemWithNewValue(
        recentRow,
        updateRowInternal?.columnIndex ?? -1,
        updateRowInternal?.value
      );
      const updatedData = upsertRowData(updatedRow, instance?.data ?? []);

      const updatedChangedData = upsertRowData(
        updatedRow,
        instance?.changedData ?? []
      );

      setEntireInstance({
        BaseGridProperties: {
          data: updatedData,
          changedData: updatedChangedData,
        },
      });
      instance.events?.onUpdateRow?.(updateRowInternal?.rowKey);
    }
  }, [instanceInternal?.accessors?._updateRowInternal]);

  const mergeDeletedHydratedDataWithFlatData = (
    row: TableDataItem,
    hydratedData: HydratedData
  ): string[] =>
    row.map((v, i) => {
      const foundColumn = getColumnByColumnIndex(i, instance?.columns ?? []);
      const valueFromHydratedData = hydratedData[foundColumn?.fieldName ?? ""];

      if (
        valueFromHydratedData === undefined ||
        valueFromHydratedData === null
      ) {
        return v;
      }

      return valueFromHydratedData;
    });

  const deleteRow = (deletedRow: any) => {
    // if (conditionHasValue(instanceInternal?.accessors?._deleteRowExternal)) {
    const rowKey = deletedRow?.rowKey ?? "NO_ROW_KEY_FOUND";
    const foundRow = findRowByKey(rowKey, instance?.data ?? []);

    const stringSafeHydratedData = stringifyHydratedDataObject(
      deletedRow?.hydratedData
    );

    const mergedResults = mergeDeletedHydratedDataWithFlatData(
      foundRow,
      stringSafeHydratedData
    );

    const updatedDataWithFlag = flagRowAsDeleted(
      instance?.columns ?? [],
      mergedResults
    );

    const updatedData = upsertDataRows(
      [updatedDataWithFlag],
      instance?.data ?? []
    );

    const updatedChangedData = upsertDataRows(
      [updatedDataWithFlag],
      instance?.changedData ?? []
    );

    const updatedDeletedData = upsertDataRows(
      [updatedDataWithFlag],
      instance?.deletedData ?? []
    );

    setEntireInstance({
      BaseGridProperties: {
        data: updatedData,
        changedData: updatedChangedData ?? [],
        deletedData: updatedDeletedData ?? [],
      },
    });

    instance?.events?.onDeleteRow?.({
      rowKey: instanceInternal?.accessors?._deleteRowExternal?.rowKey ?? "",
      hydratedRow: hydrateDataSingle(
        instance?.columns ?? [],
        updatedDataWithFlag ?? []
      ),
    });

    setSelectedRowByRowKey({ rowKey, state: "delete" });
    // }
  };

  useEffect(() => {
    if (conditionHasValue(instanceInternal?.accessors?._deleteRowInternal)) {
      deleteRow(instanceInternal?.accessors?._deleteRowInternal);
    }
    if (conditionHasValue(instanceInternal?.accessors?._deleteRowExternal)) {
      deleteRow(instanceInternal?.accessors?._deleteRowExternal);
    }
  }, [
    instanceInternal?.accessors?._deleteRowInternal,
    instanceInternal?.accessors?._deleteRowExternal,
  ]);

  useEffect(() => {
    if (
      selectedRowByRowKey !== null &&
      selectedRowByRowKey.state === "delete"
    ) {
      setCellAtomInstance({
        ...cellAtomInstance,
        isHidden: true,
      });
    }

    if (
      selectedRowByRowKey !== null &&
      selectedRowByRowKey.state === "is_add_row"
    ) {
      updateAllCellInstancesWithSameValues(
        [selectedRowByRowKey.rowKey ?? "NO_ROW_KEY_FOUND"],
        {
          isEditable: true,
        }
      );
    }

    if (
      selectedRowByRowKey !== null &&
      selectedRowByRowKey.state === "is_update_row"
    ) {
      setCellAtomInstance({
        ...cellAtomInstance,
        row: selectedRowByRowKey?.row ?? [],
      });
    }
  }, [selectedRowByRowKey]);

  useEffect(() => {
    if (
      instanceInternal?.initalInProcessComputesQueueStarted === true &&
      instanceInternal?.inProcessComputesQueue.length === 0
    ) {
      const updatedData = getDataWithoutDeletedRows(
        instance?.data ?? [],
        instance?.columns ?? []
      );

      instance?.events?.onComputeFinish?.(
        updatedData ?? [],
        instance?.columns ?? []
      );

      setEntireInstance({
        BaseGridInternalProperties: {
          lastOriginComputeChainCellKey: null,
          initalInProcessComputesQueueStarted: false,
        },
      });
    }
  }, [instanceInternal.inProcessComputesQueue]);

  useEffect(() => {
    if (instance?.advancedOptions?.tableErrors) {
      setEntireInstance({
        BaseGridProperties: {
          hasToggledSave: false,
        },
        BaseGridInternalProperties: {
          validationErrors: instance?.advancedOptions?.tableErrors,
        },
      });
      instance?.events?.onError?.(instance?.advancedOptions?.tableErrors);
    }
  }, [instance?.advancedOptions?.tableErrors]);

  useEffect(() => {
    if (conditionHasValue(instanceInternal?.accessors?._addMultipleRows)) {
      const newRows = addMultiple(
        instance?.columns ?? [],
        instance?.data ?? [],
        instance?.changedData ?? [],
        instanceInternal?.accessors?._addMultipleRows?.rows ?? []
      );

      const overridableConfiguration = {
        indicationType:
          instance?.advancedOptions?.indicationColumnConfiguration
            ?.indicationType,
        optionType:
          instance?.advancedOptions?.optionsColumnConfiguration?.optionType,
      } as OverridableInternalColumnValueProps;

      const updateInternalOverridableColumnValues =
        updateInternalColumnValuesByConfiguration(
          instance?.columns ?? [],
          newRows.updatedTableData,
          overridableConfiguration
        );

      const allUpdatedRowKeys =
        newRows.updatedTableData.map((d) => getSafeRowKey(d)) ?? [];

      // TODO - fix this

      setNewRowData({
        BaseGridProperties: {
          ...instance,
          data: updateInternalOverridableColumnValues ?? [],
          changedData: newRows?.updatedTableChangedData ?? [],
          toggleEditModeState: true,
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          allRowKeys: allUpdatedRowKeys,
          selectedEditRows:
            instanceInternal?.accessors?._addRowExternal?.isEditableAfterAdd ===
            true
              ? addRowKeysWithOutRepeat(
                  instanceInternal?.selectedEditRows ?? [],
                  newRows.rowKeys
                )
              : null,
          selectedRows: [],
          allSelectedRows: false,
          _requestingCellManagerRowKeys: addRowKeysWithOutRepeat(
            instanceInternal?.requestingCellManagerRowKeys ?? [],
            newRows.rowKeys
          ),
          _accessors: {
            ...instanceInternal?.accessors,
            _addMultipleRows: null,
          },
        },
      } as { BaseGridProperties: Partial<BaseGridProperties>; BaseGridInternalProperties: Partial<BaseGridInternalProperties> });
    }
  }, [instanceInternal?.accessors?._addMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(instanceInternal?.accessors?._updateMultipleRows)) {
      const rowKeys =
        instanceInternal?.accessors?._updateMultipleRows?.rowsKeys ?? [];
      setNewRowData({
        BaseGridProperties: {
          ...instance,
          hasToggledEdit: true,
          hasSelectedAllRows: false,
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          selectedEditRows: addRowKeysWithOutRepeat(
            instanceInternal?.selectedEditRows ?? [],
            rowKeys
          ),
          selectedRows: [],
          requestingCellManagerRowKeys: addRowKeysWithOutRepeat(
            instanceInternal?.requestingCellManagerRowKeys ?? [],
            rowKeys
          ),
        },
      });
    }
  }, [instanceInternal?.accessors?._updateMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(instanceInternal?.accessors?._deleteMultipleRows)) {
      const fullDataRows = getDataOfRowKeys(
        instance?.data ?? [],
        instanceInternal?.accessors?._deleteMultipleRows?.rowsKeys ?? []
      );

      const updatedDataWithFlag = flagRowsAsDeleted(
        instance?.columns ?? [],
        fullDataRows
      );

      const updatedData = upsertDataRows(
        [...updatedDataWithFlag],
        instance?.data ?? []
      );

      const updatedChangedData = upsertDataRows(
        [...updatedDataWithFlag],
        instance?.changedData ?? []
      );

      const updatedDeletedData = upsertDataRows(
        [...updatedDataWithFlag],
        instance?.deletedData ?? []
      );

      setEntireInstance({
        BaseGridProperties: {
          data: updatedData,
          changedData: updatedChangedData ?? [],
          deletedData: updatedDeletedData ?? [],

          hasSelectedAllRows: false,
        },
        BaseGridInternalProperties: {
          selectedRows: [],
        },
      });
    }
  }, [instanceInternal?.accessors?._deleteMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(instanceInternal?.accessors?._downloadMultipleRows)) {
      const downloadColumn = instance?.columns?.find(
        (column) => column?.type === BaseTableInputType?.DOWNLOAD_LINK
      );

      if (conditionHasValue(downloadColumn)) {
        const fullDataRows = getDataOfRowKeys(
          instance?.data ?? [],
          instanceInternal?.accessors?._downloadMultipleRows?.rowsKeys ?? []
        );

        const fileNames = fullDataRows?.map((row) => {
          const guid = row?.[downloadColumn?._columnIndex ?? 0];
          const alternatives = downloadColumn?.alternateDisplayValues?.find(
            (x) => x.guid === guid
          );
          const fileName =
            alternatives?.displayValuesOrFieldNames[1] ??
            alternatives?.displayValuesOrFieldNames[0] ??
            "";
          return fileName;
        });
        downloadMultipleFiles(fileNames);
      }

      setEntireInstance({
        BaseGridProperties: {
          hasSelectedAllRows: false,
        },
        BaseGridInternalProperties: {
          selectedRows: [],
        },
      });
    }
  }, [instanceInternal?.accessors?._downloadMultipleRows]);

  const updateDataByCellValueChange = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      () => {
        // Note: We will more than likely deprecate "BaseGridCellManagerUpdateProcessor" since that was the original processed used
        // before this implmenetation, since this flow is much more performant and flexible (to a degree). We'll continue to observe
        // this flow before actually deleting "BaseGridCellManagerUpdateProcessor" for a period of time. - antonio (2/1/24)

        // ChangedSingleGridCellInstanceCellKeysAtom is a map of cellkeys that have had their values changed which is set inside "BaseGridDynamicCells".
        const cellKeys = snapshot.getLoadable(
          ChangedSingleGridCellInstanceCellKeysAtom
        ).contents as string[];

        // Here we just get map of all the
        const singleCellKeyInstances = cellKeys.map((key) => {
          const singleCellInstance = snapshot.getLoadable(
            SingleGridCellInstanceAtomFamily(key)
          ).contents as any;

          // We can reset the changed cell default value since we've stored that data in a variable for later use (becaus we know
          // this will be processed a few lines down).
          reset(SingleGridCellInstanceAtomFamily(key));
          return singleCellInstance;
        });

        // Here we just get the unique rowkeys found in all the cells since there can be multiple cells with the same rowkeys, we
        // only want to process a single row at a time for performance reasons.
        const uniqueRowKeys = [
          ...new Set(singleCellKeyInstances.map((instance) => instance.rowKey)),
        ];

        // We use our unique rowkeys to get the CellManagerInstance(s) that way we can update the row values which we use as part of
        // our return object for "updateDataByCellValueChange" and we update the CellManagerInstance at the end of this map.
        const updatedRows = uniqueRowKeys.map((rowKey) => {
          const cellManagerInstance = snapshot.getLoadable(
            GridCellManagerInstanceAtomFamily(rowKey)
          ).contents;

          const updatedRow = cellManagerInstance.row.map((val, index) => {
            const updatedValue = singleCellKeyInstances.find(
              (cellInstance) =>
                cellInstance.rowKey === rowKey &&
                cellInstance.columnIndex === index
            );
            if (updatedValue !== undefined) {
              return updatedValue?.value ?? "";
            }
            return val ?? "";
          });

          const updatedCellManangerInstance = {
            ...cellManagerInstance,
            row: updatedRow,
          };

          // We need to update the cell manager instance(s) with newely updated row data so that the "static" cells know what's changed.
          set(
            GridCellManagerInstanceAtomFamily(rowKey),
            updatedCellManangerInstance
          );

          // Here we reset the single cell key key tracker since we have already processed all the values that those individual cells have used.
          reset(ChangedSingleGridCellInstanceCellKeysAtom);

          return updatedRow;
        });

        return updatedRows;
      }
  );

  useEffect(() => {
    if (!instance.hasToggledEdit && instanceInternal.isReadyToRender) {
      // This useEffect updates rows based on individual cell value changes. See "updateDataByCellValueChange" for more info.
      const rows = updateDataByCellValueChange();
      const updatedData = upsertDataRows(rows, instance?.data ?? []);
      const updatedChangedData = upsertDataRows(
        rows,
        instance?.changedData ?? []
      );
      const updatedSortedAndFilteredData = upsertDataRows(
        rows,
        instanceInternal.sortedAndFilteredData ?? []
      );

      setEntireInstance({
        BaseGridProperties: {
          ...instance,
          data: updatedData,
          changedData: updatedChangedData ?? [],
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          sortedAndFilteredData: updatedSortedAndFilteredData,
        },
      });
    }
  }, [instance.hasToggledEdit]);

  return null;
};

export default BaseGridEventListener;
