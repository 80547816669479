import jwt_decode from "jwt-decode";
import { JwtPayload } from "jwt-decode";

export function isStillValid(value?: string) {
    if (!value) return false;
    const claims: JwtPayload = jwt_decode(value ?? "");
    const expirationTimeInSeconds: number = (claims.exp ?? 0)*1000;   
    const now = new Date();
    const isValid = expirationTimeInSeconds >= now.getTime();

    return isValid;
}
