import { FC, useEffect, useState } from "react";
import {
  Button,
  CaptionBold,
  Col,
  InputDate,
  Row,
  Select,
  SelectWithIcon,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { AssignmentViewFormProps } from "./ModalAssignmentPropsTypes";
import {
  getAssignmentTypesOrdered,
  getInitialAssignmentTypeOption,
  getTeamsByAssignmentTypeId,
  setCurrenUserOrTeamForSelectedAssignmentType,
} from "./ModalAssignmentsUtils";
import { useApiGet } from "../../../../hooks/useApi";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { AddAssignmentSelectOptionsDto } from "../../../../dtos/add-assignment-select-options-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import style from "./ViewAssignments.module.css";
import { AssigneeTypeEnum } from "../../../../dtos/assignee-type-enum";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";

const AssignmentsViewForm: FC<AssignmentViewFormProps> = ({
  assignmentFormData,
  setAssignmentFormData,
  assignmentsGroups,
  insuredId,
  policyId,
  isDisabledAssignment = false,
  onClearAssignment,
}) => {
  const [assignmentTypes, setAssignmentTypes] = useState<
    Partial<SelectOptions>[] | null
  >(null);
  const [users, setUsers] = useState<Partial<SelectOptions>[] | null>(null);
  const [teams, setTeams] = useState<Partial<SelectOptions>[] | null>(null);
  const { responseGet, dispatchGet } = useApiGet<AddAssignmentSelectOptionsDto>(
    `api/Assignment/GetAssignmentsUsersAndTeams`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setAssignmentTypes(
        getAssignmentTypesOrdered(
          responseGet?.axiosResponse?.data?.assignmentTypes ?? []
        )
      );
      setUsers(responseGet?.axiosResponse?.data?.users ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    if (assignmentTypes !== null) {
      getInitialAssignmentTypeOption(
        assignmentFormData,
        setAssignmentFormData,
        assignmentsGroups,
        assignmentTypes?.[0]?.intValue ?? null,
        responseGet?.axiosResponse?.data?.teams ?? [],
        setTeams,
        insuredId ?? 0,
        policyId,
        isDisabledAssignment
      );
    }
  }, [assignmentTypes]);

  const assignmentTypeSelected = (e) => {
    setTeams(
      getTeamsByAssignmentTypeId(
        responseGet?.axiosResponse?.data?.teams ?? [],
        e
      )
    );
    setCurrenUserOrTeamForSelectedAssignmentType(
      setAssignmentFormData,
      assignmentsGroups,
      e,
      assignmentFormData,
      insuredId ?? 0
    );
  };

  const assigneeSelected = (assigneeId) => {
    const selectedAssignee = [...(users ?? []), ...(teams ?? [])].find(
      (option) => option.intValue === assigneeId
    );
    setAssignmentFormData({
      ...assignmentFormData,
      userOrTeamId: assigneeId,
      assigneeType:
        selectedAssignee?.iconName === "tell-a-friend"
          ? AssigneeTypeEnum.TEAM
          : AssigneeTypeEnum.USER,
      selectedAssignmentId: null,
    });
  };

  const name = "AddAssignmentsTable";
  const getURL = `api/Assignment/GetAddAssignmentsTableData/?insuredId=${insuredId}&assignmentTypeId=${
    assignmentFormData?.assignmentType ?? 0
  }&policyId=${policyId ?? 0}`;

  const tableConfiguration: BaseGridProperties = {
    name,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExportButton: false,
      hideToolbar: true,
    },
    columnOptions: [{ fieldName: "Subject", width: 50 }],
    useManuallyReloadParameters: true,
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    if (assignmentFormData?.assignmentType !== null) {
      manuallyReloadParameters();
    }
  }, [assignmentFormData?.assignmentType]);

  return (
    <Row {...rowWithNoMarginNorGutter} allowWrap>
      <Col breakpoints={{ md: 6, lg: 4, xl: 4 }}>
        <Select
          id="insured-assignment-type"
          label="Assignment Type"
          name="insuredAssignmentType"
          options={assignmentTypes ?? []}
          value={assignmentFormData?.assignmentType}
          onChange={assignmentTypeSelected}
          firstOptionAsDefault={false}
          labelFontType={"BOLD_CAPTION"}
          disabled={isDisabledAssignment}
        />
      </Col>
      <Col
        breakpoints={{ md: 6, lg: 8, xl: 8 }}
        horizontalAlign="flex-start"
        verticalGutter="15px"
      >
        <Button
          onClick={() => {
            onClearAssignment?.();
          }}
          name={"clear-assignment"}
        >
          {"Clear Assignment"}
        </Button>
      </Col>
      <Col breakpoints={{ md: 6, lg: 7, xl: 7 }}>
        <SelectWithIcon
          id="insured-assigned-user-or-team"
          label="Assigned User or Team"
          name="insuredAssignedUserOrTeam"
          options={[...(users ?? []), ...(teams ?? [])]}
          value={assignmentFormData?.userOrTeamId}
          onChange={(e) => assigneeSelected(e)}
          firstOptionAsDefault={false}
          optionsContainerPosition={"bottom"}
          labelFontType={"BOLD_CAPTION"}
          optionsMaxHeight="380px"
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 4, xl: 4 }}>
        <InputDate
          id="insured-assignments-effective-date"
          label="Effective Date"
          name="insuredAssignmentsEffectiveDate"
          value={assignmentFormData?.effectiveDate ?? new Date()}
          onChangeRawValue={(value) =>
            setAssignmentFormData({
              ...assignmentFormData,
              effectiveDate: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
        horizontalAlign={"flex-start"}
      >
        <CaptionBold>ASSIGNMENT HISTORY</CaptionBold>
      </Col>
      <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
        <div className={style.view_assignment_table_container}>
          {assignmentFormData?.assignmentType && <BaseGrid name={name} />}
        </div>
      </Col>
    </Row>
  );
};

export default AssignmentsViewForm;
