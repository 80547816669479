import { AuditWorksheetRecordTypeEnum } from "../../../../dtos/audit-worksheet-record-type-enum";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { SelectOptions } from "../../../../dtos/select-options";
import {
  getEnumKeys,
  getEnumValue,
  selectOptionsByEnumValues,
} from "../../../../utilities/enumFunctions";
import { getStates } from "../../../../utilities/staticDataFunctions";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { AuditWorksheetOvertimeTypeEnum } from "../../../../dtos/audit-worksheet-overtime-type-enum";
import { AuditWorksheetDto } from "../../../../dtos/audit-worksheet-dto";
import {
  formatNegativeNumbers,
  getNumberAsStringWithComasWithDecimals,
  unFormatLocalString,
} from "../../../../utilities/stringFunctions";
import { getPolicyQuoteStates } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { PolicyQuoteAtomProps } from "../PolicyQuoteForm/PolicyQuoteTypes";

export const EXCESS_PAYROLL = 36000;
export const OVERTIME_DIVIDING_BY_2 = 2;
export const OVERTIME_DIVIDING_BY_3 = 3;

export const getDisplayName = (address: PolicyInsuredAddressBlob) => {
  return `${address.locationNumber} - ${
    isEmptyValue(address.locationName) ? "" : address.locationName
  } ${address.address1}`;
};

export const getInsuredPrimaryAddressState = (
  statesOptions: Partial<SelectOptions>[],
  policyQuote?: PolicyBlob | null
) => {
  const primaryAddressId = policyQuote?.insured?.primaryAddressID ?? -1;
  const primaryInsuredAddress = getLocationAddresses(policyQuote).find(
    (address) => address.addressID === primaryAddressId
  );
  const primaryState = statesOptions.find(
    (state) => state.stringValue === primaryInsuredAddress?.addressState
  );
  return isEmptyValue(primaryState) ? "" : primaryState?.stringValue;
};

export const getLocationAddresses = (policyQuote?: PolicyBlob | null) =>
  policyQuote?.auditConfiguration?.auditLocationList ?? [];

export const getRecordTypeOptions = () =>
  selectOptionsByEnumValues(AuditWorksheetRecordTypeEnum);

const getOvertimeTypeValue = (value: string) => {
  switch (value) {
    case "DOUBLE_TIME":
      return "2.0";
    case "TIME_AND_HALF":
    default:
      return "1.5";
  }
};

export const getOvertimeTypeOptions = () =>
  getEnumKeys(AuditWorksheetOvertimeTypeEnum).map((enumValue) => {
    const value = getEnumValue(
      AuditWorksheetOvertimeTypeEnum[enumValue],
      AuditWorksheetOvertimeTypeEnum
    );
    return {
      intValue: AuditWorksheetOvertimeTypeEnum[enumValue],
      displayName: getOvertimeTypeValue(value),
    };
  });

export const getAllStatesOptions = () =>
  getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

export const getLocationOptions = (policyQuote?: PolicyBlob | null) =>
  getLocationAddresses(policyQuote)
    .filter((address) => address.addressType === "Location")
    .map((address) => ({
      intValue: address.addressID,
      displayName: getDisplayName(address),
    }));

export const getFinalPayroll = (
  auditWorksheetData?: AuditWorksheetDto | null
) =>
  (auditWorksheetData?.quarter1Amount ?? 0.0) +
  (auditWorksheetData?.quarter2Amount ?? 0.0) +
  (auditWorksheetData?.quarter3Amount ?? 0.0) +
  (auditWorksheetData?.quarter4Amount ?? 0.0);

export const getNvCredit = (auditWorksheetData?: AuditWorksheetDto | null) => {
  if (auditWorksheetData?.state === "NV") {
    return (auditWorksheetData?.finalPayroll ?? 0) < EXCESS_PAYROLL
      ? 0
      : EXCESS_PAYROLL - (auditWorksheetData?.finalPayroll ?? 0);
  } else {
    return 0;
  }
};

export const getNvCreditFormatted = (
  auditWorksheetData?: AuditWorksheetDto | null
) => {
  if (auditWorksheetData?.state === "NV") {
    return (auditWorksheetData?.finalPayroll ?? 0) < EXCESS_PAYROLL
      ? "0.00"
      : formatNegativeNumbers(
          EXCESS_PAYROLL - (auditWorksheetData?.finalPayroll ?? 0)
        );
  } else {
    return "0.00";
  }
};

const getCalculatedOvertimeCredit = (
  auditWorksheetData?: AuditWorksheetDto | null
) => {
  const divideBy =
    auditWorksheetData?.overTimeType ===
    AuditWorksheetOvertimeTypeEnum.TIME_AND_HALF
      ? OVERTIME_DIVIDING_BY_3
      : OVERTIME_DIVIDING_BY_2;
  return (auditWorksheetData?.overTime ?? 0.0) / divideBy;
};

export const getOvertimeCredit = (
  auditWorksheetData?: AuditWorksheetDto | null
) => {
  const overtimeCredit = getCalculatedOvertimeCredit(auditWorksheetData);
  return overtimeCredit === 0
    ? 0
    : unFormatLocalString(
        getNumberAsStringWithComasWithDecimals(overtimeCredit * -1, 2)
      );
};

export const getOvertimeCreditFormatted = (
  auditWorksheetData?: AuditWorksheetDto | null
) => {
  const overtimeCredit = getCalculatedOvertimeCredit(auditWorksheetData);
  return overtimeCredit === 0
    ? "0.00"
    : formatNegativeNumbers(overtimeCredit * -1);
};

export const getTotalIncludable = (
  auditWorksheetData?: AuditWorksheetDto | null
) =>
  (auditWorksheetData?.finalPayroll ?? 0.0) +
  (auditWorksheetData?.nVCredit ?? 0.0) +
  (auditWorksheetData?.autoAllowance ?? 0.0) +
  (auditWorksheetData?.cafeWages ?? 0.0) +
  (auditWorksheetData?.overTimeCredit ?? 0.0);

export const hasClassCodeInPolicyQuote = (
  classCodeId?: number,
  policyQuoteInformation?: PolicyQuoteAtomProps
) => {
  const policyStates = getPolicyQuoteStates(policyQuoteInformation);
  const quoteHasClassCode = policyStates.find((state) =>
    state.exposures?.find((exposure) => exposure.classCodeID === classCodeId)
  );
  return quoteHasClassCode;
};
