import { FC, useState } from "react";
import { EndorsementTypeOptionsProps } from "../EndorsementForm/EndorsementTypes";
import { EndorsementTypeEnum } from "../../../../dtos/endorsement-type-enum";
import { Switch } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { countExposurePremiumRelatedChecked } from "../EndorsementForm/EndorsementUtils";

const endorsementTypeDisabledList = [
  EndorsementTypeEnum.INSURED_NAME,
  EndorsementTypeEnum.INSURED_MAILING_ADDRESS,
  EndorsementTypeEnum.CHANGE_IN_WORKPLACE_OF_INSURED,
  EndorsementTypeEnum.ITEM_3_D_ENDORSEMENT_NUMBERS,
  EndorsementTypeEnum.CARRIER_SERVICING_OFFICE,
  EndorsementTypeEnum.INTERSTATE_INTESTATE_RISK_ID_NUMBER,
  EndorsementTypeEnum.CARRIER_NUMBER,
  EndorsementTypeEnum.ISSUING_AGENCY_PRODUCER_OFFICE_ADDRESS,
];

export const exposureAndPremiumRelatedTypes = [
  EndorsementTypeEnum.EXPERIENCE_MODIFICATION,
  EndorsementTypeEnum.ITEM_3_A_STATES,
  EndorsementTypeEnum.ITEM_3_B_LIMITS,
  EndorsementTypeEnum.ITEM_3_C_STATES,
  EndorsementTypeEnum.ITEM_4_CLASS_RATE_OTHER,
  EndorsementTypeEnum.INTERIM_ADJUSTMENT_PREMIUM,
];

export type SpecialRuleOnChangeSelection =
  | "reset-exposure-and-premium"
  | "reset-producer-change"
  | "open-producer-change-modal";

const EndorsementTypeOptions: FC<EndorsementTypeOptionsProps> = ({
  endorsementTypeSelectedList,
  readonly,
  updateEndorsementTypeSelectedList,
}) => {
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const lastItemIndexOnLeft =
    Math.round(endorsementTypeSelectedList.length / 2) - 1;

  const evaluateOnChangeChecked = (
    endorsementType: PolicyEnumValueDescriptionAndBooleanBlob,
    isChecked: boolean
  ) => {
    const allExposureRelatedUnchecked =
      exposureAndPremiumRelatedTypes.includes(endorsementType.value ?? -1) &&
      countExposurePremiumRelatedChecked(endorsementTypeSelectedList) === 1 &&
      isChecked === false;
    const producerChangeUnchecked =
      endorsementType.value === EndorsementTypeEnum.PRODUCER_NAME &&
      isChecked === false;
    const producerChangeChecked =
      endorsementType.value === EndorsementTypeEnum.PRODUCER_NAME &&
      isChecked === true;

    if (allExposureRelatedUnchecked) {
      setDialogConfiguration({
        dialogDescriptionText:
          "All the changes made to the exposure and premium area will be lost. Do you wish to continue?",
        open: true,
        onOptionYesEvent: () => {
          updateEndorsementTypeSelectedList(
            isChecked,
            endorsementType,
            endorsementTypeSelectedList,
            "reset-exposure-and-premium"
          );
          setDialogConfiguration(null);
        },
        onOptionNoEvent: () => setDialogConfiguration(null),
      });
    } else if (producerChangeUnchecked) {
      updateEndorsementTypeSelectedList(
        isChecked,
        endorsementType,
        endorsementTypeSelectedList,
        "reset-producer-change"
      );
    } else if (producerChangeChecked) {
      updateEndorsementTypeSelectedList(
        isChecked,
        endorsementType,
        endorsementTypeSelectedList,
        "open-producer-change-modal"
      );
    } else {
      // regular change process.
      updateEndorsementTypeSelectedList(
        isChecked,
        endorsementType,
        endorsementTypeSelectedList
      );
    }
  };

  return (
    <div
      id="endorsement-types-container"
      className={style.endorsement_types_container}
    >
      <div
        id="endorsement-types-left-column"
        className={style.endorsement_type_column_container}
      >
        {endorsementTypeSelectedList.map((endorsementType, index) => {
          const endorsementTypeChecked = endorsementType.isChecked ?? false;
          const isReadOnly =
            readonly === true
              ? readonly
              : endorsementTypeDisabledList.includes(
                  endorsementType.value ?? -1
                );

          return index <= lastItemIndexOnLeft ? (
            <Switch
              key={`endorsement-type-${endorsementType.value ?? ""}-id`}
              id={`endorsement-type-${endorsementType.value ?? ""}-id`}
              name={`endorsement-type-${endorsementType.value ?? ""}`}
              label={endorsementType.description ?? ""}
              labelFontType="BODY"
              control="checkbox"
              isChecked={endorsementTypeChecked}
              onChangeIsChecked={(isChecked) =>
                evaluateOnChangeChecked(endorsementType, isChecked)
              }
              readOnly={isReadOnly}
            />
          ) : null;
        })}
      </div>
      <div
        id="endorsement-types-right-column"
        className={style.endorsement_type_column_container}
      >
        {endorsementTypeSelectedList.map((endorsementType, index) => {
          const endorsementTypeChecked = endorsementType.isChecked ?? false;
          const isReadOnly =
            readonly === true
              ? readonly
              : endorsementTypeDisabledList.includes(
                  endorsementType.value ?? -1
                );

          return index > lastItemIndexOnLeft ? (
            <Switch
              key={`endorsement-type-${endorsementType.value ?? ""}-id`}
              id={`endorsement-type-${endorsementType.value ?? ""}-id`}
              name={`endorsement-type-${endorsementType.value ?? ""}`}
              label={endorsementType.description ?? ""}
              labelFontType="BODY"
              control="checkbox"
              isChecked={endorsementTypeChecked}
              onChangeIsChecked={(isChecked) =>
                evaluateOnChangeChecked(endorsementType, isChecked)
              }
              readOnly={isReadOnly}
            />
          ) : null;
        })}
      </div>
      <DialogConfirmation
        id="id-endorsement-type-options-dialog-confirmation"
        name="endorsement-type-options-dialog-confirmation"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default EndorsementTypeOptions;
