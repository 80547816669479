import { getValidatedDateToPost } from "../../../../../utilities/dateFunctions";
import {
  ComputeForCellParameters,
  ComputeForColumnParameters,
  ConditionForCellResponse,
  ConditionForColumnResponse,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { valueIncludedInDisplayOptions } from "./AgencyInformationTableUtils";

const getCommissionOverrideValidated = (value: number) => {
  if (value > 20) return 20;
  if (value < 0) return 0;
  return value;
};

export const onAgencyNameChanged = (
  options: ComputeForCellParameters<any>,
  getDates: () => {
    effectiveDate?: Date | null;
    expirationDate?: Date | null;
  }
): ConditionForCellResponse => {
  const { effectiveDate, expirationDate } = getDates();
  const computeObjectResult = {
    apiOptions: {
      getURL: `api/AgencyLocation/GetAgencyLocationsByAgencyIdAsOptions/?agencyId=${parseInt(
        options?.currentValue ?? 0
      )}${
        effectiveDate && expirationDate
          ? `&effectiveDate=${getValidatedDateToPost(effectiveDate)}`
          : ""
      }`,

      getDataName: `GetPolicyQuoteAgencyInformationTableComputeAgencyNameAPI__${options.cellKey}`,

      triggerComputes: ["Location"],
    },
  } as ConditionForCellResponse;

  return computeObjectResult;
};

export const onLocationChange = (
  options: ComputeForCellParameters<any>,
  getDates: () => {
    effectiveDate?: Date | null;
    expirationDate?: Date | null;
  }
): ConditionForCellResponse => {
  const { effectiveDate, expirationDate } = getDates();
  const validatedValue = valueIncludedInDisplayOptions(
    options.currentValue,
    options.currentApiData?.data ?? []
  );
  return {
    value: validatedValue,
    listDisplayOptions: options.currentApiData?.data ?? [],

    apiOptions:
      validatedValue !== ""
        ? {
            getURL: `api/AgencyContact/GetAgencyContactsByAgencyIdAndAgencyLocationIdAsOptions/?agencyId=${
              options?.row?.AgencyName ?? 0
            }&agencyLocationId=${parseInt(validatedValue)}${
              effectiveDate && expirationDate
                ? `&effectiveDate=${getValidatedDateToPost(effectiveDate)}`
                : ""
            }`,

            getDataName: `GetPolicyQuoteAgencyInformationTableComputeLocationAPI__${options.cellKey}`,

            triggerComputes: ["Agent"],
          }
        : null,
  } as ConditionForCellResponse;
};

export const onAgentChange = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse => {
  const validatedValue = valueIncludedInDisplayOptions(
    options.currentValue,
    options.currentApiData?.data ?? []
  );
  return {
    value: validatedValue,
    listDisplayOptions: options.currentApiData?.data ?? [],
  } as ConditionForCellResponse;
};

export const onPrimaryChange = (
  options: ComputeForColumnParameters
): ConditionForColumnResponse => {
  if (options.rowValue === "true") {
    const updateTargetFields = options.columnData.map((data, index) => {
      if (options.rowEventIndex === index) {
        return {
          ...data,
          value: "true",
        };
      }
      return { ...data, value: "false" };
    });

    return {
      targetFields: updateTargetFields,
    } as ConditionForColumnResponse;
  }

  return {
    targetFields: options.columnData,
  } as ConditionForColumnResponse;
};

export const onCommissionOverrideChange = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse => {
  const commissionOverrideValue = parseFloat(options.currentValue ?? 0);
  const commissionOverrideValueValidated = getCommissionOverrideValidated(
    commissionOverrideValue
  );
  return {
    value: commissionOverrideValueValidated.toString(),
  } as ConditionForCellResponse;
};
