import { FC, useEffect, useState } from "react";
import { FontBold, Row } from "../../../TrueUI";
import { Box } from "@mui/material";
import { insuredInformationNamesAndAddressesTableStyles } from "./InsuredInformationStyles";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import Col, { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { SelectOptions } from "../../../../dtos/select-options";
import ModalAcord130Address, {
  ModalAddressInformationProps,
} from "./ModalAcord130Address";
import ModalAcord130Name, {
  ModalNameInformationProps,
} from "./ModalAcord130Name";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { PolicyInsuredNameBlob } from "../../../../dtos/policy-insured-name-blob";
import NameNamesAndAddressesTable from "./NameNamesAndAddressesTable";
import {
  getUpdatedGroupData,
  getUpdatedGroupDataAfterDeleteAddress,
} from "./InsuredInformationMultiTableUtils";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";

type insuredInfoNamesAndAddressesProps = {
  addressTypes: SelectOptions[];
  businessTypes: SelectOptions[];
  relationshipTypes: SelectOptions[];
  errorDetails?: any | null;
  effectiveDate: Date | null;
};

export type TableGroupData = {
  name?: PolicyInsuredNameBlob | null;
  locations?: PolicyInsuredAddressBlob[];
};

const InsuredInformationNamesAndAddresses: FC<
  insuredInfoNamesAndAddressesProps
> = ({ addressTypes, businessTypes, relationshipTypes, effectiveDate }) => {
  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );
  const [tableGroupData, setTableGroupData] = useState<TableGroupData[]>([]);
  const [deletedAddresses, setDeletedAddresses] = useState<
    PolicyInsuredAddressBlob[] | null
  >(null);
  const [modalNameInformation, setModalNameInformation] =
    useState<ModalNameInformationProps>({});
  const [modalAddressInformation, setModalAddressInformation] =
    useState<ModalAddressInformationProps>({});
  const [namesAndAddressesLinkNumber, setNamesAndAddressesLinkNumber] =
    useState(1);
  const [nameNamesAndAddressesTableKey, setNameNamesAndAddressesTableKey] =
    useState(1);

  const editNameEvent = (metaDataObject) => {
    setModalNameInformation({
      name: metaDataObject.name,
      isOpen: true,
      isNewName: false,
    });
  };

  const getDeletedContacts = () =>
    deletedAddresses?.filter((address) => address.addressID) ?? [];

  useEffect(() => {
    const atomValue = getAtom();
    const names = atomValue?.insured?.names;
    const locations = atomValue?.insured?.addresses;
    const loadTableData: TableGroupData[] = [];
    if (!isEmptyValue(names)) {
      names?.forEach((name) => {
        const tableGroupToAdd: TableGroupData = {
          name: name,
          locations:
            locations?.filter(
              (location) =>
                location.parentRowIndex === name.rowIndex &&
                location.locationStatus !== StatusEnums.DELETED
            ) ?? [],
        };
        loadTableData.push(tableGroupToAdd);
      });
      setTableGroupData(loadTableData);
    }

    return () => {
      setDeletedAddresses(null);
    };
  }, []);

  useEffect(() => {
    if (tableGroupData.length > 0) {
      setNamesAndAddressesLinkNumber(tableGroupData.length + 1);
      setNameNamesAndAddressesTableKey(nameNamesAndAddressesTableKey + 1);
      const atomValue = getAtom();
      const updatedAddresses =
        getDeletedContacts().length > 0
          ? tableGroupData
              .flatMap((tableGroup) => tableGroup.locations ?? {})
              ?.concat(deletedAddresses ?? [])
          : tableGroupData.flatMap((tableGroup) => tableGroup.locations ?? {});
      setAtom({
        ...atomValue,
        insured: {
          ...atomValue?.insured,
          names: tableGroupData.map((tableGroup) => tableGroup.name ?? {}),
          addresses: updatedAddresses,
        },
      });
    }
  }, [tableGroupData]);

  const onSaveNameEvent = (
    insuredNameData?: PolicyInsuredNameBlob,
    isNewName?: boolean
  ) => {
    setModalNameInformation({});
    if (isNewName) {
      const tableGroupToAdd: TableGroupData = {
        name: {
          ...insuredNameData,
          rowIndex: tableGroupData.length + 1,
          nameID: null,
          sequenceNumber: tableGroupData.length + 1,
        },
        locations: [],
      };
      setTableGroupData([...tableGroupData, tableGroupToAdd]);
    } else {
      const tableGroupUpdated = tableGroupData.map((tableGroup, index) => {
        const tableGroupIndex = index + 1;
        if (tableGroupIndex !== insuredNameData?.rowIndex) {
          return tableGroup;
        } else {
          const tableGroupToUpdated: TableGroupData = {
            name: { ...insuredNameData },
            locations: tableGroup.locations,
          };
          return tableGroupToUpdated;
        }
      });
      setTableGroupData(tableGroupUpdated);
    }
  };

  const onCancelNameEvent = () => {
    setModalNameInformation({ isOpen: false });
  };

  const onSaveAddressEvent = (
    parentKey: number,
    locationData?: PolicyInsuredAddressBlob,
    isNewAddress?: boolean
  ) => {
    setModalAddressInformation({});
    const updatedGroupsData: TableGroupData[] = getUpdatedGroupData(
      tableGroupData,
      parentKey,
      locationData,
      isNewAddress
    );
    setTableGroupData(updatedGroupsData);
  };

  const onCancelAddressEvent = () => {
    setModalAddressInformation({ isOpen: false });
  };

  const onDeleteAddressEvent = (parentKey?: number, rowKey?: number) => {
    const nameIndex = (parentKey ?? -1) - 1;
    const locationIndex = (rowKey ?? -1) - 1;
    const removedAdd =
      tableGroupData[nameIndex]?.locations?.[locationIndex] ?? null;
    if (removedAdd !== null)
      setDeletedAddresses([
        ...(deletedAddresses ?? []),
        {
          ...removedAdd,
          locationStatus: StatusEnums.DELETED,
        },
      ]);
    const updatedGroupsData: TableGroupData[] =
      getUpdatedGroupDataAfterDeleteAddress(
        tableGroupData,
        nameIndex,
        locationIndex
      );
    setTableGroupData(updatedGroupsData);
    onCancelAddressEvent();
  };

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalGutter="10px"
    >
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold>NAMED INSURED AND LOCATIONS</FontBold>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Box sx={insuredInformationNamesAndAddressesTableStyles()}>
          <NameNamesAndAddressesTable
            groupsAndContent={tableGroupData}
            setModalAddressInformation={setModalAddressInformation}
            setModalNameInformation={setModalNameInformation}
            editNameEvent={editNameEvent}
            modalAddressInformation={modalAddressInformation}
            key={nameNamesAndAddressesTableKey}
          />

          <ModalAcord130Name
            businessTypes={businessTypes}
            relationshipTypes={relationshipTypes}
            modalInformation={modalNameInformation}
            onSaveEvent={onSaveNameEvent}
            onCancelEvent={onCancelNameEvent}
            linkNumber={namesAndAddressesLinkNumber}
            effectiveDate={effectiveDate}
          />
          <ModalAcord130Address
            groupsAndContent={tableGroupData}
            addressTypes={addressTypes}
            modalInformation={modalAddressInformation}
            onSaveEvent={onSaveAddressEvent}
            onCancelEvent={onCancelAddressEvent}
            onDeleteAddressEvent={onDeleteAddressEvent}
            effectiveDate={effectiveDate}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default InsuredInformationNamesAndAddresses;
