import { FC } from "react";
import FontBold from "../Typography/FontBold";
import style from "./taskBoard.module.css";

type TaskBoardShowContainerProps = {
  open: boolean;
  showHideContentSection: boolean;
  setOpen: (open: boolean) => void;
  InternalComponent?: () => JSX.Element;
};

const TaskBoardShowContainer: FC<TaskBoardShowContainerProps> = ({
  open,
  setOpen,
  showHideContentSection,
  InternalComponent,
}) => {
  return (
    <div
      className={`${open ? style?.show_component : style?.hide_component} ${
        style?.show_card_content_container
      }`}
    >
      <div className={style?.card_content_component}>
        <div className={style?.card_component}>{InternalComponent?.()}</div>
        {showHideContentSection && (
          <div
            className={style?.hide_card_component_section}
            onClick={() => setOpen(false)}
          >
            <div className={style.hide_section_text}>
              <FontBold primaryColor display="block" textAlign="center">
                Show Titles
              </FontBold>
            </div>
            <span className="k-icon k-i-arrow-chevron-up"></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskBoardShowContainer;
