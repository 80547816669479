import { PolicyApplicationQuestionBlob } from "../../../dtos/policy-application-question-blob";
import { PolicyBlob } from "../../../dtos/policy-blob";

export const updateGeneralQuestions = (
  atomValue: PolicyBlob | null,
  value: PolicyApplicationQuestionBlob[]
) => {
  return {
    ...atomValue,
    generalInformation: value,
  };
};
