import { colors, fonts, fontsFamily, mediaQueries } from "./themeConstants";
import { Theme } from "./themeTypes";

const defaultTheme: Theme = {
  PRIMARY: colors.TRUE_BLUE,
  SECONDARY: colors.EGGIER,
  PRIMARY_BACKGROUND: colors.WHITE,
  SECONDARY_BACKGROUND: colors.CONCRETE,
  CONTRAST_BACKGROUND: colors.PAST_MIDNIGHT,
  SELECTED_ROW_BACKGROUND: colors.DIAMOND,
  TEXT_COLOR: colors.SPACE_CADET,
  SECONDARY_TEXT_COLOR: colors.AMY,
  CONTRAST_TEXT_COLOR: colors.EGGIER,
  INPUT_TEXT_COLOR: colors.PAST_MIDNIGHT,
  BORDER_COLOR: colors.CONCRETE,
  BORDER_MODAL_COLOR: colors.BLACK,
  DANGER: colors.RED,
  WARNING: colors.YELLOW,
  SUCCESS: colors.GREEN,
  WHITE: colors.WHITE,
  ICON_PATH_COLLAPSED: "/media/iconCollapsed.svg",
  ICON_PATH_EXPANDED: "/media/iconExpanded.svg",
  ...fonts,
  ...fontsFamily,
  ...mediaQueries,
};

const darkTheme: Theme = {
  PRIMARY: colors.TRUE_BLUE, //"#3da388",
  SECONDARY: colors.WHITE,
  PRIMARY_BACKGROUND: colors.SPACE_CADET,
  SECONDARY_BACKGROUND: colors.CONCRETE,
  CONTRAST_BACKGROUND: colors.PAST_MIDNIGHT,
  SELECTED_ROW_BACKGROUND: colors.DIAMOND,
  TEXT_COLOR: colors.EGGIER,
  SECONDARY_TEXT_COLOR: colors.AMY,
  CONTRAST_TEXT_COLOR: colors.EGGIER,
  INPUT_TEXT_COLOR: colors.PAST_MIDNIGHT,
  BORDER_COLOR: colors.CONCRETE,
  BORDER_MODAL_COLOR: colors.WHITE,
  DANGER: colors.RED,
  WARNING: colors.YELLOW,
  SUCCESS: colors.GREEN,
  WHITE: colors.WHITE,
  ICON_PATH_COLLAPSED: "../media/iconCollapsed.svg",
  ICON_PATH_EXPANDED: "../media/iconExpanded.svg",
  ...fonts,
  ...fontsFamily,
  ...mediaQueries,
};

const themes = { defaultTheme, darkTheme };

export default themes;
