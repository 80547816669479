import { useEffect, useState } from "react";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { StatusEnums } from "../../../dtos/status-enums";
import { DROPDOWN_FILTER } from "../BaseGridConstants";
import { Select } from "../../TrueUI";
import { useBaseGridFilters } from "../Hooks/useBaseGridFilters";
import { BaseGridFilterOptionsParameter } from "../BaseGridProperties";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { usePermissions } from "../../../hooks";

type ToggleColumnsByOptionsProps = {
  options: string[];
  columnsToToggle: string[];
};

type BaseGridDropDownFilterProperties = {
  options: BaseGridFilterOptionsParameter;
  id: string;
  columnNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  toggleColumnsByOptions?: ToggleColumnsByOptionsProps[];
  usingExternalFiltering?: boolean;
  isStaticFilter?: boolean;
  validatingAgainstDisplayValue?: boolean;
  selectWidth?: string;
  resetOnManuallyReload?: boolean;
  onChange?: (e: any) => void;
  permissions?: PermissionsEnums[];
};

const BaseGridDropDownFilter: (
  properties: BaseGridDropDownFilterProperties
) => any = (props) => {
  const hasPermission = usePermissions(props?.permissions ?? []).hasPermission;
  const [value, setValue] = useState<string | number>();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const filterHook = useBaseGridFilters(
    props?.options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: props?.id,
      filterName: DROPDOWN_FILTER,
      defaultValue: props?.defaultValue,
      columnNames: props?.columnNames,
      resetOnManuallyReload: props?.resetOnManuallyReload ?? false,
    },
    "BaseGridDropDownFilter"
  );

  const statusTypes = [{ displayName: "All", stringValue: "all" }].concat(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName: enumType?.key ?? "",
        stringValue: props?.validatingAgainstDisplayValue
          ? enumType?.key ?? ""
          : enumType?.value.toString(),
      })
    )
  );

  const setStatus = (val: string) => {
    if (props?.toggleColumnsByOptions) {
      const columnsToToggleFound = props?.toggleColumnsByOptions?.find(
        (toggleOptionsAndColumns) => {
          return toggleOptionsAndColumns.options.includes(val)
            ? toggleOptionsAndColumns
            : null;
        }
      )?.columnsToToggle;

      const columnsNotHiddenToToggle = columnsToToggleFound?.filter(
        (column) => !hiddenColumns.includes(column)
      );

      const columnsAlreadyHiddenToToggle = hiddenColumns?.filter(
        (column) => !columnsToToggleFound?.includes(column)
      );

      const columnsToToggleResult = columnsNotHiddenToToggle?.concat(
        columnsAlreadyHiddenToToggle
      );

      const newHiddenColumns = hiddenColumns
        ?.filter((column) => columnsToToggleFound?.includes(column))
        .concat(columnsNotHiddenToToggle ?? []);

      if (
        columnsNotHiddenToToggle !== undefined &&
        columnsAlreadyHiddenToToggle !== undefined &&
        columnsToToggleResult !== undefined &&
        columnsToToggleResult.length > 0
      ) {
        setHiddenColumns(newHiddenColumns);
      }

      if (val === "all" && hiddenColumns.length > 0) {
        setHiddenColumns([]);
      }
    }
  };

  useEffect(() => {
    if (!props?.usingExternalFiltering)
      setStatus(props?.defaultValue?.toString() ?? "all");
  }, []);

  useEffect(() => {
    setValue(props?.defaultValue);
  }, [props?.defaultValue]);

  return (
    <Select
      id={`true-filter-for-table-${props?.id}`}
      name={`filter-dropdown-${props?.id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={props?.labelText ?? "Show:"}
      labelPosition={props?.labelPosition ?? "start"}
      options={props?.optionsOverride ?? statusTypes}
      value={value ?? "all"}
      type={"tableFilter"}
      inputWidth={props?.selectWidth ? props?.selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      data-base-grid-filter-column-names={props?.columnNames ?? []}
      onChange={(value) => {
        if (!props?.usingExternalFiltering) {
          setStatus(value);
          setValue(value);
          filterHook?.onChange?.(value);
        }
        props?.onChange?.(value);
      }}
      disabled={!hasPermission}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
    />
  );
};

export default BaseGridDropDownFilter;
