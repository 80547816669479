import { FC } from "react";
import { Tooltip, Typography } from "@mui/material";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontSx } from "./FontStyles";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { useRecoilValue } from "recoil";
import { PercentageType } from "../../../types/RangeNumberType";

export type FontProperties = {
  children: string | React.ReactNode;
  display?: "inline" | "block" | "inline-block" | "flex" | "none";
  whiteSpace?:
    | "normal"
    | "pre"
    | "nowrap"
    | "pre-wrap"
    | "pre-line"
    | "break-spaces";
  textAlign?: "start" | "center" | "end";
  maxWidth?: string;
  truncate?: boolean;
  name?: string;
  className?: string;
  fontType?: KeysAsType<FontsType>;
  primaryColor?: boolean;
  opacity?: PercentageType;
  secondaryFont?: boolean;
  isSuccessFont?: boolean;
  isErrorFont?: boolean;
  isWarning?: boolean;
  trueElement?: string;
  showTooltip?: boolean;
  tooltipTitle?: string | React.ReactNode;
  tooltipPosition?:
    | "top-start"
    | "top"
    | "top-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end";
};

const Font: FC<FontProperties> = ({
  fontType = "BODY",
  display = "inline",
  whiteSpace = "nowrap",
  textAlign = "center",
  name = "",
  className = "",
  maxWidth,
  truncate,
  children,
  primaryColor = false,
  opacity = 100,
  secondaryFont = false,
  trueElement = "",
  isSuccessFont = false,
  isErrorFont = false,
  isWarning = false,
  showTooltip = false,
  tooltipTitle = children,
  tooltipPosition = "top",
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const getTrueElement = () => {
    return trueElement !== ""
      ? trueElement
      : name
      ? "true-element-label-" + name
      : "";
  };
  const typographyComponent = () => {
    return (
      <Typography
        className={className}
        component={"span"}
        true-element={getTrueElement()}
        sx={FontSx(
          theme,
          fontType,
          primaryColor,
          opacity,
          whiteSpace,
          display,
          textAlign,
          maxWidth,
          truncate,
          secondaryFont,
          isSuccessFont,
          isErrorFont,
          isWarning
        )}
      >
        {children}
      </Typography>
    );
  };

  return showTooltip && truncate ? (
    <Tooltip placement={tooltipPosition} title={tooltipTitle} arrow>
      {typographyComponent()}
    </Tooltip>
  ) : (
    typographyComponent()
  );
};

export default Font;
