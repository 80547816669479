export type ParameterTrackerProperties = {
  parameterName: string | null;
  keyName?: string | number | null;
  dataType?: "string" | "number" | "boolean" | "date" | null;
  value?: any;
  event?: any;
  hookType: "common" | "key";
  readonly source: "url_hash" | "hook" | null;
  readonly forceAppendToURL?: boolean;
  readonly forceDetachFromURL?: boolean;
  readonly initialized?: boolean;
};

export const getParamKeyList = (token: string) => {
  const params = token.split("#")[1] ?? "";
  const searchParams = new URLSearchParams(params ?? "");
  const paramsKvp = Array.from(searchParams).map(([key, value]) => {
    return {
      parameterName: key ?? "NO_KEY_FOUND",
      dataType: "string",
      value: value ?? null,
    } as ParameterTrackerProperties;
  });
  return paramsKvp;
};
// REMOVE - This should not be used in new code and should be removed once all URL parameter paths are upgraded.
export const getParamFromKeyList = (token: string, paramToSearch: string) => {
  const params = token.split("#") ?? "";
  const param = params?.filter((param) => param.startsWith(paramToSearch))?.[0];
  const searchParams = new URLSearchParams(param ?? "");
  const paramsKvp = Array.from(searchParams).map(([key, value]) => {
    return {
      parameterName: key ?? "NO_KEY_FOUND",
      dataType: "string",
      value: value ?? null,
    } as ParameterTrackerProperties;
  });
  return paramsKvp?.[0];
};
// TODO - needs refinement for a variety of edge cases. phase 2?
export const urlHashQueryBuilder = (params: { name: string; value: any }[]) => {
  return params
    .map((param, index) => {
      if (params.length > 0) {
        return index === 0
          ? `${param.name}=${param.value}`
          : `&${param.name}=${param.value}`;
      }
      return "";
    })
    .join("");
};
