import { FC } from "react";
import { BaseTable2 } from "../../../TrueUI";
import { MultiBaseTable } from "../../../../dtos/multi-base-table";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableGridForm from "../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import MultiTitleHeader from "../../../TrueUI/Tables/MultiTable/MultiHeader";
import {
  getColumnsAndDataForChildrenTable,
  getGroupsForTableHeader,
} from "./AuditWorksheetUtils";
import "./AuditWorksheet.module.css";

const summaryTableName = "audit_worksheet_summary_table";

type SummaryTableProps = {
  summaryTable?: MultiBaseTable;
};

const SummaryTable: FC<SummaryTableProps> = ({ summaryTable }) => {
  const tableConfiguration: BaseTable2Properties = {
    name: summaryTableName,
    tableType: "multi",
    columnsAndGroups: {
      columns: summaryTable?.columns ?? [],
      groups: getGroupsForTableHeader(summaryTable?.groups ?? []),
    },
    columnOptions: [
      { fieldName: "ClassCode", width: 23 },
      { fieldName: "Description", width: 54 },
      { fieldName: "Payroll", width: 23 },
    ],
    advancedOptions: {
      paginate: false,
      multiTableProperties: {
        allRowsExpanded: true,
        MultiTablePatternComponent: (e) => {
          return (
            <BaseTableGridForm
              obj={e}
              columnOptions={[
                { fieldName: "ClassCode", width: 25 },
                { fieldName: "Description", width: 50 },
                { fieldName: "Payroll", align: "right" },
              ]}
              columnsAndData={getColumnsAndDataForChildrenTable(
                e.GroupUiidKey,
                summaryTable?.groups
              )}
            />
          );
        },
        MultiTableHeaderPatternComponent: (params) => (
          <MultiTitleHeader headerParams={params} />
        ),
      },
      tableStyle: {
        multiTableHeaderStyle: "normal",
      },
    },
    toolbarOptions: {
      hideToolbar: true,
      showAddButton: false,
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={summaryTableName}/>;
};

export default SummaryTable;
