import { useRecoilValue } from "recoil";
import { globalOptions } from "../GlobalAtoms";
import { globalCSSVariableOptions } from "./globalCSSVariableOptions";
import themes from "./TrueTheme";

/**
 * settingSource here is just so we can track what component is calling this hook. Strictly for debugging purposes (for now...).
 */
export function useCSSVariableManager(_settingSource: string): {
  /**
   * This function should only be called once for the entire lifecycle of the application. Generally in AppWrapper.tsx
   */
  initializeDefaultVariables: () => void;
  /**
   * Takes a list of key value pairs. key should be the css variable in the following format "--t-my-custom-variable", followed by it's value.
   */
  setTransientVariables: (
    kvps: { propertyName: string; value: string }[],
    prefix?: string
  ) => void;
  /**
   * When unmounting, call removeTransientVariables to remove the set of applied css variables.
   */
  removeTransientVariables: (propertyNames: string[]) => void;
  /**
   * Shows a list of active variables in the form of console.infos.
   */
  getListOfAvailableVariables: () => void;
} {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh ?? "defaultTheme"];
  const root = document.querySelector(":root") as HTMLElement;
  const styles = root?.style;

  const initializeGlobalVariables = () => {
    // globalCSSVariableOptions contains all the default variables. You can add more there as you see fit.
    const globalVariables = globalCSSVariableOptions(theme);
    globalVariables.forEach((style) => {
      styles.setProperty(style.propertyName, style.value);
    });
  };

  const setTransientVariables = (
    kvps: { propertyName: string; value: string }[],
    prefix: string = "--t"
  ) => {
    kvps.forEach((style) => {
      styles.setProperty(`${prefix}-${style.propertyName}`, style.value);
    });
  };

  const removeTransientVariables = (propertyNames: string[]) => {
    propertyNames.forEach((propertyName) => {
      styles.removeProperty(propertyName);
    });
  };

  const getListOfAvailableVariables = () => {
    // This function is designed only to show what variables are available in the form of console.info. Consider this a debug function.
    const sheets: any = document.styleSheets;
    [...sheets].forEach((sheet) => {
      if (sheet.href === null) {
        const rules = [...sheet.cssRules];
        rules.forEach((rule) => {
          if (rules.length > 0) {
            const style = rule.style;
            Object.entries(style ?? {}).forEach((s) => {
              const [_propertyName, variable] = s;
              if ((variable as string).startsWith("--")) {
                console.info({ variable });
              }
            });
          }
        });
      }
    });
  };

  return {
    initializeDefaultVariables: initializeGlobalVariables,
    setTransientVariables,
    removeTransientVariables,
    getListOfAvailableVariables,
  };
}
