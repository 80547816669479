import { forwardRef, useEffect, useRef, useState } from "react";
import { useForwardRef } from "../../../hooks/useForwardRef";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureBodyProperties = {
  id?: string;
  children?: any;
  onLoadHeightWidth?: (heightWidth?: { height: number; width: number }) => void;
  onResizeHeightWidth?: (heightWidth?: {
    height: number;
    width: number;
  }) => void;
  onScrollEvent?: (e: {
    scrollEvent: Event;
    rowBoundRefRect: DOMRect | null;
    direction: "up" | "down" | null;
  }) => any;
  onScrollUpEvent?: (e: {
    scrollEvent: Event;
    rowBoundRefRect: DOMRect | null;
    direction: "up" | "down" | null;
  }) => any;
  onScrollDownEvent?: (e: {
    scrollEvent: Event;
    rowBoundRefRect: DOMRect | null;
    direction: "up" | "down" | null;
  }) => any;
};

type BaseGridStructureBodyRefType = HTMLDivElement;

const BaseGridStructureBody = forwardRef<
  BaseGridStructureBodyRefType,
  BaseGridStructureBodyProperties
>(
  (
    {
      id,
      children,
      onLoadHeightWidth,
      onResizeHeightWidth,
      // onScrollEvent,
      onScrollUpEvent,
      onScrollDownEvent,
    },
    ref
  ) => {
    const [hasRegisteredListeners, setHasRegisteredListeners] =
      useState<boolean>(false);
    const rowBoundRef = useRef<HTMLDivElement | null>(null);
    const bodyRef = useForwardRef<HTMLDivElement>(ref);

    useEffect(() => {
      const resizeListener = new ResizeObserver((e) => {
        const wrapperElement =
          e.find((o) => o !== null || o !== undefined || e.length === 1) ??
          null;

        onResizeHeightWidth?.({
          height: wrapperElement?.contentRect?.height ?? 0,
          width: wrapperElement?.contentRect?.width ?? 0,
        });
      });

      resizeListener.observe(bodyRef?.current);

      onLoadHeightWidth?.({
        height: bodyRef?.current?.offsetHeight ?? 0,
        width: bodyRef?.current?.offsetWidth ?? 0,
      });

      return () => {
        onResizeHeightWidth?.({
          height: 0,
          width: 0,
        });
        onLoadHeightWidth?.({
          height: 0,
          width: 0,
        });
        resizeListener.disconnect();
      };
    }, []);

    const scrollDirection = (e: any) => {
      if (e.deltaY < 0) {
        onScrollUpEvent?.({
          scrollEvent: e,
          rowBoundRefRect:
            rowBoundRef?.current?.getBoundingClientRect() ?? null,
          direction: "up",
        });
      }

      if (e.deltaY > 0) {
        onScrollDownEvent?.({
          scrollEvent: e,
          rowBoundRefRect:
            rowBoundRef?.current?.getBoundingClientRect() ?? null,
          direction: "down",
        });
      }
    };

    useEffect(() => {
      if (bodyRef && bodyRef.current) {
        if (!hasRegisteredListeners) {
          bodyRef.current.addEventListener("wheel", (e: any) => {
            scrollDirection(e);
          });
          setHasRegisteredListeners(true);
        }
      }
    }, [bodyRef.current]);

    return (
      <div ref={bodyRef} id={id} className={styles.true_base_grid_body}>
        <div
          ref={rowBoundRef}
          className={styles.true_base_grid_body_row_bounding_container}
        >
          <>{children}</>
        </div>
      </div>
    );
  }
);

export default BaseGridStructureBody;
