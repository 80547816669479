import { Box, IconButton } from "@mui/material";
import { FC, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { OptionsContextActionParameterProperties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useApiGet } from "../../../../../../hooks";
import ModalClaimContact from "./ModalClaimContact";
import {
  ModalConfig,
  allOption,
  defaultClaimContactData,
  removeDeletedItem,
  ClaimModalReferenceSelectOptions,
} from "./ContactModalConstants/ContactModalConstants";
import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import { ClaimContactDto } from "../../../../../../dtos/claim-contact-dto";
import {
  mergeObjectsWithMatchingAttributes,
  toLowerCaseKeys,
  toUpperCaseKeys,
} from "../../../../../../utilities/objectFunctions";
import {
  getSelectOptionFromClaimReferenceList,
  getSelectOptionsByFieldName,
} from "../../../../../../utilities/dataConversionFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import BaseGrid from "../../../../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../../../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../../../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";

const tableName = "claim_contacts_table";
const CLAIM_CONTACTS_API_PATH = "api/ClaimContact";

type ClaimContactsProps = {
  claimId?: number;
  hasClaimsManagementPermissions: boolean;
};

const ClaimContacts: FC<ClaimContactsProps> = ({
  claimId,
  hasClaimsManagementPermissions,
}) => {
  const [claimContact, setClaimContact] = useState<
    ClaimContactDto | Partial<ClaimContactDto>
  >(defaultClaimContactData);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    edit: false,
    add: false,
  });
  const [referenceSelectOptions, setReferenceSelectOptions] = useState<
    SelectOptions[]
  >([]);
  const [modalSelectOptions, setModalSelectOptions] =
    useState<ClaimModalReferenceSelectOptions | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const { responseGet, dispatchGet } = useApiGet<ClaimReferenceDto[]>(
    `api/ClaimReference/GetReferencesForClaimContactsForm`
  );
  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const allReferences = responseGet?.axiosResponse?.data ?? [];
      const selectOptions = getSelectOptionsByFieldName(
        "ContactStatus",
        allReferences
      );

      const selectOptionsWithNoDeletedItem = allOption.concat(
        removeDeletedItem(selectOptions)
      );

      const modalOptions = {
        statuses: getSelectOptionsByFieldName("ContactStatus", allReferences),
        types: getSelectOptionsByFieldName("ContactType", allReferences),
        phoneTypes: [
          {
            displayName: "",
            stringValue: "",
          },
          ...getSelectOptionFromClaimReferenceList("PhoneType", allReferences),
        ],
      };

      setModalSelectOptions(modalOptions);
      setReferenceSelectOptions(selectOptionsWithNoDeletedItem);
    }
  }, [responseGet]);

  const addContact = () => {
    setModalConfig({
      edit: false,
      add: true,
      title: "Add Contact",
    });
  };

  const editContact = () => {
    setModalConfig({
      edit: true,
      add: false,
      title: "Edit Contact",
    });
  };

  const closeModal = (isOpen: boolean) => {
    if (isOpen === false) {
      setClaimContact(defaultClaimContactData);
      setModalConfig({ ...modalConfig, edit: isOpen, add: isOpen });
      setSelectedRowData(null);
    }
  };

  const saveRowToTable = (
    newRowData: ClaimContactDto | Partial<ClaimContactDto> | null,
    action: "add" | "update" | "delete" | null
  ) => {
    if (newRowData === null || action === null) return;
    switch (action) {
      case "add":
        addRowToTable(newRowData);
        break;
      case "update":
        updateExistingRow(newRowData);
        break;
      case "delete":
        deleteRow(newRowData);
        break;
      default:
        break;
    }
  };
  const addRowToTable = (
    newRowData: ClaimContactDto | Partial<ClaimContactDto>
  ) => {
    const newRow = (rowPattern: any) => {
      return { ...rowPattern, ...toUpperCaseKeys(newRowData) };
    };
    tableContactsOptions?.addRow(newRow);
  };

  const updateExistingRow = (rowData) => {
    const updatedRow = {
      rowKey: selectedRowData.rowKey,
      hydratedData: {
        ...selectedRowData.hydratedData,
        ...toUpperCaseKeys(rowData),
      },
    };
    tableContactsOptions?.updateRowAndReload(updatedRow);
    setSelectedRowData(null);
  };
  const deleteRow = (dataToUpdate) => {
    tableContactsOptions?.deleteRow({
      rowKey: selectedRowData?.rowKey,
      hydratedData: toUpperCaseKeys(dataToUpdate),
      deleteType: "hidden",
    });
  };

  const onEditButtonClick = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    const selectedData = mergeObjectsWithMatchingAttributes(
      toUpperCaseKeys(defaultClaimContactData),
      actionOptions?.row
    );

    setSelectedRowData({
      hydratedData: selectedData,
      rowKey: actionOptions?.row?._row_key_column,
    });

    const contactType = JSON.parse(actionOptions?.row?.ContactType)[0]?.Id;
    setClaimContact({ ...toLowerCaseKeys(selectedData), contactType });
    editContact();
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          disabled={!hasClaimsManagementPermissions}
          aria-label="expand row"
          size="small"
          onClick={() => onEditButtonClick(actionOptions)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `${CLAIM_CONTACTS_API_PATH}/GetClaimContacts?claimId=${claimId}`,
    columnOptions: [
      { fieldName: "ContactName", width: 15 },
      { fieldName: "Company", width: 15 },
      { fieldName: "EmailAddress", width: 20 },
      { fieldName: "Phone1", width: 10 },
      { fieldName: "ContactStatus", width: 10 },
      { fieldName: "ContactType", width: 30 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    // permissions: { TODO - Uncomment this when permissions are implemented on new BaseGrid
    //   addPermissions: [
    //     PermissionsEnums.CLAIMS_ADJUSTER,
    //     PermissionsEnums.CLAIMS_MANAGER,
    //     PermissionsEnums.CLAIMS_ADMIN,
    //   ],
    //   editPermissions: [
    //     PermissionsEnums.CLAIMS_ADJUSTER,
    //     PermissionsEnums.CLAIMS_MANAGER,
    //     PermissionsEnums.CLAIMS_ADMIN,
    //   ],
    // },
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "status-filter",
          columnNames: ["ContactStatus"],
          optionsOverride: referenceSelectOptions,
        }),
    ],
    advancedOptions: {
      overrideColumnDefaultValues: [
        {
          columnFieldName: "ClaimId",
          propName: "defaultValue",
          value: claimId,
        },
      ],
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
    },
    toolbarOptions: {
      showSortFilter: false,
      showSaveButton: false,
      showEditButton: false,
      showImportButton: false,
      showAddButton: hasClaimsManagementPermissions,
    },
    events: {
      addEventOverrideCallback: () => {
        addContact();
      },
    },
  };

  const tableContactsOptions = useBaseGrid(tableConfiguration);

  return (
    <div
      id="claim-contact-table-container"
      style={{ height: "100%", overflow: "hidden" }}
    >
      {referenceSelectOptions?.length > 0 && claimContact && (
        <>
          <BaseGrid name={tableName} />
          {modalSelectOptions && (modalConfig.add || modalConfig.edit) && (
            <ModalClaimContact
              modalConfiguration={modalConfig}
              closeModal={closeModal}
              contactFormReferences={modalSelectOptions}
              claimContactData={claimContact}
              claimId={claimId}
              saveRowToTable={saveRowToTable}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ClaimContacts;
