import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";

export const multiselectStyles = (
  theme: Theme,
  addInputIdent: boolean = false
) => ({
  "& .true_input_container": {
    display: "flex",
    alignItems: "center",
    "& .MuiOutlinedInput-notchedOutline": { border: "none !important" },
    "& .MuiSelect-select": { padding: 0 },
    "&.start": {
      "& .true_input_label": {
        marginRight: "5px",
      },
    },
    "&.top": {
      display: "block",
    },
    "&.end": {
      flexDirection: "row-reverse",
      "& .true_input_label": {
        marginLeft: "5px",
      },
    },
    "&.bottom": {
      flexDirection: "column-reverse",
      "& .true_input_label": {
        marginTop: "5px",
      },
    },
    "& .true_input": {
      "&.standard": {
        height: "32px",
        position: "relative",
        "& input, & textarea": {
          padding: "4px 0 5px",
        },
      },
      "&.filled": {
        height: "fit-content",
        minHeight: "31px",
        position: "relative",
        backgroundColor: theme?.SECONDARY,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        transition: "background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        "& input": {
          color: theme?.INPUT_TEXT_COLOR,
          padding: "4px 12px",
        },
        "& .k-button-solid-base": {
          backgroundColor: "transparent",
        },
        "&.read-only": {
          backgroundColor: "transparent",
          "& input": {
            color: theme?.TEXT_COLOR,
          },
        },
        "&:not(.read-only):hover": {
          background: getColorWithOpacityHexaDecimal(theme?.SECONDARY, 60),
        },
        "&:not(.true_input_error):hover:before": {
          borderBottom: `2px solid ${theme?.BORDER_COLOR}`,
        },
      },
      "&.outlined": {
        height: "40px",
        position: "relative",
        borderRadius: "4px",
        "& input": {
          padding: "8.5px 14px",
          borderRadius: "4px",
        },
      },
      "&.read-only": {
        "& input": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          display: "-webkit-box !important",
          pointerEvents: "none",
          paddingLeft: addInputIdent ? "12px" : "0px",
        },
        "&:before": {
          display: "none",
        },
        "&:hover": {
          cursor: "default",
        },
      },
      "& input": {
        height: "100%",
        font: "inherit",
        fontSize: "var(--default-font-size-input)",
        letterSpacing: "inherit",
        color: theme?.TEXT_COLOR,
        border: 0,
        background: "none",
        margin: 0,
        display: "block",
        width: "100%",
        "&:focus": {
          outline: "none",
        },
      },
      "&:not(.outlined)": {
        "&::after": {
          borderBottom: `2px solid ${theme?.PRIMARY}`,
          left: "0",
          bottom: "0",
          content: "''",
          position: "absolute",
          right: "0",
          transform: "scaleX(0)",
          transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          WebkitTransform: "scaleX(0)",
          MozTransform: "scaleX(0)",
          msTransform: "scaleX(0)",
          WebkitTransition:
            "-webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          pointerEvents: "none",
        },
        "&::before": {
          borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
          left: 0,
          bottom: 0,
          content: "''",
          position: "absolute",
          right: 0,
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          WebkitTransition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          pointerEvents: "none",
        },
        "&:not(.true_input_error):not(.read-only):focus-within:after": {
          transform: "scaleX(1) translateX(0)",
        },
      },
      "&.bottom-border": {
        borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
      },
    },
    "& .true_input_label": {
      display: "block",
      color: theme?.TEXT_COLOR,
      "&.withoutSize": {
        transform: "none",
        //lineHeight: "var(--default-line-height)",
      },
    },
    "& .true_input, & .true_select": {
      width: "100%",
    },
  },
});
