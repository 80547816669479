import { Theme } from "../../../media/themeTypes";

export const bottomNavStyles = (theme: Theme) => ({
  backgroundColor: theme?.PRIMARY_BACKGROUND,
  svg: {
    color: theme?.TEXT_COLOR,
  },
  div: {
    color: theme?.TEXT_COLOR,
  },
  ".help-drawer": {
    borderTop: `1px solid ${theme?.BORDER_COLOR}`,
  },
  ".icon-bottom-name": {
    color: theme?.PRIMARY,
  },
});
