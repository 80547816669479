import { FC } from "react";
import { Link } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Col, Font, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import style from "./quickActions.module.css";

type NewSubmissionFormProps = {
  similarityScore: number | null;
};

const NewSubmissionOFAC: FC<NewSubmissionFormProps> = ({ similarityScore }) => {
  return (
    <Row {...rowWithNoMarginNorGutter}>
      <Col {...colWithNoMarginNorGutter} breakpoints={{ md: 2, lg: 2, xl: 2 }}>
        <ErrorOutlineOutlined
          className={`${style.error_outline_outlined_icon}`}
        />
      </Col>
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 10, lg: 10, xl: 10 }}
      >
        <Row {...rowWithNoMarginNorGutter} rowDirection="column">
          <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
            <Font fontType={"BODY_BIG"}>O F A C Sanctions Hit</Font>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            verticalGutter="10px"
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
          >
            <Font textAlign="start" whiteSpace="normal">
              The company you entered has been found on the O F A C sanctions
              list.
            </Font>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            verticalGutter="10px"
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
          >
            <Font textAlign="start" whiteSpace="normal">
              The information you entered matched closest to SDN_NAME = XX, Type
              = 'Individual', Program = 'BALKANS'.
            </Font>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            verticalGutter="10px"
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
          >
            <Font textAlign="start" whiteSpace="normal">
              If you need further information or would like to validate this hit
              please go to:
              <br />
              <Link
                href="http://sanctionssearch.ofac.treas.gov"
                target="_blank"
              >
                http://sanctionssearch.ofac.treas.gov
              </Link>
            </Font>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            verticalGutter="10px"
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
          >
            <Font textAlign="start" whiteSpace="normal">
              You can click on Continue to go the next step in the submission or
              Cancel to close this submission.
            </Font>
          </Col>
          {similarityScore && (
            <Col
              {...colWithNoMarginNorGutter}
              verticalGutter="10px"
              horizontalAlign="flex-start"
              breakpoints={{ md: 11, lg: 11, xl: 11 }}
            >
              <Font textAlign="start" whiteSpace="normal">
                {`Similarity Score: ${similarityScore}`}
              </Font>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default NewSubmissionOFAC;
