import { useEffect, useState } from 'react';
import { models, Report, Embed, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';
import './ReportDemo.css';
import { Print,	
	//FilterAlt,	

} from "@mui/icons-material";


// Root Component to demonstrate usage of wrapper component
function DemoApp(): JSX.Element {

	const sampleReportUrl = 'https://truepowerbi.azurewebsites.net/api/reports/56b2d5c1-9df3-4070-936d-cdb2e0e812bd/27ab0bd3-cfa5-4173-894e-5e977e163f31?code=39WBOPamrlGI7wbXy8YXEc_KQbAU4xlv7YqsT_vlCW80AzFumJt2eg==';

	// Track the report object
	const [Report, setReport] = useState<Report | null>(null);

	// Track Report embedding status
	const [isEmbedded, setIsEmbedded] = useState<boolean>(false);


	// CSS Class to be passed to the embedded component
	const reportClass = 'report-container';

	const MENU_OPTIONS = [
		/*{
			text: 'Filter Data',
			icon: <FilterAlt />,
			onClick: () => handleFilterDataClick(),
		},*/
		{
			text: 'Print Report',
			icon: <Print />,
			onClick: () => handleExportReportClick(),
		},
	];
	/*
	const handleFilterDataClick = () => {
		// Implement filter data logic
	};*/

	const handleExportReportClick = () => {
		// Implement export report logic
		Report?.print();
	};

	// Pass the basic embed configurations to the embedded component to bootstrap the report on first load
	// Values for properties like embedUrl, accessToken and settings will be set on click of button
	const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		permissions: models.Permissions.All,
		viewMode: models.ViewMode.View,
		settings: {
			navContentPaneEnabled: true,
			bars: {
				actionBar: {
					visible: true
				}
			}
		},

	});

	useEffect(() => {
		embedReport();
	}, []);

	const eventHandlersMap: Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null> = new Map([
		['loaded', () => console.log('Report has loaded')],
		['rendered', () => console.log('Report has rendered')],
		['error', (event?: service.ICustomEvent<any>) => {
			if (event) {
				console.error(event.detail);
			}
		},
		],
		['visualClicked', () => console.log('visual clicked')],
		['pageChanged', (event) => console.log(event)],
	]);

	const embedReport = async (): Promise<void> => {
		console.log('Embed Report clicked');

		// Get the embed config from the service
		const reportConfigResponse = await fetch(sampleReportUrl);

		if (reportConfigResponse === null) {
			return;
		}

		if (!reportConfigResponse?.ok) {
			console.error(`Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`);
			return;
		}

		const reportConfig = await reportConfigResponse.json();

		// Update the reportConfig to embed the PowerBI report
		setReportConfig({
			...sampleReportConfig,
			embedUrl: reportConfig.EmbedUrl,
			accessToken: reportConfig.EmbedToken
		});
		setIsEmbedded(true);
			
	};
		

	const reportComponent =
		<PowerBIEmbed
			embedConfig={sampleReportConfig}
			eventHandlers={eventHandlersMap}
			cssClassName={reportClass}
			getEmbeddedComponent={(embedObject: Embed) => {
				console.log(`Embedded object of type "${embedObject.embedtype}" received`);
				setReport(embedObject as Report);
			}}
		/>;

	return (
		<div className="report-frame-container">
			<div className="menu">
				{MENU_OPTIONS.map((option) => (
					<button className="menu-item" key={option.text} onClick={option.onClick}>
						{option.icon}
						{option.text}
					</button>
				))}
			</div>
			<div className="report-controls">
				

				{isEmbedded ? reportComponent : null}
			</div>

		</div>
	);
}

export default DemoApp;