import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { RepresentationDto } from "../../../../../../dtos/representation-dto";
import { ClaimRepresentationContactDto } from "../../../../../../dtos/claim-representation-contact-dto";

export type FormStateProps = {
  title: string | null | undefined;
  mode: "edit" | "readonly" | "add";
  rowTableClicked?: "case" | "contact" | "event";
};

export type LitigationFormProps = {
  claimId?: number;
  caseData: RepresentationDto | Partial<RepresentationDto>;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps) => void;
  saveLitigation?: (
    litigationRow: RepresentationDto | Partial<RepresentationDto>,
    action: "add" | "update" | "delete",
    tableToUpdate?: "cases" | null
  ) => void;
  updateCaseData: (
    caseData: RepresentationDto | Partial<RepresentationDto>
  ) => void;
  hasClaimsManagementPermissions: boolean;
};

export type LitigationContactFormProps = {
  claimId?: number;
  contactData:
    | ClaimRepresentationContactDto
    | Partial<ClaimRepresentationContactDto>;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps) => void;
  saveLitigation?: (
    litigationContactRow:
      | ClaimRepresentationContactDto
      | Partial<ClaimRepresentationContactDto>,
    action: "add" | "update" | "delete"
  ) => void;
  selectedCase?: RepresentationDto | Partial<RepresentationDto>;
  hasClaimsManagementPermissions: boolean;
};

export type LitigationContactsFormProps = {
  contactData:
    | ClaimRepresentationContactDto
    | Partial<ClaimRepresentationContactDto>;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps) => void;
  saveLitigation?: (
    litigationRow: RepresentationDto | Partial<RepresentationDto>,
    action: "add" | "update" | "delete"
  ) => void;
};
export type LitigationProps = {
  claimId?: number;
  hasClaimsManagementPermissions: boolean;
};

export const colProps: ColProps = {
  horizontalMargin: "0px",
  displayFlex: false,
  verticalGutter: "15px",
  horizontalGutter: "15px",
};
export const tableName = "claim_litigation_table";
export const tableNameContacts = "claim_litigation_contacts_table";
export const API_PATH = "api/ClaimRepresentation";
export const API_PATH_CONTACTS =
  "api/ClaimRepresentation/GetClaimRepresentationContact";
export const defaultRepresentationData = {
  representationId: 0,
  caseNumber: "",
  status: -1,
  court: "",
  venue: "",
};

export const defaultRepresentationContactData:
  | ClaimRepresentationContactDto
  | Partial<ClaimRepresentationContactDto> = {
  representationContactId: 0,
  representationId: 0,
  claimContactId: 0,
  claimId: 0,
  contactType: -1,
  contactStatus: 1,
  contactName: "",
  company: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  phone1: "",
  phone1Type: "",
  phone2: "",
  phone2Type: "",
  fax: "",
  emailAddress: "",
};

export type TableProps = {
  claimId?: number;
  representationId?: number;
  //caseData: RepresentationDto | Partial<RepresentationDto>;
  setCaseData: (
    caseData: RepresentationDto | Partial<RepresentationDto>
  ) => void;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps | null | undefined) => void;
  //rowData: any;
  setRowData: (rowData: any) => void;
  setTableInstanceMethods: (tableInstanceMethods: any) => void;
  setSelectedCase?: (
    caseNumber: RepresentationDto | Partial<RepresentationDto>
  ) => void;
  selectedCase?: RepresentationDto | Partial<RepresentationDto>;
  caseNumber?: string;
  hasClaimsManagementPermissions: boolean;
};
export type TablesCommonProps = {
  claimId?: number;
  //caseData: RepresentationDto | Partial<RepresentationDto>;
  setCaseData: (
    caseData: RepresentationDto | Partial<RepresentationDto>
  ) => void;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps | null | undefined) => void;
  rowData: any;
  setRowData: (rowData: any) => void;
  setSelectedCaseNumber?: (caseNumber: string) => void;
  selectedCase: RepresentationDto | Partial<RepresentationDto>;
  caseNumber?: string;
};
export type ClaimLitigationTablesProps = {
  tablesCommonProps: TablesCommonProps;
  setTableInstanceMethods: (tableInstanceMethods: any) => void;
  caseNumber?: string;
  setCaseTableInstanceMethods: (tableInstanceMethods: any) => void;
  hasClaimsManagementPermissions: boolean;
};
export const parseToStringObjectValues = (object) => {
  Object.keys(object).map((key) =>
    object[key] !== null
      ? (object[key] = object[key]?.toString())
      : (object[key] = "")
  );
  return object;
};
