import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import {
  DeletedPaymentProps,
  FinancialModalProps,
  PaymentTableRow,
  PaymentType,
} from "./typesAndConstants";
import TabsClaimFinancial from "./TabsClaimFinancial";
import { useApiGet } from "../../../../../../hooks";
import { AddPaymentSelectsOptionsDto } from "../../../../../../dtos/add-payment-selects-options-dto";
import ModalAddRecovery from "./ModalAddRecovery";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { UpdatedPaymentRowData } from "./ClaimFinancialUtil";
import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { VoidedOrRefundModalOpenProps } from "./RefundComponents/TypesModalRefundPayment";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { isEmptyValue } from "../../../../../../utilities/conditionalSupportFunctions";

export type PaymentsModalProps = {
  claimId?: number;

  tabKey?: string;
  modalProps: FinancialModalProps | Partial<FinancialModalProps>;
  paymentId?: string | null;
  rowKey?: string;
  insuredId?: number;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  paymentSelectsOptions: AddPaymentSelectsOptionsDto | null;
  closeModal: (isOpen: boolean, refreshTable?: boolean) => void;

  setNewPaymentRowData?: (
    payment: PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  ) => void;
  setUpdatedPaymentRowData?: (
    paymentData?: UpdatedPaymentRowData | null
  ) => void;
  setDeletedPaymentRowData?: (paymentData: DeletedPaymentProps) => void;
  setIsVoidedRefundOrMoveModalConfig?: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
};

const ModalClaimFinancial: FC<PaymentsModalProps> = ({
  claimId,
  insuredId,
  modalProps,
  closeModal,
  paymentId,
  setNewPaymentRowData,
  setUpdatedPaymentRowData,
  setDeletedPaymentRowData,
  rowKey,
  setIsVoidedRefundOrMoveModalConfig,
  claimLandingClaimant,
  tabKey,
  paymentSelectsOptions,
}) => {
  const [paymentFormSelectsData, setPaymentFormSelectsData] =
    useState<AddPaymentSelectsOptionsDto | null>(null);

  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/ReserveType/GetPaymentFormSelects?claimId=${claimId}`
  );

  const _modalProps = {
    showCloseButton: false,
    saveEvent: undefined,
    deleteEvent: undefined,
    height: "auto",
    cancelEvent: () => closeModal(false, false),
    isCopying: false,
  };

  const [paymentModalProps, setPaymentModalProps] = useState<
    Partial<FinancialModalProps>
  >({ ...modalProps, ..._modalProps });

  useEffect(() => {
    if (paymentSelectsOptions !== null) {
      setPaymentFormSelectsData(paymentSelectsOptions);
    } else {
      dispatchGet();
    }
  }, [paymentSelectsOptions]);

  useEffect(() => {
    setPaymentModalProps({ ...modalProps, ..._modalProps });
  }, [modalProps]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPaymentFormSelectsData(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  const getModalContent = (option?: PaymentType) => {
    const modalContentCommonProps = {
      claimId: claimId,
      insuredId: insuredId,
      closeModal: closeModal,
      paymentId: paymentId,
      paymentFormSelectsData: paymentFormSelectsData,
      setNewPaymentRowData: setNewPaymentRowData,
      setUpdatedPaymentRowData: setUpdatedPaymentRowData,
      setDeletedPaymentRowData: setDeletedPaymentRowData,
      rowKey: rowKey,
      claimLandingClaimant: claimLandingClaimant,
      tabKey: tabKey,
      paymentModalProps,
      setPaymentModalProps,
    };

    if (isEmptyValue(paymentFormSelectsData)) return <>{"Loading..."}</>;

    switch (option) {
      case "view-payment":
        return (
          <TabsClaimFinancial
            {...modalContentCommonProps}
            paymentType={option}
            setIsVoidedRefundOrMoveModalConfig={
              setIsVoidedRefundOrMoveModalConfig
            }
          />
        );

      case "add-recovery":
        return (
          <ModalAddRecovery {...modalContentCommonProps} tabKey={tabKey} />
        );

      default:
        return (
          <TabsClaimFinancial
            {...modalContentCommonProps}
            paymentType={option}
            tabKey={tabKey}
          />
        );
    }
  };

  return (
    <Modal
      id="claim-financial-payments"
      title={paymentModalProps?.title ?? ""}
      open={paymentModalProps?.open ?? false}
      {...paymentModalProps}
    >
      {getModalContent(modalProps?.option)}
    </Modal>
  );
};

export default ModalClaimFinancial;
