import { ContextEnums } from "../../../../dtos/context-enums";
import { DiaryInsuredFileDto } from "../../../../dtos/diary-insured-file-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { addCustomOption } from "../../../../utilities/selectFunctions";
import DownloadAsZipButton from "../../../TrueUI/File/DownloadAsZipButton";
import {
  BaseTable2Properties,
  MultiSelectOptions,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import BaseTableMultiSelectDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableMultiSelectDropdownFilter";
import BaseTableTabStatusFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableTabStatusFilter";
import { ContextProps } from "../InsuredDrawerConstants";

export type ModalFileProps = {
  fileId: number;
  downloadedFileName: string;
  insuredFileComments?: string;
  category: string;
  dateAndUserAdded: string;
  fileDisplayName: string;
  fileType: string;
  isEditing: boolean;
  rowKey: string;
  policyId?: number;
  policyNumberAndYear?: string;
  userShortName?: string;
  addedDate?: string;
  categoryOptions?: SelectOptions[];
  policyOptions?: SelectOptions[];
  context?: ContextProps;
};

export type ModalConfigurationProps = {
  configuration: ModalFileProps;
  setConfiguration?: React.Dispatch<React.SetStateAction<ModalFileProps>>;
  saveSuccessful?: (updatedData) => void;
  deleteSuccessful?: () => void;
};
export type ModalClaimFileContentProps = {
  configuration: ModalFileProps;
  setDiaryInsuredFileData: (
    diaryInsuredFileData: Partial<DiaryInsuredFileDto> | null
  ) => void;
  diaryInsuredFileData: Partial<DiaryInsuredFileDto> | null;
  errorDetails: any;
};

export const defaultConfiguration = {
  fileId: -1,
  downloadedFileName: "",
  category: "",
  dateAndUserAdded: "",
  fileDisplayName: "",
  fileType: "",
  isEditing: false,
  rowKey: "",
  policyId: -1,
  policyNumberAndYear: "",
  userShortName: "",
  addedDate: "",
};

export const getPolicyOptions = (type, filesConfiguration) =>
  type === ContextEnums.CLAIMS ? [] : filesConfiguration?.policyOptions;

export const getFilterOptionsByContext = (type: number, filesConfiguration) => {
  const baseTableFilters = [
    (actionOptions) =>
      BaseTableTabStatusFilter({
        defaultValue: "all",
        filterOptions: actionOptions,
        columnsNames: ["FileType"],
        optionsOverride: addCustomOption(filesConfiguration?.fileTypes ?? [], {
          displayName: "ALL",
          stringValue: "all",
        }),
      }),
    (actionOptions) =>
      BaseTableMultiSelectDropdownFilter({
        id: "category-filter",
        defaultValue: [
          {
            displayName: "All",
            stringValue: "all",
          },
        ],
        filterOptions: actionOptions,
        columnsNames: ["Category"],
        optionsOverride: addCustomOption(
          filesConfiguration?.categoryOptions ?? [],
          {
            displayName: "All",
            stringValue: "all",
          }
        ),
        labelText: "Category:",
        showClearAll: false,
      }),
    (actionOptions) =>
      BaseTableDropdownFilter({
        id: "created-by-filter",
        defaultValue: "all",
        filterOptions: actionOptions,
        columnsNames: ["CreatedBy"],
        optionsOverride: addCustomOption(
          filesConfiguration?.userOptions ?? [],
          {
            displayName: "All",
            stringValue: "all",
          }
        ),
        labelText: "Added By:",
      }),
  ];
  if (type === ContextEnums.INSURED) {
    baseTableFilters.splice(1, 0, (actionOptions) =>
      BaseTableDropdownFilter({
        id: "policy-year-filter",
        defaultValue: "all",
        filterOptions: actionOptions,
        columnsNames: ["PolicyYear"],
        optionsOverride: addCustomOption(
          filesConfiguration?.policyOptions ?? [],
          {
            displayName: "All",
            stringValue: "all",
          }
        ),
        labelText: "Policy:",
      })
    );
  }
  return baseTableFilters;
};

export const GetURLFileListingByContext = (
  context: ContextProps,
  randomUUID: string
) => {
  return context.type === ContextEnums.CLAIMS
    ? `api/FileArchive/GetClaimFileList?ClaimNumber=${context.claimNumber}&a=${randomUUID}`
    : `api/FileArchive/GetInsuredFileList?insuredId=${context.insuredId}&a=${randomUUID}`;
};

export const GetURLUpdateFileByContext = (
  fileId: number,
  newCategory: string,
  policyId?: number | null,
  context?: ContextProps
) => {
  const newPolicyId = policyId === null ? -1 : policyId;
  return context?.type === ContextEnums.CLAIMS
    ? `api/FileArchive/UpdateClaimFile?fileId=${fileId}&newCategory=${newCategory}`
    : `api/FileArchive/UpdateInsuredFile?fileId=${fileId}&newCategory=${newCategory}&newPolicyId=${newPolicyId}`;
};

export const GetURLDeleteFileByContext = (
  fileId: number,
  context?: ContextProps
) => {
  return context?.type === ContextEnums.CLAIMS
    ? `api/FileArchive/DeleteClaimFile?fileId=${fileId}`
    : `api/FileArchive/DeleteInsuredFile?fileId=${fileId}`;
};

export const GetURLUploadFileByContext = (context?: ContextProps) => {
  return context?.type === ContextEnums.CLAIMS
    ? `api/FileArchive/UploadClaimFile`
    : `api/FileArchive/UploadInsuredFile`;
};

export const GetDefaultUploadFileData = (context?: ContextProps) => {
  return {
    category: null,
    fileId: null,
    policyId: null,
    claimNumber: context?.claimNumber === "" ? null : context?.claimNumber,
    insuredId: context?.insuredId,
  };
};

export type ModalConfirmationProps = {
  type?: "delete" | "discardChanges";
  isOpen?: boolean;
  id?: string;
  description?: string;
};

export const DefaultConfirmationProps = {
  isOpen: false,
  id: "",
  description: "",
};

export const getColumnAndToolbarConfig = (tableName) => {
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnOptions: [
      { fieldName: "FileName", width: 20 },
      { fieldName: "Category", width: 12 },
      { fieldName: "DateAdded", width: 12 },
      { fieldName: "FileSize", width: 10 },
      { fieldName: "DateReviewed", width: 8 },
      { fieldName: "InsuredFileComments", width: 35 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
  };

  return tableConfiguration;
};

export type MultiSelectFilesProps = {
  showButton?: boolean;
  fileNames?: string[];
  dispatchAction?: boolean;
};

export const getOnCheckEvent = (checkedRows, setMultiSelectInfo) => {
  const fileNames = checkedRows?.map((file) => file?.DownloadedFileName);
  setMultiSelectInfo({
    dispatchAction: false,
    showButton: (checkedRows?.length ?? 0) > 0,
    fileNames: fileNames,
  });
};

export const ZipButton = (multiSelectInfo, setMultiSelectInfo, code) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      {multiSelectInfo?.showButton === true && (
        <DownloadAsZipButton
          fileNames={multiSelectInfo?.fileNames ?? []}
          zipName={`${code} ${FormattingDate(new Date())} Files`}
          onClick={() =>
            setMultiSelectInfo({
              ...multiSelectInfo,
              dispatchAction: true,
            })
          }
        >
          Download
        </DownloadAsZipButton>
      )}
    </div>
  );
};

export const multiSelectOption = (
  multiSelectInfo,
  setMultiSelectInfo
): MultiSelectOptions[] => {
  return [
    {
      name: "custom",
      dispatchAction: multiSelectInfo?.dispatchAction,
      onActionComplete(isComplete) {
        setMultiSelectInfo({
          ...multiSelectInfo,
          dispatchAction: !isComplete,
        });
      },
    },
  ];
};
