import { Theme } from "../../../../media/themeTypes";

export const ModalStateErrorMessageStyles = (theme: Theme) => {
  return { ".modal-state-error": { color: theme.DANGER } };
};

export const collapseContainerStyles = () => {
  return {
    width: "100%",
    ".true_collapse_title_accordion": {
      minHeight: "fit-content !important",
      margin: "0 !important",
    },
    ".collapse_content_accordion": {
      padding: "0px 5px 5px 5px !important",
    },
    ".MuiAccordionSummary-contentGutters": {
      margin: "0px !important",
    },
  };
};
