import { grey, blue, red } from "@mui/material/colors";

const COLOR_GRAY_200 = grey[200];
const COLOR_GRAY_300 = grey[300];
const COLOR_GRAY_400 = grey[400];
const COLOR_GRAY_500 = grey[500];
const COLOR_GRAY_600 = grey[600];
const COLOR_GRAY_700 = grey[700];
const COLOR_GRAY_800 = grey[800];
const COLOR_GRAY_TABLE = "rgba(0,0,0,0.04)";
const COLOR_GRAY_READONLY = "rgba(0, 0, 0, 0.38)";
const COLOR_GRAY_MODAL_BACKGROUND = "#8E8EA440";
const COLOR_BLUE_400 = blue[400];
const COLOR_BLUE_500 = blue[500];
const COLOR_BLACK_12 = "#121212";
const COLOR_BLACK_25 = "rgb(25, 25, 25)";
const COLOR_BLACK_35 = "rgb(35, 35, 35)";
const BLACK = "rgba(0, 0, 0, 0.65)";
const INPUT_BLACK = "rgba(0, 0, 0, 0.87)";
const COLOR_RED_500 = red[500];
const SELECTED_BLUE = "#E3EEFA";
const SWITCH_DISABLED = "#818190";
const SWITCH_BACKGROUND = "#97a1d7";

//Figma
const BLACK_ICON = "#2D2D46";
const BLUE_ICON = "#66a4e1";
const GRAY_ICON = "#847F7F";
const BLUE_LINK = "#1785D8";
const BLUE_PRIMARY = "#18A0FB";
const BLUE_LINE = "rgba(24, 160, 251, 0.36)";
const LIGHT_GRAY = "#847F7F";
const BORDER_GRAY = "#CBC8C8";
const DARK_RED = "#E51A5F";
const LIGHT_RED = "#e24077";
const TRUE_BLUE = "#33A0FF";
const BLACK_TITLE = "#2D2D46";

//TAGS
const GREEN_TAG = "#37B400";
const GREEN_TAG_TEXT = "#FFFFFF";
const YELLOW_TAG = "#FFC000";
const YELLOW_TAG_TEXT = "#847F7F";
const RED_TAG = "#F31700";
const RED_TAG_TEXT = "#FFFFFF";

//Score Chip Colors
const ScoreBackground0 = "#9D9C9B";
const ScoreBackground1 = "#F31700";
const ScoreBackground2 = "#F4A32A";
const ScoreBackground3 = "#FFD643";
const ScoreBackground4 = "#A6D73F";
const ScoreBackground5 = "#37B400";

const ScoreBackgroundHover0 = "#8C8B8A";
const ScoreBackgroundHover1 = "#f54532";
const ScoreBackgroundHover2 = "#f6b554";
const ScoreBackgroundHover3 = "#ffde68";
const ScoreBackgroundHover4 = "#b7df65";
const ScoreBackgroundHover5 = "#5ec332";

//Custom Scrollbar (table)
const ScrollbarThumb = "#c1c1c1";
const ScrollbarThumbHover = "#555";

// Development Colors
const DEV_ERROR = "#FF7961";

const color = {
  BLUE_ICON,
  BLACK_ICON,
  GRAY_ICON,
  BLUE_LINE,
  BLUE_LINK,
  BLUE_PRIMARY,
  TRUE_BLUE,
  LIGHT_GRAY,
  BLACK_TITLE,
  COLOR_GRAY_200,
  COLOR_GRAY_300,
  COLOR_GRAY_400,
  COLOR_GRAY_500,
  COLOR_GRAY_600,
  COLOR_GRAY_700,
  COLOR_GRAY_800,
  COLOR_GRAY_TABLE,
  COLOR_GRAY_READONLY,
  COLOR_GRAY_MODAL_BACKGROUND,
  COLOR_BLUE_400,
  COLOR_BLUE_500,
  COLOR_BLACK_12,
  COLOR_BLACK_25,
  COLOR_BLACK_35,
  BLACK,
  BORDER_GRAY,
  COLOR_RED_500,
  LIGHT_RED,
  DARK_RED,
  ScoreBackground0,
  ScoreBackground1,
  ScoreBackground2,
  ScoreBackground3,
  ScoreBackground4,
  ScoreBackground5,
  ScoreBackgroundHover0,
  ScoreBackgroundHover1,
  ScoreBackgroundHover2,
  ScoreBackgroundHover3,
  ScoreBackgroundHover4,
  ScoreBackgroundHover5,
  SWITCH_DISABLED,
  SWITCH_BACKGROUND,
  GREEN_TAG,
  GREEN_TAG_TEXT,
  YELLOW_TAG,
  YELLOW_TAG_TEXT,
  RED_TAG,
  RED_TAG_TEXT,
  INPUT_BLACK,
  SELECTED_BLUE,
  ScrollbarThumb,
  ScrollbarThumbHover,
  // Development Colors
  DEV_ERROR,
};
export default color;
