import { SPECIAL_SORT_ORDER } from "../BaseGridConstants";
import { SelectOptions } from "../../../dtos/select-options";
import { Select } from "../../TrueUI";
import { BaseGridFilterOptionsParameter } from "../BaseGridProperties";
import { useBaseGridFilters } from "../Hooks/useBaseGridFilters";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { usePermissions } from "../../../hooks";
import { useEffect, useState } from "react";

type BaseGridSortFilterProps = {
  options: BaseGridFilterOptionsParameter;
  id: string;
  columnNames?: string[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  selectWidth?: string;
  resetOnManuallyReload?: boolean;
  onChange?: (e: any) => void;
  permissions?: PermissionsEnums[];
};

const BaseGridSortFilter: (properties: BaseGridSortFilterProps) => any = (
  props
) => {
  const [selectedValue, setSelectedValue] = useState(
    props?.defaultValue ?? "asc"
  );

  const hasPermission = usePermissions(props?.permissions ?? []).hasPermission;
  const filterHook = useBaseGridFilters(
    props?.options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: props?.id,
      filterName: SPECIAL_SORT_ORDER,
      defaultValue: props?.defaultValue,
      columnNames: props?.columnNames,
      resetOnManuallyReload: props?.resetOnManuallyReload ?? false,
    },
    "BaseGridDropDownFilter"
  );

  const defaultSortOptions: Partial<SelectOptions>[] = [
    { displayName: "Newest to Oldest", stringValue: "asc" },
    { displayName: "Oldest to Newest", stringValue: "desc" },
  ];

  useEffect(() => {
    const currentFilterInstance = filterHook.getFilterOption();
    const currentlyStoredFilterParameters = JSON.stringify(
      (currentFilterInstance?.columnNames ?? []).sort()
    );
    const updatedFilterParameters = JSON.stringify(
      (props?.columnNames ?? []).sort()
    );
    if (currentlyStoredFilterParameters !== updatedFilterParameters) {
      filterHook.upsertFilterOption({
        filterId: props?.id,
        filterName: SPECIAL_SORT_ORDER,
        defaultValue: selectedValue,
        columnNames: props?.columnNames,
        resetOnManuallyReload: props?.resetOnManuallyReload ?? false,
      });
    }
  }, [props?.columnNames, selectedValue]);

  return (
    <Select
      id={`true-filter-for-table-${props.id}`}
      name={`filter-dropdown-${props.id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={props.labelText ?? "Show:"}
      labelPosition={props.labelPosition ?? "start"}
      options={defaultSortOptions}
      value={selectedValue ?? "asc"}
      type={"tableFilter"}
      inputWidth={props.selectWidth ? props.selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      data-base-grid-filter-column-names={props.columnNames ?? []}
      onChange={(value) => {
        props.onChange?.(value);
        filterHook?.onChange?.(value);
        setSelectedValue(value);
      }}
      disabled={!hasPermission}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
    />
  );
};

export default BaseGridSortFilter;
