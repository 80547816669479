import { FC, memo } from "react";
import BaseGridChildConstructor from "./BaseGridChildConstructor";
import BaseGridRegistrationGate from "./BaseGridRegistrationGate";
import BaseGridBody from "./BaseGridBody";
import BaseGridDataProcessor from "./BaseGridDataProcessor";
import BaseGridSkeleton from "./BaseGridSkeleton";
import BaseGridSortAndFilterListener from "./BaseGridSortAndFilterListener";
import BaseGridEventListener from "./BaseGridEventListener";
import BaseGridBodyHeader from "./BaseGridBodyHeader";
import BaseGridDataExportManager from "./BaseGridDataExportManager";
import BaseGridNotifications from "./BaseGridNotifications";
import BaseGridFilterBarListener from "./BaseGridFilterBarListener";
import BaseGridDynamicFooterComponent from "./BaseGridDynamicFooterComponent";
import BaseGridDynamicHeaderComponent from "./BaseGridDynamicHeaderComponent";

type BaseGridProperties = {
  name: string;
  disableInitialize?: boolean;
};

const BaseGrid: FC<BaseGridProperties> = ({ name, disableInitialize }) => {
  return (
    // Dev Note: BaseGridRegistrationGate injects both the table UIID and Name into it's children nodes for simplficaiton.
    // This only happens at a single level, subsequent children do not get data injection.
    <>
      <BaseGridRegistrationGate
        name={name}
        disableInitialize={disableInitialize ?? false}
      >
        <BaseGridChildConstructor>
          <BaseGridDataProcessor />
          <BaseGridSortAndFilterListener />
          <BaseGridSkeleton />
          <BaseGridNotifications />
          <BaseGridFilterBarListener />
          <BaseGridBodyHeader />
          <BaseGridDynamicHeaderComponent />
          <BaseGridBody />
          <BaseGridDynamicFooterComponent />
          <BaseGridDataExportManager />
          <BaseGridEventListener />
        </BaseGridChildConstructor>
      </BaseGridRegistrationGate>
    </>
  );
};

export default memo(BaseGrid);
