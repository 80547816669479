export const ModalAddClassCodeContentStyles = () => ({
  marginBottom: "10px",
});

export const ClassCodeTableStyles = () => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  [`#AddClassCodeTable .true_ui_base_table_container .true_table_wrapper`]: {
    maxHeight: "207px",
    overflowY: "auto",
  },
});
