import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../TrueUI";
import { useApiPost } from "../../../../../hooks";
import ModalAddClassCodeContent from "./ModalAddClassCodeContent";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import {
  ConfirmationDialogProps,
  ModalAddClassCodeProps,
  defaultDialogConfiguration,
} from "./ModalAddClassCodeUtils";
import { ClassCodeDataDto } from "../../../../../dtos/class-code-data-dto";

const ModalAddClassCode: FC<ModalAddClassCodeProps> = ({
  insuredId,
  payrollReportId,
  openModalAddClassCode,
  setOpenModalAddClassCode,
  setUUID,
}) => {
  const [dialogConfiguration, setDialogConfiguration] =
    useState<ConfirmationDialogProps>(defaultDialogConfiguration);
  const [newClassCodesData, setNewClassCodeData] = useState<ClassCodeDataDto>({
    payrollReportId: payrollReportId,
    insuredId: insuredId,
    classCodeList: [],
  });

  const { responsePost: responseSaveData, dispatchPost: dispatchSaveData } =
    useApiPost<any>(
      `api/PayrollReportDetail/SaveNewClassCodeList`,
      newClassCodesData
    );

  useEffect(() => {
    setOpenModalAddClassCode?.(false);
    setUUID?.(crypto.randomUUID());
  }, [responseSaveData]);

  const onModalSaveEvent = () => {
    if (
      newClassCodesData !== undefined &&
      newClassCodesData?.classCodeList.length > 0
    ) {
      dispatchSaveData();
    } else {
      setOpenModalAddClassCode?.(false);
    }
  };

  const onModalCancelEvent = () => {
    if (
      newClassCodesData !== undefined &&
      newClassCodesData?.classCodeList.length > 0
    ) {
      setDialogConfiguration({
        ...dialogConfiguration,
        descriptionText:
          "There are selected codes, do you want to discard the changes?",
        isOpen: true,
        yesOptionText: "Yes",
        onOptionNoEvent: onDialogNoEvent,
      });
    } else {
      setOpenModalAddClassCode?.(false);
    }
  };

  const onDialogYesEventDiscardChanges = () => {
    setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
    setOpenModalAddClassCode?.(false);
  };

  const onDialogNoEvent = () => {
    setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
  };

  const isShowDialogEmptyTable = () => {
    setDialogConfiguration({
      ...dialogConfiguration,
      descriptionText:
        "All class codes on the policy are included on the payroll report",
      isOpen: true,
      yesOptionText: "Ok",
      onOptionNoEvent: undefined,
    });
  };

  return (
    <>
      <Modal
        id={"add-class-code"}
        title={"Add Class Code"}
        open={openModalAddClassCode}
        saveOverrideLabel={"Ok"}
        showCloseButton={true}
        deleteEvent={undefined}
        showCancelTextButton={true}
        saveEvent={onModalSaveEvent}
        cancelEvent={onModalCancelEvent}
      >
        <ModalAddClassCodeContent
          payrollReportId={payrollReportId}
          newClassCodesData={newClassCodesData}
          setNewClassCodeData={setNewClassCodeData}
          isShowDialogEmptyTable={isShowDialogEmptyTable}
        />
      </Modal>
      <DialogConfirmation
        name={dialogConfiguration.dialogName}
        id={dialogConfiguration.dialogId}
        open={dialogConfiguration.isOpen}
        dialogTitle={dialogConfiguration.titleText}
        dialogDescriptionText={dialogConfiguration.descriptionText}
        optionYesOverrideLabel={dialogConfiguration.yesOptionText}
        onCloseEvent={onDialogNoEvent}
        onOptionNoEvent={dialogConfiguration.onOptionNoEvent}
        onOptionYesEvent={onDialogYesEventDiscardChanges}
      />
    </>
  );
};

export default ModalAddClassCode;
