import { FC, useState } from "react";
import AgencyLocation, { AgencyLocationModalProps } from "./AgencyLocation";
import { Modal } from "../../TrueUI";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type ModalAgencyLocationProps = {
  agencyId: string;
  agencyLocationModalValues: AgencyLocationModalProps | null;
  setAgencyLocationModalValues: (value: AgencyLocationModalProps) => void;
  allLocationNumbers: string[];
};
const ModalAgencyLocation: FC<ModalAgencyLocationProps> = ({
  agencyId,
  allLocationNumbers,
  agencyLocationModalValues,
  setAgencyLocationModalValues,
}) => {
  const { hasPermission } = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
    PermissionsEnums.TRUE_ADMIN,
  ]);
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  return (
    <>
      <Modal
        id="agencyLocationModal"
        title="Agency Locations"
        open={agencyLocationModalValues?.isOpen}
        showCloseButton
        showCancelTextButton
        cancelButtonWithConfirmation
        closeEvent={(e) =>
          setAgencyLocationModalValues({
            ...agencyLocationModalValues,
            isOpen: e,
          })
        }
        saveDisabled={!hasPermission || disableSaveButton}
        saveEvent={() =>
          setAgencyLocationModalValues({
            ...agencyLocationModalValues,
            isSaving: true,
          })
        }
        deleteButtonWithConfirmation
        deleteDisabled={!hasPermission || agencyLocationModalValues?.isNew}
        deleteEvent={() => {
          setAgencyLocationModalValues({
            ...agencyLocationModalValues,
            isDeleting: true,
          });
        }}
      >
        <AgencyLocation
          agencyLocationId={agencyLocationModalValues?.agencyLocationId}
          agencyId={parseInt(agencyId ?? "-1")}
          allLocationNumbers={allLocationNumbers}
          setDisableSaveButton={setDisableSaveButton}
        />
      </Modal>
      <DialogConfirmation
        id="agencyLocationDialogConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this location?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setAgencyLocationModalValues({
            ...agencyLocationModalValues,
            isDeleting: true,
            isOpen: false,
          });
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default ModalAgencyLocation;
