import { FC, useEffect, useState } from "react";
import { usePermissions } from "../../../../../../../hooks";
import Col, {
  colWithNoMarginNorGutter,
} from "../../../../../../TrueUI/Grids/Col";
import { Button } from "../../../../../../TrueUI";
import { PermissionsEnums } from "../../../../../../../dtos/permissions-enums";
import { ButtonMoveContainerProps } from "../FormComponents/MovePaymentTypes";

const ButtonMoveContainer: FC<ButtonMoveContainerProps> = ({
  closeAddPaymentModal,
  paymentFormData,
  setIsVoidedRefundOrMoveModalConfig,
}) => {
  const [paymentBackupData, setPaymentBackupData] = useState<{
    paidAmount: number | null;
    reserveTypeId: number | null;
  }>({ paidAmount: null, reserveTypeId: null });
  const hasPermissionToRefund = usePermissions([
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]);
  useEffect(() => {
    if (
      paymentFormData?.paymentId !== 0 &&
      paymentBackupData?.paidAmount === null &&
      paymentBackupData?.reserveTypeId === null
    ) {
      setPaymentBackupData({
        paidAmount: paymentFormData?.paidAmount ?? null,
        reserveTypeId: paymentFormData?.reserveTypeId ?? null,
      });
    }
  }, [paymentFormData]);

  const onMove = () => {
    closeAddPaymentModal(false);
    setIsVoidedRefundOrMoveModalConfig?.({
      refundPaymentData: null,
      refund: false,
      voided: false,
      reserveTypes: [],
      refreshTable: false,
      paymentId: -1,
      movePayment: true,
      movePaymentData: {
        ...paymentFormData,
        paidAmount: paymentBackupData?.paidAmount ?? 0,
        reserveTypeId: paymentBackupData?.reserveTypeId ?? 0,
      },
    });
  };
  return (
    <>
      {hasPermissionToRefund.hasPermission && (
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 4, xl: 4 }}
          horizontalAlign="flex-start"
        >
          <Button
            tabIndex={24}
            variantStyle="outlined"
            onClick={() => onMove()}
            name="paymentMovePaymentButton"
          >
            Move
          </Button>
        </Col>
      )}
    </>
  );
};
export default ButtonMoveContainer;
