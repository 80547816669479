import { FC } from "react";
import "../BaseGrid.css";
import style from "../Styles/basegridcells.module.css";

type DefaultCellProperties = {
  value: string;
  align: "left" | "center" | "right";
};

const DefaultCell: FC<DefaultCellProperties> = ({ value, align }) => {
  return (
    <div
      style={{ textAlign: align ?? "left" }}
      className={style.default_render_only_cell}
      title={value}
    >
      {value}
    </div>
  );
};

export default DefaultCell;
