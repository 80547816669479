import { FC } from "react";
import { BasePageContainer } from "../../TrueUI";
import AdminReferenceInstanceWrapper from "./AdminReferenceInstanceWrapper";

const AdminReference: FC = () => {
  return (
    <BasePageContainer
      id={"insurance_container"}
      maxWidth={false}
      sxWrapper={{ height: "100%" }}
      sxBody={{ height: "100%" }}
      data
    >
      <AdminReferenceInstanceWrapper />
    </BasePageContainer>
  );
};

export default AdminReference;
