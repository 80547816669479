import { FC } from "react";
import { Col, Collapse, Row } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { PortalSettings } from "./PortalSettings";
import {
  AlignedInputWithLabel,
  onChangeSettingField,
  primaryFontTypeOptions,
  secondaryFontTypeOptions,
} from "./PortalSettingUtils";

const ColorAndFonts: FC<PortalSettings> = ({
  settings,
  setPortalSettings,
  errorDetails,
}) => {
  const commonFunction = (value, target) => {
    onChangeSettingField(settings, target, value, setPortalSettings);
  };

  return (
    <Collapse
      id="color-and-fonts"
      title={"Color and Fonts"}
      content={
        <div style={{ marginRight: "10%" }}>
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 6, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <AlignedInputWithLabel
                label={"Primary Color"}
                inputId={"primary-color"}
                inputType={"colorPicker"}
                inputValue={settings?.primaryColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "primaryColor")
                }
                errorMessage={errorDetails?.primaryColor}
              />

              <AlignedInputWithLabel
                label={"Secondary Color"}
                inputId={"secondary-color"}
                inputType={"colorPicker"}
                inputValue={settings?.secondaryColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "secondaryColor")
                }
                errorMessage={errorDetails?.secondaryColor}
              />
              <AlignedInputWithLabel
                label={"Text Color"}
                inputId={"text-color"}
                inputType={"colorPicker"}
                inputValue={settings?.textColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "textColor")
                }
                errorMessage={errorDetails?.textColor}
              />
              <AlignedInputWithLabel
                label={"Contrast Text Color"}
                inputId={"contrast-text-color"}
                inputType={"colorPicker"}
                inputValue={settings?.contrastTextColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "contrastTextColor")
                }
                errorMessage={errorDetails?.contrastTextColor}
              />
              <AlignedInputWithLabel
                label={"Border Color"}
                inputId={"border-color"}
                inputType={"colorPicker"}
                inputValue={settings?.borderColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "borderColor")
                }
                errorMessage={errorDetails?.borderColor}
              />
              <AlignedInputWithLabel
                label={"Danger Color"}
                inputId={"danger-color"}
                inputType={"colorPicker"}
                inputValue={settings?.dangerColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "dangerColor")
                }
                errorMessage={errorDetails?.dangerColor}
              />
              <AlignedInputWithLabel
                label={"Success Color"}
                inputId={"success-color"}
                inputType={"colorPicker"}
                inputValue={settings?.successColor}
                marginColorPicker="0 19% 0 0"
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "successColor")
                }
                errorMessage={errorDetails?.successColor}
              />
            </Col>
            <Col
              breakpoints={{ sm: 12, md: 6, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf="unset"
            >
              <AlignedInputWithLabel
                label={"Primary Background Color"}
                inputId={"primary-background-color"}
                inputType={"colorPicker"}
                inputValue={settings?.primaryBackgroundColor}
                onChangeRawValue={(value) =>
                  commonFunction(
                    value?.backgroundColor,
                    "primaryBackgroundColor"
                  )
                }
                errorMessage={errorDetails?.colorPicker}
              />
              <AlignedInputWithLabel
                label={"Secondary Background Color"}
                inputId={"secondary-background-color"}
                inputType={"colorPicker"}
                inputValue={settings?.secondaryBackgroundColor}
                onChangeRawValue={(value) =>
                  commonFunction(
                    value?.backgroundColor,
                    "secondaryBackgroundColor"
                  )
                }
                errorMessage={errorDetails?.secondaryBackgroundColor}
              />

              <AlignedInputWithLabel
                label={"Secondary Text Color"}
                inputId={"secondary-text-color"}
                inputType={"colorPicker"}
                inputValue={settings?.secondaryTextColor}
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "secondaryTextColor")
                }
                errorMessage={errorDetails?.secondaryTextColor}
              />
              <AlignedInputWithLabel
                label={"Contrast Background Color"}
                inputId={"contrast-background-color"}
                inputValue={settings?.contrastBackground}
                inputType={"colorPicker"}
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "contrastBackground")
                }
                errorMessage={errorDetails?.contrastBackground}
              />
              <AlignedInputWithLabel
                label={"Input Background Color"}
                inputId={"input-background-color"}
                inputValue={settings?.inputBackgroundColor}
                inputType={"colorPicker"}
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "inputBackgroundColor")
                }
                errorMessage={errorDetails?.inputBackgroundColor}
              />
              <AlignedInputWithLabel
                label={"Warning Color"}
                inputId={"warning-color"}
                inputValue={settings?.warningColor}
                inputType={"colorPicker"}
                onChangeRawValue={(value) =>
                  commonFunction(value?.backgroundColor, "warningColor")
                }
                errorMessage={errorDetails?.warningColor}
              />
            </Col>
          </Row>
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 12 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <div>
                <AlignedInputWithLabel
                  label={"Primary Font Family"}
                  inputId={"primary-font-family"}
                  inputType={"select"}
                  selectOptions={primaryFontTypeOptions as any}
                  inputValue={settings?.primaryFontFamily}
                  onChangeRawValue={(value) =>
                    commonFunction(value, "primaryFontFamily")
                  }
                  errorMessage={errorDetails?.primaryFontFamily}
                />
                <AlignedInputWithLabel
                  label={"Secondary Font Family"}
                  inputId={"secondary-font-family"}
                  inputType={"select"}
                  selectOptions={secondaryFontTypeOptions as any}
                  inputValue={settings?.secondaryFontFamily}
                  onChangeRawValue={(value) =>
                    commonFunction(value, "secondaryFontFamily")
                  }
                  errorMessage={errorDetails?.secondaryFontFamily}
                />
              </div>
            </Col>
          </Row>
        </div>
      }
    />
  );
};
export default ColorAndFonts;
