import { FC, useEffect } from "react";
import { Select } from "../../TrueUI";
import { SelectOptions } from "../../../dtos/select-options";
import { AgencyCommissionTranEnum } from "../../../dtos/agency-commission-tran-enum";
import { DROPDOWN_FILTER } from "../../BaseGrid/BaseGridConstants";
import { BaseGridFilterOptionsParameter } from "../../BaseGrid/BaseGridProperties";
import { useBaseGridFilters } from "../../BaseGrid/Hooks/useBaseGridFilters";
import { useRecoilState, useRecoilValue } from "recoil";
import { AgencyPaidMonthFilter, AgencyTranStatusFilter } from "./CommissionsAtoms";

type CommissionsDateFilterProps = {
  options: BaseGridFilterOptionsParameter;
  dateOptions: Partial<SelectOptions>[];
};

const CommissionsDateFilter: FC<CommissionsDateFilterProps> = ({
  options,
  dateOptions,
}) => {
  const id = "commissionDateFilter";

  const [defOption, setDefOption] = useRecoilState(AgencyPaidMonthFilter);
  
  const selectedStatus = useRecoilValue(AgencyTranStatusFilter);

  const filterHook = useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: id,
      filterName: DROPDOWN_FILTER,
      defaultValue: defOption,
      columnNames: ["FilterDate"],
    },
    "BaseGridDropDownFilter"
  );

  useEffect(() => {
    if (selectedStatus === AgencyCommissionTranEnum.PAID) {
      const firstOption = dateOptions?.[0].displayName ?? "-1";
      filterHook?.onChange?.(defOption ?? firstOption);
    }
    if (selectedStatus !== AgencyCommissionTranEnum.PAID) {
      filterHook?.onChange?.("all");
    }
  }, [selectedStatus]);

  useEffect(() => {
    return () => {
      filterHook?.onChange?.("all");
    };
  }, []);

  return (
    <Select
      id={"commissionDateFilter"}
      name={"commission-date-filter"}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={"Select Month:"}
      labelPosition={"start"}
      options={dateOptions}
      value={defOption ?? "all"}
      type={"tableFilter"}
      data-base-grid-filter-column-names={["FilterDate"]}
      inputWidth={"150px"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        setDefOption(value);
        filterHook?.onChange?.(value);
      }}
    />
  );
};

export default CommissionsDateFilter;
