import { AccountTableDto } from "../../../../../../dtos/account-table-dto";
import { BaseTableDisplayOptions } from "../../../../../../dtos/base-table-display-options";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { ConfigurationNewPaymentDto } from "../../../../../../dtos/configuration-new-payment-dto";
import { NewPaymentDto } from "../../../../../../dtos/new-payment-dto";
import { ProcessorTypeEnum } from "../../../../../../dtos/processor-type-enum";
import { currencyFormat } from "../../../../../../utilities/currencyFunctions";
import { GettingDateWithoutTime } from "../../../../../../utilities/dateFunctions";
import { Col, FontBold, Row } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";

export const defaultPaymentData = (
  insuredId: number,
  defaultBankAccountId?: number
) => {
  return {
    insuredId: insuredId,
    bankAccountId: defaultBankAccountId,
    paymentTypeId: 1,
    paymentDate: GettingDateWithoutTime(new Date()),
    dateReceived: GettingDateWithoutTime(new Date()),
    postmarkDate: GettingDateWithoutTime(new Date()),
    referenceNumber: "",
    billingTransactionDataList: [],
  };
};

export type ModalAddPaymentProps = {
  insuredId: number;
  hasAch: boolean;
  hasCreditCard: boolean;
  showModal: boolean;
  isOpenModal: (close: boolean) => void;
  accountTableData: AccountTableDto;
};

export type ModalAddPaymentContentProps = {
  insuredId: number;
  newPaymentData?: NewPaymentDto;
  setNewPaymentData?: (value: NewPaymentDto) => void;
  paymentFormSelectsData?: ConfigurationNewPaymentDto;
  errorMessages?: any;
  isReadyToRenderTable: boolean;
  errorDetails?: any;
  setBillingTransactionData?: (value: BillingTransactionDataDto[]) => void;
  accountTableData: AccountTableDto;
  hasAch: boolean;
  hasCreditCard: boolean;
};

export type AccountAndBalanceSectionProps = {
  insuredId?: number;
  errorDetails?: any;
  tranAccountOptions?: BaseTableDisplayOptions[];
  setBillingTransactionData?: (value: BillingTransactionDataDto[]) => void;
  processorType?: ProcessorTypeEnum;
  hideToolbar?: boolean;
  accountTableData: AccountTableDto;
};

export const CustomFooterComponent = (rows: any) => {
  const totalValue = rows
    ?.map((row) => row?.Payment ?? 0)
    ?.reduce((x: any, y: any) => {
      return Number(x) + Number(y?.toString()?.replaceAll(",", ""));
    }, 0);
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={50} rowHeight="45px">
      <Col breakpoints={{ md: 44, lg: 44, xl: 44 }} />
      <Col
        horizontalMargin="0px"
        breakpoints={{ md: 5.2, lg: 5.2, xl: 5.2 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(totalValue, false, 2, true)}</FontBold>
      </Col>{" "}
      <Col breakpoints={{ md: 0.8, lg: 0.8, xl: 0.8 }} />
    </Row>
  );
};
