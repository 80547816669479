import { FC, useEffect, useState } from "react";
import { PolicyQuoteInsuredAsProp } from "./PolicyQuoteForm/PolicyQuoteTypes";
import { useApiGet } from "../../../hooks";
import { PolicyHistoryFilterOptionDto } from "../../../dtos/policy-history-filter-option-dto";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import PolicyHistoryTable from "./PolicyHistoryTable";

export type PolicyHistoryFilterProps = {
  lineOfCoverage: string;
  status: string;
  dateSort: string;
};

const PolicyHistoryTableWrapper: FC<PolicyQuoteInsuredAsProp> = ({
  insuredId,
  tabKey,
}) => {
  const [policyFilterOptions, setPolicyFilterOptions] =
    useState<PolicyHistoryFilterOptionDto>();
  const { responseGet, dispatchGet } = useApiGet<PolicyHistoryFilterOptionDto>(
    "api/Policy/GetPolicyHistoryFilterOptions"
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPolicyFilterOptions(responseGet.axiosResponse?.data);
    }
  }, [responseGet]);

  return policyFilterOptions ? (
    <PolicyHistoryTable
      insuredId={insuredId}
      tabKey={tabKey}
      policyFilterOptions={policyFilterOptions}
    />
  ) : (
    <>LOADING...</>
  );
};

export default PolicyHistoryTableWrapper;
