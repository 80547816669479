import { FormGroup } from "@mui/material";
import { FC } from "react";
import { Row, Col, Switch } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { PortalSettings } from "./PortalSettings";
import { fontType, onChangeSettingField } from "./PortalSettingUtils";

const SwitchButtonsFields: FC<PortalSettings> = ({
  settings,
  setPortalSettings,
}) => {
  const commonFunction = (value, target) => {
    onChangeSettingField(settings, target, value, setPortalSettings);
  };

  return (
    <Row {...rowWithNoMarginNorGutter} verticalAlign={"flex-start"}>
      <Col
        breakpoints={{ xs: 12, sm: 12, md: 12 }}
        horizontalGutter="0px"
        horizontalAlign="flex-start"
      >
        <FormGroup>
          <Switch
            label={"Show Documents?"}
            labelFontType={fontType}
            labelPlacement={"start"}
            inputWidth={"100%"}
            isChecked={settings?.showDocuments}
            onChangeIsChecked={(value) =>
              commonFunction(value, "showDocuments")
            }
          />
          <Switch
            label={"Auto Approve Certs?"}
            labelFontType={fontType}
            labelPlacement={"start"}
            isChecked={settings?.autoApproveCerts}
            onChangeIsChecked={(value) =>
              commonFunction(value, "autoApproveCerts")
            }
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
export default SwitchButtonsFields;
