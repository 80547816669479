import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { BaseTable2, Button, TitleBold } from "../../../../../TrueUI";
import { Box } from "@mui/material";
import { VoidedOrRefundModalOpenProps } from "./RefundComponents/TypesModalRefundPayment";

type PaymentStatusHistoryProps = {
  paymentId: string;
  closePaymentModal: (isOpen: boolean, refreshTable?: boolean) => void;
  setIsVoidedRefundOrMoveModalConfig: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
};
const PaymentStatusHistory = ({
  paymentId,
  setIsVoidedRefundOrMoveModalConfig,
  closePaymentModal,
}: PaymentStatusHistoryProps) => {
  const name = "PaymentStatusHistoryTable";
  const getURL = `api/PaymentStatusHistory/GetHistoryByPaymentId?paymentId=${paymentId}`;

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      paginate: false,
      tableStyle: {
        height: "400px",
      },
    },
  };

  useBaseTable(tableConfiguration);

  const handleVoid = () => {
    closePaymentModal(false);
    setIsVoidedRefundOrMoveModalConfig({
      refund: false,
      voided: true,
      refundPaymentData: null,
      reserveTypes: [],
      refreshTable: false,
      paymentId: -1,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "640px" }}>
      <Box sx={{ ml: "20px", mt: "15px", mb: "10px" }}>
        <TitleBold primaryColor>Status History</TitleBold>
      </Box>
      <Box
        id="payment-status-history-table-wrapper"
        sx={{ "#PaymentStatusHistoryTable": { height: "auto" } }}
      >
        <BaseTable2 name={name} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ ml: "10px", mb: "10px", mt: "auto" }}
      >
        <Button
          variantStyle="outlined"
          onClick={handleVoid}
          permissions={[13, 14, 15]}
        >
          VOID
        </Button>
        <Button onClick={() => closePaymentModal(false, false)}>CANCEL</Button>
      </Box>
    </div>
  );
};

export default PaymentStatusHistory;
