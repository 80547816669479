import {
  Button,
  Col,
  Divider,
  FontMedium,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { RepresentationEventDto } from "../../../../../../dtos/representation-event-dto";
import { EventConfigurationDto } from "../../../../../../dtos/event-configuration-dto";
import { useEffect, useState } from "react";
import { FormStateProps } from "./typesAndConstants";
import { useApiPost } from "../../../../../../hooks";
import { TasksConfigurationDto } from "../../../../../../dtos/tasks-configuration-dto";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { TriggerComponentUpdateAtom } from "../../../../InsuredAtoms";
import {
  DeleteDialogConfiguration,
  defaultDeleteDialogConfiguration,
} from "./LitigationFormComponents/ClaimLitigationUtil";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";

type EventFormProps = {
  caseNumber?: string;
  representationId: number;
  eventData: RepresentationEventDto | Partial<RepresentationEventDto>;
  eventConfiguration: EventConfigurationDto | null;
  hasClaimsManagementPermissions: boolean;
  handleReset: () => void;
};

const ClaimLitigationEventForm = ({
  eventData,
  representationId,
  eventConfiguration,
  caseNumber,
  handleReset,
  hasClaimsManagementPermissions,
}: EventFormProps) => {
  const [formState, setFormState] = useState<Partial<FormStateProps>>({
    mode: "readonly",
  });
  const [_eventData, _setEventData] = useState<
    RepresentationEventDto | Partial<RepresentationEventDto>
  >(eventData);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const { setComponentTriggers } = useAtomFamily(
    TriggerComponentUpdateAtom("representationEventsTable")
  );

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<TasksConfigurationDto>(
      `api/RepresentationEvents/SaveRepresentationEvent`,
      _eventData
    );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DeleteDialogConfiguration>(defaultDeleteDialogConfiguration);

  const saveAction = () => {
    dispatchPost();
    setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
  };

  useEffect(() => {
    setErrorDetails(null);
    _setEventData({ ...eventData, representationId });
    if (eventData?.representationEventId === 0) {
      setFormState({ ...formState, mode: "add" });
    } else {
      setFormState({ ...formState, mode: "readonly" });
    }
  }, [eventData]);

  useEffect(() => {
    if (
      isAPITotallyCompleteNoContentResponse(responsePost) &&
      validatorErrorResponse === null
    ) {
      setFormState({ ...formState, mode: "readonly" });
      setComponentTriggers(["representationEventsTable"]);
      if (_eventData.representationEventId !== 0) {
        handleReset();
      }
    }
  }, [responsePost]);

  const cancelAction = () => {
    if (formState?.mode === "edit") {
      setFormState({ ...formState, mode: "readonly" });
      _setEventData(eventData);
      setErrorDetails(null);
      return;
    }
    handleReset();
  };

  useEffect(() => {
    setErrorDetails(validatorErrorResponse?.errorDetails ?? null);
  }, [validatorErrorResponse]);

  const yesDeleteConfirmation = () => {
    const deletedId =
      eventConfiguration?.eventStatus.find((c) => c.displayName === "Deleted")
        ?.intValue || 1;
    _setEventData({ ..._eventData, eventStatus: deletedId });
    saveAction();
  };

  return (
    <>
      <FontMedium textAlign="start" name="litigationEventFormTitle">
        {_eventData.representationEventId &&
        _eventData.representationEventId > 0
          ? `EVENT DETAILS FOR CASE NUMBER ${caseNumber}`
          : "ADD NEW CASE EVENT"}
      </FontMedium>
      <Divider height={15} />
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 6, xl: 6 }}
        >
          <Select
            {...selectConfiguration("id-type", "eventEventType", "Event Type")}
            options={eventConfiguration?.eventType ?? []}
            value={_eventData?.eventType}
            errorMessage={errorDetails?.eventType}
            onChange={(value) => {
              _setEventData({ ..._eventData, eventType: value });
            }}
            readOnly={formState?.mode === "readonly"}
            variant={"filled"}
            labelFontType={"BOLD_CAPTION"}
            firstOptionAsDefault={false}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 6, xl: 6 }}
        >
          <Select
            {...selectConfiguration(
              "id-status",
              "litigationEventStatus",
              "Status"
            )}
            options={eventConfiguration?.eventStatus ?? []}
            value={_eventData?.eventStatus ?? 1}
            errorMessage={errorDetails?.eventStatus}
            onChange={(value) => {
              _setEventData({ ..._eventData, eventStatus: value });
            }}
            readOnly={formState?.mode === "readonly"}
            variant={"filled"}
            labelFontType={"BOLD_CAPTION"}
            firstOptionAsDefault={false}
          />
        </Col>
      </Row>

      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 6, xl: 6 }}
        >
          <InputDate
            id="id-event-date"
            name="litigationEventDate"
            label="Date"
            value={_eventData?.eventDate}
            onChangeRawValue={(value) =>
              _setEventData({ ..._eventData, eventDate: value })
            }
            readOnly={formState?.mode === "readonly"}
            variant={"filled"}
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.eventDate}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 6, xl: 6 }}
        >
          <Input
            {...inputConfiguration(
              "id-result",
              "litigationEventResult",
              "Result",
              50
            )}
            value={_eventData?.eventResult ?? ""}
            onChangeRawValue={(value) =>
              _setEventData({ ..._eventData, eventResult: value })
            }
            errorMessage={errorDetails?.eventResult}
            variant={"filled"}
            labelFontType={"BOLD_CAPTION"}
            readOnly={formState?.mode === "readonly"}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 10, xl: 10 }}
          horizontalAlign="flex-start"
        >
          {formState?.mode === "readonly" && (
            <Button
              name="litigationEventEditButton"
              variantStyle="outlined"
              isDisabled={!hasClaimsManagementPermissions}
              onClick={() => {
                setFormState({ ...formState, mode: "edit" });
              }}
            >
              EDIT
            </Button>
          )}
          {formState?.mode === "edit" && (
            <Button
              variantStyle="outlined"
              onClick={() => {
                setDialogConfiguration({
                  ...dialogConfiguration,
                  isOpen: true,
                });
              }}
              name="litigationEventDeleteButton"
            >
              DELETE
            </Button>
          )}
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalGutter="15px"
          horizontalAlign="flex-end"
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
        >
          {(formState?.mode === "edit" || formState?.mode === "add") && (
            <Button onClick={saveAction} name="litigationEventSaveButton">
              SAVE
            </Button>
          )}
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 2, xl: 1 }}
        >
          {(formState?.mode === "edit" || formState?.mode === "add") && (
            <Button
              variantStyle="outlined"
              onClick={cancelAction}
              name="litigationEventCancelButton"
            >
              CANCEL
            </Button>
          )}
        </Col>
      </Row>
      <DialogConfirmation
        name={dialogConfiguration.dialogName}
        id={dialogConfiguration.dialogId}
        open={dialogConfiguration.isOpen}
        dialogDescriptionText={dialogConfiguration.descriptionText}
        onOptionNoEvent={() => {
          setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
        }}
        onOptionYesEvent={yesDeleteConfirmation}
      />
    </>
  );
};

export default ClaimLitigationEventForm;
