import { FC } from "react";
import { Input } from "../..";
import { BaseTableAlternativeDisplayValues } from "../../../../dtos/base-table-alternative-display-values";
import { useFileRequestInstance } from "../../../../hooks/useFileStorage";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTableDownloadLinkCellProperties2
  extends BaseTableCellSelectProperties {
  alternateDisplayValues: BaseTableAlternativeDisplayValues[];
}

const DownloadLinkCell2: FC<BaseTableDownloadLinkCellProperties2> = ({
  cellKey,
  value,
  alternateDisplayValues,
  column,
  // rowIndex,
}) => {
  const alternatives = alternateDisplayValues.find((x) => x.guid === value);
  const displayValue = alternatives?.displayValuesOrFieldNames[0] ?? "";
  const fileName =
    alternatives?.displayValuesOrFieldNames[1] ??
    alternatives?.displayValuesOrFieldNames[0] ??
    "";

  const { downloadFile } = useFileRequestInstance();

  return (
    <div className="linkCell-container" onClick={() => downloadFile(fileName)}>
      <Input
        id={`id-${column.fieldName}-${cellKey}`}
        name={`name-${column.fieldName}-${cellKey}`}
        className="inputLinkCell"
        key={cellKey}
        type={"text"}
        value={displayValue}
        readOnly
      />
    </div>
  );
};

export default DownloadLinkCell2;
