import { BaseTableColumn } from "../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getDateObject,
  isDateAfterDate,
} from "../../../utilities/dateFunctions";
import {
  BaseTableChildrenData,
  ColumnOptionsProperties,
  ConditionForCellBaseAPIOptions,
  ConditionForCellResponse,
  ConditionForCellResponseType,
  HydratedData,
  IndicationType,
  MultiSelectionType,
  OptionsStateConditionProperties,
  RowKey,
  TableColumns,
  TableData,
  TableDataItem,
} from "./BaseTable2/TableProperties";
import { OptionCellType } from "./SharedTableCells/OptionsCell2";
import { InternalTableColumns } from "./InternalTableColumns";
import {
  DELETED_COLUMN_FIELD_NAME,
  FILTER_BY_RENDER_ROW_KEY_WORD,
  INDICATION_COLUMN_FIELD_NAME,
  MULTI_SELECTION_COLUMN_FIELD_NAME,
  NO_ROW_KEY_ERROR,
  OPTION_COLUMN_FIELD_NAME,
  ROW_KEY_COLUMN_FIELD_NAME,
  SEARCH_FILTER,
  MULTI_DROPDOWN_FILTER,
  SPECIAL_SORT_ORDER,
} from "./TableConstants";
import { BaseTableAlternativeDisplayValues } from "../../../dtos/base-table-alternative-display-values";
import {
  dateDataTypeEnums,
  numberDataTypeEnums,
} from "../../../utilities/staticArraysByEnumTypes";
import { GridDataRow } from "../../BaseGrid/BaseGridProperties";
import { BaseTableMetaData } from "../../../dtos/base-table-meta-data";

export const debugShowInternalColumns = (columns: TableColumns) => {
  const x = columns.map((c) => {
    if (c._isInternal === true) {
      return { ...c, isHidden: false };
    }
    return c;
  });

  return x;
};

export const filterComputesByName = (
  computeName: string,
  fieldName: string,
  columnOptions: ColumnOptionsProperties<any>[]
) =>
  columnOptions
    .map((column) => {
      if (
        column.fieldName === fieldName &&
        column.computeOnChange?.[computeName] !== undefined &&
        column.computeOnChange?.[computeName]?.length > 0
      ) {
        return {
          fn: column.computeOnChange?.[computeName][0] ?? null,
          fieldName: column.fieldName ?? null,
        };
      }
      return null;
    })
    .flat()
    .filter((c) => c !== null);

export const filterComputesOnInitByName = (
  computeName: string,
  fieldName: string,
  columnOptions: ColumnOptionsProperties<any>[]
) =>
  columnOptions
    ?.map((column) => {
      if (
        column.fieldName === fieldName &&
        column.computeOnInit?.[computeName] !== undefined &&
        column.computeOnInit?.[computeName]?.length > 0
      ) {
        return {
          fn: column.computeOnInit?.[computeName][0] ?? null,
          fieldName: column.fieldName ?? null,
        };
      }
      return null;
    })
    .flat()
    .filter((c) => c !== null);

export const getFilteredComputesByComputeType = (
  computeName: string,
  fieldName: string,
  columnOptions: ColumnOptionsProperties<any>[],
  computeType: "onInit" | "onChange"
) => {
  return computeType === "onInit"
    ? filterComputesOnInitByName(computeName, fieldName, columnOptions)
    : filterComputesByName(computeName, fieldName, columnOptions);
};

export const getSafeRowKey = (row: TableDataItem): string =>
  row[InternalTableColumns._rowKeyColumn._columnIndex] ?? NO_ROW_KEY_ERROR;

export const appendCoreInternalColumns = (
  columns: BaseTableColumn[],
  isOptionsColumnHidden: boolean,
  isMultiSelectColumnHidden: boolean,
  isIndicationColumnHidden: boolean
) => {
  InternalTableColumns._multiSelectionColumn.isHidden =
    isMultiSelectColumnHidden ?? true;
  InternalTableColumns._optionsColumn.isHidden = isOptionsColumnHidden ?? true;
  InternalTableColumns._indicationColumn.isHidden =
    isIndicationColumnHidden ?? true;

  const newColumnsArray = [...columns];

  // TODO - use map instead of splice. Leaving below logic in order to get this fix out for a PR.

  newColumnsArray.splice(
    InternalTableColumns._rowKeyColumn._columnIndex,
    0,
    InternalTableColumns._rowKeyColumn
  );
  newColumnsArray.splice(
    InternalTableColumns._multiSelectionColumn._columnIndex,
    0,
    InternalTableColumns._multiSelectionColumn
  );
  newColumnsArray.splice(
    InternalTableColumns._indicationColumn._columnIndex,
    0,
    InternalTableColumns._indicationColumn
  );
  newColumnsArray.splice(
    InternalTableColumns._optionsColumn._columnIndex,
    0,
    InternalTableColumns._optionsColumn
  );
  newColumnsArray.splice(
    InternalTableColumns._isRowDeletedColumn._columnIndex,
    0,
    InternalTableColumns._isRowDeletedColumn
  );
  newColumnsArray.splice(
    InternalTableColumns._isRowNewColumn._columnIndex,
    0,
    InternalTableColumns._isRowNewColumn
  );
  return newColumnsArray;
};

export type OverridableInternalColumnValueProps = {
  indicationType?: IndicationType | null;
  optionType?: OptionCellType | null;
  multiSelectionType?: MultiSelectionType | null;
};

export const updateInternalColumnValuesByConfiguration = (
  columns: BaseTableColumn[],
  data: string[][],
  overridableInternalColumnValues: OverridableInternalColumnValueProps,
  optionsStateCondition?: (e: OptionsStateConditionProperties) => any
) => {
  const updatedData = data.map((row) => {
    const optionRowValue =
      row[InternalTableColumns._optionsColumn._columnIndex] ?? "none";
    const hydratedData = hydrateDataSingleExternalColumnsOnly(columns, row);

    const optionConditionValue = optionsStateCondition?.({
      hydratedRow: hydratedData,
      currentOptionValue: optionRowValue as OptionCellType,
      triggerState: "on_init",
    });

    return row.map((item, index) => {
      if (InternalTableColumns._indicationColumn._columnIndex === index) {
        return overridableInternalColumnValues.indicationType ?? "none";
      }
      if (InternalTableColumns._multiSelectionColumn._columnIndex === index) {
        return overridableInternalColumnValues.multiSelectionType ?? "none";
        //elara check here
      }
      if (optionConditionValue !== undefined) {
        if (InternalTableColumns._optionsColumn._columnIndex === index) {
          return optionConditionValue;
        }
        return item;
      } else {
        if (InternalTableColumns._optionsColumn._columnIndex === index) {
          return overridableInternalColumnValues.optionType ?? "none";
        }
        return item;
      }
    });
  });

  return updatedData;
};

export const addInternalDataValuesToNewRow = (
  data?: string[] | null,
  rowKeyOverride?: string | null
): string[] => {
  if (data === undefined) {
    return [];
  }

  // TODO - add additional logic to sort this array to use the _columnIndex property before merging with the real data to ensure order integrity.
  const defaultInternalColumns = [
    InternalTableColumns._rowKeyColumn,
    InternalTableColumns._indicationColumn,
    InternalTableColumns._optionsColumn,
    InternalTableColumns._isRowDeletedColumn,
    InternalTableColumns._isRowNewColumn,
    InternalTableColumns._multiSelectionColumn,
  ];

  const defaultValues = defaultInternalColumns.map((c) => {
    if (c.fieldName === ROW_KEY_COLUMN_FIELD_NAME) {
      return rowKeyOverride ?? crypto.randomUUID();
    }

    return c.defaultValue ?? "";
  });

  const mergedValues = [...(defaultValues ?? []), ...(data ?? [])];

  return mergedValues;
};

export const createBaseTableColumn = (
  displayName: string,
  fieldName: string,
  inputType: BaseTableInputType,
  dataType: DataTypeEnums,
  defaultValue: string | null,
  isHidden: boolean = false,
  displayOptions: BaseTableDisplayOptions[] | null = null
): BaseTableColumn => {
  return {
    // internal
    _isInternal: false,
    _columnIndex: 0,
    _visibleColumnIndex: 0,
    _headerSortOrder: 0,

    // external
    displayName: displayName,
    fieldName: fieldName,
    cellVariant: CellVariantEnums.STANDARD,
    type: inputType,
    displayOptions: displayOptions,
    defaultValue: defaultValue,
    defaultValueType: dataType,
    isHidden: isHidden,
    maxLength: 50,
    isFilterable: true,
    isSortable: true,
    alternateDisplayValues: null,
  } as BaseTableColumn;
};

export const addInternalDataValuesToNewRows = (data: string[][]) => {
  // data.map((row) => [INDICATION_DEFAULT_VALUE].concat(...[row]));
  // data.map((row) => row.concat(...[optionType ?? OPTION_DEFAULT_VALUE]));
  // data.map((row) => row.concat(...[DELETED_DEFAULT_VALUE]));
  // data.map((row) => row.concat(...[IS_NEW_ROW_DEFAULT_VALUE]));
  // data.map((row) => row.concat(...[crypto.randomUUID()]));

  const udpatedData =
    data.map((item) => {
      return addInternalDataValuesToNewRow(item);
    }) ?? [];
  return udpatedData;
};

export const updateRow = (
  rowKey: string,
  data: string[][],
  newData: string[]
) => {
  const updatedTableData = data.map((row) => {
    if (getSafeRowKey(row) === rowKey) {
      return newData;
    }
    return row;
  });
  return updatedTableData;
};

export const deleteRow = (deletedRow: any, actualDeletedData) => {
  const isDeletedRow = actualDeletedData.filter((row) => {
    return getSafeRowKey(row) === getSafeRowKey(deletedRow);
  });

  if (isDeletedRow.length > 0) {
    return actualDeletedData.filter(
      (row) => getSafeRowKey(row) !== getSafeRowKey(deletedRow)
    );
  }
  return [...actualDeletedData, deletedRow];
};

export const getDataIndexByColumnName = (
  columns: BaseTableColumn[],
  fieldName: string
) => columns.find((c) => c.fieldName === fieldName)?._columnIndex ?? -1;

export const getDataIndexByColumnNameWithoutExternal = (
  columns: BaseTableColumn[],
  fieldName: string
) => columns.findIndex((c) => c.fieldName === fieldName) ?? -1;

export const getRowByRowKey = (rowKey: RowKey, data: string[][]): string[] => {
  return data.find((r) => getSafeRowKey(r) === rowKey) ?? [];
};

export const updateColumnHeaderSortOrder = (columns: TableColumns) => {
  const indexController = { index: 0 }; // cheat to by pass future linting rules
  const updatedExternalColumns = columns.map((c) => {
    if (c._isInternal !== true) {
      indexController.index++;
      return { ...c, _headerSortOrder: indexController.index };
    }
    return c;
  });
  const updatedInternalColumns = [...updatedExternalColumns].map((c) => {
    if (c.fieldName === MULTI_SELECTION_COLUMN_FIELD_NAME) {
      return { ...c, _headerSortOrder: 0 };
    }
    if (c.fieldName === INDICATION_COLUMN_FIELD_NAME) {
      return { ...c, _headerSortOrder: 1 };
    }
    if (c.fieldName === OPTION_COLUMN_FIELD_NAME) {
      return { ...c, _headerSortOrder: columns.length - 1 };
    }
    return c;
  });

  return updatedInternalColumns;
};

export const updateColumnPropByName = (
  overrideArray: { columnFieldName: string; propName: string; value: any }[],
  columns: BaseTableColumn[]
): BaseTableColumn[] => {
  const updatedColumns = (columns ?? []).map((col) => {
    const mergedOverrides = (overrideArray ?? []).reduce(
      (prevOverride, override) => ({
        ...prevOverride,
        fieldName: override.columnFieldName,
        [override.propName]: override.value,
      }),
      {}
    );
    if (col.fieldName === mergedOverrides["fieldName"]) {
      return { ...col, ...mergedOverrides };
    }
    return col;
  });
  return updatedColumns;
};

export const hydrateDataSingle = <T = any>(
  columns: BaseTableColumn[],
  data: string[]
): T | null => {
  return (
    (columns ?? []).reduce(
      (prevCol, currColumn, i) => ({
        ...prevCol,
        // [toCamelCase(currColumn.fieldName)]: data[currColumn._columnIndex],
        [currColumn.fieldName]: data?.[i] ?? "",
      }),
      {} as T
    ) ?? null
  );
};

export const hydrateDataSingleExternalColumnsOnly = <T = any>(
  columns: BaseTableColumn[],
  row: string[]
): T | null => {
  const externalColumns = getExternalColumnsOnly(columns);
  const externalData = getExternalDataOnly(externalColumns, row);
  return hydrateDataSingle(externalColumns, externalData);
};

export const hydrateAndCastDataSingle = <T = any>(
  columns: BaseTableColumn[],
  data: string[]
): T | null => {
  const castedData = castRowToType(columns, data);

  const result =
    (columns ?? []).reduce(
      (prevCol, currColumn) => ({
        ...prevCol,
        // [toCamelCase(currColumn.fieldName)]: data[currColumn._columnIndex],
        [currColumn.fieldName]: castedData[currColumn._columnIndex],
      }),
      {} as T
    ) ?? null;

  return result;
};

const castInternalColumns = (columnFieldName, row, prevCol, columnIndex) => {
  if (columnFieldName === DELETED_COLUMN_FIELD_NAME) {
    return {
      ...prevCol,
      isBaseTableRowDeleted: row[columnIndex] ?? "",
    };
  }
  if (columnFieldName === ROW_KEY_COLUMN_FIELD_NAME) {
    return {
      ...prevCol,
      baseTableRowKey: row[columnIndex] ?? "",
    };
  }
  return {
    ...prevCol,
    [columnFieldName]: row[columnIndex],
  };
};

export const hydrateData2 = (
  columns: BaseTableColumn[],
  data: string[][]
): any[] => {
  var zz = data.map((row) => {
    return (columns ?? []).reduce(
      (prevCol, currColumn, i) =>
        castInternalColumns(currColumn.fieldName, row, prevCol, i),
      {}
    );
  });

  //   return {
  //     ...hydratedRow,
  //     [ROW_KEY_COLUMN_FIELD_NAME]: getSafeRowKey(row),
  //   };
  // });
  return zz;
};

export const getExternalColumnsOnly = (
  columns: BaseTableColumn[]
): BaseTableColumn[] => columns.filter((c) => c._isInternal !== true);

export const getRequestedInternalColumns = (
  columns: BaseTableColumn[],
  requestedColumns: string[]
): BaseTableColumn[] =>
  columns.filter(
    (c) => c._isInternal !== true || requestedColumns.includes(c.fieldName)
  );

export const getInternalColumnsOnly = (
  columns: BaseTableColumn[]
): BaseTableColumn[] => columns.filter((c) => c._isInternal === true);

export const getInternalDataOnly = (
  columns: TableColumns,
  row: TableDataItem
): string[] => {
  const internalColumnIndex = getInternalColumnsOnly(columns).map(
    (c) => c._columnIndex
  );
  const result = row.filter((_v, i) => {
    return internalColumnIndex.includes(i);
  });
  return result;
};

export const hydrateDataExternalColumnsOnly = (
  columns: BaseTableColumn[],
  data: string[][]
): any[] => {
  const externalColumnsOnly = getExternalColumnsOnly(columns);

  const x = hydrateData2(externalColumnsOnly, data);

  return x;
};

export const hydrateDataWithRequestedInternalColumns = (
  columns: BaseTableColumn[],
  requestedColumns: string[],
  data: string[][]
): any[] => {
  const requestedColumnsOnly = getRequestedInternalColumns(
    columns,
    requestedColumns
  );

  const x = hydrateData2(requestedColumnsOnly, data);

  return x;
};

export const findRowByKey = (key: string, data: string[][]): string[] =>
  data.find((d) => getSafeRowKey(d) === key) ?? [];

export const findRowIndexByKey = (key: string, data: string[][]): number =>
  data.findIndex((d) => getSafeRowKey(d) === key) ?? -1;

export const getColumnDataByColumnFieldName = (
  columns: BaseTableColumn[],
  data: string[][],
  columnFieldName: string
) => {
  const columnIndex = getDataIndexByColumnName(columns, columnFieldName);
  const columnDataValues = data.map((row) => {
    return { rowKey: getSafeRowKey(row), value: row[columnIndex] ?? "" };
  });
  return columnDataValues;
};

export const fillMissingNewRowData = (
  columns: BaseTableColumn[],
  newDataRow: string[]
): string[] | null => {
  const externalColumnsOnly = getExternalColumnsOnly(columns);

  // if (newDataRow === undefined) {
  //   return null;
  // }
  return externalColumnsOnly.map((c, i) => {
    if (newDataRow[i] === undefined) {
      return c.defaultValue?.toString() ?? "";
    }

    return newDataRow[i];
  });
};

export const add = (
  columns: BaseTableColumn[],
  data: string[][],
  changedData: string[][],
  newDataRow: string[]
  // rowKeyOverride?: string
) => {
  const filledNewRowMissingData = fillMissingNewRowData(columns, newDataRow);
  const populatedNewDataRow = addInternalDataValuesToNewRow(
    filledNewRowMissingData,
    crypto.randomUUID()
  );

  const newDataItem =
    populatedNewDataRow ?? createNewDataItemWithDefaults(columns ?? []);

  const updatedTableData = addDataItemToTopOfData(newDataItem, data ?? []);
  const updatedTableChangedData = addDataItemToTopOfData(
    newDataItem,
    changedData ?? []
  );
  const newRowKey = getSafeRowKey(newDataItem);

  return { newRowKey, updatedTableData, updatedTableChangedData };
};

export const addMultiple = (
  columns: BaseTableColumn[],
  data: string[][],
  changedData: string[][],
  newDataRows: string[][]
) => {
  const newDataItems = newDataRows.map((newDataRow) => {
    const filledNewRowMissingData = fillMissingNewRowData(columns, newDataRow);
    const populatedNewDataRow = addInternalDataValuesToNewRow(
      filledNewRowMissingData,
      crypto.randomUUID()
    );

    return populatedNewDataRow ?? createNewDataItemWithDefaults(columns ?? []);
  });

  const updatedTableChangedData = addMultiDataItemToTopOfData(
    newDataItems,
    changedData ?? []
  );

  const updatedTableData = addMultiDataItemToTopOfData(
    newDataItems,
    data ?? []
  );

  const rowKeys = getCurrentlySelectedRowKeys(newDataRows);

  return { rowKeys, updatedTableData, updatedTableChangedData };
};

export const getDataWithRequestedColumns = (
  columns: BaseTableColumn[],
  requestedColumns: string[],
  data: string[]
) => {
  const requestedColumnsOnly = getRequestedInternalColumns(
    columns,
    requestedColumns
  );
  return requestedColumnsOnly.map((c) => data[c._columnIndex] ?? "");
};

export const getExternalDataOnly = (
  columns: BaseTableColumn[],
  data: string[]
) => {
  const externalColumnsOnly = getExternalColumnsOnly(columns);
  return externalColumnsOnly.map((c) => data[c._columnIndex] ?? "");
};

export const castToDataType = (
  columns: BaseTableColumn[],
  data: string[][]
): any[][] =>
  data.map((row) => {
    // TODO - we need to also check if the values are of the appropriate type to cast before casting otherwise the casts will fail.
    return castRowToType(columns, row);
  }) as any[][];

export const castToRequestedInternalColumnsDataType = (
  columns: BaseTableColumn[],
  requestedColumns: string[],
  data: string[][]
): any[][] =>
  data.map((row) => {
    const requestedColumnsOnly = getRequestedInternalColumns(
      columns,
      requestedColumns
    );
    const dataWithRequestedColumns = getDataWithRequestedColumns(
      columns,
      requestedColumns,
      row
    );
    return castRowToType(requestedColumnsOnly, dataWithRequestedColumns);
  }) as any[][];

export const castToExternalColumnsDataTypeOnly = (
  columns: BaseTableColumn[],
  data: string[][]
): any[][] =>
  data.map((row) => {
    const externalColumnsOnly = getExternalColumnsOnly(columns);
    const externalDataOnly = getExternalDataOnly(columns, row);
    return castRowToType(externalColumnsOnly, externalDataOnly);
  }) as any[][];

export const castRowToType = (
  columns: BaseTableColumn[],
  row: string[]
): any[] => {
  return row.map((value, index) => {
    if (value === null) return value;
    if (columns[index] === undefined) return "";

    switch (columns[index].defaultValueType ?? null) {
      case DataTypeEnums.INT:
      case DataTypeEnums.NULLABLE_INT:
      case DataTypeEnums.DECIMAL:
      case DataTypeEnums.NULLABLE_DECIMAL:
        return value === "" ? null : parseInt(value) ?? null;
      case DataTypeEnums.DATETIME:
      case DataTypeEnums.NULLABLE_DATETIME:
      case DataTypeEnums.DATETIME2:
        return value === "" ? null : getDateObject(value) ?? null;
      case DataTypeEnums.NULLABLE_DATETIME2:
        return value === "" ? null : getDateObject(value) ?? null;
      case DataTypeEnums.BOOLEAN:
      case DataTypeEnums.NULLABLE_BOOLEAN:
        return value === "" ? null : value.toLowerCase() === "true";
      default:
        return value === "" ? null : value ?? "";
    }
  });
};

// export const addIndicationColumnValues = (data: string[][]) =>
//   data.map((row) => [INDICATION_DEFAULT_VALUE].concat(...[row]));

// export const addOptionColumnValues = (
//   data: string[][],
//   optionType?: OptionCellType
// ) => data.map((row) => row.concat(...[optionType ?? OPTION_DEFAULT_VALUE]));

// export const addDeletedColumnValue = (data: string[][]) =>
//   data.map((row) => row.concat(...[DELETED_DEFAULT_VALUE]));

// export const addIsNewRowColumnValue = (data: string[][]) =>
//   data.map((row) => row.concat(...[IS_NEW_ROW_DEFAULT_VALUE]));

// export const addRowKeys = (data: string[][]): string[][] =>
//   data.map((row) => row.concat(...[crypto.randomUUID()]));

export const addColumnIndexByArrayOrder = (
  columns: BaseTableColumn[]
): BaseTableColumn[] =>
  columns.map((column, index) => {
    return {
      ...column,
      _columnIndex: index,
    };
  });

export const createNewDataItemWithDefaults = (
  columns: BaseTableColumn[]
): string[] => {
  return columns.map((m) => {
    if (m.fieldName === ROW_KEY_COLUMN_FIELD_NAME && m.defaultValue === null) {
      return crypto.randomUUID();
    }
    if (m.defaultValue !== undefined && m.defaultValue !== null) {
      return m.defaultValue.toString();
    }
    return "";
  });
};

export const getDefaultRowValues = (columns: BaseTableColumn[]): string[] => {
  return columns
    .filter((c) => c.fieldName !== ROW_KEY_COLUMN_FIELD_NAME)
    .map((m) => m.defaultValue ?? "");
};

export const getKeysOfDefaultRows = (
  defaultRowData: string[],
  data: string[][]
): string[] => {
  return data
    .filter(
      (u) =>
        defaultRowData.filter((e, i) => u[i] === e).length ===
        defaultRowData.length
    )
    .map((row) => getSafeRowKey(row));
};

export const removeDefaultRowsByKey = (defaultRows, data) => {
  return data.filter((r) => !defaultRows.includes(r[r.length - 1]));
};

export const createNewDataRowItemWithDefaults = (
  columns: BaseTableColumn[],
  filterIdRow: string,
  filterField: string
): string[] => {
  const key = crypto.randomUUID();
  return columns.map((c) =>
    c?.fieldName === filterField
      ? filterIdRow
      : c?.displayName === ROW_KEY_COLUMN_FIELD_NAME
      ? key
      : c?.defaultValue ?? ""
  );
};

export const groupBy = (array, key) => {
  //To keep the order of the data
  const dataOrder = new Map();
  array.reduce((result, currentValue) => {
    const keyValue = currentValue[key].trim();
    (result[keyValue] = result[keyValue] || []).push(currentValue);
    dataOrder.set(keyValue, result[keyValue]);
    return result;
  }, {});
  return dataOrder;
};

export const orderRowsByGroup = (groupedData) => {
  return Array.from(groupedData.entries()) as string[][];
};

export const addDataItemToTopOfData = (
  dataItem: string[],
  data: string[][]
): string[][] => [dataItem, ...data];

export const addMultiDataItemToTopOfData = (
  dataItem: string[][],
  data: string[][]
): string[][] => [...dataItem, ...data];

export const getPaginatedData = (
  data: string[][],
  rowsPerPage: number,
  page: number
) => {
  const initialData = rowsPerPage * page;
  const endData = initialData + rowsPerPage;
  return data.slice(initialData, endData);
};

export const dehydrateData = (row: any): any[] => {
  const objectPropertyNames = Object.getOwnPropertyNames(row);

  return !objectPropertyNames.includes("length") // when the row is an empty array ([]) the objectPropertyNames is equal to ["length"] which is wrong to map.
    ? objectPropertyNames.map((x) => row[x])
    : [];
};

export const stringifyAndDehydrateDataObject = (row: any): string[] => {
  const dehydratedData = dehydrateData(row);
  return dehydratedData.map((d) => (d !== null ? d?.toString() : ""));
};

export const stringifyHydratedDataObject = (
  hydratedData: HydratedData
): HydratedData =>
  Object.entries(hydratedData).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key] = (value as any).toString() ?? "";
    }
    return acc;
  }, {});

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: number,
  columns: BaseTableColumn[]
) {
  const orderByType =
    columns[orderBy]?.defaultValueType ?? DataTypeEnums.STRING;
  const isLink = columns[orderBy]?.alternateDisplayValues ? true : false;
  const valueA = a[orderBy] as string;
  const valueB = b[orderBy] as string;
  if (numberDataTypeEnums.includes(orderByType)) {
    return sortNumbers(valueA, valueB);
  }
  if (dateDataTypeEnums.includes(orderByType)) {
    return sortDates(valueA, valueB);
  }
  if (isLink) {
    return sortLinks(
      valueA,
      valueB,
      columns[orderBy]?.alternateDisplayValues ?? []
    );
  }
  return sortStrings(
    valueA?.trim().toLocaleLowerCase(),
    valueB?.trim().toLocaleLowerCase()
  );
}

const sortLinks = (
  valueA: string,
  valueB: string,
  alternateDisplayValues: BaseTableAlternativeDisplayValues[]
) => {
  const optionA =
    alternateDisplayValues
      .find((x) => x.guid === valueA)
      ?.displayValuesOrFieldNames[0]?.trim()
      .toLocaleLowerCase() ?? "";
  const optionB =
    alternateDisplayValues
      .find((x) => x.guid === valueB)
      ?.displayValuesOrFieldNames[0]?.trim()
      .toLocaleLowerCase() ?? "";
  return sortStrings(optionA, optionB);
};

const sortNumbers = (valueA: string, valueB: string) => {
  return parseInt(valueA ?? 0) - parseInt(valueB ?? 0);
};

const sortStrings = (valueA: string, valueB: string) => {
  if (valueA === null || valueA === "") return 1;
  if (valueB === null || valueB === "") return -1;
  if (valueB < valueA) return 1;
  if (valueB > valueA) return -1;
  return 0;
};

const sortDates = (valueA: string, valueB: string) => {
  if (valueA === null || valueA === "") return 1;
  if (valueB === null || valueB === "") return -1;
  if (isDateAfterDate(valueB, valueA)) return -1;
  else if (isDateAfterDate(valueA, valueB)) return 1;
  else return 0;
};

export const getComparator = (
  order: any,
  orderBy: any,
  columns: BaseTableColumn[]
) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, columns)
    : (a, b) => -descendingComparator(a, b, orderBy, columns);
};

export const sortOrderBy = (
  data: string[][],
  order: string,
  orderBy: number,
  columns: BaseTableColumn[]
) => {
  return data.slice().sort(getComparator(order, orderBy, columns));
};

export const getIndexOfColumn = (
  columns: BaseTableColumn[],
  fieldName: string
) => {
  return columns.findIndex((column) => {
    return column.fieldName === fieldName;
  });
};

export const alternativeColumnNames = (
  fieldName: string,
  columnOptions: ColumnOptionsProperties<any>[]
) => {
  return columnOptions?.find((column) => {
    if (column.fieldName === "OPTIONS" && fieldName === "_option_column") {
      return true;
    }
    return column.fieldName === fieldName;
  });
};

export const getWidthOfCells = (
  columnOptions: ColumnOptionsProperties<any>[],
  columns: BaseTableColumn[],
  fieldName: string,
  isOptionColumn?: boolean,
  isMultiSelectionColumn?: boolean,
  isIndicationColumn?: boolean
) => {
  if (isMultiSelectionColumn) {
    return "55px";
  }

  const defaultColumn = `${
    100 /
    (columns ?? []).filter((c) => !c.isHidden && !c._isInternal === true).length
  }%`;

  const defaultColumnOption = "35px";

  if (columnOptions !== null && (columnOptions?.length ?? 0) > 0) {
    const columnInfo = alternativeColumnNames(fieldName, columnOptions);
    if (isOptionColumn === true || isIndicationColumn === true) {
      return columnInfo?.width !== undefined ? `${columnInfo?.width}%` : "35px";
    }
    return columnInfo?.width !== undefined
      ? `${columnInfo?.width}%`
      : defaultColumn;
  }
  return isOptionColumn === true ? defaultColumnOption : defaultColumn;
};

export const filterBy = (
  searchValue: {
    columnIndexes: number[];
    filterValue: string;
  }[],
  sortedData: string[][],
  filterParameters:
    | { columnIndexes: number[]; filterValue: string; filterName: string }[]
    | null
) => {
  // Filtering on "Select" Note: The displayValue and the stringValue must both match in order for the filter to filter out the searchValue.
  // Otherwise results cannot be filtered since the stored values are what we filter by, not the displayValue.
  // console.log("filterBy - ", { searchValue, sortedData, filterParameters });

  if (searchValue !== null && filterParameters?.length === 0) return sortedData;

  return sortedData.filter((row) => {
    const rowFilterResults = filterParameters?.map((filter) => {
      // the "all" keyword is special - we just always agree to return the row if all is provided as an option
      if (filter.filterValue === FILTER_BY_RENDER_ROW_KEY_WORD) return true;
      if (filter.filterName === SPECIAL_SORT_ORDER) return true;
      const regexFilter = filter.filterName === SEARCH_FILTER ? "" : "^";
      // multi columnIndexes
      if (filter.columnIndexes.length > 1) {
        const hasValueInRowByIndexes = filter.columnIndexes.map(
          (columnIndex) => {
            const val = row[columnIndex ?? -1];
            if (
              val !== null &&
              val
                .toLowerCase()
                .trim()
                .search(
                  `${regexFilter}${filter.filterValue.toLowerCase().trim()}`
                ) !== -1
            ) {
              return true;
            }

            return false;
          }
        );

        return hasValueInRowByIndexes.some((l) => l);
      }

      if (filter.columnIndexes.length === 1 && filter.columnIndexes[0] === -1) {
        // if columnIndex == -1, search all row cell values
        return row
          .map((item) => {
            if (item !== null) {
              const result = item
                .toLowerCase()
                .search(
                  `${regexFilter}${filter.filterValue.toLowerCase().trim()}`
                );
              return result === -1 ? false : true;
            }
            return false;
          })
          .some((rowMatch) => rowMatch === true);
      } else {
        // if columnIndex > -1, search only that row cell value
        const val = row[filter.columnIndexes[0] ?? -1];
        // Search Filter
        // This should not be the same filter that the user type, this is a %LIKE%
        if (filter.filterName === SEARCH_FILTER) {
          if (
            conditionHasValue(val) &&
            val
              .toLowerCase()
              .trim()
              .search(
                `${regexFilter}${filter?.filterValue?.toLowerCase().trim()}`
              ) !== -1
          ) {
            return true;
          }
        }

        if (filter.filterName === MULTI_DROPDOWN_FILTER) {
          if (
            filter?.filterValue.includes("all") === true ||
            filter?.filterValue?.length === 0
          ) {
            return true;
          }
          const filters = filter?.filterValue?.split(",");
          if (
            conditionHasValue(val.trim()) &&
            val.trim() !== "" &&
            filters.includes(val.toLowerCase())
          ) {
            return true;
          }
        }
        // Dropdown Filter
        // This option should be the same value that the filter has
        if (
          conditionHasValue(val) &&
          val.toLowerCase().trim() === filter?.filterValue?.toLowerCase().trim()
        ) {
          return true;
        }

        return false;
      }
    });

    // if conditions pass - render row
    if (rowFilterResults?.every((v) => v === true)) return row;

    // default - don't render row
    return null;
  });
};

export const updateDataByRowKey = (
  rowKey: RowKey,
  columns: TableColumns,
  data: TableData,
  updatedDataItem: TableDataItem
): TableData => {
  const updatedData = data.map((row) => {
    if (getSafeRowKey(row) === rowKey) {
      const hydratedRow = hydrateDataSingle(columns, row);
      const mergedRow = { ...hydratedRow, ...updatedDataItem };
      const flattenedDataRow = dehydrateData(mergedRow);
      return flattenedDataRow;
    }
    return row;
  });

  return updatedData;
};

export const flagRowAsDeleted = (
  columns: TableColumns,
  row: TableDataItem
): TableDataItem => {
  const updatedDataItem = row.map((val, i) => {
    const columnIndex = getColumnIndexByColumnName(
      DELETED_COLUMN_FIELD_NAME,
      columns
    );

    if (i === columnIndex) {
      return "true";
    }

    return val;
  });

  return updatedDataItem;
};

export const flagRowsAsDeleted = (
  columns: TableColumns,
  rows: TableDataItem[]
) => {
  return rows.map((row) => flagRowAsDeleted(columns, row));
};

export const updateDataRows = (
  updatedData: { rowKey: string; value: string; columnIndex: number }[],
  data: string[][]
) => {
  const result = data.map((row) => {
    const currentRowKey = getSafeRowKey(row);
    const hasRowData = updatedData.filter((ud) => currentRowKey === ud.rowKey);

    if (hasRowData.length > 0) {
      return row.map((val, colIndex) => {
        const hasColumnValue = hasRowData.find(
          (rd) => rd.columnIndex === colIndex
        );
        if (hasColumnValue) {
          return hasColumnValue.value ?? "";
        }
        return val;
      });
    }

    return row;
  });

  return result;
};

export const getColumnByColumnName = (
  columnName: string,
  columns: BaseTableColumn[]
): BaseTableColumn | null =>
  columns.find((c) => c.fieldName === columnName) ?? null;

export const getColumnByColumnIndex = (
  columnIndex: number,
  columns: BaseTableColumn[]
): BaseTableColumn | null =>
  columns.find((c) => c._columnIndex === columnIndex) ?? null;

export const getColumnIndexByColumnName = (
  columnName: string,
  columns: BaseTableColumn[]
) => columns.findIndex((c) => c.fieldName === columnName) ?? -1;

export const findRowsByKey = (keys: string[], data: string[][]): string[][] =>
  data.filter((row) => keys.includes(getSafeRowKey(row)));

export const upsertRowData = (
  newData: string[],
  data: string[][]
): string[][] => {
  const rowKey = getSafeRowKey(newData);
  const hasKey = data.find((row) => getSafeRowKey(row) === rowKey);
  if (hasKey !== undefined) {
    return data.map((row) => {
      if (getSafeRowKey(row) === rowKey) {
        return newData;
      }
      return row;
    });
  }
  return data.concat([newData]);
};

export const upsertRowDeletedData = (
  newData: string[],
  data: string[][]
): string[][] => {
  const rowKey = getSafeRowKey(newData);
  const hasKey = data.find((row) => getSafeRowKey(row) === rowKey);
  if (hasKey !== undefined) {
    return data.filter((row) => getSafeRowKey(row) !== rowKey);
  }
  return data.concat([newData]);
};

export const upsertDataRows = (
  newData: string[][],
  data: string[][]
): string[][] => {
  if (data.length === 0) {
    return newData;
  }

  const updatedKeys: string[] = [];
  const updatedData = data.map((row) => {
    const currentRowKey = getSafeRowKey(row);
    const hasRowData = newData.find(
      (row) => currentRowKey === getSafeRowKey(row)
    );

    if (hasRowData) {
      updatedKeys.push(currentRowKey);
      return hasRowData;
    }

    return row;
  });

  const results = updatedData.concat(
    newData.filter((d) => !updatedKeys.includes(getSafeRowKey(d)))
  );

  return results;
};

export const createConditionForCellResponse = (
  cellKey: string,
  rowKey: string,
  // defaultValue: any,
  value: any,
  isEdit: boolean,
  typeId: BaseTableInputType | null,
  variant: string,
  targetFieldName: string,
  triggerComputes: string[],
  requestingColumn: string | null,
  responseType: ConditionForCellResponseType,
  apiRequestOptions: ConditionForCellBaseAPIOptions | null,
  listDisplayOptions: BaseTableDisplayOptions[] | null,
  isColumnsDisabled: string[] | null,
  refreshCell: boolean
) => {
  return {
    cellKey: cellKey ?? null,
    targetFieldName: targetFieldName ?? null,
    isEditable: isEdit ?? false,
    typeId: typeId ?? null,
    variant: variant ?? "standard",
    // defaultValue: defaultValue ?? null,
    value: value ?? "",
    preserveTargetValue: false,
    triggerComputeFieldNames: triggerComputes ?? [],
    requestingColumn: requestingColumn ?? null,
    responseType: responseType,
    apiRequestOptions: apiRequestOptions,
    listDisplayOptions: listDisplayOptions,
    isColumnsDisabled: isColumnsDisabled ?? null,
    refreshCell: refreshCell,
    _originalValue: value ?? "",
    _rowKey: rowKey,
  } as ConditionForCellResponse;
};

export const getErrorsByRow = (rowIndex: string, validationErrors: any) => {
  if (conditionHasValue(validationErrors)) {
    const errors = validationErrors[rowIndex];
    return conditionHasValue(errors) ? errors.errorsByRow : [];
  }
  return [];
};

export const getDataWithoutDeletedRows = (
  data: string[][] | GridDataRow[],
  columns: BaseTableColumn[]
) => {
  const deletedColumnIndex = getColumnIndexByColumnName(
    DELETED_COLUMN_FIELD_NAME,
    columns
  );

  const updatedData = data.filter((row) => row[deletedColumnIndex] === "false");

  return updatedData;
};

export const hydratePreSetValuesForAdd = (
  optionalPropertyValueData?: {
    property: string;
    value: string | (() => string);
  }[]
) => {
  const hydratedProperties = optionalPropertyValueData?.map((item) => {
    return {
      [item.property]:
        typeof item.value === "function" ? item.value() : item.value,
    };
  });

  const hydratedRow = hydratedProperties?.reduce((previous, current) => {
    return { ...previous, [current.property]: current.value };
  });

  return hydratedRow;
};

export const getCellVariant = (variant: number) => {
  if (variant === CellVariantEnums.FILLED) return "filled";
  if (variant === CellVariantEnums.OUTLINED) return "outlined";
  if (variant === CellVariantEnums.STANDARD) return "standard";
  return "standard";
};

export const getTagsAsCommaSeparatedString = (
  tagIdsInJsonString: string,
  displayOptions: BaseTableDisplayOptions[]
) => {
  const tagIds = JSON.parse(tagIdsInJsonString);
  const matchingTags = displayOptions
    ?.filter((option) =>
      tagIds.some((tag) => Number(tag.Id) === Number(option.value))
    )
    .map((tag) => tag.text)
    .join(", ");
  return matchingTags ?? "";
};

export const updateUrlParameter = (url, param, value) => {
  const regex = new RegExp("(?<=[?|&])(" + param.paramaterKey + "=)[^&]+", "i");
  // return url.replace(regex, param + '=$1' + value);
  return url.replace(regex, param.paramaterKey + "=" + value);
};

export const updateURL = (
  urlPattern: string,
  parameters: { paramaterKey: string; parameterPropertyValue: string }[],
  obj: any
) => {
  var url = urlPattern ?? "NO_URL_FOUND";
  parameters?.forEach((param) => {
    url = updateUrlParameter(url, param, obj[param.parameterPropertyValue]);
  });
  return url;
};

export const createMetaDataObjects = (objectToHydrate) => {
  const metaDataObjects: any[] =
    objectToHydrate?.map((description) => {
      return [
        description?.name ?? "NO_NAME_FOUND",
        description?.value ?? "NO_VALUE_FOUND",
      ];
    }) ?? [];
  return metaDataObjects;
};
export const hydrateGroup = (metaDataObjects) => {
  const flattenedMetaData = new Map(metaDataObjects as any);
  const result = Object.fromEntries(flattenedMetaData ?? null) ?? null;
  return result;
};

export const hydrateMetaData = (group) => {
  const metaDataObjectProperties: any[] = createMetaDataObjects(group.metaData);

  const metaDataObjects = [
    ["GroupUiidKey", group?.groupUiidKey ?? null],
  ].concat(metaDataObjectProperties);

  return hydrateGroup(metaDataObjects);
};

export const hydrateHeader = (group) => {
  const metaDataObjects: any[] = createMetaDataObjects(group.header);
  return hydrateGroup(metaDataObjects);
};

export const getTableColumnsAsArray = (group) => {
  return group?.columns?.map((colum) => colum.fieldName);
};

export const hydrateAllGroups = (groups) => {
  return groups?.map((group) => {
    const metadata = hydrateMetaData(group);
    const header = hydrateHeader(group);
    const columns = getTableColumnsAsArray(group);
    return { Header: header, Metadata: metadata, Columns: columns };
  });
};

export const castHeaderToExports = (
  group,
  columns: BaseTableColumn[],
  headerType?: "text" | "aligned"
) => {
  const result =
    headerType === "aligned"
      ? getAlignedHeader(group, columns)
      : getHeaderText(group, columns);

  return result;
};
export const castFooterToExports = (
  metaData: BaseTableMetaData[] | null,
  columns: BaseTableColumn[],
  footerType?: "text" | "aligned"
) => {
  const result =
    footerType === "aligned"
      ? getAlignedFooter(metaData, columns)
      : getFooterText(metaData, columns);

  return result;
};

const getAlignedHeader = (group, columns: BaseTableColumn[]) => {
  return (
    (columns ?? []).reduce((prevCol, currColumn) => {
      const headerProp = group?.header?.find(
        (param) => currColumn.fieldName === param.name
      );
      return {
        ...prevCol,
        [currColumn.fieldName]: headerProp?.value ?? "",
      };
    }, {}) ?? null
  );
};

const getHeaderText = (group, columns: BaseTableColumn[]) => {
  const firstColumn = columns.find((col) => !col.isHidden);
  const headerValue = group?.header?.map((param) => param?.value)?.join(" ");
  return (
    (columns ?? []).reduce(
      (prevCol, currColumn) => ({
        ...prevCol,
        [currColumn.fieldName]:
          currColumn.fieldName === firstColumn?.fieldName ? headerValue : "",
      }),
      {}
    ) ?? null
  );
};

const getAlignedFooter = (
  metaData: BaseTableMetaData[] | null,
  columns: BaseTableColumn[]
) => {
  return (
    (columns ?? []).reduce((prevCol, currColumn) => {
      const headerProp = metaData?.find(
        (param) => currColumn.fieldName === param.name
      );
      return {
        ...prevCol,
        [currColumn.fieldName]: headerProp?.value ?? "",
      };
    }, {}) ?? null
  );
};

const getFooterText = (
  metaData: BaseTableMetaData[] | null,
  columns: BaseTableColumn[]
) => {
  const firstColumn = columns.find((col) => !col.isHidden);
  const headerValue = metaData?.map((param) => param?.value)?.join(" ");
  return (
    (columns ?? []).reduce(
      (prevCol, currColumn) => ({
        ...prevCol,
        [currColumn.fieldName]:
          currColumn.fieldName === firstColumn?.fieldName ? headerValue : "",
      }),
      {}
    ) ?? null
  );
};

export const castChildrenToExports = (
  group,
  childrenData?: BaseTableChildrenData[]
) => {
  if ((childrenData?.length ?? 0) > 0) {
    const childData = childrenData?.find(
      (child) => child?.groupId.toString() === group?.groupUiidKey
    );
    const result = hydrateData2(
      childData?.columns ?? [],
      childData?.data ?? []
    );
    return result;
  }
  return [];
};

export const getDataOfChildren = (
  group,
  columns: BaseTableColumn[],
  childrenData?: BaseTableChildrenData[]
) => {
  if ((childrenData?.length ?? 0) > 0) {
    const childData = childrenData?.find(
      (child) => child?.groupId.toString() === group?.groupUiidKey
    );
    const hasInternals = childData?.columns.some(
      (column) => column?._isInternal ?? false
    );
    const internalColumns = hasInternals
      ? []
      : getInternalColumnsOnly(columns).map(() => "");
    const hydratedData = childData?.data.map((data) => [
      ...internalColumns,
      ...data,
    ]);
    return hydratedData ?? [];
  }
  return [];
};

export const addOrRemoveKeyRow = (
  selectedRows: string[],
  rowKey: string,
  addKey: boolean
) => {
  if (!addKey) {
    return selectedRows.filter((key) => key != rowKey);
  }
  return [rowKey, ...selectedRows];
};

export const addRowKeyWithOutRepeat = (
  rowKeys: string[] | GridDataRow,
  rowKey: string
) => {
  const existRowKey = rowKeys?.find((_rowKey) => _rowKey === rowKey);
  if (conditionHasValue(existRowKey)) {
    return rowKeys;
  }
  return [rowKey, ...rowKeys];
};

export const addRowKeysWithOutRepeat = (
  rowKeys: string[],
  newRowKeys: string[]
) => {
  const missingRowKeys = newRowKeys?.filter(
    (_rowKey) => !rowKeys.includes(_rowKey)
  );
  if (missingRowKeys.length > 0) {
    return [...missingRowKeys, ...rowKeys];
  }
  return rowKeys;
};

export const getCurrentlySelectedRowKeys = (rows: string[][]) => {
  return rows.map((row) => getSafeRowKey(row));
};

export const getDataOfRowKeys = (data: string[][], rowKeys: string[]) => {
  return data.filter((row) => rowKeys.includes(getSafeRowKey(row)));
};

export const getExternalDataOnlyOfMultipleRows = (
  columns: BaseTableColumn[],
  data: string[][]
) => {
  return data.map((row) => getExternalDataOnly(columns, row));
};

export const isNumericDataType = (dataType: number) =>
  [
    DataTypeEnums.DECIMAL,
    DataTypeEnums.DOUBLE,
    DataTypeEnums.FLOAT,
    DataTypeEnums.INT,
    DataTypeEnums.LONG,
    DataTypeEnums.NULLABLE_DOUBLE,
    DataTypeEnums.NULLABLE_FLOAT,
    DataTypeEnums.NULLABLE_INT,
    DataTypeEnums.NULLABLE_LONG,
    DataTypeEnums.NULLABLE_SHORT,
  ].includes(dataType);
