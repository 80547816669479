import { useEffect, FC, useState } from "react";
import { useParams } from "react-router-dom";
import { InsuredInstanceWrapper } from ".";
import { InsuredPage } from "../../dtos/insured-page";
import { useApiPost } from "../../hooks";
import { BasePageContainer } from "../TrueUI";
import { useRecoilState, useRecoilValue } from "recoil";
import { InsuredTabDto } from "../../dtos/insured-tab-dto";
import { CLAIM_NUMBER, INSURED_ID } from "../../utilities/queryStringsHash";
import { RefreshInsuredPageAtom } from "./InsuredAtoms";
import { SamePageClickedAtom } from "../Nav/TopNav/SearchAtoms";
import { getQueryValue } from "../../utilities/URLUtilities_OBSOLETE";
import {
  areTabsEqual,
  getByInsuredIdAndClaimNumber,
  getStorageValueArray,
  OPEN_TABS_STORAGE,
  setStorageOpenTabs,
} from "../../utilities/localStorageFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";

export type TabConfigProps = {
  tabKey: string;
  insuredId: number;
  claimNumber: string;
  title?: string; // We just need to storage the title for the claim ones.
};

export type TabDto = {
  tabKey: string;
  information: InsuredTabDto;
};

const Insured: FC = () => {
  const { insuredId } = useParams();
  const insuredIdFromHash = getQueryValue(INSURED_ID);
  const [refreshPage, setRefreshPage] = useRecoilState(RefreshInsuredPageAtom);
  const _insuredId = insuredIdFromHash !== "" ? insuredIdFromHash : insuredId;
  const [tabList, setTabList] = useState<TabDto[]>([]);
  const [insuredIds, setInsuredIds] = useState<number[]>([]);
  const samePageClicked = useRecoilValue(SamePageClickedAtom);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const tabs: TabConfigProps[] = getStorageValueArray(OPEN_TABS_STORAGE);
  const limitRangeToParseToIntTypeInBackend = 2147483647;
  const newInsuredId: number = parseInt(_insuredId ?? "");
  const insuredIdsFromLocalStorage = tabs.map(function (item: TabConfigProps) {
    return item?.insuredId;
  });

  const { responsePost, dispatchPost } = useApiPost<InsuredPage>(
    "api/insured/GetInsuredPage",
    JSON.stringify(
      insuredIds.filter(
        (insuredId) =>
          insuredId !== 0 && insuredId !== null && insuredId !== undefined
      )
    )
  );

  const getInsureds = () => {
    if (
      !insuredIdsFromLocalStorage.includes(newInsuredId) &&
      newInsuredId <= limitRangeToParseToIntTypeInBackend
    ) {
      setInsuredIds([newInsuredId, ...insuredIdsFromLocalStorage]);
    } else {
      setInsuredIds(insuredIdsFromLocalStorage);
    }
  };

  const generateTabs = () => {
    // For Local Storage
    const newTabConfig: TabConfigProps = {
      tabKey: crypto.randomUUID(),
      insuredId: newInsuredId,
      claimNumber: getQueryValue(CLAIM_NUMBER),
    };

    const newTab: TabDto = {
      tabKey: newTabConfig?.tabKey,
      information: findInsuredInformation(newInsuredId) as any,
    };

    //This cannot be evaluated by tabKey because at this point is unknown
    const alreadyExist = getByInsuredIdAndClaimNumber(
      newInsuredId,
      getQueryValue(CLAIM_NUMBER)
    );
    if (!conditionHasValue(alreadyExist)) {
      const allowedTabs = tabs?.filter(
        (tab) => !areTabsEqual(tab, newTabConfig)
      );

      const otherTabs = generateOtherTabs(allowedTabs);
      setStorageOpenTabs(newTabConfig);
      return [newTab, ...otherTabs];
    }

    return generateOtherTabs(tabs);
  };

  const generateOtherTabs = (allowedTabs: TabConfigProps[]): TabDto[] =>
    allowedTabs?.map((tab: TabConfigProps) => ({
      tabKey: tab?.tabKey,
      information: findInsuredInformation(tab?.insuredId) as InsuredTabDto,
    }));

  const findInsuredInformation = (insuredId) => {
    const insuredsInfo = responsePost.axiosResponse?.data?.insuredTabList;
    return insuredsInfo?.find(
      (insured) =>
        insured?.insuredInformation?.insuredId === parseInt(insuredId)
    );
  };

  useEffect(() => {
    if ((insuredIds?.length ?? 0) > 0) {
      dispatchPost();
    }
  }, [insuredIds]);

  useEffect(() => {
    getInsureds();
  }, [insuredId, samePageClicked]);

  useEffect(() => {
    if (refreshPage?.refreshTabs) {
      getInsureds();

      if (refreshPage.reRenderComponent) setIsReadyToRender(false);

      setRefreshPage(null);
    }
  }, [refreshPage]);

  useEffect(() => {
    if (
      tabList != null &&
      tabList.length > 0 &&
      insuredIds != null &&
      insuredIds.length > 0
    ) {
      setIsReadyToRender(true);
    }
  }, [tabList, insuredIds]);

  useEffect(() => {
    if (
      responsePost?.responseData &&
      responsePost.requestInstanceSuccessful &&
      !responsePost.isLoading
    ) {
      setTabList(generateTabs() as any);
    }
  }, [responsePost]);

  return isReadyToRender ? (
    <BasePageContainer
      id={"insurance_container"}
      maxWidth={false}
      sxWrapper={{ height: "100%" }}
      sxBody={{ height: "auto" }}
      sxContainer={{
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}
      data={tabList}
    >
      <InsuredInstanceWrapper tabList={tabList} insuredId={newInsuredId!!} />
    </BasePageContainer>
  ) : (
    <></>
  );
};

export default Insured;
