export const downloadLinkButtonContainerStyles = () => ({
  display: "flex",
  width: "100% !important",
  height: "fit-content !important",
  alignItems: "center",
  marginBottom: "10px",
  "& svg": {
    width: "42px",
    height: "42px",
  },
  ".link-container": {
    paddingLeft: "10px",
    backgroundColor: "#fff",
    width: "100%",
    paddingBottom: "10px",
    paddingTop: "10px",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
  },
});
