import { FC } from "react";

// import Slide, { SlideProps } from "@mui/material/Slide";
import { Skeleton } from "@mui/material";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
// import { TransitionProps } from "@mui/material/transitions";
// import Fade from "@mui/material/Fade";

// function SlideTransition(props: SlideProps) {
//   return <Slide {...props} direction="up" />;
// }

const BaseGridSkeleton: FC<any> = ({ uiid }) => {
  const { instanceInternalSelector } = useGridInstance(
    uiid,
    "BaseGridSkeleton"
  );
  const isReadyToRender = useRecoilValue(
    instanceInternalSelector("isReadyToRender")
  );

  return !isReadyToRender ? (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "5px",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={35}
            animation="wave"
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={"100%"}
            animation="wave"
            sx={{ marginTop: "3px" }}
          />
        </div>
      </div>
    </>
  ) : null;
};

export default BaseGridSkeleton;
