import { FC } from "react";
import { Box } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { Tag, InputPhone, SmallTitle, Font } from "../TrueUI";
import { InsuredContactDto } from "../../dtos/insured-contact-dto";

type ContactItemProps = {
  contact: InsuredContactDto;
  primaryContactId: number;
};

const InsuredContactItem: FC<ContactItemProps> = ({
  contact,
  primaryContactId,
}) => {
  const mailTo = () => {
    const url = `mailto:${contact.email}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <ListItem
        true-element={`true-li-insuredContacts-${contact.contactId}`}
        className={"contact-item"}
        key={contact.contactId}
      >
        <Box className={"right-column"} key={`info_${contact.contactId}`}>
          {contact.contactId === primaryContactId && (
            <SmallTitle>Primary Contact</SmallTitle>
          )}
          <Font
            fontType="BODY"
            className="insured-contact-name"
            name="contactLastName"
          >
            {`${contact.contactFirstName} ${contact.contactLastName}`}
          </Font>
          <Font className="email-text" fontType="BODY" name="email">
            <Box onClick={mailTo}>{contact.email}</Box>
          </Font>
          {contact?.primaryPhone && (
            <InputPhone
              id="id-contact-phone-item-input"
              name="name-contact-phone-item-input"
              phoneValue={contact.primaryPhone}
              readOnly
              className={"contact-phone-item-input"}
              inputFontType={"BODY"}
            />
          )}
        </Box>
        <Box className={"left-column"} key={`tags_${contact.contactFirstName}`}>
          {contact.tags
            ? contact.tags.map((tag) => (
                <Tag
                  name={tag.tagName}
                  label={tag.tagName}
                  key={`${contact.contactId}_${tag.tagName}`}
                />
              ))
            : ""}
        </Box>
      </ListItem>
    </>
  );
};

export default InsuredContactItem;
