import { FC, useState, useEffect } from "react";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { Input } from "../../../../TrueUI";
import style from "./ExposureTable.module.css";
import { useApiGet } from "../../../../../hooks";
import {
  getClassCodeAndSuffix,
  getExposureValidated,
  getMax999Min1ValueValidated,
  getPremiumValueCalculated,
  getRateValidated,
  isDuplicatedClassCode,
} from "./ExposureTableRowFunctions";
import { getValidatedDateToPost } from "../../../../../utilities/dateFunctions";
import { ClassCodeRateDto } from "../../../../../dtos/class-code-rate-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { areObjectsEqual } from "../../../../../utilities/objectFunctions";
import { IconButton } from "@mui/material";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { customRound } from "../../../../../utilities/stringFunctions";

type ExposureTableRowProps = {
  stateCode: string;
  expirationDate: Date;
  policyEffectiveDate: Date;
  exposureJSON: PolicyExposureBlob;
  exposureJSONList: PolicyExposureBlob[];
  updateExposure: (exposureUpdated: PolicyExposureBlob) => void;
  addExposure: () => void;
  deleteExposure: (uniqueKey: string) => void;
  lcmValue: number;
  isEndorsementOrAudit?: boolean;
  isRateEditableForQuote?: boolean | null;
  isRateEditableForEndorsement?: boolean | null;
  showNetRateInQuote?: boolean | null;
  readonly?: boolean;
};

const ExposureTableRow: FC<ExposureTableRowProps> = ({
  stateCode,
  policyEffectiveDate,
  exposureJSON,
  exposureJSONList,
  updateExposure,
  addExposure,
  deleteExposure,
  lcmValue,
  isEndorsementOrAudit,
  isRateEditableForQuote,
  isRateEditableForEndorsement,
  showNetRateInQuote,
  readonly,
}) => {
  const [localExposureJSON, setLocalExposureJSON] =
    useState<PolicyExposureBlob>(exposureJSON);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const { responseGet, dispatchGet, validatorErrorResponse } =
    useApiGet<ClassCodeRateDto>(
      `api/PolicyExposurePremium/GetClassCodeRate/?classCode=${
        localExposureJSON.classCode ?? ""
      }&suffix=${
        localExposureJSON.classSuffix ?? ""
      }&stateCode=${stateCode}&effectiveDate=${getValidatedDateToPost(
        policyEffectiveDate
      )}&repeatedClassCode=${isDuplicatedClassCode(
        exposureJSONList,
        localExposureJSON
      )}`
    );

  const getDescriptionClassName = () => {
    if (isEndorsementOrAudit) {
      return `${style.exposure_table_cell_container} ${style.exposure_table_width_10}`;
    }
    if (showNetRateInQuote) {
      return `${style.exposure_table_cell_container} ${style.exposure_table_width_25}`;
    }

    return `${style.exposure_table_cell_container} ${style.exposure_table_width_30}`;
  };

  const onClassCodeChange = (
    classCodeWithSuffix: string,
    isOnBlur?: boolean
  ) => {
    const { classCode, classSuffix } =
      getClassCodeAndSuffix(classCodeWithSuffix);
    const currentClassCodeAndSuffix = `${exposureJSON.classCode ?? ""}${
      exposureJSON.classSuffix ?? ""
    }`;

    setLocalExposureJSON({ ...localExposureJSON, classCode, classSuffix });

    if (
      isOnBlur &&
      classCodeWithSuffix !== currentClassCodeAndSuffix &&
      classCodeWithSuffix !== ""
    ) {
      dispatchGet();
    }
  };

  const getLocalExposureJSONUpdatedByLocationChange = (
    locationNumber: number | null,
    isOnBlur?: boolean
  ): PolicyExposureBlob => {
    const localExposureJSONWithLocationUpdated = {
      ...localExposureJSON,
      locationNumber,
    } as PolicyExposureBlob;
    const isDuplicated = isDuplicatedClassCode(
      exposureJSONList,
      localExposureJSONWithLocationUpdated
    );

    if (isOnBlur && isDuplicated) {
      setErrorDetails({
        classCodeRepeated: [
          "There is already an exposure with the same Class Code, Suffix and Location Number combination.",
        ],
      });

      return { ...localExposureJSONWithLocationUpdated, hasErrors: true };
    }
    if (
      isOnBlur &&
      !isDuplicated &&
      errorDetails?.classCodeRepeated !== undefined
    ) {
      setErrorDetails(null);

      return { ...localExposureJSONWithLocationUpdated, hasErrors: false };
    }

    return localExposureJSONWithLocationUpdated;
  };

  const onLocationChange = (
    locationNumber: number | null,
    isOnBlur?: boolean
  ) => {
    if (locationNumber !== null) {
      const locationNumberValidated = isOnBlur
        ? getMax999Min1ValueValidated(locationNumber)
        : locationNumber;
      const localExposureJSONUpdated =
        getLocalExposureJSONUpdatedByLocationChange(
          locationNumberValidated,
          isOnBlur
        );

      setLocalExposureJSON(localExposureJSONUpdated);
      updateExposure(localExposureJSONUpdated);
    }
  };

  const onNumberOfEmployeesChange = (
    numberOfEmployees: number | null,
    isOnBlur?: boolean
  ) => {
    if (numberOfEmployees !== null) {
      const numberOfEmployeesValidated = isOnBlur
        ? getMax999Min1ValueValidated(numberOfEmployees)
        : numberOfEmployees;
      const localExposureJSONUpdated = {
        ...localExposureJSON,
        numberOfEmployees: numberOfEmployeesValidated,
      };

      setLocalExposureJSON(localExposureJSONUpdated);

      if (isOnBlur) {
        updateExposure(localExposureJSONUpdated);
      }
    }
  };

  const onExposureChange = (
    exposureAmount: number | null,
    isOnBlur?: boolean
  ) => {
    if (exposureAmount !== null) {
      const exposureAmountValidated = isOnBlur
        ? getExposureValidated(exposureAmount)
        : exposureAmount;

      const manualPremiumAtMod = !isOnBlur
        ? localExposureJSON.manualPremiumAtMod
        : getPremiumValueCalculated(
            localExposureJSON.rateBasis ?? "",
            exposureAmountValidated,
            localExposureJSON.modRate ?? 1
          );

      const localExposureJSONUpdated = {
        ...localExposureJSON,
        exposureAmount: exposureAmountValidated,
        manualPremiumAtMod,
      };

      setLocalExposureJSON(localExposureJSONUpdated);

      if (isOnBlur) {
        updateExposure(localExposureJSONUpdated);
      }
    }
  };

  const onRateChange = (modRate: number | null, isOnBlur?: boolean) => {
    if (modRate !== null) {
      const rateValidated = isOnBlur ? getRateValidated(modRate) : modRate;

      const manualPremiumAtMod = !isOnBlur
        ? localExposureJSON.manualPremiumAtMod
        : getPremiumValueCalculated(
            localExposureJSON.rateBasis ?? "",
            localExposureJSON.exposureAmount ?? 0,
            rateValidated
          );

      const localExposureJSONUpdated = {
        ...localExposureJSON,
        modRate: rateValidated,
        manualPremiumAtMod,
      };

      setLocalExposureJSON(localExposureJSONUpdated);

      if (isOnBlur) {
        updateExposure(localExposureJSONUpdated);
      }
    }
  };

  const getClassCodeErrorMessage = () => {
    if (errorDetails?.classCodeInvalidFormat !== undefined)
      return errorDetails.classCodeInvalidFormat;
    if (errorDetails?.classCodeRepeated !== undefined)
      return errorDetails.classCodeRepeated;
    if (errorDetails?.classCode !== undefined) return errorDetails.classCode;
    if (errorDetails?.classCodeRate !== undefined)
      return errorDetails.classCodeRate;

    return null;
  };

  const getLocalExposureJSONUpdatedByClassCodeChange = (
    classCodeRate?: ClassCodeRateDto
  ) => {
    const newModRate =
      classCodeRate !== undefined
        ? customRound((lcmValue * (classCodeRate.rate ?? 1)).toString(), 2)
        : 1;
    return {
      ...localExposureJSON,
      classCodeID: classCodeRate?.classCodeId ?? null,
      classCode: classCodeRate ? localExposureJSON.classCode : null,
      classSuffix: classCodeRate ? localExposureJSON.classSuffix : null,
      hazardGroup: classCodeRate?.hazardGroup ?? null,
      description: classCodeRate?.description ?? null,
      rateBasis: classCodeRate?.premiumBasis ?? null,
      baseRate: classCodeRate?.baseRate ?? 1,
      rate: classCodeRate?.rate ?? 1,
      modRate: newModRate,
      netRate: 1,
      manualPremiumAtMod: classCodeRate
        ? getPremiumValueCalculated(
            classCodeRate?.premiumBasis ?? "",
            localExposureJSON.exposureAmount ?? 0,
            newModRate
          )
        : null,
      hasErrors: classCodeRate === undefined ? true : false,
    } as PolicyExposureBlob;
  };

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      (validatorErrorResponse === null || validatorErrorResponse === undefined)
    ) {
      const classCodeRate = responseGet.axiosResponse?.data;
      const localExposureJSONUpdated =
        getLocalExposureJSONUpdatedByClassCodeChange(classCodeRate);

      setLocalExposureJSON(localExposureJSONUpdated);
      updateExposure(localExposureJSONUpdated);

      setErrorDetails(null);
    }
    if (
      responseGet.isLoading === false &&
      validatorErrorResponse !== null &&
      validatorErrorResponse !== undefined
    ) {
      const localExposureJSONUpdated =
        getLocalExposureJSONUpdatedByClassCodeChange();

      setLocalExposureJSON(localExposureJSONUpdated);
      updateExposure(localExposureJSONUpdated);

      setErrorDetails(validatorErrorResponse.errorDetails);
    }
  }, [responseGet]);

  useEffect(() => {
    if (!areObjectsEqual(exposureJSON, localExposureJSON))
      setLocalExposureJSON(exposureJSON);
  }, [exposureJSON]);

  return (
    <div
      id={localExposureJSON.uniqueKey}
      className={style.exposure_table_row_container}
    >
      <div
        id={`exposure_table_row_fields_${localExposureJSON.uniqueKey}`}
        className={style.exposure_table_row_fields_container}
      >
        <div
          id={`location_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
        >
          <Input
            id={`location_input_${localExposureJSON.uniqueKey}`}
            name={`location_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.locationNumber}
            readOnly={localExposureJSON.existInOldJSON ? true : readonly}
            type="number"
            onChangeRawValue={(value) => onLocationChange(value ?? null)}
            onBlur={(e) => onLocationChange(e.target.value ?? null, true)}
          />
        </div>
        <div
          id={`class_code_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
        >
          <Input
            id={`class_code_input_${localExposureJSON.uniqueKey}`}
            name={`class_code_input_${localExposureJSON.uniqueKey}`}
            value={`${localExposureJSON.classCode ?? ""}${
              localExposureJSON.classSuffix ?? ""
            }`}
            readOnly={localExposureJSON.existInOldJSON ? true : readonly}
            onChangeRawValue={(value) => onClassCodeChange(value ?? "")}
            onBlur={(e) => onClassCodeChange(e.target.value ?? "", true)}
          />
        </div>
        <div
          id={`description_cell_${localExposureJSON.uniqueKey}`}
          className={getDescriptionClassName()}
        >
          <Input
            id={`description_input_${localExposureJSON.uniqueKey}`}
            name={`description_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.description ?? "-"}
            readOnly
          />
        </div>
        <div
          id={`hazard_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
        >
          <Input
            id={`hazard_input_${localExposureJSON.uniqueKey}`}
            name={`hazard_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.hazardGroup ?? "-"}
            readOnly
          />
        </div>
        <div
          id={`rate_basis_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
        >
          <Input
            id={`rate_basis_input_${localExposureJSON.uniqueKey}`}
            name={`rate_basis_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.rateBasis ?? "-"}
            readOnly
          />
        </div>
        <div
          id={`number_employee_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
        >
          <Input
            id={`number_employee_input_${localExposureJSON.uniqueKey}`}
            name={`number_employee_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.numberOfEmployees}
            readOnly={readonly}
            type="number"
            onChangeRawValue={(value) =>
              onNumberOfEmployeesChange(value ?? null)
            }
            onBlur={(e) =>
              onNumberOfEmployeesChange(e.target.value ?? null, true)
            }
          />
        </div>
        {isEndorsementOrAudit && (
          <div
            id={`previous_exposure_cell_${localExposureJSON.uniqueKey}`}
            className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
          >
            <Input
              id={`previous_exposure_input_${localExposureJSON.uniqueKey}`}
              name={`previous_exposure_input_${localExposureJSON.uniqueKey}`}
              value={localExposureJSON.previousExposure}
              readOnly
              type="currency"
              prefix=""
              align="right"
            />
          </div>
        )}
        <div
          id={`exposure_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
        >
          <Input
            id={`exposure_input_${localExposureJSON.uniqueKey}`}
            name={`exposure_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.exposureAmount}
            readOnly={readonly}
            type="currency"
            prefix=""
            onChangeRawValue={(value) => onExposureChange(value ?? null)}
            onBlur={(e) => onExposureChange(e.target.value ?? null, true)}
            align="right"
          />
        </div>
        <div
          id={`base_rate_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
        >
          <Input
            id={`base_rate_input_${localExposureJSON.uniqueKey}`}
            name={`base_rate_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.baseRate ?? 1}
            readOnly
            type="currency"
            prefix=""
            align="right"
          />
        </div>
        <div
          id={`rate_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
        >
          <Input
            id={`rate_input_${localExposureJSON.uniqueKey}`}
            name={`rate_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.modRate ?? 1}
            readOnly={
              readonly === true
                ? true
                : isEndorsementOrAudit
                ? !isRateEditableForEndorsement
                : !isRateEditableForQuote
            }
            type="currency"
            prefix=""
            onChangeRawValue={(value) => onRateChange(value ?? null)}
            onBlur={(e) => onRateChange(e.target.value ?? null, true)}
            align="right"
          />
        </div>
        {!isEndorsementOrAudit && showNetRateInQuote && (
          <div
            id={`net_rate_cell_${localExposureJSON.uniqueKey}`}
            className={`${style.exposure_table_cell_container} ${style.exposure_table_width_5}`}
          >
            <Input
              id={`net_rate_input_${localExposureJSON.uniqueKey}`}
              name={`net_rate_input_${localExposureJSON.uniqueKey}`}
              value={localExposureJSON.netRate}
              readOnly
              type="currency"
              prefix=""
              align="right"
            />
          </div>
        )}
        {isEndorsementOrAudit && (
          <div
            id={`previous_premium_cell_${localExposureJSON.uniqueKey}`}
            className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
          >
            <Input
              id={`previous_premium_input_${localExposureJSON.uniqueKey}`}
              name={`previous_premium_input_${localExposureJSON.uniqueKey}`}
              value={localExposureJSON.previousPremium}
              readOnly
              type="currency"
              prefix=""
              align="right"
            />
          </div>
        )}
        <div
          id={`premium_cell_${localExposureJSON.uniqueKey}`}
          className={`${style.exposure_table_cell_container} ${style.exposure_table_width_10}`}
        >
          <Input
            id={`premium_input_${localExposureJSON.uniqueKey}`}
            name={`premium_input_${localExposureJSON.uniqueKey}`}
            value={localExposureJSON.manualPremiumAtMod ?? 0}
            readOnly
            type="currency"
            prefix=""
            align="right"
          />
        </div>
        <div
          id={`plus_minus_cell_${localExposureJSON.uniqueKey}`}
          className={style.exposure_table_plus_minus_cell_container}
        >
          <IconButton
            id={`plus_exposure_action_${localExposureJSON.uniqueKey}`}
            className="plus-minus-icon-container"
            sx={{ padding: "5px" }}
            disabled={readonly}
            onClick={() => addExposure()}
            true-element={"option-plus-icon"}
            color={readonly ? "secondary" : "default"}
          >
            <AddOutlined className="plus-action-button" fontSize="small" />
          </IconButton>
          {exposureJSONList.length > 1 && !localExposureJSON.existInOldJSON && (
            <IconButton
              id={`minus_exposure_action_${localExposureJSON.uniqueKey}`}
              className="plus-minus-icon-container"
              sx={{ padding: "5px" }}
              disabled={readonly}
              onClick={() => deleteExposure(localExposureJSON.uniqueKey)}
              true-element={"option-minus-icon"}
              color={readonly ? "secondary" : "default"}
            >
              <RemoveOutlined
                className="minus-action-button"
                fontSize="small"
              />
            </IconButton>
          )}
        </div>
      </div>
      <span
        id={`exposure_table_row_error_${localExposureJSON.uniqueKey}`}
        className={style.exposure_table_row_error}
      >
        {getClassCodeErrorMessage()}
      </span>
    </div>
  );
};

export default ExposureTableRow;
