import { BaseTableColumn } from "../../../dtos/base-table-column";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { FormattingDate } from "../../../utilities/dateFunctions";
import {
  isJsonString,
  splitPhoneNumberAndExtension,
} from "../../../utilities/stringFunctions";
import { WorkbookSheetRowCell, WorkbookSheetRow } from "@progress/kendo-ooxml";
import { getTagsAsCommaSeparatedString } from "./OLD_baseGridFunctions";
import { isNumericDataType } from "./baseGridFunctions";

export const formatColumnForExcelExport = (columns: BaseTableColumn[]) =>
  columns.map((column) => ({
    field: column?.fieldName,
    locked: true,
    width: 150,
  }));

const getAlternateDisplayValueForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) =>
  column.alternateDisplayValues?.find(
    (currentAlternateDisplayValue) =>
      currentAlternateDisplayValue.guid === tableValue
  )?.displayValuesOrFieldNames?.[0] ?? "";

const getTagOrOptionValueForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) => {
  if (isJsonString(tableValue)) {
    return getTagsAsCommaSeparatedString(
      tableValue,
      column?.displayOptions ?? []
    );
  } else {
    const columnOptionValues = column.displayOptions?.find(
      (x) => x.value === tableValue
    );

    return columnOptionValues?.text ?? "";
  }
};

const getFormattedPhoneNumberForExportToExcel = (tableValue: string) => {
  const match = splitPhoneNumberAndExtension(tableValue);
  const phoneNumber = match?.[1] ?? "";
  const extension = match?.[2] ? `-${match?.[2]}` : "";
  return `${phoneNumber}${extension}`;
};

const getCorrectValueByColumnTypeForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) => {
  if (
    column.type === BaseTableInputType.LINK ||
    column.type === BaseTableInputType.DOWNLOAD_LINK
  ) {
    return getAlternateDisplayValueForExportToExcel(column, tableValue);
  }
  if (
    column.type === BaseTableInputType.SELECT ||
    column.type === BaseTableInputType.TAGS
  ) {
    return getTagOrOptionValueForExportToExcel(column, tableValue);
  }
  if (column.type === BaseTableInputType.PHONE_AND_EXTENSION) {
    return getFormattedPhoneNumberForExportToExcel(tableValue);
  }
  if (
    column.type === BaseTableInputType.DATE_PICKER &&
    column.defaultValueType === DataTypeEnums.DATE
  ) {
    return FormattingDate(tableValue as any);
  }

  return tableValue;
};

const getTopHeaderRowForExcelExport = (columns: BaseTableColumn[]) =>
  [
    {
      cells: columns.map((column) => {
        return {
          value: column.displayName ?? "",
          format: "General",
          background: "#7A7A7A",
          color: "#FFFFFF",
          textAlign: "center",
          bold: true,
        } as WorkbookSheetRowCell;
      }),
    },
  ] as WorkbookSheetRow[];

const hydrateDataWithConfigurationForExportExcel = (
  columns: BaseTableColumn[],
  data: string[][]
) =>
  data.map((row: string[]) => {
    return {
      cells: (columns ?? []).map((column) => {
        const columnIndex = column._columnIndex;
        const correctTableValueFormat =
          getCorrectValueByColumnTypeForExportToExcel(column, row[columnIndex]);

        const isNumericColumn = isNumericDataType(
          column.defaultValueType ?? -1
        );
        const notANumberPattern = /[^0-9.,()]/g;
        // For now this "notANumberPattern" has to stay as a regex function because a number wrapped in a parenthesis is a valid negative format number
        // in the system atm and we need to support the actual cast and convertion to a negative number when exporting.

        const invalidNumberPattern = /[^0-9.]/g;
        // This is used to replace all the "," and "(", ")" that may be in the string as a representation of a string number using the replace function.

        const isNegativeStringNumber = correctTableValueFormat.includes("(");
        const isNumber = !notANumberPattern.test(correctTableValueFormat);

        if (
          correctTableValueFormat !== "" &&
          isNumber &&
          isNegativeStringNumber
        ) {
          //It's a negative number (wrapped in parenthesis).
          return {
            value:
              parseFloat(
                correctTableValueFormat.replaceAll(invalidNumberPattern, "")
              ) * -1,
            format: isNumericColumn ? "#,##0.0####" : null,
          } as WorkbookSheetRowCell;
        }
        if (
          correctTableValueFormat !== "" &&
          isNumber &&
          !isNegativeStringNumber
        ) {
          //it's a string representation of a number.
          return {
            value: parseFloat(
              correctTableValueFormat.replaceAll(invalidNumberPattern, "")
            ),
            format: isNumericColumn ? "#,##0.0####" : null,
          } as WorkbookSheetRowCell;
        }

        return {
          value: correctTableValueFormat,
        } as WorkbookSheetRowCell;
      }, {}),
    };
  }) as WorkbookSheetRow[];

export const getRowDataToExportToExcel = (
  filteredColumns: BaseTableColumn[],
  data: string[][]
) => {
  const topHeaderRowWithColumnTitles =
    getTopHeaderRowForExcelExport(filteredColumns);

  return topHeaderRowWithColumnTitles.concat(
    hydrateDataWithConfigurationForExportExcel(filteredColumns, data)
  );
};
