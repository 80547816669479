import { Theme } from "./media/themeTypes";

export const layoutStyles: any = (theme: Theme) => ({
  color: theme?.TEXT_COLOR,
  fontSize: theme?.BODY.SIZE,
  fontWeight: theme?.BODY.WEIGHT,
  display: "flex",
  height: "100%",
  // This is bad, don't do this. It overrides all colors site wide.
  // If you need to target a specific element, do not target root classes.
  // If you need a global solution, create a class that can be applied globally.

  // ".MuiBox-root, & .MuiPaper-root": {
  //   color: theme?.TEXT_COLOR,
  //   background: theme?.PRIMARY_BACKGROUND,
  // },
  a: {
    color: theme?.PRIMARY,
  },
  ".MuiBox-root, ul, .MuiPaper-root, div": {
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: theme?.SECONDARY_BACKGROUND,
      borderRadius: "5px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme?.SECONDARY_BACKGROUND,
    },
  },
  "#left_wrapper": {
    flexGrow: 0,
  },
  "#top_wrapper": {
    height: "58px",
    // padding: "5px",
    // backgroundColor: "red",
  },
  "#right_wrapper": {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "flex-start",
    overflow: "hidden",
    height: "100vh",
    "#body_wrapper": {
      // padding: "5px",
      // backgroundColor: "blue",
      flexGrow: 1,
      display: "flex",
      alignSelf: "auto",
      overflow: "hidden",
      background: theme?.PRIMARY_BACKGROUND,
    },
  },
  ".loading-box": {
    position: "absolute",
    margin: "10px",
    zIndex: 1000,
    width: 0,
    height: 0,
    overflow: "visible",
  },
  ".icon-bottom-name": {
    color: theme?.PRIMARY,
  },
});
