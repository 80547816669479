import { FC, useEffect, useState } from "react";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PolicyLossBlob } from "../../../../dtos/policy-loss-blob";
import { useApiPost } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { BaseTable2, Col, Row } from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { OPTION_COLUMN_FIELD_NAME } from "../../../TrueUI/Tables/TableConstants";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import {
  LOSS_HISTORY_SECTION,
  LossHistoryTableProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
} from "../updatesPolicyQuoteFunctions";
import { getLossHistoryTableColumns } from "./LossHistoryTableConfiguration";
import {
  allLossesAreValid,
  getLossesAsTableData,
  getLossesByTableData,
} from "./LossHistoryTableUtils";
import { PolicyBlob } from "../../../../dtos/policy-blob";

const LossHistoryTable: FC<LossHistoryTableProps> = ({
  insuredId,
  tabKey,
  tableDisplayOptions,
  quoteStatus,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const tableName = `loss_history_table_${insuredId}`;
  const [losses, setLosses] = useState<PolicyLossBlob[]>([]);
  const [dialogValues, setDialogValues] = useState<{
    description: string;
    open: boolean;
  }>({ description: "Loss history saved", open: false });
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${LOSS_HISTORY_SECTION}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const saveLossHistory = () => {
    const atomValue = getAtom();
    if (allLossesAreValid(losses) === true) {
      const newAtomValue = updatePolicyQuote(atomValue, "lossHistory", losses);
      setAtom(newAtomValue);
      setReadyToSave(true);
    } else {
      setDialogValues({
        description:
          "There are losses missing required fields (Year, Claim Number, State, Type, Status, Paid or Incurred)",
        open: true,
      });
    }
  };

  useEffect(() => {
    if (readyToSave === true) {
      dispatchPost();
    }
  }, [readyToSave]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setReadyToSave(false);
      setDialogValues({ description: "Loss history saved", open: true });
    }
  }, [responsePost]);

  const updateLossesByTableChanged = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    setLosses(getLossesByTableData(data, columns, tableDisplayOptions));
  };

  const getInitialTableData = (): string[][] => {
    const atomValue = getAtom();
    const lossHistory =
      atomValue?.policyQuoteInformation?.policyQuote?.lossHistory;
    if (lossHistory !== undefined && lossHistory !== null) {
      return getLossesAsTableData(lossHistory);
    }
    return [];
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: getLossHistoryTableColumns(
        tableDisplayOptions?.stateList ?? [],
        tableDisplayOptions?.lossTypeList ?? [],
        tableDisplayOptions?.claimStatusList ?? []
      ),
      data: getInitialTableData(),
    },
    events: { onDataChange: updateLossesByTableChanged },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      optionsColumnConfiguration: { isHidden: false, optionType: "delete" },
      tableStyle: { height: "auto" },
    },
    toolbarOptions: {
      showEditButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSaveButton: false,
      showSortFilter: false,
      addButtonText: "Add Loss",
    },
    columnOptions: [
      {
        fieldName: "PolicyYear",
        thousandSeparator: false,
        maxNumericValue: 9999,
      },
      { fieldName: "State", width: 10, selectMaxHeight: "200px" },
      { fieldName: "Type", width: 8 },
      { fieldName: "Status", width: 8 },
      {
        fieldName: "Paid",
        maxNumericValue: 2147483647,
      },
      {
        fieldName: "Incurred",
        maxNumericValue: 2147483647,
      },
      {
        fieldName: "Recovery",
        maxNumericValue: 2147483647,
      },
      { fieldName: OPTION_COLUMN_FIELD_NAME, width: 5 },
    ],
  };

  useBaseTable(tableConfiguration);

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <div className="policy-scrollable-body-container">
        <Col>
          <BaseTable2 name={tableName} />
        </Col>
      </div>
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={LOSS_HISTORY_SECTION}
        quoteStatus={quoteStatus}
        saveEvent={saveLossHistory}
      />
      <DialogConfirmation
        dialogDescriptionText={dialogValues.description}
        open={dialogValues.open}
        optionYesOverrideLabel="OK"
        onOptionYesEvent={(close) =>
          setDialogValues({ ...dialogValues, open: close })
        }
        onCloseEvent={(close) =>
          setDialogValues({ ...dialogValues, open: close })
        }
      />
    </Row>
  );
};

export default LossHistoryTable;
