import { FC, useEffect, useState } from "react";
import { Input } from "../../TrueUI";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import {
  dateTypes,
  numericTypes,
  otherTypes,
} from "./BaseGridColumnFilterConstantsAndTypes";
import { ColumnFilterExtensionFilterGateFor } from "../SupportFunctions/baseGridColumnFilterFunctions";
import Date_NEW from "../../TrueUI/Inputs_NEW/Date_NEW";

type BaseGridColumnFilterTypeSelectorProperties = {
  id: string;
  name: string;
  column: BaseTableColumn;
  defaultValue: string | null;
  resetValue: boolean;
  onChange: (value?: any) => void;
};

const BaseGridColumnFilterTypeSelector: FC<
  BaseGridColumnFilterTypeSelectorProperties
> = ({ id, name, column, defaultValue, resetValue, onChange }) => {
  const [val, setVal] = useState<string | null>("");

  useEffect(() => {
    if (resetValue === true) {
      setVal("");
    }
  }, [resetValue]);

  useEffect(() => {
    if (
      defaultValue !== undefined &&
      defaultValue !== null &&
      defaultValue !== ""
    ) {
      setVal(defaultValue);
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {ColumnFilterExtensionFilterGateFor([dateTypes], column) && (
        <Date_NEW
          id={`${id}_${
            column?.displayName?.toLocaleLowerCase() ?? "NO_DISPLAY_NAME_FOUND"
          }`}
          name={`${name}_${
            column?.displayName?.toLocaleLowerCase() ?? "NO_DISPLAY_NAME_FOUND"
          }`}
          defaultValue={val ?? ""}
          onDateChange={onChange}
        />
      )}

      {ColumnFilterExtensionFilterGateFor(
        [otherTypes, numericTypes],
        column
      ) && (
        <Input
          id={`${id}_${
            column?.displayName?.toLocaleLowerCase() ?? "NO_DISPLAY_NAME_FOUND"
          }`}
          name={`${name}_${
            column?.displayName?.toLocaleLowerCase() ?? "NO_DISPLAY_NAME_FOUND"
          }`}
          value={val}
          onChange={(e) => {
            onChange(e.target.value);
            setVal(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default BaseGridColumnFilterTypeSelector;
