import { FC, useEffect, useState } from "react";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { CustomFieldDefinitionTableDto } from "../../../../../dtos/custom-field-definition-table-dto";
import CustomFields from "./CustomFields";
import { useApiGet } from "../../../../../hooks";

type CustomFieldsType = {
  programId: number;
};

const CustomFieldsWrapper: FC<CustomFieldsType> = ({ programId }) => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [customFieldValues, setCustomFieldValues] =
    useState<CustomFieldDefinitionTableDto | null>();

  const { responseGet, dispatchGet } = useApiGet<CustomFieldDefinitionTableDto>(
    `api/AdminTools/CustomField/GetCustomFieldDefinitionTable?programId=${programId}`
  );

  const refreshTableEvent = () => setRefreshTable(true);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCustomFieldValues(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (refreshTable) {
      dispatchGet();
      setRefreshTable(false);
      setCustomFieldValues(null);
    }
  }, [refreshTable]);

  return customFieldValues ? (
    <CustomFields
      customFieldValues={customFieldValues}
      refreshTable={refreshTableEvent}
    />
  ) : null;
};
export default CustomFieldsWrapper;
