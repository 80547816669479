import { Theme } from "../../../../media/themeTypes";

export const modalBindPolicyBodyStyle = () => ({
  ".MuiDialogContent-root": {
    pb: 0,
    pt: "16px !important",
    px: 2,
  },
});

export const modalBindPolicyButtonStyle = () => ({
  marginLeft: 1,
  marginRight: 1,
});

export const modalBindPolicyIconStyle = (theme: Theme, hasError: boolean) => ({
  fontSize: "87.5px",
  color: hasError ? theme?.DANGER : theme?.SUCCESS,
});
