import { Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ClaimantContainerDto } from "../../../../../../dtos/claimant-container-dto";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  Col,
  Collapse,
  Font,
  Input,
  InputAddress,
  InputDate,
  InputPhone,
  InputTax,
  Row,
  Select,
} from "../../../../../TrueUI";
import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { RowProps } from "../../../../../TrueUI/Grids/Row";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import Link from "../../../../../TrueUI/Typography/Link";
import { formInputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

type ClaimClaimantContainerProps = {
  configuration?: DetailsConfigurationDto;
  claimClaimantData?: ClaimantContainerDto;
  errorDetails?: any;
  insuredIdAtomKey?: string;
  hasClaimsManagementPermissions: boolean;
};

const rowConfiguration: RowProps = {
  verticalAlign: "flex-start",
  horizontalAlign: "flex-start",
  horizontalMargin: "0px",
  horizontalGutter: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",

  allowWrap: true,
};

const colConfiguration: ColProps = {
  breakpoints: { md: 3, lg: 3, xl: 3 },
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
};

const colConfiguration4: ColProps = {
  breakpoints: { md: 4, lg: 4, xl: 4 },
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
};
const selectConfiguration = (
  id: string,
  name: string,
  label: string,
  readOnly: boolean
) => {
  return {
    id: id,
    name: name,
    label: label,
    labelStyle: { fontWeight: 600 },
    readOnly: readOnly,
  };
};

const ClaimClaimantContainer: FC<ClaimClaimantContainerProps> = ({
  claimClaimantData,
  configuration,
  errorDetails,
  insuredIdAtomKey = "",
  hasClaimsManagementPermissions,
}) => {
  const [claimantUI, setClaimantUI] = useState(claimClaimantData ?? null);
  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const readOnly = !claimantUI?.isExpanded ?? false;

  const setIncidentData = (updatedContainerData: any) => {
    const atomValue = getAtom();

    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "claimantContainerData",
      updatedContainerData
    );

    setAtom(newAtomValue);
    setClaimantUI(updatedContainerData);
  };

  const updateData = (fieldName: string, value: any) => {
    setIncidentData({
      ...claimantUI,
      [fieldName]: value,
    });
  };

  const updateMultipleData = (fieldNames: string[], values: any[]) => {
    const newValues = fieldNames.map((targetProperty, index) => ({
      [targetProperty]: values[index],
    }));
    const targetJoined = Object.assign({}, ...newValues);
    setIncidentData({
      ...claimantUI,
      ...targetJoined,
    });
  };

  useEffect(() => {
    setClaimantUI(claimClaimantData ?? null);
  }, [claimClaimantData]);

  const listenerClaimantComponent = useRecoilValue(
    TriggerComponentUpdateAtom("claimantComponent")
  );

  useEffect(() => {
    if (listenerClaimantComponent !== null) {
      const updatedAtom = getAtom();
      setClaimantUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.claimantContainerData,
      });
    }
  }, [listenerClaimantComponent]);

  useEffect(() => {
    if (conditionHasValue(errorDetails)) updateData("isExpanded", true);
  }, [errorDetails]);

  return (
    <Collapse
      id="claim-details-claimant-collapse"
      collapsedHeight={"200px"}
      hasContentOnReadOnly
      title={
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"Claimant"}
          name="claimantCollapseTitleLink"
        ></Link>
      }
      content={
        readOnly ? (
          <Stack width={"100%"} zIndex={99}>
            <Row {...rowConfiguration}>
              <Col {...colConfiguration}>
                <Input
                  id="readonly-id-first-name"
                  name="claimantCollapseReadOnlyFirstName"
                  label="First Name"
                  readOnly={readOnly}
                  value={claimantUI?.firstName}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <Input
                  id="readonly-id-last-name"
                  name="claimantCollapseReadOnlyLastName"
                  label="Last Name"
                  rows={1}
                  readOnly={readOnly}
                  value={claimantUI?.lastName}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <Input
                  id="readonly-id-email"
                  name="claimantCollapseReadOnlyEmail"
                  label="Email Address"
                  rows={1}
                  readOnly={readOnly}
                  value={claimantUI?.emailAddress ?? ""}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <InputPhone
                  id="readonly-id-phone1"
                  name="claimantCollapseReadOnlyPhone1"
                  phoneLabel="Phone"
                  readOnly={readOnly}
                  phoneValue={claimantUI?.phone1}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
            </Row>
            <Row {...rowConfiguration}>
              <Col {...colConfiguration}>
                <InputTax
                  id="readonly-id-ssn"
                  name="claimantCollapseReadOnlySnn"
                  label="SSN"
                  initialType="SSN"
                  readOnly={readOnly}
                  value={claimantUI?.ssn ?? ""}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <InputDate
                  id="readonly-id-date-of-birth"
                  name="claimantCollapseReadOnlyDateOfBirth"
                  label="Date of Birth"
                  readOnly={readOnly}
                  value={claimantUI?.dateOfBirth}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <Select
                  {...selectConfiguration(
                    "readonly-id-marital-status",
                    "claimantCollapseReadOnlyMaritalStatus",
                    "Marital Status",
                    readOnly
                  )}
                  options={configuration?.maritalStatus ?? []}
                  value={claimantUI?.maritalStatus}
                  onChange={(value) => updateData("maritalStatus", value)}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col {...colConfiguration}>
                <Input
                  id="readonly-id-number-of-dependents"
                  name="claimantCollapseReadOnlyNumberOfDependents"
                  label="# of Dependents"
                  type="number"
                  readOnly={readOnly}
                  value={claimantUI?.numberOfDependents}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
            </Row>
          </Stack>
        ) : (
          <Stack width={"100%"} zIndex={99}>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={42}
                  {...formInputConfiguration(
                    "id-first-name",
                    "claimantCollapseFirstName",
                    "First Name",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={50}
                  value={claimantUI?.firstName}
                  errorMessage={errorDetails?.firstName}
                  onChangeRawValue={(value) => updateData("firstName", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={43}
                  {...formInputConfiguration(
                    "id-last-name",
                    "claimantCollapseLastName",
                    "Last Name",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={50}
                  value={claimantUI?.lastName}
                  errorMessage={errorDetails?.lastName}
                  onChangeRawValue={(value) => updateData("lastName", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={44}
                  {...formInputConfiguration(
                    "id-middle-name",
                    "claimantCollapseMiddleName",
                    "Middle Name",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={50}
                  value={claimantUI?.middleName}
                  errorMessage={errorDetails?.middleName}
                  onChangeRawValue={(value) =>
                    updateData("middleName", value === null ? "" : value)
                  }
                />
              </Col>
              <Col {...colConfiguration} breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  tabIndex={45}
                  {...formInputConfiguration(
                    "id-suffix",
                    "claimantCollapseSuffix",
                    "Suffix",
                    !hasClaimsManagementPermissions
                  )}
                  options={configuration?.claimantSuffixes ?? []}
                  value={claimantUI?.suffix}
                  errorMessage={errorDetails?.suffix}
                  onChange={(value) => updateData("suffix", value)}
                />
              </Col>
            </Row>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <InputTax
                  tabIndex={46}
                  {...formInputConfiguration(
                    "id-ssn",
                    "claimantCollapseSnn",
                    "SSN",
                    !hasClaimsManagementPermissions
                  )}
                  initialType="SSN"
                  value={claimantUI?.ssn ?? ""}
                  errorMessage={errorDetails?.ssn}
                  onChangeRaw={({ value, type }) =>
                    updateMultipleData(["ssn", "taxIdType"], [value, type])
                  }
                />
              </Col>
              <Col {...colConfiguration4}>
                <InputDate
                  tabIndex={47}
                  {...formInputConfiguration(
                    "id-date-of-birth",
                    "claimantCollapseDateOfBirth",
                    "Date of Birth",
                    !hasClaimsManagementPermissions
                  )}
                  value={claimantUI?.dateOfBirth}
                  errorMessage={errorDetails?.dateOfBirth}
                  onChangeRawValue={(value) => updateData("dateOfBirth", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={48}
                  {...formInputConfiguration(
                    "id-gender",
                    "claimantCollapseGender",
                    "Gender",
                    !hasClaimsManagementPermissions
                  )}
                  options={configuration?.claimantGenders ?? []}
                  value={claimantUI?.gender}
                  errorMessage={errorDetails?.gender}
                  onChange={(value) => updateData("gender", value)}
                />
              </Col>
            </Row>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={49}
                  {...formInputConfiguration(
                    "id-age-at-accident",
                    "ageAtAccident",
                    "Age at Accident",
                    true
                  )}
                  value={claimantUI?.ageAtAccident}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={49}
                  {...formInputConfiguration(
                    "id-age-today",
                    "ageToday",
                    "Age Today",
                    true
                  )}
                  value={claimantUI?.ageToday}
                />
              </Col>
              <Col {...colConfiguration4} colDirection="column">
                <Font fontType="BOLD_CAPTION">{"Height"}</Font>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: "5px" }}>
                    <Input
                      tabIndex={49}
                      {...formInputConfiguration(
                        "id-claimant-height-ft",
                        "claimantHeightFt",
                        "Ft",
                        !hasClaimsManagementPermissions
                      )}
                      labelPosition="start"
                      labelFontType="CAPTION"
                      maxLength={1}
                      value={claimantUI?.claimantHeightFt}
                      onChangeRawValue={(value) =>
                        updateData("claimantHeightFt", value)
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    <Input
                      tabIndex={49}
                      {...formInputConfiguration(
                        "id-claimant-height-in",
                        "claimantHeightIn",
                        "In",
                        !hasClaimsManagementPermissions
                      )}
                      labelPosition="start"
                      labelFontType="CAPTION"
                      maxLength={2}
                      value={claimantUI?.claimantHeightIn}
                      onChangeRawValue={(value) =>
                        updateData("claimantHeightIn", value)
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col {...colConfiguration4} breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Input
                  tabIndex={49}
                  {...formInputConfiguration(
                    "id-claimant-weight",
                    "claimantWeight",
                    "Weight",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={3}
                  value={claimantUI?.claimantWeight}
                  onChangeRawValue={(value) =>
                    updateData("claimantWeight", value)
                  }
                />
              </Col>
            </Row>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <InputPhone
                  tabIndex={49}
                  {...formInputConfiguration(
                    "id-phone1",
                    "claimantCollapsePhone1",
                    "Phone 1",
                    !hasClaimsManagementPermissions
                  )}
                  phoneValue={claimantUI?.phone1}
                  errorMessagePhone={errorDetails?.phone1}
                  onChangePhoneValue={(value) => updateData("phone1", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={50}
                  {...formInputConfiguration(
                    "id-phone1-type",
                    "claimantCollapsePhone1Type",
                    "",
                    !hasClaimsManagementPermissions
                  )}
                  label="Phone 1 Type"
                  options={configuration?.claimantPhoneType ?? []}
                  value={claimantUI?.phone1Type}
                  errorMessage={errorDetails?.phone1Type}
                  onChange={(value) => updateData("phone1Type", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Input
                  tabIndex={51}
                  {...formInputConfiguration(
                    "id-email",
                    "claimantCollapseEmail",
                    "Email",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={100}
                  value={claimantUI?.emailAddress}
                  errorMessage={errorDetails?.emailAddress}
                  onChangeRawValue={(value) =>
                    updateData("emailAddress", value)
                  }
                />
              </Col>
            </Row>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <InputPhone
                  tabIndex={52}
                  {...formInputConfiguration(
                    "id-phone2",
                    "claimantCollapsePhone2",
                    "Phone 2",
                    !hasClaimsManagementPermissions
                  )}
                  phoneValue={claimantUI?.phone2}
                  errorMessagePhone={errorDetails?.phone2}
                  onChangePhoneValue={(value) => updateData("phone2", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={53}
                  {...formInputConfiguration(
                    "id-phone2-type",
                    "claimantCollapsePhone2Type",
                    "",
                    !hasClaimsManagementPermissions
                  )}
                  label="Phone 2 Type"
                  options={configuration?.claimantPhoneType ?? []}
                  value={claimantUI?.phone2Type}
                  errorMessage={errorDetails?.phone2Type}
                  onChange={(value) => updateData("phone2Type", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={54}
                  {...formInputConfiguration(
                    "id-marital-status",
                    "claimantCollapseMaritalStatus",
                    "Marital Status",
                    !hasClaimsManagementPermissions
                  )}
                  options={configuration?.maritalStatus ?? []}
                  value={claimantUI?.maritalStatus}
                  errorMessage={errorDetails?.maritalStatus}
                  onChange={(value) => updateData("maritalStatus", value)}
                />
              </Col>
              <Col {...colConfiguration} breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Input
                  {...formInputConfiguration(
                    "id-number-of-dependents",
                    "claimantCollapseNumberOfDependents",
                    "# of Dependents",
                    !hasClaimsManagementPermissions
                  )}
                  type="number"
                  minNumericValue={0}
                  maxNumericValue={9999}
                  value={claimantUI?.numberOfDependents}
                  errorMessage={errorDetails?.numberOfDependents}
                  onChangeRawValue={(value) =>
                    updateData("numberOfDependents", value)
                  }
                />
              </Col>
            </Row>
            <Row {...rowConfiguration} numberOfColumns={14}>
              <Col {...colConfiguration4}>
                <InputPhone
                  tabIndex={55}
                  {...formInputConfiguration(
                    "id-phone3",
                    "claimantCollapsePhone3",
                    "Phone 3",
                    !hasClaimsManagementPermissions
                  )}
                  phoneValue={claimantUI?.phone3}
                  errorMessagePhone={errorDetails?.phone3}
                  onChangePhoneValue={(value) => updateData("phone3", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={56}
                  {...formInputConfiguration(
                    "id-phone3-type",
                    "claimantCollapsePhone3Type",
                    "",
                    !hasClaimsManagementPermissions
                  )}
                  label="Phone 3 Type"
                  options={configuration?.claimantPhoneType ?? []}
                  value={claimantUI?.phone3Type}
                  errorMessage={errorDetails?.phone3Type}
                  onChange={(value) => updateData("phone3Type", value)}
                />
              </Col>
              <Col {...colConfiguration4}>
                <Select
                  tabIndex={57}
                  {...formInputConfiguration(
                    "id-primary-language",
                    "claimantCollapsePrimaryLanguage",
                    "Primary Language",
                    !hasClaimsManagementPermissions
                  )}
                  options={configuration?.primaryLanguages ?? []}
                  value={claimantUI?.primaryLanguage}
                  errorMessage={errorDetails?.primaryLanguage}
                  onChange={(value) => updateData("primaryLanguage", value)}
                />
              </Col>
            </Row>
            <Row {...rowConfiguration}>
              <InputAddress
                tabIndex={58}
                {...formInputConfiguration(
                  "id-claimant-location-address-input",
                  "claimantCollapseAddress",
                  "Address",
                  !hasClaimsManagementPermissions
                )}
                initValueAddress1={claimantUI?.address ?? ""}
                initValueAddress2={claimantUI?.address2}
                initValueCity={claimantUI?.city ?? ""}
                initValueStateCode={claimantUI?.state ?? ""}
                initValuePostCode={claimantUI?.postalCode ?? ""}
                initValueCounty={claimantUI?.county ?? ""}
                address1ErrorMessage={errorDetails?.address}
                address2ErrorMessage={errorDetails?.address2}
                cityErrorMessage={errorDetails?.city}
                stateCodeErrorMessage={errorDetails?.state}
                postCodeErrorMessage={errorDetails?.postalCode}
                showSingleLabel={false}
                showLabelCounty
                showCounty
                onChangeValueAddress1={(value) => updateData("address", value)}
                onChangeValueAddress2={(value) => updateData("address2", value)}
                onChangeValueCity={(value) => updateData("city", value)}
                onChangeStateCode={(value) => {
                  updateData("state", value);
                }}
                onChangeValuePostCode={(value) => {
                  updateData("postalCode", value);
                }}
                onChangeValueCounty={(value) => {
                  updateData("county", value);
                }}
              />
            </Row>
            <Row {...rowConfiguration}>
              <Col {...colConfiguration}>
                <InputDate
                  tabIndex={65}
                  {...formInputConfiguration(
                    "id-date-of-death",
                    "claimantCollapseDateOfDeath",
                    "Date of Death",
                    !hasClaimsManagementPermissions
                  )}
                  value={claimantUI?.dateOfDeath}
                  errorMessage={errorDetails?.dateOfDeath}
                  onChangeRawValue={(value) => updateData("dateOfDeath", value)}
                />
              </Col>
              <Col {...colConfiguration}>
                <Input
                  tabIndex={66}
                  {...formInputConfiguration(
                    "id-hicn-number",
                    "claimantCollapseHealthInsuranceClaimNumber",
                    "HICN Number",
                    !hasClaimsManagementPermissions
                  )}
                  maxLength={25}
                  value={claimantUI?.hicnNumber}
                  errorMessage={errorDetails?.hicnNumber}
                  onChangeRawValue={(value) => updateData("hicnNumber", value)}
                />
              </Col>
            </Row>
          </Stack>
        )
      }
      expanded={!readOnly}
      onChange={(value) => {
        updateData("isExpanded", value);
        setComponentTriggers(["buttonComponent"]);
      }}
    />
  );
};

export default ClaimClaimantContainer;
