import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import {
  Col,
  Divider,
  Font,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../TrueUI";
import { RowProps, rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import style from "../AuditForm/Audit.module.css";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import { AuditResultsUIProps } from "../AuditForm/AuditTypes";
import {
  AUDIT_DISPOSITION,
  getAtomUpdatedByAuditResultsUI,
  getAtomUpdatedByChangedValues,
  getAuditResultsUIByAtomValues,
  getAuditResultsUIDefaultValues,
} from "./AuditResultsUtils";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { formatToCurrency } from "../../../../utilities/stringFunctions";
import { SelectOptions } from "../../../../dtos/select-options";

const breakpointForRowWithSevenEqualColumns = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 2,
};

const defaultRowProps = {
  ...rowWithNoMarginNorGutter,
  horizontalAlign: "flex-start",
  numberOfColumns: 14,
} as RowProps;

const AuditResults: FC<EndorsementGeneralProps> = ({ tabKey, readonly }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [auditResultsUI, setAuditResultsUI] = useState<AuditResultsUIProps>();

  const setIncomingValuesToLocalHook = () => {
    const atomValue = getAtom();

    const defaultAuditUIValue = readonly
      ? getAuditResultsUIByAtomValues(atomValue)
      : getAuditResultsUIDefaultValues(atomValue);
    const newAtomValue = getAtomUpdatedByAuditResultsUI(
      defaultAuditUIValue,
      atomValue
    );

    if (!readonly) setAtom(newAtomValue);
    setAuditResultsUI(defaultAuditUIValue);
  };

  const updateAtomAndLocalHookByChange = (
    targetValue: any,
    targetName: string
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByChangedValues(
      targetValue,
      targetName,
      atomValue
    ) as GlobalInsuredAtomProperties | null;
    const newAuditUIValue = getAuditResultsUIByAtomValues(newAtomValue);

    setAtom(newAtomValue);
    setAuditResultsUI(newAuditUIValue);
  };

  const getOptionValueAndUpdateLocalHook = (
    value: any,
    optionsList: Partial<SelectOptions>[],
    targetName: string
  ) => {
    const optionFound = optionsList.find((option) => option.intValue === value);

    updateAtomAndLocalHookByChange(
      {
        value: optionFound?.intValue,
        description: optionFound?.displayName,
      } as PolicyEnumValueDescriptionAndBooleanBlob,
      targetName
    );
  };

  useEffect(() => {
    setIncomingValuesToLocalHook();
  }, [readonly]);

  return auditResultsUI ? (
    <div id="audit-results-container-id">
      <Row {...defaultRowProps}>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Input
            id="audited-premium-id"
            name="audited-premium"
            type="fixedCurrency"
            decimalScale={2}
            prefix=""
            label="Audited Premium"
            align="right"
            readOnly
            value={auditResultsUI.auditPremium}
          />
        </Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Input
            id="audited-fees-id"
            name="audited-fees"
            type="fixedCurrency"
            decimalScale={2}
            prefix=""
            label="Audited Fees"
            align="right"
            readOnly
            value={auditResultsUI.auditFees}
          />
        </Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Input
            id="total-audit-id"
            name="total-audit"
            type="fixedCurrency"
            decimalScale={2}
            prefix=""
            label="Total Audit"
            align="right"
            readOnly
            value={auditResultsUI.totalAudit}
          />
        </Col>
      </Row>
      <Row {...defaultRowProps}>
        <Col breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Input
            id="total-paid-id"
            name="total-paid"
            type="fixedCurrency"
            decimalScale={2}
            prefix=""
            label="Total Paid"
            align="right"
            readOnly
            value={auditResultsUI.totalPaid}
          />
        </Col>
      </Row>
      <Row {...defaultRowProps}>
        <Col breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Divider
            height={"1px"}
            width={"100%"}
            showBorder
            borderSize={"1px"}
          />
        </Col>
      </Row>
      <Row {...defaultRowProps}>
        <Col breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Input
            id="audit-results-id"
            name="audit-results"
            label="Audit Results"
            align="right"
            readOnly
            value={
              auditResultsUI.auditResults < 0
                ? `(${formatToCurrency(Math.abs(auditResultsUI.auditResults))})`
                : formatToCurrency(auditResultsUI.auditResults)
            }
          />
        </Col>
        <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
          <Select
            id="audit-disposition-id"
            name="audit-disposition"
            label="Audit Disposition"
            labelFontType="BOLD_CAPTION"
            options={auditResultsUI.auditDispositionList}
            value={auditResultsUI.auditDisposition}
            readOnly={readonly}
            onChange={(value) =>
              getOptionValueAndUpdateLocalHook(
                value,
                auditResultsUI.auditDispositionList,
                AUDIT_DISPOSITION
              )
            }
          />
        </Col>
      </Row>
      {auditResultsUI.displayExtraInvoiceInformation && (
        <>
          <Row {...defaultRowProps}>
            <Col breakpoints={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}></Col>
            <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
              <Input
                id="invoice-number-id"
                name="invoice-number"
                label="Invoice Number"
                readOnly
                value={auditResultsUI.invoiceNumber}
              />
            </Col>
            <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
              <InputDate
                id="date-billed-id"
                name="date-billed"
                label="Date Billed"
                readOnly
                value={auditResultsUI.dateBilled}
              />
            </Col>
            <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
              <InputDate
                id="due-date-id"
                name="due-date"
                label="Due"
                readOnly
                value={auditResultsUI.dueDate}
              />
            </Col>
            <Col breakpoints={breakpointForRowWithSevenEqualColumns}>
              <InputDate
                id="late-date-id"
                name="late-date"
                label="Late"
                readOnly
                value={auditResultsUI.lateDate}
              />
            </Col>
          </Row>
          {!readonly && (
            <Font
              className={style.informative_message_audit_results}
              display="block"
              textAlign="end"
            >
              Audit is not complete, dates are provided for reference only
            </Font>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default AuditResults;
