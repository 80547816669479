import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import { globalOptions } from "../../../GlobalAtoms";
import SubSideNav from "../../TrueUI/SubSideNav/SubSideNav";
import ProgramBodyWrapper from "./ProgramBodyWrapper";
import { NavItemDto } from "../../../dtos/nav-item-dto";
import { getQueryValue } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  PROGRAM_CHILD_ITEM,
  PROGRAM_PARENT_ITEM,
} from "../../../utilities/queryStringsHash";
import { NavSubItemDto } from "../../../dtos/nav-sub-item-dto";
import themes from "../../../media/TrueTheme";

type ProgramMainInstanceWrapperProps = {
  isNewProgram: boolean;
  navItems: NavItemDto[];
};

const ProgramMainInstanceWrapper: FC<ProgramMainInstanceWrapperProps> = ({
  isNewProgram,
  navItems,
}) => {
  const [body, setBody] = useState<number>(0);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const onChildItemClick = (_parent: NavItemDto, child: NavSubItemDto) => {
    setBody(child.section);
  };

  const queryChildValue =
    getQueryValue(PROGRAM_CHILD_ITEM) != ""
      ? parseInt(getQueryValue(PROGRAM_CHILD_ITEM))
      : 0;

  const queryParentValue =
    getQueryValue(PROGRAM_PARENT_ITEM) != ""
      ? parseInt(getQueryValue(PROGRAM_PARENT_ITEM))
      : 0;

  useEffect(() => {
    const item = navItems.find((item) => item.index === queryParentValue);
    const childItem = item?.subList?.find(
      (subitem) => subitem.index === queryChildValue
    );
    setBody(childItem?.section ?? 0);
  }, [navItems]);

  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme?.BORDER_COLOR}`,
        minHeight: "100%",
        display: "flex",
        width: "100%",
      }}
    >
      <div
        id={"left_program_wrapper"}
        style={{
          minWidth: 180,
        }}
      >
        <SubSideNav
          navItems={navItems}
          onChildItemClick={onChildItemClick}
          parentSelected={queryParentValue}
          childSelected={queryChildValue}
          parentQueryString={PROGRAM_PARENT_ITEM}
          childQueryString={PROGRAM_CHILD_ITEM}
        />
      </div>
      <div
        id={"right_program_wrapper"}
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProgramBodyWrapper bodySection={body} isNewProgram={isNewProgram} />
      </div>
    </Box>
  );
};

export default ProgramMainInstanceWrapper;
