import { BaseTableInputType } from "../../../../../../../dtos/base-table-input-type";
import { ClaimReferenceDto } from "../../../../../../../dtos/claim-reference-dto";
import { parseFloatValueIfExist } from "../../../../../../../utilities/stringFunctions";
import { ConditionForCellResponse } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { getIntValueByStringNameFromClaimReference } from "../ClaimFinancialUtil";

export const determinateTypeOfCellOnInit = (
  totalReserveRuleLimit: number | null,
  totalReservesAmount: number | null,
  currentValue: string
): ConditionForCellResponse | void => {
  if (
    totalReserveRuleLimit !== null &&
    totalReservesAmount !== null &&
    totalReservesAmount > totalReserveRuleLimit
  ) {
    return {
      value: currentValue,
      typeId: BaseTableInputType.INPUT_WITH_ICON,
    };
  } else {
    return {
      value: currentValue,
      typeId: BaseTableInputType.CURRENCY,
    };
  }
};

export const determinateTypeOfCellOnChange = (
  totalReserveRuleLimit: number | null,
  totalReservesAmount: number | null,
  reserveRuleLimit: number | null,
  currentValue: string
): ConditionForCellResponse | void => {
  if (
    totalReserveRuleLimit !== null &&
    totalReservesAmount !== null &&
    totalReservesAmount > totalReserveRuleLimit
  ) {
    return {
      value: currentValue,
      typeId: BaseTableInputType.INPUT_WITH_ICON,
    };
  } else {
    if (
      reserveRuleLimit !== null &&
      parseFloat(currentValue) > reserveRuleLimit
    ) {
      return {
        value: currentValue,
        typeId: BaseTableInputType.INPUT_WITH_ICON,
      };
    } else {
      return {
        value: currentValue,
        typeId: BaseTableInputType.CURRENCY,
      };
    }
  }
};

export const getReserveItemStatus = (
  reserveRuleLimitIndex: number,
  totalReserveRuleLimitIndex: number,
  totalReservesAmountIndex: number,
  row: any,
  amount: string,
  reserveStatusReference: ClaimReferenceDto[]
) => {
  const totalReservesAmount = parseFloatValueIfExist(
    row[totalReservesAmountIndex]
  );
  const totalReserveRuleLimit = parseFloatValueIfExist(
    row[totalReserveRuleLimitIndex]
  );
  const floatAmount = parseFloatValueIfExist(amount);
  const reserveRuleLimit = parseFloatValueIfExist(row[reserveRuleLimitIndex]);

  const statusIdByTotalReserveAmount =
    totalReservesAmount !== null &&
    totalReserveRuleLimit !== null &&
    totalReservesAmount > totalReserveRuleLimit
      ? getIntValueByStringNameFromClaimReference(
          "Pending Approval",
          reserveStatusReference ?? []
        )
      : getIntValueByStringNameFromClaimReference(
          "Approved",
          reserveStatusReference ?? []
        );

  const statusId =
    statusIdByTotalReserveAmount ===
    getIntValueByStringNameFromClaimReference(
      "Approved",
      reserveStatusReference ?? []
    )
      ? floatAmount !== null &&
        reserveRuleLimit !== null &&
        floatAmount > reserveRuleLimit
        ? getIntValueByStringNameFromClaimReference(
            "Pending Approval",
            reserveStatusReference ?? []
          )
        : getIntValueByStringNameFromClaimReference(
            "Approved",
            reserveStatusReference ?? []
          )
      : getIntValueByStringNameFromClaimReference(
          "Pending Approval",
          reserveStatusReference ?? []
        );

  return statusId;
};
