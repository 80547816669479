import { FC } from "react";
import style from "../Styles/basegridcells.module.css";
import { Tag } from "../../TrueUI";

type DefaultTagCellProperties = {
  value: string;
  displayOptions: any[];
};

const DefaultTagCell: FC<DefaultTagCellProperties> = ({
  value,
  displayOptions,
}) => {
  try {
    const parsedTagValues = (JSON.parse(value) ?? []) as any[];
    const uniqueKey = crypto.randomUUID();
    return (
      <div className={style.default_tag_render_only_cell}>
        <>
          {parsedTagValues.map((tag, index) => {
            const tagValue = (tag["Id"] ?? 0).toString();
            const displayValue =
              displayOptions.find((options) => {
                return (options.value ?? "0") === tagValue;
              }).text ?? "";
            return (
              <div
                style={{ display: "inline-block", marginRight: "4px" }}
                title={displayValue}
                key={`${uniqueKey}_${index}`}
              >
                <Tag label={displayValue} key={index} />
              </div>
            );
          })}
        </>
      </div>
    );
  } catch (e) {
    return <div className={style.default_tag_render_only_cell}></div>;
  }
};

export default DefaultTagCell;
