import { FC } from "react";
import { Button, Col, Input, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { AchBatchDetailInformationProps } from "./AchTypes";
import { BillingStatusEnum } from "../../../dtos/billing-status-enum";
import DeleteAchBatchButton from "./DeleteAchBatchButton";

const AchBatchDetailInformation: FC<AchBatchDetailInformationProps> = ({
  selectedBatchId,
  setSelectedBatchId,
  achBatchDetailInformation,
  setBatchDeleted,
}) => {
  const deleteBreakpoints =
    achBatchDetailInformation?.batchStatusId === BillingStatusEnum.PENDING
      ? { md: 3, lg: 3, xl: 3 }
      : { md: 12, lg: 12, xl: 12 };

  const spaceColumnBreakpoints =
    achBatchDetailInformation?.batchStatusId === BillingStatusEnum.PENDING
      ? { md: 2, lg: 2, xl: 2 }
      : { md: 4, lg: 5, xl: 5 };
  return (
    <Row
      {...rowWithNoMarginNorGutter}
      numberOfColumns={24}
      verticalAlign={"flex-end"}
      verticalGutter={"10px"}
      allowWrap
    >
      <Col breakpoints={{ md: 4, lg: 3, xl: 3 }}>
        <Input
          id={"achBatchNumber"}
          name={"achBatchNumber"}
          label={"ACH Batch Number"}
          type={"number"}
          value={achBatchDetailInformation?.achBatchNumber}
          readOnly
        />
      </Col>
      <Col breakpoints={{ md: 4, lg: 3, xl: 3 }}>
        <Input
          id={"achBatchDate"}
          name={"achBatchDate"}
          label={"Date"}
          value={achBatchDetailInformation?.batchDate}
          readOnly
        />
      </Col>
      <Col breakpoints={{ md: 5, lg: 4, xl: 4 }}>
        <Input
          id={"achBankAccount"}
          name={"achBankAccount"}
          label={"Bank Account"}
          value={achBatchDetailInformation?.bankAccount}
          readOnly
        />
      </Col>
      <Col breakpoints={{ md: 4, lg: 3, xl: 3 }}>
        <Input
          id={"achBatchAmount"}
          name={"achBatchAmount"}
          label={"Batch Amount"}
          value={achBatchDetailInformation?.batchAmount}
          readOnly
        />
      </Col>
      <Col breakpoints={{ md: 4, lg: 3, xl: 3 }}>
        <Input
          id={"achBatchStatus"}
          name={"achBatchStatus"}
          label={"Status"}
          value={achBatchDetailInformation?.status}
          readOnly
        />
      </Col>

      <Col breakpoints={spaceColumnBreakpoints}></Col>

      <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} verticalAlignSelf={"flex-end"}>
        <Button
          onClick={() => {
            setSelectedBatchId?.(null);
          }}
          variantStyle={"outlined"}
          fullWidth
        >
          Return
        </Button>
      </Col>
      <Col breakpoints={deleteBreakpoints} verticalAlignSelf={"flex-end"}>
        {achBatchDetailInformation?.batchStatusId ===
          BillingStatusEnum.PENDING && (
          <DeleteAchBatchButton
            selectedBatchId={selectedBatchId}
            setBatchDeleted={setBatchDeleted}
          />
        )}
      </Col>
    </Row>
  );
};

export default AchBatchDetailInformation;
