import { FC } from "react";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Switch } from "../../TrueUI";
import { getSafeStringToBool } from "../../../utilities/stringFunctions";
// import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";
// import { getSafeStringToBool } from "../../../../utilities/stringFunctions";
// import Switch from "../../Checkbox/Switch";

interface BaseTableCheckBocCellProperties
  extends BaseTableCellSelectProperties {
  isDisabled?: boolean;
}

const CheckBoxCell2: FC<BaseTableCheckBocCellProperties> = ({
  cellKey,
  rowKey,
  value,
  isEdit,
  column,
  onChange,
  isDisabled = false,
}) => {
  return (
    <Switch
      key={cellKey}
      id={cellKey}
      control={"checkbox"}
      isChecked={getSafeStringToBool(value) ?? false}
      readOnly={isDisabled ? true : !isEdit}
      onChange={(e) => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: e.target.checked ? "true" : "false",
          // cellIds: [],
        });
        // setValueAsBool(e.target.checked);
      }}
    />
  );
};

export default CheckBoxCell2;
