import { FC, useState } from "react";
import { TabPanel, Tabs } from "../../../TrueUI";
import PaymentsApproval from "./Payments/PaymentsApproval";
import ReservesApproval from "./Reserves/ReservesApproval";

const PaymentApprovalBodyWrapper: FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  return (
    <Tabs
      name={"claims-approvals-tabs"}
      selected={currentTab}
      onSelect={(e) => setCurrentTab(e.selected)}
      stateControlled
    >
      <TabPanel
        title="Pending Payment Approval"
        key={"pending-approval-payments"}
      >
        <PaymentsApproval />
      </TabPanel>
      <TabPanel
        title="Pending Reserve Approval"
        key={"pending-approval-reserves"}
      >
        <ReservesApproval />
      </TabPanel>
    </Tabs>
  );
};

export default PaymentApprovalBodyWrapper;
