import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { BaseTable2, Col, FontBold, Row } from "../../../../../TrueUI";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { SpecificWaiverTableProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { getConfigurationForColumn } from "../PremiumTableRows/PremiumTableRowsUtils";
import { useApiPost } from "../../../../../../hooks";
import { StatusEnums } from "../../../../../../dtos/status-enums";
import { currencyFormat } from "../../../../../../utilities/currencyFunctions";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";

const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

const SpecificWaiverTable: FC<SpecificWaiverTableProps> = ({
  tableTotal,
  setTableTotal,
  openFormEvent,
  specificWaiverFormUI,
  readOnly,
}) => {
  const [policyWaiverId, setPolicyWaiverId] = useState<number>();
  const TABLE_NAME = `policy_specific_waiver_modal_table_${specificWaiverFormUI?.stateCode}_${specificWaiverFormUI?.effectiveDate}_${specificWaiverFormUI?.expirationDate}_${specificWaiverFormUI?.insuredId}`;

  const { dispatchPost } = useApiPost<number>(
    `${QUOTE_POLICY_API_PATH}/UpdatePolicyWaiverStatus?policyWaiverId=${policyWaiverId}&status=${StatusEnums.DELETED}`
  );

  const CustomFooterComponent = () => (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={48}>
      <Col {...getConfigurationForColumn(18, "flex-end", "center")}></Col>
      <Col
        {...getConfigurationForColumn(15, "flex-start", "center")}
        horizontalGutter="10px"
      >
        <FontBold>Total Premium Charge</FontBold>
      </Col>
      <Col
        {...getConfigurationForColumn(readOnly ? 8 : 7, "flex-end", "center")}
        horizontalGutter="20px"
      >
        <FontBold>{currencyFormat(tableTotal, false, 2)}</FontBold>
      </Col>
      <Col
        {...getConfigurationForColumn(readOnly ? 7 : 8, "flex-end", "center")}
      ></Col>
    </Row>
  );

  const PolicyWaiverDeleteIcon = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
      useState(false);
    return (
      <>
        <Box>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => setIsDialogConfirmationOpen(true)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogConfirmation
          id="tableWaiverDialogConfirmation"
          open={isDialogConfirmationOpen}
          dialogDescriptionText="Are you sure you want to delete this Policy Waiver?"
          onCloseEvent={(close) => {
            setIsDialogConfirmationOpen(close);
          }}
          onOptionNoEvent={(close) => {
            setIsDialogConfirmationOpen(close);
          }}
          onOptionYesEvent={(close) => {
            setPolicyWaiverId(actionOptions.row.WaiverID);
            actionOptions.actionRules.delete(actionOptions.row);
            setIsDialogConfirmationOpen(close);
          }}
        />
      </>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    columnsAndData: {
      columns: specificWaiverFormUI?.policyWaiverTable?.columns ?? [],
      data: specificWaiverFormUI?.policyWaiverTable?.data ?? [[]],
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      optionsColumnConfiguration: {
        isHidden: readOnly,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          PolicyWaiverDeleteIcon(actionOptions),
      },
      tableStyle: {
        height: "auto",
      },
    },
    columnOptions: [
      {
        fieldName: "IssueDate",
        width: "10",
      },
      {
        fieldName: "CompanyName",
        width: "35",
      },
      {
        fieldName: "JobName",
        width: "30",
      },
      {
        fieldName: "PremiumCharge",
        width: "10",
        align: "right",
      },
      {
        fieldName: "LastUpdated",
        width: "15",
        align: "right",
      },
    ],
    toolbarOptions: {
      showSortFilter: false,
      showEditButton: false,
      showImportButton: false,
      showAddButton: !readOnly,
    },
    events: {
      addEventOverrideCallback: () => openFormEvent?.(),
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    setTableTotal(
      specificWaiverFormUI?.policyWaiverTable?.data?.reduce(
        (acc, row) => parseFloat(row[4]) + acc,
        0
      ) ?? 0
    );
  }, [specificWaiverFormUI?.policyWaiverTable]);

  useEffect(() => {
    if (policyWaiverId) {
      dispatchPost();
    }
  }, [policyWaiverId]);

  return (
    <Row {...rowWithNoMarginNorGutter}>
      <Col {...colWithNoMarginNorGutter}>
        <Box sx={{ width: "100%" }}>
          <BaseTable2 name={TABLE_NAME} />
          {CustomFooterComponent()}
        </Box>
      </Col>
    </Row>
  );
};

export default SpecificWaiverTable;
