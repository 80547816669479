import { useEffect, useState } from "react";
import { StatusEnums } from "../../../../dtos/status-enums";
import Switch from "../../Checkbox/Switch";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import { KeysAsType } from "../../../../types/KeysAsAType";
import { FontsType } from "../../../../media/themeTypes";

type BaseTableSwitchStatusFilterProps = {
  id?: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  defaultValue?: boolean;
  checkedLabelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelFontType?: KeysAsType<FontsType>;
  checkedStatusValue?: string;
  nonCheckedStatusValue?: string;
  usingExternalFiltering?: boolean;
  onChange?: (e: any) => void;
};

const BaseTableSwitchStatusFilter: (
  properties: BaseTableSwitchStatusFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames,
  defaultValue = false,
  checkedLabelText = "SHOW ALL",
  checkedStatusValue = "all",
  labelPosition = "end",
  labelFontType = "BOLD_BODY",
  nonCheckedStatusValue = StatusEnums.ACTIVE.toString(),
  usingExternalFiltering,
  onChange,
}) => {
  const [newStatus, setNewStatus] = useState(defaultValue);

  if (usingExternalFiltering) {
    filterOptions?.isStaticFilter?.(true);
  }

  const setStatus = (val: boolean) => {
    const newStatusValue = val ? checkedStatusValue : nonCheckedStatusValue;
    filterOptions?.setFilterInclude?.([
      {
        filterName: "switch_status_filter",
        columnNames: columnsNames,
        filterValue: newStatusValue,
      },
    ]);
  };

  useEffect(() => {
    if (!usingExternalFiltering) setStatus(newStatus);
  }, [newStatus]);

  return (
    <div>
      <Switch
        name={`filter-switch-${id}`}
        isChecked={newStatus}
        label={checkedLabelText}
        labelPlacement={labelPosition}
        labelFontType={labelFontType}
        onChange={(e) => {
          setNewStatus(e.target.checked);
          onChange?.(e.target.checked);
        }}
      />
    </div>
  );
};

export default BaseTableSwitchStatusFilter;
