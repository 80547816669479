import { BaseTableColumn } from "../../dtos/base-table-column";
import { BaseTableInputType } from "../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../dtos/data-type-enums";

export enum InternalColumnTypes {
  ROW_KEY = "_row_key_column",
  MULTI_ROW_SELECT = "_multi_row_select_column",
  INDICATION = "_indication_column",
  OPTIONS = "_options_column",
  IS_NEW = "_is_new_column",
  IS_DELETED = "_is_deleted_column",
}

export const internalColumnFactory = (
  columnTypes: InternalColumnTypes,
  config: {
    value?: any;
    isHidden?: boolean;
  }
) => {
  switch (columnTypes) {
    case InternalColumnTypes.ROW_KEY:
      return internalColumnBuilder({
        _columnIndex: 0,
        displayName: InternalColumnTypes.ROW_KEY,
        fieldName: InternalColumnTypes.ROW_KEY,
        type: BaseTableInputType.READONLY,
        defaultValue: null,
        isHidden: config?.isHidden ?? true,
      });
    case InternalColumnTypes.OPTIONS:
      return internalColumnBuilder({
        _columnIndex: 100,
        displayName: "",
        fieldName: InternalColumnTypes.OPTIONS,
        type: BaseTableInputType.OPTIONS,
        defaultValue: config?.value ?? "",
        isHidden: config?.isHidden ?? true,
      });
    default:
      return internalColumnBuilder({
        displayName: "ERROR_IN_FACTORY",
        fieldName: columnTypes,
        defaultValue: config.value,
        isHidden: false,
      });
  }
};

const internalColumnBuilder = (
  columnConfiguration: Partial<BaseTableColumn>
): BaseTableColumn => {
  return {
    _isInternal: true,
    _columnIndex: columnConfiguration?._columnIndex ?? 0,
    _visibleColumnIndex: columnConfiguration?._visibleColumnIndex ?? 0,
    _headerSortOrder: columnConfiguration?._headerSortOrder ?? 0,
    displayName: columnConfiguration?.displayName ?? "NO_DISPLAY_NAME",
    fieldName: columnConfiguration?.fieldName ?? "NO_FIELD_NAME",
    cellVariant: columnConfiguration?.cellVariant ?? CellVariantEnums.STANDARD,
    type: columnConfiguration?.type ?? BaseTableInputType.TEXT,
    displayOptions: columnConfiguration?.displayOptions ?? null,
    defaultValue: columnConfiguration?.defaultValue ?? "",
    defaultValueType:
      columnConfiguration?.defaultValueType ?? DataTypeEnums.STRING,
    isHidden: columnConfiguration?.isHidden ?? true,
    maxLength: columnConfiguration?.maxLength ?? 0,
    isFilterable: columnConfiguration?.isFilterable ?? false,
    isSortable: columnConfiguration?.isSortable ?? false,
    alternateDisplayValues: columnConfiguration?.alternateDisplayValues ?? null,
  };
};
