import { FC } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Fade,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import {
  OptionsContextActionProperties,
  TableInstanceType2,
} from "../BaseTable2/TableProperties";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";

type OptionsCellContextProcessorProps = {
  rowIndex?: number;
  contextMenuOptionRenderingCondition?:
    | {
        actionName: string;
        condition: (row: any) => { isHidden: boolean };
      }[]
    | null;
  actionRules?: any;
  actionEvents?: any;
  tableInstance?: TableInstanceType2;
  additionalRowData?: any;
  optionsContextActions:
    | OptionsContextActionProperties[]
    | ((row?: any) => JSX.Element)
    | null;
};

const OptionsCellContextProcessor: FC<OptionsCellContextProcessorProps> = ({
  rowIndex = -1,
  contextMenuOptionRenderingCondition,
  actionRules,
  actionEvents,
  tableInstance,
  additionalRowData,
  optionsContextActions,
}) => {
  const popupState = usePopupState({
    variant: "popper",
    popupId: crypto.randomUUID(),
  });
  const popperConfiguration: any = {
    transition: true,
    sx: { minWidth: "150px", zIndex: 99 },
  };

  return (
    <div onMouseLeave={() => popupState?.close()}>
      <MoreHorizIcon {...bindToggle(popupState)} />
      <Popper {...bindPopper(popupState)} {...popperConfiguration}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={4}>
              <List sx={{ pt: 0 }}>
                {(
                  (optionsContextActions as OptionsContextActionProperties[]) ??
                  []
                ).map((action) => {
                  // TODO - clean this up
                  const actionCondition =
                    contextMenuOptionRenderingCondition?.find((x) => {
                      if (x.actionName === action.actionName) {
                        return x;
                      }
                      return null;
                    });
                  const isRendered =
                    !actionCondition?.condition(additionalRowData)?.isHidden ??
                    true;
                  return (
                    (isRendered ?? true) && (
                      <ListItem
                        button
                        onClick={() =>
                          action.rule({
                            row: additionalRowData,
                            actionRules: actionRules,
                            actionEvents: actionEvents,
                            optionType: "context",
                            rowIndex: rowIndex,
                            metaData: {
                              isTableInEdit: tableInstance?.isEdit ?? false,
                            },
                          })
                        }
                        key={crypto.randomUUID()}
                      >
                        <ListItemText
                          sx={{ fontSize: "18px" }}
                          disableTypography
                        >
                          {action.displayName}
                        </ListItemText>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default OptionsCellContextProcessor;
