import { FC, useEffect, useState } from "react";
import Row, { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import Col, { colWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Col";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { useApiGet } from "../../../../../hooks";
import PaymentsApprovalTable from "./PaymentsApprovalTable";
import { PaymentApprovalConfigurationDto } from "../../../../../dtos/payment-approval-configuration-dto";

const PAYMENTS_TO_APPROVE_URL = "api/Payment/GetPaymentsToApprove";
const PAYMENTS_TO_APPROVE_CONF_URL =
  "api/Payment/GetPaymentsToApproveConfiguration";
const PaymentsApproval: FC = () => {
  const [configurationOptions, setConfigurationOptions] =
    useState<PaymentApprovalConfigurationDto>();
  const { responseGet, dispatchGet } =
    useApiGet<PaymentApprovalConfigurationDto>(PAYMENTS_TO_APPROVE_CONF_URL);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setConfigurationOptions(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowHeight={"100%"}
      rowDirection={"column"}
    >
      <Col {...colWithNoMarginNorGutter} displayFlex={false} colHeight="100%">
        {conditionHasValue(configurationOptions) && (
          <PaymentsApprovalTable
            getUrl={PAYMENTS_TO_APPROVE_URL}
            configurationOptions={configurationOptions}
          />
        )}
      </Col>
    </Row>
  );
};

export default PaymentsApproval;
