import { FC } from "react";
import { ClaimLitigationTablesProps } from "../typesAndConstants";
import CasesTable from "./CasesTable";
import ContactsTable from "./ContactsTable";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";

const ClaimLitigationTables: FC<ClaimLitigationTablesProps> = ({
  tablesCommonProps,
  setTableInstanceMethods,
  setCaseTableInstanceMethods,
  caseNumber,
  hasClaimsManagementPermissions,
}) => {
  const caseTableProps = {
    ...tablesCommonProps,
    setTableInstanceMethods: setCaseTableInstanceMethods,
    hasClaimsManagementPermissions: hasClaimsManagementPermissions,
  };

  const contactTableProps = {
    ...tablesCommonProps,
    representationId: tablesCommonProps?.selectedCase?.representationId,
    setTableInstanceMethods: setTableInstanceMethods,
    hasClaimsManagementPermissions: hasClaimsManagementPermissions,
  };

  return (
    <div id={"claim_litigation_tables_wrapper"}>
      <CasesTable {...caseTableProps} />
      {conditionHasValue(tablesCommonProps?.selectedCase?.representationId) &&
        tablesCommonProps?.selectedCase?.representationId !== 0 && (
          <ContactsTable {...contactTableProps} caseNumber={caseNumber} />
        )}
    </div>
  );
};

export default ClaimLitigationTables;
