import { useEffect, useState } from "react";
import InputTaxAutoformat from "../TrueUI/Inputs/InputTaxAutoformat";
import { Button } from "../TrueUI";
import { useApiGet } from "../../hooks";

const AlbertoSandBox = () => {
  const { responseGet, dispatchGet } = useApiGet(
    `api/AgencyContact/GetAgencyContactConfiguration?agencyId=27`
  );
  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    console.log({ responseGet });
  }, [responseGet]);

  const [FEINValue, setFEINValue] = useState<string>("123456789");
  const [SSNValue, setSSNValue] = useState<string>("123456789");
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

  useEffect(() => {
    console.log({ FEINValue });
  }, [FEINValue]);

  useEffect(() => {
    console.log({ SSNValue });
  }, [SSNValue]);

  return (
    <div style={{ width: "200px", marginLeft: "50px", marginTop: "50px" }}>
      <InputTaxAutoformat
        id="id"
        name="id"
        label="Tax Id FEIN"
        labelFontType={"CAPTION"}
        value={FEINValue}
        readOnly={isReadOnly}
        taxIdType={"FEIN"}
        onChange={(value) => {
          setFEINValue(value);
        }}
      />
      <InputTaxAutoformat
        id="id"
        name="id"
        label="Tax Id SSN"
        labelFontType={"CAPTION"}
        value={SSNValue}
        readOnly={isReadOnly}
        taxIdType={"SSN"}
        onChange={(value) => {
          setSSNValue(value);
        }}
      />
      <Button
        variantStyle="outlined"
        sx={{ marginTop: "20px" }}
        onClick={() => {
          setIsReadOnly(!isReadOnly);
        }}
        tabIndex={25}
      >
        {isReadOnly ? "Edit" : "Change to read only"}
      </Button>
    </div>
  );
};

export default AlbertoSandBox;

/**
 * 44-4444444
 * 444-44-4444
 */
