import { GlobalInsuredAtomFamily } from "../components/Insured/InsuredAtoms";
import { INSURED_ATOM_KEY } from "../utilities/queryStringsHash";
import { useAtomFamily } from "./useAtomFamily";

import { getQueryValue } from "../utilities/URLUtilities_OBSOLETE";

export function useInternalInsuredTabs(tabKey: string): {
  getTabSelected: (tabName) => number;
  setTabValue: (selected, tabName) => void;
} {
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(`${INSURED_ATOM_KEY} ${tabKey}`)
  );

  const getTabSelected = (tabName) => {
    const queryTabs = getQueryValue(tabName);
    const internalTabs = getAtom()?.internalInsuredTabs;
    const informationTabs = internalTabs?.find(
      (tab) => tab.nameTabs === tabName
    );
    return queryTabs !== ""
      ? parseInt(queryTabs)
      : informationTabs?.activeTab ?? 0;
  };

  const setTabValue = (selected, tabName) => {
    const previousInsuredTabs = getAtom()?.internalInsuredTabs?.filter(
      (tab) => tab.nameTabs !== tabName
    );
    const atomNavConfiguration = {
      ...getAtom(),
      internalInsuredTabs: [
        ...(previousInsuredTabs ?? []),
        { nameTabs: tabName, activeTab: selected },
      ],
    };

    setAtom(atomNavConfiguration);
  };

  return { getTabSelected, setTabValue };
}
