import { FC, useEffect } from "react";
import {
  Col,
  Divider,
  FontBold,
  Input,
  Select,
  Switch,
} from "../../../../../TrueUI";
import Row, { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { ModalSectionsProps } from "../TypesBillingOptions";

const ModalSectionAutoPayOptions: FC<ModalSectionsProps> = ({
  billingContentConfig,
  billingRuleFormData,
  setBillingRuleFormData,
}) => {
  const initializeInsuredAccountId = () => {
    const initialInsuredAccountId =
      billingContentConfig?.initialBillingRuleData?.insuredAccountId;
    const accountName =
      billingContentConfig?.accountNumbers?.find(
        (accountNumber) => accountNumber?.intValue === initialInsuredAccountId
      ) ?? null;
    if (accountName === null) {
      setBillingRuleFormData({
        ...billingRuleFormData,
        insuredAccountId:
          billingContentConfig?.accountNumbers?.[0]?.intValue ?? null,
      });
    }
  };

  useEffect(() => {
    if (
      billingRuleFormData !== null &&
      billingContentConfig?.isReadOnly === false
    ) {
      initializeInsuredAccountId();
    }
  }, []);

  return (
    <div>
      <Divider height={15} />
      <FontBold>AUTO PAY OPTIONS</FontBold>
      <Row verticalAlign={"flex-start"} {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionAutoPayOn"
            name="insuredBillinOptionAutoPayOn"
            label="Turn Auto Pay ON?"
            isChecked={billingRuleFormData?.autoPayOn ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                autoPayOn: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
          verticalGutter={"5px"}
        >
          {(billingRuleFormData?.autoPayOn ?? false) && (
            <Input
              {...inputConfiguration(
                "insuredBillinOptionAutoPayDay",
                "insuredBillingOptionAutoPayDay",
                "Payment Drafted on Day"
              )}
              readOnly={billingContentConfig?.isReadOnly}
              value={billingRuleFormData?.autoPayDay ?? 1}
              type={"number"}
              minNumericValue={1}
              maxNumericValue={31}
              maxLength={2}
              thousandSeparator={false}
              onChangeRawValue={(value) => {
                setBillingRuleFormData({
                  ...billingRuleFormData,
                  autoPayDay: value,
                });
              }}
              displayNumericArrows
            />
          )}
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          {(billingRuleFormData?.autoPayOn ?? false) && (
            <Select
              {...selectConfiguration(
                "insuredBillinOptionPreferredBillingMethod",
                "insuredBillingOptionPreferredBillingMethod",
                "Use This Payment Account"
              )}
              readOnly={billingContentConfig?.isReadOnly}
              options={billingContentConfig?.accountNumbers ?? []}
              value={billingRuleFormData?.insuredAccountId}
              labelFontType={"BOLD_CAPTION"}
              onChange={(value) => {
                setBillingRuleFormData?.({
                  ...billingRuleFormData,
                  insuredAccountId: value,
                });
              }}
              firstOptionAsDefault={false}
            />
          )}
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
      </Row>
    </div>
  );
};

export default ModalSectionAutoPayOptions;
