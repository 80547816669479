import { SelectOptions } from "../../../../../../dtos/select-options";
import { Input, Select } from "../../../../../TrueUI";
import { useEffect, useState } from "react";
import { useApiGet } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { FormattingDate } from "../../../../../../utilities/dateFunctions";
import { IncidentClaimModalConfigDto } from "../../../../../../dtos/incident-claim-modal-config-dto";
import styles from "./CreateClaimModalContent.module.css";
import { useSetRecoilState } from "recoil";
import { IncidentClaimModalDisabled } from "./CreateClaimModalAtoms";

const CreateClaimModalContent = (
  assigneeOptions: Partial<SelectOptions>[],
  assigneeId: any,
  onChangeMethod: (select: any) => void,
  incidentId?: number
) => {
  const [modalConfig, setModalConfig] = useState<IncidentClaimModalConfigDto>({
    currentProgram: null,
    boundPolicy: null,
  });
  const setDisabledOkButton = useSetRecoilState(IncidentClaimModalDisabled);

  const formattedPolicy = modalConfig?.boundPolicy
    ? `${modalConfig.boundPolicy?.policyNumber}: ${FormattingDate(
        modalConfig.boundPolicy?.effectiveDate
      )} - ${FormattingDate(modalConfig.boundPolicy?.expirationDate)}`
    : "Warning: No active policy for this date of injury";

  const { responseGet, dispatchGet } = useApiGet<IncidentClaimModalConfigDto>(
    `api/Policy/GetBoundPolicyByIncidentId?incidentId=${incidentId}`
  );

  useEffect(() => {
    if (incidentId && incidentId > 0) {
      dispatchGet();
    }
  }, [incidentId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet) && responseGet.responseData) {
      setModalConfig(responseGet.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      modalConfig.currentProgram?.openClaimNoPolicy ||
      modalConfig.boundPolicy
    ) {
      setDisabledOkButton(false);
    } else {
      setDisabledOkButton(true);
    }
  }, [modalConfig]);

  return (
    <div className={styles.content_container}>
      <div>
        <Select
          id="assign-to"
          name="assignTo"
          label="Assign To"
          options={assigneeOptions ?? []}
          value={assigneeId}
          onChange={(select) => onChangeMethod(select)}
        />
      </div>
      <div>
        <Input
          id="policy-id"
          name="policyId"
          label="Policy"
          labelFontType="BOLD_BODY"
          value={responseGet.isLoading ? "" : formattedPolicy}
          className={modalConfig?.boundPolicy ? "" : "input_no_policy_found"}
          readOnly
        />
      </div>
    </div>
  );
};

export default CreateClaimModalContent;
