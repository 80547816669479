import { useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { FormattingDate, StringToDate } from "../../../utilities/dateFunctions";
import CustomInputDateFilter from "../../Agency/AgencyCommission/CustomInputDateFilter";
import "./ivan.module.css";

const gridName = "sandbox_grid";

const IvanSandbox = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    FormattingDate(new Date(2024, 0, 1))
  );
  const [dateTo, setDateTo] = useState<string>(
    FormattingDate(new Date(2024, 11, 31))
  );

  const { reloadGridDataSource } = useBaseGrid({
    name: gridName,
    getURL: `api/AgencyCommissionTran/GetAgencyCommissionsPageByProgramAndDates?programId=-1&fromDate=${dateFrom}&toDate=${dateTo}`,
    filterOptions: [
      (options) =>
        CustomInputDateFilter({
          options,
          id: "fromDate",
          label: "From:",
          defaultValue: StringToDate(dateFrom),
          onChange: (value) => {
            setDateFrom(FormattingDate(value));
          },
        }),
      (options) =>
        CustomInputDateFilter({
          options,
          id: "toDate",
          label: "To:",
          defaultValue: StringToDate(dateTo),
          onChange: (value) => {
            setDateTo(FormattingDate(value));
          },
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showExportButton: false,
      showSaveButton: false,
    },
  });

  useEffect(() => {
    reloadGridDataSource();
  }, [dateFrom, dateTo]);

  return (
    <div style={{ width: "900px", margin: "50px" }}>
      <BaseGrid name={gridName} />
    </div>
  );
};

export default IvanSandbox;
