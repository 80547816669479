// import { PolicyExposureBlob } from "../../dtos/policy-exposure-blob";
// import ExposureTable from "../Insured/Policy/ExposureTable/ExposureTable";

// const mockExposureJSON = {
//   uniqueKey: "12",
//   exposureID: null,
//   classCodeID: 901,
//   classCode: "0010",
//   classSuffix: null,
//   description: "Dredging-All Types-Program Ii-State Act With Progr MD 1",
//   hazardGroup: "G",
//   baseRate: 3.5,
//   modRate: 4.4,
//   netRate: 4.2632,
//   rateBasis: "Payroll",
//   previousExposure: 111111,
//   exposureAmount: 123123,
//   exposureAct: 1,
//   manualPremiumAtBase: null,
//   previousPremium: 5000,
//   manualPremiumAtMod: 5417,
//   locationNumber: 1,
//   numberOfEmployees: 2,
// } as PolicyExposureBlob;

// const mockExposureJSON2 = {
//   uniqueKey: "22",
//   exposureID: null,
//   classCodeID: 899,
//   classCode: "0005",
//   classSuffix: "v",
//   description: "Farm: Nursery Employees & Drivers MD",
//   hazardGroup: "C",
//   baseRate: 1.24,
//   modRate: 1.56,
//   netRate: 4.2632,
//   rateBasis: "Payroll",
//   previousExposure: 123122,
//   exposureAmount: 123123,
//   exposureAct: 1,
//   manualPremiumAtBase: null,
//   previousPremium: 1000,
//   manualPremiumAtMod: 1921,
//   locationNumber: 1,
//   numberOfEmployees: 2,
//   existInOldJSON: true,
// } as PolicyExposureBlob;

// const mockExposureJSONList = [mockExposureJSON, mockExposureJSON2];

const AlfonsoSandbox = () => {
  return <></>;
};

export default AlfonsoSandbox;
