import { Fragment, forwardRef, useEffect } from "react";
import { RowKey } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useRecoilValue } from "recoil";
import BaseGridCellManagerCellSelector from "./BaseGridCellManagerCellSelector";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import BaseGridCellComputeChangeProcessor from "./BaseGridCellComputeChangeProcessor";
import BaseGridCellComputeInitProcessor from "./BaseGridCellComputeInitProcessor";
import { useGridInstance } from "../Hooks/useGridInstance";
import { useCellManagerInstance } from "../Hooks/useCellManagerInstance";

export type GridCellManagerProperties = {
  uiid: string;
  rowKey: RowKey;
  rowIndex: number;
  row: string[];
  gridBodyViewPort?: { width: number; height: number };
  listOfInitComputeFunctions: any[];
  listOfChangeComputeFunctions: any[];
  columns: BaseTableColumn[];
};

type GridCellManagerRefType = HTMLDivElement;

const BaseGridCellManager = forwardRef<
  GridCellManagerRefType,
  GridCellManagerProperties
>(
  (
    {
      uiid,
      rowKey,
      rowIndex,
      row,
      columns,
      listOfInitComputeFunctions,
      listOfChangeComputeFunctions,
    },
    _ref
  ) => {
    const { instanceCellManager, updateCellManagerInstance } =
      useCellManagerInstance(uiid);
    const cellInstance = useRecoilValue(instanceCellManager(rowKey));
    const { instanceSelector } = useGridInstance(uiid, "BaseGridCellManager");
    const advancedOptions = useRecoilValue(instanceSelector("advancedOptions"));
    const styling = useRecoilValue(instanceSelector("styling"));
    const columnOptions = useRecoilValue(instanceSelector("columnOptions"));

    useEffect(() => {
      if (!cellInstance.hasAtomInitMounted) {
        updateCellManagerInstance(rowKey, {
          ...cellInstance,
          row: row ?? [],
          hasAtomInitMounted: true,
        });
      }
    }, []);

    useEffect(() => {
      if (
        cellInstance.hasAtomInitMounted &&
        listOfInitComputeFunctions.length === 0
      ) {
        updateCellManagerInstance(rowKey, {
          ...cellInstance,
          readyToRender: true,
          hasCompletedOperations: true,
        });
      }
    }, [cellInstance.hasAtomInitMounted, listOfInitComputeFunctions]);

    if (cellInstance.isHidden) {
      return null;
    } else {
      return (
        <Fragment key={`${rowIndex}_${uiid}`}>
          {!cellInstance.hasInitOnComputeTriggered && (
            <BaseGridCellComputeInitProcessor
              uiid={uiid}
              rowKey={rowKey}
              row={row}
              listOfComputeFunctions={listOfInitComputeFunctions}
            />
          )}
          {/* <BaseGridCellManagerUpdateProcessor
            uiid={uiid}
            rowKey={rowKey}
            row={row}
            listOfChangeComputeFunctions={listOfChangeComputeFunctions}
          /> */}
          <BaseGridCellComputeChangeProcessor
            uiid={uiid}
            rowKey={rowKey}
            row={row}
            listOfChangeComputeFunctions={listOfChangeComputeFunctions}
          />
          <BaseGridCellManagerCellSelector
            uiid={uiid}
            row={row}
            rowIndex={rowIndex}
            rowKey={rowKey}
            columns={columns}
            advancedOptions={advancedOptions ?? null}
            columnProps={columnOptions}
            enableRowHighlighting={
              styling?.rowStyles?.enableRowHoverHighlighting ?? false
            }
          />
        </Fragment>
      );
    }
  }
);
export default BaseGridCellManager;
