import { FC, useEffect, useState } from "react";
import { ClaimDetailsTabDto } from "../../../../../../dtos/claim-details-tab-dto";
import { DetailsContainerDto } from "../../../../../../dtos/details-container-dto";
import { IncidentContainerDto } from "../../../../../../dtos/incident-container-dto";
import { WagesContainerDto } from "../../../../../../dtos/wages-container-dto";
import { useApiGet } from "../../../../../../hooks";
import { Col, Loading, Row } from "../../../../../TrueUI";
import ClaimWagesContainer from "./ClaimWagesContainer";
import "./ClaimDetails.css";
import ClaimBodyPartsContainer from "./ClaimBodyPartsContainer";
import { spreadDetailsContainersInformation } from "../spreadClaimTabsFunctions";
import { ClaimantContainerDto } from "../../../../../../dtos/claimant-container-dto";
import ClaimDetailsContainer from "./ClaimDetailsContainer";
import ClaimIncidentContainer from "./ClaimIncidentContainer";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import ClaimClaimantContainer from "./ClaimClaimantContainer";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { DependentsContainerDto } from "../../../../../../dtos/dependents-container-dto";
import ClaimClaimantDependentsContainer from "./ClaimClaimantDependentsContainer";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { useRecoilValue } from "recoil";
import ClaimCustomFieldsContainer from "./ClaimCustomFieldsContainer";

type ClaimDetailsMainProps = {
  insuredId?: number;
  tabKey: string;
  claimId?: number;
  errorDetails?: any;
  hasClaimsManagementPermissions: boolean;
};

export type ContainerTypes =
  | DetailsContainerDto
  | IncidentContainerDto
  | WagesContainerDto
  | ClaimantContainerDto
  | DependentsContainerDto; // Add the other containers DTO

const ClaimDetailsMain: FC<ClaimDetailsMainProps> = ({
  tabKey,
  claimId,
  errorDetails,
  hasClaimsManagementPermissions,
}) => {
  const [tableContainersKeys, setTableContainersKeys] = useState({
    bodyPartsKey: crypto.randomUUID(),
    claimClaimantDependentKey: crypto.randomUUID(),
  });
  const [claimsDetailMainUI, setClaimsDetailMainUI] =
    useState<ClaimDetailsTabDto | null>();

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const { responseGet, dispatchGet } = useApiGet<ClaimDetailsTabDto>(
    `api/Claims/GetClaimDetailsTabData?ClaimId=${claimId}`
  );

  const updateDetailsContainerInformationOnAtom = (updatedValue) => {
    const atomValue = getAtom();
    const newAtomValue = spreadDetailsContainersInformation(
      atomValue,
      updatedValue
    );
    setAtom(newAtomValue);
  };

  useEffect(() => {
    const atomValue = getAtom();
    setClaimsDetailMainUI(
      atomValue?.claimTabsInformation?.claimDetailsTab
        ?.detailsContainersInformation ?? null
    );
    return () => {
      updateDetailsContainerInformationOnAtom(null);
    };
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setClaimsDetailMainUI(responseGet?.responseData);
      updateDetailsContainerInformationOnAtom(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (claimsDetailMainUI === null) {
      dispatchGet();
    }
  }, [claimsDetailMainUI]);
  const listenerBodyPartsComponent = useRecoilValue(
    TriggerComponentUpdateAtom("bodyPartsComponent")
  );
  useEffect(() => {
    if (listenerBodyPartsComponent !== null) {
      setTableContainersKeys({
        bodyPartsKey: crypto.randomUUID(),
        claimClaimantDependentKey: crypto.randomUUID(),
      });
    }
  }, [listenerBodyPartsComponent]);
  return (
    <>
      {claimsDetailMainUI ? (
        <div
          id={"claim_details_main_wrapper"}
          style={{ display: "flex", overflow: "auto" }}
        >
          <Row {...rowWithNoMarginNorGutter} verticalAlign={"flex-start"}>
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <ClaimDetailsContainer
                claimDetailsData={claimsDetailMainUI?.detailsContainerData}
                configuration={claimsDetailMainUI?.configuration}
                errorDetails={errorDetails?.DetailsContainerDto}
                insuredIdAtomKey={insuredIdAtomKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
              <ClaimIncidentContainer
                claimIncidentData={claimsDetailMainUI?.incidentContainerData}
                configuration={claimsDetailMainUI?.configuration}
                errorDetails={errorDetails?.IncidentContainerDto}
                insuredIdAtomKey={insuredIdAtomKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
              <ClaimBodyPartsContainer
                claimId={claimsDetailMainUI?.claimId}
                allBodyParts={claimsDetailMainUI?.configuration?.allBodyParts}
                insuredIdAtomKey={insuredIdAtomKey}
                errorDetails={errorDetails?.BodyPartContainerDto}
                key={tableContainersKeys?.bodyPartsKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
              <ClaimCustomFieldsContainer
                claimId={claimsDetailMainUI?.claimId}
                customFieldsData={claimsDetailMainUI?.customFieldsContainerData}
                insuredIdAtomKey={insuredIdAtomKey}
                errorDetails={errorDetails?.CustomFieldsContainerDto}
                key={tableContainersKeys?.bodyPartsKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
            </Col>
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf="unset"
            >
              <ClaimClaimantContainer
                claimClaimantData={claimsDetailMainUI?.claimantContainerData}
                configuration={claimsDetailMainUI?.configuration}
                errorDetails={errorDetails?.ClaimantContainerDto}
                insuredIdAtomKey={insuredIdAtomKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
              <ClaimWagesContainer
                claimWagesData={claimsDetailMainUI?.wagesContainerData}
                configuration={claimsDetailMainUI?.configuration}
                errorDetails={errorDetails?.WagesContainerDto}
                insuredIdAtomKey={insuredIdAtomKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
              <ClaimClaimantDependentsContainer
                claimId={claimId}
                insuredIdAtomKey={insuredIdAtomKey}
                errorDetails={errorDetails?.DependentsContainerDto}
                key={tableContainersKeys?.claimClaimantDependentKey}
                hasClaimsManagementPermissions={hasClaimsManagementPermissions}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <Loading isLoading={true} />
      )}
    </>
  );
};

export default ClaimDetailsMain;
