import { Typography } from "@mui/material";
import { FC } from "react";
import { NoteContentProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import style from "./UnderwriterNotes.module.css";

const NoteContent: FC<NoteContentProps> = ({
  expanded,
  formattedNarrativeText,
}) => {
  return (
    <Typography
      className={
        expanded
          ? style.underwriter_note_content_expanded
          : style.underwriter_note_content_collapsed
      }
      dangerouslySetInnerHTML={{
        __html: formattedNarrativeText ?? "",
      }}
    />
  );
};

export default NoteContent;
