import { useEffect, useState } from "react";
import { MultiSelect } from "../..";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import { MULTI_DROPDOWN_FILTER } from "../TableConstants";

type ToggleColumnsByOptionsProps = {
  options: string[];
  columnsToToggle: string[];
};

type BaseTableDropdownFilterProps = {
  id: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: any[];
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  toggleColumnsByOptions?: ToggleColumnsByOptionsProps[];
  usingExternalFiltering?: boolean;
  isStaticFilter?: boolean;
  validatingAgainstDisplayValue?: boolean;
  selectWidth?: string;
  showClearAll?: boolean;
  onChange?: (e: any) => void;
};

const BaseTableMultiSelectDropdownFilter: (
  properties: BaseTableDropdownFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames = [],
  optionsOverride,
  defaultValue,
  labelText,
  labelPosition = "start",
  usingExternalFiltering,
  isStaticFilter = false,
  validatingAgainstDisplayValue,
  selectWidth,
  onChange,
  showClearAll = false
}) => {
  const [values, setValues] = useState(defaultValue);
  if (isStaticFilter) {
    filterOptions.isStaticFilter?.(true);
  }

  const statusTypes = [{ displayName: "All", stringValue: "all" }].concat(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName: enumType?.key ?? "",
        stringValue: validatingAgainstDisplayValue
          ? enumType?.key ?? ""
          : enumType?.value.toString(),
      })
    )
  );

  const setStatus = (val: any[]) => {
    const valuesString = val.map((valueObject) =>
      (valueObject?.stringValue ?? "").toLowerCase()
    );
    filterOptions?.setFilterInclude?.([
      {
        filterName: MULTI_DROPDOWN_FILTER,
        columnNames: columnsNames,
        filterValue: valuesString,
      }, // TODO - fix this, it's bad and broken
    ]);
  };

  useEffect(() => {
    if (!usingExternalFiltering) setStatus(defaultValue ?? ["all"]);
  }, []);

  return (
    <MultiSelect
      id={`true-filter-for-table-${id}`}
      name={`filter-dropdown-${id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={labelText ?? "Show:"}
      labelPosition={labelPosition ?? "start"}
      options={optionsOverride ?? statusTypes}
      values={values ?? []}
      type={"tableFilter"}
      inputWidth={selectWidth ? selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(values) => {
        setValues(values);
        if (!usingExternalFiltering) setStatus(values);
        onChange?.(values);
      }}
      showClearAll={showClearAll}
    />
  );
};

export default BaseTableMultiSelectDropdownFilter;
