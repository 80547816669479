import { FC, useEffect, useState } from "react";
import { Col, FontBold, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { calculateTotalsForScheduleTable } from "./EndorsementBindInstructionsUtils";
import { EndorsementScheduleTableFooterUI } from "../EndorsementForm/EndorsementTypes";
import { formatNegativeNumbers } from "../../../../utilities/stringFunctions";

type EndorsementScheduleTableFooterProps = {
  rows: any[];
};

const EndorsementScheduleTableFooter: FC<
  EndorsementScheduleTableFooterProps
> = ({ rows }) => {
  const [footerUI, setFooterUI] = useState<EndorsementScheduleTableFooterUI>();

  const updateFooterUIByRowsChanged = (rows: any[]) => {
    const newValuesForFooter = calculateTotalsForScheduleTable(rows);
    setFooterUI(newValuesForFooter);
  };

  useEffect(() => {
    if (rows.length > 0) {
      updateFooterUIByRowsChanged(rows);
    }
  }, [rows]);

  return (
    <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-end">
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-start"
      >
        <FontBold>Total of all payments</FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {formatNegativeNumbers(footerUI?.totalInvoiceAmount ?? 0)}
        </FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {formatNegativeNumbers(footerUI?.totalEndorsement ?? 0)}
        </FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {formatNegativeNumbers(footerUI?.totalPaymentDue ?? 0)}
        </FontBold>
      </Col>
    </Row>
  );
};

export default EndorsementScheduleTableFooter;
