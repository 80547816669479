import { FC, useEffect } from "react";
import { Link } from "@mui/material";
import List from "@mui/material/List";
import { useApiGet } from "../../hooks";
import InsuredContactItem from "./InsuredContactItem";
import {
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_INFORMATION_TABS,
  INSURED_PARENT_ITEM,
} from "../../utilities/queryStringsHash";
import { SubSideNavItemDestinationsEnum } from "../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../dtos/sub-side-nav-children-index-enum";
import { getQueryStringsURL } from "../../utilities/URLUtilities_OBSOLETE";
import InformationContainer from "../TrueUI/Containers/InformationContainer";

type ContactsProps = {
  insuredId: number;
  primaryContactId: number;
  containerHeight?: (number: number) => void;
};

const InsuredContacts: FC<ContactsProps> = ({
  insuredId,
  primaryContactId,
  containerHeight,
}) => {
  const { responseGet, dispatchGet } = useApiGet<any[]>(
    `api/InsuredContact/GetInsuredContactsList?insuredId=${insuredId}`
  );

  const contactList: any[] = responseGet?.responseData ?? [];

  useEffect(() => {
    if (insuredId) dispatchGet();
  }, []);

  useEffect(() => {
    const container: any = document.querySelector(
      ".insured-landing-contacts-container"
    );
    containerHeight?.(container.offsetHeight);
  }, [contactList]);

  return (
    <InformationContainer
      title={
        <Link
          true-element="true-element-link-contacts-title"
          href={getQueryStringsURL([
            {
              nameOfHash: INSURED_PARENT_ITEM,
              valueOfHash: SubSideNavParentsIndexEnum.INSURED,
            },
            {
              nameOfHash: INSURED_CHILD_ITEM,
              valueOfHash: SubSideNavChildrenIndexEnum.INSURED_INFORMATION,
            },
            {
              nameOfHash: INSURED_BODY_SECTION,
              valueOfHash: SubSideNavItemDestinationsEnum.INFORMATION,
            },
            {
              nameOfHash: INSURED_INFORMATION_TABS,
              valueOfHash: "1",
            },
          ])}
        >
          Contacts
        </Link>
      }
    >
      <List
        className={"contact-list"}
        key={"clist"}
        true-element="true-ul-insured-contacts"
      >
        {(contactList ?? []).map((contact) => (
          <InsuredContactItem
            contact={contact}
            primaryContactId={primaryContactId}
            key={`cItem_${contact.contactId}`}
          />
        ))}
      </List>
    </InformationContainer>
  );
};

export default InsuredContacts;
