import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../utilities/queryStringsHash";
import { removeHTMLTagsByRegex } from "../../utilities/stringFunctions";
import Alert, { AlertProps } from "../TrueUI/Alerts/Alert";
import {
  GlobalInsuredAtomFamily,
  TriggerAllComponentNames,
  TriggerComponentUpdateAtom,
} from "./InsuredAtoms";
import getValueByPath from "../../utilities/getObjectValueByPath";
import { Modal } from "../TrueUI";
import { isEmptyValue } from "../../utilities/conditionalSupportFunctions";

type AlertAtomContainerProps = {
  tabKey: string;
  insuredId: number;
  atomPath: string | string[];
  atomTrigger: TriggerAllComponentNames;
  modalTitle?: string | null;
  modalContent?: string | JSX.Element | null;
};

type AlertAtomModalProps = {
  isOpen: boolean;
  title?: string | null;
  content?: string | JSX.Element | null;
  height?: number;
};

const AlertsAtomContainer: FC<AlertAtomContainerProps> = ({
  insuredId,
  tabKey,
  atomPath,
  atomTrigger,
}) => {
  const [alertList, setAlertList] = useState<AlertProps[] | null | undefined>();
  const [configModal, setConfigModal] = useState<AlertAtomModalProps | null>(
    null
  );

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const refreshActivity = useRecoilValue(
    TriggerComponentUpdateAtom(atomTrigger)
  );

  const onClickMessage = (index: number) => {
    const atomValue = getAtom();
    const atomValueList = getValueByPath(atomValue, atomPath, []);
    if (!isEmptyValue(atomValueList?.[index].modalContent)) {
      setConfigModal({
        isOpen: true,
        title: atomValueList?.[index].modalTitle,
        content: atomValueList?.[index].modalContent,
        height: atomValueList?.[index].modalHeight,
      });
    }
  };

  const getModalHeight = (height: number) => `${height}px`;

  useEffect(() => {
    const atomValue = getAtom();
    const validations = getValueByPath(atomValue, atomPath, []);
    setAlertList(validations);
  }, [refreshActivity]);

  return (
    <>
      {alertList &&
        alertList.length > 0 &&
        alertList.map((alert, index) => (
          <Alert
            key={`alert-atom-key-${insuredId}-${index}`}
            type={alert?.type}
            message={removeHTMLTagsByRegex(alert?.message)}
            showCloseIcon={alert?.showCloseIcon ?? true}
            onClickMessage={() => onClickMessage(index)}
          />
        ))}
      {!isEmptyValue(configModal) && (
        <Modal
          id="alert-content"
          title={configModal?.title ?? ""}
          open={configModal?.isOpen}
          showCloseButton
          cancelEvent={() => setConfigModal(null)}
          height={getModalHeight(configModal?.height ?? 500)}
          size="lg"
        >
          {configModal?.content}
        </Modal>
      )}
    </>
  );
};

export default AlertsAtomContainer;
