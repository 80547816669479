import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import { ModalAssignmentsViewProps } from "./ModalAssignmentPropsTypes";
import AssignmentsViewForm from "./AssignmentsViewForm";
import { AddAssignmentFormDataDto } from "../../../../dtos/add-assignment-form-data-dto";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";

const ModalAssignmentsView: FC<ModalAssignmentsViewProps> = ({
  isOpen,
  setIsOpen,
  assignmentsGroups,
  insuredId,
  policyId,
  isDisabledAssignment = false,
}) => {
  const [assignmentFormData, setAssignmentFormData] =
    useState<AddAssignmentFormDataDto | null>(null);

  const { responsePost, dispatchPost } = useApiPost<AddAssignmentFormDataDto>(
    `api/Assignment/SaveAssignment`,
    assignmentFormData
  );

  const {
    responsePost: responseClearAssignment,
    dispatchPost: dispatchClearAssignment,
  } = useApiPost(`api/Assignment/RemoveAssignment`, assignmentFormData);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setAssignmentFormData(null);
      setIsOpen(false);
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseClearAssignment)) {
      setAssignmentFormData(null);
      setIsOpen(false);
    }
  }, [responseClearAssignment]);

  return (
    <>
      <Modal
        id={"insured_assignments_modal"}
        title={"View Assignments"}
        open={isOpen}
        cancelEvent={() => {
          setAssignmentFormData(null);
          setIsOpen(false);
        }}
        saveEvent={() => {
          dispatchPost();
        }}
        cancelButtonWithConfirmation
        size={"sm"}
        showCloseButton
        showCancelTextButton
      >
        {isOpen && (
          <AssignmentsViewForm
            assignmentFormData={assignmentFormData}
            setAssignmentFormData={setAssignmentFormData}
            assignmentsGroups={assignmentsGroups}
            insuredId={insuredId}
            policyId={policyId}
            isDisabledAssignment={isDisabledAssignment}
            onClearAssignment={() => {
              dispatchClearAssignment();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalAssignmentsView;
