import { getColorWithOpacityHexaDecimal } from "../../media/themeConstants";
import { Theme } from "../../media/themeTypes";

export const landingStyles = (theme: Theme) => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  backgroundColor: theme?.PRIMARY_BACKGROUND,
  ".container-box": {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "column",
    width: "100%",
    height: "50%",
    ".image-container": {
      display: "inline-block",
      position: "relative",
      left: "15%",
      top: "30%",
      img: {
        height: "130px",
      },
    },
    ".program-container": {
      height: "300px",
      zIndex: 9998,
      top: "30%",
      position: "relative",
      boxShadow: 10,
      backgroundColor: theme?.PRIMARY_BACKGROUND,
      color: theme?.TEXT_COLOR,
      span: {
        textAlign: "center",
        display: "block",
      },
      ".divider": {
        pt: 2,
        borderColor: theme?.BORDER_COLOR,
      },
      ".program-item": {
        textAlign: "center",
        color: theme?.TEXT_COLOR,
        "&: hover": {
          background: getColorWithOpacityHexaDecimal(
            theme?.PRIMARY_BACKGROUND,
            40
          ),
        },
      },
    },
  },
});
