import { FC } from "react";

type TableHeadProperties = {
  children?: any;
};

const TableHead: FC<TableHeadProperties> = ({ children }) => {
  return <div className="true_table_head">{children}</div>;
};

export default TableHead;
