import { FC, memo } from "react";
import { RecoilState, useRecoilValue } from "recoil";
import color from "../../../../media/colors";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { TableInstanceType2 } from "./TableProperties";

const BaseTableDebugger: FC<any> = (props) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  return tableInstance?._debug?.isDebug ? (
    <div style={{ backgroundColor: color.DEV_ERROR, padding: "10px" }}>
      <div>
        Start Time: {tableInstance?._debug.startExecTimer}sec{" "}
        {tableInstance?._debug.endExecTimer === null
          ? " - calculating End Time..."
          : ` - End Time: ${tableInstance?._debug.endExecTimer}sec`}
      </div>
      <div>
        <b>
          Differenece of:{" "}
          {tableInstance?._debug?.endExecTimer === null
            ? "calculating..."
            : `${Math.abs(
                (tableInstance?._debug?.startExecTimer ?? 0) -
                  (tableInstance?._debug?.endExecTimer ?? 0)
              )}sec`}
        </b>
      </div>
    </div>
  ) : null;
};

export default memo(BaseTableDebugger);
