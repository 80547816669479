import { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router";
import { Box, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { FormattingDate, StringToDate } from "../../../utilities/dateFunctions";
import CustomInputDateFilter from "./CustomInputDateFilter";
import { SelectOptions } from "../../../dtos/select-options";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useApiGet } from "../../../hooks";
import { AgencyCommissionTranPage } from "../../../dtos/agency-commission-tran-page";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { addEmptyOption } from "../../../utilities/selectFunctions";
import { getColumnIndexByColumnName } from "../../BaseGrid/SupportFunctions/OLD_baseGridFunctions";
import { SelectedInsuredCommissionAtom } from "./CommissionsAtoms";
import { AgencyCommissionTranTypeEnum } from "../../../dtos/agency-commission-tran-type-enum";

const gridName = "agency_commissions_table";

type CommissionFilterUI = {
  programId?: number;
  fromDate?: string;
  toDate?: string;
  showDates: "current" | "previous" | "custom";
  disableDates: boolean;
};

const showOptions: Partial<SelectOptions>[] = [
  {
    stringValue: "current",
    displayName: "Current Year",
  },
  {
    stringValue: "previous",
    displayName: "Previous Year",
  },
  {
    stringValue: "custom",
    displayName: "Custom Dates",
  },
];

const Commissions: FC = () => {
  const { agencyId } = useParams();
  const currentYear = new Date().getFullYear();

  const [commissions, setCommissions] =
    useState<AgencyCommissionTranPage | null>(null);
  const [filterUI, setFilterUI] = useState<CommissionFilterUI>({
    programId: -1,
    showDates: "current",
    disableDates: true,
    fromDate: FormattingDate(new Date(currentYear, 0, 1)),
    toDate: FormattingDate(new Date(currentYear, 11, 31)),
  });

  const setInsuredTran = useSetRecoilState(SelectedInsuredCommissionAtom);

  const { responseGet, dispatchGet } = useApiGet<AgencyCommissionTranPage>(
    `api/AgencyCommissionTran/GetAgencyCommissionsPageByProgramAndDates?AgencyId=${agencyId}&programId=${filterUI?.programId}&fromDate=${filterUI?.fromDate}&toDate=${filterUI?.toDate}`
  );

  const getCommissionFilterValues = (
    value: "current" | "previous" | "custom"
  ): CommissionFilterUI => {
    return value === "current"
      ? {
          showDates: value,
          fromDate: FormattingDate(new Date(currentYear, 0, 1)),
          toDate: FormattingDate(new Date(currentYear, 11, 31)),
          disableDates: true,
        }
      : value === "previous"
      ? {
          showDates: value,
          fromDate: FormattingDate(new Date(currentYear - 1, 0, 1)),
          toDate: FormattingDate(new Date(currentYear - 1, 11, 31)),
          disableDates: true,
        }
      : {
          showDates: value,
          fromDate: filterUI.fromDate,
          toDate: filterUI.toDate,
          disableDates: false,
        };
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          sx={{ padding: 0 }}
          aria-label="expand row"
          size="small"
          onClick={() =>
            parseInt(actionOptions.row.TranType ?? "1") ===
            AgencyCommissionTranTypeEnum.COMMISSION_EARNED
              ? setInsuredTran({
                  tranId: parseInt(actionOptions.row.TranId),
                  program: actionOptions.row.Program,
                  date: actionOptions.row.TranDate,
                  amount: actionOptions.row.TranAmount,
                })
              : alert("Future functionality")
          }
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    name: gridName,
    columnsAndData: commissions?.tableData,
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "agency-commissions-filter-show",
          optionsOverride: showOptions,
          defaultValue: filterUI?.showDates,
          usingExternalFiltering: true,
          onChange: (value) =>
            setFilterUI({ ...filterUI, ...getCommissionFilterValues(value) }),
        }),
      (options) =>
        CustomInputDateFilter({
          options,
          id: "agency-commissions-filter-from-date",
          label: "From:",
          isDisabled: filterUI.disableDates,
          defaultValue: StringToDate(filterUI?.fromDate),
          onChange: (value) => {
            setFilterUI({ ...filterUI, fromDate: FormattingDate(value) });
          },
        }),
      (options) =>
        CustomInputDateFilter({
          options,
          id: "agency-commissions-filter-to-date",
          label: "To:",
          isDisabled: filterUI.disableDates,
          defaultValue: StringToDate(filterUI?.toDate),
          onChange: (value) => {
            setFilterUI({ ...filterUI, toDate: FormattingDate(value) });
          },
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "agency-commissions-filter-programs",
          labelText: "Program:",
          optionsOverride: addEmptyOption(
            commissions?.userProgramList ?? [],
            "ALL"
          ),
          defaultValue: filterUI?.programId,
          usingExternalFiltering: true,
          onChange: (value) => setFilterUI({ ...filterUI, programId: value }),
        }),
    ],
    orderDirection: "desc",
    columnTriggeredSort: true,
    orderByColumnIndex:
      getColumnIndexByColumnName(
        "TranDate",
        commissions?.tableData?.columns ?? []
      ) + 2,
    columnOptions: [
      { fieldName: "Program", width: 7 },
      { fieldName: "TranDate", width: 9 },
      { fieldName: "Description", width: 30 },
      { fieldName: "TranAmount", width: 12, align: "right" },
      { fieldName: "Balance", width: 12, align: "right" },
      { fieldName: "Comments", width: 30 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showSaveButton: false,
    },
    advancedOptions: {
      disableSortOrder: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
  };

  const { reloadGridDataSource } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setCommissions(responseGet?.responseData ?? null);
  }, [responseGet]);

  useEffect(() => {
    if (commissions !== null) reloadGridDataSource();
  }, [commissions]);

  useEffect(() => {
    dispatchGet();
  }, [filterUI.fromDate, filterUI.toDate, filterUI.programId]);

  return (
    <div style={{ margin: "15px", maxHeight: "-webkit-fill-available" }}>
      <BaseGrid name={gridName} />
    </div>
  );
};

export default Commissions;
