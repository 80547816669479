import { FC, useEffect, useState } from "react";
import { Col, FontBold, InputDate, Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { SelectOptions } from "../../../../dtos/select-options";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { PolicyLimitDto } from "../../../../dtos/policy-limit-dto";
import {
  getPolicyEnumBySelectOption,
  getPolicyEnumValueCodeAndDescriptionAsOptions,
  getPolicyLimitsAsOptions,
} from "./InsuredInformationUtils";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { PolicyEnumValueCodeAndDescriptionBlob } from "../../../../dtos/policy-enum-value-code-and-description-blob";
import { Acord130ConfigurationDto } from "../../../../dtos/acord130-configuration-dto";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { getValidatedDateToPost } from "../../../../utilities/dateFunctions";
import { PolicyPeriodDto } from "../../../../dtos/policy-period-dto";
import {
  getExpirationDateByEffectiveDate,
  getPolicyPeriod,
} from "../../Policy/PolicyQuoteInformation/PolicyInformation/InformationUtils";

type insuredInfoPolicyProps = {
  programId: number;
  proposedEffectiveDate: Date | null;
  errorDetails?: { [key: string]: any };
  policyPeriodList: PolicyPeriodDto[];
};

const NON_EMPLOYEE_LEASING = 1;

const InsuredInformationPolicy: FC<insuredInfoPolicyProps> = ({
  programId,
  proposedEffectiveDate,
  errorDetails,
  policyPeriodList,
}) => {
  const readOnly = false;
  const [policy, setPolicy] = useState<PolicyBlob | null>();
  const [policyConfiguration, setPolicyConfiguration] =
    useState<Acord130ConfigurationDto>();

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  const { responseGet: acord130Config, dispatchGet: acord130Dispatch } =
    useApiGet<Acord130ConfigurationDto>(
      `api/InsuredInformation/GetAcord130PolicyConfiguration?programId=${programId}&effectiveDate=${getValidatedDateToPost(
        policy?.effectiveDate ?? new Date()
      )}`
    );

  const employeeOptions = getPolicyEnumValueCodeAndDescriptionAsOptions(
    policyConfiguration?.employeeLeasingList ?? []
  );
  const retrospectiveOptions = getPolicyEnumValueCodeAndDescriptionAsOptions(
    policyConfiguration?.retrospectiveRatingList ?? []
  );
  const customerOptions = policyConfiguration?.customerPolicyList ?? [];
  const experienceOptions = getPolicyEnumValueCodeAndDescriptionAsOptions(
    policyConfiguration?.experienceRatingList ?? []
  );
  const policyOptions = policyConfiguration?.policyLimitList ?? [];

  const updatePolicyItem = (value: string, target: string) => {
    setPolicy({
      ...policy,
      [target]: value,
    });
  };

  const onChangeSelectOption = (
    id: number,
    target: string,
    options: SelectOptions[]
  ) => {
    setPolicy?.((prev) => ({
      ...prev,
      [target]: getPolicyEnumBySelectOption(options, id),
    }));
  };

  const onChangeQuoteSelectOption = (
    id: number,
    target: string,
    options: SelectOptions[]
  ) => {
    setPolicy?.((prev) => ({
      ...prev,
      quote: {
        ...prev?.quote,
        [target]: getPolicyEnumBySelectOption(options, id),
      },
    }));
  };

  const onChangeQuotePolicyLimits = (
    value: number,
    policyOptions: PolicyLimitDto[]
  ) => {
    const selectedPolicyLimit = policyOptions.find(
      (policy) => policy.id === value
    );
    setPolicy?.((prev) => ({
      ...prev,
      eLEachAccident: selectedPolicyLimit?.eachAccident,
      eLEachEmployee: selectedPolicyLimit?.eachEmployee,
      eLDisease: selectedPolicyLimit?.policyLimit,
      quote: {
        ...prev?.quote,
        policyLimits: selectedPolicyLimit,
      },
    }));
  };

  const setPolicyDates = (proposedEffectiveDate?: Date | null) => {
    if (conditionHasValue(proposedEffectiveDate)) {
      const policyPeriod = proposedEffectiveDate
        ? getPolicyPeriod(proposedEffectiveDate, policyPeriodList)
        : null;

      const proposedExpirationDate = proposedEffectiveDate
        ? getExpirationDateByEffectiveDate(
            proposedEffectiveDate,
            policyPeriod?.lastDate
          )
        : null;

      setPolicy({
        ...policy,
        effectiveDate: proposedEffectiveDate,
        expirationDate: proposedExpirationDate,
      });
    }
  };

  const getDefaultPolicyLimit = (target: string, value?: number | null) => {
    const selectedPolicyLimit = conditionHasValue(policyOptions)
      ? policyOptions[0]
      : null;
    return selectedPolicyLimit && isEmptyValue(value)
      ? selectedPolicyLimit[target]
      : value;
  };

  const getDefaultSelectOptions = (
    options: SelectOptions[],
    value?: PolicyEnumValueCodeAndDescriptionBlob | null,
    defaultValue?: number
  ) => {
    const selectedOption = conditionHasValue(options)
      ? defaultValue
        ? options.find((o) => o.intValue === defaultValue)?.intValue
        : options[0]?.intValue
      : null;

    return selectedOption && isEmptyValue(value)
      ? getPolicyEnumBySelectOption(options, selectedOption)
      : value;
  };

  const defaultSelectComponent = (name: string, label: string) => (
    <Select
      id={`id-${name}-input`}
      name={`name-${name}-input`}
      label={label}
      labelFontType="BOLD_CAPTION"
      inputFontType="BODY"
      variant="filled"
      value={""}
      options={[]}
      readOnly={readOnly}
    />
  );

  useEffect(() => {
    if (
      conditionHasValue(policy) &&
      conditionHasValue(policy?.effectiveDate) &&
      conditionHasValue(policy?.expirationDate)
    ) {
      acord130Dispatch();
    }
  }, [policy?.effectiveDate, policy?.expirationDate]);

  useEffect(() => {
    if (isAPITotallyComplete(acord130Config)) {
      setPolicyConfiguration(acord130Config.axiosResponse?.data);
    }
  }, [acord130Config]);

  useEffect(() => {
    if (!isEmptyValue(policyConfiguration)) {
      const atomValue = getAtom();
      if (conditionHasValue(atomValue)) {
        setPolicy({
          ...atomValue,
          elEachAccident: getDefaultPolicyLimit(
            "eachAccident",
            atomValue?.elEachAccident
          ),
          elEachEmployee: getDefaultPolicyLimit(
            "eachEmployee",
            atomValue?.elEachEmployee
          ),
          elDisease: getDefaultPolicyLimit("policyLimit", atomValue?.elDisease),
          effectiveDate: atomValue?.effectiveDate,
          expirationDate: atomValue?.expirationDate,
          experienceRating: getDefaultSelectOptions(
            experienceOptions,
            atomValue?.experienceRating
          ),
          employeeLeasingType: getDefaultSelectOptions(
            employeeOptions,
            atomValue?.employeeLeasingType,
            NON_EMPLOYEE_LEASING
          ),
          retrospectiveRating: getDefaultSelectOptions(
            retrospectiveOptions,
            atomValue?.retrospectiveRating
          ),
          quote: {
            ...atomValue?.quote,
            effectiveDate: atomValue?.effectiveDate,
            expirationDate: atomValue?.expirationDate,
            customerPolicyType: getDefaultSelectOptions(
              customerOptions,
              atomValue?.quote?.customerPolicyType
            ),
            policyLimits: isEmptyValue(atomValue?.quote?.policyLimits)
              ? policyOptions[0]
              : atomValue?.quote?.policyLimits,
          },
        });
      }
    }
  }, [policyConfiguration]);

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(policy)) {
      setAtom({
        ...atomValue,
        elEachAccident: policy?.elEachAccident,
        elEachEmployee: policy?.elEachEmployee,
        elDisease: policy?.elDisease,
        effectiveDate: policy?.effectiveDate,
        expirationDate: policy?.expirationDate,
        experienceRating: policy?.experienceRating,
        employeeLeasingType: policy?.employeeLeasingType,
        retrospectiveRating: policy?.retrospectiveRating,
        quote: {
          ...atomValue?.quote,
          customerPolicyType: policy?.quote?.customerPolicyType,
          policyLimits: policy?.quote?.policyLimits,
        },
      });
    }
  }, [policy]);

  useEffect(() => {
    const atomValue = getAtom();
    if (
      conditionHasValue(atomValue?.effectiveDate) &&
      conditionHasValue(atomValue?.expirationDate)
    ) {
      setPolicy({
        ...policy,
        effectiveDate: atomValue?.effectiveDate,
        expirationDate: atomValue?.expirationDate,
        elEachAccident: atomValue?.elEachAccident,
        elEachEmployee: atomValue?.elEachEmployee,
        elDisease: atomValue?.elDisease,
        experienceRating: atomValue?.experienceRating,
        employeeLeasingType: atomValue?.employeeLeasingType,
        retrospectiveRating: atomValue?.retrospectiveRating,
        quote: {
          ...policy?.quote,
          customerPolicyType: atomValue?.quote?.customerPolicyType,
          policyLimits: atomValue?.quote?.policyLimits,
        },
      });
    }
    setPolicyDates(proposedEffectiveDate);
  }, []);

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold>POLICY INFORMATION</FontBold>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={27}
                  id="id-proposed-effective-date-input"
                  name="name-proposed-effective-date-input"
                  label="Proposed Effective Date"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  value={policy?.effectiveDate}
                  onChangeRawValue={(value) => setPolicyDates(value)}
                  readOnly={readOnly}
                  errorMessage={errorDetails?.effectiveDate}
                />
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={28}
                  id="id-proposed-expiration-date-input"
                  name="name-proposed-expiration-date-input"
                  label="Proposed Expiration Date"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  value={policy?.expirationDate}
                  onChangeRawValue={(value) =>
                    updatePolicyItem(value, "expirationDate")
                  }
                  readOnly={readOnly}
                  errorMessage={errorDetails?.expirationDate}
                />
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {employeeOptions.length > 0 ? (
                  <Select
                    tabIndex={29}
                    id="id-employee-leasing-input"
                    name="name-employee-leasing-input"
                    label="Employee Leasing"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={policy?.employeeLeasingType?.value}
                    options={employeeOptions}
                    onChange={(value) =>
                      onChangeSelectOption(
                        value,
                        "employeeLeasingType",
                        employeeOptions
                      )
                    }
                    readOnly={readOnly}
                    optionsMaxHeight="150px"
                    errorMessage={errorDetails?.employeeLeasingType}
                  />
                ) : (
                  defaultSelectComponent("employee-leasing", "Employee Leasing")
                )}
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {retrospectiveOptions.length > 0 ? (
                  <Select
                    tabIndex={30}
                    id="id-retrospective-rating-input"
                    name="name-retrospective-rating-input"
                    label="Retrospective Rating"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={policy?.retrospectiveRating?.value}
                    options={retrospectiveOptions}
                    onChange={(value) =>
                      onChangeSelectOption(
                        value,
                        "retrospectiveRating",
                        retrospectiveOptions
                      )
                    }
                    readOnly={readOnly}
                    optionsMaxHeight="150px"
                    errorMessage={errorDetails?.retrospectiveRating}
                  />
                ) : (
                  defaultSelectComponent(
                    "retrospective-rating",
                    "Retrospective Rating"
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {customerOptions.length > 0 ? (
                  <Select
                    tabIndex={31}
                    id="id-customer-policy-type-input"
                    name="name-customer-policy-type-input"
                    label="Customer Policy Type"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={policy?.quote?.customerPolicyType?.value}
                    options={customerOptions}
                    onChange={(value) =>
                      onChangeQuoteSelectOption(
                        value,
                        "customerPolicyType",
                        customerOptions
                      )
                    }
                    readOnly={readOnly}
                    optionsMaxHeight="150px"
                    errorMessage={errorDetails?.quote?.customerPolicyType}
                  />
                ) : (
                  defaultSelectComponent(
                    "customer-policy-type",
                    "Customer Policy Type"
                  )
                )}
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {experienceOptions.length > 0 ? (
                  <Select
                    tabIndex={32}
                    id="id-experience-rating-input"
                    name="name-experience-rating-input"
                    label="Experience Rating"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={policy?.experienceRating?.value}
                    options={experienceOptions}
                    onChange={(value) =>
                      onChangeSelectOption(
                        value,
                        "experienceRating",
                        experienceOptions
                      )
                    }
                    readOnly={readOnly}
                    optionsMaxHeight="150px"
                    errorMessage={errorDetails?.experienceRating}
                  />
                ) : (
                  defaultSelectComponent(
                    "experience-rating",
                    "Experience Rating"
                  )
                )}
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {getPolicyLimitsAsOptions(policyOptions).length > 0 ? (
                  <Select
                    tabIndex={33}
                    id="id-policy-limits-input"
                    name="name-policy-limits-input"
                    label="Policy Limits"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={policy?.quote?.policyLimits?.id}
                    options={getPolicyLimitsAsOptions(policyOptions)}
                    onChange={(value) =>
                      onChangeQuotePolicyLimits(value, policyOptions)
                    }
                    readOnly={readOnly}
                    optionsMaxHeight="150px"
                    errorMessage={errorDetails?.quote?.policyLimits}
                  />
                ) : (
                  defaultSelectComponent("policy-limits", "Policy Limits")
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InsuredInformationPolicy;
