import { Box } from "@mui/material";
import { cloneElement, FC, isValidElement, useEffect, useState } from "react";
import BaseTableDebuggerInformation from "./BaseTableDebuggerInformation";
import BaseTableDebuggerKey from "./BaseTableDebuggerKey";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { tableStyles } from "../TableStyles";
import { useBaseTableUtilities } from "../../../../hooks/useBaseTableUtilities";

const BaseTable2Wrapper: FC<any> = (props) => {
  const localOptions = useRecoilValue(globalOptions);
  const { getTableInstance } = useBaseTableUtilities("baseTableWrapper");
  const [height, setHeight] = useState("0px");
  const tableType = getTableInstance(props.uiid).tableType;
  const theme = themes[localOptions?.themeRefresh];
  const styles = tableStyles(theme, height);
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const renderKey = crypto.randomUUID(); // DEV NOTE: Don't wrap this in a useState hook. We use this to boobytrap the app. It's designed to change if this component is re-rendered.

  const registeredChildren = props.children.map((reactElement, i) => {
    if (isValidElement(reactElement)) {
      return cloneElement(props.children[i], {
        uiid: props.uiid,
        key: crypto.randomUUID(),
      });
    }
    return reactElement;
  });

  const tableElementReference = () => {
    const tableContainerElement = document.querySelector(
      `.true_ui_base_table_container`
    );
    if (!tableContainerElement) return;
    const tableInstance = getTableInstance(props.uiid);

    setHeight(tableInstance?.height ?? "parent_flex");
  };

  useEffect(() => {
    tableElementReference();
  }, []);

  return (
    <Box
      id={props.name ?? "NO_NAME_FOUND"}
      sx={{
        height: height === "parent_flex" ? "100%" : "",
        width: "100%",
        ...styles,
      }}
      true-element={props.name ?? "NO_NAME_FOUND"}
      // ref={tableElementReference}
    >
      <div
        className={`true_ui_base_table_container`}
        data-table-uiid={props.uiid}
        data-table-type={tableType}
      >
        <BaseTableDebuggerKey uiid={props.uiid} renderKey={renderKey} />

        {registeredChildren}

        <BaseTableDebuggerInformation uiid={props.uiid} />
      </div>
    </Box>
  );
};

export default BaseTable2Wrapper;
