import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { useSetRecoilState } from "recoil";
import { BaseTable } from "../../dtos/base-table";
import { BaseTable2 } from "../TrueUI";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSwitchStatusFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableSwitchStatusFilter";
import { AgencyLocationToFilterContactsAtom } from "./AgencyAtoms";
import EditIcon from "@mui/icons-material/Edit";
import { useRecoilState } from "recoil";
import { AgencyLocationModalAtom } from "./AgencyAtoms";
import { AgencyLocationDto } from "../../dtos/agency-location-dto";
import { useBaseTable } from "../../hooks/useBaseTable";

const tableName = "locations_table";

type LocationsProps = {
  locationsInformation: BaseTable;
  isNewAgency: boolean;
};

const customOptions = [
  { fieldName: "AgencyLocationNumber", width: 7 },
  { fieldName: "AgencyLocationName", width: 25 },
  { fieldName: "Address1", width: 20 },
  { fieldName: "City", width: 15 },
  { fieldName: "State", width: 6 },
  { fieldName: "PostCode", width: 9 },
  { fieldName: "PhoneNumber", width: 18 },
];

const Locations: FC<LocationsProps> = ({
  locationsInformation,
  isNewAgency,
}) => {
  const setAgencyLocationToFilterContactsAtom = useSetRecoilState<
    string | null
  >(AgencyLocationToFilterContactsAtom);
  const [agencyLocationModalValues, setAgencyLocationModalValues] =
    useRecoilState(AgencyLocationModalAtom);

  const AgencyLocationEditModalLink = (
    actionOptions: OptionsContextActionParameterProperties<AgencyLocationDto>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="save"
          size="small"
          onClick={() => {
            setAgencyLocationModalValues({
              ...agencyLocationModalValues,
              agencyLocationId: actionOptions.row?.AgencyLocationId ?? null,
              isOpen: true,
              isNew: false,
            });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: locationsInformation.columns,
      data: locationsInformation.data,
    },
    columnOptions: customOptions,
    filterOptions: [
      (actionOptions) =>
        BaseTableSwitchStatusFilter({
          filterOptions: actionOptions,
          columnsNames: ["AgencyLocationStatus"],
          checkedLabelText: "Show All?",
          labelFontType: "BODY",
          labelPosition: "start",
        }),
    ],
    events: {
      onRowClick: (e) => {
        setAgencyLocationToFilterContactsAtom(
          e?.isDeselected ? null : e?.hydratedData?.AgencyLocationId ?? ""
        );
      },
      addEventOverrideCallback: () =>
        setAgencyLocationModalValues({
          ...agencyLocationModalValues,
          isNew: true,
          isOpen: true,
        }),
    },
    toolbarOptions: {
      toolBarType: "compact",
      compactTitle: "Agency Locations",
      addButtonIsDisabled: isNewAgency,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          AgencyLocationEditModalLink(actionOptions),
      },
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={tableName} />;
};

export default Locations;
