import { FC } from "react";
import { BasePageContainer } from "../../TrueUI";
import UsersAndPermissions from "./UsersAndPermissions/UsersAndPermissions";

const AdminSecurity: FC = () => {
  return (
    <BasePageContainer
      id={"admin-security"}
      maxWidth={false}
      sxWrapper={{
        height: "100%",
        marginLeft: "5px",
        marginRight: "5px",
        marginBottom: "5px",
      }}
      sxBody={{ height: "100%" }}
      data
    >
      <UsersAndPermissions />
    </BasePageContainer>
  );
};

export default AdminSecurity;
