import { FC } from "react";
import { MultiAlignHeaderActions } from "./MultiAlignHeader";
import OptionsCellContextProcessor from "../SharedTableCells/OptionsCellContextProcessor";

type MultiAlignHeaderContextMenuProps = {
  contextConfiguration?: MultiAlignHeaderActions;
  metaData?: any;
};

const MultiAlignHeaderContextMenu: FC<MultiAlignHeaderContextMenuProps> = ({
  contextConfiguration,
  metaData,
}) => {
  return (
    <div
      className="multi_header_context_menu_container"
      style={{ width: "35px", marginRight: "5px" }}
    >
      <div className="more_horiz_icon_container">
        <OptionsCellContextProcessor
          optionsContextActions={contextConfiguration?.contextMenu ?? []}
          contextMenuOptionRenderingCondition={
            contextConfiguration?.contextMenuOptionRenderingCondition
          }
          additionalRowData={metaData}
        />
      </div>
    </div>
  );
};

export default MultiAlignHeaderContextMenu;
