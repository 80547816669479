import { Box, Container, Paper } from "@mui/material";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import { ProgramSelector } from "./";
import "./Landing.css";
import { landingStyles } from "./LandingStyles";

const Landing = () => {
  // const isLogin = useRecoilValue(LandingAtoms.IsLoginCompleteAtom());

  // const children = !isLogin ? <Login /> : <ProgramSelector />;
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  return (
    <Box sx={landingStyles(theme)}>
      <Container maxWidth={"sm"}>
        <Box id={"zzz"} className={"container-box"}>
          <Box>
            <Box className={"image-container"}>
              <img src="../media/True_long_LightMode.svg" />
            </Box>
            <Box
              component={Paper}
              elevation={0}
              className={"program-container"}
            >
              <ProgramSelector />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Landing;
