import { FC } from "react";
import Input, { InputProps } from "./Input";
import "./inputWithIcon.module.css";

type InputWithIconProps = {
  showIcon?: boolean;
  icon?: JSX.Element;
  iconColor?: string;
};

const InputWithIcon: FC<InputProps & InputWithIconProps> = ({
  id,
  name,
  value,
  label,
  variant = "filled",
  helperText = "",
  errorMessage = null,
  rows,
  align,
  maxLength,
  readOnly = false,
  onChangeRawValue,
  type,
  prefix,
  onChange,
  onBlur,
  inputFontType,
  labelFontType,
  inputWidth = "100%",
  focus = false,
  showIcon,
  icon,
  iconColor,
  tabIndex,
  allowNegatives = false,
}) => {
  const onChangeInput = (e: any) => {
    onChangeRawValue?.(e.target.value);
    onChange?.(e);
  };

  return (
    <div
      id="input-warning-icon-wrapper"
      className="true_input_warning_icon_wrapper"
    >
      <Input
        tabIndex={tabIndex}
        className={showIcon ? "true_input_with_icon" : ""}
        id={id}
        name={`${name}-input-with-icon`}
        label={label}
        variant={variant}
        inputWidth={inputWidth}
        value={value}
        maxLength={maxLength}
        onChange={onChangeInput}
        onBlur={onBlur}
        type={type}
        errorMessage={errorMessage}
        helperText={helperText}
        rows={rows}
        align={align}
        prefix={prefix}
        readOnly={readOnly}
        inputFontType={inputFontType}
        labelFontType={labelFontType}
        focus={focus}
        allowNegatives={allowNegatives}
      />
      {showIcon && (
        <div style={{ color: iconColor }} className="true_input_warning_icon">
          {icon}
        </div>
      )}
    </div>
  );
};

export default InputWithIcon;
