import { FC } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { Input } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";

const EndorsementBindInstructionsNoChange: FC<EndorsementGeneralProps> = () => {
  return (
    <div className={style.endorsement_bind_instructions_body_container}>
      <Input
        id="id-endorsement-premium-change"
        name="endorsement-premium-change"
        label="Endorsement Premium Change"
        inputWidth="250px"
        readOnly
        value={"0"}
      />
    </div>
  );
};

export default EndorsementBindInstructionsNoChange;
