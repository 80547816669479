import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2 } from "../TrueUI";
import BaseTableInputDateFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableInputDateFilter";
import { useEffect, useState } from "react";
import BaseTableTitleDisplay from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableTitleDisplay";
import { useApiGet } from "../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../utilities/apiFunctions";
import { FormattingDate } from "../../utilities/dateFunctions";
import { currencyFormat } from "../../utilities/currencyFunctions";

const PaymentActivityTable = () => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [paymentDate, setPaymentDate] = useState<Date>(new Date());
  const [totalReceived, setTotalReceived] = useState<string>();
  const { dispatchGet, responseGet } = useApiGet<number>(
    `api/PaymentActivity/GetTotalReceived?PaymentDate=${FormattingDate(
      paymentDate
    )}`
  );

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        fontSize="16px"
        width={"inherit"}
      >
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            console.log({ actionOptions });
          }}
        >
          <Launch fontSize="inherit" sx={{ color: theme?.TEXT_COLOR }} />
        </IconButton>
      </Box>
    );
  };

  const tableName = "PaymentActivityTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PaymentActivity/GetPaymentActivityTable?paymentDate=${FormattingDate(
      paymentDate
    )}`,
    columnOptions: [
      { fieldName: "InsuredName", width: 45 },
      { fieldName: "PaymentType", width: 15 },
      { fieldName: "ReferenceNumber", width: 20 },
      { fieldName: "Amount", width: 20, align: "right" },
      { fieldName: "OPTIONS", width: 2 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "Payment Date",
          labelPosition: "start",
          inputWidth: "320px",
          labelFontType: "BODY",
          columnsNames: ["PaymentDate"],
          defaultValue: paymentDate,
          onChangeRawValue: (value) => {
            setPaymentDate(value);
          },
        }),
      () =>
        BaseTableTitleDisplay({
          title: "Total Received:",
          fontType: "BODY",
          width: "200px",
          marginLeft: 20,
        }),
      () =>
        BaseTableTitleDisplay({
          title: totalReceived ?? "",
          fontType: "BOLD_BODY",
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: true,
        optionType: "component",
        optionsStateCondition: (e) => {
          if (e.triggerState === "on_init") {
            if (e.hydratedRow.IsBold === "true") {
              return "none";
            } else {
              return "component";
            }
          }
          return e.currentOptionValue;
        },
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      disableOrderBy: true,
      paginate: false,
      hideRefreshFiltersButton: true,
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    dispatchGet();
  }, [paymentDate]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseGet)) {
      setTotalReceived(
        currencyFormat(responseGet?.axiosResponse?.data, false, 2, true)
      );
    }
  }, [responseGet]);

  return (
    <div id={"payment_activity_container"} key={totalReceived}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default PaymentActivityTable;
