import { Box } from "@mui/material";
import { BaseTableColumn } from "../../../../../../dtos/base-table-column";
import { BaseTableInputType } from "../../../../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../../../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../../../../../dtos/data-type-enums";
import { PolicyWaiverDto } from "../../../../../../dtos/policy-waiver-dto";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { Col, FontBold, Row, Select } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PremiumTableRows/PremiumTableRowsUtils";
import { modalSpecificWaiverTotalsRowStyles } from "./ModalSpecificWaiverStyles";

export const specificWaiverTableColumns: BaseTableColumn[] = [
  {
    _columnIndex: 0,
    _visibleColumnIndex: 0,
    displayName: "ClassCodeID",
    fieldName: "ClassCodeID",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
    maxLength: 5,
  },
  {
    _columnIndex: 1,
    _visibleColumnIndex: 1,
    displayName: "Class Code",
    fieldName: "ClassCode",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
    maxLength: 5,
  },
  {
    _columnIndex: 2,
    _visibleColumnIndex: 2,
    displayName: "Suffix",
    fieldName: "Suffix",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
    maxLength: 5,
  },
  {
    _columnIndex: 3,
    _visibleColumnIndex: 3,
    displayName: "Rate",
    fieldName: "Rate",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 4,
    _visibleColumnIndex: 4,
    displayName: "Policy Payroll",
    fieldName: "PolicyPayroll",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 5,
    _visibleColumnIndex: 5,
    displayName: "Waiver Payroll",
    fieldName: "WaiverPayroll",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.DECIMAL,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 6,
    _visibleColumnIndex: 6,
    displayName: "Premium Charge",
    fieldName: "PremiumCharge",
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
];

export const CustomHeaderComponent = (
  locations: Partial<SelectOptions>[],
  specificWaiverUI?: PolicyWaiverDto | null,
  setSpecificWaiverUI?: React.Dispatch<
    React.SetStateAction<PolicyWaiverDto | null | undefined>
  >
) => (
  <Select
    id="id-specific-waiver-location"
    name="name-specific-waiver-location"
    options={locations}
    variant="filled"
    label="Location:"
    readOnly={false}
    labelPosition="start"
    labelFontType="BODY"
    value={specificWaiverUI?.locationId ?? ""}
    onChange={(value) =>
      setSpecificWaiverUI?.((prev) => ({ ...prev, locationId: value }))
    }
    inputWidth="250px"
  />
);

export const CustomFooterComponent = (
  theme: any,
  totalPayroll: string,
  totalCharge: string
) => (
  <Box sx={modalSpecificWaiverTotalsRowStyles(theme)}>
    <Row
      className="waiver-totals-row"
      {...rowWithNoMarginNorGutter}
      numberOfColumns={24}
    >
      <Col {...getConfigurationForColumn(11, "flex-end", "center")}>
        <FontBold>Total Waiver Payroll</FontBold>
      </Col>
      <Col
        {...getConfigurationForColumn(5, "flex-end", "center")}
        horizontalGutter="15px"
      >
        <FontBold>{totalPayroll}</FontBold>
      </Col>
      <Col {...getConfigurationForColumn(5, "flex-start", "center")}>
        <FontBold>Total Waiver Charge</FontBold>
      </Col>
      <Col {...getConfigurationForColumn(3, "flex-end", "center")}>
        <FontBold>{totalCharge}</FontBold>
      </Col>
    </Row>
  </Box>
);
