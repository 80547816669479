import { RecoilState, useRecoilCallback } from "recoil";
import {
  TriggerAllComponentNames,
  TriggerComponentUpdateAtom,
} from "../components/Insured/InsuredAtoms";

export function useAtomFamily<T = any>(
  AtomFamily: RecoilState<T>
): {
  setAtom: (newAtomValue: T) => void;
  getAtom: () => T;
  resetAtom: () => void;
  setComponentTriggers: (
    componentsKeysList: TriggerAllComponentNames[]
  ) => void;
} {
  const setAtom = useRecoilCallback(
    ({ set }) =>
      (newAtomValue: T) => {
        set(AtomFamily, newAtomValue);
      },
    []
  );

  const getAtom = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(AtomFamily).contents as T;
      },
    []
  );

  const resetAtom = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(AtomFamily);
      },
    []
  );

  /**
   * @deprecation_warning Please do not use this hook. It's only intended as a temporary solution. Please use a identical stand alone functionailty as TriggerComponentNames is not a maintainable type. - last updated: 2/1/2023
   * @deprecation_information A long term solution will come in the future. - last updated: 2/1/2023
   */
  const setComponentTriggers = useRecoilCallback(
    ({ set }) =>
      (componentsKeysList: TriggerAllComponentNames[]) => {
        componentsKeysList.map((key) => {
          const newRefresh = crypto.randomUUID();
          set(TriggerComponentUpdateAtom(key as any), newRefresh);
        });
      },
    []
  );

  return { setAtom, getAtom, resetAtom, setComponentTriggers };
}
