import { FC, useEffect, useState } from "react";
import {
  PayrollReportModalContentProps,
  getDefaultFromDateAndToDateValues,
} from "./PayrollReportUtils";
import { Col, InputDate, Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PayrollReportConfiguration } from "../../../../dtos/payroll-report-configuration";

const PayrollReportModalContent: FC<PayrollReportModalContentProps> = ({
  insuredId,
  payrollReportData,
  setPayrollReportData,
  errorMessages,
}) => {
  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/PayrollReport/GetPayrollConfiguration?insuredId=${insuredId}`
  );

  const [formConfiguration, setFormConfiguration] =
    useState<PayrollReportConfiguration>();

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormConfiguration(responseGet.axiosResponse?.data);
      setPayrollReportData({
        ...payrollReportData,
        policyDateList: responseGet.axiosResponse?.data?.policyDateList,
      });
    }
  }, [responseGet]);

  const setDefaultFromDateAndToDateValues = (policyId: number) => {
    const defaultDates = getDefaultFromDateAndToDateValues(
      formConfiguration?.policyDateList,
      policyId
    );
    setPayrollReportData({
      ...payrollReportData,
      policyId: policyId,
      fromDate: defaultDates.fromDate,
      toDate: defaultDates.toDate,
    });
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start" breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Select
            {...selectConfiguration(
              "id-policy-id",
              "policy-id",
              "Policy",
              false
            )}
            firstOptionAsDefault={false}
            labelFontType="BOLD_CAPTION"
            options={formConfiguration?.policyOptions ?? []}
            value={payrollReportData?.policyId}
            errorMessage={errorMessages?.policyId}
            onChange={(value) => {
              setDefaultFromDateAndToDateValues(value);
            }}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start" breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <InputDate
            id="id-from-date"
            name="from-date"
            label="From Date"
            labelFontType="BOLD_CAPTION"
            readOnly={false}
            value={payrollReportData?.fromDate}
            errorMessage={errorMessages?.fromDate}
            onChangeRawValue={(dateValue) =>
              setPayrollReportData({
                ...payrollReportData,
                fromDate: dateValue,
              })
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign="flex-start" breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <InputDate
            id="id-to-date"
            name="to-date"
            label="To Date"
            labelFontType="BOLD_CAPTION"
            readOnly={false}
            value={payrollReportData?.toDate}
            errorMessage={errorMessages?.toDate}
            onChangeRawValue={(dateValue) =>
              setPayrollReportData({
                ...payrollReportData,
                toDate: dateValue,
              })
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default PayrollReportModalContent;
