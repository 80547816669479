export const rgbaToHex = (rgbaColor) => {
  // Extract the RGB values and alpha value
  var match = rgbaColor.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/
  );
  if (!match) {
    throw new Error("Invalid RGBA color format");
  }

  var red = parseInt(match[1]);
  var green = parseInt(match[2]);
  var blue = parseInt(match[3]);
  var alpha = match[4] ? parseFloat(match[4]) : 1;

  // Convert RGB values to hexadecimal format
  var hexRed = red.toString(16).padStart(2, "0");
  var hexGreen = green.toString(16).padStart(2, "0");
  var hexBlue = blue.toString(16).padStart(2, "0");

  // Convert alpha value to hexadecimal format
  var alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  // Construct the hexadecimal color string
  var hexColor = "#" + hexRed + hexGreen + hexBlue + alphaHex;

  return hexColor.toUpperCase(); // Optionally, convert to uppercase
};
