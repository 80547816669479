import { FC, useEffect, useState } from "react";
import {
  EndorsementGeneralProps,
  EndorsementUIProps,
} from "./EndorsementTypes";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import {
  ENDORSEMENT_SECTION,
  INSURED_ATOM_KEY,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useRecoilValue } from "recoil";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { updatePolicyQuoteInformationMultiTarget } from "../updatesPolicyQuoteFunctions";
import { useApiGet } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import {
  breadcrumbChipItems,
  defaultBreadCrumbsChips,
  getBreadcrumbItems,
  getEndorsementSectionAndIds,
  hasEndorsementChecked,
  hasEndorsementPolicyKeyFields,
  hasEndorsementProducerName,
  hasExposurePremiumRelatedChecked,
} from "./EndorsementUtils";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { BreadcrumbNavWithActionButton } from "../../../TrueUI";
import style from "./Endorsement.module.css";
import EndorsementBodySelector from "./EndorsementBodySelector";
import EndorsementBottomButtons from "./EndorsementBottomButtons";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  BreadcrumbChipProps,
  BreadcrumbItemProps,
} from "../../../TrueUI/BreadcrumbNav/BreadcrumbNavWithActionButton";
import PolicyKeyFieldsModal from "../EndorsementInformation/PolicyKeyFields/PolicyKeyFieldsModal";
import { EndorsementTypeEnum } from "../../../../dtos/endorsement-type-enum";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import ProducerChangeModal from "../EndorsementInformation/EndorsementProducer/ProducerChangeModal";
import {
  ProducerModalConfig,
  getAgencyOptions,
  getCurrentProducerAsProducerDataUI,
} from "../EndorsementInformation/EndorsementProducer/ProducerChangeUtil";
import {
  PolicyKeyFieldsModalConfig,
  defaultPolicyKeyFieldsModalUI,
} from "../EndorsementInformation/PolicyKeyFields/PolicyKeyFieldsUtil";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";

const defaultEndorsementUI: EndorsementUIProps = {
  activeSection: EndorsementSectionEnum.ENDORSEMENT_INFORMATION,
  activeChip: -1,
  policyId: null,
  previousQuoteId: null,
  quoteId: null,
  readonly: null,
};

const defaultProducerModalUI = {
  isOpen: false,
  isProducerCheck: false,
} as ProducerModalConfig;

const Endorsement: FC<EndorsementGeneralProps> = ({ insuredId, tabKey }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const [endorsementUI, setEndorsementUI] =
    useState<EndorsementUIProps>(defaultEndorsementUI);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [policyKeyFieldsModalConfig, setPolicyKeyFieldsModalConfig] =
    useState<PolicyKeyFieldsModalConfig>(defaultPolicyKeyFieldsModalUI);
  const setActiveSection = (activeSection: any) => {
    setEndorsementUI((prev) => ({ ...prev, activeSection }));
  };
  const [breadcrumbItemList, setBreadcrumbItemList] = useState<
    BreadcrumbItemProps[]
  >(getBreadcrumbItems(setActiveSection));
  const [producerModalConfig, setProducerModalConfig] =
    useState<ProducerModalConfig>(defaultProducerModalUI);
  const [breadcrumbChipList, setBreadcrumbChipList] = useState<
    BreadcrumbChipProps[]
  >(defaultBreadCrumbsChips);

  const { responseGet, dispatchGet } = useApiGet<PolicyBlob>(
    `api/QuotePolicy/GetConfigurationForInformationForms?insuredId=${insuredId}&policyId=${
      endorsementUI.policyId
    }&quoteId=${endorsementUI.quoteId}&previousQuoteId=${
      endorsementUI.previousQuoteId
    }&formType=${PolicyFormTypeEnum.ENDORSEMENT_FORM}&isAdding=${
      endorsementUI.quoteId === 0
    }`
  );
  const listenerEndorsementComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementComponent")
  );
  const listenerEndorsementCheckItem = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementCheckItem")
  );
  const listenerReadOnlyComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteReadOnlyComponent")
  );

  const setInitialValuesByURL = () => {
    const atomValue = getAtom();
    const { endorsementSection, policyId, quoteId, previousQuoteId } =
      getEndorsementSectionAndIds(atomValue);

    if (policyId !== null && quoteId !== null) {
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyId", "quoteId", "previousQuoteId", "activeSection"],
        [policyId, quoteId, previousQuoteId, endorsementSection]
      );
      setEndorsementUI({
        activeSection: endorsementSection,
        activeChip: -1,
        policyId,
        quoteId,
        previousQuoteId,
        readonly: null,
      });
      setAtom(newAtomValue);
      addQueryStrings([
        { nameOfHash: ENDORSEMENT_SECTION, valueOfHash: endorsementSection },
        { nameOfHash: POLICY_ID, valueOfHash: policyId },
        { nameOfHash: PREVIOUS_QUOTE_ID, valueOfHash: previousQuoteId ?? "-1" },
        { nameOfHash: QUOTE_ID, valueOfHash: quoteId },
      ]);
    }
  };

  const setPolicyKeyFieldsModalProps = (
    endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
  ) =>
    setPolicyKeyFieldsModalConfig({
      ...policyKeyFieldsModalConfig,
      isPolicyNumberCheck: hasEndorsementChecked(
        EndorsementTypeEnum.POLICY_NUMBER,
        endorsementList
      ),
      isPolicyEffectiveDateCheck: hasEndorsementChecked(
        EndorsementTypeEnum.EFFECTIVE_DATE,
        endorsementList
      ),
      isPolicyExpirationDateCheck: hasEndorsementChecked(
        EndorsementTypeEnum.EXPIRATION_DATE,
        endorsementList
      ),
      isOpen: hasEndorsementPolicyKeyFields(endorsementList),
    });

  const setProducerModalProps = (
    endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
  ) => {
    const policyJSON = getAtom()?.policyQuoteInformation?.policyQuote ?? {};
    const AgencyOptionList = getAgencyOptions(policyJSON);
    const currentProducer = getCurrentProducerAsProducerDataUI(policyJSON);
    setProducerModalConfig({
      ...producerModalConfig,
      isProducerCheck: hasEndorsementChecked(
        EndorsementTypeEnum.PRODUCER_NAME,
        endorsementList
      ),
      isOpen: hasEndorsementProducerName(endorsementList),
      agencies: AgencyOptionList ?? [],
      currentProducerData: currentProducer,
    });
  };

  const setBreadcrumbsChips = (
    prevPolicyKeyFields?: BreadcrumbChipProps,
    prevProducerName?: BreadcrumbChipProps,
    endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
  ) =>
    setBreadcrumbChipList([
      {
        ...prevPolicyKeyFields,
        show: hasEndorsementPolicyKeyFields(endorsementList),
        action: () => setPolicyKeyFieldsModalProps(endorsementList),
      },
      {
        ...prevProducerName,
        show: hasEndorsementProducerName(endorsementList),
        action: () => setProducerModalProps(endorsementList),
      },
    ]);

  const setPolicyKeyFieldsModalInitialValues = (policyObject?: PolicyBlob) =>
    setPolicyKeyFieldsModalConfig({
      ...policyKeyFieldsModalConfig,
      policyPeriod: {
        firstDate: policyObject?.policyPeriod?.firstDate ?? null,
        lastDate: policyObject?.policyPeriod?.lastDate ?? null,
      },
      policyTermList:
        policyObject?.endorsementConfiguration?.policyTermCodeList ?? null,
    });

  const overrideActiveSection = () => {
    const atomValue = getAtom();
    const activeSection = atomValue?.policyQuoteInformation?.activeSection;
    return endorsementUI.readonly === true
      ? EndorsementSectionEnum.POLICY_DOCS + 1
      : activeSection === EndorsementSectionEnum.BIND_INSTRUCTIONS
      ? EndorsementSectionEnum.POLICY_DOCS
      : EndorsementSectionEnum.ENDORSEMENT_INFORMATION;
  };

  useEffect(() => {
    setInitialValuesByURL();
  }, []);

  useEffect(() => {
    if (endorsementUI.policyId !== null && endorsementUI.quoteId !== null)
      dispatchGet();
  }, [endorsementUI.policyId]);

  useEffect(() => {
    if (listenerEndorsementComponent !== null) {
      const atomValue = getAtom();
      setActiveSection(
        atomValue?.policyQuoteInformation?.activeSection ??
          EndorsementSectionEnum.ENDORSEMENT_INFORMATION
      );
      clearPolicyQuoteTriggers(["endorsementComponent"]);
    }
  }, [listenerEndorsementComponent]);

  useEffect(() => {
    if (listenerEndorsementCheckItem !== null) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const endorsementList = policyQuote?.quote?.endorsementTypeList;
      const prevPolicyKeyFields = breadcrumbChipList.find(
        (item) => item.key === breadcrumbChipItems.POLICY_KEY_FIELDS
      );
      const prevProducerName = breadcrumbChipList.find(
        (item) => item.key === breadcrumbChipItems.PRODUCER_NAME
      );
      setBreadcrumbsChips(
        prevPolicyKeyFields,
        prevProducerName,
        endorsementList
      );

      const showExposureAndPremiumSection =
        hasExposurePremiumRelatedChecked(endorsementList);
      setBreadcrumbItemList(
        getBreadcrumbItems(setActiveSection, showExposureAndPremiumSection)
      );
      clearPolicyQuoteTriggers(["endorsementCheckItem"]);
    }
  }, [listenerEndorsementCheckItem]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const atomValue = getAtom();
      const policyJSON = responseGet.axiosResponse?.data;
      const isReadOnly =
        policyJSON?.quote?.quoteStatus?.value === PolicyQuoteStatusEnum.BOUND;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, isReadOnly]
      );
      setAtom(newAtomValue);
      setEndorsementUI({
        ...endorsementUI,
        readonly: isReadOnly,
      });
      const showExposureAndPremiumSection = hasExposurePremiumRelatedChecked(
        policyJSON?.quote?.endorsementTypeList
      );
      setBreadcrumbItemList(
        getBreadcrumbItems(setActiveSection, showExposureAndPremiumSection)
      );
      setPolicyQuoteTriggers(["endorsementHeaderComponent"]);
      setPolicyKeyFieldsModalInitialValues(policyJSON);
    }
  }, [responseGet]);

  useEffect(() => {
    if (listenerReadOnlyComponent !== null) {
      const atomValue = getAtom();
      setEndorsementUI({
        ...endorsementUI,
        readonly: atomValue?.policyQuoteInformation?.readOnly ?? false,
      });
      clearPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      setPolicyQuoteTriggers(["endorsementHeaderComponent"]);
    }
  }, [listenerReadOnlyComponent]);

  useEffect(() => {
    if (endorsementUI.readonly !== null) setReadyToRender(true);
  }, [endorsementUI.readonly]);

  return readyToRender === true ? (
    <div id="endorsement-container" className={style.endorsement_container}>
      <BreadcrumbNavWithActionButton
        activeItem={endorsementUI.activeSection}
        activeChip={endorsementUI.activeChip}
        configItemList={breadcrumbItemList}
        configChipList={breadcrumbChipList}
        overrideMaxActiveSection={overrideActiveSection()}
      />
      <div
        id="endorsement-body-selector-container"
        className={style.endorsement_body_selector_container}
      >
        <EndorsementBodySelector
          insuredId={insuredId}
          tabKey={tabKey}
          activeSection={endorsementUI.activeSection}
          setActiveSection={setActiveSection}
          policyId={endorsementUI.policyId}
          previousQuoteId={endorsementUI.previousQuoteId}
          quoteId={endorsementUI.quoteId}
          readonly={endorsementUI.readonly ?? false}
          setProducerModalProps={setProducerModalProps}
        />
      </div>
      <EndorsementBottomButtons
        tabKey={tabKey}
        activeSection={endorsementUI.activeSection}
        readonly={endorsementUI.readonly ?? false}
      />
      <PolicyKeyFieldsModal
        tabKey={tabKey}
        modalConfiguration={policyKeyFieldsModalConfig}
        readonly={endorsementUI.readonly ?? false}
        closeModal={() =>
          setPolicyKeyFieldsModalConfig({
            ...policyKeyFieldsModalConfig,
            isOpen: false,
          })
        }
      />
      <ProducerChangeModal
        tabKey={tabKey}
        modalConfiguration={producerModalConfig}
        readonly={endorsementUI.readonly ?? false}
        setModalConfiguration={setProducerModalConfig}
      />
    </div>
  ) : (
    <>LOADING...</>
  );
};

export default Endorsement;
