import { useState } from "react";
import { BaseTable2 } from "../../TrueUI";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import { SelectOptions } from "../../../dtos/select-options";
import style from "./SubscriptionsGrid.module.css";
import {
  subscriptionOptions,
  UserSubscriptionState,
} from "./SubscriptionUtils";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";

const SubscriptionsGrid = () => {
  const [notificationMethod, setNotificationMethod] = useState("all");
  const [sortBy, setSortBy] = useState("category");
  const [subscriptionModalConfig, setSubscriptionModalConfig] =
    useState<UserSubscriptionState>({ isOpen: false });

  const methodQueryString =
    notificationMethod === "all"
      ? null
      : `&notificationMethod=${notificationMethod}`;

  const name = "user_subscriptions_grid";
  const getURL = `api/Subscription/GetSubscriptionsGrid?sortBy=${sortBy}${
    methodQueryString ?? ""
  }`;

  const closeModal = () => {
    setSubscriptionModalConfig({
      ...subscriptionModalConfig,
      isOpen: false,
      subscriptionId: null,
      categoryId: null,
    });
  };

  const onSubscriptionSaved = () => {
    hooks.methods.refreshTable();
  };

  const optionButtons = (rowData?: any) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          {rowData?.SubscriptionId !== null && (
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() =>
                setSubscriptionModalConfig({
                  ...subscriptionModalConfig,
                  isOpen: true,
                  categoryId: parseInt(rowData?.CategoryId ?? "0"),
                  subscriptionId: parseInt(rowData?.SubscriptionId ?? "0"),
                })
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </div>
        <div>
          {rowData?.IsHeader && !rowData?.HasAllSubscriptions && (
            <IconButton
              aria-label="save"
              size="small"
              onClick={() =>
                setSubscriptionModalConfig({
                  ...subscriptionModalConfig,
                  isOpen: true,
                  categoryId: parseInt(rowData?.CategoryId ?? "0"),
                  subscriptionId: null,
                })
              }
            >
              <AddIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  const sortByOptions: Partial<SelectOptions>[] = [
    {
      displayName: "Category",
      stringValue: "category",
    },
    {
      displayName: "Event Description",
      stringValue: "description",
    },
  ];

  const gridConfig: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      showExcelButton: false,
      showPDFButton: false,
    },
    columnOptions: [
      { fieldName: "ActivityType", width: 10 },
      { fieldName: "CategoryDescription", width: 30 },
      { fieldName: "InApp", width: 5 },
      { fieldName: "SMS", width: 5 },
      { fieldName: "Email", width: 5 },
      { fieldName: "FilterToPinned", width: 15 },
      { fieldName: "AssignedToUserOnly", width: 15 },
      { fieldName: "Individual", width: 15 },
      { fieldName: "OPTIONS", width: 5 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "notification-method",
          filterOptions: actionOptions,
          optionsOverride: addAllOption(subscriptionOptions),
          selectWidth: "210px",
          isStaticFilter: true,
          usingExternalFiltering: true,
          labelText: "Type:",
          defaultValue: "all",
          onChange: (value) => setNotificationMethod(value),
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "sort-by",
          filterOptions: actionOptions,
          optionsOverride: sortByOptions,
          selectWidth: "250px",
          isStaticFilter: true,
          usingExternalFiltering: true,
          labelText: "Sort:",
          defaultValue: "category",
          onChange: (value) => setSortBy(value),
        }),
    ],
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => optionButtons(actionOptions?.row),
      },
    },
  };

  const { tableMethods } = useBaseTable(gridConfig);
  const hooks = tableMethods[name];

  return (
    <div className={style.content_container}>
      <div className={style.subscriptions_grid_container}>
        <BaseTable2 name={name} />
      </div>
      <SubscriptionModal
        modalConfig={subscriptionModalConfig}
        closeModal={closeModal}
        onSaveSuccessful={onSubscriptionSaved}
      />
    </div>
  );
};

export default SubscriptionsGrid;
