import { FC, useEffect } from "react";
import { Col, Input, Row, Select } from "../../TrueUI";

import {
  inputConfiguration,
  selectConfiguration,
} from "../../../utilities/inputPropsConfigurationFunctions";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { AchTransactionModalContentProps } from "./AchTypes";
import { useApiGet } from "../../../hooks";
import { ScheduledTransactionConfigurationDto } from "../../../dtos/scheduled-transaction-configuration-dto";
import PolicyDropdown from "../../Insured/Policy/PolicyDropdown";

const AchTransactionModalContent: FC<AchTransactionModalContentProps> = ({
  achTransactionData,
  setAchTransactionData,
  errorDetails,
  setErrorDetails,
}) => {
  const { responseGet, dispatchGet } =
    useApiGet<ScheduledTransactionConfigurationDto>(
      `api/BillingTransaction/GetScheduledTransactionsConfiguration?insuredId=${
        achTransactionData?.InsuredId ?? 0
      }`
    );

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Input
            {...inputConfiguration(
              "id-insured-name",
              "insured-name",
              "Insured Name",
              300,
              true
            )}
            value={achTransactionData?.InsuredName}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <PolicyDropdown
            insuredId={achTransactionData?.InsuredId}
            name={"policyId"}
            id={"id-policy"}
            label={"Policy"}
            labelFontType={"BOLD_CAPTION"}
            defaultValue={achTransactionData?.PolicyId ?? -1}
            policySelectedOnChange={(value) =>
              setAchTransactionData({ ...achTransactionData, PolicyId: value })
            }
            apiUrl={"api/Policy/GetBoundPoliciesByInsured"}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {responseGet?.axiosResponse?.data?.accountOptions && (
            <Select
              {...selectConfiguration(
                "id-pay-from-account",
                "pay-from-account",
                "Pay From Account",
                false
              )}
              labelFontType="BOLD_CAPTION"
              firstOptionAsDefault={false}
              options={responseGet?.axiosResponse?.data?.accountOptions ?? []}
              value={achTransactionData?.InsuredAccountId ?? -1}
              onChange={(value) =>
                setAchTransactionData({
                  ...achTransactionData,
                  InsuredAccountId: value,
                })
              }
            />
          )}
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          {responseGet?.responseData?.accountTypes && (
            <Select
              {...selectConfiguration(
                "id-pay-account-type",
                "pay-account-type",
                "Pay Account Type",
                true
              )}
              labelFontType="BOLD_CAPTION"
              options={responseGet?.responseData?.accountTypes ?? []}
              value={achTransactionData?.InsuredAccountId}
            />
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {responseGet?.responseData?.depositAccountOptions && (
            <Select
              {...selectConfiguration(
                "id-deposit-account-number",
                "deposit-account-number",
                "Deposit Account Number",
                false
              )}
              labelFontType="BOLD_CAPTION"
              options={responseGet?.responseData?.depositAccountOptions ?? []}
              value={achTransactionData?.TranAccountId}
              onChange={(value) =>
                setAchTransactionData({
                  ...achTransactionData,
                  TranAccountId: value,
                })
              }
            />
          )}
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration("id-amount", "amount", "Amount", 23)}
            type={"fixedCurrency"}
            value={achTransactionData?.TransactionAmount}
            onChangeRawValue={(value) => {
              setAchTransactionData({
                ...achTransactionData,
                TransactionAmount: value ?? "0",
              });
              setErrorDetails(null);
            }}
            errorMessage={errorDetails?.transactionAmount ?? null}
          />
        </Col>
      </Row>
    </>
  );
};

export default AchTransactionModalContent;
