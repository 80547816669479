import { Theme } from "../../media/themeTypes";

export const permissionsStyle = (theme: Theme) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  margin: "0 auto",
  "#user-permissions-title-container": {
    paddingBottom: "20px",
  },
  "#user-permissions-table-container": {
    overflowY: "auto",
    paddingLeft: "15px",
  },
  ".table-header": {
    backgroundColor: theme?.SELECTED_ROW_BACKGROUND,
    position: "sticky",
    top: 0,
    zIndex: 1,
    ".table-header-permission": {
      width: "350px",
    },
    ".table-header-program": {
      width: "250px",
    },
  },
  td: {
    p: 0,
    pl: 1,
  },
  th: {
    fontSize: theme?.BODY.SIZE,
    color: theme?.TEXT_COLOR,
    height: "42px",
  },
  ".switch-cell": {
    "> div": {
      alignContent: "center",
      "> label": {
        width: "fit-content",
      },
    },
  },
});
