import { FC, CSSProperties } from "react";
import { DeleteTypes } from "../BaseTable2/TableProperties";

type TableRowProperties = {
  rowKey?: string;
  children?: any;
  style?: CSSProperties;
  className?: string;
  deletedRow?: { isDeleted: boolean; deletedType: DeleteTypes };
  errorsByRow?: any;
  onClick?: (e?: any) => any;
};

const TableRow: FC<TableRowProperties> = ({
  rowKey,
  children,
  style,
  deletedRow, // = { isDeleted: false, deletedType: "none" },
  errorsByRow,
  onClick,
  className,
}) => {
  const hasError = errorsByRow != null && errorsByRow.length > 0 ? true : false;
  const message =
    errorsByRow != null && errorsByRow.length > 0 ? errorsByRow?.[0] : "";

  return !deletedRow?.isDeleted ? (
    <>
      <div
        onClick={(e) => onClick?.(e)}
        className={`true_table_row  ${className ?? ""}${
          hasError ? " error" : ""
        }`} // BASETABLE TODO - move the table type class name to the top of the table and use css pointers to modify based on the type instead of having it on every row
        style={style}
        data-row-uiid={rowKey}
      >
        {children}
      </div>
      {hasError && <div className="errors-container">{message}</div>}
    </>
  ) : null;
};

export default TableRow;
