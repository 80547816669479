import { FC, useState } from "react";
import { SiteNotificationDto } from "../../dtos/site-notification-dto";
import { SiteNotificationTypeEnums } from "../../dtos/site-notification-type-enums";
import { useApiPost } from "../../hooks";
import { FromEnumToArray } from "../../utilities/enumFunctions";
import { Button, Input, Select } from "../TrueUI";

const notificationTypes = FromEnumToArray(SiteNotificationTypeEnums).map(
  (enumType) => ({
    displayName: enumType.key,
    stringValue: enumType.value,
  })
);

const SiteNotificationTryOut: FC = () => {
  const [siteNotificationMessage, setSiteNotificationMessage] =
    useState<string>("Notification Test Message");

  const [notificationType, setNotificationType] =
    useState<SiteNotificationTypeEnums>(SiteNotificationTypeEnums.WARNING);

  const siteNotificationTest: SiteNotificationDto = {
    message: siteNotificationMessage,
    notificationType: notificationType,
    displayDate: new Date(),
    expirationDate: new Date(),
  };

  const { dispatchPost } = useApiPost(
    "api/SiteNotification/SaveSiteNotification",
    siteNotificationTest
  );

  return (
    <>
      <Input
        id="id-input-test-notification-message"
        name="name-input-test-notification-message"
        sx={{ mt: 2, mb: 2 }}
        type="text"
        label="Notification Message"
        value={siteNotificationMessage}
        onChange={(e) => setSiteNotificationMessage(e.target.value)}
      />
      <Select
        id="select-notification-type"
        name="select-notification-type"
        label="Type"
        value={notificationType}
        options={notificationTypes}
        onChange={(select) => setNotificationType(select)}
      />
      <Button onClick={() => dispatchPost()}>Try Notification</Button>
    </>
  );
};

export default SiteNotificationTryOut;
