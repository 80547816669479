import { FC, useEffect, useState } from "react";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { TableData } from "../../../TrueUI/Tables/BaseTableStructure";
import { Input } from "../../../TrueUI";

type EndorsementScheduleMultiTableChildComponentProps = {
  tabKey: string;
  insuredId: number;
  policyId: number;
  metaDataFromParent: any;
};

const EndorsementScheduleMultiTableChildComponent: FC<
  EndorsementScheduleMultiTableChildComponentProps
> = ({ tabKey, insuredId, policyId, metaDataFromParent }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const listenerChildTableComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementChildTableComponent")
  );
  const [childRows, setChildRows] = useState<string[][]>([]);
  const columnWidth = 100 / 6;
  const locationNameIndex = 0,
    invoiceAmountIndex = 1,
    endorsementIndex = 2,
    totalPaymentDueIndex = 3;

  const updateChildRowsByParentIdInAtom = () => {
    const atomValue = getAtom();
    const scheduleMultiTableChildren =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.multiTableRowChildren ?? [];
    const childTableFound = scheduleMultiTableChildren.find(
      (childTable) => childTable.id.toString() === metaDataFromParent.Id
    );

    if (conditionHasValue(childTableFound))
      setChildRows(childTableFound.rowData);
  };

  useEffect(() => {
    updateChildRowsByParentIdInAtom();
  }, []);

  useEffect(() => {
    if (listenerChildTableComponent !== null) updateChildRowsByParentIdInAtom();
  }, [listenerChildTableComponent]);

  return (
    <TableContainer className="schedule_multi_table_children">
      <Table>
        <TableBody>
          {childRows.map((row: string[], index: number) => (
            <TableRow
              key={`child-row-schedule-multi-table--${insuredId}-${policyId}-${index}`}
              sx={{ display: "flex", width: "100%" }}
            >
              <TableData width={`${columnWidth * 2}%`}></TableData>
              <TableData width={`${columnWidth}%`}>
                <Input
                  id="id-state"
                  name="name-state"
                  value={row[locationNameIndex]}
                  readOnly
                />
              </TableData>
              <TableData width={`${columnWidth}%`}>
                <Input
                  id="id-invoice-amount"
                  name="name-invoice-amount"
                  value={row[invoiceAmountIndex]}
                  align="right"
                  readOnly
                />
              </TableData>
              <TableData width={`${columnWidth}%`}>
                <Input
                  id="id-endorsement"
                  name="name-endorsement"
                  value={row[endorsementIndex]}
                  align="right"
                  readOnly
                />
              </TableData>
              <TableData width={`${columnWidth}%`}>
                <Input
                  id="id-total"
                  name="name-total"
                  value={row[totalPaymentDueIndex]}
                  align="right"
                  readOnly
                />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EndorsementScheduleMultiTableChildComponent;
