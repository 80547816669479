import { FC, useEffect, useState } from "react";
import BaseGrid from "../../../../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../../../../BaseGrid/Hooks/useBaseGrid";

import ClaimFinancialHeader from "./ClaimFinancialHeader";
import { parseFloatValueIfExist } from "../../../../../../utilities/stringFunctions";
import {
  determinateTypeOfCellOnChange,
  determinateTypeOfCellOnInit,
} from "./ReservesSubTable/ReserveSubTableUtils";
import {
  BaseGridColumnOptionsOnCellChangeProperties,
  BaseGridComputeForCellParameters,
  BaseGridConditionForCellResponse,
} from "../../../../../BaseGrid/BaseGridProperties";

import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { ReserveItemDto } from "../../../../../../dtos/reserve-item-dto";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ClaimFinancialDataAtom,
  ClaimFinancialGetAPIRequesterAtom,
  ReloadReservesSubGridAtom,
} from "./ClaimFinancialAtoms";
import { SplitButton } from "../../../../../TrueUI";
import { useApiPost } from "../../../../../../hooks";
import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import { getIntValueByStringNameFromClaimReference } from "./ClaimFinancialUtil";
import AddReserveWorksheetModal from "./ReservesSubTable/AddReserveWorksheetModal";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import ReserveGridFooter from "./ReserveGridFooter";
import AddReserveReallocateModal from "./ReservesSubTable/AddReserveReallocateModal";

const reserveGridName = "reserves_grid";

type ReservesGridProperties = {
  claimId?: number;
  tabKey?: string;
  hasClaimsManagementPermissions: boolean;
  refreshReservesFinancialTable: (update: boolean) => void;
};

type ReservesGridDataProperties =
  Partial<BaseGridColumnOptionsOnCellChangeProperties> & {
    amount?: number;
    comments?: string;
  };

type ReservesGridEditableColumns = "amount" | "comments";

export type ReservesModalsConfigProps = {
  refresh: boolean;
  isWorksheetModalOpen: boolean;
  isReallocateModalOpen: boolean;
};

export const ReservesModalsConfigDefaultValue = {
  refresh: false,
  isWorksheetModalOpen: false,
  isReallocateModalOpen: false,
};

const ReservesGrid: FC<ReservesGridProperties> = ({
  claimId,
  tabKey,
  refreshReservesFinancialTable,
  hasClaimsManagementPermissions,
}) => {
  const [reloadReservesSubGrid, setReloadReservesSubGrid] = useRecoilState(
    ReloadReservesSubGridAtom
  );

  const financialData = useRecoilValue(ClaimFinancialDataAtom);
  const [reservesData, setReservesData] = useState<Partial<ReserveItemDto>[]>(
    []
  );

  const [reservesModalsConfig, setReservesModalsConfig] =
    useState<ReservesModalsConfigProps>(ReservesModalsConfigDefaultValue);

  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps>();
  const [clearMessages, setClearMessages] = useState<boolean>(false);

  const { responsePost, dispatchPost } = useApiPost(
    `api/Reserve/SaveReserves?claimId=${claimId}`,
    reservesData.filter((reserve) => reserve.amount !== null)
  );
  const [getApiRequester, setGetApiRequester] = useRecoilState(
    ClaimFinancialGetAPIRequesterAtom
  );
  const [hasInitMounted, setHasInitMounted] = useState<boolean>(false);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    refreshReservesFinancialTable(reservesModalsConfig.refresh);
  }, [reservesModalsConfig.refresh]);

  const onAddReservesInit = (
    options: BaseGridComputeForCellParameters<any>
  ): BaseGridConditionForCellResponse | void => {
    const totalReserveRuleLimit = parseFloatValueIfExist(
      options.row?.TotalReserveRuleLimit
    );
    const totalReservesAmount = parseFloatValueIfExist(
      options.row?.TotalReservesAmount
    );

    return determinateTypeOfCellOnInit(
      totalReserveRuleLimit,
      totalReservesAmount,
      options.currentValue
    );
  };

  const onAddReservesChange = (
    options: BaseGridComputeForCellParameters<any>
  ): BaseGridConditionForCellResponse | void => {
    const reserveRuleLimit = parseFloatValueIfExist(
      options.row?.ReservesRuleLimit
    );
    const totalReserveRuleLimit = parseFloatValueIfExist(
      options.row?.TotalReserveRuleLimit
    );
    const totalReservesAmount = parseFloatValueIfExist(
      options.row?.TotalReservesAmount
    );

    return determinateTypeOfCellOnChange(
      totalReserveRuleLimit,
      totalReservesAmount,
      reserveRuleLimit,
      options.currentValue
    );
  };

  const addReserves = () => {
    // Check against possible reserves with only comments but no amount value
    const reservesWithAmounts = reservesData.filter((x) => x.amount !== 0);
    if (reservesWithAmounts.length > 0) {
      dispatchPost();
    } else {
      const dialogDescriptionText =
        "Please enter a value for at least one category.";
      setDialogConfiguration({
        dialogDescriptionText,
        onCloseEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionYesEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        optionYesOverrideLabel: "OK",
        open: true,
      });
    }
  };

  const getReserveItemStatus = (
    reserveRuleLimit: number,
    totalReserveRuleLimit: number,
    totalReservesAmount: number,
    amount: number,
    reserveStatusReference: ClaimReferenceDto[]
  ) => {
    const newTotal = totalReservesAmount + amount;

    if (reserveRuleLimit && reserveRuleLimit > 0 && amount > reserveRuleLimit) {
      return getIntValueByStringNameFromClaimReference(
        "Pending Approval",
        reserveStatusReference ?? []
      );
    }
    if (
      totalReserveRuleLimit &&
      totalReserveRuleLimit > 0 &&
      newTotal > totalReserveRuleLimit
    ) {
      return getIntValueByStringNameFromClaimReference(
        "Pending Approval",
        reserveStatusReference ?? []
      );
    }
    return getIntValueByStringNameFromClaimReference(
      "Approved",
      reserveStatusReference ?? []
    );
  };

  const [addedReserveOptions, setAddedReserveOptions] =
    useState<ReservesGridDataProperties | null>(null);

  const getAddedReserveData = (
    options: BaseGridColumnOptionsOnCellChangeProperties,
    property: ReservesGridEditableColumns
  ) => {
    if (options !== null && options?.hydratedRow?.ReserveTypeId !== -1) {
      setAddedReserveOptions({ ...options, [property]: options.value });
    }
  };

  const updateReservesPostData = () => {
    // Unfortunately this logic cannot go directly inside the "getAddedReserveAmount" function. "getAddedReserveAmount" is consumed
    // by the BaseGrid but because we reference React "useState" hooks inside this logic those states get detached from the execution
    // when consumed by the Base Grid. The next time "getAddedReserveAmount" get's called in the chain of Base Grid events, the
    // useState context is lost and only works for that given execution (think single use object working fine but not useState arrays,
    // which is what we need here.).
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // In order to make this work, we create a delegate state (in our case, the delegate state is "addedReserveAmountOptions") to
    // simply pass the part we need from "getAddedReserveAmount" to a useEffect hook outside of "getAddedReserveAmount" and manage that
    // state there. This is not ideal but sort of just part of the process.
    // - antonio
    const foundAddReserve =
      reservesData?.find(
        (r) => r.typeId === addedReserveOptions?.hydratedRow?.ReserveTypeId
      ) ?? null;

    if (foundAddReserve) {
      // UPDATE
      const updatedAddReserves = reservesData.map((r) => {
        if (r.typeId === addedReserveOptions?.hydratedRow?.ReserveTypeId) {
          // Update only the value that is being modified
          const comments =
            addedReserveOptions?.columnName === "Comments"
              ? addedReserveOptions?.comments
              : r.comments;
          const amount =
            addedReserveOptions?.columnName === "AddReserves"
              ? addedReserveOptions?.amount
              : r.amount ?? 0;
          const statusId = getReserveItemStatus(
            addedReserveOptions?.hydratedRow?.ReservesRuleLimit ?? 0,
            addedReserveOptions?.hydratedRow?.TotalReserveRuleLimit ?? 0,
            addedReserveOptions?.hydratedRow?.TotalReservesAmount ?? 0,
            foundAddReserve?.amount ?? 0,
            financialData?.quickSearchOption?.reserveStatusList ?? []
          );
          return { ...r, amount, comments, statusId };
        }
        return r;
      });
      setReservesData(updatedAddReserves);
    } else {
      // INSERT
      const updatedAddReserves = (reservesData ?? []).concat({
        typeId: addedReserveOptions?.hydratedRow?.ReserveTypeId ?? -1,
        amount: addedReserveOptions?.amount ?? 0,
        statusId: getReserveItemStatus(
          addedReserveOptions?.hydratedRow?.ReservesRuleLimit ?? 0,
          addedReserveOptions?.hydratedRow?.TotalReserveRuleLimit ?? 0,
          addedReserveOptions?.hydratedRow?.TotalReservesAmount ?? 0,
          addedReserveOptions?.amount ?? 0,
          financialData?.quickSearchOption?.reserveStatusList ?? []
        ),
        comments: addedReserveOptions?.comments,
      });
      setReservesData(updatedAddReserves);
    }
  };

  useEffect(() => {
    if (addedReserveOptions !== null) {
      updateReservesPostData();
    }
  }, [addedReserveOptions]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful && !responsePost.isLoading) {
      setComponentTriggers(["claimBanner"]);
      setClearMessages(true);

      setReservesData([]);
      setGetApiRequester({
        ...getApiRequester,
        isRequested: true,
        isCompleted: false,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (reloadReservesSubGrid && hasInitMounted) {
      manuallyReloadParameters();
      setReloadReservesSubGrid(false);
      setClearMessages(true);
    }
  }, [reloadReservesSubGrid]);

  useEffect(() => {
    if (hasInitMounted && reloadReservesSubGrid) {
      setReloadReservesSubGrid(false);
    }
  }, [hasInitMounted]);

  useEffect(() => {
    if (getApiRequester.isCompleted && hasInitMounted) {
      manuallyReloadParameters();
    }
  }, [getApiRequester.isCompleted]);

  useEffect(() => {
    if (financialData !== null && hasInitMounted === false) {
      manuallyReloadParameters();
    }
  }, [financialData]);

  const { manuallyReloadParameters } = useBaseGrid({
    name: reserveGridName,
    useManuallyReloadParameters: true,
    columnsAndData: {
      columns: financialData?.reserveSummaryTable?.tableData?.columns ?? [],
      data: financialData?.reserveSummaryTable?.tableData?.data ?? [],
    },
    tableType: "standard",
    toolbarOptions: {
      showExcelButton: true,
      showPDFButton: true,
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
    },
    columnOptions: [
      { fieldName: "ReserveType", width: 10 },
      {
        fieldName: "ReservesSum",
        width: 12,
        align: "right",
      },
      {
        fieldName: "PaidSum",
        width: 15,
        align: "right",
      },
      {
        fieldName: "Outstanding",
        width: 12,
        align: "right",
      },
      {
        fieldName: "Recoveries",
        width: 8,
        align: "right",
      },
      {
        fieldName: "AddReserves",
        // maxNumericValue: 99999999.99,
        // minNumericValue: -99999999.99,
        width: 15,
        allowNegatives: true,
        align: "right",
        isEditable: hasClaimsManagementPermissions,
        onCellChange: (options) => getAddedReserveData(options, "amount"),
        computeOnInit: (options) => onAddReservesInit(options),
        computeOnChange: (options) => onAddReservesChange(options),
      },
      {
        fieldName: "Comments",
        width: 18,
        isEditable: hasClaimsManagementPermissions,
        onCellChange: (options) => getAddedReserveData(options, "comments"),
      },
      { fieldName: "LastActivity", width: 10 },
    ],
    events: {
      onInitMount: () => {
        setHasInitMounted(true);
      },
    },
  });

  const getDisableSplitButtonItems = () => {
    const reallocateOptionIndex = 2;
    return hasClaimsManagementPermissions === true
      ? []
      : [reallocateOptionIndex];
  };

  return (
    <>
      <div style={{ height: "50%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        >
          <div style={{ flexGrow: "1" }}>
            <ClaimFinancialHeader title="RESERVES" />
          </div>
        </div>

        <div style={{ flexGrow: "1" }}>
          <BaseGrid name={reserveGridName} />
          <ReserveGridFooter
            reservesData={reservesData}
            tableData={
              financialData?.reserveSummaryTable?.tableData?.data ?? []
            }
            tableColumns={
              financialData?.reserveSummaryTable?.tableData?.columns ?? []
            }
            clearMessages={clearMessages}
            setClearMessages={setClearMessages}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5px",
            alignItems: "flex-end",
          }}
        >
          {/* <Button onClick={addReserves} permissions={[2, 13, 14, 15]}>
            ADD RESERVE
          </Button> */}
          <SplitButton
            disabled={!hasClaimsManagementPermissions}
            items={[
              {
                option: "ADD RESERVE",
                action: addReserves,
              },
              {
                option: "WORKSHEET",
                action: () =>
                  setReservesModalsConfig({
                    ...reservesModalsConfig,
                    refresh: false,
                    isWorksheetModalOpen: true,
                  }),
              },
              {
                option: "REALLOCATE",
                action: () =>
                  setReservesModalsConfig({
                    ...reservesModalsConfig,
                    refresh: false,
                    isReallocateModalOpen: true,
                  }),
              },
            ]}
            disabledItems={getDisableSplitButtonItems()}
            triggerEventOnSelect
          />
        </div>
        <DialogConfirmation
          name="policy-quote-dialog"
          id="policy-quote-dialog-confirmation"
          {...dialogConfiguration}
        />
        <AddReserveWorksheetModal
          claimId={claimId}
          reservesModalsConfig={reservesModalsConfig}
          setReservesModalsConfig={setReservesModalsConfig}
          tabKey={tabKey}
        />
        <AddReserveReallocateModal
          claimId={claimId}
          reservesModalsConfig={reservesModalsConfig}
          setReservesModalsConfig={setReservesModalsConfig}
          tabKey={tabKey}
        />
      </div>
    </>
  );
};

export default ReservesGrid;
