import { FC, useEffect, useState } from "react";
import { StateReportPage } from "../../../../../../dtos/state-report-page";
import { getValue, IncidentFieldSelector } from "./IncidentFieldSelector";
import { IncidentStateReportFieldDto } from "../../../../../../dtos/incident-state-report-field-dto";
import { Box } from "@mui/material";
import { FontsType } from "../../../../../../media/themeTypes";
import { KeysAsType } from "../../../../../../types/KeysAsAType";
import {
  claimIncidentStateProps,
  updateMultipleValues,
} from "../AddNewIncidentUtils";
import { toCamelCase } from "../../../../../../utilities/stringFunctions";
import { getStates } from "../../../../../../utilities/staticDataFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

type IncidentSectionProps = {
  sectionId: number;
  stateReport?: StateReportPage;
  claimIncident?: claimIncidentStateProps;
  setClaimIncident?: any;
  textFieldVariant?: "filled" | "standard";
  labelsType?: KeysAsType<FontsType>;
  errorDetails?: any;
  readOnly?: boolean;
};

const IncidentSection: FC<IncidentSectionProps> = ({
  sectionId,
  stateReport,
  claimIncident,
  setClaimIncident,
  textFieldVariant,
  labelsType,
  errorDetails,
  readOnly,
}) => {
  const [fields, setFields] = useState<IncidentStateReportFieldDto[]>();

  const getValueFromOption = (option) => {
    return (
      (option.intValue ||
        option.stringValue ||
        option.decimalValue ||
        option.dateValue ||
        option.booleanValue) ??
      null
    );
  };

  const getFieldNamesAndValuesOfSelectFields = () => {
    const selectFields = fields?.filter(
      (field) =>
        (field?.fieldType === "state" || field?.fieldType === "dropdown") &&
        field?.isRequired
    );

    const states: Partial<SelectOptions>[] = getStates().map((state) => ({
      displayName: state.abbreviation,
      stringValue: state.abbreviation,
    }));

    const fieldNames = selectFields?.map((field) => field?.fieldName) ?? [];
    const fieldValues =
      selectFields?.map((field) => {
        const isFieldState = field?.fieldType === "state";
        const value = getValue(
          toCamelCase(field.fieldName),
          claimIncident?.claimIncident
        );
        const firstOption = isFieldState
          ? states[0]
          : (stateReport?.stateReportFieldOptions?.[field.fieldName] ??
              [])?.[0];
        return value != "" ? value : getValueFromOption(firstOption);
      }) ?? [];

    return { fieldNames, fieldValues };
  };

  useEffect(() => {
    const { fieldNames, fieldValues } = getFieldNamesAndValuesOfSelectFields();
    updateMultipleValues(
      fieldNames,
      fieldValues,
      setClaimIncident,
      claimIncident
    );
  }, [fields]);

  useEffect(() => {
    setFields(
      stateReport?.stateReportFields.filter(
        (field) => field.sectionId === sectionId
      )
    );
    setClaimIncident({
      ...claimIncident,
      claimIncident: {
        ...claimIncident?.claimIncident,
        reportSectionId: sectionId,
      },
    });
  }, []);

  return (
    <>
      {fields?.map((field, idx) => (
        <Box
          sx={{ textAlign: "left", mt: 2 }}
          key={`${field.fieldName}-injury-row-${idx}`}
        >
          {conditionHasValue(
            claimIncident?.claimIncident?.incident?.incidentID
          ) && (
            <IncidentFieldSelector
              readOnly={readOnly}
              idx={idx}
              field={field}
              section={`incident${field.fieldName}`}
              errorDetails={errorDetails}
              claimIncident={claimIncident}
              setClaimIncident={setClaimIncident}
              textFieldVariant={textFieldVariant}
              labelsType={labelsType}
              stateReportOptions={stateReport?.stateReportFieldOptions}
            />
          )}
        </Box>
      ))}
    </>
  );
};

export default IncidentSection;
