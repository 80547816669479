import { FC, useEffect, useState } from "react";
import { Col, FontBold, Row, Switch } from "../../../../../TrueUI";
import {
  ClaimCorrespondenceFormsProps,
  SelectedClaimFormProps,
} from "./typesAndConstants";
import { ProgramClaimFormDto } from "../../../../../../dtos/program-claim-form-dto";
import FormCheckbox from "./FormCheckBox";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";

import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  spreadSelectedForms,
  spreadSwitchJurisdiction,
} from "../spreadClaimTabsFunctions";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import style from "./ClaimCorrespondence.module.css";

const ClaimCorrespondenceForms: FC<ClaimCorrespondenceFormsProps> = ({
  claimJurisdiction,
  forms,
  categories,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const [jurisdictionChecked, setJurisdictionChecked] =
    useState<boolean>(false);
  const [_forms, setForms] = useState<
    ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[]
  >([]);
  const [checkedFormIds, setCheckedFormIds] = useState<
    SelectedClaimFormProps[]
  >([]);
  const [correspondenceContainerHeight, setCorrespondenceContainerHeight] =
    useState<number>();

  const getActiveForms = () => {
    const activeCategoriesValues = categories?.map(
      (category) => category.value1
    );
    const activeFormsByActiveCategories = forms.filter((form) =>
      activeCategoriesValues?.includes(form?.formCategory ?? "")
    );

    return activeFormsByActiveCategories;
  };

  const getContainerHeight = () =>
    (document?.getElementsByClassName?.("correspondence-columns")?.[0]
      ?.clientHeight ?? 650) * 0.9;

  const setNewContainerHeight = () => {
    const containerHeight = getContainerHeight();
    if (containerHeight !== correspondenceContainerHeight) {
      setCorrespondenceContainerHeight(containerHeight);
    }
  };

  useEffect(() => {
    const atomValue = getAtom();
    const atomCheckedForms =
      atomValue?.claimTabsInformation?.claimCorrespondenceTab
        ?.selectedClaimForms ?? [];
    setCheckedFormIds(atomCheckedForms);
    setJurisdictionChecked(
      atomValue?.claimTabsInformation?.claimCorrespondenceTab
        ?.switchJurisdiction ?? false
    );
    setNewContainerHeight();
    window.addEventListener("resize", setNewContainerHeight);
    return () => {
      window.removeEventListener("resize", setNewContainerHeight);
    };
  }, []);

  const setFormsByJurisdiction = (forms) => {
    if (jurisdictionChecked === true) {
      setForms(forms.filter((form) => form?.state === claimJurisdiction));
    } else {
      setForms(forms);
    }
  };

  useEffect(() => {
    const activeForms = getActiveForms();
    setFormsByJurisdiction(activeForms);
  }, [categories]);

  useEffect(() => {
    const atomValue = getAtom();
    setCheckedFormIds(
      atomValue?.claimTabsInformation?.claimCorrespondenceTab
        ?.selectedClaimForms ?? []
    );
    setFormsByJurisdiction(forms);
  }, [forms]);

  const formSelected = (form: Partial<ProgramClaimFormDto>, selected) => {
    const checkedForms =
      selected === true
        ? [
            ...checkedFormIds,
            {
              claimFormId: form?.claimFormId ?? -1,
              formName: form.formName ?? "",
              templateName: form.fileName ?? "",
              sortOrder: form.sortOrder ?? -1,
            },
          ]
        : checkedFormIds.filter(
            (checkedFormId) => checkedFormId.claimFormId !== form?.claimFormId
          );

    const sortedCheckedForms = checkedForms.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });

    setCheckedFormIds(sortedCheckedForms ?? []);
    const atomValue = getAtom();
    setAtom(spreadSelectedForms(atomValue, sortedCheckedForms));
    setComponentTriggers(["claimCorrespondenceComponent"]);
  };

  const jurisdictionSwitchChange = (jurisdictionChecked) => {
    const atomValue = getAtom();
    setAtom(spreadSwitchJurisdiction(atomValue, jurisdictionChecked));
    const activeForms = getActiveForms();
    if (jurisdictionChecked === true) {
      setForms(activeForms.filter((form) => form?.state === claimJurisdiction));
    } else {
      setForms(activeForms);
    }
    setJurisdictionChecked(jurisdictionChecked);
  };

  return (
    <Row
      id="claimCorrespondenceContainer"
      {...rowWithNoMarginNorGutter}
      horizontalAlign="flex-start"
      verticalAlign={"flex-start"}
      rowDirection={"column"}
    >
      <Col
        {...colWithNoMarginNorGutter}
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
      >
        <Row
          {...rowWithNoMarginNorGutter}
          verticalAlign={"flex-start"}
          rowWidth="100%"
        >
          <Col
            horizontalAlign={"flex-start"}
            verticalAlignSelf={"center"}
            verticalGutter="0px"
            horizontalGutter="0px"
          >
            <FontBold name="correspondenceDocumentsTitle">
              SELECT DOCUMENTS
            </FontBold>
          </Col>
          <Col
            horizontalAlign={"flex-end"}
            verticalGutter={"0px"}
            horizontalGutter={"0px"}
            colHeight="20px"
            verticalAlign="center"
          >
            <Switch
              label={"Jurisdiction Only:"}
              labelPlacement={"start"}
              control={"switch"}
              isChecked={jurisdictionChecked}
              onChangeIsChecked={jurisdictionSwitchChange}
              labelFontType={"BODY"}
              name="correspondenceJurisdictionSwitch"
            />
          </Col>
        </Row>
      </Col>
      <Col verticalMargin={"10px"} verticalGutter={"0px"}></Col>
      <Col
        {...colWithNoMarginNorGutter}
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
      >
        <Row
          {...rowWithNoMarginNorGutter}
          className={style.checkbox_documents_row_container}
          rowHeight={`${correspondenceContainerHeight}px`}
        >
          <Col
            verticalGutter={"0px"}
            horizontalGutter={"15px"}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            displayFlex={false}
          >
            {_forms?.length > 0 &&
              _forms.map((form) => (
                <FormCheckbox
                  key={`form-checkbox-${form.claimFormId}`}
                  form={form}
                  formSelected={formSelected}
                  checkedFormIds={checkedFormIds}
                />
              ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClaimCorrespondenceForms;
