import { FC, useEffect, useState } from "react";
import {
  tableName,
  getTableConfig,
  ModalConfig,
} from "./ReservesApprovalConstants";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toUpperCaseKeys } from "../../../../../utilities/objectFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { FormattingDate } from "../../../../../utilities/dateFunctions";
import ModalClaimFinancialReserves, {
  UpdatedReserve,
} from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/ModalClaimFinancialReserves";
import { ReserveConfigurationDto } from "../../../../../dtos/reserve-configuration-dto";
import { BaseTable2 } from "../../../../TrueUI";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../TrueUI/Tables/tableFunctions";
import { useRecoilState } from "recoil";
import { getNumberFromCurrencyFormat } from "../../../../../utilities/stringFunctions";
import { paymentsApprovalAtoms } from "../Payments/PaymentsApprovalAtoms";

type ReservesApprovalTableProps = {
  getUrl: string;
  configurationOptions: ReserveConfigurationDto;
  modalProps: ModalConfig | Partial<ModalConfig>;
  setModalProps: (modalProps: ModalConfig) => void;
  selectedRowData: any;
  setSelectedRowData: (rowData: any) => void;
};

const ReservesApprovalTable: FC<ReservesApprovalTableProps> = ({
  getUrl,
  configurationOptions,
  modalProps,
  setModalProps,
  selectedRowData,
  setSelectedRowData,
}) => {
  const [, setTotalReserves] = useRecoilState(paymentsApprovalAtoms);

  const PaymentEditModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setSelectedRowData({
            rowKey: rowData?._row_key,
            hydratedData: rowData,
          });

          setModalProps({
            reserveId: rowData.ReserveId,
            isReserveModalOpen: true,
            claimId: rowData.ClaimId,
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const saveRowToTable = (
    action: "update" | "delete",
    newRowData?: UpdatedReserve
  ) => {
    if (newRowData === null || action === null) return;

    switch (action) {
      case "update":
        if (
          newRowData?.ReserveStatusId !==
          parseInt(selectedRowData?.hydratedData?.ReserveStatusId)
        ) {
          deleteRow();
        } else {
          updateRow(newRowData);
        }

        break;
      case "delete":
        deleteRow();
        break;
      default:
        break;
    }
  };

  const formatRowDataToUpdate = (rowData) => {
    if (conditionHasValue(rowData?.Amount)) {
      const amount = rowData?.Amount.replace("$", "");

      rowData.PaidAmount = amount;
    }

    if (conditionHasValue(rowData?.ReserveDate)) {
      rowData.ReserveDate = FormattingDate(rowData?.ReserveDate);
    }

    const tableColumns = [
      "ClaimId",
      "ReserveId",
      "ProgramCode",
      "ClaimNumber",
      "ReserveDate",
      "ReserveType",
      "Comments",
      "PaidAmount",
      "CreateBy",
    ];
    const columnsToAdd = {};
    Object.keys(rowData).forEach((key) => {
      if (tableColumns.includes(key)) {
        columnsToAdd[key] = rowData[key];
      }
    });
    return columnsToAdd;
  };

  const updateRow = (rowData) => {
    const updatedRow = {
      rowKey: selectedRowData.rowKey,
      hydratedData: {
        ...selectedRowData.hydratedData,
        ...toUpperCaseKeys(formatRowDataToUpdate(rowData)),
      },
    };
    baseTableMethods?.updateRow(updatedRow);
    setSelectedRowData(null);
    setTableConfig({
      ...tableConfig,
      refreshTable: !tableConfig.refreshTable,
    });
  };

  const deleteRow = () => {
    baseTableMethods?.deleteRow({
      rowKey: selectedRowData?.rowKey,
      hydratedData: {
        ...selectedRowData.hydratedData,
        ...toUpperCaseKeys(selectedRowData.hydratedData),
      },
      deleteType: "hidden",
    });
    setTableConfig({
      ...tableConfig,
      refreshTable: !tableConfig.refreshTable,
    });
  };

  const updateTotalReserves = (filteredData: string[][], columns: any[]) => {
    const reservesIndex = getDataIndexByColumnNameWithoutExternal(
      columns,
      "PaidAmount"
    );

    const reservesTotal = filteredData.reduce(
      (acc, cur) => acc + getNumberFromCurrencyFormat(cur[reservesIndex]),
      0
    );

    setTotalReserves(reservesTotal);
  };

  const resetGetURL = (getURL) => {
    setTableConfig({ ...tableConfig, getURL });
  };
  const [tableConfig, setTableConfig] = useState<BaseTable2Properties>(
    getTableConfig(
      configurationOptions,
      getUrl,
      PaymentEditModalLink,
      updateTotalReserves,
      resetGetURL
    )
  );

  const { tableMethods } = useBaseTable(tableConfig ?? []);
  const baseTableMethods = tableMethods[tableName]?.methods;

  useEffect(() => {
    if (tableConfig.getURL !== getUrl)
      setTableConfig({ ...tableConfig, getURL: getUrl });
  }, [tableConfig]);

  useEffect(() => {
    return () => {
      setTotalReserves(0);
    };
  }, []);

  return (
    <>
      <BaseTable2 name={tableName} />;
      <ModalClaimFinancialReserves
        reserveId={modalProps?.reserveId ?? 0}
        claimId={modalProps?.claimId ?? 0}
        isReserveModalOpen={modalProps?.isReserveModalOpen ?? false}
        setReserveModalOpen={(value) => {
          setModalProps({
            reserveId: 0,
            isReserveModalOpen: value,
            claimId: 0,
          });
        }}
        setUpdatedReserve={saveRowToTable}
        userReserveLimit={configurationOptions.userReserveLimit}
        checkLimit
      />
    </>
  );
};

export default ReservesApprovalTable;
