import { FC } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import BaseGridReadOnlyInput from "../../BaseGrid/BaseGridCommonFilters/BaseGridReadOnlyInput";
import { SelectedInsuredCommission } from "./CommissionsAtoms";

const gridName = "agency_commission_detail_table";

type CommissionDetailsProps = {
  insuredCommission: SelectedInsuredCommission;
};

const CommissionDetails: FC<CommissionDetailsProps> = ({
  insuredCommission,
}) => {
  const tableConfiguration: BaseGridProperties = {
    name: gridName,
    getURL: `api/InsuredCommissionTran/GetInsuredCommissionTranPage?agencyCommissionTranId=${insuredCommission.tranId}`,
    columnOptions: [
      { fieldName: "InsuredName", width: 24 },
      { fieldName: "PolicyNumber", width: 15 },
      { fieldName: "CommissionableAmount", width: 12, align: "right" },
      { fieldName: "NonCommissionable", width: 12, align: "right" },
      { fieldName: "CommissionRate", width: 8, align: "right" },
      { fieldName: "CommissionAmount", width: 9, align: "right" },
      { fieldName: "PreviousPaid", width: 10, align: "right" },
      { fieldName: "PaidThisPeriod", width: 10, align: "right" },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showSaveButton: false,
    },
    filterOptions: [
      () =>
        BaseGridReadOnlyInput({
          id: "agency-commissions-details-header-program",
          defaultValue: insuredCommission.program,
          labelText: "Program:",
          labelFontType: "TITLE",
        }),
      () =>
        BaseGridReadOnlyInput({
          id: "agency-commissions-details-header-date",
          defaultValue: insuredCommission.date,
          labelText: "Date:",
          labelFontType: "TITLE",
        }),
      () =>
        BaseGridReadOnlyInput({
          id: "agency-commissions-details-header-amount",
          defaultValue: insuredCommission.amount,
          labelText: "Amount:",
          labelFontType: "TITLE",
        }),
    ],
  };

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ margin: "15px", maxHeight: "-webkit-fill-available" }}>
      <BaseGrid name={gridName} />
    </div>
  );
};

export default CommissionDetails;
