import { FC } from "react";
import { downloadBlobObject } from "../../../utilities/blobFunctions";
import Button from "./Button";

type ExportJSONButtonProps = {
  fileName: string;
  size?: "small" | "medium" | "large";
  name: string;
  data?: string;
  variantStyle?: "contained" | "outlined" | "text";
  getDataOnClick?: () => string;
};

const containerId = "download_json_file";

const ExportJSONButton: FC<ExportJSONButtonProps> = ({
  fileName = "jsonFile",
  size = "medium",
  name,
  data,
  variantStyle,
  getDataOnClick,
}) => {
  const downloadJSON = () => {
    const dataForDownload = getDataOnClick ? getDataOnClick() : data ?? "";
    downloadBlobObject(dataForDownload, fileName, containerId);
  };

  return (
    <>
      <div
        style={{ visibility: "hidden" }}
        id={containerId}
        true-element={`true-to-json-button-${name}`}
      ></div>
      <Button
        onClick={downloadJSON}
        size={size}
        variantStyle={variantStyle ?? "outlined"}
        name={name}
      >
        Export JSON
      </Button>
    </>
  );
};

export default ExportJSONButton;
