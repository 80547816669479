import { UploadFileInfo } from "@progress/kendo-react-upload";

export type allowedExt =
  | "aac"
  | "asf"
  | "cda"
  | "mid"
  | "midi"
  | "wav"
  | "wma"
  | "mp3"
  | "accdb"
  | "mda"
  | "mdb"
  | "mde"
  | "one"
  | "pub"
  | "vsd"
  | "vst"
  | "asx"
  | "avi"
  | "mov"
  | "mp2"
  | "mpa"
  | "mpe"
  | "mpeg"
  | "mpg"
  | "wmv"
  | "bmp"
  | "dib"
  | "eps"
  | "gif"
  | "jfif"
  | "jpe"
  | "png"
  | "psd"
  | "tif"
  | "tiff"
  | "jpeg"
  | "jpg"
  | "cab"
  | "tar"
  | "zip"
  | "csv"
  | "log"
  | "txt"
  | "docx"
  | "dot"
  | "dotx"
  | "doc"
  | "edn"
  | "fdp"
  | "pdf"
  | "eml"
  | "msg"
  | "htm"
  | "html"
  | "tml"
  | "xml"
  | "pot"
  | "potx"
  | "pps"
  | "pptx"
  | "ppt"
  | "xlt"
  | "xltx"
  | "xlsx"
  | "xls"
  | "rtf";

export const GetFileSizeWithUnits = (fileSizeInBytes: number) => {
  const KB_FACTOR = 1024;
  const MB_FACTOR = 1024 * 1024;
  const GB_FACTOR = 1024 * 1024 * 1024;
  if (fileSizeInBytes >= GB_FACTOR) {
    return (fileSizeInBytes / GB_FACTOR).toFixed(2) + " GB";
  } else if (fileSizeInBytes >= MB_FACTOR) {
    return (fileSizeInBytes / MB_FACTOR).toFixed(2) + " MB";
  } else {
    return (fileSizeInBytes / KB_FACTOR).toFixed(2) + " KB";
  }
};

export const filterEmptyFiles = (
  addedFiles: UploadFileInfo[]
): UploadFileInfo[] => {
  return addedFiles.filter((file) => {
    const fileSize = file.size ?? 0;
    if (fileSize <= 0) {
      return false;
    }
    if (fileSize < 10) {
      file.size = 10;
    }
    return true;
  });
};
