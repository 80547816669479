import { ClassCodeDataDto } from "../../../../../dtos/class-code-data-dto";

export type ModalAddClassCodeProps = {
  insuredId: number;
  payrollReportId: number;
  openModalAddClassCode: boolean;
  setOpenModalAddClassCode?: (value: boolean) => void;
  setUUID: (value: string) => void;
};

export type ConfirmationDialogProps = {
  isOpen: boolean;
  titleText: string;
  descriptionText: string;
  dialogId: string;
  dialogName: string;
  yesOptionText: string;
  onOptionNoEvent?: (close: boolean) => any;
};

export type ModalAddClassCodeContentProps = {
  payrollReportId: number;
  newClassCodesData: ClassCodeDataDto;
  setNewClassCodeData: (value: ClassCodeDataDto) => void;
  isShowDialogEmptyTable: (value: boolean) => void;
};

export const defaultDialogConfiguration = {
  isOpen: false,
  titleText: "Information",
  descriptionText: "",
  dialogId: "class-code-dialog-confirmation",
  dialogName: "classCodeDialog",
  yesOptionText: "Ok",
};
