import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import { BasePageContainer, Font } from "../../TrueUI";
import { useApiGet } from "../../../hooks";
import {
  DBTablesIcon,
  AdminFileIcon,
  BillIcon,
  LockIcon,
  GearsIcon,
} from "../../TrueUI/Icons/CustomIcons";
import { ChevronRight } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import style from "./adminToolsMain.module.css";

const adminToolsIcons = [
  <DBTablesIcon />,
  <AdminFileIcon />,
  <BillIcon />,
  <LockIcon />,
  <GearsIcon />,
];

type AdminTool = {
  name: string;
  description: string;
  section: string;
  isDisabled: boolean;
};

const AdminToolsMain: FC<any> = () => {
  const { responseGet, dispatchGet } = useApiGet<AdminTool[]>(
    `api/admintools/GetAdminToolsItemsList`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <div className={style.admin_tools_page_container}>
      <BasePageContainer
        id="admin-tools-main"
        title={
          <div className={style.main_title_container}>
            <Font fontType="HEADING_4" className={style.main_title}>
              Admin Tools
            </Font>
          </div>
        }
        titlePosition="center"
        maxWidth="lg"
        disableBodyPaper={true}
        data={responseGet.responseData}
      >
        <Box className={style.admin_tools_list_container}>
          {!!responseGet.responseData &&
            responseGet.responseData.map((tool, index: number) => {
              return (
                <NavLink
                  to={tool.isDisabled ? "" : tool.section}
                  className={style.tool_link}
                  key={index}
                >
                  <div className={style.tool_content}>
                    <div>{adminToolsIcons[index]}</div>
                    <div className={style.tool_info}>
                      <Font fontType="TITLE">{tool.name}</Font>
                      <Font fontType="BODY">{tool.description}</Font>
                    </div>
                  </div>
                  <ChevronRight />
                </NavLink>
              );
            })}
        </Box>
      </BasePageContainer>
    </div>
  );
};

export default AdminToolsMain;
