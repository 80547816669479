export function descendingComparator(a, b) {
  if (a === null) return 1;
  if (b === null) return -1;
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
}

export const getComparator = (order: any) => {
  return order === "newest"
    ? (a, b) => descendingComparator(a.createdOn, b.createdOn)
    : (a, b) => -descendingComparator(a.createdOn, b.createdOn);
};
