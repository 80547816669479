import { FC } from "react";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import InputDate from "../../Inputs/InputDate";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

const DatePickerCell2: FC<BaseTableCellSelectProperties> = ({
  cellKey,
  rowKey,
  isEdit,
  column,
  value,
  onChange,
  variant,
  errorMessage,
  align,
  // rowIndex,
}) => {
  const newValue = new Date(value);
  newValue.setFullYear(value?.split?.("/")?.[2] ?? "0000");
  return (
    <InputDate
      id={`id-${column.fieldName}-${cellKey}`}
      name={`name-${column.fieldName}-${cellKey}`}
      key={cellKey}
      readOnly={!isEdit}
      onChangeRawValue={(value) => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: FormattingDate(value),
          // cellIds: [],
        });
      }}
      value={value != "" ? newValue : null}
      variant={variant}
      errorMessage={errorMessage}
      align={align}
      isInputIdent={false}
    />
  );
};

export default DatePickerCell2;
