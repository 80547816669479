import { FC, useEffect } from "react";
import { ReadOnlyReserveWorksheetPage } from "../../../../../../../dtos/read-only-reserve-worksheet-page";
import { useApiGet } from "../../../../../../../hooks";
import { Input, Modal } from "../../../../../../TrueUI";
import { Stack } from "@mui/material";
import AddReserveWorksheetTable from "./AddReserveWorksheetTable";

type ViewReserveWorksheetModalProps = {
  worksheetId?: number | null;
  claimId?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const ViewReserveWorksheetModal: FC<ViewReserveWorksheetModalProps> = ({
  worksheetId,
  claimId,
  isOpen,
  setIsOpen,
}) => {
  const { responseGet, dispatchGet } = useApiGet<ReadOnlyReserveWorksheetPage>(
    `api/ReserveWorksheet/GetReadOnlyWorksheet?claimId=${claimId}&worksheetId=${worksheetId}`
  );

  useEffect(() => {
    if (isOpen) dispatchGet();
  }, [isOpen]);

  return (
    <Modal
      id="view-reserve-worksheet-modal"
      title="View Reserve Worksheet"
      open={isOpen}
      closeEvent={(value) => setIsOpen(value)}
      cancelEvent={() => setIsOpen(false)}
      showCloseButton
    >
      <Stack>
        <div
          style={{
            padding: "0 5px 10px 5px",
            display: "flex",
          }}
        >
          <Input
            id="view-reserve_worksheet_comments"
            name="view-reserve_worksheet_comments"
            label="Comments"
            multiline
            rows={3}
            readOnly
            variant="standard"
            inputWidth="100%"
            value={responseGet.responseData?.comments}
          />
        </div>
        {responseGet.responseData?.reserveTypeTableList?.map((reserveTable) => (
          <AddReserveWorksheetTable
            key={`reserve_table_${reserveTable.reserveTypeId}`}
            tableInfo={reserveTable}
            editMode={false}
          />
        ))}
      </Stack>
    </Modal>
  );
};

export default ViewReserveWorksheetModal;
