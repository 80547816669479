import { FC, useEffect, useState } from "react";
import { ContextEnums } from "../../../../dtos/context-enums";
import { FilesTabConfigurationDto } from "../../../../dtos/files-tab-configuration-dto";
import { useApiGet } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Loading } from "../../../TrueUI";
import { ContextProps } from "../InsuredDrawerConstants";
import ClaimFiles from "./ClaimFiles";
import InsuredFiles from "./InsuredFiles";

type FilesComponentProps = {
  context: ContextProps;
};

const FilesWrapper: FC<FilesComponentProps> = ({ context }) => {
  const [configurationState, setConfigurationState] =
    useState<FilesTabConfigurationDto>();
  const { responseGet, dispatchGet } = useApiGet<FilesTabConfigurationDto>(
    `api/InsuredFile/GetConfigurationForFilesTab?insuredId=${context.insuredId}&context=${context.type}`
  );
  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      responseGet?.requestInstanceSuccessful &&
      conditionHasValue(responseGet?.axiosResponse?.data)
    ) {
      setConfigurationState(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  const componentByContext = () => {
    switch (context.type) {
      case ContextEnums.CLAIMS:
        return (
          <ClaimFiles
            context={context}
            filesConfiguration={configurationState}
          />
        );
      default:
        return (
          <InsuredFiles
            context={context}
            filesConfiguration={configurationState}
          />
        );
    }
  };

  return (
    <>
      {responseGet?.axiosResponse?.data ? (
        componentByContext()
      ) : (
        <Loading isLoading />
      )}
    </>
  );
};

export default FilesWrapper;
