import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../../dtos/select-options";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { useApiGet } from "../../../../../hooks";
import InsuredPayments from "./InsuredPayments";

type InsuredPaymentsContainerProps = {
  insuredId: number;
  hasAch?: boolean;
  hasCreditCard?: boolean;
  tabKey: string;
};
const InsuredPaymentsContainer: FC<InsuredPaymentsContainerProps> = ({
  insuredId,
  hasAch = false,
  hasCreditCard = false,
  tabKey,
}) => {
  const [policies, setPolicies] = useState<
    SelectOptions[] | Partial<SelectOptions>[]
  >([{ displayName: "Current Activity", stringValue: "current" }]);

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Policy/GetPoliciesByInsured?insuredId=${insuredId}`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPolicies([
        { displayName: "Current Activity", stringValue: "current" },
        ...(responseGet.axiosResponse?.data ?? []),
      ]);
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <InsuredPayments
      insuredId={insuredId}
      hasAch={hasAch}
      hasCreditCard={hasCreditCard}
      policies={policies}
      tabKey={tabKey}
    />
  );
};

export default InsuredPaymentsContainer;
