import { FC, useEffect, useState } from "react";
import BillingOptionsTable from "./BillingOptionsTable";
import {
  BillingOptionsTabContentProps,
  ModalBillingOptionsConfigProps,
} from "./TypesBillingOptions";
import { modalBillingOptionsInitConfiguration } from "./BillingOptionsConstants";
import ModalBillingOption from "./ModalBillingOptions/ModalBillingOption";

const BillingOptionsTabContent: FC<BillingOptionsTabContentProps> = ({
  insuredId,
  tabKey,
}) => {
  const [modalBillingOptionsConfig, setModalBillingOptionsConfig] =
    useState<ModalBillingOptionsConfigProps>(
      modalBillingOptionsInitConfiguration
    );
  

  useEffect(() => {
    if (modalBillingOptionsConfig?.isModalOpen === false) {
      setModalBillingOptionsConfig(modalBillingOptionsInitConfiguration);
    }
  }, [modalBillingOptionsConfig?.isModalOpen]);

  useEffect(() => {
    if (modalBillingOptionsConfig?.refreshTable === true) {
      setModalBillingOptionsConfig({
        ...modalBillingOptionsConfig,
        uuid: crypto.randomUUID(),
      });
    }
  }, [modalBillingOptionsConfig?.refreshTable]);

  return (
    <>
      <BillingOptionsTable
        insuredId={insuredId}
        tabKey={tabKey}
        modalBillingOptionsConfig={modalBillingOptionsConfig}
        setModalBillingOptionsConfig={setModalBillingOptionsConfig}
      />
      <ModalBillingOption
        insuredId={insuredId}
        tabKey={tabKey}
        modalBillingOptionsConfig={modalBillingOptionsConfig}
        setModalBillingOptionsConfig={setModalBillingOptionsConfig}
      />
    </>
  );
};

export default BillingOptionsTabContent;
