import { FC, useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { AgencyCommissionDetailPage } from "../../../dtos/agency-commission-detail-page";
import BaseGridReadOnlyInput from "../../BaseGrid/BaseGridCommonFilters/BaseGridReadOnlyInput";
import { useApiGet } from "../../../hooks";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";

type CommissionDetailsGridProps = {
  tranId: number | null;
};
const CommissionDetailsGrid: FC<CommissionDetailsGridProps> = ({ tranId }) => {
  const [insuredCommissions, setInsuredCommissions] =
    useState<AgencyCommissionDetailPage | null>(null);

  const { responseGet, dispatchGet } = useApiGet<AgencyCommissionDetailPage>(
    `api/AgencyCommissionTran/GetCommissionDetails?tranId=${tranId}`
  );

  const name = "commission_details_grid";

  const tableConfiguration: BaseGridProperties = {
    columnsAndData: insuredCommissions?.tableData,
    name,
    columnOptions: [
      { fieldName: "InsuredName", width: 20 },
      { fieldName: "PolicyNumber", width: 15 },
      { fieldName: "CommissionableAmount", width: 15, align: "right" },
      { fieldName: "NonCommissionableAmount", width: 15, align: "right" },
      { fieldName: "CommissionRate", width: 5, align: "right" },
      { fieldName: "CommissionAmount", width: 10, align: "right" },
      { fieldName: "PreviousPaid", width: 10, align: "right" },
      { fieldName: "PaidThisPeriod", width: 10, align: "right" },
    ],
    toolbarOptions: {
      showAddButton: insuredCommissions?.showPayButton ?? false,
      addButtonText: "PAY",
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      () =>
        BaseGridReadOnlyInput({
          id: "commission-tran-status",
          labelText: "Status:",
          defaultValue: insuredCommissions?.status,
          inputWidth: "400px",
          labelFontType: "BODY",
          inputFontType: "BODY",
        }),
      () =>
        BaseGridReadOnlyInput({
          id: "commission-tran-date",
          labelText: "Date:",
          defaultValue: insuredCommissions?.tranDate,
          inputWidth: "400px",
          labelFontType: "BODY",
          inputFontType: "BODY",
        }),
      () =>
        BaseGridReadOnlyInput({
          id: "commission-tran-amount",
          labelText: "Amount:",
          defaultValue: insuredCommissions?.tranAmount,
          inputWidth: "400px",
          labelFontType: "BODY",
          inputFontType: "BODY",
        }),
    ],
    events: { addEventOverrideCallback: () => alert("soon :)") },
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setInsuredCommissions(responseGet?.responseData ?? null);
  }, [responseGet]);

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ overflowY: "auto", height: "100%", width: "100%" }}>
      <BaseGrid name={name} />
    </div>
  );
};

export default CommissionDetailsGrid;
