import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DiaryConfigurationDto } from "../../../dtos/diary-configuration-dto";
import { DiaryNoteDto } from "../../../dtos/diary-note-dto";
import { usePermissions } from "../../../hooks";
import { ContextProps } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";
import ActionNotes, {
  ModalsActionsProps,
} from "../../Insured/InsuredDrawer/Notes/ActionNotes";
import Title from "../Typography/Title";
import Note from "./Note";
import { ContextEnums } from "../../../dtos/context-enums";
import { StatusEnums } from "../../../dtos/status-enums";
import "./conversation_wrapper.module.css";
import { getQueryValue } from "../../../utilities/URLUtilities_OBSOLETE";
import { DRAWER_NOTE_ID } from "../../../utilities/queryStringsHash";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type NotesProps = {
  id?: string;
  expanded: boolean;
  notes: DiaryNoteDto[];
  context: ContextProps;
  diaryConfiguration: DiaryConfigurationDto;
  setRefreshConversation: (refresh: boolean) => void;
};

const permissionsClaims = [1, 2, 13, 14, 15];
const permissionsInsured = [2];

const ConversationWrapper: FC<NotesProps> = ({
  id,
  notes,
  context,
  expanded,
  diaryConfiguration,
  setRefreshConversation,
}) => {
  const [modalsProps, setModalProps] = useState<ModalsActionsProps | null>(
    null
  );
  const isOnClaimsPage = context?.type === ContextEnums.CLAIMS;
  const contextEditPermissions = isOnClaimsPage
    ? permissionsClaims
    : permissionsInsured;

  const hasPermissionByRoles = usePermissions(contextEditPermissions);
  const noteIdFromHash = getQueryValue(DRAWER_NOTE_ID);

  const replyNote = (
    noteInfo: DiaryNoteDto,
    isChild?: boolean,
    parentNoteId?: number | null
  ) => {
    const _parentNote: DiaryNoteDto = {
      ...noteInfo,
      diaryNoteId: isChild ? noteInfo?.diaryNoteId : null,
      parentNoteId: isChild ? parentNoteId : noteInfo?.diaryNoteId,
      content: noteInfo?.childrenNotes ?? false ? "" : noteInfo?.content,
      status: StatusEnums.ACTIVE,
      claimNumber:
        context?.type === ContextEnums.CLAIMS ? context?.claimNumber : null,
    };
    setModalProps({ currentNote: _parentNote, action: "reply" });
  };

  const openEvent = (noteToEdit: DiaryNoteDto) => {
    setModalProps({
      currentNote: {
        ...noteToEdit,
        claimNumber:
          context?.type === ContextEnums.CLAIMS ? context?.claimNumber : null,
      },
      action: "open",
      hasPermissionsToEdit: reviewPermissions(noteToEdit),
    });
  };

  const reviewPermissions = (noteToEdit: DiaryNoteDto) => {
    const hasPermissionByCreator = noteToEdit?.isActiveUserCreator ?? false;
    const hasPermissionToEdit =
      noteToEdit?.isBeforeOneDay ??
      (false && (hasPermissionByRoles || hasPermissionByCreator));
    return hasPermissionToEdit;
  };

  const getNoteById = (id: number) =>
    notes.find((note) => note.diaryNoteId === id);

  const openNoteFromUrl = (noteId: string) => {
    const information = getNoteById(parseInt(noteId));
    if (conditionHasValue(information)) {
      openEvent(information!);
    }
  };

  useEffect(() => {
    if (conditionHasValue(noteIdFromHash)) {
      openNoteFromUrl(noteIdFromHash);
    }
  }, []);

  return (
    <Box
      width={"100%"}
      id={id}
      className={`true_conversation_wrapper ${expanded ? "expanded" : ""}`}
    >
      {notes?.length > 0 ? (
        notes?.map((note: DiaryNoteDto) => (
          <Box key={note.diaryNoteId}>
            <Note
              context={context}
              information={note}
              replyEvent={replyNote}
              openEvent={openEvent}
              diaryConfiguration={diaryConfiguration}
            />
            {note?.childrenNotes &&
              note?.childrenNotes.map((childNote: DiaryNoteDto) => (
                <Note
                  context={context}
                  key={childNote.diaryNoteId}
                  parentNoteId={note.diaryNoteId}
                  information={childNote}
                  isChild
                  replyEvent={replyNote}
                />
              ))}
          </Box>
        ))
      ) : (
        <div className="true_empty_message_container">
          <Title>There are no notes yet!</Title>
        </div>
      )}

      <ActionNotes
        context={context}
        diaryConfiguration={diaryConfiguration}
        setRefreshConversation={setRefreshConversation}
        modalsProps={modalsProps}
      />
    </Box>
  );
};

export default ConversationWrapper;
