import { FC, useEffect, useState } from "react";
import {
  Col,
  Font,
  Heading6,
  Input,
  Row,
  Select,
  SplitButton,
  Switch,
} from "../../../TrueUI";
import {
  rowWithNoHorizontalMarginAndGutter,
  rowWithNoMarginNorGutter,
} from "../../../TrueUI/Grids/Row";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../utilities/inputPropsConfigurationFunctions";
import DeleteButton from "../../../TrueUI/Buttons/DeleteButton";
import CancelButton from "../../../TrueUI/Buttons/CancelButton";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { SelectOptions } from "../../../../dtos/select-options";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  getAllStatesOptions,
  getFinalPayroll,
  getInsuredPrimaryAddressState,
  getLocationAddresses,
  getLocationOptions,
  getNvCredit,
  getNvCreditFormatted,
  getOvertimeCredit,
  getOvertimeCreditFormatted,
  getOvertimeTypeOptions,
  getRecordTypeOptions,
  getTotalIncludable,
  hasClassCodeInPolicyQuote,
} from "./ModalAuditWorksheetFormUtils";
import { useApiGet } from "../../../../hooks";
import { ClassCodeDto } from "../../../../dtos/class-code-dto";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import FontError from "../../../TrueUI/Typography/FontError";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { AuditWorksheetDto } from "../../../../dtos/audit-worksheet-dto";
import { addEmptyOption } from "../../../../utilities/selectFunctions";

type ModalAuditWorksheetFormProps = {
  tabKey: string;
  isAdding: boolean;
  errorDetails: any;
  auditWorksheetData: AuditWorksheetDto | null;
  onCancelEvent: () => void;
  onDeleteEvent: () => void;
  onDataChange: (value: any, target: string) => void;
  onMultipleDataChange: (targetKeys: string[], targetValues: any[]) => void;
  onSaveEvent: (closeModal: boolean) => void;
};

type ClassCodeDescriptionProps = {
  type: string;
  description: string;
  showDialog: boolean;
};

const initialClassCode = {
  type: "",
  description: "",
  showDialog: false,
};

const ModalAuditWorksheetForm: FC<ModalAuditWorksheetFormProps> = ({
  tabKey,
  isAdding = false,
  auditWorksheetData,
  errorDetails,
  onSaveEvent,
  onCancelEvent,
  onDeleteEvent,
  onDataChange,
  onMultipleDataChange,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const policyQuote = getPolicyQuote(getAtom());
  const typeOptions: Partial<SelectOptions>[] = getRecordTypeOptions();
  const statesOptions: Partial<SelectOptions>[] = getAllStatesOptions();
  const locationOptions: Partial<SelectOptions>[] =
    getLocationOptions(policyQuote);
  const overtimeTypeOptions: Partial<SelectOptions>[] =
    getOvertimeTypeOptions();
  const defaultState = getInsuredPrimaryAddressState(
    statesOptions,
    policyQuote
  );
  const [classCodeDescription, setClassCodeDescription] =
    useState<ClassCodeDescriptionProps>(initialClassCode);
  const effectiveDate = FormattingDate(
    new Date(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate ??
        Date.now()
    )
  );
  const classCode = auditWorksheetData?.classCode;
  const suffix = auditWorksheetData?.classSuffix;

  const { responseGet, dispatchGet, validatorErrorResponse } =
    useApiGet<ClassCodeDto>(
      `api/QuotePolicy/GetValidClassCode?classCode=${classCode}&suffix=${suffix}&effectiveDate=${effectiveDate}`
    );

  const getDefaultState = () => {
    onDataChange(defaultState, "state");
    return defaultState;
  };

  const onLocationChange = (location: number) => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const locations = getLocationAddresses(policyQuote);
    const locationSelected = locations.find(
      (loc) => loc.addressID === location
    );
    onMultipleDataChange(
      ["addressId", "state"],
      [location, locationSelected?.addressState]
    );
  };

  const onClassCodeChange = (value: string = "") => {
    const classCode = (value.length ?? 0) > 4 ? value.slice(0, -1) : value;
    const classSuffix = (value.length ?? 0) > 4 ? value.slice(-1) : "";
    onMultipleDataChange(
      ["classCode", "classSuffix"],
      [classCode, classSuffix]
    );
  };

  const splitButtonOptions = [
    {
      key: 1,
      option: "SAVE AND ADD",
      dropdownText: "Save and Add",
      action: () => {
        onSaveEvent(true);
        setClassCodeDescription(initialClassCode);
      },
    },
    {
      key: 2,
      option: "SAVE AND CLOSE",
      dropdownText: "Save and Close",
      action: () => {
        onSaveEvent(false);
        setClassCodeDescription(initialClassCode);
      },
    },
  ];

  useEffect(() => {
    const finalPayroll = getFinalPayroll(auditWorksheetData);
    onDataChange(finalPayroll, "finalPayroll");
  }, [
    auditWorksheetData?.quarter1Amount,
    auditWorksheetData?.quarter2Amount,
    auditWorksheetData?.quarter3Amount,
    auditWorksheetData?.quarter4Amount,
  ]);

  useEffect(() => {
    const nvCredit = getNvCredit(auditWorksheetData);
    const nvCreditFormatted = getNvCreditFormatted(auditWorksheetData);
    onMultipleDataChange(
      ["nVCredit", "nVCreditFormatted"],
      [nvCredit, nvCreditFormatted]
    );
  }, [auditWorksheetData?.state, auditWorksheetData?.finalPayroll]);

  useEffect(() => {
    const overtimeCredit = getOvertimeCredit(auditWorksheetData);
    const overtimeCreditFormatted =
      getOvertimeCreditFormatted(auditWorksheetData);
    onMultipleDataChange(
      ["overTimeCredit", "overTimeCreditFormatted"],
      [overtimeCredit, overtimeCreditFormatted]
    );
  }, [auditWorksheetData?.overTime, auditWorksheetData?.overTimeType]);

  useEffect(() => {
    const totalIncludable = getTotalIncludable(auditWorksheetData);
    onDataChange(totalIncludable, "totalIncluded");
  }, [
    auditWorksheetData?.nVCredit,
    auditWorksheetData?.autoAllowance,
    auditWorksheetData?.cafeWages,
    auditWorksheetData?.overTimeCredit,
  ]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const atomValue = getAtom();
      const classCode = responseGet.axiosResponse?.data;
      const quoteHasClassCode = hasClassCodeInPolicyQuote(
        classCode?.classCodeId,
        atomValue?.policyQuoteInformation
      );
      onDataChange(classCode?.classCodeId, "classCodeId");
      setClassCodeDescription({
        type: "classCode",
        description: classCode?.description ?? "",
        showDialog: isEmptyValue(quoteHasClassCode),
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setClassCodeDescription({
        type: "error",
        description:
          "The class code you entered is not valid.  Please try again",
        showDialog: false,
      });
    }
  }, [validatorErrorResponse]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            tabIndex={0}
            {...selectConfiguration("id-type", "type", "Type")}
            labelFontType="BOLD_CAPTION"
            options={typeOptions}
            value={auditWorksheetData?.recordType}
            onChange={(value) => onDataChange(value, "recordType")}
          />
        </Col>
        <Col breakpoints={{ md: 7, lg: 7, xl: 7 }}>
          <Input
            tabIndex={1}
            {...inputConfiguration("id-name", "name", "Name", 200)}
            value={auditWorksheetData?.employeeName}
            onChangeRawValue={(value) => onDataChange(value, "employeeName")}
            errorMessage={errorDetails?.employeeName}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Input
            tabIndex={2}
            {...inputConfiguration(
              "id-job-title",
              "job-title",
              "Job Title",
              100
            )}
            value={auditWorksheetData?.jobTitle}
            onChangeRawValue={(value) => onDataChange(value, "jobTitle")}
            errorMessage={errorDetails?.jobTitle}
          />
        </Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Input
            tabIndex={3}
            {...inputConfiguration(
              "id-department",
              "department",
              "Department",
              100
            )}
            value={auditWorksheetData?.department}
            onChangeRawValue={(value) => onDataChange(value, "department")}
            errorMessage={errorDetails?.department}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Select
            tabIndex={4}
            {...selectConfiguration("id-location", "location", "Location")}
            labelFontType="BOLD_CAPTION"
            options={addEmptyOption(locationOptions)}
            value={auditWorksheetData?.addressId}
            onChange={(value) => onLocationChange(value)}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            tabIndex={5}
            {...selectConfiguration("id-state", "state", "State")}
            labelFontType="BOLD_CAPTION"
            options={statesOptions}
            value={auditWorksheetData?.state ?? getDefaultState()}
            onChange={(value) => onDataChange(value, "state")}
            optionsMaxHeight="200px"
          />
        </Col>
        <Col horizontalAlign="flex-start" breakpoints={{ md: 2, lg: 2, xl: 2 }}>
          <Switch
            tabIndex={6}
            id="id-exclude"
            name="exclude"
            label="Exclude"
            labelFontType="BOLD_CAPTION"
            control="checkbox"
            isChecked={auditWorksheetData?.exclude}
            onChangeIsChecked={(value) => onDataChange(value, "exclude")}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={7}
            {...inputConfiguration(
              "id-class-code",
              "class-code",
              "Class Code",
              5
            )}
            value={
              (auditWorksheetData?.classCode ?? "") +
              (auditWorksheetData?.classSuffix ?? "")
            }
            onChangeRawValue={(value) => onClassCodeChange(value)}
            onBlur={() => dispatchGet()}
            errorMessage={errorDetails?.classCode}
          />
        </Col>
        <Col
          verticalMargin="20px"
          horizontalMargin="0px"
          verticalAlignSelf="center"
          horizontalAlign="flex-start"
          breakpoints={{ md: 7, lg: 7, xl: 7 }}
        >
          {classCodeDescription?.type !== "error" ? (
            <Font>
              {classCodeDescription?.description === ""
                ? auditWorksheetData?.classCodeDescription
                : classCodeDescription?.description}
            </Font>
          ) : (
            <FontError>{classCodeDescription?.description}</FontError>
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col horizontalAlign="flex-start">
          <Heading6>QUARTERLY PAYROLL</Heading6>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col>
          <Input
            tabIndex={8}
            {...inputConfiguration("id-first", "first", "First")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.quarter1Amount ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "quarter1Amount")}
          />
        </Col>
        <Col>
          <Input
            tabIndex={9}
            {...inputConfiguration("id-second", "second", "Second")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.quarter2Amount ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "quarter2Amount")}
          />
        </Col>
        <Col>
          <Input
            tabIndex={10}
            {...inputConfiguration("id-third", "third", "Third")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.quarter3Amount ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "quarter3Amount")}
          />
        </Col>
        <Col>
          <Input
            tabIndex={11}
            {...inputConfiguration("id-fourth", "fourth", "Fourth")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.quarter4Amount ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "quarter4Amount")}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col horizontalAlign="flex-start">
          <Heading6>FINAL</Heading6>
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        rowDirection="column"
      >
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={12}
            {...inputConfiguration(
              "id-final-payroll",
              "final-payroll",
              "Final Payroll"
            )}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={
              auditWorksheetData?.finalPayroll ??
              getFinalPayroll(auditWorksheetData)
            }
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "finalPayroll")}
            errorMessage={errorDetails?.finalPayroll}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={13}
            {...inputConfiguration(
              "id-nv-credit",
              "nv-credit",
              "NV Credit",
              undefined,
              true
            )}
            value={getNvCreditFormatted(auditWorksheetData)}
            errorMessage={errorDetails?.nVCredit}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={14}
            {...inputConfiguration(
              "id-auto-allowance",
              "auto-allowance",
              "Auto Allowance"
            )}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.autoAllowance ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "autoAllowance")}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={15}
            {...inputConfiguration("id-cafe-wages", "cafe-wages", "Cafe Wages")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.cafeWages ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "cafeWages")}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={16}
            {...inputConfiguration("id-overtime", "overtime", "Overtime")}
            type="number"
            decimalScale={2}
            maxNumericValue={999999.99}
            value={auditWorksheetData?.overTime ?? "0.00"}
            thousandSeparator
            onChangeRawValue={(value) => onDataChange(value, "overTime")}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            tabIndex={17}
            {...selectConfiguration(
              "id-overtime-type",
              "overtime-type",
              "Overtime Type"
            )}
            labelFontType="BOLD_CAPTION"
            options={overtimeTypeOptions}
            value={auditWorksheetData?.overTimeType}
            onChange={(value) => onDataChange(value, "overTimeType")}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            {...inputConfiguration(
              "id-overtime-credit",
              "overtime-credit",
              "Overtime Credit",
              undefined,
              true
            )}
            value={getOvertimeCreditFormatted(auditWorksheetData)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            {...inputConfiguration(
              "id-total-includable",
              "total-includable",
              "Total Includable",
              undefined,
              true
            )}
            type="number"
            decimalScale={2}
            value={getTotalIncludable(auditWorksheetData)}
            thousandSeparator
          />
        </Col>
      </Row>
      <Row {...rowWithNoHorizontalMarginAndGutter} horizontalAlign="flex-start">
        <Col horizontalAlign="flex-start" breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          {!isAdding && (
            <DeleteButton
              tabIndex={20}
              onClick={onDeleteEvent}
              withConfirmation
            />
          )}
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <SplitButton
            tabIndex={18}
            items={splitButtonOptions}
            currentSelectionIndex={isAdding ? 0 : 1}
            disabled={classCodeDescription?.type === "error"}
          />
        </Col>
        <Col breakpoints={{ md: 1, lg: 1, xl: 1 }}>
          <CancelButton
            tabIndex={19}
            onClick={onCancelEvent}
            withConfirmation
          />
        </Col>
      </Row>
      <DialogConfirmation
        id="audit-worksheet-dialog-id"
        name="audit-worksheet-dialog"
        dialogDescriptionText="The class you entered is valid but is not on this policy. An endorsement may be required before completing the audit."
        optionYesOverrideLabel="OK"
        open={classCodeDescription.showDialog}
        onCloseEvent={() =>
          setClassCodeDescription({
            ...classCodeDescription,
            showDialog: false,
          })
        }
        onOptionYesEvent={() =>
          setClassCodeDescription({
            ...classCodeDescription,
            showDialog: false,
          })
        }
      />
    </>
  );
};

export default ModalAuditWorksheetForm;
