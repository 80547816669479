import {
  ComponentType,
  PropsWithoutRef,
  RefAttributes,
  forwardRef,
} from "react";

export function DeprecationWarningComponentRoot<P, T>(
  Component: ComponentType<PropsWithoutRef<P> & RefAttributes<T>>
): typeof Component {
  const componentName = Component?.displayName ?? Component?.name ?? undefined;
  const warning = `The component ${componentName} is scheduled to be deprecated in the future.`;
  const Deprecated = forwardRef<T, P>((props, ref) => {
    console.warn(warning);
    return <Component ref={ref} {...(props as PropsWithoutRef<P>)} />;
  });

  Deprecated.displayName = `deprecation_warning_root_(${componentName})`;

  return Deprecated;
}
