import { BaseTableFilterOptions } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Select } from "../../TrueUI";
import { useState } from "react";
import { SelectOptions } from "../../../dtos/select-options";
import {
  addAllOption,
  addCustomOption,
} from "../../../utilities/selectFunctions";

type CancelReasonFilterProps = {
  actionOptions: BaseTableFilterOptions;
  configurationState: any;
  onChange?: (e: any) => void;
};
const CancelReasonFilter: (
  properties: CancelReasonFilterProps
) => JSX.Element = ({ actionOptions, configurationState, onChange }) => {
  const [reason, setReason] = useState("all");

  actionOptions?.isStaticFilter(true);

  const includeReasonOptions: Partial<SelectOptions>[] = addAllOption(
    addCustomOption(configurationState?.cancelReasonOptions ?? [], {
      displayName: "All Except NonPay",
      stringValue: "-05",
    } as Partial<SelectOptions>)
  );

  return (
    <Select
      id="cancel-type"
      name={"type-dropdown"}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={"Include Reason:"}
      labelPosition={"start"}
      options={includeReasonOptions}
      value={reason ?? "all"}
      type={"tableFilter"}
      inputWidth={"350px"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        setReason(value);
        onChange?.(value === "all" ? null : value);
      }}
    />
  );
};

export default CancelReasonFilter;
