import { FC } from "react";
import { Button, Title, TitleBold } from "..";
import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import { TaskBoardColumnDto } from "../../../dtos/task-board-column-dto";
import { TaskBoardColumnEnum } from "../../../dtos/task-board-column-enum";
import { SelectedCardType } from "./TaskBoard";
import style from "./taskBoard.module.css";
import TaskBoardCardContent from "./TaskBoardCardContent";

type TaskBoardColumnProps = {
  column: TaskBoardColumnDto;
  selectedCard?: SelectedCardType;
  textButton?: string;
  onClickButton?: () => void;
  onClickCard: (card: SelectedCardType) => void;
};

const TaskBoardColumn: FC<TaskBoardColumnProps> = ({
  column,
  selectedCard,
  textButton,
  onClickButton,
  onClickCard,
}) => {
  const { columnType, columnTitle, count, cards, columnField } = column;
  const isMine = columnType === TaskBoardColumnEnum?.MY_WORK;
  const isWithButton = columnType === TaskBoardColumnEnum?.WITH_BUTTON;
  const isSelectedCard = (card: TaskBoardCardDto) =>
    card?.cardTitle === selectedCard?.cardInformation?.cardTitle &&
    columnField === selectedCard?.columnField;

  return (
    <div className={`${isMine && style.mine_column} ${style.column}`}>
      <div className={style.column_title}>
        <Title truncate>{columnTitle}</Title>
        <TitleBold>{count}</TitleBold>
      </div>
      <div
        className={`${
          isMine ? style.mine_column_content : style.other_column
        } ${style.column_content}`}
      >
        {isWithButton && (
          <Button
            onClick={onClickButton}
            className={style.column_button_button}
          >
            <span
              className={`${style.column_button_icon} k-icon k-i-plus-circle`}
            ></span>{" "}
            {textButton}
          </Button>
        )}
        {cards.map((card, index) => (
          <TaskBoardCardContent
            card={card}
            columnField={columnField}
            onClickCard={onClickCard}
            isSelectedCard={isSelectedCard}
            index={index}
            key={`card-${card?.cardTitle}-from-${columnField}-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TaskBoardColumn;
