import { EditFileModalProps } from "./FileUploadUtils";
import { getIconByExt } from "../TrueUI/File/DownloadFileUtils";
import DownloadLinkButton from "../TrueUI/File/DownloadLinkButton";
import {
  Button,
  Input,
  InputDate,
  InputMemo,
  SaveButton,
  Select,
} from "../TrueUI";
import { selectConfiguration } from "../../utilities/inputPropsConfigurationFunctions";
import {
  FromEnumToArray,
  FromEnumToArrayWithExceptions,
} from "../../utilities/enumFunctions";
import { InsuredFileTypesEnums } from "../../dtos/insured-file-types-enums";
import { editFileModalStyles } from "./FileUploadStyles";
import { Box } from "@mui/material";
import { SelectOptions } from "../../dtos/select-options";
import { FC, useEffect, useState } from "react";
import SearchText from "../TrueUI/Search/SearchText";
import { InsuredFileStatusEnums } from "../../dtos/insured-file-status-enums";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import DiaryNoteCategoryDropdown from "./DiaryNoteCategoryDropdown";
import {
  getFileExtension,
  getFileNameWithoutExtension,
} from "../../utilities/stringFunctions";

const InsuredText = "Insured";
const ClaimText = "Claim";

const EditFileModalContent: FC<EditFileModalProps> = ({
  configuration,
  fileData,
  setFileData,
  errorDetails,
  closeModal,
  onDeleteFile,
  setMoveNextFile,
  isLastFile,
  isFileDataEdited,
  setIsFileDataEdited,
  dataWithoutEdit,
  setIsValidClaim,
  setIsValidInsured,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [isDeleteDialogConfirmationOpen, setIsDeleteDialogConfirmationOpen] =
    useState<boolean>(false);

  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [dataToShowOnSearch, setDataToShowOnSearch] = useState({
    textInsured: configuration.insuredName ?? null,
    textClaim: configuration.claimNumber ?? null,
  });

  const typeOptions = FromEnumToArray(InsuredFileTypesEnums).map((option) => {
    return {
      displayName: option.key,
      stringValue: option.key,
    };
  });

  const statuses = FromEnumToArrayWithExceptions(InsuredFileStatusEnums, [
    InsuredFileStatusEnums.DELETED,
  ]);

  const statusOptions: Partial<SelectOptions>[] = statuses.map((status) => {
    return { displayName: status?.key, intValue: status?.value };
  });

  const getCategoryOptions = (type: string) => {
    switch (type) {
      case InsuredText:
        return configuration.insuredCategoryOptions ?? [];
      case ClaimText:
        return configuration.claimCategoryOptions ?? [];
      default:
        return [];
    }
  };

  const getValueOnChangeType = (value: string, _fileData) => {
    switch (value) {
      case InsuredText:
        return {
          ..._fileData,
          type: value,
          claimId: null,
          claimNumber: null,
          showSearchTextClaim: "",
        };
      case ClaimText:
        return {
          ..._fileData,
          type: value,
          insuredId: null,
          insuredName: null,
          showSearchTextInsured: "",
        };
      default:
        return { ..._fileData, type: value === "0" ? null : value };
    }
  };

  useEffect(() => {
    if (dataWithoutEdit !== null) {
      setIsFileDataEdited(dataWithoutEdit !== JSON.stringify(fileData));
    }
    if (
      fileData?.fileExtension === null ||
      fileData?.fileExtension === undefined
    ) {
      const fileExt = getFileExtension(configuration?.fileDisplayName ?? "");
      setFileData({
        ...fileData,
        fileExtension: fileExt.startsWith(".") ? fileExt : `.${fileExt}`,
      });
    }
  }, [fileData]);

  useEffect(() => {
    setCategoryOptions(getCategoryOptions(fileData.type ?? ""));
  }, [fileData.type]);

  useEffect(() => {
    if (
      fileData?.status === InsuredFileStatusEnums.NEW &&
      fileData?.assignedUser !== null
    ) {
      setFileData({ ...fileData, status: InsuredFileStatusEnums.ASSIGNED });
    }
  }, [fileData.assignedUser]);

  const updateForm = (e) => {
    setFileData({ ...fileData, [e.target.name]: e.target.value });
  };

  const CategorySelector = () => {
    return (
      <Select
        {...selectConfiguration("id-category", "category", "Category", false)}
        options={categoryOptions}
        labelFontType="BOLD_CAPTION"
        value={fileData.fileCategory}
        firstOptionAsDefault={false}
        onChange={(value) => setFileData({ ...fileData, fileCategory: value })}
        optionsMaxHeight="200px"
      />
    );
  };

  const resetSearchValue = (value) => {
    setDataToShowOnSearch({
      textClaim: value === ClaimText ? dataToShowOnSearch.textClaim : "",
      textInsured: value === InsuredText ? dataToShowOnSearch.textInsured : "",
    });
  };

  useEffect(() => {
    setDataToShowOnSearch({
      textInsured: configuration.insuredName ?? null,
      textClaim: configuration.claimNumber ?? null,
    });
  }, [configuration?.claimNumber, configuration?.insuredId]);
  return (
    <Box sx={editFileModalStyles}>
      <div className={"download_container"}>
        {getIconByExt(configuration.fileDisplayName)}
        <div className={"link_container"}>
          <DownloadLinkButton
            fileName={configuration.fileDisplayName}
            downloadFileName={configuration.fileDisplayName}
          />
        </div>
      </div>
      <div className={"file_container"}>
        <Input
          id="id-file"
          name="file"
          label="File"
          labelPosition="top"
          labelFontType="BOLD_CAPTION"
          value={getFileNameWithoutExtension(
            fileData?.downloadedFileName ?? ""
          )}
          onChange={(e) => {
            setFileData({ ...fileData, downloadedFileName: e.target.value });
          }}
          maxLength={255}
          errorMessage={errorDetails?.downloadedFileName}
        />
        <Input
          id="id-fileExt"
          name="fileExt"
          label="File Ext"
          labelPosition="top"
          labelFontType="BOLD_CAPTION"
          value={
            fileData?.fileExtension?.includes(".")
              ? fileData?.fileExtension
              : `.${fileData?.fileExtension}`
          }
          readOnly
        />
      </div>
      <div className={"type_container"}>
        <Select
          {...selectConfiguration("id-type", "type", "Type", false)}
          options={typeOptions}
          labelFontType="BOLD_CAPTION"
          firstOptionAsDefault={false}
          value={fileData?.type?.toString() ?? "0"}
          onChange={(value) => {
            setFileData(getValueOnChangeType(value, fileData));
            resetSearchValue(value);
          }}
          errorMessage={errorDetails?.type}
          key={fileData?.type}
          optionsMaxHeight="200px"
        />
        <Box className={"search_container"}>
          <SearchText
            saveSelectedResult={(value) =>
              setFileData({ ...fileData, claimId: value?.claimId })
            }
            url={"api/QuickSearchInsuredClaim/SearchClaimText?text="}
            labelText={ClaimText}
            searchTextKeys={[
              "claimNumber",
              "claimantName",
              "claimStatus",
              "dateOfIncident",
              "dateOfBirth",
              "assigneeName",
            ]}
            placeholderText={"Search for a claim"}
            labelFontType={"BOLD_CAPTION"}
            value={dataToShowOnSearch.textClaim ?? ""}
            focus={false}
            minCharacters={4}
            errorMessage={errorDetails?.claimId}
            key={configuration.claimId}
            setValidSelection={(isValid) => setIsValidClaim(isValid)}
            readOnly={fileData.type === InsuredText}
            onRawChange={(value) =>
              setDataToShowOnSearch({
                ...dataToShowOnSearch,
                textClaim: value,
              })
            }
            searchOnEnterOnly
          />
        </Box>
        <Box className={"search_container"}>
          <SearchText
            saveSelectedResult={(value) =>
              setFileData({ ...fileData, insuredId: value?.insuredId })
            }
            url={"api/QuickSearchInsuredClaim/SearchInsuredText?text="}
            labelText="Insured Number"
            searchTextKeys={["insuredCode", "insuredName"]}
            placeholderText={"Search for an insured"}
            labelFontType={"BOLD_CAPTION"}
            value={dataToShowOnSearch.textInsured ?? ""}
            onRawChange={(value) => {
              setDataToShowOnSearch({
                ...dataToShowOnSearch,
                textInsured: value,
              });
            }}
            focus={false}
            minCharacters={4}
            errorMessage={errorDetails?.insuredId}
            key={configuration?.insuredId}
            setValidSelection={(isValid) => setIsValidInsured(isValid)}
            readOnly={fileData.type === ClaimText}
          />
        </Box>
      </div>
      <div>
        <InputMemo
          id={"id-comments"}
          name={"comments"}
          label={"Comments"}
          rows={5}
          value={fileData.comments ?? ""}
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"MEDIUM_BODY"}
          onChange={updateForm}
          errorMessage={errorDetails?.comments}
          maxLength={500}
        />
      </div>
      <div>
        <InputMemo
          id={"id-diary-note-content"}
          name={"diaryNoteContent"}
          label={"Note Content"}
          rows={3}
          value={fileData.diaryNoteContent ?? ""}
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"MEDIUM_BODY"}
          onChange={updateForm}
          errorMessage={errorDetails?.diaryNoteContent}
          maxLength={500}
        />
      </div>
      <div>
        <DiaryNoteCategoryDropdown
          value={fileData.diaryNoteCategory}
          errorMessage={errorDetails?.diaryNoteCategory}
          onChange={(value) =>
            setFileData({ ...fileData, diaryNoteCategory: value })
          }
        />
      </div>
      <div className={"date_container"}>
        <InputDate
          id="id-service-from"
          name="serviceFrom"
          label="Service From"
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"MEDIUM_BODY"}
          value={fileData?.serviceFrom}
          onChangeRawValue={(value) => {
            setFileData({ ...fileData, serviceFrom: value });
          }}
          errorMessage={errorDetails?.serviceFrom}
        />
        <InputDate
          id="id-service-to"
          name="serviceTo"
          label="Service To"
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"MEDIUM_BODY"}
          value={fileData?.serviceTo}
          onChangeRawValue={(value) => {
            setFileData({ ...fileData, serviceTo: value });
          }}
          errorMessage={errorDetails?.serviceTo}
        />
      </div>
      <div className={"status_container"}>
        <CategorySelector />
        <Select
          {...selectConfiguration("id-status", "status", "Status", false)}
          options={statusOptions}
          labelFontType="BOLD_CAPTION"
          value={fileData.status}
          firstOptionAsDefault={false}
          onChange={(value) => setFileData({ ...fileData, status: value })}
          errorMessage={errorDetails?.status}
          key={fileData.status}
          optionsMaxHeight="200px"
        />
        <Select
          {...selectConfiguration(
            "id-assigned-to",
            "assignedTo",
            "Assigned To",
            false
          )}
          key={fileData.assignedUser}
          options={configuration.userOptions ?? []}
          labelFontType="BOLD_CAPTION"
          firstOptionAsDefault={false}
          value={fileData.assignedUser?.toString() ?? "0"}
          onChange={(value) =>
            setFileData({
              ...fileData,
              assignedUser: value === "0" ? null : value,
            })
          }
          optionsMaxHeight="200px"
        />
      </div>
      <div className={"added_by_container"}>
        <Input
          id="id-added-by"
          name="added-by"
          label="Added By"
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"BODY"}
          value={configuration.userShortName}
          readOnly
        />
        <Input
          id="id-date-added"
          name="date-added"
          label="Date Added"
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"BODY"}
          value={configuration.addedDate}
          readOnly
        />
        <Input
          id="id-date-reviewed"
          name="date-reviewed"
          label="Date Reviewed"
          labelFontType={"BOLD_CAPTION"}
          inputFontType={"BODY"}
          value={configuration.dateReviewed}
          readOnly
        />
      </div>
      <div className={"bottom_buttons_container"}>
        <div>
          <Button
            variantStyle={"outlined"}
            onClick={() => setIsDeleteDialogConfirmationOpen(true)}
          >
            DELETE
          </Button>
        </div>
        <div className="right-buttons">
          <SaveButton
            disabled={isFileDataEdited === false || isLastFile}
            onClick={() => {
              setMoveNextFile(true);
            }}
            text="SAVE & NEXT"
          />
          <SaveButton
            onClick={() => setMoveNextFile(false)}
            disabled={isFileDataEdited === false}
            text="SAVE"
          />
          <Button
            variantStyle={"outlined"}
            onClick={() => setIsDialogConfirmationOpen(true)}
          >
            CANCEL
          </Button>
        </div>
      </div>
      <DialogConfirmation
        id="editDetailsCancel"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to discard the changes?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setMoveNextFile(null);
          closeModal(close);
        }}
      />
      <DialogConfirmation
        id="deleteDetailsCancel"
        open={isDeleteDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this file?"
        onCloseEvent={(close) => {
          setIsDeleteDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDeleteDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          onDeleteFile();
          closeModal(close);
        }}
      />
    </Box>
  );
};

export default EditFileModalContent;
