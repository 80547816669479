import { Dialog, DialogTitle } from "@mui/material";
import { FC } from "react";

type DialogControlledProperties = {
  isOpen: boolean;
  title: string;
  element: JSX.Element;
  hasBackdrop?: boolean;
};

const DialogControlled: FC<DialogControlledProperties> = ({
  isOpen,
  title,
  element,
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title ?? ""}</DialogTitle>
      {element}
    </Dialog>
  );
};

export default DialogControlled;
