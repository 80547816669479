import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ClaimDetailsTabEnums } from "../../../../dtos/claim-details-tab-enums";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { getColorFromAppVariables } from "../../../../utilities/cssFunctions";
import { getClaimDetailsTabURLWithClaimNumber } from "../../../../utilities/queryStringsHashFunctions";
import { BaseTable2 } from "../../../TrueUI";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import Link from "../../../TrueUI/Typography/Link";
import { TriggerComponentUpdateAtom } from "../../InsuredAtoms";
import { claimLandingTableStyles } from "./ClaimLandingStyles";
import { unFormatLocalString } from "../../../../utilities/stringFunctions";
import { useNavigate } from "react-router";

const tableName = "claim_landing_financial_table";

type ClaimLandingFinancialTableProps = {
  claimNumber?: string;
  insuredId: number;
};

const ClaimLandingFinancialTable: FC<ClaimLandingFinancialTableProps> = ({
  claimNumber,
  insuredId,
}) => {
  const navigate = useNavigate();
  const [uiid, setUiid] = useState<string>(crypto.randomUUID());
  const refreshFinancialTable = useRecoilValue(
    TriggerComponentUpdateAtom("financialTableClaimLandingPageComponent")
  );

  const getPercentageValidated = (totalPaid: number, totalIncurred: number) => {
    if (totalIncurred === 0 && totalPaid === 0) return 100;
    if (totalIncurred === 0 && totalPaid < 0) return 0;
    if (totalIncurred === 0 && totalPaid > 0) return 100;
    return (totalPaid / totalIncurred) * 100;
  };

  useEffect(() => {
    setUiid(crypto.randomUUID());
  }, [refreshFinancialTable]);
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/claims/GetClaimFinancialTableData?ClaimNumber=${claimNumber}&uiid=${uiid}`,
    columnOptions: [
      { fieldName: "Type", width: 10 },
      { fieldName: "TotalReserves", width: 18, align: "right" },
      { fieldName: "TotalPaid", width: 18, align: "right" },
      { fieldName: "OutstandingReserves", width: 18, align: "right" },
      { fieldName: "TotalIncurred", width: 18, align: "right" },
      { fieldName: "TotalRecoveries", width: 18, align: "right" },
    ],
    advancedOptions: {
      tableStyle: {
        headerStyle: "transparent",
        height: "auto",
        rowStyle: {
          progressBar: (rowInfo) => {
            const totalPaid = unFormatLocalString(rowInfo.TotalPaid ?? "0");
            const totalIncurred = unFormatLocalString(
              rowInfo.TotalIncurred ?? "0"
            );
            const successColor = getColorFromAppVariables("--t-success");
            return {
              color: successColor,
              percentage: getPercentageValidated(totalPaid, totalIncurred),
            };
          },
          applyTo: "row",
        },
      },
      paginate: false,
      disableOrderBy: true,
    },
    toolbarOptions: { hideToolbar: true },
  };

  useBaseTable(tableConfiguration);

  return (
    <InformationContainer
      title={
        <Link
          displayValue={"Financial"}
          linkFontType={"TITLE"}
          name="claimFinancialTitle"
          onClick={() =>
            navigate(
              `/insured/${insuredId}${getClaimDetailsTabURLWithClaimNumber(
                ClaimDetailsTabEnums.FINANCIAL,
                claimNumber
              )}`
            )
          }
        />
      }
    >
      <Box sx={claimLandingTableStyles()} id="financial_table">
        <BaseTable2 name={tableName} />
      </Box>
    </InformationContainer>
  );
};

export default ClaimLandingFinancialTable;
