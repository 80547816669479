import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { useApiGet } from "../../../../hooks";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import {
  POLICY_DOCS_SECTION,
  PolicyQuoteInsuredAndActiveSetterProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getFilteredAndSortedPacketDocuments,
  getFilteredPolicyDocuments,
} from "./DocumentsUtils";
import DocumentFileOptions from "./DocumentFileOptions";
import DocumentSplitter from "./DocumentSplitter";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import { removeValue } from "../../../../utilities/arrayFunctions";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { ProgramPacketPage } from "../../../../dtos/program-packet-page";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { splitterStyles } from "./DocumentStyles";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

const DocumentsMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  quoteStatus,
  setActiveSection,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const effectiveDate = FormattingDate(
    new Date(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate ??
        Date.now()
    )
  );

  const [selectedPackets, setSelectedPackets] = useState<number[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [allDocuments, setAllDocuments] = useState<ProgramPacketPage>();
  const [programPackets, setProgramPackets] = useState<ProgramPacketDto[]>([]);
  const [programDocuments, setProgramDocuments] = useState<
    ProgramPacketDocumentDto[]
  >([]);
  const [policyDocuments, setPolicyDocuments] = useState<
    ProgramPolicyDocumentDto[]
  >([]);

  const { responseGet, dispatchGet } = useApiGet<ProgramPacketPage>(
    `${QUOTE_POLICY_API_PATH}/GetProgramPacketList?effectiveDate=${effectiveDate}&formType=${PolicyFormTypeEnum.QUOTE_FORM}`
  );

  const onSelectedCheckboxDocument = (
    checked: boolean,
    id: number,
    type: "packet" | "document"
  ) =>
    type === "packet"
      ? setSelectedPackets(
          checked ? [...selectedPackets, id] : removeValue(selectedPackets, id)
        )
      : setSelectedDocuments(
          checked
            ? [...selectedDocuments, id]
            : removeValue(selectedDocuments, id)
        );

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) dispatchGet();
    else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  useEffect(() => {
    if (
      responseGet.requestInstanceSuccessful &&
      responseGet?.axiosResponse?.data
    ) {
      setAllDocuments(responseGet?.axiosResponse?.data);
      setProgramPackets(responseGet?.axiosResponse?.data.packets);
    }
  }, [responseGet]);

  useEffect(() => {
    const filteredDocPackets = getFilteredAndSortedPacketDocuments(
      allDocuments?.packetDocuments,
      selectedPackets
    );
    setProgramDocuments(filteredDocPackets);

    const filteredDocPolicies = getFilteredPolicyDocuments(
      allDocuments?.policyDocuments,
      filteredDocPackets
    );
    setPolicyDocuments(filteredDocPolicies);
  }, [selectedPackets, allDocuments]);

  return (
    <div
      id="policy-documents-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <div className="policy-scrollable-body-container">
        <Box sx={splitterStyles(theme)}>
          <DocumentSplitter
            tabKey={tabKey}
            insuredId={insuredId}
            programPackets={programPackets}
            programDocuments={programDocuments}
            policyDocuments={policyDocuments}
            selectedPackets={selectedPackets}
            selectedDocuments={selectedDocuments}
            onSelectedCheckboxDocument={onSelectedCheckboxDocument}
          />
        </Box>
      </div>
      <DocumentFileOptions
        tabKey={tabKey}
        insuredId={insuredId}
        selectedPackets={selectedPackets}
        selectedDocuments={selectedDocuments}
        programPackets={allDocuments?.packets ?? []}
        programDocuments={programDocuments}
        policyDocuments={policyDocuments}
      />
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={POLICY_DOCS_SECTION}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
    </div>
  );
};

export default DocumentsMain;
