import { FC } from "react";
import { Box } from "@mui/material";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import Information from "./Information/Information";
import RateTables from "./RateTables/RateTables";

type ProgramBodyWrapperProps = {
  bodySection?: number;
  isNewProgram: boolean;
};

const PlaceHolderElement = () => (
  <Box sx={{ marginTop: 10 }}>Nav module In Development...</Box>
);

const renderProgramBody = (
  param: number | undefined,
  isNewProgram: boolean
) => {
  switch (param) {
    case SubSideNavItemDestinationsEnum.INFORMATION:
    case SubSideNavItemDestinationsEnum.MAIN:
      return <Information isNewProgram={isNewProgram} />;
    case SubSideNavItemDestinationsEnum.RATE_TABLES:
      return <RateTables />;
    default:
      return <PlaceHolderElement />;
  }
};

const ProgramBodyWrapper: FC<ProgramBodyWrapperProps> = ({
  bodySection,
  isNewProgram,
}) => {
  return renderProgramBody(bodySection, isNewProgram);
};

export default ProgramBodyWrapper;
