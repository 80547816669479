import { FC } from "react";
import { RecoilState, useRecoilValue } from "recoil";
import color from "../../../../media/colors";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { TableInstanceType2 } from "./TableProperties";

const BaseTableDebuggerKey: FC<any> = (props, { renderKey }) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  return tableInstance?._debug?.isDebug ?? false ? (
    <div style={{ backgroundColor: color.DEV_ERROR, padding: "10px" }}>
      If the render key changes <i>frequently</i>, we may be having some
      performance issues - <b>{renderKey}</b>
    </div>
  ) : null;
};

export default BaseTableDebuggerKey;
