import { FC, useEffect } from "react";
import { ClaimLandingTabEnums } from "../../../../dtos/claim-landing-tab-enums";
import ClaimLandingBanner from "./ClaimLandingBanner";
import ClaimLandingBody from "./ClaimLandingBody";
import ClaimLandingTabs from "./ClaimTabs/ClaimLandingTabs";
import { useApiGet } from "../../../../hooks";
import { ClaimLandingPage } from "../../../../dtos/claim-landing-page";
import { ClaimLandingDetailsDto } from "../../../../dtos/claim-landing-details-dto";
import { ClaimLandingClaimantDto } from "../../../../dtos/claim-landing-claimant-dto";
import { ClaimDetailsTabDto } from "../../../../dtos/claim-details-tab-dto";
import {
  addQueryStrings,
  getQueryValue,
  removeQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  CLAIMS_ACTIVE_SECTION,
  CLAIM_NUMBER,
  INSURED_ATOM_KEY,
  OPEN_CLAIM_INSIDE_INSURED,
} from "../../../../utilities/queryStringsHash";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  getByInsuredIdAndClaimNumber,
  setStorageOpenTabs,
} from "../../../../utilities/localStorageFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { SelectedClaimFormProps } from "./ClaimTabs/ClaimCorrespondenceComponents/typesAndConstants";
import { TabConfigProps } from "../../Insured";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { ClaimFlagsDataAtom } from "./ClaimTabs/ClaimFinancialComponents/ClaimFinancialAtoms";

export type ClaimLandingProps = {
  insuredId: number;
  tabKey: string;
  claimNumber?: string;
  activeSection?: number;
  claimId?: number;
  jurisdiction?: string;
  claimLandingClaimant?: ClaimLandingClaimantDto;
};

export type ClaimTabs = {
  activeSection?: string;
  claimDetailsTab?: {
    detailsContainersInformation?: ClaimDetailsTabDto | null;
  };
  claimFinancialTab?: {};
  claimContactsTab?: {};
  claimLitigationTab?: {};
  claimCorrespondenceTab?: {
    selectedClaimForms?: SelectedClaimFormProps[];
    switchJurisdiction?: boolean;
  };
};

const renderClaimLandingBody = (
  insuredId: number,
  claimNumber: string,
  activeSection: string,
  tabKey: string,
  claimId?: number,
  claimLandingDetails?: ClaimLandingDetailsDto,
  claimLandingClaimant?: ClaimLandingClaimantDto,
  jurisdiction?: string
) => {
  addQueryStrings([
    {
      nameOfHash: CLAIMS_ACTIVE_SECTION,
      valueOfHash: activeSection,
    },
  ]);
  switch (activeSection) {
    case ClaimLandingTabEnums.LANDING.toString():
      return (
        <ClaimLandingBody
          tabKey={tabKey}
          insuredId={insuredId}
          claimId={claimId}
          claimNumber={claimNumber}
          claimLandingDetails={claimLandingDetails}
          claimLandingClaimant={claimLandingClaimant}
        />
      );
    default:
      return (
        <ClaimLandingTabs
          insuredId={insuredId}
          claimId={claimId}
          claimNumber={claimNumber}
          jurisdiction={jurisdiction}
          claimLandingClaimant={claimLandingClaimant}
          tabKey={tabKey}
        />
      );
  }
};

const ClaimLandingMain: FC<ClaimLandingProps> = ({ insuredId, tabKey }) => {
  const renderListener = useRecoilValue(
    TriggerComponentUpdateAtom("claimLandingPageComponent")
  );

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const claimPageState = getAtom();
  const _claimNumber =
    getQueryValue(CLAIM_NUMBER) != ""
      ? getQueryValue(CLAIM_NUMBER)
      : getAtom()?.currentClaim?.claimNumber ?? "";

  const { responseGet, dispatchGet } = useApiGet<ClaimLandingPage>(
    `api/Claims/GetClaimLandingPage?ClaimNumber=${_claimNumber}`
  );

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const getActiveSection = () => {
    const claimActiveSection = getQueryValue(CLAIMS_ACTIVE_SECTION);
    if (claimActiveSection != "") {
      return claimActiveSection;
    }
    if (claimPageState?.claimTabsInformation?.activeSection) {
      return claimPageState?.claimTabsInformation?.activeSection;
    }
    return ClaimLandingTabEnums.LANDING.toString();
  };

  const actionsForTabs = () => {
    const claimClaimant =
      responseGet.axiosResponse?.data?.claimLandingClaimant?.fullNameForTab ??
      "";
    const existentTab = getByInsuredIdAndClaimNumber(insuredId, _claimNumber);
    // CASE 1: If it is a new claim that the URL was copied and pasted.
    if (!conditionHasValue(existentTab)) {
      const newTabConfig: TabConfigProps = {
        tabKey:
          getQueryValue(OPEN_CLAIM_INSIDE_INSURED) === "1"
            ? tabKey
            : crypto.randomUUID(),
        insuredId: insuredId,
        claimNumber: _claimNumber,
        title: `${_claimNumber} - ${claimClaimant}`,
      };
      setStorageOpenTabs(newTabConfig);
    }

    // CASE 2: If it is a new claim that was opened from the Home page (Recents)
    if (
      conditionHasValue(existentTab) &&
      !conditionHasValue(existentTab?.title)
    ) {
      const newTabConfig: TabConfigProps = {
        ...existentTab,
        title: `${_claimNumber} - ${claimClaimant}`,
      };
      setStorageOpenTabs(newTabConfig);
    }
    setComponentTriggers(["tabTitleComponent"]);

    // CASE 3: If there is an existent claim but it was opened inside of an insured page.
    if (
      conditionHasValue(existentTab) &&
      conditionHasValue(existentTab?.title) &&
      getQueryValue(OPEN_CLAIM_INSIDE_INSURED) === "1"
    ) {
      const newTabConfig: TabConfigProps = {
        tabKey: tabKey,
        insuredId: insuredId,
        claimNumber: _claimNumber,
        title: `${_claimNumber} - ${claimClaimant}`,
      };
      setStorageOpenTabs(newTabConfig);
    }
  };

  useEffect(() => {
    if (_claimNumber !== undefined && _claimNumber !== "") {
      dispatchGet();

      addQueryStrings([
        {
          nameOfHash: CLAIM_NUMBER,
          valueOfHash: _claimNumber,
        },
      ]);
    }
  }, [_claimNumber, renderListener]);

  useEffect(() => {
    setAtom({
      ...getAtom(),
      currentClaim: {
        ...getAtom()?.currentClaim,
        claimNumber: _claimNumber,
      },
      claimTabsInformation: {
        ...getAtom()?.claimTabsInformation,
        activeSection: getActiveSection(),
      },
    });

    return () => {
      removeQueryStrings([CLAIM_NUMBER]);
      setComponentTriggers(["tabTitleComponent"]);
    };
  }, []);
  const setClaimFlagsDataAtom = useSetRecoilState(ClaimFlagsDataAtom);

  const setClaimFlagsData = () => {
    setClaimFlagsDataAtom(
      responseGet.axiosResponse?.data.claimFlagsData ?? null
    );
  };
  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      actionsForTabs();
      setClaimFlagsData();
    }
  }, [responseGet]);

  return responseGet.requestInstanceSuccessful &&
    responseGet.axiosResponse?.data ? (
    <div
      id={"claim_landing_main_wrapper"}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <ClaimLandingBanner claimNumber={_claimNumber} tabKey={tabKey} />
      {renderClaimLandingBody(
        insuredId,
        _claimNumber,
        getActiveSection(),
        tabKey,
        responseGet.axiosResponse?.data.claimId,
        responseGet.axiosResponse?.data.claimLandingDetails,
        responseGet.axiosResponse?.data.claimLandingClaimant,
        responseGet.axiosResponse?.data.jurisdiction
      )}
    </div>
  ) : (
    <div>LOADING...</div>
  );
};

export default ClaimLandingMain;
