import { useEffect, useState } from "react";
import RecentsContent from "./RecentsContent";
import { useApiGet } from "../../hooks";
import { useRecoilState } from "recoil";
import { RecentsRefreshAtom } from "./RecentsAtoms";
import { sortByDate } from "../../utilities/dateFunctions";

const Recents = () => {
  const [recents, setRecents] = useState<[]>();
  const [refreshPage, setRefreshPage] = useRecoilState(RecentsRefreshAtom);

  const { responseGet, dispatchGet } = useApiGet<any>(
    "api/recents/getrecentspage"
  );

  useEffect(() => {
    if (responseGet?.responseData) {
      setRecents(responseGet?.responseData?.recents);
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (refreshPage) {
      dispatchGet();
      setRefreshPage(false);
    }
  }, [refreshPage]);

  return <RecentsContent recents={recents ? recents.sort(sortByDate) : []} />;
};

export default Recents;
