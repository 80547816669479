const CvvInstructions = () => {
  return (
    <p style={{ textAlign: "justify", fontSize: "12px", lineHeight: "14px" }}>
      <strong>Visa, MasterCard, Discover:</strong> Security code is a 3-digit
      number on the back of the card to the right of the signature area.{" "}
      <strong>American Express:</strong> Security code is the 4-digit number on
      the front of the card above the card number.
    </p>
  );
};
export default CvvInstructions;
