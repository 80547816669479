import { FC, useEffect } from "react";
import {
  Col,
  Input,
  InputPhone,
  MultiSelect,
  Row,
  Select,
  Switch,
} from "../../../TrueUI";
import { rowWithNoHorizontalMarginAndGutter } from "../../../TrueUI/Grids/Row";
import { FormInsuredContactProps } from "./TypesInsuredContacts";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../utilities/inputPropsConfigurationFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";
import { SelectOptions } from "../../../../dtos/select-options";

const FormInsuredContact: FC<FormInsuredContactProps> = ({
  contact,
  setContact,
  error,
  tags,
}) => {
  const contactStatusOptions: Partial<SelectOptions>[] = [
    { displayName: "Active", intValue: StatusEnums.ACTIVE },
    { displayName: "Inactive", intValue: StatusEnums.INACTIVE },
  ];

  useEffect(() => {
    if (typeof contact?.contactTag === "string") {
      const tagsObject = JSON.parse(contact?.contactTag);
      const tagsId = tagsObject?.map((tag) => tag?.Id) ?? [];
      const tagsSelected: any = tags?.filter((tag) =>
        tagsId?.includes(tag?.intValue)
      );

      setContact({
        ...contact,
        contactTagForInput: tagsSelected,
      });
    }
  }, [contact?.contactTag]);

  return (
    <Row {...rowWithNoHorizontalMarginAndGutter} allowWrap>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Input
          tabIndex={1}
          {...inputConfiguration(
            "id-insured-contact-first-name",
            "insuredContactFirstName",
            "First Name",
            50
          )}
          value={contact?.contactFirstName}
          onChangeRawValue={(value) =>
            setContact({
              ...contact,
              contactFirstName: value === null ? "" : value,
            })
          }
          errorMessage={error?.contactFirstName}
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Input
          tabIndex={2}
          {...inputConfiguration(
            "id-insured-contact-last-name",
            "insuredContactLastName",
            "Last Name",
            50
          )}
          value={contact?.contactLastName}
          onChangeRawValue={(value) =>
            setContact({
              ...contact,
              contactLastName: value === null ? "" : value,
            })
          }
          errorMessage={error?.contactLastName}
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Input
          tabIndex={3}
          {...inputConfiguration(
            "id-insured-contact-title",
            "insuredContactTitle",
            "Title",
            50
          )}
          value={contact?.contactTitle}
          onChangeRawValue={(value) =>
            setContact({
              ...contact,
              contactTitle: value === null ? "" : value,
            })
          }
          errorMessage={error?.contactTitle}
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Input
          tabIndex={4}
          {...inputConfiguration(
            "id-insured-contact-email",
            "insuredContactEmail",
            "Email",
            100
          )}
          value={contact?.email}
          onChangeRawValue={(value) =>
            setContact({
              ...contact,
              email: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
          errorMessage={error?.email}
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Row {...rowWithNoHorizontalMarginAndGutter}>
          <Col breakpoints={{ md: 8, lg: 8, xl: 9 }}>
            <InputPhone
              tabIndex={5}
              id="id-insured-contact-phone"
              name="insuredContactPhone"
              phoneLabel="Phone"
              phoneValue={contact?.primaryPhone}
              onChangePhoneValue={(value) =>
                setContact({
                  ...contact,
                  primaryPhone: value,
                })
              }
              labelFontType={"BOLD_CAPTION"}
              errorMessagePhone={error?.primaryPhone}
            />
          </Col>
          <Col breakpoints={{ md: 4, lg: 4, xl: 3 }}>
            <Input
              tabIndex={6}
              {...inputConfiguration(
                "id-insured-contact-primary-phone-add",
                "insuredContactPrimaryPhoneAdd",
                "Ext",
                10
              )}
              type={"number"}
              thousandSeparator={false}
              value={contact?.primaryPhoneAdd}
              onChangeRawValue={(value) =>
                setContact({
                  ...contact,
                  primaryPhoneAdd: value.toString(),
                })
              }
              labelFontType={"BOLD_CAPTION"}
              errorMessage={error?.primaryPhoneAdd}
            />
          </Col>
        </Row>
      </Col>

      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Row {...rowWithNoHorizontalMarginAndGutter}>
          <Col breakpoints={{ md: 8, lg: 8, xl: 9 }}>
            <Select
              tabIndex={7}
              {...selectConfiguration(
                "id-insured-contact-status",
                "insuredContactStatus",
                "Status"
              )}
              options={contactStatusOptions}
              value={contact?.contactStatus ?? StatusEnums.ACTIVE}
              onChange={(value) =>
                setContact({
                  ...contact,
                  contactStatus: value,
                })
              }
              labelFontType={"BOLD_CAPTION"}
              errorMessage={error?.contactStatus}
            />
          </Col>
          <Col breakpoints={{ md: 4, lg: 4, xl: 3 }}>
            <Switch
              tabIndex={8}
              control={"checkbox"}
              isChecked={contact?.primaryContact ?? false}
              label={"Primary"}
              labelPlacement={"top"}
              onChangeIsChecked={(value) =>
                setContact({ ...contact, primaryContact: value })
              }
              labelFontType={"BOLD_CAPTION"}
              labelAlignment={"center"}
              labelStyle={{
                display: "inline-flex",
                margin: "0 auto",
              }}
              inputWidth={"auto"}
              name={"insuredContactPrimaryContact"}
              readOnly={contact?.contactStatus === StatusEnums.INACTIVE}
            />
          </Col>
        </Row>
      </Col>
      <Col displayFlex={false}>
        <MultiSelect
          tabIndex={9}
          {...selectConfiguration(
            "id-insured-contact-tags",
            "insuredContactTags",
            "Tags"
          )}
          options={tags}
          values={contact?.contactTagForInput ?? []}
          onChange={(value) =>
            setContact({
              ...contact,
              contactTagForInput: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
          errorMessage={error?.contactTag}
          inputWidth="100%"
        />
      </Col>
    </Row>
  );
};

export default FormInsuredContact;
