import { FC, useEffect } from "react";
import { useComputeProcessor } from "../Hooks/useComputeProcessor_LEGACY";
import { useRecoilState } from "recoil";
import {
  GridCellManagerInstanceAtomFamily,
  interceptChangeAtomFamily,
} from "../BaseGridAtoms";

const BaseGridCellComputeChangeProcessor: FC<any> = ({
  uiid,
  rowKey,
  row,
  listOfChangeComputeFunctions,
}) => {
  const [cellAtomInstance, setCellAtomInstance] = useRecoilState(
    GridCellManagerInstanceAtomFamily(rowKey)
  );

  const [interceptedChange, _setInterceptedChange] = useRecoilState(
    interceptChangeAtomFamily(rowKey ?? "NO_CELLKEY_FOUND")
  );

  const { processComputes, isComplete, resetIsCompleteStatus } =
    useComputeProcessor(
      uiid,
      rowKey,
      row,
      listOfChangeComputeFunctions,
      "computeOnChange"
    );

  useEffect(() => {
    if (
      listOfChangeComputeFunctions
        .map((x: any) => x.columnIndex)
        .includes(interceptedChange?.columnIndex ?? -1)
    ) {
      setCellAtomInstance({
        ...cellAtomInstance,
        readyToRender: false,
        hasOnChangeComputeCompleted: false,
      });
    }
  }, [interceptedChange]);

  useEffect(() => {
    if (
      cellAtomInstance.readyToRender === false &&
      cellAtomInstance.hasOnChangeComputeCompleted === false
    ) {
      processComputes(
        interceptedChange?.cellKey ?? "NO_CELLKEY_FOUND",
        interceptedChange?.columnIndex ?? -1
      );
    }
  }, [cellAtomInstance.readyToRender]);

  useEffect(() => {
    if (isComplete) {
      setCellAtomInstance({
        ...cellAtomInstance,
        readyToRender: true,
        hasOnChangeComputeCompleted: true,
      });
      resetIsCompleteStatus();
    }
  }, [isComplete]);

  return null;
};

export default BaseGridCellComputeChangeProcessor;
