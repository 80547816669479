import { BaseTableColumn } from "../../../../../../../dtos/base-table-column";
import { currencyFormat } from "../../../../../../../utilities/currencyFunctions";
import {
  getNumberFromCurrencyFormat,
  parseFloatValueIfExist,
} from "../../../../../../../utilities/stringFunctions";
import InputWithIcon from "../../../../../../TrueUI/Inputs/InputWithIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { ReserveWithCategoryDto } from "../../../../../../../dtos/reserve-with-category-dto";
import style from "./ReserveWorksheet.module.css";
import { validateMaxAndMinNumberValue } from "../../../../../../../utilities/mathFunctions";
import { Font } from "../../../../../../TrueUI";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../../../TrueUI/Tables/tableFunctions.ts";

export const onQtyChange = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  return {
    value: validateMaxAndMinNumberValue(
      Number(options?.currentValue ?? 0),
      999,
      -999
    ),
    triggerComputeFieldNames: ["ReserveAmount"],
  } as ConditionForCellResponse;
};

export const onAmountChange = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  return {
    value: validateMaxAndMinNumberValue(
      Number(options?.currentValue ?? 0),
      9999999.99,
      -9999999.99
    ),
    triggerComputeFieldNames: ["ReserveAmount"],
  } as ConditionForCellResponse;
};

export const onReserveAmountChange = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  const qty = parseFloatValueIfExist(options?.row?.Qty) ?? 0;
  const amount = parseFloatValueIfExist(options?.row?.Amount) ?? 0;
  const reserveAmount = currencyFormat(qty * amount, false, 2, true);
  return {
    value: reserveAmount,
  } as ConditionForCellResponse;
};

export const getAmountTotalByFieldName = (
  data: string[][],
  columns: BaseTableColumn[],
  fieldName: string
) => {
  const index = getDataIndexByColumnNameWithoutExternal(columns, fieldName);

  if (index !== -1) {
    const amountList =
      data?.map(
        (row) =>
          parseFloatValueIfExist(getNumberFromCurrencyFormat(row[index])) ?? 0
      ) ?? [];
    return amountList?.length > 0
      ? amountList?.reduce((sum, value) => sum + value, 0)
      : 0;
  }
  return 0;
};

const hydrateReserve = (row: string[]) => {
  const reserve: ReserveWithCategoryDto = {
    paymentCategoryId: parseInt(row[6] ?? 0),
    paymentCategoryName: row[7] ?? "",
    outstandingReserve: parseFloatValueIfExist(row[8]) ?? 0,
    qty: parseInt(row[9] ?? 0),
    amount: parseFloatValueIfExist(row[10]) ?? 0,
    reserveAmount: parseFloatValueIfExist(row[11]) ?? 0,
  };
  return reserve;
};

export const updateReserves = (
  data: string[][],
  columns: BaseTableColumn[]
) => {
  const reserves: ReserveWithCategoryDto[] =
    data?.length > 0 && data?.[0].length === columns?.length
      ? data?.map((row) => hydrateReserve(row)) ?? []
      : [];
  return reserves;
};

export const getReserveStatus = (
  totalReserveAmount,
  reserveLimit,
  totalReserveLimit,
  totalClaimReserve
) => {
  if (totalReserveAmount > (reserveLimit ?? 0)) {
    return false;
  }
  const newTotal = totalReserveAmount + totalClaimReserve;
  if (newTotal > (totalReserveLimit ?? 0)) {
    return false;
  }
  return true;
};

export const ReserveWorksheetCustomFooter = (
  totalReserveAmount: number,
  tableName: string,
  reserveLimit: number,
  totalReserveLimit: number,
  totalClaimReserve: number,
  isEditMode: boolean
) => {
  return (
    <div className={style.reserve_totals_wrapper}>
      <InputWithIcon
        id={`${tableName}_total`}
        name={`${tableName}_total`}
        type="fixedCurrency"
        readOnly
        value={totalReserveAmount}
        align="right"
        inputFontType="BOLD_BODY"
        maxNumericValue={reserveLimit}
        minNumericValue={0}
        icon={<ErrorOutlineIcon fontSize="small" />}
        iconColor=""
        showIcon={
          isEditMode
            ? !getReserveStatus(
                totalReserveAmount,
                reserveLimit,
                totalReserveLimit,
                totalClaimReserve
              )
            : false
        }
        allowNegatives
      />
    </div>
  );
};

export const ReserveWorksheetCustomHeader = (
  title: string,
  totalOutstanding: number
) => {
  return (
    <div className={style.reserve_header}>
      <Font fontType={"BOLD_BODY"}>{title}</Font>
      <Font fontType={"BOLD_BODY"}>
        {currencyFormat(totalOutstanding, true, 2, true)}
      </Font>
    </div>
  );
};
