import { FC, useEffect, useState } from "react";
import {
  Col,
  Font,
  Input,
  InputDate,
  Row,
  Select,
  Switch,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import {
  ModalClaimFinancialReserveProps,
  ModalEditReservesInfo,
  colConfiguration,
} from "./ClaimFinancialUtil";
import { ReserveConfigurationDto } from "../../../../../../dtos/reserve-configuration-dto";
import { useApiGet, usePermissions } from "../../../../../../hooks";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { ReserveOptionsDto } from "../../../../../../dtos/reserve-options-dto";
import InputMemo from "../../../../../TrueUI/Inputs/InputMemo";
import { getIntValueByStringNameFromClaimReference } from "./ClaimFinancialUtil";
import { ReserveStatusEnum } from "../../../../../../dtos/reserve-status-enum";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import IconButton from "../../../../../TrueUI/Buttons/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ViewReserveWorksheetModal from "./ReservesSubTable/ViewReserveWorksheetModal";

const textFieldVariant = "filled";

const ModalClaimFinancialReserveContent: FC<
  ModalClaimFinancialReserveProps
> = ({
  reserveInformation,
  reserveId,
  claimId,
  errorDetails,
  setReserveInformation,
  setSaveDisabled,
  userReserveLimit,
  checkLimit = false,
}) => {
  const [reserveOptions, setReserveOptions] = useState<ReserveOptionsDto>();
  const isAddNewReserve = reserveId === 0;
  const permissions = usePermissions([13, 14, 15]);
  const isDisabled =
    reserveInformation?.statusId !== ReserveStatusEnum.APPROVED ||
    (reserveInformation?.amount ?? 0) < 0 ||
    !permissions.hasPermission;

  const checkReadOnly = () => {
    if (checkLimit === false) {
      return false;
    }
    const readOnly =
      reserveInformation?.statusId === ReserveStatusEnum.APPROVED &&
      !isAddNewReserve;
    if (checkLimit) {
      const userHasLimit =
        conditionHasValue(userReserveLimit) && userReserveLimit > 0;
      const amountSurpasesLimit =
        (userHasLimit &&
          (reserveInformation?.amount ?? 0) > userReserveLimit) ||
        !userHasLimit;
      return amountSurpasesLimit || readOnly;
    }
    return readOnly;
  };

  const [modalEditReservesInfo, setModalEditReservesInfo] =
    useState<ModalEditReservesInfo>({
      readOnly: checkReadOnly(),
      reallocateReservesDisabled: isDisabled,
      reallocateReservesError: false,
      showWorksheet: false,
      worksheetIsOpen: false,
    });

  const { responseGet: responseGetReserve, dispatchGet: dispatchGetReserve } =
    useApiGet<ReserveConfigurationDto>(
      `api/Reserve/GetReserve?reserveId=${reserveId}&claimId=${claimId}`
    );

  useEffect(() => {
    setReserveInformation?.(undefined);
    dispatchGetReserve();
  }, []);

  useEffect(() => {
    if (
      !responseGetReserve.isLoading &&
      conditionHasValue(responseGetReserve.axiosResponse?.data)
    ) {
      const reserveInformationData =
        responseGetReserve.axiosResponse?.data?.reserveInformation;
      setReserveInformation?.({
        reserveId: reserveId,
        claimId: claimId,
        date: reserveInformationData?.date,
        typeId: reserveInformationData?.typeId,
        typeName: reserveInformationData?.typeName,
        statusId: reserveInformationData?.statusId,
        amount: reserveInformationData?.amount,
        comments: reserveInformationData?.comments,
        workshetId: reserveInformationData?.workshetId,
      });
      setReserveOptions(responseGetReserve.axiosResponse?.data?.reserveOptions);

      setModalEditReservesInfo({
        readOnly: checkReadOnly(),
        reallocateReservesDisabled:
          reserveInformationData?.statusId !== ReserveStatusEnum.APPROVED ||
          (reserveInformationData?.amount ?? 0) < 0 ||
          !permissions.hasPermission,
        reallocateReservesError: false,
        showWorksheet:
          reserveId > 0 &&
          reserveInformationData?.statusId === ReserveStatusEnum.APPROVED &&
          conditionHasValue(reserveInformationData?.workshetId),
        worksheetIsOpen: false,
      });
    }
  }, [responseGetReserve]);

  useEffect(() => {
    setModalEditReservesInfo({
      ...modalEditReservesInfo,
      readOnly: checkReadOnly(),
    });
  }, [reserveInformation?.amount]);

  const checkReallocateReserves = () => {
    const reallocateReservesAmount =
      (reserveInformation?.reallocateReservesList?.expenseAmount ?? 0) +
      (reserveInformation?.reallocateReservesList?.indemnityAmount ?? 0) +
      (reserveInformation?.reallocateReservesList?.medicalAmount ?? 0);

    if (reallocateReservesAmount !== reserveInformation?.amount) {
      setSaveDisabled?.(true);
      setModalEditReservesInfo({
        ...modalEditReservesInfo,
        reallocateReservesError: true,
      });
    }
    if (reallocateReservesAmount === reserveInformation?.amount) {
      setSaveDisabled?.(false);
      setModalEditReservesInfo({
        ...modalEditReservesInfo,
        reallocateReservesError: false,
      });
    }
  };

  useEffect(() => {
    if (reserveInformation?.reallocateReserves) {
      checkReallocateReserves();
    }
  }, [reserveInformation?.reallocateReservesList]);

  useEffect(() => {
    if (!reserveInformation?.reallocateReserves) setSaveDisabled?.(false);
    if (reserveInformation?.reallocateReserves) checkReallocateReserves();
  }, [reserveInformation?.reallocateReserves]);

  const getReserveTypeName = (value) => {
    return reserveOptions?.reserveTypes?.find(
      (reserveType) => reserveType.intValue === value
    )?.displayName;
  };

  const onChangeStatusIfItIsNewReserve = (amount: number) => {
    if (reserveId !== 0) return reserveInformation?.statusId;

    const reserveStatusList =
      responseGetReserve?.axiosResponse?.data?.reserveStatusList ?? [];

    if (amount > Number(reserveOptions?.reserveRuleLimit)) {
      return getIntValueByStringNameFromClaimReference(
        "Pending Approval",
        reserveStatusList
      );
    } else {
      const newTotal = amount + (reserveOptions?.totalClaimReserves ?? 0);
      if (newTotal > Number(reserveOptions?.totalReserveRuleLimit)) {
        return getIntValueByStringNameFromClaimReference(
          "Pending Approval",
          reserveStatusList
        );
      }
      return getIntValueByStringNameFromClaimReference(
        "Approved",
        reserveStatusList
      );
    }
  };

  return isAPITotallyComplete(responseGetReserve) ? (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colConfiguration}>
          <InputDate
            tabIndex={1}
            id="id-reserve-date"
            name="reserveDate"
            label="Reserve Date"
            readOnly={modalEditReservesInfo.readOnly}
            variant={textFieldVariant}
            value={reserveInformation?.date}
            errorMessage={errorDetails?.date}
            onChangeRawValue={(value) =>
              setReserveInformation?.({
                ...reserveInformation,
                date: value,
              })
            }
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={2}
            {...selectConfiguration(
              "id-reserve-type",
              "reserveType",
              "Reserve Type",
              false
            )}
            readOnly={modalEditReservesInfo.readOnly}
            variant={textFieldVariant}
            labelFontType="BOLD_CAPTION"
            options={reserveOptions?.reserveTypes ?? []}
            value={reserveInformation?.typeId}
            onChange={(value) => {
              setReserveInformation?.({
                ...reserveInformation,
                typeId: value,
                typeName: getReserveTypeName(value),
              });
            }}
            firstOptionAsDefault={false}
            errorMessage={errorDetails?.typeName}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={3}
            {...selectConfiguration(
              "id-reserve-status",
              "reserveStatus",
              "Reserve Status",
              isAddNewReserve
            )}
            readOnly={modalEditReservesInfo.readOnly}
            variant={textFieldVariant}
            labelFontType="BOLD_CAPTION"
            options={reserveOptions?.reserveStatus ?? []}
            value={reserveInformation?.statusId}
            onChange={(value) => {
              setReserveInformation?.({
                ...reserveInformation,
                statusId: value,
              });
            }}
          />
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalAlign="flex-start"
        horizontalAlign="flex-start"
      >
        <Col {...colConfiguration} verticalAlign="flex-end">
          <Input
            tabIndex={4}
            id="id-reserve-amount"
            name="reserveAmount"
            label="Reserve Amount"
            readOnly={modalEditReservesInfo.readOnly || checkLimit}
            variant={textFieldVariant}
            value={reserveInformation?.amount}
            maxNumericValue={99999999.99}
            inputFontType="BODY"
            allowNegatives={true}
            type={"fixedCurrency"}
            errorMessage={errorDetails?.amount}
            onChangeRawValue={(value) => {
              setReserveInformation?.({
                ...reserveInformation,
                amount: value,
                statusId: onChangeStatusIfItIsNewReserve(value),
              });
            }}
          />
        </Col>
        <Col
          {...colConfiguration}
          verticalAlign="flex-start"
          horizontalAlign="flex-start"
        >
          {modalEditReservesInfo.showWorksheet && (
            <Row
              rowDirection="column"
              verticalGutter="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              horizontalMargin="0px"
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
            >
              <Font fontType="BOLD_CAPTION">View Worksheet</Font>
              <IconButton
                onClick={() =>
                  setModalEditReservesInfo({
                    ...modalEditReservesInfo,
                    worksheetIsOpen: true,
                  })
                }
              >
                <OpenInNewIcon />
              </IconButton>
            </Row>
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colConfiguration} breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputMemo
            tabIndex={5}
            id="id-reserve-comments"
            name="reserveComments"
            label="Comments"
            readOnly={modalEditReservesInfo.readOnly}
            variant={textFieldVariant}
            value={reserveInformation?.comments}
            errorMessage={errorDetails?.comments}
            onChangeRawValue={(value) => {
              setReserveInformation?.({
                ...reserveInformation,
                comments: value,
              });
            }}
          />
        </Col>
      </Row>
      {!isAddNewReserve && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col {...colConfiguration} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
            <Switch
              tabIndex={6}
              control="checkbox"
              label="Reallocate Reserves"
              readOnly={modalEditReservesInfo.reallocateReservesDisabled}
              isChecked={reserveInformation?.reallocateReserves ?? false}
              onChangeIsChecked={(value) =>
                setReserveInformation?.({
                  ...reserveInformation,
                  reallocateReserves: value,
                })
              }
            />
          </Col>
          {reserveInformation?.reallocateReserves && (
            <Col {...colConfiguration} breakpoints={{ md: 8, lg: 8, xl: 8 }}>
              <Font whiteSpace="normal" fontType="CAPTION" textAlign="start">
                The original reserve will be reversed and new reserve entries
                will be made for the amounts you enter below. The total must
                match the original amount.
              </Font>
            </Col>
          )}
        </Row>
      )}
      {reserveInformation?.reallocateReserves && (
        <>
          <Row
            horizontalGutter="25px"
            verticalGutter="0px"
            horizontalMargin="0px"
            verticalMargin="0px"
          >
            <Col
              horizontalGutter="5px"
              breakpoints={{ md: 3, lg: 3, xl: 3 }}
              verticalAlign="flex-end"
              colHeight="56px"
            >
              <Font>Expense</Font>
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={7}
                id="id-reserve-amount-expense"
                name="reserveAmountExpense"
                label="Reserve Amount"
                variant={textFieldVariant}
                value={
                  reserveInformation?.reallocateReservesList?.expenseAmount
                }
                maxNumericValue={99999999.99}
                inputFontType="BODY"
                allowNegatives={true}
                type={"fixedCurrency"}
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      expenseAmount: value,
                    },
                  });
                }}
              />
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 5, lg: 5, xl: 5 }}>
              <Input
                tabIndex={8}
                id="comments-expense"
                name="commentsExpense"
                label="Comments"
                variant={textFieldVariant}
                maxLength={200}
                value={
                  reserveInformation?.reallocateReservesList?.expenseComments
                }
                inputFontType="BODY"
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      expenseComments: value,
                    },
                  });
                }}
                errorMessage={errorDetails?.expenseComments}
              />
            </Col>
          </Row>
          <Row
            horizontalGutter="25px"
            verticalGutter="0px"
            horizontalMargin="0px"
            verticalMargin="0px"
          >
            <Col
              horizontalGutter="5px"
              breakpoints={{ md: 3, lg: 3, xl: 3 }}
              verticalAlign="flex-end"
              colHeight="56px"
            >
              <Font>Medical</Font>
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={9}
                id="id-reserve-amount-medical"
                name="reserveAmountMedical"
                label="Reserve Amount"
                variant={textFieldVariant}
                value={
                  reserveInformation?.reallocateReservesList?.medicalAmount
                }
                maxNumericValue={99999999.99}
                inputFontType="BODY"
                allowNegatives={true}
                type={"fixedCurrency"}
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      medicalAmount: value,
                    },
                  });
                }}
              />
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 5, lg: 5, xl: 5 }}>
              <Input
                tabIndex={10}
                id="comments-medical"
                name="commentsMedical"
                label="Comments"
                variant={textFieldVariant}
                maxLength={200}
                value={
                  reserveInformation?.reallocateReservesList?.medicalComments
                }
                inputFontType="BODY"
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      medicalComments: value,
                    },
                  });
                }}
                errorMessage={errorDetails?.medicalComments}
              />
            </Col>
          </Row>
          <Row
            horizontalGutter="25px"
            verticalGutter="0px"
            horizontalMargin="0px"
            verticalMargin="0px"
          >
            <Col
              horizontalGutter="5px"
              breakpoints={{ md: 3, lg: 3, xl: 3 }}
              verticalAlign="flex-end"
              colHeight="56px"
            >
              <Font>Indemnity</Font>
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={11}
                id="id-reserve-amount-indemnity"
                name="reserveAmountIndemnity"
                label="Reserve Amount"
                variant={textFieldVariant}
                value={
                  reserveInformation?.reallocateReservesList?.indemnityAmount
                }
                maxNumericValue={99999999.99}
                inputFontType="BODY"
                allowNegatives={true}
                type={"fixedCurrency"}
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      indemnityAmount: value,
                    },
                  });
                }}
              />
            </Col>
            <Col {...colConfiguration} breakpoints={{ md: 5, lg: 5, xl: 5 }}>
              <Input
                tabIndex={12}
                id="comments-indemnity"
                name="commentsIndemnity"
                label="Comments"
                variant={textFieldVariant}
                maxLength={200}
                value={
                  reserveInformation?.reallocateReservesList?.indemnityComments
                }
                inputFontType="BODY"
                onChangeRawValue={(value) => {
                  setReserveInformation?.({
                    ...reserveInformation,
                    reallocateReservesList: {
                      ...reserveInformation?.reallocateReservesList,
                      indemnityComments: value,
                    },
                  });
                }}
                errorMessage={errorDetails?.indemnityComments}
              />
            </Col>
          </Row>
          {modalEditReservesInfo.reallocateReservesError && (
            <Row
              horizontalGutter="25px"
              verticalGutter="0px"
              horizontalMargin="0px"
              verticalMargin="0px"
            >
              <Col
                {...colConfiguration}
                breakpoints={{ md: 12, lg: 12, xl: 12 }}
                horizontalAlign="center"
              >
                <Font isErrorFont whiteSpace="normal">
                  The new sum must match the original reserve amount.
                </Font>
              </Col>
            </Row>
          )}
        </>
      )}
      <ViewReserveWorksheetModal
        worksheetId={reserveInformation?.workshetId}
        claimId={claimId}
        isOpen={modalEditReservesInfo.worksheetIsOpen}
        setIsOpen={(open) =>
          setModalEditReservesInfo({
            ...modalEditReservesInfo,
            worksheetIsOpen: open,
          })
        }
      />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default ModalClaimFinancialReserveContent;
