import { Theme } from "../../../media/themeTypes";

export const insuredDrawerStyle = (theme: Theme) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  pt: 1,
  borderLeft: `1px solid ${theme?.BORDER_COLOR}`,
  ".true_drawer_icon": {
    color: theme?.TEXT_COLOR,
    fontSize: theme?.HEADING_5.SIZE,
    width: "32px",
    height: "32px",
  },
});

export const insuredDrawerContentStyle = () => ({
  display: "flex",
  height: "100%",
  overflowY: "hidden !important",
});
