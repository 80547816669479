import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BasePageContainer, Select, BaseTable2 } from "../../../TrueUI";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { AdminProgramAtom } from "../ProgramAtoms";

const tableName = "base_table_class_codes_table";

const ClassCodes = () => {
  const programAdminAtom = useRecoilValue(AdminProgramAtom);
  const [lineOfCoverage, setLineOfCoverage] = useState("WC");

  const lineTypes: Partial<SelectOptions>[] =
    programAdminAtom?.configuration?.lineOfCoverage.map((obj) => ({
      displayName: obj.description,
      stringValue: obj.lineType,
    }));

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/AdminTools/RateTables/GetClassCodes?lineType=${lineOfCoverage}`,
    postURL: `api/AdminTools/RateTables/SaveClassCodes`,
    postTarget: "ChangedClassCodes",
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["Status"],
          defaultValue: StatusEnums.ACTIVE.toString(),
        }),
    ],
    advancedOptions: { paginate: false },
    toolbarOptions: { showSortFilter: false, showImportButton: false },
  };

  useBaseTable(tableConfiguration);

  return (
    <>
      <Box display={"flex"} alignItems={"center"} sx={{ my: 1.5 }}>
        <Typography variant="caption" sx={{ mr: 4, fontWeight: "500" }}>
          Line of Coverage
        </Typography>
        <Box sx={{ width: "20%" }}>
          <Select
            id="select-line-of-coverage"
            name="lineOfCoverageClassCode"
            tabIndex={1}
            labelTextAlign={"center"}
            options={lineTypes as any}
            value={"WC"}
            onChange={(value) => {
              setLineOfCoverage(value);
            }}
          />
        </Box>
      </Box>

      <BasePageContainer id={"admin_tools_class_codes"} maxWidth={false} data>
        <BaseTable2 name={tableName} />
      </BasePageContainer>
    </>
  );
};

export default ClassCodes;
