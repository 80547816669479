import { FC } from "react";
import { Box } from "@mui/material";
import { Caption } from "../TrueUI";

type ActivityItemProps = {
  title: string;
  date: string;
  user: string;
};

const ActivityItem: FC<ActivityItemProps> = ({ title, date, user }) => {
  return (
    <Box className="activity-item">
      <Box className="step-container">
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Caption name="activityDateName">
        {date?.toUpperCase()} - {user}
      </Caption>
    </Box>
  );
};
export default ActivityItem;
