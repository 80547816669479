import { FC } from "react";
import style from "../Styles/basegridcells.module.css";

type DefaultCurrencyCellProperties = {
  value: string;
};
const DefaultCurrencyCell: FC<DefaultCurrencyCellProperties> = ({ value }) => {
  const convertedValue = parseInt(value ?? "0") ?? 0;
  const formattedValue =
    convertedValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) ?? null;
  return (
    <div className={style.default_currency_render_only_cell}>
      {convertedValue !== 0 ? formattedValue : ""}
    </div>
  );
};

export default DefaultCurrencyCell;
