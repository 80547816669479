import { FC, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  SimulateUserUpdatedAtom,
  UserViewInformationAtom,
} from "../AdminSecurityAtoms";
import { useParams } from "react-router";
import { useApiGet } from "../../../../hooks";
import { Divider, Heading6, Select } from "../../../TrueUI";
import { globalOptions } from "../../../../GlobalAtoms";
import { StatusEnums } from "../../../../dtos/status-enums";
import { SelectOptions } from "../../../../dtos/select-options";
import UserForm from "./UserForm";
import UserAccessRights from "./UserAccessRights";
import UserPermissions from "./UserPermissions";
import UserCommand from "./UserCommand";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const AdminSecurityUser: FC = () => {
  const { userId } = useParams();
  const userIdValid = parseInt(userId ?? "0");
  const userInfoView = useRecoilValue(UserViewInformationAtom);

  const [currentUserId, setCurrentUserId] = useState<number>(
    userInfoView?.userId ?? userIdValid
  );
  const [userOptions, setUserOptions] = useState<SelectOptions[]>([]);

  const localOptions = useRecoilValue(globalOptions);
  const tenantId = userInfoView?.tenantId ?? localOptions?.tenantId;
  const programId =
    userInfoView?.programId ?? localOptions?.selectedProgram?.programId;
  const email = userInfoView?.newUserName ?? "";
  const status = userInfoView?.status ?? StatusEnums?.ACTIVE;
  const isNewUser = userIdValid == 0;

  const [userUpdatedValue, setUserUpdated] = useRecoilState(
    SimulateUserUpdatedAtom
  );

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/AdminTools/Security/UserListing/GetUserList?customerId=${tenantId}&programId=${programId}&status=${status}&isNewUser=${isNewUser}`
  );

  useEffect(() => {
    if (conditionHasValue(userUpdatedValue)) {
      const newUserOptions = userOptions.map((user) =>
        user.intValue === userUpdatedValue.userId
          ? { ...user, displayName: userUpdatedValue.longName }
          : user
      );
      setUserOptions(newUserOptions);
      setUserUpdated(null);
    }
  }, [userUpdatedValue]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setUserOptions(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    setCurrentUserId(userIdValid);
    dispatchGet();
  }, [userIdValid]);

  return (
    <div
      id={"user_container"}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "20px",
      }}
    >
      <Heading6 textAlign="start">Admin Tools - Security - User</Heading6>
      <Divider width={"90%"} showBorder borderSize={"1px"} />
      <div className={"user_form_view"}>
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Select
            id={"user-select"}
            name={"user-select"}
            options={userOptions}
            inputWidth={"500px"}
            label={"User Name"}
            labelPosition={"start"}
            labelFontType={"BOLD_CAPTION"}
            optionsMaxHeight="250px"
            value={currentUserId}
            onChange={setCurrentUserId}
            readOnly={isNewUser}
          />
        </div>
        <div className={"user-form"}>
          <UserForm
            userId={currentUserId}
            programId={programId}
            tenantId={tenantId}
            email={email}
          />
        </div>
        <div className={"access_rights_and_permissions"}>
          <UserAccessRights userId={currentUserId} tenantId={tenantId} />
          <UserPermissions tenantId={tenantId} />
        </div>
        <UserCommand userId={currentUserId} />
      </div>
    </div>
  );
};
export default AdminSecurityUser;
