import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { ProgramNarrativeConfigurationDto } from "../../../../dtos/program-narrative-configuration-dto";
import { removeHTMLTagsByRegex } from "../../../../utilities/stringFunctions";

export const deletedNoteValues = {
  formattedNarrativeText: "",
  updateBy: "",
  updateOn: undefined,
};

export const disableOrEnableDeleteButton = (
  underwriterNote?: PolicyNarrativeBlob | null
) => {
  return (
    removeHTMLTagsByRegex(underwriterNote?.formattedNarrativeText ?? "") === ""
  );
};

export const updatePolicyNarrativeNote = (
  programNarrativeList: PolicyNarrativeBlob[],
  updatedPolicyNarrativeBlob: PolicyNarrativeBlob
): PolicyNarrativeBlob[] => {
  return programNarrativeList.map((item) => {
    if (item.narrativeID === updatedPolicyNarrativeBlob?.narrativeID) {
      return {
        ...item,
        formattedNarrativeText:
          updatedPolicyNarrativeBlob.formattedNarrativeText,
        updateBy: updatedPolicyNarrativeBlob.updateBy,
        updateOn: updatedPolicyNarrativeBlob.updateOn,
      };
    } else {
      return item;
    }
  });
};

export const getPolicyNarrativeListFromConfiguration = (
  programNarrativeList: ProgramNarrativeConfigurationDto[]
): PolicyNarrativeBlob[] => {
  return programNarrativeList.map((item) => ({
    narrativeID: item.narrativeId,
    narrativeDescription: item.narrativeDescription,
    formattedNarrativeText: "",
    updateBy: "",
    updateOn: undefined,
    expanded: false,
  }));
};

export const collapseAll = (
  policyNarrativeList: PolicyNarrativeBlob[],
  isExpanded: boolean
): PolicyNarrativeBlob[] => {
  return policyNarrativeList.map((item) => ({
    ...item,
    expanded: isExpanded,
  }));
};

export const collapseItem = (
  policyNarrativeList: PolicyNarrativeBlob[],
  noteIndex: number,
  isExpanded: boolean
): PolicyNarrativeBlob[] => {
  return policyNarrativeList.map((item, index) => ({
    ...item,
    expanded: index === noteIndex ? isExpanded : item.expanded,
  }));
};
