import { FC } from "react";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2, TitleBold } from "../../../TrueUI";
import style from "./PolicyStatusHistoryStyles.module.css";

type PolicyStatusHistoryTableProps = {
  policyId: number;
  isAudit: boolean;
};
const PolicyStatusHistoryTable: FC<PolicyStatusHistoryTableProps> = ({
  policyId,
  isAudit,
}) => {
  const name = "policy_status_history_grid";
  const getURL = `api/Dashboard/GetPolicyQuoteStatusHistory?policyId=${policyId}&isAudit=${isAudit}`;

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: { hideToolbar: true },
    advancedOptions: { paginate: false },
    columnOptions: [
      { fieldName: "Timestamp", width: 25 },
      { fieldName: "Comments", width: 75 },
    ],
  };

  useBaseTable(tableConfiguration);

  return (
    <div className={style.policy_status_history_container}>
      <div>
        <TitleBold>STATUS HISTORY</TitleBold>
      </div>
      <div className={style.policy_status_history_grid_container}>
        <BaseTable2 name={name} />
      </div>
    </div>
  );
};

export default PolicyStatusHistoryTable;
