import { FC, useEffect, useState } from "react";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  LossHistoryDisplayOptionsUIProps,
  PolicyQuoteInsuredAndActiveSetterProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import LossHistoryTable from "./LossHistoryTable";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";

const LossHistoryMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  setActiveSection,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [tableDisplayOptions, setTableDisplayOptions] =
    useState<LossHistoryDisplayOptionsUIProps>();

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) {
      const configurations =
        atomValue?.policyQuoteInformation?.policyQuote?.configuration;
      setTableDisplayOptions({
        stateList:
          configurations?.stateList.map((state) => state.selectOption) ?? [],
        lossTypeList: configurations?.lossTypeList ?? [],
        claimStatusList: configurations?.claimStatusList ?? [],
      });
    } else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  return (
    <>
      {tableDisplayOptions !== undefined && tableDisplayOptions !== null ? (
        <LossHistoryTable
          tabKey={tabKey}
          insuredId={insuredId}
          tableDisplayOptions={tableDisplayOptions}
        />
      ) : null}
    </>
  );
};

export default LossHistoryMain;
