import { FC, useEffect, useState } from "react";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2, FontBold, Row } from "../../../TrueUI";
import {
  BaseTable2Properties,
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  columnsContacts,
  tableNameContacts,
} from "./InsuredInformationTableConfigurations";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import Col, { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import {
  getContactsAsTableData,
  getContactsByTableData,
} from "./InsuredInformationTableUtils";
import { PolicyInsuredContactBlob } from "../../../../dtos/policy-insured-contact-blob";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties, globalOptions } from "../../../../GlobalAtoms";
import { Acord130ConfigurationDto } from "../../../../dtos/acord130-configuration-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { errorMessageStyle } from "./InsuredInformationStyles";
import { useRecoilValue } from "recoil";
import themes from "../../../../media/TrueTheme";
import { Typography } from "@mui/material";
import { getSafeStringToBool } from "../../../../utilities/stringFunctions";

type insuredInfoContactsProps = {
  contactsConfiguration?: Acord130ConfigurationDto;
  firstName?: string;
  lastName?: string;
  errorDetails?: { [key: string]: Object };
};

const InsuredInformationContacts: FC<insuredInfoContactsProps> = ({
  contactsConfiguration,
  firstName,
  lastName,
  errorDetails,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const [insuredContacts, setInsuredContacts] =
    useState<PolicyInsuredContactBlob[]>();
  const [deletedContacts, setDeletedContacts] = useState<
    PolicyInsuredContactBlob[] | null
  >(null);

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  const getInitialTableData = (): string[][] => {
    const atomValue = getAtom();
    const contacts = atomValue?.insured?.contacts;
    if (!isEmptyValue(contacts)) {
      return getContactsAsTableData(contacts) ?? [];
    }
    if (!isEmptyValue(firstName) && !isEmptyValue(lastName)) {
      return (
        getContactsAsTableData([
          {
            contactFirstName: firstName,
            contactLastName: lastName,
            primaryContact: true,
            contactStatus: StatusEnums.ACTIVE,
          } as PolicyInsuredContactBlob,
        ]) ?? []
      );
    }
    return [];
  };

  const getDeletedContacts = () =>
    deletedContacts?.filter((contact) => contact.insuredContactId) ?? [];

  // edit contact status
  const onContactStatusChange = (
    options: ComputeForCellParameters<PolicyInsuredContactBlob>
  ): ConditionForCellResponse | void => {
    if (options.computeTriggerSource === "PrimaryContact") {
      //triggered change
      if (options.castedRow?.PrimaryContact === true) {
        // the primary contact was marked as true, so the select gets disabled
        return {
          value: options.currentValue,
          typeId: BaseTableInputType.READONLY_SELECT,
        };
      } else {
        // the primary contact was marked as false, so the select gets enabled again
        return {
          value: options.currentValue,
          typeId: BaseTableInputType.SELECT,
        };
      }
    } else {
      //if the status changes, the primary checkbox its triggered
      return {
        value: options.currentValue,
        triggerComputeFieldNames: ["PrimaryContact"],
      };
    }
  };

  // on init contact status
  const onContactStatusInit = (
    options: ComputeForCellParameters<PolicyInsuredContactBlob>
  ): ConditionForCellResponse | void => {
    if (options.castedRow?.PrimaryContact === true) {
      // if this is the primary contact, the status gets disabled
      return {
        value: options?.currentValue,
        typeId: BaseTableInputType.READONLY_SELECT,
      };
    } else
      return {
        value: options?.currentValue,
      };
  };

  const hasPrimaryContact = () => {
    const atomValue = getAtom();
    const contacts = atomValue?.insured?.contacts;
    const hasPrimary = contacts?.some(
      (contact) => contact.primaryContact == true
    );
    return hasPrimary;
  };

  //edit primary contact
  const onPrimaryContactChange = (
    options: ComputeForCellParameters<PolicyInsuredContactBlob>
  ): ConditionForCellResponse | void => {
    if (options.computeTriggerSource === "ContactStatus") {
      //if the contact is inactive, the checkbox gets disabled
      if (
        options.castedRow?.ContactStatus === StatusEnums.INACTIVE ||
        hasPrimaryContact()
      ) {
        return {
          value: options?.currentValue,
          typeId: BaseTableInputType.READONLY_CHECKBOX,
        };
      } else {
        // if the contact was inactive and changes back to active, the checkbox goes back to normal
        return {
          value: options?.currentValue,
          typeId: BaseTableInputType.CHECKBOX,
        };
      }
    } else {
      //if the primary contact gets changes, the contact status is triggered
      return {
        value: options.currentValue,
        triggerComputeFieldNames: ["ContactStatus"],
      };
    }
  };

  //on init primary contact
  const onPrimaryContactInit = (
    options: ComputeForCellParameters<PolicyInsuredContactBlob>
  ): ConditionForCellResponse | void => {
    if (
      options.castedRow?.ContactStatus === StatusEnums.INACTIVE ||
      hasPrimaryContact()
    ) {
      // if the contact is inactive, the primary checkbox gets disabled
      return {
        value: options?.currentValue,
        typeId: BaseTableInputType.READONLY_CHECKBOX,
      };
    } else {
      return {
        value: options?.currentValue,
      };
    }
  };

  const isEmptyReturnNull = (value: string) => (value === "" ? null : value);

  const updateContactsByTableData = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    setInsuredContacts(getContactsByTableData(data, columns));
  };

  const deleteContactByHydratedRow = (hydratedRow?: any) => {
    setDeletedContacts([
      ...(deletedContacts ?? []),
      {
        baseTableRowKey: hydratedRow._row_key,
        contactId: parseInt(hydratedRow.ContactId ?? "0"),
        insuredContactId: parseInt(hydratedRow.InsuredContactId ?? "0"),
        contactFirstName: hydratedRow.ContactFirstName,
        contactLastName: hydratedRow.ContactLastName,
        contactTitle: hydratedRow.ContactTitle,
        contactStatus: hydratedRow.ContactStatus,
        insuredContactStatus: StatusEnums.DELETED,
        primaryContact: getSafeStringToBool(hydratedRow.PrimaryContact),
        primaryPhone: isEmptyReturnNull(hydratedRow.PrimaryPhone),
        primaryPhoneAdd: isEmptyReturnNull(hydratedRow.PrimaryPhoneAdd),
        email: isEmptyReturnNull(hydratedRow.Email),
        contactTag: hydratedRow.ContactTag,
        insuredId: parseInt(hydratedRow.InsuredId ?? "0"),
        tenantId: parseInt(hydratedRow.TenantId ?? "0"),
      },
    ]);
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableNameContacts,
    columnsAndData: {
      columns: columnsContacts(contactsConfiguration),
      data: getInitialTableData(),
    },
    columnOptions: [
      {
        fieldName: "ContactLastName",
        width: "15",
      },
      {
        fieldName: "ContactFirstName",
        width: "15",
      },
      {
        fieldName: "ContactTitle",
        width: "15",
      },
      {
        fieldName: "Email",
        width: "25",
      },
      {
        fieldName: "ContactTag",
        width: "25",
      },
      {
        fieldName: "PrimaryPhone",
        width: "15",
      },
      {
        fieldName: "ContactStatus",
        width: "8",
        computeOnInit: {
          conditionForCell: [(options) => onContactStatusInit(options)],
        },
        computeOnChange: {
          conditionForCell: [(options) => onContactStatusChange(options)],
        },
      },
      {
        fieldName: "PrimaryContact",
        width: "8",
        computeOnInit: {
          conditionForCell: [(options) => onPrimaryContactInit(options)],
        },
        computeOnChange: {
          conditionForCell: [(options) => onPrimaryContactChange(options)],
        },
      },
      {
        fieldName: "OPTIONS",
        width: 3,
      },
    ],
    events: {
      onDataChange: updateContactsByTableData,
      onDeleteRow: ({ hydratedRow }) => deleteContactByHydratedRow(hydratedRow),
    },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "delete",
      },
      tableErrors: errorDetails as any,
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
      showSaveButton: false,
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    return () => {
      setDeletedContacts(null);
    };
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    const updatedContacts =
      getDeletedContacts().length > 0
        ? insuredContacts?.concat(deletedContacts ?? [])
        : insuredContacts;
    if (!isEmptyValue(updatedContacts)) {
      setAtom({
        ...atomValue,
        insured: {
          ...atomValue?.insured,
          contacts: updatedContacts,
        },
      });
    }
  }, [insuredContacts]);

  const customErrorMessage = (errorDetails?: { [key: string]: Object }) =>
    !isEmptyValue(errorDetails?.contacts) ? (
      <Typography sx={errorMessageStyle(theme)}>
        {errorDetails?.contacts?.[0]}
      </Typography>
    ) : (
      <></>
    );

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalGutter="10px"
    >
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold>CONTACT INFORMATION</FontBold>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <BaseTable2 name={tableNameContacts} />
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="0px"
      >
        {customErrorMessage(errorDetails)}
      </Col>
    </Row>
  );
};

export default InsuredInformationContacts;
