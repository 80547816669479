import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { InsuredFileTypesEnums } from "../../../dtos/insured-file-types-enums";
import { BaseTableFilterOptions } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { addAllOption } from "../../../utilities/selectFunctions";
import { useRecoilState } from "recoil";
import { TypeFilterAtom } from "../MailRoomAtoms";

type MailRoomTypeFilterProps = {
  actionOptions: BaseTableFilterOptions;
  onChange?: (e: any) => void;
};

const MailRoomTypeFilter: (
  properties: MailRoomTypeFilterProps
) => JSX.Element = ({ actionOptions, onChange }) => {
  const [type, setType] = useRecoilState(TypeFilterAtom);
  const typeOptions: Partial<SelectOptions>[] = addAllOption(
    FromEnumToArray(InsuredFileTypesEnums).map((option) => {
      return {
        displayName: option.key,
        stringValue: option.key,
      };
    })
  );

  return BaseTableDropdownFilter({
    id: "type",
    filterOptions: actionOptions,
    optionsOverride: typeOptions,
    columnsNames: ["Type"],
    labelText: "Type:",
    defaultValue: type,
    onChange: (value) => {
      setType(value);
      onChange?.(value);
    },
  });
};

export default MailRoomTypeFilter;
