import { FC, useEffect, useState } from "react";
import { ReserveTypeWorksheetTableDto } from "../../../../../../../dtos/reserve-type-worksheet-table-dto";
import { BaseTable2, Collapse } from "../../../../../../TrueUI";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  ReserveWorksheetCustomFooter,
  getAmountTotalByFieldName,
  onAmountChange,
  onQtyChange,
  onReserveAmountChange,
  updateReserves,
  ReserveWorksheetCustomHeader,
} from "./ReserveWorksheetTableTableUtils";
import { ReserveTypeWithPaymentsDto } from "../../../../../../../dtos/reserve-type-with-payments-dto";

type AddReserveWorksheetTableProps = {
  tableInfo: ReserveTypeWorksheetTableDto;
  reserveLimit?: number;
  totalReserveLimit?: number;
  totalClaimReserves?: number;
  setUpdate?: (info: ReserveTypeWithPaymentsDto) => void;
  editMode: boolean;
};

const AddReserveWorksheetTable: FC<AddReserveWorksheetTableProps> = ({
  tableInfo,
  reserveLimit,
  totalReserveLimit,
  totalClaimReserves,
  setUpdate,
  editMode,
}) => {
  const [reserveTypeInfo, setReserveTypeInfo] =
    useState<ReserveTypeWithPaymentsDto>({
      reserveTypeId: tableInfo.reserveTypeId,
      reserveName: tableInfo.reserveTypeName,
      reservesList: [],
      reserveTotal: 0,
      totalOutstanding: 0,
    });

  useEffect(() => {
    setUpdate?.(reserveTypeInfo);
  }, [reserveTypeInfo]);
  
  useEffect(() => {
    setReserveTypeInfo({
      ...reserveTypeInfo,
      totalOutstanding: tableInfo.totalOutstanding,
    })
  }, [tableInfo.totalOutstanding]);
  

  const TABLE_NAME = `reserves_${tableInfo.reserveTypeName}_table`;

  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    columnsAndData: {
      columns: tableInfo.tableData?.columns,
      data: tableInfo.tableData?.data,
    },
    tableType: "standard",
    toolbarOptions: {
      hideToolbar: true,
    },
    columnOptions: [
      { fieldName: "Category", width: 40 },
      {
        fieldName: "Qty",
        width: 20,
        computeOnChange: {
          conditionForCell: [(options) => onQtyChange(options)],
        },
      },
      {
        fieldName: "Amount",
        width: 20,
        computeOnChange: {
          conditionForCell: [(options) => onAmountChange(options)],
        },
        allowNegatives: true,
      },
      {
        fieldName: "ReserveAmount",
        width: 20,
        align: "right",
        computeOnChange: {
          conditionForCell: [(options) => onReserveAmountChange(options)],
        },
      },
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: editMode,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
      },
    },
    events: {
      onDataChange: (data, columns) =>
        setReserveTypeInfo({
          ...reserveTypeInfo,
          reservesList: updateReserves(data, columns),
          reserveTotal: getAmountTotalByFieldName(
            data,
            columns,
            "ReserveAmount"
          ),
          totalOutstanding: tableInfo.totalOutstanding,
        }),
    },
    footerOptions: {
      CustomFooterComponent: () =>
        ReserveWorksheetCustomFooter(
          reserveTypeInfo.reserveTotal,
          TABLE_NAME,
          reserveLimit ?? 0,
          totalReserveLimit ?? 0,
          totalClaimReserves ?? 0,
          editMode
        ),
    },
  };

  useBaseTable(tableConfiguration);

  const content = <BaseTable2 name={TABLE_NAME} />;

  return (
    <Collapse
      title={ReserveWorksheetCustomHeader(
        tableInfo.reserveTypeName,
        reserveTypeInfo.totalOutstanding
      )}
      primaryColor={false}
      disableGutters
      fontType="BOLD_TITLE"
      titleHeightAuto
      content={content}
    />
  );
};

export default AddReserveWorksheetTable;
