import { FC } from "react";
import { Loading } from "../../TrueUI";
type PreparingExcelExportIndicatorProperties = {
  itemLength: number;
};
const PreparingExcelExportIndicator: FC<
  PreparingExcelExportIndicatorProperties
> = ({ itemLength }) => {
  return (
    <div>
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "200px", marginBottom: "25px" }}>
          <small>
            Processing <span style={{ fontWeight: "bold" }}>{itemLength}</span>{" "}
            items, this may take several minutes.
          </small>
        </div>
      </div>
      <div>
        <Loading isLoading={true} showPaper={false} />
      </div>
    </div>
  );
};

export default PreparingExcelExportIndicator;
