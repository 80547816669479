import { Theme } from "../../media/themeTypes";

export const activityStyles = (theme: Theme) => {
  return {
    width: "100%",
    height: "100%",
    "& .activity-container": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& .activity-header": {
        background: `transparent !important`,
        marginLeft: "-1px",
        marginTop: "-1px",
        marginBottom: 0,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .filters": {
          background: `transparent !important`,
          "& svg": {
            color: theme?.TEXT_COLOR,
            fontSize: theme?.TITLE.SIZE,
            "&: hover": { cursor: "pointer" },
          },
        },
      },
      "& .activity-stepper": {
        flexGrow: 1,
        overflow: "auto",
        height: "10px",
        marginTop: "0px",
        marginBottom: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        "& ul": {
          margin: 0,
        },
        "& .true_stepper_dots": {
          background: "transparent",
          border: `1px solid ${theme?.PRIMARY}`,
          color: theme?.TEXT_COLOR,
          margin: 0,
          width: "20px",
          height: "20px",
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
        },
        "& .activity-item": {
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignContent: "flex-start",
          "& > span": {
            alignSelf: "flex-start",
          },
        },
        "& .step-container": {
          color: theme?.TEXT_COLOR,
          fontSize: theme?.SMALL_TITLE.SIZE,
        },
        "& svg": {
          color: theme?.PRIMARY,
          width: "14px",
          height: "14px",
          position: "static",
        },
      },
    },
  };
};
