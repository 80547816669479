import { AccountTableDto } from "../../../../../../dtos/account-table-dto";
import { AchPaymentConfigurationDto } from "../../../../../../dtos/ach-payment-configuration-dto";
import { AchPaymentDto } from "../../../../../../dtos/ach-payment-dto";
import {
  GettingDateWithoutTime,
  getCurrentOrNextBusinessDate,
} from "../../../../../../utilities/dateFunctions";

export type AchModalProps = {
  insuredId: number;
  showModal: boolean;
  isOpenModal: (close: boolean) => void;
  accountTableData: AccountTableDto;
  onSaveSuccessful?: () => void;
};

export type AchFormProps = {
  setRefreshConfiguration: (value: string) => void;
  setAchFormData: (AchFormData: AchPaymentDto | null) => void;
  achFormData: AchPaymentDto | null;
  insuredId?: number;
  configuration?: AchPaymentConfigurationDto | null;
  errorMessages?: any;
};

export const defaultAchPaymentData = (
  insuredId: number,
  defaultBankAccountId?: number | null,
  defaultInsuredAccountId?: number | null,
  achFormData?: AchPaymentDto | null
) => {
  return {
    insuredId: insuredId,
    paymentDate: getCurrentOrNextBusinessDate(12),
    dateReceived: GettingDateWithoutTime(new Date()),
    billingTransactionDataList: achFormData?.billingTransactionDataList ?? [],
    bankAccountId: defaultBankAccountId,
    insuredAccountId: defaultInsuredAccountId,
  } as AchPaymentDto;
};
