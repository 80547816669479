import { atom, atomFamily } from "recoil";
import { DiaryNoteDto } from "../../dtos/diary-note-dto";
import {
  getStorageValueArray,
  INSURED_ATOM_FAMILY_STORAGE,
  setStorageInsuredAtomFamily,
} from "../../utilities/localStorageFunctions";
import { TmpFiles } from "../TrueUI/Upload/ShowTmpFiles";
import { ClaimTabs } from "./Claim/ClaimLanding/ClaimLandingMain";
import { claimIncidentStateProps } from "./Claim/Incident/AddNewIncident/AddNewIncidentUtils";
import { IncidentPropsForContextMenu } from "./Claim/Incident/Incident";
import { PolicyQuoteAtomProps } from "./Policy/PolicyQuoteForm/PolicyQuoteTypes";

export type tabsConfigurationType = {
  nameTabs: string;
  activeTab: number;
};

export type subSideNavConfigurationType = {
  parentItemSelected?: number;
  childItemSelected?: number;
};
export type CurrentClaim = {
  claimNumber?: string;
  claimClaimant?: string;
};

export type CurrentBillingPolicyFilterSelected = {
  policyId?: string;
};

export type BillingInformation = {
  payrollSection?: number;
  payrollReportId?: number;
};

export type CurrentNoteInformation = {
  noteInformation?: DiaryNoteDto | null;
  tmpFiles?: TmpFiles[];
  showTmpFiles?: boolean;
  files?: { [key: string]: number };
};

export type DrawerInformation = {
  currentNoteInformation?: CurrentNoteInformation | null;
};

export type GlobalInsuredAtomProperties = {
  navConfiguration?: subSideNavConfigurationType;
  internalInsuredTabs?: tabsConfigurationType[];
  claimIncidentInformation?: claimIncidentStateProps | null;
  incidentContextMenuValues?: IncidentPropsForContextMenu;
  claimTabsInformation?: ClaimTabs;
  insuredBodySection?: number;
  policyQuoteInformation?: PolicyQuoteAtomProps;
  contextDrawer?: number;
  currentClaim?: CurrentClaim;
  billingPolicyFilterSelected?: CurrentBillingPolicyFilterSelected;
  billingInformation?: BillingInformation | null;
  drawerInformation?: DrawerInformation | null;
  // more components information can be added here.
};

export type TriggerComponentNames =
  | "wagesComponent"
  | "detailsComponent"
  | "incidentComponent"
  | "claimantComponent"
  | "bodyPartsComponent"
  | "dependentsComponent"
  | "buttonComponent"
  | "claimLandingPageComponent"
  | "claimBanner"
  | "Incident"
  | "insuredBodyWrapper"
  | "representationEventsTable"
  | "activityLogComponent"
  | "alertAtomComponent"
  | "navComponent"
  | "tabTitleComponent"
  | "claimCorrespondenceComponent"
  | "financialTableClaimLandingPageComponent"
  | "assignmentComponent"
  | "customFieldComponent";

export type TriggerTaskComponentNames = "tasksWrapper";

export type TriggerAllComponentNames =
  | TriggerComponentNames
  | TriggerTaskComponentNames;

type RefreshInsuredPageAtom = {
  refreshTabs: boolean;
  reRenderComponent?: boolean;
};

const RefreshInsuredPageAtom = atom<RefreshInsuredPageAtom | null>({
  key: "RefreshInsuredPage",
  default: null,
});

const RefreshAlertComponent = atom<boolean>({
  key: "RefreshAlertComponent",
  default: false,
});

/* 
This is the new atomFamily that will use the information 
of the Insured tabs. 
*/

const TriggerComponentUpdateAtom = atomFamily<
  string | null,
  TriggerAllComponentNames
>({
  key: "TriggerComponentUpdateAtom",
  default: null,
});

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const atomFamilyValues = getStorageValueArray(INSURED_ATOM_FAMILY_STORAGE);
    const savedValue = atomFamilyValues?.find((atom) => atom?.key === key);
    if (savedValue != null) {
      setSelf(savedValue?.value);
    }

    onSet((newValue) => {
      const newAtom = { key: key, value: newValue };
      setStorageInsuredAtomFamily(newAtom);
    });
  };

const GlobalInsuredAtomFamily = atomFamily<
  GlobalInsuredAtomProperties | null,
  string | null
>({
  key: "InsuredInstanceAtomFamily",
  default: null,
  effects: (atomKey) => [localStorageEffect(atomKey)],
});

export {
  RefreshInsuredPageAtom,
  RefreshAlertComponent,
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
};
