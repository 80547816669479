import { FC, forwardRef, useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, ButtonBaseProps } from "@mui/material";
import { Col, Row } from "..";
import { rowWithNoMarginNorGutter } from "../Grids/Row";
import { colWithNoMarginNorGutter } from "../Grids/Col";
import style from "./breadcrumb.module.css";

export type BreadcrumbChipProps = {
  key?: number;
  label?: string;
  action?: React.Dispatch<any>;
  show?: boolean;
};

export type BreadcrumbItemProps = {
  key: number;
  label: string;
  action?: React.Dispatch<any>;
  show?: boolean;
};

type BreadcrumbNavProps = {
  activeItem: number;
  activeChip?: number;
  configItemList: BreadcrumbItemProps[];
  configChipList?: BreadcrumbChipProps[];
  overrideMaxActiveSection?: number;
};

const getLinkName = (name: string) => {
  return name?.replaceAll(" ", "-").toLowerCase() ?? "";
};

const BreadcrumbNavWithActionButton: FC<BreadcrumbNavProps> = ({
  activeItem,
  activeChip,
  configItemList,
  configChipList,
  overrideMaxActiveSection = activeItem,
}) => {
  const [maxActiveSection, setMaxActiveSection] = useState<number>(
    overrideMaxActiveSection
  );

  const renderBreadcrumbLink = forwardRef<ButtonBaseProps, any>(
    (props, ref) => (
      <Button
        {...props}
        ref={ref}
        sx={{ width: "max-content", textTransform: "none" }}
        name={`breadcrumb-link-${getLinkName(props.children)}`}
      >
        {props.children}
      </Button>
    )
  );

  const breadcrumbLinks = [
    configItemList.map(
      (item) =>
        (item.show ?? false) && (
          <Link
            key={"link-" + item.key}
            component={renderBreadcrumbLink}
            underline={activeItem === item.key ? "always" : "none"}
            className={`nowrap item-link ${
              activeItem === item.key
                ? style.true_breadcrumb_link_active
                : style.true_breadcrumb_link
            } ${
              (item.key ?? 9999) < maxActiveSection || activeItem === item.key
                ? style.true_breadcrumb_link_active_or_completed
                : style.true_breadcrumb_link
            }`}
            disabled={maxActiveSection < (item.key ?? 1)}
            onClick={item.action ?? (() => {})}
          >
            {item.label}
          </Link>
        )
    ),
  ];

  const chipButtons = [
    configChipList?.map(
      (chip) =>
        (chip?.show ?? false) && (
          <Chip
            clickable
            size="medium"
            label={chip.label}
            key={"chip-" + (chip?.key ?? 1)}
            color={activeChip === chip.key ? "default" : "primary"}
            variant={activeChip === chip.key ? "filled" : "outlined"}
            className={"true_breadcrumb_chip_items"}
            onClick={chip?.action ?? (() => {})}
          />
        )
    ),
  ];

  useEffect(() => {
    if (activeItem > maxActiveSection) {
      setMaxActiveSection(activeItem);
    }
  }, [activeItem]);

  useEffect(() => {
    if (overrideMaxActiveSection > maxActiveSection) {
      setMaxActiveSection(overrideMaxActiveSection);
    }
  }, [overrideMaxActiveSection]);

  return (
    <Box className={style.true_breadcrumb_container}>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          equalWidth={false}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbLinks}
          </Breadcrumbs>
        </Col>
        <Col horizontalAlign="flex-start" horizontalGutter="25px">
          {chipButtons}
        </Col>
      </Row>
    </Box>
  );
};
export default BreadcrumbNavWithActionButton;
