import { CSSObject, styled, Theme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { Theme as LocalThemeType } from "../../../media/themeTypes";

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const listItemIconSx = (
  theme: LocalThemeType,
  activeSection: boolean
) => ({
  minWidth: 45,
  color: activeSection ? theme?.PRIMARY : theme?.SECONDARY_BACKGROUND,
});

export const listItemTextIconSx = (theme: LocalThemeType) => ({
  color: theme?.SECONDARY_BACKGROUND,
});

export const divStyle = (theme: LocalThemeType) => ({
  position: "fixed",
  bottom: 0,
  textAlign: "center",
  paddingLeft: "8px",
  background: `${theme?.CONTRAST_BACKGROUND} !important`,

  ".MuiSvgIcon-root": {
    color: theme?.SECONDARY_BACKGROUND,
  },
});
