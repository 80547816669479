import { ElementType, FC, useEffect, useState } from "react";
import {
  BaseTableCellSelectProperties,
  DeleteRowParameters,
  OptionsContextActionParameterProperties,
  OptionsContextActionProperties,
  TableInstanceType2,
} from "../BaseTable2/TableProperties";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
import { usePopupState } from "material-ui-popup-state/hooks";
import Button from "../../Buttons/Button";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import PlusMinusAction from "./PlusMinusAction";
import {
  addOrRemoveKeyRow,
  addRowKeyWithOutRepeat,
  getRowByRowKey,
  hydrateAndCastDataSingle,
} from "../tableFunctions";
import OptionsCellContextProcessor from "./OptionsCellContextProcessor";

export type OptionCellType =
  | "none"
  | "context"
  | "edit"
  | "edit_save"
  | "delete"
  | "plus_minus"
  | "component"
  | "edit_delete";

interface BaseTableOptionCellProperties extends BaseTableCellSelectProperties {
  uiid: string;
  optionType: OptionCellType;
  hydratedRow?: any;
  contextMenuOptionRenderingCondition?:
    | {
        actionName: string;
        condition: (row: any) => { isHidden: boolean };
      }[]
    | null;
  optionsContextActions:
    | OptionsContextActionProperties[]
    | ((row?: any) => JSX.Element)
    | null;
}

const OptionsCell2: FC<BaseTableOptionCellProperties> = ({
  uiid,
  rowKey,
  rowIndex,
  optionType,
  contextMenuOptionRenderingCondition,
  optionsContextActions,
  hydratedRow,
}) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const popupState = usePopupState({
    variant: "popper",
    popupId: crypto.randomUUID(),
  });
  const [_, setOptionTypeValue] = useState(optionType);

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const contextRowInteract = (hydratedRow: any) => {
    console.log("contextRowInteract", hydratedRow);
    popupState.close();
  };

  const saveTable = () => {
    console.log("TODO - create a hook to save table data");
  };

  const refreshTable = () => {
    console.log("TODO - create a hook to refresh table data");
  };

  const _actionEvents = {
    closeMenu: popupState.close,
    saveTable: saveTable,
    refreshTable: refreshTable,
  };

  const autmaticallyGetOptionType = () => {
    if (optionsContextActions === null || optionsContextActions === undefined) {
      setOptionTypeValue("none");
    } else {
      if (Array.isArray(optionsContextActions)) {
        setOptionTypeValue("context");
      } else {
        setOptionTypeValue("component");
      }
    }
  };

  const Jsx = optionsContextActions as ElementType;

  useEffect(() => {
    autmaticallyGetOptionType();
  }, [optionsContextActions]);

  const editRow = (rowKey: string) => {
    if (tableInstance?.selectedEditRows?.includes(rowKey)) {
      setTableInstance({
        ...tableInstance,
        selectedEditRows: addOrRemoveKeyRow(
          tableInstance?.selectedEditRows,
          rowKey,
          false
        ),
      });
    } else {
      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        selectedEditRows: addRowKeyWithOutRepeat(
          tableInstance?.selectedEditRows ?? [],
          rowKey
        ),
        toggleEditModeState: true,
        _requestingCellManagerRowKeys: addRowKeyWithOutRepeat(
          tableInstance?._requestingCellManagerRowKeys ?? [],
          rowKey
        ),
      });
    }
  };

  const saveRow = (rowKey: string) => {
    if (tableInstance?.selectedEditRows?.includes(rowKey)) {
      setTableInstance({ ...tableInstance, selectedEditRows: null });
    } else {
      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        selectedEditRows: addRowKeyWithOutRepeat(
          tableInstance?.selectedEditRows ?? [],
          rowKey
        ),
      });
    }
  };

  const deleteRow = () => {
    const [propValue] =
      tableInstance?.advancedOptions?.optionsColumnConfiguration
        ?.optionalPropertyValueData ?? [];
    const updatedHydratedData = {
      ...hydratedRow,
      [propValue?.property]: propValue?.value,
    };
    const deletedRow: DeleteRowParameters = {
      rowKey: rowKey,
      hydratedData: updatedHydratedData,
      deleteType: "hidden",
    };

    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance?._accessors,
        _deleteRow: deletedRow,
      },
    });
  };

  const _actionRules = {
    interact: contextRowInteract,
    update: editRow,
    delete: deleteRow,
    add: () => {}, // TODO - create context to RowAdd
  };

  const getOptionType = () => {
    switch (optionType) {
      case "delete":
        return (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() => deleteRow()}
            true-element={"option-delete-icon"}
          >
            <DeleteIcon fontSize="small" />{" "}
          </IconButton>
        );
      case "edit":
        return (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() => editRow(rowKey)}
            true-element={"option-edit-icon"}
          >
            <EditIcon fontSize="small" />{" "}
          </IconButton>
        );
      case "edit_save":
        return (
          <div>
            {tableInstance?.selectedEditRows?.includes(rowKey) ? (
              <Button
                onClick={() => saveRow(rowKey)}
                true-element={"option-save-icon"}
              >
                SAVE ROW
              </Button>
            ) : (
              <Button
                onClick={() => editRow(rowKey)}
                true-element={"option-edit-icon"}
              >
                EDIT ROW
              </Button>
            )}
          </div>
        );
      case "plus_minus":
        return (
          <PlusMinusAction
            uiid={uiid}
            rowKey={rowKey}
            hydratedRow={hydratedRow}
          />
        );
      case "context":
        return (
          <OptionsCellContextProcessor
            rowIndex={rowIndex}
            contextMenuOptionRenderingCondition={
              contextMenuOptionRenderingCondition
            }
            actionRules={_actionRules}
            actionEvents={_actionEvents}
            tableInstance={tableInstance}
            additionalRowData={hydratedRow}
            optionsContextActions={optionsContextActions}
          />
        );
      case "component":
        return (
          <div>
            <>
              <Jsx
                {...({
                  row: hydrateAndCastDataSingle(
                    tableInstance?.columns ?? [],
                    getRowByRowKey(rowKey, tableInstance?.data ?? [])
                  ),
                  actionRules: _actionRules,
                  actionEvents: _actionEvents,
                  optionType: "component",
                  rowIndex: rowIndex,
                  metaData: {
                    isTableInEdit: tableInstance?.isEdit ?? false,
                  },
                } as OptionsContextActionParameterProperties<any>)}
              />
            </>
          </div>
        );
      case "edit_delete":
        return (
          <Box>
            <IconButton
              aria-label="save"
              size="small"
              onClick={() => editRow(rowKey)}
              true-element={"option-edit-icon"}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="save"
              size="small"
              onClick={() => deleteRow()}
              true-element={"option-delete-icon"}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={"options_action_container"}
      style={{ width: "100%" }}
    >
      {getOptionType()}
    </div>
  );
};

export default OptionsCell2;
