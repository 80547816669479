import { FC } from "react";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureHeaderProperties = {
  children?: any;
  width?: any;
  className?: string;
  onClick?: () => any;
};

const BaseGridStructureHeader: FC<BaseGridStructureHeaderProperties> = ({
  children,
  width,
  onClick,
}) => {
  return (
    <div
      className={styles.true_base_grid_header_data}
      style={{ width: width }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default BaseGridStructureHeader;
