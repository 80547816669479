import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, ReactNode } from "react";
import Button from "../Buttons/Button";
import "./dialog.module.css";

export type DialogConfirmationProps = {
  id?: string;
  open?: boolean;
  dialogTitle?: string;
  name?: string;
  dialogDescriptionText?: string;
  optionYesOverrideLabel?: string;
  optionNoOverrideLabel?: string;
  InteractiveComponent?: () => JSX.Element;
  onCloseEvent?: (close: boolean) => any;
  onOptionNoEvent?: (close: boolean) => any;
  onOptionYesEvent?: (close: boolean) => any;
  buttonRibbonNode?: ReactNode;
  disabledYesEvent?: boolean;
};

const DialogConfirmation: FC<DialogConfirmationProps> = ({
  id = "",
  name = "",
  open = false,
  dialogTitle,
  dialogDescriptionText = "",
  InteractiveComponent,
  onCloseEvent,
  onOptionNoEvent,
  onOptionYesEvent,
  buttonRibbonNode,
  optionYesOverrideLabel = "YES",
  optionNoOverrideLabel = "NO",
  disabledYesEvent = false,
}) => {
  return (
    <Dialog
      className={"true_dialog_confirmation"}
      id={id}
      open={open}
      fullWidth={true}
      onClose={() => {
        onCloseEvent?.(false);
      }}
      PaperProps={{
        className: "true_dialog_paper_container",
      }}
      aria-labelledby="dialogConfirmationTitle"
      aria-describedby="dialogConfirmationDescription"
    >
      {dialogTitle !== undefined && (
        <DialogTitle id="dialogConfirmationTitle">{dialogTitle}</DialogTitle>
      )}
      <DialogContent id="dialogConfirmationDescription">
        <DialogContentText>
          <div
            className="true_message_content"
            dangerouslySetInnerHTML={{ __html: dialogDescriptionText ?? "" }}
          />
        </DialogContentText>
        {InteractiveComponent && (
          <Box className={"true_dialog_content"}>{InteractiveComponent()}</Box>
        )}
      </DialogContent>
      <DialogActions>
        {onOptionYesEvent !== undefined && (
          <Button
            name={`${name}-yes`}
            onClick={() => {
              onOptionYesEvent?.(false);
            }}
            autoFocus
            isDisabled={disabledYesEvent}
          >
            {optionYesOverrideLabel}
          </Button>
        )}
        {onOptionNoEvent !== undefined && (
          <Button
            name={`${name}-no`}
            variantStyle={"outlined"}
            onClick={() => {
              onOptionNoEvent?.(false);
            }}
          >
            {optionNoOverrideLabel}
          </Button>
        )}

        {buttonRibbonNode !== undefined && (
          <Box className={"true_ribbon_node_container"}>
            <Box className={"true_ribbon_node"}>{buttonRibbonNode}</Box>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
