import { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import {
  BaseTableCellSelectProperties,
  IndicationType,
  TableInstanceType2,
} from "../BaseTable2/TableProperties";
import { RecoilState, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { hydrateDataSingle } from "../tableFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import Link from "../../Typography/Link";
import Font from "../../Typography/Font";
import {
  ClaimIconTable,
  DiscFileIcon,
  DocFileIcon,
  InsuredIconTable,
  JPGFileIcon,
  MailFileIcon,
  Mp3FileIcon,
  PDFFileIcon,
  PHPFileIcon,
  PPTFileIcon,
  TXTFileIcon,
  VideoFileIcon,
  WordFileIcon,
  XLSFileIcon,
  ZipFileIcon,
} from "../../Icons/CustomIcons";

interface BaseTableIndicationCellProperties
  extends BaseTableCellSelectProperties {}

type InternalIndicationType = IndicationType | JSX.Element | "conditional";

const IndicationCell: FC<BaseTableIndicationCellProperties> = ({
  uiid,
  value,
  row,
}) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const indConfig =
    tableInstance?.advancedOptions?.indicationColumnConfiguration;

  const indicationData = hydrateDataSingle(tableInstance?.columns ?? [], row);

  const getType = (): InternalIndicationType => {
    if (conditionHasValue(indConfig?.conditionalIcon)) {
      const result = indConfig?.conditionalIcon?.(indicationData) ?? "none";
      const typeOfConditional = typeof result;
      return typeOfConditional === "string" ? result : "conditional";
    } else {
      return indConfig?.indicationType ?? "none";
    }
  };

  const getIndicationIconType = () => {
    switch (getType()) {
      case "error":
        return <ErrorOutlineIcon />;
      case "warning":
        return <ErrorOutlineIcon />;
      case "success":
        return <CheckCircleOutlineIcon />;
      case "information":
        return <HelpOutlineIcon />;
      case "in_edit":
        return <CreateOutlinedIcon />;
      case "mp3":
        return <Mp3FileIcon />;
      case "disc":
        return <DiscFileIcon />;
      case "video":
        return <VideoFileIcon />;
      case "jpg":
        return <JPGFileIcon />;
      case "zip":
        return <ZipFileIcon />;
      case "txt":
        return <TXTFileIcon />;
      case "doc":
        return <DocFileIcon />;
      case "pdf":
        return <PDFFileIcon />;
      case "mail":
        return <MailFileIcon />;
      case "php":
        return <PHPFileIcon />;
      case "ppt":
        return <PPTFileIcon />;
      case "xls":
        return <XLSFileIcon />;
      case "word":
        return <WordFileIcon />;
      case "insured":
        return <InsuredIconTable />;
      case "claim":
        return <ClaimIconTable />;
      case "custom":
        return indConfig?.CustomIndicationComponent?.(indicationData);
      case "conditional":
        return indConfig?.conditionalIcon?.(indicationData);
      case "none":
        return "";
      default:
        return <div>&nbsp;</div>;
    }
  };

  const getValue = () => {
    if (indConfig?.isLink) {
      return <Link linkFontType="BODY" displayValue={value} />;
    }
    return <Font>{value === "none" ? "" : value}</Font>;
  };

  return (
    <div
      onClick={() => indConfig?.onClick?.(indicationData)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginLeft: "2px",
        gap: "5px",
        cursor: conditionHasValue(indConfig?.onClick) ? "pointer" : "initial",
        color: indConfig?.iconColor,
      }}
    >
      {getIndicationIconType()}
      {getValue()}
    </div>
  );
};

export default IndicationCell;
