import { FC, useEffect, useRef } from "react";
import {
  DateTimePicker,
  DatePicker,
  ToggleButton,
  ToggleButtonProps,
} from "@progress/kendo-react-dateinputs";
import moment from "moment";
import { Popup, PopupProps } from "@progress/kendo-react-popup";

type inputProps = {
  type?: "internalDateTime" | "internalDate";
  formatDate?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  onChange?: (e?: any) => void;
  value?: any | null;
  disabled?: boolean;
  trueElement?: string;
  className?: string;
  maxValue?: number;
  minValue?: number;
  tabIndex?: number;
};

const CustomPopup = (props: PopupProps) => {
  return <Popup {...props} style={{ ...props.style, zIndex: 10000 }} />;
};

const InternalDatePicker: FC<inputProps> = ({
  type,
  formatDate,
  disableFuture,
  disablePast,
  onChange,
  value,
  disabled,
  trueElement,
  className,
  maxValue,
  minValue,
  tabIndex = 0,
}) => {
  const inputRef = useRef<DatePicker>(null);
  const newDate = value != null ? moment(value).toDate() : null;

  const datePickerIcon = (props: ToggleButtonProps) => (
    <ToggleButton {...props} hidden={disabled} />
  );

  const commonTags = {
    format: formatDate,
    placeholder: "M/D/YYYY",
    onChange: onChange,
    max: disableFuture ? new Date() : maxValue ? new Date(maxValue) : undefined,
    min: disablePast ? new Date() : minValue ? new Date(minValue) : undefined,
    value: newDate,
    disabled: disabled,
    ref: inputRef,
    className: className,
  };

  const setAttribute = (
    componentRef: React.RefObject<any>,
    attr: string,
    value: string
  ) => {
    if (componentRef && componentRef.current && componentRef.current.element) {
      componentRef.current.element.setAttribute(`${attr}`, `${value}`);
    }
  };

  useEffect(() => {
    setAttribute(inputRef, "true-element", trueElement ?? "");
  }, []);

  return type === "internalDate" ? (
    <DatePicker
      {...commonTags}
      toggleButton={datePickerIcon}
      popup={CustomPopup}
      tabIndex={tabIndex}
    />
  ) : (
    <DateTimePicker {...commonTags} tabIndex={tabIndex} />
  );
};

export default InternalDatePicker;
