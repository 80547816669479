// import { ArrowRight, Add, ArrowDropDown } from "@mui/icons-material";
// import { IconButton, Box } from "@mui/material";
import { FC, ReactElement } from "react";
// import color from "../../../../media/colors";
// import {
//   castRowToType,
//   getSafeRowKey,
//   hydrateDataSingle,
// } from "../tableFunctions";
// import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
// import DetailRowContainer from "./DetailRowContainer";
// import { getStylingRow } from "../TableStyles";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { useRecoilValue } from "recoil";

import Collapse from "../../Collapse/Collapse";
import { TableRow } from "../BaseTableStructure";

type RowInternalProperties_TEMP = {
  uiid: string;
  rowKey: string;
  row: string[][];
  headerTitle: string;
  renderedCells: ReactElement[];
};

// TODO - remove this file

const BaseTableMultiRow: FC<RowInternalProperties_TEMP> = ({
  uiid,
  // rowKey,
  // row,
  // headerTitle,
  // renderedCells,
}) => {
  const tableInstance = useRecoilValue(tableInstanceAtomFamily2(uiid));
  console.log({ tableInstance });
  // const [open, setOpen] = useState(tableInstance?.expandRows ?? false);
  // const filter = row[0] ?? "";
  // console.log({ headerTitle });
  // const setTableInstance = useRecoilCallback(
  //   ({ set }) =>
  //     (newValueTableInstance: TableInstanceType2) => {
  //       set(tableInstanceAtomFamily2(uiid), {
  //         ...tableInstance,
  //         ...newValueTableInstance,
  //         uiid: uiid,
  //       });
  //     },
  //   []
  // );

  // TODO - move this to tableFunctions, I think it's being used in other places
  // const getErrorsByRow = (rowIndex: string) => {
  //   if (tableInstance?.validationErrors != null) {
  //     const errors = tableInstance?.validationErrors[rowIndex];
  //     return errors != undefined ? errors.errorsByRow : [];
  //   }
  //   return [];
  // };

  // const errorsByRow = getErrorsByRow(rowKey);

  // const addNewMulti = (filterIdRow: string) => {
  //   if (tableInstance?.events?.addRowFilterEventOverrideCallback) {
  //     tableInstance?.events?.addRowFilterEventOverrideCallback?.(filterIdRow);
  //   } else {
  //     const newDataItem = createNewDataRowItemWithDefaults(
  //       tableInstance?.columns,
  //       filterIdRow,
  //       tableInstance?.advancedOptions?.multiTableProperties
  //         ?.multiTableFilterField || ""
  //     );

  //     const updatedTableData = updateDataWithDataItem(
  //       newDataItem,
  //       tableInstance?.data
  //     );

  //     const updatedTableChangedData = updateDataWithDataItem(
  //       newDataItem,
  //       tableInstance?.changedData
  //     );

  //     setTableInstance({
  //       ...tableInstance,
  //       data: updatedTableData,
  //       changedData: updatedTableChangedData,
  //       isEdit: true,
  //       isAdd: false,
  //     });
  //   }
  // };

  // const normalRows = renderedCells.filter((row) => row[1] != "EmptyRow");

  // useEffect(() => {
  //   setOpen(tableInstance?.expandRows ?? false);
  // }, [tableInstance?.expandRows]);

  // const getHydratedData = () => {
  //   // const firstRow = filteredRows.length > 0 && filteredRows[0];
  //   if (renderedCells[2] === undefined) {
  //     return null;
  //   }
  //   return hydrateDataSingle(
  //     tableInstance?.columns ?? [],
  //     castRowToType(tableInstance?.columns ?? [], renderedCells[2].props.row) // TODO - fix this
  //   );
  // };

  // const showEditOnFilterRow =
  //   tableInstance?.advancedOptions?.multiTableProperties?.showEditOnFilterRow ??
  //   true;

  // const showAddNewOnFilterRow =
  //   tableInstance?.advancedOptions?.multiTableProperties
  //     ?.showAddNewOnFilterRow ?? true;

  // const _showEditOnFilterRowByCondition = () => {
  //   return (
  //     tableInstance?.advancedOptions?.multiTableProperties?.showEditOnFilterRowByCondition?.(
  //       getHydratedData()
  //     ) ?? true
  //   );
  // };

  // const _showAddNewOnFilterRowByCondition = () => {
  //   return (
  //     tableInstance?.advancedOptions?.multiTableProperties?.showEditOnFilterRowByCondition?.(
  //       getHydratedData()
  //     ) ?? true
  //   );
  // };

  // const headerRow = () => {
  //   // const headerRow = filteredRows[0] ?? [];
  //   return (
  //     <Box
  //       className={"parent_header"}
  //       sx={{ display: "table", width: "100%", tableLayout: "fixed" }}
  //     >
  //       <Box>
  //         <IconButton
  //           aria-label="expand row"
  //           size="small"
  //           onClick={() => setOpen(!open)}
  //         >
  //           {normalRows.length > 0 ? (
  //             open ? (
  //               <ArrowDropDown />
  //             ) : (
  //               <ArrowRight />
  //             )
  //           ) : (
  //             <ArrowRight />
  //           )}
  //         </IconButton>
  //         {headerTitle}
  //         {/* {tableInstance?.advancedOptions?.multiTableProperties
  //           ?.CustomRowHeaderComponent
  //           ? tableInstance?.advancedOptions?.multiTableProperties?.CustomRowHeaderComponent(
  //               // hydrateDataSingle(tableInstance?.columns, headerRow)
  //               hydrateDataSingle(tableInstance?.columns, [])
  //             )
  //           : filter} */}
  //       </Box>

  //       <Box
  //         sx={{ padding: "0 16px", display: "table-cell", width: "120px" }}
  //         className="actionFilterButtons"
  //       >
  //         {showEditOnFilterRow && _showEditOnFilterRowByCondition() && (
  //           <IconButton
  //             aria-label="expand row"
  //             size="small"
  //             onClick={() =>
  //               tableInstance?.methods?.editRowFilterEventCallback?.(
  //                 getHydratedData()
  //               )
  //             }
  //             className="updateIcon"
  //           >
  //             <ModeEditOutlineIcon fontSize="small" />
  //           </IconButton>
  //         )}
  //         {showAddNewOnFilterRow && _showAddNewOnFilterRowByCondition() && (
  //           <IconButton
  //             aria-label="expand row"
  //             size="small"
  //             // onClick={() => addNewMulti?.(filter)}
  //             className="addIcon"
  //           >
  //             <Add fontSize="small" />
  //           </IconButton>
  //         )}
  //       </Box>
  //     </Box>
  //   );
  // };

  // // const rowStyles = getStylingRow(
  // //   tableInstance?.columns,
  // //   tableInstance?.advancedOptions?.tableStyle?.rowStyle?.applyTo === "filter"
  // //     ? filteredRows[0] ?? []
  // //     : row,
  // //   tableInstance?.advancedOptions?.tableStyle?.rowStyle?.progressBar
  // // );

  // const rowStyles = getStylingRow(
  //   tableInstance?.columns ?? [],
  //   row[1],
  //   tableInstance?.advancedOptions?.tableStyle?.rowStyle?.progressBar
  // );

  // return (
  //   <>
  //     <TableRow
  //       key={`parent_row_${rowKey}`}
  //       className={"parent_row"}
  //       // onClick={(event) => onClick?.(event, row)}
  //       style={
  //         tableInstance?.advancedOptions?.tableStyle?.rowStyle?.applyTo ===
  //         "filter"
  //           ? rowStyles
  //           : { background: color.COLOR_GRAY_200 }
  //       }
  //     >
  //       <TableData colSpan={tableInstance?.columns.length}>
  //         <>{headerRow()}</>
  //       </TableData>
  //     </TableRow>
  //     {renderedCells.map((c) => {
  //       const innerRow = c.props.row ?? [];
  //       const innerRowKey = getSafeRowKey(innerRow);
  //       return (
  //         <DetailRowContainer
  //           key={crypto.randomUUID()}
  //           uiid={uiid}
  //           rowKey={innerRowKey}
  //           isOpen={open}
  //           row={innerRow}
  //           renderedCell={c}
  //         />
  //       );
  //     })}
  //   </>
  // );
  return (
    <TableRow>
      {/* <TableData> */}
      <Collapse hasBottomMargin={false} content={"Hi"} />
      {/* </TableData> */}
    </TableRow>
  );
};

export default BaseTableMultiRow;
