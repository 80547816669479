import { FC } from "react";
import { BaseTable2 } from "../../../../../../TrueUI";
import {
  API_PATH,
  TableProps,
  defaultRepresentationData,
  tableName,
} from "../typesAndConstants";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";
import { toLowerCaseKeys } from "../../../../../../../utilities/objectFunctions";
import { BaseTable2Properties } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";

const CasesTable: FC<TableProps> = ({
  claimId,
  setCaseData,
  formState,
  setFormState,
  setRowData,
  setTableInstanceMethods,
  setSelectedCase,
  hasClaimsManagementPermissions,
}) => {
  const rowClickEvent = (rowTableData) => {
    if (conditionHasValue(tableInstanceMethods))
      setTableInstanceMethods(tableInstanceMethods);
    setFormState({
      ...formState,
      title:
        rowTableData?.isDeselected === false
          ? `DETAILS FOR CASE NUMBER ${rowTableData?.hydratedData?.CaseNumber}`
          : "DETAILS",
      mode: "readonly",
      rowTableClicked: "case",
    });
    setSelectedCase?.(
      rowTableData?.isDeselected === false
        ? toLowerCaseKeys(rowTableData?.hydratedData)
        : defaultRepresentationData
    );
    setRowData(rowTableData?.isDeselected === false ? rowTableData : null);
    setCaseData(
      rowTableData?.isDeselected === false
        ? toLowerCaseKeys(rowTableData?.hydratedData)
        : defaultRepresentationData
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `${API_PATH}/GetClaimRepresentations?claimId=${claimId}`,
    columnOptions: [
      { fieldName: "CaseNumber", width: 15 },
      { fieldName: "Status", width: 15 },
      { fieldName: "Court", width: 20 },
      { fieldName: "Venue", width: 20 },
      { fieldName: "Served", width: 15 },
      { fieldName: "Answered", width: 15 },
    ],
    toolbarOptions: {
      toolBarType: "compact",
      showAddButton: hasClaimsManagementPermissions
    },
    advancedOptions: {
      paginate: false,
      tableStyle: {
        height: "220px",
        headerStyle: "transparent",
      },
    },
    events: {
      addEventOverrideCallback: () => {
        setFormState({
          ...formState,
          mode: "add",
          title: "ADD NEW CASE DETAILS ",
          rowTableClicked: "case",
        });
        setRowData(null);
        setCaseData(defaultRepresentationData);
        setSelectedCase?.(defaultRepresentationData);
      },
      onRowClick: (e) => rowClickEvent(e),
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const tableInstanceMethods = tableMethods[tableName];
  setTableInstanceMethods(tableInstanceMethods);
  return (
    <>
      <BaseTable2 name={tableName} />
    </>
  );
};

export default CasesTable;
