import { FC } from "react";

type ParagraphProperties = { children: any; linesToShow?: number };

const Paragraph: FC<ParagraphProperties> = ({ children, linesToShow = 2 }) => {
  return (
    <div
      style={{
        display: "-webkit-box",
        textAlign: "justify",
        whiteSpace: "pre-line",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: linesToShow,
        WebkitLineClamp: linesToShow,
        wordBreak: "break-all",
      }}
    >
      {children}
    </div>
  );
};

export default Paragraph;
