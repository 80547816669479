import { DataTypeEnums } from "../dtos/data-type-enums";

export const numberDataTypeEnums = [
  DataTypeEnums.INT,
  DataTypeEnums.DECIMAL,
  DataTypeEnums.DOUBLE,
  DataTypeEnums.FLOAT,
  DataTypeEnums.LONG,
  DataTypeEnums.NULLABLE_INT,
  DataTypeEnums.NULLABLE_DECIMAL,
  DataTypeEnums.NULLABLE_DOUBLE,
  DataTypeEnums.NULLABLE_FLOAT,
  DataTypeEnums.NULLABLE_LONG,
];

export const dateDataTypeEnums = [
  DataTypeEnums.DATE,
  DataTypeEnums.DATETIME,
  DataTypeEnums.DATETIME2,
  DataTypeEnums.NULLABLE_DATETIME,
  DataTypeEnums.NULLABLE_DATETIME2,
];
