import { ClaimStatusChangeInfoDto } from "../../../../../dtos/claim-status-change-info-dto";

export type AllModalConfiguration = {
  openModalChangeClaimDetails: boolean;
  openModalChangeClaimStatus: boolean;
  openModalAddReserve: boolean;
  openModalAddPayment: boolean;
  openModalAddDefaultTasks: boolean;
  openModalAddDefaultReserves: boolean;
};

export type ModalAddDefaultTaskProps = {
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
  claimId?: number;
  insuredId?: number;
  tabKey: string;
};

export type ModalAddDefaultReservesProps = {
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
  claimId?: number;
  tabKey: string;
};

export const modalAddDefaultTaskProps = {
  id: "add-default-task",
  title: "Add Default Task",
  showCloseButton: true,
  showCancelTextButton: true,
};

export const modalAddDefaultReservesProps = {
  id: "add-default-reserves",
  title: "Add Default Reserves",
  showCloseButton: true,
  showCancelTextButton: true,
};

export type ModalChangeClaimDetailsProps = {
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
  claimId?: number;
  tabKey: string;
};

export const modalDetailsProps = {
  id: "add-change-claim-details",
  title: "Change Details",
  showCloseButton: true,
  showCancelTextButton: true,
};

export type ModalChangeClaimStatusProps = {
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
  claimId?: number;
  insuredId: number;
};

export type ModalChangeClaimOpenTasksProps = {
  claimId: number;
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
};

export const modalStatusProps = {
  id: "change-claim-status",
  title: "Change Status",
  showCloseButton: false,
  showCancelTextButton: false,
};

export type ClaimStatusHistoryTabProps = {
  claimId: number;
};

export type ModalChangeClaimStatusTabProps = {
  claimId: number;
  insuredId: number;
  tabKey?: string;
  claimInfo: ClaimStatusChangeInfoDto | null;
  save: boolean;
  setSave: (save: boolean) => void;
  setAllModalConfiguration: (open: AllModalConfiguration) => void;
  allModalConfiguration: AllModalConfiguration;
  setIsSaveButtonDisabled: (ope) => void;
};

export type ClaimStatusErrorsProps = {
  errorStatus?: string[] | null;
  errorNote?: string[] | null;
};
