import { SelectOptions } from "../dtos/select-options";
import { formatNumberFloat } from "./dataConversionFunctions";
import { isEqualValue } from "./objectFunctions";

export function removeElementsFromArrayNonMutate(
  arr: any[],
  start: number,
  end: number
) {
  const a = arr.slice(start, end);
  console.log("removeElementsFromArrayNonMutate - ", { a });
}

export function uniqBySetWithArrayFrom<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function idArrayToFlatString(array: Partial<SelectOptions>[]) {
  const flatString = array.map(
    (value) => `{"Id":${value.stringValue ?? value.intValue}}`
  );
  return `[${flatString.toString()}]`;
}

export function getArrayByObjectOrArray<T>(argAsObjOrArray: T | T[]): T[] {
  return (
    Array.isArray(argAsObjOrArray as T)
      ? argAsObjOrArray ?? []
      : [argAsObjOrArray as T] ?? []
  ) as T[];
}

export function sumAllValuesOfAnArray(array: number[]) {
  return array.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
}

export const sumInt = (values: any[]) => {
  return values.reduce((x, y) => {
    return x + parseInt(y);
  }, 0);
};

export const sumFloat = (values: any[]) => {
  return values.reduce((x, y) => {
    return x + parseFloat(formatNumberFloat(y));
  }, 0);
};

export function dynamicSort(a, b, prop) {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}

export function groupArrayByKey(array: any[], key: string) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function removeValue(array: any[], value: any) {
  return array.filter((item: any) => item !== value);
}

export const getUniqueArrayValues = (array: any[]) => [...new Set(array)];

export const isArrayEqual = (arr1, arr2, index = 0) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Base case for recursion
  if (index === arr1.length) {
    return true;
  }
  // Recursive step: compare current elements and proceed to the next
  if (!isEqualValue(arr1[index], arr2[index])) {
    return false;
  }
  return isArrayEqual(arr1, arr2, index + 1);
};

export const splitToNChunks = <T>(array: T[], chunks: number) => {
  const numParts = array.length % chunks;
  const smallestPart = Math.ceil(array.length / chunks);
  const greatestPart = Math.floor(array.length / chunks);
  return [...(Array(chunks) as never[])].reduce(
    (acc, _, index) => (
      acc[0].push(
        array.slice(
          acc[1],
          (acc[1] += index < numParts ? smallestPart : greatestPart)
        )
      ),
      acc
    ),
    [[], 0] as [T[][], number]
  )[0];
};

const getNestedValue = (obj: any, path: string) =>
  path
    .split(".")
    .reduce(
      (nestedObj, key) =>
        nestedObj && nestedObj[key] !== undefined ? nestedObj[key] : null,
      obj
    );

export const removeDuplicateObjectsByPath = <T>(array: T[], key: string) => [
  ...new Map(array.map((v) => [getNestedValue(v, key), v])).values(),
];
