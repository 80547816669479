import { selectConfiguration } from "../../../../../utilities/inputPropsConfigurationFunctions";
import { Select } from "../../../../TrueUI";
import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { SelectOptions } from "../../../../../dtos/select-options";
import { isEmptyValue } from "../../../../../utilities/conditionalSupportFunctions";

type InvoiceNumberDropdownProps = {
  policyId: number;
  insuredId: number;
  value: number;
  onChange?: (value: number) => void;
  readOnly?: boolean;
};

const InvoiceNumberDropdown: FC<InvoiceNumberDropdownProps> = ({
  policyId,
  insuredId,
  value,
  onChange,
  readOnly = true,
}) => {
  const [invoiceOptions, setInvoiceOptions] = useState<SelectOptions[] | null>(
    null
  );

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Invoice/GetInvoiceOptionsByPolicyId?insuredId=${insuredId}&policyId=${policyId}`
  );

  useEffect(() => {
    if (policyId > 0) {
      dispatchGet();
    }
  }, [policyId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setInvoiceOptions(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  return (
    <Select
      {...selectConfiguration(
        "id-invoice-number",
        "invoice-number",
        "Invoice Number (optional)",
        readOnly
      )}
      labelFontType="BOLD_CAPTION"
      optionsMaxHeight={"400px"}
      firstOptionAsDefault={false}
      options={invoiceOptions ?? []}
      value={isEmptyValue(invoiceOptions) ? null : value}
      onChange={(value) => {
        onChange?.(value);
      }}
    />
  );
};

export default InvoiceNumberDropdown;
