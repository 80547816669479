import { FC, useEffect, useState } from "react";
import { InsuredAddressDto } from "../../../../dtos/insured-address-dto";
import { InsuredAddressModalDto } from "../../../../dtos/insured-address-modal-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useApiGet, useApiPost, usePermissions } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  Col,
  Input,
  InputAddress,
  InputDate,
  InputPhone,
  Modal,
  Row,
  Select,
} from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import {
  focusFirstChildElement,
  MODAL_CONTENT_CONTAINER_ID,
} from "../../../../utilities/tabFunctions";

export type ModalAddressInformationProps = {
  insuredId?: number;
  nameId?: number;
  addressId?: number | null;
  name?: string | null;
  effectiveDate?: Date;
  isNewAddress?: boolean;
  isOpen?: boolean;
};

type ModalAddressProps = {
  modalInformation: ModalAddressInformationProps;
  onCancelEvent?: () => void;
  onSaveEvent?: () => void;
};

type ModalConfigurationProps = {
  addressTypes?: SelectOptions[];
  errorDetails?: any | null;
};

const textFieldVariant = "standard";
const primaryType = "Primary";
const billingType = "Billing";
const physical = "Physical";

const statusTypes = [
  {
    displayName: "Active",
    intValue: 1,
  },
  {
    displayName: "Inactive",
    intValue: 2,
  },
];

const ModalAddress: FC<ModalAddressProps> = ({
  modalInformation,
  onSaveEvent,
  onCancelEvent,
}) => {
  const [insuredLocationData, setInsuredLocationData] =
    useState<InsuredAddressDto>();
  const [modalConfiguration, setModalConfiguration] =
    useState<ModalConfigurationProps>({});
  const [isDialogDeleteConfirmationOpen, setIsDialogDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { insuredId, nameId, addressId, isNewAddress, isOpen } =
    modalInformation;
  const { errorDetails, addressTypes } = modalConfiguration;

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/InsuredInformation/SaveInsuredLocation",
    insuredLocationData
  );
  const paramAddressId = isNewAddress
    ? "&addressId"
    : `&addressId=${addressId}`;

  const { responseGet, dispatchGet } = useApiGet<InsuredAddressModalDto>(
    `api/InsuredInformation/GetInformationAddressById?insuredId=${insuredId}${paramAddressId}`
  );

  const defaultLocationData: InsuredAddressDto = {
    locationName: modalInformation.isNewAddress
      ? modalInformation.name ?? ""
      : "",
    locationType: modalInformation.isNewAddress ? "Location" : "",
    locationEffDate: modalInformation.isNewAddress
      ? modalInformation.effectiveDate
      : undefined,
  };

  const permissions = [
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ];
  const { hasPermission } = usePermissions(permissions);
  const lastNumberAddress =
    (responseGet.axiosResponse?.data?.lastNumberLocation ?? 0) + 1;

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const responseData = responseGet.axiosResponse?.data;
      const address = responseData?.insuredAddress;
      setInsuredLocationData({
        ...address,
        insuredId: isNewAddress ? insuredId : address?.insuredId,
        nameId: isNewAddress ? nameId : address?.nameId,
        number: isNewAddress ? undefined : address?.number,
        locationType: isNewAddress ? "" : address?.locationType,
        locationState: isNewAddress
          ? "AL"
          : responseData?.insuredAddress?.locationState,
        locationStatus: isNewAddress
          ? StatusEnums.ACTIVE
          : responseData?.insuredAddress?.locationStatus,
        ...(isNewAddress ? defaultLocationData : {}),
      });
      setModalConfiguration({
        addressTypes: responseData?.addressTypes,
      });
      focusFirstChildElement(MODAL_CONTENT_CONTAINER_ID);
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      onSaveEvent?.();
    } else {
      setModalConfiguration({
        ...modalConfiguration,
        errorDetails: validatorErrorResponse?.errorDetails,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (isOpen) {
      dispatchGet();
    } else {
      setInsuredLocationData({});
    }
  }, [isOpen]);

  const onCancelModal = () => {
    setModalConfiguration({ errorDetails: null });
    onCancelEvent?.();
  };

  const labelType = "BOLD_CAPTION";
  return (
    <>
      {conditionHasValue(insuredLocationData) && (
        <Modal
          id={"new_insured_location_modal"}
          title={"Address"}
          open={isOpen}
          permissionsToSaveButton={permissions}
          cancelEvent={onCancelModal}
          showCloseButton
          saveEvent={dispatchPost}
          deleteEvent={
            !hasPermission ||
            isNewAddress ||
            insuredLocationData?.isPrimaryAddress ||
            responseGet.axiosResponse?.data?.insuredAddress?.locationType ===
              primaryType
              ? undefined
              : () => setIsDialogDeleteConfirmationOpen(true)
          }
          cancelButtonWithConfirmation={hasPermission}
          showCancelTextButton
        >
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 8, xl: 8 }}
            >
              <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                <Col>
                  <Input
                    id="id-insured-location-name-input"
                    name="name-insured-location-name-input"
                    label="Name or DBA"
                    tabIndex={1}
                    variant={textFieldVariant}
                    errorMessage={errorDetails?.locationName}
                    value={insuredLocationData?.locationName}
                    maxLength={300}
                    onChangeRawValue={(value) => {
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationName: value,
                      });
                    }}
                    readOnly={!hasPermission}
                    labelFontType={labelType}
                  />
                </Col>
                <Col {...colWithNoMarginNorGutter}>
                  <InputAddress
                    id="id-insured-location-address-input"
                    name="name-insured-location-address-input"
                    label="Address"
                    tabIndex={2}
                    labelPostalCode="Zip"
                    variant={textFieldVariant}
                    readOnly={!hasPermission}
                    address1ErrorMessage={errorDetails?.locationAddress}
                    address2ErrorMessage={errorDetails?.locationAddress2}
                    cityErrorMessage={errorDetails?.locationCity}
                    postCodeErrorMessage={errorDetails?.locationZip}
                    stateCodeErrorMessage={errorDetails?.locationState}
                    initValueAddress1={
                      insuredLocationData?.locationAddress ?? ""
                    }
                    initValueAddress2={insuredLocationData?.locationAddress2}
                    initValueCity={insuredLocationData?.locationCity ?? ""}
                    initValueStateCode={
                      insuredLocationData?.locationState ?? ""
                    }
                    initValuePostCode={insuredLocationData?.locationZip ?? ""}
                    labelFontType={labelType}
                    onChangeValueAddress1={(value) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationAddress: value,
                      })
                    }
                    onChangeValueAddress2={(value) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationAddress2: value,
                      })
                    }
                    onChangeValueCity={(value) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationCity: value,
                      })
                    }
                    onChangeStateCode={(value) => {
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationState: value,
                      });
                    }}
                    onChangeValuePostCode={(value) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationZip: value,
                      })
                    }
                    showSingleLabel={false}
                  />
                </Col>
                <Row {...rowWithNoMarginNorGutter}>
                  <Col>
                    <InputPhone
                      id="name-insured-location-phone-input"
                      name="name-insured-location-phone-input"
                      phoneLabel="Phone Number"
                      tabIndex={7}
                      variant={textFieldVariant}
                      phoneValue={insuredLocationData?.phone ?? ""}
                      readOnly={!hasPermission}
                      errorMessagePhone={errorDetails?.phone}
                      labelFontType={labelType}
                      onChangePhoneValue={(value) => {
                        setInsuredLocationData({
                          ...insuredLocationData,
                          phone: value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <InputPhone
                      id="id-insured-location-fax-input"
                      name="name-insured-location-fax-input"
                      phoneLabel="Fax Number"
                      tabIndex={8}
                      variant={textFieldVariant}
                      phoneValue={insuredLocationData?.fax ?? ""}
                      readOnly={!hasPermission}
                      errorMessagePhone={errorDetails?.fax}
                      labelFontType={labelType}
                      onChangePhoneValue={(value) => {
                        setInsuredLocationData({
                          ...insuredLocationData,
                          fax: value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row {...rowWithNoMarginNorGutter}>
                  <Col>
                    <Input
                      id="id-insured-location-number-employees-input"
                      name="name-insured-location-number-employees-input"
                      label="Number of Full Time Employees"
                      tabIndex={9}
                      variant={textFieldVariant}
                      readOnly={!hasPermission}
                      type={"number"}
                      maxNumericValue={999999999}
                      decimalScale={0}
                      errorMessage={errorDetails?.fullTimeEE}
                      value={insuredLocationData?.fullTimeEE}
                      labelFontType={labelType}
                      onChangeRawValue={(value) => {
                        setInsuredLocationData({
                          ...insuredLocationData,
                          fullTimeEE: value,
                        });
                      }}
                    />
                  </Col>

                  <Col>
                    <Input
                      id="id-insured-location-number-part-time-input"
                      name="name-insured-location-number-part-time-input"
                      label="Number of Part Time Employees"
                      tabIndex={10}
                      variant={textFieldVariant}
                      readOnly={!hasPermission}
                      type={"number"}
                      maxNumericValue={999999999}
                      decimalScale={0}
                      errorMessage={errorDetails?.partTimeEE}
                      value={insuredLocationData?.partTimeEE}
                      labelFontType={labelType}
                      onChangeRawValue={(value) => {
                        setInsuredLocationData({
                          ...insuredLocationData,
                          partTimeEE: value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 4, lg: 4, xl: 4 }}
            >
              <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                <Col>
                  <Select
                    id="id-insured-address-type-select"
                    name="name-insured-address-type-select"
                    label={"Address Type"}
                    tabIndex={11}
                    options={addressTypes ?? []}
                    variant={textFieldVariant}
                    errorMessage={errorDetails?.locationType}
                    value={insuredLocationData?.locationType}
                    readOnly={!hasPermission}
                    labelFontType={labelType}
                    onChange={(value) => {
                      const IsCorrectType =
                        value === primaryType ||
                        value === billingType ||
                        value === physical;
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationType: value,
                        number: IsCorrectType ? undefined : lastNumberAddress,
                      });
                    }}
                    firstOptionAsDefault={false}
                  />
                </Col>
                <Col>
                  <Input
                    id="id-insured-location-number-input"
                    name="insuredLocationNumberPartTime"
                    label="Location Number"
                    tabIndex={12}
                    variant={textFieldVariant}
                    readOnly={!hasPermission}
                    type={"number"}
                    maxLength={9}
                    errorMessage={errorDetails?.number}
                    value={insuredLocationData?.number}
                    labelFontType={labelType}
                    onChangeRawValue={(e) => {
                      setInsuredLocationData({
                        ...insuredLocationData,
                        number: e,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    id="id-insured-location-status-select"
                    name="insuredLocationStatus"
                    label={"Status"}
                    tabIndex={13}
                    options={statusTypes}
                    variant={textFieldVariant}
                    errorMessage={errorDetails?.locationStatus}
                    value={insuredLocationData?.locationStatus}
                    readOnly={!hasPermission}
                    labelFontType={labelType}
                    onChange={(value) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationStatus: value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <InputDate
                    id="id-insured-location-effective-date-input"
                    name="insuredLocationEffectiveDate"
                    label="Effective Date"
                    tabIndex={14}
                    variant={textFieldVariant}
                    errorMessage={errorDetails?.locationEffDate}
                    value={insuredLocationData?.locationEffDate}
                    readOnly={!hasPermission}
                    labelFontType={labelType}
                    onChangeRawValue={(value) => {
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationEffDate: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <InputDate
                    id="id-insured-location-expiration-date-input"
                    name="insuredLocationExpirationDate"
                    label="Expiration Date"
                    tabIndex={15}
                    variant={textFieldVariant}
                    errorMessage={errorDetails?.locationExpDate}
                    value={insuredLocationData?.locationExpDate}
                    readOnly={!hasPermission}
                    labelFontType={labelType}
                    onChangeRawValue={(value) => {
                      setInsuredLocationData({
                        ...insuredLocationData,
                        locationExpDate: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    id="id-insured-location-store-number-input"
                    name="insuredLocationStoreNumber"
                    label="Store Number"
                    tabIndex={16}
                    variant={textFieldVariant}
                    readOnly={!hasPermission}
                    maxLength={20}
                    errorMessage={errorDetails?.storeNumber}
                    value={insuredLocationData?.storeNumber}
                    labelFontType={labelType}
                    onChangeRawValue={(e) =>
                      setInsuredLocationData({
                        ...insuredLocationData,
                        storeNumber: e,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
      <DialogConfirmation
        name="insured-location-delete-dialog"
        id="modalLocationDeleteDialogConfirmation"
        open={isDialogDeleteConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete the address?"
        onCloseEvent={(close) => {
          setIsDialogDeleteConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogDeleteConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setInsuredLocationData({
            ...insuredLocationData,
            locationStatus: StatusEnums.DELETED,
          });
          dispatchPost();
          setIsDialogDeleteConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default ModalAddress;
