import { FC, useEffect, useState } from "react";
import { TabPanel, Tabs } from "../../../../TrueUI";
import { Box } from "@mui/material";
import { ClaimLandingProps } from "../ClaimLandingMain";
import {
  CLAIM_DETAILS_TABS,
  INSURED_ATOM_KEY,
} from "../../../../../utilities/queryStringsHash";

import ClaimDetailsMain from "./ClaimDetailsComponents/ClaimDetailsMain";
import SaveContainersButton from "./ClaimDetailsComponents/SaveContainersButton";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import ClaimContacts from "./ClaimContactsComponents/ClaimContacts";
import ClaimLitigation from "./ClaimLitigationComponents/ClaimLitigation";
import { useInternalInsuredTabs } from "../../../../../hooks/useInternalInsuredTabs";
import ClaimFinancial from "./ClaimFinancialComponents/ClaimFinancial";
import ClaimCorrespondence from "./ClaimCorrespondenceComponents/ClaimCorrespondence";
import style from "./claimLandingTabs.module.css";
import ClaimLien from "./ClaimLienComponents/ClaimLien";
import ClaimReturnToWork from "./ClaimReturnToWorkComponents/ClaimReturnToWork";
import { usePermissions } from "../../../../../hooks";
import { PermissionsEnums } from "../../../../../dtos/permissions-enums";

const ClaimLandingTabs: FC<ClaimLandingProps> = ({
  insuredId,
  claimId,
  jurisdiction,
  claimNumber,
  claimLandingClaimant,
  tabKey,
}) => {
  const hasClaimsManagementPermissions = usePermissions([
    PermissionsEnums.TRUE_ADMIN,
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ])?.hasPermission;

  const tabName = CLAIM_DETAILS_TABS;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [errorDetails, setErrorDetails] = useState();
  const {
    getAtom,
    setAtom: setClaimPageState,
    setComponentTriggers,
  } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { getTabSelected, setTabValue } = useInternalInsuredTabs(tabKey);

  const claimPageState = getAtom();
  const tabSelected = getTabSelected(tabName);

  const onTabClick = (e) => {
    setComponentTriggers(["buttonComponent"]);
    setTabValue(e.selected, tabName);
  };

  useEffect(() => {
    setTabValue(tabSelected, tabName);
    setClaimPageState({
      ...claimPageState,
      claimTabsInformation: {
        ...claimPageState?.claimTabsInformation,
        activeSection: "1",
      },
    });
  }, []);

  return (
    <div
      id={"claim_landing_tabs_wrapper"}
      className={style.claim_landing_tabs_wrapper}
    >
      <div className={style.claim_landing_tabs_container}>
        <Tabs
          name={CLAIM_DETAILS_TABS}
          selected={tabSelected}
          onSelect={onTabClick}
          stateControlled
        >
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Details</Box>}
            key={"claimDetailsTab"}
          >
            <ClaimDetailsMain
              insuredId={insuredId}
              claimId={claimId}
              errorDetails={errorDetails}
              tabKey={tabKey}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Financial</Box>}
            key={"claimFinancialTab"}
          >
            <ClaimFinancial
              tabKey={tabKey}
              insuredId={insuredId}
              claimId={claimId}
              claimLandingClaimant={claimLandingClaimant}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Contacts</Box>}
            key={"claimContactsTab"}
          >
            <ClaimContacts
              claimId={claimId}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Litigation</Box>}
            key={"claimLitigationTab"}
          >
            <ClaimLitigation
              claimId={claimId}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Correspondence</Box>}
            key={"claimCorrespondenceTab"}
          >
            <ClaimCorrespondence
              claimJurisdiction={jurisdiction ?? ""}
              insuredId={insuredId}
              claimId={claimId}
              claimNumber={claimNumber}
              tabKey={tabKey}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Lien</Box>}
            key={"claimLienTab"}
          >
            <ClaimLien
              claimId={claimId ?? 0}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
          <TabPanel
            title={<Box sx={{ paddingX: 2 }}>Return To Work</Box>}
            key={"claimReturnToWorkTab"}
          >
            <ClaimReturnToWork
              claimId={claimId ?? 0}
              insuredIdAtomKey={insuredIdAtomKey}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          </TabPanel>
        </Tabs>
      </div>
      <SaveContainersButton
        insuredIdAtomKey={insuredIdAtomKey}
        onErrorResponse={(errors) => setErrorDetails(errors)}
      />
    </div>
  );
};

export default ClaimLandingTabs;
