import { CssBaseline } from "@mui/material";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { useEffect } from "react";
// import { useRecoilState } from "recoil";
// import App from "./App";
// import { isProgramSelected } from "./GlobalAtoms";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
// import { Landing } from "./components/Landing";
import SiteNotification from "./components/Site/SiteNotification";
import AppTokenListener from "./AppTokenListener";
// import * as signalR from "@microsoft/signalr";
// import { useSiteNotificationProps } from "./hooks";
// import { isStillValid } from "./utilities/jwtFunctions";
import { useCSSVariableManager } from "./media/useCSSVariableManager";
// import {
//   removeSystemLocalStorage,
//   TRUE_ID_STORAGE,
// } from "./utilities/localStorageFunctions";
import { useEffect } from "react";
// import { isProgramSelected } from "./GlobalAtoms";

const AppWrapper = () => {
  // const [_isProgramSelected, _setIsProgramSelected] =
  // useRecoilState(isProgramSelected);
  // const { setNotificationPropsValue } = useSiteNotificationProps();

  const { initializeDefaultVariables } = useCSSVariableManager("AppWrapper");
  // const localOptions = useRecoilValue(globalOptions);

  // const initializeSignalRConnection = () => {
  //   //TODO: The url value is a hack right now.
  //   const hubSiteNotificationConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${process.env.REACT_APP_API_URL}sitenotificationhub`, {
  //       transport: signalR.HttpTransportType.WebSockets,
  //       skipNegotiation: true,
  //     })
  //     .build();

  //   const StartSignalRConnection = () => {
  //     try {
  //       hubSiteNotificationConnection.start();
  //     } catch (error) {}
  //   };

  //   hubSiteNotificationConnection.onclose(() => StartSignalRConnection());

  //   StartSignalRConnection();

  //   hubSiteNotificationConnection.on("sendToReact", (siteNotification) => {
  //     setNotificationPropsValue({
  //       align: "left",
  //       componentMessage: siteNotification.message,
  //       expireTime: 5000,
  //       isOpen: true,
  //       notificationType: siteNotification.notificationType,
  //     });
  //   });
  // };

  //   StartSignalRConnection();

  //   hubSiteNotificationConnection.on("sendToReact", (siteNotification) => {
  //     setNotificationPropsValue({
  //       align: "left",
  //       componentMessage: siteNotification.message,
  //       expireTime: 5000,
  //       isOpen: true,
  //       notificationType: siteNotification.notificationType,
  //     });
  //   });
  // };

  // const darkTheme = createTheme({
  //   palette: {
  //     mode: "dark",
  //   },
  // });

  // const lightTheme = createTheme({
  //   palette: {
  //     mode: "light",
  //   },
  // });

  // const listenForTokenRemoval = (storageEvent: StorageEvent) => {
  //   if (
  //     storageEvent.key === TRUE_ID_STORAGE &&
  //     storageEvent.newValue === null
  //   ) {
  //     _setIsProgramSelected(false);
  //   } else {
  //     const isValid = isStillValid(storageEvent.newValue ?? "");

  //     if (isValid) {
  //       _setIsProgramSelected(true);
  //     } else {
  //       _setIsProgramSelected(false);
  //       removeSystemLocalStorage();
  //     }
  //   }
  // };

  // useEffect(function hasTrueIdToken() {
  //   const tokenValue = localStorage.getItem(TRUE_ID_STORAGE);
  //   initializeSignalRConnection();
  //   if (tokenValue !== null) _setIsProgramSelected(true);
  //   window.addEventListener("storage", (e) => listenForTokenRemoval(e));
  // }, []);

  useEffect(() => {
    initializeDefaultVariables();
  }, []);

  return (
    // <ThemeProvider
    //   theme={localOptions?.themeRefresh === "dark" ? darkTheme : lightTheme}
    // >

    <>
      <CssBaseline />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      ></MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        <SiteNotification />
        <>
          <AppTokenListener />

          {/* {_isProgramSelected ? <App /> : <Landing />} */}
        </>
      </AuthenticatedTemplate>
    </>
    // </ThemeProvider>
  );
};

export default AppWrapper;
