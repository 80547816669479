import { FC, useEffect, useState } from "react";
import { AchBatchDetailProps } from "./AchTypes";
import AchBatchDetailInformation from "./AchBatchDetailsInformation";
import "./achBillingBatchDetails.module.css";
import AchBatchTransactionsDetailsTable from "./AchBatchTransactionsDetailsTable";
import AchBatchNachaFileCollapse from "./AchBatchNachaFileCollapse";
import { AchBillingPaymentBatchDetailsDto } from "../../../dtos/ach-billing-payment-batch-details-dto";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

const AchBatchDetail: FC<AchBatchDetailProps> = ({
  selectedBatchId,
  setSelectedBatchId,
  selectedProgramCode,
}) => {
  const [achBatchDetailInformation, setAchBatchDetailInformation] =
    useState<AchBillingPaymentBatchDetailsDto | null>(null);
  const [batchDeleted, setBatchDeleted] = useState<boolean>(false);
  const { responseGet, dispatchGet } =
    useApiGet<AchBillingPaymentBatchDetailsDto>(
      `api/BillingPaymentBatch/GetAchBatchDetails?billingPaymentBatchId=${selectedBatchId}`
    );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setBatchDeleted(false);
      setAchBatchDetailInformation(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (batchDeleted === true) {
      dispatchGet();
    }
  }, [batchDeleted]);

  return (
    <>
      <AchBatchDetailInformation
        selectedBatchId={selectedBatchId}
        setSelectedBatchId={setSelectedBatchId}
        achBatchDetailInformation={achBatchDetailInformation}
        setBatchDeleted={setBatchDeleted}
      />
      <AchBatchNachaFileCollapse
        selectedBatchId={selectedBatchId}
        achBatchDetailInformation={achBatchDetailInformation}
        selectedProgramCode={selectedProgramCode}
      />
      {selectedBatchId !== null && achBatchDetailInformation?.batchStatusId && (
        <AchBatchTransactionsDetailsTable
          selectedBatchStatusId={achBatchDetailInformation?.batchStatusId}
          selectedBatchId={selectedBatchId}
          setSelectedBatchId={setSelectedBatchId}
        />
      )}
    </>
  );
};
export default AchBatchDetail;
