import { atom, atomFamily } from "recoil";
import { Location } from "react-router";
import { SiteNotificationProperties } from "./components/Site/SiteNotification/SiteNotification";
import { PolicyBlob } from "./dtos/policy-blob";
import { ParameterTrackerProperties } from "./utilities/urlProcessingFunctions";

type UnsavedChangesProperties = {
  url: string;
  unsavedChanges: UnsavedChangesDataProperties[];
};

type UnsavedChangesDataProperties = {
  originalData?: any;
  originalHash?: number;
  newData?: any;
  newHash?: number;
};

const isProgramSelected = atom<boolean>({
  key: "isProgramSelected",
  default: false,
});

const isTenantSelected = atom<boolean>({
  key: "isTenantSelected",
  default: false,
});
const hasLocationChanged = atom<Location | null>({
  key: "hasLocationChanged",
  default: null,
});

const globalIsLoading = atom<boolean>({
  key: "globalIsLoading",
  default: false,
});

const globalIsDisabledElementAtom = atom<boolean>({
  key: "globalDisableElementAtom",
  default: false,
});

const globalIsSaving = atom<boolean>({
  key: "globalIsSaving",
  default: false,
});

const globalUnsavedChanges = atom<UnsavedChangesProperties | null>({
  key: "globalUnsavedChanges",
  default: null,
});

const globalOptions = atom<any | null>({
  key: "globalOptions",
  default: null,
});

// const tmpPermissionsAtom = atom<number[]>({
//   key: "tmpPermissionsAtom",
//   default: [1, 2, 3], // TODO: @elara Update until Antonio's PR will be ready
// });

const globalNotificationProperties = atom<SiteNotificationProperties | null>({
  key: "globalNotificationProperties",
  default: null,
});

const acord130FormProperties = atom<PolicyBlob | null>({
  key: "acord130FormAtom",
  default: null,
});

const globalApplicationAlerts = atom<any>({
  key: "globalApplicationAlerts",
  default: null,
});

const globalLastHashChangesTrigger = atom<{
  checkHashState: boolean;
  activeHashStates: {
    name: string;
    value: any;
    activeInUrl: boolean; // Is the hash value actively part of the URL at the given moment.
    isReactStateControlled: boolean; // This just means if the hash value was set using the useURLHashState vs being manually typed in the URL and is uncontrolled by react.
  }[];
}>({
  key: "globalLastHashChangeTrigger",
  default: {
    checkHashState: false,
    activeHashStates: [],
  },
});

const globalRequestingHashTriggerAtom = atom<string[]>({
  key: "globalRequestingHashTriggerAtom",
  default: [],
});

const globalHashChangeTriggers = atomFamily<ParameterTrackerProperties, string>(
  {
    key: "globalHashChangeTriggerFamily",
    default: {
      parameterName: null,
      keyName: null,
      dataType: null,
      value: null,
      event: null,
      hookType: "common",
      // readonly
      source: null,
      forceAppendToURL: false,
      initialized: false,
    },
  }
);

export {
  isProgramSelected,
  isTenantSelected,
  hasLocationChanged,
  globalIsLoading,
  globalIsDisabledElementAtom,
  globalIsSaving,
  globalUnsavedChanges,
  globalOptions,
  // tmpPermissionsAtom,
  globalNotificationProperties,
  acord130FormProperties,
  globalApplicationAlerts,
  globalLastHashChangesTrigger,
  globalRequestingHashTriggerAtom,
  globalHashChangeTriggers,
};
