import { FC, ReactElement, useEffect, useState } from "react";
import { Font } from "../../../TrueUI";
import { Box } from "@mui/material";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import { RecurringPaymentsWrapper } from "./RecurringPaymentsStyles";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import RecurringPaymentsTableFilter from "./RecurringPaymentsTableFilter";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { globalOptions } from "../../../../GlobalAtoms";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import IconButton from "../../../TrueUI/Buttons/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet, useApiPost, usePermissions } from "../../../../hooks";
import { RecurringPaymentConfigurationDto } from "../../../../dtos/recurring-payment-configuration-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { addAllOption } from "../../../../utilities/selectFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import BaseGridSearchFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";

const name = "recurring_payments_grid";
type FiltersOptionsProps = {
  userOptions: Partial<SelectOptions>[] | SelectOptions[];
  periodOptions: Partial<SelectOptions>[] | SelectOptions[];
};
type FilterValuesProps = {
  userId: number | "all";
  endDate: string;
};
const RecurringBodyWrapper: FC = () => {
  const { getAtom } = useAtomFamily(globalOptions);
  const currentUserId = getAtom().userId;
  const hasPermission = usePermissions([2, 13, 14, 15]);

  const [deleteState, setDeleteState] = useState<any>({
    isDeleteDialogConfirmationOpen: false,
    paymentToDelete: null,
  });

  const [filtersOptions, setFiltersOptions] =
    useState<FiltersOptionsProps | null>(null);

  const [filterValues, setFilterValues] = useState<FilterValuesProps>({
    userId: currentUserId,
    endDate: "all",
  });
  const [recurringPaymentsTableData, setRecurringPaymentsTableData] =
    useState<any>(null);
  const [
    filteredRecurringPaymentsTableData,
    setFilteredRecurringPaymentsTableData,
  ] = useState<any>(null);
  const { responseGet, dispatchGet } =
    useApiGet<RecurringPaymentConfigurationDto>(
      `api/Payment/GetRecurringPaymentsConfiguration`
    );

  const {
    responseGet: responseGetRecurringPaymentsTable,
    dispatchGet: dispatchGetRecurringPaymentsTable,
  } = useApiGet<any>(
    `api/Payment/GetRecurringPayments${
      filterValues.endDate === "all" ? "" : `?endDate=${filterValues.endDate}`
    }`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    dispatchGetRecurringPaymentsTable();
  }, [filterValues.endDate]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const data = responseGet?.axiosResponse?.data;
      setFiltersOptions({
        userOptions: addAllOption(data?.userOptions ?? []),
        periodOptions: addAllOption(data?.periodOptions ?? []),
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (filtersOptions !== null) {
      dispatchGetRecurringPaymentsTable();
    }
  }, [filtersOptions]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetRecurringPaymentsTable)) {
      setRecurringPaymentsTableData(
        responseGetRecurringPaymentsTable.responseData.tableData ?? null
      );
    }
  }, [responseGetRecurringPaymentsTable]);

  useEffect(() => {
    if (conditionHasValue(recurringPaymentsTableData)) {
      filterDataTable();
    }
  }, [recurringPaymentsTableData]);

  useEffect(() => {
    filterDataTable();
  }, [filterValues.userId]);

  const filterDataTable = () => {
    const processedByColumnIndex = 11;
    const filteredDataByProcessedBy = recurringPaymentsTableData?.data?.filter(
      (row) =>
        Number(row[processedByColumnIndex]) === filterValues.userId ||
        filterValues.userId === "all"
    );

    setFilteredRecurringPaymentsTableData({
      columns: recurringPaymentsTableData?.columns ?? [],
      data: filteredDataByProcessedBy ?? [],
    });
  };

  useEffect(() => {
    if (filteredRecurringPaymentsTableData !== null) {
      manuallyReloadParameters();
    }
  }, [filteredRecurringPaymentsTableData]);

  const deletePaymentUrl = `api/Payment/DeletePayment${
    deleteState?.paymentToDelete !== null
      ? `?paymentId=${deleteState.paymentToDelete.PaymentId}`
      : ""
  }`;

  const removeRow = (paymentId: number) => {
    const paymentIdIndex = 0;
    setFilteredRecurringPaymentsTableData({
      ...filteredRecurringPaymentsTableData,
      data: filteredRecurringPaymentsTableData.data.filter(
        (x) => Number(x[paymentIdIndex]) !== paymentId
      ),
    });
  };

  const { responsePost: deleteResponsePost, dispatchPost: deleteDispatchPost } =
    useApiPost(deletePaymentUrl);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(deleteResponsePost)) {
      removeRow(deleteState.paymentToDelete.PaymentId);
      setDeleteState({
        paymentToDelete: null,
        isDeleteDialogConfirmationOpen: false,
      });
    }
  }, [deleteResponsePost]);

  const onDeletePayment = (payment: any) => {
    setDeleteState({
      paymentToDelete: payment,
      isDeleteDialogConfirmationOpen: true,
    });
  };

  const cancelDelete = (close: boolean) => {
    setDeleteState({
      paymentToDelete: null,
      isDeleteDialogConfirmationOpen: close,
    });
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      onClick={() => {
        onDeletePayment(actionOptions.row);
      }}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  const onChangePeriod = (value) => {
    setFilterValues({ ...filterValues, endDate: value });
  };

  const onChangeUser = (value) => {
    setFilterValues({ ...filterValues, userId: value });
  };

  const tableConfiguration: BaseGridProperties = {
    name,
    useManuallyReloadParameters: true,
    disableInitialize: true,
    columnsAndData: {
      columns: filteredRecurringPaymentsTableData?.columns ?? [],
      data: filteredRecurringPaymentsTableData?.data ?? [],
    },
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
    },
    filterOptions: [
      () =>
        RecurringPaymentsTableFilter({
          id: "show_period",
          options: filtersOptions?.periodOptions ?? [],
          labelText: "Show:",
          value: filterValues.endDate,
          onChange: onChangePeriod,
        }),
      () =>
        RecurringPaymentsTableFilter({
          id: "processed_by",
          options: filtersOptions?.userOptions ?? [],
          labelText: "Processed By:",
          value: filterValues.userId,
          onChange: onChangeUser,
        }),
      (actionOptions) =>
        BaseGridSearchFilter({
          options: actionOptions,
          id: "recurring_payments_grid_search_filter",
          columnNames: ["ClaimNumber", "PayeeName"],
          columnDisplayNames: ["claim number", "payee name"],
          isHidden: false,
        }) as ReactElement,
    ],
    columnOptions: [
      { fieldName: "ProgramCode", width: 12 },
      { fieldName: "ClaimNumberLink", width: 10 },
      { fieldName: "PaymentDate", width: 7 },
      { fieldName: "PaymentCategory", width: 10 },
      { fieldName: "PayeeName", width: 12 },
      { fieldName: "Comments", width: 20 },
      { fieldName: "Status", width: 10 },
      { fieldName: "PaidAmount", width: 10, align: "right" },
      { fieldName: "CreateBy", width: 7 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: !hasPermission,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  return (
    <Box className="recurring_payments_wrapper" sx={RecurringPaymentsWrapper}>
      <Font fontType="HEADING_6" textAlign="start">
        Recurring Payments
      </Font>
      {<BaseGrid name={name} />}
      <DialogConfirmation
        id="deleteRecurringPayment"
        open={deleteState.isDeleteDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this recurring payment?"
        onCloseEvent={cancelDelete}
        onOptionNoEvent={cancelDelete}
        onOptionYesEvent={() => {
          deleteDispatchPost();
        }}
      />
    </Box>
  );
};

export default RecurringBodyWrapper;
