import { FC } from "react";
import { Collapse } from "../../../TrueUI";
import { NoteProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import NoteContent from "./NoteContent";
import NoteHeader from "./NoteHeader";

const Note: FC<NoteProps> = ({
  tabKey,
  noteIndex,
  note,
  isCollapsed,
  editEvent,
  collapseBottomMargin,
}) => {
  return (
    <Collapse
      key={noteIndex.toString()}
      expanded={note.expanded}
      id={noteIndex.toString()}
      hasContentOnReadOnly
      hasBottomMargin={collapseBottomMargin}
      onChange={isCollapsed}
      title={<NoteHeader tabKey={tabKey} note={note} editEvent={editEvent} />}
      content={
        <NoteContent
          expanded={note.expanded ?? false}
          formattedNarrativeText={note.formattedNarrativeText}
        />
      }
    />
  );
};

export default Note;
