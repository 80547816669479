import { BaseTableColumn } from "../../../dtos/base-table-column";
import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";
import { hydrateDataSingle } from "./tableFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

export const renderRowProgressBar = (progressBackgroundColor, percentage) => {
  const restBackgroundColor = progressBackgroundColor + 20;
  const correctPercentage = isNaN(percentage) ? 0 : percentage;
  return {
    backgroundImage: `linear-gradient(to right, ${progressBackgroundColor} ${correctPercentage}%, ${restBackgroundColor} ${correctPercentage}%)`,
  };
};

export const addFontStyle = (
  color: string,
  fontFamily?: string,
  fontWeight?: number
) => ({
  color,
  ...(conditionHasValue(fontFamily) && { fontFamily }),
  ...(conditionHasValue(fontWeight) && { fontWeight }),
});

const getProgressBarStyle = (
  columns: BaseTableColumn[],
  row: string[],
  progressBar?: any
) => {
  if (conditionHasValue(progressBar)) {
    const progressBarInfo = progressBar?.(hydrateDataSingle(columns, row));
    return renderRowProgressBar(
      progressBarInfo?.color,
      progressBarInfo?.percentage
    );
  }
  return {};
};

const getFontStyle = (
  columns: BaseTableColumn[],
  row: string[],
  fontConfig?: any
) => {
  if (conditionHasValue(fontConfig)) {
    const fontStyles = fontConfig?.(hydrateDataSingle(columns, row));
    return addFontStyle(
      fontStyles.fontColor,
      fontStyles.fontFamily,
      fontStyles.fontWeight
    );
  }
  return {};
};

export const getStylingRow = (
  columns: BaseTableColumn[],
  row: string[],
  rowStyle?: any
) => {
  if (conditionHasValue(rowStyle)) {
    const progressBarStyles = getProgressBarStyle(
      columns,
      row,
      rowStyle.progressBar
    );
    const fontStyles = getFontStyle(columns, row, rowStyle.fontStyle);

    return { ...progressBarStyles, ...fontStyles };
  }
  return {};
};

const commonHeaderDataStyles = {
  width: "100%",
  padding: "6px 7px",
  borderTop: "none",
  textAlign: "left",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

// BASETABLE TODO - organize this file a little better. Continue to decouple nested class names for easier, faster reading.

export const tableStyles: any = (theme: Theme, height: string) => {
  return {
    ///////////////////// MEDIA QUERIES
    "@media (max-width: 941px)": {
      ".toolbar_container_actions, .toolbar_exports, .toolbar_buttons, .toolbar_filters, .toolbar_exports > div, .toolbar_buttons > div":
        {
          width: "100%",
        },
      ".toolbar_filters > div, .toolbar_exports > div": {
        width: "45%",
      },
      ".base_table_toolbar": {
        paddingBottom: "3px !important",
      },
    },
    ///////////////////// WRAPPER
    ".true_ui_base_table_container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "stretch",
      flexDirection: "column",
      flexWrap: "nowrap",

      width: "100%",
      height: height === "parent_flex" ? "100%" : "",
    },
    /////////////////// SUB WRAPPER
    ".true_table_wrapper": {
      backgroundColor: "transparent",
      alignSelf: "auto",
      display: "flex",
      height: "100%",
      minHeight: "0",
      maxHeight: "100%",
    },
    ///////////////////////////// TOOLBAR
    ".true_ui_base_table_toolbar": {
      display: "flex",

      alignSelf: "auto",
      alignItems: "flex-start",

      paddingBottom: "0px",

      borderWidth: "1px 1px",

      border: `1px solid ${theme?.BORDER_COLOR}`,
      marginBottom: "5px",

      padding: "5px",
      flexWrap: "wrap",
    },
    ".base_table_toolbar > .reset_table_filters": {
      span: {
        fontSize: theme?.HEADING_6?.SIZE,
        color: theme?.TEXT_COLOR,
      },
    },
    ".reset_table_filters > button": {
      padding: "0px !important",
    },
    "base_table_toolbar > .true_search_filter": {
      borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
      backgroundColor: theme?.SECONDARY,
      borderRadius: "10px 10px 0 0",
    },
    "base_table_toolbar .true_search_filter input::placeholder": {
      opacity: 1,
      color: theme?.TEXT_COLOR,
    },

    ".toolbar_container_actions": {
      display: "flex",

      flexGrow: 1,
    },
    ".true_search_filter": {
      borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
      backgroundColor: theme?.SECONDARY,
      borderRadius: "10px 10px 0 0",
      padding: "0 10px",
      "input::placeholder": {
        opacity: 1,
        color: theme?.TEXT_COLOR,
      },
    },
    ".toolbar_buttons": {
      marginLeft: "10px",
      display: "flex",
    },
    ".toolbar_filters > div:not(:last-child)": {
      marginRight: "5px",
    },
    ".toolbar_exports > div:not(:last-child)": {
      marginRight: "10px",
    },
    ".toolbar_buttons > div:not(:last-child)": {
      marginRight: "10px",
    },
    ".toolbar_filters": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    ".toolbar_filters .true_input_general_container .true_input_helper_text": {
      marginBottom: 0,
      marginTop: 0,
    },
    ".toolbar_filters .true_search_filter": {
      maxHeight: "32px",
    },
    ".toolbar_exports": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      // padding: "2px",
    },
    /////////////////////// MIXED //////////////////////// BASETABLE TODO - needs additional clean-up
    ".true_table_body, .true_table_head": {
      ".true_table_row": {
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-between",
      },
    },
    // ".cell-collapse": {
    //   width: "35px !important",
    //   padding: "0px 0px 0px 1px !important",
    // },
    ".cell_header_initial_gap": {
      width: "5px !important",
    },
    ".cell_header_final_gap": {
      width: "35px !important",
    },
    ".true_table_row.selected": {
      backgroundColor: theme.SELECTED_ROW_BACKGROUND,
      ".true_input_general_container": {
        background: "transparent",
        ".true_input_container": {
          background: theme.SELECTED_ROW_BACKGROUND,
          ".true_input.darkTheme": {
            input: {
              color: theme.SECONDARY_BACKGROUND,
            },
          },
          ".true_input_select_container.darkTheme": {
            span: {
              color: theme.SECONDARY_BACKGROUND,
            },
          },
        },
      },
    },
    /////////////////////// TABLE
    ".true_ui_table": {
      minWidth: "350px" /* SM size on Material UI ??? 250px*/,
      // height: "calc(100% - 47px)", // Note: 33px is the height of the table column headers.
      height: "100%", // TODO - temp height. In the future this will be 100%
      maxHeight: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "stretch",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "flex-start",
    },
    ".true_table_head": {
      backgroundColor: theme?.SECONDARY_BACKGROUND,
    },
    /////////////////////// HEADER
    ".true_table_header_data": {
      display: "flex",
      alignItems: "center",
      borderLeft: `1px solid ${getColorWithOpacityHexaDecimal(
        theme?.PRIMARY_BACKGROUND,
        30
      )}`,
      height: "35px",
      background: theme?.SECONDARY_BACKGROUND,
      fontWeight: theme?.BODY?.WEIGHT,
      fontSize: theme?.BOLD_SMALL_TITLE?.SIZE,
      color: theme?.CONTRAST_TEXT_COLOR,
      ...commonHeaderDataStyles,
      ".column-name": {
        color: theme?.CONTRAST_TEXT_COLOR,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "80%",
      },
      ".MuiTableSortLabel-root": {
        width: "100%",
        justifyContent: "space-between",
        overflow: "hidden",
        svg: {
          color: theme?.CONTRAST_TEXT_COLOR,
          opacity: 1,
        },
        "&.options-column": {
          display: "none",
        },
        "&:hover": {
          color: theme?.CONTRAST_TEXT_COLOR,
        },
      },
    },
    //////////////////////// MULTI - HEADER
    ".multi_cell_collapse": {
      // width: "100% !important",
      padding: "0px 0px 0px 1px !important",

      alignItems: "center",
      borderLeft: "1px solid #FFFFFF4D",
      background: "#8E8EA4",
      fontWeight: "400",
      fontSize: "12px",
      color: "#F6F6F9",
      width: "100%",
      borderTop: "none",
      textAlign: "left",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    ".true_collapse_wrapper.multi_table .cell-collapse button": {
      padding: "4px !important",
    },
    //////////////////////// HEADER RELATED
    ".true_table_head > .true_table_row": {
      marginRight: "5px",
    },
    ".true_table_head .true_table_row .true_table_header_data": {
      // padding: "6px 1px 6px 7px !important",
      padding: "0px 0px 0px 6px",
    },
    //////////////////////// BODY
    ".true_table_body": {
      overflow: "auto",
      height: "100%",
    },
    ".true_table_body .true_table_no_results_found": {
      height: "100%",
      alignItems: "center",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
    },
    //////////////////////// BODY DATA
    ".true_table_body_data": {
      width: "100%",
      padding: "0px 7px",
      borderBottom: `1px solid ${getColorWithOpacityHexaDecimal(
        theme?.BORDER_COLOR,
        30
      )}`,
      textAlign: "left",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: theme?.TEXT_COLOR,
      display: "flex",
      alignItems: "center",
    },
    /////////////////////// SCROLL BAR
    ".true_table_wrapper, .true_table_body, .virtual-body": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme?.SECONDARY_BACKGROUND,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme?.SECONDARY_BACKGROUND,
      },
    },
    ////////////////////// SUB FOOTER
    ".base_table_sub_footer_component": {
      diplay: "flex",
      flexGrow: 1,
    },
    ////////////////////// FOOTER
    ".true_table_footer": {
      background: theme?.SECONDARY_BACKGROUND,
    },
    ".true_table_footer > .true_table_row": {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
    },
    ".true_table_footer .true_table_row .true_table_header_data": {
      borderTop: "none",
    },
    ////////////////////// PAGINATION
    ".true_pagination": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px",
      position: "relative",
      background: theme?.PRIMARY_BACKGROUND,
      border: `1px solid ${theme?.BORDER_COLOR}`,
    },
    ".true_pagination .kendo-true-pagination": {
      display: "flex",
      justifyContent: "space-between",
      width: "30%",
    },
    ".true_pagination .kendo-true-pagination .k-button:not(.k-icon-button)": {
      color: theme?.PRIMARY,
      borderRadius: "30px",
      width: "30px",
    },

    ///////////////////// PHONE
    ".container-phone-table-cell .true_input_general_container": {
      minWidth: "115px",
    },
    ".container-phone-table-cell .true_phone_container": {
      maxWidth: "115px",
    },
    ".container-phone-table-cell .true_phone_container .true_input_container": {
      display: "flex !important",
      alignItems: "center",
      flexWrap: "nowrap",
    },
    ".container-phone-table-cell .true_extension_container .true_input_container":
      {
        display: "flex !important",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    ".container-phone-table-cell .true_extension_container .true_input_container .phone-table-cell-extension":
      {
        marginLeft: "5px",
      },

    ////////////////////// LINK
    ".true_table_body_data .true_input_container .inputLinkCell": {
      // TODO - update the name "inputLinkCell" as it's not up to par with naming standards
      WebkitTextFillColor: `${theme?.PRIMARY} !important`,
      cursor: "pointer !important",
    },
    ".true_table_body_data > .inputLinkCell.read-only input": {
      pointerEvents: "auto",
    },
    ".linkCell-container": {
      width: "100%",
    },
    ///////////////////// ERROR
    ".errors-container": {
      textAlign: "left",
      color: theme?.DANGER,
      marginLeft: "8px",
      fontSize: theme?.SMALL_TITLE?.SIZE,
      borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
    },
    ".true_table_row": {
      "&.error": {
        ".true_table_body_data": {
          borderBottom: "none !important",
        },
      },
      "&.deleted": {
        ".highlight": {
          backgroundColor: theme?.DANGER,
        },
        ".hidden": {
          display: "none !important",
        },
      },
    },
    ".compact-toolbar": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 5px",
      minWidth: "120px",
      backgroundColor: getColorWithOpacityHexaDecimal(
        theme?.SECONDARY_BACKGROUND,
        60
      ),
      borderRadius: "5px 5px 0 0",
      "&.transparent": {
        backgroundColor: "transparent",
      },
      ".filters-container": {
        display: "flex",
      },
    },
    //////////////// Override with row styles if present
    ".with_font_style": {
      ".true_table_body_data": {
        color: "inherit",
        ".true_input_general_container, .true_input_container": {
          color: "inherit",
          input: {
            color: "inherit !important",
          },
        },
      },
    },
    //////////////////// COLLAPSE
    ".true_table_body .true_collapse_wrapper": {
      height: "auto",
    },
    ".true_collapse_wrapper.multi_table .collapse_content_accordion": {
      padding: "5px 0px 5px 5px",
    },
    ".true_collapse_wrapper.multi_table .true_collapse_title_accordion": {
      minHeight: "40px",
      padding: "0px 6px",
    },
    ".true_collapse_wrapper.multi_table .true_collapse_title_accordion > div": {
      margin: "3px 0 !important",
    },

    ////////////// SELECT ON TABLE
    ".true_table_row.selected .true_table_body_data .select_true_input_general_container":
      {
        background: "transparent !important",
      },
    ".true_table_row.selected .true_table_body_data .select_true_input_container":
      {
        background: "transparent !important",
      },
  };
};
