import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import { ProgramClaimFormDto } from "../../../../../../dtos/program-claim-form-dto";
import { ColProps } from "../../../../../TrueUI/Grids/Col";

// TYPES /////////////////////////
export type ClaimCorrespondenceProps = {
  claimJurisdiction: string;
  insuredId: number;
  tabKey: string;
  claimId?: number;
  claimNumber?: string;
};

export type ClaimCorrespondenceCategoriesProps = {
  tabKey: string;
  categories: ClaimReferenceDto[] | null;
  selectedCategories: ClaimReferenceDto[] | null;
  setSelectedCategories: (categories: ClaimReferenceDto[] | null) => void;
  allForms: ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[];
  setForms: (
    forms: ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[]
  ) => void;
  insuredId?: number;
};

export type ClaimCorrespondenceCategoryCheckboxProps = {
  category: ClaimReferenceDto;
  categorySelected: (category: ClaimReferenceDto, selected: boolean) => void;
};

export type ClaimCorrespondenceFormCheckboxProps = {
  form: Partial<ProgramClaimFormDto>;
  formSelected: (form: Partial<ProgramClaimFormDto>, selected: boolean) => void;
  checkedFormIds: SelectedClaimFormProps[];
};
export type CategorySelectedProp = {
  categoryId: string;
  selected: boolean;
};

export type SelectedClaimFormProps = {
  claimFormId: number;
  formName: string;
  templateName: string;
  sortOrder: number;
};

export type ClaimCorrespondenceFormsProps = {
  insuredId?: number;
  claimJurisdiction: string;
  forms: ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[];
  categories: ClaimReferenceDto[] | null;
  tabKey: string;
};
//////////////////////////////////////
// CONSTANTS /////////////////////////
export const colProps: ColProps = {
  horizontalMargin: "0px",
  verticalGutter: "10px",
  horizontalGutter: "10px",
  breakpoints: { md: 3, lg: 3, xl: 3 },
};

export const CLAIM_FORMS_API_PATH = "api/ProgramClaimForm";
//////////////////////////////////////
