import { FC, useEffect, useState } from "react";
import { PolicyLimitDto } from "../../../../../../dtos/policy-limit-dto";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPreviousRating,
  getPreviousRunningTotal,
  getStatesUpdatedByRowResult,
  getSumOfAllPolicyLimitAmount,
  isCurrentStateTheGoverningState,
} from "./PremiumTableRowsUtils";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { Font } from "../../../../../TrueUI";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
} from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import { useRecoilValue } from "recoil";
import {
  BALANCE_MIN_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { PolicyStateBlob } from "../../../../../../dtos/policy-state-blob";
import {
  customRound,
  getNumberAsStringWithComas,
} from "../../../../../../utilities/stringFunctions";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";

const getRunningTotalAndCalculatedAmount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  policyLimitOptions: PolicyLimitDto[],
  selectedPolicyLimitId: number,
  sumOfAllPolicyLimitRunningTotal,
  atomValue: GlobalInsuredAtomProperties | null,
  rating: PolicyRatingBlob
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const selectedOption = policyLimitOptions.filter(
    (policyLimit) => policyLimit.id === selectedPolicyLimitId
  );

  const policyLimitMinAmount =
    selectedOption.length > 0 ? selectedOption[0]?.minimumValue ?? 0 : 0;

  const balanceToMinimumAmount =
    sumOfAllPolicyLimitRunningTotal < policyLimitMinAmount
      ? policyLimitMinAmount - sumOfAllPolicyLimitRunningTotal
      : 0;

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const newRunningTotal = customRound(
    (previousRunningTotal + balanceToMinimumAmount).toString(),
    rating.rounding ?? 0
  );

  const rowResult: PremiumTableRowResultProps = {
    rate: balanceToMinimumAmount,
    calculatedAmount: balanceToMinimumAmount,
    runningTotal: newRunningTotal,
  };

  return rowResult;
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  policyLimitOptions: PolicyLimitDto[],
  atomValue: GlobalInsuredAtomProperties | null,
  currentStates: PolicyStateBlob[],
  rating: PolicyRatingBlob
) => {
  const previousRating = getPreviousRating(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    atomValue
  );

  const sumOfAllPolicyLimitRunningTotal =
    getSumOfAllPolicyLimitAmount(currentStates);

  const calculationResults = getRunningTotalAndCalculatedAmount(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    policyLimitOptions,
    previousRating?.policyLimits?.policyLimitsID ?? 0,
    sumOfAllPolicyLimitRunningTotal,
    atomValue,
    rating
  );

  const statesUpdatedByCalculations = getStatesUpdatedByRowResult(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    atomValue
  );

  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const ELBalanceToMinRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );

  const [showRow, setShowRow] = useState<boolean>(false);

  const listenerBalanceToMinRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${BALANCE_MIN_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  const runCalculations = () => {
    const atomValue = getAtom();
    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );
    const { isGoverningState } = isCurrentStateTheGoverningState(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      currentStates
    );
    const updatedValues = getStatesUpdatedByCalculations(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      props.rateIndex,
      props.configurations?.policyLimitsOptions ?? [],
      atomValue,
      currentStates,
      props.rating
    );
    setAtom(updatedValues.newAtomValue);
    setRowResult(updatedValues.rowResult);
    setShowRow(isGoverningState);
    props.calculationsFinished?.();
  };

  useEffect(() => {
    if (listenerBalanceToMinRowComponent !== null && !props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerBalanceToMinRowComponent]);

  return (
    <>
      {showRow ? (
        <div className={style.premium_row_container}>
          <div className={style.premium_row_name_cell_5}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
              {props.rating.elementName ?? ""}
            </Font>
          </div>
          <div className={style.premium_row_rate_cell}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
              {getNumberAsStringWithComas(rowResult.rate)}
            </Font>
          </div>
          <div className={style.premium_row_calculated_amount_cell}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
              {getNumberAsStringWithComas(rowResult.calculatedAmount)}
            </Font>
          </div>
          {props.rating.previousRunningTotal !== null &&
            hasQuotedRunningTotal && (
              <div className={style.premium_row_previous_running_total_cell}>
                <Font
                  trueElement={`${getNameToExposureTableColumns(props, 3)}`}
                >
                  {getNumberAsStringWithComas(
                    props.rating.previousRunningTotal ?? 0
                  )}
                </Font>
              </div>
            )}
          <div
            className={
              hasQuotedRunningTotal
                ? style.premium_row_running_total_cell_10
                : style.premium_row_running_total_cell_7
            }
          >
            <Font
              trueElement={`${getNameToExposureTableColumns(
                props,
                hasQuotedRunningTotal ? 4 : 3
              )}`}
            >
              {getNumberAsStringWithComas(rowResult.runningTotal)}
            </Font>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ELBalanceToMinRow;
