import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";

import { useEffect } from "react";
import {
  BaseGridCellManagerProperties,
  BaseGridCellProperties,
  RowKey,
} from "../BaseGridProperties";
import { GridCellInstanceAtomFamily } from "../BaseGridAtoms";

export function useGridCellManager(options: {
  uiid?: string;
  rowKey?: RowKey;
  initialConfiguration?: any;
}): {
  cellInstance: Required<BaseGridCellManagerProperties>;
  setCellInstance: (
    newValueTableInstance: BaseGridCellManagerProperties
  ) => void;
  updateAllCellInstancesWithSameValues: (
    listOfCellRowKeys: RowKey[],
    cellInstanceData: Partial<BaseGridCellManagerProperties>
  ) => any;
} {
  const setCellInitialInstance = useRecoilCallback(
    ({ set }) =>
      (cellInitialInstance: any) => {
        set(
          GridCellInstanceAtomFamily(options?.rowKey ?? "NO_ROWKEY_FOUND"),
          cellInitialInstance
        );
      },
    []
  );

  useEffect(() => {
    if (options?.initialConfiguration) {
      setCellInitialInstance(options?.initialConfiguration);
    }
  }, []);

  return {
    cellInstance: useRecoilValue(
      GridCellInstanceAtomFamily(
        options?.rowKey ?? "NO_ROWKEY_FOUND"
      ) as RecoilState<Required<BaseGridCellManagerProperties>>
    ),
    setCellInstance: useRecoilCallback(
      ({ snapshot, set }) =>
        (cellInstanceData: BaseGridCellManagerProperties) => {
          const cellInstance = snapshot.getLoadable(
            GridCellInstanceAtomFamily(options?.rowKey ?? "NO_ROWKEY_FOUND")
          ).contents as BaseGridCellProperties;
          set(
            GridCellInstanceAtomFamily(options?.rowKey ?? "NO_ROWKEY_FOUND"),
            {
              ...cellInstance,
              ...cellInstanceData,
            }
          );
        },
      []
    ),

    updateAllCellInstancesWithSameValues: useRecoilCallback(
      ({ snapshot, set }) =>
        (
          listOfCellRowKeys: RowKey[],
          cellInstanceData:
            | Partial<BaseGridCellManagerProperties>
            | ((
                instance?: BaseGridCellManagerProperties
              ) => BaseGridCellManagerProperties)
        ) => {
          listOfCellRowKeys.map((rowKey) => {
            const cellManagerInstance = snapshot.getLoadable(
              GridCellInstanceAtomFamily(rowKey)
            ).contents as BaseGridCellManagerProperties;

            const updatedCellManagerInstance = {
              ...cellManagerInstance,
              ...cellInstanceData,
            };
            set(GridCellInstanceAtomFamily(rowKey), updatedCellManagerInstance);
          });
        },
      []
    ),
  };
}
