import { FC } from "react";
import { Modal } from "../../TrueUI";
import { Search } from "../../Nav/TopNav";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  INCIDENT_BODY_SECTION,
  INCIDENT_ID,
  INCIDENT_STEPPER_SECTION,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
} from "../../../utilities/queryStringsHash";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { IncidentBodySectionEnums } from "../../../dtos/incident-body-section-enums";

type AddIncidentModalProperties = {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
};

const AddIncidentModal: FC<AddIncidentModalProperties> = ({
  showModal = false,
  setShowModal,
}) => {
  const hashPath = getQueryStringsURL([
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
    },
    {
      nameOfHash: INCIDENT_STEPPER_SECTION,
      valueOfHash: 0,
    },
    {
      nameOfHash: INCIDENT_ID,
      valueOfHash: 0,
    },
    {
      nameOfHash: INCIDENT_BODY_SECTION,
      valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
    },
  ]);

  return (
    <Modal
      id="search-insured-for-new-incident"
      title="Search Insured to Add New Incident"
      open={showModal}
      closeEvent={(e) => setShowModal(e)}
      size={"sm"}
      showCloseButton
    >
      <div style={{ position: "relative" }}>
        <Search customHashPath={hashPath} insuredOnly searchOnEnterOnly />
      </div>
    </Modal>
  );
};
export default AddIncidentModal;
