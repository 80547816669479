import { RecoilValue, useRecoilCallback } from "recoil";
import {
  BaseGridInternalProperties,
  BaseGridProperties,
} from "../BaseGridProperties";
import {
  GridInstanceAtomFamily,
  scopedBaseGridPropertiesInternalSelector2,
  scopedBaseGridPropertiesSelector2,
} from "../BaseGridAtoms";

export function useGridInstance(
  uiid: string,
  _source: string // This is used for debugging
): {
  instanceSelector: {
    (): RecoilValue<BaseGridProperties>;
    <T extends keyof BaseGridProperties>(param: T): RecoilValue<
      BaseGridProperties[T] | null
    >;
  };
  instanceInternalSelector: {
    (): RecoilValue<BaseGridInternalProperties>;
    <T extends keyof BaseGridInternalProperties>(param: T): RecoilValue<
      BaseGridInternalProperties[T] | null
    >;
  };
  setInstance: (newValueTableInstance: BaseGridProperties) => void;
  setInternalInstance: (
    newValueTableInstance:
      | BaseGridInternalProperties
      | Partial<BaseGridInternalProperties>
  ) => void;
  setEntireInstance: (newValueTableInstance: {
    BaseGridProperties?: BaseGridProperties | Partial<BaseGridProperties>;
    BaseGridInternalProperties?:
      | BaseGridInternalProperties
      | Partial<BaseGridInternalProperties>;
    isReadyToRender?: boolean;
  }) => void;
  setEntireInstanceWithInstanceCallback: (
    cb: (
      newValueTableInstance: {
        BaseGridProperties?: BaseGridProperties | Partial<BaseGridProperties>;
        BaseGridInternalProperties?:
          | BaseGridInternalProperties
          | Partial<BaseGridInternalProperties>;
      } | null
    ) => {
      BaseGridProperties?: BaseGridProperties;
      BaseGridInternalProperties?: BaseGridInternalProperties;
    }
  ) => void;
} {
  // external
  function instanceSelector(): RecoilValue<BaseGridProperties>;
  function instanceSelector<T extends keyof BaseGridProperties>(
    param: T
  ): RecoilValue<BaseGridProperties[T]>;
  function instanceSelector(propertyName?: keyof BaseGridProperties) {
    return scopedBaseGridPropertiesSelector2({
      uiid: uiid,
      propertyName: propertyName,
    }) as any;
  }

  // internal
  function instanceInternalSelector(): RecoilValue<BaseGridInternalProperties>;
  function instanceInternalSelector<T extends keyof BaseGridInternalProperties>(
    param: T
  ): RecoilValue<BaseGridInternalProperties[T]>;
  function instanceInternalSelector(
    propertyName?: keyof BaseGridInternalProperties
  ) {
    return scopedBaseGridPropertiesInternalSelector2({
      uiid: uiid,
      propertyName: propertyName,
    }) as any;
  }

  return {
    instanceSelector,
    instanceInternalSelector,
    setInstance: useRecoilCallback(
      ({ snapshot, set }) =>
        (newValueTableInstance: BaseGridProperties) => {
          if (uiid === undefined) {
            console.error("setInstance - NO UIID FOUND");
          }

          const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
            .contents as {
            BaseGridProperties: BaseGridProperties;
            BaseGridInternalProperties: BaseGridInternalProperties;
          };
          set(GridInstanceAtomFamily(uiid), {
            ...instance,
            BaseGridProperties: {
              ...instance.BaseGridProperties,
              ...newValueTableInstance,
            },
          });
        },
      []
    ),
    setInternalInstance: useRecoilCallback(
      ({ snapshot, set }) =>
        (
          newValueTableInstance:
            | BaseGridInternalProperties
            | Partial<BaseGridInternalProperties>
        ) => {
          if (uiid === undefined) {
            console.error("setInternalInstance - NO UIID FOUND");
          }

          const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
            .contents as {
            BaseGridProperties?: BaseGridProperties;
            BaseGridInternalProperties?: BaseGridInternalProperties;
          } | null;
          const result = {
            ...instance,
            BaseGridInternalProperties: {
              ...instance?.BaseGridInternalProperties,
              ...newValueTableInstance,
            },
          } as {
            BaseGridProperties?: BaseGridProperties;
            BaseGridInternalProperties?: BaseGridInternalProperties;
            isReadyToRender?: boolean;
          };
          set(GridInstanceAtomFamily(uiid), result);
        },
      []
    ),
    setEntireInstance: useRecoilCallback(
      ({ snapshot, set }) =>
        (newValueTableInstance: {
          BaseGridProperties?: BaseGridProperties | Partial<BaseGridProperties>;
          BaseGridInternalProperties?:
            | BaseGridInternalProperties
            | Partial<BaseGridInternalProperties>;
        }) => {
          // TODO - fix this as it currently does not work. I just copied it from setInternalInstance.
          if (uiid === undefined) {
            console.error("setEntireInstance - NO UIID FOUND");
          }

          const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
            .contents as {
            BaseGridProperties?: BaseGridProperties;
            BaseGridInternalProperties?: BaseGridInternalProperties;
            isReadyToRender?: boolean;
          } | null;
          const mergedInstance = {
            ...instance,
            ...newValueTableInstance,
            BaseGridProperties: {
              ...instance?.BaseGridProperties,
              ...newValueTableInstance.BaseGridProperties,
            },
            BaseGridInternalProperties: {
              ...instance?.BaseGridInternalProperties,
              ...newValueTableInstance.BaseGridInternalProperties,
            },
          };

          set(GridInstanceAtomFamily(uiid), mergedInstance as any);
        },
      []
    ),
    setEntireInstanceWithInstanceCallback: useRecoilCallback(
      ({ snapshot, set }) =>
        (
          cb: (
            entireInstance: {
              BaseGridProperties?: BaseGridProperties;
              BaseGridInternalProperties?: BaseGridInternalProperties;
            } | null
          ) => {
            BaseGridProperties?: BaseGridProperties;
            BaseGridInternalProperties?: BaseGridInternalProperties;
          }
        ) => {
          // TODO - fix this as it currently does not work. I just copied it from setInternalInstance.
          if (uiid === undefined) {
            console.error("setEntireInstance - NO UIID FOUND");
          }
          const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
            .contents as {
            BaseGridProperties?: BaseGridProperties;
            BaseGridInternalProperties?: BaseGridInternalProperties;
          } | null;

          const instanceUpdateResult = cb(instance);

          set(GridInstanceAtomFamily(uiid), instanceUpdateResult as any);
        },
      []
    ),
  };
}
