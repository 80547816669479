import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import { CircularProgress } from "@mui/material";
import {
  globalIsDisabledElementAtom,
  globalIsSaving,
} from "../../../GlobalAtoms";
import Button from "./Button";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import DialogConfirmation from "../Dialogs/DialogConfirmation";

type ButtonProperties = {
  text?: string;
  savingText?: string;
  disabled?: boolean;
  onClick?: () => any;
  children?: any;
  fullWidth?: boolean;
  sx?: any;
  permissions?: PermissionsEnums[];
  name?: string;
  textOnConfirmation?: string;
  withConfirmation?: boolean;
  tabIndex?: number;
};

const SaveButton: React.FC<ButtonProperties> = ({
  text = "SAVE",
  savingText = "",
  fullWidth = false,
  disabled = false,
  onClick,
  sx = {},
  name = "",
  permissions,
  textOnConfirmation = "Are you sure you want to save?",
  withConfirmation = false,
  tabIndex = 0,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const isSaving = useRecoilValue(globalIsSaving);
  const hasPermission = usePermissions(permissions ?? []);
  const isElementDisabled = useRecoilValue(globalIsDisabledElementAtom);

  const getDisabledState = () => {
    if (hasPermission.hasPermission === false)
      return hasPermission.hasPermission;
    if (isElementDisabled !== false) return !isElementDisabled;
    return disabled ?? false;
  };

  const _onClick = () => {
    if (withConfirmation) {
      setIsDialogConfirmationOpen(true);
    } else {
      onClick?.();
    }
  };

  return (
    <>
      <Button
        tabIndex={tabIndex}
        name={name}
        className={`true_button_contained ${disabled ? "disabled" : ""}`}
        sx={{ ...sx }}
        size={"small"}
        onClick={_onClick}
        fullWidth={fullWidth}
        isDisabled={getDisabledState()}
        permissions={permissions}
      >
        {isSaving ? (
          savingText && savingText !== "" ? (
            <>{savingText}</>
          ) : (
            <CircularProgress size={24} color="secondary" />
          )
        ) : (
          text
        )}
      </Button>
      <DialogConfirmation
        id="save-button-confirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={textOnConfirmation}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          onClick?.();
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default SaveButton;
