import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { AddPaymentBatchDto } from "../../../../dtos/add-payment-batch-dto";
import { BaseTable } from "../../../../dtos/base-table";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { useApiPost } from "../../../../hooks";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { sumFloat } from "../../../../utilities/arrayFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { currencyFormat } from "../../../../utilities/currencyFunctions";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { MULTI_SELECTION_COLUMN_FIELD_NAME } from "../../../BaseGrid/BaseGridConstants";
import { BaseTable2, Col, FontBold, Heading6, Row } from "../../../TrueUI";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  BaseTable2Properties,
  ComputeForColumnParameters,
  ConditionForCellResponse,
  ConditionForColumnResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSearchFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { batchInformation } from "./PaymentBatchAtoms";
import PrintBatch from "./PrintBatch";
import "./PaymentBatch.module.css";
import { areValuesOfObjectEmptyNullOrUndefined } from "../../../../utilities/objectFunctions";
import { PaymentBatchSectionEnums } from "../../../../dtos/payment-batch-section-enums";
import { getQueryStringsToPaymentBatchSections } from "../../../../utilities/queryStringsHashFunctions";
import { useNavigate } from "react-router";

const tableName = "payment_batch_preview";

const PaymentBatchPreview: FC = () => {
  const [dispatchPrint, setDispatchPrint] = useState<boolean>(false);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const batch = useRecoilValue(batchInformation);
  const [columnsAndData, setColumnsAndData] = useState<any>();
  const [newBatch, setNewBatch] = useState<AddPaymentBatchDto>();
  const navigate = useNavigate();
  const { responsePost, dispatchPost } = useApiPost<BaseTable>(
    `api/PaymentBatch/PreviewPaymentBatch`,
    batch
  );

  const {
    responsePost: responseSavePost,
    dispatchPost: dispatchSavePost,
    validatorErrorResponse,
  } = useApiPost<AddPaymentBatchDto>(
    `api/PaymentBatch/SavePaymentBatch`,
    newBatch
  );

  const onTriggerChange = (columnTriggers) => {
    return {
      triggerComputeFieldNames: columnTriggers,
      typeId: BaseTableInputType.MULTI_SELECTION,
    } as ConditionForCellResponse;
  };

  const onPaymentAmountChange = (options) => {
    if (options?.castedRow?.[MULTI_SELECTION_COLUMN_FIELD_NAME] === "true") {
      return {
        value: options.originalRow["PaymentAmount"],
      } as ConditionForCellResponse;
    }
    return {
      value: "",
    } as ConditionForCellResponse;
  };

  const onReferenceColumnChange = (
    options: ComputeForColumnParameters
  ): ConditionForColumnResponse => {
    const counter = { value: batch.referenceNo ?? 0 };

    const isMultiSelectionFalse =
      options?.castedRow?.[MULTI_SELECTION_COLUMN_FIELD_NAME] === "true";

    const newTargetFields = options.columnData.map((data) => {
      if (
        // If selected row is false take this condition.
        (isMultiSelectionFalse &&
          data.value === "" &&
          data.rowKey !== options.rowKey) ||
        // If selected row is true take this other condition.
        (!isMultiSelectionFalse &&
          (data.rowKey === options.rowKey || data.value === ""))
      ) {
        return {
          ...data,
          value: "",
        };
      }

      const newValue = { ...data, value: counter.value };
      counter.value += 1;
      return newValue;
    });

    return {
      targetFields: newTargetFields,
    } as ConditionForColumnResponse;
  };

  const onCheckEvent = (checkedItems) => {
    const paymentIds = checkedItems.flatMap((row) =>
      row["GroupOfPayments"].split(",").map((item) => parseInt(item.trim()))
    );

    setNewBatch({ ...newBatch, listOfPayments: paymentIds });
  };

  const getAmountSum = (rows) => {
    return sumFloat(
      rows?.map((row) => {
        return row?._multi_selection_column !== "false"
          ? row?.PaymentAmount.replace("$", "") ?? 0
          : 0;
      })
    );
  };

  const CustomFooterComponent = (rows) => {
    return (
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={13} rowHeight="50px">
        <Col breakpoints={{ md: 11.5, lg: 11.5, xl: 11.5 }}></Col>
        <Col
          breakpoints={{ md: 1.5, lg: 1.5, xl: 1.5 }}
          horizontalAlign="flex-end"
          verticalAlign="center"
        >
          <FontBold textAlign="start">
            {currencyFormat(getAmountSum(rows), true, 2)}
          </FontBold>
        </Col>
      </Row>
    );
  };

  const config: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: columnsAndData?.columns as any,
      data: columnsAndData?.data as any,
    },
    tableType: "standard",
    toolbarOptions: {
      showImportButton: false,
      addButtonText: "Process Payments",
      showEditButton: true,
      showSortFilter: false,
      editButtonText: "Cancel",
      showSaveButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableSearchFilter(
          actionOptions,
          ["ReferenceNumbersToSearch", "ReferenceNumbers"],
          ["check no."]
        ),
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: false,
    },
    events: {
      addEventOverrideCallback: () => {
        setDialogConfiguration({
          open: true,
          dialogDescriptionText:
            "To process this batch of payments click the Save button below. A PDF of the check batch will appear in your browser downloads shortly after Saving. The Batch will be no longer be editable. To go back and make modifications click Cancel to return to the previous screen.",
          onOptionYesEvent: () => dispatchSavePost(),
          optionYesOverrideLabel: "Save",
          onOptionNoEvent: () => setDialogConfiguration(null),
          optionNoOverrideLabel: "Cancel",
        });
      },
      onCheckClick: (checkedItems) => onCheckEvent(checkedItems),
      onEditClick: () => returnPaymentHistory(),
    },
    allSelectedRows: true,
    columnOptions: [
      {
        fieldName: MULTI_SELECTION_COLUMN_FIELD_NAME,
        computeOnChange: {
          conditionForCell: [
            () => onTriggerChange(["ReferenceNo", "PaymentAmount"]),
          ],
        },
      },
      {
        fieldName: "ReferenceNo",
        computeOnChange: {
          conditionForColumn: [(options) => onReferenceColumnChange(options)],
        },
      },
      {
        fieldName: "PaymentAmount",
        align: "right",
        computeOnChange: {
          conditionForCell: [(options) => onPaymentAmountChange(options)],
        },
      },
      {
        fieldName: "PayeeName",
        width: 35,
      },
    ],
    footerOptions: {
      CustomFooterComponent: (rows) => CustomFooterComponent(rows),
    },
    multiSelectOptions: [
      {
        name: "custom",
      },
    ],
  };

  useBaseTable(config);

  useEffect(() => {
    if (validatorErrorResponse) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "You need to select at least one payment.",
        onOptionYesEvent: () => setDialogConfiguration(null),
        optionYesOverrideLabel: "OK",
      });
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const dataResponse = responsePost.axiosResponse?.data;
      setColumnsAndData(dataResponse);
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseSavePost)) {
      setDispatchPrint(true);
    }
  }, [responseSavePost]);

  useEffect(() => {
    setNewBatch({ ...batch });
  }, [batch]);

  useEffect(() => {
    if (!areValuesOfObjectEmptyNullOrUndefined(batch)) {
      dispatchPost();
    }
  }, []);

  const returnPaymentHistory = () => {
    navigate(
      `/claims${getQueryStringsToPaymentBatchSections(
        PaymentBatchSectionEnums?.PAYMENT_BATCH_HISTORY
      )}`
    );
  };

  return (
    <>
      {areValuesOfObjectEmptyNullOrUndefined(batch) && returnPaymentHistory()}
      <Heading6 textAlign="start" display="block">
        {`NEW BATCH PREVIEW: ${FormattingDate(
          batch.fromDate
        )} - ${FormattingDate(batch.toDate)}`}
      </Heading6>
      {conditionHasValue(columnsAndData) && <BaseTable2 name={tableName} />}
      <PrintBatch
        batchId={responseSavePost?.axiosResponse?.data.paymentBatchId ?? 0}
        dispatchPrint={dispatchPrint}
        setDispatchPrint={setDispatchPrint}
      />
      <DialogConfirmation
        id="dialog-for-modal-add-batch"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </>
  );
};

export default PaymentBatchPreview;
