import { FC, Key, useState } from "react";
import { CustomFieldControlTypeEnum } from "../../../dtos/custom-field-control-type-enum";
import { CustomFieldDataTypeEnum } from "../../../dtos/custom-field-data-type-enum";
import { PolicyCustomFieldBlob } from "../../../dtos/policy-custom-field-blob";
import { Col, Input, Row, Select, Switch } from "../../TrueUI";
import { INSURED_ATOM_KEY } from "../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import {
  PolicyQuoteTriggerComponentNames,
  usePolicyQuoteTriggerComponent,
} from "./hooks/usePolicyQuoteTriggerComponent";
import "./Policy.module.css";

type PolicyCustomFieldsProps = {
  tabKey: string;
  customFieldTarget: string;
  customFieldTrigger: PolicyQuoteTriggerComponentNames;
  customFieldUpdateMethod: any;
  columnsWithCustomFields: PolicyCustomFieldBlob[][];
  readOnly: boolean;
  errorDetails?: any;
};

const PolicyCustomFields: FC<PolicyCustomFieldsProps> = ({
  tabKey,
  customFieldTarget,
  customFieldTrigger,
  customFieldUpdateMethod,
  columnsWithCustomFields,
  readOnly,
  errorDetails,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const [localCustomFields, setLocalCustomFields] = useState<
    Map<number, PolicyCustomFieldBlob>
  >(
    new Map(
      columnsWithCustomFields
        .flatMap((columnWithCustomFields) => columnWithCustomFields)
        .map((customField) => [
          customField.customFieldDefinitionID,
          customField,
        ])
    )
  );

  const setLocalCustomField = (
    customFieldUpdated: PolicyCustomFieldBlob,
    fieldKeyFromValuesObj: string,
    newValue: any,
    updateAtom: boolean = false
  ) => {
    const customFieldId = customFieldUpdated.customFieldDefinitionID;
    const copyLocalCustomFields = new Map(localCustomFields);
    const localCustomField = copyLocalCustomFields.get(customFieldId);
    copyLocalCustomFields.set(customFieldId, {
      ...localCustomField,
      [fieldKeyFromValuesObj]: newValue,
    } as PolicyCustomFieldBlob);
    setLocalCustomFields(copyLocalCustomFields);
    if (updateAtom) {
      updatedFieldValue(customFieldUpdated, fieldKeyFromValuesObj, newValue);
    }
  };

  const updatedFieldValue = (
    customFieldUpdated: PolicyCustomFieldBlob,
    fieldKeyFromValuesObj: string,
    newValue: any
  ) => {
    const atomValue = getAtom();
    const customFieldsList = columnsWithCustomFields.flatMap(
      (columnWithCustomFields) => columnWithCustomFields
    );
    const customFieldUpdatedList = customFieldsList.map((customFieldInJSON) =>
      customFieldInJSON.customFieldDefinitionID ===
      customFieldUpdated.customFieldDefinitionID
        ? { ...customFieldUpdated, [fieldKeyFromValuesObj]: newValue }
        : { ...customFieldInJSON }
    );
    const newAtomValue = customFieldUpdateMethod(
      atomValue,
      customFieldTarget,
      customFieldUpdatedList
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers([customFieldTrigger]);
  };

  const renderFieldComponentByType = (customField: PolicyCustomFieldBlob) => {
    if (customField.dataType === CustomFieldDataTypeEnum.TEXT) {
      if (customField.controlType === CustomFieldControlTypeEnum.DROPDOWN) {
        return (
          <Select
            labelFontType="BOLD_CAPTION"
            name={customField.fieldLabel}
            id={`id-${customField.fieldLabel}`}
            label={customField.fieldLabel}
            firstOptionAsDefault={false}
            readOnly={readOnly}
            options={customField.listSourceAsSelectOptions ?? []}
            value={
              localCustomFields.get(customField.customFieldDefinitionID)
                ?.fieldValueString
            }
            onChange={(value) =>
              setLocalCustomField(customField, "fieldValueString", value, true)
            }
          />
        );
      } else {
        return (
          <Input
            labelFontType="BOLD_CAPTION"
            name={customField.fieldLabel}
            id={`id-${customField.fieldLabel}`}
            label={customField.fieldLabel}
            value={
              localCustomFields.get(customField.customFieldDefinitionID)
                ?.fieldValueString ?? ""
            }
            readOnly={readOnly}
            maxLength={customField.dataSize}
            onChangeRawValue={(value) =>
              setLocalCustomField(customField, "fieldValueString", value)
            }
            onBlur={(e) =>
              updatedFieldValue(customField, "fieldValueString", e.target.value)
            }
            errorMessage={errorDetails?.[customField.errorField ?? ""]}
          />
        );
      }
    } else if (customField.dataType === CustomFieldDataTypeEnum.NUMBER) {
      if (customField.controlType === CustomFieldControlTypeEnum.DROPDOWN) {
        return (
          <Select
            labelFontType="BOLD_CAPTION"
            name={customField.fieldLabel}
            id={`id-${customField.fieldLabel}`}
            label={customField.fieldLabel}
            readOnly={readOnly}
            firstOptionAsDefault={false}
            options={customField.listSourceAsSelectOptions ?? []}
            value={localCustomFields
              .get(customField.customFieldDefinitionID)
              ?.fieldValueNumber?.toString()}
            onChange={(value) =>
              setLocalCustomField(customField, "fieldValueNumber", value, true)
            }
          />
        );
      } else {
        return (
          <Input
            labelFontType={"BOLD_CAPTION"}
            name={customField.fieldLabel}
            id={`id-${customField.fieldLabel}`}
            label={customField.fieldLabel}
            value={
              localCustomFields.get(customField.customFieldDefinitionID)
                ?.fieldValueNumber ?? ""
            }
            readOnly={readOnly}
            onChangeRawValue={(value) =>
              setLocalCustomField(customField, "fieldValueNumber", value)
            }
            onBlur={(e) =>
              updatedFieldValue(customField, "fieldValueNumber", e.target.value)
            }
            type={"number"}
            allowNegatives={false}
            maxLength={customField.dataSize}
            thousandSeparator={false}
            errorMessage={errorDetails?.[customField.errorField ?? ""]}
          />
        );
      }
    } else if (customField.dataType === CustomFieldDataTypeEnum.DECIMAL) {
      return (
        <Input
          labelFontType={"BOLD_CAPTION"}
          name={customField.fieldLabel}
          id={`id-${customField.fieldLabel}`}
          label={customField.fieldLabel}
          value={
            localCustomFields.get(customField.customFieldDefinitionID)
              ?.fieldValueNumber ?? ""
          }
          readOnly={readOnly}
          onChangeRawValue={(value) =>
            setLocalCustomField(customField, "fieldValueNumber", value)
          }
          onBlur={(e) =>
            updatedFieldValue(customField, "fieldValueNumber", e.target.value)
          }
          type={"fixedCurrency"}
          allowNegatives={false}
          maxLength={customField.dataSize + customField.dataPrecision + 1}
          decimalScale={customField.dataPrecision}
          thousandSeparator={false}
          prefix={""}
          errorMessage={errorDetails?.[customField.errorField ?? ""]}
        />
      );
    } else if (customField.dataType === CustomFieldDataTypeEnum.BOOLEAN) {
      return (
        <Switch
          labelFontType={"BOLD_CAPTION"}
          id={`id-${customField.fieldLabel}`}
          label={customField.fieldLabel}
          control="checkbox"
          name={customField.fieldLabel}
          isChecked={
            localCustomFields.get(customField.customFieldDefinitionID)
              ?.fieldValueNumber === 1
          }
          readOnly={readOnly}
          labelPlacement={"top"}
          onChangeIsChecked={(value) =>
            setLocalCustomField(
              customField,
              "fieldValueNumber",
              value ? 1 : 0,
              true
            )
          }
        />
      );
    } else {
      return <></>;
    }
  };

  const renderColumnComponents = (
    customFieldListPerColumn: PolicyCustomFieldBlob[],
    index: Key
  ) => {
    return (
      <Row
        {...rowWithNoMarginNorGutter}
        key={`policy-custom-field-column-row-${index}`}
        rowDirection="column"
      >
        {customFieldListPerColumn.map(
          (value: PolicyCustomFieldBlob, index: Key) => (
            <Col key={index} horizontalAlign="flex-start">
              {renderFieldComponentByType(value)}
            </Col>
          )
        )}
      </Row>
    );
  };

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      className="policy_custom_fields_container"
    >
      {columnsWithCustomFields.map(
        (customFieldListPerColumn: PolicyCustomFieldBlob[], index: Key) =>
          renderColumnComponents(customFieldListPerColumn, index)
      )}
    </Row>
  );
};

export default PolicyCustomFields;
