import { FC, useEffect, useState } from "react";
import {
  ModalChangeClaimDetailsProps,
  modalDetailsProps,
} from "./ModalChangeProps";
import { Col, Divider, Input, Modal, Row, Select } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Col";
import { useApiGet, useApiPost } from "../../../../../hooks";
import { ChangeClaimDetailsDto } from "../../../../../dtos/change-claim-details-dto";
import { getStates } from "../../../../../utilities/staticDataFunctions";
import { SelectOptions } from "../../../../../dtos/select-options";
import SearchText from "../../../../TrueUI/Search/SearchText";
import { inputConfiguration } from "../../../../../utilities/inputPropsConfigurationFunctions";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../utilities/apiFunctions";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";

const ModalChangeClaimDetails: FC<ModalChangeClaimDetailsProps> = ({
  setAllModalConfiguration,
  allModalConfiguration,
  claimId,
  tabKey,
}) => {
  const [claimDetails, setClaimDetails] =
    useState<Partial<ChangeClaimDetailsDto> | null>(null);
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { responseGet, dispatchGet } = useApiGet<ChangeClaimDetailsDto>(
    `api/Claims/GetChangeClaimDetailsInfo?claimId=${claimId ?? 0}&insuredId=${
      claimDetails?.insuredId ?? -1
    }`
  );

  const [errorDetails, setErrorDetails] = useState<any>();
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<ChangeClaimDetailsDto>(`api/Claims/SaveClaimDetailsFromModal`, {
      ...claimDetails,
      claimId: claimId,
    });
  const states: Partial<SelectOptions>[] = getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setComponentTriggers(["activityLogComponent", "claimBanner"]);

      setClaimDetails(null);
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalChangeClaimDetails: false,
      });
      setErrorDetails(null);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (allModalConfiguration.openModalChangeClaimDetails === true) {
      dispatchGet();
    }
  }, [allModalConfiguration.openModalChangeClaimDetails]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setClaimDetails({
        ...responseGet?.responseData,
        newLocationName: responseGet?.responseData?.newLocationName ?? "",
        insuredId:
          claimDetails?.insuredId === null
            ? claimDetails?.insured?.insuredId
            : claimDetails?.insuredId,
      });
    }
  }, [responseGet]);

  const closeButtonClicked = (e) => {
    if (e === false) {
      setErrorDetails(null);
      setClaimDetails(null);
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalChangeClaimDetails: false,
      });
    }
  };

  const saveButtonClicked = () => {
    setErrorDetails(null);
    dispatchPost();
    setErrorDetails(null);
    dispatchPost();
  };
  const getLocations = () =>
    claimDetails?.insuredAddresses?.map((insuredAddress) => ({
      displayName: insuredAddress.description,
      intValue: insuredAddress.addressId,
    })) ?? [];

  const saveSelectedResult = (searchResult) => {
    setClaimDetails({
      ...claimDetails,
      insuredId: searchResult?.insuredId,
    });
    dispatchGet();
  };

  return (
    <Modal
      {...{
        ...modalDetailsProps,
        open: allModalConfiguration.openModalChangeClaimDetails,
        closeEvent: closeButtonClicked,
        saveEvent: () => setIsDialogConfirmationOpen(true),
        size: "sm",
      }}
    >
      <Divider height={20} />
      {claimDetails !== null && (
        <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start" allowWrap>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            displayFlex={false}
          >
            <SearchText
              saveSelectedResult={saveSelectedResult}
              url={"api/QuickSearchInsuredClaim/SearchInsuredText?text="}
              labelText="Insured"
              searchTextKeys={["insuredName"]}
              placeholderText={"Search for an insured"}
              errorMessage={undefined}
              labelFontType={"BOLD_CAPTION"}
              value={claimDetails?.insuredName}
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            verticalGutter={"10px"}
          >
            <Select
              id="claim-detail-location"
              name={`changeClaimDetailsLocation`}
              inputWidth={"100%"}
              optionsMaxHeight="120px"
              label={"Location"}
              labelFontType={"BOLD_CAPTION"}
              options={[
                {
                  displayName: "Enter Location",
                  intValue: -1,
                },
                ...getLocations(),
              ]}
              readOnly={false}
              value={claimDetails?.insuredAddressId}
              variant={"filled"}
              onChange={(value) => {
                setClaimDetails({
                  ...claimDetails,
                  insuredAddressId: value,
                });
              }}
              firstOptionAsDefault={false}
            />
          </Col>
          {claimDetails?.insuredAddressId === -1 && (
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 12, lg: 12, xl: 12 }}
            >
              <Input
                {...inputConfiguration(
                  "id-new-location",
                  "changeClaimDetailsNewLocation",
                  "Location",
                  200
                )}
                value={claimDetails?.newLocationName ?? ""}
                onChangeRawValue={(value) =>
                  setClaimDetails({
                    ...claimDetails,
                    newLocationName: value === null ? "" : value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
                errorMessage={errorDetails?.newLocationName}
              />
            </Col>
          )}
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            verticalGutter={
              claimDetails?.insuredAddressId === -1 ? "10px" : "0px"
            }
          >
            <Select
              id="claim-detail-jurisdiction"
              name={`changeClaimDetailsJurisdiction`}
              inputWidth={"100%"}
              optionsMaxHeight="120px"
              label={"Jurisdiction"}
              labelFontType={"BOLD_CAPTION"}
              options={states}
              readOnly={false}
              value={claimDetails?.jurisdiction}
              variant={"filled"}
              onChange={(value) => {
                setClaimDetails({
                  ...claimDetails,
                  jurisdiction: value,
                });
              }}
            />
          </Col>
        </Row>
      )}
      <Divider height={20} />
      <DialogConfirmation
        id="saveStatusConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={`Are you sure you want to change key claim details?`}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          saveButtonClicked();
          setIsDialogConfirmationOpen(close);
        }}
      />
    </Modal>
  );
};

export default ModalChangeClaimDetails;
