import { Button, Col, Row } from "../../TrueUI";
import Link from "../../TrueUI/Typography/Link";
import "./achBillingBatchDetails.module.css";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import { FC } from "react";
import { AchBatchNachaFileCollapseHeaderProps } from "./AchBatchNachaFileCollapseUtils";

const AchBatchNachaFileCollapseHeader: FC<
  AchBatchNachaFileCollapseHeaderProps  
> = ({ isCollapse, onSaveFileAs, onCopyToClipboard }) => {
  return isCollapse ? (
    <Row {...rowWithNoMarginNorGutter}>
      <Col {...colWithNoMarginNorGutter} breakpoints={{ md: 9, lg: 9, xl: 9 }}>
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"NACHA File"}
          name="billingBatchDetailsNachaCollapseTitle"
        />
      </Col>
      <Col {...colWithNoMarginNorGutter} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
        <Button
          onClick={onSaveFileAs}
          variantStyle={"outlined"}
          sx={{ marginRight: "10px" }}
          stopClickPropagation
        >
          Save File As
        </Button>
        <Button
          onClick={onCopyToClipboard}
          variantStyle={"outlined"}
          stopClickPropagation
        >
          {"Copy to clipboard"}
        </Button>
      </Col>
    </Row>
  ) : (
    <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
      <Link
        linkFontType={"BOLD_TITLE"}
        displayValue={"NACHA File"}
        name="billingBatchDetailsNachaCollapseTitle"
      />
    </Col>
  );
};
export default AchBatchNachaFileCollapseHeader;
