import { ElementType, FC, useEffect, useState, useTransition } from "react";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import styles from "./Styles/basegrid.module.css";
import { Button, SaveButton, SplitButton } from "../TrueUI";
import {
  getExternalColumnsOnly,
  hydrateDataSingle,
} from "./SupportFunctions/OLD_baseGridFunctions";
import { createNewDataItemWithDefaults } from "./SupportFunctions/baseGridFunctions";
import { LinearProgress } from "@mui/material";
import { BaseGridFilterOptionsParameter } from "./BaseGridProperties";
import { useCellManagerInstance } from "./Hooks/useCellManagerInstance";

type BaseGridFilterBarListenerProperties = {
  uiid?: string;
  name?: string;
};
const BaseGridFilterBarListener: FC<BaseGridFilterBarListenerProperties> = ({
  uiid,
  name,
}) => {
  const {
    instanceSelector,
    instanceInternalSelector,
    setEntireInstance,
    setInternalInstance,
  } = useGridInstance(uiid ?? "NO_UIID_FOUND", "BaseGridEventListener");

  const { updateCellManagerInstance } = useCellManagerInstance(
    uiid ?? "NO_UIID_FOUND"
  );

  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());

  // TODO - fix this
  const [filterLoadLock, _setFilterLoadLock] = useTransition();

  const editAllRowsTrue = () => {
    updateCellManagerInstance(instanceInternal.rowKeys, {
      isEditable: true,
    });

    setEntireInstance({
      BaseGridProperties: {
        ...instance,
        hasToggledEdit: true,
        toolbarOptions: {
          ...instance.toolbarOptions,
          showSaveButton: true,
        },
      },
      BaseGridInternalProperties: {
        ...instanceInternal,
      },
    });
  };

  const addRow = () => {
    // TODO - Function needs clean-up. Gotta better define things here.
    if (instance?.events?.addEventOverrideCallback) {
      const externalColumnsOnly = getExternalColumnsOnly(
        instance?.columns ?? []
      );
      const defaults = createNewDataItemWithDefaults(externalColumnsOnly);
      const hydratedBaseObject = hydrateDataSingle(
        externalColumnsOnly,
        defaults
      );

      const callbackResponse =
        instance?.events?.addEventOverrideCallback?.(hydratedBaseObject);

      // const newRowKey = crypto.randomUUID();

      if (callbackResponse !== undefined && callbackResponse !== null) {
        const mergedNewRow = { ...hydratedBaseObject, ...callbackResponse };

        setInternalInstance({
          accessors: {
            _addRowExternal: {
              hydratedData: mergedNewRow,
              isEditableAfterAdd: false,
            },
          },
        });
      }
    } else {
      const defaults = createNewDataItemWithDefaults(instance?.columns ?? [], {
        newRowKey: `${instance.data?.length ?? 0}_${uiid}`,
        optionsColumnValue:
          instance.advancedOptions?.optionsColumnConfiguration?.optionType ??
          "none",
      });

      setInternalInstance({
        accessors: {
          _addRowInternal: {
            defaults,
            //hydratedData: hydratedBaseObject,
            isEditableAfterAdd: true,
          },
        },
      });
    }
  };

  const editAllRowsFalse = () => {
    updateCellManagerInstance(instanceInternal.rowKeys, {
      isEditable: false,
    });
    setEntireInstance({
      BaseGridProperties: {
        ...instance,
        hasToggledEdit: false,
        toolbarOptions: {
          ...instance.toolbarOptions,
          showSaveButton: false,
        },
      },
      BaseGridInternalProperties: {
        ...instanceInternal,
      },
    });
  };

  const save = () => {
    setEntireInstance({
      BaseGridProperties: {
        ...instance,
        hasToggledSave: true,
      },
      BaseGridInternalProperties: {
        ...instanceInternal,
        accessors: {
          _reloadDataSources: true,
        },
      },
    });
  };

  const cachedFilters = () => {
    return (
      instance?.filterOptions?.map((filterOption, filterIndex) => {
        const Jsx = filterOption as ElementType;
        const additionalProperties = {
          uiid: uiid,
          name: name,
        } as BaseGridFilterOptionsParameter;
        return (
          <div
            className={"true_base_grid_filter_sub_wrappers_item"}
            key={filterIndex}
          >
            <Jsx {...additionalProperties} key={filterIndex} />
          </div>
        );
      }) ?? []
    );
  };

  const [renderFilters, setRenderFitlers] = useState<any[]>([]);

  useEffect(() => {
    if (instanceInternal.isReadyToRender) {
      setRenderFitlers(cachedFilters());
    }
  }, [instanceInternal.isReadyToRender, instance.filterOptions]);

  return instanceInternal.isReadyToRender &&
    instance.toolbarOptions?.hideToolbar === false ? (
    <div className={styles.true_base_grid_toolbar}>
      <div className={styles.true_base_grid_toolbar_container_actions}>
        <div
          className={"true_base_grid_filter_sub_wrappers"}
          style={{ flexGrow: 1, display: "flex" }}
        >
          <>
            {renderFilters}

            {instance.toolbarOptions?.showAddButton && (
              <div className={"true_base_grid_filter_sub_wrappers_item"}>
                <Button
                  fullWidth
                  isDisabled={instance?.toolbarOptions?.addButtonIsDisabled}
                  // isDisabled={filterLoadLock}
                  permissions={instance?.permissions?.addButton ?? []}
                  onClick={() => addRow()}
                >
                  {instance?.toolbarOptions?.addButtonText ?? "ADD"}
                </Button>
              </div>
            )}
          </>
          {instance.toolbarOptions?.showEditButton && (
            <div className={"true_base_grid_filter_sub_wrappers_item"}>
              {!instance?.hasToggledEdit ? (
                <Button
                  fullWidth
                  variantStyle={"outlined"}
                  isDisabled={filterLoadLock}
                  permissions={instance.permissions?.editButton ?? []}
                  onClick={() => {
                    editAllRowsTrue();
                  }}
                >
                  EDIT
                </Button>
              ) : (
                <Button
                  fullWidth
                  variantStyle={"outlined"}
                  isDisabled={filterLoadLock}
                  onClick={() => {
                    editAllRowsFalse();
                  }}
                >
                  CANCEL
                </Button>
              )}
            </div>
          )}
          {instance?.toolbarOptions?.showSaveButton &&
            instance?.hasToggledEdit && (
              <div className={"true_base_grid_filter_sub_wrappers_item"}>
                <SaveButton
                  text={instance?.toolbarOptions?.saveButtonText}
                  fullWidth
                  disabled={filterLoadLock}
                  onClick={() => save()}
                  name="toolbarSaveButton"
                />
              </div>
            )}
        </div>
        <div
          className={"true_base_grid_filter_sub_wrappers"}
          style={{ display: "flex" }}
        >
          <div className={"true_base_grid_filter_sub_wrappers_item"}>
            <div style={{ fontWeight: "normal", fontSize: "18px" }}>
              <div style={{ fontWeight: "bold", display: "inline-block" }}>
                Items:
              </div>{" "}
              <div style={{ display: "inline-block" }}>
                {(instanceInternal.sortedAndFilteredData ?? []).length}
              </div>
            </div>

            {filterLoadLock ? (
              <div>
                <LinearProgress sx={{ height: "6px" }} />
              </div>
            ) : (
              <div>
                <LinearProgress
                  variant="buffer"
                  value={0}
                  valueBuffer={100}
                  sx={{ height: "6px", backgroundColor: "lightgray" }}
                />
              </div>
            )}
          </div>
          {instance.toolbarOptions?.showExportButton && (
            <div className={"true_base_grid_filter_sub_wrappers_item"}>
              <SplitButton
                disabled={instanceInternal.sortedAndFilteredData.length === 0}
                items={[
                  {
                    option: "Excel",
                    dropdownText: "Excel",
                    action: () => {
                      setEntireInstance({
                        BaseGridProperties: {
                          ...instance,
                          hasToggleExportExcel: true,
                        },
                        BaseGridInternalProperties: {
                          ...instanceInternal,
                        },
                      });
                    },
                  },
                  {
                    option: "PDF",
                    dropdownText: "PDF",
                    action: () => {
                      setEntireInstance({
                        BaseGridProperties: {
                          ...instance,
                          hasToggleExportPDF: true,
                        },
                        BaseGridInternalProperties: {
                          ...instanceInternal,
                        },
                      });
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default BaseGridFilterBarListener;
