import { FC } from "react";
import {
  CalendarCell,
  CalendarCellProps,
  MultiViewCalendar,
} from "@progress/kendo-react-dateinputs";
import Font from "../Typography/Font";
import { areDatesEquals, StringToDate } from "../../../utilities/dateFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import "./calendarWidget.module.css";
import { CalendarWidgetCellDto } from "../../../dtos/calendar-widget-cell-dto";

type CalendarWidgetProps = {
  value?: Date | null;
  onChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  disabledWidget?: boolean;
  datesWithValues?: CalendarWidgetCellDto[] | null;
};

const CalendarWidget: FC<CalendarWidgetProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  disabledWidget,
  datesWithValues,
}) => {
  const getFoundDate = (findDate: Date) =>
    datesWithValues?.find((item) =>
      areDatesEquals(StringToDate(item.cellDate), findDate)
    );

  const customCell = (props: CalendarCellProps) => {
    const foundDate = getFoundDate(props.value);
    return (
      <CalendarCell {...props} className="widget-calendar-cell">
        {props.children}
        {conditionHasValue(foundDate) ? (
          <Font
            className="widget-calendar-cell-data"
            fontType={foundDate.bold ? "BOLD_BODY" : "BODY"}
          >
            {foundDate.cellValue}
          </Font>
        ) : null}
      </CalendarCell>
    );
  };

  return (
    <MultiViewCalendar
      views={1}
      mode="single"
      cell={customCell}
      className="widget-calendar"
      value={value ?? new Date()}
      onChange={(e) => onChange?.(e.value as Date)}
      min={minDate}
      max={maxDate}
      disabled={disabledWidget}
    />
  );
};

export default CalendarWidget;
