import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";
import { Theme } from "../../../../media/themeTypes";

export const endorsementFormsSplitterStyles = (theme: Theme) => {
  return {
    height: "100%",
    ".k-splitter": {
      height: "100%",
      backgroundColor: "transparent",
      borderColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      ".k-splitbar": {
        backgroundColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      },
      ".k-splitbar:focus": {
        background: theme.PRIMARY,
      },
    },
  };
};

export const endorsementStatesRowStyles = (theme: Theme) => {
  return {
    display: "contents",
    ".true_col_grid_wrapper": {
      ".MuiTypography-subtitle2": {
        cursor: "pointer",
        textDecorationColor: theme.TEXT_COLOR,
        textDecorationThickness: "2px",
      },
    },
  };
};

export const endorsementFormsRowStyles = () => {
  return {
    ".true_row_grid_wrapper": {
      ".true_col_grid_wrapper": {
        minWidth: "fit-content",
        ".MuiCheckbox-root": {
          padding: "0px 9px",
        },
      },
    },
  };
};
