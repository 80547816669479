import { FC, useEffect, useState } from "react";
import { Button, Modal } from "../../../../../../TrueUI";
import { Stack } from "@mui/material";
import CancelButton from "../../../../../../TrueUI/Buttons/CancelButton";
import { ReservesModalsConfigProps } from "../ReservesGrid";
import ReallocateTableWrapper, {
  NewReserveProps,
} from "./ReallocateTableWrapper";
import { useApiPost } from "../../../../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../../utilities/apiFunctions";
import { INSURED_ATOM_KEY } from "../../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../../InsuredAtoms";
import { ClaimFinancialGetAPIRequesterAtom } from "../ClaimFinancialAtoms";
import { useRecoilState } from "recoil";

type AddReserveReallocateModalProps = {
  claimId?: number;
  reservesModalsConfig: ReservesModalsConfigProps;
  setReservesModalsConfig: (
    reservesModalsConfig: ReservesModalsConfigProps
  ) => void;
  tabKey?: string;
};

export type FooterTotalsProps = {
  totalsReallocate: number;
  totalOutstanding: number;
};
const defaultFooterTotals: FooterTotalsProps = {
  totalsReallocate: 0,
  totalOutstanding: 0,
};

const AddReserveReallocateModal: FC<AddReserveReallocateModalProps> = ({
  claimId,
  reservesModalsConfig,
  setReservesModalsConfig,
  tabKey,
}) => {
  const [footerTotals, setFooterTotals] =
    useState<FooterTotalsProps>(defaultFooterTotals);
  const [reallocateDataList, setReallocateDataList] = useState<
    NewReserveProps[]
  >([]);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/Reserve/SaveReallocateReserves",
    { newReservesList: reallocateDataList, claimId: claimId }
  );

  const [getApiRequester, setGetApiRequester] = useRecoilState(
    ClaimFinancialGetAPIRequesterAtom
  );

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const isSaveButtonDisable = () => {
    const validReallocateReserves = reallocateDataList.filter(
      (reserve) => reserve.reallocate !== null && reserve.reallocate !== 0
    );
    return (
      (footerTotals.totalsReallocate === 0 &&
        validReallocateReserves.length === 0) ||
      footerTotals.totalsReallocate !== 0
    );
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      if (validatorErrorResponse === null) {
        setComponentTriggers([
          "claimBanner",
          "financialTableClaimLandingPageComponent",
        ]);
        setReservesModalsConfig({
          ...reservesModalsConfig,
          refresh: false,
          isReallocateModalOpen: false,
        });
        setGetApiRequester({
          ...getApiRequester,
          isRequested: true,
          isCompleted: false,
        });
      }
    }
  }, [responsePost]);

  return (
    <Modal
      id="add-reserve-reallocate-modal"
      title="Reallocate Outstanding Reserves"
      open={reservesModalsConfig.isReallocateModalOpen}
      closeEvent={(value) =>
        setReservesModalsConfig({
          ...reservesModalsConfig,
          isReallocateModalOpen: value,
        })
      }
      cancelEvent={() =>
        setReservesModalsConfig({
          ...reservesModalsConfig,
          isReallocateModalOpen: false,
        })
      }
      cancelButtonWithConfirmation
    >
      <ReallocateTableWrapper
        claimId={claimId}
        reallocateDataList={reallocateDataList}
        setReallocateDataList={setReallocateDataList}
        setFooterTotals={setFooterTotals}
        footerTotals={footerTotals}
      />
      <Stack>
        <div
          style={{
            paddingBottom: "15px",
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "10px",
          }}
        >
          <Button
            isDisabled={isSaveButtonDisable()}
            onClick={() => dispatchPost()}
          >
            SAVE
          </Button>
          <CancelButton
            onClick={() =>
              setReservesModalsConfig({
                ...reservesModalsConfig,
                isReallocateModalOpen: false,
              })
            }
            withConfirmation
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default AddReserveReallocateModal;
