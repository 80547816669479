import { useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import BaseTableSearchFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { INTERNAL_TABLE_COLUMNS_OBJ } from "../../TrueUI/Tables/TableConstants";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { globalOptions } from "../../../GlobalAtoms";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { NoteCardConfigDto } from "../../../dtos/note-card-config-dto";
import BaseTableInputDateFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableInputDateFilter";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { FormattingDate, subtractDays } from "../../../utilities/dateFunctions";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  DRAWER_NOTE_ID,
  DRAWER_TABS,
  OPEN_DRAWER,
} from "../../../utilities/queryStringsHash";
import { DrawerActionsEnums } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";
import { useNavigate } from "react-router";

const NotesGrid = () => {
  const defaultToDate = new Date();
  const defaultFromDate = subtractDays(defaultToDate, 7);
  const formatDate = (date: Date) => FormattingDate(date, "YYYY-MM-DD");

  const [configuration, setConfiguration] =
    useState<NoteCardConfigDto | null>();
  const [dateFilters, setDateFilters] = useState({
    from: formatDate(defaultFromDate),
    to: formatDate(defaultToDate),
  });
  const { responseGet, dispatchGet } = useApiGet<NoteCardConfigDto>(
    "api/Dashboard/GetNotesConfiguration"
  );

  const { getAtom } = useAtomFamily(globalOptions);
  const currentUser = getAtom().userId;
  const navigate = useNavigate();

  const name = "assigned_tasks_table";
  const getURL = `api/Dashboard/GetNotes?from=${dateFilters.from}&to=${dateFilters.to}`;

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfiguration(responseGet?.responseData ?? null);
    }
  }, [responseGet]);

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() =>
          openEditNote(rowData.row?.InsuredOrClaimantLink, rowData.row?.NoteId)
        }
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const openEditNote = (noteUrl: string, noteId: number) => {
    const drawerHashes = getQueryStringsURL([
      {
        nameOfHash: OPEN_DRAWER,
        valueOfHash: 1,
      },
      {
        nameOfHash: DRAWER_TABS,
        valueOfHash: DrawerActionsEnums.NOTES,
      },
      {
        nameOfHash: DRAWER_NOTE_ID,
        valueOfHash: noteId,
      },
    ]);

    navigate(noteUrl + drawerHashes);
  };

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showExcelButton: true,
      showPDFButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "From:",
          inputWidth: "210px",
          defaultValue: defaultFromDate,
          isStaticFilter: true,
          onChangeRawValue: (value) => {
            setDateFilters({ ...dateFilters, from: formatDate(value) });
          },
        }),
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "To:",
          inputWidth: "210px",
          defaultValue: defaultToDate,
          isStaticFilter: true,
          onChangeRawValue: (value) => {
            setDateFilters({ ...dateFilters, to: formatDate(value) });
          },
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "owner",
          filterOptions: actionOptions,
          optionsOverride: addAllOption(configuration?.ownerUserOptions ?? []),
          columnsNames: ["OwnerId"],
          labelText: "Owner:",
          defaultValue: currentUser,
        }),
      (actionOptions) => BaseTableSearchFilter(actionOptions, ["Content"]),
    ],
    columnOptions: [
      {
        fieldName: INTERNAL_TABLE_COLUMNS_OBJ.INDICATION_COLUMN_FIELD_NAME,
        width: 4,
        displayNameOverride: "Type",
      },
      { fieldName: "InsuredOrClaim", width: 15 },
      { fieldName: "Owner", width: 15 },
      { fieldName: "Subject", width: 38 },
      { fieldName: "Category", width: 10 },
      { fieldName: "DateAdded", width: 10 },
      { fieldName: "HasFile", width: 5 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      indicationColumnConfiguration: {
        conditionalIcon: (row) => row.NoteType,
        isHidden: false,
      },
    },
  };

  useBaseTable(tableConfiguration);
  return (
    <div id={"notes_grid_container"} style={{ height: "100%" }}>
      {conditionHasValue(configuration) && <BaseTable2 name={name} />}
    </div>
  );
};

export default NotesGrid;
