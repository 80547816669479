import { FC, useEffect, useState } from "react";
import { Col, Header, InputDate, Modal, Row, Select } from "../../../TrueUI";
import {
  ModalStateProps,
  PolicyStateInModalStateUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  areDatesEquals,
  isDateAfterDate,
} from "../../../../utilities/dateFunctions";
import {
  getStateName,
  getStateReportingBureauAndReportCode,
  getStatesAndListCode,
  verifyStateUnique,
} from "./ExposurePremiumUtils";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { ModalStateErrorMessageStyles } from "./ExposurePremiumStyles";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";

const ModalState: FC<ModalStateProps> = ({
  modalStatesUI,
  saveEvent,
  closeEvent,
  policyEffectiveDate,
  policyExpirationDate,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [localModalStateUI, setLocalModalStateUI] = useState<
    PolicyStateInModalStateUIProps | undefined | null
  >();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<string>("");

  const saveNewState = () => {
    setIsDialogOpen(false);
    const { newStates, listCodes } = getStatesAndListCode(
      modalStatesUI.states,
      localModalStateUI
    );

    saveEvent?.(newStates, listCodes ?? "");
  };

  const validateBeforeSave = () => {
    if (
      !areDatesEquals(policyEffectiveDate, localModalStateUI?.effectiveDate) ||
      !areDatesEquals(policyExpirationDate, localModalStateUI?.expirationDate)
    ) {
      setIsDialogOpen(true);
    } else {
      saveNewState();
    }
  };

  const onStateChange = (value: string) => {
    const { reportingBureau, reportCode } =
      getStateReportingBureauAndReportCode(
        value,
        modalStatesUI.allProgramStates
      );

    setLocalModalStateUI({
      ...localModalStateUI,
      stateCode: value,
      stateName: getStateName(value, modalStatesUI.allStates),
      reportingBureau: reportingBureau,
      reportCode: reportCode,
    });
  };

  const getErrorMessageByValuesChanged = () => {
    const isUniqueState =
      verifyStateUnique(modalStatesUI.states, localModalStateUI) &&
      modalStatesUI.isModalOpen;
    if (isUniqueState) {
      return "There is an overlap based on the state as well as the effective and expiration dates.";
    }
    if (
      (localModalStateUI?.effectiveDate ?? "") === "" ||
      (localModalStateUI?.expirationDate ?? "") === ""
    ) {
      return "Please enter a valid effective/expiration date.";
    }
    if (
      isDateAfterDate(
        localModalStateUI?.effectiveDate ?? "",
        localModalStateUI?.expirationDate ?? ""
      )
    ) {
      return "The effective date couldn't be greater than the expiration date.";
    }
    if (
      isDateAfterDate(
        policyEffectiveDate ?? "",
        localModalStateUI?.effectiveDate ?? ""
      ) ||
      isDateAfterDate(
        localModalStateUI?.expirationDate ?? "",
        policyExpirationDate ?? ""
      )
    ) {
      return "The dates you are trying to add are outside the policy dates.  If they are correct, go back and update the policy dates to proceed.";
    }

    return "";
  };

  useEffect(() => {
    const errorMessage = getErrorMessageByValuesChanged();
    setErrorDetails(errorMessage);
  }, [localModalStateUI, modalStatesUI.isModalOpen]);

  useEffect(() => {
    setLocalModalStateUI(modalStatesUI.initialStateValueWhenOpen);
  }, []);

  return (
    modalStatesUI && (
      <Modal
        size="sm"
        id={"modal-add-state-policy"}
        title={"Add State to Policy"}
        open={modalStatesUI.isModalOpen}
        showCloseButton={true}
        showCancelTextButton={true}
        saveEvent={validateBeforeSave}
        saveOverrideLabel="OK"
        cancelEvent={closeEvent}
        saveDisabled={errorDetails !== "" ? true : false}
      >
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 6, lg: 6, xl: 6 }}
          >
            <Select
              id="state"
              name="policyState"
              options={modalStatesUI.allStates ?? []}
              label="State"
              labelFontType="BOLD_CAPTION"
              optionsMaxHeight="180px"
              value={localModalStateUI?.stateCode}
              onChange={onStateChange}
            />
          </Col>
        </Row>
        <Box mb="20px" mt="20px">
          <Row {...rowWithNoMarginNorGutter}>
            <Col>
              <InputDate
                id="effectiveDate"
                name="policyStateEffectiveDate"
                label="Effective Date"
                labelFontType="BOLD_CAPTION"
                value={localModalStateUI?.effectiveDate}
                onChangeRawValue={(dateValue) =>
                  setLocalModalStateUI({
                    ...localModalStateUI,
                    effectiveDate: dateValue,
                  })
                }
              />
            </Col>
            <Col>
              <InputDate
                id="expirationDate"
                name="policyStateExpirationDate"
                label="Expiration Date"
                labelFontType="BOLD_CAPTION"
                value={localModalStateUI?.expirationDate}
                onChangeRawValue={(dateValue) =>
                  setLocalModalStateUI({
                    ...localModalStateUI,
                    expirationDate: dateValue,
                  })
                }
              />
            </Col>
          </Row>
        </Box>
        {errorDetails !== "" && (
          <Row rowWidth="90%" verticalGutter="0px" verticalMargin="0px">
            <Col horizontalAlign="flex-start" verticalGutter="0px">
              <Box sx={{ ...ModalStateErrorMessageStyles(theme) }}>
                <Header
                  className="modal-state-error"
                  size="h6"
                  marginBottom="0px"
                >
                  {errorDetails}
                </Header>
              </Box>
            </Col>
          </Row>
        )}
        <DialogConfirmation
          id={`id_state_selector_modal_${policyEffectiveDate}_${policyExpirationDate}`}
          name={`state_selector_modal_${policyEffectiveDate}_${policyExpirationDate}`}
          dialogDescriptionText="Are you sure? It looks like you are going to split this policy for this state. Don't forget to come back and add the state again with the other portion of the split."
          open={isDialogOpen}
          onOptionYesEvent={() => saveNewState()}
          onOptionNoEvent={() => setIsDialogOpen(false)}
          onCloseEvent={() => setIsDialogOpen(false)}
        />
      </Modal>
    )
  );
};

export default ModalState;
