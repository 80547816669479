import { cloneElement, useEffect, useState } from "react";
import { useBaseTableUtilities } from "../../../../hooks/useBaseTableUtilities";

const BaseTable2Registration = ({ name, children }) => {
  const { nameKeySynchronizer } = useBaseTableUtilities("registration");
  const [renderChildren, setRenderChildren] = useState<JSX.Element | null>(
    null
  );

  useEffect(() => {
    const nameKey = nameKeySynchronizer(name);

    if (nameKey.uiid !== null) {
      const wrapperElement = cloneElement(children, {
        uiid: nameKey.uiid,
        name: nameKey.name,
      });
      setRenderChildren(wrapperElement);
    }
  }, []);

  return renderChildren ? <>{renderChildren}</> : null;
};

export default BaseTable2Registration;
