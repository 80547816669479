import { FC, useEffect, useState } from "react";
import { Col, Input, InputDate, Modal, Row } from "../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { useApiGet, useApiPost } from "../../../../hooks";
import { ProgramPolicyPeriodPage } from "../../../../dtos/program-policy-period-page";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PolicyPeriodDateStatusEnum } from "../../../../dtos/policy-period-date-status-enum";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

type ProgramPolicyPeriodModalProps = {
  policyPeriodId: number;
  programId?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setRefreshTable: (refresh: boolean) => void;
};
const ProgramPolicyPeriodModal: FC<ProgramPolicyPeriodModalProps> = ({
  policyPeriodId,
  programId,
  isOpen,
  setIsOpen,
  setRefreshTable,
}) => {
  const { responseGet, dispatchGet } = useApiGet<ProgramPolicyPeriodPage>(
    `api/PolicyPeriod/GetProgramPolicyPeriod?policyPeriodId=${policyPeriodId}&programId=${programId}`
  );
  const [policyPeriodInfo, setPolicyPeriodInfo] =
    useState<ProgramPolicyPeriodPage | null>();

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    `api/PolicyPeriod/SavePolicyPeriod?programId=${programId}`,
    policyPeriodInfo?.policyPeriod
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const isNew = policyPeriodId === -1;

  const currentOrFuture =
    isNew ||
    policyPeriodInfo?.policyPeriod?.dateStatus ===
      PolicyPeriodDateStatusEnum.CURRENT ||
    policyPeriodInfo?.policyPeriod?.dateStatus ===
      PolicyPeriodDateStatusEnum.FUTURE;

  const future =
    isNew ||
    policyPeriodInfo?.policyPeriod?.dateStatus ===
      PolicyPeriodDateStatusEnum.FUTURE;

  const onSaveEvent = () => {
    if (conditionHasValue(policyPeriodInfo?.policyPeriod)) dispatchPost();
  };

  const onCloseEvent = () => {
    setIsOpen(false);
    setPolicyPeriodInfo(null);
    setErrorDetails(null);
  };

  const updatePolicyInfo = (value, field) => {
    setPolicyPeriodInfo({
      ...policyPeriodInfo,
      program: policyPeriodInfo?.program,
      policyPeriod: { ...policyPeriodInfo?.policyPeriod, [field]: value },
    });
  };

  useEffect(() => {
    if (isOpen) {
      dispatchGet();
    }
    if (!isOpen) setPolicyPeriodInfo(null);
  }, [isOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPolicyPeriodInfo(responseGet.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      if (validatorErrorResponse === null) {
        onCloseEvent();
        setRefreshTable(true);
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <Modal
      id={"programPolicyPeriod"}
      title="Policy Period"
      open={isOpen}
      closeEvent={() => onCloseEvent()}
      cancelButtonWithConfirmation
      showCancelTextButton
      showCloseButton
      cancelEvent={() => onCloseEvent()}
      saveEvent={() => onSaveEvent()}
    >
      <Col {...colWithNoMarginNorGutter} colDirection="column">
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
            <Input
              id="policyPeriodProgram"
              name="policy-period-program"
              label="Program"
              labelFontType="BOLD_CAPTION"
              readOnly
              value={policyPeriodInfo?.program?.programName}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
            <Input
              id="policyPeriodDisplayValue"
              name="policy-period-display-value"
              label="Policy Period Display Value"
              labelFontType="BOLD_CAPTION"
              maxLength={10}
              readOnly={!currentOrFuture}
              value={policyPeriodInfo?.policyPeriod?.displayValue}
              onChangeRawValue={(value) =>
                updatePolicyInfo(value, "displayValue")
              }
              errorMessage={errorDetails?.displayValue}
            />
          </Col>
          <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
            <Input
              id="policyPeriodPolicyYear"
              name="policy-period-policy-year"
              label="Policy Year"
              labelFontType="BOLD_CAPTION"
              type="number"
              thousandSeparator={false}
              decimalScale={0}
              maxLength={5}
              minNumericValue={1900}
              maxNumericValue={2999}
              readOnly={!currentOrFuture}
              value={policyPeriodInfo?.policyPeriod?.policyYear}
              onChangeRawValue={(value) =>
                updatePolicyInfo(value, "policyYear")
              }
              errorMessage={errorDetails?.policyYear}
            />
          </Col>
          <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
            <Input
              id="policyPeriodReportYear"
              name="policy-period-report-year"
              label="Report Year"
              labelFontType="BOLD_CAPTION"
              type="number"
              thousandSeparator={false}
              decimalScale={0}
              maxLength={5}
              minNumericValue={1900}
              maxNumericValue={2999}
              readOnly={!currentOrFuture}
              value={policyPeriodInfo?.policyPeriod?.reportYear}
              onChangeRawValue={(value) =>
                updatePolicyInfo(value, "reportYear")
              }
              errorMessage={errorDetails?.reportYear}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
            <InputDate
              id="policyPeriodFirstEffectiveDate"
              name="policy-period-firs-effective-date"
              label="First Effective Date"
              labelFontType="BOLD_CAPTION"
              readOnly={!future}
              value={policyPeriodInfo?.policyPeriod?.firstDate}
              onChangeRawValue={(value) => updatePolicyInfo(value, "firstDate")}
              errorMessage={errorDetails?.firstDate}
            />
          </Col>
          <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
            <InputDate
              id="policyPeriodLastEffectiveDate"
              name="policy-period-last-effective-date"
              label="Last Effective Date"
              labelFontType="BOLD_CAPTION"
              readOnly={!currentOrFuture}
              value={policyPeriodInfo?.policyPeriod?.lastDate}
              onChangeRawValue={(value) => updatePolicyInfo(value, "lastDate")}
              errorMessage={errorDetails?.lastDate}
            />
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};

export default ProgramPolicyPeriodModal;
