import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import color from "../../../../media/colors";
import { InputAdornment, styled, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { SEARCH_FILTER } from "../TableConstants";
import { escapeTextForRegex } from "../../../../utilities/stringFunctions";

const StyledSearchWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  padding: "0px 10px 0px 10px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const BaseTableSearchFilter = (
  filterOptions: BaseTableFilterOptions,
  columnsNames?: string[],
  columnDisplayNames?: string[],
  searchColumnsConnector?: string,
  searchOnEnterOnly: boolean = true
) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    filterOptions?.setFilterInclude?.([
      {
        filterName: SEARCH_FILTER,
        columnNames: columnsNames,
        filterValue: "all",
      },
    ]);
  }, []);

  const triggerSearchOnEnterKey = (e) => {
    if (searchOnEnterOnly) {
      const enterCodes = ["Enter", "NumpadEnter"];
      if (enterCodes.includes(e.key)) {
        filterOptions?.setFilterInclude?.([
          {
            filterName: SEARCH_FILTER,
            columnNames: columnsNames,
            filterValue: escapeTextForRegex(searchValue),
          },
        ]);
      }
    }
  };

  const updateSearchOnInputChange = (e: any) => {
    const searchValue =
      (e.target.value ?? "").trim() === "" ? "all" : e.target.value;

    setSearchValue(searchValue);
    if (!searchOnEnterOnly || searchValue === "all") {
      filterOptions?.setFilterInclude?.([
        {
          filterName: SEARCH_FILTER,
          columnNames: columnsNames,
          filterValue: escapeTextForRegex(searchValue),
        },
      ]);
    }
  };

  const formatPlaceHolderText = () => {
    if (columnDisplayNames !== undefined) {
      if (columnDisplayNames.length === 1)
        return `Search ${columnDisplayNames[0]}`;
      if (columnDisplayNames.length === 2)
        return `Search ${columnDisplayNames[0]} ${
          searchColumnsConnector ? searchColumnsConnector : "and"
        } ${columnDisplayNames[1]}`;
      if (columnDisplayNames.length >= 3) {
        const displayNames = columnDisplayNames.map((displayName) => {
          return displayName ===
            columnDisplayNames[columnDisplayNames.length - 1]
            ? `${searchColumnsConnector ? searchColumnsConnector : "and"}` +
                displayName
            : displayName === columnDisplayNames[columnDisplayNames.length - 2]
            ? displayName
            : displayName + ", ";
        });
        return "Search ".concat(...displayNames);
      }
    }
    return "Search...";
  };

  const onSearchIconClick = () => {
    if (searchValue !== "") {
      filterOptions?.setFilterInclude?.([
        {
          filterName: SEARCH_FILTER,
          columnNames: columnsNames,
          filterValue: escapeTextForRegex(searchValue),
        },
      ]);
    }
  };

  return (
    <StyledSearchWrapper>
      <TextField
        sx={{
          width: columnDisplayNames
            ? `${(formatPlaceHolderText().length + 15).toString()}ch`
            : "15ch",
          maxWidth: "50ch",
        }}
        className={"true-search-filter"}
        variant={"standard"}
        inputProps={{
          "true-element": `true-search-filter`,
          placeholder: formatPlaceHolderText(),
          onChange: (e) => updateSearchOnInputChange(e),
          onKeyDown: triggerSearchOnEnterKey,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                htmlColor={color.BLUE_PRIMARY}
                fontSize="small"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  onSearchIconClick();
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </StyledSearchWrapper>
  );
};

export default BaseTableSearchFilter;
