import { useEffect, useState } from "react";
import { Button, InputMemo } from "../TrueUI";

type CommentLineProps = {
  text: string;
  isUserEdited: boolean;
};

const NoeSandbox = () => {
  const [systemComments, setSystemComments] = useState<string[]>([]);
  const [commentLines, setCommentLines] = useState<CommentLineProps[]>([]);

  // Handler to update the state when the user edits the comments input
  const updateUserText = (event) => {
    const stringComments = event.target.value.split("\n");
    setCommentLines(
      stringComments.map(
        (comment) =>
          ({
            text: comment,
            isUserEdited: systemComments.includes(comment) === false,
          } as CommentLineProps)
      )
    );
  };

  // Function to update system text
  const updateSystemText = (newSystemComments: string[]) => {
    const newLines = [
      ...newSystemComments.map(
        (line) => ({ text: line, isUserEdited: false } as CommentLineProps)
      ),
      ...commentLines.filter((line) => line.isUserEdited),
    ];
    setCommentLines(newLines);
  };

  // Effect to update the system text when the initialSystemText prop changes
  useEffect(() => {
    updateSystemText(systemComments);
  }, [systemComments]);

  const joinComments = () => commentLines?.map((line) => line.text).join("\n");
  return (
    <>
      <InputMemo
        id={"id-comment-box"}
        name={"comment-box"}
        value={joinComments()}
        onChange={updateUserText}
        rows={10}
      />
      <Button
        onClick={() =>
          setSystemComments([
            "system-generated line 1",
            "system-generated line 2",
          ])
        }
      />
    </>
  );
};

export default NoeSandbox;
