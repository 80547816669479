import { Theme } from "../../media/themeTypes";

export const recentsSx = (theme: Theme) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  "& .table-recents-container": {
    background: theme?.PRIMARY_BACKGROUND,
    overflowX: "unset",
    "& table": {
      ml: "10px",
      minWidth: "100%",
      "& th": {
        pl: "6px",
        paddingTop: "10px",
        border: "none",
      },
      "& tr": {
        borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
        "&:last-child td, &:last-child th": { border: 0 },
      },
      "& td": {
        paddingTop: "10px",
        border: "none",
        color: theme?.TEXT_COLOR,
        fontSize: theme?.BODY?.SIZE,
        fontWeight: theme?.BODY?.WEIGHT,
      },
      ".cell-icons-time-message": {
        width: "400px",
      },
    },
  },
  "& .cell-title-page-name svg": {
    height: "40px",
    float: "left",
    marginRight: "15px",
    color: theme?.TEXT_COLOR,
  },
  "& .cell-title-page-name .insured-icon": {
    ml: "8px",
  },
  "& .cell-title-page-name .top-row a": {
    color: theme?.TEXT_COLOR,
    fontSize: theme?.BODY?.SIZE,
    fontWeight: theme?.BODY?.WEIGHT,
    lineHeight: "var(--default-line-height)",
  },
  "& .cell-title-page-name .bottom-row a": {
    color: theme?.PRIMARY,
    fontSize: theme?.BODY?.SIZE,
    fontWeight: theme?.BODY?.WEIGHT,
    lineHeight: "var(--default-line-height)",
  },
  "& .cell-icons-time-message>div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ".time-message-container": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  "& .unpinned": {
    transform: "rotate(90deg)",
    color: theme?.PRIMARY,
  },
  svg: {
    color: theme?.TEXT_COLOR,
  },
  // PAGINATION
  ".true_pagination": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    background: theme?.PRIMARY_BACKGROUND,
    border: `1px solid ${theme?.BORDER_COLOR}`,
    borderWidth: "0 0 1px 0",
  },
  ".true_pagination .kendo-true-pagination": {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
  },
  ".true_pagination .kendo-true-pagination .k-button:not(.k-icon-button)": {
    color: theme?.PRIMARY,
    borderRadius: "30px",
    width: "30px",
  },
  ".recent-items-counter": {
    paddingRight: "10px",
  },
});
