import { ClaimLandingTabEnums } from "../dtos/claim-landing-tab-enums";
import { PaymentBatchSectionEnums } from "../dtos/payment-batch-section-enums";
import { SubSideNavChildrenIndexEnum } from "../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../dtos/sub-side-nav-parents-index-enum";
import { getQueryStringsURL, getQueryValue } from "./URLUtilities_OBSOLETE";
import {
  INSURED_PARENT_ITEM,
  INSURED_CHILD_ITEM,
  INSURED_BODY_SECTION,
  CLAIMS_ACTIVE_SECTION,
  CLAIM_DETAILS_TABS,
  CLAIMS_BODY_SECTION,
  CLAIMS_PARENT_ITEM,
  CLAIMS_CHILD_ITEM,
  CLAIM_NUMBER,
  INCIDENT_SECTION,
  PAYMENT_BATCH_ID,
  PAYMENT_BATCH_SECTION,
} from "./queryStringsHash";

/**
 * @deprecated The method should not be used
 */
export const getQueryStringsToClaim = (claimNumber) => {
  return getQueryStringsURL([
    {
      nameOfHash: CLAIMS_ACTIVE_SECTION,
      valueOfHash: 0,
    },
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_CLAIMS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
    },
    {
      nameOfHash: CLAIM_NUMBER,
      valueOfHash: claimNumber,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const getQueryStringsToPaymentBatch = (paymentBatchId) => {
  return getQueryStringsURL([
    {
      nameOfHash: CLAIMS_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.PAYMENTS_BATCH,
    },
    {
      nameOfHash: CLAIMS_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS_PAYMENTS,
    },
    {
      nameOfHash: CLAIMS_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.PAYMENT_BATCH,
    },
    {
      nameOfHash: PAYMENT_BATCH_SECTION,
      valueOfHash: PaymentBatchSectionEnums.PAYMENT_BATCH_DETAILS,
    },
    {
      nameOfHash: PAYMENT_BATCH_ID,
      valueOfHash: paymentBatchId,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const getQueryStringsToPaymentBatchSections = (section) => {
  return getQueryStringsURL([
    {
      nameOfHash: CLAIMS_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.PAYMENTS_BATCH,
    },
    {
      nameOfHash: CLAIMS_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS_PAYMENTS,
    },
    {
      nameOfHash: CLAIMS_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.PAYMENT_BATCH,
    },
    {
      nameOfHash: PAYMENT_BATCH_SECTION,
      valueOfHash: section,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const getClaimDetailsTabURL = (activeTab, claimNumber) => {
  return getQueryStringsURL([
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_CLAIMS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
    },
    {
      nameOfHash: CLAIMS_ACTIVE_SECTION,
      valueOfHash: ClaimLandingTabEnums.DETAILS.toString(),
    },
    {
      nameOfHash: CLAIM_DETAILS_TABS,
      valueOfHash: activeTab,
    },
    {
      nameOfHash: CLAIM_NUMBER,
      valueOfHash: claimNumber,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const getClaimDetailsTabURLWithClaimNumber = (
  activeTab,
  claimNumber
) => {
  return getQueryStringsURL([
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_CLAIMS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
    },
    {
      nameOfHash: CLAIMS_ACTIVE_SECTION,
      valueOfHash: ClaimLandingTabEnums.DETAILS.toString(),
    },
    {
      nameOfHash: CLAIM_DETAILS_TABS,
      valueOfHash: activeTab,
    },
    {
      nameOfHash: CLAIM_NUMBER,
      valueOfHash: claimNumber,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const goToAnyTopClaimSection = (
  topClaimSection,
  parentItem,
  childItem
) => {
  return getQueryStringsURL([
    {
      nameOfHash: CLAIMS_PARENT_ITEM,
      valueOfHash: parentItem,
    },
    {
      nameOfHash: CLAIMS_CHILD_ITEM,
      valueOfHash: childItem,
    },
    {
      nameOfHash: CLAIMS_BODY_SECTION,
      valueOfHash: topClaimSection,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const goToInsuredIncidentSection = () => {
  return getQueryStringsURL([
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.INCIDENTS,
    },
    {
      nameOfHash: INCIDENT_SECTION,
      valueOfHash: 0,
    },
  ]);
};

/**
 * @deprecated The method should not be used
 */
export const getCastedQueryValue = (queryName) => {
  const queryValue = getQueryValue(queryName);
  return queryValue !== "" ? parseInt(queryValue) : 0;
};
