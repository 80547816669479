export const insuredPaymentsContainer = {
  marginTop: "5px",
  flexGrow: 1,
  display: "flex",
  height: "100%",
  marginBottom: "5px",
};

export const insuredPaymentsFooter = {
  width: "100%",
  display: "flex",
  fontWeight: "700",
  height: "48px",
};
