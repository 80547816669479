import { FC } from "react";
import { Col, Row } from "../../../TrueUI";
import { Activity } from "../../../Activity";
import { ActivityLogFieldsEnum } from "../../../../dtos/activity-log-fields-enum";
import ClaimLandingDetails from "./ClaimLandingDetails";
import ClaimLandingClaimant from "./ClaimLandingClaimant";
import ClaimLandingContactTable from "./ClaimLandingContactTable";
import ClaimLandingFinancialTable from "./ClaimLandingFinancialTable";
import { ClaimLandingDetailsDto } from "../../../../dtos/claim-landing-details-dto";
import { ClaimLandingClaimantDto } from "../../../../dtos/claim-landing-claimant-dto";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { useRecoilValue } from "recoil";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import style from "./claimLandingBody.module.css";

type ClaimLandingBodyProps = {
  insuredId: number;
  tabKey: string;
  claimId?: number;
  claimNumber?: string;
  claimLandingDetails?: ClaimLandingDetailsDto;
  claimLandingClaimant?: ClaimLandingClaimantDto;
};

const ClaimLandingBody: FC<ClaimLandingBodyProps> = ({
  insuredId,
  claimId,
  claimNumber,
  claimLandingDetails,
  claimLandingClaimant,
  tabKey,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  return (
    <div className={style.claim_landing_body_wrapper}>
      <Row
        verticalAlign="unset"
        {...rowWithNoMarginNorGutter}
        className={style.claim_landing_body_row}
      >
        <Col
          breakpoints={{ md: 8, lg: 8, xl: 8 }}
          {...colWithNoMarginNorGutter}
        >
          <div className={style.claim_body_left}>
            <ClaimLandingDetails
              claimLandingDetailsData={claimLandingDetails}
              claimId={claimId}
              claimNumber={claimNumber}
              insuredId={insuredId}
              tabKey={tabKey}
              claimLandingClaimant={claimLandingClaimant}
            />
            <ClaimLandingClaimant
              claimLandingClaimantData={claimLandingClaimant}
              insuredId={insuredId}
            />
            <ClaimLandingFinancialTable
              claimNumber={claimNumber}
              insuredId={insuredId}
            />
            <ClaimLandingContactTable
              claimNumber={claimNumber}
              insuredId={insuredId}
            />
          </div>
        </Col>
        <Col
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          verticalMargin="0px"
          verticalGutter="0px"
          verticalAlignSelf="unset"
          className={style.claim_body_right}
        >
          <Activity
            lookupId={claimId}
            lookupField={ActivityLogFieldsEnum.CLAIM_ID}
            theme={theme}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ClaimLandingBody;
