import { ReserveConfigurationDto } from "../../../../../dtos/reserve-configuration-dto";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import ReservesApprovalFooter from "./ReservesApprovalFooter";
import { limitMessage } from "./ReservesApprovalUtils";

export const tableName = "approval_reserves_table";
export const getTableConfig = (
  configurationOptions: ReserveConfigurationDto,
  getUrl: string,
  editModalLinkEvent: (rowData?: any) => JSX.Element,
  setTotalReserves: (filteredData: string[][], columns: any[]) => void,
  resetGetURL: (getURL: string) => void
) => {
  const config: BaseTable2Properties = {
    name: tableName,
    getURL: getUrl,
    toolbarOptions: {
      showImportButton: false,
      showEditButton: false,
      showSortFilter: false,
      showAddButton: false,
    },
    footerOptions: {
      showCustomFooter: true,
      CustomFooterComponent: () => ReservesApprovalFooter(),
    },
    columnOptions: [{ fieldName: "OPTIONS", width: 5 }],
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "typeId",
          filterOptions: actionOptions,
          columnsNames: ["TypeId"],
          optionsOverride: configurationOptions?.reserveOptions?.reserveTypes,
          labelText: "Type:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "createId",
          filterOptions: actionOptions,
          columnsNames: ["CreateId"],
          optionsOverride: configurationOptions?.reserveOptions?.reserveUsers,
          labelText: "Added By:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "adjusterId",
          filterOptions: actionOptions,
          columnsNames: ["Adjuster"],
          optionsOverride: configurationOptions?.reserveOptions?.adjusters,
          labelText: "Adjuster:",
          labelPosition: "start",
        }),
      () => limitMessage(configurationOptions.userReserveLimit),
    ],
    advancedOptions: {
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          editModalLinkEvent?.(actionOptions.row) ?? null,
      },
    },
    events: {
      onDataFilteredChange: (data, columns) => {
        setTotalReserves(data, columns);
      },
      onDataToBeSavedChange: (dataToBeSaved) => {
        if (dataToBeSaved.length === 0) {
          resetGetURL(`${getUrl}?key=crypto.randomUUID()`);
        }
      },
    },
  };
  return config;
};
export type ModalConfig = {
  reserveId: number;
  isReserveModalOpen: boolean;
  claimId: number;
};
