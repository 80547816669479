import { FC } from "react";
import { useFileRequestInstance } from "../../../hooks/useFileStorage";
import Button from "../Buttons/Button";

type DownloadLinkButtonProps = {
  fileNames: string[];
  zipName: string;
  variantStyle?: "contained" | "outlined" | "text";
  children?: any;
  onClick?: () => void;
};

const DownloadAsZipButton: FC<DownloadLinkButtonProps> = ({
  fileNames,
  zipName,
  children,
  variantStyle,
  onClick,
}) => {
  const { downloadAsZipFile } = useFileRequestInstance();
  return (
    <Button
      variantStyle={variantStyle}
      name={zipName}
      onClick={() => {
        downloadAsZipFile(fileNames, zipName);
        onClick?.();
      }}
    >
      {children}
    </Button>
  );
};

export default DownloadAsZipButton;
