import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FontsType } from "../../media/themeTypes";
import { KeysAsType } from "../../types/KeysAsAType";
import { FormattingDate } from "../../utilities/dateFunctions";
import { InputDate } from "../TrueUI";
import { BaseTableFilterOptions } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { ShowMailRoomDateFiltersAtom } from "./MailRoomAtoms";

type BaseTableInputDateFilterProps = {
  id?: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  label?: string;
  defaultValue?: Date | null;
  labelPosition?: "start" | "end" | "top" | "bottom";
  inputWidth?: string;
  labelFontType?: KeysAsType<FontsType>;
  onChangeRawValue?: (e?: any) => void;
  isStaticFilter?: boolean;
};

const BaseTableInputDateFilter: (
  properties: BaseTableInputDateFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames,
  label,
  defaultValue: propValue,
  labelPosition,
  inputWidth,
  labelFontType,
  onChangeRawValue,
  isStaticFilter,
}) => {
  const [_value, _setValue] = useState<Date | null>(propValue ?? null);

  const showFilter = useRecoilValue(ShowMailRoomDateFiltersAtom);

  if (isStaticFilter) {
    filterOptions.isStaticFilter?.(true);
  }

  const setDateValueFilter = (value: string) => {
    if (!isStaticFilter) {
      filterOptions?.setFilterInclude?.([
        {
          filterName: "input_date_filter",
          columnNames: columnsNames,
          filterValue: value,
        },
      ]);
    }
    onChangeRawValue?.(new Date(value));
  };

  useEffect(() => {
    setDateValueFilter(FormattingDate(_value));
  }, []);

  return (
    <>
      {showFilter && (
        <InputDate
          id={id ?? "filter-input-date-id"}
          name={`filter-input-date-${id}`}
          value={_value}
          label={label}
          labelPosition={labelPosition ?? "start"}
          inputWidth={inputWidth}
          labelFontType={labelFontType ?? "TITLE"}
          onChangeRawValue={(value) => {
            setDateValueFilter(FormattingDate(value));
            _setValue(value);
          }}
        />
      )}
    </>
  );
};

export default BaseTableInputDateFilter;
