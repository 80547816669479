import { BaseTableColumn } from "../../../dtos/base-table-column";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import {
  DELETED_COLUMN_FIELD_NAME,
  INDICATION_COLUMN_FIELD_NAME,
  IS_NEW_ROW_COLUMN_FIELD_NAME,
  IS_NEW_ROW_DEFAULT_VALUE,
  MULTI_SELECTION_COLUMN_FIELD_NAME,
  OPTION_COLUMN_FIELD_NAME,
  ROW_KEY_COLUMN_FIELD_NAME,
} from "./TableConstants";

export const InternalTableColumns = {
  _rowKeyColumn: {
    // internal
    _columnIndex: 0,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: ROW_KEY_COLUMN_FIELD_NAME,
    fieldName: ROW_KEY_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: null, // investigate creating a default key using crypto.randomUIID()
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _multiSelectionColumn: {
    // internal
    _columnIndex: 1,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,
    // external
    displayName: " ",
    fieldName: MULTI_SELECTION_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.MULTI_SELECTION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _indicationColumn: {
    // internal
    _columnIndex: 2,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName: INDICATION_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.INDICATION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _optionsColumn: {
    // internal
    _columnIndex: 3,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName: OPTION_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.OPTIONS,
    displayOptions: [],
    defaultValue: "edit",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowDeletedColumn: {
    // internal
    _columnIndex: 4,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: DELETED_COLUMN_FIELD_NAME,
    fieldName: DELETED_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowNewColumn: {
    // internal
    _columnIndex: 5,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: "New",
    fieldName: IS_NEW_ROW_COLUMN_FIELD_NAME,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: IS_NEW_ROW_DEFAULT_VALUE,
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
};
