import { FC } from "react";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { Col, Input, InputDate, Row, Select } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import AccountAndBalanceSection from "../AccountAndBalanceSection";
import { ModalAddPaymentContentProps } from "./ModalAddPaymentUtil";

const ModalAddPaymentContent: FC<ModalAddPaymentContentProps> = ({
  newPaymentData,
  setNewPaymentData,
  paymentFormSelectsData,
  errorMessages,
  isReadyToRenderTable,
  setBillingTransactionData,
  accountTableData,
}) => {
  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-bank-account",
              "bank-account",
              "Bank Account",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={paymentFormSelectsData?.bankAccountsList ?? []}
            value={newPaymentData?.bankAccountId}
            onChange={(value) => {
              setNewPaymentData?.({ ...newPaymentData, bankAccountId: value });
            }}
            errorMessage={errorMessages?.bankAccountId}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-payment-type",
              "payment-type",
              "Payment Type",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={paymentFormSelectsData?.paymentTypesList ?? []}
            value={newPaymentData?.paymentTypeId}
            onChange={(value) => {
              setNewPaymentData?.({ ...newPaymentData, paymentTypeId: value });
            }}
            errorMessage={errorMessages?.paymentTypeId}
          />
        </Col>
        <Col
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          horizontalAlign="flex-start"
        ></Col>
      </Row>

      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-payment-date"
            name="payment-date"
            label="Payment Date"
            labelFontType="BOLD_CAPTION"
            value={newPaymentData?.paymentDate}
            onChangeRawValue={(dateValue) =>
              setNewPaymentData?.({
                ...newPaymentData,
                paymentDate: dateValue,
              })
            }
            errorMessage={errorMessages?.paymentDate}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-date-received"
            name="name-date-received"
            label="Date Received"
            labelFontType="BOLD_CAPTION"
            value={newPaymentData?.dateReceived}
            onChangeRawValue={(dateValue) =>
              setNewPaymentData?.({
                ...newPaymentData,
                dateReceived: dateValue,
              })
            }
            errorMessage={errorMessages?.dateReceived}
          />{" "}
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-postmark-date"
            name="name-postmark-date"
            label="Postmark Date"
            labelFontType="BOLD_CAPTION"
            value={newPaymentData?.postmarkDate}
            onChangeRawValue={(dateValue) =>
              setNewPaymentData?.({
                ...newPaymentData,
                postmarkDate: dateValue,
              })
            }
            errorMessage={errorMessages?.postmarkDate}
          />{" "}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            id={"id-amount"}
            name={"amount"}
            label={"Amount"}
            labelFontType={"BOLD_CAPTION"}
            prefix=""
            value={newPaymentData?.amount}
            maxNumericValue={99999999.99}
            inputFontType="BODY"
            allowNegatives={false}
            variant={"filled"}
            type={"fixedCurrency"}
            errorMessage={errorMessages?.amount}
            onChangeRawValue={(value) => {
              setNewPaymentData?.({
                ...newPaymentData,
                amount: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-reference-number",
              "reference-number",
              "Check/Reference Number",
              50
            )}
            type={"text"}
            prefix={""}
            value={newPaymentData?.referenceNumber}
            onChange={(value) =>
              setNewPaymentData?.({
                ...newPaymentData,
                referenceNumber: value.target.value,
              })
            }
            errorMessage={errorMessages?.referenceNumber}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      {isReadyToRenderTable && (
        <AccountAndBalanceSection
          accountTableData={accountTableData}
          errorDetails={errorMessages}
          tranAccountOptions={paymentFormSelectsData?.tranAccountOptions ?? []}
          setBillingTransactionData={setBillingTransactionData}
        />
      )}
    </>
  );
};

export default ModalAddPaymentContent;
