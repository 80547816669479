import {
  conditionHasValue,
  isEmptyValue,
} from "../../../utilities/conditionalSupportFunctions";

export const getFormattedValue = (
  result: any,
  searchTextKeys: string[],
  searchTextKeysToShowExtraDataIntoInput?: string[]
) => {
  const arrayResult: string[] = [];
  const arrayResultExtraData: string[] = [];
  searchTextKeys?.forEach((key) => {
    const fieldValue = !isEmptyValue(key) ? result?.[key] : "";
    if (!isEmptyValue(fieldValue)) {
      arrayResult.push(fieldValue);
    }
  });
  if (conditionHasValue(searchTextKeysToShowExtraDataIntoInput)) {
    searchTextKeysToShowExtraDataIntoInput?.forEach((key) => {
      const fieldValue = !isEmptyValue(key) ? result?.[key] : "";
      if (!isEmptyValue(fieldValue)) {
        arrayResultExtraData.push(fieldValue);
      }
    });
  }
  const resultJoin = arrayResult.join(" - ");
  const resultExtraDataJoin = !isEmptyValue(arrayResultExtraData)
    ? arrayResultExtraData.join(", ")
    : "";

  return `${resultJoin} ${resultExtraDataJoin}`;
};
