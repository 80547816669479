import { FC, useEffect, useState } from "react";
import {
  ClaimStatusErrorsProps,
  ModalChangeClaimStatusTabProps,
} from "../ModalChangeProps";
import { Box } from "@mui/material";
import { Select } from "../../../../../TrueUI";
import { useApiPost } from "../../../../../../hooks";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";
import ClaimSwitchActions from "./ClaimSwitchActions";
import { ClaimStatusUpdatesDto } from "../../../../../../dtos/claim-status-updates-dto";
import { ClaimStatusEnums } from "../../../../../../dtos/claim-status-enums";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { getKeyFromEnumValue } from "../../../../../../utilities/enumFunctions";

const ModalChangeClaimStatusTab: FC<ModalChangeClaimStatusTabProps> = ({
  claimId,
  claimInfo,
  save,
  setSave,
  tabKey,
  setAllModalConfiguration,
  allModalConfiguration,
  setIsSaveButtonDisabled,
}) => {
  const [claimStatusUpdates, setClaimStatusUpdates] =
    useState<ClaimStatusUpdatesDto>({
      claimId: claimId,
      status: null,
      note: "",
      addNoteFlag: true,
      closeTasksFlag: false,
      adjustTotalReservesFlag: false,
      addReservesFlag: false,
      isAddReservesFlagReadonly: true,
    });
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const { responsePost, dispatchPost } = useApiPost(
    `api/Claims/SaveClaimStatusFromModal`,
    claimStatusUpdates
  );
  const [errors, setErrors] = useState<ClaimStatusErrorsProps | null>(null);
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const canSave = () =>
    claimStatusUpdates.status !== null &&
    ((claimStatusUpdates.addNoteFlag === true &&
      claimStatusUpdates.note.trim() !== "") ||
      claimStatusUpdates.addNoteFlag === false);

  useEffect(() => {
    if (save) {
      if (canSave()) {
        setIsDialogConfirmationOpen(true);
      } else {
        const errorsObject: ClaimStatusErrorsProps = {
          errorStatus: null,
          errorNote: null,
        };
        if (claimStatusUpdates.status === null) {
          errorsObject.errorStatus = ["You must select a new status."];
        }
        if (
          claimStatusUpdates.addNoteFlag === true &&
          claimStatusUpdates.note.trim() === ""
        ) {
          errorsObject.errorNote = [
            "You must add a note if the Add Note is selected.",
          ];
        }
        setErrors(errorsObject);
        setSave(false);
      }
    }
  }, [save]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setSave(false);
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalChangeClaimStatus: false,
      });
      setComponentTriggers([
        "claimBanner",
        "activityLogComponent",
        "financialTableClaimLandingPageComponent",
      ]);
    }
  }, [responsePost]);

  useEffect(() => {
    setErrors(null);
  }, [claimStatusUpdates]);

  useEffect(() => {
    setClaimStatusUpdates({
      ...claimStatusUpdates,
      adjustTotalReservesFlag:
        claimStatusUpdates.status === ClaimStatusEnums.CLOSED,
    });
  }, [claimStatusUpdates.status]);

  const onChangeClaimStatus = (value) => {
    setClaimStatusUpdates({
      ...claimStatusUpdates,
      status: value,
      addReservesFlag:
        value === ClaimStatusEnums.OPEN || value === ClaimStatusEnums.REOPENED,
      isAddReservesFlagReadonly: !(
        value === ClaimStatusEnums.OPEN || value === ClaimStatusEnums.REOPENED
      ),
    });
    setIsSaveButtonDisabled(claimInfo?.currentClaimStatus === value);
  };

  return (
    <Box
      className="select-status-container"
      sx={{ width: "100%", height: "300px", paddingTop: "10px" }}
    >
      <Select
        id="claim-new-status"
        name="claimStatusSelect"
        inputWidth="200px"
        label="New Status"
        labelFontType="BOLD_CAPTION"
        firstOptionAsDefault={false}
        options={claimInfo?.statusOptions ?? []}
        onChange={(value) => onChangeClaimStatus(value)}
        errorMessage={errors?.errorStatus}
      />
      <ClaimSwitchActions
        claimStatusUpdates={claimStatusUpdates}
        setClaimStatusUpdates={setClaimStatusUpdates}
        errorTextNote={errors?.errorNote}
      />
      <DialogConfirmation
        id="saveStatusConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={`Are you sure you want to change the status to ${getKeyFromEnumValue(
          ClaimStatusEnums,
          claimStatusUpdates?.status ?? 0
        )}?`}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
          setSave(false);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
          setSave(false);
        }}
        onOptionYesEvent={(close) => {
          dispatchPost();
          setIsDialogConfirmationOpen(close);
          setSave(false);
        }}
      />
    </Box>
  );
};

export default ModalChangeClaimStatusTab;
