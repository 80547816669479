import { FC, useState } from "react";
import { Box, Grid, Link } from "@mui/material";
import { Font, MobileTrueStepper } from "../../TrueUI";
import { PolicyDto } from "../../../dtos/policy-dto";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { currencyFormat } from "../../../utilities/currencyFunctions";
import { PolicyStatusEnums } from "../../../dtos/policy-status-enums";
import { formattingKey } from "../../../utilities/enumFunctions";
import { Item } from "../InsuredLandingStyles";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type PriorPolicyProps = {
  priorPolicyList: PolicyDto[] | undefined | null;
};

const PriorPolicy: FC<PriorPolicyProps> = ({ priorPolicyList }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const priorPolicyObj = priorPolicyList ? priorPolicyList[activeSlide] : null;

  const handleNext = () => {
    setActiveSlide((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveSlide((prevActiveStep) => prevActiveStep - 1);
  };

  const onPriorPolicyClick = () => {
    if (conditionHasValue(priorPolicyObj)) {
      alert("Waiting instructions");
    }
  };

  const titleStepper = () => {
    const priorPolicyTitle = conditionHasValue(priorPolicyObj)
      ? `Prior Policy - ${priorPolicyObj?.policyNumber}`
      : "No Prior Policy";
    return (
      <Link
        true-element="true-element-link-priorPolicyTitle"
        href="#"
        onClick={onPriorPolicyClick}
        variant="body2"
      >
        {priorPolicyTitle}
      </Link>
    );
  };

  const validateNegativeAuditResult = (auditResult) => auditResult < 0;
  const getAuditResultFormatted = (auditResult) =>
    `Audit Result - ${
      validateNegativeAuditResult(auditResult ?? -1) ? "(" : ""
    }${currencyFormat(Math.abs(auditResult ?? 0), true)}${
      validateNegativeAuditResult(auditResult ?? -1) ? ")" : ""
    }`;

  const BodyStepper = () => {
    return (
      <Box className={"insured-prior-policy-stepper"}>
        <Grid className={"prior-policy-grid"} container>
          <Grid item xs={12} lg={4}>
            <Item>
              <Font name="priorPolicyEffectiveDate">
                Effective - {FormattingDate(priorPolicyObj?.effectiveDate)} to{" "}
                {FormattingDate(priorPolicyObj?.expirationDate)}
              </Font>
            </Item>
            <Item>
              <Font name="priorPolicyWrittenPremium">
                {`Written Premium - ${currencyFormat(
                  priorPolicyObj?.writtenPremium,
                  true
                )}`}
              </Font>
            </Item>
            <Item>
              <Font name="priorPolicyAuditedPremium">
                {`Audited Premium - ${currencyFormat(
                  priorPolicyObj?.auditedPremium,
                  true
                )}`}
              </Font>
            </Item>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Item>
              <Font name="priorPolicyStatus">
                Status - {priorPolicyObj?.status}
                {priorPolicyObj?.status ==
                  formattingKey(PolicyStatusEnums[PolicyStatusEnums.AUDITED]) &&
                  ` (${FormattingDate(priorPolicyObj?.statusDate)})`}
              </Font>
            </Item>
            <Item>
              <Font name="priorPolicyAuditResult">
                {getAuditResultFormatted(priorPolicyObj?.auditResult)}
              </Font>
            </Item>
            <Item>
              <Font name="priorPolicyBalanceDue">
                {`Balance Due - ${currencyFormat(
                  priorPolicyObj?.balanceDue,
                  true
                )}`}
              </Font>
            </Item>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Item>
              <Link
                true-element="true-element-link-reviseAudit"
                href="#"
                onClick={() => {
                  alert("Waiting instructions");
                }}
                variant="body2"
              >
                + Revise Audit
              </Link>
            </Item>
            <Item>
              <Link
                true-element="true-element-link-receivePayment"
                href="#"
                onClick={() => {
                  alert("Waiting instructions");
                }}
                variant="body2"
              >
                + Receive a Payment
              </Link>
            </Item>
            <Item>
              <Link
                true-element="true-element-link-setUpPaymentPlan"
                href="#"
                onClick={() => {
                  alert("Waiting instructions");
                }}
                variant="body2"
              >
                + Set Up a Payment Plan
              </Link>
            </Item>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <div className="prior_policy_wrapper">
      <MobileTrueStepper
        stepsInfo={priorPolicyList || []}
        activeStep={activeSlide}
        handleBack={handleBack}
        handleNext={handleNext}
        BodyStepper={BodyStepper}
        variantText={titleStepper()}
        variant={"custom"}
      />
    </div>
  );
};

export default PriorPolicy;
