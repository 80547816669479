import { FC } from "react";
import { FormLabel } from "@mui/material";
import { ClaimSystemEnums } from "../../../../dtos/claim-system-enums";
import { FromEnumToArray } from "../../../../utilities/enumFunctions";
import { Col, Input, Row, Select, Switch } from "../../../TrueUI";
import { ProgramOptionDto } from "../../../../dtos/program-option-dto";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import style from "./AdminProgramInformation.module.css";
import { SelectOptions } from "../../../../dtos/select-options";

type ProgramOptionsProps = {
  programOptionsData: ProgramOptionDto;
  setProgramOptionsData: any;
  readOnly?: boolean;
  paymentProcessors?: SelectOptions[];
  programOptionErrors?: any;
};

const getChecked = (check: boolean) => (check ? true : false);

export const ProgramOptions: FC<ProgramOptionsProps> = ({
  programOptionsData,
  setProgramOptionsData,
  readOnly = false,
  paymentProcessors = [],
  programOptionErrors,
}) => {
  const claimSystem = FromEnumToArray(ClaimSystemEnums)
    .map((enumType) => ({
      displayName: enumType.key,
      stringValue: enumType.value,
    }))
    .reverse();

  const onTargetSwitchChange = (target: string, isChecked: boolean) =>
    setProgramOptionsData(
      (prev: ProgramOptionDto) =>
        ({
          ...prev,
          [target]: isChecked,
        } as ProgramOptionDto)
    );

  const onUnderwritingSwitchChange = (isChecked: boolean) =>
    setProgramOptionsData(
      (prev: ProgramOptionDto) =>
        ({
          ...prev,
          hasUnderwriting: isChecked,
          useLossHistory: false,
          useNarrative: false,
          customPolicyTypeLabel: "",
        } as ProgramOptionDto)
    );

  const onBillingSwitchChange = (isChecked: boolean) =>
    setProgramOptionsData(
      (prev: ProgramOptionDto) =>
        ({
          ...prev,
          hasBilling: isChecked,
          hasACH: false,
          hasCreditCard: false,
          creditCardProcessor: "",
          hasScheduled: false,
          hasCancellation: false,
          hasLateFees: false,
          hasRefunds: false,
          hasCommissions: false,
        } as ProgramOptionDto)
    );

  return (
    <div className={style.program_options_container}>
      <FormLabel className={style.form_label_program_options}>
        Program Options:
      </FormLabel>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={36}
        rowDirection="column"
        verticalAlign={"flex-end"}
        verticalGutter={"10px"}
        allowWrap
      >
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
          breakpoints={{ md: 35, lg: 35, xl: 35 }}
        >
          <Switch
            tabIndex={12}
            id="program-option-has-underwriting"
            name="has-underwriting"
            label={"Underwriting"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            labelPlacement={"start"}
            isChecked={getChecked(programOptionsData?.hasUnderwriting)}
            control={"switch"}
            onChangeIsChecked={(isChecked) =>
              onUnderwritingSwitchChange(isChecked)
            }
            readOnly={readOnly}
          />
        </Col>
        {getChecked(programOptionsData?.hasUnderwriting) && (
          <>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={13}
                id="program-option-loss-history"
                name="loss-history"
                label="Use Loss History?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.useLossHistory)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("useLossHistory", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={14}
                id="program-option-use-narrative"
                name="use-narrative"
                label="Use UW Notes?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.useNarrative)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("useNarrative", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
              className="admin-program-policy-type"
              breakpoints={{ md: 34, lg: 34, xl: 34 }}
            >
              <Input
                label="Policy Type Label"
                labelPosition="start"
                tabIndex={15}
                id="program-option-policy-type"
                name="policy-type"
                value={programOptionsData?.customPolicyTypeLabel}
                maxLength={50}
                sx={{ ml: 2 }}
                readOnly={
                  readOnly || !getChecked(programOptionsData?.hasUnderwriting)
                }
                className={
                  readOnly || !getChecked(programOptionsData?.hasUnderwriting)
                    ? "true_information_disabled"
                    : ""
                }
                onChangeRawValue={(value) => {
                  setProgramOptionsData({
                    ...programOptionsData,
                    customPolicyTypeLabel: value,
                  });
                }}
                errorMessage={programOptionErrors?.customPolicyTypeLabel}
              />
            </Col>
          </>
        )}
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
        >
          <Switch
            tabIndex={16}
            id="program-option-has-billing"
            name="has-billing"
            label={"Billing"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            labelPlacement={"start"}
            isChecked={getChecked(programOptionsData?.hasBilling)}
            control={"switch"}
            onChangeIsChecked={(isChecked) => onBillingSwitchChange(isChecked)}
            readOnly={readOnly}
          />
        </Col>
        {getChecked(programOptionsData?.hasBilling) && (
          <>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={17}
                id="program-option-has-ach"
                name="has-ach"
                label="ACH?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasACH)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasACH", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={18}
                id="program-option-has-credit-card"
                name="has-credit-card"
                label="Credit Card?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasCreditCard)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasCreditCard", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
              className="admin-program-payment-processor"
            >
              <Select
                tabIndex={19}
                id="program-option-payment-processor"
                name="payment-processor"
                readOnly={
                  readOnly || !getChecked(programOptionsData?.hasBilling)
                }
                firstOptionAsDefault={false}
                options={paymentProcessors}
                value={programOptionsData?.creditCardProcessor}
                inputWidth="200px"
                label="Processor"
                labelPosition="start"
                labelFontType="BOLD_CAPTION"
                onChange={(value) => {
                  setProgramOptionsData({
                    ...programOptionsData,
                    creditCardProcessor: value,
                  });
                }}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={20}
                id="program-option-has-scheduled"
                name="has-scheduled"
                label="Allow Scheduled?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasScheduled)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasScheduled", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={21}
                id="program-option-has-cancellation"
                name="has-cancellation"
                label="Cancellation?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasCancellation)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasCancellation", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={22}
                id="program-option-has-late-fees"
                name="has-late-fees"
                label="Late Fees?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasLateFees)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasLateFees", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={23}
                id="program-option-has-refunds"
                name="has-refunds"
                label="Refunds?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasRefunds)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasRefunds", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalMargin="50px"
              horizontalAlign="flex-start"
            >
              <Switch
                tabIndex={24}
                id="program-option-has-commissions"
                name="has-commissions"
                label="Commissions?"
                labelFontType="BOLD_CAPTION"
                className="switch_program_options"
                labelPlacement={"start"}
                isChecked={getChecked(programOptionsData?.hasCommissions)}
                control={"switch"}
                onChangeIsChecked={(isChecked) =>
                  onTargetSwitchChange("hasCommissions", isChecked)
                }
                readOnly={readOnly}
              />
            </Col>
          </>
        )}
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
        >
          <Switch
            tabIndex={25}
            id="program-option-has-agency"
            name="has-agency"
            label={"Agency Management"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            labelPlacement={"start"}
            isChecked={getChecked(programOptionsData?.hasAgency)}
            control={"switch"}
            onChangeIsChecked={(isChecked) =>
              onTargetSwitchChange("hasAgency", isChecked)
            }
            readOnly={readOnly}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
        >
          <Switch
            tabIndex={26}
            id="program-option-has-audit"
            name="has-audit"
            label={"Audit"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            labelPlacement={"start"}
            isChecked={getChecked(programOptionsData?.hasAudit)}
            control={"switch"}
            onChangeIsChecked={(isChecked) =>
              onTargetSwitchChange("hasAudit", isChecked)
            }
            readOnly={readOnly}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
        >
          <Switch
            tabIndex={27}
            id="program-option-has-claims"
            name="has-claims"
            label={"Claims"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            labelPlacement={"start"}
            isChecked={getChecked(programOptionsData?.hasClaims)}
            control={"switch"}
            onChangeIsChecked={(isChecked) =>
              onTargetSwitchChange("hasClaims", isChecked)
            }
            readOnly={readOnly}
          />
        </Col>
        {getChecked(programOptionsData?.hasClaims) && (
          <Col
            {...colWithNoMarginNorGutter}
            horizontalMargin="50px"
            horizontalAlign="flex-start"
            className="admin-program-payment-processor"
          >
            <Select
              tabIndex={28}
              id="program-option-claim-source"
              name="claim-source"
              readOnly={readOnly || !getChecked(programOptionsData?.hasClaims)}
              options={claimSystem}
              value={programOptionsData?.claimSource}
              inputWidth="200px"
              label="Claim System"
              labelPosition="start"
              labelFontType="BOLD_CAPTION"
              onChange={(value) => {
                setProgramOptionsData({
                  ...programOptionsData,
                  claimSource: value,
                });
              }}
            />
          </Col>
        )}
        <Col
          {...colWithNoMarginNorGutter}
          horizontalMargin="20px"
          horizontalAlign="flex-start"
        >
          <Switch
            tabIndex={29}
            id="program-option-has-portal"
            name="has-portal"
            label={"Portal"}
            labelPlacement={"start"}
            labelFontType="BOLD_CAPTION"
            className="switch_program_options"
            isChecked={getChecked(programOptionsData?.hasPortal)}
            control={"switch"}
            onChangeIsChecked={(isChecked) =>
              onTargetSwitchChange("hasPortal", isChecked)
            }
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </div>
  );
};
