import { FC, useState } from "react";
import { Font } from "../../TrueUI";
import { StatusEnums } from "../../../dtos/status-enums";
import { useNavigate } from "react-router";
import style from "./AgencyTable.module.css";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

const tableName = "agency_main_instance_wrapper_table";

const AgencyTable: FC = () => {
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState(
    StatusEnums.ACTIVE.toString()
  );

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          sx={{ padding: 0 }}
          aria-label="expand row"
          size="small"
          onClick={() => navigate(`${actionOptions?.row?.AgencyId}`)}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };
  const hasPermissionToAddAgency = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
    PermissionsEnums.TRUE_ADMIN,
  ]);
  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: "api/Agency/GetMainAgencyPage",
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          id: "agency-status-filter",
          columnNames: ["AgencyStatusId"],
          labelText: "Show:",
          defaultValue: filterStatus,
          onChange: (value) => setFilterStatus(value),
          options,
        }),
    ],
    toolbarOptions: {
      addButtonText: "ADD AGENCY",
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showAddButton: hasPermissionToAddAgency?.hasPermission ?? false,
    },
    columnOptions: [
      { fieldName: "AgencyScore", width: 4 },
      { fieldName: "AgencyCode", width: 10 },
      { fieldName: "AgencyName", width: 30 },
      { fieldName: "AgencyWebsite", width: 20, isEditable: true },
      { fieldName: "AgencyPhone", width: 12 },
      { fieldName: "AgencyStatus", width: 8 },
      { fieldName: "Appointments", width: 20 },
    ],
    events: {
      addEventOverrideCallback: () => {
        navigate("/agency/new");
      },
    },
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
  };

  useBaseGrid(tableConfiguration);
  return (
    <div className={style.agency_main_instance_wrapper}>
      <Font fontType="HEADING_4" textAlign="start">
        Agency Management
      </Font>
      <div style={{ height: "100%", overflow: "hidden", flex: 1 }}>
        <BaseGrid name={tableName} />
      </div>
    </div>
  );
};

export default AgencyTable;
