import { FC } from "react";
import { PortalSettings } from "./PortalSettings";
import {
  AlignedInputWithLabel,
  onChangeSettingField,
} from "./PortalSettingUtils";

const ContactEmailAddresses: FC<PortalSettings> = ({
  settings,
  setPortalSettings,
  errorDetails,
}) => {
  const commonFunction = (value, target) => {
    onChangeSettingField(settings, target, value, setPortalSettings);
  };

  return (
    <>
      <div style={{ margin: "0px 20px" }}>
        <AlignedInputWithLabel
          label={"Information"}
          inputId={"information"}
          inputValue={settings?.emailInformation}
          onChangeRawValue={(value) =>
            commonFunction(value, "emailInformation")
          }
          errorMessage={errorDetails?.emailInformation}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Billing"}
          inputId={"billing"}
          inputValue={settings?.emailBilling}
          onChangeRawValue={(value) => commonFunction(value, "emailBilling")}
          errorMessage={errorDetails?.emailBilling}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Claims"}
          inputId={"claims"}
          inputValue={settings?.emailClaims}
          onChangeRawValue={(value) => commonFunction(value, "emailClaims")}
          errorMessage={errorDetails?.emailClaims}
          maxLength={100}
        />
        <AlignedInputWithLabel
          label={"Loss Control"}
          inputId={"lossControl"}
          inputValue={settings?.emailLossControl}
          onChangeRawValue={(value) =>
            commonFunction(value, "emailLossControl")
          }
          errorMessage={errorDetails?.emailLossControl}
          maxLength={100}
        />
      </div>
    </>
  );
};

export default ContactEmailAddresses;
