import { FC, useEffect, useState } from "react";
import { Button, TitleBold } from "../../../TrueUI";
import "./userFormStyles.module.css";
import { useRecoilState } from "recoil";
import { UserCustomerProgramSelectedAtom } from "../AdminSecurityAtoms";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { PermissionInfoDto } from "../../../../dtos/permission-info-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import HierarchyList, {
  ItemProps,
} from "../../../TrueUI/List/HierarchyList/HierarchyList";
import { UserPermissionDto } from "../../../../dtos/user-permission-dto";
import CancelButton from "../../../TrueUI/Buttons/CancelButton";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { SaveActionPermissionsEnum } from "../../../../dtos/save-action-permissions-enum";
import { PermissionDto } from "../../../../dtos/permission-dto";

type UserPermissionsProps = {
  tenantId?: number;
};

type ConfigurationOptionsProps = {
  permissionOptions?: ItemProps[] | null;
  userPermissions?: UserPermissionDto | null;
  permissionsInfo?: PermissionInfoDto[] | null;
  isProcessFinished?: boolean;
};

export type DialogProps = {
  dialogId?: string;
  dialogText?: string;
  yesButtonLabel?: string;
  isOpen?: boolean;
  permissionId?: number;
  exclusions?: number[];
  onNoEvent?: boolean;
};

const UserPermissions: FC<UserPermissionsProps> = ({ tenantId }) => {
  const [configurationOptions, setConfigurationOptions] =
    useState<ConfigurationOptionsProps>({});
  const [dialogProps, setDialogProps] = useState<DialogProps>();
  const [userCustomerProgramSelected, setUserCustomerProgramSelected] =
    useRecoilState(UserCustomerProgramSelectedAtom);

  const { responseGet, dispatchGet } = useApiGet<PermissionInfoDto[]>(
    `api/AdminTools/Security/UserListing/GetPermissionsForAppUsers`
  );

  const {
    responseGet: responseUserPermissions,
    dispatchGet: dispatchUserPermissions,
  } = useApiGet<UserPermissionDto>(
    `api/AdminTools/Security/UserListing/GetProgramUserPermissions?programUser=${userCustomerProgramSelected}&tenantId=${tenantId}`
  );

  const { responsePost, dispatchPost } = useApiPost(
    `api/AdminTools/Security/UserListing/SaveProgramUserPermissions?tenantId=${tenantId}`,
    configurationOptions?.userPermissions
  );

  const reviewIfChecked = (permissionId): boolean => {
    const selectedPermissions =
      configurationOptions?.userPermissions?.permissions?.find(
        (p) => p.permissionId === permissionId
      );
    return selectedPermissions?.hasPermission ?? false;
  };

  const getDisabledPermissions = (
    permissionsInfo: PermissionInfoDto[],
    userPermissions?: PermissionDto[]
  ): number[] => {
    const checkedPermissions = userPermissions?.filter(
      (up) => up.hasPermission === true
    );

    const disabledPermission: number[] = [];

    checkedPermissions?.forEach((cp) => {
      const permissionExclusions =
        permissionsInfo?.find((ptr) => ptr.permissionId === cp.permissionId)
          ?.exclusionIds ?? [];

      disabledPermission.push(...permissionExclusions);
    });

    return disabledPermission;
  };

  const getOrderedItems = (
    permissions: PermissionInfoDto[],
    userPermissions: PermissionDto[]
  ): ItemProps[] => {
    const organizedData: ItemProps[] = [];

    const disabledPermissions = getDisabledPermissions(
      permissions,
      userPermissions
    );

    permissions?.forEach((item) => {
      const isChecked = reviewIfChecked(item.permissionId);
      const newItem = {
        itemId: item.permissionId,
        displayName: item.permissionName,
        description: item.permissionDescription,
        isChecked: isChecked,
        isDisabled: isChecked
          ? false
          : disabledPermissions?.includes(item.permissionId),
      };
      if (item.sortOrder % 10 === 0) {
        organizedData.push({ ...newItem, childrenItems: [] });
      } else {
        const lastParent = organizedData[organizedData.length - 1];
        if (lastParent) {
          lastParent?.childrenItems?.push(newItem);
        }
      }
    });
    return organizedData ?? [];
  };

  const setPermissions = (permissions) => {
    setConfigurationOptions({
      ...configurationOptions,
      userPermissions: {
        ...configurationOptions?.userPermissions,
        permissions: permissions,
      },
      isProcessFinished: false,
    });
  };

  const onCheckChange = (permissionId, isChecked) => {
    const permissionsInfo = configurationOptions?.permissionsInfo;
    const permission = permissionsInfo?.find(
      (permission) => permission?.permissionId === permissionId
    );
    const permissionExclusions = permission?.exclusionIds;

    if ((permissionExclusions?.length ?? 0) > 0 && isChecked) {
      const excludedPermissions = permissionsInfo
        ?.filter((pi) => permissionExclusions?.includes(pi.permissionId))
        .map((ex) => ex.permissionName);
      const excludedNames = excludedPermissions?.join(", ");

      setDialogProps({
        dialogText: `Adding permission <b>${permission?.permissionName}</b> would require removing permission(s) <b>${excludedNames}</b>. Do you wish to continue?`,
        yesButtonLabel: "Yes",
        dialogId: "permissions-exclusion",
        exclusions: permissionExclusions,
        onNoEvent: true,
        isOpen: true,
        permissionId: permissionId,
      });
    } else {
      const allPermissions = getAllPermissions(permissionId, isChecked);
      setPermissions(allPermissions);
    }
  };

  const getAllPermissions = (permissionId, isChecked) => {
    const userPermission = configurationOptions?.userPermissions;

    const selectedPermission = userPermission?.permissions?.find(
      (p) => p.permissionId === permissionId
    );

    const otherPermissions = userPermission?.permissions?.filter(
      (p) => p.permissionId !== permissionId
    );

    const newPermission = {
      userPermissionId: 0,
      programUserId: userPermission?.programUserId ?? 0,
      permissionId: permissionId,
    };

    const permission = conditionHasValue(selectedPermission)
      ? selectedPermission
      : newPermission;

    const allPermissions = [
      ...(otherPermissions ?? []),
      { ...permission, hasPermission: isChecked },
    ];

    return allPermissions;
  };

  const getPermissionsWithExclusions = (
    updatedPermissions,
    permissionExclusions
  ) =>
    updatedPermissions?.map((permission) =>
      permissionExclusions?.includes(permission.permissionId)
        ? { ...permission, hasPermission: false }
        : { ...permission }
    );

  const refreshData = () => {
    setUserCustomerProgramSelected(null);
    setConfigurationOptions({
      ...configurationOptions,
      userPermissions: null,
      permissionOptions: null,
    });
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (conditionHasValue(userCustomerProgramSelected)) {
      dispatchUserPermissions();
    }
  }, [userCustomerProgramSelected]);

  useEffect(() => {
    if (isAPITotallyComplete(responseUserPermissions)) {
      const userPermissions = responseUserPermissions?.responseData;
      setConfigurationOptions({
        ...configurationOptions,
        userPermissions: {
          ...userPermissions,
          saveAction: SaveActionPermissionsEnum.UPDATE,
        },
        isProcessFinished: false,
      });
    }
  }, [responseUserPermissions]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const permissionsInfo = responseGet?.responseData;
      const orderPermissions =
        permissionsInfo?.sort((a, b) => a.sortOrder - b.sortOrder) ?? [];

      setConfigurationOptions({
        ...configurationOptions,
        permissionsInfo: orderPermissions,
        isProcessFinished: false,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      conditionHasValue(configurationOptions?.permissionsInfo) &&
      conditionHasValue(configurationOptions?.userPermissions) &&
      configurationOptions?.isProcessFinished === false
    ) {
      const permissionsList: ItemProps[] = getOrderedItems(
        configurationOptions?.permissionsInfo ?? [],
        configurationOptions?.userPermissions?.permissions ?? []
      );

      setConfigurationOptions({
        ...configurationOptions,
        permissionOptions: permissionsList,
        isProcessFinished: true,
      });
    }
  }, [configurationOptions]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setDialogProps({
        dialogText:
          "The user's permissions have been saved.  They will go into effect the next time the user logs into the system.",
        yesButtonLabel: "OK",
        dialogId: "permissions-saved",
        isOpen: true,
      });
    }
  }, [responsePost]);

  return (
    <div className={"admin_permissions_container"}>
      <div className={"admin_permissions_title"}>
        <TitleBold display="block" textAlign="start">
          PERMISSIONS
        </TitleBold>
      </div>
      <div className={"admin_permissions_section"}>
        {conditionHasValue(userCustomerProgramSelected) ? (
          <>
            <div className={"admin_permissions_checks"}>
              <HierarchyList
                items={configurationOptions?.permissionOptions ?? []}
                isCheckboxList
                showTooltip
                onCheckboxChange={(value, isChecked) => {
                  onCheckChange(value, isChecked);
                }}
              />
            </div>
            <div className={"admin_permissions_actions"}>
              <Button onClick={() => dispatchPost()}>SAVE</Button>

              <CancelButton withConfirmation onClick={refreshData} />
            </div>
          </>
        ) : null}
      </div>
      <DialogConfirmation
        id={dialogProps?.dialogId ?? ""}
        open={dialogProps?.isOpen}
        dialogDescriptionText={dialogProps?.dialogText}
        optionYesOverrideLabel={dialogProps?.yesButtonLabel}
        onOptionNoEvent={() => {
          if (dialogProps?.onNoEvent) {
            return setDialogProps({ ...dialogProps, isOpen: false });
          }
          return null;
        }}
        onOptionYesEvent={() => {
          if (dialogProps?.dialogId === "permissions-saved") {
            refreshData();
          }
          if (dialogProps?.dialogId === "permissions-exclusion") {
            const allPermissions = getAllPermissions(
              dialogProps?.permissionId,
              true
            );
            const permissionsExcluded = getPermissionsWithExclusions(
              allPermissions,
              dialogProps?.exclusions
            );
            setPermissions(permissionsExcluded);
          }

          setDialogProps({ ...dialogProps, isOpen: false });
        }}
      />
    </div>
  );
};
export default UserPermissions;
