import { FC } from "react";
import { Modal } from "../../TrueUI";
import AccessRightsSection from "./AccessRightsSection";
import CommandSection from "./CommandSection";
import PermissionsSection from "./PermissionsSection";
import UserInformationSection from "./UserInformationSection";
import { ModalPortalUserProps } from "./PortalUsersConst";

const ModalPortalUser: FC<ModalPortalUserProps> = ({
  modalInfo,
  setModalInfo,
}) => {
  const onCancelModal = () => {
    setModalInfo?.({
      ...modalInfo,
      isModalOpen: false,
      userId: null,
    });
  };

  return (
    <Modal
      id={"portal_user_modal"}
      title={"Portal User"}
      open={modalInfo?.isModalOpen}
      cancelEvent={onCancelModal}
      size={"lg"}
      cancelButtonWithConfirmation
    >
      <UserInformationSection modalInfo={modalInfo} />
      <div style={{ display: "flex", alignItems: "stretch", gap: "20px" }}>
        <AccessRightsSection userId={modalInfo?.userId} />
        <PermissionsSection />
      </div>
      <CommandSection modalInfo={modalInfo} setModalInfo={setModalInfo} />
    </Modal>
  );
};
export default ModalPortalUser;
