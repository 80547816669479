import { FC, useEffect, useState } from "react";
import { AchTransactionPostProps, ModalAchTransactionProps } from "./AchTypes";
import { Modal } from "../../TrueUI";
import AchTransactionModalContent from "./AchTransactionModalContent";
import { useApiPost } from "../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";

const ModalAchTransaction: FC<ModalAchTransactionProps> = ({
  modalData,
  setModalData,
}) => {
  const [achTransactionData, setAchTransactionData] = useState<any | null>(
    null
  );
  const [postConfiguration, setPostConfiguration] =
    useState<AchTransactionPostProps | null>(null);
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    postConfiguration?.postApiUrl ?? "",
    postConfiguration?.postData ?? 0
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      const newModalData = modalData.deleteRow
        ? {
            ...modalData,
            isModalOpen: false,
          }
        : {
            ...modalData,
            isModalOpen: false,
            refreshTable: crypto.randomUUID(),
          };
      setModalData(newModalData);
    }
  }, [responsePost]);

  useEffect(() => {
    if (postConfiguration !== null) {
      dispatchPost();
    }
  }, [postConfiguration]);

  const onSaveEvent = () => {
    setPostConfiguration({
      ...postConfiguration,
      postData: {
        scheduleId: achTransactionData?.ScheduleId ?? 0,
        policyId: achTransactionData?.PolicyId,
        insuredAccountId: achTransactionData?.InsuredAccountId,
        tranAccountId: achTransactionData?.TranAccountId,
        transactionAmount: parseFloat(achTransactionData?.TransactionAmount),
      },
      postApiUrl: `api/BillingTransaction/SaveAchBillingTransaction`,
    });
  };

  const onCancelEvent = () => {
    setModalData({ ...modalData, isModalOpen: false });
    setErrorDetails(null);
  };
  const onDeleteEvent = () => {
    setErrorDetails(null);
    setModalData({ ...modalData, deleteRow: true });
    setPostConfiguration({
      ...postConfiguration,
      postData: {
        scheduleId: achTransactionData?.ScheduleId ?? 0,
      },
      postApiUrl: `api/BillingTransaction/DeleteAchBillingTransaction`,
    });
  };

  const onCloseEvent = () => {};

  useEffect(() => {
    setAchTransactionData(modalData?.rowData);
  }, [modalData]);

  return (
    <>
      <Modal
        id={"ach_billing_transaction"}
        title={"ACH Transaction"}
        open={modalData.isModalOpen}
        showCloseButton={true}
        deleteEvent={onDeleteEvent}
        showCancelTextButton={true}
        saveEvent={onSaveEvent}
        cancelEvent={onCancelEvent}
        closeEvent={onCloseEvent}
        saveOverrideLabel={"SAVE"}
        cancelButtonWithConfirmation
        cancelButtonConfirmationText={
          "Are you sure you want to cancel, all you work will be lost?"
        }
        deleteButtonConfirmationText="Are you sure you want to delete this transaction?"
        deleteButtonWithConfirmation={true}
      >
        <AchTransactionModalContent
          achTransactionData={achTransactionData}
          setAchTransactionData={setAchTransactionData}
          errorDetails={errorDetails}
          setErrorDetails={setErrorDetails}
        />
      </Modal>
    </>
  );
};

export default ModalAchTransaction;
