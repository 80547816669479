import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import BaseTable2 from "../../../../../TrueUI/Tables/BaseTable2/BaseTable2";
import { RepresentationEventDto } from "../../../../../../dtos/representation-event-dto";
import { toLowerCaseKeys } from "../../../../../../utilities/objectFunctions";
import { FormStateProps } from "./typesAndConstants";

type ClaimLitigationEventsProps = {
  representationId?: number;
  hasClaimsManagementPermissions: boolean;
  handleSelectEvent: (data: RepresentationEventDto) => void;
  handleAddEvent: () => void;
  setFormState: (formState: FormStateProps) => void;
};

const ClaimLitigationEvents = ({
  representationId,
  handleSelectEvent,
  handleAddEvent,
  setFormState,
  hasClaimsManagementPermissions,
}: ClaimLitigationEventsProps) => {
  const url = `api/RepresentationEvents/GetEventsByRepresentationId?representationId=${representationId}`;

  const selectEvent = (data) => {
    const dataToSet: RepresentationEventDto = toLowerCaseKeys(
      data
    ) as RepresentationEventDto;
    dataToSet.eventStatus = parseInt(data.EventStatus);
    dataToSet.eventType = parseInt(data.EventType);
    handleSelectEvent(dataToSet);
    setFormState({
      title: "",
      mode: "readonly",
      rowTableClicked: "event",
    });
  };

  const tableName = "RepresentationEventsTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: url,
    columnOptions: [
      { fieldName: "EventType", width: 25 },
      { fieldName: "FormattedDate", width: 15 },
      { fieldName: "EventResult", width: 48 },
      { fieldName: "EventStatus", width: 12 },
    ],
    toolbarOptions: {
      toolBarType: "compact",
      showAddButton: hasClaimsManagementPermissions,
    },
    advancedOptions: {
      paginate: false,
      tableStyle: {
        height: "230px",
        headerStyle: "transparent",
      },
    },
    events: {
      onRowClick: (e) => selectEvent(e.hydratedData),
      addEventOverrideCallback: handleAddEvent,
    },
  };
  useBaseTable(tableConfiguration);

  return <BaseTable2 name={tableName} />;
};

export default ClaimLitigationEvents;
