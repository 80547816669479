import { FC, useEffect, useState } from "react";
import style from "./dashboardRightContent.module.css";
import { useApiGet } from "../../../hooks";
import { MainPage } from "../../../dtos/main-page";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { TaskBoardColumnDto } from "../../../dtos/task-board-column-dto";
import Recents from "../../Recents/Recents";
import TaskBoard, { SelectedCardType } from "../../TrueUI/TaskBoard/TaskBoard";
import { TaskBoardCardEnum } from "../../../dtos/task-board-card-enum";
import AssignedFilesCardContent from "./CardsContent/AssignedFilesCardContent";
import NotesGrid from "../Notes/NotesGrid";
import AssignedTasksTableWrapper from "../AssignedTasks/AssignedTasksTableWrapper";
import {
  HOME_CARD_STORAGE,
  getStorageValue,
  setStorageValue,
} from "../../../utilities/localStorageFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

const DashboardRightContent: FC = () => {
  const { responseGet, dispatchGet } = useApiGet<MainPage>(
    `api/Dashboard/GetMyWorkCards`
  );
  const columnNameToDisplay = "MyWorkCard";
  const [selectedCard, setSelectedCard] = useState<SelectedCardType>();
  const [columns, setColumns] = useState<TaskBoardColumnDto[]>([]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setColumns(responseGet?.axiosResponse?.data?.columns ?? []);
    }
  }, [responseGet]);

  const getColumnByColumnName = (columnName: string) => {
    return columns.find((column) => column.columnField === columnName) ?? null;
  };

  useEffect(() => {
    if (columns.length > 0) {
      const localStorageCard = getStorageValue(HOME_CARD_STORAGE);
      const defaultCard = conditionHasValue(localStorageCard)
        ? localStorageCard?.cardId ?? TaskBoardCardEnum.RECENT_AND_PINNED
        : TaskBoardCardEnum.RECENT_AND_PINNED;

      const column = getColumnByColumnName(columnNameToDisplay);
      setSelectedCard({
        columnField: column?.columnField,
        cardInformation: column?.cards.find(
          (card) => card.cardType === defaultCard
        ),
      } as SelectedCardType);
    }
  }, [columns]);

  const onClickCard = (cardInfo: any) => {
    setSelectedCard(cardInfo);
  };

  const setLocalStorageCard = (cardType: number) => {
    const localCard = getStorageValue(HOME_CARD_STORAGE);
    setStorageValue(HOME_CARD_STORAGE, { ...localCard, cardId: cardType });
  };

  const updateWorkCards = () => {
    dispatchGet();
  };

  const getContent = (cardType: number, cardTitle: string, cardId: string) => {
    setLocalStorageCard(cardType);
    switch (cardType) {
      case TaskBoardCardEnum.RECENT_AND_PINNED:
        return <Recents />;
      case TaskBoardCardEnum.TASKS:
      case TaskBoardCardEnum.DUE_SELECTED_DATE:
        return (
          <AssignedTasksTableWrapper filterType={cardType} cardId={cardId} />
        );
      case TaskBoardCardEnum.ASSIGNED_FILES:
        return <AssignedFilesCardContent updateWorkCards={updateWorkCards} />;
      case TaskBoardCardEnum.NOTES_IN_PAST_7_DAYS:
        return <NotesGrid />;
      default:
        return <div>Content For {cardTitle}</div>;
    }
  };
  const getRightContent = () => {
    const cardTitle: string = selectedCard?.cardInformation?.cardTitle ?? "";
    const cardType: number = selectedCard?.cardInformation?.cardType ?? 0;
    const cardId: string =
      selectedCard?.cardInformation.cardId ?? cardType.toString();
    return (
      <div className={style.dashboard_bottom_right_content}>
        {getContent(cardType, cardTitle, cardId)}
      </div>
    );
  };
  return (
    <div className={style.dashboard_bottom_content_container}>
      {columns.length > 0 && (
        <TaskBoard
          columns={columns}
          onClickCard={onClickCard}
          InternalComponent={getRightContent}
          currentCard={selectedCard}
          showHideContentSection={false}
          startLeftPanelOpen
        />
      )}
    </div>
  );
};

export default DashboardRightContent;
