import { FC, useEffect } from "react";
import { FormInvoiceItemProps } from "./TypesForModalAddInvoiceItem";
import { Col, Input, Row, Select } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";

const FormInvoiceItem: FC<FormInvoiceItemProps> = ({
  locations,
  tranAccounts,
  tranAccountDescriptions,
  invoiceItem,
  setInvoiceItem,
  errorDetails,
}) => {
  useEffect(() => {
    if (
      (invoiceItem?.priceEach ?? 0) !== 0 &&
      (invoiceItem?.quantity ?? 0) !== 0
    ) {
      setInvoiceItem({
        ...invoiceItem,
        extended: (invoiceItem?.priceEach ?? 0) * (invoiceItem?.quantity ?? 0),
      });
    }
  }, [invoiceItem?.priceEach, invoiceItem?.quantity]);

  useEffect(() => {
    if ((invoiceItem?.tranAccountId ?? null) !== null) {
      const tranAccountDescription = tranAccountDescriptions.find(
        (tranAccount) => tranAccount.intValue === invoiceItem?.tranAccountId
      );
      setInvoiceItem({
        ...invoiceItem,
        description:
          invoiceItem?.description ?? tranAccountDescription?.displayName ?? "",
      });
    }
  }, [invoiceItem?.tranAccountId]);

  useEffect(() => {
    if ((invoiceItem?.lineNumber ?? 1) > 999) {
      setInvoiceItem({
        ...invoiceItem,
        lineNumber: 999,
      });
    }
  }, [invoiceItem?.lineNumber]);

  useEffect(() => {
    if ((invoiceItem?.quantity ?? 1) > 999) {
      setInvoiceItem({
        ...invoiceItem,
        quantity: 999,
      });
    }
  }, [invoiceItem?.quantity]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} horizontalGutter={"15px"}>
          <Select
            id={"invoice-item-location"}
            name="invoice-item-location-name"
            label="Bill to Location"
            options={locations ?? []}
            value={invoiceItem?.addressId ?? null}
            onChange={(value) =>
              setInvoiceItem({ ...invoiceItem, addressId: value })
            }
            inputFontType="BODY"
            labelFontType="BOLD_CAPTION"
            readOnly={(invoiceItem?.invoiceDetailId ?? 0) !== 0}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 3, xl: 2 }} horizontalGutter={"15px"}>
          <Input
            id={"invoice-item-line-number"}
            name="invoice-item-line-number-name"
            label="Line Number"
            value={invoiceItem?.lineNumber ?? null}
            onChangeRawValue={(value) =>
              setInvoiceItem({ ...invoiceItem, lineNumber: value })
            }
            type={"number"}
            displayNumericArrows
            decimalScale={0}
            allowNegatives={false}
            minNumericValue={1}
            errorMessage={errorDetails?.lineNumber ?? null}
            maxNumericValue={999}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalGutter={"15px"}>
          <Select
            id={"invoice-item-transaction-account-number"}
            name="invoice-item-transaction-account-number-name"
            label="Transaction Account Number"
            options={tranAccounts ?? []}
            value={invoiceItem?.tranAccountId ?? null}
            onChange={(value) =>
              setInvoiceItem({ ...invoiceItem, tranAccountId: value })
            }
            inputFontType="BODY"
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            errorMessage={errorDetails?.tranAccountId}
          />
        </Col>
        <Col horizontalGutter={"15px"}>
          <Input
            id={"invoice-item-description"}
            name="invoice-item-description-name"
            label="Description"
            maxLength={200}
            value={invoiceItem?.description ?? null}
            onChangeRawValue={(value) =>
              setInvoiceItem({ ...invoiceItem, description: value })
            }
            errorMessage={errorDetails?.description ?? null}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 2 }} horizontalGutter={"15px"}>
          <Input
            id={"invoice-item-quantity"}
            name="invoice-item-quantity-name"
            label="Quantity"
            value={invoiceItem?.quantity ?? 1}
            onChangeRawValue={(value) =>
              setInvoiceItem({ ...invoiceItem, quantity: value })
            }
            type={"number"}
            displayNumericArrows
            decimalScale={0}
            allowNegatives={false}
            minNumericValue={1}
            maxNumericValue={999}
          />
        </Col>
        <Col
          breakpoints={{ md: 2, lg: 2, xl: 1 }}
          horizontalGutter={"15px"}
        ></Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} horizontalGutter={"15px"}>
          <Input
            id={"invoice-item-price-each"}
            name="invoice-item-price-each-name"
            label="Price Each"
            maxNumericValue={9000000}
            value={invoiceItem?.priceEach ?? null}
            onChangeRawValue={(value) =>
              setInvoiceItem({ ...invoiceItem, priceEach: value })
            }
            type={"fixedCurrency"}
            decimalScale={2}
            errorMessage={errorDetails?.priceEach ?? null}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} horizontalGutter={"15px"}>
          <Input
            id={"invoice-item-extended"}
            name="invoice-item-extended-name"
            label="Extended"
            value={invoiceItem?.extended ?? 0}
            readOnly
            type={"fixedCurrency"}
            decimalScale={2}
          />
        </Col>
        <Col
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
          horizontalGutter={"15px"}
        ></Col>
      </Row>
    </>
  );
};

export default FormInvoiceItem;
