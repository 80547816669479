import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Box,
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useApiPost } from "../../hooks";
import { RecentItemDto } from "../../dtos/recent-item-dto";
import { InsuredIcon, BillIcon, ClaimsIcon } from "../TrueUI/Icons/CustomIcons";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { RecentsRefreshAtom } from "./RecentsAtoms";
import { sortByDate } from "../../utilities/dateFunctions";
import { recentsSx } from "./RecentsStyles";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { Font, Select } from "../TrueUI";

const pageSizes: number[] = [5, 10, 25, 50, 100];
const optionsSizes = pageSizes.map((size) => ({
  displayName: size.toString(),
  intValue: size,
}));

type RecentsMainTableProps = {
  filteredRecents: RecentItemDto[];
};

const RecentsMainTable: FC<RecentsMainTableProps> = ({ filteredRecents }) => {
  const [pinned, setPinned] = useState<any>(null);
  const [insuredId, setInsuredId] = useState<number | null>();
  const [claimId, setClaimId] = useState<number | null>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const setRefreshPage = useSetRecoilState(RecentsRefreshAtom);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const skip = page * rowsPerPage ?? 0;

  const getInitialItem = () => {
    if (page === 0) {
      if (filteredRecents.length > 0) {
        return 1;
      }
      return 0;
    }
    return skip + 1;
  };

  const getCurrentItems = () => {
    const currentItems = (page + 1) * rowsPerPage;
    return currentItems > filteredRecents?.length
      ? filteredRecents?.length
      : currentItems;
  };

  const onChangePage = (event: PageChangeEvent) => {
    const { skip, take } = event;
    const newPage = skip / take;
    setPage(newPage);
  };

  const onChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const {
    dispatchPost: dispatchPostInsured,
    responsePost: responsePostInsured,
  } = useApiPost<RecentItemDto>("api/recents/SaveRecentInsuredPage", {
    id: insuredId,
    pinned: pinned,
  });
  const { dispatchPost: dispatchPostClaim, responsePost: responsePostClaim } =
    useApiPost<RecentItemDto>("api/recents/SaveRecentClaimPage", {
      id: claimId,
      pinned: pinned,
    });

  const pinTypeCheck = (row) => {
    switch (row.typeOfRecent) {
      case 1:
        pinInsured(row);
        break;
      case 3:
        pinClaim(row);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      responsePostInsured.responseData !== null ||
      responsePostClaim.responseData !== null
    ) {
      setRefreshPage(true);
    }
  }, [responsePostInsured, responsePostClaim]);

  useEffect(() => {
    if (conditionHasValue(insuredId)) dispatchPostInsured();
  }, [insuredId]);

  useEffect(() => {
    if (conditionHasValue(claimId)) dispatchPostClaim();
  }, [claimId]);

  const pinInsured = (row) => {
    setInsuredId(row.sourceId);
    setPinned(!row.isPinned);
    row.isPinned = !row.isPinned;
    row.isRecent = true;
    row.recentTimeMessage = "Just now";
  };

  const pinClaim = (row) => {
    setClaimId(row.sourceId);
    setPinned(!row.isPinned);
    row.isPinned = !row.isPinned;
    row.isRecent = true;
    row.recentTimeMessage = "Just now";
  };

  useEffect(() => {
    filteredRecents.sort(sortByDate);
  }, [filteredRecents]);

  return (
    <Box
      className={rowsPerPage < 11 ? "" : "table-container"}
      sx={recentsSx(theme)}
    >
      {filteredRecents && (
        <>
          <div style={{ flexGrow: 1, overflowX: "hidden", overflowY: "auto" }}>
            <TableContainer
              component={Paper}
              elevation={0}
              className={"table-recents-container"}
            >
              <Table size="small">
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredRecents.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredRecents
                  ).map((row: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="cell-title-page-name"
                      >
                        {row.typeOfRecent === 1 && (
                          <InsuredIcon
                            fontSize="medium"
                            className="insured-icon"
                          ></InsuredIcon>
                        )}
                        {row.typeOfRecent === 2 && <BillIcon></BillIcon>}
                        {row.typeOfRecent === 3 && (
                          <ClaimsIcon fontSize="large" />
                        )}
                        <div>
                          <div className="top-row">
                            <Link
                              to={row.path}
                              component={RouterLink}
                              underline="none"
                            >
                              {row.title}
                            </Link>
                          </div>
                          <div className="bottom-row">
                            <Link
                              to={row.path}
                              component={RouterLink}
                              underline="none"
                            >
                              {row.pageName}
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        className="cell-icons-time-message"
                      >
                        <div>
                          <div className="time-message-container">
                            <span>{row.recentTimeMessage}</span>
                          </div>
                          <span onClick={() => pinTypeCheck(row)}>
                            <PushPinOutlinedIcon
                              true-element={`true-element-pin-${
                                row.isPinned ? "pinned" : "unpinned"
                              }`}
                              className={row.isPinned ? "" : "unpinned"}
                            />
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="true_pagination">
            <Pager
              className="kendo-true-pagination"
              skip={skip}
              take={rowsPerPage ?? 10}
              total={filteredRecents.length}
              buttonCount={6}
              info={false}
              onPageChange={onChangePage}
              previousNext
            ></Pager>
            <div className="select-rows-recent-container">
              <Select
                id="recents-main"
                name="selectRowsRecent"
                inputWidth={"100px"}
                options={optionsSizes}
                optionsContainerPosition={"top"}
                label={"Items per page"}
                labelPosition={"end"}
                labelFontType={"BODY"}
                value={rowsPerPage ?? 10}
                onChange={onChangeRowsPerPage}
              />
            </div>
            <Font className="recent-items-counter">
              {`Items ${getInitialItem()} to ${getCurrentItems()} of ${
                filteredRecents.length
              }`}
            </Font>
          </div>
        </>
      )}
    </Box>
  );
};
export default RecentsMainTable;
