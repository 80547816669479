import { atom } from "recoil";
import { PortalSaveNewUserInformationDto } from "../../../dtos/portal-save-new-user-information-dto";

type PortalUserInformation = {
  insuredName?: string;
  programName?: string;
  programUserId?: number;
  insuredUserId?: number;
};

const PortalUserInformation = atom<PortalUserInformation | null>({
  key: "PortalUserInformation",
  default: null,
});

const RefreshAccessRightsTable = atom<boolean>({
  key: "RefreshAccessRightsTable",
  default: false,
});

const NewPortalInsuredUser = atom<PortalSaveNewUserInformationDto | null>({
  key: "NewPortalInsuredUser",
  default: null,
});

export {
  PortalUserInformation,
  RefreshAccessRightsTable,
  NewPortalInsuredUser,
};
