import { currencyFormat } from "../../../../../utilities/currencyFunctions";
import { Font } from "../../../../TrueUI";

export const limitMessage = (limit: number) => (
  <div style={{ alignSelf: "center", padding: "0 5px" }}>
    <Font isErrorFont>{`User Reserve Limit: ${currencyFormat(
      limit,
      true
    )}`}</Font>
  </div>
);
