import { FC, useEffect, useState } from "react";
import {
  BaseGridStructureHead,
  BaseGridStructureHeader,
  BaseGridStructureRow,
} from "./BaseGridStructure";
import { useGridInstance } from "./Hooks/useGridInstance";
import { dynamicSort } from "../../utilities/arrayFunctions";
import { BaseTableColumn } from "../../dtos/base-table-column";
import { useRecoilValue } from "recoil";
import styles from "./Styles/basegrid.module.css";
import { getWidthOfCells } from "./SupportFunctions/baseGridFunctions";
import { BaseTableOrder } from "../TrueUI/Tables/BaseTable2/TableProperties";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BaseGridFilterColumnManager from "./ColumnHeaderFilters/BaseGridFilterColumnManager";

const BaseGridBodyHeader: FC<any> = ({ uiid }) => {
  const { instanceSelector, setInstance } = useGridInstance(
    uiid,
    "BaseGridBodyHeader"
  );

  const changeOrder = (order: BaseTableOrder) =>
    order === "desc" ? "asc" : "desc";

  const columnOptions = useRecoilValue(instanceSelector("columnOptions"));
  const advancedOptions = useRecoilValue(instanceSelector("advancedOptions"));
  const columns = useRecoilValue(instanceSelector("columns"));
  const orderDirection = useRecoilValue(instanceSelector("orderDirection"));
  const orderByColumnIndex = useRecoilValue(
    instanceSelector("orderByColumnIndex")
  );
  const [columnHeaders, setColumnHeaders] = useState<BaseTableColumn[]>([]);

  useEffect(() => {
    if (columns?.length ?? 0 > 0) {
      const clonedColumns = [...(columns ?? [])];
      const sortedColumns = clonedColumns
        .filter((col) => col.isHidden === null || col.isHidden === false)
        .map((col) => ({ ...col, ["orderDirection"]: "desc" }))
        .sort((a, b) => dynamicSort(a, b, "_headerSortOrder"));
      setColumnHeaders(sortedColumns);
    }
  }, [columns]);

  const setSortOrder = (columnIndex: number) => {
    const isSameColumn = (orderByColumnIndex ?? columnIndex) === columnIndex;
    const order = isSameColumn ? changeOrder(orderDirection ?? "desc") : "desc";
    setInstance({
      orderDirection: order,
      orderByColumnIndex: columnIndex,
      columnTriggeredSort: true,
    });

    const updatedDirection = columnHeaders.map((ch) => {
      if (ch._columnIndex === columnIndex) {
        return { ...ch, orderDirection: order === "asc" ? "desc" : "asc" };
      } else {
        return { ...ch, orderDirection: "desc" };
      }
    });
    setColumnHeaders(updatedDirection);
  };

  return (
    <BaseGridStructureHead>
      <BaseGridStructureRow key={crypto.randomUUID()}>
        {columnHeaders.length > 0 &&
          columnHeaders.map((col) => {
            return (
              <BaseGridStructureHeader
                key={crypto.randomUUID()}
                width={getWidthOfCells(
                  columnOptions ?? [],
                  columnHeaders ?? [],
                  col.fieldName
                )}
              >
                <div className={styles.true_base_grid_header_data_column_name}>
                  {col.displayName ?? "NO_DISPLAY_NAME_FOUND"}
                </div>
                {col.showColumnFilterExtension === true ? (
                  <div style={{ width: "25px", height: "50%" }}>
                    <BaseGridFilterColumnManager uiid={uiid} column={col} />
                  </div>
                ) : !(advancedOptions?.disableSortOrder ?? false) &&
                  col.isSortable ? (
                  <div
                    className={
                      styles.true_base_grid_header_data_column_name_order_arrow
                    }
                    onClick={
                      () =>
                        col.isSortable ? setSortOrder(col._columnIndex) : null // TODO - turning this off for testing because it triggers a reset on the header everytime I click it.
                    }
                  >
                    {(col as BaseTableColumn)?.orderDirection === "asc" &&
                    orderByColumnIndex === col._columnIndex ? (
                      <ArrowUpwardIcon style={{ height: "18px" }} />
                    ) : (
                      <ArrowDownwardIcon style={{ height: "18px" }} />
                    )}
                  </div>
                ) : null}
              </BaseGridStructureHeader>
            );
          })}
      </BaseGridStructureRow>
    </BaseGridStructureHead>
  );
};

export default BaseGridBodyHeader;
