import { FC, useEffect, useState } from "react";
import { Col, FontBold, Input, Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { AgencyLocationDto } from "../../../../dtos/agency-location-dto";
import { AgencyContactDto } from "../../../../dtos/agency-contact-dto";
import {
  getAgencyContactAsOptions,
  getAgencyInformationAsOptions,
  getAgencyLocationAsOptions,
  onChangeAndDispatchAgency,
  onChangeCustomerId,
  onChangeAndDispatchLocation,
  onChangeProducer,
} from "./InsuredInformationUtils";
import { AgencyInformationDto } from "../../../../dtos/agency-information-dto";
import { PolicyProducerBlob } from "../../../../dtos/policy-producer-blob";

type insuredInfoAgencyProps = {
  agencies: AgencyInformationDto[];
  errorDetails?: { [key: string]: any };
};

const InsuredInformationAgency: FC<insuredInfoAgencyProps> = ({
  agencies,
  errorDetails,
}) => {
  const readOnly = false;
  const [insuredAgency, setInsuredAgency] = useState<PolicyProducerBlob>();
  const [locations, setLocations] = useState<AgencyLocationDto[]>();
  const [producers, setProducers] = useState<AgencyContactDto[]>();

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  const { responseGet: locationResponse, dispatchGet: locationDispatch } =
    useApiGet<AgencyLocationDto[]>(
      `api/AgencyLocation/GetAgencyLocationsByAgencyId?agencyId=${insuredAgency?.agency?.agencyID}`
    );
  const { responseGet: producerResponse, dispatchGet: producerDispatch } =
    useApiGet<AgencyContactDto[]>(
      `api/AgencyContact/GetAgencyContactsByAgencyIdAndAgencyLocationId?agencyId=${insuredAgency?.agency?.agencyID}&agencyLocationId=${insuredAgency?.location?.locationID}`
    );

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(atomValue?.producers?.[0])) {
      setLocations(atomValue?.configuration?.agencyLocationList);
      setProducers(atomValue?.configuration?.agencyContactList);
      setInsuredAgency({
        agency: atomValue?.producers?.[0].agency,
        location: atomValue?.producers?.[0].location,
        agent: atomValue?.producers?.[0].agent,
      } as PolicyProducerBlob);
    } else {
      setLocations([]);
      setProducers([]);
    }
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(locationResponse)) {
      const atomValue = getAtom();
      const agencyLocations = locationResponse.axiosResponse?.data ?? [];
      setLocations(agencyLocations);
      setAtom({
        ...atomValue,
        configuration: {
          ...atomValue?.configuration,
          agencyLocationList: agencyLocations,
        },
      } as PolicyBlob);
    }
  }, [locationResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(producerResponse)) {
      const atomValue = getAtom();
      const agencyProducers = producerResponse.axiosResponse?.data ?? [];
      setProducers(agencyProducers);
      setAtom({
        ...atomValue,
        configuration: {
          ...atomValue?.configuration,
          agencyContactList: agencyProducers,
        },
      } as PolicyBlob);
    }
  }, [producerResponse]);

  useEffect(() => {
    if (insuredAgency) {
      const atomValue = getAtom();
      const producerAtom = {
        agency: {
          ...insuredAgency?.agency,
          agencyCustomerID: insuredAgency?.agency?.agencyCustomerID,
        },
        location: insuredAgency?.location,
        agent: insuredAgency?.agent,
      } as PolicyProducerBlob;
      setAtom({
        ...atomValue,
        producers: [producerAtom],
      });
    }
  }, [insuredAgency]);

  useEffect(() => {
    if (insuredAgency?.agency?.agencyID) {
      locationDispatch();
    }
  }, [insuredAgency?.agency?.agencyID]);

  useEffect(() => {
    if (insuredAgency?.location?.locationID) {
      producerDispatch();
    }
  }, [insuredAgency?.location?.locationID]);

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <FontBold>AGENCY INFORMATION</FontBold>
      </Col>
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Select
              tabIndex={1}
              id="id-agency-name-input"
              name="name-agency-name-input"
              label="Agency Name"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insuredAgency?.agency?.agencyID}
              options={getAgencyInformationAsOptions(agencies)}
              onChange={(value) => {
                setProducers([]);
                onChangeAndDispatchAgency(value, agencies, setInsuredAgency);
              }}
              readOnly={readOnly}
              firstOptionAsDefault={false}
              optionsMaxHeight="250px"
              errorMessage={errorDetails?.agencyName}
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            {locations && (
              <Select
                tabIndex={2}
                id="id-agency-location-input"
                name="name-agency-location-input"
                label="Agency Location"
                labelFontType="BOLD_CAPTION"
                inputFontType="BODY"
                variant="filled"
                value={insuredAgency?.location?.locationID}
                options={getAgencyLocationAsOptions(locations ?? [])}
                onChange={(value) => {
                  onChangeAndDispatchLocation(
                    value,
                    locations,
                    insuredAgency,
                    setInsuredAgency
                  );
                }}
                readOnly={readOnly}
                firstOptionAsDefault={false}
                optionsMaxHeight="220px"
                errorMessage={
                  errorDetails?.["agencyLocation[0]"] ??
                  errorDetails?.agencyLocation
                }
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            {producers && (
              <Select
                tabIndex={3}
                id="id-producer-name-input"
                name="name-producer-name-input"
                label="Producer Name"
                labelFontType="BOLD_CAPTION"
                inputFontType="BODY"
                variant="filled"
                value={insuredAgency?.agent?.agentID}
                options={getAgencyContactAsOptions(producers ?? [])}
                onChange={(value) =>
                  onChangeProducer(
                    value,
                    producers,
                    insuredAgency,
                    setInsuredAgency
                  )
                }
                readOnly={readOnly}
                firstOptionAsDefault={false}
                optionsMaxHeight="180px"
                errorMessage={
                  errorDetails?.["producerName[0]"] ??
                  errorDetails?.producerName
                }
              />
            )}
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Input
              tabIndex={4}
              id="id-agency-customer-input"
              name="name-agency-customer-input"
              label="Agency Customer ID"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insuredAgency?.agency?.agencyCustomerID ?? ""}
              onChangeRawValue={(value) =>
                onChangeCustomerId(value, insuredAgency, setInsuredAgency)
              }
              maxLength={20}
              readOnly={readOnly}
              errorMessage={
                errorDetails?.["agencyCustomerId[0]"] ??
                errorDetails?.agencyCustomerId
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InsuredInformationAgency;
