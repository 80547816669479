import { FC, useEffect, useState } from "react";
import { BaseTable2, Col, FontBold, Row } from "../../../TrueUI";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import {
  columnsOfficers,
  tableNameOfficers,
} from "./InsuredInformationTableConfigurations";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyInsuredOfficerBlob } from "../../../../dtos/policy-insured-officer-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  getOfficersAsTableData,
  getOfficersByTableData,
} from "./InsuredInformationTableUtils";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { getDateObject } from "../../../../utilities/dateFunctions";

type insuredInfoOfficersProps = {
  officersOptions: BaseTableDisplayOptions[];
  errorDetails?: { [key: string]: Object };
  effectiveDate: Date | null;
};

const InsuredInformationOfficers: FC<insuredInfoOfficersProps> = ({
  officersOptions,
  effectiveDate,
  errorDetails,
}) => {
  const [insuredOfficers, setInsuredOfficers] =
    useState<PolicyInsuredOfficerBlob[]>();
  const [deletedOfficers, setDeletedOfficers] = useState<
    PolicyInsuredOfficerBlob[] | null
  >(null);

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  const getInitialTableData = (): string[][] => {
    const atomValue = getAtom();
    const officers = atomValue?.insured?.officers;
    if (!isEmptyValue(officers)) {
      return getOfficersAsTableData(officers) ?? [];
    }
    return [];
  };

  const getDeletedOfficers = () =>
    deletedOfficers?.filter((officer) => officer.officerID) ?? [];

  const updateOfficersByTableData = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    setInsuredOfficers(getOfficersByTableData(data, columns));
  };

  const deleteOfficerByHydratedRow = (hydratedRow?: any) => {
    setDeletedOfficers([
      ...(deletedOfficers ?? []),
      {
        baseTableRowKey: hydratedRow._row_key,
        officerID: parseInt(hydratedRow.OfficerId ?? "0"),
        inclExcl: hydratedRow.OfficerInclExcl,
        officerName: hydratedRow.OfficerName,
        officerTitle: hydratedRow.OfficerTitle,
        officerType: hydratedRow.OfficerType,
        officerStatus: hydratedRow.OfficerStatus,
        insuredOfficerStatus: StatusEnums.DELETED,
        classCode: hydratedRow.ClassCode,
        ownershipPercent: parseInt(hydratedRow.OwnershipPct ?? "0"),
        effectiveDate:
          hydratedRow.EffectiveDate !== ""
            ? getDateObject(hydratedRow.EffectiveDate)
            : null,
        expirationDate:
          hydratedRow.ExpirationDate !== ""
            ? getDateObject(hydratedRow.ExpirationDate)
            : null,
        insuredId: parseInt(hydratedRow.InsuredId ?? "0"),
        tenantId: parseInt(hydratedRow.TenantId ?? "0"),
      },
    ]);
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableNameOfficers,
    columnsAndData: {
      columns: columnsOfficers(officersOptions, effectiveDate),
      data: getInitialTableData(),
    },
    events: {
      onDataChange: updateOfficersByTableData,
      onDeleteRow: ({ hydratedRow }) => deleteOfficerByHydratedRow(hydratedRow),
    },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "delete",
      },
      tableErrors: errorDetails as any,
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
      showSaveButton: false,
    },
    columnOptions: [
      { fieldName: "OPTIONS", width: 3 },
      { fieldName: "OwnershipPct", maxNumericValue: 100, minNumericValue: 0 },
    ],
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    return () => {
      setDeletedOfficers(null);
    };
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    const updatedOfficers =
      getDeletedOfficers().length > 0
        ? insuredOfficers?.concat(deletedOfficers ?? [])
        : insuredOfficers;
    if (!isEmptyValue(updatedOfficers)) {
      setAtom({
        ...atomValue,
        insured: {
          ...atomValue?.insured,
          officers: updatedOfficers,
        },
      });
    }
  }, [insuredOfficers]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalGutter="10px"
    >
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold>INDIVIDUALS INCLUDED / EXCLUDED</FontBold>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <BaseTable2 name={tableNameOfficers} />
      </Col>
    </Row>
  );
};

export default InsuredInformationOfficers;
