import { FC, forwardRef, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Col, Row } from "..";
import { Box, Button, ButtonBaseProps } from "@mui/material";
import { rowWithNoMarginNorGutter } from "../Grids/Row";
import { colWithNoMarginNorGutter } from "../Grids/Col";
import style from "./breadcrumb.module.css";

type BreadcrumbNavProps = {
  activeBreadcrumbLink: number;
  setActive: (activeSection: number, isBreadcrumbNavigation?: boolean) => void;
  configItemList: string[];
  configChipList: string[];
  showChipButtons?: boolean;
  overrideMaxActiveSection?: number;
};

const getLinkName = (name: string) => {
  return name?.replaceAll(" ", "-").toLowerCase() ?? "";
};

const BreadcrumbNav: FC<BreadcrumbNavProps> = ({
  activeBreadcrumbLink: active,
  setActive,
  configItemList,
  configChipList,
  showChipButtons = false,
  overrideMaxActiveSection = active,
}) => {
  const [maxActiveSection, setMaxActiveSection] = useState<number>(
    overrideMaxActiveSection
  );

  const setActiveOnClickEvent = (selectedBreadcrumb: number) => {
    setActive(selectedBreadcrumb, true);
  };

  const renderBreadcrumbLink = forwardRef<ButtonBaseProps, any>(
    (props, ref) => (
      <Button
        {...props}
        ref={ref}
        sx={{ width: "max-content", textTransform: "none" }}
        name={`breadcrumb-link-${getLinkName(props.children)}`}
      >
        {props.children}
      </Button>
    )
  );

  const breadcrumbLinks = [
    configItemList.map((item, index) => (
      <Link
        key={"link-" + index + 1}
        component={renderBreadcrumbLink}
        underline={active === index + 1 ? "always" : "none"}
        className={`nowrap item-link ${
          active === index + 1
            ? style.true_breadcrumb_link_active
            : style.true_breadcrumb_link
        } ${
          index + 1 < maxActiveSection || active === index + 1
            ? style.true_breadcrumb_link_active_or_completed
            : style.true_breadcrumb_link
        }`}
        disabled={maxActiveSection < index + 1}
        onClick={() => {
          setActiveOnClickEvent(index + 1);
        }}
      >
        {item}
      </Link>
    )),
  ];

  const chipClickEvent = (index) => {
    setActiveOnClickEvent(index + configItemList.length);
  };

  const chipButtons = [
    configChipList.map((chipLabel, index) => (
      <Chip
        key={"chip-" + index + 1}
        clickable
        label={chipLabel}
        size="medium"
        color={
          active === index + 1 + configItemList.length ? "default" : "primary"
        }
        variant={
          active === index + 1 + configItemList.length ? "filled" : "outlined"
        }
        className={"true_breadcrumb_chip_items"}
        onClick={() => chipClickEvent(index + 1)}
      />
    )),
  ];

  useEffect(() => {
    if (active > maxActiveSection) {
      setMaxActiveSection(active);
    }
  }, [active]);

  useEffect(() => {
    if (overrideMaxActiveSection > maxActiveSection) {
      setMaxActiveSection(overrideMaxActiveSection);
    }
  }, [maxActiveSection]);

  return (
    <Box className={style.true_breadcrumb_container}>
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} allowWrap>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 13 }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbLinks}
          </Breadcrumbs>
        </Col>
        {showChipButtons && (
          <Col
            horizontalAlign="flex-end"
            breakpoints={{ xs: 10, sm: 10, md: 24, lg: 24, xl: 11 }}
            horizontalMargin="0px"
            horizontalGutter="25px"
          >
            {chipButtons}
          </Col>
        )}
      </Row>
    </Box>
  );
};
export default BreadcrumbNav;
