import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { SelectOptions } from "../../../../../dtos/select-options";
import {
  fromStatusesToOptionsToToggle,
  statusToHideAssignedToAndClaimNo,
  statusToHideAssignedToClaimNoAndReason,
  statusToHideReason,
} from "./IncidentFunctions";
import {
  contextActionsForIncident,
  contextActionsRenderingConditionsForIncidents,
} from "./IncidentContextMenu";
import {
  FromEnumToArray,
  formattingKey,
} from "../../../../../utilities/enumFunctions";
import {
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  INCIDENT_BODY_SECTION,
} from "../../../../../utilities/queryStringsHash";
import { getQueryStringsURL } from "../../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../../dtos/sub-side-nav-item-destinations-enum";
import { IncidentBodySectionEnums } from "../../../../../dtos/incident-body-section-enums";
import { SubSideNavParentsIndexEnum } from "../../../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../../../dtos/sub-side-nav-children-index-enum";
import {
  getDateDiff,
  getUniversalTime,
} from "../../../../../utilities/dateFunctions";
import { BaseGridProperties } from "../../../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import BaseGridSearchFilter from "../../../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import { Dispatch, SetStateAction } from "react";
import { usePermissions } from "../../../../../hooks";
import { PermissionsEnums } from "../../../../../dtos/permissions-enums";

const isExpiredIncident = (row) =>
  row.IncidentStatus === "Submitted" &&
  row.DateReported !== "" &&
  getDateDiff(new Date(row.DateReported), getUniversalTime(), "hours", false) >
    24;

const onChangeFilterValue = (
  newValue: string,
  setIncidentTableFilterSelected: Dispatch<SetStateAction<number>>
) => {
  const arrayAllValues = FromEnumToArray(IncidentStatusEnums);
  const newStatusItem = arrayAllValues.find((x) => x.key === newValue);
  setIncidentTableFilterSelected(newStatusItem?.value ?? null);
};

const permissionToAddIncident = [
  PermissionsEnums.CLAIMS_ADJUSTER,
  PermissionsEnums.CLAIMS_MANAGER,
  PermissionsEnums.CLAIMS_ADMIN,
];

export const IncidentTableConfiguration = (
  name: string,
  setIncidentContextMenuValues: any,
  incidentTableFilterSelected: number,
  setIncidentTableFilterSelected: Dispatch<SetStateAction<number>>,
  incidentStatuses?: Partial<SelectOptions>[],
  isFromOutSideInsured?: boolean,
  navigate?: any,
  assigneeOptions?: Partial<SelectOptions>[]
) => {
  const hasPermissionToAddIncident = usePermissions(
    permissionToAddIncident
  ).hasPermission;

  const config: BaseGridProperties = {
    name: name,
    columnOptions: [
      { fieldName: "IncidentIdLink", width: 6 },
      { fieldName: "ProgramCode", width: 7 },
      { fieldName: "LineType", width: 5 },
      { fieldName: "State", width: 5 },
      { fieldName: "Claimant", width: 11 },
      { fieldName: "SSN", width: 10 },
      { fieldName: "DateOfLoss", width: 7 },
      { fieldName: "DateReported", width: 7 },
      { fieldName: "IncidentStatus", width: 10 },
      { fieldName: "AssignedTo", width: 10 },
      { fieldName: "ClaimNumber", width: 10 },
      { fieldName: "Reason", width: 10 },
      { fieldName: "CreatedBy", width: 10 },
      { fieldName: "OPTIONS", width: 4 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "incidents-table-status-filter",
          optionsOverride: incidentStatuses,
          labelText: "Status:",
          columnNames: ["IncidentStatus"],
          defaultValue:
            incidentStatuses?.find(
              (option) =>
                option.stringValue ===
                formattingKey(
                  incidentTableFilterSelected === null
                    ? "ALL"
                    : IncidentStatusEnums[incidentTableFilterSelected]
                )
            )?.stringValue ?? "all",
          onChange(value) {
            onChangeFilterValue(value, setIncidentTableFilterSelected);
          },
          toggleColumnsByOptions: [
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideAssignedToClaimNoAndReason,
                incidentStatuses
              ),
              columnsToToggle: ["ClaimNumber", "AssignedTo", "Reason"],
            },
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideAssignedToAndClaimNo,
                incidentStatuses
              ),
              columnsToToggle: ["ClaimNumber", "AssignedTo"],
            },
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideReason,
                incidentStatuses
              ),
              columnsToToggle: ["Reason"],
            },
          ],
        }),
      assigneeOptions
        ? (options) =>
            BaseGridDropDownFilter({
              options,
              id: "incidents-table-assigned-to-filter",
              optionsOverride: assigneeOptions,
              labelText: "Assigned To:",
              columnNames: ["AssigneeUserId"],
              defaultValue: "all",
            })
        : () => {},
      isFromOutSideInsured
        ? (options) =>
            BaseGridSearchFilter({
              options,
              id: "incident_table_configuration_filter_search",
              columnNames: ["IncidentId", "Claimant"],
              columnDisplayNames: ["incident no.", "claimant"],
            })
        : () => {},
    ],
    advancedOptions: {
      optionsContextActions: {
        contextMenuOptionRenderingCondition:
          contextActionsRenderingConditionsForIncidents,
        contextMenu: contextActionsForIncident(
          setIncidentContextMenuValues,
          isFromOutSideInsured ?? false,
          navigate
        ),
      },
      optionsColumnConfiguration: {
        isHidden: !hasPermissionToAddIncident,
        optionType: "context",
      },
      indicationColumnConfiguration: {
        iconColor: "red",
        isHidden: false,
        conditionalIcon: (row) => {
          if (isExpiredIncident(row)) {
            return "error";
          }
          return "custom";
        },
      },
    },
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    permissions: {
      addButton: permissionToAddIncident,
    },
    events: {
      addEventOverrideCallback() {
        const newURL = getQueryStringsURL([
          {
            nameOfHash: INCIDENT_BODY_SECTION,
            valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
          },
          {
            nameOfHash: INSURED_PARENT_ITEM,
            valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
          },
          {
            nameOfHash: INSURED_CHILD_ITEM,
            valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
          },
          {
            nameOfHash: INSURED_BODY_SECTION,
            valueOfHash:
              SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
          },
        ]);
        navigate(newURL);
      },
    },
  };

  return config;
};
