import { FC } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import AuditInformation from "../AuditInformation/AuditInformation";
import ExposureAndPremium from "../EndorsementExposurePremium/ExposureAndPremium";
import AuditWorksheet from "../AuditWorksheet/AuditWorksheet";
import AuditResultsAndDocs from "../AuditResultsAndDocs/AuditResultsAndDocs";
import UnderwriterNotes from "../AuditUnderwriterNotes/UnderwriterNotes";

const AuditBodySelector: FC<EndorsementGeneralProps> = (props) => {
  switch (props.activeSection) {
    case AuditSectionEnum.AUDIT_INFORMATION:
    default:
      return <AuditInformation {...props} />;
    case AuditSectionEnum.EXPOSURE_AND_PREMIUM:
      return <ExposureAndPremium {...props} isAudit={true} />;
    case AuditSectionEnum.AUDIT_RESULTS_AND_DOCS:
      return <AuditResultsAndDocs {...props} />;
    case AuditSectionEnum.AUDIT_WORKSHEET:
      return <AuditWorksheet {...props} />;
    case AuditSectionEnum.AUDIT_UNDERWRITING_NOTES:
      return <UnderwriterNotes {...props} />;
  }
};

export default AuditBodySelector;
