import { FC } from "react";
import { BaseGridProperties } from "../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../BaseGrid/BaseGrid";

const gridName = "mafer_sandbox_grid";

const MaferSandBox: FC = () => {
  const tableConfiguration: BaseGridProperties = {
    name: gridName,
    getURL: "api/SubscriptionActivity/GetInAppNotifications",
    toolbarOptions: { showAddButton: false, showEditButton: false },
  };

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ width: "100%", margin: "50px" }}>
      <BaseGrid name={gridName} />
    </div>
  );
};

export default MaferSandBox;
