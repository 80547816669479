import { FC } from "react";
import style from "../Styles/basegridcells.module.css";

type DefaultSelectCellProperties = {
  value: string;
  displayOptions: any[];
};
const DefaultSelectCell: FC<DefaultSelectCellProperties> = ({
  value,
  displayOptions,
}) => {
  const foundSelect =
    (displayOptions.length > 0 &&
      (displayOptions ?? []).find((option) => option.value === value)) ??
    null;

  if (foundSelect !== null) {
    const selectedValue = foundSelect?.text ?? "";
    return (
      <div className={style.default_select_render_only_cell}>
        {selectedValue}
      </div>
    );
  } else {
    return <div className={style.default_select_render_only_cell}></div>;
  }
};

export default DefaultSelectCell;
