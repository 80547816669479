import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { usePermissions } from "../../../hooks";
import {
  createDateFromParts,
  FormattingDate,
  isValidDate,
} from "../../../utilities/dateFunctions";
import { getMonths } from "../../../utilities/staticDataFunctions";
import Select from "../Select/Select";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import { SelectOptions } from "../../../dtos/select-options";
import "./inputDateSelector.module.css";

type inputDateSelectorProps = {
  id?: string;
  name?: string;
  inputKey?: string;
  labelDay?: string;
  labelMonth?: string;
  labelYear?: string;
  className?: string;
  readOnly?: boolean;
  focus?: boolean;
  variant?: "filled" | "standard";
  helperText?: string;
  value: Date | string;
  yearValue?: string;
  showMode:
    | "all"
    | "hideMonth"
    | "onlyMonth"
    | "hideDay"
    | "onlyDay"
    | "hideYear"
    | "onlyYear";
  onChange?: (e) => void;
  onChangeRawDate?: (e?: any) => void;
  onChangeRawString?: (e?: any) => void;
  permissions?: number[];
  displayInline?: boolean;
  isLabelInline?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelHeadingSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  labelFontType?: KeysAsType<FontsType>;
  disableUnderline?: boolean;
  tabIndex?: number;
};

const InputDateSelector: FC<inputDateSelectorProps> = ({
  id = "",
  name = "",
  labelDay = "",
  labelMonth = "",
  labelYear = "",
  readOnly = false,
  focus,
  showMode = "all",
  value,
  onChangeRawDate,
  onChangeRawString,
  isLabelInline = true,
  labelPosition = null,
  helperText,
  variant = "filled",
  className = "",
  permissions,
  tabIndex = 0,
  labelFontType = "BOLD_CAPTION",
}) => {
  const [dateValue, setDateValue] = useState<Date | null>(
    typeof value === "string"
      ? new Date(value)
      : new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          value.getHours(),
          value.getMinutes(),
          value.getSeconds(),
          value.getMilliseconds()
        )
  );
  const localOptions = useRecoilValue(globalOptions);
  const { hasPermission } = usePermissions(permissions ?? []);
  const [year, setYear] = useState(FormattingDate(dateValue, "YYYY"));
  const [day, setDay] = useState(FormattingDate(dateValue, "D"));
  const [month, setMonth] = useState(FormattingDate(dateValue, "M"));

  const monthsList = getMonths().map(
    (month) =>
      ({
        displayName: month.name,
        stringValue: month.value,
      } as SelectOptions)
  );

  const changeDateValue = (newDateValue: Date | null) => {
    setDateValue(newDateValue);
    if (newDateValue !== null) {
      onChangeRawDate?.(newDateValue);
      onChangeRawString?.(newDateValue?.toISOString());
    }
  };

  const validateDayOnChange = (e) => {
    if (e.target.value === "") {
      setDay(e.target.value);
      changeDateValue(null);
    } else {
      if (isValidDate(year, month, e.target.value)) {
        setDay(e.target.value);
        changeDateValue(createDateFromParts(year, month, e.target.value));
      }
    }
  };

  const validateYearOnChange = (e) => {
    setYear(e.target.value);
    if (e.target.value.length === 4) {
      if (isValidDate(year, month, day)) {
        changeDateValue(createDateFromParts(e.target.value, month, day));
      } else {
        setDay("");
        changeDateValue(null);
      }
    }
  };

  const validateMonthOnChange = (value) => {
    setMonth(value);
    if (isValidDate(year, value, day)) {
      changeDateValue(createDateFromParts(year, value, day));
    } else {
      setDay("");
      changeDateValue(null);
    }
  };

  const handleDayChangeByInput = (e: any) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 2);
    if (parseInt(e.target.value) < 1) {
      e.target.value = "1";
    } else if (parseInt(e.target.value) > 31) {
      e.target.value = e.target.value.slice(0, 1);
    }
  };
  const handleYearChangeByInput = (e: any) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 4);
  };

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      sx={{ width: "-webkit-fill-available" }}
    >
      {showMode !== "hideMonth" &&
        showMode !== "onlyYear" &&
        showMode !== "onlyDay" && (
          <Grid item xs sx={{ paddingLeft: "0px !important" }}>
            {/* month part */}
            <Select
              tabIndex={tabIndex}
              id={`${id}-month`}
              name={`${name}-month`}
              variant={variant ?? "standard"}
              label={labelMonth}
              labelFontType={labelFontType}
              options={monthsList}
              value={month}
              readOnly={!hasPermission}
              onChange={validateMonthOnChange}
            />
          </Grid>
        )}
      {showMode !== "hideDay" &&
        showMode !== "onlyYear" &&
        showMode !== "onlyMonth" && (
          <Grid //day part
            item
            xs={3}
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              tabIndex={tabIndex + 1}
              id={`${id}-day`}
              name={`${name}-day`}
              label={labelDay}
              autoFocus={focus ?? false}
              type="number"
              value={day}
              variant={variant ?? "standard"}
              helperText={helperText ?? ""}
              disabled={hasPermission ? readOnly : true}
              className={`true_input_date_selector ${className} ${localOptions?.themeRefresh}`}
              fullWidth
              onChange={validateDayOnChange}
              onInput={handleDayChangeByInput}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                height: 31,
                maxLength: 2,
                max: 31,
                min: 1,
              }}
            />
          </Grid>
        )}
      {showMode !== "hideYear" &&
        showMode !== "onlyDay" &&
        showMode !== "onlyMonth" && (
          <Grid //year part
            item
            xs={4}
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              tabIndex={tabIndex + 2}
              id={`${id}-year`}
              name={`${name}-year`}
              label={labelYear}
              autoFocus={focus ?? false}
              type="number"
              value={year}
              variant={variant ?? "standard"}
              size={"small"}
              helperText={helperText ?? ""}
              disabled={hasPermission ? readOnly : true}
              className={`true_input       
                ${
                  labelPosition !== null
                    ? "true_input_no_inner_label"
                    : isLabelInline
                    ? "true_input_has_inner_label"
                    : "true_input_no_inner_label"
                }
                ${className}
                ${localOptions?.themeRefresh}`}
              fullWidth
              onChange={validateYearOnChange}
              onBlur={(e) => {
                if (parseInt(year) < 1900) {
                  e.target.value = "1900";
                  validateYearOnChange(e);
                }
              }}
              onInput={handleYearChangeByInput}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 4,
                max: 9999,
                min: 1900,
              }}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default InputDateSelector;
