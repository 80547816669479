import { SelectOptions } from "../../dtos/select-options";
import { InsuredFileDto } from "../../dtos/insured-file-dto";
import { Dispatch, SetStateAction } from "react";

export type EditFileConfiguration = {
  fileId: number;
  category: string;
  fileDisplayName: string;
  downloadedFileName: string;
  rowKey: string;
  claimNumber?: string;
  claimId?: number | null;
  insuredId?: number | null;
  insuredName?: string;
  status?: number | null;
  comments?: string;
  userShortName?: string;
  addedDate?: string;
  type?: string;
  dateReviewed?: string;
  assignedTo?: number | null;
  insuredCategoryOptions?: SelectOptions[];
  claimCategoryOptions?: SelectOptions[];
  userOptions?: SelectOptions[];
  isEditing: boolean;
  searchNextIdList: number[];
  fileExtension?: string;
  serviceFrom?: Date;
  serviceTo?: Date;
};

export type EditFileModalProps = {
  configuration: EditFileConfiguration;
  fileData: Partial<InsuredFileDto>;
  setFileData: Dispatch<SetStateAction<Partial<InsuredFileDto>>>;
  errorDetails: any;
  closeModal: (e: boolean) => void;
  onDeleteFile: () => void;
  setMoveNextFile: (moveNextFile: boolean | null) => void;
  isLastFile: boolean;
  isFileDataEdited: boolean;
  setIsFileDataEdited: (isFileDataEdited: boolean) => void;
  dataWithoutEdit: string | null;
  setIsValidClaim?: any;
  setIsValidInsured?: any;
};

export const defaultEditConfiguration: EditFileConfiguration = {
  fileId: 0,
  category: "",
  fileDisplayName: "",
  downloadedFileName: "",
  rowKey: "",
  insuredCategoryOptions: [],
  claimCategoryOptions: [],
  userOptions: [],
  isEditing: false,
  searchNextIdList: [],
};

export type ModalState = {
  moveNextFile: boolean | null;
  fileIdNextToEdit: number | null;
  isFileDataEdited: boolean;
  isValidClaim: boolean;
  isValidInsured: boolean;
};
