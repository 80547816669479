import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  changeStatusModal,
  openTasksContainer,
  openTasksTableTitleContainer,
} from "./ModalChangeClaimStyles";
import { Box } from "@mui/material";
import { BaseTable2, TitleBold } from "../../../../TrueUI";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { ClaimStatusHistoryTabProps } from "./ModalChangeProps";

const ClaimStatusHistoryTab = ({ claimId }: ClaimStatusHistoryTabProps) => {
  const name = "ClaimStatusHistoryTable";
  const getURL = `api/Claims/GetClaimStatusHistory?claimId=${claimId}`;
  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      tableStyle: {
        height: "215px",
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div style={openTasksContainer}>
      <Box sx={openTasksTableTitleContainer}>
        <TitleBold primaryColor name="statusHistoryLabel">
          Status History
        </TitleBold>
      </Box>
      <Box sx={changeStatusModal()}>
        <BaseTable2 name={name} />
      </Box>
    </div>
  );
};

export default ClaimStatusHistoryTab;
