import { FC, useEffect, useState } from "react";
import { Collapse } from "../../TrueUI";
import "./achBillingBatchDetails.module.css";
import AchBatchNachaFileCollapseHeader from "./AchBatchNachaFileCollapseHeader";
import { AchBatchNachaFileCollapseProps } from "./AchTypes";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import AchBatchNachaFileCollapseContent from "./AchBatchNachaFileCollapseContent";
import {
  CopyToClipboardProps,
  extractFileContent,
  onCopyToClipboard,
  onSaveFileAs,
} from "./AchBatchNachaFileCollapseUtils";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { PaymentBatchStatusEnum } from "../../../dtos/payment-batch-status-enum";

const AchBatchNachaFileCollapse: FC<AchBatchNachaFileCollapseProps> = ({
  selectedBatchId,
  achBatchDetailInformation,
  selectedProgramCode,
}) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(
    conditionHasValue(achBatchDetailInformation) &&
      achBatchDetailInformation?.batchStatusId ===
        PaymentBatchStatusEnum.PENDING
      ? true
      : false
  );
  const [nachaFileContent, setNachaFileContent] = useState<string>("");
  const [copyToClipboard, setCopyToClipboard] = useState<CopyToClipboardProps>({
    modalText: "NACHA file content was copied!",
    isModalOpen: false,
  });
  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/BillingPaymentBatch/GetNachaFileData?batchId=${selectedBatchId}`
  );

  useEffect(() => {
    if (
      conditionHasValue(achBatchDetailInformation) &&
      achBatchDetailInformation.batchStatusId === PaymentBatchStatusEnum.PENDING
    ) {
      setIsCollapse(true);
    }
  }, [achBatchDetailInformation]);

  useEffect(() => {
    if (isCollapse) {
      dispatchGet();
    } else {
      setNachaFileContent("");
    }
  }, [isCollapse]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const contentFile = extractFileContent(
        responseGet.responseData.contentFile
      );
      setNachaFileContent(contentFile);
    }
  }, [responseGet]);

  return (
    <div className={"billing_batch_details_nacha_collapse_container"}>
      <Collapse
        id="billing_batch_details_nacha_collapse"
        collapsedHeight={"47px"}
        expanded={isCollapse}
        title={
          <AchBatchNachaFileCollapseHeader
            isCollapse={isCollapse}
            onSaveFileAs={() =>
              onSaveFileAs?.(
                nachaFileContent,
                achBatchDetailInformation,
                selectedProgramCode
              )
            }
            onCopyToClipboard={() => {
              onCopyToClipboard?.(nachaFileContent);
              setCopyToClipboard({ ...copyToClipboard, isModalOpen: true });
            }}
          />
        }
        onChange={() => setIsCollapse(!isCollapse)}
        content={
          <AchBatchNachaFileCollapseContent
            nachaFileContent={nachaFileContent}
            isCollapse={isCollapse}
          />
        }
      />
      <DialogConfirmation
        id="copy_to_clipboard_confirmation"
        open={copyToClipboard.isModalOpen}
        dialogDescriptionText={copyToClipboard.modalText}
        optionYesOverrideLabel={"OK"}
        onOptionYesEvent={(close) => {
          setCopyToClipboard({ ...copyToClipboard, isModalOpen: close });
        }}
      />
    </div>
  );
};

export default AchBatchNachaFileCollapse;
