import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  InputDate,
  Modal,
  Row,
  Select,
} from "../../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../../hooks";
import {
  ModalAddBatchProps,
  modalProps,
  defaultAddBatchObject,
} from "./ModalAddBatchProps";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { inputConfiguration } from "../../../../../utilities/inputPropsConfigurationFunctions";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { PaymentBatchConfiguration } from "../../../../../dtos/payment-batch-configuration";
import { SelectOptions } from "../../../../../dtos/select-options";
import { getCurrentDateByTimeZone } from "../../../../../utilities/dateTimeZoneFunctions";
import { useRecoilState, useRecoilValue } from "recoil";
import { globalOptions } from "../../../../../GlobalAtoms";
import { batchInformation } from "../PaymentBatchAtoms";
import { useNavigate } from "react-router";
import { getQueryStringsToPaymentBatchSections } from "../../../../../utilities/queryStringsHashFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { PaymentBatchSectionEnums } from "../../../../../dtos/payment-batch-section-enums";

const ModalAddBatch: FC<ModalAddBatchProps> = ({
  setOpenModal,
  openModal = true,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const navigate = useNavigate();
  const currentDate = getCurrentDateByTimeZone(
    localOptions?.timeZoneID ?? null
  );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const [batch, setBatch] = useRecoilState(batchInformation);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [paymentBatchConfiguration, setPaymentBatchConfiguration] =
    useState<PaymentBatchConfiguration>();

  const {
    responseGet: responseGetPaymentConfig,
    dispatchGet: dispatchGetPaymentConfig,
  } = useApiGet<PaymentBatchConfiguration>(
    `api/PaymentBatch/GetPaymentBatchConfiguration`
  );

  const {
    validatorErrorResponse,
    responsePost: responseValidationPayment,
    dispatchPost: dispatchValidationPayment,
  } = useApiPost<boolean>(`api/PaymentBatch/ValidatePaymentBatch`, batch);

  const reserveTypeOptions: Partial<SelectOptions>[] = [
    {
      displayName: "All",
      intValue: -1,
    },
    ...(paymentBatchConfiguration?.reserveTypes ?? []),
  ];

  const onCancel = () => {
    setDialogConfiguration({
      open: true,
      dialogDescriptionText: "Are you sure you want to cancel?",
      onOptionNoEvent: () => setDialogConfiguration(null),
      onOptionYesEvent: () => onCancelYes(),
    });
  };

  const processButtonClicked = () => {
    dispatchValidationPayment();
  };

  const onCancelYes = () => {
    setDialogConfiguration(null);
    setOpenModal(false);
    setBatch(defaultAddBatchObject);
    setErrorDetails(null);
  };

  const getDefaultBankAccountId = (
    config: PaymentBatchConfiguration | null
  ) => {
    const defaultBankAccount =
      config?.bankAccountOptions?.find((x) => x.isDefault)?.intValue ??
      config?.bankAccountOptions?.[0]?.intValue;

    return defaultBankAccount ?? -1;
  };

  useEffect(() => {
    setBatch({
      ...defaultAddBatchObject,
      paymentDate: currentDate,
      fromDate: currentDate,
      toDate: currentDate,
    });
  }, []);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    setErrorDetails(null);
  }, [batch]);

  useEffect(() => {
    if (openModal) {
      dispatchGetPaymentConfig();
    }
  }, [openModal]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetPaymentConfig)) {
      setPaymentBatchConfiguration(
        responseGetPaymentConfig?.axiosResponse?.data
      );
      setBatch({
        ...batch,
        referenceNo: responseGetPaymentConfig?.responseData?.nextSystemNumber,
        bankAccountId: getDefaultBankAccountId(
          responseGetPaymentConfig?.axiosResponse?.data ?? null
        ),
      });
    }
  }, [responseGetPaymentConfig]);

  useEffect(() => {
    if (
      responseValidationPayment.requestInstanceSuccessful &&
      conditionHasValue(responseValidationPayment?.axiosResponse?.data) &&
      !responseValidationPayment?.isLoading
    ) {
      const hasPayments: any = responseValidationPayment.axiosResponse?.data;
      if (hasPayments === false) {
        setDialogConfiguration({
          open: true,
          dialogDescriptionText:
            "No valid payments found within this date range",
          onOptionYesEvent: () => setDialogConfiguration(null),
          optionYesOverrideLabel: "OK",
        });
      } else {
        navigate(
          `/claims${getQueryStringsToPaymentBatchSections(
            PaymentBatchSectionEnums?.PAYMENT_BATCH_PREVIEW
          )}`
        );
      }
    }
  }, [responseValidationPayment]);

  return (
    <Modal
      {...{
        ...modalProps,
        open: openModal,
        showCloseButton: false,
        size: "sm",
      }}
    >
      <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start" allowWrap>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }} displayFlex={false}>
          <InputDate
            tabIndex={1}
            id="id-batch-date"
            name="batchDate"
            label="Batch Date"
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            value={batch?.paymentDate}
            onChangeRawValue={(value) =>
              setBatch({ ...batch, paymentDate: value })
            }
            errorMessage={errorDetails?.paymentDate}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Select
            tabIndex={2}
            id="id-reserve-type"
            name={`reserveType`}
            inputWidth={"100%"}
            optionsMaxHeight="120px"
            label={"Reserve Type"}
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            options={reserveTypeOptions}
            readOnly={false}
            value={batch?.reserveType}
            onChange={(value) => setBatch({ ...batch, reserveType: value })}
            errorMessage={errorDetails?.reserveType}
          />
        </Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <InputDate
            tabIndex={3}
            id="id-from-date"
            name="fromDate"
            label="From Date"
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            value={batch?.fromDate}
            onChangeRawValue={(value) =>
              setBatch({ ...batch, fromDate: value })
            }
            errorMessage={errorDetails?.fromDate}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <InputDate
            tabIndex={4}
            id="id-to-date"
            name="toDate"
            label="To Date "
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            value={batch?.toDate}
            onChangeRawValue={(value) => setBatch({ ...batch, toDate: value })}
            errorMessage={errorDetails?.toDate}
          />
        </Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Select
            tabIndex={5}
            id="id-payment-method"
            name={`paymentMethod`}
            inputWidth={"100%"}
            optionsMaxHeight="120px"
            label={"Payment Method"}
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            options={[...(paymentBatchConfiguration?.paymentMethods ?? [])]}
            readOnly={false}
            value={batch?.paymentMethod}
            onChange={(value) => setBatch({ ...batch, paymentMethod: value })}
            firstOptionAsDefault={false}
            errorMessage={errorDetails?.paymentMethod}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}></Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Input
            tabIndex={6}
            {...inputConfiguration(
              "id-starting-reference-number",
              "startingReferenceNumber",
              "Starting Reference Number",
              20
            )}
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            value={batch?.referenceNo}
            onChangeRawValue={(value) =>
              setBatch({ ...batch, referenceNo: value ?? null })
            }
            errorMessage={errorDetails?.referenceNo}
            type={"number"}
            thousandSeparator={false}
            prefix={""}
            decimalScale={0}
          />
        </Col>
        <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
          <Select
            tabIndex={7}
            id="id-bank-account-id"
            name={`bankAccountId`}
            inputWidth={"100%"}
            optionsMaxHeight="120px"
            label={"Select Bank Account"}
            labelFontType={"BOLD_CAPTION"}
            variant={"filled"}
            options={paymentBatchConfiguration?.bankAccountOptions ?? []}
            readOnly={false}
            value={batch?.bankAccountId}
            onChange={(value) => setBatch({ ...batch, bankAccountId: value })}
            errorMessage={errorDetails?.bankAccountId}
          />
        </Col>

        <Col
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          displayFlex={false}
          verticalGutter={"0px"}
        >
          <p>
            To process this batch of payments click the Save button below. A PDF
            of the check batch will appear in your browser downloads shortly
            after Saving. The Batch will be no longer be editable.
          </p>
          <p>
            To go back to make modifications click Cancel on this modal to
            return the previous screen.
          </p>
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalAlign="flex-start"
        allowWrap
        horizontalAlign={"flex-end"}
      >
        <Col breakpoints={{ md: 3, lg: 2, xl: 2 }} displayFlex={false}>
          <Button
            onClick={() => processButtonClicked()}
            tabIndex={8}
            name="batchProcessButton"
          >
            PROCESS
          </Button>
        </Col>
        <Col breakpoints={{ md: 3, lg: 2, xl: 2 }} displayFlex={false}>
          <Button
            variantStyle={"outlined"}
            onClick={() => onCancel()}
            tabIndex={9}
            name="batchCancelButton"
          >
            CANCEL
          </Button>
        </Col>
      </Row>
      <Divider height={20} />
      <DialogConfirmation
        id="dialog-for-modal-add-batch"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </Modal>
  );
};

export default ModalAddBatch;
