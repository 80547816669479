import { FC, useState } from "react";
import { Box } from "@mui/material";
import { Col, Font, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { documentsGroupRowStyles } from "./DocumentStyles";
import { PolicyQuoteDocumentIndividualProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { usePermissions } from "../../../../hooks";
import Link from "../../../TrueUI/Typography/Link";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const DocumentIndividual: FC<PolicyQuoteDocumentIndividualProps> = ({
  tabKey,
  policyDocuments,
  selectedDocuments,
  onSelectedCheckboxDocument,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);

  const onCheckBoxChange = (checked: boolean, docId: number) => {
    onSelectedCheckboxDocument(checked, docId, "document");
  };

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (
      policyMergeFields !== undefined &&
      policyMergeFields !== null &&
      !isEmptyValue(document.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.POLICY_FORM,
        jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
          getAtom()?.policyQuoteInformation
        ),
        templateNames: [document.templateName ?? ""],
        customFileName: `${policyMergeFields.insured?.insuredName ?? ""} ${
          document.docName ?? ""
        }`,
        printOrDownload: "download",
      });
    }
    if (isEmptyValue(document.templateName)) {
      setDialogOpen(true);
    }
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
        <Font>INDIVIDUAL POLICY DOCUMENTS</Font>
      </Col>
      {policyDocuments.map((document, idx) => (
        <Box sx={documentsGroupRowStyles()} key={`documents-individual-${idx}`}>
          <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
            <Switch
              inputWidth="auto"
              tabIndex={idx}
              control="checkbox"
              isChecked={selectedDocuments.includes(document.programDocId)}
              onChangeIsChecked={(checked) =>
                onCheckBoxChange(checked, document.programDocId)
              }
              primaryLabelColor
              labelFontType={"BODY"}
              readOnly={!hasPermission}
            />
            <Link
              linkFontType="BODY"
              displayValue={`${document.docName}`}
              onClick={() => printIndividualDocument(document)}
            />
          </Col>
        </Box>
      ))}
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Row>
  );
};

export default DocumentIndividual;
