import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { IconButton, Collapse, Box, TableRow } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { hydrateDataSingle } from "../tableFunctions";
import { getStylingRow } from "../TableStyles";
import { TableData } from "../BaseTableStructure";

type RowInternalProperties_TEMP = {
  uiid: string;
  rowKey: string;
  row: string[];
  renderedCells: JSX.Element;
};

const BaseTableCollapseRow: FC<RowInternalProperties_TEMP> = ({
  uiid,
  rowKey,
  row,
  renderedCells,
}) => {
  const tableInstance = useRecoilValue(tableInstanceAtomFamily2(uiid));
  const [open, setOpen] = useState(
    tableInstance?.toggleMultiTableRowCollapse ?? false
  );
  const childrenRows = [];

  useEffect(() => {
    setOpen(tableInstance?.toggleMultiTableRowCollapse ?? false);
  }, [tableInstance?.toggleMultiTableRowCollapse]);

  const hydratedData = tableInstance?.advancedOptions?.collapsedTableProperties
    ?.structureDataByParent
    ? childrenRows.map((child) =>
        hydrateDataSingle(tableInstance?.columns, child)
      )
    : hydrateDataSingle(tableInstance?.columns ?? [], row);

  return (
    <Fragment>
      <TableRow
        key={`row_title_${rowKey}`}
        style={getStylingRow(
          tableInstance?.columns ?? [],
          row,
          tableInstance?.advancedOptions?.tableStyle?.rowStyle?.progressBar
        )}
        // onClick={(event) => onClick?.(event, parentRow)}
      >
        <TableData>
          {tableInstance?.advancedOptions?.collapsedTableProperties
            ?.DetailRowComponent &&
            childrenRows.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ArrowDropDown /> : <ArrowRight />}
              </IconButton>
            )}
        </TableData>
        {/* {getColumnValues(parentRow, rowIndex)} */}
        {renderedCells}
      </TableRow>
      {tableInstance?.advancedOptions?.collapsedTableProperties
        ?.DetailRowComponent && (
        <TableRow>
          <TableData colSpan={tableInstance?.columns.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                {tableInstance?.advancedOptions?.collapsedTableProperties?.DetailRowComponent(
                  hydratedData
                )}
              </Box>
            </Collapse>
          </TableData>
        </TableRow>
      )}
    </Fragment>
  );
};

export default BaseTableCollapseRow;
