import { Box } from "@mui/system";
import { FC, cloneElement, isValidElement, memo } from "react";
import { BaseGridStructureWrapper } from "./BaseGridStructure";
import styles from "./Styles/basegrid.module.css";

type BaseGridWrapperProperties = {
  name?: string;
  uiid?: string;
  children?: any | any[];
};

const BaseGridChildConstructor: FC<BaseGridWrapperProperties> = (props) => {
  const registeredChildren =
    props?.children?.length > 0
      ? props?.children?.map((reactElement, i) => {
          if (isValidElement(reactElement)) {
            return cloneElement(props.children[i], {
              name: props.name,
              uiid: props.uiid,
              key: crypto.randomUUID(),
            });
          }
          return reactElement;
        })
      : props?.children;

  return (
    <Box
      className={styles.true_base_grid_container}
      id={props.name ?? "NO_NAME_FOUND"}
      data-uiid={props?.uiid ?? "NO_UIID_FOUND"}
    >
      <BaseGridStructureWrapper>{registeredChildren}</BaseGridStructureWrapper>
    </Box>
  );
};

export default memo(BaseGridChildConstructor);
