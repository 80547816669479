import { FC, useEffect, useState } from "react";
import { PolicyInsuredNameBlob } from "../../../../dtos/policy-insured-name-blob";
import { SelectOptions } from "../../../../dtos/select-options";
import { formatNumber } from "../../../../utilities/stringFunctions";
import {
  Col,
  Input,
  InputDate,
  InputTax,
  Modal,
  Row,
  Select,
  Switch,
} from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { useApiPost } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { getTaxIdType } from "./InsuredInformationUtils";
import {
  areObjectsEqual,
  removeFalsyValues,
} from "../../../../utilities/objectFunctions";

export type ModalNameInformationProps = {
  name?: PolicyInsuredNameBlob;
  isNewName?: boolean;
  isOpen?: boolean;
};

type ModalNamesProps = {
  businessTypes: SelectOptions[];
  relationshipTypes: SelectOptions[];
  modalInformation: ModalNameInformationProps;
  linkNumber: number;
  onCancelEvent?: () => void;
  onSaveEvent?: (
    insuredNameData?: PolicyInsuredNameBlob,
    isNewName?: boolean
  ) => void;
  effectiveDate: Date | null;
};

const textFieldVariant = "standard";

const ModalAcord130Name: FC<ModalNamesProps> = ({
  businessTypes,
  relationshipTypes,
  modalInformation,
  onSaveEvent,
  onCancelEvent,
  linkNumber,
  effectiveDate,
}) => {
  const [insuredNameData, setInsuredNameData] =
    useState<PolicyInsuredNameBlob>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [isActiveLocationDialogOpen, setIsActiveLocationDialogOpen] =
    useState<boolean>(false);

  const { isOpen, isNewName, name } = modalInformation;

  const { responsePost, validatorErrorResponse, dispatchPost } =
    useApiPost<any>("api/QuotePolicy/SaveInsuredName", insuredNameData);

  const setInsuredNameMultipleData = (fieldNames: string[], values: any[]) => {
    const newValues = fieldNames.map((targetProperty, index) => ({
      [targetProperty]: values[index],
    }));
    const targetJoined = Object.assign({}, ...newValues);
    setInsuredNameData({
      ...insuredNameData,
      ...targetJoined,
    });
  };

  const onSaveModal = () => {
    dispatchPost();
  };

  const onCancelModal = () => {
    setErrorDetails(null);
    onCancelEvent?.();
    setIsActiveLocationDialogOpen(false);
  };

  const checkPendingChangesToSave = () => {
    const nameData = isNewName
      ? { effectiveDate: effectiveDate, taxIdType: "SSN" }
      : modalInformation.name;
    if (
      !areObjectsEqual(
        removeFalsyValues(nameData),
        removeFalsyValues(insuredNameData)
      )
    ) {
      setIsActiveLocationDialogOpen(true);
    } else {
      onCancelModal();
    }
  };

  useEffect(() => {
    if (isOpen && !(isNewName ?? true)) {
      setInsuredNameData(name ?? {});
    } else {
      setInsuredNameData({ effectiveDate: effectiveDate });
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponse)
    ) {
      onSaveEvent?.(insuredNameData, isNewName);
      setErrorDetails(null);
    }
    if (conditionHasValue(validatorErrorResponse)) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <>
      <Modal
        id={"new_insured_name_modal"}
        title={"Named Insured"}
        open={isOpen}
        cancelEvent={checkPendingChangesToSave}
        saveEvent={onSaveModal}
        showCloseButton
        showCancelTextButton
      >
        <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 8, lg: 8, xl: 8 }}
          >
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Input
                  id="id-insured-name-input"
                  name="insuredNameInput"
                  label="Name"
                  maxLength={300}
                  variant={textFieldVariant}
                  value={insuredNameData?.legalName}
                  errorMessage={errorDetails?.legalName}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      legalName: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  id="id-insured-name-dba-input"
                  name="insuredDbaInput"
                  label="DBA"
                  maxLength={300}
                  variant={textFieldVariant}
                  value={insuredNameData?.nameDBA}
                  errorMessage={errorDetails?.nameDBA}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      nameDBA: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputTax
                  id="id-insured-name-tax-id-input"
                  name="insuredTaxIdInput"
                  label="Tax ID"
                  initialType={getTaxIdType(insuredNameData?.taxIdType)}
                  variant={textFieldVariant}
                  value={insuredNameData?.taxID ?? ""}
                  errorMessage={errorDetails?.taxID}
                  onChangeRaw={({ value, type }) =>
                    setInsuredNameMultipleData(
                      ["taxID", "taxIdType"],
                      [value, type]
                    )
                  }
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Switch
                  id="id-insured-name-billed-independently-input"
                  name="insuredBilledIndependentlyInput"
                  label={"Billed Independently"}
                  labelFontType="BOLD_CAPTION"
                  isChecked={insuredNameData?.billedIndependently ?? false}
                  labelPlacement={"end"}
                  control={"checkbox"}
                  onChangeIsChecked={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      billedIndependently: value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 4, lg: 4, xl: 4 }}
          >
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Input
                  id="id-insured-name-link-number-input"
                  name="insuredLinkNumberInput"
                  label="Name Link Number"
                  variant={textFieldVariant}
                  readOnly={true}
                  value={formatNumber(
                    isNewName
                      ? linkNumber ?? 1
                      : insuredNameData?.sequenceNumber ?? 1,
                    3
                  )}
                />
              </Col>
              <Col>
                <Select
                  id="id-insured-name-relationship-type-select"
                  name="insuredRelationshipTypeSelect"
                  label={"Relationship Type"}
                  labelFontType="BOLD_CAPTION"
                  options={relationshipTypes ?? []}
                  variant={textFieldVariant}
                  value={insuredNameData?.nameType}
                  errorMessage={errorDetails?.nameType}
                  firstOptionAsDefault={false}
                  onChange={(value) =>
                    setInsuredNameData({
                      ...insuredNameData,
                      nameType: value,
                    })
                  }
                />
              </Col>
              <Col>
                <Select
                  id="id-insured-name-business-type-select"
                  name="insuredBusinessTypeSelect"
                  label={"Business Type"}
                  labelFontType="BOLD_CAPTION"
                  options={businessTypes ?? []}
                  variant={textFieldVariant}
                  optionsMaxHeight="145px"
                  errorMessage={errorDetails?.businessType}
                  value={insuredNameData?.businessType}
                  firstOptionAsDefault={false}
                  onChange={(value) =>
                    setInsuredNameData({
                      ...insuredNameData,
                      businessType: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  id="id-insured-name-effective-date-input"
                  name="insuredEffectiveDateInput"
                  label="Effective Date"
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.effectiveDate}
                  value={insuredNameData?.effectiveDate}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      effectiveDate: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputDate
                  id="id-insured-name-expiration-date-input"
                  name="insuredExpirationDateInput"
                  label="Expiration Date"
                  errorMessage={errorDetails?.expirationDate}
                  variant={textFieldVariant}
                  value={insuredNameData?.expirationDate}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      expirationDate: value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <DialogConfirmation
        name="activeLocationDialog"
        id="modalActiveLocationDialogConfirmation"
        open={isActiveLocationDialogOpen}
        dialogDescriptionText="Are you sure you want to close before saving the changes?"
        onCloseEvent={(close) => setIsActiveLocationDialogOpen(close)}
        onOptionYesEvent={onCancelModal}
        optionYesOverrideLabel={"OK"}
        optionNoOverrideLabel={"Cancel"}
        onOptionNoEvent={(close) => setIsActiveLocationDialogOpen(close)}
      />
    </>
  );
};

export default ModalAcord130Name;
