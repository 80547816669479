import "./PDFTemplateWrapper.css";
import { FormattingDate } from "../../../utilities/dateFunctions";
const PDFTemplateWrapper = (props) => {
  return (
    <>
      <header className="true_pdf_header_template">
        <span className="true_pdf_header_date">
          {FormattingDate(new Date())}
        </span>
      </header>

      <footer className="true_pdf_footer_template">
        <p></p>
        <p className="true_pdf_footer_signature">
          Page {props.pageNum} of {props.totalPages}
        </p>
      </footer>
    </>
  );
};

export default PDFTemplateWrapper;
