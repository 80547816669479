import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import { TitleBold } from "../../../TrueUI";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { UserCustomerProgramSelectedAtom } from "../AdminSecurityAtoms";
import AddProgramModal from "./AddProgramModal";

type UserAccessRightsProps = {
  userId?: number | null;
  tenantId?: number | null;
};
const tableName = "user-access-rights-table";

export type UserAccessRightsInteractProps = {
  isAddProgramModalOpen?: boolean;
  isTableRefresh?: boolean;
};

const UserAccessRights: FC<UserAccessRightsProps> = ({ userId, tenantId }) => {
  const setUserCustomerProgramSelected = useSetRecoilState(
    UserCustomerProgramSelectedAtom
  );

  const [componentActions, setComponentActions] =
    useState<UserAccessRightsInteractProps>();

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() =>
            setUserCustomerProgramSelected(actionOptions?.row?.ProgramUserId)
          }
          sx={{ ml: "5px" }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `api/AdminTools/Security/UserListing/GetUserAccessRightsTable?tenantId=${tenantId}&userId=${userId}`,
    useManuallyReloadParameters: true,
    advancedOptions: {
      disableSortOrder: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showEditButton: false,
      showExcelButton: false,
      showPDFButton: false,
      showExportButton: false,
      addButtonText: "ADD",
      addButtonIsDisabled: userId === 0 ? true : false,
    },
    columnOptions: [
      { fieldName: "Program", width: 10 },
      { fieldName: "ProgramName", width: 90 },
      { fieldName: "OPTIONS", width: 5 },
    ],
    events: {
      addEventOverrideCallback: () =>
        setComponentActions({
          ...componentActions,
          isAddProgramModalOpen: true,
        }),
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    if (conditionHasValue(userId)) {
      manuallyReloadParameters();
    }
  }, [userId]);

  useEffect(() => {
    if (componentActions?.isTableRefresh) {
      manuallyReloadParameters();
      setComponentActions({ ...componentActions, isTableRefresh: false });
    }
  }, [componentActions?.isTableRefresh]);

  return conditionHasValue(userId) ? (
    <div style={{ width: "40%", paddingBottom: "45px" }}>
      <div
        style={{ paddingLeft: "5px", marginBottom: "10px", marginTop: "10px" }}
      >
        <TitleBold display="block" textAlign="start">
          PROGRAM ACCESS
        </TitleBold>
      </div>
      <div style={{ height: "100%" }}>
        <BaseGrid name={tableName} />
        <AddProgramModal
          tenantId={tenantId ?? -1}
          userId={userId}
          componentActions={componentActions}
          setComponentActions={setComponentActions}
        />
      </div>
    </div>
  ) : null;
};
export default UserAccessRights;
