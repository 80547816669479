import moment from "moment";
import { TabConfigProps } from "../components/Insured/Insured";
import { conditionHasValue } from "./conditionalSupportFunctions";
import { areObjectsEqual } from "./objectFunctions";

export const OPEN_TABS_STORAGE = "openTabs";
export const INSURED_ATOM_FAMILY_STORAGE = "insuredAtomFamily";
export const TRUE_ID_STORAGE = "true_id";
export const HOME_CARD_STORAGE = "homeCardStorage";
export const LAST_UPDATED_CACHE = "lastTrueRefreshTimestamp";

export type ClaimTabsStorage = {
  insuredId: number;
  claimNumber: string;
};

export const setStorageOpenTabs = (newTab: TabConfigProps) => {
  const claimTabs = getStorageValueArray(OPEN_TABS_STORAGE);
  const clearClaimTabs = claimTabs?.filter(
    (tab) => tab?.tabKey !== newTab?.tabKey
  );

  const newClaimsTabs = [...[newTab], ...clearClaimTabs];
  localStorage.setItem(OPEN_TABS_STORAGE, JSON.stringify(newClaimsTabs));
};

export const setStorageInsuredAtomFamily = (newTab) => {
  const atoms = getStorageValueArray(INSURED_ATOM_FAMILY_STORAGE);
  const clearAtoms = atoms?.filter((tab) => tab?.key !== newTab?.key);

  const newAtoms = clearAtoms?.concat([newTab]);
  localStorage.setItem(INSURED_ATOM_FAMILY_STORAGE, JSON.stringify(newAtoms));
};

export const removeClaimNumberFromTab = (elementTab: TabConfigProps) => {
  if (conditionHasValue(elementTab)) {
    const claimTabs = getStorageValueArray(OPEN_TABS_STORAGE);
    const existentTab = getByInsuredIdAndClaimNumber(
      elementTab.insuredId,
      "" // Empty because we will remove the selected claim
    );

    if (conditionHasValue(existentTab)) {
      const clearClaimTabs = claimTabs?.filter(
        (tab) => tab?.tabKey !== elementTab?.tabKey
      );

      localStorage.setItem(OPEN_TABS_STORAGE, JSON.stringify(clearClaimTabs));
    } else {
      const selectedTab = claimTabs?.find(
        (tab) => tab?.tabKey === elementTab?.tabKey
      );

      const newValueObject = {
        ...selectedTab,
        claimNumber: "",
        title: undefined,
      };

      setStorageOpenTabs(newValueObject);
    }
  }
};

export const getStorageValueArray = (nameOfStorage: string) => {
  const localTabs = localStorage.getItem(nameOfStorage);
  return localTabs ? JSON.parse(localTabs) : [];
};

export const getStorageValue = (nameOfStorage: string) => {
  const localItem = localStorage.getItem(nameOfStorage);
  return localItem ? JSON.parse(localItem) : null;
};

export const setStorageValue = (nameOfStorage: string, value: any) => {
  localStorage.setItem(nameOfStorage, JSON.stringify(value));
};

export const areTabsEqual = (
  initialTab: TabConfigProps,
  secondTab: TabConfigProps
) => {
  const initialObject = {
    insuredId: initialTab?.insuredId,
    claimNumber: initialTab?.claimNumber,
  };
  const secondObject = {
    insuredId: secondTab?.insuredId,
    claimNumber: secondTab?.claimNumber,
  };
  return areObjectsEqual(initialObject, secondObject);
};

export const getByInsuredIdAndClaimNumber = (
  insuredId: number,
  claimNumber: string
): TabConfigProps => {
  const tabs = getStorageValueArray(OPEN_TABS_STORAGE);
  const tabToEvaluate = {
    tabKey: "TEMP_TO_EVALUATE",
    insuredId: insuredId,
    claimNumber: claimNumber,
  };
  return tabs?.find((tab) => areTabsEqual(tab, tabToEvaluate));
};

export const getOpenTabByTabKey = (tabKey: string): TabConfigProps => {
  const openTabs = getStorageValueArray(OPEN_TABS_STORAGE);
  return openTabs?.find((tab) => tab?.tabKey === tabKey);
};

export const removeOpenTabByTabKey = (tabKey: string) => {
  const openTabs = getStorageValueArray(OPEN_TABS_STORAGE);
  const newTabs = openTabs?.filter((tab) => tab?.tabKey !== tabKey);
  localStorage.setItem(OPEN_TABS_STORAGE, JSON.stringify(newTabs));
};

export const removeSystemLocalStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const hardRefresh = () => {
  window.location.reload();
};

export const refreshCachePeriodically = () => {
  const lastRefreshString = localStorage.getItem(LAST_UPDATED_CACHE);
  const lastRefreshMoment = lastRefreshString
    ? moment(lastRefreshString)
    : null;

  if (!lastRefreshMoment || moment().diff(lastRefreshMoment, "hours") > 6) {
    hardRefresh();
    localStorage.setItem(LAST_UPDATED_CACHE, moment().toISOString());
  }
};
