import { FC, useEffect, useState } from "react";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  INSURED_ATOM_KEY,
  POLICY_ID,
  POLICY_QUOTE_SECTION,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { BreadcrumbNav } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBodySelector from "./PolicyQuoteBodySelector";
import { PolicyQuoteProps } from "./PolicyQuoteTypes";
import {
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { Box } from "@mui/material";
import { policyQuoteBodySelectorStyles } from "./PolicyQuoteStyles";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import { useApiGet, useApiPost } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  getPolicyQuoteSectionAndIds,
  getSectionByEnum,
} from "./PolicyQuoteUtils";
import { useRecoilValue } from "recoil";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { policyStatusesThanCanEdit } from "../PolicyHistoryTableConfiguration";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { validateExposuresAndPremium } from "./PolicyQuoteValidationUtils";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";

const breadcrumbItemList = [
  "Policy Information",
  "Exposure and Premium",
  "Bind Instructions",
  "Policy Docs",
];

const breadcrumbChipList = [
  "ENDORSEMENT FORMS",
  "UNDERWRITING NOTES",
  "LOSS HISTORY",
];

const PolicyQuote: FC<PolicyQuoteProps> = ({ insuredId, tabKey }) => {
  const [policyQuoteActiveSectionUI, setPolicyQuoteActiveSectionUI] =
    useState<number>(QuotePolicySectionEnum.INFORMATION);
  const [readOnlyPolicyQuote, setReadOnlyPolicyQuote] =
    useState<boolean>(false);
  const [quoteStatus, setQuoteStatus] =
    useState<PolicyEnumValueDescriptionAndBooleanBlob>();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps>();

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const listenerReadOnlyComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteReadOnlyComponent")
  );
  const [policyAndQuoteId, setPolicyAndQuoteId] = useState<{
    policyId: number;
    quoteId: number;
  }>();
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${getSectionByEnum(
      policyQuoteActiveSectionUI
    )}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const { responseGet, dispatchGet } = useApiGet<PolicyBlob>(
    `api/QuotePolicy/GetConfigurationForInformationForms?insuredId=${insuredId}&policyId=${
      policyAndQuoteId?.policyId
    }&quoteId=${policyAndQuoteId?.quoteId}&previousQuoteId=${0}&formType=${
      PolicyFormTypeEnum.QUOTE_FORM
    }`
  );
  const [readyToRender, setReadyToRender] = useState<boolean | null>(null);
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();

  const overrideActiveSection = () => {
    const atomValue = getAtom();
    const activeSection = atomValue?.policyQuoteInformation?.activeSection;
    return readOnlyPolicyQuote
      ? QuotePolicySectionEnum.LOSS_HISTORY
      : activeSection === QuotePolicySectionEnum.BIND_INSTRUCTIONS
      ? QuotePolicySectionEnum.ENDORSEMENT_FORMS
      : QuotePolicySectionEnum.INFORMATION;
  };
  const defaultDialogConfiguration = {
    onCloseEvent: (close) =>
      setDialogConfiguration({ ...dialogConfiguration, open: close }),
    onOptionYesEvent: (close) =>
      setDialogConfiguration({ ...dialogConfiguration, open: close }),
  };

  const setActiveSection = (
    activeSection: number,
    isBreadcrumbNavigation?: boolean
  ) => {
    const atomValue = getAtom();
    const newAtomValue = updatePolicyQuoteInformation(
      atomValue,
      "activeSection",
      activeSection
    );

    setAtom(newAtomValue);

    const validExposureAndPremium =
      policyQuoteActiveSectionUI === QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM
        ? validateExposuresAndPremium(newAtomValue, defaultDialogConfiguration)
        : null;

    if (
      isBreadcrumbNavigation === true &&
      !readOnlyPolicyQuote &&
      policyQuoteActiveSectionUI !== QuotePolicySectionEnum.ENDORSEMENT_FORMS &&
      policyQuoteActiveSectionUI !==
        QuotePolicySectionEnum.UNDERWRITING_NOTES &&
      policyQuoteActiveSectionUI !== QuotePolicySectionEnum.LOSS_HISTORY &&
      validExposureAndPremium === null
    ) {
      dispatchPost();
    } else {
      if (validExposureAndPremium !== null) {
        setDialogConfiguration(validExposureAndPremium);
      } else {
        setPolicyQuoteActiveSectionUI(activeSection);
      }
    }
  };

  const setInitialValuesByURL = () => {
    const atomValue = getAtom();
    const { quoteSection, policyId, quoteId } =
      getPolicyQuoteSectionAndIds(atomValue);
    const newAtomValue = updatePolicyQuoteInformationMultiTarget(
      atomValue,
      ["policyId", "quoteId", "activeSection"],
      [policyId, quoteId, quoteSection]
    );

    setPolicyQuoteActiveSectionUI(quoteSection);
    setPolicyAndQuoteId({
      policyId: policyId,
      quoteId: quoteId,
    });
    setAtom(newAtomValue);
    addQueryStrings([
      { nameOfHash: POLICY_QUOTE_SECTION, valueOfHash: quoteSection },
      { nameOfHash: POLICY_ID, valueOfHash: policyId },
      { nameOfHash: QUOTE_ID, valueOfHash: quoteId },
    ]);
  };

  useEffect(() => {
    setInitialValuesByURL();
  }, []);

  useEffect(() => {
    if (!isEmptyValue(policyAndQuoteId)) dispatchGet();
  }, [policyAndQuoteId]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const atomValue = getAtom();
      const newAtomVale = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomVale);
      setPolicyQuoteActiveSectionUI(
        atomValue?.policyQuoteInformation?.activeSection ??
          QuotePolicySectionEnum.INFORMATION
      );
    }
  }, [responsePost]);

  useEffect(() => {
    if (responseGet?.responseData && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyJSON = responseGet.axiosResponse?.data;
      const policyStatusId = policyJSON?.policyStatus?.value ?? -1;

      const isReadOnly =
        policyJSON?.quote?.quoteStatus?.value === PolicyQuoteStatusEnum.BOUND ||
        policyStatusesThanCanEdit.includes(policyStatusId) === false;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, isReadOnly]
      );
      setAtom(newAtomValue);
      setReadyToRender(true);
      setReadOnlyPolicyQuote(isReadOnly);
      setQuoteStatus(policyJSON?.quote?.quoteStatus ?? {});
    }
  }, [responseGet]);

  useEffect(() => {
    const atomValue = getAtom();
    addQueryStrings([
      {
        nameOfHash: POLICY_QUOTE_SECTION,
        valueOfHash:
          atomValue?.policyQuoteInformation?.activeSection ??
          QuotePolicySectionEnum.INFORMATION,
      },
    ]);
  }, [policyQuoteActiveSectionUI]);

  useEffect(() => {
    if (listenerReadOnlyComponent !== null) {
      const atomValue = getAtom();
      setReadOnlyPolicyQuote(
        atomValue?.policyQuoteInformation?.readOnly ?? false
      );
      setQuoteStatus(
        atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteStatus ?? {}
      );
      clearPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      if (atomValue?.policyQuoteInformation?.readOnly === false)
        setReadyToRender(false);
      setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
    }
  }, [listenerReadOnlyComponent]);

  useEffect(() => {
    if (readyToRender === false) setReadyToRender(true);
  }, [readyToRender]);

  return (
    <div
      id="policy-quote-container"
      style={{
        overflow: "hidden",
      }}
    >
      {readyToRender && (
        <>
          <BreadcrumbNav
            activeBreadcrumbLink={policyQuoteActiveSectionUI}
            showChipButtons={
              policyQuoteActiveSectionUI >
              QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM
            }
            setActive={setActiveSection}
            configItemList={breadcrumbItemList}
            configChipList={breadcrumbChipList}
            overrideMaxActiveSection={overrideActiveSection()}
          />
          <Box
            id="policy-quote-body-selector-container"
            sx={policyQuoteBodySelectorStyles()}
          >
            <PolicyQuoteBodySelector
              tabKey={tabKey}
              insuredId={insuredId}
              quoteStatus={quoteStatus}
              activeSection={policyQuoteActiveSectionUI}
              setActiveSection={setActiveSection}
              readOnly={readOnlyPolicyQuote}
            />
          </Box>
          <DialogConfirmation
            name="policy-quote-dialog"
            id="policy-quote-dialog-confirmation"
            {...dialogConfiguration}
          />
        </>
      )}
    </div>
  );
};

export default PolicyQuote;
