import { ColumnOptionsProperties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableGridForm from "../../../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import { IconButton } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import TableRow from "../../../../../TrueUI/Tables/BaseTableStructure/TableRow";
import TableData from "../../../../../TrueUI/Tables/BaseTableStructure/TableData";
import { FontBold, Input } from "../../../../../TrueUI";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import style from "./InsuredInvoiceDetailsStyles.module.css";

export const columnOptionsProps: ColumnOptionsProperties<any>[] = [
  {
    fieldName: "AccountNumber",
    width: 13,
  },
  {
    fieldName: "Description",
    width: 42,
  },
  {
    fieldName: "Quantity",
    width: 8,
    align: "right",
  },
  {
    fieldName: "Each",
    width: 13,
    align: "right",
  },
  {
    fieldName: "Extended",
    width: 13,
    align: "right",
  },
];
const CustomOptionsForChildBaseTable = (
  actionOptions: any,
  rowGroupData: any,
  setInvoiceSubIdAndDetailId: (
    invoiceSubId: number,
    invoiceDetailId: number,
    maxLineNumber: number
  ) => void
) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {!actionOptions?.row?.IsPrimary && (
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            setInvoiceSubIdAndDetailId(
              parseInt(rowGroupData?.InvoiceSubId ?? "0"),
              parseInt(actionOptions?.row?.InvoiceDetailId ?? "0"),
              parseInt(actionOptions?.row?.MaxLineNumber ?? "0")
            );
          }}
        >
          <ModeEditOutlineIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};
export const MultiTablePatternComponent = (
  e,
  setInvoiceSubIdAndDetailId: (
    invoiceSubId: number,
    invoiceDetailId: number,
    maxLineNumber: number
  ) => void
) => {
  return (
    <BaseTableGridForm
      obj={e}
      urlPattern={"api/Invoice/GetInsuredInvoiceDetailChildRows?invoiceId=null"}
      columnOptions={columnOptionsProps}
      parameters={[
        {
          paramaterKey: "invoiceId",
          parameterPropertyValue: "InvoiceId",
        },
      ]}
      childEditComponent={(actionOptions) =>
        CustomOptionsForChildBaseTable(
          actionOptions,
          e,
          setInvoiceSubIdAndDetailId
        )
      }
    />
  );
};

export const getCellValue = (column, totalValue) => {
  switch (column) {
    case "Description":
      return <FontBold>{"Invoice Total"}</FontBold>;
    case "Extended":
      return (
        <Input
          type={"fixedCurrency"}
          id={"total-invoices"}
          name={"total-invoices"}
          value={totalValue}
          inputFontType={"BOLD_BODY"}
          align="right"
          prefix=""
          readOnly
        />
      );
    default:
      return "";
  }
};

export const getColumnWidth = (columns, column) => {
  const selected = columnOptionsProps?.find((c) => c?.fieldName === column);
  if (conditionHasValue(selected)) {
    return `${selected?.width}%`;
  }
  return `${100 / (columns?.length ?? 1)}%`;
};

export const CustomFooterComponent = (rows, invoiceId) => {
  const totalValue = rows
    ?.map((row) => row?.Metadata?.Extended ?? 0)
    .reduce((x, y) => {
      return Number(x) + Number(y);
    }, 0);
  const rowBase = rows?.[0];
  return invoiceId !== 0 ? (
    <TableRow className={style.insured_invoice_modal_footer_row}>
      {rowBase?.Columns.map((column, key) => (
        <TableData
          key={key}
          width={getColumnWidth(rowBase?.Columns, column)}
          className={`${
            column === "Extended" ? style.totals_totals_column : ""
          } ${column === "Description" ? style.footer_description_column : ""}`}
        >
          {getCellValue(column, totalValue)}
        </TableData>
      ))}
      <TableData width={"76px"}></TableData>
    </TableRow>
  ) : (
    <TableRow className={style.insured_invoice_modal_footer_row}>
      <TableData
        key={"no_invoice_total"}
        width={"62%"}
        className={style.footer_description_column}
      >
        <FontBold>Invoice Total</FontBold>
      </TableData>

      <TableData
        key={"no_invoice_total_value"}
        width={"5%"}
        className={style.totals_totals_column}
      >
        <Input
          type={"fixedCurrency"}
          id={"total-invoices"}
          name={"total-invoices"}
          value={0}
          inputFontType={"BOLD_BODY"}
          align="right"
          prefix=""
          readOnly
        />
      </TableData>
      <TableData width={"76px"}></TableData>
    </TableRow>
  );
};
