import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../TrueUI";
import CustomFieldForm from "./CustomFieldForm";
import { useApiPost } from "../../../../../hooks";
import { SelectOptions } from "../../../../../dtos/select-options";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { CustomFieldDefinitionDto } from "../../../../../dtos/custom-field-definition-dto";

type CustomFieldModalProps = {
  customFieldData?: CustomFieldDefinitionDto;
  setCustomFieldData: (customFieldData?: CustomFieldDefinitionDto) => void;
  programs: SelectOptions[];
  fieldTypeFilter: number;
  refreshTable: () => void;
  showInHeaderList: number[];
  programsWithShowInHeader?: { [key: number]: number[] };
};

const CustomFieldModal: FC<CustomFieldModalProps> = ({
  programs,
  refreshTable,
  fieldTypeFilter,
  customFieldData,
  setCustomFieldData,
  showInHeaderList,
  programsWithShowInHeader,
}) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const {
    responsePost: saveResponse,
    dispatchPost: saveEvent,
    validatorErrorResponse: saveErrors,
  } = useApiPost<CustomFieldDefinitionDto>(
    "api/AdminTools/CustomField/SaveCustomFieldDefinition",
    {
      ...customFieldData,
      fieldType: customFieldData?.fieldType ?? fieldTypeFilter,
    }
  );

  const onCloseEvent = () => setCustomFieldData?.();

  useEffect(() => {
    if (isAPITotallyComplete(saveResponse)) {
      onCloseEvent();
      refreshTable();
    }
  }, [saveResponse]);

  useEffect(() => {
    if (saveErrors) {
      setErrorDetails(saveErrors?.errorDetails);
    }
  }, [saveErrors]);

  return (
    <Modal
      open={true}
      title="Custom Field"
      id={"customFieldModalId"}
      showCloseButton
      showCancelTextButton
      cancelButtonWithConfirmation
      saveEvent={saveEvent}
      closeEvent={onCloseEvent}
      cancelEvent={onCloseEvent}
    >
      <CustomFieldForm
        customField={customFieldData}
        setCustomField={setCustomFieldData}
        programs={programs}
        errorDetails={errorDetails}
        fieldTypeFilter={fieldTypeFilter}
        showInHeaderList={showInHeaderList}
        programsWithShowInHeader={programsWithShowInHeader}
      />
    </Modal>
  );
};

export default CustomFieldModal;
