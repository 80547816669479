import { forwardRef } from "react";
import BaseGridStructure from "./BaseGridStructure";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureWrapperProperties = {
  children?: any;
  fullWidth?: boolean;
};

type BaseGridStructureWrapperRefType = HTMLDivElement;

const BaseGridStructureWrapper = forwardRef<
  BaseGridStructureWrapperRefType,
  BaseGridStructureWrapperProperties
>(({ children }, ref) => {
  return (
    <div ref={ref} className={styles.true_base_grid_wrapper}>
      <BaseGridStructure>{children}</BaseGridStructure>
    </div>
  );
});

export default BaseGridStructureWrapper;
