import { FC, useEffect, useState } from "react";
import { Font } from "../../../../../TrueUI";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPreviousRunningTotal,
  getProRateCalculation,
  getStatesUpdatedByRowResult,
  isCurrentStateTheGoverningState,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getDateObject,
  getDaysBetweenDates,
} from "../../../../../../utilities/dateFunctions";
import {
  customRound,
  getNumberAsStringWithComas,
} from "../../../../../../utilities/stringFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
} from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import { useRecoilValue } from "recoil";
import {
  EXPENSE_CONSTANT_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";

const getRunningTotalCalculatedAmountAndRate = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  isGoverningState: boolean,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const actualStateEffectiveDate = getDateObject(effectiveDate);
  const actualStateEffectiveDatePlusOneYear = new Date(
    actualStateEffectiveDate.getFullYear() + 1,
    actualStateEffectiveDate.getMonth(),
    actualStateEffectiveDate.getDate()
  );
  const policyPeriodTotalDays = getDaysBetweenDates(
    actualStateEffectiveDate,
    actualStateEffectiveDatePlusOneYear
  );
  const numberOfDaysInPolicy = getDaysBetweenDates(
    getDateObject(effectiveDate),
    getDateObject(expirationDate)
  );
  const rate = customRound(rating?.rate?.toString() ?? "0", 0);
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const newRunningTotal = rating.proRate
    ? customRound(
        (
          previousRunningTotal +
          getProRateCalculation(
            rating.rate ?? 1,
            policyPeriodTotalDays,
            numberOfDaysInPolicy
          )
        ).toString(),
        rating.rounding ?? 0
      )
    : customRound(
        (previousRunningTotal + rate).toString(),
        rating.rounding ?? 0
      );

  const calculatedAmount = isGoverningState
    ? customRound(
        (Number(newRunningTotal) - previousRunningTotal).toString(),
        0
      )
    : 0;
  const rowResult: PremiumTableRowResultProps = {
    calculatedAmount: calculatedAmount,
    runningTotal: isGoverningState ? newRunningTotal : previousRunningTotal,
    rate: rate,
  };

  return rowResult;
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  isGoverningState: boolean,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const calculationResults = getRunningTotalCalculatedAmountAndRate(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    rating,
    isGoverningState,
    atomValue
  );

  const statesUpdatedByCalculations = getStatesUpdatedByRowResult(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    atomValue
  );

  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const ExpenseConstantRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );

  const listenerExpenseConstantRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${EXPENSE_CONSTANT_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  const runCalculations = () => {
    const atomValue = getAtom();
    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );

    const { isGoverningState } = isCurrentStateTheGoverningState(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      currentStates ?? []
    );

    const updatedValues = getStatesUpdatedByCalculations(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      props.rateIndex,
      props.rating,
      isGoverningState,
      atomValue
    );

    setAtom(updatedValues.newAtomValue);
    setRowResult(updatedValues.rowResult);
    props.calculationsFinished?.();
  };

  useEffect(() => {
    if (listenerExpenseConstantRowComponent !== null && !props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerExpenseConstantRowComponent]);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_5}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div className={style.premium_row_rate_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
          {getNumberAsStringWithComas(rowResult.rate)}
        </Font>
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default ExpenseConstantRow;
