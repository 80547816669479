import { FC, useEffect, useState } from "react";
import { PortalSettingsDto } from "../../../../../dtos/portal-settings-dto";
import { useApiGet, useApiPost } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { Col, Font, Row, SaveButton } from "../../../../TrueUI";
import CancelButton from "../../../../TrueUI/Buttons/CancelButton";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import ColorAndFonts from "./ColorAndFonts";
import ContactEmailAddresses from "./ContactEmailAddresses";
import PolicyEmailAddresses from "./PolicyEmailAddresses";
import { fontType, PortalAdminTabsEnum } from "./PortalSettingUtils";
import ProgramInfoContainer from "./ProgramInfoContainer";
import SwitchButtonsFields from "./SwitchButtonsFields";

type PortalSettingsProps = {
  programId?: number;
  setSelectTab?: (tab: number) => void;
};

export type PortalSettings = {
  settings?: PortalSettingsDto | null;
  errorDetails?: any;
  setPortalSettings?: (settings: PortalSettingsDto) => void;
};

const PortalSettings: FC<PortalSettingsProps> = ({
  programId,
  setSelectTab,
}) => {
  const [portalSettings, setPortalSettings] =
    useState<PortalSettingsDto | null>();
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { responseGet, dispatchGet } = useApiGet<PortalSettingsDto>(
    `api/AdminTools/PortalSettings/GetPortalSettings?programId=${programId}`
  );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/AdminTools/PortalSettings/SavePortalSettings",
    portalSettings
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPortalSettings(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    } else {
      setErrorDetails(null);
    }
  }, [responsePost]);

  return (
    <>
      {conditionHasValue(portalSettings) && (
        <div
          style={{
            marginTop: "10px",
            overflow: "auto",
            height: "100%",
          }}
        >
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <ProgramInfoContainer
                settings={portalSettings}
                setPortalSettings={setPortalSettings}
                errorDetails={errorDetails}
              />
            </Col>
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf="unset"
            >
              <ColorAndFonts
                settings={portalSettings}
                setPortalSettings={setPortalSettings}
                errorDetails={errorDetails}
              />
            </Col>
          </Row>
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf="unset"
            >
              <SwitchButtonsFields
                settings={portalSettings}
                setPortalSettings={setPortalSettings}
              />
            </Col>
          </Row>
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 12 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <Font fontType={fontType}>Contact Email Addresses</Font>
            </Col>
          </Row>
          <Row
            {...rowWithNoMarginNorGutter}
            verticalAlign={"flex-start"}
            allowWrap
          >
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf={"flex-start"}
            >
              <ContactEmailAddresses
                settings={portalSettings}
                setPortalSettings={setPortalSettings}
                errorDetails={errorDetails}
              />
            </Col>
            <Col
              breakpoints={{ sm: 12, md: 12, lg: 6 }}
              verticalGutter="0px"
              displayFlex={false}
              verticalAlignSelf="unset"
            >
              <PolicyEmailAddresses
                settings={portalSettings}
                setPortalSettings={setPortalSettings}
                errorDetails={errorDetails}
              />
            </Col>
          </Row>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <SaveButton onClick={() => dispatchPost()} />
            <CancelButton
              withConfirmation
              onClick={() => setSelectTab?.(PortalAdminTabsEnum.INFORMATION)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PortalSettings;
