import { FC } from "react";
import { useRecoilValue } from "recoil";
import { SelectedInsuredCommissionAtom } from "./CommissionsAtoms";
import Commissions from "./Commissions";
import CommissionDetails from "./CommissionDetails";

const CommissionsContentWrapper: FC = () => {
  const transaction = useRecoilValue(SelectedInsuredCommissionAtom);

  return transaction == null ? (
    <Commissions />
  ) : (
    <CommissionDetails insuredCommission={transaction} />
  );
};

export default CommissionsContentWrapper;
