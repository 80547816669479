import { FC, useEffect } from "react";
import { Tabs, TabPanel } from "../../TrueUI";
import Information from "./Information";
import InsuredInformationContacts from "./Contacts/InsuredInformationContacts";
import InsuredInformationOfficers from "./InsuredInformationOfficers";
import InsuredInformationNamesAndAddresses from "./NamesAndAddresses/InsuredInformationNamesAndAddresses";
import { INSURED_INFORMATION_TABS } from "../../../utilities/queryStringsHash";

import { useInternalInsuredTabs } from "../../../hooks/useInternalInsuredTabs";
import { Box } from "@mui/material";

type InsuredInformationProps = {
  insuredId: number;
  tabKey: string;
};

const tabName = INSURED_INFORMATION_TABS;

const InsuredInformation: FC<InsuredInformationProps> = ({
  insuredId,
  tabKey,
}) => {
  const { getTabSelected, setTabValue } = useInternalInsuredTabs(tabKey);

  const tabSelected = getTabSelected(tabName);

  const onTabClick = (e) => {
    setTabValue(e.selected, tabName);
  };

  useEffect(() => {
    setTabValue(tabSelected, tabName);
  }, []);

  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <Tabs
        name={tabName}
        themeOverride={"material"}
        selected={tabSelected}
        onSelect={onTabClick}
        stateControlled
      >
        <TabPanel title={"Information"} key={"insuredInformationTab"}>
          <Information insuredId={insuredId} />
        </TabPanel>
        <TabPanel title={"Contacts"} key={"insuredContactsTab"}>
          <InsuredInformationContacts insuredId={insuredId} />
        </TabPanel>
        <TabPanel title={"Names & Addresses"} key={"insuredNamesTab"}>
          <InsuredInformationNamesAndAddresses insuredId={insuredId} />
        </TabPanel>
        <TabPanel title={"Officers"} key={"insuredOfficersTab"}>
          <InsuredInformationOfficers insuredId={insuredId} />
        </TabPanel>
        <TabPanel title={"Schedules"} key={"insuredSchedulesTab"}>
          Schedules
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default InsuredInformation;
