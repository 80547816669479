import { FC, useEffect } from "react";
import { Modal, TitleBold } from "../../../../TrueUI";
import {
  ModalAddDefaultReservesProps,
  modalAddDefaultReservesProps,
} from "./ModalChangeProps";

import { useRecoilValue } from "recoil";
import { ClaimTypeAtom } from "../ClaimLandingInformationAtoms";
import { useApiPost } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { BaseGridProperties } from "../../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../../BaseGrid/BaseGrid";

const ModalDefaultReserves: FC<ModalAddDefaultReservesProps> = ({
  setAllModalConfiguration,
  allModalConfiguration,
  claimId,
  tabKey,
}) => {
  const claimType = useRecoilValue(ClaimTypeAtom);

  const tableName = "claim_default_reserves_table";
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    `api/claimEventRules/SaveDefaultReserves?claimId=${claimId}`
  );
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  useEffect(() => {
    if (isAPITotallyComplete(responsePost) && validatorErrorResponse === null) {
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalAddDefaultReserves: false,
      });
      setComponentTriggers(["activityLogComponent", "claimBanner", "financialTableClaimLandingPageComponent"]);
    }
  }, [responsePost]);

  const closeButtonClicked = (e) => {
    if (e === false) {
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalAddDefaultReserves: false,
      });
    }
  };

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `api/claimEventRules/GetDefaultReservesTable?claimId=${claimId}`,
    toolbarOptions: {
      hideToolbar: true,
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
      showSaveButton: false,
      showAddButton: false,
      showExportButton: false,
    },
  };

  const { mountGrid, unmountGrid } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    if (allModalConfiguration.openModalAddDefaultReserves) {
      mountGrid();
    }
  }, [allModalConfiguration.openModalAddDefaultReserves]);

  return (
    <Modal
      {...{
        ...modalAddDefaultReservesProps,
        open: allModalConfiguration.openModalAddDefaultReserves,
        closeEvent: (e) => {
          closeButtonClicked(e);
          unmountGrid();
        },
        saveEvent: () => dispatchPost(),
      }}
    >
      <TitleBold primaryColor> {claimType} </TitleBold>
      <div style={{ height: "200px", marginTop: "10px" }}>
        <BaseGrid name={tableName} />
      </div>
    </Modal>
  );
};

export default ModalDefaultReserves;
