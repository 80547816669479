import { FC, useEffect, useState } from "react";
import { Col, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import GeneralQuestionSelector from "./GeneralQuestionSelector";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { PolicyApplicationQuestionBlob } from "../../../../dtos/policy-application-question-blob";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import {
  getGeneralInformationWithQuestionUpdated,
  updateApplicationQuestionValues,
} from "./GeneralInformationUtils";
import { updateGeneralQuestions } from "../updateGlobalPolicyQuoteFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { focusElement } from "../../../../utilities/tabFunctions";

export type GeneralQuestionsProps = {
  applicationQuestions: PolicyApplicationQuestionBlob[];
};

const GeneralQuestions: FC<GeneralQuestionsProps> = ({
  applicationQuestions,
}) => {
  const { getAtom, setAtom } = useAtomFamily(acord130FormProperties);
  const [localQuestions, setLocalQuestions] =
    useState<PolicyApplicationQuestionBlob[]>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChangeQuestion = (
    checked: boolean,
    textboxValue: string,
    applicationQuestion: PolicyApplicationQuestionBlob
  ) => {
    if (conditionHasValue(localQuestions)) {
      const atomValue = getAtom();
      const newApplicationQuestion = updateApplicationQuestionValues(
        checked,
        textboxValue,
        applicationQuestion
      );

      const newGeneralInformation = getGeneralInformationWithQuestionUpdated(
        atomValue?.generalInformation ?? [],
        newApplicationQuestion
      );

      const newAtomValue = updateGeneralQuestions(
        atomValue,
        newGeneralInformation
      );
      setAtom(newAtomValue);
      setLocalQuestions(newGeneralInformation);
    }
  };

  const questionsRendered = localQuestions?.map((question, index) => (
    <Col
      {...colWithNoMarginNorGutter}
      horizontalAlign="flex-start"
      key={`application-question-${question.applicationQuestionId}`}
    >
      <GeneralQuestionSelector
        index={index + 2}
        applicationQuestion={question}
        onChangeQuestion={onChangeQuestion}
      />
    </Col>
  ));

  useEffect(() => {
    setLocalQuestions(applicationQuestions);
  }, [applicationQuestions]);

  useEffect(() => {
    if (
      questionsRendered !== undefined &&
      questionsRendered !== null &&
      isFocused === false &&
      localQuestions !== undefined &&
      localQuestions.length > 0
    ) {
      const firstQuestionNumber = localQuestions[0].questionNumber;
      focusElement(`general-information-yes-no-switch-${firstQuestionNumber}`);
      setIsFocused(true);
    }
  }, [questionsRendered]);

  return localQuestions !== undefined && localQuestions !== null ? (
    <Row
      {...rowWithNoMarginNorGutter}
      id="acord_130_general_questions_container"
      rowDirection="column"
    >
      {questionsRendered}
    </Row>
  ) : (
    <>LOADING...</>
  );
};

export default GeneralQuestions;
