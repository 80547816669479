export type DeleteDialogConfiguration = {
  isOpen: boolean;
  descriptionText: string;
  dialogId: string;
  dialogName: string;
};
export const defaultDeleteDialogConfiguration = {
  isOpen: false,
  descriptionText: "Are you sure you want to delete this item?",
  dialogId: "delete-dialog-confirmation",
  dialogName: "deleteDialog",
};