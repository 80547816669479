import { FC } from "react";
import style from "../Styles/basegridcells.module.css";

type DefaultCheckboxCellProperties = {
  value: string;
};

const DefaultCheckboxCell: FC<DefaultCheckboxCellProperties> = ({ value }) => {
  console.log("DefaultCheckboxCell", value);
  const checkedValue = value === "true" ? true : false;
  return (
    <div className={style.default_checkbox_render_only_cell}>
      {checkedValue ? (
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckBoxIcon"
        >
          <path
            className="default_checkbox_render_only_cell_path"
            d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
          ></path>
        </svg>
      ) : (
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckBoxOutlineBlankIcon"
        >
          <path
            className="default_checkbox_render_only_cell_path"
            d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
          ></path>
        </svg>
      )}

      {/* <span className="MuiTouchRipple-root css-w0pj6f"></span>
        <span className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-6x4ux9"></span> */}
      {/* </label> */}
    </div>
    // </div>
  );
  //return <div>{value}</div>;
};

export default DefaultCheckboxCell;
