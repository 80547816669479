import { FC, useEffect, useState } from "react";
import {
  BaseGridCellManagerOperations,
  GridDataRow,
  RowKey,
} from "./BaseGridProperties";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import BaseGridCellManager from "./GridCellManager/BaseGridCellManager";
import { useCellManagerInstance } from "./Hooks/useCellManagerInstance";
import { getSafeRowKey } from "./SupportFunctions/OLD_baseGridFunctions";

/////// Dev Note ///////
// Retaining this file as it is an enhancement over the current BaseGridRenderProcessor in terms of maintenance.
////////////////////////

const addBaseChunkGroup = 10;
const defaultChunkGroup = 60;

type BaseGridBodyProcessorProperties = {
  uiid: string;
  scrollEventTrigger: {
    scrollEvent: { rowBoundRefRect: DOMRect | null; scrollEvent: Event };
    direction: "down" | "up" | null;
  } | null;
  gridBodyRef: any;
  gridBodyWidthHeight: { height: number; width: number };
};

const BaseGridRenderProcessor_v2: FC<BaseGridBodyProcessorProperties> = ({
  uiid,
  scrollEventTrigger,
  gridBodyRef,
  gridBodyWidthHeight,
}) => {
  const [hasMounted, setHasMounted] = useState(false);
  const { instanceSelector, instanceInternalSelector, setInternalInstance } =
    useGridInstance(uiid, "BaseGridBodyProcessor");
  const { createCellManagerAtomInstance } = useCellManagerInstance(uiid);

  const instance = useRecoilValue(instanceSelector());
  const internalInstance = useRecoilValue(instanceInternalSelector());
  const [unprocessedMountedCellManagers, setUnprocessedMountedCellManagers] =
    useState<string[]>([]);
  const [renderedItems, setRenderedItems] = useState<any[]>([]);

  const [viewPortRange, setViewPortRange] = useState<any>({
    top: 0,
    bottom: 0,
    width: gridBodyRef?.current?.offsetWidth ?? 0,
    height: gridBodyRef?.current?.offsetHeight ?? 0,
  });

  const [bodyHeightWidth, setBodyHeightWidth] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  useEffect(() => {
    setBodyHeightWidth(gridBodyWidthHeight);
  }, [gridBodyWidthHeight]);

  // We support the ability to read elements heights before they are rendered on the screen.
  // We can use this in order to limit the number of elements that are "initially" rendered give a height for the table container.
  // We use the first 3 elements in our array as a minimum in order to get the average. By default we set 43px for all 3 values.
  // const [rowHeights, setRowHeights] = useState<number[]>([43, 43, 43]);

  const [currentTotalRowHeight, _setCurrentTotalRowHeight] = useState<{
    avgRowHeight: number;
    totalInitalRowHeight: number;
  }>({ avgRowHeight: 0, totalInitalRowHeight: 0 });

  const listOfInitComputeFunctions = () => {
    return (
      instance.columnOptions?.filter((option) => {
        if (option?.computeOnInit !== undefined) {
          return {
            columnIndex:
              instance?.columns?.find((c) => c.fieldName === option.fieldName)
                ?._columnIndex ?? -1,
            fn: option?.computeOnInit,
          };
        }
        return false;
      }) ?? []
    );
  };

  const listOfChangeComputeFunctions = () => {
    return (
      instance.columnOptions?.filter((option) => {
        if (option?.computeOnChange !== undefined) {
          return {
            columnIndex:
              instance?.columns?.find((c) => c.fieldName === option.fieldName)
                ?._columnIndex ?? -1,
            fn: option?.computeOnChange,
          };
        }
        return false;
      }) ?? []
    );
  };

  const [lastResultSetIndex, setLastResultSetIndex] = useState<{
    lastIndex: number;
    previousDirection: "init" | "up" | "down" | null;
  }>({
    lastIndex: 0,
    previousDirection: null,
  });

  const renderChunks = (results: any[]) => {
    const inProcessCellmanagerOperations: BaseGridCellManagerOperations[] = [];
    const currentlyRenderedItemsList = (results ?? []).map((row) => {
      inProcessCellmanagerOperations.push({
        rowKey: row.rowKey,
        hasCompletedOperations: false,
      });

      createCellManagerAtomInstance(row.rowKey);

      return (
        <BaseGridCellManager
          key={crypto.randomUUID()}
          uiid={uiid}
          rowKey={row.rowKey}
          rowIndex={row.rowIndex}
          row={row.data}
          gridBodyViewPort={viewPortRange}
          columns={instance?.columns ?? []}
          listOfInitComputeFunctions={listOfInitComputeFunctions()}
          listOfChangeComputeFunctions={listOfChangeComputeFunctions()}
          ref={(_r) => {}}
        />
      );
    });

    return currentlyRenderedItemsList;
  };

  const getChunkMappedResult = (
    resultSet: GridDataRow[],
    currentDirection: "init" | "up" | "down" | null,
    startPosition: number,
    endPosition: number
  ) => {
    const resultSetRange = resultSet.slice(startPosition, endPosition);
    const mappedResult = resultSetRange.map((r, i) => {
      return { data: r, rowKey: getSafeRowKey(r), rowIndex: i } as {
        data: GridDataRow;
        rowKey: RowKey;
        rowIndex: number;
      };
    });

    const remainingChunkLength = resultSetRange.length;
    const remainingChunks = addBaseChunkGroup - remainingChunkLength;

    const lastIndexByDirection = {
      init: defaultChunkGroup,
      up: startPosition,
      down: endPosition - remainingChunks,
    };

    setLastResultSetIndex({
      lastIndex:
        startPosition === 0
          ? defaultChunkGroup
          : lastIndexByDirection[currentDirection ?? "init"],
      previousDirection: startPosition === 0 ? "init" : currentDirection,
    });

    return mappedResult;
  };

  const getChunks = (
    resultSet: GridDataRow[],
    direction: "init" | "up" | "down" | null
  ): any[] => {
    if (
      (lastResultSetIndex.previousDirection === "init" &&
        lastResultSetIndex.lastIndex === defaultChunkGroup &&
        direction === "up") || // If the top of the last known index is 0 as the previousDirection state was "init", we don't return anything else
      (lastResultSetIndex.lastIndex <= 0 && direction === "up") || // If we hit the top of the grid, we don't return anything else
      (lastResultSetIndex.lastIndex >= resultSet.length && direction === "down") // If we hit the bottom of the grid, we don't return anything else
    ) {
      return [];
    }

    if (direction === "init") {
      // this is the default state for rendered results
      return getChunkMappedResult(resultSet, "init", 0, defaultChunkGroup);
    }

    if (direction === "up") {
      if (lastResultSetIndex.previousDirection === "down") {
        // this state gets rendered when the user scrolls "up" when the previous state was a "down" state. this attempts to compensate for 60 rows inbetween the new resultset and the previous resultset
        return getChunkMappedResult(
          resultSet,
          "up",
          lastResultSetIndex.lastIndex -
            (defaultChunkGroup + addBaseChunkGroup),
          lastResultSetIndex.lastIndex - defaultChunkGroup
        );
      } else {
        // these states trigger when a normal "up" state occurs
        if (lastResultSetIndex.lastIndex < addBaseChunkGroup) {
          // this triggers when there are less than 10 remaining results left in the current resultSet
          return getChunkMappedResult(
            resultSet,
            "up",
            0,
            lastResultSetIndex.lastIndex
          );
        } else {
          // this is the default "up" state
          return getChunkMappedResult(
            resultSet,
            "up",
            lastResultSetIndex.lastIndex - addBaseChunkGroup,
            lastResultSetIndex.lastIndex
          );
        }
      }
    }

    if (direction === "down") {
      if (lastResultSetIndex.previousDirection === "up") {
        // this state gets rendered when the user scrolls "down" when the previous state was a "up" state. this attempts to compensate for 60 rows inbetween the new resultset and the previous resultset
        return getChunkMappedResult(
          resultSet,
          "down",
          lastResultSetIndex.lastIndex,
          lastResultSetIndex.lastIndex + (defaultChunkGroup + addBaseChunkGroup)
        );
      } else {
        // this is the default "down" state
        return getChunkMappedResult(
          resultSet,
          "down",
          lastResultSetIndex.lastIndex,
          lastResultSetIndex.lastIndex + addBaseChunkGroup
        );
      }
    }

    return [];
  };

  const popRenderedChunk = (direction: "init" | "up" | "down" | null) => {
    if (direction === null || direction === "init") {
      return renderedItems;
    }

    if (direction === "up" || direction === "down") {
      const popPositions = {
        up: { from: 0, to: renderedItems.length - addBaseChunkGroup }, // if up, we want to remove from bottom
        down: { from: addBaseChunkGroup, to: renderedItems.length }, // if down, we want to remove from top
      };

      const newRenderedResult = [
        ...renderedItems.slice(
          popPositions[direction].from,
          popPositions[direction].to
        ),
      ];

      return newRenderedResult;
    }
    return renderedItems;
  };

  const mergeRenderedChunks = (
    previousRenderedChunks: any[],
    newelyRenderedChunks: any[],
    direction: "init" | "up" | "down" | null
  ) => {
    if (direction === null || direction === "init") {
      return newelyRenderedChunks;
    }

    if (direction === "up") {
      return newelyRenderedChunks.concat(previousRenderedChunks);
    }

    if (direction === "down") {
      return previousRenderedChunks.concat(newelyRenderedChunks);
    }
    return previousRenderedChunks;
  };

  const updateRenderedItems = (
    resultSet: GridDataRow[],
    // addFrom: number,
    // addTo: number,
    direction: "up" | "down" | "init" | null,
    _sourceX: string,
    _clearPreviousRenderResults?: boolean
  ) => {
    const previouslyRenderedChunks = popRenderedChunk(direction);
    const chunks = getChunks(resultSet, direction);
    const newelyRenderedChunks = renderChunks(chunks);
    const combinedRenderedChunks = mergeRenderedChunks(
      previouslyRenderedChunks,
      newelyRenderedChunks,
      direction
    );

    if (chunks.length > 0) {
      setRenderedItems(combinedRenderedChunks);
    }
  };

  const calcTopBottomSpace = (
    topRowBoundingBox,
    topViewport,
    bottomRowBoundingBox,
    bottomViewport,
    viewportHeight
  ) => {
    const halfViewportHeight = (60 * viewportHeight) / 100;
    const top = Math.abs(topRowBoundingBox - topViewport);
    const bottom = Math.abs(bottomRowBoundingBox - bottomViewport);
    if (scrollEventTrigger?.direction === "up" && top < halfViewportHeight) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "up",
        "up",
        false
      );
    }

    if (
      scrollEventTrigger?.direction === "down" &&
      bottom < halfViewportHeight
    ) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "down",
        "down",
        false
      );
    }
  };

  useEffect(() => {
    const gridRefRect = gridBodyRef?.current?.getBoundingClientRect() ?? {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
    calcTopBottomSpace(
      (scrollEventTrigger as any)?.scrollEvent?.rowBoundRefRect?.top,
      gridRefRect.top,
      (scrollEventTrigger as any)?.scrollEvent?.rowBoundRefRect?.bottom,
      gridRefRect.bottom,
      bodyHeightWidth.height
    );
  }, [currentTotalRowHeight, scrollEventTrigger]);

  // On mount
  useEffect(() => {
    if (
      hasMounted === false &&
      instance !== null &&
      (instance?.data ?? []).length > 0 &&
      (instance?.columns ?? []).length > 0
    ) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "init",
        "source sortandfilterdata eff on mount",
        true
      );
      setHasMounted(true);
      instance.events?.onInitMount?.(true);
    }
  }, []);

  // This is for a unique scenerio where the initial data that is set has a length of 0 but an updated "unique" data set has more than 1.
  useEffect(() => {
    if (
      hasMounted === false &&
      instance !== null &&
      (instance?.data ?? []).length > 0 &&
      (instance?.columns ?? []).length > 0
      // internalInstance?.sortedAndFilteredData?.length > 0
    ) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "init",
        "source sortandfilterdata eff on mount",
        true
      );
      setHasMounted(true);
      instance.events?.onInitMount?.(true);
    }
  }, [internalInstance.sortedAndFilteredData]);

  useEffect(() => {
    if (
      hasMounted === true &&
      instance !== null &&
      (instance?.data ?? []).length > 0 &&
      (instance?.columns ?? []).length > 0 &&
      internalInstance.accessors?._reloadDataSources === false &&
      internalInstance.hasSortedAndFilteredProcessingCompleted
      // && internalInstance?.sortedAndFilteredData?.length > 0
    ) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "init",
        "source sortandfilterdata eff on mount"
      );
      setLastResultSetIndex({
        lastIndex: 60,
        previousDirection: "init",
      });
    }
  }, [internalInstance?.sortedAndFilteredData]);

  useEffect(() => {
    if (
      hasMounted === true &&
      instance !== null &&
      ((instance?.data ?? []).length === 0 ||
        internalInstance?.sortedAndFilteredData.length === 0) &&
      (instance?.columns ?? []).length > 0
    ) {
      // This block of code basically resets the rendered rows if no data in the "data"
      // object exsits, which assumes "sortedAndFilteredData" has no data neither.
      setRenderedItems([]);
    }
  }, [internalInstance?.sortedAndFilteredData]);

  useEffect(() => {
    if (
      hasMounted === true &&
      instance !== null &&
      (instance?.data ?? []).length > 0 &&
      (instance?.columns ?? []).length > 0 &&
      internalInstance?.sortedAndFilteredData?.length > 0 &&
      internalInstance?.refreshAfterAddRow === true
    ) {
      updateRenderedItems(
        internalInstance.sortedAndFilteredData,
        "init",
        "source sortandfilterdata eff on mount",
        true
      );
      setInternalInstance({
        refreshAfterAddRow: false,
      });
    }
  }, [internalInstance?.refreshAfterAddRow]);

  useEffect(() => {
    if (
      instance !== null &&
      (instance?.data ?? []).length > 0 &&
      (instance?.columns ?? []).length > 0
    ) {
      setViewPortRange({
        top: gridBodyRef?.current?.getBoundingClientRect().top ?? 0,
        bottom: gridBodyRef?.current?.getBoundingClientRect().bottom ?? 0,
        width: gridBodyRef?.current?.getBoundingClientRect().width ?? 0,
        height: gridBodyRef?.current?.getBoundingClientRect().height ?? 0,
      });
    }
  }, [instance?.data, instance?.columns]);

  useEffect(() => {
    setViewPortRange({
      top: gridBodyRef?.current?.getBoundingClientRect().top ?? 0,
      bottom: gridBodyRef?.current?.getBoundingClientRect().bottom ?? 0,
      width: gridBodyRef?.current?.offsetWidth ?? 0,
      height: gridBodyRef?.current?.offsetHeight ?? 0,
    });
  }, []);

  useEffect(() => {
    if (
      renderedItems.length === 0 &&
      (scrollEventTrigger === null || scrollEventTrigger?.direction === null)
    ) {
      const rowKeysToProcess = renderedItems.map((r) => r.props.rowKey);
      setUnprocessedMountedCellManagers(rowKeysToProcess);
    } else {
      setUnprocessedMountedCellManagers([]);
    }
  }, [renderedItems, scrollEventTrigger?.direction]);

  useEffect(() => {
    if (
      unprocessedMountedCellManagers.length > 0 &&
      (scrollEventTrigger === null || scrollEventTrigger?.direction === null)
    ) {
      const updatedUnprocessedMountedCellManagers =
        unprocessedMountedCellManagers.slice(1);
      setUnprocessedMountedCellManagers(
        updatedUnprocessedMountedCellManagers as any
      );
    }
  }, [scrollEventTrigger?.direction]);

  return <>{renderedItems.length > 0 ? renderedItems : null}</>;
};

export default BaseGridRenderProcessor_v2;
