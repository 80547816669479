import { FC, useState } from "react";
import { Box, Link } from "@mui/material";
import { Col, Font, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { documentsGroupRowStyles } from "./DocumentStyles";
import { PolicyQuoteDocumentPacketProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { usePermissions } from "../../../../hooks";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { ERROR_TEMPLATE_NAME } from "./DocumentsUtils";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const DocumentPacket: FC<PolicyQuoteDocumentPacketProps> = ({
  tabKey,
  programDocuments,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const templateNames =
        document.fileId !== null
          ? [document.templateName ?? ERROR_TEMPLATE_NAME]
          : policyMergeFields.endorsementForms?.map((form) => {
              return form.templateName ?? ERROR_TEMPLATE_NAME;
            }) ?? [];

      if (templateNames.includes(ERROR_TEMPLATE_NAME)) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: templateNames,
          customFileName: `${policyMergeFields.insured?.insuredName ?? ""} ${
            document.docName ?? ""
          }`,
          printOrDownload: "download",
        });
      }
    }
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        verticalGutter="10px"
        {...getConfigurationForColumn(12, "flex-start", "center")}
      >
        <Font>PACKET POLICY DOCUMENTS</Font>
      </Col>
      {programDocuments.map((document, idx) => (
        <Box sx={documentsGroupRowStyles()} key={`documents-packet-${idx}`}>
          <Col
            horizontalGutter="20px"
            {...getConfigurationForColumn(12, "flex-start", "center")}
          >
            <Link
              href="#"
              underline={hasPermission ? "hover" : "none"}
              onClick={(e: any) => {
                hasPermission
                  ? printIndividualDocument(document.programPolicyDocument)
                  : e.preventDefault();
              }}
              sx={{ cursor: hasPermission ? "pointer" : "not-allowed" }}
            >
              {document.programPolicyDocument.docName}
            </Link>
          </Col>
        </Box>
      ))}
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Row>
  );
};

export default DocumentPacket;
