import { Stack } from "@mui/material";
import { FC } from "react";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { WagesContainerDto } from "../../../../../../dtos/wages-container-dto";
import { Col, Input, InputDate, Row, Select } from "../../../../../TrueUI";
import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { RowProps } from "../../../../../TrueUI/Grids/Row";
import { SelectOptions } from "../../../../../../dtos/select-options";

type ClaimWagesContainerCollapsedProps = {
  wagesUI?: WagesContainerDto | null;
  configuration?: DetailsConfigurationDto;
  readOnly: boolean;
};

const rowConfiguration: RowProps = {
  verticalAlign: "flex-start",
  horizontalAlign: "flex-start",
  horizontalMargin: "0px",
  horizontalGutter: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",
  allowWrap: true,
};

const colConfiguration: ColProps = {
  breakpoints: { md: 3, lg: 3, xl: 3 },
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
};

const selectConfiguration = (
  id: string,
  name: string,
  label: string,
  readOnly: boolean
) => {
  return {
    id: id,
    name: name,
    label: label,
    readOnly: readOnly,
  };
};

const ClaimWagesContainerCollapsed: FC<ClaimWagesContainerCollapsedProps> = ({
  wagesUI,
  readOnly,
  configuration,
}) => {
  const getConfigurationSelectValue = (
    options: SelectOptions[],
    isBoolType: boolean,
    value?: number | boolean
  ) =>
    options.find((opt) =>
      isBoolType ? opt.booleanValue === value : opt.intValue === value
    )?.displayName ?? "";

  return (
    <Stack width={"100%"} zIndex={99}>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            id="id-class-code"
            name="wagesCollapseReadOnlyClassCode"
            label="Class Code"
            readOnly={readOnly}
            value={wagesUI?.classCode}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            id="id-occupation"
            name="wagesCollapseReadOnlyOccupation"
            label="Occupation"
            readOnly={readOnly}
            value={wagesUI?.occupation ?? ""}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            id="id-wage-amount"
            name="wagesCollapseReadOnlyWageAmount"
            label="Wage Amount"
            readOnly={readOnly}
            type="currency"
            value={wagesUI?.wageAmount}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            {...selectConfiguration(
              "id-wage-period",
              "wagesCollapseReadOnlyWagePeriod",
              "Wage Period",
              readOnly
            )}
            options={configuration?.wagePeriodsOptions ?? []}
            value={wagesUI?.wagePeriod}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            id="id-medicare-eligible"
            name="wagesCollapseReadOnlyMedicareEligible"
            label="Medicare"
            readOnly={readOnly}
            value={getConfigurationSelectValue(
              configuration?.yesNoOptions ?? [],
              true,
              wagesUI?.medicareEligible
            )}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            id="id-employment-type"
            name="wagesCollapseReadOnlyEmploymentType"
            label="Employment Type"
            readOnly={readOnly}
            value={getConfigurationSelectValue(
              configuration?.employmentTypeOptions ?? [],
              false,
              wagesUI?.employmentType
            )}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <InputDate
            id="id-last-day-worked"
            name="wagesCollapseReadOnlyLastDayWorked"
            label="Last Day Worked"
            readOnly={readOnly}
            value={wagesUI?.lastDayWorked}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
        <Col {...colConfiguration}>
          <InputDate
            id="id-est-return-to-work"
            name="wagesCollapseReadOnlyEstimateReturnToWork"
            label="Est. Return To Work"
            readOnly={readOnly}
            value={wagesUI?.estimatedReturnToWork}
            labelFontType={"CAPTION"}
            inputFontType={"BODY"}
          />
        </Col>
      </Row>
    </Stack>
  );
};

export default ClaimWagesContainerCollapsed;
