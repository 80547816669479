import { FC, useState } from "react";
import { TableRow } from "@mui/material";
import { Input, InputDate } from "../../../TrueUI";
import { TableData } from "../../../TrueUI/Tables/BaseTableStructure";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { ColumnOptionsProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import {
  FormattingDate,
  StringToDate,
} from "../../../../utilities/dateFunctions";
import "./PaymentSchedule.module.css";

type MultiTableParentComponentProps = {
  readOnly: boolean;
  metaData: any;
  paramDate: string | null;
  headerParams: BaseTableMetaData[];
  columnOptions: ColumnOptionsProperties<any>[];
  updatePaymentScheduleMultiTableDueDate?: (
    rowIndex: number,
    newDueDate: Date
  ) => void;
};

const MultiTableParentComponent: FC<MultiTableParentComponentProps> = ({
  readOnly,
  metaData,
  paramDate,
  headerParams = [],
  columnOptions = [],
  updatePaymentScheduleMultiTableDueDate,
}) => {
  const [dueDate, setDueDate] = useState<string | null>(paramDate);

  const getColumnWidth = (param) => {
    const selected = columnOptions?.find((c) => c?.fieldName === param.name);
    if (conditionHasValue(selected)) {
      return `${selected?.width}%`;
    }
    return `${100 / (headerParams?.length ?? 1)}%`;
  };

  const updateDueDate = (date: Date) => {
    setDueDate(FormattingDate(date));
    updatePaymentScheduleMultiTableDueDate?.(Number(metaData.Id), date);
  };

  return (
    <TableRow
      key={`internal-row-payment-schedule-multi-table-${metaData.Id}`}
      className={"payment_schedule_multi_table_parent"}
    >
      {headerParams?.map((param, index) => (
        <TableData width={getColumnWidth(param)}>
          {param.inputType === BaseTableInputType.DATE_PICKER ? (
            <InputDate
              id={`id-${columnOptions?.[index].fieldName.toLowerCase()}`}
              name={`name-${columnOptions?.[index].fieldName.toLowerCase()}`}
              variant="standard"
              value={StringToDate(dueDate)}
              onChangeRawValue={updateDueDate}
              readOnly={readOnly}
            />
          ) : (
            <Input
              id={`id-${columnOptions?.[index].fieldName.toLowerCase()}`}
              name={`name-${columnOptions?.[index].fieldName.toLowerCase()}`}
              value={param?.value}
              align={`${columnOptions?.[index].align ?? "left"}`}
              readOnly
            />
          )}
        </TableData>
      ))}
    </TableRow>
  );
};

export default MultiTableParentComponent;
