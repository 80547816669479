import { FC, useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import {
  FilterValuesSelectedProps,
  StatementTableType,
  StatementsBatchTableWrapperProps,
  defaultFilterValuesSelected,
  filterDataByDateAndStatementType,
  getDatesRange,
  tableName,
} from "./StatementsUtil";
import LaunchIcon from "@mui/icons-material/Launch";

import { Box, IconButton } from "@mui/material";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import StatementBatchTableFilter from "./StatementBatchTableFilter";

const StatementsBatchTableWrapper: FC<StatementsBatchTableWrapperProps> = ({
  statementTypeOptions,
  statementYearsOptions,
  setRenderTableConf,
}) => {
  const {
    responseGet: responseGetStatementBatchTable,
    dispatchGet: dispatchGetStatementBatchTable,
  } = useApiGet<any>("api/StatementBatch/GetStatementBatchTable");

  const [statementTableData, setStatementTableData] = useState<any>(null);
  const [filteredStatementTableData, setFilteredStatementTableData] =
    useState<any>(null);

  const [filterValuesSelected, setFilterValuesSelected] =
    useState<FilterValuesSelectedProps>(defaultFilterValuesSelected);

  useEffect(() => {
    const datesRange = getDatesRange(new Date().getFullYear().toString());
    setFilterValuesSelected({
      statementType: "all",
      date: new Date().getFullYear().toString(),
      startDateRange: datesRange.startDate,
      endDateRange: datesRange.endDate,
    });

    dispatchGetStatementBatchTable();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetStatementBatchTable)) {
      setStatementTableData(
        responseGetStatementBatchTable.responseData.tableData ?? null
      );
    }
  }, [responseGetStatementBatchTable]);

  useEffect(() => {
    if (statementTableData !== null) {
      const filteredTableData = filterDataByDateAndStatementType(
        statementTableData?.data ?? [],
        filterValuesSelected
      );
      setFilteredStatementTableData({
        columns: statementTableData.columns,
        data: filteredTableData,
      });
    }
  }, [statementTableData, filterValuesSelected]);

  useEffect(() => {
    if (filteredStatementTableData !== null) {
      manuallyReloadParameters();
    }
  }, [filteredStatementTableData]);

  const onChangeDateFilter = (value: string) => {
    const range = getDatesRange(value);
    setFilterValuesSelected({
      ...filterValuesSelected,
      date: value,
      startDateRange: range.startDate,
      endDateRange: range.endDate,
    });
  };

  const onChangeTypeFilter = (value: string) => {
    setFilterValuesSelected({
      ...filterValuesSelected,
      statementType: value,
    });
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            const statementBatchId = actionOptions.row.StatementBatchId;
            setRenderTableConf?.({
              tableToRender: StatementTableType.STATEMENT_DETAIL,
              statementBatchId: statementBatchId,
              statementDate: actionOptions.row.Date,
            });
          }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const statementsBatchTableConfig = () => {
    return {
      name: tableName,
      useManuallyReloadParameters: true,
      disableInitialize: true,
      columnsAndData: {
        columns: filteredStatementTableData?.columns ?? [],
        data: filteredStatementTableData?.data ?? [],
      },
      columnOptions: [
        { fieldName: "StatementType", width: 15 },
        { fieldName: "Date", width: 15 },
        { fieldName: "DueDate", width: 15 },
        { fieldName: "Mail", width: 12 },
        { fieldName: "Email", width: 12 },
        { fieldName: "TotalBilled", width: 12, align: "right" },
        { fieldName: "CreatedByOn", width: 19 },
        { fieldName: "OPTIONS", width: 3 },
      ],
      filterOptions: [
        () =>
          StatementBatchTableFilter({
            id: "year-filter",
            options: statementYearsOptions,
            labelText: "Year:",
            value:
              filterValuesSelected?.date ?? new Date().getFullYear().toString(),
            onChange: onChangeDateFilter,
          }),
        () =>
          StatementBatchTableFilter({
            id: "type-filter",
            options: statementTypeOptions,
            labelText: "Statement type:",
            value: filterValuesSelected?.statementType,
            onChange: onChangeTypeFilter,
          }),
      ],
      orderDirection: "asc",
      orderByColumnIndex: 4,
      toolbarOptions: {
        showEditButton: false,
        showSaveButton: false,
        showSortFilter: false,
        showImportButton: false,
        showAddButton: true,
        addButtonText: "New Statement Batch",
      },
      advancedOptions: {
        optionsContextActions: {
          contextComponent: actionsForRows,
        },
        optionsColumnConfiguration: {
          isHidden: false,
          optionType: "component",
        },
      },
    } as BaseGridProperties;
  };

  const { manuallyReloadParameters } = useBaseGrid(
    statementsBatchTableConfig()
  );

  return <BaseGrid name={tableName} />;
};

export default StatementsBatchTableWrapper;
