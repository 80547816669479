import { IconButton } from "@mui/material";
import { FC } from "react";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { BaseTable2, TitleBold } from "../../TrueUI";
import EditIcon from "@mui/icons-material/Edit";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { addAllOption } from "../../../utilities/selectFunctions";
import { StatusEnums } from "../../../dtos/status-enums";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { PortalUserInformation, RefreshAccessRightsTable } from "./UsersAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";

type AccessRightsSectionProps = {
  userId?: number | null;
};
const tableName = "portal-user-access-rights-table";
const AccessRightsSection: FC<AccessRightsSectionProps> = ({ userId }) => {
  const setUserInfoToPermissions = useSetRecoilState(PortalUserInformation);

  const refreshTable = useRecoilValue(RefreshAccessRightsTable);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() =>
            setUserInfoToPermissions({
              insuredName: actionOptions?.row?.InsuredName,
              programName: actionOptions?.row?.Program,
              programUserId: actionOptions?.row?.ProgramUserId,
              insuredUserId: actionOptions?.row?.InsuredUserId,
            })
          }
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const statusOptions = addAllOption(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName:
          enumType?.value == StatusEnums.ACTIVE
            ? "Active Only"
            : enumType?.key ?? "",
        intValue: enumType?.value ?? "",
      })
    ),
    false,
    "Both"
  );

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PortalUser/GetPortalUserAccessRightsTable?userId=${userId}&refresh=${refreshTable}`,
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    filterOptions: [
      (actionOptions) => {
        return BaseTableDropdownFilter({
          id: "include-filter",
          filterOptions: actionOptions,
          columnsNames: ["InsuredUserStatus"],
          optionsOverride: statusOptions,
          defaultValue: StatusEnums.ACTIVE,
          labelText: "Include:",
        });
      },
    ],
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showEditButton: false,
      addButtonText: "ADD",
      addButtonIsDisabled: true,
      showExcelButton: false,
      showPDFButton: false,
    },
    columnOptions: [
      { fieldName: "Program", width: 15 },
      { fieldName: "Admin", width: 10 },
      { fieldName: "Billing", width: 10 },
      { fieldName: "Claims", width: 10 },
      { fieldName: "Policy", width: 10 },
      { fieldName: "LossControl", width: 10 },
      { fieldName: "InsuredName", width: 30 },
      { fieldName: "OPTIONS", width: 5 },
    ],
    events: {
      addEventOverrideCallback: () => console.log("Adding Button"),
    },
  };

  useBaseTable(tableConfiguration);

  return conditionHasValue(userId) ? (
    <div style={{ width: "70%", marginBottom: "10px" }}>
      <div
        style={{ paddingLeft: "5px", marginBottom: "10px", marginTop: "10px" }}
      >
        <TitleBold display="block" textAlign="start">
          ACCESS RIGHTS
        </TitleBold>
      </div>
      <div style={{ height: "365px" }}>
        <BaseTable2 name={tableName} />
      </div>
    </div>
  ) : null;
};
export default AccessRightsSection;
