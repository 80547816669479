import { FC, useEffect, useState } from "react";
import {
  addQueryStrings,
  removeQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { PAYMENT_BATCH_ID } from "../../../../utilities/queryStringsHash";
import { getCastedQueryValue } from "../../../../utilities/queryStringsHashFunctions";
import { BaseTable2, Col, FontBold, Heading6, Row } from "../../../TrueUI";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSearchFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { currencyFormat } from "../../../../utilities/currencyFunctions";
import { sumFloat } from "../../../../utilities/arrayFunctions";
import "./PaymentBatch.module.css";
import PrintBatch from "./PrintBatch";
import { usePermissions } from "../../../../hooks";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import PrintBatchPayment from "./PrintBatchPayment";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

const tableName = "batch_history_details";

const getAmountSum = (rows) =>
  sumFloat(rows?.map((row) => row?.PaymentAmount ?? 0));

const PaymentBatchDetail: FC = () => {
  const [dispatchPrintPaymentBatch, setDispatchPrintPaymentBatch] =
    useState<boolean>(false);
  const [paymentIds, setPaymentIds] = useState<string | null>(null);
  const [
    dispatchPrintPaymentBatchPayment,
    setDispatchPrintPaymentBatchPayment,
  ] = useState<boolean>(false);
  const paymentBatchId = getCastedQueryValue(PAYMENT_BATCH_ID);
  const hasPermission = usePermissions([
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]).hasPermission;

  useEffect(() => {
    addQueryStrings([
      {
        nameOfHash: PAYMENT_BATCH_ID,
        valueOfHash: paymentBatchId,
      },
    ]);
    return () => {
      removeQueryStrings([PAYMENT_BATCH_ID]);
    };
  }, []);

  useEffect(() => {
    if (conditionHasValue(paymentIds)) {
      setDispatchPrintPaymentBatchPayment(true);
    }
  }, [paymentIds]);

  useEffect(() => {
    if (dispatchPrintPaymentBatchPayment === false) {
      setPaymentIds(null);
    }
  }, [dispatchPrintPaymentBatchPayment]);

  const CustomFooterComponent = (rows) => {
    return (
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={29} rowHeight="50px">
        <Col breakpoints={{ md: 22, lg: 22, xl: 22 }}></Col>
        <Col
          horizontalMargin="20px"
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          horizontalAlign="flex-end"
          verticalAlign="center"
        >
          <FontBold>{currencyFormat(getAmountSum(rows), false, 2)}</FontBold>
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}></Col>
      </Row>
    );
  };
  const printItemLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setPaymentIds(rowData.GroupOfPayments);
        }}
      >
        <PrintIcon fontSize="small" />
      </IconButton>
    );
  };

  const configuration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PaymentBatchPayment/GetBatchPayments?batchID=${paymentBatchId}`,
    toolbarOptions: {
      showImportButton: false,
      showAddButton: hasPermission,
      showEditButton: false,
      showSortFilter: false,
      addButtonText: "Print Batch",
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableSearchFilter(actionOptions, ["ReferenceNo"], ["check no."]),
    ],
    columnOptions: [
      {
        fieldName: "PaymentAmount",
        align: "right",
      },
    ],
    footerOptions: {
      CustomFooterComponent: (rows) => CustomFooterComponent(rows),
    },
    events: {
      addEventOverrideCallback: () => setDispatchPrintPaymentBatch(true),
    },
    advancedOptions: {
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          printItemLink?.(actionOptions.row) ?? null,
      },
    },
  };

  useBaseTable(configuration);
  return (
    <>
      <Heading6 textAlign="start" display="block">
        BATCH HISTORY DETAIL #{paymentBatchId}
      </Heading6>
      <BaseTable2 name={tableName} />
      <PrintBatch
        batchId={paymentBatchId}
        dispatchPrint={dispatchPrintPaymentBatch}
        setDispatchPrint={setDispatchPrintPaymentBatch}
      />
      <PrintBatchPayment
        paymentIds={paymentIds}
        batchId={paymentBatchId}
        dispatchPrint={dispatchPrintPaymentBatchPayment}
        setDispatchPrint={setDispatchPrintPaymentBatchPayment}
      />
    </>
  );
};

export default PaymentBatchDetail;
