import { FC } from "react";
import {
  Input,
  InputDate,
  InputDateTime,
  InputPhone,
  InputTaxAutoformat,
  InputTime,
  Select,
  Switch,
  MultiSwitch,
} from "../../../../../TrueUI";
import { ClaimIncidentDto } from "../../../../../../dtos/claim-incident-dto";
import {
  toCamelCase,
  toCamelCaseWhenAllCaps,
} from "../../../../../../utilities/stringFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { getStates } from "../../../../../../utilities/staticDataFunctions";
import InputMemo from "../../../../../TrueUI/Inputs/InputMemo";
import { IncidentStateReportFieldDto } from "../../../../../../dtos/incident-state-report-field-dto";
import { addEmptyOption } from "../../../../../../utilities/selectFunctions";
import { KeysAsType } from "../../../../../../types/KeysAsAType";
import { FontsType } from "../../../../../../media/themeTypes";
import {
  claimIncidentStateProps,
  updateMultipleValues,
  updateValue,
} from "../AddNewIncidentUtils";
import IncidentBodyPartsFieldWrapper from "./BodyPartsComponent/IncidentBodyPartsFieldWrapper";
import "./IncidentFieldSelector.module.css";

type IncidentFieldProperties = {
  idx: number;
  section: string;
  field: IncidentStateReportFieldDto | undefined;
  errorDetails?: any;
  claimIncident?: claimIncidentStateProps;
  setClaimIncident?: any;
  textFieldVariant?: "filled" | "standard";
  labelsType?: KeysAsType<FontsType>;
  stateReportOptions?: { [key: string]: SelectOptions[] };
  readOnly?: boolean;
};

export const getValue = (
  field: string,
  claimIncident: ClaimIncidentDto | undefined | null,
  fieldType?: string
) => {
  const value = claimIncident?.incident?.[field];
  const valueTrueCamelCase =
    claimIncident?.incident?.[toCamelCaseWhenAllCaps(field)];
  switch (fieldType) {
    case "currency":
      return value ?? 0;
    case "checkbox":
      return value ?? false;
    case "multiselect":
      return stringOptionsToArray(value);
    default:
      return value ?? valueTrueCamelCase ?? "";
  }
};

const optionsToString = (options: Partial<SelectOptions>[]) => {
  const stringOptions = options
    .map((item) => (item.booleanValue ? item.intValue : null))
    .filter((i) => i);
  return stringOptions.toString();
};

const stringOptionsToArray = (stringOptions: string) => {
  const stringArray =
    stringOptions && stringOptions != null ? stringOptions.split(",") : [];
  const numberArray = stringArray?.map((n) => Number(n));
  return numberArray;
};

export const IncidentFieldSelector: FC<IncidentFieldProperties> = ({
  idx,
  field,
  section,
  errorDetails,
  claimIncident,
  setClaimIncident,
  textFieldVariant,
  labelsType,
  stateReportOptions,
  readOnly,
}) => {
  const states: Partial<SelectOptions>[] = getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

  const getWidthDependingType = (type: string) => {
    const halfWidthInputs = [
      "number",
      "currency",
      "shorttext",
      "postalcode",
      "state",
      "phone",
      "date",
      "datetime",
      "time",
      "natureofinjury",
      "causeofinjury",
      "dropdown",
      "multiselect",
      "memo",
      "checkbox",
      "classcode",
    ];

    if (halfWidthInputs.includes(type)) {
      return window.innerWidth < 1268 ? "30%" : "25%";
    }
    return window.innerWidth < 1268 ? "100%" : "50%";
  };

  const addCorrectOptionsToDropdown = () => {
    if (!field?.isRequired) {
      return addEmptyOption(stateReportOptions?.[field?.fieldName ?? 0] ?? []);
    }
    return stateReportOptions?.[field.fieldName] ?? [];
  };

  const incidentInformation = claimIncident?.claimIncident;

  switch (field?.fieldType) {
    case "ssn":
      return (
        <InputTaxAutoformat
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-ssn-${idx}`}
          label={field.fieldLabel}
          variant={textFieldVariant}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChange={(value) => {
            updateMultipleValues(
              [
                toCamelCase(field.fieldName),
                `${toCamelCase(field.fieldName)}Type`,
              ],
              [value, "SSN"],
              setClaimIncident,
              claimIncident
            );
          }}
          inputWidth={getWidthDependingType(field?.fieldType)}
          errorMessage={
            errorDetails?.[toCamelCase(field.fieldName)] ??
            errorDetails?.[`${toCamelCase(field.fieldName)}Type`]
          }
          helperText={field.helpText ?? " "}
          labelFontType={labelsType}
          taxIdType="SSN"
        />
      );
    case "currency":
    case "email":
    case "text":
    case "number":
    case "shorttext":
      return (
        <Input
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          type={field.fieldType === "shorttext" ? "text" : field.fieldType}
          label={field.fieldLabel}
          variant={textFieldVariant}
          value={getValue(
            toCamelCase(field.fieldName),
            incidentInformation,
            field.fieldType
          )}
          inputWidth={getWidthDependingType(field?.fieldType)}
          maxLength={field.maxLength}
          onChangeRawValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          labelFontType={labelsType}
        />
      );
    case "postalcode":
      return (
        <Input
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          type="number"
          label={field.fieldLabel}
          variant={textFieldVariant}
          value={getValue(
            toCamelCase(field.fieldName),
            incidentInformation,
            field.fieldType
          )}
          inputWidth={getWidthDependingType(field?.fieldType)}
          maxLength={field.maxLength}
          thousandSeparator={false}
          prefix=""
          onChangeRawValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value.toString(),
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          labelFontType={labelsType}
        />
      );
    case "state":
      return (
        <Select
          id={`state-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          options={states}
          label={field.fieldLabel}
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          variant={textFieldVariant}
          firstOptionAsDefault={field?.isRequired}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChange={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          helperText={field.helpText}
          inputWidth={"25%"}
          optionsMaxHeight="180px"
          labelFontType={labelsType}
        />
      );
    case "phone":
      return (
        <InputPhone
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          inputWidth={getWidthDependingType(field?.fieldType)}
          key={`input-${field.fieldType}-${idx}`}
          phoneLabel={field.fieldLabel}
          variant={textFieldVariant}
          phoneValue={getValue(
            toCamelCase(field.fieldName),
            incidentInformation
          )}
          maxLength={300}
          onChangePhoneValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessagePhone={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          labelFontType={labelsType}
        />
      );
    case "date":
      return (
        <InputDate
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          variant={textFieldVariant}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChangeRawValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          inputWidth={getWidthDependingType(field?.fieldType)}
          labelFontType={labelsType}
        />
      );
    case "datetime":
      return (
        <InputDateTime
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          variant={textFieldVariant}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChangeRawValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          inputWidth={getWidthDependingType(field?.fieldType)}
          labelFontType={labelsType}
        />
      );
    case "time":
      return (
        <InputTime
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          variant={textFieldVariant}
          defaultValue={getValue(
            toCamelCase(field.fieldName),
            incidentInformation
          )}
          onChange={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          inputWidth={getWidthDependingType(field?.fieldType)}
        />
      );
    case "natureofinjury":
    case "causeofinjury":
      return (
        <Select
          id={`injury-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`select-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          options={stateReportOptions?.[field.fieldType] ?? []}
          variant={textFieldVariant}
          optionsMaxHeight="190px"
          value={getValue(
            toCamelCase(field.fieldName),
            incidentInformation,
            field.fieldType
          )}
          onChange={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText}
          inputWidth={getWidthDependingType(field?.fieldType)}
          labelFontType={labelsType}
        />
      );
    case "dropdown":
    case "classcode":
      return (
        <Select
          id={`select-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`select-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          options={addCorrectOptionsToDropdown()}
          variant={textFieldVariant}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChange={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText}
          inputWidth={getWidthDependingType(field?.fieldType)}
          firstOptionAsDefault={field?.isRequired}
          labelFontType={labelsType}
        />
      );
    case "checkbox":
      return (
        <Switch
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          className="checkbox_without_left_padding"
          key={`input-${field.fieldType}-${idx}`}
          spaceBetweenLabelAndControl
          label={field.fieldLabel}
          labelPlacement="top"
          isChecked={getValue(
            toCamelCase(field.fieldName),
            incidentInformation,
            "checkbox"
          )}
          control={"checkbox"}
          onChangeIsChecked={(value) => {
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            );
          }}
          labelStyle={{ marginLeft: 0, alignItems: "flex-start" }}
          labelFontType="BOLD_CAPTION"
          helperText={field.helpText ?? ""}
          inputWidth={getWidthDependingType("none")}
        />
      );
    case "memo":
      return (
        <InputMemo
          id={`id-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          name={`name-${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          readOnly={readOnly}
          key={`input-${field.fieldType}-${idx}`}
          label={field.fieldLabel}
          variant={textFieldVariant}
          maxLength={field.maxLength}
          rows={4}
          value={getValue(toCamelCase(field.fieldName), incidentInformation)}
          onChangeRawValue={(value) =>
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            )
          }
          errorMessage={errorDetails?.[toCamelCase(field.fieldName)]}
          helperText={field.helpText ?? " "}
          inputWidth={getWidthDependingType(field?.fieldType)}
          labelFontType={labelsType}
        />
      );
    case "multiselect":
      return (
        <MultiSwitch
          name={`${toCamelCase(section)}-${toCamelCase(field.fieldName)}`}
          value={getValue(
            toCamelCase(field.fieldName),
            incidentInformation,
            field.fieldType
          )}
          readOnly={readOnly}
          selectOptions={stateReportOptions?.[field.fieldName] ?? []}
          title={field.fieldLabel}
          key={`input-${field.fieldType}-${idx}`}
          keyPrefix={`input-${field.fieldType}-${idx}`}
          spaceBetweenLabelAndControls
          control={"checkbox"}
          helperText={field.helpText ?? ""}
          onChangeRawValue={(value) => {
            updateValue(
              toCamelCase(field.fieldName),
              optionsToString(value),
              setClaimIncident,
              claimIncident
            );
          }}
          inputWidth={getWidthDependingType("none")}
        />
      );
    case "bodypart":
      return (
        <IncidentBodyPartsFieldWrapper
          label={field.fieldLabel}
          errorDetails={errorDetails}
          readOnly={readOnly}
          claimBodyParts={claimIncident?.claimIncident?.incident?.bodyParts}
          onChangeValue={(value) => {
            updateValue(
              toCamelCase(field.fieldName),
              value,
              setClaimIncident,
              claimIncident
            );
          }}
        />
      );
    default:
      return <>{field?.fieldType}</>;
  }
};
