import { FC, useEffect, useState } from "react";
import { Button } from "../../../TrueUI";
import CancelButton from "../../../TrueUI/Buttons/CancelButton";
import "./userFormStyles.module.css";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { useNavigate } from "react-router";

type UserCommandProps = {
  userId?: number | null;
};

const buttonProps = {
  variantStyle: "outlined",
  sx: { margin: "10px" },
  width: "150px",
};

type DialogProps = {
  isOpen?: boolean;
  dialogTxt?: string;
};

const defaultTxt = `This user's status has been changed to "Inactive". They will not be able to log into the application again.  If they are currently in the system, they will not be automatically kicked off until their session expires or they leave the site.`;
const UserCommand: FC<UserCommandProps> = ({ userId }) => {
  const navigate = useNavigate();

  const [dialogProps, setDialogProps] = useState<DialogProps>({
    isOpen: false,
    dialogTxt: defaultTxt,
  });

  const { responsePost, dispatchPost } = useApiPost(
    `api/AdminTools/Security/UserListing/LockAccount?userId=${userId}`
  );

  const { responsePost: responseReset, dispatchPost: dispatchReset } =
    useApiPost(
      `api/AdminTools/Security/UserListing/ResetUserPassword?userId=${userId}`
    );

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setDialogProps({ isOpen: true, dialogTxt: defaultTxt });
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseReset)) {
      setDialogProps({ isOpen: true, dialogTxt: "Password reset email sent" });
    }
  }, [responseReset]);

  return (
    <div className={"command_section_container"}>
      <div>
        <Button
          {...(buttonProps as any)}
          onClick={() => dispatchPost()}
          isDisabled={userId === 0}
        >
          LOCK ACCOUNT
        </Button>
        <Button
          {...(buttonProps as any)}
          onClick={() => dispatchReset()}
          isDisabled={userId === 0}
        >
          RESET PASSWORD
        </Button>
      </div>
      <CancelButton
        textOnButton="Close"
        withConfirmation
        onClick={() => navigate("/admin-tools/security")}
      />
      <DialogConfirmation
        open={dialogProps?.isOpen}
        dialogDescriptionText={dialogProps?.dialogTxt}
        onOptionYesEvent={() => {
          setDialogProps({
            ...dialogProps,
            isOpen: false,
          });
          navigate("/admin-tools/security");
        }}
        optionYesOverrideLabel="Close"
      />
    </div>
  );
};

export default UserCommand;
