import { Alert } from "@mui/material";
import { FC } from "react";
type BaseGridRegistrationGateMountErrorProperties = {
  name: string;
};

//// TODO - needs to have it styles moved to a style sheet at some point. ////
const BaseGridRegistrationGateMountError: FC<
  BaseGridRegistrationGateMountErrorProperties
> = ({ name }) => {
  return (
    <div style={{ marginTop: "5px" }}>
      <Alert severity="error">
        <div>
          The grid named "
          <span style={{ fontWeight: "bold", color: "#E51A5F" }}>
            {name ?? "NO_GRID_NAME_FOUND"}
          </span>
          " was unmounted using the{" "}
          <span style={{ fontWeight: "bold", color: "#569cd6" }}>const</span>{" "}
          <span style={{ fontWeight: "bold", color: "#BF6374" }}>{"{ "}</span>
          <span style={{ fontWeight: "bold", color: "#D9AA52" }}>
            unmountGrid
          </span>
          <span style={{ fontWeight: "bold", color: "#BF6374" }}>{" }"}</span> ={" "}
          <span style={{ fontWeight: "bold", color: "#569cd6" }}>
            useBaseGrid(...)
          </span>{" "}
          function but was never remounted using the{" "}
          <span style={{ fontWeight: "bold", color: "#569cd6" }}>const</span>{" "}
          <span style={{ fontWeight: "bold", color: "#BF6374" }}>{"{ "}</span>
          <span style={{ fontWeight: "bold", color: "#D9AA52" }}>
            mountGrid
          </span>
          <span style={{ fontWeight: "bold", color: "#BF6374" }}>{" }"}</span> ={" "}
          <span style={{ fontWeight: "bold", color: "#569cd6" }}>
            useBaseGrid(...)
          </span>{" "}
          function.
        </div>
        <br />
        <div>
          This occurs when a parent component is not destoryed and the existing
          grid within that element persists.
        </div>
        <br />
        <div>
          {" "}
          You can can use the [{" "}
          <span style={{ fontWeight: "bold", color: "#D9AA52" }}>
            mountGrid
          </span>{" "}
          ] function to rebuild the grid instance{" "}
          <span style={{ fontWeight: "bold" }}>or</span> destory the parent
          component to rerender the grid.
        </div>
      </Alert>
    </div>
  );
};

export default BaseGridRegistrationGateMountError;
