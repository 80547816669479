import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { TasksCardConfigDto } from "../../../dtos/tasks-card-config-dto";
import AssignedTasksTable from "./AssignedTasksTable";
import { useRecoilValue } from "recoil";
import { TaskBoardItemsAtom } from "../../TrueUI/TaskBoard/TaskBoardAtoms";

type AssignedTasksTableWrapperProps = {
  filterType: number;
  cardId: string;
};

const AssignedTasksTableWrapper: FC<AssignedTasksTableWrapperProps> = ({
  filterType,
  cardId,
}) => {
  const taskItemsAtom = useRecoilValue(TaskBoardItemsAtom);
  const [tasksConfig, setTasksConfig] = useState<TasksCardConfigDto | null>(
    null
  );

  const { responseGet, dispatchGet } = useApiGet<TasksCardConfigDto>(
    "api/Dashboard/GetTasksCardConfiguration"
  );

  useEffect(() => {
    setTasksConfig(null);
  }, [filterType]);

  useEffect(() => {
    if (tasksConfig === null) {
      dispatchGet();
    }
  }, [tasksConfig]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTasksConfig(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  return (
    <div id={"assigned_tasks_container"} style={{ height: "100%" }}>
      {tasksConfig !== null && (
        <AssignedTasksTable
          key={taskItemsAtom[cardId]}
          tasksConfig={tasksConfig}
          filterType={filterType}
          cardId={cardId}
        />
      )}
    </div>
  );
};

export default AssignedTasksTableWrapper;
