import { InstanceRequestConfigurationOptions } from "../hooks/useApi";

export const isAPITotallyComplete = (
  responseAPI: InstanceRequestConfigurationOptions
) => {
  return (
    responseAPI.requestInstanceSuccessful &&
    responseAPI?.axiosResponse?.data &&
    !responseAPI?.isLoading
  );
};


export const isAPITotallyCompleteNoContentResponse = (
  responseAPI: InstanceRequestConfigurationOptions
) => {
  return (
    responseAPI.requestInstanceSuccessful &&
    !responseAPI?.isLoading
  );
};
