import { CertificateDto } from "../../../../../dtos/certificate-dto";
import { CertificateStatusEnums } from "../../../../../dtos/certificate-status-enums";

export type CertificateModalMode = "ADD" | "EDIT" | "REISSUE";

export type IssueCertificateModalConfig = {
  isOpen: boolean;
  insuredId?: number | null;
  editCertificateId?: number | null;
  mode?: CertificateModalMode;
};

export type IssueCertificateModalProps = {
  configuration: IssueCertificateModalConfig;
  closeModal: (close: boolean) => void;
  onSaveSuccessful?: (newData?: CertificateDto | null) => void;
  onDeleteSuccessful?: () => void;
  onReissue?: () => void;
};

export const defaultCertificateData = (insuredId: number): CertificateDto => ({
  certificateId: 0,
  insuredId,
  insuredCopyOnly: false,
  certHolder: "",
  certDba: "",
  certAddress1: "",
  certAddress2: "",
  certCity: "",
  certState: "",
  certPostalCode: "",
  certPhone: "",
  certFax: "",
  certAttention: "",
  certEmail: "",
  jobName: "",
  comments: "",
  issueAtRenewal: false,
  auditSource: "Internal",
  issuedBy: "",
  issueDate: new Date(),
  certificateStatus: CertificateStatusEnums.ISSUED,
});
