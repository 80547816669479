import { FC, useState } from "react";
import { Collapse, Font } from "../../TrueUI";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import RelatedInsuredTableWrapper from "./RelatedInsuredTableWrapper";
import "./RelatedInsuredsStyles.module.css";
import ModalRelatedInsuredWrapper from "./ModalRelatedIsnured/ModalRelatedInsuredWrapper";
import {
  RelatedInsuredsWrapperProps,
  modalConfigProps,
} from "./RelatedInsuredTypes";
import { useEffect } from "react";
import { INSURED_ATOM_KEY } from "../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";

const RelatedInsuredsWrapper: FC<RelatedInsuredsWrapperProps> = ({
  insuredId,
  tabKey,
}) => {
  const [tableHasInsureds, setTableHasInsured] = useState<boolean>(true);
  const [modalConfig, setModalConfig] = useState<modalConfigProps>({
    isModalOpen: false,
    refreshRelatedTable: false,
    relatedInsuredCollapseTableKey: crypto.randomUUID(),
  });
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    if (modalConfig.refreshRelatedTable === true) {
      setModalConfig({
        ...modalConfig,
        relatedInsuredCollapseTableKey: crypto.randomUUID(),
      });
      setTableHasInsured(true);
      setComponentTriggers(["activityLogComponent"]);
    }
  }, [modalConfig.refreshRelatedTable]);

  return (
    <div
      className={`insured_related_insureds ${
        tableHasInsureds === false && "hide_collapse_icon"
      }`}
    >
      <Collapse
        title={
          <>
            <Font primaryColor>Relationships</Font>
            <IconButton
              className="plus-minus-icon-container"
              onClick={(e) => {
                setModalConfig({ ...modalConfig, isModalOpen: true });
                e.stopPropagation();
              }}
            >
              <AddOutlined className="plus-action-button" />
            </IconButton>
          </>
        }
        isHeaderCompact
        expanded={false}
        content={
          <>
            {tableHasInsureds === true && (
              <RelatedInsuredTableWrapper
                insuredId={insuredId}
                setTableHasInsured={setTableHasInsured}
                key={modalConfig?.relatedInsuredCollapseTableKey}
              />
            )}
          </>
        }
      />
      <ModalRelatedInsuredWrapper
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        insuredId={insuredId}
      />
    </div>
  );
};

export default RelatedInsuredsWrapper;
