import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { SelectOptions } from "../../../../../dtos/select-options";
import {
  filterSelectOptionsByEnumValues,
  formattingKey,
} from "../../../../../utilities/enumFunctions";

export const statusToHideDeleteIncidentAsKeyString = [
  formattingKey(IncidentStatusEnums[IncidentStatusEnums.IN_PROGRESS]),
  formattingKey(IncidentStatusEnums[IncidentStatusEnums.UNDER_REVIEW]),
];

export const statusToHideAssignedToClaimNoAndReason = [
  IncidentStatusEnums.SUBMITTED,
  IncidentStatusEnums.IN_PROGRESS,
  IncidentStatusEnums.UNDER_REVIEW,
];

export const statusToHideAssignedToAndClaimNo = [
  IncidentStatusEnums.REPORT_ONLY,
  IncidentStatusEnums.FIRST_AID,
  IncidentStatusEnums.DENIED,
  IncidentStatusEnums.DELETED,
];

export const statusToHideReason = [IncidentStatusEnums.CLAIM_CREATED];

export const fromStatusesToOptionsToToggle = (
  statusToHide: number[],
  incidentStatuses?: Partial<SelectOptions>[]
) =>
  filterSelectOptionsByEnumValues(incidentStatuses ?? [], statusToHide).map(
    (option) => {
      return option.stringValue;
    }
  ) as string[];
