import { SelectOptions } from "../../../../../../../dtos/select-options";

import { ClaimReferenceDto } from "../../../../../../../dtos/claim-reference-dto";
import { ClaimContactDto } from "../../../../../../../dtos/claim-contact-dto";
import { FormStateProps } from "../../ClaimLitigationComponents/typesAndConstants";
import { FormattingDate } from "../../../../../../../utilities/dateFunctions";
import { StatusEnums } from "../../../../../../../dtos/status-enums";

export type ClaimModalReferenceSelectOptions = {
  statuses: Partial<SelectOptions>[] | [];
  types: Partial<SelectOptions>[] | [];
  phoneTypes: Partial<SelectOptions>[] | [];
};

export const getContactTypeFromTag = (type) => {
  if (type === "") return -1;
  const tagInfo = JSON.parse(type);
  return tagInfo[0]?.Id ?? null;
};

const getDateOfBirth = (dateOfBirth) => {
  if (dateOfBirth === null) return dateOfBirth;
  return typeof dateOfBirth === "string" && dateOfBirth !== ""
    ? new Date(dateOfBirth)
    : dateOfBirth;
};

export const getDtoDataFromRowData = (rowData) => {
  return {
    ...rowData,
    contactType: getContactTypeFromTag(rowData?.contactType ?? "") ?? 1,
    contactStatus:
      typeof rowData?.contactStatus === "string"
        ? parseInt(rowData?.contactStatus ?? "1")
        : rowData?.contactStatus,
    dateOfBirth: getDateOfBirth(rowData?.dateOfBirth),
  };
};

export const getStringValuesFromPostResponse = (contact) => {
  Object.keys(contact).forEach((key) => {
    switch (typeof contact[key]) {
      case "number":
        return (contact[key] = contact[key].toString());
      default:
        if (contact[key] instanceof Date) {
          return (contact[key] = FormattingDate(contact[key]));
        }
        return ""; // Needs review, flawed on inception.
    }
  });
  contact.contactType = `[{"Id":${contact?.contactType}}]`;
  return contact;
};

export const defaultClaimContactData = {
  claimContactId: 0,
  contactName: "",
  relationshipToClaimant: "",
  assistantName: "",
  company: "",
  contactStatus: -1,
  contactType: -1,
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  phone1: "",
  phone1Type: "",
  phone2: "",
  phone2Type: "",
  fax: "",
  emailAddress: "",
  dateOfBirth: null,
  saveRepeated: false,
};
export type ModalConfig = {
  edit: boolean;
  add: boolean;
  title?: string;
};
export type ContactModalFormProps = {
  modalConfiguration: ModalConfig;
  closeModal: (isOpen: boolean) => void;
  contactFormReferences: ClaimModalReferenceSelectOptions;
  claimContactData: ClaimContactDto | Partial<ClaimContactDto>;
  claimId?: number;
  saveRowToTable?: (
    newRowData: ClaimContactDto | Partial<ClaimContactDto> | null,
    action: "add" | "update" | "delete" | null
  ) => void;
};

export type ContactFormProps = {
  contactFormReferences: ClaimReferenceDto[] | [];
  claimContactData: ClaimContactDto | Partial<ClaimContactDto>;
  claimId?: number;
  formState: FormStateProps | null | undefined;
  setFormState: (formState: FormStateProps) => void;
};

export const removeDeletedItem = (selectOptions: any) =>
  selectOptions.filter((option) => option?.intValue !== StatusEnums.DELETED);

export const allOption: SelectOptions[] = [
  {
    displayName: "All",
    stringValue: "all",
    intValue: null,
    dateValue: null,
    decimalValue: null,
    booleanValue: null,
  },
];
