import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost, usePermissions } from "../../hooks";
import { AgencyContactModalDto } from "../../dtos/agency-contact-modal-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../utilities/apiFunctions";
import { Button, Col, Input, InputDate, Row, Select } from "../TrueUI";
import { rowWithNoMarginNorGutter } from "../TrueUI/Grids/Row";
import { AgencyContactModalConfiguration } from "./AgencyContacts";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { AgencyContactConfiguration } from "../../dtos/agency-contact-configuration";
import { AgencyContactDataDto } from "../../dtos/agency-contact-data-dto";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import { PermissionsEnums } from "../../dtos/permissions-enums";

type AgencyContactFormProps = {
  agencyId?: string;
  contactModalConfiguration: AgencyContactModalConfiguration;
  setContactModalConfiguration?: (
    value: AgencyContactModalConfiguration
  ) => void;
};

type DialogConfigurationProps = {
  isOpen: boolean;
  type: "cancel" | "delete";
  message: string;
  yesButtonText: "Yes" | "OK";
  noButtonText: "No" | "Cancel";
};

const defaultDialogConfiguration: DialogConfigurationProps = {
  isOpen: false,
  type: "cancel",
  message: "",
  yesButtonText: "Yes",
  noButtonText: "No",
};

const AgencyContactForm: FC<AgencyContactFormProps> = ({
  agencyId,
  contactModalConfiguration,
  setContactModalConfiguration,
}) => {
  const [contactConfig, setContactConfig] =
    useState<AgencyContactConfiguration | null>();

  const hasPermission = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
  ]).hasPermission;

  const [contactData, setContactData] = useState<AgencyContactDataDto>();
  const [errorMessages, setErrorMessages] = useState<any>();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfigurationProps>(defaultDialogConfiguration);

  const {
    responseGet: responseGetConfiguration,
    dispatchGet: dispatchGetConfiguration,
  } = useApiGet<AgencyContactModalDto>(
    `api/agencyContact/GetAgencyContactConfiguration?agencyId=${agencyId}&agencyContactId=${contactModalConfiguration.agencyContactId}`
  );

  const {
    responsePost: responsePostSaveAgency,
    dispatchPost: dispatchPostSaveAgency,
  } = useApiPost(`api/agencyContact/SaveAgencyContact`, contactData);

  const {
    responsePost: responsePostDeleteAgency,
    dispatchPost: dispatchPostDeleteAgency,
  } = useApiPost(
    `api/agencyContact/DeleteAgencyContact?agencyContactId=${contactData?.agencyContactId}`
  );

  useEffect(() => {
    dispatchGetConfiguration();
  }, []);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePostSaveAgency)) {
      setContactModalConfiguration?.({
        ...contactModalConfiguration,
        isOpen: false,
        refreshTable: true,
      });
    }
    if (conditionHasValue(responsePostSaveAgency.errorResponse?.errorDetails)) {
      setErrorMessages(responsePostSaveAgency.errorResponse?.errorDetails);
    }
  }, [responsePostSaveAgency]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePostDeleteAgency)) {
      setContactModalConfiguration?.({
        ...contactModalConfiguration,
        isOpen: false,
        refreshTable: true,
      });
    }
  }, [responsePostDeleteAgency]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetConfiguration)) {
      setContactConfig(responseGetConfiguration?.responseData?.configuration);
      setContactData(responseGetConfiguration?.responseData?.contactData);
    }
  }, [responseGetConfiguration]);

  const updateValue = (field: string, value: any) => {
    setContactData({
      ...(contactData as AgencyContactDataDto),
      [field]: value,
    });
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Input
            focus
            tabIndex={34}
            id="id-last-name"
            name="last-name"
            label="Last Name"
            type="text"
            value={contactData?.lastName ?? ""}
            errorMessage={errorMessages?.lastName}
            maxLength={100}
            onChangeRawValue={(value) => updateValue("lastName", value)}
          />
        </Col>
        <Col>
          <Input
            tabIndex={35}
            id="id-first-name"
            name="first-name"
            label="First Name"
            type="text"
            value={contactData?.firstName ?? ""}
            errorMessage={errorMessages?.firstName}
            maxLength={100}
            onChangeRawValue={(value) => updateValue("firstName", value)}
          />
        </Col>
        <Col>
          <Select
            tabIndex={36}
            id="id-locations"
            name="locations"
            label="Location"
            labelFontType={"BOLD_CAPTION"}
            options={contactConfig?.agencyLocationOptions ?? []}
            value={contactData?.locationId}
            errorMessage={errorMessages?.locationId}
            onChange={(value) => updateValue("locationId", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            tabIndex={37}
            id="id-title"
            name="title"
            label="Title"
            type="text"
            value={contactData?.title ?? ""}
            errorMessage={errorMessages?.title}
            maxLength={50}
            onChangeRawValue={(value) => updateValue("title", value)}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            tabIndex={38}
            id="id-type"
            name="type"
            label="Contact Type"
            labelFontType={"BOLD_CAPTION"}
            options={contactConfig?.contactTypeOptions ?? []}
            value={contactData?.contactType}
            firstOptionAsDefault={false}
            errorMessage={errorMessages?.contactType}
            onChange={(value) => updateValue("contactType", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            tabIndex={39}
            id="id-email"
            name="email"
            label="Email"
            type="email"
            value={contactData?.email ?? ""}
            errorMessage={errorMessages?.email}
            maxLength={100}
            onChangeRawValue={(value) => updateValue("email", value)}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            tabIndex={40}
            id="id-status"
            name="status"
            label="Status"
            labelFontType={"BOLD_CAPTION"}
            options={contactConfig?.statusOptions ?? []}
            value={contactData?.status}
            errorMessage={errorMessages?.status}
            onChange={(value) => updateValue("status", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={41}
            id="id-phone-number"
            name="phone-number"
            label="Phone Number"
            type="internalPhone"
            value={contactData?.phoneNumber ?? ""}
            errorMessage={errorMessages?.phoneNumber}
            maxLength={100}
            onChangeRawValue={(value) => updateValue("phoneNumber", value)}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={42}
            id="id-alternate-phone-number"
            name="alternate-phone-number"
            label="Alternate Phone Number"
            type="internalPhone"
            value={contactData?.alternatePhoneNumber ?? ""}
            errorMessage={errorMessages?.alternatePhoneNumber}
            maxLength={100}
            onChangeRawValue={(value) =>
              updateValue("alternatePhoneNumber", value)
            }
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={43}
            id="id-effective-date"
            name="name-effective-date"
            label="Effective Date"
            value={contactData?.effectiveDate}
            errorMessage={errorMessages?.effectiveDate}
            onChangeRawValue={(value) => updateValue("effectiveDate", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="space-between">
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={44}
            id="id-license-number"
            name="license-number"
            label="License Number"
            type="text"
            value={contactData?.licenseNumber ?? ""}
            errorMessage={errorMessages?.licenseNumber}
            maxLength={50}
            onChangeRawValue={(value) => updateValue("licenseNumber", value)}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={45}
            id="id-expiration-date"
            name="name-expiration-date"
            label="Expiration Date"
            value={contactData?.expirationDate}
            errorMessage={errorMessages?.expirationDate}
            onChangeRawValue={(value) => updateValue("expirationDate", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={46}
            id="id-npn-number"
            name="npn-number"
            label="NPN Number"
            type="text"
            thousandSeparator={false}
            value={contactData?.npnNumber ?? ""}
            errorMessage={errorMessages?.npnNumber}
            maxLength={50}
            onChangeRawValue={(value) => updateValue("npnNumber", value)}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={47}
            id="id-nipr-number"
            name="nipr-number"
            label="NIPR Number"
            type="text"
            thousandSeparator={false}
            value={contactData?.niprNumber ?? ""}
            errorMessage={errorMessages?.niprNumber}
            maxLength={50}
            onChangeRawValue={(value) => updateValue("niprNumber", value)}
          />
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalMargin={"20px"}
        horizontalAlign="space-between"
      >
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }} horizontalAlign="flex-start">
          <Button
            tabIndex={48}
            name="contact-form-delete"
            variantStyle="outlined"
            isDisabled={
              contactModalConfiguration.agencyContactId === 0 || !hasPermission
            }
            onClick={() =>
              setDialogConfiguration({
                isOpen: true,
                type: "delete",
                message: "Are you sure you want to delete this contact",
                yesButtonText: "OK",
                noButtonText: "Cancel",
              })
            }
          >
            DELETE
          </Button>
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }} horizontalAlign="flex-end">
          <Button
            tabIndex={49}
            name="contact-form-save"
            variantStyle="contained"
            sx={{ marginRight: "20px" }}
            isDisabled={!hasPermission}
            onClick={() => dispatchPostSaveAgency()}
          >
            SAVE
          </Button>
          <Button
            tabIndex={50}
            name="contact-form-cancel"
            variantStyle="outlined"
            onClick={() =>
              setDialogConfiguration({
                isOpen: true,
                type: "cancel",
                message: "Are you sure you want to cancel?",
                yesButtonText: "Yes",
                noButtonText: "No",
              })
            }
          >
            CANCEL
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        id="dialog-confirmation"
        open={dialogConfiguration.isOpen}
        dialogDescriptionText={dialogConfiguration.message}
        optionYesOverrideLabel={dialogConfiguration.yesButtonText}
        optionNoOverrideLabel={dialogConfiguration.noButtonText}
        onOptionYesEvent={() => {
          if (dialogConfiguration.type === "delete") {
            dispatchPostDeleteAgency();
          }
          if (dialogConfiguration.type === "cancel") {
            setContactModalConfiguration?.({
              ...contactModalConfiguration,
              isOpen: false,
            });
          }
        }}
        onOptionNoEvent={() =>
          setDialogConfiguration({ ...dialogConfiguration, isOpen: false })
        }
      />
    </>
  );
};

export default AgencyContactForm;
