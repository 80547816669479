import { LogLevel } from "@azure/msal-browser";

export const DEFAULT_SPA_PROXY_SERVER_ROUTE = "https://localhost:44424"; // this is supposed to match the SpaProxyServerUrl in .csproj

const REACT_APP_ADB2C_CLIENT_ID: any = process.env.REACT_APP_ADB2C_CLIENT_ID;
const REACT_APP_ADB2C_AUTHORITY: any = process.env.REACT_APP_ADB2C_AUTHORITY;
const REACT_APP_ADB2C_KNOWN_AUTHORITIES: any =
  process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES;
const REACT_APP_ADB2C_REDIRECT_URI: any =
  process.env.REACT_APP_ADB2C_REDIRECT_URI;
const REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI: any =
  process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI;
const REACT_APP_ADB2C_LOGIN_SCOPES: any =
  process.env.REACT_APP_ADB2C_LOGIN_SCOPES;
const REACT_APP_ADB2C_TOKEN_SCOPES: any =
  process.env.REACT_APP_ADB2C_TOKEN_SCOPES;
const REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY: any =
  process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_ADB2C_CLIENT_ID,
    authority: REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: REACT_APP_ADB2C_KNOWN_AUTHORITIES.split(","),
    redirectUri: window.location.origin ?? REACT_APP_ADB2C_REDIRECT_URI,  // ?? DEFAULT_SPA_PROXY_SERVER_ROUTE,
    postLogoutRedirectUri: window.location.origin ?? REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI, // ?? DEFAULT_SPA_PROXY_SERVER_ROUTE,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: REACT_APP_ADB2C_LOGIN_SCOPES.split(","),
};

export const tokenRequest = {
  scopes: REACT_APP_ADB2C_TOKEN_SCOPES.split(","), // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// export const editProfile = {
//     authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY,
// };

export const policyNames = {
  signUpSignIn: REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY,
};
