import { atom } from "recoil";
import { PaymentBatchDto } from "../../../../dtos/payment-batch-dto";
import { defaultAddBatchObject } from "./AddBatch/ModalAddBatchProps";

const batchInformation = atom<PaymentBatchDto>({
  key: "quickSearchSelectsAtomFamily",
  default: defaultAddBatchObject,
});

export { batchInformation };
