import { FC } from "react";
import style from "../Styles/basegridcells.module.css";

type DefaultLinkCellProperties = {
  value: string;
  displayValues: any[];
};
const DefaultLinkCell: FC<DefaultLinkCellProperties> = ({
  value,
  displayValues,
}) => {
  const foundDisplayValueIndex =
    displayValues.map((v) => v.guid).indexOf(value) ?? -1;
  const [linkDisplayName, linkPath, linkTarget] = displayValues[
    foundDisplayValueIndex
  ]?.displayValuesOrFieldNames ?? [null, null, null];
  return (
    <div>
      <a
        className={style.default_link_render_only_cell}
        href={linkPath}
        target={linkTarget}
      >
        {linkDisplayName}
      </a>
    </div>
  );
};

export default DefaultLinkCell;
