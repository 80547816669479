import { AchBillingPaymentBatchDetailsDto } from "../../../dtos/ach-billing-payment-batch-details-dto";
import { FormattingDate } from "../../../utilities/dateFunctions";

export type AchBatchNachaFileCollapseHeaderProps = {
  isCollapse: boolean;
  onSaveFileAs?: () => void;
  onCopyToClipboard?: () => void;
};

export type CopyToClipboardProps = {
  modalText: string;
  isModalOpen: boolean;
};
export const extractFileContent = (content: String[]) => {
  const contentFile = content
    .filter((x) => x != null)
    .map((item) => item)
    .join("\n");
  return contentFile;
};

export const onCopyToClipboard = async (nachaFileContent) => {
  try {
    await navigator.clipboard.writeText(nachaFileContent);
  } catch (err) {
    console.log("Failed to copy text: ", err);
  }
};

export const onSaveFileAs = (
  nachaFileContent: string,
  achBatchDetailInformation: AchBillingPaymentBatchDetailsDto | null,
  selectedProgramCode: string
) => {
  const date = achBatchDetailInformation?.batchDate
    ? FormattingDate(new Date(achBatchDetailInformation.batchDate), "YYYYMMDD")
    : "";
  const blob = new Blob([nachaFileContent], {
    type: "text/plain;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${date}${selectedProgramCode}ACH.txt`;
  link.click();
  URL.revokeObjectURL(url);
};
