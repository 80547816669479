import { FC } from "react";
import { Col, Input, InputAddress, InputPhone } from "../../../TrueUI";
import { ProgramPolicyDto } from "../../../../dtos/program-policy-dto";
import { useRecoilValue } from "recoil";
import { AdminProgramErrorAtom } from "../ProgramAtoms";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";

type ProgramPolicyProps = {
  programPolicyData: ProgramPolicyDto | any;
  setProgramPolicyData: any;
  readOnly?: boolean;
  programPolicyErrors?: any;
};

export const ProgramPolicy: FC<ProgramPolicyProps> = ({
  programPolicyData,
  setProgramPolicyData,
  readOnly = false,
  programPolicyErrors,
}) => {
  const programAdminErrors = useRecoilValue(AdminProgramErrorAtom);
  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col>
        <Input
          tabIndex={30}
          id="program-policy-issuing-name"
          name="program-policy-issuing-name"
          label="Issuing Name"
          value={programPolicyData?.programIssueName}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"text"}
          maxLength={100}
          onChangeRawValue={(value) => {
            setProgramPolicyData({
              ...programPolicyData,
              programIssueName: value,
            });
          }}
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <InputAddress
          tabIndex={31}
          id="program-policy-inputAddress"
          showAddress2={false}
          showSingleLabel={false}
          initValueAddress1={programPolicyData?.programAddress}
          initValueCity={programPolicyData?.programCity}
          initValueStateCode={programPolicyData?.programState}
          initValuePostCode={programPolicyData?.programPostalCode}
          onChangeValueAddress1={(value) =>
            setProgramPolicyData({
              ...programPolicyData,
              programAddress: value,
            })
          }
          onChangeValueCity={(value) =>
            setProgramPolicyData({
              ...programPolicyData,
              programCity: value,
            })
          }
          onChangeStateCode={(value) =>
            setProgramPolicyData({
              ...programPolicyData,
              programState: value,
            })
          }
          onChangeValuePostCode={(value) =>
            setProgramPolicyData({
              ...programPolicyData,
              programPostalCode: value,
            })
          }
          readOnly={readOnly}
          className={"inputAddress"}
          labelFontType="BOLD_CAPTION"
        />
      </Col>
      <Col>
        <Input
          tabIndex={38}
          id="program-policy-attention"
          name="program-policy-attention"
          label="Policy Attention/Contact"
          value={programPolicyData?.programAttention}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"text"}
          maxLength={50}
          onChangeRawValue={(value) => {
            setProgramPolicyData({
              ...programPolicyData,
              programAttention: value,
            });
          }}
        />
      </Col>
      <Col>
        <Input
          tabIndex={39}
          id="program-policy-email"
          name="program-policy-email"
          label="Email Address"
          value={programPolicyData?.programEmail}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"email"}
          maxLength={100}
          onChangeRawValue={(value) => {
            setProgramPolicyData({
              ...programPolicyData,
              programEmail: value,
            });
          }}
          errorMessage={programPolicyErrors?.programEmail}
        />
      </Col>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <InputPhone
            tabIndex={40}
            id="program-policy-phone1"
            name="program-policy-phone1"
            phoneLabel="Phone Number"
            phoneValue={programPolicyData?.programPhone1}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            errorMessagePhone={
              programAdminErrors?.errorDetails?.programPolicy?.programPhone1
            }
            onChangePhoneValue={(value) => {
              setProgramPolicyData({
                ...programPolicyData,
                programPhone1: value,
              });
            }}
          />
        </Col>
        <Col horizontalAlign="flex-end">
          <InputPhone
            tabIndex={41}
            id="program-policy-phone2"
            name="program-policy-phone2"
            phoneLabel="Alternate Phone Number"
            phoneValue={programPolicyData?.programPhone2}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            errorMessagePhone={
              programAdminErrors?.errorDetails?.programPolicy?.programPhone2
            }
            onChangePhoneValue={(value) => {
              setProgramPolicyData({
                ...programPolicyData,
                programPhone2: value,
              });
            }}
          />
        </Col>
      </Row>
      <Col
        horizontalAlignSelf="flex-start"
        breakpoints={{ md: 6, lg: 6, xl: 6 }}
      >
        <InputPhone
          tabIndex={42}
          id="program-policy-fax"
          name="program-policy-fax"
          phoneLabel="Fax Number"
          phoneValue={programPolicyData?.programFax}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          errorMessagePhone={
            programAdminErrors?.errorDetails?.programPolicy?.programFax
          }
          onChangePhoneValue={(value) => {
            setProgramPolicyData({
              ...programPolicyData,
              programFax: value,
            });
          }}
        />
      </Col>
    </Row>
  );
};
