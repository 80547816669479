import { BaseTableFilterOptions } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { ReactElement, useEffect, useState } from "react";
import { Select } from "../../TrueUI";
import { SelectOptions } from "../../../dtos/select-options";
import { addAllOption } from "../../../utilities/selectFunctions";
import { useRecoilValue } from "recoil";
import { DROPDOWN_FILTER } from "../../TrueUI/Tables/TableConstants";
import { TasksTypeFilterAtom } from "./AssignedTasksAtoms";

type AssignedTasksCategoryFilterProps = {
  actionOptions: BaseTableFilterOptions;
  configurationState: any;
};

const AssignedTasksCategoryFilter: (
  properties: AssignedTasksCategoryFilterProps
) => ReactElement = ({ actionOptions, configurationState }) => {
  const [categoryOptions, setCategoryOptions] = useState<
    Partial<SelectOptions>[]
  >(addAllOption([]));
  const [categoryFilter, setCategoryFilter] = useState("all");

  const tasksTypeFilter = useRecoilValue(TasksTypeFilterAtom);

  const updateCategoryOptions = () => {
    if (tasksTypeFilter === "all") setCategoryOptions(addAllOption([]));
    else {
      const categories =
        tasksTypeFilter === "insured"
          ? addAllOption(configurationState?.insuredCategoryOptions ?? [])
          : addAllOption(configurationState?.claimCategoryOptions ?? []);

      setCategoryOptions(categories);
    }
  };

  const setFilter = () => {
    actionOptions?.setFilterInclude?.([
      {
        filterName: DROPDOWN_FILTER,
        columnNames: ["CategoryId"],
        filterValue: categoryFilter,
      },
    ]);
  };

  useEffect(() => {
    setCategoryFilter("all");
    updateCategoryOptions();
  }, [tasksTypeFilter]);

  useEffect(() => {
    setFilter();
  }, [categoryFilter]);
  
  return (
    <Select
      id="task-category"
      name={"category-dropdown"}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={"Category:"}
      labelPosition={"start"}
      options={categoryOptions}
      value={categoryFilter ?? "all"}
      type={"tableFilter"}
      inputWidth={"150px"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        setCategoryFilter(value);
        setFilter();
      }}
    />
  );
};

export default AssignedTasksCategoryFilter;
