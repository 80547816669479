import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import { EndorsementTypeEnum } from "../../../../dtos/endorsement-type-enum";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  ENDORSEMENT_SECTION,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { BreadcrumbItemProps } from "../../../TrueUI/BreadcrumbNav/BreadcrumbNavWithActionButton";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { exposureAndPremiumRelatedTypes } from "../EndorsementInformation/EndorsementTypeOptions";
import { updatePolicyQuoteInformationMultiTarget } from "../updatesPolicyQuoteFunctions";

export const ERROR_DATE = new Date(1234, 5, 6);

export const CONTINUE_ACTION = "continue";
export const SAVE_ONLY_ACTION = "save-only";
export const REVISE_AUDIT_ACTION = "revise-audit";

export const breadcrumbChipItems = { POLICY_KEY_FIELDS: 1, PRODUCER_NAME: 2 };

export const defaultBreadCrumbsChips = [
  {
    show: false,
    label: "POLICY KEY FIELDS",
    key: breadcrumbChipItems.POLICY_KEY_FIELDS,
  },
  {
    show: false,
    label: "PRODUCER CHANGE",
    key: breadcrumbChipItems.PRODUCER_NAME,
  },
];

export const getBreadcrumbItems = (
  setActiveSection: (activeSection: number) => void,
  showExposureAndPremium?: boolean
) =>
  [
    {
      show: true,
      label: "Endorsement Information",
      key: EndorsementSectionEnum.ENDORSEMENT_INFORMATION,
      action: () =>
        setActiveSection(EndorsementSectionEnum.ENDORSEMENT_INFORMATION),
    },
    {
      show: showExposureAndPremium ?? false,
      label: "Exposure and Premium",
      key: EndorsementSectionEnum.EXPOSURE_AND_PREMIUM,
      action: () =>
        setActiveSection(EndorsementSectionEnum.EXPOSURE_AND_PREMIUM),
    },
    {
      show: true,
      label: "Bind Instructions",
      key: EndorsementSectionEnum.BIND_INSTRUCTIONS,
      action: () => setActiveSection(EndorsementSectionEnum.BIND_INSTRUCTIONS),
    },
    {
      show: true,
      label: "Policy Docs",
      key: EndorsementSectionEnum.POLICY_DOCS,
      action: () => setActiveSection(EndorsementSectionEnum.POLICY_DOCS),
    },
  ] as BreadcrumbItemProps[];

export const getEndorsementSectionAndIds = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const endorsementSection = getQueryValue(ENDORSEMENT_SECTION);
  const policyId = getQueryValue(POLICY_ID);
  const quoteId = getQueryValue(QUOTE_ID);
  const previousQuoteId = getQueryValue(PREVIOUS_QUOTE_ID);

  const validatedQuoteSection =
    endorsementSection !== ""
      ? parseInt(endorsementSection)
      : atomValue?.policyQuoteInformation?.activeSection ??
        EndorsementSectionEnum.ENDORSEMENT_INFORMATION;
  const validatedPolicyId =
    policyId !== ""
      ? parseInt(policyId)
      : atomValue?.policyQuoteInformation?.policyId ?? null;
  const validatedQuoteId =
    quoteId !== ""
      ? parseInt(quoteId)
      : atomValue?.policyQuoteInformation?.quoteId ?? null;
  const validatedPreviousQuoteId =
    quoteId !== ""
      ? parseInt(previousQuoteId)
      : atomValue?.policyQuoteInformation?.previousQuoteId ?? null;

  return {
    endorsementSection: validatedQuoteSection,
    policyId: validatedPolicyId,
    quoteId: validatedQuoteId,
    previousQuoteId: validatedPreviousQuoteId,
  };
};

export const getAtomUpdatedAfterSaveEndorsement = (
  saveAction: string | null,
  atomValue: GlobalInsuredAtomProperties | null,
  policyJSONFromSaveResponse: PolicyBlob,
  sectionToContinue?: number
) => {
  if (saveAction === CONTINUE_ACTION) {
    const newAtomValue = updatePolicyQuoteInformationMultiTarget(
      atomValue,
      [
        "policyQuote",
        "activeSection",
        "quoteId",
        "breadcrumbTargetSectionAfterSave",
      ],
      [
        policyJSONFromSaveResponse,
        sectionToContinue,
        policyJSONFromSaveResponse.quote?.quoteID ?? 0,
        null,
      ]
    );
    return newAtomValue;
  }
  if (saveAction === SAVE_ONLY_ACTION) {
    const newAtomValue = updatePolicyQuoteInformationMultiTarget(
      atomValue,
      ["policyQuote", "quoteId"],
      [
        policyJSONFromSaveResponse,
        policyJSONFromSaveResponse.quote?.quoteID ?? 0,
      ]
    );
    return newAtomValue;
  }
  return atomValue;
};

export const hasEndorsementChecked = (
  endorsementType: number,
  endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
) =>
  endorsementList?.some(
    (endorsement) =>
      endorsement.isChecked && endorsement.value === endorsementType
  );

export const hasEndorsementPolicyKeyFields = (
  endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
) =>
  endorsementList?.some(
    (endorsement) =>
      endorsement.isChecked &&
      (endorsement.value === EndorsementTypeEnum.EFFECTIVE_DATE ||
        endorsement.value === EndorsementTypeEnum.EXPIRATION_DATE ||
        endorsement.value === EndorsementTypeEnum.POLICY_NUMBER)
  );

export const hasEndorsementProducerName = (
  endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
) =>
  endorsementList?.some(
    (endorsement) =>
      endorsement.isChecked &&
      endorsement.value === EndorsementTypeEnum.PRODUCER_NAME
  );

export const hasExposurePremiumRelatedChecked = (
  endorsementList?: PolicyEnumValueDescriptionAndBooleanBlob[] | null
) =>
  conditionHasValue(
    endorsementList?.find(
      (endorsementType) =>
        exposureAndPremiumRelatedTypes.includes(endorsementType.value ?? -1) &&
        endorsementType.isChecked === true
    )
  );

export const countExposurePremiumRelatedChecked = (
  endorsementList: PolicyEnumValueDescriptionAndBooleanBlob[]
) =>
  endorsementList.filter(
    (endorsementType) =>
      exposureAndPremiumRelatedTypes.includes(endorsementType.value ?? -1) &&
      endorsementType.isChecked === true
  ).length;
