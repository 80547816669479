export const fileUploadStyles = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  overflow: "hidden",
  m: "15px",
  ".upload_container": {
    width: "75%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "100px",
    alignItems: "center",
  },
  ".document_grid_wrapper": {
    width: "100%",
    height: "100%",
    overflow: "scroll",
    mt: 1,
    "#document_queue_grid": {
      ".base_table_toolbar": {
        ".toolbar_filters": {
          width: "100%",
          justifyContent: "flex-start",
          "div:last-of-type": {
            marginLeft: "auto",
          },
        },
      },
    },
  },
};

export const dividerStyles = {
  mb: 2,
  width: "90%",
};

export const editFileModalStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  ".type_container, .status_container, .added_by_container": {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    ".search_container": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      ".true_search_text_input": {
        height: "31px",
      },
    },
  },
  ".type_container, .status_container, .date_container, .bottom_buttons_container":
    {
      display: "flex",
      justifyContent: "space-between",
      gap: "15px",
      paddingBottom: "20px",
      ".right-buttons": {
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
      },
    },
  ".download_container": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    ".link_container": {
      paddingLeft: "10px",
      width: "100%",
      paddingBottom: "10px",
      paddingTop: "10px",
    },
  },
};
