import { AgencyContactDto } from "../../../../../dtos/agency-contact-dto";
import { AgencyLocationDto } from "../../../../../dtos/agency-location-dto";
import { AgencyProgramDefaultRatesDto } from "../../../../../dtos/agency-program-default-rates-dto";
import { AgencyQuoteConfiguration } from "../../../../../dtos/agency-quote-configuration";
import { PolicyBlob } from "../../../../../dtos/policy-blob";
import { SelectOptions } from "../../../../../dtos/select-options";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { isBetweenEffectiveAndExpirationDates } from "../../../../../utilities/dateFunctions";

export type ProducerDataProps = {
  agencyId?: number | null;
  agencyName?: string | null;
  locationId?: number | null;
  locationName?: string | null;
  agentContactId?: number | null;
  agentName?: string | null;
};

export type ProducerModalConfig = {
  isOpen?: boolean;
  isProducerCheck?: boolean;
  agencies: AgencyQuoteConfiguration[];
  currentProducerData: ProducerDataProps;
};

export type ProducerModalProps = {
  tabKey: string;
  modalConfiguration: ProducerModalConfig;
  readonly?: boolean;
  setModalConfiguration: (modalConfiguration: ProducerModalConfig) => void;
};

export type ProducerFormProps = {
  currentProducerData: ProducerDataProps;
  agencies: SelectOptions[];
  setNewProducerData: (newProducerData: ProducerDataProps) => void;
};

export type ProducerFormUI = {
  isFirstRender: boolean | null;
  newProducerData: ProducerDataProps;
  locations: AgencyLocationDto[] | null;
  agents: AgencyContactDto[] | null;
};

export const defaultProducerData = (
  currentProducerData?: ProducerDataProps | null
) =>
  ({
    agencyId: currentProducerData?.agencyId,
    locationId: currentProducerData?.locationId,
    agentContactId: currentProducerData?.agentContactId,
  } as ProducerDataProps);

export const getAgencyOptions = (policyJSON: PolicyBlob) => {
  const policyEffectiveDate = policyJSON.effectiveDate;
  const atomAgencyList = policyJSON?.endorsementConfiguration?.agencyList;
  const activeAtomAgencyList = atomAgencyList?.filter((atomAgency) =>
    isBetweenEffectiveAndExpirationDates(
      policyEffectiveDate,
      atomAgency.effectiveDate,
      atomAgency.expirationDate
    )
  );

  return activeAtomAgencyList;
};

export const getPrimaryProducer = (policyJSON: PolicyBlob) =>
  policyJSON?.endorsementConfiguration?.oldPolicyJSON?.producers?.find(
    (p) => p.policyAgencyPrimary
  );

export const getCurrentProducerAsProducerDataUI = (policyJSON: PolicyBlob) => {
  const primaryProducer = getPrimaryProducer(policyJSON);
  return {
    agencyId: primaryProducer?.agency?.agencyID,
    agencyName: primaryProducer?.agency?.agencyName,
    locationId: primaryProducer?.location?.locationID,
    locationName: primaryProducer?.location?.locationName,
    agentContactId: primaryProducer?.agent?.agentID,
    agentName: `${primaryProducer?.agent?.agentFirstName ?? ""} ${
      primaryProducer?.agent?.agentLastName ?? ""
    }`,
  } as ProducerDataProps;
};

export const filterDefaultRatesByPolicyDates = (
  defaultRates?: AgencyProgramDefaultRatesDto[],
  effectiveDate?: Date | null,
  expirationDate?: Date | null
) => {
  if (conditionHasValue(effectiveDate) && conditionHasValue(expirationDate)) {
    return defaultRates?.filter((defaultRate) =>
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        defaultRate?.effectiveDate,
        defaultRate?.expirationDate
      )
    );
  }
  return defaultRates;
};
