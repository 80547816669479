import { BillingActivityTabsProps } from "../BillingConstants";
import { BaseTable2 } from "../../../TrueUI";
import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTablePolicyDropdownFilter from "../../Policy/BaseTablePolicyDropdownFilter";
import { Box, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { useRecoilValue } from "recoil";
import themes from "../../../../media/TrueTheme";
import { globalOptions } from "../../../../GlobalAtoms";
import { optionIconStyles } from "./PayrollReportsStyles";
import { getQueryStringsURL } from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  PAYROLL_REPORT_ID,
} from "../../../../utilities/queryStringsHash";
import { useLocation, useNavigate } from "react-router";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { PayrollReportProps, defaultPayrollReport } from "./PayrollReportUtils";
import PayrollReportModal from "./PayrollReportModal";
import { InsertedPayrollReport } from "../../../../dtos/inserted-payroll-report";
import { toUpperCaseKeys } from "../../../../utilities/objectFunctions";

const tableName = "BillingPayrollReportsTable";

const BillingPayrollReports: FC<BillingActivityTabsProps> = ({
  insuredId,
  tabKey,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );

  const [payrollReportModal, setPayrollReportModal] =
    useState<PayrollReportProps>(defaultPayrollReport);

  const location = useLocation();
  const navigate = useNavigate();

  const goToDetails = (payrollReportId: number) => {
    const detailHashes = getQueryStringsURL([
      {
        nameOfHash: PAYROLL_REPORT_ID,
        valueOfHash: payrollReportId,
      },
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash:
          SubSideNavItemDestinationsEnum.BILLING_PAYROLL_REPORT_DETAILS,
      },
    ]);

    const url = location.pathname + location.hash + detailHashes;

    navigate(url);
  };
  const updateRowIntoTable = (
    action: "add",
    newPayrollReport?: InsertedPayrollReport | null
  ) => {
    if (action === "add" && newPayrollReport)
      addRowToTable(newPayrollReport ?? null);
  };

  const addRowToTable = (
    newRowData?: InsertedPayrollReport | Partial<InsertedPayrollReport>
  ) => {
    const newRow = (rowPattern: any) => {
      return { ...rowPattern, ...toUpperCaseKeys(newRowData) };
    };
    getTableMethods?.methods?.addRow(newRow);
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            goToDetails(actionOptions?.row?.PayrollReportId);
          }}
        >
          <LaunchIcon fontSize="small" style={optionIconStyles(theme)} />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PayrollReport/GetPayrollReports?insuredId=${insuredId}`,
    toolbarOptions: {
      addButtonText: "New Report",
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    columnOptions: [
      { fieldName: "Policy", width: 18 },
      { fieldName: "PayrollReportingPeriod", width: 18 },
      { fieldName: "Invoice", width: 16 },
      { fieldName: "Status", width: 18 },
      { fieldName: "Amount", width: 15, align: "right" },
      { fieldName: "Balance", width: 15, align: "right" },
      { fieldName: "OPTIONS", width: 3 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownFilter({
          columnsNames: ["PolicyId", "Status"],
          insuredId: insuredId,
          filterOptions: actionOptions,
          overrideAllOptionLabel: "All Active",
          overrideAllOptionValue: "Active",
          selectAllOptionAsDefault: true,
        }),
    ],
    events: {
      addEventOverrideCallback: () => {
        setPayrollReportModal({
          isOpen: true,
          action: "add",
        });
      },
    },
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      paginate: false,
    },
  };

  const { tableMethods } = useBaseTable([tableConfiguration]);
  const getTableMethods = tableMethods[tableName];

  useEffect(() => {
    setAtom({
      ...getAtom(),
      billingInformation: null,
    });
  }, []);

  return (
    <>
      <div id={"files-table-wrapper"}>
        <BaseTable2 name={tableName} />
      </div>
      <PayrollReportModal
        insuredId={insuredId}
        payrollReportModal={payrollReportModal}
        setPayrollReportModal={setPayrollReportModal}
        updateRowIntoTable={updateRowIntoTable}
      />
    </>
  );
};

export default BillingPayrollReports;
