import { FC, ReactNode } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import "./VerticalStepper.css";

export type StepsProps = {
  stepIcon?: ReactNode;
  stepInfo?: ReactNode;
};
type VerticalStepperProps = {
  steps: StepsProps[];
};

const isLastDot = (dots: StepsProps[], index: number) =>
  (dots?.length ?? 1) - 1 > index;

const VerticalStepper: FC<VerticalStepperProps> = ({ steps }) => {
  const timelineConfiguration: any = {
    position: "right",
    sx: { "&:before": { display: "none" } },
  };
  return (
    <Timeline {...timelineConfiguration} true-element="true-ul-timeLine">
      {steps.map((step: StepsProps, i: number) => (
        <TimelineItem
          true-element={`true-li-timeLine-${i}`}
          key={`${step} ${i}`}
          sx={{ minHeight: "0px" }}
        >
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              className="true_stepper_dots"
              sx={{
                mb: isLastDot(steps, i) ? "0px" : "10px !important",
              }}
            >
              {step.stepIcon}
            </TimelineDot>
            {isLastDot(steps, i) && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: "5px", px: 2 }}>
            {step.stepInfo}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export { VerticalStepper };
