import { FC } from "react";
import { DiaryNoteDto } from "../../../dtos/diary-note-dto";
import InputMemo from "../Inputs/InputMemo";
import Modal from "../Modal/Modal";

type ModalReplyProps = {
  id?: string;
  isOpen: boolean;
  diaryNote?: DiaryNoteDto | null;
  errorDetails: any;
  saveEvent?: () => void;
  cancelEvent?: () => void;
  deleteEvent?: (noteToDelete?: DiaryNoteDto | null) => void;
  setDiaryNote: (diaryNote: DiaryNoteDto | null) => void;
};

const ModalReply: FC<ModalReplyProps> = ({
  id = "",
  isOpen,
  diaryNote,
  errorDetails,
  saveEvent,
  cancelEvent,
  deleteEvent,
  setDiaryNote,
}) => {
  return (
    <>
      <Modal
        open={isOpen}
        id={id}
        title={"Reply"}
        size={"md"}
        saveEvent={saveEvent}
        cancelEvent={cancelEvent}
        deleteEvent={
          diaryNote?.childrenNotes ?? false
            ? undefined
            : () => deleteEvent?.(diaryNote)
        }
        showCloseButton
        showCancelTextButton
      >
        <InputMemo
          id={"id-reply-content"}
          name={"name-reply-content"}
          rows={15}
          value={diaryNote?.content ?? ""}
          resize
          focus
          onChangeRawValue={(value) => {
            setDiaryNote({ ...diaryNote, content: value });
          }}
          errorMessage={errorDetails?.content}
        />
      </Modal>
    </>
  );
};

export default ModalReply;
