import { FC } from "react";
import { useRecoilValue, RecoilState, useRecoilCallback } from "recoil";
import { addOrRemoveKeyRow } from "../../TrueUI/Tables/tableFunctions";
import { Switch } from "../../TrueUI";
import { GridInstanceAtomFamily } from "../BaseGridAtoms";
import {
  BaseGridInternalProperties,
  BaseGridProperties,
} from "../BaseGridProperties";
// import { Switch } from "../..";
// import { TableInstanceType2 } from "../BaseTable2/TableProperties";
// import { tableInstanceAtomFamily2 } from "../TableAtoms";
// import { addOrRemoveKeyRow } from "../tableFunctions";

type MultiSelectCellProps = {
  rowKey: string;
  uiid: string;
};

const MultiSelectCell: FC<MultiSelectCellProps> = ({ rowKey, uiid }) => {
  // TODO - add useGridInstance
  const entireInstance = useRecoilValue(
    GridInstanceAtomFamily(uiid) as RecoilState<{
      BaseGridProperties: BaseGridProperties;
      BaseGridInternalProperties: BaseGridInternalProperties;
    }>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: {
        BaseGridProperties: BaseGridProperties;
        BaseGridInternalProperties: BaseGridInternalProperties;
      }) => {
        set(GridInstanceAtomFamily(uiid), {
          ...entireInstance,
          ...newValueTableInstance,
        });
      },
    []
  );

  const isThisRowChecked = () => {
    if (!entireInstance?.BaseGridProperties.hasSelectedAllRows) {
      const isSelected =
        entireInstance?.BaseGridInternalProperties.selectedRowKeys?.find(
          (row) => (row === rowKey ? true : false)
        );
      return isSelected !== undefined;
    }
    return true;
  };

  const onCheckEvent = (value) => {
    const newSelectedArray = addOrRemoveKeyRow(
      (entireInstance?.BaseGridInternalProperties?.selectedRowKeys ??
        []) as any,
      rowKey,
      value?.target?.checked
    );
    setTableInstance({
      BaseGridProperties: {
        ...entireInstance.BaseGridProperties,
        hasSelectedAllRows: false,
      },
      BaseGridInternalProperties: {
        ...entireInstance.BaseGridInternalProperties,
        selectedRowKeys: newSelectedArray,
      },
    });
  };

  return (
    <Switch
      control="checkbox"
      isChecked={isThisRowChecked()}
      onChange={onCheckEvent}
      // readOnly={tableInstance?.toggleEditModeState}
      readOnly={entireInstance.BaseGridProperties.hasToggledEdit}
    />
  );
};

export default MultiSelectCell;
