import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalApplicationAlerts } from "./GlobalAtoms";

const AppNotifications = () => {
  const [open, setOpen] = useState(false);
  const [appAlert, setAppAlert] = useRecoilState(globalApplicationAlerts);

  useEffect(() => {
    if (appAlert !== null) {
      setOpen(true);
    }
  }, [appAlert]);

  const closeAlertDialog = () => {
    setOpen(false);
    setAppAlert(null);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={15000}
      onClose={() => {
        closeAlertDialog();
      }}
    >
      <Alert
        // onClose={handleClose}
        severity="error"
        color="error"
        // variant="outlined"
        sx={{ width: "500px" }}
      >
        <div style={{ display: "inline-block" }}>
          <span style={{ fontWeight: "bold" }}>ERROR</span> -{" "}
          {appAlert?.message ?? null}
          <div style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>
              #{appAlert?.referenceNumber ?? null}
            </span>
          </div>
        </div>
      </Alert>
    </Snackbar>
  );
};

export default AppNotifications;
