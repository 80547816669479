import { FC } from "react";
import style from "./multiSelect.module.css";
import { IconButton } from "@mui/material";
type ClearAllIconProps = {
  onClick?: () => any;
};

const ClearAllIcon: FC<ClearAllIconProps> = ({ onClick }) => {
  return (
    <span className={style.clear_icon_container}>
      <IconButton onClick={() => onClick?.()}>
        <span className="k-icon k-i-unsort" style={{ fontSize: "24px" }}></span>
      </IconButton>
    </span>
  );
};

export default ClearAllIcon;
