import { useEffect, useState } from "react";
import { SelectOptions } from "../../../dtos/select-options";
import BaseGrid from "../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import BaseGridSearchFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";

import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { Button } from "../../TrueUI";
import Date_NEW from "../../TrueUI/Inputs_NEW/Date_NEW";

const AntonioSandbox = () => {
  const table_name = "testing";

  const test_drop_down_filter_options = [
    {
      displayName: "All",
      stringValue: "all",
    },
    {
      displayName: "Text1",
      stringValue: "text1",
    },
    {
      displayName: "Text2",
      stringValue: "text2",
    },
    {
      displayName: "Text3",
      stringValue: "text3",
    },
    {
      displayName: "Text4",
      stringValue: "text4",
    },
    {
      displayName: "Text5",
      stringValue: "text5",
    },
    {
      displayName: "Text6",
      stringValue: "text6",
    },
    {
      displayName: "Text7",
      stringValue: "text7",
    },
    {
      displayName: "Text8",
      stringValue: "text8",
    },
    {
      displayName: "Text9",
      stringValue: "text9",
    },
    {
      displayName: "Text10",
      stringValue: "text10",
    },
  ] as SelectOptions[];

  const [toggleFilter, setToggleFilter] = useState(false);

  const populatedFilters = [
    (options) =>
      BaseGridDropDownFilter({
        options,
        id: "test_filter_1_drop_down",
        columnNames: ["Col3"],
        optionsOverride: test_drop_down_filter_options,
        labelText: "TEST 1",
      }),
    (options) =>
      BaseGridDropDownFilter({
        options,
        id: "test_filter_2_drop_down",
        columnNames: ["Col6"],
        optionsOverride: test_drop_down_filter_options,
        labelText: "TEST 2",
      }),
    (options) =>
      BaseGridSearchFilter({
        options,
        id: "test_filter_3_search",
        columnNames: ["Col1", "Col2"],
        columnDisplayNames: ["Col1", "Col2"],
      }),
  ];

  const [filters, setFilters] = useState(populatedFilters);

  const { mountGrid, unmountGrid } = useBaseGrid({
    name: table_name,
    getURL: "api/sandbox/GetFakeTableData?noOfRows=10",
    filterOptions: filters,
  });

  useEffect(() => {
    mountGrid();
  }, []);

  useEffect(() => {
    if (toggleFilter) {
      setFilters([]);
    } else {
      setFilters(populatedFilters);
    }
  }, [toggleFilter]);

  useEffect(() => {
    mountGrid();
  }, [filters]);

  return (
    <div style={{ width: "1200px", margin: "50px" }}>
      <div style={{ height: "500px" }}>
        <BaseGrid name={table_name} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Button
          onClick={() => {
            unmountGrid();
            setToggleFilter(!toggleFilter);
          }}
        >
          TOGGLE TOP LEVEL FILTERS
        </Button>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Date_NEW
          id={"test"}
          name={"test"}
          // defaultValue={new Date().toString()}
          onChange={(e) => {
            console.log("eeeeeee", e);
          }}
        />
      </div>
    </div>
  );
};

export default AntonioSandbox;
