import { FC, useEffect, useState } from "react";
import { UserInformationDto } from "../../../../dtos/user-information-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  Button,
  CaptionBold,
  Col,
  Input,
  InputPhone,
  Select,
  Switch,
} from "../../../TrueUI";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { truncateString } from "../../../../utilities/stringFunctions";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { timeZoneOptions } from "../../../../utilities/staticDataFunctions";
import InputColorPicker from "../../../TrueUI/Inputs/InputColorPicker";
import { Box } from "@mui/material";
import style from "./userFormStyles.module.css";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { useSetRecoilState } from "recoil";
import { SimulateUserUpdatedAtom } from "../AdminSecurityAtoms";
import { useNavigate } from "react-router-dom";

type UserFormProps = {
  userId?: number;
  programId?: number;
  tenantId?: number;
  email?: string;
};

type DialogProps = {
  isOpen?: boolean;
  dialogTxt?: string;
};

const UserForm: FC<UserFormProps> = ({
  userId,
  programId,
  tenantId,
  email,
}) => {
  const [userInformation, setUserInformation] =
    useState<UserInformationDto | null>();
  const [dialogProps, setDialogProps] = useState<DialogProps>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const setUserUpdated = useSetRecoilState(SimulateUserUpdatedAtom);
  const isNewUser = userId === 0;
  const API = isNewUser ? "SaveNewAppUser" : "SaveUserInformation";
  const { responseGet, dispatchGet } = useApiGet<UserInformationDto>(
    `api/AdminTools/Security/UserListing/GetUserInformation?userId=${userId}`
  );

  const navigate = useNavigate();

  const {
    responsePost: responseSavePost,
    dispatchPost: dispatchSavePost,
    validatorErrorResponse,
  } = useApiPost(
    `api/AdminTools/Security/UserListing/${API}?tenantId=${tenantId}&programId=${programId}`,
    userInformation
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const newEmail = isNewUser ? email : responseGet?.responseData?.userEmail;
      setUserInformation({ ...responseGet?.responseData, userEmail: newEmail });
    }
  }, [responseGet]);

  useEffect(() => {
    setUserInformation(null);
    setErrorDetails(null);
    dispatchGet();
  }, [userId]);

  const getShortName = (firstName, lastName) => {
    if (
      conditionHasValue(firstName) &&
      conditionHasValue(lastName) &&
      !conditionHasValue(userInformation?.shortName)
    ) {
      const newShortName = `${firstName}${lastName.charAt()}`;
      return truncateString(newShortName, 20);
    }
    return userInformation?.shortName;
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseSavePost)) {
      if (validatorErrorResponse === null) {
        setErrorDetails(null);
        setDialogProps({
          dialogTxt: isNewUser
            ? "New user password email sent"
            : "The user information has been updated.",
          isOpen: true,
        });

        if (isNewUser) {
          navigate(
            `/admin-tools/security/user/${responseSavePost?.responseData ?? 0}`
          );
        }
        if (!isNewUser) {
          // This helps to update the selected option on real time
          // if it updates the first and last name.
          setUserUpdated({
            userId: userId ?? 0,
            longName: `${userInformation?.firstName} ${userInformation?.lastName}`,
          });
        }
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responseSavePost]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 4 }}>
          <Input
            id="email-address"
            name="email-address"
            label="Email Address"
            labelPosition="top"
            value={userInformation?.userEmail}
            readOnly={true}
            maxLength={100}
            errorMessage={errorDetails?.userEmail}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={1}
            id="first-name"
            name="first-name"
            label="First Name"
            labelPosition="top"
            value={userInformation?.firstName}
            maxLength={100}
            errorMessage={errorDetails?.firstName}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                firstName: value,
                shortName: getShortName(value, userInformation?.lastName),
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={2}
            id="last-name"
            name="last-name"
            label="Last Name"
            labelPosition="top"
            value={userInformation?.lastName}
            maxLength={100}
            errorMessage={errorDetails?.lastName}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                lastName: value,
                shortName: getShortName(userInformation?.firstName, value),
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={3}
            id="short-name"
            name="short-name"
            label="Short Name"
            labelPosition="top"
            value={userInformation?.shortName}
            errorMessage={errorDetails?.shortName}
            maxLength={20}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                shortName: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <InputPhone
            tabIndex={4}
            id="phone-number"
            name="phone-number"
            phoneLabel="Phone Number"
            labelPosition="top"
            phoneValue={userInformation?.phoneNumber}
            errorMessagePhone={errorDetails?.phoneNumber}
            onChangePhoneValue={(value) => {
              setUserInformation({
                ...userInformation,
                phoneNumber: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <InputPhone
            tabIndex={5}
            id="mobile-number"
            name="mobile-number"
            phoneLabel="Mobile Phone"
            labelPosition="top"
            phoneValue={userInformation?.mobileNumber}
            errorMessagePhone={errorDetails?.mobileNumber}
            onChangePhoneValue={(value) => {
              setUserInformation({
                ...userInformation,
                mobileNumber: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2.5 }}>
          <Switch
            tabIndex={6}
            id="txt-message"
            name="txt-message"
            label="Accepts Text Messages"
            isChecked={userInformation?.textMessage ?? false}
            control={"checkbox"}
            labelFontType={"BOLD_CAPTION"}
            spaceBetweenLabelAndControl={false}
            onChangeIsChecked={(value) => {
              setUserInformation({
                ...userInformation,
                textMessage: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            id="last-logon"
            name="last-logon"
            label="Last Logon"
            labelPosition="top"
            value={FormattingDate(
              userInformation?.lastLogon,
              "M/d/yyyy h:mm a"
            )}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 3.5 }}></Col>
        <div id={"credential-key"} style={{ display: "none" }}>
          {userInformation?.credentialKey ?? ""}
        </div>
      </Row>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 4 }}>
          <Select
            tabIndex={7}
            labelFontType={"BOLD_CAPTION"}
            id="time-zone-id"
            name="time-zone-id"
            label="Time Zone"
            labelPosition="top"
            value={userInformation?.timeZone}
            errorMessage={errorDetails?.timeZone}
            options={timeZoneOptions}
            onChange={(value) =>
              setUserInformation({ ...userInformation, timeZone: value })
            }
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <div className={style.color_container}>
            <CaptionBold>Avatar Color</CaptionBold>
            <div className={style.preferences_colors_container}>
              <Box
                true-element="true-preferences-initials"
                className={style.i_container}
                sx={{
                  color: userInformation?.avatarColor,
                  backgroundColor: userInformation?.backgroundColor,
                }}
              >
                {userInformation?.initials ?? "N/A"}
              </Box>
              <div>
                <InputColorPicker
                  id="user-preferences-color-picker"
                  onRawChange={(value) => {
                    setUserInformation({
                      ...userInformation,
                      avatarColor: value?.textColor,
                      backgroundColor: value?.backgroundColor,
                    });
                  }}
                  value={userInformation?.backgroundColor}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={8}
            id="title-user"
            name="title-user"
            label="Title"
            labelPosition="top"
            value={userInformation?.title}
            maxLength={100}
            errorMessage={errorDetails?.title}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                title: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}></Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Button
            size={"medium"}
            sx={{ mt: 1.5 }}
            onClick={() => dispatchSavePost()}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        id="dialog-confirmation"
        open={dialogProps?.isOpen}
        dialogDescriptionText={dialogProps?.dialogTxt}
        optionYesOverrideLabel={"OK"}
        onOptionYesEvent={() => {
          setDialogProps({ ...dialogProps, isOpen: false });
        }}
      />
    </>
  );
};
export default UserForm;
