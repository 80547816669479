import { FC, useState } from "react";
import style from "../AuditForm/Audit.module.css";
import { Font } from "../../../TrueUI";
import { AuditDocumentSelectedPacketsProps } from "../AuditForm/AuditTypes";
import Link from "../../../TrueUI/Typography/Link";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { useFormRequest } from "../../../../hooks";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const AuditDocumentSelectedPackets: FC<AuditDocumentSelectedPacketsProps> = ({
  tabKey,
  selectedPacketDocuments,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      if (isEmptyValue(document.templateName)) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: [document.templateName],
          customFileName: `${policyMergeFields.insured?.insuredName ?? ""} ${
            document.docName ?? ""
          }`,
          printOrDownload: "download",
        });
      }
    }
  };

  return (
    <div
      id="audit-document-selected-packets-container-id"
      className={style.audit_document_divider_section_container}
    >
      <div className={style.divider_section_title_container}>
        <Font whiteSpace="normal" textAlign="start">
          PACKET AUDIT DOCUMENTS
        </Font>
      </div>
      {selectedPacketDocuments.map((packetDocument, index) => (
        <div
          key={`packet-selected-document-container-${packetDocument.programDocId}-${index}`}
          className={style.audit_document_selected_packets_single_doc_container}
        >
          <Link
            key={`packet-selected-document-id-${packetDocument.programDocId}-${index}`}
            id={`packet-selected-document-id-${packetDocument.programDocId}-${index}`}
            linkFontType="BODY"
            displayValue={packetDocument.docName}
            underline="hover"
            onClick={() => printIndividualDocument(packetDocument)}
          />
        </div>
      ))}
      <DialogConfirmation
        id="no-template-message-id"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </div>
  );
};

export default AuditDocumentSelectedPackets;
