import { EndorsementConfigurationDto } from "../../../../dtos/endorsement-configuration-dto";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { PolicyExposureBlob } from "../../../../dtos/policy-exposure-blob";
import { PolicyQuoteBlob } from "../../../../dtos/policy-quote-blob";
import { PolicyRatingBlob } from "../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../dtos/policy-state-blob";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
  updatePolicyQuoteMultipleTargets,
  updateQuoteInPolicyQuote,
} from "../updatesPolicyQuoteFunctions";
import { removeProducerNameComments } from "./EndorsementEngineUtils";
import { SpecialRuleOnChangeSelection } from "./EndorsementTypeOptions";

export const QUOTE_EFFECTIVE_DATE = "effectiveDate";
export const QUOTE_EXPIRATION_DATE = "expirationDate";
export const QUOTE_NAME = "quoteName";
export const POLICY_CHANGE_EFFECTIVE_DATE = "policyChangeEffectiveDate";
export const ENDORSEMENT_TYPE_LIST = "endorsementTypeList";
export const ENDORSEMENT_COMMENTS = "endorsementComments";
export const ENDORSEMENT_CONFIGURATION = "endorsementConfiguration";

export const getAtomUpdatedByEndorsementTypeListChange = (
  endorsementList: PolicyEnumValueDescriptionAndBooleanBlob[],
  atomValue: GlobalInsuredAtomProperties | null,
  specialRuleOnChangeSelection?: SpecialRuleOnChangeSelection
) => {
  if (specialRuleOnChangeSelection === "reset-exposure-and-premium") {
    return getAtomUpdatedByResetExposureAndPremium(endorsementList, atomValue);
  }
  if (specialRuleOnChangeSelection === "reset-producer-change") {
    return getAtomUpdatedByResetProducerChange(endorsementList, atomValue);
  }

  return getAtomUpdatedByChangedValues(
    endorsementList,
    ENDORSEMENT_TYPE_LIST,
    atomValue
  );
};

export const getAtomUpdatedByChangedValues = (
  targetValue: any,
  targetName: string,
  atomValue: GlobalInsuredAtomProperties | null,
  endorsementNamePrefix?: string
) => {
  if (targetName === QUOTE_NAME) {
    const quoteName =
      targetName === QUOTE_NAME
        ? `${endorsementNamePrefix} ${targetValue}`
        : targetValue;
    const newEndorsementConfiguration = {
      ...atomValue?.policyQuoteInformation?.policyQuote
        ?.endorsementConfiguration,
      endorsementName: targetValue,
    } as EndorsementConfigurationDto;
    const newQuoteValue = {
      ...atomValue?.policyQuoteInformation?.policyQuote?.quote,
      quoteName: quoteName,
    } as PolicyQuoteBlob;
    const newAtomValue = updatePolicyQuoteMultipleTargets(
      atomValue,
      ["quote", ENDORSEMENT_CONFIGURATION],
      [newQuoteValue, newEndorsementConfiguration]
    );

    return newAtomValue;
  }
  if (targetName !== POLICY_CHANGE_EFFECTIVE_DATE) {
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      targetName,
      targetValue
    );

    return newAtomValue;
  }

  const newAtomValue = updatePolicyQuote(atomValue, targetName, targetValue);
  return newAtomValue;
};

const getAtomUpdatedByResetExposureAndPremium = (
  endorsementList: PolicyEnumValueDescriptionAndBooleanBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
  const oldPolicyJSON = policyJSON?.endorsementConfiguration?.oldPolicyJSON;
  const oldStatesReinstated = oldPolicyJSON?.quote?.states?.map((oldState) => {
    return {
      ...oldState,
      expanded: false,
      exposures: oldState.exposures?.map((oldExposure) => {
        return {
          ...oldExposure,
          previousExposure: oldExposure.exposureAmount,
          previousPremium: oldExposure.manualPremiumAtMod,
          existInOldJSON: true,
        } as PolicyExposureBlob;
      }),
      ratings: oldState.ratings?.map((oldRating) => {
        return {
          ...oldRating,
          previousRunningTotal: oldRating.runningTotal,
        } as PolicyRatingBlob;
      }),
    } as PolicyStateBlob;
  });

  const policyJSONWithOriginalExposureAndPremiumValues = {
    ...policyJSON,
    totalPremium: oldPolicyJSON?.totalPremium,
    totalFees: oldPolicyJSON?.totalFees,
    policyTotal: oldPolicyJSON?.policyTotal,
    quote: {
      ...policyJSON?.quote,
      governingState: oldPolicyJSON?.quote?.governingState,
      minimumPremiumAmount: oldPolicyJSON?.quote?.minimumPremiumAmount,
      estimatedAnnualPremium: oldPolicyJSON?.quote?.estimatedAnnualPremium,
      totalFees: oldPolicyJSON?.quote?.totalFees,
      policyLimits: oldPolicyJSON?.quote?.policyLimits,
      stateList: oldPolicyJSON?.quote?.stateList,
      states: oldStatesReinstated,
      endorsementTypeList: endorsementList,
    },
  } as PolicyBlob;

  const newAtomValue = updatePolicyQuoteInformation(
    atomValue,
    "policyQuote",
    policyJSONWithOriginalExposureAndPremiumValues
  );

  return newAtomValue;
};

export const getUpdatedEndorsementSelectedList = (
  isChecked: boolean,
  endorsementTypeSelected: PolicyEnumValueDescriptionAndBooleanBlob,
  endorsementTypeSelectedList: PolicyEnumValueDescriptionAndBooleanBlob[]
) => {
  return endorsementTypeSelectedList.map((endorsementType) => {
    if (endorsementType.value === endorsementTypeSelected.value)
      return { ...endorsementType, isChecked };
    else return endorsementType;
  });
};

const getAtomUpdatedByResetProducerChange = (
  endorsementList: PolicyEnumValueDescriptionAndBooleanBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  // remove producer name comments
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
  const policyJSONWithNoProducerNameComments =
    removeProducerNameComments(policyJSON);
  const updatedPolicyJSON = {
    ...policyJSONWithNoProducerNameComments,
    quote: {
      ...policyJSONWithNoProducerNameComments.quote,
      endorsementTypeList: endorsementList,
    },
  } as PolicyBlob;

  const newAtomValue = updatePolicyQuoteInformation(
    atomValue,
    "policyQuote",
    updatedPolicyJSON
  );

  return newAtomValue;
};
