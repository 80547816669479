import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  InputDate,
  InputMemo,
  Row,
  Select,
} from "../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../utilities/inputPropsConfigurationFunctions";
import { SelectOptions } from "../../../../../../../dtos/select-options";
import {
  LitigationFormProps,
  defaultRepresentationData,
  API_PATH,
} from "../typesAndConstants";
import { useApiGet } from "../../../../../../../hooks";
import { ClaimReferenceDto } from "../../../../../../../dtos/claim-reference-dto";
import { getSelectOptionsByFieldName } from "../../../../../../../utilities/dataConversionFunctions";
import { RepresentationDto } from "../../../../../../../dtos/representation-dto";
import { StatusEnums } from "../../../../../../../dtos/status-enums";
import { useApiPost } from "../../../../../../../hooks";
import { toUpperCaseKeys } from "../../../../../../../utilities/objectFunctions";

import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../../../../TrueUI/Dialogs/DialogConfirmation";
import {
  DeleteDialogConfiguration,
  defaultDeleteDialogConfiguration,
} from "./ClaimLitigationUtil";
import { FormattingDate } from "../../../../../../../utilities/dateFunctions";

const ClaimLitigationForm: FC<LitigationFormProps> = ({
  claimId,
  caseData,
  formState,
  setFormState,
  saveLitigation,
  updateCaseData,hasClaimsManagementPermissions
}) => {
  const [selectStatusOptions, setSelectStatusOptions] = useState<
    Partial<SelectOptions>[] | null | undefined
  >();
  const [_caseDataBackup, setCaseDataBackup] = useState<
    RepresentationDto | Partial<RepresentationDto>
  >();
  const [_caseData, setCaseData] = useState<
    RepresentationDto | Partial<RepresentationDto>
  >();
  const [errorDetails, setErrorDetails] = useState<any>();
  const { responseGet, dispatchGet } = useApiGet<ClaimReferenceDto[]>(
    `api/ClaimReference/GetReferencesForClaimLitigationForm`
  );
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    `${API_PATH}/SaveRepresentation`,
    { ..._caseData, claimId: claimId }
  );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DeleteDialogConfiguration>(defaultDeleteDialogConfiguration);

  const getActionFromCaseData = (caseData) => {
    if (caseData?.representationId === 0) {
      return "add";
    } else {
      return caseData?.status === StatusEnums.DELETED ? "delete" : "update";
    }
  };

  useEffect(() => {
    if (
      responsePost?.errorResponse === null &&
      responsePost?.isLoading === false &&
      responsePost?.responseData !== null
    ) {
      if (_caseData !== undefined) {
        updateCaseData(_caseData);
      }
      setErrorDetails(undefined);
      setCaseDataBackup(defaultRepresentationData);

      const answeredDate = conditionHasValue(_caseData?.answered)
        ? new Date(_caseData?.answered ?? "")
        : new Date();
      const servedDate = conditionHasValue(_caseData?.served)
        ? new Date(_caseData?.served ?? "")
        : new Date();
        
      saveLitigation?.(
        toUpperCaseKeys({
          ..._caseData,
          representationId: responsePost?.responseData,
          status: _caseData?.status?.toLocaleString(),
          answered: FormattingDate(answeredDate),
          served: FormattingDate(servedDate),
        }),
        getActionFromCaseData(_caseData),
        "cases"
      );

      setFormState({
        ...formState,
        mode: "readonly",
        title:
          _caseData?.status === StatusEnums.DELETED
            ? "DETAILS"
            : formState?.title ?? "",
        rowTableClicked: "case",
      });
      if (_caseData?.status === StatusEnums.DELETED) {
        setCaseData(defaultRepresentationData);
      }
      setFormState({ mode: "readonly", title: "" });
      setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    const caseDataToSet = {
      ...caseData,
      status:
        typeof caseData?.status === "string"
          ? parseInt(caseData?.status ?? "1")
          : caseData?.status,
      answered:
        !conditionHasValue(caseData?.answered) ||
        (typeof caseData?.answered === "string" && caseData?.answered === "")
          ? null
          : new Date(caseData?.answered ?? ""),
      served:
        !conditionHasValue(caseData?.served) ||
        (typeof caseData?.served === "string" && caseData?.served === "")
          ? null
          : new Date(caseData?.served ?? ""),
    };
    setErrorDetails(null);
    setCaseData(caseDataToSet);
    setCaseDataBackup(caseDataToSet);
  }, [caseData]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      responseGet?.axiosResponse?.data !== null &&
      responseGet?.axiosResponse?.data !== undefined
    ) {
      setSelectStatusOptions(
        getSelectOptionsByFieldName(
          "RepresentationStatus",
          responseGet?.axiosResponse?.data
        )
      );
    }
  }, [responseGet]);

  const yesDeleteConfirmation = () => {
    setCaseData({
      ..._caseData,
      status: StatusEnums.DELETED,
      answered: conditionHasValue(_caseData?.answered)
        ? new Date(_caseData?.answered ?? "")
        : new Date(),
      served: conditionHasValue(_caseData?.served)
        ? new Date(_caseData?.served ?? "")
        : new Date(),
    });
    dispatchPost();
  };

  const saveAction = () => dispatchPost();

  const cancelAction = () => {
    setCaseData(_caseDataBackup);
    setErrorDetails(null);
    setFormState({
      ...formState,
      mode: "readonly",
      title:
        formState?.mode === "edit"
          ? `DETAILS FOR CASE NUMBER ${_caseDataBackup?.caseNumber ?? ""}`
          : "",
    });
  };

  return (
    <>
      {(_caseData?.caseNumber !== "" || formState?.mode === "add") && (
        <div>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <Input
                tabIndex={1}
                {...inputConfiguration(
                  "id-case-number",
                  "litigationCaseNumber",
                  "Case Number",
                  20
                )}
                value={_caseData?.caseNumber ?? ""}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, caseNumber: value })
                }
                errorMessage={errorDetails?.caseNumber}
                readOnly={formState?.mode === "readonly"}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              {selectStatusOptions && selectStatusOptions?.length > 0 && (
                <Select
                  tabIndex={2}
                  {...selectConfiguration(
                    "id-status",
                    "litigationCaseStatus",
                    "Status"
                  )}
                  options={selectStatusOptions ?? []}
                  value={_caseData?.status ?? 1}
                  errorMessage={errorDetails?.status}
                  onChange={(value) => {
                    setCaseData({ ..._caseData, status: value });
                  }}
                  readOnly={formState?.mode === "readonly"}
                  firstOptionAsDefault={false}
                  labelFontType={"BOLD_CAPTION"}
                  variant={"filled"}
                />
              )}
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <Input
                tabIndex={3}
                {...inputConfiguration(
                  "id-court",
                  "litigationCaseCourt",
                  "Court",
                  30
                )}
                value={_caseData?.court ?? ""}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, court: value })
                }
                errorMessage={errorDetails?.court}
                readOnly={formState?.mode === "readonly"}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <Input
                tabIndex={4}
                {...inputConfiguration(
                  "id-venue",
                  "litigationCaseVenue",
                  "Venue",
                  30
                )}
                value={_caseData?.venue ?? ""}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, venue: value })
                }
                errorMessage={errorDetails?.venue}
                readOnly={formState?.mode === "readonly"}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <InputDate
                tabIndex={5}
                id="id-served"
                name="litigationCaseDateServed"
                label=" Date Suit Filed"
                value={_caseData?.served}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, served: value })
                }
                readOnly={formState?.mode === "readonly"}
                errorMessage={errorDetails?.served}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <InputDate
                tabIndex={6}
                id="id-answered"
                name="litigationCaseDateAnswered"
                label="Answered"
                value={_caseData?.answered}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, answered: value })
                }
                readOnly={formState?.mode === "readonly"}
                errorMessage={errorDetails?.answered}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 6, xl: 6 }}
            >
              <InputMemo
                tabIndex={7}
                id="id-comment"
                name="litigationCaseComments"
                label="Comments"
                value={_caseData?.comments}
                onChangeRawValue={(value) =>
                  setCaseData({ ..._caseData, comments: value })
                }
                readOnly={formState?.mode === "readonly"}
                labelFontType={"BOLD_CAPTION"}
                variant={"filled"}
                rows={10}
              />
            </Col>
          </Row>
          <Divider height={45} />
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 10, xl: 10 }}
              horizontalAlign="flex-start"
            >
              {formState?.mode === "readonly" && (
                <Button
                  variantStyle="outlined"
                  isDisabled={!hasClaimsManagementPermissions}
                  onClick={() => {
                    setFormState({ ...formState, mode: "edit" });
                  }}
                  name="litigationCaseEditButton"
                >
                  EDIT
                </Button>
              )}
              {formState?.mode === "edit" && (
                <Button
                  variantStyle="outlined"
                  onClick={() => {
                    setDialogConfiguration({
                      ...dialogConfiguration,
                      isOpen: true,
                    });
                  }}
                  name="litigationCaseDeleteButton"
                >
                  DELETE
                </Button>
              )}
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalGutter="15px"
              horizontalAlign="flex-end"
              breakpoints={{ md: 2, lg: 2, xl: 2 }}
            >
              {(formState?.mode === "edit" || formState?.mode === "add") && (
                <Button onClick={saveAction} name="litigationCaseSaveButton">
                  SAVE
                </Button>
              )}
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 2, lg: 2, xl: 1 }}
            >
              {(formState?.mode === "edit" || formState?.mode === "add") && (
                <Button
                  variantStyle="outlined"
                  onClick={cancelAction}
                  name="litigationCaseCancelButton"
                >
                  CANCEL
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}
      <DialogConfirmation
        name={dialogConfiguration.dialogName}
        id={dialogConfiguration.dialogId}
        open={dialogConfiguration.isOpen}
        dialogDescriptionText={dialogConfiguration.descriptionText}
        onOptionNoEvent={() => {
          setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
        }}
        onOptionYesEvent={yesDeleteConfirmation}
      />
    </>
  );
};

export default ClaimLitigationForm;
