import { useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import TabPanel from "../../Tabs/TabPanel";
import Tabs from "../../Tabs/Tabs";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";

type BaseTableTabStatusFilterProps = {
  id?: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: string | number;
  themeOverride?: "material" | "square";
  usingExternalFiltering?: boolean;
  onChange?: (e: any) => void;
};

const BaseTableTabStatusFilter: (
  properties: BaseTableTabStatusFilterProps
) => any = ({
  id = "filterTab",
  filterOptions,
  columnsNames,
  optionsOverride,
  defaultValue,
  themeOverride = "material",
  usingExternalFiltering,
  onChange,
}) => {
  const statusTypes = [{ displayName: "ALL", stringValue: "all" }].concat(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName: enumType?.key ?? "",
        stringValue: enumType?.value.toString(),
      })
    )
  );

  const tabs = optionsOverride ?? statusTypes;

  const defaultTabIndex = tabs.findIndex(
    (tab) => tab.stringValue === defaultValue
  );

  const [selectedTab, setSelectedTab] = useState<number>(defaultTabIndex);

  const setStatus = (val: string) => {
    filterOptions?.setFilterInclude?.([
      {
        filterName: "tab_status_filter",
        columnNames: columnsNames,
        filterValue: val,
      },
    ]);
  };

  useEffect(() => {
    defaultValue != undefined && setStatus(defaultValue.toString());
  }, []);

  const onTabClick = (e) => {
    setSelectedTab(e.selected);
    if (!usingExternalFiltering) {
      setStatus(tabs[e.selected].stringValue ?? "all");
    }
    onChange?.(e);
  };

  return (
    <div id={`filter-tab-container-${id}`} style={{ width: "auto" }}>
      <Tabs
        name={`filter-tab-${id}`}
        themeOverride={themeOverride}
        onSelect={onTabClick}
        selected={selectedTab}
      >
        {tabs.map((tab) => (
          <TabPanel title={tab.displayName} key={tab.stringValue}></TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default BaseTableTabStatusFilter;
