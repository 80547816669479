import { FC, useEffect } from "react";
import { Search, FilterAlt } from "@mui/icons-material";
import { Box } from "@mui/material";
import { VerticalStepper, StepsProps } from "../TrueUI/Steppers";
import { useApiGet } from "../../hooks";
import { FormattingDate } from "../../utilities/dateFunctions";
import { ActivityLogFieldsEnum } from "../../dtos/activity-log-fields-enum";
import InformationContainer from "../TrueUI/Containers/InformationContainer";
import { activityStyles } from "./ActivityStyles";
import { Theme } from "../../media/themeTypes";
import { Font } from "../TrueUI";
import { TriggerComponentUpdateAtom } from "../Insured/InsuredAtoms";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import { getDateFromUtcToUserTimeZone } from "../../utilities/dateTimeZoneFunctions";
import ActivityItem from "./ActivityItem";
import ActivityIcon from "./ActivityIcon";

type ActivityProps = {
  theme: Theme;
  lookupId?: number;
  lookupField?: ActivityLogFieldsEnum;
};

const Activity: FC<ActivityProps> = ({
  theme,
  lookupId,
  lookupField = ActivityLogFieldsEnum.INSURED_ID,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const { responseGet, dispatchGet } = useApiGet<any[]>(
    `api/ActivityLog/GetActivityLog?lookupField=${lookupField}&lookupId=${lookupId}`
  );

  const refreshActivity = useRecoilValue(
    TriggerComponentUpdateAtom("activityLogComponent")
  );
  useEffect(() => {
    if (lookupId) dispatchGet();
  }, []);

  useEffect(() => {
    if (lookupId) dispatchGet();
  }, [refreshActivity]);

  const ActivityList: any[] = responseGet?.responseData ?? [];

  const activityData: StepsProps[] = (ActivityList ?? []).map((_activity) => ({
    stepIcon: <ActivityIcon category={_activity?.category} />,
    stepInfo: (
      <ActivityItem
        title={_activity?.description}
        date={FormattingDate(
          getDateFromUtcToUserTimeZone(
            _activity?.createOn,
            localOptions?.timeZoneID ?? null
          ),
          "M/D/YYYY h:mm a"
        )}
        user={_activity?.createByName}
      />
    ),
  }));

  return (
    <Box className="activity-section-container" sx={activityStyles(theme)}>
      <InformationContainer
        className={"activity-container"}
        title={
          <Box className="activity-header">
            <Font name="activityLabel">Activity</Font>
            <Box className={"filters"}>
              <Search
                onClick={() => {
                  alert("Waiting instructions");
                }}
              />
              <FilterAlt
                onClick={() => {
                  alert("Waiting instructions");
                }}
              />
            </Box>
          </Box>
        }
      >
        <div className="activity-stepper">
          <VerticalStepper steps={activityData}></VerticalStepper>
        </div>
      </InformationContainer>
    </Box>
  );
};
export default Activity;
