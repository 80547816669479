import { Box } from "@mui/material";
import { BaseTable2, TitleBold } from "../../../../TrueUI";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { ModalChangeClaimOpenTasksProps } from "./ModalChangeProps";
import {
  changeStatusModal,
  openTasksContainer,
  openTasksLinkContainer,
} from "./ModalChangeClaimStyles";
import { getQueryStringsURL } from "../../../../../utilities/URLUtilities_OBSOLETE";
import {
  DRAWER_TABS,
  OPEN_DRAWER,
} from "../../../../../utilities/queryStringsHash";
import { DrawerActionsEnums } from "../../../InsuredDrawer/InsuredDrawerConstants";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import Link from "../../../../TrueUI/Typography/Link";

const ModalChangeClaimOpenTasks = ({
  claimId,
  setAllModalConfiguration,
  allModalConfiguration,
}: ModalChangeClaimOpenTasksProps) => {
  const name = "OpenTasksTable";
  const getURL = `api/Task/GetOpenTasksByClaimId?claimId=${claimId}`;
  const location = useLocation();
  const navigate = useNavigate();
  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      hideToolbar: true,
    },
    columnOptions: [{ fieldName: "Subject", width: 50 }],
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      tableStyle: {
        height: "215px",
      },
    },
  };

  useBaseTable(tableConfiguration);

  const goToTasks = () => {
    setAllModalConfiguration({
      ...allModalConfiguration,
      openModalChangeClaimStatus: false,
    });
    const drawerHashes = getQueryStringsURL([
      {
        nameOfHash: OPEN_DRAWER,
        valueOfHash: 1,
      },
      {
        nameOfHash: DRAWER_TABS,
        valueOfHash: DrawerActionsEnums.TASKS,
      },
    ]);

    const url = location.pathname + location.hash + drawerHashes;
    navigate(url);
  };

  return (
    <div style={openTasksContainer}>
      <TitleBold primaryColor>Open Tasks</TitleBold>
      <Box sx={changeStatusModal()}>
        <BaseTable2 name={name} />
      </Box>
      <Box sx={openTasksLinkContainer}>
        <div>
          <Link
            onClick={goToTasks}
            linkFontType={"BODY"}
            displayValue={"Go to Tasks"}
            name="goToTasksLink"
          />
        </div>
      </Box>
    </div>
  );
};

export default ModalChangeClaimOpenTasks;
