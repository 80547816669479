import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import UserPermissionsHelp from "./UserPermissionsHelp";
import { useApiGet } from "../../hooks";
import { Heading4, Switch } from "../TrueUI";
import { PermissionPage } from "../../dtos/permission-page";
import helpComponentAtom from "../Site/SiteAtoms";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import { permissionsStyle } from "./UserPermissionsStyles";

const UserPermissions = () => {
  const [permissions, setPermissions] =
    useState<Partial<PermissionPage> | null>();
  const { responseGet, dispatchGet } = useApiGet<PermissionPage>(
    "api/permissions/GetPermissionsPage"
  );
  const setHelper = useSetRecoilState(helpComponentAtom);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  // const updatePermission = (
  //   userId: number | null,
  //   permissionId: number | null,
  //   hasPermission: boolean
  // ) => {
  //   const updatedRow = (permissions?.rowPermissions || []).map((row) => {
  //     if (row.permissionId === permissionId) {
  //       const updatedRow = (row.permissions || []).map((permission) => {
  //         if (permission.programUserId === userId) {
  //           return { ...permission, hasPermission: hasPermission };
  //         }
  //         return permission;
  //       });
  //       return { ...row, updatedRow };
  //     }
  //     return row;
  //   });

  //   const updatedPermission = {
  //     ...permissions,
  //     rowPermissions: updatedRow,
  //   };

  //   setPermissions(updatedPermission);
  // };

  useEffect(() => {
    dispatchGet();
    setHelper(<UserPermissionsHelp />);
    return () => {
      setHelper(null);
    };
  }, []);

  useEffect(() => {
    setPermissions(responseGet?.responseData ?? null);
  }, [responseGet]);

  return permissions !== null &&
    permissions?.availablePrograms !== undefined &&
    permissions?.rowPermissions !== undefined ? (
    <Box id="user-permissions-container" sx={permissionsStyle(theme)}>
      <Box id="user-permissions-title-container">
        <Heading4>Effective Permissions</Heading4>
      </Box>
      <Box id="user-permissions-table-container">
        <Table size="small" aria-label="a dense table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="table-header-permission">
                Permission
              </TableCell>
              {permissions?.availablePrograms.length > 0 &&
                permissions?.availablePrograms.map((program: string) => (
                  <TableCell key={program} className="table-header-program">
                    {program}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions?.rowPermissions.length > 0 &&
              permissions?.rowPermissions.map((row) => (
                <TableRow
                  key={row.permissionId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="td" scope="row">
                    {row.permissionName}
                  </TableCell>
                  {row?.permissions !== undefined &&
                    row?.permissions.length > 0 &&
                    row?.permissions.map((permission) => (
                      <TableCell
                        align="left"
                        key={`${permission.permissionId}_${permission.programUserId}`}
                        className="switch-cell"
                      >
                        <Switch
                          control={"checkbox"}
                          isChecked={permission.hasPermission}
                          readOnly
                        />
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  ) : (
    <>{permissions === null && "No Results"}</>
  );
};

export default UserPermissions;
