import { Box } from "@mui/material";
import { Editor } from "@progress/kendo-react-editor";
import { CSSProperties, FC, ReactNode, useEffect, useState } from "react";
import { Collapse, Button, SaveButton } from "..";
import { defaultTools } from "./DefaultToolsEditor";
import "./richEditor.module.css";

type RichEditorProperties = {
  id?: string;
  tools?: any[];
  title?: string;
  value?: string;
  contentStyle?: CSSProperties;
  className?: string;
  disableCollapse?: boolean;
  expanded?: boolean;
  headerComponent?: ReactNode;
  footerComponent?: ReactNode;
  reset?: boolean;
  errorMessage?: string[] | null;
  readOnly?: boolean;
  setExpanded?: (expanded: boolean) => void;
  saveEvent?: () => void;
  resetEvent?: (reset?: boolean) => void;
  cancelEvent?: () => void;
  onChangeRawValue?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  tabIndex?: number;
};

const RichEditor: FC<RichEditorProperties> = ({
  id,
  tools = defaultTools,
  value,
  contentStyle = { height: 150 },
  className,
  headerComponent,
  footerComponent,
  title,
  disableCollapse = false,
  readOnly = false,
  expanded,
  onChange,
  onBlur,
  saveEvent,
  cancelEvent,
  reset,
  resetEvent,
  onChangeRawValue,
  setExpanded,
  errorMessage,
  tabIndex = 0,
}) => {
  const [refreshContent, setRefreshContent] = useState(reset ?? true);

  useEffect(() => {
    if (reset !== undefined && reset) setRefreshContent(false);
  }, [reset]);

  useEffect(() => {
    if (!refreshContent) {
      resetEvent?.(false);
      setRefreshContent(true);
    }
  }, [refreshContent]);

  const customStyles = {
    pointerEvents: readOnly ? "none" : "auto",
    opacity: readOnly ? 0.7 : 1,
    p: 0,
    m: 0,
    // Add any other custom styles as needed
  };

  const item = (
    <Box className="true_rich_editor" id={id} tabIndex={tabIndex}>
      <Box
        id={"true-rich-editor-header"}
        alignSelf={"auto"}
        sx={{ ...customStyles }}
        component={"div"}
      >
        {headerComponent}
      </Box>

      <Editor
        tools={tools}
        contentStyle={contentStyle}
        defaultEditMode={"div"}
        className={`${className} rich_editor_class ${
          readOnly ? "read_only_disabled" : ""
        }`}
        value={value}
        preserveWhitespace={false}
        onChange={(e) => {
          onChangeRawValue?.(e.html);
          onChange?.(e);
        }}
        onBlur={(e) => {
          onBlur?.(e);
        }}
      />
      {errorMessage != null && (
        <span className="true_input_error_txt">{errorMessage?.join(" ")}</span>
      )}
      <Box
        id={"true-rich-editor-footer"}
        alignSelf={"auto"}
        sx={{ ...customStyles }}
        component={"div"}
      >
        {footerComponent}

        <Box display={"flex"} justifyContent={"flex-end"} mt={"10px"}>
          {saveEvent !== undefined && (
            <SaveButton
              sx={{ mr: 1 }}
              onClick={() => {
                saveEvent?.();
              }}
            >
              SAVE
            </SaveButton>
          )}
          {cancelEvent !== undefined && (
            <Button
              onClick={() => {
                cancelEvent?.();
              }}
              variantStyle={"outlined"}
            >
              CANCEL
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="true_rich_editor_container">
      {refreshContent ? (
        disableCollapse ? (
          item
        ) : (
          <Collapse
            content={item}
            title={title}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )
      ) : null}
    </Box>
  );
};

export default RichEditor;
