import { FC, useEffect } from "react";
import { SubSideNavItemDestinationsEnum } from "../../dtos/sub-side-nav-item-destinations-enum";
import { addQueryStrings } from "../../utilities/URLUtilities_OBSOLETE";
import { CLAIMS_BODY_SECTION } from "../../utilities/queryStringsHash";
import { getCastedQueryValue } from "../../utilities/queryStringsHashFunctions";
import { useLocation } from "react-router-dom";
// import TopClaimsTable from "./Claims/TopClaimsTable";
import PaymentBatchBodyWrapper from "./Payments/PaymentsBatch/PaymentBatchBodyWrapper";
import PaymentApprovalBodyWrapper from "./Payments/Approvals/ApprovalsBodyWrapper";
import RecurringBodyWrapper from "./Payments/Recurring/RecurringBodyWrapper";
import TopClaimsAndIncidentsTabs from "./Claims/TopClaimsAndIncidentsTabs";

const renderClaimBody = (param: number | undefined) => {
  switch (param) {
    case SubSideNavItemDestinationsEnum.CLAIMS:
    case SubSideNavItemDestinationsEnum.MAIN:
      // return <TopClaimsTable />;
      return <TopClaimsAndIncidentsTabs />;
    case SubSideNavItemDestinationsEnum.PAYMENTS_BATCH:
      return <PaymentBatchBodyWrapper />;
    case SubSideNavItemDestinationsEnum.PAYMENTS_HISTORY:
      return <>Coming soon!</>;
    case SubSideNavItemDestinationsEnum.PAYMENTS_APPROVALS:
      return <PaymentApprovalBodyWrapper />;
    case SubSideNavItemDestinationsEnum.PAYMENTS_RECURRING:
      return <RecurringBodyWrapper />;
    default:
      // return <TopClaimsTable />;
      return <TopClaimsAndIncidentsTabs />;
  }
};

const ClaimsBodyWrapper: FC = () => {
  useLocation(); //This help to refresh the Wrapper when a URL is added.
  const bodySection = getCastedQueryValue(CLAIMS_BODY_SECTION);
  useEffect(() => {
    addQueryStrings([
      { nameOfHash: CLAIMS_BODY_SECTION, valueOfHash: bodySection },
    ]);
  }, []);

  return renderClaimBody(bodySection);
};

export default ClaimsBodyWrapper;
