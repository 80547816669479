import { Box } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";
import IconButton from "../../TrueUI/Buttons/IconButton";
import Drawer from "../../TrueUI/Drawer/Drawer";
import InsuredDrawerContent from "./InsuredDrawerContent";
import {
  addQueryStrings,
  getQueryValue,
  removeQueryStrings,
} from "../../../utilities/URLUtilities_OBSOLETE";
import {
  ContextProps,
  DrawerActionsEnums,
  DrawerContainerProps,
  IconsOnDrawer,
} from "./InsuredDrawerConstants";
import {
  CLAIM_NUMBER,
  DRAWER_TABS,
  INSURED_ATOM_KEY,
  OPEN_DRAWER,
} from "../../../utilities/queryStringsHash";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { insuredDrawerStyle } from "./InsuredDrawerStyles";
import { Icon } from "@progress/kendo-react-common";
import { ContextEnums } from "../../../dtos/context-enums";

const InsuredDrawerContainer: FC<DrawerContainerProps> = ({
  insuredId,
  insuredCode,
  tabKey,
}) => {
  const defaultContext: ContextProps = {
    type: ContextEnums.INSURED,
    insuredId: insuredId,
    insuredCode: insuredCode,
    claimNumber: getQueryValue(CLAIM_NUMBER),
    tabKey: tabKey,
  };
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const drawerTab = getQueryValue(DRAWER_TABS);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(DrawerActionsEnums.NOTES);
  const [context, setContext] = useState<ContextProps>(defaultContext);
  const idFamilyAtom = `${INSURED_ATOM_KEY} ${tabKey}`;
  const drawerQueryValue = getQueryValue(OPEN_DRAWER);

  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(idFamilyAtom));
  const atomContext = getAtom()?.contextDrawer;

  const onClickIcon = (tab: number) => {
    setTab(tab);
    setOpenDrawer(true);
    addQueryStrings([
      {
        nameOfHash: OPEN_DRAWER,
        valueOfHash: 1,
      },
    ]);
  };

  useEffect(() => {
    if (drawerQueryValue !== "") {
      setOpenDrawer(true);
    }
  }, [drawerQueryValue]);

  useEffect(() => {
    if (drawerTab !== "") {
      setTab(parseInt(drawerTab));
    }
  }, [drawerTab]);

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    removeQueryStrings([OPEN_DRAWER]);
  };

  useEffect(() => {
    const contextType = atomContext ?? ContextEnums.INSURED;
    setContext({
      type: contextType,
      insuredId: insuredId,
      insuredCode: insuredCode,
      claimNumber:
        contextType === ContextEnums.CLAIMS ? getQueryValue(CLAIM_NUMBER) : "",
      tabKey: tabKey,
    });
  }, [atomContext, insuredId]);

  return (
    <Box sx={insuredDrawerStyle(theme)}>
      {IconsOnDrawer.map((icon) => (
        <IconButton
          name={icon.iconName}
          permissions={icon.permissions}
          onClick={() => onClickIcon(icon.tab)}
          key={`drawer-button-${icon.iconName}`}
        >
          <Icon className={"true_drawer_icon"} name={icon.iconName} />
        </IconButton>
      ))}
      <Drawer open={openDrawer} onClose={onCloseDrawer}>
        <InsuredDrawerContent
          currentTab={tab}
          onSelectTab={setTab}
          onCloseDrawer={onCloseDrawer}
          context={context}
        />
      </Drawer>
    </Box>
  );
};

export default InsuredDrawerContainer;
