import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PolicyLossBlob } from "../../../../dtos/policy-loss-blob";
import { getDataIndexByColumnName } from "../../../TrueUI/Tables/tableFunctions";
import { LossHistoryDisplayOptionsUIProps } from "../PolicyQuoteForm/PolicyQuoteTypes";

export const getLossesAsTableData = (losses: PolicyLossBlob[]) => {
  return losses.map((loss) => [
    loss.policyLossHistoryID?.toString() ?? "",
    loss.policyYear?.toString() ?? "",
    loss.claimNumber ?? "",
    loss.state ?? "",
    loss.type?.value?.toString() ?? "",
    loss.status?.value?.toString() ?? "",
    loss.paid?.toString() ?? "",
    loss.incurred?.toString() ?? "",
    loss.recovery?.toString() ?? "",
    loss.comments ?? "",
  ]);
};

export const getLossesByTableData = (
  data: string[][],
  columns: BaseTableColumn[],
  tableDisplayOptions: LossHistoryDisplayOptionsUIProps
): PolicyLossBlob[] => {
  const policyLossHistoryIdIndex = getDataIndexByColumnName(
    columns,
    "PolicyLossHistoryId"
  );
  const policyYearIndex = getDataIndexByColumnName(columns, "PolicyYear");
  const claimNumberIndex = getDataIndexByColumnName(columns, "ClaimNumber");
  const stateIndex = getDataIndexByColumnName(columns, "State");
  const typeIndex = getDataIndexByColumnName(columns, "Type");
  const statusIndex = getDataIndexByColumnName(columns, "Status");
  const paidIndex = getDataIndexByColumnName(columns, "Paid");
  const incurredIndex = getDataIndexByColumnName(columns, "Incurred");
  const recoveryIndex = getDataIndexByColumnName(columns, "Recovery");
  const commentsIndex = getDataIndexByColumnName(columns, "Comments");

  const newLossesValues = data.map((row) => {
    return {
      policyLossHistoryID:
        row[policyLossHistoryIdIndex] === ""
          ? null
          : parseInt(row[policyLossHistoryIdIndex]),
      policyYear:
        row[policyYearIndex] === "" ? null : parseInt(row[policyYearIndex]),
      claimNumber: row[claimNumberIndex] === "" ? null : row[claimNumberIndex],
      state: row[stateIndex] === "" ? null : row[stateIndex],
      type: {
        value: row[typeIndex] === "" ? null : row[typeIndex],
        description: tableDisplayOptions.lossTypeList.find(
          (lossType) => lossType.value === row[typeIndex]
        )?.text,
      },
      status: {
        value: row[statusIndex] === "" ? null : row[statusIndex],
        description: tableDisplayOptions.claimStatusList.find(
          (claimStatus) => claimStatus.value === row[statusIndex]
        )?.text,
      },
      paid: row[paidIndex] === "" ? null : parseInt(row[paidIndex]),
      incurred: row[incurredIndex] === "" ? null : parseInt(row[incurredIndex]),
      recovery: row[recoveryIndex] === "" ? null : parseInt(row[recoveryIndex]),
      comments: row[commentsIndex] === "" ? null : row[commentsIndex],
    } as PolicyLossBlob;
  });

  return newLossesValues;
};

export const allLossesAreValid = (losses: PolicyLossBlob[]) => {
  const allLossesValid = losses.every(
    (loss) =>
      loss.policyLossHistoryID !== null &&
      loss.policyYear !== null &&
      loss.claimNumber !== null &&
      loss.state !== null &&
      loss.type !== null &&
      loss.status !== null &&
      loss.paid !== null &&
      loss.incurred !== null
  );

  return allLossesValid;
};
