import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import { PayrollReportDataDto } from "../../../../dtos/payroll-report-data-dto";
import {
  PayrollReportModalProps,
  defaultPayrollReport,
} from "./PayrollReportUtils";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import PayrollReportModalContent from "./PayrollReportModalContent";

const PayrollReportModal: FC<PayrollReportModalProps> = ({
  insuredId,
  payrollReportModal,
  setPayrollReportModal,
  updateRowIntoTable,
}) => {
  const [payrollReportData, setPayrollReportData] =
    useState<PayrollReportDataDto | null>();
  const [errorMessages, setErrorMessages] = useState<any>();

  const {
    responsePost: responsePostSave,
    dispatchPost: dispatchPostSave,
    validatorErrorResponse: errorResponseSave,
  } = useApiPost<any>("api/PayrollReport/SavePayrollReport", payrollReportData);

  useEffect(() => {
    if (isAPITotallyComplete(responsePostSave)) {
      setPayrollReportModal({
        ...payrollReportModal,
        isOpen: false,
      });
      setPayrollReportData(null);
      setErrorMessages(null);
      updateRowIntoTable("add", responsePostSave.axiosResponse?.data)
    }
  }, [responsePostSave]);

  useEffect(() => {
    if (conditionHasValue(errorResponseSave)) {
      setErrorMessages(errorResponseSave?.errorDetails);
    }
  }, [errorResponseSave]);

  const onCancelEvent = () => {
    setPayrollReportModal(defaultPayrollReport);
    setPayrollReportData(null);
    setErrorMessages(null);
  };

  const onSaveEvent = () => {
    dispatchPostSave();
  };

  return (
    <>
      <Modal
        id="payroll-report-modal"
        title={"New Payroll Report"}
        open={payrollReportModal.isOpen}
        size={"xs"}
        showCloseButton
        showCancelTextButton
        saveEvent={onSaveEvent}
        cancelEvent={onCancelEvent}
        saveOverrideLabel={"OK"}
      >
        <PayrollReportModalContent
          insuredId={insuredId}
          payrollReportData={payrollReportData}
          setPayrollReportData={setPayrollReportData}
          errorMessages={errorMessages}
        />
      </Modal>
    </>
  );
};

export default PayrollReportModal;
