import { FC, useState } from "react";
import { Switch } from "../../../../../TrueUI";
import { ClaimCorrespondenceCategoryCheckboxProps } from "./typesAndConstants";

const CategoryCheckbox: FC<ClaimCorrespondenceCategoryCheckboxProps> = ({
  category,
  categorySelected,
}) => {
  const [checked, setChecked] = useState<boolean>(true);
  return (
    <Switch
      control="checkbox"
      label={`${category.value1}`}
      labelPlacement={"end"}
      labelStyle={{
        "& span:first-of-type": {
          padding: "0px 4px",
        },
      }}
      isChecked={checked}
      onChangeIsChecked={(checked) => {
        setChecked(checked);
        categorySelected(category, checked);
      }}
      primaryLabelColor
      labelFontType={"BODY"}
      name={`correspondenceCategory${category.fieldName.replace(" ", "")}`}
    />
  );
};

export default CategoryCheckbox;
