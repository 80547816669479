import { FC, useEffect, useState } from "react";
import ModalClaimFinancial from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/ModalClaimFinancial";
import {
  DeletedPaymentProps,
  FinancialModalProps,
} from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/typesAndConstants";
import { ClaimLandingClaimantDto } from "../../../../../dtos/claim-landing-claimant-dto";
import { AddPaymentSelectsOptionsDto } from "../../../../../dtos/add-payment-selects-options-dto";
import { UpdatedPaymentRowData } from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/ClaimFinancialUtil";
import { useApiGet } from "../../../../../hooks/useApi";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import ModalMovePayment from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/MovePaymentComponents/ModalMovePayment";
import { VoidedOrRefundModalOpenProps } from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/RefundComponents/TypesModalRefundPayment";

export type ModalEditPaymentApprovalProps = {
  claimId?: number;
  modalProps: FinancialModalProps | Partial<FinancialModalProps>;
  closeModal: (isOpen: boolean, refreshTable?: boolean) => void;
  paymentId?: string | null;
  setUpdatedPaymentRowData?: (
    paymentData?: UpdatedPaymentRowData | null
  ) => void;
  setDeletedPaymentRowData?: (paymentData: DeletedPaymentProps) => void;
  rowKey?: string;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  paymentSelectsOptions: AddPaymentSelectsOptionsDto | null;
};

const ModalEditPaymentApproval: FC<ModalEditPaymentApprovalProps> = ({
  claimId,
  modalProps,
  closeModal,
  paymentId,
  setUpdatedPaymentRowData,
  setDeletedPaymentRowData,
  rowKey,
  paymentSelectsOptions,
}) => {
  const [isVoidedRefundOrMoveModalConfig, setIsVoidedRefundOrMoveModalConfig] =
    useState<VoidedOrRefundModalOpenProps>({
      voided: false,
      refund: false,
      movePayment: false,
      movePaymentData: null,
      refundPaymentData: null,
      reserveTypes: [],
      refreshTable: true,
      paymentId: -1,
    });
  const { dispatchGet, responseGet } = useApiGet<ClaimLandingClaimantDto>(
    `api/Payment/GetPaymentClaimClaimantById?paymentId=${paymentId}`
  );

  const [claimLandingClaimant, setClaimLandingClaimant] =
    useState<ClaimLandingClaimantDto | null>(null);

  useEffect(() => {
    if (modalProps?.open === true && (paymentId?.length ?? 0) > 0) {
      dispatchGet();
    }
  }, [paymentId, modalProps]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setClaimLandingClaimant(responseGet?.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      isVoidedRefundOrMoveModalConfig?.movePayment === false &&
      isVoidedRefundOrMoveModalConfig?.refreshTable === true
    ) {
      closeModal(false);
    }
  }, [isVoidedRefundOrMoveModalConfig]);
  return (
    <>
      {claimLandingClaimant && (
        <ModalClaimFinancial
          claimId={claimId}
          modalProps={modalProps}
          closeModal={closeModal}
          paymentId={paymentId}
          setUpdatedPaymentRowData={setUpdatedPaymentRowData}
          setDeletedPaymentRowData={setDeletedPaymentRowData}
          rowKey={rowKey}
          paymentSelectsOptions={paymentSelectsOptions}
          claimLandingClaimant={claimLandingClaimant}
          setIsVoidedRefundOrMoveModalConfig={
            setIsVoidedRefundOrMoveModalConfig
          }
        />
      )}
      {isVoidedRefundOrMoveModalConfig?.movePayment === true && (
        <ModalMovePayment
          claimId={0}
          setIsVoidedRefundOrMoveModalConfig={
            setIsVoidedRefundOrMoveModalConfig
          }
          isVoidedRefundOrMoveModalConfig={isVoidedRefundOrMoveModalConfig}
        />
      )}
    </>
  );
};
export default ModalEditPaymentApproval;
