import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { AuditResultsUIProps } from "../AuditForm/AuditTypes";
import { AuditResultDispositionEnum } from "../../../../dtos/audit-result-disposition-enum";
import { SelectOptions } from "../../../../dtos/select-options";
import {
  updateAuditResultsInPolicyQuote,
  updatePolicyQuote,
} from "../updatesPolicyQuoteFunctions";
import { PolicyAuditResultsBlob } from "../../../../dtos/policy-audit-results-blob";
import { addDays, newDate } from "../../../../utilities/dateFunctions";

export const AUDIT_DISPOSITION = "auditDisposition";

const AUDIT_DISPOSITION_FOR_PREMIUM_DUE = [
  AuditResultDispositionEnum.INVOICE,
  AuditResultDispositionEnum.WRITE_OFF,
  AuditResultDispositionEnum.FLAT,
];
const AUDIT_DISPOSITION_FOR_NON_PREMIUM_DUE = [
  AuditResultDispositionEnum.CREDIT,
  AuditResultDispositionEnum.REFUND,
  AuditResultDispositionEnum.FLAT,
];

const getAuditDispositionList = (
  auditDispositionList: Partial<SelectOptions>[],
  premiumIsDue: boolean
) => {
  if (premiumIsDue) {
    return auditDispositionList.filter((option) =>
      AUDIT_DISPOSITION_FOR_PREMIUM_DUE.includes(option.intValue ?? -1)
    );
  }

  return auditDispositionList.filter((option) =>
    AUDIT_DISPOSITION_FOR_NON_PREMIUM_DUE.includes(option.intValue ?? -1)
  );
};

const getAuditDisposition = (
  auditResults: number,
  programAuditFlatValue: number,
  premiumIsDue: boolean
) => {
  if (auditResults > programAuditFlatValue && premiumIsDue) {
    return AuditResultDispositionEnum.INVOICE;
  }
  if (auditResults > programAuditFlatValue && !premiumIsDue) {
    return AuditResultDispositionEnum.CREDIT;
  }

  return AuditResultDispositionEnum.FLAT;
};

export const getAuditResultsUIDefaultValues = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
  const auditConfiguration = policyJSON?.auditConfiguration;
  const auditResults =
    (policyJSON?.policyTotal ?? 0) -
    (auditConfiguration?.totalPaidByBillingTransactions ?? 0);
  const currentDate = newDate();

  const dateBilled =
    auditConfiguration?.auditValues?.dateCompleted ?? currentDate;
  const dueDate = addDays(dateBilled, policyJSON?.payPlan?.auditDueDays ?? 0);
  const lateDate = addDays(dateBilled, policyJSON?.payPlan?.auditLateDays ?? 0);
  const auditDisposition = getAuditDisposition(
    auditResults,
    policyJSON?.program?.auditFlatValue ?? 0,
    auditResults > 0
  );

  const defaultAuditUIValue = {
    auditPremium: policyJSON?.totalPremium ?? 0,
    auditFees: policyJSON?.totalFees ?? 0,
    totalAudit: policyJSON?.policyTotal ?? 0,
    totalPaid: auditConfiguration?.totalPaidByBillingTransactions ?? 0,
    auditResults,
    auditDisposition,
    invoiceNumber:
      policyJSON?.auditResults?.invoiceNumber ??
      auditConfiguration?.auditValues?.invoiceNumber ??
      "TBD",
    dateBilled,
    dueDate,
    lateDate,
    auditDispositionList: getAuditDispositionList(
      auditConfiguration?.auditDispositionList ?? [],
      auditResults > 0
    ),
    displayExtraInvoiceInformation:
      auditResults > 0 &&
      auditDisposition === AuditResultDispositionEnum.INVOICE,
  } as AuditResultsUIProps;

  return defaultAuditUIValue;
};

export const getAuditResultsUIByAtomValues = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
  const auditResultsJSON = policyJSON?.auditResults;
  const auditResults = auditResultsJSON?.auditResults ?? 0;
  const auditDisposition = auditResultsJSON?.auditDisposition?.value;
  const auditConfiguration = policyJSON?.auditConfiguration;

  const newAuditResultsUI = {
    auditPremium: policyJSON?.totalPremium ?? 0,
    auditFees: policyJSON?.totalFees ?? 0,
    totalAudit: policyJSON?.policyTotal ?? 0,
    totalPaid: auditResultsJSON?.totalPaid,
    auditResults,
    auditDisposition,
    invoiceNumber:
      auditResultsJSON?.invoiceNumber ??
      auditConfiguration?.auditValues?.invoiceNumber ??
      "TBD",
    dateBilled: auditResultsJSON?.dateBilled,
    dueDate: auditResultsJSON?.dueDate,
    lateDate: auditResultsJSON?.lateDate,
    auditDispositionList: getAuditDispositionList(
      auditConfiguration?.auditDispositionList ?? [],
      auditResults > 0
    ),
    displayExtraInvoiceInformation:
      auditResults > 0 &&
      auditDisposition === AuditResultDispositionEnum.INVOICE,
  } as AuditResultsUIProps;

  return newAuditResultsUI;
};

export const getAtomUpdatedByAuditResultsUI = (
  auditResultUI: AuditResultsUIProps,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const oldAuditResultsJSON =
    atomValue?.policyQuoteInformation?.policyQuote?.auditResults;

  const newAuditResultsJSON = {
    totalAudit: auditResultUI.totalAudit,
    totalPaid: auditResultUI.totalPaid,
    auditResults: auditResultUI.auditResults,
    auditDisposition: {
      value: auditResultUI.auditDisposition,
      description: auditResultUI.auditDispositionList.find(
        (ad) => ad.intValue === auditResultUI.auditDisposition
      )?.displayName,
    },
    invoiceNumber: auditResultUI.invoiceNumber,
    dateBilled: auditResultUI.dateBilled,
    dueDate: auditResultUI.dueDate,
    lateDate: auditResultUI.lateDate,
    invoiceID: oldAuditResultsJSON?.invoiceID,
    billingTransactions: oldAuditResultsJSON?.billingTransactions,
  } as PolicyAuditResultsBlob;

  const newAtomValue = updatePolicyQuote(
    atomValue,
    "auditResults",
    newAuditResultsJSON
  );

  return newAtomValue;
};

export const getAtomUpdatedByChangedValues = (
  targetValue: any,
  targetName: string,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const newAtomValue = updateAuditResultsInPolicyQuote(
    atomValue,
    targetName,
    targetValue
  );
  return newAtomValue;
};
