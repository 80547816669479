import {
  Box,
  Grow,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  Popover,
  Typography,
} from "@mui/material";
import { useLocation, Location } from "react-router-dom";
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useApiGet } from "../../../hooks";
import { getEnumValue } from "../../../utilities/enumFunctions";
import { InsuredStatusEnum } from "../../../dtos/insured-status-enum";
import { ClaimStatusEnums } from "../../../dtos/claim-status-enums";
import { useRecoilState, useRecoilValue } from "recoil";
import { SamePageClickedAtom } from "./SearchAtoms";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import {
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  CLAIM_NUMBER,
  OPEN_FROM_SEARCH,
} from "../../../utilities/queryStringsHash";
import {
  menuStyles,
  searchStyle,
  SearchStyle,
  StyledInputBase,
} from "./TopNavStyles";
import themes from "../../../media/TrueTheme";
import { globalOptions } from "../../../GlobalAtoms";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import { Font } from "../../TrueUI";
import { SearchAltIcon } from "../../TrueUI/Icons/CustomIcons";
import { Theme } from "../../../media/themeTypes";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

type SearchProperties = {
  customHashPath?: string;
  insuredOnly?: boolean;
  searchOnEnterOnly?: boolean;
};

type ResultsState = {
  isOpen: boolean;
  noSearchResults: boolean;
  currentResults: any[] | null;
};

const Search: FC<SearchProperties> = ({
  customHashPath = "",
  insuredOnly = false,
  searchOnEnterOnly = false,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [resultsState, setResultsState] = useState<ResultsState>({
    isOpen: false,
    noSearchResults: false,
    currentResults: null,
  });
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const [samePageClicked, setSamePageClicked] =
    useRecoilState(SamePageClickedAtom);
  const location = useLocation();

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const localOptions = useRecoilValue(globalOptions);
  const theme: Theme = themes[localOptions?.themeRefresh];

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (searchOnEnterOnly) {
      setResultsState({ ...resultsState, isOpen: false, currentResults: null });
    }
    setSearchText(e.target.value);
  };

  const searchEndpoint = insuredOnly ? "SearchInsuredText" : "SearchText";
  const url = `api/QuickSearchInsuredClaim/${searchEndpoint}?text=${encodeURIComponent(
    searchText.trim()
  )}`;

  const { responseGet, dispatchGet } = useApiGet<any>(url, {
    disablePropertyUpdaters: true,
  });

  const searchOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.repeat) return;
    const enterCodes = ["Enter", "NumpadEnter"];
    if (searchOnEnterOnly) {
      if (enterCodes.includes(e.code) && searchText.trim() !== "") {
        dispatchGet();
      }
    }
  };

  useEffect(() => {
    if (
      !searchOnEnterOnly &&
      searchText !== null &&
      searchText.trim() !== "" &&
      searchText.trim().length >= 3
    ) {
      dispatchGet();
    }
  }, [searchText]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (responseGet?.responseData?.length > 0) {
        setResultsState({
          ...resultsState,
          isOpen: true,
          noSearchResults: false,
          currentResults: responseGet.responseData,
        });
      } else {
        setResultsState({
          ...resultsState,
          isOpen: false,
          noSearchResults: true,
          currentResults: [],
        });
      }
    }
  }, [responseGet]);

  useEffect(() => {
    if (location?.pathname === currentLocation?.pathname) {
      if (location?.hash !== currentLocation?.hash) {
        if (location.hash.includes(OPEN_FROM_SEARCH)) {
          window.location.reload();
        }
      }
    }
    setCurrentLocation(location);
  }, [location]);

  const getCodeAndName = (result) => {
    switch (result.responseDescription) {
      case "Claim Number":
      case "Claimant Name":
        return `${result.claimNumber} - ${result.claimantName}`;
      case result.insuredCode === null && result.ClaimNumber === null:
        return result.insuredName;
      default:
        return `${result.insuredCode ?? ""} - ${result.insuredName ?? ""}`;
    }
  };

  const getProgram = (result) =>
    result.insuredProgram !== null
      ? result.insuredProgram
      : result.claimProgram;

  const getStatus = (result) =>
    result.insuredStatus !== null && result.insuredStatus !== undefined
      ? getEnumValue(result.insuredStatus.toString(), InsuredStatusEnum)
      : getEnumValue(result.claimStatus.toString(), ClaimStatusEnums);

  const checkIfIsOnSamePage = (path: string) => {
    if (path === location.pathname) {
      setSamePageClicked(!samePageClicked);
    }
  };
  const getPath = (result) => {
    if (customHashPath !== "") {
      return `/insured/${result.insuredId + customHashPath}`;
    }

    return result.responseDescription !== "Claimant Name" &&
      result.responseDescription !== "Claim Number"
      ? `/insured/${result.insuredId}`
      : `/insured/${
          result.insuredId +
          getQueryStringsURL([
            {
              nameOfHash: INSURED_PARENT_ITEM,
              valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
            },
            {
              nameOfHash: INSURED_CHILD_ITEM,
              valueOfHash: SubSideNavChildrenIndexEnum.INSURED_INFORMATION,
            },
            {
              nameOfHash: INSURED_BODY_SECTION,
              valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
            },
            {
              nameOfHash: CLAIM_NUMBER,
              valueOfHash: result.claimNumber,
            },
            {
              nameOfHash: OPEN_FROM_SEARCH,
              valueOfHash: 1,
            },
          ])
        }`;
  };
  
  return (
    <div ref={searchContainerRef}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchStyle sx={searchStyle(theme)}>
          <StyledInputBase
            className="input-base"
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearchTextChange}
            onKeyDown={searchOnEnter}
            autoFocus
          />
        </SearchStyle>
        <IconButton
          sx={{ padding: "0 5px !important" }}
          onClick={() => {
            dispatchGet();
          }}
        >
          <SearchAltIcon sx={{ color: theme?.PRIMARY, fontSize: "27px" }} />
        </IconButton>
      </div>
      {resultsState.currentResults &&
        (searchOnEnterOnly || searchText.trim().length >= 3) && (
          <Menu
            anchorEl={searchContainerRef.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            id={"primary-notifications-menu"}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={3}
            open={resultsState.isOpen}
            onClose={() => setResultsState({ ...resultsState, isOpen: false })}
            sx={menuStyles(theme)}
            disableAutoFocus
            TransitionComponent={Grow as any}
          >
            <Box className={"true_container_list"}>
              {resultsState.currentResults.length >= 50 && (
                <Typography>Please refine your search criteria</Typography>
              )}
              <List dense className="result-list">
                {resultsState.currentResults.length > 0 &&
                  resultsState.currentResults.map((result, i) => {
                    return (
                      <ListItemButton
                        href={getPath(result)}
                        key={i}
                        onClick={() => {
                          setResultsState({ ...resultsState, isOpen: false });
                          checkIfIsOnSamePage(getPath(result));
                        }}
                        component={Link}
                      >
                        <ListItemText
                          className={"true_first_row_typography"}
                          primary={getCodeAndName(result)}
                          secondary={`${result.responseDescription} - ${result.searchText}`}
                        />
                        <ListItem className={"second-row-typography"}>
                          <Font>{getProgram(result)}</Font>
                          <Font>{getStatus(result)}</Font>
                        </ListItem>
                      </ListItemButton>
                    );
                  })}
              </List>
            </Box>
          </Menu>
        )}

      {resultsState.currentResults?.length === 0 && (
        <Popover
          id={"true_not_found_results_message"}
          anchorEl={searchContainerRef.current}
          open={resultsState.noSearchResults}
          onClose={() =>
            setResultsState({ ...resultsState, noSearchResults: false })
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            mt: 6,
          }}
          elevation={3}
          keepMounted
          disableAutoFocus
          TransitionComponent={Grow as any}
        >
          <Typography sx={{ p: 2 }}>
            Sorry... We couldn't find any matches for <b>{searchText}</b>
          </Typography>
        </Popover>
      )}
    </div>
  );
};

export default Search;
