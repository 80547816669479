import { FC } from "react";
import { BasePageContainer } from "../../TrueUI";
import ProgramMainInstanceWrapper from "./ProgramMainInstanceWrapper";

const ProgramMain: FC = () => {
  return (
    <BasePageContainer
      id={"programMain_container"}
      maxWidth={false}
      sxWrapper={{ height: "100%" }}
      sxBody={{
        height: "100%",
        display: "flex",
        alignContent: "stretch",
        flexDirection: "row",
      }}
      data
    >
      <ProgramMainInstanceWrapper />
    </BasePageContainer>
  );
};

export default ProgramMain;
