import { useEffect, useState } from "react";
import { BaseTableFilterOptions } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useRecoilValue } from "recoil";
import { TypeFilterAtom } from "../MailRoomAtoms";
import { SelectOptions } from "../../../dtos/select-options";
import { addAllOption } from "../../../utilities/selectFunctions";
import { Select } from "../../TrueUI";
import { DROPDOWN_FILTER } from "../../TrueUI/Tables/TableConstants";

type MailRoomCategoryFilterProps = {
  actionOptions: BaseTableFilterOptions;
  configurationState: any;
};

const MailRoomCategoryFilter: (
  properties: MailRoomCategoryFilterProps
) => JSX.Element = ({ actionOptions, configurationState }) => {
  const [categoryOptions, setCategoryOptions] = useState<
    Partial<SelectOptions>[]
  >(addAllOption([]));
  const [categoryFilter, setCategoryFilter] = useState("all");

  const type = useRecoilValue(TypeFilterAtom);

  const updateCategoryOptions = () => {
    if (type === "all") setCategoryOptions(addAllOption([]));
    else {
      const categories =
        type === "Insured"
          ? addAllOption(configurationState?.insuredCategoryOptions ?? [])
          : addAllOption(configurationState?.claimCategoryOptions ?? []);

      setCategoryOptions(categories);
    }
  };

  const setFilter = () => {
    actionOptions?.setFilterInclude?.([
      {
        filterName: DROPDOWN_FILTER,
        columnNames: ["Category"],
        filterValue: categoryFilter,
      },
    ]);
  };

  useEffect(() => {
    setCategoryFilter("all");
    updateCategoryOptions();
  }, [type]);

  useEffect(() => {
    setFilter();
  }, [categoryFilter]);

  return (
    <Select
      id="mail-category"
      name={"category-dropdown"}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={"Category:"}
      labelPosition={"start"}
      options={categoryOptions}
      value={categoryFilter ?? "all"}
      type={"tableFilter"}
      inputWidth={"210px"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => setCategoryFilter(value)}
    />
  );
};

export default MailRoomCategoryFilter;
