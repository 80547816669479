import { FC, useEffect, useState, useCallback, useRef } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { usePermissions } from "../../../../hooks";
import Font from "../../Typography/Font";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontsType } from "../../../../media/themeTypes";
import { KeysAsType } from "../../../../types/KeysAsAType";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  getCoordsForOptionContainer,
  setCurrentElementOption,
  getPositionTopByOptionsPosition,
  updateOptionWidthToLargestOptionWidth,
  isAcceptKey,
  isCancelKey,
  getSelectedOptionIndexByKeyPress,
} from "../SelectUtils";
import { getVariant } from "../../Inputs/InputUtil";
import {
  TABBABLE_CLASS_NAME,
  focusNextElement,
} from "../../../../utilities/tabFunctions";
import style from "./multiSelect.module.css";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import SelectTag from "./SelectTag";
import ClearAllIcon from "./ClearAllIcon";
import { FocusedSelectOptionProps } from "../Select";

export type MultiSelectProperties = {
  id: string;
  name: string;
  trueElement?: string;
  label?: string;
  className?: string;
  errorMessage?: string[] | null;
  labelPosition?: "start" | "end" | "top" | "bottom";
  values?: any[];
  options: SelectOptions[] | Partial<SelectOptions>[];
  readOnly?: boolean;
  disabled?: boolean;
  variant?: "filled" | "standard";
  permissions?: PermissionsEnums[];
  helperText?: string;
  inputWidth?: string;
  optionsMaxHeight?: string;
  labelFontType?: KeysAsType<FontsType>;
  labelTextAlign?: "start" | "center" | "end";
  isCustomArrow?: boolean;
  optionsContainerPosition?: "bottom" | "top";
  type?: "standard" | "tableFilter" | "tableCell";
  tagsType?: "greenTags" | "grayTags";
  onChange?: (e: any) => void;
  tabIndex?: number;
  title?: string;
  customSx?: any;
  showClearAll?: boolean;
};

const MultiSelect: FC<MultiSelectProperties> = ({
  id = "",
  name = "",
  trueElement,
  label,
  className,
  errorMessage,
  labelPosition = "top",
  values = [],
  options,
  readOnly,
  disabled = false,
  variant,
  permissions,
  helperText,
  inputWidth = "100%",
  optionsMaxHeight = "auto",
  labelFontType = "BOLD_BODY",
  labelTextAlign = "start",
  isCustomArrow = false,
  optionsContainerPosition = "bottom",
  type = "standard",
  tagsType = "grayTags",
  onChange,
  tabIndex = 0,
  title = "",
  customSx,
  showClearAll = false,
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);

  const [selectedValues, setSelectedValues] = useState<any[]>(values);
  const [focusedSelectOption, setFocusedSelectOption] =
    useState<FocusedSelectOptionProps>({ index: -1, keySelected: "" });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isThereError, setIsThereError] = useState(false);
  const [optionContainerWidth, setOptionContainerWidth] = useState(0);

  const hasPermission = usePermissions(permissions ?? []);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const isDisabled = disabled || !hasPermission.hasPermission;

  const SELECT_ITEM_CLASS = `select_item_option_${id}`;

  useEffect(() => {
    function setOptionsWidthByWindowsResize() {
      setOptionContainerWidth(selectRef?.current?.clientWidth ?? 0);
    }

    window.addEventListener("resize", setOptionsWidthByWindowsResize);

    return () => {
      window.removeEventListener("resize", setOptionsWidthByWindowsResize);
    };
  }, []);

  const getValueFromOption = (option) => {
    return (
      option?.intValue ??
      option?.stringValue ??
      option?.decimalValue ??
      option?.dateValue ??
      option?.booleanValue ??
      null
    );
  };

  const isOptionSelected = (option) => {
    return selectedValues?.includes(option);
  };

  const updateSelectedValues = (newOption) => {
    const existentValue = selectedValues?.find(
      (value) => getValueFromOption(value) === getValueFromOption(newOption)
    );
    if (conditionHasValue(existentValue)) {
      const filteredValues = selectedValues.filter(
        (option) => getValueFromOption(option) !== getValueFromOption(newOption)
      );
      setSelectedValues(filteredValues);
      onChange?.(filteredValues);
    }
    if (!conditionHasValue(existentValue)) {
      onChange?.([...selectedValues, newOption]);
      setSelectedValues([...selectedValues, newOption]);
    }
  };

  const removeSelectedValues = (e, value) => {
    if (readOnly || isDisabled) return;
    e.stopPropagation(); //this is used to avoid the parent container from triggerting the click event
    const filteredValues = selectedValues.filter(
      (option) => getValueFromOption(option) !== value
    );
    setSelectedValues(filteredValues);
    onChange?.(filteredValues);
  };

  const getErrorHelper = useCallback(() => {
    if (conditionHasValue(errorMessage)) {
      setIsThereError(true);
    } else {
      setIsThereError(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    getErrorHelper();
  }, [errorMessage, getErrorHelper]);

  useEffect(() => {
    if (values !== selectedValues) {
      setSelectedValues(values);
    }
  }, [values, options]);

  useEffect(() => {
    updateOptionWidthToLargestOptionWidth(
      isOpen,
      selectRef,
      options,
      setOptionContainerWidth,
      setFocusedSelectOption,
      true
    );
  }, [isOpen]);

  useEffect(() => {
    setCurrentElementOption(SELECT_ITEM_CLASS, focusedSelectOption.index);
  }, [focusedSelectOption]);

  const onSelectedOption = (option: Partial<SelectOptions>) => {
    updateSelectedValues(option);
    setIsOpen(!isOpen);
  };

  const onOptionKeyPressEvent = (event, option) => {
    if (isAcceptKey(event) && isOpen === true) onSelectedOption(option);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
      setIsOpen(false);
      focusNextElement(id);
    }
    if (
      event.code === "ArrowDown" &&
      isOpen === true &&
      focusedSelectOption.index < options.length - 1
    ) {
      setFocusedSelectOption({
        ...focusedSelectOption,
        index: focusedSelectOption.index + 1,
      });
    }
    if (
      event.code === "ArrowUp" &&
      isOpen === true &&
      focusedSelectOption.index > 0
    ) {
      setFocusedSelectOption({
        ...focusedSelectOption,
        index: focusedSelectOption.index - 1,
      });
    }

    if (event.key.length === 1 && event.key.match(/[a-zA-Z0-9]/)) {
      setFocusedSelectOption(
        getSelectedOptionIndexByKeyPress(event, options, focusedSelectOption)
      );
    }
  };

  const onContainerKeyPressEvent = (event) => {
    if (isAcceptKey(event)) setIsOpen(!isOpen);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
    }
    if (event.code === "ArrowDown" && isOpen === false)
      if (isOpen === false) setIsOpen(true);
  };

  const inputProps = {
    id: id,
    name: name,
    "data-true-element": trueElement
      ? `data-true-select-options-${trueElement}`
      : `data-true-select-options-${name}`,
  };

  const inputSize = theme?.BODY.SIZE;
  const labelSize = label ? theme?.[labelFontType].SIZE : "0px";

  const getStyleType = () => {
    switch (type) {
      case "tableFilter":
        return "true_select_options_container_table_filter";
      case "tableCell":
        return "true_select_options_container_table_cell";
      default:
        return "";
    }
  };

  const GetStyleLabelPosition = () => {
    switch (labelPosition) {
      case "start":
        return style.true_input_container_start;
      case "top":
        return style.true_input_container_top;
      case "end":
        return style.true_input_container_end;
      case "bottom":
        return style.true_input_container_bottom;
      default:
        return "";
    }
  };

  const getMappedSelectedValues = () => {
    return (
      <div className={style.multiSelect_tags_container}>
        {selectedValues.map((value, idx) => (
          <SelectTag
            name={`${name}-${idx}`}
            type={tagsType}
            label={value?.displayName}
            hideRemoveButton={readOnly || isDisabled}
            onRemove={(e) => removeSelectedValues(e, getValueFromOption(value))}
          />
        ))}
      </div>
    );
  };

  const getOptionContainer = () => {
    const { top, left } = getCoordsForOptionContainer(selectRef.current);

    return isOpen && !readOnly && !isDisabled ? (
      <div
        className={style.true_select_option_container_paper}
        onClick={isOpen ? () => setIsOpen(!isOpen) : () => {}}
      >
        <div
          className={`${style.true_select_options_container} ${getStyleType()}`}
          style={{
            transform: `translateY(calc(${inputSize} + ${labelSize} + ${
              label ? "23px" : "17px"
            }))`,
            maxHeight: optionsMaxHeight,
            width: `${optionContainerWidth}px`,
            top: getPositionTopByOptionsPosition(
              top,
              options?.length ?? 0,
              optionsContainerPosition,
              labelPosition,
              conditionHasValue(label),
              labelRef?.current?.offsetHeight ?? 0,
              optionsMaxHeight.includes("px")
                ? parseInt(optionsMaxHeight.split("px")[0])
                : null
            ),
            left: left,
            height: "auto",
          }}
          data-true-element={
            trueElement
              ? `data-true-select-options-${trueElement}`
              : `data-true-select-options-${name}`
          }
        >
          {options?.map((option) => (
            <button
              className={`${
                option?.displayName === "" ? style.empty_option : ""
              } ${style.true_select_option} ${SELECT_ITEM_CLASS} ${
                isOptionSelected(getValueFromOption(option))
                  ? style.true_select_option_is_selected
                  : ""
              } ${
                option?.displayName === ""
                  ? style.true_select_option_empty_option
                  : ""
              }`}
              key={`true-select-option${getValueFromOption(option)}`}
              onClick={() => onSelectedOption(option)}
              onKeyUp={(e) => onOptionKeyPressEvent(e, option)}
              title={option?.displayName}
            >
              <Font fontType="BODY">{option?.displayName}</Font>
            </button>
          ))}
        </div>
      </div>
    ) : null;
  };

  const clearAllOptions = () => {
    setSelectedValues([]);
  };

  return (
    <>
      <div
        className={`${style.true_input_general_container} select_true_input_general_container`}
        style={{
          ...customSx,
          width: `${
            labelPosition === "start" || labelPosition === "end"
              ? "fit-content"
              : inputWidth
          } !important`,
        }}
      >
        <div
          className={`${GetStyleLabelPosition()} select_true_input_container`}
          style={{
            width: `${
              labelPosition === "start" || labelPosition === "end"
                ? "fit-content"
                : inputWidth.includes("%")
                ? "100%"
                : inputWidth
            } !important`,
          }}
        >
          {conditionHasValue(label) && (
            <div
              className={style.true_input_label_container_for_select}
              ref={labelRef}
            >
              <Font
                name={name}
                trueElement={
                  trueElement ? trueElement : `true-element-select-${name}`
                }
                className={`${style.label_for_select} true-input-label ${
                  labelFontType === undefined ? "withoutSize" : ""
                }`}
                fontType={labelFontType}
                textAlign={labelTextAlign}
              >
                {label}
              </Font>
            </div>
          )}
          <div
            {...inputProps}
            tabIndex={tabIndex}
            className={`${
              style.true_input_select_container
            } ${TABBABLE_CLASS_NAME} ${
              getVariant(readOnly, variant) === "filled" ? style.filled : ""
            } ${errorMessage != null ? style.true_input_error_txt : ""} ${
              readOnly ? style.true_input_select_container_read_only : ""
            } ${
              isDisabled ? style.true_input_select_container_is_disabled : ""
            } ${
              isThereError ? style.true_input_select_container_has_error : ""
            } ${className} ${localOptions?.themeRefresh} ${
              isOpen && !readOnly && !isDisabled
                ? style.true_input_select_container_open
                : "closed"
            } ${getStyleType()}`}
            style={{
              width: inputWidth.includes("%") ? "100%" : inputWidth,
            }}
            title={title}
            onClick={() => !readOnly && setIsOpen(!isOpen)}
            onKeyUp={(e) => onContainerKeyPressEvent(e)}
            ref={selectRef}
          >
            {getMappedSelectedValues()}
            <div className={style.select_arrow_container}>
              {!readOnly &&
                !isDisabled &&
                (isCustomArrow ? (
                  <KeyboardArrowDownIcon
                    className={`${style.select_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className={`${style.select_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ))}
            </div>
          </div>
          {!readOnly &&
            !isDisabled &&
            showClearAll &&
            (selectedValues?.length ?? 0) > 0 && (
              <ClearAllIcon onClick={() => clearAllOptions()} />
            )}
          {conditionHasValue(errorMessage) && !isOpen && (
            <span className={style.true_input_error_txt}>
              {errorMessage?.join(" ")}
            </span>
          )}
        </div>
        {helperText && (
          <span className="true_input_helper_text">{helperText}</span>
        )}
        {getOptionContainer()}
      </div>
    </>
  );
};

export default MultiSelect;
