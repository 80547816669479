import { FC } from "react";
import { Col, Divider, InputMemo, Row, Switch } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import "./claimSwitchOptions.module.css";
import { ClaimSwitchActionsProps } from "./ClaimOptionsProps";

const ClaimSwitchActions: FC<ClaimSwitchActionsProps> = ({
  claimStatusUpdates,
  setClaimStatusUpdates,
  errorTextNote,
}) => {
  return (
    <div className="claim_switch_custom_styles">
      <Divider height={45} />
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
        <Col
          breakpoints={{ md: 11, lg: 11, xl: 11 }}
          {...colWithNoMarginNorGutter}
        >
          <Row {...rowWithNoMarginNorGutter} rowDirection="column">
            <Col horizontalAlign="flex-start" {...colWithNoMarginNorGutter}>
              <Switch
                control="switch"
                name="claimAddNote"
                label="Add Note"
                labelPlacement="start"
                isChecked={claimStatusUpdates?.addNoteFlag ?? true}
                onChangeIsChecked={(value) => {
                  setClaimStatusUpdates({
                    ...claimStatusUpdates,
                    addNoteFlag: value,
                  });
                }}
              />
            </Col>
            <Col horizontalAlign="flex-start" {...colWithNoMarginNorGutter}>
              <Switch
                control="switch"
                name="claimCompleteTasks"
                label="Complete Tasks"
                labelPlacement="start"
                isChecked={claimStatusUpdates?.closeTasksFlag ?? true}
                onChangeIsChecked={(value) => {
                  setClaimStatusUpdates({
                    ...claimStatusUpdates,
                    closeTasksFlag: value,
                  });
                }}
              />
            </Col>
            <Col horizontalAlign="flex-start" {...colWithNoMarginNorGutter}>
              <Switch
                control="switch"
                name="adjustTotalReserves"
                label="Adjust Reserves - Closing"
                labelPlacement="start"
                isChecked={claimStatusUpdates?.adjustTotalReservesFlag ?? true}
                onChangeIsChecked={(value) => {
                  setClaimStatusUpdates({
                    ...claimStatusUpdates,
                    adjustTotalReservesFlag: value,
                  });
                }}
              />
            </Col>
            <Col horizontalAlign="flex-start" {...colWithNoMarginNorGutter}>
              <Switch
                control="switch"
                name="add-reserves"
                label="Add Reserves - Reopen"
                labelPlacement="start"
                isChecked={claimStatusUpdates?.addReservesFlag ?? true}
                readOnly={claimStatusUpdates?.isAddReservesFlagReadonly}
                onChangeIsChecked={(value) => {
                  setClaimStatusUpdates({
                    ...claimStatusUpdates,
                    addReservesFlag: value,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col breakpoints={{ md: 13, lg: 13, xl: 13 }}>
          <InputMemo
            id="claim_status_note"
            name="claimStatusNote"
            label="Note Text"
            value={claimStatusUpdates?.note ?? true}
            onChangeRawValue={(note) => {
              setClaimStatusUpdates({
                ...claimStatusUpdates,
                note: note,
              });
            }}
            errorMessage={errorTextNote}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ClaimSwitchActions;
