import { FC } from "react";
import { Box } from "@mui/material";
import "./information_container.module.css";

type InformationContainerProps = {
  children: any;
  title?: React.ReactNode | string;
  className?: string;
};

const InformationContainer: FC<InformationContainerProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <Box className={`${className} true_information_container`}>
      <Box className={"true_title_container"}>{title}</Box>
      {children}
    </Box>
  );
};

export default InformationContainer;
