import { FC } from "react";
import { ProgramBillingDto } from "../../../../dtos/program-billing-dto";
import { Col, Input, InputAddress, InputPhone } from "../../../TrueUI";
import { useRecoilValue } from "recoil";
import { AdminProgramErrorAtom } from "../ProgramAtoms";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";

type ProgramBillingProps = {
  programBillingData: ProgramBillingDto | any;
  setProgramBillingData: any;
  readOnly?: boolean;
  programBillingErrors?: any;
};

export const ProgramBilling: FC<ProgramBillingProps> = ({
  programBillingData,
  setProgramBillingData,
  readOnly = false,
  programBillingErrors,
}) => {
  const programAdminErrors = useRecoilValue(AdminProgramErrorAtom);
  return (
    <>
      {programBillingData ? (
        <Row {...rowWithNoMarginNorGutter} rowDirection="column">
          <Col>
            <Input
              tabIndex={55}
              id="billing-name"
              name="billing-name"
              label="Name"
              value={programBillingData?.billingName}
              readOnly={readOnly}
              className={readOnly ? "true_information_disabled" : ""}
              type={"text"}
              maxLength={100}
              onChangeRawValue={(value) => {
                setProgramBillingData({
                  ...programBillingData,
                  billingName: value,
                });
              }}
            />
          </Col>
          <Col {...colWithNoMarginNorGutter}>
            <InputAddress
              tabIndex={56}
              id="program-billing-inputAddress"
              showAddress2={false}
              showSingleLabel={false}
              initValueAddress1={programBillingData?.billingAddress}
              initValueCity={programBillingData?.billingCity}
              initValueStateCode={programBillingData?.billingState}
              initValuePostCode={programBillingData?.billingPostalCode}
              onChangeValueAddress1={(value) =>
                setProgramBillingData({
                  ...programBillingData,
                  billingAddress: value,
                })
              }
              onChangeValueCity={(value) =>
                setProgramBillingData({
                  ...programBillingData,
                  billingCity: value,
                })
              }
              onChangeStateCode={(value) =>
                setProgramBillingData({
                  ...programBillingData,
                  billingState: value,
                })
              }
              onChangeValuePostCode={(value) =>
                setProgramBillingData({
                  ...programBillingData,
                  billingPostalCode: value,
                })
              }
              readOnly={readOnly}
              className={"inputAddress"}
              labelFontType="BOLD_CAPTION"
            />
          </Col>
          <Col>
            <Input
              tabIndex={63}
              id="billing-attention"
              name="billing-attention"
              label="Billing Attention/Contact"
              value={programBillingData?.billingAttention}
              readOnly={readOnly}
              className={readOnly ? "true_information_disabled" : ""}
              type={"text"}
              maxLength={50}
              onChangeRawValue={(value) => {
                setProgramBillingData({
                  ...programBillingData,
                  billingAttention: value,
                });
              }}
            />
          </Col>
          <Col>
            <Input
              tabIndex={64}
              id="billing-email"
              name="billing-email"
              label="Email Address"
              value={programBillingData?.billingEmail}
              readOnly={readOnly}
              className={readOnly ? "true_information_disabled" : ""}
              type={"email"}
              maxLength={100}
              onChangeRawValue={(value) => {
                setProgramBillingData({
                  ...programBillingData,
                  billingEmail: value,
                });
              }}
              errorMessage={programBillingErrors?.billingEmail}
            />
          </Col>
          <Row {...rowWithNoMarginNorGutter}>
            <Col>
              <InputPhone
                tabIndex={65}
                id="billing-phone"
                name="billing-phone"
                phoneLabel="Phone Number"
                phoneValue={programBillingData?.billingPhone}
                readOnly={readOnly}
                className={readOnly ? "true_information_disabled" : ""}
                errorMessagePhone={
                  programAdminErrors?.errorDetails?.programBilling?.billingPhone
                }
                onChangePhoneValue={(value) => {
                  setProgramBillingData({
                    ...programBillingData,
                    billingPhone: value,
                  });
                }}
              />
            </Col>
            <Col>
              <InputPhone
                tabIndex={66}
                id="billing-fax"
                name="billing-fax"
                phoneLabel="Fax Number"
                phoneValue={programBillingData?.billingFax}
                readOnly={readOnly}
                className={readOnly ? "true_information_disabled" : ""}
                errorMessagePhone={
                  programAdminErrors?.errorDetails?.programBilling?.billingFax
                }
                onChangePhoneValue={(value) => {
                  setProgramBillingData({
                    ...programBillingData,
                    billingFax: value,
                  });
                }}
              />
            </Col>
          </Row>
        </Row>
      ) : (
        <div> Loading ...</div>
      )}
    </>
  );
};
