import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ClaimDetailsTabDto } from "../../../../../../dtos/claim-details-tab-dto";
import { useApiPost, usePermissions } from "../../../../../../hooks";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { Button } from "../../../../../TrueUI";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadIsExpandedWithInformation } from "../spreadClaimTabsFunctions";
import { PermissionsEnums } from "../../../../../../dtos/permissions-enums";

type SaveContainersButtonProps = {
  insuredIdAtomKey: string | null;
  onErrorResponse?: (errors) => void;
};

const SaveContainersButton: FC<SaveContainersButtonProps> = ({
  insuredIdAtomKey,
  onErrorResponse,
}) => {
  const hasClaimsManagementPermissions = usePermissions([
    PermissionsEnums.TRUE_ADMIN,
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ])?.hasPermission;

  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [detailsContainersInfo, setDetailsContainersInfo] = useState<any>();
  const [hasOneContainerExpanded, setHasOneContainerExpanded] =
    useState<boolean>(false);

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<ClaimDetailsTabDto>(
      "api/Claims/SaveClaimDetailsTabData",
      detailsContainersInfo
    );

  const listenerButtonComponent = useRecoilValue(
    TriggerComponentUpdateAtom("buttonComponent")
  );

  useEffect(() => {
    if (listenerButtonComponent !== null) {
      const claimPageState = getAtom();
      if (hasClaimsManagementPermissions) {
        setHasOneContainerExpanded(
          CheckIfHasOneContainerExpanded(claimPageState)
        );
      }
    }
  }, [listenerButtonComponent]);

  const CheckIfHasOneContainerExpanded = (claimPageState: any) =>
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.detailsContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.claimantContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.incidentContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.wagesContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.bodyPartContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.dependentsContainerData?.isExpanded ||
    claimPageState?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.customFieldsContainerData?.isExpanded;

  const buttonCustomStyles = () =>
    hasOneContainerExpanded
      ? {
          left: -12,
          bottom: 10,
          zIndex: 999,
          marginTop: "10px",
        }
      : {
          zIndex: -1,
          opacity: 0,
        };

  const saveContainersInformation = () => {
    const detailsContainersInfoFromAtom =
      getAtom()?.claimTabsInformation?.claimDetailsTab
        ?.detailsContainersInformation;
    setDetailsContainersInfo(detailsContainersInfoFromAtom);
  };

  const closeExpandedContainersAfterSave = (
    detailsContainersInformation?: ClaimDetailsTabDto | null
  ) => {
    const claimPageState = getAtom();
    setAtom(
      spreadIsExpandedWithInformation(
        claimPageState,
        detailsContainersInformation,
        false
      )
    );

    setComponentTriggers([
      "wagesComponent",
      "bodyPartsComponent",
      "claimantComponent",
      "detailsComponent",
      "incidentComponent",
      "dependentsComponent",
      "buttonComponent",
      "claimBanner",
      "claimLandingPageComponent",
    ]);
  };

  useEffect(() => {
    if (detailsContainersInfo !== null && detailsContainersInfo !== undefined) {
      dispatchPost();
    }
  }, [detailsContainersInfo]);

  useEffect(() => {
    if (
      // responsePost.requestInstanceSuccessful &&
      // validatorErrorResponse === null
      isAPITotallyComplete(responsePost) &&
      validatorErrorResponse === null
    ) {
      closeExpandedContainersAfterSave(responsePost.responseData);
    }
  }, [responsePost]);

  useEffect(
    () => onErrorResponse?.(validatorErrorResponse?.errorDetails),
    [validatorErrorResponse]
  );

  return (
    <Button sx={buttonCustomStyles()} onClick={saveContainersInformation}>
      Save
    </Button>
  );
};

export default SaveContainersButton;
