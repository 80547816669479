import { useEffect, useState } from "react";
import TaskBoard, { SelectedCardType } from "../TrueUI/TaskBoard/TaskBoard";
import { useApiGet } from "../../hooks";
import { AuditPage } from "../../dtos/audit-page";
import { Heading4 } from "../TrueUI";
import style from "./Audit.module.css";
import { AuditAssignTypeEnum } from "../../dtos/audit-assign-type-enum";
import AuditAggregateTable from "./AuditAggregateTable";

const Audit = () => {
  const { responseGet, dispatchGet } = useApiGet<AuditPage>(
    `api/Audit/GetAuditCards`
  );
  const [cardInformation, setCardInformation] = useState<SelectedCardType>();
  const columns = responseGet?.axiosResponse?.data?.columns;

  const onClickCard = (cardInformation: any) => {
    setCardInformation(cardInformation);
  };

  const getAssignedType = () => {
    const assignedInfo = cardInformation?.cardInformation?.cardMetaData?.find(
      (metadata) => metadata?.name === "AssignedType"
    );
    return parseInt(assignedInfo?.value ?? "-1");
  };

  const getAssignedToValue = () => {
    const userId =
      cardInformation?.cardInformation?.cardMetaData?.find(
        (metadata) => metadata?.name === "UserId"
      )?.value ?? "";
    const teamId =
      cardInformation?.cardInformation?.cardMetaData?.find(
        (metadata) => metadata?.name === "TeamId"
      )?.value ?? "";
    const companyName =
      cardInformation?.cardInformation?.cardMetaData?.find(
        (metadata) => metadata?.name === "CompanyName"
      )?.value ?? "";
    const assignedType =
      cardInformation?.cardInformation?.cardMetaData?.find(
        (metadata) => metadata?.name === "AssignedType"
      )?.value ?? "";

    if (userId === "" && teamId === "" && companyName === "") return "all";

    switch (parseInt(assignedType)) {
      case AuditAssignTypeEnum.USER:
        return `${userId}_USER_TYPE`;
      case AuditAssignTypeEnum.TEAM:
        return `${teamId}_TEAM_TYPE`;
      default:
        return companyName;
    }
  };

  const InternalComponent = () => {
    return (
      <AuditAggregateTable
        defaultCardType={cardInformation?.cardInformation?.cardType ?? 0}
        defaultAssignedToValue={getAssignedToValue()}
        defaultAssignedType={getAssignedType()}
      />
    );
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <>
      {columns !== null && columns !== undefined && (
        <div className={style.audit_container}>
          <Heading4>Audits</Heading4>
          <div className={style.audit_task_board_container}>
            <TaskBoard
              columns={columns as any}
              InternalComponent={InternalComponent}
              onClickCard={onClickCard}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Audit;
