import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useApiPost, useApiGet } from "../../../hooks";
import { RecentItemDto } from "../../../dtos/recent-item-dto";
import { InsuredTabDto } from "../../../dtos/insured-tab-dto";
import { getEnumValue } from "../../../utilities/enumFunctions";
import { useRecoilValue } from "recoil";
import { Font, Heading4 } from "../../TrueUI";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { insuredPanelStyles } from "./InsuredPanelStyles";
import InsuredPanelSubHeaderSelector from "./InsuredPanelSubHeaderSelector";
import { InsuredStatusEnum } from "../../../dtos/insured-status-enum";

export type InsuredPanelProps = {
  insuredTab: InsuredTabDto;
  insuredBodySection: number | undefined;
  tabKey: string;
};

const InsuredPanel: FC<InsuredPanelProps> = ({
  insuredTab,
  tabKey,
  insuredBodySection,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [pinned, setPinned] = useState<any>(null);
  const [insuredId, setInsuredId] = useState(
    insuredTab?.insuredInformation?.insuredId
  );

  const { dispatchPost } = useApiPost<RecentItemDto>(
    "api/recents/SaveRecentInsuredPage",
    { id: insuredId, pinned: pinned }
  );
  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/recents/GetRecentByInsuredPage?insuredId=${insuredId}`
  );

  const checkRecentIsPinned = (response: any) => {
    return response?.responseData?.isPinned ?? false;
  };

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setPinned(checkRecentIsPinned(responseGet));
      setRotatePin(checkRecentIsPinned(responseGet));
    }
    dispatchPost();
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
    if (
      insuredTab?.insuredInformation?.insuredId !== null &&
      insuredTab?.insuredInformation?.insuredId !== undefined &&
      insuredTab?.insuredInformation?.insuredId > 0
    ) {
      setInsuredId(insuredTab?.insuredInformation?.insuredId * 1);
    }
  }, []);

  const [rotatePin, setRotatePin] = useState(false);
  const handleRotate = () => {
    setRotatePin(!rotatePin);
    setPinned(!rotatePin);
    dispatchPost();
  };

  return (
    <Box
      className={"insured_panel_inner_wrapper"}
      sx={insuredPanelStyles(theme)}
    >
      <Box className={"left-container"}>
        <Box className={"top-row"}>
          <PushPinOutlinedIcon
            onClick={handleRotate}
            className={rotatePin ? "pinned" : ""}
            true-element="true-element-pin-insured"
          />
          <Font name="insured-code" primaryColor>
            {insuredTab?.insuredInformation?.insuredCode
              ? insuredTab?.insuredInformation?.insuredCode
              : "unassigned"}
          </Font>
        </Box>

        <div>
          <Font name="insured-status">
            {getEnumValue(
              insuredTab?.insuredInformation.insuredStatus.toString(),
              InsuredStatusEnum
            )}
          </Font>
        </div>
        <div>
          <Font name="insured-program">
            {insuredTab?.insuredInformation.insuredProgram}
          </Font>
        </div>
      </Box>
      <Box className={"right-container"}>
        <Heading4
          className={"insured-name"}
          whiteSpace="nowrap"
          textAlign="start"
          trueElement="true-element-label-insured-name"
          display={"block"}
        >
          {insuredTab?.insuredInformation?.insuredName}
        </Heading4>
        <InsuredPanelSubHeaderSelector
          insuredTab={insuredTab}
          tabKey={tabKey}
          insuredBodySection={insuredBodySection}
        />
      </Box>
    </Box>
  );
};

export default InsuredPanel;
