import { useEffect, useMemo, useState } from "react";
import { atom, atomFamily, useRecoilCallback } from "recoil";
import { defaultConfigurationToolBar } from "../components/TrueUI/Tables/BaseTable2/BaseTable2Toolbar";
import {
  BaseTable2InstanceMethods,
  BaseTable2Properties,
  DeleteRowParameters,
  HydratedData,
  TableInstanceType2,
  TableName,
  UpdateRowParameters,
} from "../components/TrueUI/Tables/BaseTable2/TableProperties";
import { tableInstanceAtomFamily2 } from "../components/TrueUI/Tables/TableAtoms";
import {
  createNewDataItemWithDefaults,
  getExternalColumnsOnly,
  hydrateDataSingle,
} from "../components/TrueUI/Tables/tableFunctions";
import { getArrayByObjectOrArray } from "../utilities/arrayFunctions";
import { useBaseTableUtilities } from "./useBaseTableUtilities";

export type BaseTableKeyNameMapProperty = {
  uiid: string;
  name: string;
};

export const BaseTableIsReadyTrackerFamily = atomFamily<string | null, string>({
  key: "BaseTableTrackerFamily",
  default: null,
});

// we track the tableInstanceAtomFamily2 uiid and name because tableInstanceAtomFamily2 uses the uiid as a key
// which is automically generated but name is developer created so we use this as a key (of sorts) to access the tableInstanceAtomFamily2 object.
export const BaseTableKeyNameMapAtom = atom<BaseTableKeyNameMapProperty[]>({
  // TODO - move to atom file
  key: "BaseTableKeyNameMap",
  default: [],
});

const mappedTableInstanceType = (tableProperties: BaseTable2Properties) => {
  const mergedToolBarConfiguration = {
    ...defaultConfigurationToolBar,
    ...tableProperties.toolbarOptions,
  };
  // TODO - profile how many times this function runs
  return {
    name: tableProperties?.name ?? null,
    uiid: tableProperties?.uiid ?? null,
    getURL: tableProperties?.getURL ?? null,
    postURL: tableProperties?.postURL ?? null,
    postTarget: tableProperties?.postTarget ?? null,
    columns: tableProperties?.columnsAndData?.columns ?? [],
    data: tableProperties?.columnsAndData?.data ?? [],
    groups: [],
    parentMetaData: tableProperties?.parentMetaData ?? null,
    columnsAndData: tableProperties?.columnsAndData ?? null,
    columnsAndGroups: tableProperties?.columnsAndGroups ?? null,
    childrenData: tableProperties?.childrenData ?? null,
    refreshTable: false,
    changedData: [],
    multiChangedData: {},
    dataToBeSaved: [],
    deletedData: [],
    hydratedData: [],
    validationErrors: null,
    isPreProcessingComplete: false,
    isAdd: false,
    isEdit: tableProperties?.advancedOptions?.isEditMode ?? false,
    toggleEditModeState: false,
    isSave: false,
    gridWidth: 0,
    order: "asc",
    orderBy: null,
    tableType: tableProperties?.tableType ?? "standard",
    allRowKeys: [],
    toggleMultiTableRowCollapse:
      tableProperties?.advancedOptions?.multiTableProperties?.allRowsExpanded ??
      false,
    rowsPerPage: 100,
    paginate: tableProperties?.advancedOptions?.paginate ?? true,
    selectedPage: 0,
    searchValue: [],
    cellConfiguration: [],
    renderType: "standard",
    height:
      tableProperties?.advancedOptions?.tableStyle?.height ?? "parent_flex",
    marginBottom:
      tableProperties.advancedOptions?.tableStyle?.marginBottom ?? 5,
    _newestRowKey: null,
    filterOptions: tableProperties.filterOptions ?? null,
    multiSelectOptions: tableProperties.multiSelectOptions ?? null,
    listeners: tableProperties?.listeners ?? null,
    tableTypeProperties: tableProperties?.tableTypeProperties ?? null,
    advancedOptions: tableProperties?.advancedOptions ?? null,
    footerOptions: tableProperties?.footerOptions ?? null,
    methods: tableProperties?.events ?? null,
    events: tableProperties?.events ?? null,
    columnOptions: tableProperties?.columnOptions ?? null,
    isCellProcessingComplete: false,
    filterColumnValues: [],
    filterDataValues: [],
    sortedAndFilteredData: [],
    sortedAndFilteredDataWithOutPaginate: [],
    groupSortedAndFilteredData: [],
    interceptedChanges: null,
    hasDataChanged: false,
    selectedRow: null,
    rowClickTriggered: false,
    selectedEditRows: null,
    toolbarOptions: mergedToolBarConfiguration,
    selectedRows: [],
    allSelectedRows: tableProperties?.allSelectedRows ?? false,
    _recentlyUpdatedData: [],
    _recentlyAddedRowKey: [],
    _renderedCellManagerInstances: [],
    _inProcessOnInitComputesQueue: [],
    _inProcessComputesQueue: [],
    _initalInProcessComputesQueueStarted: false,
    _inProcessCell: null,
    _lastOriginComputeChainCellKey: null,
    _lastOnChangeInitiatorCellKey: null,
    _inProcessAPIResponse: null,
    _computeAPIData: [],
    _debug: {
      isDebug: false,
      showInternalColumnHeaders: false,
      startExecTimer: Math.round(performance.now() / 1000),
      endExecTimer: null,
    },
    autoFocusCellKey: null,
  } as TableInstanceType2;
};

export function useBaseTable<T = any>(
  initTableConfiguration: BaseTable2Properties<T> | BaseTable2Properties<T>[]
) {
  const { nameKeySynchronizer } = useBaseTableUtilities("hook");

  // for simplification, we can accept a single TableInstanceType2 object or an array of them but we always process it as an array.
  const tableConfigs = useMemo(() => {
    return getArrayByObjectOrArray<BaseTable2Properties>(
      initTableConfiguration
    );
  }, [initTableConfiguration]);

  // gets the table instance object (just TableInstanceType2, not the recoil RecoilState<TableInstanceType2> state)
  const getTableInstance = useRecoilCallback(
    ({ snapshot }) =>
      (uiid: string) => {
        return (
          (snapshot.getLoadable(tableInstanceAtomFamily2(uiid))
            .contents as TableInstanceType2) ?? null
        );
      },
    []
  );

  // sets the initial configuration state and updates the BaseTableKeyNameMap state so we can track the tableInstanceAtomFamily2.
  const setInitialTableInstance = useRecoilCallback(
    ({ set }) =>
      (tableInstance: BaseTable2Properties) => {
        const ll = mappedTableInstanceType(tableInstance);

        set(
          tableInstanceAtomFamily2(tableInstance.uiid ?? "NO_UIID_FOUND"),
          ll
        );

        set(
          BaseTableIsReadyTrackerFamily(tableInstance.name),
          tableInstance.uiid ?? "NO_UIID_FOUND"
        );
      },
    []
  );

  const updateTableInstance = useRecoilCallback(
    ({ set }) =>
      (tableInstance: TableInstanceType2) => {
        set(
          tableInstanceAtomFamily2(tableInstance.uiid ?? "NO_UIID_FOUND"),
          tableInstance
        );
      },
    []
  );

  const getTableInstanceByName = (tableName: TableName): TableInstanceType2 => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(tableName);
    const instance = getTableInstance(nameKeySynchronizeredHook.uiid);
    return instance;
  };

  const initMergeKeyNameWithTableInstance = (
    tableInstance: BaseTable2Properties,
    uiid: string,
    name: string
  ) => {
    return {
      ...tableInstance,
      uiid: uiid ?? "NO_KEY_FOUND",
      name: name ?? "NO_NAME_FOUND",
    };
  };

  useEffect(() => {
    if (tableConfigs.length > 0) {
      tableConfigs.map((instance) => {
        const nameKeySynchronizeredHook = nameKeySynchronizer(instance.name);

        const tableInstance = initMergeKeyNameWithTableInstance(
          instance,
          nameKeySynchronizeredHook.uiid,
          nameKeySynchronizeredHook.name
        );

        setInitialTableInstance(tableInstance);
      });
    }
  }, []);

  // called on tableConfiguration change
  useEffect(() => {
    if (tableConfigs.length > 0) {
      tableConfigs.map((instance) => {
        const nameKeySynchronizeredHook = nameKeySynchronizer(instance.name);
        const resultAfterUpdate = getTableInstance(
          nameKeySynchronizeredHook.uiid
        );

        const updatedTableInstanceObject = mappedTableInstanceType(instance);

        const editMode = updatedTableInstanceObject.advancedOptions?.isEditMode;
        const TEMP_mergedInstance = {
          ...resultAfterUpdate,
          getURL: updatedTableInstanceObject.getURL,
          refreshTable: updatedTableInstanceObject.refreshTable,
          columnsAndData: updatedTableInstanceObject.columnsAndData,
          toolbarOptions: updatedTableInstanceObject.toolbarOptions,
          listeners: updatedTableInstanceObject.listeners,
          filterOptions: updatedTableInstanceObject.filterOptions,
          footerOptions: updatedTableInstanceObject.footerOptions,
          advancedOptions: updatedTableInstanceObject.advancedOptions,
          multiSelectOptions: updatedTableInstanceObject.multiSelectOptions,
          events: updatedTableInstanceObject.events,
          toggleEditModeState:
            editMode ?? resultAfterUpdate?.toggleEditModeState,
        } as TableInstanceType2;

        updateTableInstance(TEMP_mergedInstance);
      });
    }
  }, [tableConfigs]);

  const updateMethodEvent = (instance: TableInstanceType2, obj: any) => {
    // BASETABLE TODO - configure this method correctly. the final product is targeted toward _accessors which might be fine for
    // a separate standalone function but we are unable to target properties outside of the _accessors namespace.
    // and are forced to do hacky things like the "resetURL" object.

    const resetURL = {
      getURL: obj._refreshTable ? "" : instance.getURL,
    } as TableInstanceType2;

    const TEMP_mergedInstance = {
      ...instance,
      ...resetURL,
      _accessors: {
        ...instance._accessors,
        ...obj,
      },
    } as TableInstanceType2;

    updateTableInstance(TEMP_mergedInstance);
    // });
  };

  const addRow = (
    tableName: TableName,
    callback: (hydratedDataDefault: HydratedData) => void
  ) => {
    const instance = getTableInstanceByName(tableName);
    const externalColumnsOnly = getExternalColumnsOnly(instance?.columns ?? []);
    const defaults = createNewDataItemWithDefaults(externalColumnsOnly);
    const hydratedBaseObject = hydrateDataSingle(externalColumnsOnly, defaults);
    const hydratedData = callback(hydratedBaseObject);
    const newRowKey = crypto.randomUUID();
    updateMethodEvent(instance, { _addRow: { hydratedData, newRowKey } });
    // return newRowKey;
  };

  const refreshTable = (tableName: TableName) => {
    const instance = getTableInstanceByName(tableName);
    updateMethodEvent(instance, { _refreshTable: true });
  };

  const updateRow = (
    tableName: TableName,
    hydratedData: UpdateRowParameters
  ) => {
    const instance = getTableInstanceByName(tableName);
    updateMethodEvent(instance, { _updateRow: hydratedData });
  };

  const deleteRow = (
    tableName: TableName,
    deleteAction: DeleteRowParameters
  ) => {
    const instance = getTableInstanceByName(tableName);
    updateMethodEvent(instance, { _deleteRow: deleteAction });
  };

  const closuresMethods = (tableName: TableName) => {
    return {
      addRow: (callback: (hydratedDataDefault: HydratedData) => void) => {
        addRow(tableName, callback);
      },
      updateRow: (hydratedData: UpdateRowParameters) => {
        updateRow(tableName, hydratedData);
      },
      deleteRow: (deleteAction: DeleteRowParameters) => {
        deleteRow(tableName, deleteAction);
      },
      refreshTable: () => {
        refreshTable(tableName);
      },
    } as BaseTable2InstanceMethods;
  };

  const [tableMethods, setTableMethods] = useState<{
    [tableName: TableName]: {
      name: string;
      methods: BaseTable2InstanceMethods;
    };
  }>({});

  const createInstanceLiterals = () => {
    const instanceConfigs = tableConfigs.map((config) => {
      return {
        [config.name]: {
          name: config.name,
          methods: closuresMethods(config.name),
        },
      };
    });

    const instanceLiterals = Object.assign({}, ...instanceConfigs);

    setTableMethods(instanceLiterals);
  };

  useEffect(() => {
    createInstanceLiterals();
  }, []);

  return { tableMethods };
}
