import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { ModalSpecificWaiverFormProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { useApiPost } from "../../../../../../hooks";
import { PolicyWaiverDto } from "../../../../../../dtos/policy-waiver-dto";
import WaiverForm from "./WaiverForm";
import { GettingDateWithoutTime } from "../../../../../../utilities/dateFunctions";

const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

const ModalSpecificWaiverForm: FC<ModalSpecificWaiverFormProps> = ({
  showModal,
  specificWaiverFormUI,
  closeEvent,
  refreshTable,
  saveDisabled,
}) => {
  const [specificWaiverUI, setSpecificWaiverUI] =
    useState<PolicyWaiverDto | null>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState(false);

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<number>(
      `${QUOTE_POLICY_API_PATH}/SavePolicyWaiver`,
      specificWaiverUI
    );

  const saveEvent = () => {
    dispatchPost();
  };

  useEffect(() => {
    setSpecificWaiverUI({
      ...specificWaiverUI,
      policyId: specificWaiverFormUI?.policyId,
      policyQuoteId: specificWaiverFormUI?.policyQuoteId,
      stateCode: specificWaiverFormUI?.stateCode,
      issueDate: GettingDateWithoutTime(specificWaiverFormUI?.policyQuoteDate),
      minimumValue: specificWaiverFormUI?.minimumValue,
      maximumValue: specificWaiverFormUI?.maximumValue,
      elementRate: specificWaiverFormUI?.rate,
    });
  }, [showModal]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      refreshTable?.();
      setSpecificWaiverUI(null);
      setErrorDetails(null);
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <>
      <Modal
        size="lg"
        id={"modal-specific-waiver-table"}
        title="Specific Waiver of Subrogation"
        open={showModal}
        showCloseButton={true}
        cancelEvent={() => setIsDialogConfirmationOpen(true)}
        saveEvent={saveEvent}
        saveDisabled={saveDisabled}
      >
        <WaiverForm
          specificWaiverUI={specificWaiverUI}
          specificWaiverFormUI={specificWaiverFormUI}
          setSpecificWaiverUI={setSpecificWaiverUI}
          errorDetails={errorDetails}
        />
      </Modal>
      <DialogConfirmation
        id="addWaiverDialogConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to close before saving the changes?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          closeEvent?.();
          setErrorDetails(null);
          setSpecificWaiverUI(null);
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default ModalSpecificWaiverForm;
