const RouteError = () => {
  return (
    <div
      style={{
        backgroundColor: "red",
        height: "100%",
        width: "500px",
        margin: "0 auto",
        textAlign: "center",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <span style={{ fontWeight: "bold", fontSize: "800" }}>
        Oops, something went wrong with that path.
      </span>

      <div
        className={"hazard-border"}
        style={{
          textAlign: "center",
          padding: "25px",
          backgroundColor: "white",
          margin: "25px",
        }}
      >
        <div
          style={{
            fontSize: "x-large",
            fontWeight: "900",
            color: "black",
            textShadow: "white 0.1em 0.1em 0.2em",
          }}
        >
          Ah Ah Ah!
        </div>
        <img src={"../media/Dave_loading.png"} className={"rotate"} />
        <div
          style={{
            fontSize: "x-large",
            fontWeight: "900",
            color: "black",
            textShadow: "white 0.1em 0.1em 0.2em",
          }}
        >
          You didn't say the magic word!
        </div>
      </div>
    </div>
  );
};

export default RouteError;
