import { FC, useState } from "react";
import ModalVerifyAppUser from "./ModalVerifyAppUser";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import UsersTable from "./UsersTable";

type UsersTableWrapperProps = {
  selectedCustomer?: number;
};

const UsersTableWrapper: FC<UsersTableWrapperProps> = ({
  selectedCustomer,
}) => {
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const openEditModal = (
    options: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <IconButton
        size="small"
        onClick={() => {
          const userId = options?.row?.UserId;
          navigate(`/admin-tools/security/user/${userId}`);
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    );
  };

  return (
    <>
      <UsersTable
        selectedCustomer={selectedCustomer}
        openEditModal={openEditModal}
        addEventOverrideCallback={() => setIsVerifyModalOpen(true)}
      />
      {isVerifyModalOpen ? (
        <ModalVerifyAppUser
          isModalOpen={isVerifyModalOpen}
          setIsModalOpen={setIsVerifyModalOpen}
        />
      ) : null}
    </>
  );
};
export default UsersTableWrapper;
