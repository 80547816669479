import { FC } from "react";
import Font, { FontProperties } from "./Font";

const FontError: FC<FontProperties> = (props) => {
  return (
    <Font textAlign="start" fontType="SMALL_TITLE" isErrorFont {...props} />
  );
};

export default FontError;
