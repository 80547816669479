import { FC, useEffect, useState } from "react";
import { Col, Input, InputDate, Modal, Row, Select } from "../../TrueUI";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { AgencyCoverageModalProps } from "./Coverages";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { SelectOptions } from "../../../dtos/select-options";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { useApiGet, usePermissions } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { AgencyCoverageDto } from "../../../dtos/agency-coverage-dto";
import { StatusEnums } from "../../../dtos/status-enums";
import { addYears } from "../../../utilities/dateFunctions";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type ModalAgencyCoverageProps = {
  errorDetails: any;
  coverageModalValues: AgencyCoverageModalProps | null;
  setCoverageModalValues: (value: AgencyCoverageModalProps) => void;
};

const statusOptions: Partial<SelectOptions>[] = FromEnumToArrayWithExceptions(
  StatusEnums,
  [StatusEnums.DELETED]
).map((enumType) => ({
  displayName: enumType?.key ?? "",
  stringValue: enumType?.value,
}));

const ModalAgencyCoverage: FC<ModalAgencyCoverageProps> = ({
  errorDetails,
  coverageModalValues,
  setCoverageModalValues,
}) => {
  const { hasPermission } = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
    PermissionsEnums.TRUE_ADMIN,
  ]);

  const [agencyCoverageValues, setAgencyCoverageValues] =
    useState<AgencyCoverageDto>();
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [coverageTypeOptions, setCoverageTypeOptions] =
    useState<SelectOptions[]>();

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    "api/coverage/GetAgencyCoverageTypeList"
  );

  const setDefaultValues = () => {
    const coverage = coverageModalValues?.agencyCoverage;
    if (isEmptyValue(coverage?.agencyCoverageId))
      setAgencyCoverageValues({
        status: StatusEnums.ACTIVE,
        agencyId: coverage?.agencyId,
        coverageExpirationDate: null,
        coverageEffectiveDate: new Date(),
      } as AgencyCoverageDto);
    else
      setAgencyCoverageValues({
        status: coverage?.status,
        agencyId: coverage?.agencyId,
        coverageType: coverage?.coverageType,
        coverageLimits: coverage?.coverageLimits,
        coverageNumber: coverage?.coverageNumber,
        coverageCarrier: coverage?.coverageCarrier,
        agencyCoverageId: coverage?.agencyCoverageId,
        coverageEffectiveDate: coverage?.coverageEffectiveDate,
        coverageExpirationDate: coverage?.coverageExpirationDate,
      } as AgencyCoverageDto);
  };

  const effectiveDateChanges = (effDate: Date) => {
    setAgencyCoverageValues({
      ...(agencyCoverageValues as AgencyCoverageDto),
      coverageEffectiveDate: effDate,
      coverageExpirationDate: addYears(effDate, 1),
    });
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setDefaultValues();
      setCoverageTypeOptions(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  return (
    <>
      <Modal
        id="agencyCoverageModal"
        title="Agency Coverage"
        open={coverageModalValues?.isOpen}
        showCloseButton
        showCancelTextButton
        cancelButtonWithConfirmation
        closeEvent={(e) =>
          setCoverageModalValues({
            ...coverageModalValues,
            isOpen: e,
          })
        }
        saveDisabled={!hasPermission}
        saveEvent={() =>
          setCoverageModalValues({
            ...coverageModalValues,
            agencyCoverage: agencyCoverageValues,
            isSaving: true,
          })
        }
        deleteButtonWithConfirmation
        deleteDisabled={
          !hasPermission ||
          isEmptyValue(coverageModalValues?.agencyCoverage?.agencyCoverageId)
        }
        deleteEvent={() => {
          setCoverageModalValues({
            ...coverageModalValues,
            agencyCoverage: {
              ...(agencyCoverageValues as AgencyCoverageDto),
              status: StatusEnums.DELETED,
            },
            isDeleting: true,
          });
        }}
      >
        {!isEmptyValue(agencyCoverageValues) && (
          <div>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 13, lg: 13, xl: 13 }}>
                <Select
                  tabIndex={20}
                  key={"coverageType"}
                  id="id-coverage-type"
                  name="name-coverage-type"
                  label="Coverage Type"
                  labelFontType="BOLD_CAPTION"
                  options={coverageTypeOptions ?? []}
                  optionsMaxHeight="250px"
                  value={agencyCoverageValues?.coverageType}
                  errorMessage={errorDetails?.coverageType}
                  onChange={(option) =>
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      coverageType: option,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <InputDate
                  tabIndex={21}
                  id="id-effective-date"
                  name="name-effective-date"
                  key={"effectiveDate"}
                  label="Effective Date"
                  value={agencyCoverageValues?.coverageEffectiveDate}
                  errorMessage={errorDetails?.coverageEffectiveDate}
                  onChangeRawValue={effectiveDateChanges}
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 7, lg: 7, xl: 7 }}>
                <Input
                  tabIndex={22}
                  key={"coverageCarrier"}
                  id="id-coverage-carrier"
                  name="name-coverage-carrier"
                  label="Coverage Carrier"
                  maxLength={100}
                  value={agencyCoverageValues?.coverageCarrier}
                  errorMessage={errorDetails?.coverageCarrier}
                  onChangeRawValue={(value) =>
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      coverageCarrier: value,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <InputDate
                  tabIndex={23}
                  id="id-expiration-date"
                  name="name-expiration-date"
                  key={"expirationDate"}
                  label="Expiration Date"
                  value={agencyCoverageValues?.coverageExpirationDate}
                  errorMessage={errorDetails?.coverageExpirationDate}
                  onChangeRawValue={(dateValue) => {
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      coverageExpirationDate: dateValue,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 7, lg: 7, xl: 7 }}>
                <Input
                  tabIndex={24}
                  key={"coverageNumber"}
                  id="id-coverage-number"
                  name="name-coverage-number"
                  label="Policy Number"
                  maxLength={50}
                  value={agencyCoverageValues?.coverageNumber}
                  errorMessage={errorDetails?.coverageNumber}
                  onChangeRawValue={(value) =>
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      coverageNumber: value,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <Select
                  tabIndex={21}
                  key={"status"}
                  id="id-status"
                  name="name-status"
                  label="Status"
                  labelFontType="BOLD_CAPTION"
                  options={statusOptions}
                  value={agencyCoverageValues?.status}
                  onChange={(option) =>
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      status: option,
                    })
                  }
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 7, lg: 7, xl: 7 }}>
                <Input
                  tabIndex={25}
                  key={"coverageLimits"}
                  id="id-coverage-limits"
                  name="name-coverage-limits"
                  label="Coverage Limits"
                  maxLength={50}
                  value={agencyCoverageValues?.coverageLimits}
                  errorMessage={errorDetails?.coverageLimits}
                  onChangeRawValue={(value) =>
                    setAgencyCoverageValues({
                      ...(agencyCoverageValues as AgencyCoverageDto),
                      coverageLimits: value,
                    })
                  }
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <DialogConfirmation
        id="agencyCoverageDialogConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this appointment?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setCoverageModalValues({
            ...coverageModalValues,
            isDeleting: true,
            isOpen: false,
          });
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default ModalAgencyCoverage;
