import { FC } from "react";
import { useRecoilValue } from "recoil";
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { Button, Col, Modal, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import {
  modalBindPolicyBodyStyle,
  modalBindPolicyButtonStyle,
  modalBindPolicyIconStyle,
} from "./ModalBindPolicyStyles";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";

type ModalBindPolicyProps = {
  showModal: boolean;
  onClose: () => void;
  onCloseQuote: () => void;
  hasError: boolean;
  modalBody: JSX.Element;
};

const ModalBindPolicy: FC<ModalBindPolicyProps> = ({
  showModal,
  onClose,
  onCloseQuote,
  hasError,
  modalBody,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  return (
    <Modal
      size="sm"
      id={"modal-bind-policy"}
      title={"Bind Policy"}
      open={showModal}
      showCloseButton={hasError}
      showCancelTextButton={hasError}
      saveEvent={hasError ? null : onClose}
      saveOverrideLabel="Continue"
      cancelEvent={onClose}
      sxInternalComponentsStyles={modalBindPolicyBodyStyle()}
      buttonRibbonNode={
        hasError ? null : (
          <Button
            variantStyle="outlined"
            onClick={onCloseQuote}
            sx={modalBindPolicyButtonStyle()}
          >
            CLOSE QUOTE
          </Button>
        )
      }
    >
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
        >
          {hasError ? (
            <ErrorOutlineOutlined
              sx={modalBindPolicyIconStyle(theme, hasError)}
            />
          ) : (
            <CheckCircleOutlineOutlined
              sx={modalBindPolicyIconStyle(theme, hasError)}
            />
          )}
        </Col>
        <Col
          horizontalAlign="flex-start"
          breakpoints={{ md: 10, lg: 10, xl: 10 }}
        >
          {modalBody}
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalBindPolicy;
