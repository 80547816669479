import { Box } from "@mui/material";
import { FC } from "react";
import { ADMIN_REFERENCE_TABS } from "../../../utilities/queryStringsHash";
import { Tabs, TabPanel, Heading4 } from "../../TrueUI";
import AdminReferenceInsured from "./AdminReferenceInsured";

const AdminReferenceInstanceWrapper: FC = () => {
  return (
    <Box
      sx={{
        px: 2,
        pb: 1,
      }}
    >
      <Heading4>Admin Tools - Reference Tables</Heading4>
      <Tabs name={ADMIN_REFERENCE_TABS} themeOverride={"material"}>
        <TabPanel title={"Insured"} key={"insured_tab"}>
          <AdminReferenceInsured />
        </TabPanel>
        <TabPanel title={"Claims"} key={"claims_tab"}>
          <Box>Claims</Box>
        </TabPanel>
        <TabPanel title={"Policy"} key={"policy_tab"}>
          <Box>Policy</Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default AdminReferenceInstanceWrapper;
