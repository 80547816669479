import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { ClaimsIncidentsFiltersOptionsProps } from "./TopClaimsAndIncidentsTabs";
import { IncidentPropsForContextMenu } from "../../Insured/Claim/Incident/Incident";
import { NavigateFunction } from "react-router";
import BaseGridSearchFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { useApiGet, usePermissions } from "../../../hooks";
import IncidentTableFilter from "./IncidentTableFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import { IncidentStatusEnums } from "../../../dtos/incident-status-enums";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  contextActionsForIncident,
  contextActionsRenderingConditionsForIncidents,
} from "../../Insured/Claim/Incident/IncidentTable/IncidentContextMenu";
import {
  getDateDiff,
  getUniversalTime,
} from "../../../utilities/dateFunctions";
import {
  BaseGridOptionCellType,
  BaseGridProperties,
} from "../../BaseGrid/BaseGridProperties";
import { formattingKey } from "../../../utilities/enumFunctions";
import {
  fromStatusesToOptionsToToggle,
  statusToHideAssignedToAndClaimNo,
  statusToHideAssignedToClaimNoAndReason,
  statusToHideReason,
} from "../../Insured/Claim/Incident/IncidentTable/IncidentFunctions";
import { getColumnIndexByColumnName } from "../../TrueUI/Tables/tableFunctions";

type TopIncidentsGridProperties = {
  searchFilters: ClaimsIncidentsFiltersOptionsProps;
  setIncidentContextMenuValues: Dispatch<
    SetStateAction<IncidentPropsForContextMenu | undefined>
  >;
  navigate: NavigateFunction;
  setShowSearchModal: Dispatch<React.SetStateAction<boolean>>;
};

const tableIncidentsName = "incidents_body_table";
type IncidentTableFiltersValuesProps = {
  status: string;
  assigned: number | "all";
};
const permissionToAddIncident = [
  PermissionsEnums.CLAIMS_ADJUSTER,
  PermissionsEnums.CLAIMS_MANAGER,
  PermissionsEnums.CLAIMS_ADMIN,
];

const TopIncidentsGrid: FC<TopIncidentsGridProperties> = ({
  searchFilters,
  setIncidentContextMenuValues,
  navigate,
  setShowSearchModal,
}) => {
  const [incidentTableFiltersValues, setIncidentTableFiltersValues] =
    useState<IncidentTableFiltersValuesProps>({
      status: formattingKey(IncidentStatusEnums[IncidentStatusEnums.SUBMITTED]),
      assigned: "all",
    });
  const hasPermissionToAddIncident = usePermissions(
    permissionToAddIncident
  ).hasPermission;
  const [incidentsTableData, setIncidentsTableData] = useState<any>(null);

  const [filteredIncidentsTableData, setFilteredIncidentsTableData] =
    useState<any>(null);

  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/Claims/GetClaimsIncidentsTableData?tabType=incidents`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (conditionHasValue(responseGet?.axiosResponse?.data)) {
      setIncidentsTableData(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(incidentsTableData)) {
      const filteredTableData = filterData(
        incidentsTableData?.tableData.columns ?? []
      );
      setFilteredIncidentsTableData({
        tableData: {
          columns: incidentsTableData?.tableData.columns ?? [],
          data: filteredTableData ?? [],
        },
      });
    }
  }, [incidentsTableData, incidentTableFiltersValues]);

  useEffect(() => {
    if (conditionHasValue(filteredIncidentsTableData)) {
      manuallyReloadParameters();
    }
  }, [filteredIncidentsTableData]);

  const filterData = (columns: any) => {
    const incidentStatusColumnIndex = getColumnIndexByColumnName(
      "IncidentStatus",
      columns
    );
    const assigneeUserIdColumnIndex = getColumnIndexByColumnName(
      "AssigneeUserId",
      columns
    );
    const tableData = incidentsTableData?.tableData.data;
    const filteredDataByAssignedId =
      incidentTableFiltersValues.assigned === "all"
        ? tableData
        : tableData?.filter(
            (row) =>
              row[assigneeUserIdColumnIndex] ===
              incidentTableFiltersValues.assigned.toString()
          );

    const filteredDataByAssignedIdAndStatus =
      incidentTableFiltersValues.status === "all"
        ? filteredDataByAssignedId
        : filteredDataByAssignedId?.filter(
            (row) =>
              row[incidentStatusColumnIndex] ===
              incidentTableFiltersValues.status.toString()
          );

    return filteredDataByAssignedIdAndStatus;
  };

  const isExpiredIncident = (row) =>
    row.IncidentStatus === "Submitted" &&
    row.DateReported !== "" &&
    getDateDiff(
      new Date(row.DateReported),
      getUniversalTime(),
      "hours",
      false
    ) > 24;

  const onChangeAssigned = (value) => {
    setIncidentTableFiltersValues({
      ...incidentTableFiltersValues,
      assigned: value,
    });
  };

  const onChangeStatus = (value) => {
    setIncidentTableFiltersValues({
      ...incidentTableFiltersValues,
      status: value,
    });
  };

  const tableIncidentsConfiguration: BaseGridProperties = {
    name: tableIncidentsName,
    useManuallyReloadParameters: true,
    disableInitialize: true,
    columnsAndData: {
      columns: filteredIncidentsTableData?.tableData?.columns ?? [],
      data: filteredIncidentsTableData?.tableData?.data ?? [],
    },
    columnOptions: [
      { fieldName: "IncidentIdLink", width: 5 },
      { fieldName: "ProgramCode", width: 6 },
      { fieldName: "LineType", width: 5 },
      { fieldName: "State", width: 5 },
      { fieldName: "InsuredName", width: 15 },
      { fieldName: "Claimant", width: 7 },
      { fieldName: "SSN", width: 7 },
      { fieldName: "DateOfLoss", width: 7 },
      { fieldName: "DateReported", width: 7 },
      { fieldName: "IncidentStatus", width: 7 },
      { fieldName: "AssignedTo", width: 7 },
      { fieldName: "ClaimNumber", width: 7 },
      { fieldName: "Reason", width: 8 },
      { fieldName: "CreatedBy", width: 7 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    filterOptions: [
      () =>
        IncidentTableFilter({
          id: "incidents-table-status-filter",
          options: searchFilters?.incidentStatuses,
          labelText: "Status:",
          value: incidentTableFiltersValues.status,
          onChange(value) {
            onChangeStatus(value);
          },
          toggleColumnsByOptions: [
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideAssignedToClaimNoAndReason,
                searchFilters?.incidentStatuses
              ),
              columnsToToggle: ["ClaimNumber", "AssignedTo", "Reason"],
            },
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideAssignedToAndClaimNo,
                searchFilters?.incidentStatuses
              ),
              columnsToToggle: ["ClaimNumber", "AssignedTo"],
            },
            {
              options: fromStatusesToOptionsToToggle(
                statusToHideReason,
                searchFilters?.incidentStatuses
              ),
              columnsToToggle: ["Reason"],
            },
          ],
        }),
      () =>
        IncidentTableFilter({
          id: "incidents-table-assigned-to-filter",
          options: addAllOption(searchFilters?.allAssignees),
          labelText: "Assigned To:",
          value: incidentTableFiltersValues.assigned,
          onChange(value) {
            onChangeAssigned(value);
          },
        }),
      (options) =>
        BaseGridSearchFilter({
          options,
          id: "incident_table_configuration_filter_search",
          columnNames: ["IncidentId", "Claimant"],
          columnDisplayNames: ["incident no.", "claimant"],
        }) as ReactElement,
    ],
    advancedOptions: {
      optionsContextActions: {
        contextMenuOptionRenderingCondition:
          contextActionsRenderingConditionsForIncidents,
        contextMenu: contextActionsForIncident(
          setIncidentContextMenuValues,
          true,
          navigate
        ),
      },
      optionsColumnConfiguration: {
        isHidden: !hasPermissionToAddIncident,
        optionType: "context" as BaseGridOptionCellType,
      },
      indicationColumnConfiguration: {
        iconColor: "red",
        isHidden: false,
        conditionalIcon: (row) => {
          if (isExpiredIncident(row)) {
            return "error";
          }
          return "custom";
        },
      },
    },
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    permissions: {
      addButton: permissionToAddIncident,
    },
    events: {
      addEventOverrideCallback: () => setShowSearchModal(true),
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableIncidentsConfiguration);

  return <BaseGrid name={tableIncidentsName} />;
};

export default TopIncidentsGrid;
