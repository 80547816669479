import { FC, useEffect, useState } from "react";
import { Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import CalendarWidget from "../../../TrueUI/CalendarWidget/CalendarWidget";
import { SelectOptions } from "../../../../dtos/select-options";
import { CalendarWidgetCellDto } from "../../../../dtos/calendar-widget-cell-dto";
import { useApiPost } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { ContextProps } from "../InsuredDrawerConstants";
import { TaskConfigurationDto } from "../../../../dtos/task-configuration-dto";

type TaskCalendarProps = {
  userList: SelectOptions[] | undefined;
  selectedUser: number | null;
  context: ContextProps;
};

const TaskCalendar: FC<TaskCalendarProps> = ({
  userList,
  selectedUser,
  context,
}) => {
  const [configuration, setConfiguration] = useState<TaskConfigurationDto>();
  const [datesWithValues, setDatesWithValues] =
    useState<CalendarWidgetCellDto[]>();
  const { responsePost, dispatchPost } = useApiPost<CalendarWidgetCellDto[]>(
    `api/Task/GetAssignedTasksCountByDateByUserId`,
    configuration
  );
  useEffect(() => {
    if (userList !== undefined && userList.length > 0) {
      const userId = selectedUser ?? userList[0].intValue ?? 0;
      setConfiguration({
        selectedUserId: userId,
        insuredId: context.insuredId,
        claimNumber: context.claimNumber,
      });
    }
  }, [userList]);

  useEffect(() => {
    if (
      configuration?.selectedUserId !== undefined &&
      configuration?.selectedUserId > 0
    ) {
      dispatchPost();
    }
  }, [configuration?.selectedUserId]);

  useEffect(() => {
    if (conditionHasValue(responsePost)) {
      setDatesWithValues(responsePost.axiosResponse?.data ?? []);
    }
  }, [responsePost]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      verticalAlign="center"
      rowDirection={"column"}
    >
      <Select
        id="task_assigned_to_users"
        tabIndex={1}
        name="assignedTo"
        label="Assigned To"
        variant="filled"
        labelFontType="BOLD_CAPTION"
        options={userList ?? []}
        value={configuration?.selectedUserId}
        onChange={(value) => {
          setConfiguration({ ...configuration, selectedUserId: value });
        }}
        optionsMaxHeight="300px"
      />
      <CalendarWidget
        value={null}
        onChange={undefined}
        datesWithValues={datesWithValues}
      />
    </Row>
  );
};

export default TaskCalendar;
