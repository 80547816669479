import { FC, useState } from "react";
import { Button, Row, SplitButton } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../TrueUI/Dialogs/DialogConfirmation";
import { useNavigate } from "react-router";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { acord130FormProperties } from "../../../GlobalAtoms";

export const CONTINUE_ACTION = "continue";
export const SAVE_ONLY_ACTION = "save-only";

type Accord130BottomButtonsProps = {
  saveFunction: (action: string) => void;
};

const Acord130BottomButtons: FC<Accord130BottomButtonsProps> = ({
  saveFunction,
}) => {
  const navigate = useNavigate();
  const { setAtom } = useAtomFamily(acord130FormProperties);

  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps>();

  const splitButtonOptions = [
    {
      key: 1,
      option: "CONTINUE",
      dropdownText: "Continue to Quote",
      action: () => saveFunction(CONTINUE_ACTION),
    },
    {
      key: 2,
      option: "Save Only",
      dropdownText: "Save Only",
      action: () => saveFunction(SAVE_ONLY_ACTION),
    },
  ];

  const cancelEvent = () => {
    {
      setDialogConfiguration({
        dialogDescriptionText:
          "Are you sure you want to close this application and lose your work?",
        onCloseEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionNoEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionYesEvent: (close) => {
          setAtom(null);
          navigate("/");
          setDialogConfiguration({ ...dialogConfiguration, open: close });
        },
        open: true,
      });
    }
  };

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalGutter="20px"
        horizontalAlign="flex-end"
      >
        <SplitButton items={splitButtonOptions} tabIndex={36} />
        <Button
          variantStyle="outlined"
          sx={{ ml: 2 }}
          onClick={cancelEvent}
          tabIndex={37}
        >
          Cancel
        </Button>
      </Row>
      <DialogConfirmation
        id="acord130-dialog-confirmation"
        name="acord130-dialog"
        {...dialogConfiguration}
      />
    </>
  );
};

export default Acord130BottomButtons;
