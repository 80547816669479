import { FC } from "react";
import "../BaseGrid.css";
import style from "../Styles/basegridcells.module.css";

type DefaultPhoneCellProperties = {
  value: string;
};

const DefaultPhoneCell: FC<DefaultPhoneCellProperties> = ({ value }) => {
  const formatedPhone = value
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  return (
    <div className={style.default_phone_render_only_cell}>{formatedPhone}</div>
  );
};

export default DefaultPhoneCell;
