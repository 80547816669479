import { useRecoilCallback } from "recoil";
import { TableInstanceType2 } from "../components/TrueUI/Tables/BaseTable2/TableProperties";
import { tableInstanceAtomFamily2 } from "../components/TrueUI/Tables/TableAtoms";
import { BaseTableKeyNameMapAtom } from "./useBaseTable";

export function useBaseTableUtilities(sourceInitializer: string): {
  nameKeySynchronizer: (tableName: string) => { uiid; name; status };
  getTableInstance: (uiid: string) => TableInstanceType2;
  setTableInstance: (
    newValueTableInstance: Partial<TableInstanceType2>
  ) => void;
} {
  const getUiid = useRecoilCallback(({ snapshot }) => (tableName) => {
    const zz =
      (snapshot.getLoadable(BaseTableKeyNameMapAtom).contents as any[]) ?? null;

    const uiid = zz?.find((z) => z.name === tableName) ?? null;

    return uiid?.uiid ?? null;
  });

  const nameKeySynchronizer = useRecoilCallback(
    ({ set }) =>
      (tableName: string) => {
        const existingUiid = getUiid(tableName);
        if (existingUiid) {
          return {
            uiid: existingUiid,
            name: tableName,
            status: `found_it_in_${sourceInitializer}`,
          };
        }
        const keyNameMap = {
          uiid: crypto.randomUUID(),
          name: tableName,
          status: `created_in_${sourceInitializer}`,
        };
        set(BaseTableKeyNameMapAtom, (keyName) => [...keyName, keyNameMap]);
        return keyNameMap;
      },
    []
  );

  const getTableInstance = useRecoilCallback(
    ({ snapshot }) =>
      (uiid: string) => {
        return snapshot.getLoadable(
          tableInstanceAtomFamily2(uiid ?? "NO_UIID_FOUND")
        ).contents as TableInstanceType2;
      },
    []
  );

  const setTableInstance = useRecoilCallback(
    ({ snapshot, set }) =>
      (newValueTableInstance: Partial<TableInstanceType2>) => {
        const tableSnapshot = snapshot.getLoadable(
          tableInstanceAtomFamily2(
            newValueTableInstance.uiid ?? "NO_UIID_FOUND"
          )
        ).contents;

        set(
          tableInstanceAtomFamily2(
            newValueTableInstance.uiid ?? "NO_UIID_FOUND"
          ),
          {
            ...tableSnapshot,
            ...newValueTableInstance,
            uiid: newValueTableInstance.uiid,
          }
        );
      },
    []
  );

  return { nameKeySynchronizer, getTableInstance, setTableInstance };
}
