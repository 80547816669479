import { FC } from "react";
import { ButtonCopyContainerProps } from "../RefundComponents/TypesModalRefundPayment";
import { usePermissions } from "../../../../../../../hooks";
import Col, {
  colWithNoMarginNorGutter,
} from "../../../../../../TrueUI/Grids/Col";
import { Button } from "../../../../../../TrueUI";
import { PermissionsEnums } from "../../../../../../../dtos/permissions-enums";

const ButtonCopyContainer: FC<ButtonCopyContainerProps> = ({
  setCopyPaymentConfig,
  copyPaymentConfig,
}) => {
  const hasPermissionToRefund = usePermissions([
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]);
  const onCopy = () => {
    setCopyPaymentConfig({
      ...copyPaymentConfig,
      isFormReadOnly: false,
      isPaymentBeingCopied: true,
    });
  };
  return (
    <>
      {hasPermissionToRefund.hasPermission && (
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 4, xl: 4 }}
          horizontalAlign="flex-start"
        >
          <Button
            tabIndex={23}
            variantStyle="outlined"
            onClick={() => onCopy()}
            name="paymentCopyButton"
          >
            Copy
          </Button>
        </Col>
      )}
    </>
  );
};
export default ButtonCopyContainer;
