import {
  Button,
  Col,
  Input,
  InputAddress,
  InputDate,
  Row,
  Select,
  Switch,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import ReserveTypeAndPaymentCategory from "./FormComponents/ReserveTypeAndPaymentCategory";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { FC, useEffect, useState } from "react";
import {
  defaultRecoveryData,
  PaymentTableRow,
  TabsClaimFinancialProps,
} from "./typesAndConstants";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { useApiPost } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import {
  getEnumValue,
  toTitleCase,
} from "../../../../../../utilities/enumFunctions";
import { PaymentStatusEnum } from "../../../../../../dtos/payment-status-enum";
import { SelectOptions } from "../../../../../../dtos/select-options";
import {
  payeeSearchTextKeys,
  payeeSearchTextKeysToShowExtraDataIntoInput,
} from "./AddPaymentForm";
import { getFormattedValue } from "../../../../../TrueUI/Search/SearchTextUtil";
import SearchText from "../../../../../TrueUI/Search/SearchText";

const ModalAddRecovery: FC<TabsClaimFinancialProps> = ({
  paymentFormSelectsData,
  closeModal,
  claimId,
  setNewPaymentRowData,
  tabKey,
}) => {
  const [selectedPayee, setSelectedPayee] = useState<any>(null);
  const [paymentFormData, setPaymentFormData] =
    useState<Partial<PaymentDto> | null>(defaultRecoveryData);
  const [paymentCategories, setPaymentCategories] = useState<
    Partial<SelectOptions>[]
  >([]);
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    Partial<PaymentDto>[]
  >("api/Payment/SavePayment", { ...paymentFormData, claimId: claimId });

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const selectOptions = {
    paymentStatuses: paymentFormSelectsData?.paymentStatusesSelectValues ?? [],
    reserveTypes: paymentFormSelectsData?.reserveStatusSelectValues ?? [],
  };

  const onSaveSelectedPayeeResult = (payee: any) => {
    setSelectedPayee(payee);
    savePayeeData(payee);
  };

  const savePayeeData = (payee: any) => {
    setPaymentFormData?.({
      ...paymentFormData,
      payeeId: payee?.payeeId && payee?.payeeId !== "" ? payee?.payeeId : null,
      payeeName:
        payee?.payeeName && payee?.payeeName !== "" ? payee?.payeeName : null,
      payeeNameAndAddress: getFormattedValue(
        payee,
        payeeSearchTextKeys,
        payeeSearchTextKeysToShowExtraDataIntoInput
      ),
      payeeAddress1: payee?.address,
      payeeCity: payee?.city,
      payeeStateCode: payee?.state,
      payeeZip: payee?.zip,
      payeeCompanyName: "",
      payeeAddress2: "",
    });
  };

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (paymentFormData?.overridePayee === false) {
      savePayeeData(selectedPayee);
    }
  }, [paymentFormData?.overridePayee]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setErrorDetails(null);
      setPaymentFormData(defaultRecoveryData);
      closeModal(false);
      setComponentTriggers([
        "activityLogComponent",
        "claimBanner",
        "financialTableClaimLandingPageComponent",
      ]);
      if (paymentFormData?.paymentId === 0) {
        const paymentFromResponse = responsePost?.responseData ?? [];
        const [recoveryPayment] = paymentFromResponse;

        const paymentStatus = toTitleCase(
          getEnumValue(
            paymentFormData?.paymentStatus?.toString() ?? "",
            PaymentStatusEnum
          )
        );

        const paymentCategory = paymentCategories.find(
          (status) => status.intValue === paymentFormData?.paymentCategoryId
        )?.displayName;

        const newPaymentRow: Partial<PaymentDto & PaymentTableRow> = {
          ...paymentFormData,
          paymentId: recoveryPayment.paymentId,
          updateBy: recoveryPayment.updateBy,
          PaymentStatus: paymentStatus ?? "",
          PaymentCategory: paymentCategory ?? "",
          payeeId: recoveryPayment.payeeId,
          payeeName: recoveryPayment.payeeName,
          paymentStatusName: recoveryPayment.paymentStatusName,
          paidAmount: recoveryPayment.paidAmount,
        };

        setNewPaymentRowData?.([newPaymentRow]);
      }
    }
  }, [responsePost]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={20}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
        >
          <ReserveTypeAndPaymentCategory
            paymentData={paymentFormData}
            setPaymentData={(data) => setPaymentFormData(data)}
            reserveTypeOptions={selectOptions?.reserveTypes ?? []}
            errorDetails={errorDetails}
            setPaymentCategories={setPaymentCategories}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          displayFlex={false}
          breakpoints={{ md: 8, lg: 8, xl: 8 }}
        >
          <SearchText
            tabIndex={3}
            saveSelectedResult={onSaveSelectedPayeeResult}
            onRawChange={() => setSelectedPayee(null)}
            searchTextKeys={payeeSearchTextKeys}
            searchTextKeysToShowExtraDataIntoInput={
              payeeSearchTextKeysToShowExtraDataIntoInput
            }
            value={paymentFormData?.payeeNameAndAddress ?? ""}
            labelText={"Payee"}
            placeholderText={"Received From"}
            url={"api/Payee/GetPayeeList?searchPayeeValue="}
            labelFontType={"BOLD_CAPTION"}
            key={`payee-search-${paymentFormData?.payeeNameAndAddress}`}
            name={"payeeReceivedFromName"}
          />
        </Col>
      </Row>
      {paymentFormData?.overridePayee && (
        <>
          <Row {...rowWithNoMarginNorGutter} numberOfColumns={4}>
            <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={4}
                {...inputConfiguration(
                  "id-payee-name",
                  "payee-name",
                  "Payee Name",
                  100
                )}
                labelFontType={"BOLD_CAPTION"}
                value={paymentFormData?.payeeName ?? ""}
                onChangeRawValue={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeName: value,
                  })
                }
                errorMessage={errorDetails?.payeeName}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter} numberOfColumns={4}>
            <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={5}
                {...inputConfiguration(
                  "id-company-name",
                  "company-name",
                  "Company Name",
                  100
                )}
                labelFontType={"BOLD_CAPTION"}
                value={paymentFormData?.payeeCompanyName ?? ""}
                onChangeRawValue={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeCompanyName: value,
                  })
                }
                errorMessage={errorDetails?.payeeCompanyName}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter} numberOfColumns={8}>
            <Col displayFlex={false} breakpoints={{ md: 8, lg: 8, xl: 8 }}>
              <InputAddress
                tabIndex={6}
                readOnly={false}
                labelFontType="BOLD_CAPTION"
                showSingleLabel={false}
                initValueAddress2={paymentFormData?.payeeAddress2 ?? ""}
                initValueAddress1={paymentFormData?.payeeAddress1 ?? ""}
                initValueCity={paymentFormData?.payeeCity ?? ""}
                initValuePostCode={paymentFormData?.payeeZip ?? ""}
                initValueStateCode={paymentFormData?.payeeStateCode ?? ""}
                onChangeValueAddress1={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeAddress1: value,
                  })
                }
                onChangeValueAddress2={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeAddress2: value,
                  })
                }
                onChangeValueCity={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeCity: value,
                  })
                }
                onChangeValuePostCode={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeZip: value,
                  })
                }
                onChangeStateCode={(value) =>
                  setPaymentFormData({
                    ...paymentFormData,
                    payeeStateCode: value,
                  })
                }
                address1ErrorMessage={errorDetails?.payeeAddress1}
                address2ErrorMessage={errorDetails?.payeeAddress2}
                cityErrorMessage={errorDetails?.payeeCity}
                postCodeErrorMessage={errorDetails?.payeeZip}
                stateCodeErrorMessage={errorDetails?.payeeStateCode}
              />
            </Col>
          </Row>
        </>
      )}
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={20}>
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={11}
            {...inputConfiguration(
              "id-paid-amount",
              "paymentFormPaidAmount",
              "Paid Amount",
              20
            )}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={paymentFormData?.paidAmount ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData({ ...paymentFormData, paidAmount: value })
            }
            errorMessage={errorDetails?.paidAmount}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={12}
            {...inputConfiguration(
              "id-payment-method",
              "paymentFormPaymentMethod",
              "Payment Method",
              20
            )}
            value={paymentFormData?.paymentMethod ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                paymentMethod: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.paymentMethod}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={13}
            {...inputConfiguration(
              "id-reference-number",
              "paymentFormReferenceNumber",
              "Reference Number",
              30
            )}
            value={paymentFormData?.referenceNo ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                referenceNo: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.referenceNo}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            tabIndex={14}
            {...selectConfiguration(
              "id-payment-status",
              "paymentFormPaymentStatus",
              "Payment Status"
            )}
            options={selectOptions.paymentStatuses}
            value={paymentFormData?.paymentStatus ?? -1}
            onChange={(e) => {
              setPaymentFormData({
                ...paymentFormData,
                paymentStatus: e,
              });
            }}
            errorMessage={errorDetails?.paymentStatus}
            labelFontType={"BOLD_CAPTION"}
            firstOptionAsDefault={false}
            readOnly
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Switch
            tabIndex={15}
            control="checkbox"
            id="id-overrride-payee"
            name="override-payee"
            label="Override Payee"
            labelFontType="BODY"
            isChecked={paymentFormData?.overridePayee ?? false}
            onChangeIsChecked={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                overridePayee: value,
              })
            }
          />
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={20}
        horizontalAlign={"flex-start"}
      >
        <Col displayFlex={false} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={16}
            id="id-payment-date"
            name={"paymentFormPaymentDate"}
            label="Payment Date"
            value={paymentFormData?.paymentDate ?? new Date()}
            onChangeRawValue={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                paymentDate: value,
              })
            }
            errorMessage={errorDetails?.paymentDate}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={17}
            id="id-paid-from-date"
            name={"paymentFormPaidFromDate"}
            label="Paid From"
            value={paymentFormData?.paidFromDate}
            onChangeRawValue={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                paidFromDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={18}
            id="id-paid-to-date"
            name={"paymentFormPaidToDate"}
            label="Paid To"
            value={paymentFormData?.paidToDate}
            onChangeRawValue={(value) =>
              setPaymentFormData({
                ...paymentFormData,
                paidToDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"CAPTION"}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col displayFlex={false}>
          <Input
            tabIndex={19}
            {...inputConfiguration(
              "id-payment-comments",
              "paymentFormPaymentComments",
              "Comments",
              200
            )}
            value={paymentFormData?.comments ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                comments: value,
              })
            }
            errorMessage={errorDetails?.comments}
            labelFontType={"BOLD_CAPTION"}
            rows={3}
          />
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalGutter="15px"
        horizontalAlign={"space-between"}
      >
        <Col
          {...colWithNoMarginNorGutter}
          horizontalGutter={"5px"}
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          horizontalAlign={"flex-end"}
        >
          <Button
            tabIndex={20}
            onClick={() => dispatchPost()}
            name="paymentFormSaveButton"
          >
            SAVE
          </Button>
          <Button
            tabIndex={21}
            variantStyle="outlined"
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              closeModal(false);
            }}
            name="paymentFormCancelButton"
          >
            CANCEL
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModalAddRecovery;
