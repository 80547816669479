import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Font, Input, Modal } from "../../../TrueUI";
import { VerifyUserDto } from "../../../../dtos/verify-user-dto";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserViewInformationAtom } from "../AdminSecurityAtoms";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { globalOptions } from "../../../../GlobalAtoms";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";

export type ModalAppUserProps = {
  isModalOpen?: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
};

type DialogProps = {
  text?: string;
  isOpen?: boolean;
  canSave?: boolean;
  buttonText?: string;
};

const defaultDialog = {
  isOpen: true,
  text: "The email address you entered already exists as an active user.  You can edit this user and add additional access as needed.",
  canSave: true,
  buttonText: "OK",
};
const ModalVerifyAppUser: FC<ModalAppUserProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const [email, setEmail] = useState<string | null>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [dialogProps, setDialogProps] = useState<DialogProps>();
  const localOptions = useRecoilValue(globalOptions);

  const [userInfo, setUserInformationView] = useRecoilState(
    UserViewInformationAtom
  );

  const { responseGet, dispatchGet, validatorErrorResponse } =
    useApiGet<VerifyUserDto>(
      `api/AdminTools/Security/UserListing/VerifyAppUser?userEmail=${email}&tenantId=${userInfo?.tenantId}`
    );

  const navigate = useNavigate();
  const onCancelModal = () => {
    setEmail(null);
    setErrorDetails(null);
    setIsModalOpen?.(false);
    setUserInformationView({ ...userInfo, newUserName: "" });
  };

  const onSaveUser = () => {
    setUserInformationView({
      ...userInfo,
      newUserName: email ?? "",
    });
    const userId = responseGet?.responseData?.userId;
    navigate(`/admin-tools/security/user/${userId}`);
  };

  useEffect(() => {
    if (
      responseGet.requestInstanceSuccessful &&
      conditionHasValue(responseGet?.axiosResponse?.data) &&
      !responseGet?.isLoading
    ) {
      if (validatorErrorResponse === null) {
        const userPermissions = localOptions?.permissions;

        const isActiveUser = responseGet?.responseData?.userId !== 0;
        if (isActiveUser) {
          if (userPermissions.includes(PermissionsEnums.TRUE_ADMIN)) {
            setDialogProps(defaultDialog);
            return;
          }
          if (userPermissions.includes(PermissionsEnums.CUSTOMER_ADMIN)) {
            if (responseGet?.responseData?.customerUserId === 0) {
              setDialogProps({
                isOpen: true,
                text: "This user already exists for another customer.  Please contact support for more assistance.",
                canSave: false,
                buttonText: "Close",
              });
            } else {
              setDialogProps(defaultDialog);
            }
          }
        } else {
          onSaveUser();
        }
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responseGet]);

  return (
    <>
      <Modal
        id={"portal__new_user_modal"}
        title={"Add User"}
        open={isModalOpen}
        cancelEvent={onCancelModal}
        size={"md"}
        saveEvent={() => dispatchGet()}
        showCloseButton
        showCancelTextButton
      >
        <Font whiteSpace="normal">
          Enter the email address of the user you wish to add.
        </Font>
        <div style={{ marginTop: "15px" }}>
          <Input
            label="Email Address"
            labelPosition="top"
            labelFontType="BOLD_CAPTION"
            id={"new-user-email"}
            name={"new-user-email"}
            value={email}
            onChangeRawValue={(value) => setEmail(value)}
            errorMessage={errorDetails?.userEmail}
            inputWidth="500px"
            maxLength={100}
          />
        </div>
      </Modal>
      <DialogConfirmation
        open={dialogProps?.isOpen}
        dialogDescriptionText={dialogProps?.text}
        onOptionYesEvent={() => {
          if (dialogProps?.canSave) {
            onSaveUser();
          }
          onCancelModal();
        }}
        optionYesOverrideLabel={dialogProps?.buttonText ?? ""}
      />
    </>
  );
};
export default ModalVerifyAppUser;
