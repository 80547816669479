import { FC, useEffect, useState } from "react";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { SelectOptions } from "../../../../dtos/select-options";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, IconButton } from "@mui/material";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { InAppNotificationsPage } from "../../../../dtos/in-app-notifications-page";
import { useApiGet, useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const gridName = "notifications_grid";

type NotificationsGridProps = {
  setUpdateNotifications: (boolean) => any;
};

const NotificationsGrid: FC<NotificationsGridProps> = ({
  setUpdateNotifications,
}) => {
  const { responseGet, dispatchGet } = useApiGet<InAppNotificationsPage>(
    `api/SubscriptionActivity/GetInAppNotifications`
  );
  const [notificationsInfo, setNotificationsInfo] =
    useState<InAppNotificationsPage | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const {
    responsePost: acknowledgeOneResponse,
    dispatchPost: acknowledgeOneDispatch,
    validatorErrorResponse: acknowledgeOneValidator,
  } = useApiPost(
    `api/SubscriptionActivity/AcknowledgeNotification?subscriptionActivityId=${selectedRowId}`
  );
  const {
    responsePost: acknowledgeAllResponse,
    dispatchPost: acknowledgeAllDispatch,
    validatorErrorResponse: acknowledgeAllValidator,
  } = useApiPost(
    `api/SubscriptionActivity/AcknowledgeAllNotifications`,
    notificationsInfo?.idList ?? []
  );

  const statusOptions: Partial<SelectOptions>[] = [
    { intValue: 1, displayName: "Active Only" },
  ];

  const resetPage = () => {
    dispatchGet();
    setSelectedRowId(null);
    setUpdateNotifications(true);
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() =>
            setSelectedRowId(actionOptions.row.SubscriptionActivityID ?? null)
          }
        >
          <CheckCircleIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    name: gridName,
    columnsAndData: notificationsInfo?.tableData,
    useManuallyReloadParameters: true,
    toolbarOptions: {
      showAddButton: true,
      addButtonText: "Acknowledge All ",
      showEditButton: false,
    },
    columnOptions: [
      { fieldName: "Insured", width: 20 },
      { fieldName: "Description", width: 35 },
      { fieldName: "PolicyClaim", width: 20 },
      { fieldName: "Created", width: 25 },
      { fieldName: "OPTIONS", width: 4 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          id: "acknowledged-filter",
          columnNames: ["AcknowledgedStatus"],
          optionsOverride: statusOptions,
          defaultValue: 1,
          options,
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    events: {
      addEventOverrideCallback: () => acknowledgeAllDispatch(),
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setNotificationsInfo(responseGet.responseData ?? null);
  }, [responseGet]);

  useEffect(() => {
    manuallyReloadParameters();
  }, [notificationsInfo]);

  useEffect(() => {
    if (selectedRowId !== null) acknowledgeOneDispatch();
  }, [selectedRowId]);

  useEffect(() => {
    if (
      isAPITotallyComplete(acknowledgeOneResponse) &&
      acknowledgeOneValidator === null
    )
      resetPage();
  }, [acknowledgeOneResponse]);

  useEffect(() => {
    if (
      isAPITotallyComplete(acknowledgeAllResponse) &&
      acknowledgeAllValidator === null
    )
      resetPage();
  }, [acknowledgeAllResponse]);

  return (
    <div style={{ width: "100%", height: "75vh" }}>
      <BaseGrid name={gridName} />
    </div>
  );
};

export default NotificationsGrid;
