import { ColProps } from "../../../TrueUI/Grids/Col";

export const colConfigurationForCalculationsInHeaderAndFooter: ColProps = {
  breakpoints: { md: 2, lg: 2, xl: 2 },
  horizontalAlign: "flex-end",
  horizontalGutter: "10px",
};

export const colConfigurationForLabelsInHeaderAndFooter: ColProps = {
  breakpoints: { md: 3, lg: 3, xl: 3 },
  verticalAlignSelf: "center",
};

export const getPaymentPremiumLabel = (
  isAudit: boolean,
  labelNumber: "PRE-PAY" | "END-PAY" | "PRE-PRE" | "END-PRE"
) => {
  switch (labelNumber) {
    case "PRE-PAY":
      return `${isAudit ? "ORIGINAL" : "PREVIOUS"} PAYROLL:`;
    case "END-PAY":
      return `${isAudit ? "AUDITED" : "ENDORSED"} PAYROLL:`;
    case "PRE-PRE":
      return `${isAudit ? "ORIGINAL" : "PREVIOUS"} PREMIUM:`;
    case "END-PRE":
      return `${isAudit ? "AUDITED" : "ENDORSED"} PREMIUM:`;
  }
};
