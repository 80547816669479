import { FC } from "react";
import { UserAccessRightsDto } from "../../../../dtos/user-access-rights-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { addAllOption } from "../../../../utilities/selectFunctions";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import { TitleBold } from "../../../TrueUI";

type NewUserAccessRightsSectionProps = {
  tableData?: UserAccessRightsDto;
};
const tableName = "portal-new-user-access-rights-table";
const NewUserAccessRightsSection: FC<NewUserAccessRightsSectionProps> = ({
  tableData,
}) => {
  const statusOptions = addAllOption(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName:
          enumType?.value == StatusEnums.ACTIVE
            ? "Active Only"
            : enumType?.key ?? "",
        intValue: enumType?.value ?? "",
      })
    ),
    false,
    "Both"
  );

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    columnsAndData: {
      columns: tableData?.tableData?.columns ?? [],
      data: tableData?.tableData?.data ?? [],
    },
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "include-filter",
          columnNames: ["InsuredUserStatus"],
          optionsOverride: statusOptions,
          defaultValue: StatusEnums.ACTIVE,
          labelText: "Include:",
        }),
    ],
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showEditButton: false,
      showAddButton: false,
      showExcelButton: false,
      showPDFButton: false,
      showExportButton: false,
    },
    columnOptions: [
      { fieldName: "Program", width: 15 },
      { fieldName: "Admin", width: 10 },
      { fieldName: "Billing", width: 10 },
      { fieldName: "Claims", width: 10 },
      { fieldName: "Policy", width: 10 },
      { fieldName: "LossControl", width: 10 },
      { fieldName: "InsuredName", width: 35 },
    ],
    advancedOptions: {
      disableSortOrder: true,
    },
  };

  useBaseGrid(tableConfiguration);

  return conditionHasValue(tableData) ? (
    <div style={{ width: "70%", marginBottom: "10px" }}>
      <div
        style={{ paddingLeft: "5px", marginBottom: "10px", marginTop: "10px" }}
      >
        <TitleBold display="block" textAlign="start">
          ACCESS RIGHTS
        </TitleBold>
      </div>
      <div style={{ height: "320px" }}>
        <BaseGrid name={tableName} />
      </div>
    </div>
  ) : null;
};
export default NewUserAccessRightsSection;
