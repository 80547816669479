import { FC } from "react";
import { Box } from "@mui/material";
import { Col, Font, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { documentsGroupRowStyles } from "./DocumentStyles";
import { PolicyQuoteDocumentGroupProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { usePermissions } from "../../../../hooks";

const DocumentGroup: FC<PolicyQuoteDocumentGroupProps> = ({
  programPackets,
  selectedPackets,
  onSelectedCheckboxDocument,
}) => {
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);
  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        verticalGutter="10px"
        {...getConfigurationForColumn(12, "flex-start", "center")}
      >
        <Font whiteSpace="pre-wrap" textAlign="start">
          SELECT A GROUP OF DOCUMENTS BY SELECTING A PACKET BELOW
        </Font>
      </Col>
      {programPackets.map((document, idx) => (
        <Box sx={documentsGroupRowStyles()} key={`documents-group-${idx}`}>
          <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
            <Switch
              tabIndex={idx}
              control="checkbox"
              label={document.packetName}
              labelPlacement={"end"}
              isChecked={selectedPackets.includes(document.packetId)}
              onChangeIsChecked={(checked) =>
                onSelectedCheckboxDocument(checked, document.packetId, "packet")
              }
              labelFontType={"BODY"}
              readOnly={!hasPermission}
            />
          </Col>
        </Box>
      ))}
    </Row>
  );
};

export default DocumentGroup;
