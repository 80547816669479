import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Col,
  Font,
  Input,
  InputDate,
  Modal,
  Row,
  Switch,
} from "../../../TrueUI";
import {
  ModalStateAnyProps,
  PolicyStateInModalStateAnyUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { inputConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import FontError from "../../../TrueUI/Typography/FontError";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { ClassCodeDto } from "../../../../dtos/class-code-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import {
  FormattingDate,
  isDateAfterDate,
} from "../../../../utilities/dateFunctions";
import { ClassCodeWithRatesDto } from "../../../../dtos/class-code-with-rates-dto";
import "./ModalStateAny.module.css";

type ClassCodeDescriptionProps = {
  type: string;
  description: string;
};

const initialClassCode = {
  type: "",
  description: "",
};

const ModalStateAny: FC<ModalStateAnyProps> = ({
  tabKey,
  modalStatesUI,
  saveEvent,
  closeEvent,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const [allStates, setAllStates] = useState<Map<string, string>>();
  const [localModalStateUI, setLocalModalStateUI] = useState<
    PolicyStateInModalStateAnyUIProps | undefined | null
  >();
  const [classCodeDescription, setClassCodeDescription] =
    useState<ClassCodeDescriptionProps>(initialClassCode);

  const { responseGet, dispatchGet, validatorErrorResponse } =
    useApiGet<ClassCodeDto>(
      `api/QuotePolicy/GetValidClassCode?classCode=${
        localModalStateUI?.classCode
      }&suffix=${localModalStateUI?.classSuffix}&effectiveDate=${FormattingDate(
        localModalStateUI?.effectiveDate
      )}`
    );

  const { responsePost, dispatchPost } = useApiPost<ClassCodeWithRatesDto>(
    `api/QuotePolicy/GetClassCodeRatesByClassCode?classCodeId=${
      localModalStateUI?.classCodeId
    }&stateCodes=${
      localModalStateUI?.selectedStates
    }&effectiveDate=${FormattingDate(localModalStateUI?.effectiveDate)}`
  );

  const onSaveModal = () => {
    saveEvent?.(localModalStateUI);
  };

  const onClassCodeChange = (value: string = "") => {
    const classCode = (value?.length ?? 0) > 4 ? value?.slice(0, -1) : value;
    const classSuffix = (value?.length ?? 0) > 4 ? value?.slice(-1) : "";
    setLocalModalStateUI({
      ...localModalStateUI,
      classCode: classCode,
      classSuffix: classSuffix,
    });
  };

  const addSelectedState = (checked: boolean, stateCode: string) => {
    const selectedStates = localModalStateUI?.selectedStates ?? [];
    const newSelectedStates = checked
      ? selectedStates.concat([stateCode])
      : selectedStates.filter((state) => state !== stateCode);
    setLocalModalStateUI({
      ...localModalStateUI,
      selectedStates: newSelectedStates,
    });
  };

  const disableSaveButton = () => {
    if (
      classCodeDescription?.type === "error" ||
      !conditionHasValue(localModalStateUI?.classCodeId) ||
      !conditionHasValue(localModalStateUI?.effectiveDate) ||
      !conditionHasValue(localModalStateUI?.expirationDate) ||
      (localModalStateUI?.selectedStates ?? []).length === 0
    ) {
      return true;
    }
    return false;
  };

  const statesKeyValueMap = allStates ? [...allStates?.entries()] : [];

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const states = policyQuote?.configuration?.stateList;
    setAllStates(
      new Map(
        states
          ?.filter((state) => state.reportingBureau !== "Mono")
          .map((state) => [state.stateCode, state.longName]) ?? []
      )
    );
    const alreadyStates =
      policyQuote?.quote?.stateList?.trim().split(",") ?? [];
    setLocalModalStateUI({
      alreadyStates: alreadyStates,
      effectiveDate: modalStatesUI.initialStateValueWhenOpen?.effectiveDate,
      expirationDate: modalStatesUI.initialStateValueWhenOpen?.expirationDate,
    });
  }, []);

  useEffect(() => {
    if (
      (localModalStateUI?.effectiveDate ?? "") === "" ||
      (localModalStateUI?.expirationDate ?? "") === ""
    ) {
      setClassCodeDescription({
        type: "error",
        description: "Please enter a valid effective/expiration date",
      });
    } else if (
      isDateAfterDate(
        localModalStateUI?.effectiveDate ?? "",
        localModalStateUI?.expirationDate ?? ""
      )
    ) {
      setClassCodeDescription({
        type: "error",
        description:
          "The effective date couldn't be greater than the expiration date",
      });
    } else {
      if (conditionHasValue(localModalStateUI?.classCode)) {
        dispatchGet();
      }
      setClassCodeDescription(initialClassCode);
    }
  }, [
    localModalStateUI?.effectiveDate,
    localModalStateUI?.expirationDate,
    modalStatesUI.isModalAnyOpen,
  ]);

  useEffect(() => {
    if (
      !isEmptyValue(localModalStateUI?.selectedStates) &&
      conditionHasValue(localModalStateUI?.classCodeId)
    ) {
      dispatchPost();
    }
  }, [localModalStateUI?.classCodeId, localModalStateUI?.selectedStates]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const classCode = responseGet.axiosResponse?.data;
      setLocalModalStateUI({
        ...localModalStateUI,
        classCodeId: classCode?.classCodeId,
        classDescription: classCode?.description ?? "",
      });
      setClassCodeDescription({
        type: "classCode",
        description: classCode?.description ?? "",
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (validatorErrorResponse) {
      const classCodeInput: any = document.getElementById("id-class-code");
      classCodeInput?.focus();
      classCodeInput?.select();
      setClassCodeDescription({
        type: "error",
        description:
          "The class code you entered is not valid. Please try again",
      });
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setLocalModalStateUI({
        ...localModalStateUI,
        classRates: responsePost.axiosResponse?.data,
      });
    }
  }, [responsePost]);

  return (
    modalStatesUI && (
      <Modal
        id={"modal-add-state-any-policy"}
        title={"Add If Any State to Policy"}
        open={modalStatesUI.isModalAnyOpen}
        showCloseButton={true}
        showCancelTextButton={true}
        saveEvent={onSaveModal}
        saveOverrideLabel="OK"
        cancelEvent={closeEvent}
        cancelButtonWithConfirmation={true}
        saveDisabled={disableSaveButton()}
      >
        <Row {...rowWithNoMarginNorGutter}>
          <Col colHeight="305px">
            <StyleBox
              showBorder
              height="305px"
              flexWrap="wrap"
              horizontalAlign="start"
              verticalAlign={"center"}
              className="styled-container-modal-state-any"
            >
              {statesKeyValueMap.map((stateKeyValue: [string, string]) => (
                <div className="modal-state-any-checkbox-container">
                  <Switch
                    {...inputConfiguration(
                      "id-state-code",
                      "state-code",
                      stateKeyValue[1]
                    )}
                    inputWidth="270px"
                    key={stateKeyValue[0]}
                    control="checkbox"
                    isChecked={localModalStateUI?.selectedStates?.includes(
                      stateKeyValue[0]
                    )}
                    labelFontType="BOLD_CAPTION"
                    onChangeIsChecked={(value) =>
                      addSelectedState(value, stateKeyValue[0])
                    }
                    readOnly={localModalStateUI?.alreadyStates?.includes(
                      stateKeyValue[0]
                    )}
                  />
                </div>
              ))}
            </StyleBox>
          </Col>
        </Row>
        <Box mb="20px" mt="20px">
          <Row {...rowWithNoMarginNorGutter}>
            <Col>
              <Input
                {...inputConfiguration(
                  "id-class-code",
                  "class-code",
                  "Class Code",
                  5
                )}
                value={
                  (localModalStateUI?.classCode ?? "") +
                  (localModalStateUI?.classSuffix ?? "")
                }
                onChangeRawValue={(value) => onClassCodeChange(value)}
                onBlur={() => dispatchGet()}
              />
            </Col>
            <Col>
              <InputDate
                {...inputConfiguration(
                  "id-effectiveDate",
                  "policyStateEffectiveDate",
                  "Effective Date"
                )}
                value={localModalStateUI?.effectiveDate}
                onChangeRawValue={(dateValue) =>
                  setLocalModalStateUI({
                    ...localModalStateUI,
                    effectiveDate: dateValue,
                  })
                }
              />
            </Col>
            <Col>
              <InputDate
                {...inputConfiguration(
                  "id-expirationDate",
                  "policyStateExpirationDate",
                  "Expiration Date"
                )}
                value={localModalStateUI?.expirationDate}
                onChangeRawValue={(dateValue) =>
                  setLocalModalStateUI({
                    ...localModalStateUI,
                    expirationDate: dateValue,
                  })
                }
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col horizontalAlign="flex-start">
              {classCodeDescription?.type !== "error" ? (
                <Font>{classCodeDescription?.description}</Font>
              ) : (
                <FontError>{classCodeDescription?.description}</FontError>
              )}
            </Col>
          </Row>
        </Box>
      </Modal>
    )
  );
};

export default ModalStateAny;
