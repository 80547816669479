import { FC, useEffect, useState } from "react";
import { Col, Font, Input, Row, Select } from "../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import { ReserveTypeAndPaymentCategoryProps } from "../typesAndConstants";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../utilities/inputPropsConfigurationFunctions";
import { useApiGet } from "../../../../../../../hooks";
import { SelectOptions } from "../../../../../../../dtos/select-options";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import { ReservesInformationDto } from "../../../../../../../dtos/reserves-information-dto";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../../../../GlobalAtoms";
import themes from "../../../../../../../media/TrueTheme";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import { PaymentStatusEnum } from "../../../../../../../dtos/payment-status-enum";
import { parenthesesCurrencyFormat } from "../../../../../../../utilities/stringFunctions";

type SelectedOption = {
  reserveTypeId?: number;
  reserveAmount?: number | null;
  paymentCategorySelectOptions: SelectOptions[] | Partial<SelectOptions>[] | [];
  paymentCategorySelectKey: string;
  errorMessage: string | null;
};

const ReserveTypeAndPaymentCategory: FC<ReserveTypeAndPaymentCategoryProps> = ({
  claimId,
  reserveTypeOptions,
  setPaymentData,
  paymentData,
  errorDetails,
  readOnly,
  setPaymentCategories,
  showTotalReserve = false,
  claimLandingClaimant,
}) => {
  const [selectedReserve, setSelectedReserve] = useState<SelectedOption>({
    reserveTypeId: paymentData?.reserveTypeId ?? -1,
    reserveAmount: null,
    paymentCategorySelectOptions: [],
    paymentCategorySelectKey: crypto.randomUUID(),
    errorMessage: null,
  });

  const [reservesInformation, setReservesInformation] =
    useState<ReservesInformationDto | null>(null);
  const { responseGet, dispatchGet } = useApiGet<ReservesInformationDto>(
    `api/Payment/GetReservesInformation?claimId=${claimId ?? 0}`
  );

  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  useEffect(() => {
    dispatchGet();
  }, []);

  const getReserve = (reserveTypeId) =>
    reservesInformation?.reserves?.find(
      (x) => x?.reserveTypeId === reserveTypeId
    );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setReservesInformation(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    const reserve = getReserve(paymentData?.reserveTypeId);
    const error = getErrorMessage(
      paymentData?.paidAmount,
      reserve?.reserveAmount
    );

    setSelectedReserve({
      ...selectedReserve,
      reserveTypeId: reserve?.reserveTypeId,
      reserveAmount: reserve?.reserveAmount,
      paymentCategorySelectOptions: reserve?.paymentCategoryList ?? [],
      errorMessage: error,
    });

    setPaymentData({
      ...paymentData,
      outstandingReserves: reserve?.reserveAmount,
    });
  }, [reservesInformation]);

  const claimantRatesAndCategoryTypeRelationship = {
    TTDRate: "tempDisabilityRate",
    VocRate: "vocationalRehabRate",
    PPDRate: "permDisabilityRate",
  };

  const getRateValueFromClaimClaimantForPaymentCategory = (
    categoryId,
    reserveId: number | null
  ) => {
    const reserve = getReserve(reserveId);
    const categoryOptions =
      reserveId !== null
        ? reserve?.paymentCategoryList ?? []
        : selectedReserve?.paymentCategorySelectOptions ?? [];
    const defaultCategoryOption =
      categoryOptions.find(
        (categoryOption) => categoryOption.intValue === categoryId
      ) ?? null;
    const claimClaimantRateType =
      (defaultCategoryOption?.stringValue ?? "") !== ""
        ? defaultCategoryOption?.stringValue
        : null;
    const rateToUseFromClaimant =
      claimantRatesAndCategoryTypeRelationship?.[claimClaimantRateType ?? ""] ??
      null;

    return {
      paidAmount:
        paymentData?.paymentId === 0
          ? claimLandingClaimant?.[rateToUseFromClaimant] ?? 0
          : paymentData?.paidAmount,
    };
  };

  const onReserveTypeChange = (value: number) => {
    const reserve = getReserve(value);
    const categoryOptions = reserve?.paymentCategoryList ?? [];
    const defaultCategoryId =
      categoryOptions?.length > 0 ? categoryOptions[0]?.intValue ?? -1 : -1;
    setPaymentCategories(categoryOptions);
    setPaymentData({
      ...paymentData,
      reserveTypeId: value,
      paymentCategoryId: defaultCategoryId,
      outstandingReserves: reserve?.reserveAmount,
      ...getRateValueFromClaimClaimantForPaymentCategory(
        defaultCategoryId,
        value
      ),
    });
  };

  const getErrorMessage = (paidAmount?: number, reserveAmount?: number) => {
    if (
      paymentData?.paymentStatus !== PaymentStatusEnum.PAID &&
      paidAmount &&
      reserveAmount &&
      paidAmount > reserveAmount
    )
      return "Exceeded Reserves";
    return null;
  };

  useEffect(() => {
    if (
      paymentData?.reserveTypeId !== null &&
      paymentData?.reserveTypeId !== undefined &&
      (reservesInformation?.reserves?.length ?? 0) > 0
    ) {
      const reserve = getReserve(paymentData?.reserveTypeId);
      const error = getErrorMessage(
        paymentData?.paidAmount,
        reserve?.reserveAmount
      );
      setSelectedReserve({
        ...selectedReserve,
        reserveTypeId: paymentData?.reserveTypeId,
        reserveAmount: reserve?.reserveAmount,
        paymentCategorySelectOptions: reserve?.paymentCategoryList ?? [],
        errorMessage: error,
      });
    }
  }, [paymentData]);

  useEffect(() => {
    if (selectedReserve?.paymentCategorySelectOptions?.length > 0) {
      setSelectedReserve({
        ...selectedReserve,
        paymentCategorySelectKey: crypto.randomUUID(),
      });
    }
  }, [selectedReserve?.paymentCategorySelectOptions]);

  const onPaymentCategoryChange = (value: number) => {
    setPaymentData({
      ...paymentData,
      paymentCategoryId: value,
      ...getRateValueFromClaimClaimantForPaymentCategory(value, null),
    });
  };

  return (
    <Col {...colWithNoMarginNorGutter} colDirection="column">
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {readOnly ? (
            <Input
              {...inputConfiguration(
                "id-reserve-type-readonly",
                "paymentFormReadOnlyReserveType",
                "Reserve Type",
                100
              )}
              value={paymentData?.reserveType}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Select
              tabIndex={1}
              {...selectConfiguration(
                "id-reserve-type",
                "paymentFormReserveType",
                "Reserve Type"
              )}
              options={reserveTypeOptions ?? []}
              value={paymentData?.reserveTypeId ?? 0}
              onChange={(value) => {
                onReserveTypeChange(value);
              }}
              firstOptionAsDefault={false}
              errorMessage={errorDetails?.reserveTypeId}
              labelFontType={"BOLD_CAPTION"}
              readOnly={readOnly}
            />
          )}
        </Col>

        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {readOnly ? (
            <Input
              {...inputConfiguration(
                "id-payment-category-readonly",
                "paymentFormReadOnlyPaymentCategory",
                "Payment Category",
                100
              )}
              value={paymentData?.paymentCategory}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Select
              tabIndex={2}
              {...selectConfiguration(
                "id-payment-category",
                "paymentFormPaymentCategory",
                "Payment Category"
              )}
              options={selectedReserve?.paymentCategorySelectOptions ?? []}
              value={paymentData?.paymentCategoryId}
              onChange={(value) => {
                onPaymentCategoryChange(value);
              }}
              firstOptionAsDefault={true}
              errorMessage={errorDetails?.paymentCategoryId}
              labelFontType={"BOLD_CAPTION"}
              key={selectedReserve?.paymentCategorySelectKey}
            />
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        {showTotalReserve &&
          selectedReserve?.reserveTypeId !== undefined &&
          selectedReserve?.reserveTypeId !== -1 && (
            <div style={{ display: "flex", marginLeft: "6px" }}>
              <Input
                type={
                  (selectedReserve?.reserveAmount ?? 0) < 0
                    ? "text"
                    : "fixedCurrency"
                }
                {...inputConfiguration(
                  "total-resverve-amount",
                  "totalReserveAmount",
                  "Outstanding Reserves:",
                  100
                )}
                labelPosition="start"
                labelFontType="BODY"
                value={
                  (selectedReserve?.reserveAmount ?? 0) < 0
                    ? parenthesesCurrencyFormat(
                        selectedReserve?.reserveAmount ?? 0
                      )
                    : selectedReserve?.reserveAmount ?? 0
                }
                readOnly
                allowNegatives
              />
              {selectedReserve?.errorMessage !== null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                >
                  <ErrorOutlineIcon fontSize="small" htmlColor={theme.DANGER} />
                  <Font isErrorFont>{selectedReserve?.errorMessage}</Font>
                </div>
              )}
            </div>
          )}
      </Row>
    </Col>
  );
};

export default ReserveTypeAndPaymentCategory;
