import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Font,
  Input,
  InputDate,
  Row,
  Select,
  Switch,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import {
  AddPaymentFormProps,
  AddPaymentFormSelectOptionsProps,
  API_PATH,
  PaymentTableRow,
} from "./typesAndConstants";
import ReserveTypeAndPaymentCategory from "./FormComponents/ReserveTypeAndPaymentCategory";
import {
  selectConfiguration,
  inputConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../utilities/conditionalSupportFunctions";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import SearchText from "../../../../../TrueUI/Search/SearchText";
import { SelectOptions } from "../../../../../../dtos/select-options";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import VoidedButtonContainer from "./FormComponents/VoidedButtonContainer";
import InputWithIcon from "../../../../../TrueUI/Inputs/InputWithIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../../../GlobalAtoms";
import themes from "../../../../../../media/TrueTheme";
import { RulesConditionsDto } from "../../../../../../dtos/rules-conditions-dto";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { PaymentStatusEnum } from "../../../../../../dtos/payment-status-enum";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { FromEnumToArray } from "../../../../../../utilities/enumFunctions";
import { PaymentFrequencyEnums } from "../../../../../../dtos/payment-frequency-enums";
import DeniedAndLienWarningComponent from "./ReservesSubTable/DeniedAndLienWarningComponent";
import {
  getPayeeDataByPayToClaimantOnChange,
  getPaymentFormDataFromPayee,
  getPaymentFormSelectsData,
} from "./AddPaymentFormUtils";
import ButtonRefundContainer from "./RefundComponents/ButtonRefundContainer";
import { parenthesesCurrencyFormat } from "../../../../../../utilities/stringFunctions";
import { ClaimFlagsDataAtom } from "./ClaimFinancialAtoms";
import ButtonCopyContainer from "./FormComponents/ButtonCopyContainer";
import { CopyPaymentConfigProps } from "./RefundComponents/TypesModalRefundPayment";
import ButtonMoveContainer from "./MovePaymentComponents/ButtonMoveContainer";

export const payeeSearchTextKeys = ["payeeName"];
export const payeeSearchTextKeysToShowExtraDataIntoInput = [
  "address",
  "city",
  "state",
  "zip",
  "taxId",
];
type DialogConfirmationOptions = {
  isDeleteDialogOpen: boolean;
  isDuplicateDialogOpen: boolean;
};

const AddPaymentForm: FC<AddPaymentFormProps> = ({
  paymentFormSelectsData,
  claimId,
  tabKey,
  closeModal,
  paymentType,
  setNewPaymentRowData,
  setUpdatedPaymentRowData,
  setDeletedPaymentRowData,
  rowKey,
  setIsVoidedRefundOrMoveModalConfig,
  setPaymentFormData,
  paymentFormData,
  claimLandingClaimant,
  setExternalSave,
  externalErrorDetails,
  paymentResponse,
  isReadOnly,
  currentPaymentStatus,
  paymentModalProps,
  setPaymentModalProps,
}) => {
  const [initialStatusAndAmount, setInitialStatusAndAmount] = useState<{
    statusId: number | null;
    amount: number | null;
  }>({ statusId: null, amount: null });
  const [copyPaymentConfig, setCopyPaymentConfig] =
    useState<CopyPaymentConfigProps>({
      isFormReadOnly: false,
      isPaymentBeingCopied: false,
    });
  const [isFormReadOnly, setIsFormReadOnly] = useState<boolean>(isReadOnly);
  const claimFlagsData = useRecoilValue(ClaimFlagsDataAtom);
  const defaultPaymentSelectOptions: AddPaymentFormSelectOptionsProps =
    getPaymentFormSelectsData(paymentFormSelectsData);
  const [selectOptions, setSelectOptions] =
    useState<AddPaymentFormSelectOptionsProps>(defaultPaymentSelectOptions);

  const [paymentCategories, setPaymentCategories] = useState<
    SelectOptions[] | Partial<SelectOptions>[] | []
  >([]);

  const { responsePost: deleteResponsePost, dispatchPost: deleteDispatchPost } =
    useApiPost(
      `${API_PATH}/DeletePayment?paymentId=${paymentFormData?.paymentId}`
    );
  const { responseGet: paidRulesResponse, dispatchGet: getPaidRules } =
    useApiGet<RulesConditionsDto>(
      `api/ClaimApprovalRule/GetUserRules?claimId=${claimId}`
    );
  const {
    responsePost: checkDuplicatesResponsePost,
    dispatchPost: checkDuplicatesDispatchPost,
  } = useApiPost<boolean>(
    `api/Payment/CheckForDuplicates?claimId=${claimId}`,
    paymentFormData
  );

  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<DialogConfirmationOptions>({
      isDeleteDialogOpen: false,
      isDuplicateDialogOpen: false,
    });

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const isDenied =
    claimFlagsData?.isDenied || conditionHasValue(claimFlagsData?.dateDenied);
  const isLien = claimFlagsData?.isLien;
  const showVoidedAndRefoundButtons =
    !paymentFormData?.recovery &&
    !paymentFormData?.voided &&
    !paymentFormData?.refund;
  const showCopyAndMoveButtons =
    paymentType !== "manual-payment" && paymentType !== "add-payment";
  const showActionButtons =
    paymentType === "manual-payment" ||
    paymentType === "add-payment" ||
    [
      PaymentStatusEnum.DENIED,
      PaymentStatusEnum.PENDING_APPROVAL,
      PaymentStatusEnum.PENDING,
      PaymentStatusEnum.PAID,
    ].includes(paymentFormData?.paymentStatus ?? 0) ||
    paymentModalProps?.isCopying === true;
  const showDeleteButton =
    paymentType === "view-payment" &&
    (paymentModalProps?.isCopying ?? false) === false;
  const showSaveButton =
    !isFormReadOnly &&
    (paymentModalProps?.isCopying === true ||
      [PaymentStatusEnum.PENDING_APPROVAL, PaymentStatusEnum.PENDING].includes(
        paymentFormData?.paymentStatus ?? 0
      ) ||
      paymentType === "manual-payment");

  const getPaymentStatusValueFromId = (paymentStatusId?: number | null) => {
    const paymentStatus = selectOptions?.paymentStatuses.find(
      (status) => status.intValue === paymentStatusId
    );
    return paymentStatus?.displayName;
  };

  const getPaymentReserveTypeValueFromId = (paymentCategoryId?: number) => {
    const paymentStatus = paymentCategories.find(
      (status) => status.intValue === paymentCategoryId
    );
    return paymentStatus?.displayName;
  };

  const [paymentRulesByClaim, setPaymentRulesByClaim] =
    useState<RulesConditionsDto>();
  const [exceededUserLimit, setExceededUserLimit] = useState<boolean>(false);

  useEffect(() => {
    if (
      paymentType === "view-payment" &&
      paymentFormData?.paymentId !== 0 &&
      initialStatusAndAmount.amount === null &&
      initialStatusAndAmount.statusId === null
    ) {
      setInitialStatusAndAmount({
        statusId: paymentFormData?.paymentStatus ?? null,
        amount: paymentFormData?.paidAmount ?? null,
      });
    }
  }, [paymentFormData]);

  useEffect(() => {
    if (paymentType !== "manual-payment") {
      getPaidRules();
    }
  }, [claimId]);

  useEffect(() => {
    if (
      copyPaymentConfig.isFormReadOnly === false &&
      copyPaymentConfig.isPaymentBeingCopied === true
    ) {
      setExceededUserLimit(userLimit(paymentFormData?.paidAmount ?? 0));
      setPaymentFormData({
        ...paymentFormData,
        paymentId: 0,
        paymentStatus: getStatus(paymentFormData?.paidAmount ?? 0),
        paymentStatusName: getPaymentStatusValueFromId(
          getStatus(paymentFormData?.paidAmount ?? 0)
        ),
        isPaymentCopied: true,
        referenceNo: "",
        cleared: false,
        clearedDate: null,
      });
      setIsFormReadOnly(false);
      setPaymentModalProps({
        ...paymentModalProps,
        title: "Add Payment",
        option: "add-payment",
        isCopying: true,
      });
    }
  }, [copyPaymentConfig]);

  useEffect(() => {
    setIsFormReadOnly(isReadOnly);
  }, [isReadOnly]);

  useEffect(() => {
    if (paymentFormSelectsData !== null) {
      setSelectOptions(defaultPaymentSelectOptions);
    }
  }, [paymentFormSelectsData]);

  useEffect(() => {
    if (isAPITotallyComplete(paidRulesResponse)) {
      setPaymentRulesByClaim(paidRulesResponse.axiosResponse?.data);
    }
  }, [paidRulesResponse]);

  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const paymentFrequencyOptions = FromEnumToArray(PaymentFrequencyEnums).map(
    (frequency) => ({
      displayName: frequency.key,
      stringValue: frequency.value,
    })
  );
  useEffect(() => {
    setSelectOptions(defaultPaymentSelectOptions);
  }, [paymentFormSelectsData]);

  useEffect(() => {
    if (paymentResponse !== null) {
      setComponentTriggers([
        "activityLogComponent",
        "claimBanner",
        "financialTableClaimLandingPageComponent",
      ]);
      if (paymentFormData?.paymentId === 0) {
        const paymentsFromResponse = paymentResponse ?? [];
        const paymentsToAdd = paymentsFromResponse.map((payment) => {
          return {
            ...paymentFormData,
            paymentDate: payment?.paymentDate,
            paymentId: payment.paymentId,
            updateBy: payment?.updateBy,
            PaymentStatus: payment?.paymentStatusName,
            PaymentCategory:
              getPaymentReserveTypeValueFromId(
                paymentFormData?.paymentCategoryId
              ) ?? "",
            payeeId: payment?.payeeId,
            payeeName: payment?.payeeName,
          } as Partial<PaymentDto> & PaymentTableRow;
        });
        setNewPaymentRowData?.(paymentsToAdd);
      } else {
        if (!isEmptyValue(paymentResponse)) {
          const [editedPayment, ...newPayments] = paymentResponse ?? [];
          setUpdatedPaymentRowData?.({
            paymentData: {
              ...editedPayment,
              paymentStatusName:
                getPaymentStatusValueFromId(editedPayment.paymentStatus) ?? "",
              paymentCategory:
                getPaymentReserveTypeValueFromId(
                  editedPayment.paymentCategoryId
                ) ?? "",
            },
            rowKey: rowKey,
            recurringPayments: newPayments,
          });
        }
      }
    }
  }, [paymentResponse]);

  useEffect(() => {
    if (deleteResponsePost.requestInstanceSuccessful) {
      closeModal(false);
      setDeletedPaymentRowData?.({
        deletedPayment: null,
        rowKey: rowKey,
      });
      setComponentTriggers(["claimBanner"]);
    }
  }, [deleteResponsePost]);

  useEffect(() => {
    if (isFormReadOnly && paymentType !== "manual-payment") {
      const isDeniedOrLien = isDenied || isLien;
      setPaymentFormData?.({
        ...paymentFormData,
        paymentStatus: isDeniedOrLien
          ? PaymentStatusEnum.PENDING_APPROVAL
          : PaymentStatusEnum.PENDING,
        paymentStatusName: getPaymentStatusValueFromId(
          isDeniedOrLien
            ? PaymentStatusEnum.PENDING_APPROVAL
            : PaymentStatusEnum.PENDING
        ),
      });
    }
  }, [selectOptions?.paymentStatuses]);

  useEffect(() => {
    if (checkDuplicatesResponsePost.responseData !== null) {
      if (checkDuplicatesResponsePost.responseData === true) {
        setIsDialogConfirmationOpen({
          ...isDialogConfirmationOpen,
          isDuplicateDialogOpen: true,
        });
      }
      if (checkDuplicatesResponsePost.responseData !== true) {
        setExternalSave(true);
      }
    }
  }, [checkDuplicatesResponsePost]);

  const saveAction = () => {
    checkDuplicatesDispatchPost();
  };

  const savePayeeData = (payee) => {
    setPaymentFormData?.(
      getPaymentFormDataFromPayee(
        paymentFormData,
        payee,
        payeeSearchTextKeys,
        payeeSearchTextKeysToShowExtraDataIntoInput
      )
    );
  };

  const applyPaymentRules = () => {
    if (paymentRulesByClaim?.isSumPaidTotalExceededLimit) {
      setExceededUserLimit(true);
      setPaymentFormData?.({
        ...paymentFormData,
        paymentStatus: PaymentStatusEnum.PENDING,
        paymentStatusName: getPaymentStatusValueFromId(
          PaymentStatusEnum.PENDING_APPROVAL
        ),
      });
    }
    if (paymentFormData?.paymentStatus !== PaymentStatusEnum.PAID)
      setExceededUserLimit(userLimit(paymentFormData?.paidAmount ?? 0));
  };

  useEffect(() => {
    applyPaymentRules();
  }, [paymentRulesByClaim]);

  const getStatus = (value) => {
    if (paymentType !== "manual-payment") {
      if (
        userLimit(value) ||
        outstandingReservesLimit(value) ||
        isDenied ||
        isLien
      ) {
        return PaymentStatusEnum.PENDING_APPROVAL;
      } else {
        return PaymentStatusEnum.PENDING;
      }
    }
    return paymentFormData?.paymentStatus !== null
      ? paymentFormData?.paymentStatus
      : PaymentStatusEnum.PENDING;
  };

  const userLimit = (value: number) => {
    const paymentRule = paymentRulesByClaim?.approvalRules.find(
      (rule) => rule.type === "Payment"
    );
    if (paymentRule !== undefined) {
      const limit = paymentRule.limit;
      if (value > limit || paymentRulesByClaim?.isSumPaidTotalExceededLimit)
        return true;
    }
    return false;
  };

  const outstandingReservesLimit = (value?: number) =>
    (paymentFormData?.outstandingReserves ?? false) &&
    (value ?? false) &&
    (value ?? 0) > (paymentFormData?.outstandingReserves ?? 0);

  const onChangePaidAmount = (value) => {
    if (value !== initialStatusAndAmount.amount) {
      const status = getStatus(value);
      setPaymentFormData?.({
        ...paymentFormData,
        paidAmount: value,
        paymentStatus: status,
        paymentStatusName: getPaymentStatusValueFromId(status),
      });
    }
    setExceededUserLimit(userLimit(value));
  };

  const onChangePayToClaimant = (value) => {
    const updatedFormData = getPayeeDataByPayToClaimantOnChange(
      value,
      paymentFormData,
      claimLandingClaimant
    );
    setPaymentFormData?.({
      ...updatedFormData,
      payToClaimant: value,
      payeeId: 0,
    });
  };

  const getNewStatusSelectOptions = () =>
    currentPaymentStatus !== PaymentStatusEnum.PENDING_APPROVAL
      ? selectOptions?.paymentStatuses
      : paymentFormSelectsData?.paymentStatusesSelectValues?.filter(
          (status) => {
            const pendingApprovalOptions = [
              PaymentStatusEnum.PENDING,
              PaymentStatusEnum.PENDING_APPROVAL,
              PaymentStatusEnum.DENIED,
            ];
            return pendingApprovalOptions.includes(status?.intValue ?? 0);
          }
        ) ?? [];

  const getCorrectStatusField = () => {
    if (
      copyPaymentConfig.isPaymentBeingCopied ||
      paymentType === "add-payment" ||
      (paymentType === "view-payment" &&
        currentPaymentStatus !== PaymentStatusEnum.PENDING_APPROVAL)
    ) {
      return (
        <Input
          tabIndex={16}
          {...inputConfiguration(
            "id-payment-status-readonly",
            "paymentFormReadOnlyPaymentStatus",
            "Payment Status",
            30
          )}
          value={paymentFormData?.paymentStatusName}
          readOnly
          labelFontType={"BOLD_CAPTION"}
        />
      );
    } else {
      return (
        <Select
          tabIndex={16}
          {...selectConfiguration(
            "id-payment-status",
            "paymentFormPaymentStatus",
            "Payment Status"
          )}
          options={getNewStatusSelectOptions()}
          value={paymentFormData?.paymentStatus ?? -1}
          onChange={(e) => {
            setPaymentFormData?.({
              ...paymentFormData,
              paymentStatus: e,
              paymentStatusName: getPaymentStatusValueFromId(e),
            });
          }}
          errorMessage={externalErrorDetails?.paymentStatus}
          labelFontType={"BOLD_CAPTION"}
          firstOptionAsDefault={false}
          readOnly={isFormReadOnly}
        />
      );
    }
  };

  useEffect(() => {
    if (selectOptions?.paymentStatuses?.length > 0) {
      setPaymentFormData?.({
        ...paymentFormData,
        paymentStatusName: getPaymentStatusValueFromId(
          paymentFormData?.paymentStatus
        ),
      });
    }
  }, [paymentFormData?.paymentStatus, selectOptions?.paymentStatuses]);

  useEffect(() => {
    if (
      paymentFormData?.paymentStatus !== PaymentStatusEnum.PAID &&
      paymentFormData?.outstandingReserves !== null &&
      initialStatusAndAmount.statusId !== null
    ) {
      const status = getStatus(paymentFormData?.paidAmount);
      setPaymentFormData?.({
        ...paymentFormData,
        paymentStatus: status,
        paymentStatusName: getPaymentStatusValueFromId(status),
      });
    }
  }, [paymentFormData?.reserveTypeId]);

  useEffect(() => {
    if (externalErrorDetails !== null && externalErrorDetails !== undefined) {
      if (Object.keys(externalErrorDetails).length > 0) {
        setIsDialogConfirmationOpen({
          isDeleteDialogOpen: false,
          isDuplicateDialogOpen: false,
        });
      }
    }
  }, [externalErrorDetails]);

  return (
    <>
      {paymentType !== "manual-payment" && <DeniedAndLienWarningComponent />}
      <Row verticalGutter={"10px"} horizontalAlign={"center"}>
        {copyPaymentConfig.isPaymentBeingCopied === true && (
          <Font
            name="copy-message"
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
            textAlign="center"
            isErrorFont
          >
            You are creating a new payment record
          </Font>
        )}
      </Row>
      <Row {...rowWithNoMarginNorGutter} verticalGutter={"10px"}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 10, lg: 10, xl: 10 }}
        >
          <ReserveTypeAndPaymentCategory
            claimId={claimId}
            paymentData={paymentFormData}
            setPaymentData={setPaymentFormData}
            reserveTypeOptions={selectOptions?.reserveTypes ?? []}
            errorDetails={externalErrorDetails}
            readOnly={isFormReadOnly}
            setPaymentCategories={setPaymentCategories}
            showTotalReserve
            claimLandingClaimant={claimLandingClaimant}
          />
        </Col>
        <Col
          displayFlex={false}
          breakpoints={{ md: 12, lg: 2, xl: 2 }}
          horizontalAlignSelf={"center"}
          verticalAlign={"center"}
        >
          <Switch
            tabIndex={3}
            control={"checkbox"}
            isChecked={paymentFormData?.payToClaimant ?? false}
            label={"Pay to Claimant"}
            labelPlacement={"top"}
            onChangeIsChecked={(value) => onChangePayToClaimant(value)}
            labelFontType={"BOLD_CAPTION"}
            labelAlignment={"center"}
            readOnly={isFormReadOnly}
            labelStyle={{
              display: "inline-flex",
              margin: "0 auto",
            }}
            inputWidth={"auto"}
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyPayToClaimant"
                : "paymentFormPayToClaimant"
            }
          />
        </Col>
      </Row>

      <Row {...rowWithNoMarginNorGutter}>
        <Col displayFlex={false} breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          {isFormReadOnly ? (
            <Input
              {...inputConfiguration(
                "id-payee-name",
                "paymentFormReadOnlyPayeeName",
                "Payee",
                100
              )}
              value={paymentFormData?.payeeNameAndAddress ?? ""}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <SearchText
              tabIndex={4}
              saveSelectedResult={savePayeeData}
              searchTextKeys={payeeSearchTextKeys}
              searchTextKeysToShowExtraDataIntoInput={
                payeeSearchTextKeysToShowExtraDataIntoInput
              }
              value={paymentFormData?.payeeNameAndAddress ?? ""}
              labelText={"Payee"}
              placeholderText={"Search for a payee"}
              url={"api/Payee/GetPayeeList?searchPayeeValue="}
              errorMessage={externalErrorDetails?.payeeName}
              labelFontType={"BOLD_CAPTION"}
              key={`payee-search-${paymentFormData?.payeeNameAndAddress}`}
              name={"payeeName"}
            />
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={5}
            id="id-invoice-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyInvoiceDate"
                : "paymentFormInvoiceDate"
            }
            label="Invoice Date"
            className={"input-padding-left"}
            value={paymentFormData?.invoiceDate}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                invoiceDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={6}
            id="id-service-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyServiceDate"
                : "paymentFormServiceDate"
            }
            label="Service Date"
            value={paymentFormData?.serviceDate}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                serviceDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={7}
            id="id-payment-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyPaymentDate"
                : "paymentFormPaymentDate"
            }
            label="Payment Date"
            value={paymentFormData?.paymentDate ?? new Date()}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                paymentDate: value,
              })
            }
            readOnly={isFormReadOnly}
            errorMessage={externalErrorDetails?.paymentDate}
            disablePast
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={8}
            {...inputConfiguration(
              "id-payment-method",
              isFormReadOnly
                ? "paymentFormReadOnlyPaymentMethod"
                : "paymentFormPaymentMethod",
              "Payment Method",
              20
            )}
            value={paymentFormData?.paymentMethod ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                paymentMethod: value,
              })
            }
            readOnly={isFormReadOnly || paymentType !== "manual-payment"}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={9}
            {...inputConfiguration(
              "id-invoice-amount",
              isFormReadOnly
                ? "paymentFormReadOnlyInvoiceAmount"
                : "paymentFormInvoiceAmount",
              "Invoice Amount"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={isFormReadOnly ? "left" : "right"}
            value={paymentFormData?.invoiceAmount ?? null}
            onChangeRawValue={(value) => {
              setPaymentFormData?.({
                ...paymentFormData,
                invoiceAmount: value,
              });
            }}
            errorMessage={externalErrorDetails?.invoiceAmount}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={10}
            {...inputConfiguration(
              "id-discount-amount",
              isFormReadOnly
                ? "paymentFormReadOnlyDiscountAmount"
                : "paymentFormDiscountAmount",
              "Discount Amount"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={isFormReadOnly ? "left" : "right"}
            value={paymentFormData?.discountAmount ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                discountAmount: value,
              })
            }
            errorMessage={externalErrorDetails?.discountAmount}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputWithIcon
            tabIndex={11}
            {...inputConfiguration(
              "id-paid-amount",
              isFormReadOnly
                ? "paymentFormReadOnlyPaidAmount"
                : "paymentFormPaidAmount",
              "Paid Amount"
            )}
            maxNumericValue={999999999}
            type={
              (paymentFormData?.paidAmount ?? 0) < 0 &&
              copyPaymentConfig?.isPaymentBeingCopied === false
                ? "text"
                : "fixedCurrency"
            }
            prefix={""}
            align={isFormReadOnly ? "left" : "right"}
            value={
              (paymentFormData?.paidAmount ?? 0) < 0 &&
              copyPaymentConfig?.isPaymentBeingCopied === false
                ? parenthesesCurrencyFormat(paymentFormData?.paidAmount ?? 0)
                : paymentFormData?.paidAmount ?? null
            }
            onChangeRawValue={onChangePaidAmount}
            errorMessage={externalErrorDetails?.paidAmount}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
            icon={<ErrorOutlineIcon fontSize="small" />}
            iconColor={theme.DANGER}
            showIcon={exceededUserLimit}
            allowNegatives={copyPaymentConfig?.isPaymentBeingCopied === true}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          {exceededUserLimit && (
            <div
              style={{
                height: "31px",
                marginTop: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Font isErrorFont>{"Exceeded user limit"}</Font>
            </div>
          )}
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={12}
            {...inputConfiguration(
              "id-invoice-number",
              isFormReadOnly
                ? "paymentFormReadOnlyInvoiceNumber"
                : "paymentFormInvoiceNumber",
              "Invoice Number",
              20
            )}
            value={paymentFormData?.invoiceNumber ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                invoiceNumber: value,
              })
            }
            errorMessage={externalErrorDetails?.invoiceNumber}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={13}
            {...inputConfiguration(
              "id-voucher-number",
              isFormReadOnly
                ? "paymentFormVoucherNumber"
                : "paymentFormVoucherNumber",
              "Voucher Number",
              20
            )}
            value={paymentFormData?.voucherNumber ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                voucherNumber: value,
              })
            }
            errorMessage={externalErrorDetails?.voucherNumber}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={14}
            {...inputConfiguration(
              "id-reference-number",
              isFormReadOnly
                ? "paymentFormReadOnlyReferenceNumber"
                : "paymentFormReferenceNumber",
              "Reference Number",
              30
            )}
            value={paymentFormData?.referenceNo ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                referenceNo: value,
              })
            }
            readOnly={
              paymentModalProps?.isCopying === true
                ? false
                : isFormReadOnly || paymentType !== "manual-payment"
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} horizontalAlign="flex-start">
          <Switch
            tabIndex={15}
            control={"checkbox"}
            isChecked={paymentFormData?.consolidate}
            label={"Consolidate Check"}
            labelPlacement={"top"}
            onChangeIsChecked={(e) => {
              setPaymentFormData?.({
                ...paymentFormData,
                consolidate: e,
              });
            }}
            readOnly={isFormReadOnly}
            labelFontType={"BOLD_CAPTION"}
            labelAlignment={"left"}
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyConsolidate"
                : "paymentFormConsolidate"
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          {getCorrectStatusField()}
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          {isFormReadOnly ? (
            <Input
              {...inputConfiguration(
                "id-released-readonly",
                "paymentFormReadOnlyReleased",
                "Released",
                30
              )}
              value={
                conditionHasValue(paymentFormData?.released) &&
                paymentFormData?.released! > 0
                  ? "Yes"
                  : "No"
              }
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Input
              tabIndex={17}
              {...inputConfiguration(
                "id-released",
                "paymentFormReleased",
                "Released",
                30
              )}
              value={paymentFormData?.released ?? null}
              onChangeRawValue={(value) =>
                setPaymentFormData?.({
                  ...paymentFormData,
                  released: value,
                })
              }
              readOnly={paymentType !== "manual-payment"}
              labelFontType={"BOLD_CAPTION"}
              type={"fixedCurrency"}
              allowNegatives={false}
              decimalScale={0}
              prefix={""}
              thousandSeparator={false}
            />
          )}
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Switch
              tabIndex={18}
              control={"checkbox"}
              isChecked={paymentFormData?.voided}
              label={"Voided"}
              labelPlacement={"top"}
              onChangeIsChecked={(e) => {
                setPaymentFormData?.({
                  ...paymentFormData,
                  voided: e,
                });
              }}
              readOnly={isFormReadOnly || paymentType !== "manual-payment"}
              labelFontType={"BOLD_CAPTION"}
              labelAlignment={"left"}
              name={
                isFormReadOnly
                  ? "paymentFormReadOnlyVoided"
                  : "paymentFormVoided"
              }
            />
            <Switch
              tabIndex={19}
              control={"checkbox"}
              isChecked={paymentFormData?.cleared ?? false}
              label={"Cleared"}
              labelPlacement={"top"}
              onChangeIsChecked={(e) => {
                setPaymentFormData?.({
                  ...paymentFormData,
                  cleared: e,
                });
              }}
              readOnly={isFormReadOnly}
              labelFontType={"BOLD_CAPTION"}
              labelAlignment={"left"}
              name={
                isFormReadOnly
                  ? "paymentFormReadOnlyCleared"
                  : "paymentFormCleared"
              }
            />
          </div>
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} horizontalAlign="flex-start">
          <InputDate
            tabIndex={23}
            id="id-cleared-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyClearedDate"
                : "paymentFormClearedDate"
            }
            label="Cleared Date"
            value={paymentFormData?.clearedDate}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                clearedDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={20}
            id="id-paid-from-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyPaidFromDate"
                : "paymentFormPaidFromDate"
            }
            label="Paid From"
            includeTime={false}
            value={paymentFormData?.paidFromDate}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                paidFromDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={21}
            id="id-paid-to-date"
            name={
              isFormReadOnly
                ? "paymentFormReadOnlyPaidToDate"
                : "paymentFormPaidToDate"
            }
            label="Paid To"
            includeTime={false}
            value={paymentFormData?.paidToDate}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                paidToDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          {isFormReadOnly ? (
            <Input
              {...inputConfiguration(
                "id-ten99-type-readonly",
                "paymentReadOnlyFormTen99",
                "1099 Type",
                30
              )}
              value={paymentFormData?._1099TypeName}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Select
              tabIndex={22}
              {...selectConfiguration(
                "id-ten99-type",
                "paymentFormTen99",
                "1099 Type"
              )}
              options={selectOptions?.ten99Types ?? []}
              value={paymentFormData?._1099type ?? -1}
              onChange={(e) => {
                setPaymentFormData?.({
                  ...paymentFormData,
                  _1099type: e,
                });
              }}
              errorMessage={externalErrorDetails?._1099type}
              firstOptionAsDefault={false}
              labelFontType={"BOLD_CAPTION"}
              readOnly={isFormReadOnly}
            />
          )}
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
        <Col displayFlex={false}>
          <Input
            tabIndex={24}
            {...inputConfiguration(
              "id-payment-comments",
              isFormReadOnly
                ? "paymentFormReadOnlyPaymentComments"
                : "paymentFormPaymentComments",
              "Comments",
              200
            )}
            value={paymentFormData?.comments ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                comments: value,
              })
            }
            errorMessage={externalErrorDetails?.comments}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
            multiline={isFormReadOnly}
            rows={3}
          />
        </Col>
        <Col displayFlex={false}>
          <Input
            tabIndex={25}
            {...inputConfiguration(
              "id-payment-check-comments",
              isFormReadOnly
                ? "paymentFormReadOnlyPaymentCheckComments"
                : "paymentFormPaymentCheckComments",
              "Check Comments",
              200
            )}
            value={paymentFormData?.checkComments ?? null}
            onChangeRawValue={(value) =>
              setPaymentFormData?.({
                ...paymentFormData,
                checkComments: value,
              })
            }
            errorMessage={externalErrorDetails?.checkComments}
            labelFontType={"BOLD_CAPTION"}
            readOnly={isFormReadOnly}
            multiline={isFormReadOnly}
            rows={3}
          />
        </Col>
        <Row
          {...rowWithNoMarginNorGutter}
          numberOfColumns={16}
          horizontalAlign={"flex-start"}
        >
          <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
            <Select
              tabIndex={26}
              {...selectConfiguration(
                "id-paid-from-account",
                "bankAccountId",
                "Paid From Account"
              )}
              options={paymentFormSelectsData?.bankAccountValues ?? []}
              value={paymentFormData?.bankAccountId ?? -1}
              onChange={(e) => {
                setPaymentFormData?.({
                  ...paymentFormData,
                  bankAccountId: e,
                });
              }}
              errorMessage={externalErrorDetails?.bankAccountId}
              labelFontType={"BOLD_CAPTION"}
              readOnly={isFormReadOnly}
            />
          </Col>
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Switch
              tabIndex={27}
              control={"checkbox"}
              isChecked={paymentFormData?.recurring ?? false}
              label={"Recurring"}
              labelPlacement={"top"}
              onChangeIsChecked={(value) => {
                setPaymentFormData?.({
                  ...paymentFormData,
                  recurring: value,
                });
              }}
              labelFontType={"BOLD_CAPTION"}
              readOnly={isFormReadOnly}
              labelStyle={{
                marginLeft: 0,
                paddingLeft: "5px",
              }}
              inputWidth={"auto"}
              name={
                isFormReadOnly
                  ? "paymentFormReadOnlyRecurring"
                  : "paymentFormRecurring"
              }
            />
          </Col>
          {paymentFormData?.recurring && (
            <>
              <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
                <Select
                  tabIndex={28}
                  {...selectConfiguration(
                    "id-recurring-frequency",
                    "paymentFormFrequency",
                    "Frequency"
                  )}
                  options={paymentFrequencyOptions ?? []}
                  value={paymentFormData?.frequency}
                  onChange={(e) => {
                    setPaymentFormData?.({
                      ...paymentFormData,
                      frequency: e,
                    });
                  }}
                  errorMessage={externalErrorDetails?.frequency}
                  firstOptionAsDefault={false}
                  labelFontType={"BOLD_CAPTION"}
                  readOnly={isFormReadOnly}
                />
              </Col>
              <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
                <Input
                  tabIndex={29}
                  {...inputConfiguration(
                    "id-recurring-number-of-payments",
                    isFormReadOnly
                      ? "paymentFormReadOnlyNumberOfPayments"
                      : "paymentFormNumberOfPayments",
                    "# of Payments",
                    20
                  )}
                  type={"number"}
                  prefix={""}
                  align={"left"}
                  value={paymentFormData?.numberOfPayments ?? null}
                  onChangeRawValue={(value) => {
                    setPaymentFormData?.({
                      ...paymentFormData,
                      numberOfPayments: value,
                    });
                  }}
                  maxNumericValue={12}
                  errorMessage={externalErrorDetails?.numberOfPayments}
                  labelFontType={"BOLD_CAPTION"}
                  readOnly={isFormReadOnly}
                />
              </Col>
              <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
                <InputDate
                  tabIndex={30}
                  id="id-recurring-start-date"
                  name={
                    isFormReadOnly
                      ? "paymentFormReadOnlyRecurringStartDate"
                      : "paymentFormRecurringStartDate"
                  }
                  label="Start Date"
                  className={"input-padding-left"}
                  includeTime={false}
                  value={paymentFormData?.startDate}
                  onChangeRawValue={(value) =>
                    setPaymentFormData?.({
                      ...paymentFormData,
                      startDate: value,
                    })
                  }
                  errorMessage={externalErrorDetails?.startDate}
                  labelFontType={"BOLD_CAPTION"}
                  readOnly={isFormReadOnly}
                  disablePast
                />
              </Col>
            </>
          )}
        </Row>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        //verticalGutter="15px"
        horizontalAlign={"space-between"}
        numberOfColumns={24}
      >
        {showVoidedAndRefoundButtons && (
          <>
            <VoidedButtonContainer
              payment={paymentFormData ?? null}
              closeAddPaymentModal={closeModal}
              setIsVoidedRefundOrMoveModalConfig={
                setIsVoidedRefundOrMoveModalConfig
              }
              paymentType={paymentType}
            />
            <ButtonRefundContainer
              payment={paymentFormData ?? null}
              closeAddPaymentModal={closeModal}
              setIsVoidedRefundOrMoveModalConfig={
                setIsVoidedRefundOrMoveModalConfig
              }
              paymentType={paymentType}
              reserveTypes={selectOptions?.reserveTypes ?? []}
            />
          </>
        )}
        {showCopyAndMoveButtons && (
          <>
            <ButtonCopyContainer
              setCopyPaymentConfig={setCopyPaymentConfig}
              copyPaymentConfig={copyPaymentConfig}
            />
            <ButtonMoveContainer
              {...{
                closeAddPaymentModal: closeModal,
                paymentFormData,
                setIsVoidedRefundOrMoveModalConfig,
              }}
            />
          </>
        )}
        {showActionButtons ? (
          <>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 19, lg: 19, xl: 19 }}
              horizontalAlign="flex-start"
            >
              {showDeleteButton && (
                <Button
                  tabIndex={30}
                  variantStyle="outlined"
                  onClick={() => {
                    setIsDialogConfirmationOpen({
                      ...isDialogConfirmationOpen,
                      isDeleteDialogOpen: true,
                    });
                  }}
                  name="paymentFormDeleteButton"
                >
                  DELETE {paymentModalProps?.isCopying}
                </Button>
              )}
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalGutter={"5px"}
              breakpoints={{ md: 5, lg: 5, xl: 5 }}
              horizontalAlign={showSaveButton ? "space-between" : "flex-end"}
            >
              {showSaveButton && (
                <Button
                  tabIndex={31}
                  onClick={saveAction}
                  name="paymentFormSaveButton"
                >
                  SAVE
                </Button>
              )}
              <Button
                tabIndex={32}
                variantStyle="outlined"
                sx={{ marginLeft: "10px" }}
                onClick={() => {
                  closeModal(false, false);
                }}
                name="paymentFormCancelButton"
              >
                CANCEL
              </Button>
            </Col>
          </>
        ) : (
          <Col
            {...colWithNoMarginNorGutter}
            horizontalGutter="5px"
            horizontalAlign="flex-end"
            breakpoints={{ md: 20, lg: 20, xl: 20 }}
          >
            <Button
              tabIndex={30}
              variantStyle="outlined"
              sx={{ marginLeft: "10px" }}
              onClick={() => {
                closeModal(false, false);
              }}
              name="paymentFormCloseButton"
            >
              CLOSE
            </Button>
          </Col>
        )}
      </Row>
      <DialogConfirmation
        id="delete-confirmation"
        open={isDialogConfirmationOpen.isDeleteDialogOpen}
        dialogDescriptionText={"Are you sure you want to delete this payment?"}
        optionYesOverrideLabel="OK"
        optionNoOverrideLabel={"Cancel"}
        onOptionYesEvent={() => {
          deleteDispatchPost();
        }}
        onOptionNoEvent={() => {
          setIsDialogConfirmationOpen({
            ...isDialogConfirmationOpen,
            isDeleteDialogOpen: false,
          });
        }}
      />
      <DialogConfirmation
        id="duplicate-confirmation"
        open={isDialogConfirmationOpen.isDuplicateDialogOpen}
        dialogDescriptionText={
          "This could be a duplicate payment. Do you want to proceed?"
        }
        onOptionYesEvent={() => {
          setExternalSave(true);
        }}
        onOptionNoEvent={() => {
          setIsDialogConfirmationOpen({
            ...isDialogConfirmationOpen,
            isDuplicateDialogOpen: false,
          });
        }}
      />
    </>
  );
};

export default AddPaymentForm;
