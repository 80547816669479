import { FC } from "react";
import { truncateString } from "../../utilities/stringFunctions";
import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "./InsuredAtoms";
import { useRecoilValue } from "recoil";
import { INSURED_ATOM_KEY } from "../../utilities/queryStringsHash";
import { useAtomFamily } from "../../hooks/useAtomFamily";
import { TabDto } from "./Insured";
import { InsuredDto } from "../../dtos/insured-dto";
import { getOpenTabByTabKey } from "../../utilities/localStorageFunctions";

type InsuredTabsTitleProps = {
  tab: TabDto;
  setRemovedTab: (tabKey: string) => void;
};

export const getInsuredName = (insuredInformation?: InsuredDto) => {
  const _insuredName = insuredInformation?.insuredName ?? "";

  const insuredTitle = insuredInformation?.insuredCode
    ? `${insuredInformation?.insuredCode ?? ""} - ${_insuredName}`
    : _insuredName;

  return insuredTitle;
};

const getTitleFromLocalStorage = (tab: TabDto) => {
  const tabInformation = getOpenTabByTabKey(tab?.tabKey);
  const insuredInformation = tab.information?.insuredInformation;
  const _insuredName = insuredInformation?.insuredName;

  const insuredTitle = insuredInformation?.insuredCode
    ? `${insuredInformation?.insuredCode} - ${_insuredName}`
    : _insuredName;

  return tabInformation?.title ?? insuredTitle;
};

const InsuredTabsTitle: FC<InsuredTabsTitleProps> = ({
  tab,
  setRemovedTab,
}) => {
  useRecoilValue(TriggerComponentUpdateAtom("tabTitleComponent"));
  const { resetAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(`${INSURED_ATOM_KEY} ${tab?.tabKey}`)
  );

  return (
    <Tooltip title={getTitleFromLocalStorage(tab)} placement="top">
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        true-element={`true-element-tab-title-insuredName-${tab?.information?.insuredInformation?.insuredId}`}
      >
        <Box sx={{ marginRight: 2 }}>
          {truncateString(getTitleFromLocalStorage(tab), 20)}
        </Box>
        <IconButton
          value={tab?.information?.insuredInformation?.insuredId}
          onClick={(e) => {
            e.stopPropagation();
            setRemovedTab(tab?.tabKey);
            resetAtom();
          }}
        >
          <CloseIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default InsuredTabsTitle;
