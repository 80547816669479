import { FC } from "react";
import { Col, Input, InputDate, InputTax, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import { addDays, subtractDays } from "../../../utilities/dateFunctions";
import { NewSubmissionFormUIProps } from "./ModalNewSubmission";

type NewSubmissionFormProps = {
  newSubmissionFormUI?: NewSubmissionFormUIProps | null;
  setNewSubmissionFormUI?: (
    newSubmissionForm: NewSubmissionFormUIProps
  ) => void;
  errorDetails: any;
};

const NewSubmissionForm: FC<NewSubmissionFormProps> = ({
  newSubmissionFormUI,
  setNewSubmissionFormUI,
  errorDetails,
}) => {
  const setNewSubmissionTarget = (targetProperty: string, targetValue: any) => {
    setNewSubmissionFormUI?.({
      ...newSubmissionFormUI,
      [targetProperty]: targetValue,
    });
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
      >
        <Input
          id="id-new-submission-company-name"
          name="name-new-submission-company-name"
          label="Company Name"
          labelFontType="BOLD_CAPTION"
          maxLength={300}
          value={newSubmissionFormUI?.companyName}
          onChangeRawValue={(value) =>
            setNewSubmissionTarget("companyName", value)
          }
          errorMessage={errorDetails?.companyName}
        />
      </Col>
      <Col horizontalAlign="flex-start" breakpoints={{ md: 5, lg: 5, xl: 5 }}>
        <InputTax
          id="id-new-submission-tax-id"
          name="name-new-submission-tax-id"
          label="Tax ID"
          labelFontType="BOLD_CAPTION"
          value={newSubmissionFormUI?.taxId}
          onChangeRaw={({ value, type }) =>
            setNewSubmissionFormUI?.({
              ...newSubmissionFormUI,
              taxId: value,
              taxIdType: type,
            })
          }
          errorMessage={errorDetails?.taxId || errorDetails?.taxIdType}
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
          >
            <Input
              id="id-new-submission-last-name"
              name="name-new-submission-last-name"
              label="Owner/ Primary Contact Last Name"
              labelFontType="BOLD_CAPTION"
              maxLength={50}
              value={newSubmissionFormUI?.lastName}
              onChangeRawValue={(value) =>
                setNewSubmissionTarget("lastName", value)
              }
              errorMessage={errorDetails?.lastName}
            />
          </Col>
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalMargin="20px"
          >
            <Input
              id="id-new-submission-first-name"
              name="name-new-submission-first-name"
              label="First Name"
              labelFontType="BOLD_CAPTION"
              maxLength={50}
              value={newSubmissionFormUI?.firstName}
              onChangeRawValue={(value) =>
                setNewSubmissionTarget("firstName", value)
              }
              errorMessage={errorDetails?.firstName}
            />
          </Col>
        </Row>
      </Col>
      <Col horizontalAlign="flex-start" breakpoints={{ md: 5, lg: 5, xl: 5 }}>
        <InputDate
          id="id-new-submission-expiration-date"
          name="name-new-submission-expiration-date"
          label="Effective Date"
          labelFontType="BOLD_CAPTION"
          value={newSubmissionFormUI?.effectiveDate}
          onChangeRawValue={(dateValue) =>
            setNewSubmissionTarget("effectiveDate", dateValue)
          }
          minNumericValue={subtractDays(new Date(), 60).getTime()}
          maxNumericValue={addDays(new Date(), 120).getTime()}
          errorMessage={errorDetails?.effectiveDate}
        />
      </Col>
    </Row>
  );
};

export default NewSubmissionForm;
