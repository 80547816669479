import { FC } from "react";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { Col, Font, Input, Row, Select, Switch } from "../../../TrueUI";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { notificationFrequencyOptions } from "../SubscriptionUtils";
import { NotificationMethodEnums } from "../../../../dtos/notification-method-enums";
import { SubscriptionModalConfigurationDto } from "../../../../dtos/subscription-modal-configuration-dto";
import { NotificationFrequencyEnums } from "../../../../dtos/notification-frequency-enums";

type SubscriptionModalContentProps = {
  subscriptionConfig: SubscriptionModalConfigurationDto;
  formData: any;
  setFormData: (value: any) => void;
  errorDetails: any;
};

const SubscriptionModalContent: FC<SubscriptionModalContentProps> = ({
  subscriptionConfig,
  formData,
  setFormData,
  errorDetails,
}) => {
  const updateSubscriptionMethod = (value: number) => {
    setFormData?.({
      ...formData,
      notificationMethod: value,
      summary:
        value === NotificationMethodEnums.EMAIL ? formData.summary : false,
      frequency:
        value === NotificationMethodEnums.EMAIL
          ? formData.frequency
          : NotificationFrequencyEnums.INSTANT,
    });
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={1}
            id="id-activity-category"
            name="activity-category"
            label="Category"
            type="text"
            labelFontType="BOLD_CAPTION"
            value={subscriptionConfig?.activityType}
            readOnly
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            tabIndex={2}
            id="id-event-description"
            name="event-description"
            label="Event Description"
            type="text"
            labelFontType="BOLD_CAPTION"
            value={subscriptionConfig?.categoryDescription}
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-notification-method",
              "notification-method",
              "Notification Method",
              false
            )}
            tabIndex={3}
            labelFontType="BOLD_CAPTION"
            options={subscriptionConfig?.subscriptionOptions}
            value={formData?.notificationMethod}
            onChange={updateSubscriptionMethod}
            errorMessage={errorDetails?.subscriptionOptions}
          />
        </Col>
        <Col
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          verticalAlignSelf={"flex-end"}
        >
          {formData?.notificationMethod === NotificationMethodEnums.EMAIL && (
            <Switch
              tabIndex={4}
              id="id-summary"
              name="summary"
              label="Summary?"
              labelFontType="BODY"
              labelPlacement="end"
              isChecked={formData?.summary}
              control={"checkbox"}
              onChangeIsChecked={(value) =>
                setFormData?.({
                  ...formData,
                  summary: value,
                  frequency: value
                    ? formData.frequency
                    : NotificationFrequencyEnums.INSTANT,
                })
              }
            />
          )}
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          {formData?.notificationMethod === NotificationMethodEnums.EMAIL &&
            formData?.summary && (
              <Select
                {...selectConfiguration(
                  "id-frequency",
                  "frequency",
                  "Frequency",
                  false
                )}
                tabIndex={5}
                labelFontType="BOLD_CAPTION"
                options={notificationFrequencyOptions}
                value={formData?.frequency}
                onChange={(value) => {
                  setFormData?.({
                    ...formData,
                    frequency: value,
                  });
                }}
                errorMessage={errorDetails?.frequency}
              />
            )}
        </Col>
      </Row>
      {(subscriptionConfig.userOnly || subscriptionConfig.pinnedOnly) && (
        <Row
          {...rowWithNoMarginNorGutter}
          verticalMargin={"5px"}
          horizontalGutter={"5px"}
        >
          <Font fontType={"BOLD_TITLE"}>OPTIONS</Font>
        </Row>
      )}
      {subscriptionConfig.userOnly && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
            horizontalAlign={"flex-start"}
          >
            <Switch
              tabIndex={6}
              id="id-assigned-to-user-only"
              name="assigned-to-user-only"
              label="Filter to Mine Only"
              labelFontType="BODY"
              labelPlacement="end"
              isChecked={formData?.assignedToUserOnly}
              control={"switch"}
              onChangeIsChecked={(value) =>
                setFormData?.({
                  ...formData,
                  assignedToUserOnly: value,
                })
              }
            />
          </Col>
          <Col
            breakpoints={{ md: 9, lg: 9, xl: 9 }}
            horizontalAlign={"flex-start"}
            verticalAlignSelf={"center"}
          >
            <Font>
              When selected will only send notification for insured/policy/claim
              that you are assigned to
            </Font>
          </Col>
        </Row>
      )}
      {subscriptionConfig.pinnedOnly && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
            horizontalAlign={"flex-start"}
          >
            <Switch
              tabIndex={7}
              id="id-filter-to-pinned"
              name="filter-to-pinned"
              label="Filter to Pinned Only"
              labelFontType="BODY"
              labelPlacement="end"
              isChecked={formData?.filterToPinned}
              control={"switch"}
              onChangeIsChecked={(value) =>
                setFormData?.({
                  ...formData,
                  filterToPinned: value,
                })
              }
            />
          </Col>
          <Col
            breakpoints={{ md: 9, lg: 9, xl: 9 }}
            horizontalAlign={"flex-start"}
            verticalAlignSelf={"center"}
          >
            <Font>
              When selected will only send notification for insured/policy/claim
              that you have in your pinned list
            </Font>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SubscriptionModalContent;
