import { FC, useEffect, useState } from "react";
// import { useRecoilValue } from "recoil";
import { Input } from "../..";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { KeysAsType } from "../../../../types/KeysAsAType";
import { FontsType } from "../../../../media/themeTypes";
// import { tableCellInstanceAtomFamilyIds } from "../TableAtoms";

type ExtraInputProps = {
  prefix?: string;
  maxNumericValue?: number;
  minNumericValue?: number;
  decimalScale?: number;
  thousandSeparator?: boolean;
  allowNegatives?: boolean;
};

interface BaseTableTextCellProperties extends BaseTableCellSelectProperties {
  // TODO - fix the T (type) reference issue. set to `any` to prevent it from complaining | note: maybe convert this and all cell types to use intersect rather than extending the interface
  type: "text" | "number" | "email" | "currency";
  extraProps?: ExtraInputProps;
  readOnly?: boolean;
  inputFontType?: KeysAsType<FontsType>;
  defaultValue?: string;
}

const TextCell2: FC<BaseTableTextCellProperties> = ({
  cellKey,
  rowKey,
  value,
  onChange,
  column,
  isEdit,
  type,
  readOnly,
  variant,
  errorMessage,
  extraProps,
  align,
  maxLength,
  // rowIndex,
  refreshCellManager,
  inputFontType,
  isAutoFocused,
  defaultValue = "",
}) => {
  // const cellIds = useRecoilValue(tableCellInstanceAtomFamilyIds);
  const [_value, _setValue] = useState(value ?? "");

  useEffect(() => {
    if (value === "" || conditionHasValue(value)) {
      _setValue(value);
    }
  }, [value, refreshCellManager]);

  useEffect(() => {
    if (refreshCellManager === true) {
      if (value === "" || conditionHasValue(value)) {
        _setValue(value);
      }
    }
  }, [refreshCellManager]);

  return (
    <Input
      id={`id-${column.fieldName}-${cellKey}`}
      name={`name-${column.fieldName}-${cellKey}`}
      key={cellKey}
      readOnly={readOnly ?? !isEdit}
      type={type}
      value={_value}
      variant={variant}
      isInputIdent={false}
      onBlur={(e) => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: e.target.value,
          // cellIds: cellIds ?? [],
        });
      }}
      onChange={(e) => {
        _setValue(e?.target?.value ?? defaultValue);
      }}
      errorMessage={errorMessage}
      decimalScale={extraProps?.decimalScale}
      prefix={extraProps?.prefix}
      align={type == "currency" ? "right" : align}
      maxLength={maxLength}
      maxNumericValue={extraProps?.maxNumericValue}
      minNumericValue={extraProps?.minNumericValue}
      thousandSeparator={extraProps?.thousandSeparator}
      allowNegatives={extraProps?.allowNegatives}
      inputFontType={inputFontType ?? "BODY"}
      isAutoComplete={false}
      focus={isAutoFocused}
    />
  );
};

export default TextCell2;
