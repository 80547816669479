import { FC } from "react";
import { Button, Col } from "../../../../../../TrueUI";
import { VoidedButtonContainerProps } from "./VoidedTypesAndConstants";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import { usePermissions } from "../../../../../../../hooks";
import { PaymentStatusEnum } from "../../../../../../../dtos/payment-status-enum";

const VoidedButtonContainer: FC<VoidedButtonContainerProps> = ({
  payment,
  closeAddPaymentModal,
  setIsVoidedRefundOrMoveModalConfig,
  paymentType,
}) => {
  const hasPermissionToVoid = usePermissions([13, 14, 15]);

  const onVoid = () => {
    closeAddPaymentModal(false);
    setIsVoidedRefundOrMoveModalConfig?.({
      refundPaymentData: null,
      refund: false,
      voided: true,
      reserveTypes: [],
      refreshTable: false,
      paymentId: payment?.paymentId ?? -1,
      movePayment: false,
      movePaymentData: null,
    });
  };

  return (
    <>
      {hasPermissionToVoid &&
        paymentType !== "manual-payment" &&
        payment?.paymentStatus === PaymentStatusEnum.PAID &&
        payment?.voided === false && (
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 4, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Button
              tabIndex={22}
              variantStyle="outlined"
              onClick={() => onVoid()}
              name="paymentFormVoidButton"
            >
              VOID
            </Button>
          </Col>
        )}
    </>
  );
};

export default VoidedButtonContainer;
