import { FC } from "react";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { GroupSectionIcon } from "../../../TrueUI/Icons/CustomIcons";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { StatusEnums } from "../../../../dtos/status-enums";

type RelatedInsuredTableWrapperProps = {
  insuredId: number;
  setTableHasInsured?: (tableHasInsured: boolean) => void;
};

const RelatedInsuredTableWrapper: FC<RelatedInsuredTableWrapperProps> = ({
  insuredId,
  setTableHasInsured,
}) => {
  const tableName = "related_insureds_add_parent_table";
  const getURL = `api/InsuredRelationship/GetRelationshipsByInsuredId?insuredId=${insuredId}&showAllData=${true}`;

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: false,
      showPDFButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    columnOptions: [
      { fieldName: "OPTIONS", width: "10px" },
      { fieldName: "InsuredName", width: 40 },
      { fieldName: "RelationshipType", width: 20 },
      { fieldName: "StartDate", width: 20 },
      { fieldName: "EndDate", width: 20 },
    ],
    filterOptions: [
      (actionOptions) => {
        return BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          labelText: "Display:",
          columnsNames: ["RelationshipStatus"],
          defaultValue: StatusEnums.ACTIVE.toString(),
          optionsOverride: [
            {
              stringValue: StatusEnums.ACTIVE.toString(),
              displayName: "Active Only",
            },
            {
              stringValue: StatusEnums.INACTIVE.toString(),
              displayName: "Inactive",
            },
          ],
        });
      },
    ],
    advancedOptions: {
      paginate: false,
      hideRefreshFiltersButton: true,
      disableOrderBy: true,
      indicationColumnConfiguration: {
        isHidden: false,
        conditionalIcon: (row) => {
          return parseInt(row.InsuredId) === insuredId ? (
            <GroupSectionIcon />
          ) : (
            <></>
          );
        },
      },
    },
    events: {
      onDataChange: (data) => {
        setTableHasInsured?.((data?.length ?? 0) > 0);
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div id={"related_insureds_add_parent_table_container"}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default RelatedInsuredTableWrapper;
