import { FC, useEffect, useState } from "react";
import { Button, Col, FontBold, Input, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { PayrollReportPayInlineDto } from "../../../../dtos/payroll-report-pay-inline-dto";
import { TableData } from "../../../TrueUI/Tables/BaseTableStructure";
import { getColumnWidth } from "./PayrollReportDetailUtils";
import { Box } from "@mui/material";

type PayrollReportPayInlineFormProps = {
  allColumns: any;
  totalAmount: number;
  payInlineData: PayrollReportPayInlineDto | null;
  setPayInlineData: (value: PayrollReportPayInlineDto | null) => void;
  errorDetails: any;
  isCheckPaymentDisabled: boolean;
};

const PayrollReportPayInlineForm: FC<PayrollReportPayInlineFormProps> = ({
  allColumns,
  totalAmount,
  payInlineData,
  setPayInlineData,
  errorDetails,
  isCheckPaymentDisabled,
}) => {
  const [isAddingCheckPayment, setIsAddingCheckPayment] = useState(false);

  const onClickAddCheck = () => {
    setIsAddingCheckPayment(true);
  };

  useEffect(() => {
    if (isAddingCheckPayment) {
      setPayInlineData({
        ...payInlineData,
        amount: totalAmount,
      } as PayrollReportPayInlineDto);
    }
  }, [totalAmount, isAddingCheckPayment]);

  const renderCellComponent = (column) => {
    switch (column) {
      case "NetPayroll":
        return <FontBold>{"Check Number"}</FontBold>;
      case "BaseRate":
        return (
          <Input
            type={"text"}
            id={"check-number"}
            name={"check-number"}
            value={payInlineData?.checkNumber}
            align="right"
            maxLength={50}
            onChangeRawValue={(checkNumber) =>
              setPayInlineData({
                ...payInlineData,
                checkNumber,
              } as PayrollReportPayInlineDto)
            }
            errorMessage={errorDetails?.checkNumber}
          />
        );
      case "ModifiedRate":
        return <FontBold>{"Amount"}</FontBold>;
      case "Amount":
        return (
          payInlineData && (
            <Input
              id={"check-amount"}
              name={"check-amount"}
              type={"fixedCurrency"}
              decimalScale={2}
              maxNumericValue={99999999.99}
              allowNegatives={false}
              prefix=""
              value={payInlineData?.amount}
              align="right"
              onChangeRawValue={(amount) =>
                setPayInlineData({
                  ...payInlineData,
                  amount,
                } as PayrollReportPayInlineDto)
              }
              errorMessage={errorDetails?.amount}
            />
          )
        );
      default:
        return "";
    }
  };

  return !isAddingCheckPayment ? (
    <Row
      numberOfColumns={8}
      {...rowWithNoMarginNorGutter}
      verticalGutter="10px"
    >
      <Col horizontalAlign="flex-end" breakpoints={{ xl: 8, lg: 8, md: 8 }}>
        <Button
          onClick={onClickAddCheck}
          variantStyle="outlined"
          isDisabled={isCheckPaymentDisabled}
          sx={{ textTransform: "none", height: "25px" }}
        >
          Add Check Payment
        </Button>
      </Col>
      <Col
        breakpoints={{ xl: true, lg: true, md: true, sm: true }}
        horizontalMargin="20px"
        horizontalGutter="0px"
      ></Col>
    </Row>
  ) : (
    <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
      {allColumns?.map((column, key) => (
        <TableData key={key} width={getColumnWidth(allColumns, column)}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: column === "NetPayroll" ? "flex-end" : "center",
              padding: "5px 0",
            }}
          >
            {renderCellComponent(column)}
          </div>
        </TableData>
      ))}
      <TableData width={"40px"}></TableData>
    </Box>
  );
};

export default PayrollReportPayInlineForm;
