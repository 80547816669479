import { Box } from "@mui/material";

const Sandbox = () => {
  return (
    <Box display={"flex"} flexWrap={"wrap"} sx={{ overflow: "scroll" }}>
      SandBox
    </Box>
  );
};

export default Sandbox;
