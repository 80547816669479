import { FC, useEffect, useState } from "react";
import Row, { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import Col, { colWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Col";
import { ModalConfig } from "./ReservesApprovalConstants";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { ReserveConfigurationDto } from "../../../../../dtos/reserve-configuration-dto";
import { useApiGet } from "../../../../../hooks";
import ReservesApprovalTable from "./ReservesApprovalTable";

const RESERVES_TO_APPROVE_URL = "api/Reserve/GetReservesToApprove";
const RESERVES_TO_APPROVE_CONF_URL = "api/Reserve/GetReserveConfiguration";
const ReservesApproval: FC = () => {
  const [configurationOptions, setConfigurationOptions] =
    useState<ReserveConfigurationDto>();
  const { responseGet, dispatchGet } = useApiGet<ReserveConfigurationDto>(
    RESERVES_TO_APPROVE_CONF_URL
  );
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [modalProps, setModalProps] = useState<ModalConfig>({
    reserveId: 0,
    isReserveModalOpen: false,
    claimId:0
  });

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setConfigurationOptions(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      rowHeight={"100%"}
      rowDirection={"column"}
    >
      <Col {...colWithNoMarginNorGutter} displayFlex={false} colHeight="100%">
        {conditionHasValue(configurationOptions) && (
          <ReservesApprovalTable
            getUrl={RESERVES_TO_APPROVE_URL}
            configurationOptions={configurationOptions}
            modalProps={modalProps}
            setModalProps={setModalProps}
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
          />
        )}
      </Col>
    </Row>
  );
};

export default ReservesApproval;
