import { Badge, IconButton } from "@mui/material";
import { FC } from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

type NotificationsIconProps = {
  onClick: () => void;
  notificationsFlag: number;
};

const NotificationsIcon: FC<NotificationsIconProps> = ({
  onClick,
  notificationsFlag,
}) => {
  return (
    <IconButton
      size="large"
      aria-label="show new notifications"
      color="inherit"
      onClick={onClick}
    >
      {notificationsFlag > 0 ? (
        <Badge badgeContent={notificationsFlag} color="error">
          <NotificationsNoneOutlinedIcon />
        </Badge>
      ) : (
        <NotificationsNoneOutlinedIcon />
      )}
    </IconButton>
  );
};

export default NotificationsIcon;
