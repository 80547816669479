import { FC } from "react";
import { BaseTable2 } from "../../../TrueUI";
import { BaseTable } from "../../../../dtos/base-table";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { AuditWorksheetRecordTypeEnum } from "../../../../dtos/audit-worksheet-record-type-enum";
import { addAllOption } from "../../../../utilities/selectFunctions";
import IconButton from "../../../TrueUI/Buttons/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const detailsTableName = "audit_worksheet_details_table";

type DetailsTableProps = {
  detailsTable?: BaseTable;
  addAction: () => void;
  editAction: (rowData: any) => void;
};

const DetailTable: FC<DetailsTableProps> = ({
  detailsTable,
  addAction,
  editAction,
}) => {
  const filterTypes = FromEnumToArrayWithExceptions(
    AuditWorksheetRecordTypeEnum,
    []
  ).map((enumType) => ({
    displayName: enumType?.key ?? "",
    stringValue: enumType?.value.toString(),
  }));

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton aria-label="expand row" onClick={() => editAction(rowData)}>
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: detailsTableName,
    columnsAndData: {
      columns: detailsTable?.columns ?? [],
      data: detailsTable?.data ?? [],
    },
    toolbarOptions: {
      showImportButton: false,
      showEditButton: false,
      showSortFilter: false,
    },
    columnOptions: [
      { fieldName: "Type", width: 7 },
      { fieldName: "EmployeeName", width: 24 },
      { fieldName: "JobTitle", width: 31 },
      { fieldName: "LocationNumber", width: 4 },
      { fieldName: "State", width: 5 },
      { fieldName: "ClassCode", width: 6 },
      { fieldName: "Exclude", width: 5 },
      { fieldName: "FinalPayroll", width: 9, align: "right" },
      { fieldName: "TotalIncluded", width: 9, align: "right" },
      { fieldName: "OPTIONS", width: 2 },
    ],
    events: {
      addEventOverrideCallback: () => {
        addAction();
      },
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["RecordType"],
          optionsOverride: addAllOption(filterTypes),
          labelText: "Type",
        }),
    ],
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={detailsTableName} />;
};

export default DetailTable;
