import { useRecoilState } from "recoil";
import { SiteNotificationProperties } from "../components/Site/SiteNotification/SiteNotification";
import { globalNotificationProperties } from "../GlobalAtoms";

export function useSiteNotificationProps() {
  const [notificationProps, setNotificationProps] = useRecoilState(
    globalNotificationProperties
  );

  const setNotificationPropsValue = (
    notificationProperties: SiteNotificationProperties
  ) => {
    setNotificationProps(notificationProperties);
  };

  const setNotificationOpenValue = (notificationOpen: boolean) => {
    const notificationPropsTemp: SiteNotificationProperties = {
      notificationTitle: notificationProps?.notificationTitle,
      align: notificationProps?.align,
      componentMessage: notificationProps?.componentMessage,
      actionClose: notificationProps?.actionClose,
      isOpen: notificationOpen,
      expireTime: notificationProps?.expireTime ?? 100000,
      notificationType: notificationProps?.notificationType,
    };
    setNotificationProps(notificationPropsTemp);
  };

  return {
    notificationProps,
    setNotificationPropsValue,
    setNotificationOpenValue,
  };
}
