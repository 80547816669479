import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import EndorsementReportingBindInstructions from "./EndorsementReportingBindInstructions";
import EndorsementInstallBindInstructions from "./EndorsementInstallBindInstructions";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
  hasExposurePremiumRelatedChecked,
} from "../EndorsementForm/EndorsementUtils";
import EndorsementBindInstructionsNoChange from "./EndorsementBindInstructionsNoChange";
import { useApiPost, usePermissions } from "../../../../hooks";
import { Button, Font, Row } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import { useRecoilState } from "recoil";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { validateEndorsementPremiumChangeBeforeBind } from "./EndorsementBindInstructionsUtils";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { AlertProps } from "../../../TrueUI/Alerts/Alert";
import { evaluateRule } from "../PolicyValidation/ValidationEngineFunctions";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import {
  updateInsuredAtom,
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { AlertEnums } from "../../../../dtos/alert-enums";
import ProgramValidationForAlerts from "../PolicyValidation/ProgramValidationForAlerts";
import ModalBindPolicy from "../PolicyQuoteBindInstructions/ModalBindPolicy";
import { policyInformationReset } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { getQueryStringsURL } from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { useNavigate } from "react-router";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { validateExposuresAndPremium } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";

type EndorsementBindInstructionsUI = {
  planType: string | null;
  disableButtonByValidationsFailed: boolean;
  dialogConfiguration: DialogConfirmationProps | null;
};

const defaultEndorsementBindInstructionsUI = {
  planType: null,
  disableButtonByValidationsFailed: false,
  dialogConfiguration: null,
} as EndorsementBindInstructionsUI;

const EndorsementBindInstructions: FC<EndorsementGeneralProps> = (props) => {
  const navigate = useNavigate();
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(atomKey)
  );
  const userPermissions = usePermissions([
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);
  const [endorsementBindInstructionsUI, setEndorsementBindInstructionsUI] =
    useState<EndorsementBindInstructionsUI>(
      defaultEndorsementBindInstructionsUI
    );
  const [showBindPolicyModal, setShowBindPolicyModal] =
    useState<boolean>(false);
  const [bindPolicyErrors, setBindPolicyErrors] = useState<string | null>(null);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps>();
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const {
    responsePost: responseSaveEndorsement,
    dispatchPost: dispatchSaveEndorsement,
  } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SavePolicyEndorsement?endorsementSection=${EndorsementSectionEnum.BIND_INSTRUCTIONS}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const {
    responsePost: responseBindEndorsement,
    dispatchPost: dispatchBindEndorsement,
    validatorErrorResponse: validationErrorBindEndorsementResponse,
  } = useApiPost<PolicyBlob>(
    "api/QuotePolicy/BindEndorsement",
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const getBindInstructionsBody = () => {
    switch (endorsementBindInstructionsUI.planType) {
      case "Reporting":
        return <EndorsementReportingBindInstructions {...props} />;
      case "Install":
        return <EndorsementInstallBindInstructions {...props} />;
      default:
        return <EndorsementBindInstructionsNoChange {...props} />;
    }
  };

  const successBody = () => (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalAlign="flex-start"
    >
      <Font textAlign="start" whiteSpace="normal">
        This policy has been bound successfully.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        The quote is now in an issued status and can no longer be edited.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        Click on the Continue button to close this modal and return to the quote
        to print documents or enter notes.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        Click on the Close Quote button to close this modal and the quote
        completely.
      </Font>
    </Row>
  );

  const errorBody = () => (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalAlign="flex-start"
    >
      <Font textAlign="start" whiteSpace="normal">
        AN ERROR HAS OCURRED!
      </Font>
      <Font textAlign="start" whiteSpace="normal">
        {bindPolicyErrors}
      </Font>
    </Row>
  );

  const getBanner = (validationResults: AlertProps[]) => {
    if (validationResults.length > 1) {
      const type = validationResults.some(
        (validation) => validation.type === AlertEnums.WARNING
      )
        ? AlertEnums.WARNING
        : AlertEnums.INFORMATION;
      const modalHeight = validationResults.reduce(
        (height, validation, index) => {
          if (index < 6)
            validation.message.length > 141 ? (height += 68) : (height += 45);
          return height;
        },
        0
      );
      const message =
        "More than one warning exists for this quote. Click here for more information";

      const body = () => (
        <ProgramValidationForAlerts
          validationResults={validationResults}
          insuredId={props.insuredId}
          policyId={props.policyId}
        />
      );

      return [
        {
          type,
          message,
          showCloseIcon: false,
          modalTitle: "Quote Validation Messages",
          modalContent: body(),
          modalHeight: modalHeight + 230,
        } as AlertProps,
      ];
    }
    return validationResults;
  };

  const executeBind = () => {
    const atomValue = getAtom();
    const isValid =
      endorsementBindInstructionsUI.planType === "Install"
        ? validateEndorsementPremiumChangeBeforeBind(atomValue)
        : true;

    if (isValid) {
      setBindPolicyErrors(null);
      dispatchBindEndorsement();
    } else {
      setEndorsementBindInstructionsUI({
        ...endorsementBindInstructionsUI,
        dialogConfiguration: {
          open: true,
          dialogDescriptionText:
            "The total endorsement amount does not match the endorsement premium change value.",
          optionYesOverrideLabel: "OK",
          onOptionYesEvent: () =>
            setEndorsementBindInstructionsUI({
              ...endorsementBindInstructionsUI,
              dialogConfiguration: null,
            }),
        },
      });
    }
  };

  const getPlanType = (policyJSON?: PolicyBlob | null) => {
    const showExposureAndPremiumSection = hasExposurePremiumRelatedChecked(
      policyJSON?.quote?.endorsementTypeList
    );
    if (showExposureAndPremiumSection) {
      return policyJSON?.payPlan?.planType ?? null;
    } else return null;
  };

  const executeProgramValidations = (
    atomValue: GlobalInsuredAtomProperties | null,
    policyJSON?: PolicyBlob | null
  ) => {
    const validations =
      policyJSON?.endorsementConfiguration?.programValidationList ?? [];
    const validationResults: AlertProps[] = [];
    validations.map((validation) => {
      if (evaluateRule(validation.validationQuery, policyJSON))
        validationResults.push({
          type: validation.validationType,
          message: validation.validationMessage,
          showCloseIcon: false,
        });
    });
    if (!isEmptyValue(validationResults)) {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyValidations",
        getBanner(validationResults)
      );
      setAtom(newAtomValue);
      setComponentTriggers(["alertAtomComponent"]);

      return true;
    } else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyValidations",
        []
      );
      setAtom(newAtomValue);
      setComponentTriggers(["alertAtomComponent"]);

      return false;
    }
  };

  const returnToHistoryTable = () => {
    const atomValue = getAtom();
    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      policyInformationReset
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["endorsementHeaderComponent"]);

    const historyHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: SubSideNavItemDestinationsEnum.HISTORY,
      },
    ]);
    navigate(historyHashes);
  };

  const dialogActionEvent = (close) => {
    const atomValue = getAtom();
    setDialogConfiguration({ ...dialogConfiguration, open: close });
    props.setActiveSection?.(
      (atomValue?.policyQuoteInformation?.activeSection ??
        EndorsementSectionEnum.BIND_INSTRUCTIONS) - 1
    );
  };

  const defaultDialogConfiguration = {
    onCloseEvent: (close) => dialogActionEvent(close),
    onOptionYesEvent: (close) => dialogActionEvent(close),
  };

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const dialogValues = validateExposuresAndPremium(
      atomValue,
      defaultDialogConfiguration
    );

    if (dialogValues !== null) {
      setDialogConfiguration(dialogValues);
    } else {
      const planType = getPlanType(policyJSON);
      const disableButtonByValidationsFailed = executeProgramValidations(
        atomValue,
        policyJSON
      );

      setEndorsementBindInstructionsUI({
        ...endorsementBindInstructionsUI,
        planType,
        disableButtonByValidationsFailed,
      });
    }

    return () => {
      const currentAtomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        currentAtomValue,
        "policyValidations",
        []
      );
      setAtom(newAtomValue);
      setComponentTriggers(["alertAtomComponent"]);
    };
  }, []);

  useEffect(() => {
    if (
      responseBindEndorsement.requestInstanceSuccessful &&
      responseBindEndorsement.axiosResponse?.data !== undefined &&
      !conditionHasValue(validationErrorBindEndorsementResponse)
    ) {
      setBindPolicyErrors(null);
      const atomValue = getAtom();
      const policyJSON = responseBindEndorsement.axiosResponse?.data ?? null;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, true]
      );
      setAtom(newAtomValue);
      setShowBindPolicyModal(true);
      setPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      if (
        (atomValue?.policyQuoteInformation?.policyQuote?.program
          ?.portalUserInviteOnBind ?? false) === true
      ) {
        // TODO - Send portal invitation email for the primary contact.
      }
    }
    if (conditionHasValue(validationErrorBindEndorsementResponse)) {
      const errorMsg =
        validationErrorBindEndorsementResponse?.message ??
        validationErrorBindEndorsementResponse?.title ??
        "";
      setBindPolicyErrors(errorMsg);
      setShowBindPolicyModal(true);
      // TODO - Send email to support@experiencetrue.com that a bind failed, support is ready.
    }
  }, [responseBindEndorsement]);

  //#region Save Logic

  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveEndorsement = (
    policyJSON: PolicyBlob,
    saveAction: string
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      saveAction,
      atomValue,
      policyJSON,
      EndorsementSectionEnum.POLICY_DOCS
    );
    setAtom(newAtomValue);

    if (saveAction === CONTINUE_ACTION)
      setPolicyQuoteTriggers(["endorsementComponent"]);

    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      dispatchSaveEndorsement();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseSaveEndorsement) &&
      responseSaveEndorsement.axiosResponse !== null &&
      responseSaveEndorsement.axiosResponse !== undefined &&
      (saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
        saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION)
    ) {
      updateAtomAfterSaveEndorsement(
        responseSaveEndorsement.axiosResponse.data,
        saveQuoteAtom.saveAction
      );
    }
  }, [responseSaveEndorsement]);

  //#endregion

  return (
    <div className={style.endorsement_bind_instructions_container}>
      {getBindInstructionsBody()}
      {userPermissions.hasPermission ? (
        <div className={style.endorsement_bind_button_container}>
          <Button
            id="id-endorsement-bind-button"
            name="endorsement-bind-button"
            className={style.endorsement_bind_button}
            size="large"
            onClick={() => executeBind()}
            isDisabled={
              props.readonly === true
                ? props.readonly
                : endorsementBindInstructionsUI.disableButtonByValidationsFailed
            }
          >
            BIND POLICY
          </Button>
        </div>
      ) : null}
      <DialogConfirmation
        id="id-dialog-confirmation-endorsement-bind-instructions"
        name="name-dialog-confirmation-endorsement-bind-instructions"
        onCloseEvent={() =>
          setEndorsementBindInstructionsUI({
            ...endorsementBindInstructionsUI,
            dialogConfiguration: null,
          })
        }
        {...endorsementBindInstructionsUI.dialogConfiguration}
      />
      <DialogConfirmation
        name="endorsement-error-dialog"
        id="endorsement-error-dialog-confirmation"
        {...dialogConfiguration}
      />
      <ModalBindPolicy
        showModal={showBindPolicyModal}
        onClose={() => setShowBindPolicyModal(false)}
        onCloseQuote={returnToHistoryTable}
        hasError={!isEmptyValue(bindPolicyErrors)}
        modalBody={
          !isEmptyValue(bindPolicyErrors) ? errorBody() : successBody()
        }
      />
    </div>
  );
};

export default EndorsementBindInstructions;
