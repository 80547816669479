import { FC, useEffect, useState } from "react";
import { Col, Input, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getTotalSurchargesAndFees } from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { BindInstructionPremiumBreakdownProp } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getTotalPremium } from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  colWithHorizontalGutterOnly,
  disabledProrateTerm,
  policyQuoteBilledFees,
  policyQuoteBilledPremium,
} from "./BindInstructionUtils";
import PaymentPlan from "./PaymentPlan";

const PremiumBreakdown: FC<BindInstructionPremiumBreakdownProp> = ({
  insuredId,
  tabKey,
  bindInstructionUI,
  setBindInstructionUI,
  readOnly,
}) => {
  const [prorateTerm, setProrateTerm] = useState(false);

  const updateBindInstructionUI = () => {
    setBindInstructionUI?.({
      ...bindInstructionUI,
      billedPremium: policyQuoteBilledPremium(prorateTerm, bindInstructionUI),
      billedFees: policyQuoteBilledFees(prorateTerm, bindInstructionUI),
    });
  };

  useEffect(() => {
    updateBindInstructionUI();
  }, [prorateTerm]);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign={"flex-start"}
        numberOfColumns={24}
      >
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            id="id-total-premium-input"
            name="nameTotalPremiumInput"
            label="Total Premium"
            labelFontType="BOLD_CAPTION"
            value={getTotalPremium(bindInstructionUI?.states)}
            align="right"
            labelAlign="end"
            readOnly
            inputWidth="110px"
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
        >
          <Input
            id="id-total-fees-input"
            name="totalFeesInput"
            label="Total Fees, Surcharges and Taxes"
            labelFontType="BOLD_CAPTION"
            value={getTotalSurchargesAndFees(
              bindInstructionUI?.states ?? []
            ).toLocaleString()}
            align="right"
            labelAlign="end"
            readOnly
            inputWidth="160px"
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
        >
          <Switch
            id="id-prorate-to-term-input"
            name="prorateToTermInput"
            label="Prorate to Term?"
            labelFontType="BODY"
            labelPlacement="start"
            isChecked={prorateTerm}
            control={"checkbox"}
            onChangeIsChecked={(value) => setProrateTerm(value)}
            readOnly={
              readOnly ||
              disabledProrateTerm(
                bindInstructionUI?.effectiveDate,
                bindInstructionUI?.expirationDate
              )
            }
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            id="id-total-billed-premium-input"
            name="totalBilledPremiumInput"
            label="Billed Premium"
            labelFontType="BOLD_CAPTION"
            value={(bindInstructionUI?.billedPremium ?? 0).toLocaleString()}
            align="right"
            labelAlign="end"
            readOnly
            inputWidth="110px"
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
        >
          <Input
            id="id-total-billed-fees-input"
            name="totalBilledFeesInput"
            label="Billed Fees, Surcharges and Taxes"
            labelFontType="BOLD_CAPTION"
            value={(bindInstructionUI?.billedFees ?? 0).toLocaleString()}
            align="right"
            labelAlign="end"
            readOnly
            inputWidth="160px"
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} rowDirection="column">
        <PaymentPlan
          tabKey={tabKey}
          insuredId={insuredId}
          bindInstructionUI={bindInstructionUI}
          setBindInstructionUI={setBindInstructionUI}
          readOnly={readOnly}
        />
      </Row>
    </>
  );
};

export default PremiumBreakdown;
