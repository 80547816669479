import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { ClassCodesRatesImport } from "../../../../dtos/class-codes-rates-import";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  Button,
  Col,
  Input,
  InputDate,
  Select,
  Switch,
  TitleBold,
  Upload,
} from "../../../TrueUI";
import { getStates } from "../../../../utilities/staticDataFunctions";
import style from "./RateTableStyle.module.css";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { Tooltip } from "@mui/material";

type ClassCodesRatesImportProps = {
  setIsImportFormOpen?: (isOpen: boolean) => void;
  programsList?: SelectOptions[];
  selectedProgram?: number;
};

type ButtonProps = {
  isWCRATEDisabled?: boolean;
  isExcelDisabled?: boolean;
};

const ClassCodesRatesImportForm: FC<ClassCodesRatesImportProps> = ({
  selectedProgram,
  programsList,
}) => {
  const [files, setFiles] = useState<{ [key: string]: number }>();
  const [clear, setClear] = useState<boolean>(false);
  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    isWCRATEDisabled: true,
    isExcelDisabled: true,
  });
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const [importFormInfo, setImportFormInfo] = useState<ClassCodesRatesImport>({
    programId: selectedProgram ?? undefined,
    effectiveDate: new Date(),
    lCM: 1.0,
    isExpired: true,
  });

  const {
    responsePost: responseImportPost,
    dispatchPost: dispatchImportPost,
    validatorErrorResponse,
  } = useApiPost(
    `api/AdminTools/RateTables/ImportClassCodesRates`,
    importFormInfo
  );

  const StatesList: Partial<SelectOptions>[] = getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

  const updateFileValues = (files: { [key: string]: number }, response) => {
    const keys = Object.keys(files ?? []);
    const newFileIds = keys.map((key) => files?.[key]);
    setFiles(files);
    setImportFormInfo({
      ...importFormInfo,
      files: newFileIds,
    });
    setButtonProps({
      isExcelDisabled: response?.[0]?.fileName.includes(".txt") ?? true,
      isWCRATEDisabled: response?.[0]?.fileName.includes(".xls") ?? true,
    });
  };

  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  ).getTime();

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseImportPost)) {
      if (validatorErrorResponse === null) {
        setErrorDetails(null);
        // Do all the stuff to fill the import table
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responseImportPost]);

  useEffect(() => {
    setImportFormInfo({
      ...importFormInfo,
      programId: selectedProgram,
    });
  }, [selectedProgram]);

  return (
    <div className={style?.container_form_class_code_rates}>
      <TitleBold truncate>IMPORTED RATES WILL BE APPLIED TO:</TitleBold>
      <div className={style?.container_form}>
        <Row {...rowWithNoMarginNorGutter} allowWrap>
          <Col breakpoints={{ xs: 12, md: 6, lg: 3 }}>
            <Select
              id="program-id"
              name="program-name"
              label="Program"
              labelPosition="top"
              labelFontType="BOLD_CAPTION"
              value={importFormInfo?.programId}
              options={programsList ?? []}
              onChange={(e) => {
                setImportFormInfo({
                  ...importFormInfo,
                  programId: e,
                });
              }}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 1 }}>
            <Select
              id="state-id"
              name="state-name"
              label="State"
              labelPosition="top"
              labelFontType="BOLD_CAPTION"
              value={importFormInfo?.state}
              options={StatesList}
              onChange={(e) => {
                setImportFormInfo({
                  ...importFormInfo,
                  state: e,
                });
              }}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 3 }}>
            <InputDate
              id="effective-date"
              name="effective-date"
              label="Effective Date"
              value={importFormInfo?.effectiveDate}
              errorMessage={errorDetails?.effectiveDate}
              minNumericValue={firstDayOfCurrentMonth}
              onChangeRawValue={(e) => {
                setImportFormInfo({
                  ...importFormInfo,
                  effectiveDate: e,
                });
              }}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 1 }}>
            <Input
              id={"LCM"}
              name="LCM"
              label="LCM"
              labelPosition="top"
              value={importFormInfo?.lCM?.toString() ?? "0"}
              type={"fixedCurrency"}
              decimalScale={4}
              minNumericValue={0.0005}
              maxNumericValue={99.9999}
              prefix=""
              errorMessage={errorDetails?.lCM}
              onChangeRawValue={(value) => {
                setImportFormInfo({
                  ...importFormInfo,
                  lCM: value,
                });
              }}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 4 }}>
            <Switch
              control={"switch"}
              label="Expire All Current (including those without new rates)?"
              labelFontType="BOLD_CAPTION"
              labelPlacement="top"
              inputWidth="100%"
              labelAlignment="left"
              className={style?.expired_switch}
              isChecked={importFormInfo?.isExpired}
              onChangeIsChecked={(value) => {
                setImportFormInfo({
                  ...importFormInfo,
                  isExpired: value,
                });
              }}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} allowWrap>
          <Col breakpoints={{ xs: 12, md: 6, lg: 7 }}>
            <Upload
              name="upload-files"
              files={files}
              onUploadFileList={updateFileValues}
              clearFiles={clear}
              resetClearFiles={setClear}
              maxFileCount={1}
              allowedExtensions={[".txt", ".xlsx"]}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
            <Button
              fullWidth
              onClick={() => dispatchImportPost()}
              isDisabled={buttonProps?.isWCRATEDisabled}
            >
              IMPORT WCRATE
            </Button>
          </Col>
          <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
            <Button fullWidth isDisabled={buttonProps?.isExcelDisabled}>
              IMPORT EXCEL
            </Button>
          </Col>
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ xs: 12, md: 6, lg: 1 }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "500px",
                  },
                },
              }}
              title={
                <div className={style.tooltip_title}>
                  <span className={style.tooltip_line}>
                    Allowable file type = .xlsx
                  </span>
                  <span className={style.tooltip_line}>
                    Column headers should be removed. Line 1 is the first class
                    code
                  </span>
                  <span className={style.tooltip_line}>
                    Columns: Class Code, Base Rate, ELR, D-ratio, Hazard,
                    Industry, Minimum Premium
                  </span>
                  <span className={style.tooltip_line}>
                    Column values: String, Decimal, Decimal, Decimal, String,
                    Integer, Decimal
                  </span>
                </div>
              }
              className={style.tooltip_import_help}
              placement={"left-start"}
              arrow
            >
              <HelpOutlineIcon className={style.help_icon} />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ClassCodesRatesImportForm;
