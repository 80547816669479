import { FC } from "react";
import { UserInformationDto } from "../../../../dtos/user-information-dto";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { truncateString } from "../../../../utilities/stringFunctions";
import {
  TitleBold,
  Row,
  Col,
  InputPhone,
  Input,
  Switch,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { NewPortalInsuredUser } from "../UsersAtom";
import { useRecoilState } from "recoil";

type UserInfoSectionProps = {
  errorDetails?: any;
};
const NewUserInformationSection: FC<UserInfoSectionProps> = ({
  errorDetails,
}) => {
  const [newPortalInsuredUser, setNewPortalInsuredUser] =
    useRecoilState(NewPortalInsuredUser);

  const userInformation = newPortalInsuredUser?.portalUserInformation;

  const getShortName = (firstName, lastName) => {
    if (
      conditionHasValue(firstName) &&
      conditionHasValue(lastName) &&
      !conditionHasValue(userInformation?.shortName)
    ) {
      const newShortName = `${firstName}${lastName.charAt()}`;
      return truncateString(newShortName, 20);
    }
    return userInformation?.shortName;
  };

  const setUserInformation = (portalUserInformation: UserInformationDto) => {
    setNewPortalInsuredUser?.({
      ...newPortalInsuredUser,
      portalUserInformation: portalUserInformation,
    });
  };

  return (
    <>
      <div style={{ paddingLeft: "5px", marginBottom: "10px" }}>
        <TitleBold display="block" textAlign="start">
          USER INFORMATION
        </TitleBold>
      </div>

      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 5 }}>
          <Input
            id="email-address"
            name="email-address"
            label="Email Address"
            labelPosition="top"
            value={userInformation?.userEmail}
            readOnly={true}
            maxLength={100}
            errorMessage={errorDetails?.userEmail}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={1}
            id="first-name"
            name="first-name"
            label="First Name"
            labelPosition="top"
            value={userInformation?.firstName}
            maxLength={100}
            errorMessage={errorDetails?.firstName}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                firstName: value,
                shortName: getShortName(value, userInformation?.lastName),
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 3 }}>
          <Input
            tabIndex={2}
            id="last-name"
            name="last-name"
            label="Last Name"
            labelPosition="top"
            value={userInformation?.lastName}
            maxLength={100}
            errorMessage={errorDetails?.lastName}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                lastName: value,
                shortName: getShortName(userInformation?.firstName, value),
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Input
            tabIndex={3}
            id="short-name"
            name="short-name"
            label="Short Name"
            labelPosition="top"
            value={userInformation?.shortName}
            errorMessage={errorDetails?.shortName}
            maxLength={20}
            onChangeRawValue={(value) => {
              setUserInformation({
                ...userInformation,
                shortName: value,
              });
            }}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2.5 }}>
          <InputPhone
            tabIndex={4}
            id="phone-number"
            name="phone-number"
            phoneLabel="Phone Number"
            labelPosition="top"
            phoneValue={userInformation?.phoneNumber}
            errorMessagePhone={errorDetails?.phoneNumber}
            onChangePhoneValue={(value) => {
              setUserInformation({
                ...userInformation,
                phoneNumber: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2.5 }}>
          <InputPhone
            tabIndex={5}
            id="mobile-number"
            name="mobile-number"
            phoneLabel="Mobile Phone"
            labelPosition="top"
            phoneValue={userInformation?.mobileNumber}
            errorMessagePhone={errorDetails?.mobileNumber}
            onChangePhoneValue={(value) => {
              setUserInformation({
                ...userInformation,
                mobileNumber: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2.5 }}>
          <Switch
            tabIndex={6}
            id="txt-message"
            name="txt-message"
            label="Accepts Text Messages"
            isChecked={userInformation?.textMessage ?? false}
            control={"checkbox"}
            labelFontType={"BOLD_CAPTION"}
            spaceBetweenLabelAndControl={false}
            onChangeIsChecked={(value) => {
              setUserInformation({ ...userInformation, textMessage: value });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2.5 }}>
          <Input
            id="last-logon"
            name="last-logon"
            label="Last Logon"
            labelPosition="top"
            value={FormattingDate(
              userInformation?.lastLogon,
              "M/d/yyyy h:mm a"
            )}
            readOnly
          />
        </Col>
        <div id={"credential-key"} style={{ display: "none" }}>
          {userInformation?.credentialKey ?? ""}
        </div>
      </Row>
    </>
  );
};
export default NewUserInformationSection;
