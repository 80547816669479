import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { removeDuplicateObjectsByPath } from "../../../../utilities/arrayFunctions";

export const ERROR_TEMPLATE_NAME = "_ERROR_TEMPLATE_NO_FOUND_";

export const getFilteredAndSortedPacketDocuments = (
  packetDocuments?: ProgramPacketDocumentDto[],
  selectedPackets?: number[]
) => {
  const filteredAndSortedPacketDocuments =
    packetDocuments
      ?.filter((document) => selectedPackets?.includes(document.packetID))
      .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)) ?? [];

  const packetDocumentsWithoutDuplicateDocs: ProgramPacketDocumentDto[] =
    removeDuplicateObjectsByPath(
      filteredAndSortedPacketDocuments,
      "programPolicyDocument.programDocId"
    );

  return packetDocumentsWithoutDuplicateDocs;
};

export const getFilteredPolicyDocuments = (
  policyDocuments?: ProgramPolicyDocumentDto[],
  filteredDocuments?: ProgramPacketDocumentDto[]
) =>
  policyDocuments?.filter(
    (document) =>
      !filteredDocuments
        ?.map((doc) => doc.programPolicyDocument.programDocId)
        .includes(document.programDocId)
  ) ?? [];

export const getAllTemplateNamesToPrint = (
  selectedPackets: number[],
  selectedDocuments: number[],
  programDocuments: ProgramPacketDocumentDto[],
  policyDocuments: ProgramPolicyDocumentDto[],
  endorsementForms: ProgramEndorsementBlob[]
) => {
  const templateNamesFromPacket = programDocuments.map((packetDocument) => {
    if (
      selectedPackets.includes(packetDocument.packetID) &&
      packetDocument.programPolicyDocument.fileId !== null
    )
      return [
        packetDocument.programPolicyDocument.templateName ??
          ERROR_TEMPLATE_NAME,
      ];
    else if (
      selectedPackets.includes(packetDocument.packetID) &&
      packetDocument.programPolicyDocument.fileId === null // if it's the packet for the endorsement forms.
    ) {
      return endorsementForms.map((form) => {
        return form.templateName ?? ERROR_TEMPLATE_NAME;
      });
    }
    return [];
  });

  const templateNamesFromPacketJoined = templateNamesFromPacket.flatMap(
    (x) => x
  );

  const templateNamesBySelectedDocument = policyDocuments.map(
    (policyDocument) => {
      if (selectedDocuments.includes(policyDocument.programDocId))
        return policyDocument.templateName ?? ERROR_TEMPLATE_NAME;
      return "";
    }
  );

  const allTemplateNames =
    templateNamesFromPacketJoined?.concat(
      templateNamesBySelectedDocument ?? []
    ) ?? [];

  const allTemplateNameFiltered = allTemplateNames.filter(
    (templateName) => templateName !== ""
  );

  const hasInvalidTemplateNames =
    allTemplateNameFiltered.includes(ERROR_TEMPLATE_NAME);

  return {
    hasInvalidTemplateNames,
    allTemplateNames: allTemplateNameFiltered.filter(
      (x) => x !== ERROR_TEMPLATE_NAME
    ),
  };
};

export const getDefaultFileName = (
  insuredName: string,
  selectedPackets: number[],
  selectedDocuments: number[],
  programPackets: ProgramPacketDto[],
  policyDocuments: ProgramPolicyDocumentDto[]
) => {
  if (selectedPackets.length === 1 && selectedDocuments.length === 0) {
    const packetName =
      programPackets.find(
        (programPacket) => programPacket.packetId === selectedPackets[0]
      )?.packetName ?? "";

    return `${insuredName} ${packetName}`;
  } else if (selectedPackets.length === 0 && selectedDocuments.length === 1) {
    const documentName =
      policyDocuments.find(
        (policyDocument) => policyDocument.programDocId === selectedDocuments[0]
      )?.docName ?? "";

    return `${insuredName} ${documentName}`;
  }
  return `${insuredName} Policy Documents`;
};
