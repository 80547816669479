import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { InitOptionsDto } from "../../../dtos/init-options-dto";
import { SelectOptions } from "../../../dtos/select-options";
import { SystemReferenceDto } from "../../../dtos/system-reference-dto";
import { globalOptions } from "../../../GlobalAtoms";
import { useApiGet } from "../../../hooks";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2, Font } from "../../TrueUI";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import style from "./AdminReferenceInsured.module.css";

const tableName = "admin_reference_insured_table";

const AdminReferenceInsured: FC = () => {
  const [localOptions] = useRecoilState<InitOptionsDto>(globalOptions);

  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  const { responseGet, dispatchGet } = useApiGet<SystemReferenceDto[]>(
    "api/SystemReference/GetSystemReferenceListByArea?area=Insured"
  );

  const [selectOptions, setSelectOptions] = useState<Partial<SelectOptions>[]>(
    []
  );

  const [selectedOption, setSelectedOption] = useState<
    SystemReferenceDto | undefined
  >();

  const [getUrlString, setGetUrlString] = useState<string>("");
  const [saveUrlString, setSaveUrlString] = useState<string>("");

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setSelectOptions(
        (responseGet?.responseData?.map((systemReference) => {
          return {
            displayName: systemReference.displayName,
            intValue: systemReference.referenceID,
          };
        }) as Partial<SelectOptions>[]) ?? []
      );
      setSelectedOption(
        responseGet?.responseData ? responseGet?.responseData[0] : undefined
      );
      setReadyToRender(true);
    }
  }, [responseGet]);

  useEffect(() => {
    if (selectedOption !== undefined) {
      const urlGetString = `api/InsuredReference/GetInsuredReferencePage?tableName=${selectedOption?.tableName}&fieldName=${selectedOption?.fieldName}`;
      setGetUrlString(urlGetString);
      const urlSaveString = `api/InsuredReference/SaveInsuredReference?table=${selectedOption?.tableName}&field=${selectedOption?.fieldName}`;
      setSaveUrlString(urlSaveString);
    }
  }, [selectedOption]);

  const selectChange = (value: number) => {
    const selectedSystemReference = responseGet?.responseData?.find(
      (reference) => reference.referenceID === value
    );
    setSelectedOption(selectedSystemReference);
  };

  const DescriptionComponent = () => {
    return (
      <div className={style.description_container}>
        <Font textAlign="start" fontType={"BOLD_CAPTION"}>
          Description
        </Font>
        <div>
          <Font textAlign="start" whiteSpace="break-spaces">{selectedOption?.description}</Font>
        </div>
      </div>
    );
  };

  const editPermission =
    (selectedOption?.tenantDefinable ? true : false) ||
    localOptions.permissions.includes(2);

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: getUrlString,
    postURL: saveUrlString,
    postTarget: "ChangedInsuredReferences",
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "reference-filter",
          filterOptions: actionOptions,
          optionsOverride: selectOptions.length > 0 ? selectOptions : [],
          defaultValue: selectOptions[0]?.intValue ?? "",
          usingExternalFiltering: true,
          isStaticFilter: true,
          onChange: selectChange,
        }),
    ],
    advancedOptions: { CustomHeaderComponent: DescriptionComponent },
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showEditButton: false,
      showAddButton: editPermission,
      showSaveButton: editPermission,
      showPDFButton: false,
      showExcelButton: false,
    },
  };

  useBaseTable(tableConfiguration);

  return readyToRender ? (
    <div className={style.reference_insured_grid_container}>
      <BaseTable2 name={tableName} />
    </div>
  ) : (
    <div>LOADING...</div>
  );
};

export default AdminReferenceInsured;
