import { PolicyBlob } from "../../../../dtos/policy-blob";
import { isValidDateMoment } from "../../../../utilities/dateFunctions";

const AND = "AND";
const OR = "OR";

const RULE_OPERATOR = {
  [AND]: AND,
  [OR]: OR,
};

const EQUAL = "==";
const NOT_EQUAL = "!=";
const LESS_THAN = "<";
const LESS_EQ_THAN = "<=";
const GREATER_THAN = ">";
const GREATER_EQ_THAN = ">=";

const LOGICAL_OPERATORS = {
  [EQUAL]: EQUAL,
  [NOT_EQUAL]: NOT_EQUAL,
  [LESS_THAN]: LESS_THAN,
  [LESS_EQ_THAN]: LESS_EQ_THAN,
  [GREATER_THAN]: GREATER_THAN,
  [GREATER_EQ_THAN]: GREATER_EQ_THAN,
};

class ConditionalRequiredRule {
  constructor() {
    this.PathPropertyNameList = [];
    this.ReservedPropertyName = "";
    this.LogicalOperator = "";
    this.ValueToCompareList = [];
    this.RuleOperator = "";
  }
  public PathPropertyNameList: string[];
  public ReservedPropertyName: string;
  public LogicalOperator: string;
  public ValueToCompareList: string[];
  public RuleOperator: string;
}

class ConditionalRequiredRuleList {
  constructor() {
    this.Rules = <any>[];
  }

  Add(rule: any) {
    if (Array.isArray(this.Rules)) {
      this.Rules.push(rule);
    }
  }

  Length() {
    return this.Rules.length;
  }

  public Rules = <any>[];
}

const GetRulesByConditionalRequiredValue = (rule: string) => {
  let newRule = new ConditionalRequiredRule();
  const newRules = new ConditionalRequiredRuleList();

  let buildingRule = false;
  let buildingPathProp = false;
  let buildingPropName = false;
  let buildingValue = false;

  let pathPropName = "";
  let valueToCompare = "";

  const isPair = (num: number) => (num % 2 === 0 ? true : false);

  if (
    isPair(rule.match(/[%]/g)?.length ?? 0) &&
    isPair(rule.match(/[#]/g)?.length ?? 0) &&
    isPair(rule.match(/[&]/g)?.length ?? 0) &&
    isPair(rule.match(/[$]/g)?.length ?? 0)
  ) {
    for (let i = 0; i < rule.length; i++) {
      const c = rule[i];
      if (c == "%") {
        if (!buildingRule) {
          buildingRule = true;
        } else {
          newRules.Add(newRule);
          newRule = new ConditionalRequiredRule();
          buildingRule = false;
        }
      } else {
        if (c == "#" || c == "&" || c == "$") {
          if (c == "#") {
            if (!buildingPathProp) {
              buildingPathProp = true;
            } else {
              newRule.PathPropertyNameList.push(pathPropName);
              pathPropName = "";
              buildingPathProp = false;
            }
          } else if (c == "&") {
            buildingPropName = !buildingPropName;
          } else if (c == "$") {
            if (!buildingValue) {
              buildingValue = true;
            } else {
              newRule.ValueToCompareList.push(valueToCompare);
              valueToCompare = "";
              buildingValue = false;
            }
          }
        } else {
          if (buildingPathProp) {
            if (c != ".") {
              pathPropName += c;
            } else {
              newRule.PathPropertyNameList.push(pathPropName);
              pathPropName = "";
            }
          } else if (buildingPropName) {
            newRule.ReservedPropertyName += c;
          } else if (buildingValue) {
            if (c != "|") {
              valueToCompare += c;
            } else {
              newRule.ValueToCompareList.push(valueToCompare);
              valueToCompare = "";
            }
          } else if (c != " " && buildingRule) {
            newRule.LogicalOperator += c;
          } else if (c != " " && !buildingRule) {
            newRule.RuleOperator += c.toLocaleUpperCase();
          }
        }
      }
    }
  }
  return newRules;
};

const ValidateTargetValue = (newObj: any, rule: ConditionalRequiredRule) => {
  let isValid = true;
  for (const valueToCompare of rule.ValueToCompareList) {
    const valueType = typeof newObj;
    let valueToCompareParsed: string | number;
    try {
      valueToCompareParsed =
        valueType === "number"
          ? Number(valueToCompare)
          : newObj != null && isValidDateMoment(new Date(newObj))
          ? new Date(new Date(valueToCompare)).toISOString().slice(0, -1)
          : valueToCompare;
    } catch {
      valueToCompareParsed = "null";
    }
    if ("string" === valueType && !isValidDateMoment(new Date(newObj))) {
      isValid = eval(
        `"${newObj?.toString()?.toLocaleLowerCase() ?? ""}"${
          rule.LogicalOperator
        }"${valueToCompareParsed?.toString()?.toLocaleLowerCase() ?? ""}"`
      );
    } else if ("number" === valueType) {
      isValid = eval(`${newObj}${rule.LogicalOperator}${valueToCompareParsed}`);
    } else if ("string" === valueType && isValidDateMoment(new Date(newObj))) {
      if (
        [LOGICAL_OPERATORS[EQUAL], LOGICAL_OPERATORS[NOT_EQUAL]].includes(
          rule.LogicalOperator
        )
      ) {
        isValid = eval(
          `new Date("${newObj}").getTime()${rule.LogicalOperator}new Date("${valueToCompareParsed}").getTime()`
        );
      } else {
        isValid = eval(
          `new Date("${newObj}")${rule.LogicalOperator}new Date("${valueToCompareParsed}")`
        );
      }
    } else if ("object" === valueType) {
      const newValueToCompare =
        valueToCompareParsed.toString().toLocaleLowerCase() === "null"
          ? null
          : valueToCompareParsed;
      if (rule.LogicalOperator === LOGICAL_OPERATORS[EQUAL]) {
        isValid = newObj === newValueToCompare;
      }
      if (rule.LogicalOperator === LOGICAL_OPERATORS[NOT_EQUAL]) {
        isValid = newObj !== newValueToCompare;
      }
    }

    if (
      (!isValid && rule.LogicalOperator === LOGICAL_OPERATORS[NOT_EQUAL]) ||
      (isValid && rule.LogicalOperator === LOGICAL_OPERATORS[EQUAL])
    ) {
      return isValid;
    }
  }
  return isValid;
};

const ValidateIteratedValueList = (
  list: any[],
  rule: ConditionalRequiredRule,
  propNameIndex: number
) => {
  let isValid = false;
  if (Array.isArray(list)) {
    for (const item of list) {
      isValid = ValidateEndorsementRule(rule, item, propNameIndex + 1);
      if (isValid) break;
    }
  } else {
    isValid = ValidateEndorsementRule(rule, list, propNameIndex + 1);
  }
  return isValid;
};

const ValidateListByReservedProperty = (
  list: any[],
  rule: ConditionalRequiredRule
) => {
  let isValid = true;
  for (const valueToCompare of rule.ValueToCompareList) {
    if (rule.ReservedPropertyName == "Count") {
      if (rule.LogicalOperator == LOGICAL_OPERATORS[EQUAL])
        isValid = list.length == parseInt(valueToCompare);
      else if (rule.LogicalOperator == LOGICAL_OPERATORS[NOT_EQUAL])
        isValid = list.length != parseInt(valueToCompare);
      else if (rule.LogicalOperator == LOGICAL_OPERATORS[LESS_THAN])
        isValid = list.length < parseInt(valueToCompare);
      else if (rule.LogicalOperator == LOGICAL_OPERATORS[GREATER_THAN])
        isValid = list.length > parseInt(valueToCompare);
      else if (rule.LogicalOperator == LOGICAL_OPERATORS[LESS_EQ_THAN])
        isValid = list.length <= parseInt(valueToCompare);
      else if (rule.LogicalOperator == LOGICAL_OPERATORS[GREATER_EQ_THAN])
        isValid = list.length >= parseInt(valueToCompare);
    }
    if (
      (!isValid && rule.LogicalOperator == LOGICAL_OPERATORS[NOT_EQUAL]) ||
      (isValid && rule.LogicalOperator == LOGICAL_OPERATORS[EQUAL])
    )
      break;
  }
  return isValid;
};

const ValidateEndorsementRule = (
  rule: ConditionalRequiredRule,
  obj: any,
  propNameIndex: number
) => {
  const propNameListType = [
    // Policy elements
    "policyStatus",
    "policyType",
    "policyCustomFields",
    "program",
    "experienceRating",
    "typeOfCoverage",
    "employeeLeasingType",
    "policyTerm",
    "planType",
    "auditFrequency",
    "billingFrequency",
    "payPlan",
    "retrospectiveRating",
    "producers",
    "insured",
    "assignment",
    "quote",
    "lossHistory",
    "endorsementForms",
    "invoices",
    "bindInstructions",
    "policyPeriod",
    "narratives",
    "generalInformation",
    // Policy- Producers elements
    "agency",
    "location",
    "agent",
    "oldProducer",
    // Policy-Insured elements
    "insuredCustomFields",
    "names",
    "oldNames",
    "deletedNames",
    "addresses",
    "oldAddresses",
    "deletedAddresses",
    "officers",
    "oldOfficers",
    "deletedOfficers",
    "contacts",
    // Policy-Quote elements
    "customerPolicyType",
    "policyLimits",
    "states",
    "endorsementTypeList",
    "endorsementComments",
    // Policy-Quote-State elements
    "exposures",
    "ratings",
    "waivers",
    // Policy-Quote-State-Rating elements
    "rateOption",
    "policyLimits",
    "policyReference",
    "scheduleRatings",
    // Policy-Quote-EndorsementFormList elements
    "addType",
    // TODO - Add more elements
  ];
  const propName = rule.PathPropertyNameList[propNameIndex];
  const newObjValue = obj?.[propName] ?? null;
  if (propNameIndex < rule.PathPropertyNameList.length - 1) {
    if (propNameListType.includes(propName)) {
      return ValidateIteratedValueList(newObjValue, rule, propNameIndex);
    }
    return ValidateEndorsementRule(rule, newObjValue, propNameIndex + 1);
  } else if (propNameIndex == rule.PathPropertyNameList.length - 1) {
    let isValid = false;
    if (propNameListType.includes(propName)) {
      isValid = ValidateListByReservedProperty(newObjValue, rule);
    } else {
      isValid = ValidateTargetValue(newObjValue, rule);
    }
    return isValid;
  }

  return obj != null ? true : false;
};

const ValidateRules = (
  ruleList: ConditionalRequiredRuleList,
  policyJson: PolicyBlob
) => {
  let isValid = true;
  for (const rule of ruleList.Rules) {
    if (rule.RuleOperator != "") {
      isValid =
        rule.RuleOperator == RULE_OPERATOR[AND]
          ? isValid && ValidateEndorsementRule(rule, policyJson, 0)
          : rule.RuleOperator == RULE_OPERATOR[OR]
          ? isValid || ValidateEndorsementRule(rule, policyJson, 0)
          : false;
    } else {
      isValid = ValidateEndorsementRule(rule, policyJson, 0);
    }
  }

  return isValid;
};

export const evaluateRule = (rule: string, policyJson: any) => {
  const ruleList = GetRulesByConditionalRequiredValue(rule);
  const hasInvalidOperator =
    ruleList?.Rules?.map(
      (rule: ConditionalRequiredRule) => LOGICAL_OPERATORS[rule.LogicalOperator]
    )?.includes(undefined) ?? true;
  const areRulesValid =
    !hasInvalidOperator && ruleList.Length() > 0
      ? ValidateRules(ruleList, policyJson)
      : false;
  return areRulesValid;
};
