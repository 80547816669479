import { FC, useEffect, useState } from "react";
import { useApiPost } from "../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import { Button } from "../../TrueUI";
import CancelButton from "../../TrueUI/Buttons/CancelButton";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import "./PortalUser.module.css";
import { ModalInfoProps } from "./PortalUsersConst";

type CommandSectionProps = {
  modalInfo?: ModalInfoProps;
  setModalInfo?: (info: ModalInfoProps) => void;
};

type DialogProps = {
  isOpen?: boolean;
  dialogTxt?: string;
};

const defaultTxt =
  "This user's permissions have been removed from all programs and insureds.  They will not be able to log into the portal again.  If they are currently on the portal, they will not be automatically kicked off until their session expires or they leave the site.";

const CommandSection: FC<CommandSectionProps> = ({
  modalInfo,
  setModalInfo,
}) => {
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    isOpen: false,
    dialogTxt: defaultTxt,
  });
  const { responsePost, dispatchPost } = useApiPost(
    `api/PortalUser/LockAccount?userId=${modalInfo?.userId}`
  );

  const { responsePost: responseReset, dispatchPost: dispatchReset } =
    useApiPost(`api/PortalUser/ResetUserPassword?userId=${modalInfo?.userId}`);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setDialogProps({ isOpen: true, dialogTxt: defaultTxt });
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseReset)) {
      setDialogProps({ isOpen: true, dialogTxt: "Password reset email sent" });
    }
  }, [responseReset]);

  const buttonProps = {
    variantStyle: "outlined",
    sx: { margin: "10px" },
    width: "150px",
  };

  return (
    <div className={"command_section_container"}>
      <div>
        <Button {...(buttonProps as any)} onClick={() => dispatchPost()}>
          LOCK ACCOUNT
        </Button>
        <Button {...(buttonProps as any)} onClick={() => dispatchReset()}>
          RESET PASSWORD
        </Button>
        <Button {...(buttonProps as any)}>IMPERSONATE USER</Button>
      </div>
      <CancelButton
        withConfirmation
        onClick={() => setModalInfo?.({ ...modalInfo, isModalOpen: false })}
      />
      <DialogConfirmation
        open={dialogProps?.isOpen}
        dialogDescriptionText={dialogProps?.dialogTxt}
        onOptionYesEvent={() => {
          setDialogProps({
            ...dialogProps,
            isOpen: false,
          });
          setModalInfo?.({ ...modalInfo, isModalOpen: false });
        }}
        optionYesOverrideLabel="Close"
      />
    </div>
  );
};
export default CommandSection;
