import { FC } from "react";
import "./achBillingBatchDetails.module.css";
import { Typography } from "@mui/material";

type AchBatchNachaFileCollapseContentProps = {
  nachaFileContent: string;
  isCollapse: boolean;
};
const AchBatchNachaFileCollapseContent: FC<
  AchBatchNachaFileCollapseContentProps
> = ({ nachaFileContent, isCollapse }) => {
  return (
    <div style={{ height: "60%", maxHeight: "60%", marginTop: "-30px" }}>
      {isCollapse && (
        <Typography
          align="justify"
          sx={{
            display: "-webkit-box",
            textAlign: "justify",
            marginBottom: "30px",
            marginRight: "30px",
            whiteSpace: "pre",
            fontFamily: "courier new",
            fontSize: "14px",
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{
            __html: nachaFileContent ?? "",
          }}
        />
      )}
    </div>
  );
};

export default AchBatchNachaFileCollapseContent;
