import { EndorsementTypeEnum } from "../../../../../dtos/endorsement-type-enum";
import { PolicyBlob } from "../../../../../dtos/policy-blob";
import { PolicyEnumValueCodeAndDescriptionBlob } from "../../../../../dtos/policy-enum-value-code-and-description-blob";
import { PolicyTermEnums } from "../../../../../dtos/policy-term-enums";
import {
  addDays,
  addYears,
  areDatesEquals,
  isDateAfterDate,
} from "../../../../../utilities/dateFunctions";
import { GlobalInsuredAtomProperties } from "../../../InsuredAtoms";
import { getSelectedPolicyTerm } from "../../PolicyQuoteInformation/PolicyInformation/InformationUtils";
import { updatePolicyQuoteInformation } from "../../updatesPolicyQuoteFunctions";

export type PolicyKeyFieldsModalConfig = {
  isOpen?: boolean;
  isPolicyNumberCheck?: boolean;
  isPolicyEffectiveDateCheck?: boolean;
  isPolicyExpirationDateCheck?: boolean;
  policyPeriod?: {
    firstDate: Date | null;
    lastDate: Date | null;
  };
  policyTermList?: PolicyEnumValueCodeAndDescriptionBlob[] | null;
};

export type PolicyKeyFieldsModalProps = {
  tabKey: string;
  modalConfiguration: PolicyKeyFieldsModalConfig;
  readonly?: boolean;
  closeModal: () => void;
};

export type PolicyKeyFieldsFormData = {
  policyTerm: string | null;
  policyNumber: string | null;
  effectiveDate: Date | null;
  expirationDate: Date | null;
};

export const defaultPolicyKeyFieldsModalUI = {
  isOpen: false,
  policyNumber: null,
  effectiveDate: null,
  expirationDate: null,
  policyTerm: null,
  policyTermList: null,
  isPolicyNumberCheck: false,
  isPolicyEffectiveDateCheck: false,
  isPolicyExpirationDateCheck: false,
};

export const clearPolicyKeyFieldsEndorsementTypeList = (
  policyJSON: PolicyBlob
) =>
  ({
    ...policyJSON,
    quote: {
      ...policyJSON.quote,
      endorsementTypeList: policyJSON?.quote?.endorsementTypeList?.map(
        (type) => ({
          ...type,
          isChecked:
            type.value === EndorsementTypeEnum.POLICY_NUMBER ||
            type.value === EndorsementTypeEnum.EFFECTIVE_DATE ||
            type.value === EndorsementTypeEnum.EXPIRATION_DATE
              ? false
              : type.isChecked,
        })
      ),
    },
  } as PolicyBlob);

export const getPolicyJSONWithEndorsementTypesRemoved = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
  const policyJSONWithEndorsementTypesRemoved =
    clearPolicyKeyFieldsEndorsementTypeList(policyJSON);
  return updatePolicyQuoteInformation(
    atomValue,
    "policyQuote",
    policyJSONWithEndorsementTypesRemoved
  );
};

export const getPolicyTerm = (
  effectiveDate: Date,
  policyTermCodeList?: PolicyEnumValueCodeAndDescriptionBlob[],
  expirationDate?: Date | null
) => {
  const dateWithDays = addDays(effectiveDate, 16);
  const dateWithYear = addYears(dateWithDays, 1);

  if (areDatesEquals(dateWithYear, expirationDate)) {
    return getSelectedPolicyTerm(
      policyTermCodeList,
      PolicyTermEnums.STANDARD_ONE_YEAR
    );
  }

  if (isDateAfterDate(dateWithYear, expirationDate))
    return getSelectedPolicyTerm(
      policyTermCodeList,
      PolicyTermEnums.SHORT_TERM
    );

  return getSelectedPolicyTerm(policyTermCodeList, PolicyTermEnums.OTHER);
};
