import { Children, cloneElement, createElement, isValidElement } from "react";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { getSafeDateObject } from "../../../utilities/dateFunctions";
import { TableDataItem } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { NO_ROW_KEY_ERROR } from "../../TrueUI/Tables/TableConstants";
import { InternalColumnTypes } from "../BaseGridColumnFactory";
import {
  BaseGridColumnOptionsProperties,
  BaseGridOptionCellType,
  CellKey,
  GridData,
  GridDataRow,
  RowKey,
  StylingFunctionResponse,
} from "../BaseGridProperties";
import { BaseGridFilterParameters } from "../Hooks/useBaseGridFilters";
import { InternalTableColumns } from "./InternalGridColumns";
import { NO_FILTER_ID_FOUND, NO_FILTER_NAME_FOUND } from "../BaseGridConstants";
import {
  addDataItemToTopOfData,
  addInternalDataValuesToNewRow,
  fillMissingNewRowData,
} from "./OLD_baseGridFunctions";
import { getColorFromAppVariables } from "../../../utilities/cssFunctions";

export const getSafeRowKey = (row: TableDataItem): string =>
  row[InternalTableColumns._rowKeyColumn._columnIndex] ?? NO_ROW_KEY_ERROR;

export const getRowByRowKey = (rowKey: RowKey, data: string[][]): string[] => {
  return data.find((r) => getSafeRowKey(r) === rowKey) ?? [];
};

export const getColumnByFieldName = (
  fieldName: string,
  columns: BaseTableColumn[]
): BaseTableColumn | null => {
  return columns.find((c) => c.fieldName === fieldName) ?? null;
};

export const getColumnByColumnIndex = (
  columnIndex: number,
  columns: BaseTableColumn[]
): BaseTableColumn | null =>
  columns.find((c) => c._columnIndex === columnIndex) ?? null;

export const getColumnIndexByColumnName = (
  columnName: string,
  columns: BaseTableColumn[]
) => columns.findIndex((c) => c.fieldName === columnName) ?? -1;

export const getGridDataItemByRowKey = (
  rowKey: RowKey,
  data: GridData
): GridDataRow => {
  return data.find((r) => getSafeRowKey(r) === rowKey) ?? [];
};

export const createNewDataItemWithDefaults = (
  columns: BaseTableColumn[],
  optionalOverrides?: {
    newRowKey?: RowKey;
    optionsColumnValue?: BaseGridOptionCellType;
  }
): string[] => {
  return columns.map((m) => {
    if (
      m.fieldName === InternalColumnTypes.ROW_KEY &&
      (m.defaultValue === null || m.defaultValue === "")
    ) {
      return optionalOverrides?.newRowKey ?? crypto.randomUUID();
    }

    if (m.fieldName === InternalColumnTypes.OPTIONS) {
      return optionalOverrides?.optionsColumnValue ?? "none";
    }

    if (m.defaultValue !== undefined && m.defaultValue !== null) {
      return m.defaultValue.toString();
    }

    return "";
  });
};

export const createCellKey = (rowKey: RowKey, columnIndex: number): CellKey =>
  `${rowKey}_${columnIndex}`;

// Although getCellKeyBy is identical to "createCellKey", there may be some modified logic in the future that where we do not "create" the cellkey in the "getter" version of this function.
export const getCellKey = (rowKey: RowKey, columnIndex: number): CellKey =>
  `${rowKey}_${columnIndex}`;

export const getInvertedCellKey = (
  rowKey: RowKey,
  columnIndex: number
): CellKey => `${columnIndex}_${rowKey}`;

export const rehydrateGridDataRowToType = <T = any>(
  columns: BaseTableColumn[],
  data: GridDataRow
): T | null => {
  return (
    (columns ?? []).reduce(
      (prevCol, currColumn, i) => ({
        ...prevCol,
        [currColumn.fieldName]: data?.[i] ?? "",
      }),
      {} as T
    ) ?? null
  );
};

export const hydrateDataSingle = <T = any>(
  columns: BaseTableColumn[],
  data: string[]
): T | null => {
  return (
    (columns ?? []).reduce(
      (prevCol, currColumn, i) => ({
        ...prevCol,
        [currColumn.fieldName]: data?.[i] ?? "",
      }),
      {} as T
    ) ?? null
  );
};

export const updateGridDataItemWithNewValue = (
  row: GridDataRow,
  columnIndex: number,
  value: any
): GridDataRow => {
  const updatedRow = [...row].map((currentValue, index) => {
    if (index === columnIndex) {
      return value;
    }
    return currentValue;
  });

  return updatedRow;
};

export const add = (
  columns: BaseTableColumn[],
  data: string[][],
  changedData: string[][],
  newDataRow: string[]
  // rowKeyOverride?: string
) => {
  const filledNewRowMissingData = fillMissingNewRowData(columns, newDataRow);
  const populatedNewDataRow = addInternalDataValuesToNewRow(
    filledNewRowMissingData,
    crypto.randomUUID()
  );

  const newDataItem =
    populatedNewDataRow ?? createNewDataItemWithDefaults(columns ?? []);

  const updatedTableData = addDataItemToTopOfData(newDataItem, data ?? []);
  const updatedTableChangedData = addDataItemToTopOfData(
    newDataItem,
    changedData ?? []
  );
  const newRowKey = getSafeRowKey(newDataItem);

  return { newRowKey, updatedTableData, updatedTableChangedData };
};

export const upsertRowData = (
  newData: string[],
  data: string[][]
): string[][] => {
  const rowKey = getSafeRowKey(newData);
  const hasKey = data.find((row) => getSafeRowKey(row) === rowKey);
  if (hasKey !== undefined) {
    return data.map((row) => {
      if (getSafeRowKey(row) === rowKey) {
        return newData;
      }
      return row;
    });
  }
  return data.concat([newData]);
};

export const addOrRemoveRowKey = (
  selectedRowKeys: RowKey[],
  rowKey: string,
  addKey: boolean
): RowKey[] => {
  if (!addKey) {
    return selectedRowKeys.filter((key) => key != rowKey);
  }
  return [rowKey, ...selectedRowKeys];
};

export const getWidthOfCells = (
  columnOptions: BaseGridColumnOptionsProperties<any>[],
  columns: BaseTableColumn[],
  fieldName: string
) => {
  if (InternalColumnTypes.MULTI_ROW_SELECT === fieldName) {
    return "55px";
  }

  if (InternalColumnTypes.INDICATION === fieldName) {
    return "35px";
  }

  const defaultColumn = `${
    100 /
    (columns ?? []).filter((c) => !c.isHidden && !c._isInternal === true).length
  }%`;

  const defaultColumnOption = "35px";

  const alternativeColumnNames = (fieldName: string) => {
    return columnOptions?.find((column) => {
      if (
        column.fieldName === InternalColumnTypes.OPTIONS &&
        fieldName === InternalColumnTypes.OPTIONS
      ) {
        return true;
      }
      return column.fieldName === fieldName;
    });
  };

  if (columnOptions !== null && (columnOptions?.length ?? 0) > 0) {
    const columnInfo = alternativeColumnNames(fieldName);
    if (InternalColumnTypes.OPTIONS === fieldName) {
      return columnInfo?.width !== undefined ? `${columnInfo?.width}%` : "35px";
    }
    return columnInfo?.width !== undefined
      ? `${columnInfo?.width}%`
      : defaultColumn;
  }
  return (InternalColumnTypes.OPTIONS === fieldName) === true
    ? defaultColumnOption
    : defaultColumn;
};

export const addColumnIndexByArrayOrder = (
  columns: BaseTableColumn[]
): BaseTableColumn[] =>
  columns.map((column, index) => {
    return {
      ...column,
      _columnIndex: index,
    };
  });

export const getExternalColumnsOnly = (
  columns: BaseTableColumn[]
): BaseTableColumn[] =>
  columns.filter(
    (c) =>
      c._isInternal !== true &&
      (c._externalColumnIndex !== undefined || c._externalColumnIndex !== null)
  );

export const getExternalDataOnly = (
  columns: BaseTableColumn[],
  data: string[]
) => {
  const externalColumnsOnly = getExternalColumnsOnly(columns);

  return externalColumnsOnly.map((c) => data[c._columnIndex ?? -1] ?? "");
};

export const getAllExternalDataOnly = (
  columns: BaseTableColumn[],
  data: string[][]
) => {
  const externalColumnsOnly = getExternalColumnsOnly(columns);
  const result = data.map((row) => {
    return externalColumnsOnly.map((c) => row[c._columnIndex ?? -1] ?? "");
  });
  return result;
};

export const hydrateAndCastDataSingleExternalColumnsOnly = <T = any>(
  columns: BaseTableColumn[],
  row: string[]
): T | null => {
  const externalColumns = getExternalColumnsOnly(columns);
  const externalRow = getExternalDataOnly(columns, row);
  const castedData = castRowToType(externalColumns, externalRow);

  const result =
    (externalColumns ?? []).reduce(
      (prevCol, currColumn) => ({
        ...prevCol,
        // [toCamelCase(currColumn.fieldName)]: data[currColumn._columnIndex],
        [currColumn.fieldName]:
          castedData[currColumn?._externalColumnIndex ?? -1],
      }),
      {} as T
    ) ?? null;

  return result;
};

export const castValueToType = (
  column: BaseTableColumn | null,
  value: string
): any => {
  if (column === null) {
    return value ?? "";
  }

  switch (column.defaultValueType ?? null) {
    case DataTypeEnums.INT:
    case DataTypeEnums.NULLABLE_INT:
    case DataTypeEnums.DECIMAL:
    case DataTypeEnums.NULLABLE_DECIMAL:
      return value === "" ? null : parseInt(value) ?? null;
    case DataTypeEnums.DATETIME:
    case DataTypeEnums.NULLABLE_DATETIME:
    case DataTypeEnums.DATETIME2:
      return value === "" ? null : getSafeDateObject(value) ?? null;
    case DataTypeEnums.NULLABLE_DATETIME2:
      return value === "" ? null : getSafeDateObject(value) ?? null;
    case DataTypeEnums.BOOLEAN:
    case DataTypeEnums.NULLABLE_BOOLEAN:
      return value === "" ? null : value.toLowerCase() === "true";
    default:
      return value === "" ? null : value ?? "";
  }
};

export const castRowToType = (
  columns: BaseTableColumn[],
  row: string[]
): any[] => {
  return columns.map((column) => {
    // if (value === null) return value;
    // if (columns[index] === undefined) return "";
    const value = row[column?._externalColumnIndex ?? -1] ?? "";

    switch (column.defaultValueType ?? null) {
      case DataTypeEnums.DECIMAL:
      case DataTypeEnums.NULLABLE_DECIMAL:
        return value === ""
          ? null
          : Number(parseFloat(value.replace(/,/g, "")).toFixed(2)) ?? null;
      case DataTypeEnums.INT:
      case DataTypeEnums.NULLABLE_INT:
        return value === "" ? null : parseInt(value ?? "") ?? null;
      case DataTypeEnums.DATETIME:
      case DataTypeEnums.NULLABLE_DATETIME:
        return value === "" ? null : getSafeDateObject(value) ?? null;
      case DataTypeEnums.DATETIME2:
      case DataTypeEnums.NULLABLE_DATETIME2:
        return value === "" ? null : getSafeDateObject(value) ?? null;
      case DataTypeEnums.BOOLEAN:
      case DataTypeEnums.NULLABLE_BOOLEAN:
        return value === "" ? null : value.toLowerCase() === "true";
      default:
        return value === "" ? null : value ?? "";
    }
  });
};

export const getColumnIndexesByColumnNames = (
  names: string[],
  columns: BaseTableColumn[]
): number[] => {
  if (names.length > 0) {
    return names.map((columnName) => {
      return (
        columns?.find((c) => c.fieldName === columnName)?._columnIndex ?? -1
      );
    });
  } else {
    return [-1];
  }
};

export const processFilterOptions = (
  uiid: string,
  name: string,
  filterOptions: any[]
  // columns: BaseTableColumn[]
) => {
  const defaultFilterParameters: BaseGridFilterParameters[] = [];
  const renderedFilterElements = (filterOptions ?? []).map(
    (filterOption, index) => {
      const isValid = isValidElement(filterOption);

      const childrenOfElement = filterOption.props?.["children"] ?? null;
      const updatedChildrenOfElements = {
        ...cloneElement(Children.only(childrenOfElement), {
          uiid: uiid,
          name: name,
        } as any),
        ["uiid"]: uiid,
        ["name"]: name,
      };

      if (isValid) {
        const isStatic = filterOption?.props?.["isStaticFilter"] ?? false;

        const filterId =
          filterOption?.props?.["filterId"] ?? NO_FILTER_ID_FOUND;
        const filterName =
          filterOption?.props?.["filterName"] ?? NO_FILTER_NAME_FOUND;
        if (
          filterId !== NO_FILTER_ID_FOUND &&
          filterName !== NO_FILTER_NAME_FOUND &&
          isStatic === false
        ) {
          defaultFilterParameters.push({
            filterId: filterId,
            filterName: filterName,
            defaultValue: filterOption?.props?.["defaultValue"] ?? null,
            filterValue: filterOption?.props?.["defaultValue"] ?? null,
            columnNames: filterOption?.props?.["columnNames"] ?? [],
            columnIndexes: [],
          });
        }
        const clonedFilterOptionElement = cloneElement(filterOption, {
          uiid: uiid,
          name: name,
        } as any) as any;

        return {
          ...clonedFilterOptionElement,
          ["uiid"]: uiid,
          ["name"]: name,
          props: {
            ...clonedFilterOptionElement?.props,
            ["children"]: updatedChildrenOfElements,
          },
        };
      } else {
        return createElement(
          "div",
          { className: "base_grid_invalid_filter_option" },
          `The provided filterOption is not a valid ReactElement. See filterOptions filter at index ${index} for table ${
            name ?? "NO_TABLE_NAME_FOUND"
          }.`
        );
      }
    }
  );

  return { renderedFilterElements, defaultFilterParameters };
};

export const isNumericDataType = (dataType: number) =>
  [
    DataTypeEnums.DECIMAL,
    DataTypeEnums.DOUBLE,
    DataTypeEnums.FLOAT,
    DataTypeEnums.INT,
    DataTypeEnums.LONG,
    DataTypeEnums.NULLABLE_DOUBLE,
    DataTypeEnums.NULLABLE_FLOAT,
    DataTypeEnums.NULLABLE_INT,
    DataTypeEnums.NULLABLE_LONG,
    DataTypeEnums.NULLABLE_SHORT,
  ].includes(dataType);

export function styleBuilder(
  data: { columns: BaseTableColumn[]; row: any },
  targetColumnName: string | null,
  func: ((...e: any) => StylingFunctionResponse) | undefined,
  cb: (resultValues: StylingFunctionResponse) => React.CSSProperties
): React.CSSProperties | undefined {
  if (func !== undefined) {
    const styleFuncResults = func(
      data?.columns ?? [],
      hydrateDataSingle(data.columns ?? [], data?.row ?? []) ?? null
    ) as StylingFunctionResponse;

    if (targetColumnName !== null) {
      const identifiedColumn =
        (styleFuncResults?.columnNames ?? []).find(
          (z) => z === targetColumnName
        ) ?? "NO_COLUMN_FOUND";

      if (identifiedColumn === "NO_COLUMN_FOUND") return undefined;
    }

    const color = getColorFromAppVariables(
      `--t-${styleFuncResults?.color ?? "default"}`
    );

    const updatedResponse = {
      columnNames: [targetColumnName],
      color: color,
      percent: styleFuncResults?.percent ?? 0,
    } as StylingFunctionResponse;

    const styleResults = cb(updatedResponse);

    return styleResults;
  }
  return undefined; // Normally I don't approve of using 'undefined' but this function is intended only for use directly on the 'style' property which takes 'undefined' well.
}
