export const getVariant = (
  readOnly?: boolean,
  variant?: "standard" | "filled" | "outlined"
) => {
  if (variant === undefined) {
    return readOnly ? "standard" : "filled";
  }
  return variant;
};

export const validatePastedFileName = (
  pastedValue: string,
  reservedCharacters: string[]
) =>
  !reservedCharacters
    .map((keyValue) => {
      if (pastedValue.includes(keyValue)) return false;
      return true;
    })
    .includes(false);
