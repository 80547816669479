import { FC } from "react";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { usePermissions } from "../../../hooks";
import { getUserPreferencesColorPalette } from "../../../utilities/staticDataFunctions";
import Font from "../Typography/Font";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import "./input.module.css";
import { rgbaToHex } from "../../../utilities/colors";
import { ColorPickerView } from "@progress/kendo-react-inputs/dist/npm/colors/interfaces/ColorPickerView";
import FontError from "../Typography/FontError";

export type InputColorResponseProperties = {
  backgroundColor: string;
  textColor: string;
};

type InputColorPickerProperties = {
  id?: string;
  permissions?: number[];
  value?: string;
  label?: string;
  labelFontType?: KeysAsType<FontsType>;
  onRGB?: boolean;
  view?: ColorPickerView;
  errorMessage?: any;
  showGradient?: boolean;
  onChange?: (e: any) => void;
  onRawChange?: (e: InputColorResponseProperties) => void;
};

const InputColorPicker: FC<InputColorPickerProperties> = ({
  id = "",
  onChange,
  onRawChange,
  value,
  permissions = [1, 2, 3],
  label,
  view = "gradient",
  labelFontType,
  errorMessage,
  onRGB = false,
  showGradient = false,
}) => {
  // TODO: @elara Update until Antonio's PR will be ready
  const initValue = value ?? "green";

  const gradientSettings: { opacity: boolean } = {
    opacity: showGradient,
  };

  const getColors = (color: any) => {
    if (color !== null) {
      let colors = color?.value.match(
        /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/
      );

      let r = colors[1],
        g = colors[2],
        b = colors[3];

      let o = Math.round(
        (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000
      );

      let textColor = o > 125 ? "black" : "white";

      return {
        backgroundColor: onRGB ? color.value : rgbaToHex(color.value),
        textColor: textColor,
      };
    }
    return { backgroundColor: "black", textColor: "white" };
  };

  const hasPermission = usePermissions(permissions);

  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <Font fontType={labelFontType}>{label}</Font>
      <ColorPicker
        id={id}
        view={view}
        paletteSettings={{
          palette: getUserPreferencesColorPalette(),
          columns: 7,
          tileSize: { width: 50, height: 40 },
        }}
        gradientSettings={gradientSettings}
        value={initValue}
        size={"small"}
        disabled={!hasPermission}
        onChange={(e) => {
          onChange?.(e);
          onRawChange?.(getColors(e));
        }}
      />
      <FontError>{errorMessage}</FontError>
    </div>
  );
};

export default InputColorPicker;
