import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import { CardContentTypeEnums } from "../../../dtos/card-content-type-enums";
import TaskBoardCalendarCard from "./TaskBoardCalendarCard";
import TaskBoardCard from "./TaskBoardCard";
import { SelectedCardType } from "./TaskBoard";
import { FC } from "react";

type TaskBoardCardContentProps = {
  card: TaskBoardCardDto;
  index: number;
  isSelectedCard: (card: TaskBoardCardDto) => boolean;
  columnField: string;
  onClickCard: (card: SelectedCardType) => void;
};

const TaskBoardCardContent: FC<TaskBoardCardContentProps> = ({
  card,
  index,
  isSelectedCard,
  columnField,
  onClickCard,
}) => {
  const getCardWithContentType = (card: TaskBoardCardDto, index: number) => {
    switch (card.cardContentType) {
      case CardContentTypeEnums.CALENDAR:
        return (
          <TaskBoardCalendarCard
            card={card}
            onClickCard={onClickCard}
            columnField={columnField}
            isSelectedCard={isSelectedCard}
            index={index}
          />
        );
      default:
        return (
          <TaskBoardCard
            card={card}
            onClickCard={onClickCard}
            columnField={columnField}
            isSelected={isSelectedCard(card)}
            key={`card-${card?.cardTitle}-from-${columnField}-${index}`}
          />
        );
    }
  };

  return getCardWithContentType(card, index);
};

export default TaskBoardCardContent;
