import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AppWrapper from "./AppWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import "./AppVariables.css";
// import { StrictMode } from "react";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root") as Element);

root.render(
  <RecoilRoot>
    {/* <StrictMode> */}
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <AppWrapper />
      </MsalProvider>
    </BrowserRouter>
    {/* </StrictMode> */}
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
