import { FC } from "react";
import { PROGRAM_ADMIN_RATE_TABLES_TABS } from "../../../../utilities/queryStringsHash";
import { TabPanel, Tabs } from "../../../TrueUI";
import Header from "../../../TrueUI/Headers/Header";
import ClassCodes from "./ClassCodes";
import ClassCodesRatesWrapper from "./ClassCodesRatesWrapper";
import { useParams } from "react-router";

const RateTables: FC = () => {
  const titlePanel = `Program Admin - Rate Tables`;
  const { programId } = useParams();

  return (
    <>
      <Header>{titlePanel}</Header>
      <Tabs name={PROGRAM_ADMIN_RATE_TABLES_TABS} themeOverride={"material"}>
        <TabPanel title={"Class Codes"} key={"program_class_codes_tab"}>
          <ClassCodes />
        </TabPanel>
        <TabPanel
          title={"Class Code Rates"}
          key={"program_class_codes_rates_tab"}
        >
          <ClassCodesRatesWrapper programId={parseInt(programId ?? "0")} />
        </TabPanel>
        <TabPanel title={"Deductibles"} key={"program_deductibles_tab"}>
          Deductibles
        </TabPanel>
        <TabPanel title={"Discounts"} key={"program_discounts_tab"}>
          Discounts
        </TabPanel>
        <TabPanel title={"Policy Limits"} key={"program_policy_limits_tab"}>
          Policy Limits
        </TabPanel>
        <TabPanel title={"Schedule Rating"} key={"program_schedule_rating_tab"}>
          Schedule Rating
        </TabPanel>
        <TabPanel title={"Short Rate"} key={"program_short_rate_tab"}>
          Short Rate
        </TabPanel>
      </Tabs>
    </>
  );
};

export default RateTables;
