import CalendarWidget from "../CalendarWidget/CalendarWidget";
import TaskBoardWithComponentCard from "./TaskBoardWithComponentCard";
import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import { FC, useEffect, useState } from "react";
import { SelectedCardType } from "./TaskBoard";
import moment from "moment/moment";
import style from "./taskBoard.module.css";
import { Font } from "../index";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { CalendarWidgetCellDto } from "../../../dtos/calendar-widget-cell-dto";
import { useRecoilState } from "recoil";
import { TaskBoardItemsAtom } from "./TaskBoardAtoms";
import {
  HOME_CARD_STORAGE,
  getStorageValue,
  setStorageValue,
} from "../../../utilities/localStorageFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type TaskBoardCalendarCardProps = {
  card: TaskBoardCardDto;
  index: number;
  isSelectedCard: (card: TaskBoardCardDto) => boolean;
  columnField: string;
  onClickCard: (card: SelectedCardType) => void;
};

const TaskBoardCalendarCard: FC<TaskBoardCalendarCardProps> = ({
  card,
  index,
  isSelectedCard,
  columnField,
  onClickCard,
}) => {
  const currentDate = moment().startOf("day").toDate();
  const [taskBoardItemsState, setTaskBoardItemsState] =
    useRecoilState(TaskBoardItemsAtom);
  const [cellValues, setCellValues] = useState<CalendarWidgetCellDto[] | null>(
    null
  );

  const atomIndex = card.cardId ?? card.cardType.toString();

  const { responseGet, dispatchGet } = useApiGet<CalendarWidgetCellDto[]>(
    card.calendarCellValuesApi ?? ""
  );

  const getLocalDate = () => {
    const localStorageCard = getStorageValue(HOME_CARD_STORAGE);
    const defaultDate = conditionHasValue(localStorageCard)
      ? localStorageCard?.date ?? currentDate
      : currentDate;

    return new Date(defaultDate);
  };

  useEffect(() => {
    setTaskBoardItemsState({ [atomIndex]: getLocalDate() });
  }, []);

  useEffect(() => {
    if (card.calendarCellValuesApi) {
      dispatchGet();
    }
  }, [card.calendarCellValuesApi]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCellValues(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  const updateLocalStorageDate = (date: Date) => {
    const localStorageCard = getStorageValue(HOME_CARD_STORAGE);
    const updatedCard = { ...localStorageCard, date: date };
    setStorageValue(HOME_CARD_STORAGE, updatedCard);
  };

  const onDateChanged = (date: Date) => {
    setTaskBoardItemsState({ ...taskBoardItemsState, [atomIndex]: date });
    updateLocalStorageDate(date);
  };

  return (
    <TaskBoardWithComponentCard
      key={`card-${card?.cardTitle}-from-${columnField}-${index}`}
      card={card}
      isSelected={isSelectedCard(card)}
      columnField={columnField}
      onClickCard={onClickCard}
    >
      <div className={style.title}>
        <Font truncate>{card?.cardTitle}</Font>
      </div>
      <CalendarWidget
        value={getLocalDate()}
        onChange={onDateChanged}
        datesWithValues={cellValues}
      />
    </TaskBoardWithComponentCard>
  );
};

export default TaskBoardCalendarCard;
