import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyEndorsementFormAddTypeEnum } from "../../../../dtos/policy-endorsement-form-add-type-enum";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { groupArrayByKey } from "../../../../utilities/arrayFunctions";
import { hasOneStateWithReportingBureau } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { evaluateRule } from "../PolicyValidation/ValidationEngineFunctions";

export const getRequiredAndOptionalFormsFromResponse = (
  responseData: ProgramEndorsementBlob[],
  policyQuote?: PolicyBlob | null
) => {
  const addTypeList =
    policyQuote?.configuration?.endorsementFormAddTypeList ?? [];
  const addTypeRequired = addTypeList.find(
    (addReason) => addReason.value === PolicyEndorsementFormAddTypeEnum.REQUIRED
  );
  const addTypeConditional = addTypeList.find(
    (addReason) =>
      addReason.value === PolicyEndorsementFormAddTypeEnum.CONDITIONAL
  );
  const addTypeOptional = addTypeList.find(
    (addReason) => addReason.value === PolicyEndorsementFormAddTypeEnum.OPTIONAL
  );

  const hasReportingBureau = hasOneStateWithReportingBureau(policyQuote);
  const policyQuoteStatesList = policyQuote?.quote?.stateList?.split(",") ?? [];

  const evaluatedForms = responseData.map((form) => {
    if (
      form.required === 1 &&
      ((hasReportingBureau && form.stateCode !== "") || !hasReportingBureau) &&
      form.requiredCondition === ""
    ) {
      return {
        ...form,
        addType: addTypeRequired,
        checked: true,
      };
    } else if (
      form.required === 1 &&
      ((hasReportingBureau && form.stateCode !== "") || !hasReportingBureau) &&
      evaluateRule(form.requiredCondition ?? "", policyQuote)
    ) {
      return {
        ...form,
        addType: addTypeConditional,
        checked: true,
      };
    } else if (
      form.required === 0 &&
      (form.state === "99" ||
        policyQuoteStatesList.includes(form.stateCode ?? ""))
    ) {
      const endorsementFormFound = policyQuote?.endorsementForms?.find(
        (formInAtom) => formInAtom.endorsementFormID === form.endorsementFormID
      );
      return {
        ...form,
        addType: addTypeOptional,
        checked: endorsementFormFound?.checked ?? false,
      };
    } else {
      return {
        required: -1,
        addType: { value: -1, description: "", isChecked: false },
      };
    }
  });

  const optionalForms = groupArrayByKey(
    evaluatedForms.filter((form) => form.required === 0),
    "stateCode"
  );

  const requiredForms = groupArrayByKey(
    evaluatedForms.filter(
      (form) =>
        form.addType?.value === PolicyEndorsementFormAddTypeEnum.CONDITIONAL ||
        form.addType?.value === PolicyEndorsementFormAddTypeEnum.REQUIRED
    ),
    "stateCode"
  );

  return { requiredForms, optionalForms };
};
