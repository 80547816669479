import { ElementType, FC, useEffect, useState } from "react";
import "./MultiCollapse.css";
import Collapse from "../../Collapse/Collapse";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { TableInstanceType2 } from "../BaseTable2/TableProperties";
import MultiHeader from "./MultiHeader";
import BaseTableGridForm from "../ComponentPatterns/BaseTableGridForm";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { useBaseTableUtilities } from "../../../../hooks/useBaseTableUtilities";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";

type MultiCollapseProperties = {
  parentUiid: string;
  tableName: string;
  header: BaseTableMetaData[];
  component: any;
  content: any;
};

const MultiCollapse: FC<MultiCollapseProperties> = ({
  parentUiid,
  tableName,
  header,
  content,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [renderedBodyContent, setRenderedBodyContent] = useState<any>(null);
  const [renderedHeaderContent, setRenderedHeaderContent] = useState<any>(null);
  const parentTableInstance = useRecoilValue(
    tableInstanceAtomFamily2(parentUiid) as RecoilState<TableInstanceType2>
  );
  const { nameKeySynchronizer } = useBaseTableUtilities("multicollapse");
  const uiid = nameKeySynchronizer(tableName).uiid ?? "NO_UIID_FOUND";

  const childTableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const setChildTableInstance = useRecoilCallback(
    ({ snapshot, set }) =>
      (func: (instance: any) => TableInstanceType2) => {
        const instance = snapshot.getLoadable(tableInstanceAtomFamily2(uiid))
          .contents as any;
        const instanceResult = func(instance);
        set(tableInstanceAtomFamily2(uiid), {
          ...instance,
          ...instanceResult,
        });
      },
    []
  );

  const setParentTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(parentUiid), {
          ...childTableInstance,
          ...newValueTableInstance,
          uiid: parentUiid,
        });
      },
    []
  );

  useEffect(() => {
    if (
      childTableInstance !== null &&
      !isEmptyValue(childTableInstance?.columns) &&
      !isEmptyValue(childTableInstance?.data) &&
      isOpen
    ) {
      const newChildData =
        parentTableInstance?.childrenData?.filter(
          (group) => group.groupId !== parseInt(tableName)
        ) ?? [];

      const newItem = {
        data: childTableInstance.data,
        columns: childTableInstance.columns,
        groupId: parseInt(tableName),
      };
      setParentTableInstance({
        ...parentTableInstance,
        childrenData: [...newChildData, newItem],
      });
    }
  }, [isOpen, childTableInstance?.columns, childTableInstance?.data]);

  const editRow = () => {
    setChildTableInstance((instance) => {
      return {
        ...instance,
        isEdit: !instance.isEdit,
      };
    });
  };

  useEffect(() => {
    if (isOpen) {
      setChildTableInstance((instance) => {
        return {
          ...instance,
          order: parentTableInstance.order,
          orderBy: parentTableInstance.orderBy,
        };
      });
    }
  }, [parentTableInstance.order, parentTableInstance.orderBy]);

  useEffect(() => {
    if (childTableInstance?.changedData?.length > 0) {
      const z = {
        [uiid]: {
          tableUIID: uiid,
          changedData: childTableInstance.changedData,
        },
      };

      const x = {
        ...parentTableInstance?.multiChangedData,
        ...z,
      };

      const updatedMultiChangedData = Object.assign(
        {},
        parentTableInstance?.multiChangedData,
        x
      );

      setParentTableInstance({
        ...parentTableInstance,
        multiChangedData: updatedMultiChangedData,
      });
    }
  }, [childTableInstance]);

  useEffect(() => {
    if (Object.keys(parentTableInstance?.multiChangedData).length > 0)
      if (parentTableInstance.tableType === "multi") {
        parentTableInstance?.events?.onChangeDataListener?.(
          parentTableInstance?.multiChangedData
        );
      }
  }, [parentTableInstance.multiChangedData]);

  const addRow = () => {
    const hydratedData = [];
    setChildTableInstance((instance) => {
      return {
        ...instance,
        _accessors: {
          ...childTableInstance._accessors,
          _addRow: { hydratedData },
        },
      };
    });
  };

  const actions = {
    addRow: addRow,
    editRow: editRow,
  };

  const [containerPaddingRightToggle, setContainerPaddingRightToggle] =
    useState<string>("5px");

  useEffect(() => {
    if (
      childTableInstance?.advancedOptions?.optionsColumnConfiguration
        ?.isHidden === false
    ) {
      setContainerPaddingRightToggle("5px");
    } else {
      setContainerPaddingRightToggle("30px");
    }
  }, [childTableInstance]);

  const getDefaultHeader = (obj: any) => {
    const JsxHeader: ElementType = () => {
      return (
        parentTableInstance?.advancedOptions?.multiTableProperties?.MultiTableHeaderPatternComponent?.(
          header,
          obj,
          parentTableInstance?.columnOptions
        ) ?? null
      );
    };

    return parentTableInstance?.advancedOptions?.multiTableProperties
      ?.MultiTableHeaderPatternComponent ? (
      <JsxHeader
        headerParams={header}
        obj={obj}
        columns={parentTableInstance?.columnOptions}
        actions={actions}
      />
    ) : (
      <MultiHeader headerParams={header} actions={actions} />
    );
  };

  const getDefaultGrid = (vars: any) => {
    const JsxContent: ElementType = () => {
      return (
        parentTableInstance?.advancedOptions?.multiTableProperties?.MultiTablePatternComponent?.(
          vars
        ) ?? null
      );
    };

    return parentTableInstance?.advancedOptions?.multiTableProperties
      ?.MultiTablePatternComponent ? (
      <JsxContent {...vars} />
    ) : (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <>
          <BaseTableGridForm obj={vars} />
        </>
      </div>
    );
  };

  const hydrateMetaData = () => {
    const metaDataObjectProperties: any[] =
      content?.metaData?.map((description) => {
        return [
          description?.name ?? "NO_NAME_FOUND",
          description?.value ?? "NO_VALUE_FOUND",
        ];
      }) ?? [];

    const metaDataObjects = [
      ["GroupUiidKey", content?.groupUiidKey ?? null],
    ].concat(metaDataObjectProperties);

    const flattenedMetaData = new Map(metaDataObjects as any);

    const result = Object.fromEntries(flattenedMetaData ?? null) ?? null;

    return result;
  };

  useEffect(() => {
    if (isOpen) {
      const hydratedMetaData = hydrateMetaData();
      setRenderedBodyContent(
        getDefaultGrid({
          ...hydratedMetaData,
        })
      );
    } else {
      setRenderedBodyContent(null);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(parentTableInstance?.toggleMultiTableRowCollapse ?? false);
  }, [parentTableInstance.toggleMultiTableRowCollapse]);

  useEffect(() => {
    const hydratedMetaData = hydrateMetaData();
    setRenderedHeaderContent(
      getDefaultHeader({
        ...hydratedMetaData,
      })
    );
  }, [header]);

  const headerStyle =
    parentTableInstance.advancedOptions?.tableStyle?.multiTableHeaderStyle ??
    "";

  return (
    <>
      <Collapse
        className={`multi_table ${headerStyle}`}
        expanded={isOpen}
        title={renderedHeaderContent}
        content={
          <div
            style={{
              width: "100%",
              height: "100%",
              paddingRight: containerPaddingRightToggle,
            }}
          >
            {renderedBodyContent ?? null}
          </div>
        }
        setExpanded={setIsOpen}
        hasBottomMargin={false}
        disableGutters
        square
      />
    </>
  );
};

export default MultiCollapse;
