export function getInstanceOfTypeOrProperty<K, T extends keyof K>(
  obj: K,
  param?: T
): T extends keyof K ? K[T] : K {
  if (param !== undefined) {
    if (obj !== undefined && obj !== null) {
      return obj[param] as T extends keyof K ? K[T] : K;
    } else {
      return {} as any;
    }
  } else {
    return obj as T extends keyof K ? K[T] : K;
  }
}
