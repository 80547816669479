import { FC } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { BaseTableColumn } from "../../../dtos/base-table-column";

type BaseGridSortHeaderIconProperties = {
  column: BaseTableColumn;
  orderByColumnIndex: number | null | undefined;
  orderDirection: "asc" | "desc" | null | undefined;
};

const BaseGridSortHeaderIcon: FC<BaseGridSortHeaderIconProperties> = ({
  column,
  orderByColumnIndex,
  orderDirection,
}) => {
  return (
    <>
      {orderByColumnIndex !== column._columnIndex && (
        <ArrowDownwardIcon style={{ height: "18px" }} />
      )}
      {orderByColumnIndex === column._columnIndex &&
        orderDirection === "desc" && (
          <ArrowDownwardIcon style={{ height: "18px" }} />
        )}
      {orderByColumnIndex === column._columnIndex &&
        column._columnIndex &&
        orderDirection === "asc" && (
          <ArrowUpwardIcon style={{ height: "18px" }} />
        )}
    </>
  );
};

export default BaseGridSortHeaderIcon;
