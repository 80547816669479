import {
  Card,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Popper,
} from "@mui/material";
import { FC, useEffect, useState, MouseEvent } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { useGridInstance } from "../Hooks/useGridInstance";
import { useRecoilState, useRecoilValue } from "recoil";
import BaseGridColumnFilterTypeSelector from "./BaseGridColumnFilterTypeSelector";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { Button, Switch } from "../../TrueUI";
import { BaseGridFilterParameters } from "../Hooks/useBaseGridFilters";
import {
  COLUMN_EXTENSION_COMPARE_IDENTIFIER,
  COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY,
} from "../BaseGridEnums";
import { BaseTableOrder } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  dateTypes,
  numericTypes,
  otherTypes,
} from "./BaseGridColumnFilterConstantsAndTypes";
import { ColumnFilterExtensionFilterGateFor } from "../SupportFunctions/baseGridColumnFilterFunctions";
import { BaseGridlFilterColumnManagerAtomFamily } from "../BaseGridAtoms";
import { BaseGridlFilterColumnManager } from "../BaseGridProperties";
import BaseGridSortHeaderIcon from "../SupportComponents/BaseGridSortHeaderIcon";

type BaseGridFilterColumnManagerProperties = {
  uiid: string;
  column: BaseTableColumn;
};

const BaseGridFilterColumnManager: FC<
  BaseGridFilterColumnManagerProperties
> = ({ uiid, column }) => {
  const {
    instanceSelector,
    instanceInternalSelector,
    setInternalInstance,
    setInstance,
  } = useGridInstance(uiid, "BaseGridColumnFilterExention");

  const [managerAtom, setManagerAtom] = useRecoilState(
    BaseGridlFilterColumnManagerAtomFamily(
      column.fieldName ?? "NO_COLUMN_FIELD_NAME_FOUND"
    )
  );

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const [filterParametersToUpdate, setFilterParametersToUpdate] = useState<
    BaseGridFilterParameters[]
  >([]);

  const [betweenValues, setBetweenValues] = useState<{
    filterId: string;
    filterValue: string[];
  }>({ filterId: "", filterValue: ["", ""] });

  const orderByColumnIndex = useRecoilValue(
    instanceSelector("orderByColumnIndex")
  );

  const columns = useRecoilValue(instanceSelector("columns"));

  const filterParameters = useRecoilValue(
    instanceInternalSelector("filterParameters")
  );

  const updateIsOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    setManagerAtom({ ...managerAtom, isOpen: !managerAtom.isOpen });
  };

  const orderDirection = useRecoilValue(instanceSelector("orderDirection"));
  const [filteredColumnHeaders, setFilteredColumnHeaders] = useState<
    BaseTableColumn[]
  >([]);

  const changeOrder = (order: BaseTableOrder) =>
    order === "desc" ? "asc" : "desc";

  const setSortOrder = (columnIndex: number) => {
    const isSameColumn = (orderByColumnIndex ?? columnIndex) === columnIndex;
    const order = isSameColumn ? changeOrder(orderDirection ?? "desc") : "desc";

    setInstance({
      orderDirection: order,
      orderByColumnIndex: columnIndex,
      columnTriggeredSort: true,
    });

    const updatedDirection = filteredColumnHeaders.map((ch) => {
      if (ch._columnIndex === columnIndex) {
        return { ...ch, orderDirection: order === "asc" ? "desc" : "asc" };
      } else {
        return { ...ch, orderDirection: "desc" };
      }
    });
    setFilteredColumnHeaders(updatedDirection);
    setManagerAtom({ ...managerAtom, isOpen: false });
  };

  const updateFilterManagerUIValues = <
    T extends keyof BaseGridlFilterColumnManager
  >(
    param: T,
    value: string
  ) => {
    // This is only to maintain the values on the fields, stricly for the user. These values don't apply in anyway in the filtering process. Being lazy.
    setManagerAtom({ ...managerAtom, [param]: value });
  };

  const updateFilterParameter = (filterId: string, filterValue: any) => {
    const updatedFilterParamers = filterParameters?.map((filter) => {
      if (filter.filterId === filterId) {
        return {
          ...filter,
          filterValue: filterValue?.toString() ?? null,
        };
      }
      return filter;
    });

    setFilterParametersToUpdate(updatedFilterParamers ?? []);
  };

  const updateFilterParameterBetween = (
    filterId: string,
    filterValue: any,
    position: 0 | 1
  ) => {
    const splitValues = betweenValues.filterValue.map((value, index) => {
      if (index === position) {
        return filterValue;
      }
      return value;
    });

    const updatedBetweenValues = {
      ...betweenValues,
      filterId: filterId,
      filterValue: splitValues,
    };

    setBetweenValues(updatedBetweenValues);

    const updatedFilterParamers = filterParameters?.map((filter) => {
      if (filter.filterId === filterId) {
        return { ...filter, filterId: filterId, filterValue: splitValues };
      }
      return filter;
    });

    setFilterParametersToUpdate(updatedFilterParamers ?? []);
  };

  const getPopperPlacement = (
    col: BaseTableColumn,
    columns: BaseTableColumn[]
  ): "left" | "right" => {
    const middle = Math.floor(columns.length / 2);
    return middle < (col?._externalColumnIndex ?? 0) ? "right" : "left";
  };

  const applyFilterParameters = () => {
    const extFilterIds = COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY.map(
      (extType) => {
        return `${extType}_${column._columnIndex}`;
      }
    );

    const updatedFilterParameters = filterParametersToUpdate?.map((filter) => {
      if (extFilterIds.includes(filter.filterId ?? "NO_FILTER_ID_FOUND")) {
        if (Array.isArray(filter.filterValue)) {
          const result = filter.filterValue.map((value) => {
            if (value === null || value === undefined || value.trim() === "") {
              return true; // "true" in this case means to ignore.
            }

            return false;
          });

          const ignore = result.every((v) => v === true);

          return { ...filter, ignore: ignore };
        }

        if (
          filter.filterValue === null ||
          filter.filterValue === undefined ||
          filter.filterValue.trim() === ""
        ) {
          return { ...filter, ignore: true };
        }

        return { ...filter, ignore: false };
      }

      return filter;
    });

    setInternalInstance({
      filterParameters: updatedFilterParameters,
    });

    setManagerAtom({ ...managerAtom, isOpen: false, filtersApplied: true });
  };

  const resetFilterParameters = () => {
    const extFilterIds = COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY.map(
      (extType) => {
        return `${extType}_${column._columnIndex}`;
      }
    );

    const updatedFilterParameters = filterParametersToUpdate?.map((filter) => {
      if (extFilterIds.includes(filter.filterId ?? "NO_FILTER_ID_FOUND")) {
        return { ...filter, filterValue: null, ignore: true };
      }

      return filter;
    });

    setInternalInstance({
      filterParameters: updatedFilterParameters,
    });

    setManagerAtom({
      ...managerAtom,
      isOpen: false,
      filtersApplied: false,
      removeEmptyEntires: false,
      ///////////////////////////////
      betweenStartValue: null,
      betweenEndValue: null,
      greaterThanValue: null,
      lessThanValue: null,
      containsValue: null,
      equalsValue: null,
      beginsWith: null,
      endsWith: null,
    });
  };

  useEffect(() => {
    setFilterParametersToUpdate(filterParameters ?? []);
  }, [filterParameters]);

  useEffect(() => {
    const filteredHiddenColumn =
      columns?.filter((c) => c.isHidden === null || c.isHidden === false) ?? [];
    setFilteredColumnHeaders(filteredHiddenColumn);
  }, []);

  useEffect(() => {
    // This forces all column filter panel to colse when any sort order direction is changed. Otherwise, the panel would float to the top left corner of the screen.
    setManagerAtom({ ...managerAtom, isOpen: false });
  }, [orderDirection, orderByColumnIndex]);

  return (
    <div>
      <div
        onClick={(e) => {
          updateIsOpen(e);
        }}
      >
        {managerAtom.filtersApplied ? (
          <FilterAltIcon
            style={{
              height: "18px",
            }}
          />
        ) : (
          <FilterAltOutlinedIcon
            style={{
              height: "18px",
            }}
          />
        )}
      </div>
      <Popper
        id={crypto.randomUUID()}
        open={managerAtom.isOpen}
        anchorEl={anchorElement}
        style={{ zIndex: 9999 }}
        placement={
          getPopperPlacement(column, filteredColumnHeaders) === "left"
            ? "bottom-start"
            : "bottom-end"
        }
      >
        <div
          style={{
            position: "relative",
            top: "5px",
            right: `${
              getPopperPlacement(column, filteredColumnHeaders) === "left"
                ? "90px"
                : null
            }`,
            left: `${
              getPopperPlacement(column, filteredColumnHeaders) === "right"
                ? "0px"
                : null
            }`,
          }}
        >
          <div
            id={`column_filter_extension_container_${column._columnIndex}`}
            className={"column_filter_extension_container"}
            style={{ width: "220px" }}
          >
            <Card variant="outlined">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  paddingBottom: "0px",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{ display: "flex", width: "85%" }}
                        title={`${
                          column?.displayName ?? "NO_NAME_FOUND"
                        } has a data type of ${
                          DataTypeEnums[column?.defaultValueType ?? 0] ??
                          "NO_DEFAULT_VALUE_TYPE_FOUND"
                        }`}
                      >
                        {column?.displayName ?? "NO_NAME_FOUND"}
                      </div>
                      <div
                        style={{ display: "flex", marginTop: "15px" }}
                        onClick={
                          () =>
                            column.isSortable
                              ? setSortOrder(column._columnIndex)
                              : null // TODO - turning this off for testing because it triggers a reset on the header everytime I click it.
                        }
                      >
                        <BaseGridSortHeaderIcon
                          column={column}
                          orderByColumnIndex={orderByColumnIndex}
                          orderDirection={orderDirection}
                        />
                      </div>
                    </div>
                  </ListSubheader>
                }
              >
                {ColumnFilterExtensionFilterGateFor(
                  [otherTypes],
                  column,
                  "columnExtRemoveEmptyResult"
                ) && (
                  <>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Switch
                        id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_REMOVE_EMPTY_RESULT}_${column.displayName}id`}
                        name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_REMOVE_EMPTY_RESULT}_${column.displayName}_name`}
                        label={"Remove Empty Entries"}
                        labelFontType={"BODY"}
                        isChecked={managerAtom.removeEmptyEntires}
                        onChangeIsChecked={(e) => {
                          updateFilterManagerUIValues("removeEmptyEntires", e);
                          updateFilterParameter(
                            `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_REMOVE_EMPTY_RESULT}_${column._columnIndex}`,
                            e
                          );
                        }}
                      />
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [otherTypes],
                  column,
                  "columnExtEndsWith"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Ends With</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_ENDS_WITH}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_ENDS_WITH}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.endsWith ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("endsWith", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_ENDS_WITH}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [otherTypes],
                  column,
                  "columnExtBeginsWith"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Begins With</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BEGINS_WITH}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BEGINS_WITH}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.beginsWith ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("beginsWith", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BEGINS_WITH}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [otherTypes, numericTypes, dateTypes],
                  column,
                  "columnExtEquals"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Equals</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_EQUALS}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_EQUALS}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.equalsValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("equalsValue", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_EQUALS}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [otherTypes],
                  column,
                  "columnExtContains"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Contains</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_CONTAINS}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_CONTAINS}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.containsValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("containsValue", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_CONTAINS}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [numericTypes, dateTypes],
                  column,
                  "columnExtBetween"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Between</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_start_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_start_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.betweenStartValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("betweenStartValue", e);
                            updateFilterParameterBetween(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_${column._columnIndex}`,
                              e,
                              0
                            );
                          }}
                        />
                      </ListItem>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_end_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_end_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.betweenEndValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("betweenEndValue", e);
                            updateFilterParameterBetween(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN}_${column._columnIndex}`,
                              e,
                              1
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [numericTypes, dateTypes],
                  column,
                  "columnExtGreaterThan"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Greater Than</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_GREATER_THAN}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_GREATER_THAN}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.greaterThanValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("greaterThanValue", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_GREATER_THAN}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {ColumnFilterExtensionFilterGateFor(
                  [numericTypes, dateTypes],
                  column,
                  "columnExtLessThan"
                ) && (
                  <>
                    <Divider />
                    <div style={{ paddingBottom: "8px" }}>
                      <ListSubheader>Less Than</ListSubheader>
                      <ListItem>
                        <BaseGridColumnFilterTypeSelector
                          id={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_LESS_THAN}_${column.displayName}_id`}
                          name={`${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_LESS_THAN}_${column.displayName}_name`}
                          column={column}
                          defaultValue={managerAtom?.lessThanValue ?? null}
                          resetValue={!managerAtom?.filtersApplied ?? false}
                          onChange={(e) => {
                            updateFilterManagerUIValues("lessThanValue", e);
                            updateFilterParameter(
                              `${COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_LESS_THAN}_${column._columnIndex}`,
                              e
                            );
                          }}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                <Divider />
                <div style={{ paddingBottom: "8px" }}>
                  <ListItem>
                    <Button
                      fullWidth
                      sx={{ marginRight: "5px" }}
                      isDisabled={managerAtom.filtersApplied}
                      onClick={() => {
                        applyFilterParameters();
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        resetFilterParameters();
                      }}
                    >
                      Reset
                    </Button>
                  </ListItem>
                </div>
              </List>
            </Card>
          </div>
        </div>
      </Popper>
    </div>
  );
};

export default BaseGridFilterColumnManager;
