import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import BillingAccountModal from "./BillingAccountModal";
import {
  ACHCellInitCondition,
  BillingAccountProps,
  BillingAccountsTableProps,
  billingAccountTableName,
  defaultBillingAccount,
} from "./BillingAccountsUtil";

const BillingAccountsTable: FC<BillingAccountsTableProps> = ({ insuredId }) => {
  const [billingAccount, setBillingAccount] = useState<BillingAccountProps>(
    defaultBillingAccount
  );

  const [refresh, setRefresh] = useState<string>(crypto.randomUUID());

  const BillingAccountModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setBillingAccount({
            isOpen: true,
            action: "edit",
            rowData: rowData,
            modalData: {
              insuredId: insuredId,
              insuredAccountId: Number(rowData.InsuredAccountId),
            },
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: billingAccountTableName,
    getURL: `api/InsuredAccount/GetPaymentAccountsTable?insuredId=${insuredId}&refresh=${refresh}`,
    columnOptions: [
      { fieldName: "Type", width: 20 },
      { fieldName: "Nickname", width: 30 },
      { fieldName: "Account", width: 30 },
      { fieldName: "Status", width: 10 },
      {
        fieldName: "Ach",
        width: 10,
        computeOnInit: {
          conditionForCell: [(options) => ACHCellInitCondition(options)],
        },
      },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showAddButton: true,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
      addButtonText: "Add pay account",
    },
    events: {
      addEventOverrideCallback: () => {
        setBillingAccount({
          isOpen: true,
          action: "add",
          rowData: null,
          modalData: {
            insuredId: insuredId,
            insuredAccountId: 0,
          },
        });
      },
    },
    advancedOptions: {
      paginate: false,
      isEditMode: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          BillingAccountModalLink(actionOptions.row),
      },
    },
  };

  useBaseTable([tableConfiguration]);

  return (
    <>
      <div id={"billing_account-table-wrapper"}>
        <BaseTable2 name={billingAccountTableName} />
      </div>
      <BillingAccountModal
        billingAccount={billingAccount}
        setBillingAccount={setBillingAccount}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default BillingAccountsTable;
