import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiPost } from "../../../../hooks";
import { Col, Row, SaveButton, Select } from "../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { InsuredFileDto } from "../../../../dtos/insured-file-dto";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { ContextEnums } from "../../../../dtos/context-enums";
import { ContextProps } from "../InsuredDrawerConstants";
import {
  GetDefaultUploadFileData,
  GetURLUploadFileByContext,
} from "./FilesUtil";
import { addCustomOption } from "../../../../utilities/selectFunctions";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { UploadFileDto } from "../../../../dtos/upload-file-dto";
import MultiUpload from "../../../TrueUI/Upload/MultiUpload";

type FilesFormProps = {
  context: ContextProps;
  categoryOptions?: Partial<SelectOptions>[];
  policyOptions?: Partial<SelectOptions>[];
  allowedExtensions: string[];
  saveSuccessful?: () => void;
};

const FilesForm: FC<FilesFormProps> = ({
  context,
  categoryOptions,
  policyOptions,
  allowedExtensions,
  saveSuccessful,
}) => {
  const [uploadFileData, setUploadFileData] = useState<UploadFileDto>(
    GetDefaultUploadFileData(context)
  );

  const [files, setFiles] = useState<{ [key: string]: number }>({});
  const [clear, setClear] = useState<boolean>(false);
  const [saveUrl, setSaveUrl] = useState<string>("");
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${context.tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    InsuredFileDto | Partial<InsuredFileDto> | undefined
  >(saveUrl, uploadFileData);

  const save = () => {
    if (uploadFileData.fileIds && uploadFileData.fileIds !== null) {
      dispatchPost();
    }
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setClear(true);
      setUploadFileData({
        ...uploadFileData,
        category: null,
        policyId: null,
        fileIds: null,
      });
      setFiles({});
      saveSuccessful?.();
      setComponentTriggers(["activityLogComponent"]);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    setSaveUrl(GetURLUploadFileByContext(context));
  }, [context]);

  useEffect(() => {
    if (files && files !== null) {
      const newFileIds = Object.values(files);
      setUploadFileData({ ...uploadFileData, fileIds: newFileIds });
    } else {
      setUploadFileData({ ...uploadFileData, fileIds: [] });
    }
  }, [files]);

  return (
    <div id="files-form-container" style={{ height: "auto" }}>
      <Row
        horizontalMargin="0px"
        verticalMargin="0px"
        verticalGutter="10px"
        verticalAlign="center"
        horizontalAlign="flex-start"
      >
        <Col
          horizontalGutter={"25px"}
          verticalGutter={"0px"}
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          horizontalAlign="space-between"
          verticalAlignSelf="flex-start"
        >
          <Select
            id="category-claim-files"
            name="category-claim-files"
            label="Category"
            labelFontType="BOLD_CAPTION"
            options={categoryOptions ?? []}
            firstOptionAsDefault={false}
            value={uploadFileData.category}
            inputWidth="40%"
            errorMessage={errorDetails?.category}
            onChange={(value) => {
              setUploadFileData({ ...uploadFileData, category: value });
              setErrorDetails(null);
            }}
            optionsMaxHeight={"200px"}
          />
          {context.type === ContextEnums.INSURED && (
            <Select
              id="policy-files"
              name="policy-files"
              label="Policy"
              labelFontType="BOLD_CAPTION"
              options={addCustomOption(policyOptions ?? [], {
                displayName: "",
                intValue: -1,
              })}
              firstOptionAsDefault
              value={uploadFileData.policyId}
              inputWidth="40%"
              onChange={(value) => {
                setUploadFileData({ ...uploadFileData, policyId: value });
              }}
            />
          )}
        </Col>
        <Row
          {...rowWithNoMarginNorGutter}
          rowDirection="column"
          rowHeight="100%"
        >
          <Col {...colWithNoMarginNorGutter} verticalAlignSelf="flex-start">
            <Box width="100%" height="100%">
              <MultiUpload
                name="upload-files"
                files={files}
                onUploadFileList={setFiles}
                saveUrl={"api/FileArchive/UploadMultiple"}
                removeUrl={"api/FileArchive/Remove"}
                clearFiles={clear}
                resetClearFiles={setClear}
                errorMessages={errorDetails?.fileIds}
                allowedExtensions={allowedExtensions}
              />
            </Box>
          </Col>
        </Row>
        <Col
          horizontalAlign="flex-end"
          verticalAlignSelf="flex-start"
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter="10px"
        >
          <SaveButton text="SAVE" onClick={save} />
        </Col>
      </Row>
    </div>
  );
};

export default FilesForm;
