import { FC } from "react";
import { ModalSectionsProps } from "../TypesBillingOptions";
import {
  Divider,
  FontBold,
  InputMemo,
  Row,
  Switch,
} from "../../../../../TrueUI";
import Col, { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { inputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";

const ModalSectionAddProcessingOptions: FC<ModalSectionsProps> = ({
  billingContentConfig,
  billingRuleFormData,
  setBillingRuleFormData,
}) => {
  return (
    <div>
      <Divider height={15} />
      <FontBold>PROCESSING OPTIONS</FontBold>
      <Row {...rowWithNoMarginNorGutter} rowDirection="row" allowWrap>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromCancel"
            name="insuredBillinOptionExcludeFromCancel"
            label="Exclude From Non-Pay Cancel"
            isChecked={billingRuleFormData?.excludeFromCancel ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromCancel: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromAuditOrder"
            name="insuredBillinOptiExcludeFromAuditOrder"
            label="Exclude From Audit Order"
            isChecked={billingRuleFormData?.excludeFromAuditOrder ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromAuditOrder: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromLateNotice"
            name="insuredBillinOptionExcludeFromLateNotice"
            label="Exclude From Late Notice"
            isChecked={billingRuleFormData?.excludeFromLateNotice ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromLateNotice: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromAuditInvoice"
            name="insuredBillinOptionExcludeFromAuditInvoice"
            label="Exclude From Audit Invoice"
            isChecked={billingRuleFormData?.excludeFromAuditInvoice ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromAuditInvoice: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromLateFee"
            name="insuredBillinOptionExcludeFromLateFee"
            label="Exclude From Late Fee"
            isChecked={billingRuleFormData?.excludeFromLateFee ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromLateFee: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromAuditCancel"
            name="insuredBillinOptionExcludeFromAuditCancel"
            label="Exclude From Audit Cancel"
            isChecked={billingRuleFormData?.excludeFromAuditCancel ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromAuditCancel: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionExcludeFromInvoiceBatch"
            name="insuredBillinOptionExcludeFromInvoiceBatch"
            label="Exclude From Invoice Batch"
            isChecked={billingRuleFormData?.excludeFromInvoiceBatch ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeFromInvoiceBatch: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        ></Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <InputMemo
            {...inputConfiguration(
              "insuredBillingOptionExcludeComments",
              "insuredBillingOptionExcludeComments",
              "Comments"
            )}
            readOnly={billingContentConfig?.isReadOnly}
            value={billingRuleFormData?.excludeComments}
            maxLength={1000}
            rows={2}
            onChangeRawValue={(value) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                excludeComments: value,
              })
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalSectionAddProcessingOptions;
