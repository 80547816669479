import { FC, useEffect, useState } from "react";
import { useApiPost } from "../../../../../../hooks";
import { ReserveItemDto } from "../../../../../../dtos/reserve-item-dto";
import { Modal } from "../../../../../TrueUI";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import ModalClaimFinancialReserveContent from "./ModalClaimFinancialReserveContent";
import { parseReserveObj } from "./ClaimFinancialUtil";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";

export type UpdatedReserve = {
  ReserveId?: number;
  ReserveDate?: Date;
  Amount?: string;
  ReserveStatus?: string;
  ReserveStatusId?: number;
  ReserveType?: string;
  Comments?: string;
  UpdateBy?: string;
};

type ModalClaimFinancialReservesProps = {
  reserveId: number;
  isReserveModalOpen: boolean;
  insuredId?: number;
  claimId?: number;
  setReserveModalOpen?: (value: boolean) => void;
  setUpdatedReserve?: (
    action: "update" | "delete",
    updatedReserve?: UpdatedReserve
  ) => void;
  tabKey?: string;
  userReserveLimit?: number;
  checkLimit?: boolean;
};

const ModalClaimFinancialReserves: FC<ModalClaimFinancialReservesProps> = ({
  reserveId,
  isReserveModalOpen,
  claimId,
  setReserveModalOpen,
  setUpdatedReserve,
  tabKey,
  userReserveLimit,
  checkLimit,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<any>(undefined);
  const [reserveInformation, setReserveInformation] =
    useState<ReserveItemDto>();
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const {
    responsePost: responseDeleteReserve,
    dispatchPost: dispatchPostDeleteReserve,
  } = useApiPost(`api/Reserve/DeleteReserve?reserveId=${reserveId}`);

  const {
    responsePost: responseUpdateReserve,
    dispatchPost: dispatchPostUpdateReserve,
    validatorErrorResponse,
  } = useApiPost(`api/Reserve/UpdateReserve`, reserveInformation);

  const {
    responsePost: responseSaveReserve,
    dispatchPost: dispatchPostSaveReserve,
    validatorErrorResponse: saveReserveErrorResponse,
  } = useApiPost(`api/Reserve/SaveOneReserve`, reserveInformation);

  useEffect(() => {
    if (reserveId !== 0) {
      if (isAPITotallyCompleteNoContentResponse(responseDeleteReserve)) {
        setOpenConfirmation(false);
        setReserveModalOpen?.(false);
      }
      setComponentTriggers([
        "activityLogComponent",
        "claimBanner",
        "financialTableClaimLandingPageComponent",
      ]);
      setUpdatedReserve?.("delete");
    }
  }, [responseDeleteReserve]);

  useEffect(() => {
    setErrorDetails(validatorErrorResponse?.errorDetails);
    if (responseUpdateReserve.requestInstanceSuccessful) {
      setOpenConfirmation(false);
      setReserveModalOpen?.(false);
      setUpdatedReserve?.(
        "update",
        parseReserveObj(responseUpdateReserve.axiosResponse?.data)
      );
    }
  }, [responseUpdateReserve]);

  useEffect(() => {
    setErrorDetails(saveReserveErrorResponse?.errorDetails);
    if (responseSaveReserve.requestInstanceSuccessful) {
      setOpenConfirmation(false);
      setReserveModalOpen?.(false);
      setComponentTriggers([
        "activityLogComponent",
        "claimBanner",
        "financialTableClaimLandingPageComponent",
      ]);
    }
  }, [responseSaveReserve]);

  const saveOrUpdateReserve = () => {
    setErrorDetails(undefined);
    if (reserveId === 0) dispatchPostSaveReserve();
    if (reserveId !== 0) dispatchPostUpdateReserve();
  };
  const getModalTitle = () => {
    return reserveId === 0 ? "Add Reserve" : "Edit Reserve";
  };

  return (
    <>
      <Modal
        id="claim-file"
        title={getModalTitle()}
        size="sm"
        open={isReserveModalOpen}
        showCancelTextButton
        showCloseButton
        deleteDisabled={false}
        hideDeleteButton={reserveId === 0}
        saveDisabled={saveDisabled}
        closeEvent={() => {
          setErrorDetails(undefined);
        }}
        saveEvent={() => {
          saveOrUpdateReserve();
        }}
        deleteEvent={() => {
          setErrorDetails(undefined);
          setOpenConfirmation(true);
        }}
        cancelEvent={() => {
          setErrorDetails(undefined);
          setReserveModalOpen?.(false);
        }}
      >
        <ModalClaimFinancialReserveContent
          reserveId={reserveId}
          claimId={claimId}
          errorDetails={errorDetails}
          reserveInformation={reserveInformation}
          setReserveInformation={setReserveInformation}
          setSaveDisabled={setSaveDisabled}
          userReserveLimit={userReserveLimit}
          checkLimit={checkLimit}
        />
      </Modal>
      <DialogConfirmation
        id="reserve-confirmation"
        open={openConfirmation}
        dialogDescriptionText={"Are you sure you want to delete this entry?"}
        optionYesOverrideLabel="OK"
        optionNoOverrideLabel={"Cancel"}
        onOptionYesEvent={() => {
          dispatchPostDeleteReserve();
        }}
        onOptionNoEvent={() => {
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default ModalClaimFinancialReserves;
