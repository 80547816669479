import { FC, useEffect, useState } from "react";
import AchBatchesGrid from "./AchBatchesGrid";
import AchBatchDetail from "./AchBatchDetail";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { AchTabContentWrapperProps } from "./AchTypes";
import { CreateAchBatchResultDto } from "../../../dtos/create-ach-batch-result-dto";

const AchTabContentWrapper: FC<AchTabContentWrapperProps> = ({
  hasAch,
  selectedProgramId,
  selectedProgramCode,
}) => {
  const [selectedBatchId, setSelectedBatchId] = useState<number | null>(null);
  const [createdBatchResponse, setCreatedBatchResponse] =
    useState<CreateAchBatchResultDto | null>(null);

  useEffect(() => {
    if (createdBatchResponse !== null) {
      if (createdBatchResponse.batchesForToday === true) {
        setSelectedBatchId(createdBatchResponse?.billingPaymentBatchId ?? null);
      } else {
      }
    }
  }, [createdBatchResponse]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {selectedBatchId === null && (
        <AchBatchesGrid
          setSelectedBatchId={setSelectedBatchId}
          setCreatedBatchResponse={setCreatedBatchResponse}
          hasAch={hasAch}
          selectedProgramId={selectedProgramId}
        />
      )}
      {selectedBatchId !== null && (
        <AchBatchDetail
          selectedBatchId={selectedBatchId}
          setSelectedBatchId={setSelectedBatchId}
          selectedProgramCode={selectedProgramCode}
        />
      )}
      <DialogConfirmation
        id="achNotTransactionsDialogNotification"
        open={createdBatchResponse?.batchesForToday === false}
        dialogDescriptionText="There are no scheduled payments to process at this time."
        onCloseEvent={() => {
          setCreatedBatchResponse(null);
        }}
        onOptionYesEvent={() => {
          setCreatedBatchResponse(null);
        }}
        optionYesOverrideLabel="OK"
      />
    </div>
  );
};

export default AchTabContentWrapper;
