import { FC } from "react";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";

type TableDataProperties = {
  children?: any;
  cellType?: BaseTableInputType | null;
  width?: string;
  colSpan?: number;
  className?: string;
};

const TableData: FC<TableDataProperties> = ({
  children,
  cellType = BaseTableInputType.TEXT,
  width,
  className = "",
}) => {
  // TODO: Add the colSpan styles
  const allowOverflowCells = [BaseTableInputType.SELECT];

  const allowOverflow = (type: BaseTableInputType | null) => {
    return type ? allowOverflowCells.includes(type) : false;
  };

  return (
    <div
      className={`true_table_body_data ${className} ${
        allowOverflow(cellType) ? "allowOverflow" : ""
      }`}
      style={{ width: width }}
    >
      {children}
    </div>
  );
};

export default TableData;
