import { ClaimIncidentDto } from "../../../../../dtos/claim-incident-dto";
import { IncidentBodyPartDto } from "../../../../../dtos/incident-body-part-dto";
import { IncidentStateReportFieldDto } from "../../../../../dtos/incident-state-report-field-dto";
import { InsuredAddressesDto } from "../../../../../dtos/insured-addresses-dto";
import { InsuredInformationPage } from "../../../../../dtos/insured-information-page";
import { PolicyDto } from "../../../../../dtos/policy-dto";
import { StateReportPage } from "../../../../../dtos/state-report-page";
import { uniqBySetWithArrayFrom } from "../../../../../utilities/arrayFunctions";
import { isDateBetweenDates } from "../../../../../utilities/dateFunctions";

export type AddNewIncidentProps = {
  insuredId?: number;
  incidentId?: number;
  tabKey?: string;
};

export type SectionDot = {
  sectionId: number;
  sectionName: string;
};

export type Section = {
  sectionId: number;
  sectionName: string;
  fields?: IncidentStateReportFieldDto[];
};

export type claimIncidentStateProps = {
  incidentSection?: number | null;
  hasPolicy?: boolean | null;
  hasSignature?: boolean | null;
  foundInsured?: number | null;
  insuredInfo?: InsuredInformationPage | null;
  jurisdiction?: string | null;
  locations?: InsuredAddressesDto[] | null;
  policies?: PolicyDto[] | null;
  stateReport?: StateReportPage | null;
  claimIncident?: ClaimIncidentDto | null;
  activeSection?: number;
  lastSavedSection?: number;
  sectionDots?: SectionDot[] | null;
  errorDetails?: any;
  isAfterSave?: boolean;
  filledFromAtom?: boolean;
};

export const getInitialState = (
  stepperSection: number,
  insuredId?: number
) => ({
  hasPolicy: null,
  hasSignature: false,
  foundInsured: insuredId,
  insuredInfo: null,
  jurisdiction: null,
  locations: null,
  stateReport: null,
  claimIncident: null,
  activeSection: stepperSection,
  finishedSection: stepperSection,
  lastSavedSection: stepperSection,
  sectionDots: [],
  errorDetails: null,
  isAfterSave: false,
  filledFromAtom: false,
});

export const getValue = (value: any) => (value || value === 0 ? value : null);

export const hasActivePolicy = (incidentDate: Date, policies: PolicyDto[]) => {
  const filteredPolicies =
    policies &&
    policies.map((val) =>
      isDateBetweenDates(incidentDate, val.effectiveDate, val.expirationDate)
    );
  return filteredPolicies?.includes(true);
};

export const getSectionDots = (
  fields: IncidentStateReportFieldDto[] | undefined
) => {
  const start: SectionDot = { sectionId: 0, sectionName: "Start" };
  const finish: SectionDot = { sectionId: 99, sectionName: "Finish" };
  const sections = [
    start,
    ...uniqBySetWithArrayFrom(
      fields
        ? fields
            .map((field) => {
              //map sections
              const f: SectionDot = {
                sectionId: field.sectionId,
                sectionName: field.sectionName,
              };
              return f;
            })
            .filter(
              //remove duplicates
              (section, i, arr) =>
                arr.findIndex((s) => s.sectionId === section.sectionId) === i
            )
        : []
    ),
    finish,
  ];
  return sections;
};

export const getSectionDotNames = (sections: SectionDot[] | undefined) => {
  return sections ? sections.map((section) => section.sectionName) : [];
};

export const getSectionsWithFields = (
  fields: IncidentStateReportFieldDto[] | undefined
) => {
  const sections = getSectionDots(fields).map((section) => {
    const s: Section = {
      sectionId: section.sectionId,
      sectionName: section.sectionName,
      fields: fields
        ? fields.filter((field) => field.sectionId === section.sectionId)
        : [],
    };
    return s;
  });
  return sections;
};

export const getActiveSectionById = (
  sections: SectionDot[] | null | undefined,
  sectionId: number
) => {
  const section =
    sections?.find(
      (section) => section?.sectionId === sections?.[sectionId]?.sectionId
    ) ?? null;
  return section;
};

export const getSectionIndexByName = (
  sections: SectionDot[] | null | undefined,
  sectionName: string
) => {
  return sections && sections != null
    ? sections.findIndex((section) => section.sectionName === sectionName) ?? -1
    : -1;
};

export const showSaveItems = (claimIncidentUI) =>
  claimIncidentUI?.activeSection !==
  (claimIncidentUI?.sectionDots ? claimIncidentUI?.sectionDots.length - 1 : 0);

export const GetSplitButtonLabel = (claimIncidentUI, readOnlyMode) => {
  return showSaveItems(claimIncidentUI)
    ? readOnlyMode
      ? "Next"
      : "Save and next"
    : readOnlyMode
    ? "Close"
    : "Save as a submission";
};

export const updateValue = (
  field: string,
  value: string | number | Date | IncidentBodyPartDto[],
  setClaimIncident: any,
  claimIncident?: claimIncidentStateProps
) =>
  setClaimIncident({
    ...claimIncident,
    claimIncident: {
      ...claimIncident?.claimIncident,
      incident: {
        ...claimIncident?.claimIncident?.incident,
        [field]: value,
      },
    },
  });

export const updateMultipleValues = (
  fieldNames: string[],
  values: any[],
  setClaimIncident: any,
  claimIncident?: claimIncidentStateProps
) => {
  const newValues = fieldNames.map((targetProperty, index) => ({
    [targetProperty]: values[index],
  }));
  const targetJoined = Object.assign({}, ...newValues);
  setClaimIncident({
    ...claimIncident,
    claimIncident: {
      ...claimIncident?.claimIncident,
      incident: {
        ...claimIncident?.claimIncident?.incident,
        ...targetJoined,
      },
    },
  });
};
