import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { IncidentBodySectionEnums } from "../../../../../dtos/incident-body-section-enums";
import { IncidentInformationPage } from "../../../../../dtos/incident-information-page";
import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { SubSideNavItemDestinationsEnum } from "../../../../../dtos/sub-side-nav-item-destinations-enum";
import { globalOptions } from "../../../../../GlobalAtoms";
import { useApiGet } from "../../../../../hooks";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import {
  addQueryStrings,
  getQueryValue,
} from "../../../../../utilities/URLUtilities_OBSOLETE";
import themes from "../../../../../media/TrueTheme";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import {
  INCIDENT_BODY_SECTION,
  INCIDENT_ID,
  INCIDENT_STEPPER_SECTION,
  INSURED_ATOM_KEY,
} from "../../../../../utilities/queryStringsHash";
import { Heading6 } from "../../../../TrueUI";
import { ProgressStepper } from "../../../../TrueUI/Steppers";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { newIncidentStyles } from "./AddNewIncidentStyles";

import {
  AddNewIncidentProps,
  claimIncidentStateProps,
  getInitialState,
  getSectionDotNames,
  getSectionDots,
  getValue,
  hasActivePolicy,
} from "./AddNewIncidentUtils";
import IncidentFields from "./IncidentFields";

const AddNewIncidentNew: FC<AddNewIncidentProps> = ({
  insuredId,
  incidentId,
  tabKey,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const insuredIdAtom = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom: setClaimIncidentState } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtom)
  );
  const [claimIncidentUI, setClaimIncidentUI] =
    useState<claimIncidentStateProps>();
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);

  const { responseGet: getIncidentResponse, dispatchGet: dispatchGetIncident } =
    useApiGet<IncidentInformationPage>(
      incidentId
        ? `api/Incident/GetIncidentInformationByInsuredIdAndIncidentId?insuredId=${
            claimIncidentUI?.foundInsured
          }&incidentId=${incidentId ?? -1}`
        : `api/Incident/GetIncidentInformationByInsuredIdAndIncidentId?insuredId=${claimIncidentUI?.foundInsured}`
    );

  const claimIncidentState = getAtom();
  const stepperSectionTmp = getQueryValue(INCIDENT_STEPPER_SECTION);
  const stepperSection =
    stepperSectionTmp !== "" ? parseInt(stepperSectionTmp) : 0;

  const onClickDot = (clickedDot: number) => {
    setClaimIncidentUI({
      ...claimIncidentUI,
      activeSection: clickedDot,
    });
  };

  const setStateExistingIncident = () => {
    const responseData = getIncidentResponse?.axiosResponse?.data;
    setClaimIncidentUI({
      ...claimIncidentUI,
      insuredInfo: responseData?.insured,
      locations: responseData?.insuredAddresses,
      policies: responseData?.policies ?? [],
      hasPolicy: hasActivePolicy(
        getValue(claimIncidentUI?.claimIncident?.incident?.injuryDateTime),
        getValue(responseData?.policies)
      ),
      claimIncident: responseData?.claimIncident,
      stateReport: responseData?.stateReportPage,
      sectionDots: getSectionDots(
        responseData?.stateReportPage?.stateReportFields
      ),
      jurisdiction: null,
    });
    setReadOnlyMode(
      responseData?.claimIncident?.incident?.incidentStatus !==
        IncidentStatusEnums.IN_PROGRESS
    );
  };

  const setStateNewIncident = () => {
    const responseData = getIncidentResponse?.axiosResponse?.data;
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          insuredID: Number(responseData?.insured.insuredId),
          injuryAddressID: Number(responseData?.insured.primaryAddressId),
          stateReportID:
            claimIncidentUI?.stateReport?.stateReport?.stateReportId,
        },
      },
      insuredInfo: responseData?.insured,
      jurisdiction: responseData?.insured?.addressInformation?.state,
      locations: responseData?.insuredAddresses,
      policies: responseData?.policies ?? [],
      hasPolicy: hasActivePolicy(
        getValue(claimIncidentUI?.claimIncident?.incident?.injuryDateTime),
        getValue(responseData?.policies)
      ),
    });
  };

  const setClaimIncidentInformation = () => {
    if (claimIncidentState?.claimIncidentInformation?.claimIncident?.incident) {
      setIncidentInformationFromAtom();
    } else {
      setClaimIncidentUI(getInitialState(stepperSection, insuredId));
    }
  };

  const setIncidentInformationFromAtom = () => {
    const incidentInformation = claimIncidentState?.claimIncidentInformation;
    setClaimIncidentUI({
      ...incidentInformation,
      filledFromAtom: true,
    });

    setReadOnlyMode(
      incidentInformation?.claimIncident?.incident?.incidentStatus ===
        IncidentStatusEnums.SUBMITTED
    );

    addQueryStrings([
      {
        nameOfHash: INCIDENT_ID,
        valueOfHash:
          incidentInformation?.claimIncident?.incident?.incidentID ?? 0,
      },
    ]);
  };

  useEffect(() => {
    setClaimIncidentInformation();
    addQueryStrings([
      {
        nameOfHash: INCIDENT_BODY_SECTION,
        valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
      },
    ]);
  }, []);

  useEffect(() => {
    if (claimIncidentUI?.foundInsured && !claimIncidentUI?.filledFromAtom) {
      dispatchGetIncident();
    }
  }, [claimIncidentUI?.foundInsured]);

  useEffect(() => {
    if (conditionHasValue(claimIncidentUI?.activeSection)) {
      addQueryStrings([
        {
          nameOfHash: INCIDENT_STEPPER_SECTION,
          valueOfHash: claimIncidentUI?.activeSection ?? 0,
        },
      ]);
    }
  }, [claimIncidentUI?.activeSection]);

  useEffect(() => {
    if (isAPITotallyComplete(getIncidentResponse)) {
      if (incidentId) {
        setStateExistingIncident();
      } else {
        setStateNewIncident();
      }
    }
  }, [getIncidentResponse]);

  useEffect(() => {
    setClaimIncidentState({
      ...claimIncidentState,
      // This is set by the InsuredWrapper, but with this
      // we can avoid the raise condition and do not lose data
      insuredBodySection:
        SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
      claimIncidentInformation: {
        ...claimIncidentUI,
        incidentSection: IncidentBodySectionEnums.NEW_INCIDENT,
      },
    });
  }, [claimIncidentUI]);

  return (
    <Box id={"add_new_incident_container"} sx={newIncidentStyles(theme)}>
      <div id={"header_wrapper"}>
        <Heading6>First Report of Injury</Heading6>
      </div>
      <div className={"add_incident_fields_container"}>
        <div id={"left_incident_wrapper"}>
          <ProgressStepper
            dots={getSectionDotNames(
              getSectionDots(claimIncidentUI?.stateReport?.stateReportFields)
            )}
            active={claimIncidentUI?.activeSection ?? 0}
            lastSavedSection={claimIncidentUI?.lastSavedSection ?? -1}
            allDotsAvailable={incidentId ? true : false}
            onClickDot={onClickDot}
          />
        </div>
        <div id={"right_incident_wrapper"}>
          <IncidentFields
            readOnlyMode={readOnlyMode}
            insuredId={insuredId}
            claimIncidentState={claimIncidentState}
            claimIncidentUI={claimIncidentUI}
            setClaimIncidentState={setClaimIncidentState}
            setClaimIncidentUI={setClaimIncidentUI}
          />
        </div>
      </div>
    </Box>
  );
};

export default AddNewIncidentNew;
