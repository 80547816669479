import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { dividerStyles } from "./dividerStyles";

export type DividerProps = {
  height?: number | string;
  width?: number | string;
  borderSize?: number | string;
  showBorder?: boolean;
};
const Divider: FC<DividerProps> = (props) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  return <div style={dividerStyles(theme, props)}></div>;
};

export default Divider;
