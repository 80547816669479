import { FC } from "react";
import { SaveButton } from "../../../TrueUI";
import CancelButton from "../../../TrueUI/Buttons/CancelButton";

type ProgramBottomButtonsProps = {
  showBottomButtons: boolean;
  saveFunction: () => void;
  cancelFunction: () => void;
};

const ProgramBottomButtons: FC<ProgramBottomButtonsProps> = ({
  saveFunction,
  cancelFunction,
  showBottomButtons,
}) => {
  return (
    <>
      {showBottomButtons && (
        <div
          style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <SaveButton tabIndex={67} onClick={saveFunction} />
          <CancelButton
            tabIndex={68}
            withConfirmation
            onClick={cancelFunction}
            textOnConfirmation="Are you sure you want to cancel and lose your work?"
          />
        </div>
      )}
    </>
  );
};

export default ProgramBottomButtons;
