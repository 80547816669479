import { FC } from "react";
import { ModalSectionCurrenPaymentPlanProps } from "../TypesBillingOptions";
import { Button, FontBold, Input, Row } from "../../../../../TrueUI";
import Col, { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { inputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";

const ModalSectionCurrentPaymentPlan: FC<
  ModalSectionCurrenPaymentPlanProps
> = ({ billingContentConfig }) => {
  return (
    <div>
      <FontBold>CURRENT PAYMENT PLAN</FontBold>
      <Row verticalAlign={"flex-start"} {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter={"5px"}
        >
          <Input
            {...inputConfiguration(
              "insuredBillinOptionPolicyNumber",
              "insuredBillingOptionPolicyNumber",
              "Policy Number"
            )}
            readOnly
            value={billingContentConfig?.initialBillingRuleData?.policyNumber}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter={"5px"}
        >
          <Input
            {...inputConfiguration(
              "insuredBillinOptionStatus",
              "insuredBillingOptionStatus",
              "Status"
            )}
            readOnly
            value={billingContentConfig?.initialBillingRuleData?.statusDate}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter={"5px"}
        >
          <Input
            {...inputConfiguration(
              "insuredBillinOptionPaymentPlan",
              "insuredBillingOptionPaymentPlan",
              "Payment Plan"
            )}
            readOnly
            value={
              billingContentConfig?.initialBillingRuleData?.currentPayTypeName
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter={"5px"}
          verticalAlignSelf={"flex-end"}
        >
          <Button variantStyle={"outlined"} fullWidth>
            change Pay Plan
          </Button>
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
      </Row>
    </div>
  );
};

export default ModalSectionCurrentPaymentPlan;
