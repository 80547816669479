import { FC, useRef, useState } from "react";
import { BaseGridStructureBody } from "./BaseGridStructure";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import BaseGridRenderProcessor_v2 from "./BaseGridRenderProcessor_v2";
import style from "./Styles/basegrid.module.css";

const BaseGridBody: FC<any> = ({ uiid }) => {
  const bodyStructureId = `true_base_grid_structure_body_${uiid}`;
  const gridBodyRef = useRef<HTMLDivElement>(null);
  const { instanceSelector, instanceInternalSelector } = useGridInstance(
    uiid,
    "BaseGridBody"
  );
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());

  const [scrollEventTrigger, setScrollEventTrigger] = useState<{
    scrollEvent: { rowBoundRefRect: DOMRect | null; scrollEvent: Event };
    direction: "down" | "up" | null;
  } | null>(null);

  const [bodyWidthHeight, setBodyWidthHeight] = useState<any>(null);

  return instanceInternal?.isReadyToRender &&
    instanceInternal?.hasSortedAndFilteredProcessingCompleted ? (
    <BaseGridStructureBody
      id={bodyStructureId}
      ref={gridBodyRef}
      onLoadHeightWidth={(e) => setBodyWidthHeight(e)}
      onResizeHeightWidth={(e) => setBodyWidthHeight(e)}
      onScrollEvent={(e) => {
        setScrollEventTrigger({
          scrollEvent: {
            scrollEvent: e.scrollEvent,
            rowBoundRefRect: e.rowBoundRefRect,
          },
          direction: e.direction,
        });
      }}
      onScrollUpEvent={(e) => {
        setScrollEventTrigger({
          scrollEvent: {
            scrollEvent: e.scrollEvent,
            rowBoundRefRect: e.rowBoundRefRect,
          },
          direction: "up",
        });
      }}
      onScrollDownEvent={(e) => {
        setScrollEventTrigger({
          scrollEvent: {
            scrollEvent: e.scrollEvent,
            rowBoundRefRect: e.rowBoundRefRect,
          },
          direction: "down",
        });
      }}
    >
      <BaseGridRenderProcessor_v2
        uiid={uiid}
        scrollEventTrigger={scrollEventTrigger}
        gridBodyRef={gridBodyRef}
        gridBodyWidthHeight={bodyWidthHeight}
      />
    </BaseGridStructureBody>
  ) : (
    <div className={style.true_base_grid_no_result_found}>
      {instance?.advancedOptions?.noResultFoundMessageOverride ??
        "No results found."}
    </div>
  );
};

export default BaseGridBody;
