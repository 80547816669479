import { FC, useEffect, useState } from "react";
import {
  AchBatchTransactionsDetailsTableProps,
  AchTransactionProps,
} from "./AchTypes";
import IconButton from "../../TrueUI/Buttons/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { BillingStatusEnum } from "../../../dtos/billing-status-enum";
import ModalAchTransaction from "./ModalAchTransaction";
import { useApiPost } from "../../../hooks";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../BaseGrid/BaseGrid";

const AchBatchTransactionsDetailsTable: FC<
  AchBatchTransactionsDetailsTableProps
> = ({ selectedBatchId, selectedBatchStatusId, setSelectedBatchId }) => {
  const [modalData, setModalData] = useState<AchTransactionProps>({
    isModalOpen: false,
    rowData: null,
    refreshTable: crypto.randomUUID(),
  });
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const TABLE_NAME = "ach_batch_transactions_details";
  const GET_URL = `api/BillingPaymentBatch/GetAchBatchDetailsTable?billingPaymentBatchId=${selectedBatchId}&refresh=${modalData.refreshTable}`;

  const { responsePost, dispatchPost } = useApiPost(
    `api/BillingPaymentBatch/PostBillingAchPaymentBatch`,
    {
      billingBatchId: selectedBatchId,
    }
  );

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() =>
          setModalData({
            ...modalData,
            isModalOpen: true,
            rowData: rowData?.row,
            deleteRow: false,
          })
        }
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };
  const tableConfiguration: BaseGridProperties = {
    name: TABLE_NAME,
    getURL: GET_URL,
    toolbarOptions: {
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showAddButton: selectedBatchStatusId === BillingStatusEnum.PENDING,
      addButtonText: "POST BATCH",
    },
    columnOptions: [
      { fieldName: "InsuredName", width: 18 },
      { fieldName: "AccountNickName", width: 20 },
      { fieldName: "BatchDate", width: 20 },
      { fieldName: "PostAccount", width: 20 },
      { fieldName: "TransactionAmount", width: 20, align: "right" },
      { fieldName: "OPTIONS", width: 10 },
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: selectedBatchStatusId !== BillingStatusEnum.PENDING,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      addEventOverrideCallback: () => setIsDialogOpen(true),
    },
  };

  const { deleteRow } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    if (modalData?.deleteRow === true) {
      deleteRow({
        rowKey: modalData?.rowData?._row_key ?? "",
        hydratedData: modalData?.rowData,
        deleteType: "hidden",
      });
    }
  }, [modalData?.deleteRow]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setSelectedBatchId?.(null);
    }
  }, [responsePost]);

  return (
    <>
      <div id={"ach_batch_transactions_details"} style={{ height: "95%" }}>
        <BaseGrid name={TABLE_NAME} />
      </div>
      <ModalAchTransaction modalData={modalData} setModalData={setModalData} />
      <DialogConfirmation
        id="dialog-confirmation"
        open={isDialogOpen}
        dialogDescriptionText={"Do you wish to post all payments in the batch?"}
        onOptionYesEvent={() => {
          dispatchPost();
          setIsDialogOpen(false);
        }}
        onOptionNoEvent={() => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export default AchBatchTransactionsDetailsTable;
