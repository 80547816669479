import { IconButton } from "@mui/material";
import EyeBallIcon from "@mui/icons-material/VisibilityOutlined";
import EyeBallOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FC, useState } from "react";
type DefaultSSNCellProperties = {
  value: string;
};
const DefaultSSNCell: FC<DefaultSSNCellProperties> = ({ value }) => {
  const [showSSNFull, setShowSSNFull] = useState(false);
  const isValidSSN = new RegExp("^[0-9]{9}$").test(value);
  const ssnHiddenValue = `XXX-XX-${value.substring(5, 9)}`;
  const ssnVisibleValue = `${value.substring(0, 3)}-${value.substring(
    3,
    5
  )}-${value.substring(5, 9)}`;

  return isValidSSN && value !== "" ? (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ display: "flex", alignSelf: "center", width: "80%" }}>
        {showSSNFull ? ssnVisibleValue : ssnHiddenValue}
      </div>
      <div style={{ width: "20%", alignItems: "flex-end" }}>
        <IconButton onClick={() => setShowSSNFull(!showSSNFull)}>
          {showSSNFull ? (
            <EyeBallOffIcon style={{ height: "16px", width: "16px" }} />
          ) : (
            <EyeBallIcon style={{ height: "16px", width: "16px" }} />
          )}
        </IconButton>
      </div>
    </div>
  ) : null;
};

export default DefaultSSNCell;
