import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";
import { PercentageType } from "../../../types/RangeNumberType";

export const FontSx = (
  theme: Theme,
  type,
  primaryColor: boolean,
  opacity: PercentageType,
  whiteSpace,
  display,
  textAlign,
  maxWidth,
  truncate,
  secondaryFont,
  isSuccessFont,
  isErrorFont,
  isWarning
) => ({
  color: getColorWithOpacityHexaDecimal(
    isErrorFont
      ? theme?.DANGER
      : isSuccessFont
      ? theme?.SUCCESS
      : isWarning
      ? theme?.WARNING
      : primaryColor
      ? theme?.PRIMARY
      : theme?.TEXT_COLOR,
    opacity
  ),
  fontSize: theme?.[type]?.SIZE,
  fontWeight: theme?.[type]?.WEIGHT,
  whiteSpace: whiteSpace,
  display: display,
  textAlign: textAlign,
  maxWidth: maxWidth ?? "none",
  overflow: truncate ? "hidden" : "visible",
  textOverflow: "ellipsis",
  "&.align-label-left": {
    display: "block",
    textAlign: "left",
  },
  "&.align-label-center": { display: "block", textAlign: "center" },
  "&.align-label-right": { display: "block", textAlign: "right" },
  fontFamily: secondaryFont
    ? theme?.SECONDARY_FONT_FAMILY
    : theme?.PRIMARY_FONT_FAMILY,
});
