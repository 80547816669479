import { Theme } from "../../../../media/themeTypes";
import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";

export const bindPolicyButtonStyle = (theme: Theme) => ({
  height: "52px",
  padding: "16px !important",
  borderRadius: "50px",
  background: `${theme.SECONDARY_TEXT_COLOR} !important`,
  position: "absolute",
  bottom: "15%",
  right: "60px",
  "&:hover": {
    background: `${getColorWithOpacityHexaDecimal(
      theme?.SECONDARY_TEXT_COLOR,
      50
    )} !important`,
  },
});
