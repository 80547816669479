import { Collapse, Font } from "../TrueUI";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
const FelipeSandbox = () => {
  return (
    <>
      <Collapse
        title={
          <>
            <Font primaryColor>Testing new styles</Font>
            <IconButton
              className="plus-minus-icon-container"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AddOutlined className="plus-action-button" />
            </IconButton>
          </>
        }
        isHeaderCompact
        expanded={false}
        content={<div style={{ height: 1000, background: "red" }}></div>}
      />
    </>
  );
};

export default FelipeSandbox;
