import { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Input, Modal, Row } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { ModalVoidPaymentProps } from "./typesAndConstants";
import { inputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { VoidPaymentDto } from "../../../../../../dtos/void-payment-dto";
import { useApiPost } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { VoidPaymentResponseDto } from "../../../../../../dtos/void-payment-response-dto";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";

const ModalVoidPayment: FC<ModalVoidPaymentProps> = ({
  setIsVoidedRefundOrMoveModalConfig,
  isVoidedRefundOrMoveModalConfig,
  paymentId,
  claimId,
  rowKey,
  setUpdatedPaymentRowData,
  setNewPaymentRowData,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [errorDetails, setErrorDetails] = useState<any>();
  const [voidPayment, setVoidPayment] = useState<Partial<VoidPaymentDto>>({
    paymentId: paymentId ?? -1,
    voidPaymentReason: "",
    claimId: claimId,
  });
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    Partial<VoidPaymentResponseDto>
  >("api/Payment/VoidPayment", voidPayment);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      if (conditionHasValue(responsePost?.axiosResponse?.data)) {
        const paymentVoided =
          responsePost?.axiosResponse?.data?.paymentVoided ?? null;
        const paymentCreated =
          responsePost?.axiosResponse?.data?.paymentCreated ?? null;

        setNewPaymentRowData?.([
          {
            ...paymentCreated,
          },
        ]);
        setUpdatedPaymentRowData?.({
          paymentData: {
            ...paymentVoided,
            paymentStatusName:
              paymentVoided?.voided === true
                ? `${paymentVoided?.paymentStatusName} (V)`
                : paymentVoided?.paymentStatusName ?? "",
          },
          rowKey: rowKey ?? "",
        });

        setIsVoidedRefundOrMoveModalConfig?.({
          ...isVoidedRefundOrMoveModalConfig,
          refund: false,
          voided: false,
          refreshTable: true,
          movePayment: false,
          movePaymentData: null,
        });
      }

      setComponentTriggers(["claimBanner"]);
    }
  }, [responsePost]);

  const _voidModalProps = {
    id: "claim-financial-void-payment",
    title: "Void Payment",
    showCloseButton: false,
    saveEvent: undefined,
    deleteEvent: undefined,
    open: isVoidedRefundOrMoveModalConfig?.voided,
    cancelEvent: () => {
      setIsVoidedRefundOrMoveModalConfig?.({
        ...isVoidedRefundOrMoveModalConfig,
        refund: false,
        voided: false,
        movePayment: false,
        movePaymentData: null,
      });
    },
  };
  return (
    <Modal {..._voidModalProps}>
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colWithNoMarginNorGutter} displayFlex={false}>
          <p>
            Please confirm to void this payment and enter a void comment below.
          </p>
          <Input
            {...inputConfiguration(
              "id-void-reason",
              "name-void-reason",
              "",
              200
            )}
            value={voidPayment?.voidPaymentReason}
            onChangeRawValue={(value) =>
              setVoidPayment({ ...voidPayment, voidPaymentReason: value ?? "" })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.voidPaymentReason}
          />
        </Col>
      </Row>
      <Divider height={20} />
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign={"flex-end"}
          verticalGutter={"20px"}
        >
          <Button
            onClick={() => {
              dispatchPost();
            }}
            sx={{ marginRight: "15px" }}
          >
            Ok
          </Button>
          <Button
            variantStyle={"outlined"}
            onClick={() =>
              setIsVoidedRefundOrMoveModalConfig?.({
                ...isVoidedRefundOrMoveModalConfig,
                refund: false,
                voided: false,
                movePayment: false,
                movePaymentData: null,
              })
            }
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalVoidPayment;
