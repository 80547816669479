import { FC } from "react";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BaseTable2 } from "../../TrueUI";
import { useBaseTable } from "../../../hooks/useBaseTable";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSwitchStatusFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSwitchStatusFilter";
import { AgencyProgramDto } from "../../../dtos/agency-program-dto";
import { AgencyAppointmentModalProps } from "./Appointments";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

const tableName = "appointments_table";

type AppointmentsTableProps = {
  agencyId?: string;
  isNewAgency: boolean;
  appointmentModalValues: AgencyAppointmentModalProps | null;
  setAppointmentModalValues: (
    value: AgencyAppointmentModalProps | null
  ) => void;
};

const AppointmentsTable: FC<AppointmentsTableProps> = ({
  agencyId,
  isNewAgency,
  appointmentModalValues,
  setAppointmentModalValues,
}) => {
  const getAgencyAppointmentByRow = (row: any) =>
    ({
      status: row.Status,
      agencyId: row.AgencyId,
      programId: row.ProgramId,
      effectiveDate: new Date(row.EffectiveDate),
      expirationDate: conditionHasValue(row.ExpirationDate)
        ? new Date(row.ExpirationDate)
        : null,
      defaultRateNew: parseFloat(row.DefaultRateNew),
      agencyProgramId: row.AgencyProgramId,
      discountOverride: parseFloat(row.DiscountOverride),
      defaultRateRenewal: parseFloat(row.DefaultRateRenewal),
    } as AgencyProgramDto);

  const AgencyAppointmentsEditModalLink = (
    actionOptions: OptionsContextActionParameterProperties<AgencyProgramDto>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="edit"
          size="small"
          disabled={actionOptions.row.IsExpiredProgram ?? false}
          onClick={() => {
            setAppointmentModalValues({
              ...appointmentModalValues,
              isOpen: true,
              agencyAppointment: getAgencyAppointmentByRow(actionOptions.row),
            });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/AgencyProgram/GetAgencyPrograms?agencyId=${agencyId}`,
    columnOptions: [
      { fieldName: "Program", width: 60 },
      { fieldName: "EffectiveDate", width: 20 },
      { fieldName: "ExpirationDate", width: 20 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTableSwitchStatusFilter({
          filterOptions: actionOptions,
          columnsNames: ["Status"],
          checkedLabelText: "Show All?",
          labelFontType: "BODY",
          labelPosition: "start",
        }),
    ],
    toolbarOptions: {
      toolBarType: "compact",
      compactTitle: "Appointments",
      addButtonIsDisabled: isNewAgency,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          AgencyAppointmentsEditModalLink(actionOptions),
      },
    },
    events: {
      addEventOverrideCallback: () =>
        setAppointmentModalValues({
          ...appointmentModalValues,
          agencyAppointment: {
            agencyId: parseInt(agencyId ?? "-1"),
          } as AgencyProgramDto,
          isOpen: true,
        }),
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={tableName} />;
};

export default AppointmentsTable;
