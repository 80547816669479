export enum DrawerActionsEnums {
  NOTES = 0,
  TASKS = 1,
  FILES = 2,
}

export type ContextProps = {
  type: number;
  tabKey: string;
  claimNumber?: string;
  insuredId?: number;
  insuredCode?: string;
};

export type IconsOnDrawerProps = {
  tab: number;
  iconName: string;
  permissions: number[];
};

export type DrawerContainerProps = {
  insuredId: number;
  insuredCode?: string;
  tabKey: string;
};

export const IconsOnDrawer: IconsOnDrawerProps[] = [
  {
    tab: DrawerActionsEnums.NOTES,
    iconName: "track-changes-enable",
    permissions: [1, 2, 3], // TODO: Add the correct permissions
  },
  {
    tab: DrawerActionsEnums.TASKS,
    iconName: "clipboard-text",
    permissions: [1, 2, 3],
  },
  {
    tab: DrawerActionsEnums.FILES,
    iconName: "paperclip-alt",
    permissions: [1, 2, 3],
  },
];
