import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { FilesTabConfigurationDto } from "../../../../dtos/files-tab-configuration-dto";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import EditFileModal from "../../../FileUpload/EditFileModal";
import {
  defaultEditConfiguration,
  EditFileConfiguration,
} from "../../../FileUpload/FileUploadUtils";
import { BaseTable2 } from "../../../TrueUI";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { ContextProps } from "../InsuredDrawerConstants";
import { claimFilesTableStyle } from "./ClaimFilesStyles";
import FilesForm from "./FilesForm";
import {
  defaultConfiguration,
  getColumnAndToolbarConfig,
  getFilterOptionsByContext,
  getOnCheckEvent,
  getPolicyOptions,
  GetURLFileListingByContext,
  MultiSelectFilesProps,
  multiSelectOption,
  ZipButton,
} from "./FilesUtil";

type ClaimFilesProps = {
  context: ContextProps;
  filesConfiguration?: FilesTabConfigurationDto;
};

const ClaimFiles: FC<ClaimFilesProps> = ({ context, filesConfiguration }) => {
  const [editConfig, setEditConfig] = useState<Partial<EditFileConfiguration>>(
    defaultEditConfiguration
  );
  const [getURL, setGetURL] = useState(
    GetURLFileListingByContext(context, crypto.randomUUID())
  );
  const [multiSelectInfo, setMultiSelectInfo] =
    useState<MultiSelectFilesProps>();

  const refreshURL = () => {
    setGetURL(GetURLFileListingByContext(context, crypto.randomUUID()));
  };

  const onUpdatedFileClaim = () => {
    refreshURL();
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            const row = actionOptions?.row;
            setEditConfig({
              ...editConfig,
              fileId: row?.FileId,
              category: row?.Category,
              fileDisplayName: row?.FileDisplayName,
              downloadedFileName: row?.DownloadedFileName,
              type: row?.ContextFileType,
              claimNumber: row?.ClaimNumber,
              claimId: row?.ClaimId,
              insuredId: row?.InsuredId,
              insuredName: row?.InsuredName,
              comments: row?.InsuredFileComments,
              status: row?.FileStatus,
              isEditing: true,
              rowKey: row?._row_key,
              addedDate: row?.AddedDate,
              userShortName: row?.UserShortName,
              assignedTo: row?.AssignedId,
              dateReviewed: row?.DateReviewed,
              serviceFrom: row?.ServiceFrom,
              serviceTo: row?.ServiceTo,
              //--insuredCategoryOptions: configurationState?.insuredCategoryOptions ?? [],
              claimCategoryOptions:
                filesConfiguration?.claimCategoryOptions ?? [],
              userOptions: filesConfiguration?.userOptions,
            });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableName = "ClaimsFilesTable";
  const tableConfiguration: BaseTable2Properties = {
    ...getColumnAndToolbarConfig(tableName),
    getURL: getURL,
    filterOptions: getFilterOptionsByContext(context.type, filesConfiguration),
    multiSelectOptions: multiSelectOption(multiSelectInfo, setMultiSelectInfo),
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      onCheckClick: (checkedRows) => {
        getOnCheckEvent(checkedRows, setMultiSelectInfo);
      },
      onDataFilteredChange: (data) => {
        setEditConfig({
          ...editConfig,
          searchNextIdList:
            data
              ?.filter((row) => row[0] !== undefined && row[0] !== null)
              .map((row) => parseInt(row[0], 10)) || [],
        });
      },
    },
  };

  useBaseTable(tableConfiguration);

  const closeEditModal = (e: boolean) => {
    if (e === false) {
      setEditConfig({ ...defaultConfiguration, isEditing: e });
    }
  };

  return (
    <>
      {filesConfiguration && (
        <div
          id="claim-files-container"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <FilesForm
            context={context}
            categoryOptions={filesConfiguration?.categoryOptions ?? []}
            policyOptions={getPolicyOptions(context.type, filesConfiguration)}
            allowedExtensions={filesConfiguration?.allowedExtensions}
            saveSuccessful={refreshURL}
          />
          {ZipButton(multiSelectInfo, setMultiSelectInfo, context?.claimNumber)}
          <Box id={"files-table-wrapper"} sx={claimFilesTableStyle()}>
            <BaseTable2 name={tableName} />
          </Box>
          <EditFileModal
            configuration={editConfig}
            closeModal={closeEditModal}
            saveSuccessful={onUpdatedFileClaim}
            setConfiguration={setEditConfig}
          />
        </div>
      )}
    </>
  );
};

export default ClaimFiles;
