import { FC } from "react";
import { DownloadFile } from "..";
import "./showFiles.module.css";

type FileProperties = {
  fileName: string;
  fileId: number;
};

type ShowFilesProps = {
  files: FileProperties[];
  onDeleteFile?: (e?: any) => void;
};

const ShowFiles: FC<ShowFilesProps> = ({ files, onDeleteFile }) => {
  return (
    <div className="true_show_files_scrollable_container">
      <div className="true_files_wrap_container">
        {files.map((file) => (
          <DownloadFile
            fileName={file?.fileName}
            fileId={file?.fileId}
            key={file?.fileId}
            showDeleteButton
            onDeleteFile={(id) => onDeleteFile?.(id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ShowFiles;
