import { Button } from "@mui/material";

const RecentsHelp = () => {
  return (
    <div>
      Recents Help
      <Button variant="contained">Call Us</Button>
    </div>
  );
};

export default RecentsHelp;
