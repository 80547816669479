import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  InputAddress,
  InputPhone,
  Row,
  Select,
} from "../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../utilities/inputPropsConfigurationFunctions";
import {
  LitigationContactFormProps,
  defaultRepresentationContactData,
  API_PATH,
  parseToStringObjectValues,
} from "../typesAndConstants";
import {
  useApiGet,
  useApiPost,
  usePermissions,
} from "../../../../../../../hooks";
import { ClaimReferenceDto } from "../../../../../../../dtos/claim-reference-dto";
import {
  getSelectOptionFromClaimReferenceList,
  getSelectOptionsByFieldName,
} from "../../../../../../../utilities/dataConversionFunctions";
import { StatusEnums } from "../../../../../../../dtos/status-enums";
import { toUpperCaseKeys } from "../../../../../../../utilities/objectFunctions";
import { ClaimRepresentationContactDto } from "../../../../../../../dtos/claim-representation-contact-dto";
import { ClaimModalReferenceSelectOptions } from "../../ClaimContactsComponents/ContactModalConstants/ContactModalConstants";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";
import {
  DeleteDialogConfiguration,
  defaultDeleteDialogConfiguration,
} from "./ClaimLitigationUtil";
import DialogConfirmation from "../../../../../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";

const LitigationContactForm: FC<LitigationContactFormProps> = ({
  claimId,
  contactData,
  formState,
  setFormState,
  saveLitigation,
  selectedCase,
  hasClaimsManagementPermissions,
}) => {
  const hasEditAddPermission = usePermissions([2, 13, 14, 15]);
  const canSeeEditAddButtons = usePermissions([3, 12]);
  const [referenceSelectOptions, setReferenceSelectOptions] =
    useState<ClaimModalReferenceSelectOptions>({
      statuses: [],
      types: [],
      phoneTypes: [],
    });
  const [_contactData, setCaseContactData] = useState<
    ClaimRepresentationContactDto | Partial<ClaimRepresentationContactDto>
  >();
  const [errorDetails, setErrorDetails] = useState<any>();
  const { responseGet, dispatchGet } = useApiGet<ClaimReferenceDto[]>(
    `api/ClaimReference/GetReferencesForClaimContactsForm`
  );
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    ClaimRepresentationContactDto | Partial<ClaimRepresentationContactDto>
  >(`${API_PATH}/SaveRepresentationContact`, {
    ..._contactData,
    claimId: claimId,
  });
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DeleteDialogConfiguration>(defaultDeleteDialogConfiguration);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setErrorDetails(null);
      if (
        responsePost?.responseData?.contactStatus?.toString() ===
        StatusEnums.DELETED.toString()
      ) {
        saveLitigation?.(
          toUpperCaseKeys(
            parseToStringObjectValues(responsePost?.responseData)
          ),
          "delete"
        );
        setFormState({
          mode: "readonly",
          title: "DETAILS",
          rowTableClicked: "case",
        });
        setCaseContactData({});
      } else {
        saveLitigation?.(
          toUpperCaseKeys(
            parseToStringObjectValues(responsePost?.responseData)
          ),
          contactData?.claimContactId?.toString() === "0" ? "add" : "update"
        );
        setFormState({
          mode: "readonly",
          title: "DETAILS",
          rowTableClicked: "case",
        });

        setCaseContactData({});
      }
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    setErrorDetails(null);
    if (
      conditionHasValue(responsePost?.responseData) &&
      contactData?.representationContactId ===
        responsePost?.responseData?.representationContactId
    ) {
      setCaseContactData({
        ...responsePost?.responseData,
        contactType: parseInt(
          responsePost?.responseData?.contactType?.toString() ?? "-1"
        ),
      });
    } else {
      setCaseContactData({
        ...contactData,
        representationId: selectedCase?.representationId,
        claimId: selectedCase?.claimId,
        contactType:
          typeof contactData?.contactType === "string"
            ? parseInt(contactData?.contactType ?? "-1")
            : contactData?.contactType,
      });
    }
  }, [contactData]);

  useEffect(() => {
    console.log(hasEditAddPermission, canSeeEditAddButtons); // added to not trigger error
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      responseGet?.axiosResponse?.data !== null &&
      responseGet?.axiosResponse?.data !== undefined
    ) {
      setReferenceSelectOptions({
        statuses: getSelectOptionsByFieldName(
          "ContactStatus",
          responseGet?.axiosResponse?.data ?? []
        ),
        types: getSelectOptionsByFieldName(
          "ContactType",
          responseGet?.axiosResponse?.data ?? []
        ),
        phoneTypes: [
          {
            displayName: "",
            intValue: -1,
          },
          ...getSelectOptionFromClaimReferenceList(
            "PhoneType",
            responseGet?.axiosResponse?.data ?? []
          ),
        ],
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      referenceSelectOptions?.types?.length > 0 &&
      referenceSelectOptions?.statuses?.length > 0
    ) {
      setCaseContactData({
        ..._contactData,
        contactType:
          typeof contactData?.contactType === "string"
            ? parseInt(contactData?.contactType ?? "-1")
            : contactData?.contactType,
      });
    }
  }, [referenceSelectOptions]);

  useEffect(() => {
    if (_contactData?.contactStatus === StatusEnums.DELETED) dispatchPost();
  }, [_contactData]);

  const saveAction = () => dispatchPost();

  const cancelAction = () => {
    if (formState?.mode === "edit") {
      setFormState({ ...formState, mode: "readonly" });
      setCaseContactData({
        ...contactData,
        representationId: selectedCase?.representationId,
        claimId: selectedCase?.claimId,
        contactType:
          typeof contactData?.contactType === "string"
            ? parseInt(contactData?.contactType ?? "-1")
            : contactData?.contactType,
      });
      setErrorDetails(null);
      return;
    }
    setCaseContactData(defaultRepresentationContactData);
    setErrorDetails(null);
    setFormState({
      ...formState,
      mode: "readonly",
      title: `DETAILS FOR CASE NUMBER ${selectedCase?.caseNumber}`,
      rowTableClicked: "case",
    });
  };

  const selectsHasValues = () => {
    return (
      referenceSelectOptions.types.length > 0 &&
      referenceSelectOptions.phoneTypes.length > 0 &&
      referenceSelectOptions.statuses.length > 0
    );
  };
  const yesDeleteConfirmation = () => {
    setCaseContactData({
      ..._contactData,
      contactStatus: StatusEnums.DELETED,
    });
    setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
  };

  return (
    <>
      {selectsHasValues() && (
        <div>
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col {...colWithNoMarginNorGutter}>
              <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                <Col>
                  <Input
                    tabIndex={1}
                    {...inputConfiguration(
                      "id-name",
                      "litigationContactName",
                      "Name",
                      100
                    )}
                    value={_contactData?.contactName}
                    onChangeRawValue={(value) => {
                      setCaseContactData({
                        ..._contactData,
                        contactName: conditionHasValue(value) ? value : "",
                      });
                    }}
                    errorMessage={errorDetails?.contactName}
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                  />
                </Col>
                <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                  <Select
                    tabIndex={2}
                    {...selectConfiguration(
                      "id-contact-type",
                      "litigationContactContactType",
                      "Type"
                    )}
                    firstOptionAsDefault={false}
                    options={referenceSelectOptions.types}
                    value={_contactData?.contactType}
                    errorMessage={errorDetails?.contactType}
                    onChange={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        contactType: value,
                      })
                    }
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                  />
                </Col>
                <Col>
                  <Input
                    tabIndex={3}
                    {...inputConfiguration(
                      "id-company",
                      "litigationContactCompany",
                      "Company",
                      100
                    )}
                    value={_contactData?.company}
                    onChangeRawValue={(value) =>
                      setCaseContactData({ ..._contactData, company: value })
                    }
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                    errorMessage={errorDetails?.company}
                  />
                </Col>
                <Col horizontalAlignSelf="flex-start">
                  <Input
                    tabIndex={4}
                    {...inputConfiguration(
                      "id-email",
                      "litigationContactEmail",
                      "Email",
                      100
                    )}
                    value={_contactData?.emailAddress}
                    onChangeRawValue={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        emailAddress: value,
                      })
                    }
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                    errorMessage={errorDetails?.emailAddress}
                  />
                </Col>
                <Col displayFlex={false} {...colWithNoMarginNorGutter}>
                  <Row {...rowWithNoMarginNorGutter}>
                    <Col>
                      <InputPhone
                        tabIndex={5}
                        id="id-phone1"
                        name="litigationContactPhone1"
                        phoneLabel="Phone 1"
                        phoneValue={_contactData?.phone1}
                        errorMessagePhone={errorDetails?.phone1}
                        onChangePhoneValue={(value) =>
                          setCaseContactData({
                            ..._contactData,
                            phone1: value,
                          })
                        }
                        readOnly={formState?.mode === "readonly"}
                        labelFontType={"BOLD_CAPTION"}
                        variant={"filled"}
                      />
                    </Col>
                    <Col verticalAlignSelf="flex-end">
                      <Select
                        tabIndex={6}
                        {...selectConfiguration(
                          "id-phone1-type",
                          "litigationContactPhone1Type",
                          "Phone 1 Type"
                        )}
                        options={referenceSelectOptions?.phoneTypes ?? []}
                        value={_contactData?.phone1Type}
                        onChange={(value) => {
                          setCaseContactData({
                            ..._contactData,
                            phone1Type: value,
                          });
                        }}
                        readOnly={formState?.mode === "readonly"}
                        labelFontType={"BOLD_CAPTION"}
                        variant={"filled"}
                      />
                    </Col>
                  </Row>
                  <Row {...rowWithNoMarginNorGutter}>
                    <Col>
                      <InputPhone
                        tabIndex={7}
                        id="id-phone2"
                        name="litigationContactPhone2"
                        phoneLabel="Phone 2"
                        phoneValue={_contactData?.phone2}
                        errorMessagePhone={errorDetails?.phone2}
                        onChangePhoneValue={(value) =>
                          setCaseContactData({
                            ..._contactData,
                            phone2: value,
                          })
                        }
                        readOnly={formState?.mode === "readonly"}
                        labelFontType={"BOLD_CAPTION"}
                        variant={"filled"}
                      />
                    </Col>
                    <Col verticalAlignSelf="flex-end">
                      <Select
                        tabIndex={8}
                        {...selectConfiguration(
                          "id-phone2-type",
                          "litigationContactPhone2Type",
                          "Phone 2 Type"
                        )}
                        options={referenceSelectOptions?.phoneTypes ?? []}
                        value={_contactData?.phone2Type}
                        onChange={(value) =>
                          setCaseContactData({
                            ..._contactData,
                            phone2Type: value,
                          })
                        }
                        readOnly={formState?.mode === "readonly"}
                        labelFontType={"BOLD_CAPTION"}
                        variant={"filled"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col {...colWithNoMarginNorGutter}>
                  <InputAddress
                    tabIndex={9}
                    id="id-_contactData-location-address-input"
                    name="litigationContactAddress"
                    label="Address"
                    initValueAddress1={_contactData?.address1 ?? ""}
                    initValueAddress2={_contactData?.address2}
                    initValueCity={_contactData?.city ?? ""}
                    initValueStateCode={_contactData?.state ?? ""}
                    initValuePostCode={_contactData?.postalCode ?? ""}
                    address1ErrorMessage={errorDetails?.address1}
                    address2ErrorMessage={errorDetails?.address2}
                    cityErrorMessage={errorDetails?.city}
                    stateCodeErrorMessage={undefined}
                    postCodeErrorMessage={undefined}
                    showSingleLabel={false}
                    onChangeValueAddress1={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        address1: value,
                      })
                    }
                    onChangeValueAddress2={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        address2: value,
                      })
                    }
                    onChangeValueCity={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        city: value,
                      })
                    }
                    onChangeStateCode={(value) => {
                      setCaseContactData({
                        ..._contactData,
                        state: value,
                      });
                    }}
                    onChangeValuePostCode={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        postalCode: value,
                      })
                    }
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                  />
                </Col>
                <Col>
                  <InputPhone
                    tabIndex={15}
                    id="id-fax"
                    name="litigationContactFax"
                    phoneLabel="Fax"
                    phoneValue={_contactData?.fax}
                    errorMessagePhone={errorDetails?.fax}
                    onChangePhoneValue={(value) =>
                      setCaseContactData({
                        ..._contactData,
                        fax: value,
                      })
                    }
                    readOnly={formState?.mode === "readonly"}
                    labelFontType={"BOLD_CAPTION"}
                    variant={"filled"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 7, lg: 9, xl: 9 }}
              horizontalAlign="flex-start"
            >
              {formState?.mode === "readonly" && (
                <Button
                  tabIndex={16}
                  variantStyle="outlined"
                  isDisabled={!hasClaimsManagementPermissions}
                  onClick={() => setFormState({ ...formState, mode: "edit" })}
                  name="litigationContactEditButton"
                >
                  EDIT
                </Button>
              )}
              {formState?.mode === "edit" && (
                <Button
                  tabIndex={17}
                  variantStyle="outlined"
                  onClick={() => {
                    setDialogConfiguration({
                      ...dialogConfiguration,
                      isOpen: true,
                    });
                  }}
                  name="litigationContactDeleteButton"
                >
                  DELETE
                </Button>
              )}
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              horizontalGutter="15px"
              horizontalAlign="flex-end"
              breakpoints={{ md: 2, lg: 2, xl: 2 }}
            >
              {(formState?.mode === "edit" || formState?.mode === "add") && (
                //canSeeEditAddButtons === false && //Commented to test
                <Button
                  tabIndex={18}
                  onClick={saveAction}
                  //disabled={hasEditAddPermission ? false : true} //Commented to test
                  name="litigationContactSaveButton"
                >
                  SAVE
                </Button>
              )}
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 3, lg: 2, xl: 2 }}
            >
              {(formState?.mode === "edit" || formState?.mode === "add") && (
                <Button
                  tabIndex={19}
                  variantStyle="outlined"
                  onClick={cancelAction}
                  name="litigationContactCancelButton"
                >
                  CANCEL
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}
      <DialogConfirmation
        name={dialogConfiguration.dialogName}
        id={dialogConfiguration.dialogId}
        open={dialogConfiguration.isOpen}
        dialogDescriptionText={dialogConfiguration.descriptionText}
        onOptionNoEvent={() => {
          setDialogConfiguration({ ...dialogConfiguration, isOpen: false });
        }}
        onOptionYesEvent={yesDeleteConfirmation}
      />
    </>
  );
};

export default LitigationContactForm;
