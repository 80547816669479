import { PolicyRatingBlob } from "../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../dtos/policy-state-blob";
import {
  FormattingDate,
  getDateDiff,
  getDateObject,
  getDaysBetweenDates,
  getNumberOfDaysInAYear,
} from "../../../../utilities/dateFunctions";
import {
  customRound,
  unFormatLocalString,
} from "../../../../utilities/stringFunctions";
import { getTotalSurchargesAndFees } from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { getTotalPremium } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { ProgramPayPlanDto } from "../../../../dtos/program-pay-plan-dto";
import { getTimeBetweenDates } from "../../../../utilities/dateFunctions";
import {
  BindInstructionPaymentPlanUIProps,
  PolicyBindInstructionUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import { ColProps } from "../../../TrueUI/Grids/Col";
import {
  updatePolicyQuote,
  updatePolicyQuoteMultipleTargets,
} from "../updatesPolicyQuoteFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { PolicyBillingFrequencyEnum } from "../../../../dtos/policy-billing-frequency-enum";
import { PolicyPayPlanBlob } from "../../../../dtos/policy-pay-plan-blob";
import { PolicyBindInstructionsBlob } from "../../../../dtos/policy-bind-instructions-blob";
import { getRequiredAndOptionalFormsFromResponse } from "../PolicyEndorsementForms/EndorsementFormsUtils";
import { ProgramPolicyEndorsementBlob } from "../../../../dtos/program-policy-endorsement-blob";

const AMOUNT = "Amount";
const INSTALL = "Install";
const PERCENT = "Percent";
const REPORTING = "Reporting";
const NON_EARNING = "NonEarning";
export const constants = { AMOUNT, INSTALL, PERCENT, REPORTING, NON_EARNING };

const getBindInstructionsStateRatingsByPolicyQuoteState = (
  ratings: PolicyRatingBlob[]
) =>
  ratings.map((rating) => ({
    amount: rating.amount,
    proRate: rating.proRate,
    runningTotal: rating.runningTotal,
    elementCategory: rating.elementCategory,
  }));

export const getBindInstructionsStatesByPolicyQuote = (
  states: PolicyStateBlob[]
) =>
  states.map((state?: PolicyStateBlob) => ({
    stateCode: state?.stateCode,
    stateName: state?.stateName,
    ratings: getBindInstructionsStateRatingsByPolicyQuoteState(
      state?.ratings ?? []
    ),
  }));

export const disabledProrateTerm = (
  effectiveDate?: Date | null,
  expirationDate?: Date | null
) =>
  getDateDiff(
    getDateObject(FormattingDate(effectiveDate)),
    getDateObject(FormattingDate(expirationDate)),
    "years",
    true
  ) === 1;

export const getSumOfTotalSurchargesAndFeesPerStateByProRate = (
  ratings: PolicyRatingBlob[],
  proRate: boolean
) => {
  return Object.values(ratings).reduce((prevValue, rating) => {
    const isSurchargesOrFees =
      (["Fee", "Tax", "Surcharge"].includes(rating.elementCategory ?? "") &&
        rating.proRate === proRate) ??
      false;
    return prevValue + (isSurchargesOrFees ? rating.amount ?? 0 : 0);
  }, 0);
};

export const getSumOfTotalSurchargesAndFeesByProRate = (
  states: PolicyStateBlob[],
  proRate: boolean
) => {
  return states
    .map((state: PolicyStateBlob) => {
      return getSumOfTotalSurchargesAndFeesPerStateByProRate(
        state?.ratings ?? [],
        proRate
      );
    })
    .reduce(
      (a, value) => (a ?? 0) + (value = !null && value != undefined ? value : 0)
    ) as number;
};

const calculatePremiumProratedValues = (
  bindInstructionUI: PolicyBindInstructionUIProps
) => {
  const amount = getTotalPremium(bindInstructionUI?.states);
  const policyDaysYear = getNumberOfDaysInAYear(
    getDateObject(bindInstructionUI?.effectiveDate).getFullYear()
  );
  const policyDays = getDaysBetweenDates(
    getDateObject(bindInstructionUI?.effectiveDate),
    getDateObject(bindInstructionUI?.expirationDate)
  );
  const totalBilled = customRound(
    ((unFormatLocalString(amount) / policyDaysYear) * policyDays).toString(),
    0
  );
  return totalBilled;
};

const calculateFeeProratedValues = (
  bindInstructionUI: PolicyBindInstructionUIProps
) => {
  const amountProrated = getSumOfTotalSurchargesAndFeesByProRate(
    bindInstructionUI?.states ?? [],
    true
  );
  const amountNoProrated = getSumOfTotalSurchargesAndFeesByProRate(
    bindInstructionUI?.states ?? [],
    false
  );
  const policyDaysYear = getNumberOfDaysInAYear(
    getDateObject(bindInstructionUI?.effectiveDate).getFullYear()
  );
  const policyDays = getDaysBetweenDates(
    getDateObject(bindInstructionUI?.effectiveDate),
    getDateObject(bindInstructionUI?.expirationDate)
  );
  const totalBilledFeeProrated = customRound(
    ((amountProrated / policyDaysYear) * policyDays).toString(),
    0
  );
  return totalBilledFeeProrated + amountNoProrated;
};

export const policyQuoteBilledPremium = (
  prorate: boolean,
  bindInstructionUI?: PolicyBindInstructionUIProps
) =>
  prorate
    ? calculatePremiumProratedValues(bindInstructionUI ?? {})
    : unFormatLocalString(getTotalPremium(bindInstructionUI?.states ?? []));

export const policyQuoteBilledFees = (
  prorate: boolean,
  bindInstructionUI?: PolicyBindInstructionUIProps
) =>
  prorate
    ? customRound(
        calculateFeeProratedValues(bindInstructionUI ?? {}).toString(),
        0
      )
    : getTotalSurchargesAndFees(bindInstructionUI?.states ?? []);

export const defaultPaymentPlanOption = {
  displayName: "",
  intValue: -1,
  stringValue: null,
  decimalValue: null,
  dateValue: null,
};

export const paymentPlanDepositTypeOptions = [
  {
    displayName: constants.PERCENT,
    stringValue: constants.PERCENT,
    intValue: null,
    decimalValue: null,
    dateValue: null,
  },
  {
    displayName: constants.AMOUNT,
    stringValue: constants.AMOUNT,
    intValue: null,
    decimalValue: null,
    dateValue: null,
  },
];

export const getSelectOptionsFromResponse = (
  paymentPlans: ProgramPayPlanDto[]
) =>
  paymentPlans.map((opt) => ({
    displayName: opt.planName,
    intValue: opt.payPlanId,
    stringValue: null,
    decimalValue: null,
    dateValue: null,
  }));

export const getLongevityThreshold = (
  bindInstructionUI?: PolicyBindInstructionUIProps
) =>
  bindInstructionUI?.firstPolicyDate
    ? getTimeBetweenDates(
        bindInstructionUI?.firstPolicyDate,
        bindInstructionUI?.effectiveDate
      )
    : 0;

export const getPaymentPlanOption = (
  paymentPlanOptions?: ProgramPayPlanDto[] | null,
  paymentPlan?: number | null
) =>
  paymentPlanOptions &&
  paymentPlanOptions.find((payment) => payment.payPlanId === paymentPlan ?? -1);

export const isDepositOverride = (
  paymentPlan: number,
  paymentPlans: ProgramPayPlanDto[]
) => getPaymentPlanOption(paymentPlans, paymentPlan)?.depositOverride === 1;

export const hasBilledDepositAmount = (
  bindInstructionUI?: PolicyBindInstructionUIProps
) => (bindInstructionUI?.billedDepositAmount ? true : false);

export const hasBilledMethodReporting = (
  bindInstructionUI?: PolicyBindInstructionUIProps
) => bindInstructionUI?.billingMethod === constants.REPORTING;

export const getBilledDepositAmountByPlanTypeOrDepositOverride = (
  billedDepositAmount: number,
  billedPremiumAmount: number,
  paymentOption?: ProgramPayPlanDto | null,
  paymentPlanUI?: BindInstructionPaymentPlanUIProps
) => {
  if (
    isDepositOverride(
      paymentOption?.payPlanId ?? -1,
      paymentPlanUI?.paymentPlans ?? []
    )
  ) {
    return billedDepositAmount;
  } else if (paymentOption?.planType === constants.REPORTING) {
    const calculatedAmount =
      billedPremiumAmount *
      (paymentOption?.depositAmount === 0
        ? 1
        : paymentOption?.depositAmount / 100);
    return customRound(calculatedAmount.toString(), 0);
  } else if (paymentOption?.depositType === constants.PERCENT) {
    const { billedDepositAmount } = calculatedBilledDepositAmount(
      paymentOption.depositAmount,
      billedPremiumAmount,
      paymentOption?.numberOfPayments ?? 0,
      false,
      paymentOption.allEqual
    );

    return billedDepositAmount;
  } else if (paymentOption?.depositType === constants.AMOUNT) {
    return paymentOption?.depositAmount;
  } else if (paymentOption?.depositType === constants.NON_EARNING) {
    const { billedDepositAmount } = calculatedBilledDepositAmount(
      paymentOption.depositAmount,
      billedPremiumAmount,
      paymentOption?.numberOfPayments ?? 0,
      true,
      paymentOption.allEqual
    );
    return billedDepositAmount;
  }
  return 0;
};

const calculatedNonEarningBilledDepositAmount = (
  depositRate: number,
  billedPremiumAmount: number,
  numberPayments: number,
  depositType?: string
) => {
  const nonEarningAmount =
    depositType === constants.PERCENT
      ? customRound(
          (
            billedPremiumAmount * (depositRate === 0 ? 1 : depositRate / 100)
          ).toString(),
          0
        )
      : depositRate;
  const installmentPayment = customRound(
    (billedPremiumAmount / numberPayments).toString(),
    0
  );
  const numberOfPayments = numberPayments - 1 === 0 ? 1 : numberPayments - 1;
  const totalAmount = installmentPayment * numberOfPayments;
  const billedDepositAmount = customRound(
    (billedPremiumAmount - totalAmount).toString(),
    0
  );

  return { nonEarningAmount, installmentPayment, billedDepositAmount };
};

export const calculatedBilledDepositAmount = (
  depositRate: number,
  billedPremiumAmount: number,
  numberPayments: number,
  isNonEarning: boolean,
  allEqual: boolean,
  depositType?: string
) => {
  if (isNonEarning) {
    return calculatedNonEarningBilledDepositAmount(
      depositRate,
      billedPremiumAmount,
      numberPayments,
      depositType
    );
  }
  if (depositType === constants.PERCENT) {
    const baseDepositAmount = customRound(
      (
        billedPremiumAmount * (depositRate === 0 ? 1 : depositRate / 100)
      ).toString(),
      0
    );
    const remainingPremium = billedPremiumAmount - baseDepositAmount;
    const numberOfPayments = numberPayments - 1 === 0 ? 1 : numberPayments - 1;
    const installmentPayment = customRound(
      (remainingPremium / numberOfPayments).toString(),
      0
    );
    const totalAmount = installmentPayment * numberOfPayments;
    const billedDepositAmount = allEqual
      ? baseDepositAmount
      : customRound((billedPremiumAmount - totalAmount).toString(), 0);

    return { installmentPayment, billedDepositAmount, nonEarningAmount: null };
  }
  return {
    installmentPayment: 0,
    billedDepositAmount: depositRate,
    nonEarningAmount: null,
  };
};

export const colWithHorizontalGutterOnly: ColProps = {
  horizontalMargin: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",
  horizontalGutter: "15px",
  horizontalAlign: "flex-start",
};

const getBillingFrequencyByNumberOfPayments = (numberOfPayments: number) => {
  switch (numberOfPayments) {
    case 1:
      return PolicyBillingFrequencyEnum.ANNUAL;
    case 4:
      return PolicyBillingFrequencyEnum.QUARTERLY;
    case 2:
      return PolicyBillingFrequencyEnum.SEMI_ANNUAL;
    default:
      return PolicyBillingFrequencyEnum.MONTHLY;
  }
};

export const getNewAtomValueByBindInstructions = (
  atomValue: GlobalInsuredAtomProperties | null,
  bindInstructionUI?: PolicyBindInstructionUIProps
) => {
  const billingFrequency =
    atomValue?.policyQuoteInformation?.policyQuote?.configuration?.billingFrequencyList.find(
      (billingFrequency) =>
        billingFrequency.value ===
        getBillingFrequencyByNumberOfPayments(
          bindInstructionUI?.numberOfPayments ?? 0
        )
    );

  const bindInstructions = {
    ...atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions,
    billedPremium: bindInstructionUI?.billedPremium ?? null,
    billedFees: bindInstructionUI?.billedFees ?? null,
    nonEarningAmount: bindInstructionUI?.nonEarningAmount ?? null,
    billedDepositAmount: bindInstructionUI?.billedDepositAmount ?? null,
    installmentPaymentAmount:
      bindInstructionUI?.installmentPaymentAmount ?? null,
    billingMethod: bindInstructionUI?.billingMethod ?? null,
    billByLocation: bindInstructionUI?.billByLocation ?? null,
    depositTypeForDepositOverride:
      bindInstructionUI?.depositTypeForDepositOverride ?? null,
    depositRate: bindInstructionUI?.depositRate ?? 1,
  } as PolicyBindInstructionsBlob;

  const newAtomValue = updatePolicyQuoteMultipleTargets(
    atomValue,
    ["billingFrequency", "payPlan", "bindInstructions"],
    [billingFrequency, bindInstructionUI?.payPlan, bindInstructions]
  );

  return newAtomValue;
};

export const getPaymentPlanDtoAsJSON = (
  payPlan: ProgramPayPlanDto
): PolicyPayPlanBlob => ({
  payPlanID: payPlan.payPlanId,
  planName: payPlan.planName,
  planType: payPlan.planType,
  dueOnDay: payPlan.dueOnDay,
  deposit: payPlan.deposit.toString(),
  depositAmount: payPlan.depositAmount,
  depositRate: payPlan.depositAmount,
  depositType: payPlan.depositType,
  numberOfPayment: payPlan.numberOfPayments,
  premiumTranAccountId: payPlan.premiumTranAccountId,
  assessmentTranAccountID: payPlan.assessmentTranAccountID,
  auditDueDays: payPlan.auditDueDays,
  auditLateDays: payPlan.auditLateDays,
  depositOverride: payPlan.depositOverride,
  allEqual: payPlan.allEqual,
  renewalFirstOffset: payPlan.renewalFirstOffset,
});

export const getAtomUpdatedByEndorsementForms = (
  atomValue: GlobalInsuredAtomProperties | null,
  allForms: ProgramEndorsementBlob[]
) => {
  const { requiredForms, optionalForms } =
    getRequiredAndOptionalFormsFromResponse(
      allForms,
      atomValue?.policyQuoteInformation?.policyQuote
    );
  const requiredFormValues = Object.values(
    requiredForms
  ) as ProgramPolicyEndorsementBlob[][];
  const optionalFormValues = Object.values(
    optionalForms
  ) as ProgramPolicyEndorsementBlob[][];

  const requiredFormsFlat = requiredFormValues.flatMap((form) => form);
  const optionalFormsFlat = optionalFormValues.flatMap((form) => form);

  const newAtomValue = updatePolicyQuote(
    atomValue,
    "endorsementForms",
    requiredFormsFlat.concat(optionalFormsFlat)
  );

  return newAtomValue;
};
