import { FC, useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import {
  BaseTable2Properties,
  DeleteRowParameters,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { SelectOptions } from "../../../../dtos/select-options";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { BillingScheduledTransactionsStatusEnum } from "../../../../dtos/billing-scheduled-transactions-status-enum";
import ScheduledTransactionModal, {
  ScheduledTransactionModalConfig,
} from "./TransactionModal/ScheduledTransactionModal";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import ModalAchPayment from "../Activity/PaymentsTab/AchPayment/ModalAchPayment";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { AccountTableDto } from "../../../../dtos/account-table-dto";
import { ProcessorFunctionEnum } from "../../../../dtos/processor-function-enum";

type ScheduledTransactionsTableProps = {
  insuredId: number;
  statusList: SelectOptions[];
  accountTableData?: AccountTableDto | null;
};

const billingScheduledTransactionsTableName =
  "billing_scheduled_transaction_table";

const ScheduledTransactionsTable: FC<ScheduledTransactionsTableProps> = ({
  insuredId,
  statusList,
  accountTableData,
}) => {
  const [modalConfiguration, setModalConfiguration] =
    useState<ScheduledTransactionModalConfig>({
      isOpen: false,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
    });

  const onEditTransaction = (rowData: any) => {
    setModalConfiguration({
      isOpen: true,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
      transactionId: rowData.BillingScheduledTransactionId,
      insuredId: parseInt(rowData.InsuredId),
      rowKey: rowData._row_key,
      rowData: rowData,
    });
  };

  const onSaveSuccessful = () => {
    hooks?.methods?.refreshTable();
  };

  const onDeleteSuccessful = () => {
    if (conditionHasValue(modalConfiguration.transactionId)) {
      const deletedRow: DeleteRowParameters = {
        rowKey: modalConfiguration.rowKey ?? "",
        hydratedData: { ...modalConfiguration.rowData },
        deleteType: "hidden",
      };
      hooks?.methods?.deleteRow(deletedRow);
    }
  };

  const EditIconComponent = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          onEditTransaction(rowData);
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const openModalAchPayment = () => {
    setModalConfiguration({
      isOpen: false,
      isOpenAchPaymentModal:
        accountTableData?.paymentProcessorFunction !==
        ProcessorFunctionEnum.NOT_FOUND,
      isOpenDialogConfirmation:
        accountTableData?.paymentProcessorFunction ===
        ProcessorFunctionEnum.NOT_FOUND,
    });
  };

  const tableConfiguration: BaseTable2Properties = {
    name: billingScheduledTransactionsTableName,
    getURL: `api/BillingTransaction/GetBillingScheduledTransactionByInsuredId?insuredId=${insuredId}`,
    columnOptions: [
      {
        fieldName: "TransactionAmount",
        align: "right",
      },
    ],
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showAddButton: true,
      showImportButton: false,
      showSortFilter: false,
      addButtonText: "NEW SCHEDULE PAYMENT",
    },
    events: {
      addEventOverrideCallback: openModalAchPayment,
    },
    advancedOptions: {
      paginate: false,
      isEditMode: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          EditIconComponent(actionOptions.row),
      },
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          columnsNames: ["StatusId"],
          filterOptions: actionOptions,
          usingExternalFiltering: false,
          labelText: "Status:",
          optionsOverride: statusList.filter(
            (status) => (status?.intValue ?? 0) > 0
          ),
          defaultValue: BillingScheduledTransactionsStatusEnum.PENDING,
        }),
    ],
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const hooks = tableMethods[billingScheduledTransactionsTableName];

  return (
    <div>
      <BaseTable2 name={billingScheduledTransactionsTableName} />
      {modalConfiguration.isOpen && (
        <ScheduledTransactionModal
          configuration={modalConfiguration}
          closeModal={(close) =>
            setModalConfiguration({
              ...modalConfiguration,
              isOpen: close,
              transactionId: null,
              insuredId: null,
            })
          }
          onDeleteSuccessful={onDeleteSuccessful}
          onSaveSuccessful={onSaveSuccessful}
        />
      )}
      {accountTableData && modalConfiguration.isOpenAchPaymentModal && (
        <ModalAchPayment
          insuredId={insuredId}
          showModal={modalConfiguration.isOpenAchPaymentModal}
          isOpenModal={(close) =>
            setModalConfiguration({
              ...modalConfiguration,
              isOpenAchPaymentModal: close,
              transactionId: null,
              insuredId: null,
            })
          }
          onSaveSuccessful={onSaveSuccessful}
          accountTableData={accountTableData}
        />
      )}
      {modalConfiguration.isOpenDialogConfirmation && (
        <DialogConfirmation
          id="ProcessorFunctionConfirmation"
          open={modalConfiguration.isOpenDialogConfirmation}
          dialogDescriptionText="No valid payment processor found."
          onCloseEvent={(close) =>
            setModalConfiguration({
              ...modalConfiguration,
              isOpenDialogConfirmation: close,
            })
          }
          onOptionYesEvent={(close) =>
            setModalConfiguration({
              ...modalConfiguration,
              isOpenDialogConfirmation: close,
            })
          }
          optionYesOverrideLabel={"OK"}
        />
      )}
    </div>
  );
};

export default ScheduledTransactionsTable;
