import { FC, useEffect } from "react";
import { useGridInstance } from "./Hooks/useGridInstance";
import {
  filterResultSetBy_v5,
  sortOrderBy,
} from "./SupportFunctions/baseGridSortAndFilterFunctions";
import { SPECIAL_SORT_ORDER } from "../TrueUI/Tables/TableConstants";
import { useRecoilValue } from "recoil";
import { BaseGridFilterParameters } from "./Hooks/useBaseGridFilters";
import { getColumnIndexesByColumnNames } from "./SupportFunctions/baseGridFunctions";
import { BaseTableColumn } from "../../dtos/base-table-column";
import { FILTER_ALL_RESULTS } from "./BaseGridConstants";

type BaseGridSortAndFilterListenerProperties = {
  uiid?: string;
};
const BaseGridSortAndFilterListener: FC<
  BaseGridSortAndFilterListenerProperties
> = ({ uiid }) => {
  const { instanceSelector, instanceInternalSelector, setInternalInstance } =
    useGridInstance(uiid ?? "", "BaseGridBody");
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());

  const sortAndFilterBy = (
    data: string[][],
    order: any,
    orderBy: any,
    filterValues: BaseGridFilterParameters[],
    triggeredByColumn: boolean
  ) => {
    // "defaultSortOrder" is for the default sort order only. That's why we are using the constant "SPECIAL_SORT_ORDER".
    const defaultSortOrder = triggeredByColumn
      ? undefined
      : filterValues?.find(
          (filter) => filter?.filterName === SPECIAL_SORT_ORDER
        );
    const orderResult = sortOrderBy(
      data,
      defaultSortOrder ? defaultSortOrder?.filterValue : order,
      defaultSortOrder ? defaultSortOrder?.columnIndexes?.[0] : orderBy,
      instance?.columns ?? []
    );

    const searchResult = filterResultSetBy_v5(
      orderResult,
      filterValues,
      instance?.columns ?? []
    );

    return searchResult;
  };

  const updateMissingFilterParameterData = (
    filterParameters: BaseGridFilterParameters[],
    columns: BaseTableColumn[]
  ) => {
    if (filterParameters.length > 0) {
      return filterParameters.map((params) => {
        return {
          ...params,
          columnIndexes: getColumnIndexesByColumnNames(
            params?.columnNames ?? [],
            columns ?? []
          ),
          filterValue: params?.filterValue ?? FILTER_ALL_RESULTS,
          ignore: params.ignore === undefined ? false : params.ignore,
          filterGroupId:
            params?.filterGroupId === undefined ? null : params?.filterGroupId,
        };
      });
    }
    return [];
  };

  useEffect(() => {
    if (instanceInternal.isReadyToRender && (instance?.data ?? []).length > 0) {
      const updatedFilterParams = updateMissingFilterParameterData(
        instanceInternal?.filterParameters ?? [],
        instance?.columns ?? []
      );

      const result = sortAndFilterBy(
        instance?.data ?? [],
        instance?.orderDirection,
        instance?.orderByColumnIndex,
        updatedFilterParams,
        instance?.columnTriggeredSort ?? false
      );

      setInternalInstance({
        ...instanceInternal,
        sortedAndFilteredData: result,
        hasSortedAndFilteredProcessingCompleted: true,
        accessors: {
          ...instanceInternal.accessors,
          _reloadDataSources: false, // Not sure if this is okay - requires more testing
        },
      });
    }
  }, [
    instance?.data,
    instance?.orderDirection,
    instance?.orderByColumnIndex,
    instanceInternal?.filterParameters,
    instanceInternal?.isReadyToRender,
    instanceInternal?.accessors?._reloadDataSources,
    instanceInternal?.hasInitialFilterParametersProcessingCompleted,
  ]);

  return null;
};

export default BaseGridSortAndFilterListener;
