import { FontsType } from "../../../../media/themeTypes";
import { KeysAsType } from "../../../../types/KeysAsAType";
import StyleBox, { StyleBoxPoints } from "../../StyleBox/StyleBox";
import Font from "../../Typography/Font";

type BaseTableTitleDisplayProps = {
  title: string;
  fontType?: KeysAsType<FontsType>;
  width?: string;
  marginLeft?: StyleBoxPoints;
  marginRight?: StyleBoxPoints;
};

const BaseTableTitleDisplay: (props: BaseTableTitleDisplayProps) => any = ({
  title,
  fontType = "HEADING_5",
  width = "auto",
  marginLeft = 5,
  marginRight = 5,
}) => {
  return (
    <div className="base_table_title_table" style={{ width: width }}>
      <StyleBox
        className="base_table_title_table"
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <Font fontType={fontType}>{title}</Font>
      </StyleBox>
    </div>
  );
};

export default BaseTableTitleDisplay;
