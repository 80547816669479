import HierarchyList, {
  ItemProps,
} from "../TrueUI/List/HierarchyList/HierarchyList";

const EmmaSandBox = () => {
  const MockList: ItemProps[] = [
    {
      itemId: 1,
      displayName: "Item 1",
      description: "Description Item 1",
      childrenItems: [
        {
          itemId: 1.1,
          displayName: "Item 1.1",
          description: "Description Item 1.1",
          isChecked: true,
        },
        {
          itemId: 1.2,
          displayName: "Item 1.2",
          description: "Description Item 1.2",
        },
        {
          itemId: 1.3,
          displayName: "Item 1.3",
          description: "Description Item 1.3",
        },
      ],
    },
    {
      itemId: 2,
      displayName: "Item 2",
      description: "Description Item 2",
      isChecked: true,
    },
    { itemId: 3, displayName: "Item 3", description: "Description Item 3" },
    { itemId: 4, displayName: "Item 4", description: "Description Item 4" },
    {
      itemId: 5,
      displayName: "Item 5",
      description: "Description Item 5",
      isChecked: true,
      childrenItems: [
        {
          itemId: 5.1,
          displayName: "Item 5.1",
          description: "Description Item 1.1",
        },
        {
          itemId: 5.2,
          displayName: "Item 5.2",
          description: "Description Item 5.2",
        },
        {
          itemId: 5.3,
          displayName: "Item 5.3",
          description: "Description Item 5.3",
        },
      ],
    },
    {
      itemId: 6,
      displayName: "Item 6",
      description: "Description Item 6",
      isChecked: true,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "15px",
          marginBottom: "10px",
        }}
      >
        <HierarchyList
          items={MockList}
          isCheckboxList
          showTooltip
          onCheckboxChange={(value, isChecked) => console.log(value, isChecked)}
        />
      </div>
    </div>
  );
};

export default EmmaSandBox;
