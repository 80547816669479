import { FC, useEffect, useState } from "react";
import { RelatedInsuredFormProps } from "../RelatedInsuredTypes";
import style from "../RelatedInsuredsStyles.module.css";
import SearchText from "../../../TrueUI/Search/SearchText";
import { Button, Font, FontBold, Select } from "../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../hooks";
import { InsuredRelationshipFormOptionsDto } from "../../../../dtos/insured-relationship-form-options-dto";
import { AddInsuredParentRelationshipDto } from "../../../../dtos/add-insured-parent-relationship-dto";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import FontError from "../../../TrueUI/Typography/FontError";

const RelatedInsuredForm: FC<RelatedInsuredFormProps> = ({
  insuredId,
  insuredRelationshipData,
  setInsuredRelationshipData,
}) => {
  const [errorsDetails, setErrorsDetails] = useState<any>(null);
  const [blockSetParent, setBlockSetParent] = useState<boolean>(false);

  const { responseGet, dispatchGet } =
    useApiGet<InsuredRelationshipFormOptionsDto>(
      `api/InsuredRelationship/GetInsuredRelationshipFormOtions/?insuredId=${insuredId}`
    );

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<AddInsuredParentRelationshipDto>(
      "api/InsuredRelationship/SetAsParentInsuredRelationship",
      insuredRelationshipData
    );

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorsDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setInsuredRelationshipData({
        ...insuredRelationshipData,
        ...responsePost?.responseData,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setBlockSetParent(responseGet?.responseData?.hasChildren ?? true);
    }
  }, [responseGet]);

  useEffect(() => {
    setErrorsDetails(null);
  }, [
    insuredRelationshipData?.parentInsuredId,
    insuredRelationshipData?.relationshipTypeId,
  ]);

  useEffect(() => {
    if (insuredRelationshipData?.onlyRemoveRelationship === true) {
      if (responseGet?.responseData?.hasAParent === true) {
        dispatchPost();
      } else {
        setErrorsDetails({
          currentInsuredIsParent: "This insured has no parent to remove.",
        });
      }
    }
  }, [insuredRelationshipData?.onlyRemoveRelationship]);

  return (
    <>
      <Font whiteSpace={"break-spaces"}>
        You can only add or remove a parent insured to establish a relationship.
        In the field below search for an insured that you would like to set as
        the parent for this relationship. Choose a type and click on the Set as
        Parent button.
      </Font>
      {blockSetParent && (
        <p>
          <FontError>
            This insured already has a relationship as parent for other
            insureds.
          </FontError>
        </p>
      )}

      {errorsDetails?.currentInsuredIsParent && (
        <p>
          <FontError>{errorsDetails?.currentInsuredIsParent}</FontError>
        </p>
      )}
      <div className={style.search_insured_container}>
        <SearchText
          saveSelectedResult={(insuredData) =>
            setInsuredRelationshipData({
              ...insuredRelationshipData,
              parentInsuredId: insuredData?.insuredId ?? null,
            })
          }
          url={"api/QuickSearchInsuredClaim/SearchInsuredText?text="}
          labelText="Search for an Insured"
          searchTextKeys={["insuredCode", "insuredName"]}
          placeholderText={"Search for an insured"}
          labelFontType={"BOLD_CAPTION"}
          value=""
          focus={true}
          minCharacters={4}
          key={insuredId}
          errorMessage={
            errorsDetails?.parentInsuredId ??
            errorsDetails?.parentInsuredHasParent
          }
          readOnly={blockSetParent}
        />
      </div>
      <div className={style.relationship_select_and_button_container}>
        <div className={style.relationship_select__container}>
          <Select
            id="insuredRelationshipTypeId"
            name="insuredRelationshipTypeId"
            label="Relationship"
            options={
              responseGet?.responseData?.relationshipTypeSelectOptions ?? []
            }
            value={insuredRelationshipData?.relationshipTypeId}
            onChange={(insuredRelationshipTypeId) =>
              setInsuredRelationshipData({
                ...insuredRelationshipData,
                relationshipTypeId: insuredRelationshipTypeId,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={blockSetParent}
          />
        </div>
        <div className={style.relationship_button_container}>
          <Button
            onClick={() => {
              dispatchPost();
            }}
            isDisabled={blockSetParent}
          >
            Set as Parent
          </Button>
        </div>
      </div>
      <FontBold fontType={"BOLD_TITLE"}>RELATED INSUREDS</FontBold>
    </>
  );
};

export default RelatedInsuredForm;
