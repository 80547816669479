import { FC, useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import {
  Caption,
  Col,
  Font,
  FontBold,
  Loading,
  Row,
  SmallTitle,
  Switch,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { PolicyQuoteEndorsementFormsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import {
  endorsementFormsRowStyles,
  endorsementStatesRowStyles,
} from "./EndorsementFormStyles";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";

const RequiredForms: FC<PolicyQuoteEndorsementFormsProps> = ({ forms }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [localForms, setLocalForms] = useState<any>();

  useEffect(() => {
    setLocalForms(forms);
  }, [forms]);

  return localForms ? (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        {...colWithNoMarginNorGutter}
        {...getConfigurationForColumn(12, "flex-start", "center")}
        displayFlex={false}
      >
        <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
          <Font>REQUIRED FORMS</Font>
        </Col>
      </Col>
      {localForms &&
        Object.keys(localForms)
          .sort()
          .map((key) => (
            <Box
              sx={endorsementStatesRowStyles(theme)}
              key={`required-states-box-${key}`}
            >
              <Col
                key={`required-forms-col-${key}`}
                horizontalGutter="5px"
                {...getConfigurationForColumn(12, "flex-start", "center")}
                displayFlex={false}
              >
                <Link variant="subtitle2">
                  <FontBold>
                    {localForms?.[key]?.[0]?.stateName?.toUpperCase() ?? ""}
                  </FontBold>
                </Link>
                {localForms?.[key]?.map((form: ProgramEndorsementBlob) => (
                  <Box
                    sx={endorsementFormsRowStyles()}
                    key={`required-forms-box-${form.endorsementFormID}`}
                  >
                    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
                      <Col
                        {...colWithNoMarginNorGutter}
                        {...getConfigurationForColumn(
                          5,
                          "flex-start",
                          "center"
                        )}
                      >
                        <Switch
                          control="checkbox"
                          label={`${form.formNumber}`}
                          labelPlacement={"end"}
                          isChecked={true}
                          primaryLabelColor
                          readOnly
                          labelFontType={"BODY"}
                          inputWidth="150px"
                        />
                        <SmallTitle>
                          {form.formName}{" "}
                          <Caption>({FormattingDate(form.formDate)})</Caption>
                        </SmallTitle>
                      </Col>
                    </Row>
                  </Box>
                ))}
              </Col>
            </Box>
          ))}
    </Row>
  ) : (
    <Loading isLoading={true} />
  );
};

export default RequiredForms;
