import { FC, useEffect, useState } from "react";
import StatementsBatchTableWrapper from "./StatementsBatchTableWrapper";
import { useApiGet } from "../../../hooks";
import { StatementBatchConfigurationDto } from "../../../dtos/statement-batch-configuration-dto";
import {
  RenderTableContentConfig,
  StatementTableType,
  defaultRenderTableValues,
} from "./StatementsUtil";
import StatementsDetailsTableWrapper from "./StatementsDetailsTableWrapper";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

const StatementsTabContent: FC = () => {
  const { responseGet, dispatchGet } =
    useApiGet<StatementBatchConfigurationDto>(
      "api/StatementBatch/GetStatementBatchConfiguration"
    );

  const [statementsTableOptions, setStatementsTableOptions] =
    useState<StatementBatchConfigurationDto | null>();

  const [renderTableConf, setRenderTableConf] =
    useState<RenderTableContentConfig>(defaultRenderTableValues);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setStatementsTableOptions(responseGet.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (statementsTableOptions !== undefined) {
      setRenderTableConf({
        ...renderTableConf,
        tableToRender: StatementTableType.STATEMENT_BATCH,
      });
    }
  }, [statementsTableOptions]);

  const renderTableContent = () => {
    switch (renderTableConf.tableToRender) {
      case StatementTableType.STATEMENT_BATCH:
        return (
          <StatementsBatchTableWrapper
            statementTypeOptions={statementsTableOptions?.statementTypes ?? []}
            statementYearsOptions={statementsTableOptions?.years ?? []}
            setRenderTableConf={setRenderTableConf}
          />
        );
      case StatementTableType.STATEMENT_DETAIL:
        return (
          <StatementsDetailsTableWrapper
            statementBatchId={renderTableConf.statementBatchId}
            statementDate={renderTableConf.statementDate}
            onClickReturnToBatchTable={() =>
              setRenderTableConf({
                ...renderTableConf,
                tableToRender: StatementTableType.STATEMENT_BATCH,
              })
            }
          />
        );
      default:
        return null;
    }
  };
  return renderTableContent();
};

export default StatementsTabContent;
