import { Box, FormControlLabel } from "@mui/material";
import Switch from "../TrueUI/Checkbox/Switch";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useApiAuthenticate, useApiGet, useApiPost } from "../../hooks";
import {
  SaveButton,
  Button,
  Input,
  InputPhone,
  Row,
  Col,
  Select,
  TabPanel,
  Tabs,
  Heading4,
} from "../TrueUI";
import InputColorPicker from "../TrueUI/Inputs/InputColorPicker";
import { globalOptions } from "../../GlobalAtoms";
import { PreferencesPage } from "../../dtos/preferences-page";
import { InitOptionsDto } from "../../dtos/init-options-dto";
import CancelButton from "../TrueUI/Buttons/CancelButton";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import SubscriptionsGrid from "./Subscriptions/SubscriptionsGrid";
import style from "./UserPreferences.module.css";
import { timeZoneOptions } from "../../utilities/staticDataFunctions";

const rowProps: any = {
  verticalAlign: "flex-start",
  horizontalAlign: "flex-start",
  horizontalMargin: "0px",
  horizontalGutter: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",
  allowWrap: true,
};

const colProps: any = {
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
  verticalAlignSelf: "stretch",
};

const UserPreferences = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const [localUserPreferences, setLocalUserPreferences] =
    useState<PreferencesPage>();

  const [localOptions, setGlobalOptions] =
    useRecoilState<InitOptionsDto>(globalOptions);

  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { responseGet, dispatchGet } = useApiGet<PreferencesPage>(
    `api/preferences/getpreferencespage`
  );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/preferences/savepreferencespage",
    localUserPreferences
  );

  const [, dispatchProgramAuthentication] = useApiAuthenticate(
    `api/authentication/authenticateUser`,
    {
      tenantId: localOptions?.tenantId,
      programUserId: localOptions?.selectedProgram?.programUserId,
      programId: localOptions?.selectedProgram?.programId,
      userId: localOptions?.userId,
      timeZoneId: localUserPreferences?.timeZoneID,
      isInternalUser: localOptions?.isInternalUser,
    }
  );

  const onChangeDarkMode = (e) => {
    const selectedTheme = e.target.checked ? "darkTheme" : "defaultTheme";
    setLocalUserPreferences({
      ...localUserPreferences,
      theme: selectedTheme,
    });
    setGlobalOptions({
      ...localOptions,
      themeRefresh: selectedTheme,
    });
  };

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful) {
      setLocalUserPreferences(responseGet.axiosResponse?.data);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setErrorDetails(undefined);
      setGlobalOptions({
        ...localOptions,
        firstName: localUserPreferences?.firstName ?? "NO_FIRST_NAME_FOUND",
        lastName: localUserPreferences?.lastName ?? "NO_LAST_NAME_FOUND",
        avatarColor:
          localUserPreferences?.avatarColor ?? "NO_AVATAR_COLOR_FOUND",
        backgroundColor:
          localUserPreferences?.backgroundColor ?? "NO_BACKGROUND_COLOR_FOUND",
        themeRefresh: localUserPreferences?.theme ?? "defaultTheme",
        timeZoneID: localUserPreferences?.timeZoneID ?? "",
      });
      dispatchProgramAuthentication();
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    dispatchGet();
  }, []);

  return localUserPreferences ? (
    <div className={style.user_tabs_container}>
      <div className={style.user_preferences_title_container}>
        <Heading4>Preferences</Heading4>
      </div>
      <Tabs
        name={"userPreferencesTabs"}
        selected={currentTab}
        onSelect={(e) => setCurrentTab(e.selected)}
        stateControlled
        disableQueryString
      >
        <TabPanel title="User Preferences" key={"user-preferences-tab"}>
          <div className={style.user_preferences_form_container}>
            <div className={"form-container"}>
              <Row {...rowProps}>
                <Col {...colProps} verticalMargin="10px">
                  <Button
                    sx={{ borderRadius: "50px" }}
                    className={"change-password-button"}
                    name={"changePasswordButton"}
                    onClick={() =>
                      alert(
                        "No FIGMA screens for this button action as of 3/23/2022 - see Colin."
                      )
                    }
                  >
                    CHANGE PASSWORD
                  </Button>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps}>
                  <Input
                    id="id-preferences-first-name"
                    name="namePreferencesFirstNameInput"
                    label="First Name"
                    value={localUserPreferences?.firstName ?? ""}
                    maxLength={100}
                    onChangeRawValue={(value) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        firstName: value,
                      })
                    }
                    errorMessage={errorDetails?.firstName}
                  />
                </Col>
                <Col {...colProps}>
                  <Input
                    id="id-preferences-last-name"
                    name="namePreferencesLastNameInput"
                    label="Last Name"
                    value={localUserPreferences?.lastName ?? ""}
                    maxLength={100}
                    onChangeRawValue={(value) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        lastName: value,
                      })
                    }
                    errorMessage={errorDetails?.lastName}
                  />
                </Col>
                <Col
                  {...colProps}
                  breakpoints={{ xs: 6, sm: 2, md: 2, lg: 2, xl: 2 }}
                >
                  <div className={style.preferences_colors_container}>
                    <Box
                      true-element="true-preferences-initials"
                      className={style.i_container}
                      sx={{
                        color: localUserPreferences.avatarColor,
                        backgroundColor: localUserPreferences.backgroundColor,
                      }}
                    >
                      {localOptions?.initials ?? "N/A"}
                    </Box>
                    <div>
                      <InputColorPicker
                        id="user-preferences-color-picker"
                        onRawChange={(value) => {
                          setLocalUserPreferences({
                            ...localUserPreferences,
                            avatarColor: value?.textColor,
                            backgroundColor: value?.backgroundColor,
                          });
                        }}
                        value={localUserPreferences?.backgroundColor}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col
                  {...colProps}
                  breakpoints={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
                >
                  <InputPhone
                    id="id-preferences-phone-number"
                    name="namePreferencesPhoneNumberInputPhone"
                    phoneLabel="Phone Number"
                    phoneValue={localUserPreferences?.phoneNumber ?? ""}
                    errorMessagePhone={errorDetails?.phoneNumber}
                    onChangePhoneValue={(phone) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        phoneNumber: phone,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col
                  {...colProps}
                  breakpoints={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
                >
                  <InputPhone
                    id="id-preferences-mobile-phone-number"
                    name="namePreferencesMobilePhoneNumberInputPhone"
                    phoneLabel="Mobile Phone"
                    phoneValue={localUserPreferences?.mobilePhoneNumber ?? ""}
                    errorMessagePhone={errorDetails?.mobilePhoneNumber}
                    onChangePhoneValue={(phone) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        mobilePhoneNumber: phone,
                      })
                    }
                  />
                </Col>
                <Col {...colProps}>
                  <div className={style.accept_sms_container}>
                    <Switch
                      label="Accept SMS?"
                      labelPlacement={"start"}
                      control="checkbox"
                      name="acceptSmsSwitch"
                      className="accept-sms"
                      isChecked={localUserPreferences?.hasSms ?? false}
                      onChange={(e) =>
                        setLocalUserPreferences({
                          ...localUserPreferences,
                          hasSms: e.target.checked,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col
                  {...colProps}
                  verticalAlignSelf="flex-start"
                  breakpoints={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
                >
                  <Input
                    id="id-preferences-number-of-recent"
                    name="namePreferencesNumberOfRecentInput"
                    label="Number of Recent"
                    value={localUserPreferences?.numberOfRecents ?? ""}
                    type="number"
                    maxNumericValue={100}
                    onChange={(e) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        numberOfRecents: e.target.value,
                      })
                    }
                    errorMessage={errorDetails?.numberOfRecents}
                    decimalScale={0}
                  />
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps}>
                  <FormControlLabel
                    control={
                      <Switch
                        control="switch"
                        name="darkModeSwitch"
                        isChecked={localOptions?.themeRefresh === "darkTheme"}
                        onChange={onChangeDarkMode}
                      />
                    }
                    label="Dark Mode"
                    labelPlacement="start"
                    sx={{ marginLeft: 0 }}
                  />
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col
                  {...colProps}
                  breakpoints={{ xs: 7, sm: 7, md: 7, lg: 7, xl: 7 }}
                >
                  <Select
                    name="preferencesTimeZoneSelect"
                    id="selTimeZone"
                    value={localUserPreferences?.timeZoneID}
                    onChange={(value) =>
                      setLocalUserPreferences({
                        ...localUserPreferences,
                        timeZoneID: value,
                      })
                    }
                    label="Time Zone"
                    options={timeZoneOptions}
                  />
                </Col>
              </Row>
            </div>
            <div className={style.buttons_container}>
              <SaveButton
                name={"savePreferencesButton"}
                onClick={() => dispatchPost()}
              >
                SAVE
              </SaveButton>
              <CancelButton />
            </div>
          </div>
        </TabPanel>
        <TabPanel title="Subscriptions" key={"subscriptions-tab"}>
          <SubscriptionsGrid />
        </TabPanel>
        <TabPanel title="Teams" key={"teams-tab"}>
          <>Placeholder for Teams</>
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <>LOADING...</>
  );
};

export default UserPreferences;
