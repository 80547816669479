export enum COLUMN_EXTENSION_COMPARE_IDENTIFIER {
  COLUMN_EXT_REMOVE_EMPTY_RESULT = "column_ext_remove_empty_result",
  COLUMN_EXT_SEARCH = "column_ext_search",
  COLUMN_EXT_BETWEEN = "column_ext_between",
  COLUMN_EXT_GREATER_THAN = "column_ext_greater_than",
  COLUMN_EXT_LESS_THAN = "column_ext_less_than",
  COLUMN_EXT_EQUALS = "column_ext_equals",
  COLUMN_EXT_CONTAINS = "column_ext_contains",
  COLUMN_EXT_BEGINS_WITH = "column_ext_begins_with",
  COLUMN_EXT_ENDS_WITH = "column_ext_ends_with",
}

// I know below aren't enums but I wanted to keep these together just so I don't have to page hop around to find some of these and this should make it easier to maintain since they're all related.

/////////////////////////////////////////////////////////////////////

export type COLUMN_EXTENSION_COMPARE_IDENTIFIER_NULLABLE_UNION =
  | `${COLUMN_EXTENSION_COMPARE_IDENTIFIER}`
  | null;

export const COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY = Object.values(
  COLUMN_EXTENSION_COMPARE_IDENTIFIER
);

export const COLUMN_EXTENSION_COMPARE_IDENTIFIER_NULLABLE_ARRAY = [
  ...Object.values(COLUMN_EXTENSION_COMPARE_IDENTIFIER),
  null,
];

/////////////////////////////////////////////////////////////////////
