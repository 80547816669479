import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { StatusEnums } from "../../../dtos/status-enums";
import { BaseTable2 } from "../../TrueUI";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { useSetRecoilState } from "recoil";
import { RefreshInsuredPageAtom } from "../InsuredAtoms";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

const tableName = "insuredInformationOfficers";

const INSURED_INFORMATION_API_PATH = "api/InsuredInformation";

type InsuredInformationOfficersProps = {
  insuredId: number;
};

const InsuredInformationOfficers: FC<InsuredInformationOfficersProps> = ({
  insuredId,
}) => {
  const { hasPermission } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);

  const setRefreshPage = useSetRecoilState(RefreshInsuredPageAtom);
  const actionsForRows = (
    _actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            //TODO: Add logic to save
            //actionOptions.actionEvents.saveTable()
            alert("Save Icon clicked");
          }}
        >
          <SaveIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            //TODO: Add logic to delete
            //actionOptions.actionEvents.refreshTable();
            alert("Delete Icon clicked");
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `${INSURED_INFORMATION_API_PATH}/GetInsuredInformationOfficers?insuredId=${insuredId}`,
    postURL: `${INSURED_INFORMATION_API_PATH}/SaveInsuredInformationOfficers`,
    postTarget: "changedInsuredInformationOfficers",
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["OfficerStatus"],
          defaultValue: StatusEnums.ACTIVE.toString(),
        }),
    ],
    advancedOptions: {
      overrideColumnDefaultValues: [
        {
          columnFieldName: "InsuredId",
          propName: "defaultValue",
          value: insuredId,
        },
      ],
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      optionsColumnConfiguration: {
        isHidden: true,
        optionType: "component",
      },
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      addButtonIsDisabled: !hasPermission,
      editButtonIsDisabled: !hasPermission,
    },
    events: {
      onDataToBeSavedChange: (dataToBeSaved) => {
        if (dataToBeSaved.length === 0) {
          setRefreshPage({ refreshTabs: true });
        }
      },
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={tableName} />;
};

export default InsuredInformationOfficers;
