import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { FontBold } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  PolicyExposurePremiumFooterUIProps,
  PolicyQuoteInsuredAsProp,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getTotalPayrollSurchargesAndFeesAndPremium } from "./ExposurePremiumUtils";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { updatePolicyQuoteMultiTargetAndQuoteMultiTarget } from "../updatesPolicyQuoteFunctions";
import style from "../PolicyQuoteForm/PolicyQuote.module.css";

const ExposurePremiumFooter: FC<PolicyQuoteInsuredAsProp> = ({ tabKey }) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [footerUI, setFooterUI] =
    useState<PolicyExposurePremiumFooterUIProps>();

  const listenerStateHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("exposurePremiumFooterComponent")
  );

  useEffect(() => {
    const atomValue = getAtom();
    const calculations = getTotalPayrollSurchargesAndFeesAndPremium(
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.states ?? []
    );

    const newAtomValue = updatePolicyQuoteMultiTargetAndQuoteMultiTarget(
      atomValue,
      ["totalPremium", "totalFees", "policyTotal"],
      [
        calculations.totalPremium,
        calculations.totalSurchargesAndFees,
        calculations.totalPremium + calculations.totalSurchargesAndFees,
      ],
      ["estimatedAnnualPremium", "totalFees"],
      [calculations.totalPremium, calculations.totalSurchargesAndFees]
    );

    setAtom(newAtomValue);
    setFooterUI({
      payroll: calculations.totalPayroll.toLocaleString(),
      surchargesAndFees: calculations.totalSurchargesAndFees.toLocaleString(),
      premium: calculations.totalPremium.toLocaleString(),
    });
  }, [listenerStateHeaderComponent]);

  return (
    <div className={style.exposure_and_premium_footer_container}>
      <div className={style.footer_header_description_container}>
        <FontBold>Policy Total</FontBold>
      </div>
      <div className={style.footer_header_calculation_label_container}>
        <FontBold>PAYROLL:</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <FontBold name={"policy-footer-payroll"}>
          {footerUI?.payroll ?? ""}
        </FontBold>
      </div>
      <div
        className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
      >
        <FontBold>SURCHARGES AND FEES:</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <FontBold name={"policy-footer-surcharges-fees"}>
          {footerUI?.surchargesAndFees ?? ""}
        </FontBold>
      </div>
      <div
        className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
      >
        <FontBold>PREMIUM:</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <FontBold name={"policy-footer-premium"}>
          {footerUI?.premium ?? ""}
        </FontBold>
      </div>
    </div>
  );
};

export default ExposurePremiumFooter;
