import { FC, useEffect, useState } from "react";
import CoveragesTable from "./CoveragesTable";
import ModalAgencyCoverage from "./ModalAgencyCoverage";
import { AgencyCoverageDto } from "../../../dtos/agency-coverage-dto";
import { useApiPost } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

export type AgencyCoverageModalProps = {
  isOpen?: boolean;
  isSaving?: boolean;
  isDeleting?: boolean;
  agencyCoverage?: AgencyCoverageDto | null;
  refreshTable?: boolean;
};

type CoveragesProps = {
  agencyId?: string;
  isNewAgency: boolean;
};

const Coverages: FC<CoveragesProps> = ({ agencyId, isNewAgency }) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [coverageModalValues, setCoverageModalValues] =
    useState<AgencyCoverageModalProps | null>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/Coverage/InsertUpdateAgencyCoverage",
    coverageModalValues?.agencyCoverage
  );

  useEffect(() => {
    if (coverageModalValues?.isSaving) {
      dispatchPost();
      setCoverageModalValues({
        ...coverageModalValues,
        isSaving: false,
      });
    }
  }, [coverageModalValues?.isSaving]);

  useEffect(() => {
    if (coverageModalValues?.isDeleting) {
      dispatchPost();
      setCoverageModalValues({
        ...coverageModalValues,
        isDeleting: false,
      });
    }
  }, [coverageModalValues?.isDeleting]);

  useEffect(() => {
    if (coverageModalValues?.refreshTable) {
      setCoverageModalValues({
        ...coverageModalValues,
        refreshTable: false,
      });
    }
  }, [coverageModalValues?.refreshTable]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setCoverageModalValues({
        ...coverageModalValues,
        refreshTable: true,
        isOpen: false,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (!coverageModalValues?.isOpen) {
      setErrorDetails(null);
    }
  }, [coverageModalValues?.isOpen]);

  return (
    <>
      {!(coverageModalValues?.refreshTable ?? false) && (
        <CoveragesTable
          agencyId={agencyId}
          isNewAgency={isNewAgency}
          coverageModalValues={coverageModalValues}
          setCoverageModalValues={setCoverageModalValues}
        />
      )}
      {coverageModalValues?.isOpen && (
        <ModalAgencyCoverage
          errorDetails={errorDetails}
          coverageModalValues={coverageModalValues}
          setCoverageModalValues={setCoverageModalValues}
        />
      )}
    </>
  );
};

export default Coverages;
