import { useEffect, useState } from "react";
import { AssigneeTypeEnum } from "../../dtos/assignee-type-enum";
import { UnderwritingPage } from "../../dtos/underwriting-page";
import { globalOptions } from "../../GlobalAtoms";
import { useApiGet } from "../../hooks";
import { useAtomFamily } from "../../hooks/useAtomFamily";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { Heading4 } from "../TrueUI";
import TaskBoard, { SelectedCardType } from "../TrueUI/TaskBoard/TaskBoard";
import style from "./underwriting.module.css";
import UnderwritingAggregateTable from "./UnderwritingAggregateTable";
import ModalNewSubmission from "../Dashboard/QuickActions/ModalNewSubmission";

const Underwriting = () => {
  const [cardInformation, setCardInformation] = useState<SelectedCardType>();
  const { getAtom } = useAtomFamily(globalOptions);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { responseGet, dispatchGet } = useApiGet<UnderwritingPage>(
    `api/Underwriting/GetUnderwritingCards`
  );

  const onClickCard = (cardInformation: any) => {
    setCardInformation(cardInformation);
  };

  const currentUserId = getAtom().userId;

  const getAssignedType = () => {
    const assignedInfo = cardInformation?.cardInformation?.cardMetaData?.find(
      (metadata) => metadata?.name === "AssignedType"
    );
    switch (parseInt(assignedInfo?.value ?? "")) {
      case AssigneeTypeEnum.USER:
        return "UserId";
      case AssigneeTypeEnum?.TEAM:
        return "TeamId";
      default:
        return "UserTeamID";
    }
  };

  const getAssignedId = () => {
    const cardInfo = cardInformation?.cardInformation?.cardMetaData?.find(
      (metadata) => metadata?.name === getAssignedType()
    );
    return cardInfo?.value !== ""
      ? parseInt(cardInfo?.value ?? currentUserId)
      : cardInfo?.value;
  };

  const InternalComponent = () => {
    return (
      <UnderwritingAggregateTable
        status={cardInformation?.cardInformation?.cardType ?? 0}
        assignedId={getAssignedId()}
        filterColumns={[getAssignedType()]}
        // Because there is a problem in the table filters render
        key={crypto.randomUUID()}
      />
    );
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  const columns = responseGet?.axiosResponse?.data?.columns;

  return (
    <>
      {conditionHasValue(columns) && (
        <div className={style.underwriting_container}>
          <Heading4>Underwriting</Heading4>
          <div className={style.underwriting_task_board_container}>
            <TaskBoard
              columns={columns as any}
              onClickCard={onClickCard}
              InternalComponent={InternalComponent}
              columnButtonText={"New Quote"}
              onColumnButtonClick={() => setModalOpen(true)}
            />
          </div>
        </div>
      )}
      <ModalNewSubmission
        showModal={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default Underwriting;
