import { FC, useEffect, useState } from "react";
import {
  calculatedResultForDiscountEditableTable,
  getInitialRowResult,
  getNameToExposureTableColumns,
  getStatesUpdatedByRowResult,
  sumRunningTotalBySpecificPremiumBasis,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import Font from "../../../../../TrueUI/Typography/Font";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import { DiscountDto } from "../../../../../../dtos/discount-dto";
import { QuoteRatingTypeEnum } from "../../../../../../dtos/quote-rating-type-enum";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import { getNumberAsStringWithComas } from "../../../../../../utilities/stringFunctions";
import { useRecoilValue } from "recoil";
import {
  DISCOUNT_EDITABLE_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import { Input } from "../../../../../TrueUI";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

const getRunningTotalOfDefaultRow = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  isRateEdited: boolean,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const standardPremium = sumRunningTotalBySpecificPremiumBasis(
    QuoteRatingTypeEnum.STANDARD_PREMIUM,
    atomValue
  );
  return calculatedResultForDiscountEditableTable(
    stateCode,
    effectiveDate,
    expirationDate,
    rate,
    isRateEdited,
    rateIndex,
    standardPremium,
    rating,
    discountRates,
    atomValue
  );
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  isRateEdited: boolean,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const calculationResults = getRunningTotalOfDefaultRow(
    stateCode,
    effectiveDate,
    expirationDate,
    rate,
    isRateEdited,
    rateIndex,
    rating,
    discountRates,
    atomValue
  );
  const statesUpdatedByCalculations = getStatesUpdatedByRowResult(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    atomValue
  );
  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const DiscountEditableRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const [isRateEdited, setIsRateEdited] = useState<boolean>(false);
  const [discountRates, setDiscountRates] = useState<DiscountDto[] | null>();
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const setRate = (value: number) => {
    setIsRateEdited(true);
    setRowResult({ ...rowResult, rate: value ?? 0 });
  };

  const listenerDiscountRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${DISCOUNT_EDITABLE_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  const runCalculations = () => {
    if (discountRates) {
      const atomValue = getAtom();
      const updatedValues = getStatesUpdatedByCalculations(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        rowResult.rate,
        isRateEdited,
        props.rateIndex,
        props.rating,
        discountRates ?? [],
        atomValue
      );

      setAtom(updatedValues.newAtomValue);
      setRowResult(updatedValues.rowResult);
      props.calculationsFinished?.();
    }
  };

  useEffect(() => {
    if (!props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [discountRates, rowResult.rate]);

  useEffect(() => {
    if (listenerDiscountRowComponent !== null && !props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerDiscountRowComponent]);

  useEffect(() => {
    if (conditionHasValue(props.rating.rate)) {
      setIsRateEdited(true);
    }

    const discountList =
      props.configurations?.discountList?.filter(
        (discount) => discount.discountTableName === props.rating.sourceName
      ) ?? [];
    setDiscountRates(discountList);
  }, []);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_5}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div className={style.premium_row_rate_cell}>
        <Input
          prefix=""
          value={rowResult.rate}
          maxNumericValue={99.999}
          inputWidth="80px"
          inputFontType="BODY"
          allowNegatives={true}
          variant={"filled"}
          type={"fixedCurrency"}
          onChangeRawValue={setRate}
          id={`${props.nameElement}`}
          name={`${props.nameElement}`}
          trueElement={`exposure-table-${props?.nameElement}-rate`}
          readOnly={props.readOnly}
          align="center"
          decimalScale={3}
        />
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default DiscountEditableRow;
