import { FC, useState } from "react";
import { Grid, Link, IconButton, Box } from "@mui/material";
import { InsuredDto } from "../../../dtos/insured-dto";
import {
  FormattingDate,
  getTimeUntilNow,
} from "../../../utilities/dateFunctions";
import {
  einFormat,
  einMask,
  phoneMask,
  ssnFormat,
  ssnMask,
} from "../../../utilities/stringFunctions";
import { Font, Input } from "../../TrueUI";
import EyeBallIcon from "@mui/icons-material/VisibilityOutlined";
import EyeBallOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import { usePermissions } from "../../../hooks";
import {
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_INFORMATION_TABS,
  INSURED_PARENT_ITEM,
} from "../../../utilities/queryStringsHash";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import InformationContainer from "../../TrueUI/Containers/InformationContainer";
import { Item } from "../InsuredLandingStyles";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type InsuredInfoProps = {
  insuredInformation: InsuredDto | undefined;
};

const getTaxId = (insuredInformation: InsuredDto | undefined) =>
  insuredInformation?.ssn !== null &&
  insuredInformation?.ssn !== undefined &&
  insuredInformation?.ssn !== ""
    ? ssnFormat(insuredInformation?.ssn)
    : einFormat(insuredInformation?.ein);

const getMaskTaxId = (insuredInformation: InsuredDto | undefined) =>
  insuredInformation?.ssn !== null &&
  insuredInformation?.ssn !== undefined &&
  insuredInformation?.ssn !== ""
    ? ssnMask(insuredInformation?.ssn ?? "")
    : einMask(insuredInformation?.ein ?? "");

const Insured: FC<InsuredInfoProps> = ({ insuredInformation }) => {
  const [showTaxId, setShowTaxId] = useState(false);
  const { hasPermission } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);
  const addressInfo = insuredInformation?.addressInformation;

  return (
    <InformationContainer
      title={
        <Link
          true-element="true-element-link-insuredTitle"
          href={getQueryStringsURL([
            {
              nameOfHash: INSURED_PARENT_ITEM,
              valueOfHash: SubSideNavParentsIndexEnum.INSURED,
            },
            {
              nameOfHash: INSURED_CHILD_ITEM,
              valueOfHash: SubSideNavChildrenIndexEnum.INSURED_INFORMATION,
            },
            {
              nameOfHash: INSURED_BODY_SECTION,
              valueOfHash: SubSideNavItemDestinationsEnum.INFORMATION,
            },
            {
              nameOfHash: INSURED_INFORMATION_TABS,
              valueOfHash: "0",
            },
          ])}
        >
          Insured
        </Link>
      }
    >
      <Grid container className="insured-grid">
        <Grid item xs={12} lg={4}>
          <Item className="insured-info-item">
            <Input
              id="insuredAddress"
              name="insuredAddress"
              readOnly
              value={addressInfo?.address}
            />
          </Item>
          {addressInfo?.address2 && (
            <Item className="insured-info-item">
              <Input
                id="insuredAddress2"
                name="insuredAddress2"
                readOnly
                value={addressInfo?.address2}
              />
            </Item>
          )}
          <Item className="insured-info-item">
            <Input
              id="insuredLocation"
              name="insuredLocation"
              readOnly
              value={`${addressInfo?.city}, ${addressInfo?.state} ${addressInfo?.postCode}`}
            />
          </Item>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Item>
            <Font name="insuredPhone">
              Phone - {phoneMask(insuredInformation?.phone ?? "")}
            </Font>
            {insuredInformation?.phoneAdd && (
              <Font name="insuredPhoneExt">
                {" "}
                {`Ext - ${insuredInformation?.phoneAdd}`}
              </Font>
            )}
          </Item>
          <Item>
            <Box className="insured-tax-id-container">
              <Font name="insuredTaxId">
                {`Tax ID - ${
                  showTaxId
                    ? getTaxId(insuredInformation)
                    : getMaskTaxId(insuredInformation)
                }`}
              </Font>
              {hasPermission && getTaxId(insuredInformation) !== "" && (
                <IconButton
                  true-element="true-element-icon-button-showTaxId"
                  className="insured-tax-id-show-button"
                  onClick={() => setShowTaxId(!showTaxId)}
                >
                  {showTaxId ? <EyeBallOffIcon /> : <EyeBallIcon />}
                </IconButton>
              )}
            </Box>
          </Item>
          <Item>
            <Font name="insuredFirstPolicyDate">
              {`Insured since -
            ${FormattingDate(
              insuredInformation?.firstPolicyDate
            )} (${getTimeUntilNow(
                insuredInformation?.firstPolicyDate,
                "years"
              )} years)`}
            </Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
      </Grid>
    </InformationContainer>
  );
};

export default Insured;
