import { FC, useEffect } from "react";
import {
  INSURED_ATOM_KEY,
  POLICY_CANCELLATION_ID,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import {
  getQueryValue,
  addQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import PolicyCancellationTable from "./PolicyCancellationTable";

type PolicyCancellationWrapperProps = {
  insuredId: number;
  tabKey: string;
};

const PolicyCancellationWrapper: FC<PolicyCancellationWrapperProps> = ({
  insuredId,
  tabKey,
}) => {
  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );

  const getSectionId = () => {
    if (getQueryValue(POLICY_CANCELLATION_ID) !== "") {
      return parseInt(getQueryValue(POLICY_CANCELLATION_ID));
    }
    return 1;
  };

  const GetSection = () => {
    switch (getSectionId()) {
      case 1:
        return <PolicyCancellationTable insuredId={insuredId} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const sectionId = getSectionId();
    addQueryStrings([
      { nameOfHash: POLICY_CANCELLATION_ID, valueOfHash: sectionId },
    ]);

    setAtom({
      ...getAtom(),
      insuredBodySection: SubSideNavItemDestinationsEnum.POLICY_CANCELLATION,
    });
  }, []);

  return GetSection();
};

export default PolicyCancellationWrapper;
