import { Modal } from "../../../../TrueUI";
import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost, useFormRequest } from "../../../../../hooks";
import { CertificateDto } from "../../../../../dtos/certificate-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import IssueCertificateModalContent from "./IssueCertificateModalContent";
import {
  defaultCertificateData,
  IssueCertificateModalProps,
} from "./IssueCertificateUtils";
import { CertificateConfigurationDto } from "../../../../../dtos/certificate-configuration-dto";
import { ButtonWithConfirmationProps } from "../../../../TrueUI/Buttons/ButtonWithConfirmation";
import { CertificateStatusEnums } from "../../../../../dtos/certificate-status-enums";
import { SelectOptions } from "../../../../../dtos/select-options";
import { AllCertificateJSON } from "../../../../../dtos/all-certificate-json";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { isEmptyValue } from "../../../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../../../dtos/form-type-enum";

type CertificateInformation = {
  certificateConfig: CertificateConfigurationDto | null;
  printCertificateConfig: AllCertificateJSON | null;
  dialogConfiguration: DialogConfirmationProps | null;
};

const IssueCertificateModal: FC<IssueCertificateModalProps> = ({
  configuration: { isOpen, insuredId, mode, editCertificateId },
  closeModal,
  onSaveSuccessful,
  onDeleteSuccessful,
  onReissue,
}) => {
  const [certificateInformation, setCertificateInformation] =
    useState<CertificateInformation>({
      certificateConfig: null,
      printCertificateConfig: null,
      dialogConfiguration: null,
    });
  const [data, setData] = useState<CertificateDto | null>(
    defaultCertificateData(insuredId ?? 0)
  );
  const [errorDetails, setErrorDetails] = useState<any>();
  const [defaultCertificateStatus, setDefaultCertificateStatus] = useState<
    number | null
  >();

  const { responseGet: responsePrint, dispatchGet: dispatchPrint } =
    useApiGet<AllCertificateJSON>(
      `api/Certificate/GetCertificateJSON?certificateId=${data?.certificateId}`
    );
  const { sendMergeFormRequest } = useFormRequest();

  const { responseGet, dispatchGet } = useApiGet(
    `api/Certificate/GetCertificateConfiguration?insuredId=${insuredId}`
  );

  const { responsePost, validatorErrorResponse, dispatchPost } =
    useApiPost<CertificateDto>(`api/Certificate/SaveCertificate`, data);

  const getDefaultPolicyId = (policyOptions?: SelectOptions[] | null) =>
    policyOptions?.find((x) => x.isDefault)?.intValue ?? -1;

  const updateDefaultValues = (config: CertificateConfigurationDto | null) => {
    // Since the defaultValue in the Select component isn't triggering the onChange event, a manual update is required
    const defaultIssuedName = config?.issueNameOptions?.[0]?.displayName;
    const defaultPolicyId = getDefaultPolicyId(config?.policyOptions);
    setData({
      ...data,
      issueName: defaultIssuedName,
      policyId: defaultPolicyId,
    });
  };

  const printCertificate = () => {
    if (
      certificateInformation.printCertificateConfig !== null &&
      !isEmptyValue(certificateInformation.printCertificateConfig?.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.CERTIFICATE_FORM,
        jsonObjectWithMergeFields:
          certificateInformation.printCertificateConfig,
        templateNames: [
          certificateInformation.printCertificateConfig?.templateName ?? "",
        ],
        customFileName: `Certificate ${certificateInformation.printCertificateConfig?.certificate?.certificateId}`,
        printOrDownload: "download",
      });
    }
    if (
      certificateInformation.printCertificateConfig !== null &&
      isEmptyValue(certificateInformation.printCertificateConfig?.templateName)
    ) {
      setCertificateInformation({
        ...certificateInformation,
        dialogConfiguration: {
          dialogDescriptionText: "No print template found.",
          open: true,
          optionYesOverrideLabel: "OK",
          onOptionYesEvent: () =>
            setCertificateInformation({
              ...certificateInformation,
              dialogConfiguration: null,
            }),
        },
      });
    }
  };

  useEffect(() => {
    dispatchGet();
  }, [insuredId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCertificateInformation({
        ...certificateInformation,
        certificateConfig: responseGet?.responseData ?? null,
      });
      updateDefaultValues(responseGet?.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      onSaveSuccessful?.(responsePost.responseData);
      setData(responsePost.responseData ?? null);
      if (
        responsePost.responseData?.certificateStatus ===
        CertificateStatusEnums.DELETED
      ) {
        onDeleteSuccessful?.();
      }
    }
    if (responsePost.errorResponse) {
      // Reset the status if API returns an error response
      if (defaultCertificateStatus) {
        setData({ ...data, certificateStatus: defaultCertificateStatus });
      }
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePrint)) {
      setCertificateInformation({
        ...certificateInformation,
        printCertificateConfig: responsePrint.responseData ?? null,
      });
    }
  }, [responsePrint]);

  useEffect(() => {
    printCertificate();
  }, [certificateInformation.printCertificateConfig]);

  useEffect(() => {
    if (isOpen && certificateInformation.certificateConfig !== null) {
      updateDefaultValues(certificateInformation.certificateConfig);
    }
  }, [isOpen]);

  const onReissueCertificate = () => {
    const currentPolicyId = getDefaultPolicyId(
      certificateInformation.certificateConfig?.policyOptions
    );
    setData({
      ...data,
      certificateId: 0,
      issueDate: new Date(),
      certificateStatus: -1,
      policyId: currentPolicyId,
      issuedBy: "",
    });
    onReissue?.();
  };

  const onCloseEvent = (close: boolean) => {
    closeModal(close);
    setErrorDetails(null);
    setDefaultCertificateStatus(null);
    setData(defaultCertificateData(insuredId ?? 0));
  };

  const onSaveEvent = () => {
    if (data?.certificateStatus === CertificateStatusEnums.PENDING) {
      setDefaultCertificateStatus(CertificateStatusEnums.PENDING);
      setData({ ...data, certificateStatus: CertificateStatusEnums.ISSUED });
    }
    setErrorDetails(null);
    dispatchPost();
  };
  const onDeleteEvent = () => {
    setDefaultCertificateStatus(data?.certificateStatus);
    setData({ ...data, certificateStatus: CertificateStatusEnums.DELETED });
    setErrorDetails(null);
    dispatchPost();
  };

  const extraButtons: ButtonWithConfirmationProps[] = [
    { textOnButton: "PRINT", onClick: () => dispatchPrint() },
    { textOnButton: "RE-ISSUE", onClick: () => onReissueCertificate() },
  ];

  return (
    <Modal
      id={"issue_certificate_modal"}
      title={"Certificate"}
      open={isOpen}
      showCloseButton={true}
      deleteEvent={mode !== "ADD" ? onDeleteEvent : undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      saveOverrideLabel={
        data?.certificateStatus === CertificateStatusEnums.PENDING
          ? "RELEASE"
          : "SAVE"
      }
      cancelEvent={() => onCloseEvent(false)}
      closeEvent={onCloseEvent}
      cancelButtonWithConfirmation
      deleteButtonWithConfirmation
      extraButtons={extraButtons}
      showExtraButtons={
        mode !== "ADD" &&
        data?.certificateStatus === CertificateStatusEnums.ISSUED
      }
    >
      {certificateInformation.certificateConfig !== null && (
        <IssueCertificateModalContent
          configuration={certificateInformation.certificateConfig}
          modalMode={mode}
          editCertificateId={editCertificateId}
          formData={data}
          setFormData={setData}
          errorDetails={errorDetails}
        />
      )}
      <DialogConfirmation
        id="payrollDetailsCancel"
        {...certificateInformation.dialogConfiguration}
        onCloseEvent={() =>
          setCertificateInformation({
            ...certificateInformation,
            dialogConfiguration: null,
          })
        }
      />
    </Modal>
  );
};

export default IssueCertificateModal;
