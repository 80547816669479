import { FC, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { InsuredInvoiceInformationFormProps } from "./InsuredInvoiceDetailsProps";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { areObjectsEqual } from "../../../../../../utilities/objectFunctions";
import PolicyDropdown from "../../../../Policy/PolicyDropdown";
import { StatusEnums } from "../../../../../../dtos/status-enums";
import { addDays } from "../../../../../../utilities/dateFunctions";

const InsuredInvoiceInformationForm: FC<InsuredInvoiceInformationFormProps> = ({
  policyIdFromAtom,
  insuredId,
  invoiceTypes,
  statuses,
  invoiceId,
  hasPermissionToEdit,
  formData,
  setFormData,
  setInvoiceDetailsProps,
  invoiceDetailsProps,
  errorDetails,
}) => {
  const {
    responsePost: responseCreateBillingTransactions,
    dispatchPost: dispatchCreateBillingTransactions,
  } = useApiPost<number | null>(
    "api/Invoice/CreateBillingTransactionsForNewInvoices",
    formData
  );

  const { responseGet, dispatchGet } = useApiGet(
    `api/Invoice/GetInsuredInvoiceById?invoiceId=${invoiceId}`
  );
  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormData({
        ...(responseGet?.axiosResponse?.data ?? null),
        actionPerformed: invoiceDetailsProps?.renderingOption ?? "",
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      invoiceDetailsProps.originalFormData === null &&
      conditionHasValue(formData)
    ) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        originalFormData: formData,
      });
    } else {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        formDataChanged: !areObjectsEqual(
          invoiceDetailsProps.originalFormData,
          formData
        ),
      });
    }
  }, [formData]);

  useEffect(() => {
    if (invoiceId !== 0) {
      dispatchGet();
    }
  }, [invoiceId]);

  useEffect(() => {
    const canDispatchGet =
      invoiceTypes.length > 0 &&
      statuses.length > 0 &&
      conditionHasValue(invoiceId) &&
      invoiceId !== 0;

    if (canDispatchGet) {
      dispatchGet();
    }
    if (invoiceId === 0) {
      setFormData({
        ...formData,
        invoiceStatus: StatusEnums.ACTIVE,
        policyId: parseInt(policyIdFromAtom ?? "0"),
        invoiceDate: new Date(),
        dueDate: addDays(new Date(), 30),
        invoiceTypeId: null,
      });
    }
  }, [invoiceTypes, statuses]);

  useEffect(() => {
    if (invoiceId === 0 && (formData?.invoiceTypeId ?? 0) !== 0) {
      const invoiceType = invoiceTypes.find(
        (invoiceType) => invoiceType.intValue === formData?.invoiceTypeId
      );
      if (invoiceType !== null) {
        setFormData({
          ...formData,
          invoiceDescription: invoiceType?.displayName,
        });
      }
    }
  }, [formData?.invoiceTypeId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseCreateBillingTransactions)) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        renderingOption: "table",
        invoiceId: 0,
      });
    }
  }, [responseCreateBillingTransactions]);

  const returnButtonActions = () => {
    if (
      invoiceDetailsProps.renderingOption === "add-view" &&
      invoiceDetailsProps?.invoiceId !== 0
    ) {
      dispatchCreateBillingTransactions();
    } else {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        renderingOption: "table",
        invoiceId: 0,
      });
    }
  };

  useEffect(() => {
    if ((formData?.invoiceDate ?? null) !== null) {
      const invoiceDate =
        typeof formData?.invoiceDate === "string"
          ? new Date(formData?.invoiceDate)
          : formData?.invoiceDate;
      if ((invoiceDate?.getFullYear() ?? 0) === 0) {
        const newDate = invoiceDate;
        newDate?.setFullYear(1800);
        setFormData({
          ...formData,
          invoiceDate: newDate,
        });
      }
    }
  }, [formData?.invoiceDate]);

  useEffect(() => {
    if ((formData?.dueDate ?? null) !== null) {
      const dueDate =
        typeof formData?.dueDate === "string"
          ? new Date(formData?.dueDate)
          : formData?.dueDate;
      if ((dueDate?.getFullYear() ?? 0) === 0) {
        const newDate = dueDate;
        newDate?.setFullYear(1800);
        setFormData({
          ...formData,
          dueDate: newDate,
        });
      }
    }
  }, [formData?.dueDate]);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalGutter="10px"
        horizontalAlign="flex-end"
      >
        <Button variantStyle={"outlined"} onClick={() => returnButtonActions()}>
          Return
        </Button>
      </Row>
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={20} allowWrap>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          <Input
            id={"insured-invoice-invoice-number"}
            name={"insuredInvoiceInvoiceNumber"}
            label={"Invoice Number"}
            value={formData?.invoiceNumber ?? ""}
            maxLength={30}
            errorMessage={errorDetails.invoiceNumber as []}
            onChangeRawValue={(value) => {
              setFormData({ ...formData, invoiceNumber: value });
            }}
            readOnly={!hasPermissionToEdit}
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          <Select
            id="insured-invoice-type"
            label="Invoice Type"
            name="insuredInvoiceType"
            options={invoiceTypes ?? []}
            value={formData?.invoiceTypeId}
            firstOptionAsDefault={false}
            labelFontType={"BOLD_CAPTION"}
            readOnly={!hasPermissionToEdit}
            onChange={(value) => {
              setFormData({ ...formData, invoiceTypeId: value });
            }}
            errorMessage={errorDetails?.invoiceTypeId as []}
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          <InputDate
            id="insured-invoice-invoice-date"
            label="Invoice Date"
            name="insuredInvoiceInvoiceDate"
            value={formData?.invoiceDate ?? new Date()}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, invoiceDate: value })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={!hasPermissionToEdit}
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          <InputDate
            id="insured-invoice-due-date"
            label="Due Date"
            name="insuredInvoiceDueDate"
            value={formData?.dueDate ?? new Date()}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, dueDate: value })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={!hasPermissionToEdit}
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          <Select
            id="insured-invoice-status"
            label="Status"
            name="insuredInvoiceStatus"
            options={statuses ?? []}
            value={
              invoiceId === 0 ? StatusEnums.ACTIVE : formData?.invoiceStatus
            }
            onChange={(e) => {
              setFormData({ ...formData, invoiceStatus: e });
            }}
            firstOptionAsDefault={false}
            labelFontType={"BOLD_CAPTION"}
            readOnly={
              invoiceDetailsProps?.renderingOption === "add-view" ||
              !hasPermissionToEdit
            }
          />
        </Col>
        <Col breakpoints={{ md: 8, lg: 4, xl: 4 }}>
          {(conditionHasValue(formData?.insuredId) || insuredId !== null) && (
            <PolicyDropdown
              insuredId={
                (formData?.insuredId ?? 0) !== 0
                  ? formData?.insuredId ?? -1
                  : insuredId
              }
              name={"policyId"}
              id={"id-policy"}
              label={"Policy"}
              defaultValue={
                invoiceId !== 0 && policyIdFromAtom !== null
                  ? formData?.policyId
                  : policyIdFromAtom ?? ""
              }
              labelFontType={"BOLD_CAPTION"}
              readOnly={!hasPermissionToEdit}
              policySelectedOnChange={(value) => {
                setFormData({ ...formData, policyId: value });
              }}
              firstOptionAsDefault={false}
            />
          )}
        </Col>
        <Col breakpoints={{ md: 12, lg: 16, xl: 16 }}>
          <Input
            id={"insured-invoice-invoice-description"}
            name={"insuredInvoiceInvoiceDescription"}
            label={"Invoice Description"}
            value={formData?.invoiceDescription ?? ""}
            maxLength={100}
            errorMessage={errorDetails.invoiceDescription as []}
            readOnly={!hasPermissionToEdit}
            onChangeRawValue={(value) => {
              setFormData({ ...formData, invoiceDescription: value ?? "" });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InsuredInvoiceInformationForm;
