import { FC } from "react";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTableDisplayOptions } from "../../../dtos/base-table-display-options";
import { BaseTableDisplayOptionsToSelectOptions } from "../../../utilities/dataConversionFunctions";
import Autocomplete from "../../TrueUI/Autocomplete/Autocomplete";

interface BaseTableAutocompleteCellProperties
  extends BaseTableCellSelectProperties {
  dataOptions: BaseTableDisplayOptions[];
}

const AutocompleteCell2: FC<BaseTableAutocompleteCellProperties> = ({
  rowKey,
  cellKey,
  column,
  value,
  dataOptions,
  isEdit,
  onChange,
  variant,
}) => {
  const optionsAsSelectOptions =
    BaseTableDisplayOptionsToSelectOptions(dataOptions);

  return (
    <Autocomplete
      key={`table-autocomplete_${cellKey}`}
      isOnTable
      staticOptions={optionsAsSelectOptions}
      defaultValueSelected={value}
      readOnly={!isEdit}
      variant={variant}
      onChangeRawValue={(optionSelected) =>
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: optionSelected,
        })
      }
    />
  );
};

export default AutocompleteCell2;
