import { FC } from "react";
import { Switch } from "../../../../../TrueUI";
import { ClaimCorrespondenceFormCheckboxProps } from "./typesAndConstants";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

const FormCheckbox: FC<ClaimCorrespondenceFormCheckboxProps> = ({
  form,
  formSelected,
  checkedFormIds,
}) => {
  return (
    <Switch
      control="checkbox"
      name={`correspondenceDocument${form.fileId}`}
      label={`${form.formName}`}
      labelPlacement={"end"}
      labelStyle={{
        "& span:first-of-type": {
          padding: "0px 4px",
        },
      }}
      isChecked={conditionHasValue(
        checkedFormIds?.find(
          (checkedForm) => checkedForm.claimFormId === form?.claimFormId ?? 0
        )
      )}
      onChangeIsChecked={(checked) => {
        formSelected(form, checked);
      }}
      primaryLabelColor
      labelFontType={"BODY"}
    />
  );
};

export default FormCheckbox;
