import { FC } from "react";
import { PortalThemeBackgroundTypeEnum } from "../../../../../dtos/portal-theme-background-type-enum";
import { SelectOptions } from "../../../../../dtos/select-options";
import { FontsType } from "../../../../../media/themeTypes";
import { KeysAsType } from "../../../../../types/KeysAsAType";
import { FromEnumToArray } from "../../../../../utilities/enumFunctions";
import { CaptionBold, Input, Select } from "../../../../TrueUI";
import InputColorPicker from "../../../../TrueUI/Inputs/InputColorPicker";

export const fontType: KeysAsType<FontsType> = "BOLD_CAPTION";

export const backgroundTypeOptions: Partial<SelectOptions>[] = FromEnumToArray(
  PortalThemeBackgroundTypeEnum
).map((enumType) => {
  return {
    displayName: enumType.key,
    stringValue: enumType.value,
  };
});

export const defaultThemeOptions: Partial<SelectOptions>[] = [
  { displayName: "Default", intValue: 0 },
];

export const primaryFontTypeOptions: Partial<SelectOptions>[] = [
  { displayName: "Roboto", stringValue: "Roboto" },
];

export const secondaryFontTypeOptions: Partial<SelectOptions>[] = [
  { displayName: "Segoe UI", stringValue: "Segoe UI" },
];

type AlignedInputWithLabelProps = {
  label: string;
  inputId: string;
  inputType?: string;
  selectOptions?: SelectOptions[];
  inputValue?: string;
  marginColorPicker?: string;
  errorMessage?: any;
  maxLength?: number;
  onChangeRawValue?: (value) => void;
};

export const AlignedInputWithLabel: FC<AlignedInputWithLabelProps> = ({
  label,
  inputId,
  inputType = "input",
  selectOptions = [],
  inputValue = "",
  onChangeRawValue,
  errorMessage,
  maxLength,
  marginColorPicker,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        margin: "10px 0",
        gap: "10px",
      }}
    >
      <CaptionBold display="block">{label}</CaptionBold>
      {inputType === "input" && (
        <Input
          id={inputId}
          name={inputId}
          value={inputValue}
          labelFontType={fontType}
          inputFontType={"BODY"}
          inputWidth={"80%"}
          onChangeRawValue={onChangeRawValue}
          errorMessage={errorMessage}
          maxLength={maxLength}
        />
      )}
      {inputType === "select" && (
        <Select
          id={inputId}
          name={inputId}
          value={inputValue}
          labelFontType={fontType}
          inputFontType={"BODY"}
          inputWidth={"70%"}
          options={selectOptions}
          onChange={onChangeRawValue}
          errorMessage={errorMessage}
        />
      )}
      {inputType == "colorPicker" && (
        <div style={{ margin: marginColorPicker }}>
          <InputColorPicker
            id={inputId}
            labelFontType={fontType}
            value={inputValue}
            onRawChange={onChangeRawValue}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </div>
  );
};

export enum PortalAdminTabsEnum {
  INFORMATION = 0,
  POLICY_PERIODS = 1,
  PAY_PLANS = 2,
  CUSTOM_FIELDS = 3,
  PORTAL_SETTINGS = 4,
  ADDITIONAL_CONTACTS = 5,
}

export const onChangeSettingField = (
  settingsObject,
  target,
  value,
  setSettingsObject
) => {
  setSettingsObject({ ...settingsObject, [target]: value });
};
