import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { Select } from "../../TrueUI";
import { SelectOptions } from "../../../dtos/select-options";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import { addCustomOption } from "../../../utilities/selectFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type PolicyDropdownProps = {
  insuredId: number;
  id: string;
  name: string;
  className?: string;
  errorMessage?: string[] | null;
  readOnly?: boolean;
  inputWidth?: string;
  variant?: "filled" | "standard";
  labelPosition?: "start" | "end" | "top" | "bottom";
  type?: "standard" | "tableFilter" | "tableCell";
  label?: string;
  labelFontType?: KeysAsType<FontsType>;
  isCustomArrow?: boolean;
  overrideAllOptionLabel?: string;
  overrideAllOptionValue?: string;
  selectAllOptionAsDefault?: boolean;
  defaultValue?: string | number;
  firstOptionAsDefault?: boolean;
  currentPolicyAsDefault?: boolean;
  policySelectedOnChange?: (value: any) => void;
  apiUrl?: string;
  optionsMaxHeight?: string;
  tabIndex?: number;
  showAllOption?: boolean;
};

const PolicyDropdown: FC<PolicyDropdownProps> = ({
  insuredId,
  id,
  name,
  className,
  readOnly,
  errorMessage,
  inputWidth,
  variant,
  labelPosition = "top",
  label = "Policy: ",
  type = "standard",
  labelFontType = "BOLD_CAPTION",
  isCustomArrow = false,
  overrideAllOptionLabel,
  overrideAllOptionValue,
  selectAllOptionAsDefault,
  defaultValue,
  firstOptionAsDefault = true,
  currentPolicyAsDefault,
  apiUrl = `api/Policy/GetPoliciesByInsured`,
  policySelectedOnChange,
  optionsMaxHeight,
  tabIndex,
  showAllOption = true,
}) => {
  const [policies, setPolicies] = useState<
    SelectOptions[] | Partial<SelectOptions>[]
  >([]);
  const [selectedPolicy, setSelectedPolicy] = useState<number | string | null>(
    null
  );

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `${apiUrl}?insuredId=${insuredId}`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  const allOptionValue = overrideAllOptionValue ?? "All";

  const getDefaultValue = (data: Partial<SelectOptions[]>) => {
    if (selectAllOptionAsDefault === true) {
      return allOptionValue;
    }
    if (conditionHasValue(defaultValue)) {
      return defaultValue;
    }
    if (currentPolicyAsDefault) {
      return data.find((x) => x?.isDefault)?.intValue ?? allOptionValue;
    }

    return allOptionValue;
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (type === "tableFilter" && showAllOption === true) {
        setPolicies(
          addCustomOption(responseGet.axiosResponse?.data ?? [], {
            displayName: overrideAllOptionLabel
              ? overrideAllOptionLabel
              : "All",
            stringValue: overrideAllOptionValue
              ? overrideAllOptionValue
              : "Active",
          })
        );
      } else {
        setPolicies(responseGet.axiosResponse?.data ?? []);
      }

      const defaultValue = getDefaultValue(
        responseGet.axiosResponse?.data ?? []
      );
      setSelectedPolicy(defaultValue);
      policySelectedOnChange?.(defaultValue);
    }
  }, [responseGet]);

  return (
    <Select
      id={id}
      name={name}
      label={label}
      className={className}
      variant={variant}
      labelFontType={labelFontType}
      labelPosition={labelPosition}
      type={type}
      options={policies}
      firstOptionAsDefault={firstOptionAsDefault}
      value={selectedPolicy}
      errorMessage={errorMessage}
      readOnly={readOnly}
      isCustomArrow={isCustomArrow}
      inputWidth={inputWidth}
      onChange={(value) => {
        policySelectedOnChange?.(value);
      }}
      optionsMaxHeight={optionsMaxHeight ?? "auto"}
      tabIndex={tabIndex}
    />
  );
};

export default PolicyDropdown;
