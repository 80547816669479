import { FC, useEffect, useState } from "react";
import { BaseTable2, Divider, FontBold } from "../../../../../TrueUI";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import MultiHeader from "../../../../../TrueUI/Tables/MultiTable/MultiHeader";
import {
  CustomFooterComponent,
  columnOptionsProps,
} from "./InvoiceDetailTableUtils";
import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { InsuredInvoiceDetailTableProps } from "./InsuredInvoiceDetailsProps";
import { MultiTablePatternComponent } from "./InvoiceDetailTableUtils";
import styles from "./InsuredInvoiceDetailsStyles.module.css";

const tableName = "invoices_detail_multi_table";
const InvoiceDetailTable: FC<InsuredInvoiceDetailTableProps> = ({
  invoiceId,
  setInvoiceSubIdAndDetailId,
  refreshTable,
  resetRefreshTable,
}) => {
  const [refreshKey, setRefreshKey] = useState(crypto.randomUUID());

  useEffect(() => {
    if (refreshTable === true) {
      setRefreshKey(crypto.randomUUID());
      resetRefreshTable();
    }
  }, [refreshTable]);

  const invoicesConfig: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Invoice/GetInsuredInvoiceSubGroups?invoiceId=${invoiceId}&uuid=${refreshKey}`,
    tableType: "multi",

    toolbarOptions: {
      hideToolbar: true,
      showSortFilter: false,
    },
    columnOptions: columnOptionsProps,
    advancedOptions: {
      paginate: false,
      tableStyle: {
        gapColumnAtTheEnd: true,
        multiTableHeaderStyle: "transparent",
      },
      multiTableProperties: {
        MultiTablePatternComponent: (data) =>
          MultiTablePatternComponent(data, setInvoiceSubIdAndDetailId),
        MultiTableHeaderPatternComponent: (params) => {
          const titleParams = params ?? [];
          return <MultiHeader headerParams={titleParams} />;
        },
      },
      headerExportType: "aligned",
    },

    footerOptions: {
      CustomFooterComponent: (rows) => CustomFooterComponent(rows, invoiceId),
    },
  };
  useBaseTable(invoicesConfig);
  return (
    <>
      <FontBold textAlign={"start"}>Detail</FontBold>
      <Divider height={15} />
      <div className={styles.detail_table_invoice_wrapper}>
        <BaseTable2 name={tableName} />
      </div>
    </>
  );
};
export default InvoiceDetailTable;
