import { FC, useEffect } from "react";
import { Input, Row, Select } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import Col from "../../../../../TrueUI/Grids/Col";
import { FormCreditCardProps } from "./ModalCreditCardPaymentTypes";
import { abbreviatedMonths } from "../../../../../../utilities/staticDataFunctions";
import {
  evaluateCardNumber,
  evaluateEmailReceipt,
  evaluateMonthToSetDefaultYear,
  getAbbrMonthsSelectOptions,
  getCardYearsSelectOptions,
} from "./FormCreditCardUtils";
import CvvInstructions from "./CvvInstructions";
import { useApiGet } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

const FormCreditCard: FC<FormCreditCardProps> = ({
  insuredId,
  creditCardInfo,
  setCreditCardInfo,
  errorDetails,
}) => {
  const { responseGet, dispatchGet } = useApiGet<string>(
    `api/Processor/GetBillingContactEmailByInsuredId?insuredId=${insuredId}`
  );

  const getEmailError = () =>
    conditionHasValue(errorDetails?.emailReceiptTo)
      ? errorDetails?.emailReceiptTo
      : creditCardInfo?.emailFormatError !== null
      ? [creditCardInfo?.emailFormatError ?? ""]
      : null;

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCreditCardInfo({
        ...creditCardInfo,
        emailReceipt: responseGet.axiosResponse?.data ?? "",
      });
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
    evaluateMonthToSetDefaultYear(setCreditCardInfo, creditCardInfo);
  }, []);

  useEffect(() => {
    evaluateMonthToSetDefaultYear(setCreditCardInfo, creditCardInfo);
  }, [creditCardInfo?.cardMonth]);

  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} allowWrap={true}>
      <Col breakpoints={{ md: 24, lg: 10, xl: 12 }}>
        <Input
          id={"paymentCreditCardNumber"}
          name={"paymentCreditCardNumber"}
          label={"Enter Card Number"}
          type={"number"}
          maxLength={creditCardInfo?.cardNumberMaxLength}
          thousandSeparator={false}
          value={creditCardInfo?.cardNumber}
          onChangeRawValue={(cardNumber) =>
            evaluateCardNumber(cardNumber, setCreditCardInfo, creditCardInfo)
          }
          errorMessage={errorDetails?.cardNumber}
        />
      </Col>
      <Col breakpoints={{ md: 12, lg: 6, xl: 5 }}>
        <Select
          id={"paymentCreditCardMonth"}
          name={"paymentCreditCardMonth"}
          label={"Expiration Month"}
          firstOptionAsDefault={false}
          options={getAbbrMonthsSelectOptions(abbreviatedMonths)}
          value={creditCardInfo?.cardMonth ?? 1}
          onChange={(month) =>
            setCreditCardInfo({ ...creditCardInfo, cardMonth: month })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 12, lg: 6, xl: 5 }}>
        <Select
          id={"paymentCreditCardYear"}
          name={"paymentCreditCardYear"}
          label={"Year"}
          firstOptionAsDefault={false}
          options={getCardYearsSelectOptions(7)}
          value={creditCardInfo?.cardYear ?? new Date().getUTCFullYear()}
          onChange={(year) =>
            setCreditCardInfo({ ...creditCardInfo, cardYear: year })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 2, xl: 2 }}></Col>

      {/* Line 2 */}
      <Col breakpoints={{ md: 24, lg: 5, xl: 5 }}>
        <Input
          id={"paymentCreditCardBillingZipCode"}
          name={"paymentCreditCardBillingZipCode"}
          label={"Billing Zip Code"}
          maxLength={5}
          thousandSeparator={false}
          type={"number"}
          value={creditCardInfo?.cardBillingZipCode}
          onChangeRawValue={(cardBillingZipCode) =>
            setCreditCardInfo({
              ...creditCardInfo,
              cardBillingZipCode: cardBillingZipCode,
            })
          }
          errorMessage={errorDetails?.postcode}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 5, xl: 5 }}>
        <Input
          id={"paymentCreditCardCvv"}
          name={"paymentCreditCardCvv"}
          label={"Security/CVV Code"}
          type={"number"}
          maxLength={creditCardInfo?.cardCvvMaxLength}
          thousandSeparator={false}
          value={creditCardInfo?.cardCvv}
          onChangeRawValue={(cardCvv) =>
            setCreditCardInfo({
              ...creditCardInfo,
              cardCvv: cardCvv,
            })
          }
          errorMessage={errorDetails?.cardCode}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 14, xl: 14 }}>
        <CvvInstructions />
      </Col>
      {/* Line 3 */}
      <Col breakpoints={{ md: 24 }}>
        <Input
          id={"paymentEmailReceiptTo"}
          name={"paymentEmailReceiptTo"}
          label={"Send Email Receipt To"}
          thousandSeparator={false}
          type="email"
          value={creditCardInfo?.emailReceipt}
          onChangeRawValue={(emailReceipt) =>
            evaluateEmailReceipt(
              emailReceipt,
              setCreditCardInfo,
              creditCardInfo
            )
          }
          errorMessage={getEmailError()}
        />
      </Col>
    </Row>
  );
};
export default FormCreditCard;
