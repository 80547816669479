import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BottomNav, LeftNav } from "./components/Nav";
import { useRecoilState } from "recoil";
import { globalOptions } from "./GlobalAtoms";
import { Loading } from "./components/TrueUI";
import { TopNav } from "./components/Nav/TopNav";
import { useApiGet } from "./hooks";
import { layoutStyles } from "./LayoutStyles";
import themes from "./media/TrueTheme";
import { refreshCachePeriodically } from "./utilities/localStorageFunctions";
import AppNotifications from "./AppNotifications";

const Layout: FC<any> = ({ children }) => {
  const [localOptions, setOptions] = useRecoilState(globalOptions);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = themes[localOptions?.themeRefresh];
  const { responseGet, dispatchGet } = useApiGet("api/init/getinitoptions", {
    disablePropertyUpdaters: true,
  });

  useEffect(() => {
    refreshCachePeriodically();
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet.responseData) {
      setOptions(responseGet.axiosResponse?.data);
      setIsLoading(false);
    }
  }, [responseGet]);

  return (
    <>
      <Box id={"layout"} sx={layoutStyles(theme)}>
        <Box id={"left_wrapper"}>
          <LeftNav isInitLoading={isLoading ?? false} />
        </Box>
        <div id={"right_wrapper"}>
          <div id={"top_wrapper"}>
            <AppNotifications />
            <TopNav isInitLoading={isLoading ?? false} />
          </div>
          <div id={"body_wrapper"}>
            <div className="loading-box">
              <Loading isLoading={isLoading ?? false} />
            </div>

            {!isLoading && children}
          </div>
        </div>
        <BottomNav />
      </Box>
    </>
  );
};

export default Layout;
