import { FC, Fragment, useEffect, useState } from "react";
import { useApiPost } from "../../../../../hooks";
import {
  PolicyQuotePremiumTableProps,
  PremiumTableUIProps,
} from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { RateElementsPage } from "../../../../../dtos/rate-elements-page";
import {
  getAnyOtherStateStartOfCalculationsRowKeyCollection,
  getDependantRatingsByRatingType,
  getRatingsUpdatedByAtomValue,
  getRowAtomKeyByRatingTypeAndRateElementId,
  getStatesUpdatedByRatingList,
  isLastRating,
} from "./PremiumTableRows/PremiumTableRowsUtils";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { areObjectsEqual } from "../../../../../utilities/objectFunctions";
import { Row } from "../../../../TrueUI";
import { updateQuoteInPolicyQuote } from "../../updatesPolicyQuoteFunctions";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { getStateByStateCodeAndDates } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import PremiumRowSelector from "./PremiumTableRows/PremiumRowSelector";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuotePremiumRowTriggerComponent,
  usePolicyQuoteTriggerComponent,
} from "../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { getStatesWithGeneralPropsUpdated } from "../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { PolicyRatingBlob } from "../../../../../dtos/policy-rating-blob";
import { getValidatedDateToPost } from "../../../../../utilities/dateFunctions";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import {
  areAllClassCodesValid,
  getHazardGroupOfHighestManualPremiumItem,
} from "../ExposureTable/ExposureTableRowFunctions";
import { isEmptyValue } from "../../../../../utilities/conditionalSupportFunctions";
import { GetRateElementByStateParametersDto } from "../../../../../dtos/get-rate-element-by-state-parameters-dto";

const PremiumTable: FC<PolicyQuotePremiumTableProps> = ({
  tabKey,
  insuredId,
  quoteStateId,
  stateCode,
  stateName,
  effectiveDate,
  expirationDate,
  policyEffectiveDate,
  readOnly = false,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { setPolicyQuotePremiumRowTriggers } =
    usePolicyQuotePremiumRowTriggerComponent();
  const [_errorDetails, setErrorDetails] = useState<any>();
  const [premiumTableUI, setPremiumTableUI] = useState<PremiumTableUIProps>();
  const [
    hazardGroupOfHighestManualPremium,
    setHazardGroupOfHighestManualPremium,
  ] = useState<string>("");
  const [exposures, setExposures] = useState<PolicyExposureBlob[]>([]);

  const getPrimaryAgencyId = () => {
    const atomValue = getAtom();
    const producers = atomValue?.policyQuoteInformation?.policyQuote?.producers;
    if (!isEmptyValue(producers)) {
      const primaryAgency = producers?.find(
        (producer) => producer.policyAgencyPrimary ?? false
      );
      if (!isEmptyValue(primaryAgency)) return primaryAgency?.agency?.agencyID;
    }
    return null;
  };

  const configurationRequestParameters = {
    stateCode,
    effectiveDate: getValidatedDateToPost(policyEffectiveDate ?? new Date()),
    hazardGroupOfHighestManualPremium,
    primaryAgencyId: getPrimaryAgencyId(),
    quoteStateId,
  } as GetRateElementByStateParametersDto;

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<RateElementsPage>(
      "api/PolicyExposurePremium/GetRateElementConfigurationsByState",
      configurationRequestParameters
    );

  const listenerPremiumTableByExposureComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("premiumTableByExposureComponent")
  );

  const setInitialValuesBasedOnAtomValues = () => {
    if (validatorErrorResponse === null && isAPITotallyComplete(responsePost)) {
      const atomValue = getAtom();

      const rateElements = responsePost?.axiosResponse?.data.rateElements ?? [];

      const currentState = getStateByStateCodeAndDates(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        atomValue
      );

      if (
        currentState?.ratings === undefined ||
        currentState.ratings === null
      ) {
        const statesUpdatedByRatings = getStatesUpdatedByRatingList(
          stateCode ?? "",
          effectiveDate ?? new Date(),
          expirationDate ?? new Date(),
          rateElements,
          atomValue
        );

        const newAtomValue = updateQuoteInPolicyQuote(
          atomValue,
          "states",
          statesUpdatedByRatings
        );

        setAtom(newAtomValue);

        setPremiumTableUI({
          ...premiumTableUI,
          ratingsLoaded: true,
          ratingDependantCollection: getDependantRatingsByRatingType(
            insuredId,
            rateElements ?? []
          ),
          ratings: rateElements,
          configurationsPerRow: responsePost?.axiosResponse?.data.configuration,
        });
      } else {
        setPremiumTableUI({
          ...premiumTableUI,
          ratingsLoaded: true,
          ratingDependantCollection: getDependantRatingsByRatingType(
            insuredId,
            rateElements ?? []
          ),
          ratings: currentState.ratings ?? [],
          configurationsPerRow: responsePost?.axiosResponse?.data.configuration,
        });
      }
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  };

  const getCalculationsFinishedAction = (
    premiumTableUI: PremiumTableUIProps,
    index: number
  ) => {
    return isLastRating(premiumTableUI.ratings, index)
      ? () => {
          const atomValue = getAtom();
          const newAtomValue = updateQuoteInPolicyQuote(
            atomValue,
            "states",
            getStatesWithGeneralPropsUpdated(
              atomValue?.policyQuoteInformation?.policyQuote?.quote?.states ??
                []
            )
          );
          setAtom(newAtomValue);

          const newRatings = getRatingsUpdatedByAtomValue(
            stateCode ?? "",
            effectiveDate ?? new Date(),
            expirationDate ?? new Date(),
            newAtomValue
          );

          if (areObjectsEqual(premiumTableUI?.ratings, newRatings) === false) {
            setPremiumTableUI({
              ...premiumTableUI,
              ratings: newRatings ?? [],
            });
            setPolicyQuoteTriggers([
              "exposurePremiumFooterComponent",
              "endorsementExposurePremiumFooterComponent",
              "exposureTableHeaderComponent",
              "endorsementExposureTableHeaderComponent",
              "policyNetRateChangedComponent",
            ]);
          }
        }
      : null;
  };

  const getElementName = (element: PolicyRatingBlob) => {
    const nameSections = element?.elementName?.split(" ") ?? [""];
    return nameSections.reduce((name, nameSectionActual, index) => {
      return index > 0
        ? name +
            (nameSectionActual.charAt(0).toUpperCase() +
              nameSectionActual.slice(1))
        : name +
            (nameSectionActual.charAt(0).toLowerCase() +
              nameSectionActual.slice(1));
    }, "");
  };

  const getDependentRatingsTriggerFunction = (
    rateElementID: number,
    ratingDependantCollection: {
      rateElementId: number;
      dependantRatingTriggerComponentNames: string[];
    }[]
  ) => {
    const dependantRatingTriggerComponentNames =
      ratingDependantCollection.find(
        (item) => item.rateElementId === rateElementID
      )?.dependantRatingTriggerComponentNames ?? [];

    return dependantRatingTriggerComponentNames.length > 0
      ? () =>
          setPolicyQuotePremiumRowTriggers(dependantRatingTriggerComponentNames)
      : null;
  };

  useEffect(() => {
    if (listenerPremiumTableByExposureComponent !== null) {
      const atomValue = getAtom();
      const currentState = getStateByStateCodeAndDates(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        atomValue
      );
      setExposures(currentState?.exposures ?? []);
      setHazardGroupOfHighestManualPremium(
        getHazardGroupOfHighestManualPremiumItem(currentState?.exposures ?? [])
      );
    }
  }, [listenerPremiumTableByExposureComponent]);

  useEffect(() => {
    if (hazardGroupOfHighestManualPremium !== "" && exposures.length > 0)
      dispatchPost();
  }, [hazardGroupOfHighestManualPremium]);

  useEffect(() => {
    setInitialValuesBasedOnAtomValues();
  }, [responsePost]);

  useEffect(() => {
    if (areAllClassCodesValid(exposures) && premiumTableUI?.ratingsLoaded) {
      const testRowKey = getRowAtomKeyByRatingTypeAndRateElementId(
        insuredId,
        premiumTableUI?.ratings?.[0]?.elementType ?? -1,
        premiumTableUI?.ratings?.[0]?.rateElementID ?? -1
      );
      setPolicyQuotePremiumRowTriggers([testRowKey]);

      const currentStates =
        getAtom()?.policyQuoteInformation?.policyQuote?.quote?.states ?? [];
      const anyOtherStartOfCalculationRowAtomKey =
        getAnyOtherStateStartOfCalculationsRowKeyCollection(
          insuredId,
          stateCode ?? "",
          effectiveDate ?? new Date(),
          expirationDate ?? new Date(),
          currentStates
        );
      setPolicyQuotePremiumRowTriggers(anyOtherStartOfCalculationRowAtomKey);
    }
  }, [premiumTableUI?.ratingsLoaded, exposures]);

  return (
    <>
      {areAllClassCodesValid(exposures) && (
        <Row
          {...rowWithNoMarginNorGutter}
          rowDirection="column"
          className="premium-table-container"
        >
          {premiumTableUI?.configurationsPerRow &&
            premiumTableUI?.ratings &&
            premiumTableUI?.ratings.map((rating, index) => {
              return (
                <Fragment key={`exposure-premium-row-${index}`}>
                  <PremiumRowSelector
                    tabKey={tabKey}
                    insuredId={insuredId}
                    quoteStateId={quoteStateId}
                    stateCode={stateCode ?? ""}
                    stateName={stateName ?? ""}
                    effectiveDate={effectiveDate ?? new Date()}
                    expirationDate={expirationDate ?? new Date()}
                    rateIndex={index}
                    readOnly={readOnly}
                    rating={rating}
                    nameElement={getElementName(premiumTableUI.ratings[index])}
                    exposures={exposures}
                    configurations={premiumTableUI?.configurationsPerRow}
                    calculationsFinished={getCalculationsFinishedAction(
                      premiumTableUI,
                      index
                    )}
                    triggerDependantRatings={getDependentRatingsTriggerFunction(
                      rating.rateElementID ?? -1,
                      premiumTableUI.ratingDependantCollection ?? []
                    )}
                  />
                </Fragment>
              );
            })}
        </Row>
      )}
    </>
  );
};

export default PremiumTable;
