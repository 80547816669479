import { FC } from "react";
import { IconButton as MaterialIconButton } from "@mui/material";
import { usePermissions } from "../../../hooks";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";

type IconButtonProps = {
  children?: any;
  name?: string;
  permissions?: number[];
  disabled?: boolean;
  onClick?: () => void;
};

const IconButton: FC<IconButtonProps> = ({
  children,
  name = "",
  onClick,
  disabled,
  permissions = [1, 2, 3],
}) => {
  const hasPermission = usePermissions(permissions);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const iconButtonStyles = {
    svg: { color: theme?.TEXT_COLOR },
    height: "35px",
  };

  return (
    <>
      {hasPermission && (
        <MaterialIconButton
          sx={iconButtonStyles}
          onClick={() => {
            onClick?.();
          }}
          aria-label="save"
          size="small"
          true-element={`${name ? "true-element-icon-button-" + name : ""}`}
          className={TABBABLE_CLASS_NAME}
          disabled={disabled}
        >
          {children}
        </MaterialIconButton>
      )}
    </>
  );
};

export default IconButton;
