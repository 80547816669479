import { FC, memo } from "react";

import BaseTable2Container from "./BaseTable2Container";

import BaseTable2Toolbar from "./BaseTable2Toolbar";
import BaseTable2EventListenerManager from "./BaseTable2EventListenerManager";
import BaseTable2Registration from "./BaseTable2Registration";
import BaseTable2Wrapper from "./BaseTable2Wrapper";
import BaseTable2AdvancedPropertiesManager from "./BaseTable2AdvancedPropertiesManager";
import ProcessesIncomingData from "./ProcessesIncomingData";
import SortAndFilterData from "./SortAndFilterData";
import BodyListenerManager from "./BodyListenerManager";
// import BaseTableFooter from "./BaseTableFooter";
import BaseTable2Pagination from "./BaseTable2Pagination";
import BaseTableFooterSubComponent from "./BaseTableFooterSubComponent";
import BaseTable2MultiSelectManager from "./BaseTable2MultiSelectManager";
import { DeprecationWarningComponentRoot } from "../../Support/DeprecationWarningComponentRoot";

const BaseTable2: FC<{ name: string }> = ({ name }) => {
  /////////////////////////
  //
  //  DEV NOTE: We are currently not allowed to put the tableInstance object in this component as it constantly changes which will cause this component to re-render.
  //
  /////////////////////////
  return (
    <BaseTable2Registration name={name}>
      <BaseTable2Wrapper>
        <ProcessesIncomingData />
        <SortAndFilterData />
        <BaseTable2EventListenerManager />
        <BodyListenerManager />
        <BaseTable2AdvancedPropertiesManager />
        <BaseTable2MultiSelectManager />
        <BaseTable2Toolbar />
        <BaseTable2Container />
        <BaseTableFooterSubComponent />
        {/* <BaseTableFooter /> */}
        <BaseTable2Pagination />
      </BaseTable2Wrapper>
    </BaseTable2Registration>
  );
};

BaseTable2.displayName = "BaseTable2";
export default memo(DeprecationWarningComponentRoot(BaseTable2));
