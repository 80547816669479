const INSURED_ID = "insid";
const INCIDENT_ID = "incid";
const INCIDENT_SECTION = "incsec";
const INCIDENT_BODY_SECTION = "incbsec";
const INCIDENT_STEPPER_SECTION = "incstsec";
const CLAIM_NUMBER = "cn";
const OUT_OF_INSURED = "ooi";
const PARENT_ITEM = "pi";
const CHILD_ITEM = "ci";
const BODY_SECTION = "bs";
const INSURED_INFORMATION_TABS = "iit";
const TOP_LEVEL_CLAIMS_TABS = "tlct";
const CLAIM_DETAILS_TABS = "cdt";
const CLAIMS_ACTIVE_SECTION = "cas";
const PROGRAM_ADMIN_INFORMATION_TABS = "pait";
const PROGRAM_ADMIN_RATE_TABLES_TABS = "part";
const ADMIN_REFERENCE_TABS = "art";
const AGENCY_TABS = "at";
const DRAWER_TABS = "dt";
const DIARY_TABS = "dat";
const INSURED_PARENT_ITEM = "ins" + PARENT_ITEM;
const INSURED_CHILD_ITEM = "ins" + CHILD_ITEM;
const INSURED_BODY_SECTION = "ins" + BODY_SECTION;
const PROGRAM_PARENT_ITEM = "p" + PARENT_ITEM;
const PROGRAM_CHILD_ITEM = "p" + CHILD_ITEM;
const PROGRAM_BODY_SECTION = "p" + BODY_SECTION;
const CLAIMS_PARENT_ITEM = "c" + PARENT_ITEM;
const CLAIMS_CHILD_ITEM = "c" + CHILD_ITEM;
const CLAIMS_BODY_SECTION = "c" + BODY_SECTION;
const OPEN_DRAWER = "od";
const PAYMENT_BATCH_ID = "pbid";
const PAYMENT_BATCH_SECTION = "pbs";
const INSURED_ATOM_KEY = "INSURED_ATOM";
const BILLING_ACTIVITY_TABS = "bat";
const TOP_LEVEL_BILLING_TABS = "tlbt";
const PAYROLL_REPORT_DETAILS = "prd";
const PAYROLL_REPORT_ID = "prid";
const POLICY_QUOTE_SECTION = "pqs";
const ENDORSEMENT_SECTION = "es";
const AUDIT_SECTION = "as";
const POLICY_ID = "pid";
const QUOTE_ID = "qid";
const PREVIOUS_QUOTE_ID = "pqid";
const POLICY_CANCELLATION_ID = "pc";
const OPEN_CLAIM_INSIDE_INSURED = "opii";
const OPEN_FROM_SEARCH = "ofs";
const DRAWER_TASK_ID = "dtid";
const BILLING_ACCOUNTS_ID = "bac";
const BILLING_SCHEDULED_TRANSACTION_ID = "bst";
const DRAWER_NOTE_ID = "dnid";

export {
  INSURED_ID,
  PARENT_ITEM,
  CHILD_ITEM,
  BODY_SECTION,
  INSURED_INFORMATION_TABS,
  PROGRAM_ADMIN_RATE_TABLES_TABS,
  PROGRAM_ADMIN_INFORMATION_TABS,
  ADMIN_REFERENCE_TABS,
  AGENCY_TABS,
  DRAWER_TABS,
  CLAIM_DETAILS_TABS,
  CLAIMS_ACTIVE_SECTION,
  INSURED_PARENT_ITEM,
  INSURED_CHILD_ITEM,
  INSURED_BODY_SECTION,
  PROGRAM_PARENT_ITEM,
  PROGRAM_CHILD_ITEM,
  PROGRAM_BODY_SECTION,
  CLAIMS_PARENT_ITEM,
  CLAIMS_CHILD_ITEM,
  CLAIMS_BODY_SECTION,
  INCIDENT_ID,
  INCIDENT_BODY_SECTION,
  INCIDENT_STEPPER_SECTION,
  OUT_OF_INSURED,
  CLAIM_NUMBER,
  TOP_LEVEL_CLAIMS_TABS,
  INSURED_ATOM_KEY,
  INCIDENT_SECTION,
  OPEN_DRAWER,
  DIARY_TABS,
  PAYMENT_BATCH_ID,
  PAYMENT_BATCH_SECTION,
  BILLING_ACTIVITY_TABS,
  TOP_LEVEL_BILLING_TABS,
  PAYROLL_REPORT_DETAILS,
  PAYROLL_REPORT_ID,
  POLICY_QUOTE_SECTION,
  POLICY_ID,
  QUOTE_ID,
  PREVIOUS_QUOTE_ID,
  POLICY_CANCELLATION_ID,
  OPEN_CLAIM_INSIDE_INSURED,
  DRAWER_TASK_ID,
  BILLING_ACCOUNTS_ID,
  ENDORSEMENT_SECTION,
  DRAWER_NOTE_ID,
  BILLING_SCHEDULED_TRANSACTION_ID,
  OPEN_FROM_SEARCH,
  AUDIT_SECTION,
};
