const getSignificantFigures = (value: number) => {
  const valueAsString = value.toString();
  const valueWithoutZerosAndDot = valueAsString.replace(/^0+|\.|0+$/g, "");
  const significantFigures = valueWithoutZerosAndDot.length;

  return significantFigures;
};

const getDecimalPlaces = (value: number) => {
  const valueAsString = value.toString();
  const decimalIndex = valueAsString.indexOf(".");
  if (decimalIndex !== -1) {
    const decimalPlaces = valueAsString.length - decimalIndex - 1;
    return decimalPlaces;
  } else {
    return 0;
  }
};

export const divideAndRound = (dividend: number, divisor: number) => {
  const significantFigures = getSignificantFigures(dividend);
  const decimalPlaces = getDecimalPlaces(dividend);
  const result = dividend / divisor;
  const roundedResult = parseFloat(
    result.toFixed(significantFigures + decimalPlaces)
  );

  return roundedResult;
};

export const validateMaxAndMinNumberValue = (
  value: number,
  maxNumericValue: number,
  minNumericValue: number
) => {
  if (value > maxNumericValue) return maxNumericValue;
  if (value < minNumericValue) return minNumericValue;
  return value;
};

