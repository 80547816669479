export const downloadBlobObject = (
  data: string,
  fileName: string,
  parentContainerId: string
) => {
  const element = document.createElement("a");
  const jsonFile = new Blob([data as string as BlobPart], {
    type: "json/plain",
  });
  element.href = URL.createObjectURL(jsonFile);
  element.download = fileName + ".json";
  document?.querySelector(`#${parentContainerId}`)?.appendChild(element);
  element.click();
  element.remove();
};

export const convertFromBase64ToUINT8Array = (fileContent) => {
  const binaryData = atob(fileContent);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  return uint8Array;
};
