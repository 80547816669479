import { FC } from "react";
import { Select } from "../..";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { SelectOptions } from "../../../../dtos/select-options";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTableDropDownCellProperties
  extends BaseTableCellSelectProperties {
  dataOptions: BaseTableDisplayOptions[];
  selectMaxHeight?: string;
  isDisabled?: boolean;
}

// TODO - complete dropdown
const SelectCell2: FC<BaseTableDropDownCellProperties> = ({
  rowKey,
  dataOptions,
  cellKey,
  column,
  value,
  isEdit,
  onChange,
  variant,
  errorMessage,
  selectMaxHeight,
  isDisabled = false,
}) => {
  const dropdownValues: Partial<SelectOptions>[] = dataOptions?.map(
    (options) => {
      return { displayName: options.text, stringValue: options.value };
    }
  );

  return (
    <Select
      id={`true-select-cell-${cellKey}`}
      name={`name-${column.fieldName}-${cellKey}`}
      type={"tableCell"}
      key={`table_select_${cellKey}`}
      value={value ?? ""}
      readOnly={isDisabled ? true : !isEdit}
      options={dropdownValues}
      onChange={(val) => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: val,
        });
      }}
      variant={variant}
      optionsMaxHeight={selectMaxHeight}
      errorMessage={errorMessage}
      firstOptionAsDefault={false}
    />
  );
};

export default SelectCell2;
