import { useState, FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Link } from "@mui/material";
import {
  Input,
  Select,
  InputAddress,
  InputDate,
  Button,
  InputPhone,
  InputTax,
  Col,
  FontBold,
  Switch,
} from "../TrueUI";
import { AgencyInformationDto } from "../../dtos/agency-information-dto";
import { StatusEnums } from "../../dtos/status-enums";
import { useApiPost, useApiGet, usePermissions } from "../../hooks";
import { SelectOptions } from "../../dtos/select-options";
import { globalOptions } from "../../GlobalAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { FromEnumToArray } from "../../utilities/enumFunctions";
import { RefreshAgencyInformationPageAtom } from "./AgencyAtoms";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import { getCorrectURL } from "../../utilities/stringFunctions";
import {
  areObjectsEqual,
  removeFalsyValues,
  removeObjectProperty,
  replaceEmptyStringWithNullForPost,
} from "../../utilities/objectFunctions";
import Row, { rowWithNoMarginNorGutter } from "../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../TrueUI/Grids/Col";
import { getQueryStringsURL } from "../../utilities/URLUtilities_OBSOLETE";
import { AGENCY_TABS } from "../../utilities/queryStringsHash";
import { PermissionsEnums } from "../../dtos/permissions-enums";

type InformationProps = {
  agencyInformationObj: AgencyInformationDto;
};

const statusTypes = FromEnumToArray(StatusEnums).map((enumType) => ({
  displayName: enumType.key,
  stringValue: enumType.value,
}));

const Information: FC<InformationProps> = ({ agencyInformationObj }) => {
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const isNewAgency = agencyId === "new";
  const { hasPermission } = usePermissions([PermissionsEnums.AGENCY_ADMIN]);
  const localOptions = useRecoilValue(globalOptions);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [businessType, setBusinessType] = useState<Partial<SelectOptions>[]>(
    []
  );

  const [agencyInformation, setAgencyInformation] =
    useState(agencyInformationObj);
  const [agencyInformationCopy, setAgencyInformationCopy] =
    useState(agencyInformationObj);
  const [readOnly, setReadOnly] = useState(true);
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<number>("api/Agency/UpdateAgencyInformation", agencyInformation);
  const { responseGet, dispatchGet } = useApiGet<string[]>(
    "api/agency/GetAgencyBusinessTypeList"
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [refreshPage, setRefreshPage] = useRecoilState(
    RefreshAgencyInformationPageAtom
  );
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  useEffect(() => {
    dispatchGet();
  }, []);

  const hasAgencyChanges = () => {
    const originalObj = removeFalsyValues(
      removeObjectProperty(agencyInformation, "taxIdType")
    );
    const copyObj = removeFalsyValues(
      removeObjectProperty(agencyInformationCopy, "taxIdType")
    );
    return !areObjectsEqual(originalObj, copyObj);
  };

  const getBusinessTypeSelectList = (businessTypeList: any[]) =>
    businessTypeList.map((businessType: any) => ({
      displayName: businessType,
      stringValue: businessType,
    }));

  useEffect(() => {
    if (responseGet?.responseData) {
      setBusinessType(
        getBusinessTypeSelectList(responseGet?.responseData ?? [])
      );
      setReadyToRender(true);
    }
  }, [responseGet]);

  useEffect(() => {
    if (!responsePost.isLoading) {
      if (
        responsePost.requestInstanceSuccessful &&
        validatorErrorResponse === null
      ) {
        setReadOnly(true);
        setErrorDetails(null);
        setRefreshPage(!refreshPage);
        const agencyId = responsePost.axiosResponse?.data ?? -1;
        setAgencyInformation({ ...agencyInformation, agencyId: agencyId });
        setAgencyInformationCopy({ ...agencyInformation, agencyId: agencyId });
        const queryStringsToAgency = getQueryStringsURL([
          {
            nameOfHash: AGENCY_TABS,
            valueOfHash: 0,
          },
        ]);
        navigate(`/agency/${agencyId}${queryStringsToAgency}`);
      } else {
        setErrorDetails(validatorErrorResponse?.errorDetails);
      }
    }
  }, [responsePost]);

  useEffect(() => {
    setAgencyInformation(agencyInformationObj);
    setAgencyInformationCopy(agencyInformationObj);
    setReadOnly(agencyInformationObj.agencyId !== 0);
  }, [agencyInformationObj]);

  const dialogConfirmationCloseOrCancel = () => {
    if (!readOnly && hasAgencyChanges() && !isNewAgency) {
      setReadOnly(true);
      setIsDialogConfirmationOpen(false);
      setAgencyInformation(agencyInformationCopy);
    } else {
      setReadyToRender(false);
      setErrorDetails(null);
      navigate("/agency");
    }
  };

  const save = async () => {
    setAgencyInformation(replaceEmptyStringWithNullForPost(agencyInformation));
    dispatchPost();
  };

  useEffect(() => {
    if (!readyToRender) setReadyToRender(true);
  }, [agencyInformation]);

  return (
    <>
      {agencyInformation && readyToRender ? (
        <>
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 8, lg: 8, xl: 8 }}
            >
              <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                <Col>
                  <Input
                    tabIndex={1}
                    id="id-agency-name-input"
                    name="agencyName"
                    label="Agency Name"
                    value={agencyInformation?.agencyName ?? ""}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.agencyName}
                    className={readOnly ? "true_information_disabled" : ""}
                    maxLength={200}
                    onChangeRawValue={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        agencyName: value,
                      });
                    }}
                  />
                </Col>
                <Col {...colWithNoMarginNorGutter}>
                  <InputAddress
                    tabIndex={2}
                    id="id-agency-address"
                    label="Address"
                    labelFontType="BOLD_CAPTION"
                    name={"agencyAddress"}
                    initValueAddress1={agencyInformation?.address1 ?? ""}
                    initValueAddress2={agencyInformation?.address2 ?? ""}
                    initValueCity={agencyInformation?.city ?? ""}
                    initValueStateCode={agencyInformation?.state ?? ""}
                    initValuePostCode={agencyInformation?.postCode ?? ""}
                    address1ErrorMessage={errorDetails?.address1}
                    address2ErrorMessage={errorDetails?.address2}
                    cityErrorMessage={errorDetails?.city}
                    postCodeErrorMessage={errorDetails?.postCode}
                    stateCodeErrorMessage={errorDetails?.state}
                    onChangeValueAddress1={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        address1: value,
                      });
                    }}
                    onChangeValueAddress2={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        address2: value,
                      });
                    }}
                    onChangeValueCity={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        city: value,
                      });
                    }}
                    onChangeStateCode={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        state: value,
                      });
                    }}
                    onChangeValuePostCode={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        postCode: value,
                      });
                    }}
                    readOnly={readOnly}
                    className={readOnly ? "true_information_disabled" : ""}
                    showSingleLabel={false}
                  />
                </Col>
                <Col>
                  <InputPhone
                    tabIndex={7}
                    id={"id-agency-phone-input"}
                    name={"agencyPhoneNumber"}
                    phoneLabel="Phone Number"
                    phoneValue={agencyInformation?.phoneNumber ?? ""}
                    extValue={agencyInformation?.phoneAdd ?? ""}
                    readOnly={readOnly}
                    errorMessagePhone={errorDetails?.phoneNumber}
                    errorMessageExtension={errorDetails?.phoneAdd}
                    className={readOnly ? "true_information_disabled" : ""}
                    onChangePhoneValue={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        phoneNumber: value,
                      });
                    }}
                    onChangeExtensionValue={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        phoneAdd: value,
                      });
                    }}
                    hasExtension
                  />
                </Col>
                <Col>
                  <InputPhone
                    tabIndex={9}
                    id={"id-fax-number-input"}
                    name={"faxNumber"}
                    phoneLabel="Fax Number"
                    phoneValue={agencyInformation?.faxNumber ?? ""}
                    readOnly={readOnly}
                    errorMessagePhone={errorDetails?.faxNumber}
                    className={readOnly ? "true_information_disabled" : ""}
                    onChangePhoneValue={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        faxNumber: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  {!readOnly ? (
                    <Input
                      tabIndex={10}
                      id="id-website-input"
                      name="websiteUrl"
                      label="Website URL"
                      value={agencyInformation?.websiteURL ?? ""}
                      readOnly={readOnly}
                      errorMessage={errorDetails?.websiteURL}
                      className={readOnly ? "true_information_disabled" : ""}
                      maxLength={100}
                      onChangeRawValue={(value) => {
                        setAgencyInformation({
                          ...agencyInformation,
                          websiteURL: value,
                        });
                      }}
                    />
                  ) : (
                    <div style={{ width: "100%" }}>
                      <div>
                        <FontBold
                          fontType="BOLD_CAPTION"
                          name="websiteUrlLabel"
                        >
                          Website URL
                        </FontBold>
                      </div>
                      <Link
                        tabIndex={10}
                        true-element="true-element-link-insured-website-url"
                        href={getCorrectURL(agencyInformation?.websiteURL)}
                        target="_blank"
                        rel="noopener"
                      >
                        {agencyInformation?.websiteURL}
                      </Link>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              {...colWithNoMarginNorGutter}
              breakpoints={{ md: 4, lg: 4, xl: 4 }}
            >
              <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                <Col>
                  <Input
                    tabIndex={11}
                    id="id-agency-code-input"
                    name="agencyCode"
                    label="Agency Code"
                    value={agencyInformation?.agencyCode ?? ""}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.agencyCode}
                    maxLength={10}
                    className={readOnly ? "true_information_disabled" : ""}
                    onChangeRawValue={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        agencyCode: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <InputTax
                    tabIndex={12}
                    id="id-agency-tax-id-input"
                    name="agencyTaxId"
                    label="Tax ID"
                    value={agencyInformation?.taxId ?? ""}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.taxIdType}
                    className={readOnly ? "true_information_disabled" : ""}
                    onChangeRaw={({ value, type }) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        taxId: value,
                        taxIdType: type,
                      });
                    }}
                    initialType={"FEIN"}
                  />
                </Col>
                <Col>
                  <Select
                    id="information-bussines-type"
                    tabIndex={13}
                    label={"Business Type"}
                    labelFontType="BOLD_CAPTION"
                    name={"businessType"}
                    inputWidth={"100%"}
                    options={businessType}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.businessType}
                    value={agencyInformation?.businessType}
                    onChange={(value) =>
                      setAgencyInformation({
                        ...agencyInformation,
                        businessType: value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <InputDate
                    tabIndex={14}
                    id="id-agency-date-opened-input"
                    name="agencyDateOpened"
                    label="Date Opened"
                    value={agencyInformation?.dateOpened}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.dateOpened}
                    className={
                      readOnly
                        ? `true_information_disabled ${localOptions.themeRefresh}`
                        : `${localOptions.themeRefresh}`
                    }
                    onChangeRawValue={(value) =>
                      setAgencyInformation({
                        ...agencyInformation,
                        dateOpened: value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <Select
                    id="information-agency-status"
                    tabIndex={15}
                    label={"Status"}
                    labelFontType="BOLD_CAPTION"
                    name={"agencyStatus"}
                    inputWidth={"100%"}
                    options={statusTypes}
                    readOnly={readOnly}
                    errorMessage={errorDetails?.status}
                    value={agencyInformation?.status}
                    onChange={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        status: value,
                      });
                    }}
                  />
                </Col>
                <Col horizontalAlign="flex-start">
                  <Switch
                    tabIndex={16}
                    label={"Require 1099?"}
                    labelPlacement="top"
                    labelFontType="BOLD_CAPTION"
                    control="checkbox"
                    name={"require1099"}
                    isChecked={agencyInformation?.isRequired1099 ?? false}
                    readOnly={readOnly}
                    onChangeIsChecked={(value) => {
                      setAgencyInformation({
                        ...agencyInformation,
                        isRequired1099: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Box sx={{ mt: 2, textAlign: "left" }}>
            {hasPermission && (
              <>
                {!readOnly && (
                  <Button onClick={save} tabIndex={17} name={"saveAgency"}>
                    Save
                  </Button>
                )}
                {readOnly && (
                  <Button
                    onClick={() => setReadOnly(false)}
                    tabIndex={18}
                    name="editAgency"
                  >
                    Edit
                  </Button>
                )}
                <Button
                  sx={{ ml: 2 }}
                  variantStyle="outlined"
                  onClick={() => setIsDialogConfirmationOpen(true)}
                  tabIndex={19}
                  name="cancelAgency"
                >
                  {!readOnly && hasAgencyChanges() ? "Cancel" : "Close"}
                </Button>
              </>
            )}
          </Box>
          <DialogConfirmation
            id="agencyInformationConfirmation"
            open={isDialogConfirmationOpen}
            dialogDescriptionText={
              !readOnly && hasAgencyChanges()
                ? "Are you sure you want to cancel and lose your work?"
                : "Do you want to return to the agency grid?"
            }
            onCloseEvent={(close) => {
              setIsDialogConfirmationOpen(close);
            }}
            onOptionNoEvent={(close) => {
              setIsDialogConfirmationOpen(close);
            }}
            onOptionYesEvent={dialogConfirmationCloseOrCancel}
          />
        </>
      ) : (
        <div> Loading ...</div>
      )}
    </>
  );
};

export default Information;
