import { IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { CustomFieldTypeEnum } from "../../../../../dtos/custom-field-type-enum";
import {
  formattingKey,
  FromEnumToArrayWithExceptions,
  selectOptionsByEnumValues,
} from "../../../../../utilities/enumFunctions";
import { StatusEnums } from "../../../../../dtos/status-enums";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../../TrueUI";
import { addAllOption } from "../../../../../utilities/selectFunctions";
import CustomFieldModal from "./CustomFieldModal";
import { CustomFieldDefinitionDto } from "../../../../../dtos/custom-field-definition-dto";
import { CustomFieldDefinitionTableDto } from "../../../../../dtos/custom-field-definition-table-dto";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { getColumnIndexByColumnName } from "../../../../TrueUI/Tables/tableFunctions";
import { getSafeStringToBool } from "../../../../../utilities/stringFunctions";

type CustomFieldsType = {
  customFieldValues: CustomFieldDefinitionTableDto;
  refreshTable: () => void;
};

const CustomFields: FC<CustomFieldsType> = ({
  customFieldValues,
  refreshTable,
}) => {
  const tableName = "custom-fields-definition-table";

  const [showInHeaderList, setShowInHeaderList] = useState<number[]>([]);

  const [fieldTypeFilter, setFieldTypeFilter] = useState<number>(
    CustomFieldTypeEnum.INSURED
  );
  const [customFieldModalValues, setCustomFieldModalValues] =
    useState<CustomFieldDefinitionDto>();

  const rowDataToModalData = (row: any) => {
    setCustomFieldModalValues({
      customFieldDefinitionId: row.CustomFieldDefinitionId,
      controlType: row.ControlTypeId,
      program: row.ProgramId,
      fieldType: row.FieldType,
      fieldLabel: row.FieldLabel,
      fieldStatus: row.StatusId,
      dataType: row.DataTypeId,
      dataSize: row.DataSize,
      dataPrecision: row.Precision,
      controlSize: row.ControlSize,
      controlOrder: row.ControlOrder,
      showInHeader: row.ShowInHeader,
      listSourceType: row.ListSourceType,
      listSource: row.ListSource,
    });
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => rowDataToModalData(actionOptions.row)}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const fieldTypeOptions = selectOptionsByEnumValues(CustomFieldTypeEnum);

  const statusOptions = addAllOption(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName:
          enumType?.value == StatusEnums.ACTIVE
            ? "Active Only"
            : enumType?.key ?? "",
        stringValue: enumType?.key ?? "",
      })
    )
  );

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: customFieldValues.tableData.columns,
      data: customFieldValues.tableData.data,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    filterOptions: [
      (actionOptions) => {
        return BaseTableDropdownFilter({
          id: "field-type-filter",
          filterOptions: actionOptions,
          columnsNames: ["FieldType"],
          optionsOverride: fieldTypeOptions,
          defaultValue: CustomFieldTypeEnum.INSURED,
          labelText: "Field Type",
          onChange: (option) => setFieldTypeFilter(option),
        });
      },
      (actionOptions) => {
        return BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["Status"],
          defaultValue: formattingKey(StatusEnums[StatusEnums.ACTIVE]),
          optionsOverride: statusOptions,
          labelText: "Status",
        });
      },
    ],
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showEditButton: false,
      addButtonText: "NEW FIELD",
    },
    events: {
      addEventOverrideCallback: () =>
        setCustomFieldModalValues({
          customFieldDefinitionId: 0,
          dataSize: 10,
          dataPrecision: 2,
          controlOrder: 0,
          showInHeader: false,
        }),
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    const showInHeaderIdx = getColumnIndexByColumnName(
      "ShowInHeader",
      customFieldValues.tableData.columns
    );
    const customFieldDefinitionIdIdx = getColumnIndexByColumnName(
      "CustomFieldDefinitionId",
      customFieldValues.tableData.columns
    );
    const showInHeaderListTmp: number[] = [];
    customFieldValues.tableData.data.forEach((row) => {
      if (getSafeStringToBool(row[showInHeaderIdx])) {
        showInHeaderListTmp.push(parseInt(row[customFieldDefinitionIdIdx]));
      }
    });
    setShowInHeaderList(showInHeaderListTmp);
  }, []);

  return (
    <>
      <BaseTable2 name={tableName} />
      {conditionHasValue(customFieldModalValues?.customFieldDefinitionId) && (
        <CustomFieldModal
          customFieldData={customFieldModalValues}
          setCustomFieldData={setCustomFieldModalValues}
          programs={customFieldValues.programList}
          fieldTypeFilter={fieldTypeFilter}
          refreshTable={refreshTable}
          showInHeaderList={showInHeaderList}
          programsWithShowInHeader={customFieldValues.programsWithShowInHeader}
        />
      )}
    </>
  );
};
export default CustomFields;
