import { PolicyExposureBlob } from "../../../../../../dtos/policy-exposure-blob";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../../../dtos/policy-state-blob";
import { QuoteRatingTypeEnum } from "../../../../../../dtos/quote-rating-type-enum";
import { RateElementDto } from "../../../../../../dtos/rate-element-dto";
import { GlobalInsuredAtomProperties } from "../../../../InsuredAtoms";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
} from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import {
  getDateObject,
  isDateEqualDate,
} from "../../../../../../utilities/dateFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { PolicyLimitDto } from "../../../../../../dtos/policy-limit-dto";
import { sumAllValuesOfAnArray } from "../../../../../../utilities/arrayFunctions";
import { getDisplayNameByOptionId } from "../../../../../../utilities/selectFunctions";
import { DiscountDto } from "../../../../../../dtos/discount-dto";
import { customRound } from "../../../../../../utilities/stringFunctions";
import { PolicyLimitsOption } from "../../../../../../dtos/policy-limits-option";
import { RateOptionElementDto } from "../../../../../../dtos/rate-option-element-dto";
import { RateElementsConfigurationDto } from "../../../../../../dtos/rate-elements-configuration-dto";
import { RatingRateOption } from "../../../../../../dtos/rating-rate-option";
import {
  AGENCY_DISCOUNT_ROW_NAME,
  BALANCE_MIN_ROW_NAME,
  DISCOUNT_EDITABLE_ROW_NAME,
  DISCOUNT_ROW_NAME,
  ENTERED_AMOUNT_ROW_NAME,
  ENTERED_RATE_ROW_NAME,
  EXPENSE_CONSTANT_ROW_NAME,
  LCM_ROW_NAME,
  MIN_PREM_ROW_NAME,
  OPTIONAL_ROW_NAME,
  POLICY_LIMITS_ROW_NAME,
  RATING_ROW_NAME,
  REGION_ROW_NAME,
  REQUIRED_ROW_NAME,
  SCHEDULE_FORM_ROW_NAME,
  SELECTION_ROW_NAME,
  SPECIFIC_WAIVER_FORM_ROW_NAME,
  X_MOD_ROW_NAME,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { PolicyReferenceOption } from "../../../../../../dtos/policy-reference-option";
import { isEmptyValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { PolicyReferenceDto } from "../../../../../../dtos/policy-reference-dto";
import { divideAndRound } from "../../../../../../utilities/mathFunctions";
import { sumExposuresAmount } from "../../../PolicyQuoteExposurePremium/ExposurePremiumUtils";

export const PREMIUM_BASIS_PREVIOUS = 0;

const getRatingsUpdatedByRowResult = (
  rateIndex: number,
  rowResult: PremiumTableRowResultProps,
  state?: PolicyStateBlob
) => {
  const newRatings = state?.ratings?.map((rating, index) => {
    if (rateIndex === index) {
      return {
        ...rating,
        rate: rowResult.rate,
        amount: rowResult.calculatedAmount,
        runningTotal: rowResult.runningTotal,
        optionalElement: rowResult.optionalElement,
        policyLimits: rowResult.policyLimits,
        rateOption: rowResult.rateOption,
        policyReference: rowResult.policyReference,
        scheduleRatings: rowResult.scheduleRatings,
      };
    } else {
      return { ...rating };
    }
  });

  return newRatings;
};

export const getStatesUpdatedByRowResult = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rowResult: PremiumTableRowResultProps,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        ratings: getRatingsUpdatedByRowResult(rateIndex ?? 0, rowResult, state),
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

const getPolicyWaiversByTable = (data: string[][]) => {
  if (data.length > 0) {
    return data.map((row) => ({
      waiverID: parseInt(row?.[0]),
      issueDate: getDateObject(row?.[1]),
      companyName: row?.[2],
      jobName: row?.[3],
      premiumCharge: row?.[4],
    }));
  }
  return [];
};

export const getStatesUpdatedByRowResultAndWaivers = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rowResult: PremiumTableRowResultProps,
  policyWaiverTable: string[][] | null,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);
  const policyWaivers = getPolicyWaiversByTable(policyWaiverTable ?? []);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        ratings: getRatingsUpdatedByRowResult(rateIndex ?? 0, rowResult, state),
        waivers: isEmptyValue(policyWaivers) ? state?.waivers : policyWaivers,
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getStatesUpdatedByRatingList = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  ratings: PolicyRatingBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        ratings: ratings,
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getRateElementAsRatingList = (
  rateElements: RateElementDto[],
  elementTypeList: SelectOptions[] | Partial<SelectOptions>[]
) => {
  const newRatings = rateElements.map((rateElement) => {
    return {
      rateElementID: rateElement.rateElementID,
      elementName: rateElement.elementName,
      elementType: rateElement.elementType,
      elementTypeValue: getDisplayNameByOptionId(
        rateElement.elementType,
        elementTypeList
      ),
      elementCategory: rateElement.elementCategory,
      premiumBasis: rateElement.premiumBasis,
      premiumBasisValue: getDisplayNameByOptionId(
        rateElement.premiumBasis,
        elementTypeList
      ),
      statCode: rateElement.statCode,
      sourceName: rateElement.sourceName,
      balanceToMinCode: rateElement.balanceToMinCode,
      rate: rateElement.elementRate,
      rateBasis: rateElement.rateType,
      amount: 0,
      runningTotal: 0,
      proRate: rateElement.proRate,
      minimumValue: rateElement.minimumValue,
      maximumValue: rateElement.maximumValue,
      rounding: rateElement.rounding,
      effectiveDate: rateElement.effectiveDate,
      expirationDate: rateElement.expirationDate,
    } as PolicyRatingBlob;
  });

  return newRatings;
};

export const getConfigurationForColumn = (
  columnSpan: number,
  horizontalAlign: "flex-start" | "flex-end" | "center",
  verticalAlign: "flex-start" | "flex-end" | "center",
  className?: string
) => {
  return {
    breakpoints: { md: columnSpan, lg: columnSpan, xl: columnSpan },
    horizontalAlign: horizontalAlign,
    verticalAlign: verticalAlign,
    className,
  };
};

export const getInitialRowResult = (rating: PolicyRatingBlob) => {
  return {
    calculatedAmount: rating.amount ?? 0,
    runningTotal: rating.runningTotal ?? 0,
    rate:
      rating.elementType === QuoteRatingTypeEnum.ENTERED_AMOUNT
        ? rating.rate ?? 0
        : rating.rate ?? 1,
    optionalElement: rating.optionalElement ?? false,
  };
};

export const sumManualPremium = (exposures?: PolicyExposureBlob[]) => {
  return exposures !== undefined && exposures?.length > 0
    ? exposures
        .map((exposure) => exposure?.manualPremiumAtMod ?? 0)
        .reduce((a, b) => (a ?? 0) + (b ?? 0))
    : 0;
};

export const sumRunningTotalBySpecificPremiumBasis = (
  premiumBasis: QuoteRatingTypeEnum,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const states = getPolicyQuoteStates(atomValue?.policyQuoteInformation);
  const sumRunningTotal = states.reduce((previousValue, state) => {
    const runningTotal =
      state?.ratings?.find(
        (currentRating) => currentRating.elementType === premiumBasis
      )?.runningTotal ?? 0;
    return previousValue + runningTotal;
  }, 0);
  return sumRunningTotal;
};

export const getPreviousRunningTotal = (
  rateIndex: number,
  ratingList?: PolicyRatingBlob[] | null
) => {
  const previousRates = ratingList?.filter(
    (rating, index) =>
      index < rateIndex &&
      Object.values(QuoteRatingTypeEnum).includes(rating.elementType ?? 0)
  );

  const runningTotal =
    previousRates != undefined && previousRates.length > 0
      ? previousRates[previousRates.length - 1].runningTotal ?? 0
      : 0;

  // Use this implementation when all ratings are completed.
  // const previousRating = ratingList?.find(
  //   (rating, index) => rating && index === rateIndex - 1
  // );

  // const runningTotal =
  //   previousRating?.runningTotal !== undefined
  //     ? previousRating.runningTotal
  //     : 0;

  return runningTotal;
};

export const getRunningTotalByPremiumBasisRow = (
  rateIndex: number,
  rating: PolicyRatingBlob,
  currentState?: PolicyStateBlob
) => {
  const runningTotal =
    rating.premiumBasis === PREMIUM_BASIS_PREVIOUS
      ? getPreviousRunningTotal(rateIndex ?? 0, currentState?.ratings)
      : currentState?.ratings?.find(
          (currentRating) => currentRating.rateElementID === rating.premiumBasis
        )?.runningTotal;

  return runningTotal ?? 0;
};

export const getRunningTotalByTotalExposure = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode ?? "",
    effectiveDate ?? new Date(),
    expirationDate ?? new Date(),
    atomValue
  );
  const runningTotal = sumExposuresAmount(currentState?.exposures ?? []);
  return (runningTotal ?? 0) / 100;
};

const getPolicyLimitAmountByBalanceToMin = (
  ratingList?: PolicyRatingBlob[] | null
) => {
  const policyLimitIndex =
    ratingList?.findIndex(
      (rating) => rating.elementType === QuoteRatingTypeEnum.POLICY_LIMITS
    ) ?? 0;

  const amount = ratingList?.[policyLimitIndex]
    ? ratingList[policyLimitIndex].amount ?? 0
    : 0;

  return amount;
};

export const getRatingsUpdatedByAtomValue = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );

  const newRatings = currentState?.ratings?.filter(
    (rating) =>
      ({
        ...rating,
        amount: rating.amount,
        runningTotal: rating.runningTotal,
        rate: rating.rate,
        rateOption: rating.rateOption,
        policyLimits: rating.policyLimits,
        policyReference: rating.policyReference,
      } as PolicyRatingBlob)
  );

  return newRatings;
};

export const getPreviousRating = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  if (rateIndex > 0 && (currentState?.ratings?.length ?? 0) > rateIndex) {
    const previousRating = currentState?.ratings?.[rateIndex - 1];

    return previousRating ? previousRating : null;
  }

  return null;
};

export const getSumOfAllPolicyLimitAmount = (states: PolicyStateBlob[]) => {
  const amountOfAllStates = states.map((state) => {
    if (state.ratings !== undefined && state.ratings !== null) {
      return getPolicyLimitAmountByBalanceToMin(state.ratings);
    } else return 0;
  });

  const sumOfAllPremiumBasis = sumAllValuesOfAnArray(amountOfAllStates);

  return sumOfAllPremiumBasis;
};

export const getPolicyLimitAsSelectOptionList = (
  policyLimits: PolicyLimitDto[]
) => {
  return policyLimits.map((policyLimit) => ({
    displayName: policyLimit.displayValue,
    intValue: policyLimit.id ?? 0,
  })) as Partial<SelectOptions>[];
};

export const getDefaultOptionForPolicyLimits = (
  options: PolicyLimitDto[],
  selectedPolicyLimit?: PolicyLimitsOption | null,
  defaultPolicyLimit?: PolicyLimitDto | null
) => {
  const defaultOption = options.find(
    (option) => option.id === selectedPolicyLimit?.policyLimitsID
  );

  if (defaultOption !== undefined) return defaultOption.id;
  else if (options.length > 0) {
    const defaultOptionFromPolicyInformation = options.find(
      (option) =>
        option.eachAccident === defaultPolicyLimit?.eachAccident &&
        option.policyLimit === defaultPolicyLimit.policyLimit &&
        option.eachEmployee === defaultPolicyLimit.eachEmployee
    );

    if (defaultOptionFromPolicyInformation !== undefined)
      return defaultOptionFromPolicyInformation.id;
    else return options[0].id;
  } else return 0;
};

export const getConfigurationListBySourceName = (
  sourceName: string,
  configuration?: RateElementsConfigurationDto | null
) => {
  if (configuration !== undefined && configuration !== null) {
    return configuration?.rateOptions.filter(
      (rateOption) => rateOption.sourceName === sourceName
    );
  }
  return [];
};

export const getRateOptionAsSelectOptionList = (
  sourceName: string,
  configuration?: RateElementsConfigurationDto | null
) => {
  if (configuration !== undefined && configuration !== null) {
    const optionsBySourceName = getConfigurationListBySourceName(
      sourceName,
      configuration
    );

    const filteredOptionsAsSelectOptions = optionsBySourceName.map(
      (rateOption) => ({
        displayName: rateOption.displayValue,
        intValue: rateOption.id ?? 0,
      })
    ) as Partial<SelectOptions>[];

    return {
      selectOptions: filteredOptionsAsSelectOptions,
      filteredOptions: optionsBySourceName,
    };
  }
  return { selectOptions: [], filteredOptions: [] };
};

export const getPolicyReferenceAsSelectOptionList = (
  policyReferenceList: PolicyReferenceDto[]
) => {
  return policyReferenceList.map((policyReference) => ({
    displayName: policyReference.value1,
    intValue: policyReference.valueN ?? 0,
  })) as SelectOptions[];
};

export const getDefaultOptionForRateOptionList = (
  options: RateOptionElementDto[],
  selectedRateOption?: RatingRateOption | null
) => {
  const defaultOption = options.find(
    (option) => option.id === selectedRateOption?.rateOptionID
  );

  if (defaultOption !== undefined) return defaultOption.id;
  else if (options.length > 0) {
    return options[0].id;
  } else return 0;
};

export const getDefaultOptionForPolicyReferenceList = (
  options: SelectOptions[],
  selectedPolicyReference?: PolicyReferenceOption | null
) => {
  const defaultOption = options.find(
    (option) => option.intValue === selectedPolicyReference?.policyReferenceID
  );

  if (defaultOption !== undefined) return defaultOption.intValue ?? -1;
  else if (options.length > 0) {
    return options[0].intValue ?? -1;
  } else return -1;
};

export const getProRateCalculation = (
  value: number,
  numberOfDaysInYear: number,
  numberOfDaysInPolicy: number
) => (numberOfDaysInPolicy / numberOfDaysInYear) * value;

export const getDifferenceBetweenMinimumPremiumAndRunningTotal = (
  previousRunningTotal: number,
  minimumPremium: number
) => {
  if (minimumPremium - previousRunningTotal > 0)
    return minimumPremium - previousRunningTotal;
  else return 0;
};

const getHighestManualPremiumExposureByState = (
  states: PolicyStateBlob[],
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date
) => {
  const NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING = -10000000000000;

  const currentState = states.find(
    (state) =>
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
  );

  const exposuresOrderedByPremiumFromHighestToLower =
    currentState?.exposures
      ?.slice()
      ?.sort(
        (a, b) =>
          (b.manualPremiumAtMod ?? NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING) -
          (a.manualPremiumAtMod ?? NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING)
      ) ?? [];

  const exposureWithHighestManualPremium =
    exposuresOrderedByPremiumFromHighestToLower.length > 0
      ? exposuresOrderedByPremiumFromHighestToLower[0]
      : null;

  return exposureWithHighestManualPremium;
};

export const isCurrentStateTheGoverningState = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  if (states.length > 0) {
    const statesAndHighestManualPremium = states.map((state) => {
      const exposureWithHighestPremium = getHighestManualPremiumExposureByState(
        states,
        state.stateCode ?? "",
        state.effectiveDate ?? new Date(),
        state.expirationDate ?? new Date()
      );
      const classCodeWithSuffix = `${
        exposureWithHighestPremium?.classCode ?? ""
      }${exposureWithHighestPremium?.classSuffix ?? ""}`;
      return {
        stateCode: state.stateCode ?? "",
        effectiveDate: state.effectiveDate ?? new Date(),
        expirationDate: state.expirationDate ?? new Date(),
        highestManualPremium:
          exposureWithHighestPremium?.manualPremiumAtMod ?? 0,
        classCodeWithHighestPremium: classCodeWithSuffix,
      };
    }); //[{stateCode: "MD", effectiveDate: 1/1/1, expirationDate: 1/1/1, highestManualPremium: 2000, classCode: "0010"}, {stateCode: "NV",effectiveDate: 1/1/1, expirationDate: 1/1/1, highestManualPremium: 1000, classCode: "0010"}]

    const statesSortedByHighestManualPremiumDesc =
      statesAndHighestManualPremium.sort((a, b) => {
        if (a.highestManualPremium === b.highestManualPremium) return 0;
        if (a.highestManualPremium < b.highestManualPremium) return 1;
        return -1;
      });

    const stateWithHighestManualPremium =
      statesSortedByHighestManualPremiumDesc[0];

    if (
      stateWithHighestManualPremium.stateCode === stateCode &&
      stateWithHighestManualPremium.effectiveDate === effectiveDate &&
      stateWithHighestManualPremium.expirationDate === expirationDate
    )
      return {
        isGoverningState: true,
        classCodeWithHighestPremium:
          stateWithHighestManualPremium.classCodeWithHighestPremium,
      };
    else
      return {
        isGoverningState: false,
        classCodeWithHighestPremium: "",
      };
  }
  return {
    isGoverningState: false,
    classCodeWithHighestPremium: "",
  };
};

export const isCurrentStateTheStateWithHighestMinimumPremium = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  if (states.length > 0) {
    const statesAndMinimumPremium = states.map((state) => {
      if (state.ratings !== undefined && state.ratings !== null) {
        const minPremiumValue =
          state.ratings.find(
            (rating) => rating.elementType === QuoteRatingTypeEnum.MIN_PREM
          )?.minimumValue ?? -1000000;

        return {
          stateCode: state.stateCode,
          effectiveDate: state.effectiveDate ?? new Date(),
          expirationDate: state.expirationDate ?? new Date(),
          minimumPremium: minPremiumValue,
        };
      } else return { stateCode: state.stateCode, minimumPremium: -1000000 };
    }); //[{stateCode: "MD", effectiveDate: 1/1/1, expirationDate: 1/1/1, minimumPremium: 2000}, {stateCode: "NV", effectiveDate: 1/1/1, expirationDate: 1/1/1, minimumPremium: 1000}]

    const statesSortedByHighestMinimumPremiumDesc =
      statesAndMinimumPremium.sort((a, b) => {
        if (a.minimumPremium === b.minimumPremium) return 0;
        if ((a.minimumPremium ?? 0) < (b.minimumPremium ?? 0)) return 1;
        return -1;
      });

    const stateWithHighestMinimumPremium =
      statesSortedByHighestMinimumPremiumDesc[0];

    if (
      stateWithHighestMinimumPremium.stateCode === stateCode &&
      stateWithHighestMinimumPremium.effectiveDate === effectiveDate &&
      stateWithHighestMinimumPremium.expirationDate === expirationDate
    )
      return {
        isStateWithHMP: true,
        stateCode: stateWithHighestMinimumPremium.stateCode,
        effectiveDate: stateWithHighestMinimumPremium.effectiveDate,
        expirationDate: stateWithHighestMinimumPremium.expirationDate,
      };
    else
      return {
        isStateWithHMP: false,
        stateCode: stateWithHighestMinimumPremium.stateCode,
        effectiveDate: stateWithHighestMinimumPremium.effectiveDate,
        expirationDate: stateWithHighestMinimumPremium.expirationDate,
      };
  }
  return {
    isStateWithHMP: true,
    stateCode: null,
    effectiveDate: null,
    expirationDate: null,
  };
};

export const getSumOfPremiumBasisForSpecifiedRatingMultiState = (
  states: PolicyStateBlob[],
  specificRating: PolicyRatingBlob
) => {
  const premiumBasisOfAllStates = states.map((state) => {
    if (
      state.ratings !== undefined &&
      state.ratings !== null &&
      state.ratings.length > 0
    ) {
      const premiumBasisAndIndex = state.ratings.map((rating, index) => {
        return rating.elementType === specificRating.elementType
          ? { rating: rating, ratingIndex: index }
          : null;
      });

      const ratingResult = premiumBasisAndIndex.find(
        (object) => object !== null
      );

      if (
        ratingResult?.rating.premiumBasis !== undefined &&
        ratingResult.ratingIndex !== undefined
      ) {
        const runningTotal =
          ratingResult?.rating.premiumBasis === PREMIUM_BASIS_PREVIOUS
            ? getPreviousRunningTotal(
                ratingResult.ratingIndex ?? 0,
                state.ratings
              )
            : state.ratings.find(
                (currentRating) =>
                  currentRating.rateElementID ===
                  ratingResult?.rating.premiumBasis
              )?.runningTotal;

        return runningTotal ?? 0;
      }
      return 0;
    }
    return 0;
  });

  const sumOfAllPremiumBasis = sumAllValuesOfAnArray(premiumBasisOfAllStates);

  return sumOfAllPremiumBasis;
};

export const isLastRating = (
  rateElements: PolicyRatingBlob[],
  rateIndex: number
) => rateElements.length - 1 === rateIndex;

export const calculatedResultForDiscountTable = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const discountRate = discountRates?.filter(
    (discount) =>
      standardPremium > (discount?.lowThreshold ?? 0) &&
      standardPremium < (discount?.highThreshold ?? 0)
  );
  const discountRateValue = discountRate[0]
    ? discountRate[0].discountRate ?? 0
    : 0;
  const rateToDivide = 100 - discountRateValue;
  const rate = customRound(divideAndRound(rateToDivide, 100).toString(), 3);
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: rate,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const calculatedResultForDiscountTier = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const discounts: [{ total?: number; discountRate?: number }] = [{}];
  for (let index = 0; index < discountRates.length; index++) {
    const discountAvg = (discountRates[index].discountRate ?? 0) / 100;
    if (standardPremium > (discountRates[index].highThreshold ?? 0)) {
      if (index === 0) {
        discounts.pop();
        discounts.push({
          total: discountRates[index].highThreshold ?? 0,
          discountRate: discountRates[index].discountRate ?? 0,
        });
      } else {
        const total =
          (discountRates[index].highThreshold ?? 0) -
          (discountRates[index - 1].highThreshold ?? 0);
        const discountRate = total * discountAvg;
        discounts.push({ total, discountRate });
      }
    } else {
      if (index === 0) {
        discounts.pop();
      }
      const prev = discounts.reduce(
        (acc, discount) => (discount?.total ?? 0) + acc,
        0
      );
      const total = standardPremium - prev;
      const discountRate = total * discountAvg;
      discounts.push({ total, discountRate });
      break;
    }
  }

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (
      runningTotalByPremiumBasis -
      discounts.reduce(
        (acc, discount) => (discount?.discountRate ?? 0) + acc,
        0
      )
    ).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: 0,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const calculatedResultForAgencyDiscount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  if ((discountRates[0]?.discountOverride ?? false) === false) {
    const rowResult: PremiumTableRowResultProps =
      calculatedResultForDiscountTable(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        standardPremium,
        rating,
        discountRates,
        atomValue
      );
    return rowResult;
  } else {
    const currentState = getStateByStateCodeAndDates(
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );
    const discountRateValue = discountRates?.[0].discountRate ?? 0;
    const rateToDivide = 100 - discountRateValue;
    const rate = customRound(divideAndRound(rateToDivide, 100).toString(), 3);

    const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
      rateIndex,
      rating,
      currentState
    );
    const previousRunningTotal = getPreviousRunningTotal(
      rateIndex,
      currentState?.ratings
    );
    const runningTotalCalculated = customRound(
      (runningTotalByPremiumBasis * rate).toString(),
      rating.rounding ?? 0
    );
    const calculatedAmount = customRound(
      (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
      rating.rounding ?? 0
    );
    const newRunningTotal = previousRunningTotal + calculatedAmount;

    const rowResult: PremiumTableRowResultProps = {
      rate: rate,
      runningTotal: newRunningTotal,
      calculatedAmount: calculatedAmount,
    };
    return rowResult;
  }
};

export const calculatedResultForDiscountEditableTable = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  isRateEdited: boolean,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const discountRate = discountRates?.filter(
    (discount) =>
      standardPremium > (discount.lowThreshold ?? 0) &&
      standardPremium < (discount.highThreshold ?? 0)
  );
  const discountRateValue = discountRate[0]
    ? discountRate[0].discountRate ?? 0
    : 0;
  const rateToDivide = 100 - discountRateValue;
  const calculatedRate = isRateEdited
    ? rate
    : customRound(divideAndRound(rateToDivide, 100).toString(), 3);

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * calculatedRate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: calculatedRate,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const getRowAtomKeyByRatingTypeAndRateElementId = (
  insuredId: number,
  ratingType: number,
  rateElementId: number
) => {
  switch (
    ratingType // TODO: add more cases for the rows that are pending.
  ) {
    case QuoteRatingTypeEnum.OPTION:
      return `${insuredId}_${OPTIONAL_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.SELECTION:
      return `${insuredId}_${SELECTION_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.X_MOD:
      return `${insuredId}_${X_MOD_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.MANUAL_PREMIUM:
    case QuoteRatingTypeEnum.SUBJECT_PREMIUM:
    case QuoteRatingTypeEnum.MODIFIED_PREMIUM:
    case QuoteRatingTypeEnum.STANDARD_PREMIUM:
    case QuoteRatingTypeEnum.ESTIMATED_ANNUAL_PREMIUM:
      return `${insuredId}_${RATING_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.ENTERED_RATE:
      return `${insuredId}_${ENTERED_RATE_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.SCHEDULE_FORM:
      return `${insuredId}_${SCHEDULE_FORM_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.MIN_PREM:
      return `${insuredId}_${MIN_PREM_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.DISCOUNT_TABLE:
    case QuoteRatingTypeEnum.DISCOUNT_TIER:
      return `${insuredId}_${DISCOUNT_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.AGENCY_DISCOUNT:
      return `${insuredId}_${AGENCY_DISCOUNT_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.DISCOUNT_TABLE_EDIT:
      return `${insuredId}_${DISCOUNT_EDITABLE_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.EXPENSE_CONSTANT:
      return `${insuredId}_${EXPENSE_CONSTANT_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.REQUIRED:
    case QuoteRatingTypeEnum.EXPOSURE_BASIS:
      return `${insuredId}_${REQUIRED_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.LCM:
      return `${insuredId}_${LCM_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.EL_BALANCE_TO_MIN:
      return `${insuredId}_${BALANCE_MIN_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.ENTERED_AMOUNT:
      return `${insuredId}_${ENTERED_AMOUNT_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.POLICY_LIMITS:
      return `${insuredId}_${POLICY_LIMITS_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.SPECIFIC_WAIVER_FORM:
      return `${insuredId}_${SPECIFIC_WAIVER_FORM_ROW_NAME}_${rateElementId}`;
    case QuoteRatingTypeEnum.REGION:
      return `${insuredId}_${REGION_ROW_NAME}_${rateElementId}`;
    default:
      return `${insuredId}_${OPTIONAL_ROW_NAME}_${rateElementId}`;
  }
};

export const getDependantRatingsByRatingType = (
  insuredId: number,
  rateElements: PolicyRatingBlob[]
) => {
  const dependantRatingTypesByRatingTypes = rateElements.map(
    (rateElement, index) => {
      const rateElementId = rateElement.rateElementID ?? 0;

      if (index !== rateElements.length - 1) {
        const nextRateElement = rateElements[index + 1];

        const nextRatingAtomKey = getRowAtomKeyByRatingTypeAndRateElementId(
          insuredId,
          nextRateElement.elementType ?? 0,
          nextRateElement.rateElementID ?? 0
        );

        return {
          rateElementId,
          dependantRatingTriggerComponentNames: [nextRatingAtomKey],
        };
      }

      return {
        rateElementId,
        dependantRatingTriggerComponentNames: [],
      };
    }
  );

  return dependantRatingTypesByRatingTypes;
};

export const getAnyOtherStateStartOfCalculationsRowKeyCollection = (
  insuredId: number,
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  const anyOtherStateStartOfCalculationsRowKeyCollection = states.map(
    (state) => {
      if (
        state.stateCode !== stateCode ||
        state.effectiveDate !== effectiveDate ||
        state.expirationDate !== expirationDate
      ) {
        const firstRating = state.ratings?.[0];
        if (firstRating !== undefined && firstRating !== null)
          return getRowAtomKeyByRatingTypeAndRateElementId(
            insuredId,
            firstRating.elementType ?? -1,
            firstRating.rateElementID ?? -1
          );

        return null;
      }
      return null;
    }
  );
  const filteredAtomKeys =
    anyOtherStateStartOfCalculationsRowKeyCollection.filter(
      (rowAtomKey) => rowAtomKey !== null
    );

  return filteredAtomKeys as string[];
};

export const getNameToExposureTableColumns = (
  props: PremiumTableRowProps,
  columnIndex: 0 | 1 | 2 | 3 | 4
) => {
  const columnNames = [
    "name",
    "rate",
    "calculated-amount",
    "previous-running-total",
    "running-total",
  ];
  return `exposure-table-${props?.nameElement}-${columnNames[columnIndex]}`;
};

export const getValidatedCalculatedAmountByMinMax = (
  calculatedAmount: number,
  rating: PolicyRatingBlob
) => {
  if (
    rating.minimumValue !== undefined &&
    rating.minimumValue !== null &&
    calculatedAmount < rating.minimumValue
  ) {
    return rating.minimumValue;
  }
  if (
    rating.maximumValue !== undefined &&
    rating.maximumValue !== null &&
    calculatedAmount > rating.maximumValue
  ) {
    return rating.maximumValue;
  }

  return calculatedAmount;
};
