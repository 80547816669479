import { FC } from "react";
import { Box } from "@mui/material";
import PriorPolicy from "./Information/PriorPolicy";
import PolicyModule from "./Information/Policy";
import CurrentBilling from "./Information/CurrentBilling";
import Performance from "./Information/Performance/Performance";
import Insured from "./Information/Insured";
import InsuredContacts from "../Contacts/InsuredContactsList";
import { InsuredTabDto } from "../../dtos/insured-tab-dto";
import { Activity } from "../Activity";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import { useRecoilValue } from "recoil";
import { insuredLandingStyles } from "./InsuredLandingStyles";
import RelatedInsuredsWrapper from "./RelatedInsureds/RelatedInsuredsWrapper";
import styles from "./InsuredLandingStyles.module.css";

type InsuredBodyProps = {
  insuredObject: InsuredTabDto;
  tabKey: string;
};

const InsuredLanding: FC<InsuredBodyProps> = ({ insuredObject, tabKey }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const insuredId = insuredObject?.insuredInformation?.insuredId;
  const hasAch = insuredObject?.insuredInformation?.hasAch;
  const hasCreditCard = insuredObject?.insuredInformation?.hasCreditCard;

  return (
    <Box
      sx={insuredLandingStyles(theme)}
      className={styles.insured_landing_page_wrapper}
    >
      <div className="landing-right-container" style={{ width: "68%" }}>
        <Box className={"landing-container"}>
          <Insured insuredInformation={insuredObject?.insuredInformation} />
          <PolicyModule
            currentPolicy={insuredObject?.currentPolicy}
            tabKey={tabKey}
            insuredId={insuredId}
          />
          <PriorPolicy priorPolicyList={insuredObject?.priorPolicyList} />
          <CurrentBilling
            insuredId={insuredId}
            hasAch={hasAch}
            hasCreditCard={hasCreditCard}
          />
          <Performance insuredId={insuredId} />
          {/* <InformationCharts theme={localOptions?.themeRefresh} /> */}
        </Box>
      </div>
      <div className="landing-left-container">
        <Box className="insured-landing-contacts-container">
          <InsuredContacts
            insuredId={insuredObject?.insuredInformation?.insuredId}
            primaryContactId={insuredObject?.insuredInformation?.primaryContact}
          />
        </Box>

        <RelatedInsuredsWrapper insuredId={insuredId} tabKey={tabKey} />
        <Box className={styles.insured_activity_container} sx={{ flexGrow: 1 }}>
          <Activity
            lookupId={insuredObject?.insuredInformation?.insuredId}
            theme={theme}
          />
        </Box>
      </div>
    </Box>
  );
};

export default InsuredLanding;
