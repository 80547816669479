import { AuditWorksheetDto } from "../../../../dtos/audit-worksheet-dto";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { MultiBaseTable } from "../../../../dtos/multi-base-table";
import { MultiBaseTableGroupColumn } from "../../../../dtos/multi-base-table-group-column";
import { PolicyExposureBlob } from "../../../../dtos/policy-exposure-blob";
import { PolicyStateBlob } from "../../../../dtos/policy-state-blob";
import { SummaryClassCodeRateDto } from "../../../../dtos/summary-class-code-rate-dto";
import { isDateEqualDate } from "../../../../utilities/dateFunctions";
import {
  customRound,
  unFormatLocalString,
} from "../../../../utilities/stringFunctions";
import { DialogConfirmationProps } from "../../../TrueUI/Dialogs/DialogConfirmation";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import {
  getPolicyQuote,
  getPolicyQuoteStates,
} from "../PolicyQuoteForm/PolicyQuoteUtils";

const STATE_CODE_METADATA_INDEX = 3;

export const getGroupsForTableHeader = (
  groups: MultiBaseTableGroupColumn[]
) => {
  const map = new Map();
  const result: MultiBaseTableGroupColumn[] = [];
  for (const group of groups) {
    if (!map.has(group.groupUiidKey)) {
      map.set(group.groupUiidKey, group);
      result.push(group);
    }
  }
  return result;
};

export const getColumnsAndDataForChildrenTable = (
  groupUiidKey: string,
  groupsAndContent?: MultiBaseTableGroupColumn[]
) => {
  const allRowsPerState = groupsAndContent?.filter(
    (x) => x.groupUiidKey === groupUiidKey
  );

  const dataForSingleTable =
    allRowsPerState?.map((source: MultiBaseTableGroupColumn) => {
      return source.metaData.map((s) => s.value);
    }) ?? [];

  return {
    columns: allRowsPerState?.[0].columns ?? [],
    data: dataForSingleTable ?? [],
  };
};

export const getSummaryTargetFromMultiTable = (
  targetName: string,
  metaData: BaseTableMetaData[]
) => metaData.find((data) => data.name === targetName)?.value;

export const getNewStatesUpdatedWithExposures = (
  atomValue: GlobalInsuredAtomProperties | null,
  multiTableData: MultiBaseTable | null,
  newExposures: Map<string, PolicyExposureBlob[]>
) => {
  const policyQuote = getPolicyQuote(atomValue);
  const programStates = atomValue?.policyQuoteInformation?.programStates;
  const newStates: PolicyStateBlob[] = [];
  newExposures.forEach((value, key) => {
    const state = programStates?.find((state) => state.stateCode === key);
    const summaryGroup = multiTableData?.groups.find(
      (group) =>
        group.metaData?.[STATE_CODE_METADATA_INDEX].value === state?.stateCode
    );
    newStates.push({
      stateCode: state?.stateCode,
      stateName: summaryGroup?.groupUiidKey,
      reportCode: state?.reportCode,
      reportingBureau: state?.reportingBureau,
      lossCostMultiplier: 1,
      effectiveDate: policyQuote?.effectiveDate,
      expirationDate: policyQuote?.expirationDate,
      exposures: value,
    } as PolicyStateBlob);
  });
  return newStates;
};

const getPremiumCalculatedByLCMChanged = (
  modRate: number,
  exposureAmount: number,
  rateBasis: string
) => {
  switch (rateBasis) {
    case "Payroll":
      return customRound(
        (((exposureAmount ?? 0) / 100) * modRate).toString(),
        0
      );
    case "Per Annum":
      return customRound(((exposureAmount ?? 0) * modRate).toString(), 0);
    default:
      return null;
  }
};

export const getNewExposuresUpdated = (
  multiTableData: MultiBaseTable | null,
  classCodeRates?: SummaryClassCodeRateDto[]
): Map<string, PolicyExposureBlob[]> => {
  const stateExposureMap = new Map<string, PolicyExposureBlob[]>();
  multiTableData?.groups.forEach((row) => {
    const classCode =
      getSummaryTargetFromMultiTable("ClassCode", row.metaData) ?? "";
    const stateCode =
      getSummaryTargetFromMultiTable("StateCode", row.metaData) ?? "";
    const exposureAmount = unFormatLocalString(
      getSummaryTargetFromMultiTable("Payroll", row.metaData) ?? "0"
    );
    const classCodeRate = classCodeRates?.find(
      (ccr) =>
        ccr.classCode === classCode && ccr.classCodeRate.stateCode === stateCode
    );
    const newExposure = {
      uniqueKey: crypto.randomUUID(),
      classCodeID: classCodeRate?.classCodeRate.classCodeId,
      classCode: classCodeRate?.classCode,
      classSuffix: classCodeRate?.classSuffix,
      description: classCodeRate?.classCodeRate?.description,
      hazardGroup: classCodeRate?.classCodeRate?.hazardGroup,
      rateBasis: classCodeRate?.classCodeRate?.premiumBasis,
      exposureAmount: exposureAmount,
      baseRate: classCodeRate?.classCodeRate?.baseRate,
      modRate: classCodeRate?.classCodeRate?.rate,
      manualPremiumAtMod: getPremiumCalculatedByLCMChanged(
        classCodeRate?.classCodeRate?.rate ?? 1,
        exposureAmount,
        classCodeRate?.classCodeRate?.premiumBasis ?? ""
      ),
    } as PolicyExposureBlob;
    if (stateExposureMap.get(stateCode)) {
      const prevExposures = stateExposureMap.get(stateCode) ?? [];
      stateExposureMap.set(stateCode, prevExposures.concat(newExposure));
    } else {
      stateExposureMap.set(stateCode, [newExposure]);
    }
  });
  return stateExposureMap;
};

export const getStatesUpdatedByExposures = (
  auditWorksheetStates: PolicyStateBlob[],
  effectiveDate: Date,
  expirationDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
): PolicyStateBlob[] => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);
  const newStateCodes = auditWorksheetStates.map((state) => state.stateCode);
  const updatedPrevStates = currentStates.map((currentState) => {
    if (
      newStateCodes.includes(currentState.stateCode) &&
      isDateEqualDate(currentState.effectiveDate, effectiveDate) &&
      isDateEqualDate(currentState.expirationDate, expirationDate)
    ) {
      return (
        auditWorksheetStates.find(
          (state) => state.stateCode === currentState.stateCode
        ) ?? currentState
      );
    } else {
      return { ...currentState };
    }
  });

  const currentStatesCodes = currentStates.map((state) => state.stateCode);
  const filteredNewStateCodes = newStateCodes.filter(
    (newState) => !currentStatesCodes.includes(newState)
  );
  const filteredNewStates = auditWorksheetStates.filter((state) =>
    filteredNewStateCodes.includes(state.stateCode)
  );
  return [...updatedPrevStates, ...filteredNewStates];
};

export const getStateListUpdatedByExposures = (
  auditWorksheetStates: PolicyStateBlob[],
  atomValue: GlobalInsuredAtomProperties | null
): string => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);
  const newStateCodes = auditWorksheetStates.map(
    (state) => state?.stateCode ?? ""
  );
  const currentStatesCodes = currentStates.map(
    (state) => state?.stateCode ?? ""
  );
  const filteredNewStateCodes = newStateCodes.filter(
    (newState) => !currentStatesCodes.includes(newState)
  );
  return currentStatesCodes.concat(filteredNewStateCodes).join(",");
};

export const dialogAfterUpdateAuditWorksheet = (
  defaultEventConfigurationForDialog?: Partial<DialogConfirmationProps>
) => {
  return {
    ...defaultEventConfigurationForDialog,
    dialogDescriptionText: "Audit Exposure Updated!",
    optionYesOverrideLabel: "OK",
    open: true,
  } as DialogConfirmationProps;
};

export const getAuditWorksheetDataFromTable = (rowData: any) => {
  const jobDep = rowData.JobTitle.trim().split("/");
  const finalPayroll = unFormatLocalString(rowData.FinalPayroll ?? "0.00");
  const nvCredit = finalPayroll < 36000 ? 0.0 : 36000 - finalPayroll;
  const totalIncluded = unFormatLocalString(rowData.TotalIncluded ?? "0.00");
  const quarter1Amount = unFormatLocalString(rowData.Quarter1Amount ?? "0.00");
  const quarter2Amount = unFormatLocalString(rowData.Quarter2Amount ?? "0.00");
  const quarter3Amount = unFormatLocalString(rowData.Quarter3Amount ?? "0.00");
  const quarter4Amount = unFormatLocalString(rowData.Quarter4Amount ?? "0.00");
  const autoAllowance = unFormatLocalString(rowData.AutoAllowance ?? "0.00");
  const cafeWages = unFormatLocalString(rowData.CafeWages ?? "0.00");
  const overtime = unFormatLocalString(rowData.OverTime ?? "0.00");
  const overtimeCredit = unFormatLocalString(rowData.OverTimeCredit ?? "0.00");
  return {
    auditId: parseInt(rowData.AuditId),
    auditWorksheetId: parseInt(rowData.AuditWorksheetId),
    locationNumber: parseInt(rowData.LocationNumber),
    recordStatus: parseInt(rowData.RecordStatus),
    recordType: parseInt(rowData.RecordType),
    employeeName: rowData.EmployeeName,
    jobTitle: jobDep[0],
    department: jobDep[1],
    addressId: parseInt(rowData.AddressId),
    state: rowData.State,
    exclude: rowData.Exclude,
    classCode: rowData.ClassCode,
    classCodeId: parseInt(rowData.ClassCodeId),
    classSuffix: rowData.ClassCodeSuffix ?? "",
    classCodeDescription: rowData.ClassCodeDescription,
    quarter1Amount: quarter1Amount,
    quarter2Amount: quarter2Amount,
    quarter3Amount: quarter3Amount,
    quarter4Amount: quarter4Amount,
    finalPayroll: finalPayroll,
    nVCredit: nvCredit,
    autoAllowance: autoAllowance,
    cafeWages: cafeWages,
    overTime: overtime,
    overTimeType: parseInt(rowData.OverTimeType),
    overTimeCredit: overtimeCredit,
    totalIncluded: totalIncluded,
  } as AuditWorksheetDto;
};
