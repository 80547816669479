import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../hooks";
import { SelectOptions } from "../../../../dtos/select-options";
import { QuickSearchClaimsDto } from "../../../../dtos/quick-search-claims-dto";
import ClaimsTable from "./ClaimsTable";

type ClaimsTableProps = {
  insuredId: number;
};

type ClaimsTableSelectFiltersOptionsProps = {
  claimTypes: Partial<SelectOptions>[];
  claimStatuses: Partial<SelectOptions>[];
  assignees: Partial<SelectOptions>[];
  renderInsuredClaims: boolean;
};

const optionAll: Partial<SelectOptions>[] = [
  {
    displayName: "All",
    stringValue: "all",
  },
];

const ClaimsTableWrapper: FC<ClaimsTableProps> = ({ insuredId }) => {
  const [searchFilters, setSearchFilters] =
    useState<ClaimsTableSelectFiltersOptionsProps>({
      claimTypes: [],
      claimStatuses: [],
      assignees: [],
      renderInsuredClaims: false,
    });

  const { responseGet, dispatchGet } = useApiGet<QuickSearchClaimsDto>(
    `api/InsuredClaims/GetInsuredClaimsQuickSearchOption/?insuredId=${insuredId}`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      responseGet?.requestInstanceSuccessful === true &&
      responseGet?.axiosResponse?.data !== undefined
    ) {
      setSearchFilters({
        claimTypes: optionAll.concat(
          responseGet?.axiosResponse?.data?.claimTypes ?? []
        ),
        claimStatuses: optionAll.concat(
          responseGet?.axiosResponse?.data?.claimStatuses ?? []
        ),
        assignees: optionAll.concat(
          responseGet?.axiosResponse?.data?.assigneesListFilter ?? []
        ),
        renderInsuredClaims: true,
      });
    }
  }, [responseGet]);

  return (
    <>
      {searchFilters?.renderInsuredClaims === true ? (
        <ClaimsTable searchFilters={searchFilters} insuredId={insuredId} />
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default ClaimsTableWrapper;
