import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DiaryAlertDto } from "../../dtos/diary-alert-dto";
import { useApiGet, useApiPost } from "../../hooks";
import { useAtomFamily } from "../../hooks/useAtomFamily";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { INSURED_ATOM_KEY } from "../../utilities/queryStringsHash";
import { removeHTMLTagsByRegex } from "../../utilities/stringFunctions";
import { Modal } from "../TrueUI";
import Alert from "../TrueUI/Alerts/Alert";
import { GlobalInsuredAtomFamily, RefreshAlertComponent } from "./InsuredAtoms";

type AlertContainerProps = {
  insuredId: number;
  tabKey: string;
  context?: number;
  claimNumber: string;
};

type AlertModalProps = {
  isOpen: boolean;
  alertContent: any;
};

const AlertsContainer: FC<AlertContainerProps> = ({
  insuredId,
  tabKey,
  context,
  claimNumber
}) => {
  const [alertList, setAlertList] = useState<DiaryAlertDto[]>([]);
  const [configModal, setConfigModal] = useState<AlertModalProps>();
  const [clickedAlert, setClickedAlert] = useState<number>();
  const { responseGet, dispatchGet } = useApiGet<DiaryAlertDto[]>(
    `api/DiaryNoteAlert/GetDiaryAlerts?insuredID=${insuredId}&contextEnumId=${context}&claimNumber=${claimNumber}`
  );

  const { responsePost, dispatchPost } = useApiPost(
    `api/DiaryNoteAlert/SaveDiaryAlertLog?insuredID=${insuredId}&alertID=${clickedAlert}`
  );
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const refresh = useRecoilValue(RefreshAlertComponent);

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const onClickClose = (alertId: number) => {
    setClickedAlert(alertId);
  };

  useEffect(() => {
    dispatchGet();
  }, [context]);

  useEffect(() => {
    dispatchGet();
  }, [refresh]);

  useEffect(() => {
    if (conditionHasValue(clickedAlert)) {
      dispatchPost();
    }
  }, [clickedAlert]);

  useEffect(() => {
    if (responseGet?.responseData) {
      setAlertList(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      responsePost.errorResponse === null &&
      responsePost?.isLoading === false &&
      responsePost?.requestInstanceSuccessful === true
    ) {
      setComponentTriggers(["activityLogComponent"]);
    }
  }, [responsePost]);

  const onClickMessage = (content: any) => {
    setConfigModal({ isOpen: true, alertContent: content });
  };

  return (
    <>
      {alertList.length > 0 &&
        alertList.map((alert) => (
          <Alert
            key={`alert-key-${alert.alertId}`}
            type={alert.alertType}
            message={removeHTMLTagsByRegex(alert.content)}
            onClickClose={() => onClickClose(alert.alertId)}
            onClickMessage={() => onClickMessage(alert.content)}
          />
        ))}
      <Modal
        id="alert-content"
        title="Alert Content"
        open={configModal?.isOpen}
        showCloseButton
        cancelEvent={() =>
          setConfigModal({
            alertContent: "",
            isOpen: false,
          })
        }
      >
        <div
          dangerouslySetInnerHTML={{ __html: configModal?.alertContent ?? "" }}
        />
      </Modal>
    </>
  );
};

export default AlertsContainer;
