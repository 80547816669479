import { FC } from "react";
import { useRecoilState } from "recoil";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { AuditStatusChangedModalAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { getAuditStatusListFromConfiguration } from "../PolicyQuoteForm/PolicyQuoteUtils";
import PolicyChangeStatusModal from "./PolicyChangeStatusModal";

const PolicyChangeStatusWrapper: FC<{ tabKey: string }> = ({ tabKey }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));

  const [auditStatusChangedModal, setAuditStatusChangedModal] = useRecoilState(
    AuditStatusChangedModalAtom
  );

  return auditStatusChangedModal !== null && auditStatusChangedModal.isOpen ? (
    <PolicyChangeStatusModal
      tabKey={tabKey}
      modalConfiguration={auditStatusChangedModal}
      auditStatusOptions={getAuditStatusListFromConfiguration(getAtom())}
      closeModal={() => {
        setAuditStatusChangedModal(null);
      }}
      onSaveSuccessful={() => {}}
    />
  ) : (
    <></>
  );
};

export default PolicyChangeStatusWrapper;
