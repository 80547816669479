import { FC } from "react";
import { BaseGridFilterOptionsParameter } from "../../../../../BaseGrid/BaseGridProperties";
import { Select } from "../../../../../TrueUI";
import { useBaseGridFilters } from "../../../../../BaseGrid/Hooks/useBaseGridFilters";
import {
  FILTER_ALL_RESULTS,
  NO_FILTER_NAME_FOUND,
} from "../../../../../BaseGrid/BaseGridConstants";
import { ClaimFinancialDto } from "../../../../../../dtos/claim-financial-dto";
import { FinancialTypeEnum } from "../../../../../../dtos/financial-type-enum";
type PaymentsAndReservesFilterProperties = {
  options: BaseGridFilterOptionsParameter;
  financialData: ClaimFinancialDto | null;
  activeFinancialType: FinancialTypeEnum;
  onChange?: (value: any) => void;
};
const PaymentsAndReservesFilter: FC<PaymentsAndReservesFilterProperties> = ({
  options,
  financialData,
  activeFinancialType,
  onChange,
}) => {
  useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: `true-filter-for-table-${crypto.randomUUID()}`,
      filterName: NO_FILTER_NAME_FOUND,
      defaultValue: FILTER_ALL_RESULTS,
      columnNames: [],
      isStatic: true,
    },
    "PaymentsAndReservesFilter"
  );

  return (
    <Select
      id={`true-filter-for-table-${crypto.randomUUID()}`}
      name={`filter-dropdown-${crypto.randomUUID()}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={""}
      labelPosition={"start"}
      options={financialData?.quickSearchOption?.type ?? []}
      value={activeFinancialType}
      type={"tableFilter"}
      inputWidth={"fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        onChange?.(value);
      }}
    />
  );
};

export default PaymentsAndReservesFilter;
