import { ElementType, FC, useEffect, useState } from "react";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import { getAllExternalDataOnly } from "./SupportFunctions/baseGridFunctions";

type BaseGridDynamicFooterComponentProperties = {
  uiid?: string;
};

const BaseGridDynamicFooterComponent: FC<
  BaseGridDynamicFooterComponentProperties
> = ({ uiid }) => {
  const { instanceSelector, instanceInternalSelector } = useGridInstance(
    uiid ?? "NO_UIID_FOUND",
    "BaseGridDynamicFooterComponent"
  );
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const [externalData, setExternalData] = useState<{ data: string[][] }>({
    data: [],
  });
  const Jsx = (instance?.advancedOptions?.footer?.component ??
    null) as ElementType;

  useEffect(() => {
    const externalData = getAllExternalDataOnly(
      instance?.columns ?? [],
      (instanceInternal?.sortedAndFilteredData as string[][]) ?? []
    );
    setExternalData({ data: externalData ?? [] });
  }, [instanceInternal.sortedAndFilteredData]);

  return instance?.advancedOptions?.footer?.component ? (
    <div>
      <Jsx {...externalData} />
    </div>
  ) : null;
};

export default BaseGridDynamicFooterComponent;
