import { FC } from "react";
import { Switch } from "../..";
import {
  BaseTableCellSelectProperties,
  TableInstanceType2,
} from "../BaseTable2/TableProperties";
import { useRecoilValue, RecoilState, useRecoilCallback } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { addOrRemoveKeyRow } from "../tableFunctions";

interface MultiSelectCellProps extends BaseTableCellSelectProperties {
  rowKey: string;
  uiid: string;
}

const MultiSelectCell: FC<MultiSelectCellProps> = ({
  rowKey,
  uiid,
  onChange,
  cellKey,
  column,
}) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const isThisRowChecked = () => {
    if (!tableInstance?.allSelectedRows) {
      const isSelected = tableInstance?.selectedRows?.find(
        (row) => row === rowKey
      );
      return isSelected !== undefined;
    }
    return true;
  };

  const onCheckEvent = (value) => {
    const newSelectedArray = addOrRemoveKeyRow(
      tableInstance?.selectedRows ?? [],
      rowKey,
      value?.target?.checked
    );
    setTableInstance({
      ...tableInstance,
      selectedRows: newSelectedArray,
      allSelectedRows: false,
    });
    onChange?.({
      cellKey: cellKey,
      rowKey: rowKey,
      column: column,
      value: value.target.checked ? "true" : "false",
      // cellIds: cellIds ?? [],
    });
  };

  return (
    <Switch
      control="checkbox"
      isChecked={isThisRowChecked()}
      onChange={onCheckEvent}
      readOnly={tableInstance?.toggleEditModeState}
    />
  );
};

export default MultiSelectCell;
