import { FC, useEffect, useState } from "react";
import { BaseTable2 } from "../../../../../TrueUI";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { useApiPost } from "../../../../../../hooks";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";

type ClaimReturnToWorkProps = {
  claimId: number;
  insuredIdAtomKey: string;
  hasClaimsManagementPermissions: boolean;
};

const ClaimReturnToWork: FC<ClaimReturnToWorkProps> = ({
  claimId,
  insuredIdAtomKey,
  hasClaimsManagementPermissions,
}) => {
  const [refreshTableKey, setRefreshTableKey] = useState<string>(
    crypto.randomUUID()
  );
  const name = "claim_return_to_work_table";
  const getURL = `api/ClaimReturnToWork/GetReturnToWorkTable?claimId=${claimId}&uiid=${refreshTableKey}`;
  const postURL = `api/ClaimReturnToWork/SaveReturnToWorkTable`;
  const postTarget = "changedClaimReturnToWork";

  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  const { dispatchPost, responsePost } = useApiPost(
    "api/ClaimReturnToWork/DeleteReturnToWork",
    rowToDelete
  );

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const onCancelDelete = () => {
    setRowToDelete(null);
    setDialogOpen(false);
  };

  const onDeleteClick = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    const returnToWorkId = actionOptions.row.ReturnToWorkId;
    if (conditionHasValue(returnToWorkId) && returnToWorkId > 0) {
      setRowToDelete(actionOptions.row);
      setDialogOpen(true);
    } else {
      tableInstanceMethods?.methods?.deleteRow({
        rowKey: actionOptions.row._row_key,
        hydratedData: actionOptions.row,
        deleteType: "hidden",
      });
    }
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      disabled={!hasClaimsManagementPermissions}
      aria-label="expand row"
      onClick={() => onDeleteClick(actionOptions)}
    >
      <DeleteIcon
        fontSize="small"
      />
    </IconButton>
  );

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      tableInstanceMethods?.methods?.deleteRow({
        rowKey: rowToDelete._row_key,
        hydratedData: rowToDelete,
        deleteType: "hidden",
      });
      setDialogOpen(false);
      setComponentTriggers(["claimBanner"]);
    }
  }, [responsePost]);

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    postURL,
    postTarget,
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showAddButton: hasClaimsManagementPermissions,
      showEditButton: hasClaimsManagementPermissions,
    },
    advancedOptions: {
      paginate: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      overrideColumnDefaultValues: [
        {
          columnFieldName: "ClaimId",
          propName: "defaultValue",
          value: claimId,
        },
      ],
    },
    columnOptions: [
      {
        fieldName: "StatusDate",
        width: "14",
      },
      {
        fieldName: "WorkStatus",
        width: "13",
      },
      {
        fieldName: "StartDate",
        width: "12",
      },
      {
        fieldName: "EndDate",
        width: "12",
      },
      {
        fieldName: "Comments",
        width: "43",
      },
      {
        fieldName: "AddedBy",
        width: "6",
      },
    ],
    events: {
      onDataToBeSavedChange: (dataToBeSaved) => {
        if (dataToBeSaved.length === 0) {
          setComponentTriggers(["claimBanner"]);
          setRefreshTableKey(crypto.randomUUID());
        }
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const tableInstanceMethods = tableMethods[name];

  return (
    <div style={{ height: "100%" }}>
      <BaseTable2 name={name} />
      <DialogConfirmation
        id="id-delete-return-to-work-confirmation"
        dialogDescriptionText="Are you sure you want to delete this?"
        open={dialogOpen}
        onOptionYesEvent={() => dispatchPost()}
        onOptionNoEvent={onCancelDelete}
        onCloseEvent={onCancelDelete}
      />
    </div>
  );
};

export default ClaimReturnToWork;
