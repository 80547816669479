import { FC, useState } from "react";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTablePolicyDropdownAsyncFilter from "../../../Policy/BaseTablePolicyDropdownAsyncFilter";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../../TrueUI";
import { usePermissions } from "../../../../../hooks";
import {
  actionsForEditIcon,
  getBillingOptionsTableConfiguration,
  tableName,
} from "./BillingOptionsConstants";
import { BillingOptionsTableProps } from "./TypesBillingOptions";

const BillingOptionsTable: FC<BillingOptionsTableProps> = ({
  insuredId,
  tabKey,
  modalBillingOptionsConfig,
  setModalBillingOptionsConfig,
}) => {
  const hasPermissionToAddNewBillinOptionRule = usePermissions([6, 7, 10, 11]);
  const [currentPolicy, setCurrentPolicy] = useState<number | null>(null);

  const getURL = `api/InsuredBillingRule/GetInsuredBillingRuleTable/?insuredId=${insuredId}${
    currentPolicy !== null
      ? `&policyId=${currentPolicy}&uiid=${modalBillingOptionsConfig?.uuid}`
      : ""
  }`;

  const editIconClicked = (rowData: any) => {
    const expirationDate = rowData?.row?.ExpirationDate ?? "";
    const billingRuleStatus = parseInt(rowData?.row?.BillingRuleStatus ?? "0");
    const billingRuleId = parseInt(rowData?.row?.BillingRuleID ?? "0");
    setModalBillingOptionsConfig({
      ...modalBillingOptionsConfig,
      isModalOpen: true,
      expirationDate: expirationDate !== "" ? new Date(expirationDate) : null,
      billingRuleStatus: billingRuleStatus !== 0 ? billingRuleStatus : null,
      billingRuleId: billingRuleId !== 0 ? billingRuleId : null,
    });
  };

  const addEventClickCallback = () => {
    console.log("ADD button clicked");
  };

  const activityConfig: BaseTable2Properties = {
    ...getBillingOptionsTableConfiguration(
      addEventClickCallback,
      getURL,
      hasPermissionToAddNewBillinOptionRule.hasPermission
    ),
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          columnsNames: ["PolicyId"],
          insuredId: insuredId,
          filterOptions: actionOptions,
          overrideAllOptionLabel: "",
          overrideAllOptionValue: "",
          selectWidth: "180px",
          onChange: (value) => {
            setCurrentPolicy(typeof value !== "number" ? null : value);
          },
          tabKey: tabKey,
          showAllOption: false,
        }),
    ],
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          actionsForEditIcon(
            actionOptions,
            editIconClicked,
            hasPermissionToAddNewBillinOptionRule.hasPermission
          ),
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
    },
  };

  useBaseTable(activityConfig);

  return (
    <div
      style={{
        marginTop: 10,
        display: "flex",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default BillingOptionsTable;
