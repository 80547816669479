import { FC, useState } from "react";
import { Grid, Link } from "@mui/material";
import { PolicyDto } from "../../../dtos/policy-dto";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { currencyFormat } from "../../../utilities/currencyFunctions";
import InformationContainer from "../../TrueUI/Containers/InformationContainer";
import { Font } from "../../TrueUI";
import { Item } from "../InsuredLandingStyles";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";
import { PolicyQuoteTypeEnum } from "../../../dtos/policy-quote-type-enum";
import { updateInsuredAtom } from "../Policy/updatesPolicyQuoteFunctions";
import { getPolicyQuoteInitialValues } from "../Policy/PolicyQuoteForm/PolicyQuoteUtils";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import { useNavigate } from "react-router";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import IssueCertificateModal from "../Policy/PolicyCertificates/IssueCertificateModal/IssueCertificateModal";
import { IssueCertificateModalConfig } from "../Policy/PolicyCertificates/IssueCertificateModal/IssueCertificateUtils";

type PolicyInfoProps = {
  currentPolicy: PolicyDto | undefined;
  tabKey: string;
  insuredId: number;
};

const Policy: FC<PolicyInfoProps> = ({ currentPolicy, tabKey, insuredId }) => {
  const insuredIdAtom = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtom)
  );

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState<IssueCertificateModalConfig>({
    isOpen: false,
    insuredId,
  });

  const getValueOfHashForInsuredBodySection = (quoteType?: number) => {
    switch (quoteType) {
      case PolicyQuoteTypeEnum.INITIAL:
      default:
        return SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS;
      case PolicyQuoteTypeEnum.ENDORSEMENT:
        return SubSideNavItemDestinationsEnum.ENDORSEMENT_SECTIONS;
      case PolicyQuoteTypeEnum.FINAL_AUDIT:
        return SubSideNavItemDestinationsEnum.AUDIT_SECTIONS;
    }
  };

  const dynamicURLValues = (
    quoteType?: number,
    previousQuoteId?: number,
    quoteId?: number
  ) => {
    if (
      quoteType === PolicyQuoteTypeEnum.ENDORSEMENT ||
      quoteType === PolicyQuoteTypeEnum.FINAL_AUDIT
    ) {
      return [
        {
          nameOfHash: PREVIOUS_QUOTE_ID,
          valueOfHash: previousQuoteId?.toString() ?? "0",
        },
        { nameOfHash: QUOTE_ID, valueOfHash: quoteId?.toString() ?? "0" },
      ];
    }

    return [{ nameOfHash: QUOTE_ID, valueOfHash: quoteId?.toString() ?? "0" }];
  };

  const updateIsQuoteOpen = (
    policyId?: number,
    quoteId?: number,
    quoteType?: number,
    previousQuoteId?: number
  ) => {
    const atomValue = getAtom();

    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      getPolicyQuoteInitialValues(
        policyId ?? 0,
        quoteId ?? 0,
        previousQuoteId ?? null
      )
    );
    setAtom(newAtomValue);

    const quoteHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: getValueOfHashForInsuredBodySection(quoteType),
      },
      {
        nameOfHash: INSURED_PARENT_ITEM,
        valueOfHash: SubSideNavParentsIndexEnum.POLICY,
      },
      {
        nameOfHash: INSURED_CHILD_ITEM,
        valueOfHash: SubSideNavChildrenIndexEnum.POLICY_HISTORY,
      },
      {
        nameOfHash: POLICY_ID,
        valueOfHash: policyId?.toString() ?? "0",
      },
      ...dynamicURLValues(quoteType, previousQuoteId, quoteId),
    ]);

    navigate(quoteHashes);
  };
  const onSaveSuccessful = () => {
    setModalConfig({ ...modalConfig, mode: "REISSUE", isOpen: false });
  };

  const onDeleteSuccessful = () => {
    setModalConfig({ insuredId, isOpen: false });
  };
  return (
    <InformationContainer
      title={
        <Link
          true-element="true-element-link-currentPolicyTitle"
          href="#"
          onClick={() => {
            alert("Waiting instructions");
          }}
          variant="body2"
        >
          Current Policy - {currentPolicy?.policyNumber}
        </Link>
      }
    >
      <Grid container className={"policy-grid"}>
        <Grid item xs={12} lg={4}>
          <Item>
            <Font name="currentPolicyEffectiveDate">
              Effective - {FormattingDate(currentPolicy?.effectiveDate)} to{" "}
              {FormattingDate(currentPolicy?.expirationDate)}
            </Font>
          </Item>
          <Item>
            <Font name="currentPolicyType">
              Policy Type - {currentPolicy?.type}{" "}
            </Font>
          </Item>
          <Item>
            <Font name="currentPolicyAgency">
              Agency - {currentPolicy?.agency}{" "}
            </Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Item>
            <Font name="currentPolicyStatus">
              Status - {currentPolicy?.status} on{" "}
              {FormattingDate(currentPolicy?.statusDate)}
            </Font>
          </Item>
          <Item>
            <Font name="currentPolicyWrittenPremium">{`Written Premium - ${currencyFormat(
              currentPolicy?.writtenPremium,
              true
            )}`}</Font>
          </Item>
          <Item>
            <Font name="currentPolicyPayroll">{`Payroll (gov cc) - ${currencyFormat(
              currentPolicy?.payroll,
              true
            )} (${currentPolicy?.classCode})`}</Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Item>
            <Link
              true-element="true-element-link-addEndorsement"
              href="#"
              onClick={() => {
                updateIsQuoteOpen(
                  currentPolicy?.policyId,
                  0,
                  PolicyQuoteTypeEnum.ENDORSEMENT,
                  currentPolicy?.currentQuoteId
                );
              }}
              variant="body2"
            >
              + Add Endorsement
            </Link>
          </Item>
          <Item>
            <Link
              true-element="true-element-link-addCertificate"
              href="#"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  editCertificateId: null,
                  mode: "ADD",
                  isOpen: true,
                })
              }
              variant="body2"
            >
              + Add Certificate
            </Link>
          </Item>
          <Item>
            <Font>
              {`Excluded Officers - ${
                currentPolicy?.insuredOfficersCount === 0
                  ? "No"
                  : `Yes (${currentPolicy?.insuredOfficersCount})`
              }`}
            </Font>
          </Item>
        </Grid>
      </Grid>
      <IssueCertificateModal
        configuration={modalConfig}
        closeModal={() => setModalConfig({ insuredId, isOpen: false })}
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
        onReissue={() =>
          setModalConfig({
            ...modalConfig,
            editCertificateId: null,
            mode: "ADD",
          })
        }
      />
    </InformationContainer>
  );
};

export default Policy;
