import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import InsuredInformationContacts from "./InsuredInformationContacts";
import InsuredInformationOfficers from "./InsuredInformationOfficers";
import InsuredInformationNamesAndAddresses from "./InsuredInformationNamesAndAddresses";
import InsuredInformationAgency from "./InsuredInformationAgency";
import InsuredInformationInsured from "./InsuredInformationInsured";
import InsuredInformationPolicy from "./InsuredInformationPolicy";
import InsuredInformationRemarks from "./InsuredInformationRemarks";
import InsuredInformationNatureBusiness from "./InsuredInformationNatureBusiness";
import { useApiGet } from "../../../../hooks";
import { Acord130ConfigurationDto } from "../../../../dtos/acord130-configuration-dto";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { InitOptionsDto } from "../../../../dtos/init-options-dto";
import { NewSubmissionFormUIProps } from "../../../Dashboard/QuickActions/ModalNewSubmission";

type InsuredInformationMainProps = {
  newSubmissionForm: NewSubmissionFormUIProps;
  errorDetails?: { [key: string]: Object };
};

const InsuredInformationMain: FC<InsuredInformationMainProps> = ({
  newSubmissionForm,
  errorDetails,
}) => {
  const [localOptions] = useRecoilState<InitOptionsDto>(globalOptions);
  const [acord130Configuration, setAcord130Configuration] =
    useState<Acord130ConfigurationDto>();
  const { responseGet: acord130Config, dispatchGet: acord130Dispatch } =
    useApiGet<Acord130ConfigurationDto>(
      `api/InsuredInformation/GetAcord130Configuration?programId=${
        localOptions?.selectedProgram?.programId ?? 0
      }`
    );

  useEffect(() => {
    acord130Dispatch();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(acord130Config)) {
      setAcord130Configuration(acord130Config.axiosResponse?.data);
    }
  }, [acord130Config]);

  return acord130Configuration ? (
    <div id="insured-information-main-container">
      <InsuredInformationAgency
        agencies={acord130Configuration?.agencyList ?? []}
        errorDetails={
          errorDetails as {
            [key: string]: any;
          }
        }
      />
      <InsuredInformationInsured
        programs={acord130Configuration?.programList ?? []}
        underwriterOptions={acord130Configuration?.underwriterList ?? []}
        businessTypeOptions={acord130Configuration?.businessTypeList ?? []}
        assignmentTypeOptions={acord130Configuration.assignmentTypeList ?? []}
        assigneeTypeOptions={acord130Configuration.assigneeTypeList ?? []}
        selectedProgram={localOptions?.selectedProgram?.programId ?? 0}
        newSubmissionForm={newSubmissionForm}
        errorDetails={
          errorDetails as {
            [key: string]: any;
          }
        }
      />
      <InsuredInformationNamesAndAddresses
        addressTypes={acord130Configuration?.addressTypes ?? []}
        businessTypes={acord130Configuration?.businessTypeList ?? []}
        relationshipTypes={acord130Configuration?.relationshipTypes ?? []}
        effectiveDate={newSubmissionForm.effectiveDate ?? null}
      />
      <InsuredInformationPolicy
        programId={localOptions?.selectedProgram?.programId ?? 0}
        proposedEffectiveDate={newSubmissionForm.effectiveDate ?? null}
        policyPeriodList={acord130Configuration.policyPeriodList ?? []}
        errorDetails={
          errorDetails as {
            [key: string]: any;
          }
        }
      />
      <InsuredInformationContacts
        contactsConfiguration={acord130Configuration}
        firstName={newSubmissionForm.firstName}
        lastName={newSubmissionForm.lastName}
        errorDetails={errorDetails}
      />
      <InsuredInformationOfficers
        errorDetails={errorDetails}
        effectiveDate={newSubmissionForm.effectiveDate ?? null}
        officersOptions={acord130Configuration?.officersTypes ?? []}
      />
      <InsuredInformationRemarks />
      <InsuredInformationNatureBusiness />
    </div>
  ) : (
    <>LOADING...</>
  );
};

export default InsuredInformationMain;
