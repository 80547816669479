import { useEffect } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { TableInstanceType2 } from "./TableProperties";

const BaseTable2AdvancedPropertiesManager = (props) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(props.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  useEffect(() => {
    if (tableInstance.advancedOptions?.isEditMode) {
      setTableInstance({
        ...tableInstance,
        isEdit: tableInstance?.advancedOptions?.isEditMode ?? false,
      });
    }
  }, [tableInstance.advancedOptions?.isEditMode]);

  return null;
};

export default BaseTable2AdvancedPropertiesManager;
