import { Theme } from "../../media/themeTypes";

export const billingStyles = (theme: Theme) => {
  return {
    display: "flex",
    justifyContent: "flex-start",
    alignTtems: "flex-start !important",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    m: "15px",
    ".select-program-container": {
      mt: "10px",
      mb: "10px",
    },
    ".true_tabs_container": {
      overflow: "hidden",
      ul: {
        borderBottom: `${theme?.BORDER_COLOR} solid 1.5px`,
        ".k-item": {
          borderBottom: "none !important",
        },
        ".k-active": {
          borderBottom: `3px solid ${theme?.PRIMARY} !important`,
        },
      },
    },
    "#payment_activity_container, #cancellation_listing_container": {
      display: "flex",
      overflow: "hidden",
      height: "100%",
      flexGrow: 1,
      paddingTop: "10px",
    },
    "#payroll_reporting_table_container": {
      display: "flex",
      overflow: "hidden",
      height: "100%",
      flexGrow: 1,
      paddingTop: "10px",
    },
  };
};
