import { FC, useState } from "react";
import { Font, Upload } from "../../TrueUI";
import style from "./quickActions.module.css";

type NewSubmissionImportAcordProps = {
  setFileIds: (newFileIds: number[]) => void;
};

const NewSubmissionImportAcord: FC<NewSubmissionImportAcordProps> = ({
  setFileIds,
}) => {
  const [files, setFiles] = useState<{ [key: string]: number }>();
  const [clear, setClear] = useState<boolean>(false);

  const updateFileValues = (files: { [key: string]: number }) => {
    const keys = Object.keys(files ?? []);
    const newFileIds = keys.map((key) => files?.[key]);
    setFiles(files);
    setFileIds(newFileIds);
  };

  return (
    <div className="import_acord_container">
      <Font>Select the Acord application to import</Font>
      <div className={style.import_acord_upload_container}>
        <Upload
          name="upload-files"
          files={files}
          onUploadFileList={updateFileValues}
          clearFiles={clear}
          resetClearFiles={setClear}
          allowedExtensions={["docx", "pdf"]}
        />
      </div>
    </div>
  );
};

export default NewSubmissionImportAcord;
