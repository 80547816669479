import { atom } from "recoil";

export type SelectedInsuredCommission = {
  tranId: number;
  program: string;
  date: string;
  amount: string;
};

const SelectedInsuredCommissionAtom = atom<SelectedInsuredCommission | null>({
  key: "SelectedInsuredCommission",
  default: null,
});

export { SelectedInsuredCommissionAtom };
