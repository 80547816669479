import { AddAssignmentFormDataDto } from "../../../../dtos/add-assignment-form-data-dto";
import { AssignmentDto } from "../../../../dtos/assignment-dto";
import { AssignmentTypeDto } from "../../../../dtos/assignment-type-dto";
import { AssignmentTypesEnum } from "../../../../dtos/assignment-types-enum";
import { SelectOptions } from "../../../../dtos/select-options";
import { getUniqueArrayValues } from "../../../../utilities/arrayFunctions";

export const getAssignmentTypesOrdered = (
  selectOptions: Partial<SelectOptions>[]
) =>
  selectOptions.sort((a, b) => {
    const displayNameA = a?.displayName?.toLowerCase() ?? "";
    const displayNameB = b?.displayName?.toLowerCase() ?? "";

    if (displayNameA < displayNameB) {
      return -1;
    }
    if (displayNameA > displayNameB) {
      return 1;
    }
    return 0;
  });

export const getAssignmentsOrderedbyFromDate = (assignments: AssignmentDto[]) =>
  assignments.sort((a, b) => {
    const displayNameA = a?.fromDate;
    const displayNameB = b?.fromDate;

    if (displayNameA > displayNameB) {
      return -1;
    }
    if (displayNameA < displayNameB) {
      return 1;
    }
    return 0;
  });

export const getCurrentAssignmentTypeId: any = (
  assignmentsGroups: AssignmentTypeDto[] | null
) => {
  if (assignmentsGroups === null) return [];
  const assignmentTypeIds: { result: number[] } = { result: [] };
  assignmentsGroups?.forEach((assignmentGroup) =>
    assignmentGroup?.assignments?.forEach((assignment) => {
      assignmentTypeIds.result = [
        ...assignmentTypeIds?.result,
        assignment?.assignmentType,
      ];
    })
  );
  return getUniqueArrayValues(assignmentTypeIds.result)?.[0] ?? null;
};

export const setCurrenUserOrTeamForSelectedAssignmentType = (
  setAssignmentFormData: (formData: AddAssignmentFormDataDto) => void,
  assignmentsGroups: AssignmentTypeDto[] | null,
  assignmentType: number | null,
  assignmentFormData: AddAssignmentFormDataDto | null,
  insuredId: number,
  policyId?: number | null
) => {
  const assignmentsTypeGroup =
    assignmentsGroups?.find(
      (assignmentsGroup) => assignmentsGroup.assignmentType === assignmentType
    ) ?? null;
  const orderedAssignment = getAssignmentsOrderedbyFromDate(
    assignmentsTypeGroup?.assignments ?? []
  );
  const filteredAssignment =
    policyId === undefined || policyId === null
      ? orderedAssignment
      : orderedAssignment.filter(
          (assignment) => assignment.assignmentForeignId === policyId
        );
  const firstUserOrTeam =
    filteredAssignment?.length > 0
      ? filteredAssignment?.[0]
      : orderedAssignment?.[0];
  if (assignmentsTypeGroup === null) {
    setAssignmentFormData({
      assignmentType: assignmentType,
      effectiveDate: new Date(),
      selectedAssignmentId: null,
      insuredId: insuredId,
      policyId: policyId,
    });
  } else {
    setAssignmentFormData({
      ...assignmentFormData,
      assignmentType: assignmentType,
      userOrTeamId: firstUserOrTeam?.assigneeId ?? null,
      effectiveDate: new Date(),
      selectedAssignmentId: firstUserOrTeam?.assignmentId,
      assigneeType: firstUserOrTeam?.assigneeType,
      insuredId: insuredId,
      policyId: policyId,
    });
  }
};

export const getInitialAssignmentTypeOption = (
  assignmentFormData: AddAssignmentFormDataDto | null,
  setAssignmentFormData: (formData: AddAssignmentFormDataDto) => void,
  assignmentsGroups: AssignmentTypeDto[] | null,
  firstAssignmentTypeId: number | null,
  allTeams: Partial<SelectOptions>[],
  setTeams: (teams: Partial<SelectOptions>[] | null) => void,
  insuredId: number,
  policyId?: number | null,
  isDisabledAssignment?: boolean
) => {
  const assignmentTypeId: { value: number | null } = {
    value: firstAssignmentTypeId,
  };
  if ((assignmentsGroups?.length ?? 0) > 0 && assignmentFormData === null) {
    assignmentTypeId.value = isDisabledAssignment
      ? AssignmentTypesEnum.POLICY
      : getCurrentAssignmentTypeId(assignmentsGroups);
  }
  const filteredTeams = getTeamsByAssignmentTypeId(
    allTeams,
    assignmentTypeId.value
  );
  setTeams(filteredTeams);
  setCurrenUserOrTeamForSelectedAssignmentType(
    setAssignmentFormData,
    assignmentsGroups,
    assignmentTypeId.value,
    assignmentFormData,
    insuredId,
    policyId
  );
};

export const getTeamsByAssignmentTypeId = (
  allTeams: Partial<SelectOptions>[],
  typeId: number | null
) => allTeams.filter((team) => parseInt(team?.stringValue ?? "0") === typeId);
