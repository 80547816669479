import { FC, useEffect, useState } from "react";
import { DiaryConfigurationDto } from "../../../../dtos/diary-configuration-dto";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useApiPost } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  DRAWER_NOTE_ID,
  INSURED_ATOM_KEY,
} from "../../../../utilities/queryStringsHash";
import { ModalReply } from "../../../TrueUI";
import ModalShowAllNoteContent from "../../../TrueUI/Conversation/ModalShowAllNoteContent";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { ContextProps } from "../InsuredDrawerConstants";
import { removeQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";

export type ModalsActionsProps = {
  currentNote: DiaryNoteDto | null;
  action: "open" | "reply";
  hasPermissionsToEdit?: boolean;
};

export type ConfirmationDialog = {
  isOpen: boolean;
  message: string;
  action: "save" | "delete";
};

type ActionProps = {
  modalsProps: ModalsActionsProps | null;
  diaryConfiguration: DiaryConfigurationDto;
  context: ContextProps;
  setRefreshConversation: (refresh: boolean) => void;
};

const ActionNotes: FC<ActionProps> = ({
  setRefreshConversation,
  diaryConfiguration,
  modalsProps,
  context,
}) => {
  const [diaryNote, setDiaryNote] = useState<DiaryNoteDto | null>(
    modalsProps?.currentNote ?? null
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${context?.tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<ConfirmationDialog>({
      isOpen: false,
      message: "Are you sure you want to close before save the note?",
      action: "save",
    });

  const [isModalReplyOpen, setIsModalReplyOpen] = useState(false);
  const [isModalShowOpen, setIsModalShowOpen] = useState(false);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/DiaryNote/SaveDiaryNote",
    diaryNote
  );

  const actionModals = (value: boolean, action?: "open" | "reply") => {
    if (action === "open") {
      setIsModalShowOpen(value);
    } else if (action === "reply") {
      setIsModalReplyOpen(value);
    }
  };

  useEffect(() => {
    setDiaryNote(modalsProps?.currentNote ?? null);
    actionModals(true, modalsProps?.action);
  }, [modalsProps]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      setDiaryNote(null);
      setErrorDetails(null);
      actionModals(false, modalsProps?.action);
      setRefreshConversation?.(true);
      setComponentTriggers(["activityLogComponent"]);
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  const cancel = () => {
    if (modalsProps?.currentNote?.content !== diaryNote?.content) {
      setIsDialogConfirmationOpen({
        ...isDialogConfirmationOpen,
        isOpen: true,
      });
    } else {
      removeQueryStrings([DRAWER_NOTE_ID]);
      setDiaryNote(null);
      setErrorDetails(null);
      actionModals(false, modalsProps?.action);
    }
  };

  const deleteEvent = (noteToDelete?: DiaryNoteDto | null) => {
    setDiaryNote({
      diaryNoteId: noteToDelete?.diaryNoteId,
      claimNumber: noteToDelete?.claimNumber,
      insuredId: noteToDelete?.insuredId,
      content: noteToDelete?.content,
      type: noteToDelete?.type,
      category: noteToDelete?.category,
      policy: noteToDelete?.policy,
      status: StatusEnums.DELETED,
    });
    setIsDialogConfirmationOpen({
      isOpen: true,
      message: "Are you sure you want to delete the note?",
      action: "delete",
    });
  };

  return (
    <>
      <ModalReply
        id={"modalReplyNote"}
        isOpen={isModalReplyOpen}
        diaryNote={diaryNote}
        errorDetails={errorDetails}
        setDiaryNote={setDiaryNote}
        cancelEvent={cancel}
        saveEvent={dispatchPost}
        deleteEvent={deleteEvent}
      />

      <ModalShowAllNoteContent
        id={"modalShowNote"}
        hasPermissionsToEdit={modalsProps?.hasPermissionsToEdit}
        isOpen={isModalShowOpen}
        diaryNote={diaryNote}
        errorDetails={errorDetails}
        setDiaryNote={setDiaryNote}
        context={context}
        diaryConfiguration={diaryConfiguration}
        cancelEvent={cancel}
        saveEvent={dispatchPost}
        deleteEvent={deleteEvent}
        setRefreshConversation={setRefreshConversation}
      />

      <DialogConfirmation
        id="replyDialogConfirmation"
        open={isDialogConfirmationOpen.isOpen}
        dialogDescriptionText={isDialogConfirmationOpen.message}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen({
            ...isDialogConfirmationOpen,
            isOpen: close,
          });
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen({
            ...isDialogConfirmationOpen,
            isOpen: close,
          });
        }}
        onOptionYesEvent={(close) => {
          removeQueryStrings([DRAWER_NOTE_ID]);
          if (isDialogConfirmationOpen?.action === "delete") {
            dispatchPost();
          } else {
            actionModals(false, modalsProps?.action);
            setDiaryNote(null);
            setErrorDetails(null);
          }
          setIsDialogConfirmationOpen({
            ...isDialogConfirmationOpen,
            isOpen: close,
          });
        }}
      />
    </>
  );
};

export default ActionNotes;
