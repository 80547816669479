import { FC, useState } from "react";
import { IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/Launch";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import BaseTablePolicyDropdownFilter from "../BaseTablePolicyDropdownFilter";
import IssueCertificateModal from "./IssueCertificateModal/IssueCertificateModal";
import { IssueCertificateModalConfig } from "./IssueCertificateModal/IssueCertificateUtils";

type PolicyCertificatesProps = {
  insuredId: number;
};

const PolicyCertificates: FC<PolicyCertificatesProps> = ({ insuredId }) => {
  const [modalConfig, setModalConfig] = useState<IssueCertificateModalConfig>({
    isOpen: false,
    insuredId,
  });

  const name = "policy_certificates_grid";
  const getURL = `api/Certificate/GetCertificateGridByInsuredId?insuredId=${insuredId}`;

  const onEditCertificate = (certificateNum?: string) => {
    const editCertificateId = parseInt(certificateNum ?? "0");
    setModalConfig({
      ...modalConfig,
      editCertificateId,
      mode: "EDIT",
      isOpen: true,
    });
  };

  const openCertificateModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => onEditCertificate(rowData?.CertificateId)}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const onSaveSuccessful = () => {
    setModalConfig({ ...modalConfig, mode: "REISSUE", isOpen: false });
    tableInstanceMethods?.methods?.refreshTable();
  };

  const onDeleteSuccessful = () => {
    setModalConfig({ insuredId, isOpen: false });
  };

  const gridConfig: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      addButtonText: "NEW CERTIFICATE",
    },
    columnOptions: [
      { fieldName: "IssueDate", width: 8 },
      { fieldName: "CertificateStatus", width: 10 },
      { fieldName: "CertificateHolder", width: 30 },
      { fieldName: "CertificateId", width: 8 },
      { fieldName: "AuditSource", width: 8 },
      { fieldName: "IssueAtRenewal", width: 16 },
      { fieldName: "CreateBy", width: 20 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownFilter({
          columnsNames: ["PolicyId"],
          insuredId: insuredId,
          filterOptions: actionOptions,
          overrideAllOptionValue: "all",
          currentPolicyAsDefault: true,
          selectWidth: "250px",
        }),
    ],
    events: {
      addEventOverrideCallback: () =>
        setModalConfig({ ...modalConfig, mode: "ADD", isOpen: true }),
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          openCertificateModalLink(actionOptions.row),
      },
    },
  };

  const { tableMethods } = useBaseTable(gridConfig);
  const tableInstanceMethods = tableMethods[name];

  return (
    <div>
      <BaseTable2 name={name} />
      <IssueCertificateModal
        configuration={modalConfig}
        closeModal={() => setModalConfig({ insuredId, isOpen: false })}
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
        onReissue={() =>
          setModalConfig({
            ...modalConfig,
            editCertificateId: null,
            mode: "ADD",
          })
        }
      />
    </div>
  );
};

export default PolicyCertificates;
