import { FC, useEffect, useState } from "react";
import { Divider, FontMedium } from "../../../../../TrueUI";
import ClaimLitigationForm from "./LitigationFormComponents/ClaimLitigationForm";
import {
  LitigationProps,
  LitigationFormProps,
  FormStateProps,
  defaultRepresentationData,
  LitigationContactFormProps,
} from "./typesAndConstants";
import {
  toLowerCaseKeys,
  toUpperCaseKeys,
} from "../../../../../../utilities/objectFunctions";
import { RepresentationDto } from "../../../../../../dtos/representation-dto";
import ClaimLitigationEvents from "./ClaimLitigationEvents";
import { RepresentationEventDto } from "../../../../../../dtos/representation-event-dto";
import { EventConfigurationDto } from "../../../../../../dtos/event-configuration-dto";
import { useApiGet } from "../../../../../../hooks";
import ClaimLitigationEventForm from "./ClaimLitigationEventForm";
import { useRecoilValue } from "recoil";
import { TriggerComponentUpdateAtom } from "../../../../InsuredAtoms";
import ClaimLitigationTables from "./LitigationTableComponents/ClaimLitigationTables";
import themes from "../../../../../../media/TrueTheme";
import LitigationContactForm from "./LitigationFormComponents/LitigationContactForm";
import { globalOptions } from "../../../../../../GlobalAtoms";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { Box } from "@mui/material";
import { defaultClaimContactData } from "../ClaimContactsComponents/ContactModalConstants/ContactModalConstants";

const ClaimLitigation: FC<LitigationProps> = ({
  claimId,
  hasClaimsManagementPermissions,
}) => {
  const [formState, setFormState] = useState<
    FormStateProps | null | undefined
  >();
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [tableInstanceMethods, setTableInstanceMethods] = useState<any>(null);
  const [caseTableInstanceMethods, setCaseTableInstanceMethods] =
    useState<any>(null);
  const [selectedCase, setSelectedCase] = useState<
    RepresentationDto | Partial<RepresentationDto>
  >({});
  const [rowData, setRowData] = useState<any>(null);
  const [caseData, setCaseData] = useState<
    RepresentationDto | Partial<RepresentationDto>
  >(defaultRepresentationData);

  const [eventFormConfiguration, setEventFormConfiguration] =
    useState<EventConfigurationDto | null>(null);
  const [eventData, setEventData] = useState<
    RepresentationEventDto | Partial<RepresentationEventDto> | null
  >(null);

  const { responseGet: eventConfigData, dispatchGet: getEventConfig } =
    useApiGet<EventConfigurationDto>(
      "api/RepresentationEvents/GetEventConfiguration"
    );

  useEffect(() => {
    setFormState({
      title: "DETAILS",
      mode: "readonly",
    });

    getEventConfig();
  }, []);

  useEffect(() => {
    if (
      eventConfigData?.axiosResponse?.data != null &&
      eventConfigData?.requestInstanceSuccessful === true
    ) {
      setEventFormConfiguration(eventConfigData?.axiosResponse?.data);
    }
  }, [eventConfigData]);

  const saveLitigationToTable = (
    litigationRow: RepresentationDto | Partial<RepresentationDto>,
    action: "add" | "update" | "delete",
    tableToUpdate?: "cases" | null
  ) => {
    switch (action) {
      case "add":
        addNewRow(litigationRow, tableToUpdate ?? null);
        break;
      case "update":
        updateRow(litigationRow, tableToUpdate ?? null);
        break;
      default:
        deleteRow(litigationRow, tableToUpdate ?? null);
        break;
    }
  };
  const addNewRow = (newRowData, tableToUpdate) => {
    const newRow = (rowPattern: any) => {
      return { ...rowPattern, ...toUpperCaseKeys(newRowData) };
    };
    if (tableToUpdate === null) {
      tableInstanceMethods?.methods?.addRow(newRow);
    } else {
      caseTableInstanceMethods?.methods?.addRow(newRow);
    }
  };

  const updateRow = (dataToUpdate, tableToUpdate) => {
    const updatedRow = {
      rowKey: rowData.rowKey,
      hydratedData: toUpperCaseKeys(dataToUpdate),
    };
    if (tableToUpdate === null) {
      tableInstanceMethods?.methods?.updateRow(updatedRow);
    } else {
      setSelectedCase(toLowerCaseKeys(updatedRow?.hydratedData));
      caseTableInstanceMethods?.methods?.updateRow(updatedRow);
    }
  };

  const deleteRow = (dataToUpdate, tableToUpdate) => {
    const rowToDelete = {
      rowKey: rowData?.rowKey,
      hydratedData: toUpperCaseKeys(dataToUpdate),
      deleteType: "hidden",
    };
    if (tableToUpdate === null) {
      tableInstanceMethods?.methods?.deleteRow(rowToDelete);
    } else {
      caseTableInstanceMethods?.methods?.deleteRow(rowToDelete);
      setCaseData(defaultRepresentationData);
      setRowData(null);
      setSelectedCase(defaultRepresentationData);
    }
  };

  const handleUpdateCaseData = (caseData) => {
    setCaseData(caseData);
  };

  const claimLitigationProps: LitigationFormProps = {
    claimId: claimId,
    caseData: selectedCase,
    formState: formState,
    setFormState: setFormState,
    saveLitigation: saveLitigationToTable,
    updateCaseData: handleUpdateCaseData,
    hasClaimsManagementPermissions: hasClaimsManagementPermissions,
  };

  const claimLitigationContactProps: LitigationContactFormProps = {
    claimId: claimId,
    contactData: rowData?.hydratedData
      ? toLowerCaseKeys(rowData?.hydratedData)
      : defaultClaimContactData,
    formState: formState,
    setFormState: setFormState,
    saveLitigation: saveLitigationToTable,
    selectedCase: selectedCase,
    hasClaimsManagementPermissions: hasClaimsManagementPermissions,
  };
  const tablesCommonProps = {
    claimId: claimId,
    setCaseData: setCaseData,
    rowData: rowData,
    formState: formState,
    setFormState: setFormState,
    setRowData: setRowData,
    setSelectedCase: setSelectedCase,
    selectedCase: selectedCase,
  };

  const handleSelectEvent = (data: RepresentationEventDto) => {
    setEventData(data);
  };

  const handleReset = () => {
    setEventData(null);
  };

  const handleAddEvent = () => {
    setFormState({ title: null, mode: "add", rowTableClicked: "event" });

    const defaultEvent: RepresentationEventDto = {
      representationEventId: 0,
      representationId: caseData.representationId,
      eventStatus: 1,
      eventDate: null,
      eventType: -1,
      eventResult: "",
    };

    setEventData(defaultEvent);
  };

  const refreshTable = useRecoilValue(
    TriggerComponentUpdateAtom("representationEventsTable")
  );

  return (
    <Box
      id={"claim_litigation_tab"}
      sx={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        border: `3px solid ${theme?.BORDER_COLOR}`,
        mt: "5px",
        mb: "5px",
        minHeight: "calc(100vh - 280px)",
        // display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
        gap: "1rem",
        px: "15px",
      }}
    >
      <Box
        id={"claim_litigation_tab_sub_wrapper"}
        sx={{
          width: "50%", // This is a hack until we can refactor the entire Ligitation page. It's in real bad shape.
          py: { lg: "10px", md: 0 },
          pr: { lg: "10px", md: 0 },
          borderRight: { lg: `6px solid ${theme?.BORDER_COLOR}`, md: "none" },
          overflow: "auto",
        }}
      >
        <FontMedium name="litigationCasesTableTitle">
          SELECT A CASE TO VIEW CONTACTS AND EVENTS
        </FontMedium>
        <ClaimLitigationTables
          tablesCommonProps={tablesCommonProps}
          setTableInstanceMethods={setTableInstanceMethods}
          setCaseTableInstanceMethods={setCaseTableInstanceMethods}
          caseNumber={selectedCase.caseNumber}
          hasClaimsManagementPermissions={hasClaimsManagementPermissions}
        />
        {selectedCase.representationId !== undefined &&
          selectedCase.representationId > 0 && (
            <>
              <Divider height={15} />
              <FontMedium name="litigationEventsTableTitle">
                EVENTS FOR CASE NUMBER {selectedCase.caseNumber}
              </FontMedium>
              <div style={{ height: "auto" }}>
                <ClaimLitigationEvents
                  key={refreshTable}
                  representationId={selectedCase.representationId}
                  handleSelectEvent={handleSelectEvent}
                  handleAddEvent={handleAddEvent}
                  setFormState={setFormState}
                  hasClaimsManagementPermissions={
                    hasClaimsManagementPermissions
                  }
                />
              </div>
            </>
          )}
      </Box>
      <div
        id={"litigation_editor_container"}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "15px",
          overflow: "auto",
        }}
      >
        {formState?.rowTableClicked === "case" && (
          <>
            <FontMedium textAlign="start" name="litigationCaseFormTitle">
              {formState?.title ?? ""}
            </FontMedium>
            <Divider height={15} />

            <ClaimLitigationForm {...claimLitigationProps} />
          </>
        )}
        {formState?.rowTableClicked === "contact" &&
          conditionHasValue(selectedCase?.representationId) && (
            <>
              <FontMedium textAlign="start" name="litigationContactFormTitle">
                {formState?.title ?? ""}
              </FontMedium>
              <Divider height={15} />
              <LitigationContactForm {...claimLitigationContactProps} />
            </>
          )}

        {eventData &&
          formState?.rowTableClicked === "event" &&
          conditionHasValue(selectedCase?.representationId) && (
            <ClaimLitigationEventForm
              handleReset={handleReset}
              caseNumber={selectedCase?.caseNumber}
              representationId={selectedCase.representationId ?? 0}
              eventData={eventData}
              eventConfiguration={eventFormConfiguration}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          )}
      </div>
    </Box>
  );
};

export default ClaimLitigation;
