import { FC } from "react";
import { Input } from "../../../../TrueUI";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { IncidentPropsForContextMenu } from "../Incident";
import { useRecoilValue } from "recoil";
import { IncidentClaimModalDisabled } from "./CreateClaimModalContent/CreateClaimModalAtoms";

export const ReasonComponent = (value, onChangeMethod: (value) => void) => {
  return (
    <Input
      id="id-reason-input"
      name="name-reason-input"
      type="text"
      label="Reason"
      sx={{ marginTop: "15px" }}
      value={value}
      onChangeRawValue={(value) => onChangeMethod(value)}
    />
  );
};


type InteractiveDialogProps = {
  incidentContextMenuValues?: IncidentPropsForContextMenu;
  setIncidentContextMenuValues?: any;
  ReasonComponent?: () => JSX.Element;
  AssigneeComponent?: () => JSX.Element;
};

export const IncidentInteractiveDialog: FC<InteractiveDialogProps> = ({
  incidentContextMenuValues,
  setIncidentContextMenuValues,
  ReasonComponent,
  AssigneeComponent,
}) => {
  const disabledOkButton = useRecoilValue(IncidentClaimModalDisabled);

  return (
    <DialogConfirmation
      name="insured-incident-dialog"
      id="insuredIncidentDialog"
      open={incidentContextMenuValues?.dialogConfirmationOpen}
      dialogDescriptionText={
        incidentContextMenuValues?.incidentStatus !== undefined
          ? "Are you sure you want to update the status of the incident?"
          : incidentContextMenuValues?.runCreateClaimProcess
          ? "Do you want to create a claim for this incident?"
          : undefined
      }
      disabledYesEvent={
        incidentContextMenuValues?.runCreateClaimProcess && disabledOkButton
      }
      InteractiveComponent={
        incidentContextMenuValues?.incidentStatus !== undefined
          ? ReasonComponent
          : incidentContextMenuValues?.runCreateClaimProcess
          ? AssigneeComponent
          : undefined
      }
      onCloseEvent={(close) => {
        setIncidentContextMenuValues({
          ...incidentContextMenuValues,
          dialogConfirmationOpen: close,
        });
      }}
      onOptionNoEvent={(close) => {
        setIncidentContextMenuValues({
          ...incidentContextMenuValues,
          dialogConfirmationAnswerIsYes: false,
          dialogConfirmationOpen: close,
        });
      }}
      onOptionYesEvent={(close) => {
        setIncidentContextMenuValues({
          ...incidentContextMenuValues,
          dialogConfirmationAnswerIsYes: true,
          dialogConfirmationOpen: close,
          dispatchConfirmation: true,
        });
      }}
    />
  );
};
