import { FC, useEffect, useState } from "react";
import { usePermissions } from "../../hooks";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2, Col, Row } from "../TrueUI";
import { StatusEnums } from "../../dtos/status-enums";
import { PermissionsEnums } from "../../dtos/permissions-enums";
import CancelButton from "../TrueUI/Buttons/CancelButton";
import { rowWithNoMarginNorGutter } from "../TrueUI/Grids/Row";
import { AgencyContactModalConfiguration } from "./AgencyContacts";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import "./AgencyContactLicensesStyles.module.css";

const tableName = "agency_contact_licenses";

const AGENCY_API_PATH = "api/AgencyContactLicense";

type AgencyContactLicensesProps = {
  contactModalConfiguration: AgencyContactModalConfiguration;
  setContactModalConfiguration?: (
    value: AgencyContactModalConfiguration
  ) => void;
};

const AgencyContactLicensesForm: FC<AgencyContactLicensesProps> = ({
  contactModalConfiguration,
  setContactModalConfiguration,
}) => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const hasPermission = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
  ]).hasPermission;

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `${AGENCY_API_PATH}/GetAgencyContactLicense?agencyContactId=${contactModalConfiguration.agencyContactId}`,
    postURL: `${AGENCY_API_PATH}/SaveAgencyContactLicense`,
    postTarget: "changedAgencyContactLicense",
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["LicenseStatus"],
          defaultValue: StatusEnums.ACTIVE.toString(),
        }),
    ],
    columnOptions: [{ fieldName: "LicenseState", selectMaxHeight: "200px" }],
    advancedOptions: {
      paginate: false,
      overrideColumnDefaultValues: [
        {
          columnFieldName: "AgencyContactId",
          propName: "defaultValue",
          value: contactModalConfiguration.agencyContactId,
        },
      ],
      optionsColumnConfiguration: {
        isHidden: true,
        optionType: "component",
      },
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      addButtonIsDisabled: !hasPermission,
      editButtonIsDisabled: !hasPermission,
    },
    events: {
      onDataToBeSavedChange: (dataToBeSaved) => {
        if (dataToBeSaved.length === 0) {
          setRefreshTable(true);
        }
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const getTableMethods = tableMethods[tableName];

  useEffect(() => {
    if (refreshTable) {
      getTableMethods.methods.refreshTable();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  return (
    <>
      <div className="agency_contact_licenses_table">
        <BaseTable2 name={tableName} />
      </div>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalMargin={"20px"}
        horizontalAlign="space-between"
      >
        <Col horizontalAlign="flex-end">
          <CancelButton
            withConfirmation
            onClick={() =>
              setContactModalConfiguration?.({
                ...contactModalConfiguration,
                isOpen: false,
              })
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default AgencyContactLicensesForm;
