import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../../dtos/select-options";
import { useApiGet, useApiPost } from "../../../../../hooks";
import { BaseTable2, Modal, TitleBold } from "../../../../TrueUI";
import {
  ModalAddDefaultTaskProps,
  modalAddDefaultTaskProps,
} from "./ModalChangeProps";

import { useRecoilValue } from "recoil";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../utilities/apiFunctions";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { ClaimTypeAtom } from "../ClaimLandingInformationAtoms";
import { DefaultTasksDto } from "../../../../../dtos/default-tasks-dto";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";

const ModalDefaultTask: FC<ModalAddDefaultTaskProps> = ({
  setAllModalConfiguration,
  allModalConfiguration,
  claimId,
  insuredId,
  tabKey,
}) => {
  const claimType = useRecoilValue(ClaimTypeAtom);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const tableName = "claim_event_rules_table";
  const [filterOptions, setFilterOptions] = useState<any>();
  const [defaultTasksInfo, setDefaultTasksInfo] = useState<DefaultTasksDto>({
    claimId: claimId,
    claimType: claimType,
    insuredId: insuredId,
  });

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Claims/GetClaimTypesOptions`
  );

  const { responsePost, dispatchPost } = useApiPost(
    `api/Task/SaveDefaultTasks`,
    defaultTasksInfo
  );

  const closeButtonClicked = (e) => {
    if (e === false) {
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalAddDefaultTasks: false,
      });
      setComponentTriggers(["activityLogComponent"]);
    }
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/ClaimEventRules/GetClaimEventRulesTable?claimId=${claimId}`,
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "claim-type-filter",
          filterOptions: actionOptions,
          columnsNames: ["ClaimType"],
          optionsOverride: filterOptions,
          defaultValue: defaultTasksInfo.claimType ?? "",
          labelText: "Claim Type",
          onChange: (value) =>
            setDefaultTasksInfo({ ...defaultTasksInfo, claimType: value }),
        }),
    ],
    advancedOptions: {
      paginate: false,
      noResultsOverrideMessage: "No default tasks configured.",
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
      showSaveButton: false,
      showAddButton: false,
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    if (allModalConfiguration.openModalAddDefaultTasks === true) {
      dispatchGet();
    }
  }, [allModalConfiguration.openModalAddDefaultTasks]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setAllModalConfiguration({
        ...allModalConfiguration,
        openModalAddDefaultTasks: false,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFilterOptions(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    setDefaultTasksInfo({ ...defaultTasksInfo, claimType: claimType });
  }, [claimType]);

  return (
    <Modal
      {...{
        ...modalAddDefaultTaskProps,
        open: allModalConfiguration.openModalAddDefaultTasks,
        closeEvent: closeButtonClicked,
        saveEvent: () => dispatchPost(),
      }}
    >
      <TitleBold primaryColor> Tasks </TitleBold>
      <div style={{ height: "300px", marginTop: "10px" }}>
        <BaseTable2 name={tableName} />
      </div>
    </Modal>
  );
};

export default ModalDefaultTask;
