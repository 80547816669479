import { FC } from "react";
import { Col, Input, InputAddress, InputPhone, Row } from "../../../TrueUI";
import { ProgramClaimDto } from "../../../../dtos/program-claim-dto";
import { useRecoilValue } from "recoil";
import { AdminProgramErrorAtom } from "../ProgramAtoms";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";

type ProgramClaimProps = {
  programClaimData: ProgramClaimDto | any;
  setProgramClaimData: any;
  readOnly?: boolean;
  programClaimErrors?: any;
};

export const ProgramClaim: FC<ProgramClaimProps> = ({
  programClaimData,
  setProgramClaimData,
  readOnly = false,
  programClaimErrors,
}) => {
  const programAdminErrors = useRecoilValue(AdminProgramErrorAtom);
  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col>
        <Input
          tabIndex={43}
          id="program-claim-processing-name"
          name="claim-processing-name"
          label="Name"
          value={programClaimData?.claimProcessingName}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"text"}
          maxLength={100}
          onChangeRawValue={(value) => {
            setProgramClaimData({
              ...programClaimData,
              claimProcessingName: value,
            });
          }}
        />
      </Col>
      <Col {...colWithNoMarginNorGutter}>
        <InputAddress
          tabIndex={44}
          id="program-claim-inputAddress"
          showAddress2={false}
          showSingleLabel={false}
          initValueAddress1={programClaimData?.claimAddress}
          initValueCity={programClaimData?.claimCity}
          initValueStateCode={programClaimData?.claimState}
          initValuePostCode={programClaimData?.claimPostalCode}
          onChangeValueAddress1={(value) =>
            setProgramClaimData({
              ...programClaimData,
              claimAddress: value,
            })
          }
          onChangeValueCity={(value) =>
            setProgramClaimData({
              ...programClaimData,
              claimCity: value,
            })
          }
          onChangeStateCode={(value) =>
            setProgramClaimData({
              ...programClaimData,
              claimState: value,
            })
          }
          onChangeValuePostCode={(value) =>
            setProgramClaimData({
              ...programClaimData,
              claimPostalCode: value,
            })
          }
          readOnly={readOnly}
          className={"inputAddress"}
          labelFontType="BOLD_CAPTION"
        />
      </Col>
      <Col>
        <Input
          tabIndex={50}
          id="program-claim-attention"
          name="claim-attention"
          label="Claim Attention/Contact"
          value={programClaimData?.claimAttention}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"text"}
          maxLength={50}
          onChangeRawValue={(value) => {
            setProgramClaimData({
              ...programClaimData,
              claimAttention: value,
            });
          }}
        />
      </Col>
      <Col>
        <Input
          tabIndex={51}
          id="program-claim-email"
          name="claim-email"
          label="Email Address"
          value={programClaimData?.claimEmail}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          type={"email"}
          maxLength={100}
          onChangeRawValue={(value) => {
            setProgramClaimData({
              ...programClaimData,
              claimEmail: value,
            });
          }}
          errorMessage={programClaimErrors?.claimEmail}
        />
      </Col>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <InputPhone
            tabIndex={52}
            id="program-claim-phone1"
            name="claim-phone1"
            phoneLabel="Phone Number"
            phoneValue={programClaimData?.claimPhone1}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            errorMessagePhone={
              programAdminErrors?.errorDetails?.programClaim?.claimPhone1
            }
            onChangePhoneValue={(value) => {
              setProgramClaimData({
                ...programClaimData,
                claimPhone1: value,
              });
            }}
          />
        </Col>
        <Col horizontalAlign="flex-end">
          <InputPhone
            tabIndex={53}
            id="program-claim-phone2"
            name="claim-phone2"
            phoneLabel="Alternate Phone Number"
            phoneValue={programClaimData?.claimPhone2}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            errorMessagePhone={
              programAdminErrors?.errorDetails?.programClaim?.claimPhone2
            }
            onChangePhoneValue={(value) => {
              setProgramClaimData({
                ...programClaimData,
                claimPhone2: value,
              });
            }}
          />
        </Col>
      </Row>
      <Col
        horizontalAlignSelf="flex-start"
        breakpoints={{ md: 6, lg: 6, xl: 6 }}
      >
        <InputPhone
          tabIndex={54}
          id="program-claim-fax"
          name="claim-fax"
          phoneLabel="Fax Number"
          phoneValue={programClaimData?.claimFax}
          readOnly={readOnly}
          className={readOnly ? "true_information_disabled" : ""}
          errorMessagePhone={
            programAdminErrors?.errorDetails?.programClaim?.claimFax
          }
          onChangePhoneValue={(value) => {
            setProgramClaimData({
              ...programClaimData,
              claimFax: value,
            });
          }}
        />
      </Col>
    </Row>
  );
};
