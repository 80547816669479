import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { Col, Font, FontBold, Row } from "../../../TrueUI";
import { ClaimLandingDetailsDto } from "../../../../dtos/claim-landing-details-dto";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import Link from "../../../TrueUI/Typography/Link";
import { getClaimDetailsTabURLWithClaimNumber } from "../../../../utilities/queryStringsHashFunctions";
import ModalChangeClaimDetails from "./ModalChangeClaims/ModalChangeClaimDetails";
import { ClaimDetailsTabEnums } from "../../../../dtos/claim-details-tab-enums";
import { usePermissions } from "../../../../hooks";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import ModalChangeClaimStatus from "./ModalChangeClaims/ModalChangeClaimStatus";
import ModalClaimFinancialReserves from "./ClaimTabs/ClaimFinancialComponents/ModalClaimFinancialReserves";
import { AllModalConfiguration } from "./ModalChangeClaims/ModalChangeProps";
import ModalClaimFinancial from "./ClaimTabs/ClaimFinancialComponents/ModalClaimFinancial";
import { ClaimLandingClaimantDto } from "../../../../dtos/claim-landing-claimant-dto";
import Paragraph from "../../../TrueUI/Typography/Paragraph";
import ModalDefaultTask from "./ModalChangeClaims/ModalDefaultTask";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import ModalDefaultReserves from "./ModalChangeClaims/ModalDefaultReserves";
import { useNavigate } from "react-router";
import { getParamFromKeyList } from "../../../../utilities/urlProcessingFunctions";
import { INSURED_ID } from "../../../../utilities/queryStringsHash";

type ClaimLandingDetailsProps = {
  claimLandingDetailsData?: ClaimLandingDetailsDto;
  claimId?: number;
  claimNumber?: string;
  insuredId: number;
  tabKey: string;
  claimLandingClaimant?: ClaimLandingClaimantDto;
};

const ClaimLandingDetails: FC<ClaimLandingDetailsProps> = ({
  claimLandingDetailsData,
  claimId,
  claimNumber,
  insuredId,
  tabKey,
  claimLandingClaimant,
}) => {
  const navigate = useNavigate();
  const [allModalConfiguration, setAllModalConfiguration] =
    useState<AllModalConfiguration>({
      openModalChangeClaimDetails: false,
      openModalChangeClaimStatus: false,
      openModalAddReserve: false,
      openModalAddPayment: false,
      openModalAddDefaultTasks: false,
      openModalAddDefaultReserves: false,
    });

  const hasClaimsManagementPermissions = usePermissions([
    PermissionsEnums.TRUE_ADMIN,
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ])?.hasPermission;

  const openClaimDetails = () => {
    const hashParamList = getParamFromKeyList(window.location.hash, INSURED_ID);
    if ((hashParamList?.value ?? 0) !== 0) {
      const pathParts = window.location.pathname.split("/");
      const lastPathPart = pathParts[pathParts.length - 1];
      navigate(
        `/insured/${lastPathPart}${getClaimDetailsTabURLWithClaimNumber(
          ClaimDetailsTabEnums.DETAILS,
          claimNumber
        )}#${INSURED_ID}=${
          (hashParamList?.value ?? 0) == insuredId
            ? hashParamList?.value
            : lastPathPart
        }`
      );
    } else {
      navigate(
        `/insured/${insuredId}${getClaimDetailsTabURLWithClaimNumber(
          ClaimDetailsTabEnums.DETAILS,
          claimNumber
        )}`
      );
    }
  };

  return (
    <div>
      <InformationContainer
        title={
          <Link
            displayValue={"Details"}
            linkFontType={"TITLE"}
            name="claimDetailsTitle"
            onClick={() => openClaimDetails()}
          />
        }
      >
        <Row
          verticalMargin="0px"
          horizontalMargin="0px"
          verticalGutter="0px"
          horizontalGutter="5px"
          verticalAlign="flex-start"
          horizontalAlign="flex-start"
          numberOfColumns={15}
        >
          <Col
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            horizontalGutter="0px"
            horizontalAlign="flex-start"
            verticalAlign="flex-start"
          >
            <Row
              allowWrap
              horizontalAlign="flex-start"
              verticalAlign="flex-start"
              verticalMargin="0px"
              horizontalMargin="0px"
              verticalGutter="0px"
              horizontalGutter="0px"
              numberOfColumns={13}
            >
              <Col
                breakpoints={{ md: 6, lg: 6, xl: 6 }}
                horizontalGutter="0px"
                horizontalAlign="flex-start"
              >
                <Stack spacing={"5px"}>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetailPolicyNumberLabel"
                  >
                    {`Policy Number - ${
                      claimLandingDetailsData?.policyNumber ?? ""
                    }`}
                  </Font>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetailLastDiaryDateLabel"
                  >
                    {`Last Diary Date -  ${
                      claimLandingDetailsData?.lastDiaryDate?.split(" ")?.[0] ??
                      ""
                    }`}
                  </Font>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetailOpenTasksLabel"
                  >
                    {`# Open Tasks - `}
                    <Font>
                      {claimLandingDetailsData?.openTasksCounter ?? ""}
                    </Font>
                  </Font>
                </Stack>
              </Col>
              <Col
                breakpoints={{ md: 7, lg: 7, xl: 7 }}
                horizontalGutter="0px"
                horizontalAlign="flex-start"
              >
                <Stack spacing={"5px"}>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetail-InjuryLabel"
                  >{`Injury - ${claimLandingDetailsData?.injury ?? ""}`}</Font>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetailBodyPartLabel"
                  >
                    {`Body Part - ${claimLandingDetailsData?.bodyPart ?? ""}`}
                  </Font>
                  <Font
                    whiteSpace="normal"
                    textAlign="start"
                    name="claimDetailCauseLabel"
                  >
                    {`Cause - ${claimLandingDetailsData?.cause ?? ""}`}
                  </Font>
                </Stack>
              </Col>
              <Col
                breakpoints={{ md: 13, lg: 13, xl: 13 }}
                {...colWithNoMarginNorGutter}
                horizontalAlign="flex-start"
              >
                <Paragraph>
                  <FontBold name="claimDetailIncidentDescriptionLabel">
                    Incident Description:{" "}
                  </FontBold>
                  {claimLandingDetailsData?.incidentDescription ?? ""}
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
            horizontalAlign="flex-start"
          >
            <Stack textAlign={"left"} spacing={"5px"}>
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Add Default Tasks"}
                name="addDefaultTasksLink"
                disabled={!hasClaimsManagementPermissions}
                onClick={() =>
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalAddDefaultTasks: true,
                  })
                }
                underline="always"
              />
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Add Default Reserves"}
                name="addDefaultReservesLink"
                disabled={!hasClaimsManagementPermissions}
                onClick={() => {
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalAddDefaultReserves: true,
                  });
                }}
                underline="always"
              />
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Add a Reserve"}
                name="claimDetailAddReserveLink"
                disabled={!hasClaimsManagementPermissions}
                onClick={() =>
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalAddReserve: true,
                  })
                }
                underline="always"
              />
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Add a Payment"}
                name="claimDetailAddPaymentLink"
                disabled={!hasClaimsManagementPermissions}
                onClick={() =>
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalAddPayment: true,
                  })
                }
                underline="always"
              />
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Change Status"}
                disabled={!hasClaimsManagementPermissions}
                onClick={() =>
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalChangeClaimStatus: true,
                  })
                }
                name="claimDetailChangeStatusLink"
                underline="always"
              />
              <Link
                linkFontType={"SMALL_TITLE"}
                displayValue={"+ Change Details"}
                disabled={!hasClaimsManagementPermissions}
                onClick={() =>
                  setAllModalConfiguration({
                    ...allModalConfiguration,
                    openModalChangeClaimDetails: true,
                  })
                }
                name="claimDetailChangeDetailsLink"
                underline="always"
              />
            </Stack>
          </Col>
        </Row>
      </InformationContainer>
      <ModalChangeClaimDetails
        setAllModalConfiguration={setAllModalConfiguration}
        allModalConfiguration={allModalConfiguration}
        claimId={claimId}
        tabKey={tabKey}
      />
      <ModalChangeClaimStatus
        setAllModalConfiguration={setAllModalConfiguration}
        allModalConfiguration={allModalConfiguration}
        claimId={claimId}
        insuredId={insuredId}
      />
      <ModalDefaultTask
        setAllModalConfiguration={setAllModalConfiguration}
        allModalConfiguration={allModalConfiguration}
        claimId={claimId}
        insuredId={insuredId}
        tabKey={tabKey}
      />
      <ModalClaimFinancialReserves
        reserveId={0}
        insuredId={insuredId}
        isReserveModalOpen={allModalConfiguration.openModalAddReserve}
        claimId={claimId}
        setReserveModalOpen={(value) => {
          setAllModalConfiguration({
            ...allModalConfiguration,
            openModalAddReserve: value,
          });
        }}
      />
      <ModalClaimFinancial
        claimId={claimId}
        tabKey={tabKey}
        insuredId={insuredId}
        modalProps={{
          claimId,
          open: allModalConfiguration.openModalAddPayment,
          title: "Add Payment",
          option: "add-payment",
        }}
        closeModal={(value) =>
          setAllModalConfiguration({
            ...allModalConfiguration,
            openModalAddPayment: value,
          })
        }
        paymentSelectsOptions={null as any}
        claimLandingClaimant={claimLandingClaimant}
      />
      <ModalDefaultReserves
        setAllModalConfiguration={setAllModalConfiguration}
        allModalConfiguration={allModalConfiguration}
        claimId={claimId}
        tabKey={tabKey}
      />
    </div>
  );
};

export default ClaimLandingDetails;
