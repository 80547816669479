import { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { globalOptions } from "../../../../GlobalAtoms";
import { getUniversalTime } from "../../../../utilities/dateFunctions";
import { areObjectsEqual } from "../../../../utilities/objectFunctions";
import { Modal, RichEditor } from "../../../TrueUI";
import { DialogConfirmationConfigurationProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  disableOrEnableDeleteButton,
  updatePolicyNarrativeNote,
  deletedNoteValues,
} from "./UnderwriterNotesUtils";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getDateFromUtcToUserTimeZone } from "../../../../utilities/dateTimeZoneFunctions";

type UnderwriterNoteModalProps = {
  currentNote: PolicyNarrativeBlob | null;
  setCurrentNote: (note: PolicyNarrativeBlob | null) => void;
  underwriterNotesUI?: PolicyNarrativeBlob[] | null;
  setUnderwriterNotesUI?: (narratives: PolicyNarrativeBlob[] | null) => void;
};

const UnderwriterNoteModal: FC<UnderwriterNoteModalProps> = ({
  currentNote,
  setCurrentNote,
  underwriterNotesUI,
  setUnderwriterNotesUI,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const shortName = localOptions?.shortName;

  const [dialogConfirmationConfiguration, setDialogConfirmationConfiguration] =
    useState<DialogConfirmationConfigurationProps>({
      isOpen: false,
      text: "",
      option: "save-one",
    });

  const showNoButton = () => {
    return dialogConfirmationConfiguration.option !== "save-all"
      ? (close) => {
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
        }
      : undefined;
  };

  const checkPendingChangesToSave = () => {
    const underwriterNote =
      underwriterNotesUI?.find(
        (note) => note.narrativeID === currentNote?.narrativeID
      ) ?? {};
    if (!areObjectsEqual(currentNote, underwriterNote)) {
      setDialogConfirmationConfiguration({
        isOpen: true,
        text: "Are you sure you want to close before saving the changes?",
        option: "save-one",
      });
    } else {
      setCurrentNote(null);
    }
  };

  const saveEditedNote = (isDeleted) => {
    const newEditedNote = isDeleted
      ? { ...deletedNoteValues, narrativeID: currentNote?.narrativeID }
      : {
          narrativeID: currentNote?.narrativeID,
          formattedNarrativeText: currentNote?.formattedNarrativeText ?? "",
          updateBy: shortName,
          updateOn: getDateFromUtcToUserTimeZone(
            getUniversalTime(),
            localOptions?.timeZoneID ?? null
          ),
        };

    const newUpdatedNote = updatePolicyNarrativeNote(
      underwriterNotesUI ?? [],
      newEditedNote
    );
    setUnderwriterNotesUI?.(newUpdatedNote);
    setCurrentNote(null);
  };

  return (
    <>
      <Modal
        id="underwriterNoteModal"
        title={currentNote?.narrativeDescription ?? ""}
        size="lg"
        open={true}
        showCancelTextButton
        showCloseButton
        deleteDisabled={disableOrEnableDeleteButton(currentNote)}
        saveEvent={() => saveEditedNote(false)}
        deleteEvent={() => {
          setDialogConfirmationConfiguration({
            isOpen: true,
            text: "Are you sure you want to delete this content?",
            option: "delete",
          });
        }}
        cancelEvent={() => {
          checkPendingChangesToSave();
        }}
      >
        <RichEditor
          disableCollapse
          value={currentNote?.formattedNarrativeText}
          contentStyle={{ height: 300 }}
          onChangeRawValue={(value) =>
            setCurrentNote({ ...currentNote, formattedNarrativeText: value })
          }
        />
      </Modal>
      <DialogConfirmation
        id="underwriterNoteDialogConfirmation"
        open={dialogConfirmationConfiguration?.isOpen}
        dialogDescriptionText={dialogConfirmationConfiguration?.text}
        optionYesOverrideLabel="OK"
        onOptionNoEvent={showNoButton()}
        optionNoOverrideLabel={"Cancel"}
        onOptionYesEvent={(close) => {
          if (dialogConfirmationConfiguration.option === "save-one") {
            setCurrentNote(null);
          } else if (dialogConfirmationConfiguration.option === "delete") {
            saveEditedNote(true);
            setCurrentNote(null);
          }
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
        }}
      />
    </>
  );
};

export default UnderwriterNoteModal;
