import { FC, useState } from "react";
import SmallTitle from "../Typography/SmallTitle";
import style from "./downloadFile.module.css";
import { useFileRequestInstance } from "../../../hooks/useFileStorage";
import { getIconByExt } from "./DownloadFileUtils";
import CloseIcon from "@mui/icons-material/Close";
import DialogConfirmation from "../Dialogs/DialogConfirmation";

type DownloadFileProps = {
  fileName: string;
  fileId: number;
  showDeleteButton?: boolean;
  onDeleteFile?: (e?: any) => void;
};

const DownloadFile: FC<DownloadFileProps> = ({
  fileName,
  fileId,
  showDeleteButton = false,
  onDeleteFile,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const { downloadFile } = useFileRequestInstance();

  return (
    <div className={style.download_file_general_wrapper}>
      <div
        className={`true_input_general_container ${style.download_file_wrapper}`}
        true-element={`true-element true-download-file-${fileName}`}
        onClick={() => downloadFile(fileName)}
      >
        {getIconByExt(fileName)}
        <SmallTitle primaryColor className={style.file_name_download}>
          {fileName}
        </SmallTitle>
      </div>
      {showDeleteButton && (
        <CloseIcon
          className={style.delete_file_icon}
          fontSize="small"
          onClick={() => setIsDialogConfirmationOpen(true)}
        />
      )}
      <DialogConfirmation
        id="download-file-dialog-confirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this File?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          onDeleteFile?.(fileId);
          setIsDialogConfirmationOpen(close);
        }}
      />
    </div>
  );
};

export default DownloadFile;
