import { FC } from "react";
import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ScorecardItemDto } from "../../../dtos/scorecard-item-dto";

type ScoreBarProps = {
  scorecardItem: ScorecardItemDto;
  info?: {
    comparison: string;
    percentage: number;
  };
};

const ScoreBar: FC<ScoreBarProps> = ({ scorecardItem, info }) => {
  return (
    <Box className={"listItem"}>
      <Box className={"listText"}>
        <CircleIcon className={"circle"} />
        <Typography className={"text"}>
          {`${scorecardItem.scoreDescription}  ${info?.comparison}`}
        </Typography>
      </Box>
      <Box className={"barsArea"}>
        <Box
          className={"indicator"}
          sx={{
            width:
              scorecardItem.scale.scaleOrder === "Ascending"
                ? `${info?.percentage}%`
                : `${100 - (info?.percentage ?? 0)}%`,
          }}
        >
          <Typography className={"indicatorPercentage"}>
            {info?.percentage}
          </Typography>
          <ArrowDropDownIcon className={"arrowIcon"} />
        </Box>
        <Box className={"listBars"}>
          <Box
            className={"barBox"}
            sx={{
              width: `${
                scorecardItem.scale.scaleOrder === "Ascending"
                  ? scorecardItem.scale.range1Top
                  : 100 - scorecardItem.scale.range2Top
              }%`,
            }}
          >
            <Box
              className={"bar"}
              key={`${scorecardItem.scoreName}_range1`}
              sx={{
                backgroundColor: `${scorecardItem.scale.range1Color}`,
              }}
            >
              1
            </Box>
            <Box className={"range1"}>
              <Typography className={"percentage"} sx={{}}>
                {scorecardItem.scale.scaleOrder === "Ascending" ? 0 : 100}
              </Typography>
              <Typography className={"percentage r1"} sx={{}}>
                {scorecardItem.scale.range1Top}
              </Typography>
            </Box>
          </Box>
          <Box
            className={"barBox"}
            sx={{
              width: `${
                scorecardItem.scale.scaleOrder === "Ascending"
                  ? scorecardItem.scale.range2Top -
                    scorecardItem.scale.range1Top
                  : scorecardItem.scale.range1Top -
                    scorecardItem.scale.range2Top
              }%`,
            }}
          >
            <Box
              className={"bar"}
              key={`${scorecardItem.scoreName}_range2`}
              sx={{
                backgroundColor: `${scorecardItem.scale.range2Color}`,
              }}
            >
              2
            </Box>
            <Typography className={"percentage rx"} sx={{}}>
              {scorecardItem.scale.range2Top}
            </Typography>
          </Box>
          <Box
            className={"barBox"}
            sx={{
              width: `${
                scorecardItem.scale.scaleOrder === "Ascending"
                  ? scorecardItem.scale.range3Top -
                    scorecardItem.scale.range2Top
                  : scorecardItem.scale.range2Top -
                    scorecardItem.scale.range3Top
              }%`,
            }}
          >
            <Box
              className={"bar"}
              key={`${scorecardItem.scoreName}_range3`}
              sx={{
                backgroundColor: `${scorecardItem.scale.range3Color}`,
              }}
            >
              3
            </Box>
            <Typography className={"percentage rx"} sx={{}}>
              {scorecardItem.scale.range3Top}
            </Typography>
          </Box>
          <Box
            className={"barBox"}
            sx={{
              width: `${
                scorecardItem.scale.scaleOrder === "Ascending"
                  ? scorecardItem.scale.range4Top -
                    scorecardItem.scale.range3Top
                  : scorecardItem.scale.range3Top -
                    scorecardItem.scale.range4Top
              }%`,
            }}
          >
            <Box
              className={"bar"}
              key={`${scorecardItem.scoreName}_range4`}
              sx={{
                backgroundColor: `${scorecardItem.scale.range4Color}`,
              }}
            >
              4
            </Box>
            <Typography className={"percentage rx"} sx={{}}>
              {scorecardItem.scale.range4Top}
            </Typography>
          </Box>
          <Box
            className={"barBox"}
            sx={{
              width: `${
                scorecardItem.scale.scaleOrder === "Ascending"
                  ? scorecardItem.scale.range5Top -
                    scorecardItem.scale.range4Top
                  : scorecardItem.scale.range4Top -
                    scorecardItem.scale.range5Top
              }%`,
            }}
          >
            <Box
              className={"bar"}
              key={`${scorecardItem.scoreName}_range5`}
              sx={{
                backgroundColor: `${scorecardItem.scale.range5Color}`,
              }}
            >
              5
            </Box>
            <Typography className={"percentage rx"} sx={{}}>
              {scorecardItem.scale.range5Top}
            </Typography>
          </Box>
        </Box>
        <Box className={"percentageBox"}></Box>
      </Box>
    </Box>
  );
};

export default ScoreBar;
