import { useEffect, useState } from "react";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { SelectOptions } from "../../../dtos/select-options";
import { usePermissions } from "../../../hooks";
import { Select } from "../../TrueUI";

type ToggleColumnsByOptionsProps = {
  options: string[];
  columnsToToggle: string[];
};

type IncidentTableFilterProps = {
  options: Partial<SelectOptions>[] | SelectOptions[];
  id: string;
  value?: any;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  selectWidth?: string;
  toggleColumnsByOptions?: ToggleColumnsByOptionsProps[];
  onChange?: (e: any) => void;
  permissions?: PermissionsEnums[];
};

const IncidentTableFilter: (properties: IncidentTableFilterProps) => any = (
  props
) => {
  const hasPermission = usePermissions(props?.permissions ?? []).hasPermission;
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  //TODO: review this function to implement hidden columns
  const setStatus = (val: string) => {
    if (props?.toggleColumnsByOptions) {
      const columnsToToggleFound = props?.toggleColumnsByOptions?.find(
        (toggleOptionsAndColumns) => {
          return toggleOptionsAndColumns.options.includes(val)
            ? toggleOptionsAndColumns
            : null;
        }
      )?.columnsToToggle;

      const columnsNotHiddenToToggle = columnsToToggleFound?.filter(
        (column) => !hiddenColumns.includes(column)
      );

      const columnsAlreadyHiddenToToggle = hiddenColumns?.filter(
        (column) => !columnsToToggleFound?.includes(column)
      );

      const columnsToToggleResult = columnsNotHiddenToToggle?.concat(
        columnsAlreadyHiddenToToggle
      );

      const newHiddenColumns = hiddenColumns
        ?.filter((column) => columnsToToggleFound?.includes(column))
        .concat(columnsNotHiddenToToggle ?? []);

      if (
        columnsNotHiddenToToggle !== undefined &&
        columnsAlreadyHiddenToToggle !== undefined &&
        columnsToToggleResult !== undefined &&
        columnsToToggleResult.length > 0
      ) {
        setHiddenColumns(newHiddenColumns);
      }

      if (val === "all" && hiddenColumns.length > 0) {
        setHiddenColumns([]);
      }
    }
  };

  useEffect(() => {
    setStatus(props?.value?.toString() ?? "all");
  }, []);

  return (
    <Select
      id={`true-filter-for-table-${props?.id}`}
      name={`filter-dropdown-${props?.id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={props?.labelText ?? "Show:"}
      labelPosition={props?.labelPosition ?? "start"}
      options={props?.options ?? []}
      value={props?.value ?? "all"}
      type={"tableFilter"}
      inputWidth={props?.selectWidth ? props?.selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        props?.onChange?.(value);
        setStatus(value);
      }}
      disabled={!hasPermission}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
    />
  );
};

export default IncidentTableFilter;
