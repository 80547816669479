import moment from "moment";
import { SelectOptions } from "../../../dtos/select-options";

export const tableName = "statements_table";

export type StatementsBatchTableWrapperProps = {
  statementTypeOptions: Partial<SelectOptions>[];
  statementYearsOptions: Partial<SelectOptions>[];
  setRenderTableConf?: (value: RenderTableContentConfig) => void;
};

export type FilterValuesSelectedProps = {
  statementType: string;
  date: string | null;
  startDateRange: string | null;
  endDateRange: string | null;
};

export type StatementsDetailsTableWrapperProps = {
  statementBatchId: number | null;
  statementDate: string | null;
  onClickReturnToBatchTable?: () => void;
};

export enum StatementTableType {
  WAIT_UNTIL_CONFIGURATION_DONE = 0,
  STATEMENT_BATCH = 1,
  STATEMENT_DETAIL = 2,
}

export type RenderTableContentConfig = {
  tableToRender: StatementTableType;
  statementBatchId: number | null;
  statementDate: string | null;
};

export const defaultRenderTableValues = {
  tableToRender: StatementTableType.WAIT_UNTIL_CONFIGURATION_DONE,
  statementBatchId: null,
  statementDate: null,
};

export const defaultFilterValuesSelected = {
  statementType: "all",
  date: null,
  startDateRange: null,
  endDateRange: null,
};

export const getDatesRange = (year: string) => {
  if (year === new Date().getFullYear().toString()) {
    const currentDate = new Date();
    const format = "M/D/YYYY";
    const startDate = moment(currentDate)
      .subtract(12, "months")
      .startOf("month")
      .format(format);
    const endDate = moment(currentDate)
      .add(1, "months")
      .endOf("month")
      .format(format);
    return { startDate, endDate };
  } else {
    const intYear = parseInt(year);
    const startDate = moment().year(intYear).startOf("year").format("M/D/YYYY");
    const endDate = moment().year(intYear).endOf("year").format("M/D/YYYY");
    return { startDate, endDate };
  }
};

export const filterDataByDateAndStatementType = (
  tableData: string[],
  filterValuesSelected: FilterValuesSelectedProps
) => {
  const typeColumnIndex = 1;
  const dateColumnIndex = 2;

  const filteredDataByDates = tableData.filter((row) => {
    const rowDate = new Date(row[dateColumnIndex]);
    const startDate = new Date(
      filterValuesSelected.startDateRange ?? new Date()
    );
    const endDate = new Date(filterValuesSelected.endDateRange ?? new Date());
    return rowDate >= startDate && rowDate <= endDate;
  });

  const filterDataByType = filteredDataByDates.filter(
    (row) =>
      row[typeColumnIndex] === filterValuesSelected.statementType ||
      filterValuesSelected.statementType === "all"
  );

  return filterDataByType;
};
