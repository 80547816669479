import { SelectOptions } from "../../../dtos/select-options";
import { FocusedSelectOptionProps } from "./Select";

const PADDINGS_AND_MARGINS = 15;
const HEIGHT_OPTION = 29;
const HEIGHT_DROPDOWN = 32;
const SAFE_DISTANCE_TO_BOTTOM_PAGE = 40;

const validateTopAgainstBody = (
  top: number,
  totalOptionsHeight: number,
  bodyHeight: number,
  isOptionContainerAtTop: boolean,
  maxHeight: number | null
) => {
  if (
    top + totalOptionsHeight + SAFE_DISTANCE_TO_BOTTOM_PAGE > bodyHeight ||
    isOptionContainerAtTop === true
  ) {
    if (maxHeight === null) {
      return {
        newTopValue: top - totalOptionsHeight - HEIGHT_DROPDOWN,
        isValid: false,
      };
    } else {
      if (
        totalOptionsHeight > maxHeight &&
        top + maxHeight + SAFE_DISTANCE_TO_BOTTOM_PAGE > bodyHeight
      ) {
        return {
          newTopValue: top - maxHeight - HEIGHT_DROPDOWN,
          isValid: false,
        };
      } else if (totalOptionsHeight < maxHeight) {
        return {
          newTopValue: top - totalOptionsHeight - HEIGHT_DROPDOWN,
          isValid: false,
        };
      } else {
        return {
          newTopValue: top,
          isValid: true,
        };
      }
    }
  }
  return {
    newTopValue: top,
    isValid: true,
  };
};

export const getPositionTopByOptionsPosition = (
  top: number,
  numberOfOptions: number,
  optionsContainerPosition: "bottom" | "top",
  labelPosition: "start" | "end" | "top" | "bottom",
  isThereLabel: boolean,
  labelHeight: number,
  maxHeight: number | null
) => {
  const body = document.body;

  const totalOptionsHeight =
    PADDINGS_AND_MARGINS + HEIGHT_OPTION * numberOfOptions;

  const { newTopValue, isValid } = validateTopAgainstBody(
    top,
    totalOptionsHeight,
    body.offsetHeight,
    optionsContainerPosition === "top",
    maxHeight
  );

  if (optionsContainerPosition === "top" || isValid === false) {
    return newTopValue - labelHeight;
  }

  if (
    isThereLabel &&
    (labelPosition === "top" ||
      labelPosition === "start" ||
      labelPosition === "end")
  )
    return top - labelHeight;

  if (isThereLabel && labelPosition === "bottom") return top + labelHeight;

  return top;
};

export const getCoordsForOptionContainer = (select: HTMLDivElement | null) => {
  if (select !== null) {
    const box = select.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {
      top: Math.round(top),
      left: Math.round(left),
    };
  }
  return {
    top: 0,
    left: 0,
  };
};

export const getTextWidth = (text, isMultiTable?: boolean) => {
  const selector = isMultiTable ? "multiSelect" : "select";
  const optionSpanContainer: any = document.querySelector(
    `button[class*='${selector}_true_select_option'] span`
  );
  const buttonContainer: any = document.querySelector(
    `button[class*='${selector}_true_select_option']`
  );
  const buttonComputedStyle = window.getComputedStyle(buttonContainer);
  const buttonPaddingLeft = parseFloat(
    buttonComputedStyle.getPropertyValue("padding-left")
  );
  const buttonPaddingRight = parseFloat(
    buttonComputedStyle.getPropertyValue("padding-right")
  );
  const spanComputedStyle = window.getComputedStyle(optionSpanContainer);
  const letterSpacing: any = parseFloat(
    spanComputedStyle.getPropertyValue("letter-spacing")
  );
  const spanFontFamily = spanComputedStyle.getPropertyValue("font-family");
  const spanFontSize = spanComputedStyle.getPropertyValue("font-size");

  // Create a temporary canvas element
  let canvas: any = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Set the font for the context
  ctx.font = `${spanFontSize} ${spanFontFamily}`;
  // Measure the width of the text
  let width = ctx?.measureText(text).width;
  width += text.length * letterSpacing;
  // Clean up
  canvas = null; // Optional, for memory cleanup
  //the 5 is for some selects having scroll bar
  return Math.ceil(width + buttonPaddingLeft + buttonPaddingRight) + 5;
};

export function isAcceptKey(event: any) {
  return ["Space", "Enter"].includes(event.code);
}

export function isCancelKey(event: any) {
  return ["Escape", "Backspace"].includes(event.code);
}

export const setCurrentElementOption = (
  SELECT_ITEM_CLASS: string,
  focusedSelectOptionIndex: number
) => {
  //Here we get the element options in the select each time the index for the option should have the focus
  //is updated via arrows keys and with the index get the element option and we wet the focus to that element
  const elementItemOptions =
    getElementOptionsBySelectClassName(SELECT_ITEM_CLASS);

  if (
    focusedSelectOptionIndex >= 0 &&
    focusedSelectOptionIndex < elementItemOptions.length
  ) {
    const currentFocusedElement = elementItemOptions.at(
      focusedSelectOptionIndex
    );
    currentFocusedElement?.focus();
  }
};

const getElementOptionsBySelectClassName = (SELECT_ITEM_CLASS: string) => {
  return [
    ...(document.getElementsByClassName(SELECT_ITEM_CLASS) ?? []),
  ] as HTMLElement[];
};

export const updateOptionWidthToLargestOptionWidth = (
  isOpen: boolean,
  selectRef: any,
  options: SelectOptions[] | Partial<SelectOptions>[],
  setOptionContainerWidth: any,
  setFocusedSelectOption: (
    focusedSelectOption: FocusedSelectOptionProps
  ) => void,
  isMultiTable?: boolean
) => {
  if (isOpen === true) {
    // if the select is opened we get the select width
    const selectCurrentWidth = selectRef?.current?.clientWidth ?? 0;
    //we get the width of each option using the displayName
    //in the function getTextWidth the width is calculated
    //based in string length, font size, font family and letter spacing
    const optionsWidth = options?.map((option) =>
      getTextWidth(option.displayName, isMultiTable)
    );
    //We get the max width
    const maxLengthOption = Math.max.apply(null, optionsWidth);
    //we compare select width against the max width from the options
    //if is greater we use the calculated option width and the focus is setted to the first option
    const containerWidth =
      selectCurrentWidth < maxLengthOption
        ? maxLengthOption
        : selectCurrentWidth;
    setOptionContainerWidth(containerWidth);
    const firstLetterOfFirstOption =
      options.length > 0 ? options[0].stringValue?.[0]?.toLowerCase() : "";
    setFocusedSelectOption({
      index: 0,
      keySelected: firstLetterOfFirstOption ?? "",
    });
  }
  if (isOpen === false) setFocusedSelectOption({ index: -1, keySelected: "" });
};

export const getSelectedOptionIndexByKeyPress = (
  event: any,
  options: Partial<SelectOptions>[],
  focusedSelectOption: FocusedSelectOptionProps
): FocusedSelectOptionProps => {
  const typedKey = event.key.toLowerCase();
  const indexOfFirstMatch = options.findIndex(
    (option) => option.displayName?.[0].toLowerCase() === typedKey
  );

  if (indexOfFirstMatch > -1) {
    const isDifferentKey = typedKey !== focusedSelectOption.keySelected;
    const isNextMatchWithKey =
      focusedSelectOption.index + 1 < options.length &&
      options[focusedSelectOption.index + 1].displayName?.[0].toLowerCase() ===
        typedKey;

    const newIndex = isDifferentKey
      ? indexOfFirstMatch
      : isNextMatchWithKey
      ? focusedSelectOption.index + 1
      : indexOfFirstMatch;

    return {
      index: newIndex,
      keySelected: typedKey,
    };
  }
  return {
    index: -1,
    keySelected: "",
  };
};
