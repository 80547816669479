import { useEffect, FC, useState } from "react";
import { useApiGet } from "../../hooks";
import { ClaimsBasePageDto } from "../../dtos/claims-base-page-dto";
import SubSideNav from "../TrueUI/SubSideNav/SubSideNav";
import ClaimsBodyWrapper from "./ClaimsBodyWrapper";
import "./ClaimsStyles.css";
import { NavItemDto } from "../../dtos/nav-item-dto";
import {
  CLAIMS_PARENT_ITEM,
  CLAIMS_CHILD_ITEM,
} from "../../utilities/queryStringsHash";
import { NavSubItemDto } from "../../dtos/nav-sub-item-dto";
import {
  goToAnyTopClaimSection,
} from "../../utilities/queryStringsHashFunctions";
import { useNavigate } from "react-router-dom";

const Claims: FC = () => {
  const [parentSelected, setParentSelected] = useState<number>(0)
  const [childSelected, setChildSelected] = useState<number>(0)
  const navigate = useNavigate();
  const [navItems, setNavItems] = useState<NavItemDto[]>([]);
  const { responseGet, dispatchGet } = useApiGet<ClaimsBasePageDto>(
    "api/Claims/GetClaimsBasePage"
  );
  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setNavItems(responseGet?.axiosResponse?.data?.subSideNav?.navItems ?? []);
    }
  }, [responseGet]);

  const onChildItemClick = (_parent: NavItemDto, child: NavSubItemDto) => {    
    setParentSelected(_parent.index);
    setChildSelected(child.index);
    navigate(
      goToAnyTopClaimSection(child?.section, parentSelected, childSelected)
    );
  };

  const onParentItemClick = (parent?: NavItemDto) => {
    if (parent?.section) {
      setParentSelected(parent.index);
      navigate(
        goToAnyTopClaimSection(parent?.section, parentSelected, childSelected)
      );
    }
  };

  return (
    <div id={"claims_wrapper"}>
      <div id={"left_claims_wrapper"}>
        <SubSideNav
          navItems={navItems}
          onChildItemClick={onChildItemClick}
          onParentItemClick={onParentItemClick}
          parentSelected={parentSelected}
          childSelected={childSelected}
          parentQueryString={CLAIMS_PARENT_ITEM}
          childQueryString={CLAIMS_CHILD_ITEM}
        />
      </div>

      <div id={"right_claims_wrapper"}>
        <div className={"claims_body_container"}>
          <div
            className={"claims_body_wrapper_container"}
            style={{
              display: "flex",
              overflow: "hidden",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <ClaimsBodyWrapper />
          </div>
        </div>
      </div>
    </div>
    // </Box>
  );
};

export default Claims;
