import { FC, useEffect, useState } from "react";
import { Font, Select } from "../../../../../TrueUI";
import {
  getDefaultOptionForPolicyLimits,
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPolicyLimitAsSelectOptionList,
  getPreviousRunningTotal,
  getRunningTotalByPremiumBasisRow,
  getStatesUpdatedByRowResult,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import {
  customRound,
  getNumberAsStringWithComas,
} from "../../../../../../utilities/stringFunctions";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { PolicyLimitDto } from "../../../../../../dtos/policy-limit-dto";
import {
  POLICY_LIMITS_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import { getStateByStateCodeAndDates } from "../../../PolicyQuoteForm/PolicyQuoteUtils";

const getRunningTotalAndCalculatedAmount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  atomValue: GlobalInsuredAtomProperties | null,
  selectedPolicyLimit?: PolicyLimitDto
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * (selectedPolicyLimit?.rate ?? 0)).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: selectedPolicyLimit?.rate ?? 0,
    policyLimits: {
      policyLimitsID: selectedPolicyLimit?.id ?? 0,
      optionDisplay: selectedPolicyLimit?.displayValue ?? "",
      elRate: selectedPolicyLimit?.rate ?? 0,
    },
  };
  return rowResult;
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  atomValue: GlobalInsuredAtomProperties | null,
  selectedPolicyLimit?: PolicyLimitDto
) => {
  const calculationResults = getRunningTotalAndCalculatedAmount(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    rating,
    atomValue,
    selectedPolicyLimit
  );
  const statesUpdatedByCalculations = getStatesUpdatedByRowResult(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    atomValue
  );
  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const PolicyLimitsRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const [dropDownOptions, setDropDownOptions] = useState<
    Partial<SelectOptions>[] | null
  >(null);
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );

  const listenerPolicyLimitsRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${POLICY_LIMITS_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  const runCalculations = () => {
    const atomValue = getAtom();
    const policyLimitSelected = props.configurations?.policyLimitsOptions?.find(
      (x) => x.id === selectedOption
    );

    const updatedValues = getStatesUpdatedByCalculations(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      props.rateIndex,
      props.rating,
      atomValue,
      policyLimitSelected
    );

    setAtom(updatedValues.newAtomValue);
    setRowResult(updatedValues.rowResult);
    props.calculationsFinished?.();
  };

  useEffect(() => {
    if (!props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (listenerPolicyLimitsRowComponent !== null && !props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerPolicyLimitsRowComponent]);

  useEffect(() => {
    const atomValue = getAtom();

    const selectOptions = getPolicyLimitAsSelectOptionList(
      props.configurations?.policyLimitsOptions ?? []
    );

    const defaultOptionId = getDefaultOptionForPolicyLimits(
      props.configurations?.policyLimitsOptions ?? [],
      props.rating.policyLimits,
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.policyLimits
    );

    setSelectedOption(defaultOptionId ?? 0);
    setDropDownOptions(selectOptions);
  }, [props.configurations]);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_6}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div
        className={`${style.premium_row_cell_container} ${style.premium_row_input_cell}`}
      >
        <Select
          id={`policy-limit-${props?.nameElement}`}
          name={`${props?.nameElement}`}
          trueElement={`exposure-table-${props?.nameElement}-selection-option`}
          variant={"filled"}
          value={selectedOption}
          options={dropDownOptions ?? []}
          onChange={(value) => setSelectedOption(value)}
          inputFontType="BODY"
          readOnly={props.readOnly}
        />
      </div>
      <div className={style.premium_row_rate_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
          {getNumberAsStringWithComas(rowResult.rate)}
        </Font>
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default PolicyLimitsRow;
