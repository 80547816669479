import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import {
  Collapse,
  BasePageContainer,
  Input,
  Select,
  Button,
  SaveButton,
  ScoreChip,
  Switch,
  // ModalReply,
  Loading,
  Modal,
  RichEditor,
  MobileTrueStepper,
  VerticalStepper,
  Tabs,
  TabPanel,
  InputPhone,
  InputDate,
  InputTax,
} from "../TrueUI";
import { CheckCircleOutline, BedtimeOutlined } from "@mui/icons-material";
import { globalIsSaving } from "../../GlobalAtoms";
import { useSetRecoilState } from "recoil";
import { useApiGet } from "../../hooks";
import { BaseTable2 } from "../TrueUI";
import { InsuredInformationContactsPage } from "../../dtos/insured-information-contacts-page";
import Row from "../TrueUI/Grids/Row";
import Col from "../TrueUI/Grids/Col";
import { SelectOptions } from "../../dtos/select-options";
import "./LiveStyleGuide.css";
import SiteNotificationTryOut from "./SiteNotificationTryOut";
import Header from "../TrueUI/Headers/Header";
import SystemNumberTryOut from "./SystemNumberTryOut";
import { useBaseTable } from "../../hooks/useBaseTable";
let closingModalInterval: any = null;
let isModalOpenCounterV: number = 5;
// const tableConfiguration = {
//   addTableElementButton: "ADD",
//   editTableElementButton: "EDIT",
//   saveTableElementButton: "SAVE",
//   showExportToExcel: true,
//   showExportToPDF: true,
//   showRowEditButton: true,
//   showPagination: true,
//   paginationDefault: 10,
//   showHeaderFilter: true,
//   headerFilterOptions: [
//     { text: "Active", value: 1 },
//     { text: "Inactive", value: 2 },
//   ],
//   headerFilterByColumn: "status",
//   filterMenu: "checkbox",
// };

const tableName = "live_style_guide_table";

const LiveStyleGuide = () => {
  const setGlobalIsSaving = useSetRecoilState<boolean>(globalIsSaving);

  const Item = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const { responseGet, dispatchGet } =
    useApiGet<InsuredInformationContactsPage>(
      `api/InsuredInformation/getinsuredinformationcontacts?insuredId=${4387}`
    );

  useEffect(() => {
    dispatchGet();
  }, []);

  const [_isModalReplyShown, setIsModalReplyShown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSizeOpen, setIsModalSizeOpen] = useState(false);
  const [isModalBROpen, setIsModalBROpen] = useState(false);
  const [isModalSaveOpen, setIsModalSaveOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [stepperVariant, setStepperVariant] = useState<
    "text" | "custom" | "dots" | "progress"
  >("dots");

  const [modalSize, setModalSize] = useState<"xs" | "sm" | "md" | "lg" | "xl">(
    "xs"
  );

  const [inputVarients, setInputVarients] = useState<
    "standard" | "filled" | "outlined"
  >("standard");

  const [buttonVariant, setButtonVariant] = useState<"contained" | "outlined">(
    "contained"
  );

  const [scoreChipButtonColor, setScoreChipButtonColor] = useState<
    "red" | "orange" | "yellow" | "green" | "darkGreen"
  >("green");

  const [scoreChipButtonSize, setScoreChipButtonSize] = useState<
    "small" | "medium" | "big"
  >("small");

  const [rowVerticalGutter, setRowVerticalGutter] = useState<
    "5px" | "10px" | "15px" | "20px" | "25px"
  >("5px");

  const [rowHorizontalGutter, setRowHorizontalGutter] = useState<
    "5px" | "10px" | "15px" | "20px" | "25px"
  >("5px");

  const [rowVerticalMargin, setRowVerticalMargin] = useState<
    "10px" | "20px" | "30px" | "40px" | "50px"
  >("10px");

  const [rowHorizontalMargin, setRowHorizontalMargin] = useState<
    "10px" | "20px" | "30px" | "40px" | "50px"
  >("10px");

  const [rowSpacing, setRowSpacing] = useState<
    "0" | "0.5" | "1" | "2" | "3" | "4" | "8" | "12"
  >("0");

  const [rowAsContainer, setRowAsContainer] = useState<string>("True");

  const [colVerticalGutter, setColVerticalGutter] = useState<
    "5px" | "10px" | "15px" | "20px" | "25px"
  >("5px");

  const [colHorizontalGutter, setColHorizontalGutter] = useState<
    "5px" | "10px" | "15px" | "20px" | "25px"
  >("5px");

  const [colVerticalMargin, setColVerticalMargin] = useState<
    "10px" | "20px" | "30px" | "40px" | "50px"
  >("10px");

  const [colHorizontalMargin, setColHorizontalMargin] = useState<
    "10px" | "20px" | "30px" | "40px" | "50px"
  >("10px");

  const varientOptions = ["standard", "filled", "outlined"].map((option) => ({
    displayName: option,
    stringValue: option,
  }));

  const buttonVariantOptions = ["contained", "outlined"].map((option) => ({
    displayName: option,
    stringValue: option,
  }));

  const modalSizeOptions = ["xs", "sm", "md", "lg", "xl"].map((option) => ({
    displayName: option,
    stringValue: option,
  }));

  const stepperVariantOptions = ["text", "custom", "dots", "progress"].map(
    (option) => ({
      displayName: option,
      stringValue: option,
    })
  );

  const rowColGutterOptions = ["5px", "10px", "15px", "20px", "25px"].map(
    (option) => ({
      displayName: option,
      stringValue: option,
    })
  );

  const rowColMarginOptions = ["10px", "20px", "30px", "40px", "50px"].map(
    (option) => ({
      displayName: option,
      stringValue: option,
    })
  );

  const rowSpacingOptions = ["0", "0.5", "1", "2", "3", "4", "8", "12"].map(
    (option) => ({
      displayName: option,
      stringValue: option,
    })
  );

  const rowContainerOptions = [
    {
      displayName: "True",
      stringValue: "True",
    },
    { displayName: "False", stringValue: "false" },
  ];

  const scoreChipButtonColors = [
    "red",
    "orange",
    "yellow",
    "green",
    "darkGreen",
  ].map((option) => ({
    displayName: option,
    stringValue: option,
  }));

  const scoreChipButtonSizes = ["small", "medium", "big"].map((option) => ({
    displayName: option,
    stringValue: option,
  }));

  const closingModal = () => {
    if (closingModalInterval === null) {
      closingModalInterval = setInterval(function () {
        if (isModalOpenCounterV > 0) {
          isModalOpenCounterV = isModalOpenCounterV - 1;
        } else {
          isModalOpenCounterV = 5;
          clearInterval(closingModalInterval);
          setIsModalSaveOpen(false);
        }
      }, 1000);
    }
  };

  const tableConfiguration = {
    name: tableName,
    columns: responseGet?.axiosResponse?.data?.tableData?.columns ?? [],
    data: responseGet?.axiosResponse?.data?.tableData?.data ?? [],
  };

  useBaseTable(tableConfiguration);

  return (
    <Box
      sx={{
        p: 2,
        pl: 4,
        pr: 4,
        overflowY: "auto",
        height: "95vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h5" component="div" align="center" mb={4}>
          Live Style Guide
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h1" border>
              H1 Header Title
            </Header>
            <Header size="main_title" href="/home">
              Main Title Header
            </Header>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h2">H2 Header Title</Header>
            <Header size="extra_large" href="/home">
              Extra Large Header Title
            </Header>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h3">H3 Header Title</Header>
            <Header size="large" href="/home">
              Large Header Title
            </Header>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h4">H4 Header Title</Header>
            <Header size="medium" href="/home">
              Medium Header Title
            </Header>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h5">H5 Header Title</Header>
            <Header size="small" href="/home">
              Small Header Title
            </Header>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={3}>
            <Header size="h6">Subtitle 1</Header>
            <Header size="subtitle1" href="/home">
              Subtitle 1
            </Header>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Inputs:
            </Typography>
            <Select
              id="input-select"
              name="inputSelect"
              inputWidth={"150"}
              value={inputVarients}
              options={varientOptions}
              onChange={(value) => setInputVarients(value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <InputTax
              id={"id-live-style-guide-tax-Id-ssn"}
              name={"name-live-style-guide-tax-Id-ssn"}
              label="Tax ID"
              initialType={"SSN"}
              variant={inputVarients}
              value={"123456789"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - SSN
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supplied mask - "XXX-XX-0000"
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <InputTax
              id={"id-live-style-guide-tax-Id-fein"}
              name={"name-live-style-guide-tax-Id-fein"}
              label="Tax ID"
              initialType={"FEIN"}
              variant={inputVarients}
              value={"123456789"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - EIN
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supplied mask - "00-0000000"
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Text
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Input for text
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-number"}
              name={"name-live-style-guide-number"}
              variant={inputVarients}
              type={"number"}
              value="123"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Number
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Input for numbers
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <InputPhone
              id={"id-live-style-guide-phone-number"}
              name={"name-live-style-guide-phone-number"}
              variant={inputVarients}
              phoneValue={"0000000000"}
              extValue={"00000"}
              hasExtension
              hideLabelExt
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Phone
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Phone mask - "(000) 000-0000" | Ext mask - "00000"
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-riskId"}
              name={"name-live-style-guide-riskId"}
              variant={inputVarients}
              type={"riskid"}
              value={"1234567"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Risk Id
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supplied mask - "1-23-45-67"
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-email"}
              name={"name-live-style-guide-email"}
              variant={inputVarients}
              type={"email"}
              value={"mail@mail.com"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Mail
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Input for email
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-currency"}
              name={"name-live-style-guide-currency"}
              variant={inputVarients}
              type={"currency"}
              value={"123456"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Currency
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supplied mask - "$1,234.56"
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-password"}
              name={"name-live-style-guide-password"}
              variant={inputVarients}
              type={"password"}
              value={"safe_password"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Password
              </Typography>
              <Typography variant="body2" color="text.secondary">
                DSupplied mask - "............."
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Input
              id={"id-live-style-guide-readonly"}
              name={"name-live-style-guide-readonly"}
              variant={inputVarients}
              readOnly
              value={"Hello!"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Read Only
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Input Read Only
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <InputDate
              id={"id-live-style-guide-date"}
              name={"name-live-style-guide-date"}
              value={new Date()}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Input - Date
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Input for Dates (Default format M/D/YYYY)
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Buttons:
            </Typography>
            <Select
              id="button-select"
              name="buttonSelect"
              inputWidth={"150"}
              value={buttonVariant}
              options={buttonVariantOptions}
              onChange={(value) => setButtonVariant(value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Button variantStyle={buttonVariant}>Button</Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Default button
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Button variantStyle={buttonVariant} isDisabled={true}>
              Button
            </Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Disabled button
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Button variantStyle={buttonVariant} fullWidth>
              Button
            </Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Full width button
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <SaveButton
              text="Save"
              onClick={() => {
                setGlobalIsSaving(true);
                setTimeout(() => {
                  setGlobalIsSaving(false);
                }, 1500);
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Save Button
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <SaveButton
              text="Save"
              savingText="Saving..."
              onClick={() => {
                setGlobalIsSaving(true);
                setTimeout(() => {
                  setGlobalIsSaving(false);
                }, 1500);
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Save Button w/ saving text
              </Typography>
              <Typography variant="body2" color="text.secondary">
                The text for the button when is saving the data, can be setted
                as required
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1} mt={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Score Chip Buttons:
            </Typography>
            <Select
              id="scorechip-select"
              name="scorechipSelect"
              inputWidth={"150"}
              value={scoreChipButtonColor}
              options={scoreChipButtonColors}
              onChange={(value) => setScoreChipButtonColor(value)}
            />
            <Select
              id="scorechip-size-select"
              name="scorechipSizeSelect"
              inputWidth={"150"}
              value={scoreChipButtonSize}
              options={scoreChipButtonSizes}
              onChange={(value) => setScoreChipButtonSize(value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <ScoreChip
              value={123}
              color={scoreChipButtonColor}
              size={scoreChipButtonSize}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Score Chip button
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <ScoreChip value={1} size={scoreChipButtonSize} />
            <ScoreChip value={2} size={scoreChipButtonSize} />
            <ScoreChip value={3} size={scoreChipButtonSize} />
            <ScoreChip value={4} size={scoreChipButtonSize} />
            <ScoreChip value={5} size={scoreChipButtonSize} />
            <ScoreChip value={123} size={scoreChipButtonSize} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Score Chip color according to value
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Values from (1-5) has a color, greater than 5 default color is
                green
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Accordion
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Collapse
              id="1"
              title="Item 1"
              content={<p>Accordion Content</p>}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Accordion default open
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Collapse
              id="1"
              title="Item 1"
              content={<p>Accordion Content</p>}
              expanded={false}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Accordion default closed
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Checkbox
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Switch />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Switch
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Switch isChecked={true} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Switch checked
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Switch label="Switch Label" />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Switch with label
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Containers
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <BasePageContainer id="gallery" data>
              <h1>Hello World!</h1>
            </BasePageContainer>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Base Page Container
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <BasePageContainer id="gallery" title="Title" data>
              <h1>Hello World!</h1>
            </BasePageContainer>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Base Page Container
                <br />
                with title
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <BasePageContainer
              id="gallery"
              title="Title"
              titlePosition="center"
              data
            >
              <h1>Hello World!</h1>
            </BasePageContainer>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Base Page Container
                <br />
                with title centered
              </Typography>
              <span>Can be "center" | "left" | "right"</span>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <BasePageContainer id="gallery" title="Title" data>
              <Box>
                <span>Upper bar content!</span>
                <h1>Hello World!</h1>
                <span>Lower bar content!</span>
              </Box>
            </BasePageContainer>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Base Page Container w/ upper and lower bar content
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Conversation
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            {/* <ModalReply
              id="modal-reply"
              isOpen={isModalReplyShown}
              cancelEvent={() => setIsModalReplyShown(false)}
            /> */}
            <Button onClick={() => setIsModalReplyShown(true)}>
              Show Reply Modal
            </Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Reply modal
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Close and save button of modal will close it but custom tasks
                can be added
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            {/* <ModalReply
              id="modal-reply-2"
              isOpen={isModalReplyShown}
              cancelEvent={() => setIsModalReplyShown(false)}
            /> */}
            <Button onClick={() => setIsModalReplyShown(true)}>
              Show Reply Modal
            </Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Reply modal w/ title
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Close and save button of modal will close it but custom tasks
                can be added
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Loading bar
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Loading isLoading={true} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Loading bar
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Bar to indicate a loading state
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Modal
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Modal
              id="1"
              open={isModalOpen}
              title="Modal title"
              closeEvent={() => {
                setIsModalOpen(false);
              }}
            ></Modal>

            <Button onClick={() => setIsModalOpen(true)}>Show Modal</Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Basic modal
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Modal w/ close button, a title must be setted, has support for
                close, save, and open events
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Modal
              id="1"
              open={isModalSizeOpen}
              title="Modal title"
              closeEvent={() => {
                setIsModalSizeOpen(false);
              }}
              size={modalSize}
            >
              <Select
                id="modal-select"
                name="modalSelect"
                inputWidth={"150"}
                value={modalSize}
                options={modalSizeOptions}
                onChange={(value) => setModalSize(value)}
              />
            </Modal>

            <Button onClick={() => setIsModalSizeOpen(true)}>Show Modal</Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Basic modal w/ Size prop
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Modal w/ size dynamic size prop
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Modal
              id="1"
              open={isModalBROpen}
              title="Modal title"
              closeEvent={() => {
                setIsModalBROpen(false);
              }}
              buttonRibbonNode={<strong>Button Ribbon</strong>}
            ></Modal>

            <Button onClick={() => setIsModalBROpen(true)}>Show Modal</Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Basic modal w/ button ribbon
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Modal w/ button ribbon, is kind of a label that can be any html
                element, in the example is a strong tag
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <Modal
              id="1"
              open={isModalSaveOpen}
              title="Modal w/ Save button"
              saveEvent={() => {
                closingModal();
              }}
            >
              Save event is closing in 5 sec
            </Modal>

            <Button onClick={() => setIsModalSaveOpen(true)}>Show Modal</Button>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Basic modal w/save
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Modal w/ close button, a title must be setted, has support for
                close, save, and open event, close present always
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Rich Text Editor
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <RichEditor title="Title RTE" />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Rich Text Editor
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <RichEditor title="RTE w/ Default Content" value="Hello!" />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Rich Text Editor w/ default content
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <RichEditor
              title="RTE w/ Default Content"
              headerComponent={<strong>Header</strong>}
              footerComponent={
                <strong>
                  <i>Footer</i>
                </strong>
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Rich Text Editor w/ header and footer content (ReactNode)
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <RichEditor
              disableCollapse
              title="RTE w/ Default Content"
              headerComponent={<strong>Header</strong>}
              footerComponent={
                <strong>
                  <i>Footer</i>
                </strong>
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Rich Text Editor w/ collapse disabled
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <RichEditor
              expanded={false}
              title="RTE w/ Default Content"
              value="Hello!"
              contentStyle={{ color: "red", fontSize: 20, letterSpacing: 2 }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Rich Text Editor w/ custom styles & collapse closed
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Rich Text Editor w/ custom styles for content and prop for
                expanded setted to false
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Steppers
            </Typography>
            <Select
              id="stepper-select"
              name="stepperSelect"
              inputWidth={"150"}
              value={stepperVariant}
              options={stepperVariantOptions}
              onChange={(value) => setStepperVariant(value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <VerticalStepper
              steps={[
                {
                  stepIcon: <CheckCircleOutline />,
                  stepInfo: <span>Step 1</span>,
                },
                {
                  stepIcon: <CheckCircleOutline />,
                  stepInfo: <span>Step 2</span>,
                },
              ]}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Vertical Stepper
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vertical stepper has props for a custom icon and step info
              </Typography>
            </CardContent>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Item sx={{ minHeight: 100 }}>
            <MobileTrueStepper
              variant={stepperVariant}
              variantText={["Step Info 1", "Step Info 2", "Step Info 3"].map(
                (ci, index) => (
                  <BedtimeOutlined
                    htmlColor={ci && index === activeStep ? "#f90" : "#222"}
                  />
                )
              )}
              stepsInfo={["Step Info 1", "Step Info 2", "Step Info 3"]}
              activeStep={activeStep}
              handleNext={() => {
                setActiveStep(activeStep + 1);
              }}
              handleBack={() => {
                setActiveStep(activeStep - 1);
              }}
              BodyStepper={"span"}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Mobile True Stepper
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Stepper, can be used with dots, progress, text and custom
                indicator, using the variant prop
              </Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Tables
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ minHeight: 100 }}>
            {responseGet?.axiosResponse?.data?.tableData?.data !== undefined &&
            responseGet?.axiosResponse?.data?.tableData?.data?.length > 0 ? (
              <BasePageContainer id={"information_officers"} maxWidth={false}>
                <BaseTable2 name={tableName} />
              </BasePageContainer>
            ) : (
              <div>LOADING...</div>
            )}
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Base table
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box mb={3} sx={{ display: "flex" }}>
            <Typography variant="h6" gutterBottom component="div" mr={3}>
              Tabs
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ minHeight: 100 }}>
            <Tabs name={"style-guide-tabs"} disableQueryString>
              <TabPanel title="Tab Title 1">
                <br />
                Tab Content 1
              </TabPanel>
              <TabPanel title="Tab Title 2">
                <br />
                Tab Content 2
              </TabPanel>
            </Tabs>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Tabs
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Item>
        </Grid>
      </Grid>
      <Box id="row-column-container" mt={3}>
        <Typography gutterBottom variant="h6" component="div" textAlign="left">
          Row and Column
        </Typography>
        <Item
          id="grid-for-props-wrapper"
          className="item-for-row-wrapping"
          sx={{ mt: 5 }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            textAlign="left"
          >
            Properties for Rows and Columns
          </Typography>
          <Row rowDirection="column">
            <Row numberOfColumns={10}>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="vertical-row-gutter-select"
                  name="verticalSowGutterSelect"
                  label="Vertical Row Gutter "
                  value={rowVerticalGutter}
                  options={rowColGutterOptions}
                  onChange={(value) => setRowVerticalGutter(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="horizontal-row-gutter-select"
                  name="horizontalRowGutterSelect"
                  label="Horizontal Row Gutter"
                  value={rowHorizontalGutter}
                  options={rowColGutterOptions}
                  onChange={(value) => setRowHorizontalGutter(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="vertical-column-gutter-select"
                  name="verticalColumnGutterSelect"
                  label="Vertical Column Gutter"
                  value={colVerticalGutter}
                  options={rowColGutterOptions}
                  onChange={(value) => setColVerticalGutter(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="horizontal-column-gutter-select"
                  name="horizontalColumnGutterSelect"
                  label="Horizontal Column Gutter"
                  value={colHorizontalGutter}
                  options={rowColGutterOptions}
                  onChange={(value) => setColHorizontalGutter(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="row-spacing-select"
                  name="rowSpacingSelect"
                  label="Row Spacing For Container"
                  value={rowSpacing}
                  options={rowSpacingOptions as Partial<SelectOptions>[]}
                  onChange={(value) => setRowSpacing(value)}
                />
              </Col>
            </Row>
            <Row numberOfColumns={10}>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="vertical-row-margin-select"
                  name="verticalRowMarginSelect"
                  label="Vertical Row Margin"
                  value={rowVerticalMargin}
                  options={rowColMarginOptions}
                  onChange={(value) => setRowVerticalMargin(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="horizontal-row-margin-select"
                  name="horizontalRowMarginSelect"
                  label="Horizontal Row Margin"
                  value={rowHorizontalMargin}
                  options={rowColMarginOptions}
                  onChange={(value) => setRowHorizontalMargin(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="vertical-col-margin-select"
                  name="verticalColMarginSelect"
                  label="Vertical Col Margin"
                  value={colVerticalMargin}
                  options={rowColMarginOptions}
                  onChange={(value) => setColVerticalMargin(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="horizontal-row-margin-select"
                  name="horizontalRowMarginSelect"
                  label="Horizontal Col Margin"
                  value={colHorizontalMargin}
                  options={rowColMarginOptions}
                  onChange={(value) => setColHorizontalMargin(value)}
                />
              </Col>
              <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
                <Select
                  id="row-as-container-select"
                  name="rowAsContainerSelect"
                  label="Row As Container"
                  value={rowAsContainer}
                  options={rowContainerOptions as Partial<SelectOptions>[]}
                  onChange={(value) => setRowAsContainer(value)}
                />
              </Col>
            </Row>
          </Row>
        </Item>
        <Item
          id="grid-applied-props-wrapper"
          className="item-for-row-wrapping"
          sx={{ mt: 5 }}
        >
          <Row
            verticalGutter={rowVerticalGutter}
            horizontalGutter={rowHorizontalGutter}
            verticalMargin={rowVerticalMargin}
            horizontalMargin={rowHorizontalMargin}
            spacing={rowAsContainer === "True" ? rowSpacing : undefined}
            rowAsContainer={rowAsContainer === "True"}
          >
            <Col
              verticalGutter={colVerticalGutter}
              horizontalGutter={colHorizontalGutter}
              verticalMargin={colVerticalMargin}
              horizontalMargin={colHorizontalMargin}
            >
              <Input
                id={"id-live-style-guide-text"}
                name={"name-live-style-guide-text"}
                variant="standard"
                type="text"
                value="12345456"
              />
            </Col>
            <Col
              verticalGutter={colVerticalGutter}
              horizontalGutter={colHorizontalGutter}
              verticalMargin={colVerticalMargin}
              horizontalMargin={colHorizontalMargin}
            >
              <Input
                id={"id-live-style-guide-number2"}
                name={"name-live-style-guide-number2"}
                variant="standard"
                type="number"
                value="12345456"
              />
            </Col>
            <Col
              verticalGutter={colVerticalGutter}
              horizontalGutter={colHorizontalGutter}
              verticalMargin={colVerticalMargin}
              horizontalMargin={colHorizontalMargin}
            >
              <Input
                id={"id-live-style-guide-text2"}
                name={"name-live-style-guide-text2"}
                variant="standard"
                type="text"
                value="1234567890"
              />
            </Col>
          </Row>
        </Item>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          textAlign="left"
          mt={2}
        >
          Break Points for positioning
        </Typography>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          textAlign="center"
          mt={2}
        >
          Using <u>md</u>, <u>sm</u> and <u>xs</u> as break point (<u>md</u> is
          between 900px and 1200px, <br />
          <u>sm</u> is between 900px and 600px and <u>xs</u> is 600px and lower)
        </Typography>
      </Box>
      <Item
        id="grid-example-wrapper"
        className="example item-for-row-wrapping"
        sx={{ mt: 5 }}
      >
        <Row rowDirection="column">
          <Row>
            <Col horizontalMargin="10px" breakpoints={{ xs: 12, sm: 3, md: 6 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign="center"
                mt={2}
              >
                xs = 12 sm = 3<br />
                md = 6
              </Typography>
            </Col>
            <Col horizontalMargin="10px" breakpoints={{ xs: 12, sm: 6, md: 3 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign="center"
                mt={2}
              >
                xs = 12 sm = 6<br />
                md = 3
              </Typography>
            </Col>
            <Col horizontalMargin="10px" breakpoints={{ xs: 12, sm: 3, md: 3 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign="center"
                mt={2}
              >
                xs = 12 sm = 3<br />
                md = 3
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col horizontalMargin="10px" breakpoints={{ xs: 12, sm: 3, md: 9 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign="center"
                mt={2}
              >
                xs = 12 sm = 3<br />
                md = 9
              </Typography>
            </Col>
            <Col horizontalMargin="10px" breakpoints={{ xs: 12, sm: 9, md: 3 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign="center"
                mt={2}
              >
                xs = 12 sm = 9<br />
                md = 3
              </Typography>
            </Col>
          </Row>
        </Row>
      </Item>
      <Box mt={3}>
        <Typography
          textAlign={"left"}
          gutterBottom
          variant="h6"
          component="div"
        >
          Site Notification Try Out
        </Typography>
        <Item sx={{ mt: 6 }}>
          <SiteNotificationTryOut />
        </Item>
      </Box>
      <Box mt={3}>
        <Typography
          textAlign={"left"}
          gutterBottom
          variant="h6"
          component="div"
        >
          System Number Generator Try Out
        </Typography>
        <Item sx={{ mt: 6 }}>
          <SystemNumberTryOut />
        </Item>
      </Box>
    </Box>
  );
};

export default LiveStyleGuide;
