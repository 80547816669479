import { UploadOnBeforeUploadEvent } from "@progress/kendo-react-upload";
import { FC } from "react";
import { FileArchiveResponseDto } from "../../../dtos/file-archive-response-dto";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import MultiUpload from "./MultiUpload";
import "./Upload.css";
import "./upload.module.css";

type UploadProps = {
  name: string;
  saveUrl?: string;
  removeUrl?: string;
  allowedExtensions?: string[];
  files?: { [key: string]: number };
  readOnly?: boolean;
  clearFiles?: boolean;
  label?: string;
  labelFontType?: KeysAsType<FontsType>;
  errorMessages?: string[];
  maxSizeMB?: number;
  maxFileCount?: number;
  tabIndex?: number;
  onUploadFileList: (files: any | null, response?: any) => void;
  resetClearFiles?: (clear: boolean) => void;
  onBeforeUploadFileList?: (event?: UploadOnBeforeUploadEvent) => void;
  onRemovedFile?: (
    files: any,
    response?: FileArchiveResponseDto | null
  ) => void;
};

const Upload: FC<UploadProps> = ({
  name,
  files,
  onUploadFileList,
  saveUrl = "api/FileArchive/UploadMultiple",
  removeUrl = "api/FileArchive/Remove",
  allowedExtensions,
  readOnly,
  clearFiles = false,
  label,
  labelFontType = "BOLD_BODY",
  errorMessages,
  maxSizeMB = 500,
  maxFileCount = 1,
  resetClearFiles,
  onBeforeUploadFileList,
  onRemovedFile,
  tabIndex = 0,
}) => {
  return (
    <MultiUpload
      name={name}
      files={files}
      onUploadFileList={onUploadFileList}
      saveUrl={saveUrl}
      removeUrl={removeUrl}
      clearFiles={clearFiles}
      readOnly={readOnly}
      label={label}
      labelFontType={labelFontType}
      errorMessages={errorMessages}
      allowedExtensions={allowedExtensions}
      maxSizeMB={maxSizeMB}
      maxFileCount={maxFileCount}
      autoUpload
      resetClearFiles={resetClearFiles}
      onBeforeUploadFileList={onBeforeUploadFileList}
      onRemovedFile={onRemovedFile}
      tabIndex={tabIndex}
    />
  );
};

export default Upload;
