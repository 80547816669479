import { Box, SxProps, Theme } from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";

import Heading4 from "../Typography/Heading4";

type BasicPageProperties = {
  id: any;
  title?: string | ReactNode | undefined;
  titlePosition?: "center" | "left" | "right";
  mountedEffect?: () => void;
  unmountedEffect?: () => void;
  sxContainer?: SxProps<Theme>;
  sxWrapper?: SxProps<Theme>;
  sxBody?: SxProps<Theme>;
  disableBodyPaper?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false | string;
  children?: any;
  data?: any;
};

const Body = ({ id, children }) => {
  const [height, setHeight] = useState("100%");
  const tableElementReference = (el: any) => {
    if (!el) return;
    setHeight("100%");
  };
  return (
    <Box
      id={`basic_page_body_${id}`}
      alignSelf={"auto"}
      flexGrow={1}
      sx={{ display: "flex", height: height, overflow: "hidden" }}
      ref={tableElementReference}
    >
      {children}
    </Box>
  );
};

const BasePageContainer: FC<BasicPageProperties> = (props) => {
  useEffect(() => {
    if (props.mountedEffect) {
      props.mountedEffect();
    }

    return () => {
      props.unmountedEffect && props.unmountedEffect();
    };
  }, [props.data]);

  return props.data ? (
    <Box
      className={"true_base_page_container"}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {props.title !== undefined && (
        <Heading4 textAlign="start">{props.title}</Heading4>
      )}
      <Box
        id={`common_page_container_for_${props.id}`}
        className={"true_common_page_container"}
        sx={{ ...props.sxWrapper }}
      >
        {props.disableBodyPaper ? (
          <Body id={props.id}>{props.children}</Body>
        ) : (
          <div
            className={"true_common_page_container_inner_paper"}
            style={{ height: "100%", overflow: "hidden" }}
          >
            <Body id={props.id}>{props.children}</Body>
          </div>
        )}
      </Box>
    </Box>
  ) : (
    <div>LOADING...</div>
  );
};

export default BasePageContainer;
