import { FC, useEffect, useState } from "react";
import ScheduledTransactionsTable from "./ScheduledTransactionsTable";
import { BillingSchduledTransactionProps } from "../BillingConstants";
import {
  BILLING_SCHEDULED_TRANSACTION_ID,
  INSURED_ATOM_KEY,
  INSURED_CHILD_ITEM,
  INSURED_ID,
  INSURED_PARENT_ITEM,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  addQueryStrings,
  getQueryValue,
  removeQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { useApiGet } from "../../../../hooks";
import { SelectOptions } from "../../../../dtos/select-options";
import { SubSideNavChildrenIndexEnum } from "../../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavParentsIndexEnum } from "../../../../dtos/sub-side-nav-parents-index-enum";
import { AccountTableDto } from "../../../../dtos/account-table-dto";
import { ProcessorTypeEnum } from "../../../../dtos/processor-type-enum";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const ScheduledWrapper: FC<BillingSchduledTransactionProps> = ({
  insuredId,
  tabKey,
}) => {
  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/BillingTransaction/GetBillingScheduledTransactionStatusSelectOptions`
  );

  const [accountTableData, setAccountTableData] =
    useState<AccountTableDto | null>();

  const { responseGet: responseBillingTran, dispatchGet: dispatchBillingTran } =
    useApiGet<AccountTableDto>(
      `api/BillingTransaction/GetAccountAndBalanceTable?insuredId=${insuredId}&processorType=${ProcessorTypeEnum.ACH}`
    );

  const getSectionId = () => {
    if (getQueryValue(BILLING_SCHEDULED_TRANSACTION_ID) !== "") {
      return parseInt(getQueryValue(BILLING_SCHEDULED_TRANSACTION_ID));
    }
    return SubSideNavChildrenIndexEnum.BILLING_SCHEDULED;
  };

  const GetSection = () => {
    switch (getSectionId()) {
      case SubSideNavChildrenIndexEnum.BILLING_SCHEDULED:
        return (responseGet?.axiosResponse?.data ?? []).length > 0 &&
          accountTableData ? (
          <ScheduledTransactionsTable
            insuredId={insuredId}
            accountTableData={accountTableData}
            statusList={responseGet?.axiosResponse?.data ?? []}
          />
        ) : (
          <></>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const sectionId = getSectionId();
    addQueryStrings([
      { nameOfHash: BILLING_SCHEDULED_TRANSACTION_ID, valueOfHash: sectionId },
      { nameOfHash: INSURED_ID, valueOfHash: insuredId },
      {
        nameOfHash: INSURED_PARENT_ITEM,
        valueOfHash: SubSideNavParentsIndexEnum.BILLING,
      },
      {
        nameOfHash: INSURED_CHILD_ITEM,
        valueOfHash:
          SubSideNavItemDestinationsEnum.BILLING_SCHEDULED_TRANSACTIONS,
      },
    ]);

    setAtom({
      ...getAtom(),
      insuredBodySection:
        SubSideNavItemDestinationsEnum.BILLING_SCHEDULED_TRANSACTIONS,
    });

    dispatchGet();
    dispatchBillingTran();

    return () => {
      removeQueryStrings([
        BILLING_SCHEDULED_TRANSACTION_ID,
        INSURED_PARENT_ITEM,
        INSURED_CHILD_ITEM,
      ]);
    };
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseBillingTran)) {
      setAccountTableData(responseBillingTran?.responseData);
    }
  }, [responseBillingTran]);

  return GetSection();
};

export default ScheduledWrapper;
