import { FC, useEffect, useState } from "react";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { AgencyCommissionTranEnum } from "../../../dtos/agency-commission-tran-enum";
import { AgencyCommissionTranPage } from "../../../dtos/agency-commission-tran-page";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import CommissionsDateFilter from "./CommissionsDateFilter";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  AgencyPaidMonthFilter,
  AgencyTranStatusFilter,
  SelectedAgencyCommissionAtom,
} from "./CommissionsAtoms";

const TABLE_NAME = "AgencyCommissionBaseTable";

const AgencyCommissionGrid: FC = () => {
  const [agencyCommissions, setAgencyCommissions] =
    useState<AgencyCommissionTranPage | null>(null);

  const setTransaction = useSetRecoilState(SelectedAgencyCommissionAtom);

  const { responseGet, dispatchGet } = useApiGet<AgencyCommissionTranPage>(
    `api/AgencyCommissionTran/GetAgencyCommisionsPage`
  );

  const [selectedFilter, setSelectedFilter] = useRecoilState(
    AgencyTranStatusFilter
  );
  const setMonthPaidFilter = useSetRecoilState(AgencyPaidMonthFilter);

  const showPayAllButton = selectedFilter === AgencyCommissionTranEnum.UNPAID;

  const showDateFilter = selectedFilter === AgencyCommissionTranEnum.PAID;

  const statusOptions: Partial<SelectOptions>[] = FromEnumToArrayWithExceptions(
    AgencyCommissionTranEnum,
    [AgencyCommissionTranEnum.DELETED]
  ).map((enumType) => ({
    displayName: enumType?.key ?? "",
    stringValue: enumType?.value,
  }));

  const resetMonthPaidFilter = () => {
    setMonthPaidFilter(
      agencyCommissions?.monthOptions?.[0]?.stringValue ?? "-1"
    );
  };

  const onTranStatusFilterChange = (value: number) => {
    if (value !== AgencyCommissionTranEnum.PAID) {
      resetMonthPaidFilter();
    }
    setSelectedFilter(value);
    baseGrid.manuallyReloadParameters();
  };

  const actionsForRows = (
    _actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            setTransaction({
              tranId: _actionOptions.row.TranId,
              agencyId: _actionOptions.row.AgencyId,
            });
          }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    columnsAndData: agencyCommissions?.tableData,
    name: TABLE_NAME,
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "AgencyName", width: 35 },
      { fieldName: "TranDate", width: 20 },
      { fieldName: "TranStatus", width: 15 },
      { fieldName: "TranType", width: 14 },
      { fieldName: "TranAmount", width: 14 },
      { fieldName: "OPTIONS", width: 2 },
    ],
    toolbarOptions: {
      showAddButton: showPayAllButton,
      addButtonText: "PAY ALL",
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          id: "commission-status-filter",
          columnNames: ["TranStatus"],
          labelText: "Status:",
          optionsOverride: statusOptions,
          defaultValue: selectedFilter ?? AgencyCommissionTranEnum.UNPAID,
          options,
          onChange: onTranStatusFilterChange,
        }),
      (options) =>
        showDateFilter
          ? CommissionsDateFilter({
              options,
              dateOptions: agencyCommissions?.monthOptions ?? [],
            })
          : null,
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    events: { addEventOverrideCallback: () => alert("soon :)") },
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setAgencyCommissions(responseGet?.responseData ?? null);
  }, [responseGet]);

  useEffect(() => {
    if (agencyCommissions !== null) baseGrid.manuallyReloadParameters();
  }, [agencyCommissions]);

  const baseGrid = useBaseGrid(tableConfiguration);

  return (
    <div style={{ overflowY: "auto", height: "100%", width: "100%", flexGrow: "1" }}>
      <BaseGrid name={TABLE_NAME} />
    </div>
  );
};

export default AgencyCommissionGrid;
