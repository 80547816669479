import { PayrollReportStatusEnum } from "../../../dtos/payroll-report-status-enum";
import { SelectOptions } from "../../../dtos/select-options";
import { capitalizeText } from "../../../utilities/stringFunctions";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
export const tableName = "payroll_report_table";
export const getTableConfig = (
  columnsAndData?: { columns: any[]; data: any[][] },
  editModalLinkEvent?: (row: any) => void
) => {
  const config: BaseTable2Properties = {
    columnsAndData: columnsAndData ?? defaultColsAndData,
    name: tableName,
    toolbarOptions: {
      showImportButton: false,
      showEditButton: false,
      showSortFilter: false,
      showAddButton: false,
    },
    columnOptions: [{ fieldName: "OPTIONS", width: 5 }],
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          editModalLinkEvent?.(actionOptions.row) ?? null,
      },
    },
  };
  return config;
};

export type PayrollReportingTabContentProps = {
  programId?: number | null;
};
export const activeStringValue = capitalizeText(
  PayrollReportStatusEnum[PayrollReportStatusEnum.ACTIVE]
);
export const allOption: SelectOptions = {
  displayName: "All Active",
  stringValue: activeStringValue,
  intValue: null,
  dateValue: null,
  decimalValue: null,
  booleanValue: null,
};
export const defaultColsAndData = { columns: [], data: [] };
