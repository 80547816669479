import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { UserPermissionDto } from "../../../dtos/user-permission-dto";
import { PermissionInfoDto } from "../../../dtos/permission-info-dto";
import { SaveActionPermissionsEnum } from "../../../dtos/save-action-permissions-enum";
import { useApiGet, useApiPost } from "../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { Button, Input, Switch, TitleBold } from "../../TrueUI";
import "./PortalUser.module.css";
import { PortalUserInformation, RefreshAccessRightsTable } from "./UsersAtom";

const PermissionsSection: FC = () => {
  const [permissionList, setPermissionList] = useState<
    PermissionInfoDto[] | null
  >();

  const [userInfoToPermissions, setUserInfoToPermissions] = useRecoilState(
    PortalUserInformation
  );
  const [refreshTable, setRefreshTable] = useRecoilState(
    RefreshAccessRightsTable
  );

  const [userPermission, setUserPermission] =
    useState<UserPermissionDto | null>();

  const { responseGet, dispatchGet } = useApiGet<PermissionInfoDto[]>(
    `api/PortalUser/GetPermissionsDescription`
  );

  const {
    responseGet: responseUserPermissions,
    dispatchGet: dispatchUserPermissions,
  } = useApiGet<UserPermissionDto>(
    `api/PortalUser/GetProgramUserPermissions?programUser=${userInfoToPermissions?.programUserId}`
  );

  const { responsePost, dispatchPost } = useApiPost(
    `api/PortalUser/SaveProgramUserPermissions`,
    userPermission
  );

  const reviewIfChecked = (permissionId): boolean => {
    const selectedPermissions = userPermission?.permissions?.find(
      (p) => p.permissionId === permissionId
    );
    return selectedPermissions?.hasPermission ?? false;
  };

  const onCheckChange = (permissionId, isChecked) => {
    const selectedPermission = userPermission?.permissions?.find(
      (p) => p.permissionId === permissionId
    );

    const otherPermissions = userPermission?.permissions?.filter(
      (p) => p.permissionId !== permissionId
    );

    if (conditionHasValue(selectedPermission)) {
      const allPermissions = [
        ...(otherPermissions ?? []),
        { ...selectedPermission, hasPermission: isChecked },
      ];
      setUserPermission({
        ...userPermission,
        permissions: allPermissions ?? [],
      });
    } else {
      const newPermission = {
        userPermissionId: 0,
        programUserId: userPermission?.programUserId ?? 0,
        permissionId: permissionId,
        hasPermission: isChecked,
      };
      const allPermissions = [
        ...(otherPermissions ?? []),
        { ...newPermission },
      ];
      setUserPermission({
        ...userPermission,
        permissions: allPermissions ?? [],
      });
    }
  };

  useEffect(() => {
    if (conditionHasValue(userInfoToPermissions?.programUserId)) {
      dispatchUserPermissions();
    }
  }, [userInfoToPermissions]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPermissionList(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responseUserPermissions)) {
      setUserPermission({
        ...responseUserPermissions?.responseData,
        insuredUserId: userInfoToPermissions?.insuredUserId,
      });
    }
  }, [responseUserPermissions]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setRefreshTable(!refreshTable);
      setUserPermission(null);
      setUserInfoToPermissions(null);
    }
  }, [responsePost]);

  useEffect(() => {
    if (conditionHasValue(userPermission?.saveAction)) {
      dispatchPost();
    }
  }, [userPermission?.saveAction]);

  return (
    <div className={"permissions_container"}>
      <div className={"permissions_title"}>
        <TitleBold display="block" textAlign="start">
          PERMISSIONS
        </TitleBold>
      </div>

      <div className={"permissions_section"}>
        {conditionHasValue(userInfoToPermissions?.programUserId) ? (
          <>
            <Input
              id={"permissions-program-name"}
              name={"permissions-program-name"}
              label={"Program"}
              labelPosition={"top"}
              value={userInfoToPermissions?.programName}
              readOnly
            />
            <Input
              id={"permissions-insured-name"}
              name={"permissions-insured-name"}
              label={"Insured"}
              labelPosition={"top"}
              value={userInfoToPermissions?.insuredName}
              readOnly
            />
            <div className={"permissions_checks"}>
              {permissionList?.map((permission) => {
                return (
                  <Switch
                    key={`permission-id-${permission.permissionId}`}
                    label={permission?.permissionDescription}
                    labelPlacement={"end"}
                    control={"checkbox"}
                    labelFontType={"BODY"}
                    isChecked={reviewIfChecked(permission?.permissionId)}
                    onChangeIsChecked={(isChecked) => {
                      onCheckChange(permission?.permissionId, isChecked);
                    }}
                  />
                );
              })}
            </div>
            <div className={"permissions_actions"}>
              <Button
                variantStyle="outlined"
                onClick={() =>
                  setUserPermission({
                    ...userPermission,
                    saveAction: SaveActionPermissionsEnum.REMOVE_ALL,
                  })
                }
              >
                REMOVE ALL
              </Button>
              <Button
                onClick={() =>
                  setUserPermission({
                    ...userPermission,
                    saveAction: SaveActionPermissionsEnum.UPDATE,
                  })
                }
              >
                SAVE
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default PermissionsSection;
