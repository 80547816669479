import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { Font, Modal } from "../../../../../TrueUI";
import AccountAndBalanceSection from "../AccountAndBalanceSection";
import { ModalCreditCardPaymentRedirectProps } from "./ModalCreditCardPaymentRedirectTypes";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { ConfigurationNewPaymentDto } from "../../../../../../dtos/configuration-new-payment-dto";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { TranzpayRequestDto } from "../../../../../../dtos/tranzpay-request-dto";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import FontError from "../../../../../TrueUI/Typography/FontError";
import { TranzpayResponseDto } from "../../../../../../dtos/tranzpay-response-dto";

const domainURL = "https://" + window.location.host;

const ModalCreditCardPaymentRedirect: FC<
  ModalCreditCardPaymentRedirectProps
> = ({ insuredId, showModal, isOpenModal, accountTableData }) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [paymentFormSelectsData, setPaymentFormSelectsData] = useState<
    ConfigurationNewPaymentDto | null | undefined
  >();
  const [transactionRequest, setTransactionRequest] = useState<
    TranzpayRequestDto | null | undefined
  >();
  const [billingTransactionData, setBillingTransactionData] = useState<
    BillingTransactionDataDto[]
  >([]);

  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<ConfigurationNewPaymentDto>(
      `api/PaymentActivity/GetConfigurationNewPaymentRedirect?insuredId=${insuredId}`
    );
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<TranzpayRequestDto>(
      `api/Tranzpay/HostedPayment`,
      transactionRequest
    );

  const onSaveEvent = () => {
    setErrorDetails(null);
    setTransactionRequest({
      billingNameFirst: responseGetConfig?.responseData?.firstName,
      billingNameLast: responseGetConfig?.responseData?.lastName,
      billingAddress: responseGetConfig?.responseData?.address,
      billingCity: responseGetConfig?.responseData?.city,
      billingState: responseGetConfig?.responseData?.state,
      billingZipCode: responseGetConfig?.responseData?.postCode,
      phoneNumber: responseGetConfig?.responseData?.phone,
      email: responseGetConfig?.responseData?.email,
      insuredID: insuredId,
      paymentData: billingTransactionData,
      domain: domainURL,
    });
  };

  const onCancelEvent = () => {
    setErrorDetails(null);
    isOpenModal(false);
  };

  useEffect(() => {
    dispatchGetConfig();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetConfig)) {
      setPaymentFormSelectsData(responseGetConfig?.responseData);
    }
  }, [responseGetConfig]);

  useEffect(() => {
    if (conditionHasValue(transactionRequest)) dispatchPost();
  }, [transactionRequest]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const response = responsePost.axiosResponse?.data as TranzpayResponseDto;
      if (response?.errorCode) {
        setErrorDetails({
          code: response.errorCode.concat(": "),
          msg: response.errorText,
        });
      } else {
        window.open(response.tranzpayURL ?? "", "_self");
      }
    }
    if (validatorErrorResponse) {
      setErrorDetails({
        code: "",
        msg: validatorErrorResponse?.errorDetails.billingNameFirst,
      });
    }
  }, [responsePost, validatorErrorResponse]);

  return (
    <Modal
      id={"add-credit-card-payment-redirect"}
      title={"Credit Card Payment"}
      open={showModal}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCancelEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <Font>
        Select the amount(s) to pay and when done, the total will be redirected
        to the payment processor.
      </Font>
      <AccountAndBalanceSection
        setBillingTransactionData={setBillingTransactionData}
        accountTableData={accountTableData}
        tranAccountOptions={paymentFormSelectsData?.tranAccountOptions ?? []}
      />
      {errorDetails && (
        <FontError display="inline-block" maxWidth="840px" truncate>
          {errorDetails.code + errorDetails.msg}
        </FontError>
      )}
    </Modal>
  );
};

export default ModalCreditCardPaymentRedirect;
