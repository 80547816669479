import { atom } from "recoil";
import { ClaimFinancialDto } from "../../../../../../dtos/claim-financial-dto";
import { ClaimFlagsDataDto } from "../../../../../../dtos/claim-flags-data-dto";

export const ClaimFinancialGetAPIRequesterAtom = atom<{
  isRequested: boolean;
  isCompleted: boolean;
}>({
  key: "ClaimFinancialGetAPIRequesterAtom",
  default: {
    isRequested: true,
    isCompleted: false,
  },
});

export const ClaimFinancialDataAtom = atom<ClaimFinancialDto | null>({
  key: "ClaimFinancialDataAtom",
  default: null,
});

export const ClaimFlagsDataAtom = atom<ClaimFlagsDataDto | null>({
  key: "ClaimFlagsDataAtom",
  default: null,
});

export const ReloadPaymentsAndReservesGridAtom = atom<boolean>({
  key: "ReloadPaymentsAndReservesGridAtom",
  default: false,
});

export const ReloadReservesSubGridAtom = atom<boolean>({
  key: "ReloadReservesSubGridAtom",
  default: false,
});
