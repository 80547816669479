import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
  TableType,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { BaseTable2, Font } from "../../../../TrueUI";
import {
  ClassCodeTableStyles,
  ModalAddClassCodeContentStyles,
} from "./ModalAddClassCodeContentStyles";
import { getDataIndexByColumnName } from "../../../../TrueUI/Tables/tableFunctions";
import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import { useApiGet } from "../../../../../hooks";
import { ClassCodeTableDto } from "../../../../../dtos/class-code-table-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { BaseTable } from "../../../../../dtos/base-table";
import { ModalAddClassCodeContentProps } from "./ModalAddClassCodeUtils";
import { Box } from "@mui/material";

const ModalAddClassCodeContent: FC<ModalAddClassCodeContentProps> = ({
  payrollReportId,
  newClassCodesData,
  setNewClassCodeData,
  isShowDialogEmptyTable,
}) => {
  const tableType: TableType = "standard";
  const { responseGet, dispatchGet } = useApiGet<ClassCodeTableDto>(
    `api/PayrollReport/GetClassCodeList?payrollReportId=${payrollReportId}`
  );
  const [tableData, setTableData] = useState<BaseTable>();

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTableData(responseGet.axiosResponse?.data.tableData);
      if (responseGet.axiosResponse?.data.tableData.data.length == 0) {
        isShowDialogEmptyTable(true);
      }
    }
  }, [responseGet]);

  const tableName = "AddClassCodeTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    tableType: tableType,
    columnsAndData: {
      columns: tableData?.columns ?? [],
      data: tableData?.data ?? [],
    },
    columnOptions: [
      { fieldName: "State", width: 10 },
      { fieldName: "ClassCode", width: 15 },
      { fieldName: "Description", width: 68 },
      { fieldName: "Checkbox", width: 7 },
    ],
    toolbarOptions: {
      hideToolbar: true,
      toolBarType: "compact",
      showEditButton: false,
      showSaveButton: false,
      showAddButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
      },
    },
    events: {
      onDataChange: (data, columns) => onChangeTableEvent(data, columns),
    },
  };

  const onChangeTableEvent = (data: string[][], columns: BaseTableColumn[]) => {
    const stateIndex = getDataIndexByColumnName(columns, "State");
    const classCodeIdIndex = getDataIndexByColumnName(columns, "ClassCodeId");
    const checkboxIndex = getDataIndexByColumnName(columns, "Checkbox");
    const modRateFactorIndex = getDataIndexByColumnName(
      columns,
      "ModRateFactor"
    );

    const newClassCodeList = data
      .filter((row) => row[checkboxIndex] !== "false")
      .map((row: string[]) => {
        const state = row[stateIndex];
        const classCodeId = row[classCodeIdIndex];
        const modRateFactor = row[modRateFactorIndex];

        return {
          stateCode: state,
          classCodeId: Number(classCodeId),
          modRateFactor: modRateFactor == "" ? null : Number(modRateFactor),
        };
      });
    setNewClassCodeData({
      ...newClassCodesData,
      classCodeList: newClassCodeList,
    });
  };
  useBaseTable([tableConfiguration]);

  return (
    <>
      <div style={ModalAddClassCodeContentStyles()}>
        <Font fontType="BOLD_TITLE" textAlign="start">
          These Class Codes can be added to this payroll report:
        </Font>
      </div>
      <Box id={"class-code-table-wrapper"} sx={ClassCodeTableStyles()}>
        {tableData && <BaseTable2 name={tableName} />}
      </Box>
    </>
  );
};

export default ModalAddClassCodeContent;
