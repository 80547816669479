import { FC, useEffect, useState } from "react";
import { useApiPost } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import AppointmentsTable from "./AppointmentsTable";
import ModalAgencyAppointment from "./ModalAgencyAppointment";
import { AgencyProgramDto } from "../../../dtos/agency-program-dto";

export type AgencyAppointmentModalProps = {
  isOpen?: boolean;
  isSaving?: boolean;
  isDeleting?: boolean;
  agencyAppointment?: AgencyProgramDto | null;
  refreshTable?: boolean;
};

type AppointmentsProps = {
  agencyId?: string;
  isNewAgency: boolean;
};

const Appointments: FC<AppointmentsProps> = ({ agencyId, isNewAgency }) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [appointmentModalValues, setAppointmentModalValues] =
    useState<AgencyAppointmentModalProps | null>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/AgencyProgram/UpdateInsertAgencyProgram",
    appointmentModalValues?.agencyAppointment
  );

  useEffect(() => {
    if (appointmentModalValues?.isSaving) {
      dispatchPost();
      setAppointmentModalValues({
        ...appointmentModalValues,
        isSaving: false,
      });
    }
  }, [appointmentModalValues?.isSaving]);

  useEffect(() => {
    if (appointmentModalValues?.isDeleting) {
      dispatchPost();
      setAppointmentModalValues({
        ...appointmentModalValues,
        isDeleting: false,
      });
    }
  }, [appointmentModalValues?.isDeleting]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setAppointmentModalValues({
        ...appointmentModalValues,
        refreshTable: true,
        isOpen: false,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (!appointmentModalValues?.isOpen) {
      setErrorDetails(null);
    }
  }, [appointmentModalValues?.isOpen]);

  useEffect(() => {
    if (appointmentModalValues?.refreshTable) {
      setAppointmentModalValues({
        ...appointmentModalValues,
        refreshTable: false,
      });
    }
  }, [appointmentModalValues?.refreshTable]);

  return (
    <>
      {!(appointmentModalValues?.refreshTable ?? false) && (
        <AppointmentsTable
          agencyId={agencyId}
          isNewAgency={isNewAgency}
          appointmentModalValues={appointmentModalValues}
          setAppointmentModalValues={setAppointmentModalValues}
        />
      )}
      {appointmentModalValues?.isOpen && (
        <ModalAgencyAppointment
          errorDetails={errorDetails}
          appointmentModalValues={appointmentModalValues}
          setAppointmentModalValues={setAppointmentModalValues}
        />
      )}
    </>
  );
};

export default Appointments;
