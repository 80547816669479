import { FC, useEffect, useState } from "react";
import { Font, Input, Modal, Select } from "../../../TrueUI";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlineIcon from "@mui/icons-material/InfoOutlined";
import style from "./PolicyQuote.module.css";
import { useApiGet, useApiPost } from "../../../../hooks";
import { WarningValidationForUnbindDto } from "../../../../dtos/warning-validation-for-unbind-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { updatePolicyQuoteInformationMultiTarget } from "../updatesPolicyQuoteFunctions";
import { usePolicyQuoteTriggerComponent } from "../hooks/usePolicyQuoteTriggerComponent";
import { UnbindRegularQuoteRequestDto } from "../../../../dtos/unbind-regular-quote-request-dto";

type UnbindModalProps = {
  tabKey: string;
  modalOpen: boolean;
  closeEvent: () => void;
};

type UnbindModalUIProps = {
  warningValidations: WarningValidationForUnbindDto;
  commentsForActivityLog: string;
  newPolicyStatus: number | null;
};

const UnbindModal: FC<UnbindModalProps> = ({
  tabKey,
  modalOpen,
  closeEvent,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const policyJSON = getAtom()?.policyQuoteInformation?.policyQuote;
  const insuredId = policyJSON?.insured?.insuredID ?? 0;
  const policyId = policyJSON?.policyID ?? 0;
  const quoteId = policyJSON?.quote?.quoteID ?? 0;
  const [unbindModalUI, setUnbindModalUI] = useState<UnbindModalUIProps>();
  const { responseGet, dispatchGet } = useApiGet<WarningValidationForUnbindDto>(
    `api/QuotePolicy/GetWarningValidationsBeforeUnbind?insuredId=${insuredId}&policyId=${policyId}`
  );
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<PolicyBlob>(`api/QuotePolicy/UnbindRegularQuote`, {
      activityLogComments: unbindModalUI?.commentsForActivityLog,
      newPolicyStatus: unbindModalUI?.newPolicyStatus,
      policyId: policyId,
      quoteId: quoteId,
      policyJSONInProcess: getAtom()?.policyQuoteInformation?.policyQuote,
    } as UnbindRegularQuoteRequestDto);
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      responseGet.axiosResponse?.data !== null &&
      responseGet.axiosResponse?.data !== undefined
    ) {
      const warningValidations = responseGet.axiosResponse.data;
      setUnbindModalUI({
        ...unbindModalUI,
        warningValidations: warningValidations,
        commentsForActivityLog: "",
        newPolicyStatus:
          warningValidations.policyStatusList.length > 0
            ? warningValidations.policyStatusList[0].intValue
            : null,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responsePost) &&
      (validatorErrorResponse === undefined || validatorErrorResponse === null)
    ) {
      const atomValue = getAtom();
      const policyJSON = responsePost.axiosResponse?.data ?? null;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, false]
      );
      setAtom(newAtomValue);
      setPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      closeEvent();
    }
  }, [responsePost]);

  return unbindModalUI ? (
    <Modal
      id="policy-quote-unbind-modal-id"
      title="Unbind Quote"
      open={modalOpen}
      showCloseButton
      showCancelTextButton
      saveOverrideLabel="UNBIND"
      saveEvent={() => dispatchPost()}
      saveDisabled={unbindModalUI.warningValidations.isAlreadyEndorsed}
      cancelEvent={closeEvent}
      cancelButtonWithConfirmation
    >
      <div className={style.policy_quote_unbind_modal_body_container}>
        <Font
          whiteSpace="normal"
          className={style.informative_text_unbind_quote}
        >
          Clicking on the unbind button will change this quote back to the the
          status selected below and will remove any billing related to this
          policy. It will not remove any payments or affect the status of the
          insured.
        </Font>
        {unbindModalUI.warningValidations.submittedViaWCPOLS && (
          <Font className={style.warning_message_unbind_quote}>
            <InfoOutlineIcon
              fontSize="small"
              className={style.warning_icon_unbind_quote_warning}
            />
            Policy was submitted via WCPOLS.
          </Font>
        )}
        {unbindModalUI.warningValidations.withPaymentReceived && (
          <Font className={style.warning_message_unbind_quote}>
            <InfoOutlineIcon
              fontSize="small"
              className={style.warning_icon_unbind_quote_warning}
            />
            A payment has been received and posted to this policy.
          </Font>
        )}
        {unbindModalUI.warningValidations.boundForMoreThan5Days && (
          <Font className={style.warning_message_unbind_quote}>
            <InfoOutlineIcon
              fontSize="small"
              className={style.warning_icon_unbind_quote_warning}
            />
            {`This policy has been bound for ${unbindModalUI.warningValidations.numberOfDaysBound} days.`}
          </Font>
        )}
        {unbindModalUI.warningValidations.isAlreadyEndorsed && (
          <Font className={style.warning_message_unbind_quote}>
            <ErrorOutlineIcon
              fontSize="small"
              className={style.warning_icon_unbind_quote_error}
            />
            This policy has already been endorsed.
          </Font>
        )}
        <Select
          id="change-status-unbind-quote-id"
          name="change-status-unbind-quote"
          label="Change Status To"
          labelFontType="BOLD_CAPTION"
          options={unbindModalUI.warningValidations.policyStatusList}
          onChange={(value) =>
            setUnbindModalUI({ ...unbindModalUI, newPolicyStatus: value })
          }
        />
        <Input
          id="comments-unbind-quote-id"
          name="comments-unbind-quote"
          label="Comments (required)"
          value={unbindModalUI.commentsForActivityLog}
          maxLength={230}
          onChangeRawValue={(value) =>
            setUnbindModalUI({
              ...unbindModalUI,
              commentsForActivityLog: value,
            })
          }
          errorMessage={
            validatorErrorResponse?.errorDetails?.commentsForActivityLog
              ? (validatorErrorResponse?.errorDetails
                  ?.commentsForActivityLog as string[])
              : null
          }
        />
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default UnbindModal;
