import { EndorsementTypeEnum } from "../../../../dtos/endorsement-type-enum";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { PolicyInsuredOfficerBlob } from "../../../../dtos/policy-insured-officer-blob";
import { PolicyInsuredOldAddressBlob } from "../../../../dtos/policy-insured-old-address-blob";
import { PolicyInsuredNameBlob } from "../../../../dtos/policy-insured-name-blob";
import { StatusEnums } from "../../../../dtos/status-enums";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import {
  isDateAfterDate,
  isBetweenEffectiveAndExpirationDates,
  FormattingDate,
} from "../../../../utilities/dateFunctions";
import { areObjectsEqual } from "../../../../utilities/objectFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { EndorsementCommentBlob } from "../../../../dtos/endorsement-comment-blob";
import {
  ProducerDataProps,
  getPrimaryProducer,
} from "./EndorsementProducer/ProducerChangeUtil";
import { PolicyProducerBlob } from "../../../../dtos/policy-producer-blob";
import { PolicyProducerAgencyBlob } from "../../../../dtos/policy-producer-agency-blob";
import { PolicyProducerLocationBlob } from "../../../../dtos/policy-producer-location-blob";
import { PolicyProducerAgentBlob } from "../../../../dtos/policy-producer-agent-blob";
import { AgencyLocationDto } from "../../../../dtos/agency-location-dto";
import { AgencyContactDto } from "../../../../dtos/agency-contact-dto";
import { PolicyKeyFieldsFormData } from "./PolicyKeyFields/PolicyKeyFieldsUtil";
import { phoneMask } from "../../../../utilities/stringFunctions";
import { AgencyProgramDefaultRatesDto } from "../../../../dtos/agency-program-default-rates-dto";
import { getDefaultRateByAgency } from "../PolicyQuoteInformation/PolicyQuoteAgencyInformationTable/AgencyInformationTableUtils";

export const executeEndorsementEngine = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  // Run all the endorsement engine rules.
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
  const policyJSONUpdatedByNameChange = endorsementEngineNameChange(policyJSON);

  const policyJSONUpdatedByPrimaryAddressChange =
    endorsementEnginePrimaryAddressChange(policyJSONUpdatedByNameChange);

  const {
    policyJSON: policyJSONUpdatedByLocationChange,
    isTypeItemSelectedByPreviousChange,
  } = endorsementEngineLocationChange(policyJSONUpdatedByPrimaryAddressChange);

  const policyJSONUpdatedByNameInsuredChange =
    endorsementEngineNameInsuredChange(
      policyJSONUpdatedByLocationChange,
      isTypeItemSelectedByPreviousChange
    );

  const policyJSONUpdatedByOfficerChange = endorsementEngineOfficerChange(
    policyJSONUpdatedByNameInsuredChange
  );

  const newAtomValue = updatePolicyQuoteInformation(
    atomValue,
    "policyQuote",
    policyJSONUpdatedByOfficerChange // This should use the last policy json updated returned by the last rule executed.
  );

  return {
    policyJSONUpdated: policyJSONUpdatedByOfficerChange, // This should use the last policy json updated returned by the last rule executed.
    newAtomValue,
  };
};

const getNewSelectedList = (
  policyJSON: PolicyBlob,
  endorsementTypeTarget: EndorsementTypeEnum,
  value: boolean
) =>
  policyJSON.quote?.endorsementTypeList?.map((endorsementType) => {
    if (endorsementType.value === endorsementTypeTarget)
      return { ...endorsementType, isChecked: value };
    return endorsementType;
  }) ?? [];

const concatCommentList = (
  policyJSON: PolicyBlob,
  newEndorsementCommentList: EndorsementCommentBlob[]
) => {
  return [
    ...(policyJSON.quote?.endorsementComments ?? []),
    ...newEndorsementCommentList,
  ];
};

export const clearEndorsementTypeList = (
  policyJSON: PolicyBlob,
  endorsementType: EndorsementTypeEnum
) => {
  const policyJSONWithDefaultEndorsementTypes = {
    ...policyJSON,
    quote: {
      ...policyJSON.quote,
      endorsementTypeList:
        policyJSON?.quote?.endorsementTypeList ??
        policyJSON.endorsementConfiguration?.endorsementTypeList,
    },
  } as PolicyBlob;

  const updatedEndorsementTypeSelectedList = getNewSelectedList(
    policyJSONWithDefaultEndorsementTypes,
    endorsementType,
    false
  );
  const policyJSONUpdated = {
    ...policyJSON,
    quote: {
      ...policyJSON?.quote,
      endorsementTypeList: updatedEndorsementTypeSelectedList,
    },
  } as PolicyBlob;
  return policyJSONUpdated;
};

//#region Name Change Rule

const endorsementEngineNameChange = (policyJSON: PolicyBlob) => {
  if (
    policyJSON?.endorsementConfiguration?.insured?.legalName !==
    policyJSON?.insured?.legalName
  ) {
    const newEndorsementComments = getNameComment(
      policyJSON?.endorsementConfiguration?.insured?.legalName ?? ""
    );
    const allEndorsementComments = concatCommentList(policyJSON, [
      newEndorsementComments,
    ]);
    const policyJSONWithDefaultEndorsementTypes = {
      ...policyJSON,
      quote: {
        ...policyJSON.quote,
        endorsementTypeList:
          policyJSON.endorsementConfiguration?.endorsementTypeList,
      },
    } as PolicyBlob;
    const updatedEndorsementTypeSelectedList = getNewSelectedList(
      policyJSONWithDefaultEndorsementTypes,
      EndorsementTypeEnum.INSURED_NAME,
      true
    );

    const policyJSONUpdated = {
      ...policyJSON,
      insured: {
        ...policyJSON?.insured,
        legalName: policyJSON?.endorsementConfiguration?.insured?.legalName,
      },
      oldLegalName: policyJSON?.insured?.legalName,
      quote: {
        ...policyJSON?.quote,
        endorsementTypeList: updatedEndorsementTypeSelectedList,
        endorsementComments: allEndorsementComments,
      },
    } as PolicyBlob;

    return policyJSONUpdated;
  }
  const policyJSONWithSpecificEndorsementTypesRemoved =
    clearEndorsementTypeList(policyJSON, EndorsementTypeEnum.INSURED_NAME);
  return policyJSONWithSpecificEndorsementTypesRemoved;
};

const getNameComment = (newLegalName: string) =>
  ({
    textComment: `Insured's Name: ${newLegalName}`,
    isUserEdited: false,
  } as EndorsementCommentBlob);

//#endregion

//#region Primary Address Change Rule

const endorsementEnginePrimaryAddressChange = (policyJSON: PolicyBlob) => {
  const policyEffectiveDate = policyJSON.effectiveDate;
  const policyExpirationDate = policyJSON.expirationDate;
  if (
    conditionHasValue(policyExpirationDate) &&
    conditionHasValue(policyEffectiveDate)
  ) {
    const insuredPrimaryAddressDB =
      policyJSON.endorsementConfiguration?.insuredAddressList?.find(
        (addr) =>
          addr?.addressType?.toLowerCase() === "primary" &&
          addr?.locationStatus === StatusEnums.ACTIVE &&
          isBetweenEffectiveAndExpirationDates(
            policyEffectiveDate,
            addr?.effectiveDate,
            addr.expirationDate
          )
      ) as PolicyInsuredAddressBlob;
    const insuredPrimaryAddressJSON = policyJSON.insured?.addresses?.find(
      (addr) =>
        addr?.addressType?.toLowerCase() === "primary" &&
        addr?.locationStatus === StatusEnums.ACTIVE &&
        isBetweenEffectiveAndExpirationDates(
          policyEffectiveDate,
          addr?.effectiveDate,
          addr?.expirationDate
        )
    ) as PolicyInsuredAddressBlob;

    if (
      !isEmptyValue(insuredPrimaryAddressDB) &&
      !areObjectsEqual(insuredPrimaryAddressDB, insuredPrimaryAddressJSON)
    ) {
      const newEndorsementComments = getAddressComment(insuredPrimaryAddressDB);
      const allEndorsementComments = concatCommentList(policyJSON, [
        newEndorsementComments,
      ]);

      const updatedEndorsementTypeSelectedList = getNewSelectedList(
        policyJSON,
        EndorsementTypeEnum.INSURED_MAILING_ADDRESS,
        true
      );

      const policyJSONUpdated = {
        ...policyJSON,
        insured: {
          ...policyJSON?.insured,
          addresses: policyJSON?.insured?.addresses?.map((insuredAddress) =>
            insuredAddress?.addressType?.toLowerCase() === "primary"
              ? {
                  ...insuredAddress,
                  ...insuredPrimaryAddressDB,
                  oldAddress: {
                    address1: insuredAddress.address1,
                    address2: insuredAddress.address2,
                    addressCity: insuredAddress.addressCity,
                    addressFaxNumber: insuredAddress.addressFaxNumber,
                    addressFaxNumberNoFormat:
                      insuredAddress.addressFaxNumberNoFormat,
                    addressID: insuredAddress.addressID,
                    addressPhoneNumber: insuredAddress.addressPhoneNumber,
                    addressPhoneNumberNoFormat:
                      insuredAddress.addressPhoneNumberNoFormat,
                    addressPostalCode: insuredAddress.addressPostalCode,
                    addressState: insuredAddress.addressState,
                    addressType: insuredAddress.addressType,
                    effectiveDate: insuredAddress.effectiveDate,
                    expirationDate: insuredAddress.expirationDate,
                    fullTimeEE: insuredAddress.fullTimeEE,
                    locationName: insuredAddress.locationName,
                    locationNumber: insuredAddress.locationNumber,
                    locationStatus: insuredAddress.locationStatus,
                    nameID: insuredAddress.nameID,
                    partTimeEE: insuredAddress.partTimeEE,
                    parentRowIndex: insuredAddress.parentRowIndex,
                    storeNumber: insuredAddress.storeNumber,
                  } as PolicyInsuredOldAddressBlob,
                }
              : insuredAddress
          ),
        },
        quote: {
          ...policyJSON?.quote,
          endorsementTypeList: updatedEndorsementTypeSelectedList,
          endorsementComments: allEndorsementComments,
        },
      } as PolicyBlob;
      return policyJSONUpdated;
    }
  }
  const policyJSONWithSpecificEndorsementTypesRemoved =
    clearEndorsementTypeList(
      policyJSON,
      EndorsementTypeEnum.INSURED_MAILING_ADDRESS
    );
  return policyJSONWithSpecificEndorsementTypesRemoved;
};

const getAddressComment = (
  insuredPrimaryAddressDB: PolicyInsuredOldAddressBlob
) =>
  ({
    textComment: `Insured's Mailing Address: ${
      insuredPrimaryAddressDB?.address1 ?? ""
    } ${insuredPrimaryAddressDB?.address2 ?? ""} ${
      insuredPrimaryAddressDB?.addressCity ?? ""
    } ${insuredPrimaryAddressDB?.addressState ?? ""} ${
      insuredPrimaryAddressDB?.addressPostalCode ?? ""
    }`,
    isUserEdited: false,
  } as EndorsementCommentBlob);

//#endregion

//#region Location Change Rule

const getAddressToDeleteFromJSON = (
  currentAddressListInJSON: PolicyInsuredAddressBlob[],
  currentAddressListInDB: PolicyInsuredAddressBlob[],
  policyEffectiveDate: Date
) =>
  currentAddressListInJSON.filter((addressInJSON) => {
    const addressInDB = currentAddressListInDB.find(
      (addressInDB) => addressInDB.addressID === addressInJSON.addressID
    );
    if (
      addressInJSON.addressType?.toLowerCase() === "location" &&
      addressInDB !== undefined &&
      addressInJSON.locationStatus === StatusEnums.ACTIVE &&
      (addressInDB.locationStatus === StatusEnums.INACTIVE ||
        !isBetweenEffectiveAndExpirationDates(
          policyEffectiveDate,
          addressInDB.effectiveDate,
          addressInDB.expirationDate
        ) ||
        (!conditionHasValue(addressInJSON.expirationDate) &&
          isDateAfterDate(new Date(), addressInJSON.expirationDate)))
    ) {
      return addressInJSON;
    }
    return null;
  });

const getAddressToUpdateFromJSONList = (
  currentAddressListInJSON: PolicyInsuredAddressBlob[],
  currentAddressListInDB: PolicyInsuredAddressBlob[],
  policyEffectiveDate: Date
) =>
  currentAddressListInJSON.filter((addressInJSON) => {
    const addressInDB = currentAddressListInDB.find(
      (addressInDB) => addressInDB.addressID === addressInJSON.addressID
    );
    if (
      addressInJSON.addressType?.toLowerCase() === "location" &&
      addressInDB !== undefined &&
      addressInJSON.locationStatus === StatusEnums.ACTIVE &&
      addressInDB.locationStatus === StatusEnums.ACTIVE &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        addressInDB.effectiveDate,
        addressInDB.expirationDate
      ) &&
      areObjectsEqual(addressInJSON, addressInDB) === false
    ) {
      return addressInJSON;
    }
    return null;
  });

const getAddressToUpdateFromDBList = (
  currentAddressListInDB: PolicyInsuredAddressBlob[],
  addressToUpdateFromJSON: PolicyInsuredAddressBlob[]
) =>
  currentAddressListInDB.filter((addressInDB) => {
    const addressInJSON = addressToUpdateFromJSON.find(
      (addressInJSON) => addressInJSON.addressID === addressInDB.addressID
    );
    if (addressInJSON !== undefined) {
      return addressInDB;
    }
    return null;
  });

const getAddressUpdatedForLocationChange = (
  currentAddressListInJSON: PolicyInsuredAddressBlob[],
  currentAddressListInDB: PolicyInsuredAddressBlob[],
  addressToDeleteList: PolicyInsuredAddressBlob[],
  policyEffectiveDate: Date
) => {
  // Address updated
  const addressUpdatedList = currentAddressListInJSON.map((addressInJSON) => {
    const addressInDB = currentAddressListInDB.find(
      (addressInDB) => addressInDB.addressID === addressInJSON.addressID
    );
    if (
      addressInJSON.addressType?.toLowerCase() === "location" &&
      addressInDB !== undefined &&
      addressInJSON.locationStatus === StatusEnums.ACTIVE &&
      addressInDB.locationStatus === StatusEnums.ACTIVE &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        addressInDB.effectiveDate,
        addressInDB.expirationDate
      )
    ) {
      return addressInDB;
    }
    return addressInJSON;
  });

  // Address without deleted
  const addressWithoutDeletedList = addressUpdatedList.filter(
    (addressInJSON) => !addressToDeleteList.includes(addressInJSON)
  );

  return addressWithoutDeletedList;
};

const getNewAddressFromDBList = (
  currentAddressListInJSON: PolicyInsuredAddressBlob[],
  currentAddressListInDB: PolicyInsuredAddressBlob[],
  policyEffectiveDate: Date
) =>
  currentAddressListInDB.filter((addressInDB) => {
    const addressInJSON = currentAddressListInJSON.find(
      (addressInJSON) => addressInJSON.addressID === addressInDB.addressID
    );
    if (
      addressInDB.addressType?.toLowerCase() === "location" &&
      addressInJSON === undefined &&
      addressInDB.locationStatus === StatusEnums.ACTIVE &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        addressInDB.effectiveDate,
        addressInDB.expirationDate
      )
    ) {
      return addressInDB;
    }
    return null;
  });

const getCommentsForLocationChange = (
  addressToDeleteList: PolicyInsuredAddressBlob[],
  addressToUpdateFromDBList: PolicyInsuredAddressBlob[],
  newAddressFromDBList: PolicyInsuredAddressBlob[]
) => {
  const commentsForAddressDeleted = addressToDeleteList.map(
    (address) =>
      ({
        textComment: address.expirationDate
          ? `Location ${address.locationNumber} ${address.address1} ${address.addressCity}, ${address.addressCity} ${address.addressPostalCode} has been removed effective ${address.expirationDate}`
          : `Location ${address.locationNumber} ${address.address1} ${address.addressCity}, ${address.addressCity} ${address.addressPostalCode} has been removed`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );
  const commentsForAddressUpdated = addressToUpdateFromDBList.map(
    (address) =>
      ({
        textComment: `Location ${address.locationNumber} has been changed to read ${address.address1} ${address.addressCity}, ${address.addressState} ${address.addressPostalCode}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );
  const commentsForNewAddress = newAddressFromDBList.map(
    (address) =>
      ({
        textComment: `Location ${address.locationNumber} ${address.address1} ${address.addressCity}, ${address.addressState} ${address.addressPostalCode} has been added effective ${address.effectiveDate}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );

  const allComments = [
    ...commentsForAddressDeleted,
    ...commentsForAddressUpdated,
    ...commentsForNewAddress,
  ];

  return allComments;
};

const endorsementEngineLocationChange = (policyJSON: PolicyBlob) => {
  const currentAddressListInDB =
    policyJSON.endorsementConfiguration?.insuredAddressList?.filter(
      (address) => address.addressType?.toLowerCase() === "location"
    ) ?? [];
  const currentAddressListInJSON = policyJSON.insured?.addresses ?? [];
  const policyEffectiveDate = policyJSON.effectiveDate;
  const policyExpirationDate = policyJSON.expirationDate;

  if (
    conditionHasValue(policyEffectiveDate) &&
    conditionHasValue(policyExpirationDate)
  ) {
    // Address to delete
    const addressToDeleteList = getAddressToDeleteFromJSON(
      currentAddressListInJSON,
      currentAddressListInDB,
      policyEffectiveDate
    );

    // Address to update from JSON
    const addressToUpdateFromJSONList = getAddressToUpdateFromJSONList(
      currentAddressListInJSON,
      currentAddressListInDB,
      policyEffectiveDate
    );

    // Address updated from DB

    const addressToUpdateFromDBList = getAddressToUpdateFromDBList(
      currentAddressListInDB,
      addressToUpdateFromJSONList
    );

    // Address updated

    const addressUpdatedList = getAddressUpdatedForLocationChange(
      currentAddressListInJSON,
      currentAddressListInDB,
      addressToDeleteList,
      policyEffectiveDate
    );

    // New address from DB

    const newAddressFromDBList = getNewAddressFromDBList(
      currentAddressListInJSON,
      currentAddressListInDB,
      policyEffectiveDate
    );

    const hasAnyChangeOcurred =
      addressToDeleteList.length > 0 ||
      addressToUpdateFromJSONList.length > 0 ||
      newAddressFromDBList.length > 0;

    if (hasAnyChangeOcurred) {
      // Update selected list

      const updatedEndorsementTypeSelectedList = getNewSelectedList(
        policyJSON,
        EndorsementTypeEnum.CHANGE_IN_WORKPLACE_OF_INSURED,
        true
      );

      // Update comments

      const newEndorsementComments = getCommentsForLocationChange(
        addressToDeleteList,
        addressToUpdateFromDBList,
        newAddressFromDBList
      );

      const allEndorsementComments = concatCommentList(
        policyJSON,
        newEndorsementComments
      );

      // Update policy JSON

      const policyJSONUpdated = {
        ...policyJSON,
        insured: {
          ...policyJSON?.insured,
          addresses: [...addressUpdatedList, ...newAddressFromDBList],
          oldAddresses: addressToUpdateFromJSONList,
          deletedAddresses: addressToDeleteList,
        },
        quote: {
          ...policyJSON?.quote,
          endorsementTypeList: updatedEndorsementTypeSelectedList,
          endorsementComments: allEndorsementComments,
        },
      } as PolicyBlob;

      return {
        policyJSON: policyJSONUpdated,
        isTypeItemSelectedByPreviousChange: true,
      };
    }
    const policyJSONWithSpecificEndorsementTypesRemoved =
      clearEndorsementTypeList(
        policyJSON,
        EndorsementTypeEnum.CHANGE_IN_WORKPLACE_OF_INSURED
      );
    return {
      policyJSON: policyJSONWithSpecificEndorsementTypesRemoved,
      isTypeItemSelectedByPreviousChange: false,
    };
  }
  return {
    policyJSON,
    isTypeItemSelectedByPreviousChange: false,
  };
};

//#endregion

//#region Named Insured Change Rule

const getNameToDeleteListFromJSON = (
  currentNameListInJSON: PolicyInsuredNameBlob[],
  currentNameListInDB: PolicyInsuredNameBlob[],
  policyEffectiveDate: Date
) =>
  currentNameListInJSON.filter((nameInJSON) => {
    const nameInDB = currentNameListInDB.find(
      (nameInDB) => nameInDB.nameID === nameInJSON.nameID
    );
    if (
      nameInJSON.nameType?.toLowerCase() === "named insured" &&
      nameInDB !== undefined &&
      (!isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        nameInDB.effectiveDate,
        nameInDB.expirationDate
      ) ||
        (!conditionHasValue(nameInJSON.expirationDate) &&
          isDateAfterDate(new Date(), nameInDB.expirationDate)))
    ) {
      return nameInJSON;
    }
    return null;
  });

const getNameToUpdateListFromJSON = (
  currentNameListInJSON: PolicyInsuredNameBlob[],
  currentNameListInDB: PolicyInsuredNameBlob[],
  policyEffectiveDate: Date
) =>
  currentNameListInJSON.filter((nameInJSON) => {
    const nameInDB = currentNameListInDB.find(
      (nameInDB) => nameInDB.nameID === nameInJSON.nameID
    );
    if (
      nameInJSON.nameType?.toLowerCase() === "named insured" &&
      nameInDB !== undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        nameInDB.effectiveDate,
        nameInDB.expirationDate
      ) &&
      areObjectsEqual(nameInJSON, nameInDB) === false
    ) {
      return nameInJSON;
    }
    return null;
  });

const getNameToUpdateListFromDB = (
  currentNameListInDB: PolicyInsuredNameBlob[],
  nameToUpdateListFromJSON: PolicyInsuredNameBlob[]
) =>
  currentNameListInDB.filter((nameInDB) => {
    const nameInJSON = nameToUpdateListFromJSON.find(
      (nameInJSON) => nameInJSON.nameID === nameInDB.nameID
    );
    if (nameInJSON !== undefined) {
      return nameInDB;
    }
    return null;
  });

const getNameUpdatedListForNameInsuredChange = (
  currentNameListInJSON: PolicyInsuredNameBlob[],
  currentNameListInDB: PolicyInsuredNameBlob[],
  nameToDeleteList: PolicyInsuredNameBlob[],
  policyEffectiveDate: Date
) => {
  // Name updated
  const nameUpdatedList = currentNameListInJSON.map((nameInJSON) => {
    const nameInDB = currentNameListInDB.find(
      (nameInDB) => nameInDB.nameID === nameInJSON.nameID
    );
    if (
      nameInJSON.nameType?.toLowerCase() === "named insured" &&
      nameInDB !== undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        nameInDB.effectiveDate,
        nameInDB.expirationDate
      ) &&
      areObjectsEqual(nameInJSON, nameInDB) === false
    ) {
      return nameInDB;
    }
    return nameInJSON;
  });

  // Name without deleted
  const nameWithoutDeletedList = nameUpdatedList.filter(
    (nameInJSON) => !nameToDeleteList.includes(nameInJSON)
  );

  return nameWithoutDeletedList;
};

const getNewNameListFromDB = (
  currentNameListInJSON: PolicyInsuredNameBlob[],
  currentNameListInDB: PolicyInsuredNameBlob[],
  policyEffectiveDate: Date
) =>
  currentNameListInDB.filter((nameInDB) => {
    const nameInJSON = currentNameListInJSON.find(
      (nameInJSON) => nameInJSON.nameID === nameInDB.nameID
    );
    if (
      nameInDB.nameType?.toLowerCase() === "named insured" &&
      nameInJSON === undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        nameInDB.effectiveDate,
        nameInDB.expirationDate
      )
    ) {
      return nameInDB;
    }
    return null;
  });

const getCommentsForNameInsuredChange = (
  nameToDeleteList: PolicyInsuredNameBlob[],
  nameToUpdateListFromJSON: PolicyInsuredNameBlob[],
  nameToUpdateListFromDB: PolicyInsuredNameBlob[],
  newNameListFromDB: PolicyInsuredNameBlob[]
) => {
  const commentsForNameDeleted = nameToDeleteList.map(
    (name) =>
      ({
        textComment: name.expirationDate
          ? `Named Insured ${name.legalName} has been removed effective ${name.expirationDate}`
          : `Named Insured ${name.legalName} has been removed`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );
  const commentsForNameUpdated = nameToUpdateListFromDB.map((nameUpdated) => {
    const oldName = nameToUpdateListFromJSON.find(
      (oldName) => oldName.nameID === nameUpdated.nameID
    );
    return {
      textComment: `Named Insured ${oldName?.legalName} has been changed to ${nameUpdated.legalName}`,
      isUserEdited: false,
    } as EndorsementCommentBlob;
  });
  const commentsForNewName = newNameListFromDB.map(
    (name) =>
      ({
        textComment: `Named Insured ${name.legalName} has been added effective ${name.effectiveDate}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );

  const allComments = [
    ...commentsForNameDeleted,
    ...commentsForNameUpdated,
    ...commentsForNewName,
  ];

  return allComments;
};

const endorsementEngineNameInsuredChange = (
  policyJSON: PolicyBlob,
  isTypeItemSelectedByPreviousChange: boolean
) => {
  const currentNameListInDB =
    policyJSON.endorsementConfiguration?.nameList?.filter(
      (name) => name.nameType?.toLowerCase() === "named insured"
    ) ?? [];
  const currentNameListInJSON = policyJSON.insured?.names ?? [];
  const policyEffectiveDate = policyJSON.effectiveDate;
  const policyExpirationDate = policyJSON.expirationDate;

  if (
    conditionHasValue(policyEffectiveDate) &&
    conditionHasValue(policyExpirationDate)
  ) {
    // Name to delete
    const nameToDeleteList = getNameToDeleteListFromJSON(
      currentNameListInJSON,
      currentNameListInDB,
      policyEffectiveDate
    );

    // Name to update from JSON
    const nameToUpdateListFromJSON = getNameToUpdateListFromJSON(
      currentNameListInJSON,
      currentNameListInDB,
      policyEffectiveDate
    );

    // Name updated from DB

    const nameToUpdateListFromDB = getNameToUpdateListFromDB(
      currentNameListInDB,
      nameToUpdateListFromJSON
    );

    // Name updated

    const nameUpdatedList = getNameUpdatedListForNameInsuredChange(
      currentNameListInJSON,
      currentNameListInDB,
      nameToDeleteList,
      policyEffectiveDate
    );

    // New name from DB

    const newNameListFromDB = getNewNameListFromDB(
      currentNameListInJSON,
      currentNameListInDB,
      policyEffectiveDate
    );

    const hasAnyChangeOcurred =
      nameToDeleteList.length > 0 ||
      nameToUpdateListFromJSON.length > 0 ||
      newNameListFromDB.length > 0;

    if (hasAnyChangeOcurred) {
      // Update selected list

      const updatedEndorsementTypeSelectedList = getNewSelectedList(
        policyJSON,
        EndorsementTypeEnum.CHANGE_IN_WORKPLACE_OF_INSURED,
        true
      );

      // Update comments

      const newEndorsementComments = getCommentsForNameInsuredChange(
        nameToDeleteList,
        nameToUpdateListFromJSON,
        nameToUpdateListFromDB,
        newNameListFromDB
      );

      const allEndorsementComments = concatCommentList(
        policyJSON,
        newEndorsementComments
      );

      // Update policy JSON

      const policyJSONUpdated = {
        ...policyJSON,
        insured: {
          ...policyJSON?.insured,
          names: [...nameUpdatedList, ...newNameListFromDB],
          oldNames: nameToUpdateListFromJSON,
          deletedNames: nameToDeleteList,
        },
        quote: {
          ...policyJSON?.quote,
          endorsementTypeList: updatedEndorsementTypeSelectedList,
          endorsementComments: allEndorsementComments,
        },
      } as PolicyBlob;

      return policyJSONUpdated;
    }
    if (isTypeItemSelectedByPreviousChange === false) {
      const policyJSONWithSpecificEndorsementTypesRemoved =
        clearEndorsementTypeList(
          policyJSON,
          EndorsementTypeEnum.CHANGE_IN_WORKPLACE_OF_INSURED
        );
      return policyJSONWithSpecificEndorsementTypesRemoved;
    }
  }
  return policyJSON;
};

//#endregion

//#region Officer Change Rule
const getOfficerToDeleteFromJSON = (
  currentOfficerListInJSON: PolicyInsuredOfficerBlob[],
  currentOfficerListInDB: PolicyInsuredOfficerBlob[],
  policyEffectiveDate: Date
) =>
  currentOfficerListInJSON.filter((officerInJSON) => {
    const officerInDB = currentOfficerListInDB.find(
      (officerInDB) => officerInDB.officerID === officerInJSON.officerID
    );
    if (
      officerInDB !== undefined &&
      (!isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        officerInDB.effectiveDate,
        officerInDB.expirationDate
      ) ||
        (!conditionHasValue(officerInJSON.expirationDate) &&
          isDateAfterDate(new Date(), officerInDB.expirationDate)))
    ) {
      return officerInJSON;
    }
    return null;
  });

const getOfficerToUpdateFromJSONList = (
  currentOfficerListInJSON: PolicyInsuredOfficerBlob[],
  currentOfficerListInDB: PolicyInsuredOfficerBlob[],
  policyEffectiveDate: Date
) =>
  currentOfficerListInJSON.filter((officerInJSON) => {
    const officerInDB = currentOfficerListInDB.find(
      (officerInDB) => officerInDB.officerID === officerInJSON.officerID
    );
    if (
      officerInDB !== undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        officerInDB.effectiveDate,
        officerInDB.expirationDate
      ) &&
      areObjectsEqual(officerInJSON, officerInDB) === false
    ) {
      return officerInJSON;
    }
    return null;
  });

const getOfficerToUpdateFromDBList = (
  currentOfficerListInDB: PolicyInsuredOfficerBlob[],
  officerToUpdateFromJSON: PolicyInsuredOfficerBlob[]
) =>
  currentOfficerListInDB.filter((officerInDB) => {
    const officerInJSON = officerToUpdateFromJSON.find(
      (officerInJSON) => officerInJSON.officerID === officerInDB.officerID
    );
    return officerInJSON !== undefined ? officerInDB : null;
  });

const getOfficerUpdatedForOfficerChange = (
  currentOfficerListInJSON: PolicyInsuredOfficerBlob[],
  currentOfficerListInDB: PolicyInsuredOfficerBlob[],
  officerToDeleteList: PolicyInsuredOfficerBlob[],
  policyEffectiveDate: Date
) => {
  // Officer updated
  const officerUpdatedList = currentOfficerListInJSON.map((officerInJSON) => {
    const officerInDB = currentOfficerListInDB.find(
      (officerInDB) => officerInDB.officerID === officerInJSON.officerID
    );
    if (
      officerInDB !== undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        officerInDB.effectiveDate,
        officerInDB.expirationDate
      )
    ) {
      return officerInDB;
    }
    return officerInJSON;
  });

  // Officer without deleted
  const officerWithoutDeletedList = officerUpdatedList.filter(
    (officerInJSON) => !officerToDeleteList.includes(officerInJSON)
  );

  return officerWithoutDeletedList;
};

const getNewOfficerFromDBList = (
  currentOfficerListInJSON: PolicyInsuredOfficerBlob[],
  currentOfficerListInDB: PolicyInsuredOfficerBlob[],
  policyEffectiveDate: Date
) =>
  currentOfficerListInDB.filter((officerInDB) => {
    const officerInJSON = currentOfficerListInJSON.find(
      (officerInJSON) => officerInJSON.officerID === officerInDB.officerID
    );
    if (
      officerInJSON === undefined &&
      isBetweenEffectiveAndExpirationDates(
        policyEffectiveDate,
        officerInDB.effectiveDate,
        officerInDB.expirationDate
      )
    ) {
      return officerInDB;
    }
    return null;
  });

const getCommentsForOfficerChange = (
  officerToDeleteList: PolicyInsuredOfficerBlob[],
  officerToUpdateFromDBList: PolicyInsuredOfficerBlob[],
  newOfficerFormDBList: PolicyInsuredOfficerBlob[]
) => {
  const commentsForOfficerDeleted = officerToDeleteList.map(
    (officer) =>
      ({
        textComment: officer.expirationDate
          ? `${officer.inclExcl} ${officer.officerType} ${officer.officerName}, has been removed effective ${officer.expirationDate}`
          : `${officer.inclExcl} ${officer.officerType} ${officer.officerName}, has been removed`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );
  const commentsForOfficerUpdated = officerToUpdateFromDBList.map(
    (officer) =>
      ({
        textComment: `${officer.officerType}  ${officer.officerName} has been changed. See the included endorsement for detail`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );
  const commentsForNewOfficer = newOfficerFormDBList.map(
    (officer) =>
      ({
        textComment: `A new ${officer.inclExcl} ${officer.officerType} has been added - ${officer.officerName}, ${officer.officerTitle} (${officer.ownershipPercent}%) effective ${officer.effectiveDate}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
  );

  const allComments = [
    ...commentsForOfficerDeleted,
    ...commentsForOfficerUpdated,
    ...commentsForNewOfficer,
  ];

  return allComments;
};

const endorsementEngineOfficerChange = (policyJSON: PolicyBlob) => {
  const currentOfficerListInDB =
    policyJSON.endorsementConfiguration?.insuredOfficerList ?? [];
  const currentOfficerListInJSON = policyJSON.insured?.officers ?? [];
  const policyEffectiveDate = policyJSON.effectiveDate;
  const policyExpirationDate = policyJSON.expirationDate;

  if (
    conditionHasValue(policyEffectiveDate) &&
    conditionHasValue(policyExpirationDate)
  ) {
    // Officer to delete
    const officerToDeleteList = getOfficerToDeleteFromJSON(
      currentOfficerListInJSON,
      currentOfficerListInDB,
      policyEffectiveDate
    );

    // Officer to update from JSON
    const officerToUpdateFromJSONList = getOfficerToUpdateFromJSONList(
      currentOfficerListInJSON,
      currentOfficerListInDB,
      policyEffectiveDate
    );

    // Officer updated from DB
    const officerToUpdateFromDBList = getOfficerToUpdateFromDBList(
      currentOfficerListInDB,
      officerToUpdateFromJSONList
    );

    // Officer updated
    const officerUpdatedList = getOfficerUpdatedForOfficerChange(
      currentOfficerListInJSON,
      currentOfficerListInDB,
      officerToDeleteList,
      policyEffectiveDate
    );

    // New officer from DB
    const newOfficerFormDBList = getNewOfficerFromDBList(
      currentOfficerListInJSON,
      currentOfficerListInDB,
      policyEffectiveDate
    );

    const hasAnyChangeOcurred =
      officerToDeleteList.length > 0 ||
      officerToUpdateFromJSONList.length > 0 ||
      newOfficerFormDBList.length > 0;

    if (hasAnyChangeOcurred) {
      // Update selected list
      const updatedEndorsementTypeSelectedList = getNewSelectedList(
        policyJSON,
        EndorsementTypeEnum.ITEM_3_D_ENDORSEMENT_NUMBERS,
        true
      );

      // Update comments
      const newEndorsementComments = getCommentsForOfficerChange(
        officerToDeleteList,
        officerToUpdateFromDBList,
        newOfficerFormDBList
      );

      const allEndorsementComments = concatCommentList(
        policyJSON,
        newEndorsementComments
      );

      // Update policy JSON
      const policyJSONUpdated = {
        ...policyJSON,
        insured: {
          ...policyJSON?.insured,
          officers: [...officerUpdatedList, ...newOfficerFormDBList],
          oldOfficers: officerToUpdateFromJSONList,
          deletedOfficers: officerToDeleteList,
        },
        quote: {
          ...policyJSON?.quote,
          endorsementTypeList: updatedEndorsementTypeSelectedList,
          endorsementComments: allEndorsementComments,
        },
      } as PolicyBlob;

      return policyJSONUpdated;
    }
  }
  const policyJSONWithSpecificEndorsementTypesRemoved =
    clearEndorsementTypeList(
      policyJSON,
      EndorsementTypeEnum.ITEM_3_D_ENDORSEMENT_NUMBERS
    );
  return policyJSONWithSpecificEndorsementTypesRemoved;
};
//#endregion

//#region Producer Change Rule
const getAgencyProducer = (
  policyJSON: PolicyBlob,
  newProducerFormData: ProducerDataProps
) => {
  const foundAgency = policyJSON.endorsementConfiguration?.agencyList?.find(
    (agency) => agency.agencyId === newProducerFormData.agencyId
  );
  const agencyProducer = {
    agencyID: foundAgency?.agencyId,
    agencyName: foundAgency?.agencyName,
    agencyAddress: foundAgency?.agencyAddress,
    agencyCity: foundAgency?.agencyAddress,
    agencyCode: foundAgency?.agencyCode,
    agencyPhoneNumber: phoneMask(foundAgency?.agencyPhoneNumber ?? ""),
    agencyPhoneNumberNoFormat: foundAgency?.agencyPhoneNumber,
    agencyPostalCode: foundAgency?.agencyCode,
    agencyStateCode: foundAgency?.agencyStateCode,
    agencyWebsite: foundAgency?.agencyWebsite,
  } as PolicyProducerAgencyBlob;
  return agencyProducer;
};

const getLocationProducer = (
  newProducerFormData: ProducerDataProps,
  locationList: AgencyLocationDto[]
) => {
  const foundLocation = locationList?.find(
    (location) => location.agencyLocationId === newProducerFormData.locationId
  );
  const locationProducer = {
    locationID: foundLocation?.agencyLocationId,
    locationAddress: foundLocation?.address1,
    locationCity: foundLocation?.city,
    locationEffectiveDate: foundLocation?.effectiveDate,
    locationExpirationDate: foundLocation?.expirationDate,
    locationName: foundLocation?.agencyLocationName,
    locationNumber: foundLocation?.agencyLocationNumber,
    locationPhoneNumber: phoneMask(foundLocation?.phoneNumber ?? ""),
    locationPhoneNumberNoFormat: foundLocation?.phoneNumber,
    locationPostalCode: foundLocation?.postCode,
    locationStateCode: foundLocation?.state,
  } as PolicyProducerLocationBlob;
  return locationProducer;
};

const getAgentProducer = (
  newProducerFormData: ProducerDataProps,
  agentList: AgencyContactDto[]
) => {
  const foundAgentContact = agentList.find(
    (agencyContact) =>
      agencyContact.agencyContactId === newProducerFormData.agentContactId
  );
  const locationProducer = {
    agentID: foundAgentContact?.agencyContactId,
    agentFirstName: foundAgentContact?.firstName,
    agentLastName: foundAgentContact?.lastName,
    agentEmail: foundAgentContact?.agencyContactEmail,
    agentPhoneNumber: phoneMask(foundAgentContact?.agencyContactPhone ?? ""),
    agentPhoneNumberNoFormat: foundAgentContact?.agencyContactPhone,
    agentEffectiveDate: foundAgentContact?.effectiveDate,
    agentExpirationDate: foundAgentContact?.expirationDate,
  } as PolicyProducerAgentBlob;
  return locationProducer;
};

const getCommentsForProducerChange = (
  newProducer: PolicyProducerBlob,
  oldProducer?: PolicyProducerBlob
) =>
  ({
    textComment: `Producer's Name changed ${
      oldProducer?.agency?.agencyName !== undefined
        ? `from ${oldProducer?.agency?.agencyName} `
        : ""
    }to ${newProducer?.agency?.agencyName}`,
    isUserEdited: false,
  } as EndorsementCommentBlob);

export const removeProducerNameComments = (policyJSON: PolicyBlob) => {
  const updatedEndorsementTypeSelectedList = getNewSelectedList(
    policyJSON,
    EndorsementTypeEnum.PRODUCER_NAME,
    false
  );
  const allEndorsementComments = policyJSON?.quote?.endorsementComments?.filter(
    (comment) => !comment.textComment?.startsWith("Producer's Name changed")
  );

  const previousProducers =
    policyJSON?.endorsementConfiguration?.oldPolicyJSON?.producers;

  const policyJSONWithCommentRemoved = {
    ...policyJSON,
    producers: previousProducers,
    quote: {
      ...policyJSON?.quote,
      endorsementComments: allEndorsementComments,
      endorsementTypeList: updatedEndorsementTypeSelectedList,
    },
  } as PolicyBlob;

  return policyJSONWithCommentRemoved;
};

const updateProducers = (
  policyJSON: PolicyBlob,
  newProducer: PolicyProducerBlob,
  currentPrimaryProducer?: PolicyProducerBlob
) => {
  return policyJSON?.producers?.some((p) => p.policyAgencyPrimary === true)
    ? policyJSON?.producers?.map((producer) => {
        if (producer.policyAgencyPrimary === true) {
          return {
            ...newProducer,
            oldProducer: currentPrimaryProducer,
          };
        } else {
          return {
            ...producer,
            oldProducer: null,
          };
        }
      })
    : [...(policyJSON?.producers ?? []), { ...newProducer, oldProducer: null }];
};

export const endorsementEngineProducerChange = (
  policyJSON: PolicyBlob,
  newProducerFormData: ProducerDataProps,
  locationList: AgencyLocationDto[],
  agentList: AgencyContactDto[],
  policyType?: number,
  defaultRatesList?: AgencyProgramDefaultRatesDto[] | null
) => {
  const currentPrimaryProducer = getPrimaryProducer(policyJSON);
  const hasAnyChangeOcurred =
    currentPrimaryProducer?.agency?.agencyID !== newProducerFormData.agencyId ||
    currentPrimaryProducer?.location?.locationID !==
      newProducerFormData.locationId ||
    currentPrimaryProducer?.agent?.agentID !==
      newProducerFormData.agentContactId;

  if (hasAnyChangeOcurred) {
    const newAgencyProducer = getAgencyProducer(
      policyJSON,
      newProducerFormData
    );
    const newLocationProducer = getLocationProducer(
      newProducerFormData,
      locationList
    );
    const newAgentProducer = getAgentProducer(newProducerFormData, agentList);

    const newProducer = {
      policyAgencyID: currentPrimaryProducer?.policyAgencyID ?? 0,
      policyAgencyStatus: StatusEnums.ACTIVE,
      policyAgencyRateDefault: getDefaultRateByAgency(
        newAgencyProducer.agencyID ?? 0,
        policyType ?? 0,
        defaultRatesList ?? []
      ),
      policyAgencyRateOverride:
        currentPrimaryProducer?.policyAgencyRateOverride ?? 0,
      policyAgencyPrimary: true,
      policyAgencyEffectiveDate: policyJSON?.effectiveDate,
      policyAgencyExpirationDate: policyJSON?.expirationDate,
      agency: newAgencyProducer,
      location: newLocationProducer,
      agent: newAgentProducer,
    } as PolicyProducerBlob;

    const updatedEndorsementTypeSelectedList = getNewSelectedList(
      policyJSON,
      EndorsementTypeEnum.PRODUCER_NAME,
      true
    );

    const newEndorsementComments = getCommentsForProducerChange(
      newProducer,
      currentPrimaryProducer
    );

    const commentExists = policyJSON?.quote?.endorsementComments?.some(
      (comment) => comment?.textComment?.startsWith("Producer's Name changed")
    );
    const allEndorsementComments = commentExists
      ? policyJSON?.quote?.endorsementComments?.map((comment) =>
          comment?.textComment?.startsWith("Producer's Name changed")
            ? newEndorsementComments
            : comment
        )
      : concatCommentList(policyJSON, [newEndorsementComments]);
    const updatedPolicyJSON = {
      ...policyJSON,
      producers: updateProducers(
        policyJSON,
        newProducer,
        currentPrimaryProducer
      ),
      quote: {
        ...policyJSON?.quote,
        endorsementTypeList: updatedEndorsementTypeSelectedList,
        endorsementComments: allEndorsementComments,
      },
    } as PolicyBlob;

    return updatedPolicyJSON;
  }

  const policyJSONWithSpecificEndorsementTypesRemoved =
    clearEndorsementTypeList(policyJSON, EndorsementTypeEnum.PRODUCER_NAME);
  return policyJSONWithSpecificEndorsementTypesRemoved;
};

//#endregion

//#region Policy Key Fields Rule
const getPolicyJSONWithPolicyNumber = (
  policyJSON: PolicyBlob,
  newPolicyKeyFormData: PolicyKeyFieldsFormData,
  isPolicyNumberChanged: boolean
) =>
  (isPolicyNumberChanged
    ? {
        ...policyJSON,
        oldPolicyNumber: isPolicyNumberChanged ? policyJSON.policyNumber : null,
        policyNumber: newPolicyKeyFormData.policyNumber,
      }
    : { ...policyJSON }) as PolicyBlob;

const getPolicyJSONWithEffectiveDate = (
  policyJSON: PolicyBlob,
  newPolicyKeyFormData: PolicyKeyFieldsFormData,
  isEffectiveDateChanged: boolean
) =>
  (isEffectiveDateChanged
    ? {
        ...policyJSON,
        oldEffectiveDate: policyJSON.effectiveDate,
        effectiveDate: newPolicyKeyFormData.effectiveDate,
        quote: {
          ...policyJSON.quote,
          effectiveDate: newPolicyKeyFormData.effectiveDate,
          states: policyJSON.quote?.states?.map((state) => ({
            ...state,
            effectiveDate: newPolicyKeyFormData.effectiveDate,
          })),
        },
      }
    : { ...policyJSON }) as PolicyBlob;

const getPolicyJSONWithExpirationDate = (
  policyJSON: PolicyBlob,
  newPolicyKeyFormData: PolicyKeyFieldsFormData,
  isExpirationDateChanged: boolean
) =>
  (isExpirationDateChanged
    ? {
        ...policyJSON,
        oldExpirationDate: policyJSON.expirationDate,
        expirationDate: newPolicyKeyFormData.expirationDate,
        quote: {
          ...policyJSON.quote,
          expirationDate: newPolicyKeyFormData.expirationDate,
          states: policyJSON.quote?.states?.map((state) => ({
            ...state,
            expirationDate: newPolicyKeyFormData.expirationDate,
          })),
        },
      }
    : { ...policyJSON }) as PolicyBlob;

const getNewEndorsementComments = (
  commentsForPolicyNumber: EndorsementCommentBlob | null,
  commentsForEffectiveDate: EndorsementCommentBlob | null
) => {
  if (commentsForPolicyNumber !== null && commentsForEffectiveDate !== null)
    return [commentsForPolicyNumber, commentsForEffectiveDate];

  if (commentsForPolicyNumber !== null) return [commentsForPolicyNumber];

  if (commentsForEffectiveDate !== null) return [commentsForEffectiveDate];

  return [];
};

const getCommentsForPolicyKeyFields = (
  policyJSON: PolicyBlob,
  newPolicyKeyFormData: PolicyKeyFieldsFormData,
  isPolicyNumberChanged: boolean,
  isEffectiveDateChanged: boolean
) => {
  const commentsForPolicyNumber = isPolicyNumberChanged
    ? ({
        textComment: `Policy Number changed from ${policyJSON.policyNumber} to ${newPolicyKeyFormData.policyNumber}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
    : null;

  const commentsForEffectiveDate = isEffectiveDateChanged
    ? ({
        textComment: `Effective Date changed from ${FormattingDate(
          policyJSON.effectiveDate
        )} to ${FormattingDate(newPolicyKeyFormData.effectiveDate)}`,
        isUserEdited: false,
      } as EndorsementCommentBlob)
    : null;

  const newEndorsementComments = getNewEndorsementComments(
    commentsForPolicyNumber,
    commentsForEffectiveDate
  );

  return concatCommentList(policyJSON, newEndorsementComments);
};

export const endorsementEnginePolicyKeyFields = (
  policyJSON: PolicyBlob,
  newPolicyKeyFormData: PolicyKeyFieldsFormData,
  isPolicyNumberChanged: boolean,
  isEffectiveDateChanged: boolean,
  isExpirationDateChanged: boolean
) => {
  const updatedPolicyJSONWithPolicyNumber = getPolicyJSONWithPolicyNumber(
    policyJSON,
    newPolicyKeyFormData,
    isPolicyNumberChanged
  );

  const updatedPolicyJSONWithEffectiveDate = getPolicyJSONWithEffectiveDate(
    updatedPolicyJSONWithPolicyNumber,
    newPolicyKeyFormData,
    isEffectiveDateChanged
  );

  const updatedPolicyJSONWithExpirationDate = getPolicyJSONWithExpirationDate(
    updatedPolicyJSONWithEffectiveDate,
    newPolicyKeyFormData,
    isExpirationDateChanged
  );

  const newEndorsementComments = getCommentsForPolicyKeyFields(
    policyJSON,
    newPolicyKeyFormData,
    isPolicyNumberChanged,
    isEffectiveDateChanged
  );

  return {
    ...updatedPolicyJSONWithExpirationDate,
    quote: {
      ...updatedPolicyJSONWithExpirationDate.quote,
      endorsementComments: newEndorsementComments,
      endorsementTypeList: policyJSON?.quote?.endorsementTypeList?.map(
        (type) => ({
          ...type,
          isChecked:
            type.value === EndorsementTypeEnum.POLICY_NUMBER
              ? conditionHasValue(
                  updatedPolicyJSONWithExpirationDate?.oldPolicyNumber
                )
              : type.value === EndorsementTypeEnum.EFFECTIVE_DATE
              ? conditionHasValue(
                  updatedPolicyJSONWithExpirationDate?.oldEffectiveDate
                )
              : type.value === EndorsementTypeEnum.EXPIRATION_DATE
              ? conditionHasValue(
                  updatedPolicyJSONWithExpirationDate?.oldExpirationDate
                )
              : type.isChecked,
        })
      ),
    },
  } as PolicyBlob;
};
//#endregion
