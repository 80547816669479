import { FC } from "react";
import { Input, InputDate, MultiSelect, Select, Switch } from "../../TrueUI";
import { CustomFieldDataTypeEnum } from "../../../dtos/custom-field-data-type-enum";
import { CustomFieldControlTypeEnum } from "../../../dtos/custom-field-control-type-enum";
import { CustomFieldDefinitionToShowInHeaderDto } from "../../../dtos/custom-field-definition-to-show-in-header-dto";
import FontError from "../../TrueUI/Typography/FontError";
import { StringToDate } from "../../../utilities/dateFunctions";
import { CustomFieldErrorCodeEnum } from "../../../dtos/custom-field-error-code-enum";

type CustomFieldProps = {
  customField: CustomFieldDefinitionToShowInHeaderDto;
};

const CustomField: FC<CustomFieldProps> = ({ customField }) => {
  const readOnly = true;

  const getInputByDataTypeText = (
    field: CustomFieldDefinitionToShowInHeaderDto
  ) => {
    switch (field?.controlType) {
      case CustomFieldControlTypeEnum.DROPDOWN:
        return (
          <Select
            labelFontType="BOLD_CAPTION"
            labelPosition="start"
            name={field?.fieldLabel}
            id={`id-${field?.fieldLabel}`}
            label={field?.fieldLabel}
            firstOptionAsDefault={false}
            readOnly={readOnly}
            options={field?.listSourceSelectOption ?? []}
            value={field?.fieldValueString}
          />
        );

      case CustomFieldControlTypeEnum.MULTISELECT:
        const options = field?.listSourceSelectOption ?? [];
        const values = field?.fieldValueString?.split("|") ?? [];
        const selectedValues = options.filter((x) =>
          values.includes(x?.stringValue ?? "")
        );
        return (
          <MultiSelect
            labelFontType="BOLD_CAPTION"
            labelPosition="start"
            name={field?.fieldLabel}
            id={`id-${field?.fieldLabel}`}
            label={field?.fieldLabel}
            options={options}
            values={selectedValues}
            readOnly={readOnly}
          />
        );
      default:
        return (
          <Input
            labelFontType="BOLD_CAPTION"
            labelPosition="start"
            name={field?.fieldLabel}
            id={`id-${field?.fieldLabel}`}
            label={field?.fieldLabel}
            value={field?.fieldValueString ?? ""}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            maxLength={field?.dataSize}
          />
        );
    }
  };
  const getInputByDataTypeNumber = (
    field: CustomFieldDefinitionToShowInHeaderDto
  ) => {
    if (field.controlType === CustomFieldControlTypeEnum.DROPDOWN) {
      return (
        <Select
          labelFontType="BOLD_CAPTION"
          labelPosition="start"
          name={field?.fieldLabel}
          id={`id-${field?.fieldLabel}`}
          label={field?.fieldLabel}
          readOnly={readOnly}
          firstOptionAsDefault={false}
          options={field?.listSourceSelectOption ?? []}
          value={field?.fieldValueNumber?.toString()}
        />
      );
    } else {
      return (
        <Input
          labelFontType={"BOLD_CAPTION"}
          labelPosition="start"
          name={field?.fieldLabel}
          id={`id-${field?.fieldLabel}`}
          label={field?.fieldLabel}
          value={field?.fieldValueNumber ?? ""}
          readOnly={readOnly}
          type={"number"}
          allowNegatives={false}
          maxLength={field?.dataSize}
          thousandSeparator={false}
        />
      );
    }
  };
  const renderFieldComponentByType = (
    field: CustomFieldDefinitionToShowInHeaderDto
  ) => {
    switch (field?.dataType) {
      case CustomFieldDataTypeEnum.TEXT:
        return getInputByDataTypeText(field);
      case CustomFieldDataTypeEnum.NUMBER:
        return getInputByDataTypeNumber(field);
      case CustomFieldDataTypeEnum.DECIMAL:
        return (
          <Input
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            name={field?.fieldLabel}
            id={`id-${field?.fieldLabel}`}
            label={field?.fieldLabel}
            value={field?.fieldValueNumber ?? ""}
            readOnly={readOnly}
            type={"currency"}
            allowNegatives={false}
            maxLength={field.dataSize + field.dataPrecision + 1}
            decimalScale={field.dataPrecision}
            thousandSeparator={false}
            prefix={""}
          />
        );
      case CustomFieldDataTypeEnum.BOOLEAN:
        return (
          <Switch
            labelFontType={"BOLD_CAPTION"}
            labelPlacement="start"
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            control="checkbox"
            name={field.fieldLabel}
            isChecked={field?.fieldValueNumber === 1}
            readOnly={readOnly}
          />
        );
      case CustomFieldDataTypeEnum.DATE:
        return (
          <InputDate
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            value={StringToDate(field?.fieldValueString)}
            readOnly={readOnly}
          />
        );
      default:
        return <></>;
    }
  };

  return customField !== null ? (
    <div>
      {customField?.errorCode === CustomFieldErrorCodeEnum.NO_ERROR &&
        renderFieldComponentByType(customField)}
      {customField?.errorCode === CustomFieldErrorCodeEnum.MORE_THAN_ONE && (
        <FontError>ERROR</FontError>
      )}
    </div>
  ) : null;
};

export default CustomField;
