import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  FontBold,
  Input,
  Row,
  Switch,
} from "../../../../../TrueUI";
import { useApiGet } from "../../../../../../hooks";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import {
  colProps,
  ClaimCorrespondenceProps,
  CLAIM_FORMS_API_PATH,
} from "./typesAndConstants";
import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import ClaimCorrespondenceCategories from "./ClaimCorrespondenceCategories";
import ClaimCorrespondenceForms from "./ClaimCorrespondenceForms";
import { ProgramClaimFormsPage } from "../../../../../../dtos/program-claim-forms-page";
import { ProgramClaimFormDto } from "../../../../../../dtos/program-claim-form-dto";
import StyleBox from "../../../../../TrueUI/StyleBox/StyleBox";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import {
  MergedFileExtensionType,
  useFormRequest,
} from "../../../../../../hooks/useFileStorage";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { ClaimJSON } from "../../../../../../dtos/claim-json";
import { FormTypeEnum } from "../../../../../../dtos/form-type-enum";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import PrintSplitButton from "../../../../../TrueUI/Buttons/PrintSplitButton";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import style from "./ClaimCorrespondence.module.css";
import { ActivityCategoryEnums } from "../../../../../../dtos/activity-category-enums";
type ClaimPrintDocumentProps = {
  saveFile: boolean;
  fileName: string;
};

const ClaimCorrespondence: FC<ClaimCorrespondenceProps> = ({
  claimJurisdiction,
  insuredId,
  claimId,
  claimNumber,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const [printValues, setPrintValues] = useState<ClaimPrintDocumentProps>({
    saveFile: false,
    fileName: "",
  });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<ClaimReferenceDto[] | null>(
    null
  );
  const [forms, setForms] = useState<
    ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<
    ClaimReferenceDto[] | null
  >(null);
  const [mergedFileExtension, setMergedFileExtension] =
    useState<MergedFileExtensionType>("pdf");

  const {
    responseGet: responseGetCategoriesAndForms,
    dispatchGet: dispatchGetCategoriesAndForms,
  } = useApiGet<ProgramClaimFormsPage>(
    `${CLAIM_FORMS_API_PATH}/GetFormsCategoriesAndFormsByProgramAndTenant`
  );

  const { sendMergeFormRequest } = useFormRequest();

  const {
    responseGet: responseGetClaimJSON,
    dispatchGet: dispatchGetClaimJSON,
  } = useApiGet<ClaimJSON>(`api/Claims/GetClaimJSON?claimId=${claimId}`);

  useEffect(() => {
    dispatchGetCategoriesAndForms();
  }, []);

  useEffect(() => {
    if (responseGetCategoriesAndForms.requestInstanceSuccessful) {
      setCategories(
        responseGetCategoriesAndForms?.axiosResponse?.data?.categories ?? []
      );
      setSelectedCategories(
        responseGetCategoriesAndForms?.axiosResponse?.data?.categories ?? []
      );
    }
  }, [responseGetCategoriesAndForms]);

  const printForms = (claimJSON?: ClaimJSON) => {
    if (claimJSON !== undefined && claimJSON !== null) {
      const templateNames =
        getAtom?.()?.claimTabsInformation?.claimCorrespondenceTab?.selectedClaimForms?.map(
          (form) => form.templateName
        ) ?? [];

      if (templateNames.length > 0 && !templateNames.includes("")) {
        sendMergeFormRequest({
          formType: FormTypeEnum.CLAIM_FORM,
          jsonObjectWithMergeFields: claimJSON,
          templateNames: templateNames,
          customFileName: printValues.fileName,
          printOrDownload: "download",
          mergedFileExtension,
          configurationToSaveFile: {
            saveFileToInsured: printValues.saveFile,
            fileCategory: "Claim Documents",
            insuredId,
            claimId: claimId ?? -1,
            policyId: null,
            activityLogCategory: ActivityCategoryEnums.CLAIM_DOCUMENT_PRINTED,
            activityLogDescription: `The claim document ${printValues.fileName} for ${claimNumber} was generated`,
          },
        });
      }
      if (templateNames.includes("")) {
        setDialogOpen(true);
      }
    }
  };

  useEffect(() => {
    if (responseGetClaimJSON.requestInstanceSuccessful) {
      printForms(responseGetClaimJSON.axiosResponse?.data);
    }
  }, [responseGetClaimJSON]);

  const listenerClaimCorrespondenceComponent = useRecoilValue(
    TriggerComponentUpdateAtom("claimCorrespondenceComponent")
  );

  const getFileNameByFileSelected = () => {
    const selectedClaimForms =
      getAtom()?.claimTabsInformation?.claimCorrespondenceTab
        ?.selectedClaimForms;

    if (selectedClaimForms === undefined || selectedClaimForms?.length === 0)
      return "";
    else if (selectedClaimForms?.length === 1)
      return `${claimNumber ?? ""} ${selectedClaimForms[0].formName}`;
    else if (selectedClaimForms !== undefined && selectedClaimForms.length > 1)
      return `${claimNumber ?? ""} Documents`;

    return "";
  };

  useEffect(() => {
    setPrintValues({
      ...printValues,
      fileName: getFileNameByFileSelected(),
    });
  }, [listenerClaimCorrespondenceComponent]);

  const onPrintSplitButtonClick = (fileExtension: MergedFileExtensionType) => {
    setMergedFileExtension(fileExtension);
    dispatchGetClaimJSON();
  };

  const printOptions = [
    {
      key: 1,
      option: "Print PDF",
      dropdownText: "Print PDF",
      action: () => onPrintSplitButtonClick("pdf"),
    },
    {
      key: 2,
      option: "Print Word",
      dropdownText: "Print Word",
      action: () => onPrintSplitButtonClick("docx"),
    },
  ];

  return (
    <Box
      id={"claim_correspondence_tab"}
      className={style.claim_correspondence_container}
    >
      <StyleBox
        className="styled-container-claim-correspondence"
        width={"100%"}
        margin={0}
        padding={0}
        display="block"
        showBorder={true}
        height={"90%"}
      >
        <Row
          {...rowWithNoMarginNorGutter}
          verticalAlign="flex-start"
          allowWrap
          numberOfColumns={10}
          className="correspondence_columns"
          rowHeight="100%"
        >
          <Col
            {...colProps}
            displayFlex={false}
            breakpoints={{ md: 4, lg: 5, xl: 3 }}
          >
            {responseGetCategoriesAndForms?.axiosResponse?.data && (
              <ClaimCorrespondenceCategories
                setSelectedCategories={setSelectedCategories}
                categories={categories}
                selectedCategories={selectedCategories}
                allForms={
                  responseGetCategoriesAndForms?.axiosResponse?.data?.forms ??
                  []
                }
                setForms={setForms}
                insuredId={insuredId}
                tabKey={tabKey}
              />
            )}
          </Col>
          <Col
            {...colProps}
            displayFlex={false}
            breakpoints={{ md: 6, lg: 5, xl: 3 }}
            colHeight="100%!important"
            borderLeftWidth={"4px"}
            borderRightWidth={"4px"}
            borderStyle={"solid"}
            horizontalAlign={"flex-start"}
            verticalAlign={"flex-start"}
          >
            <ClaimCorrespondenceForms
              claimJurisdiction={claimJurisdiction}
              forms={forms}
              categories={selectedCategories}
              insuredId={insuredId}
              tabKey={tabKey}
            />
          </Col>
          <Col
            {...colProps}
            breakpoints={{ md: 10, lg: 10, xl: 4 }}
            displayFlex={false}
          >
            <FontBold>THIRD COLUMN</FontBold>
          </Col>
        </Row>
      </StyleBox>
      <Row {...rowWithNoMarginNorGutter} verticalGutter="10px">
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        >
          <Switch
            id="claim-correspondence-save-file"
            name="claimCorrespondenceSaveFile"
            control="checkbox"
            label="Save File"
            isChecked={printValues.saveFile}
            onChangeIsChecked={(value) =>
              setPrintValues({ ...printValues, saveFile: value })
            }
            className={style.claim_correspondence_save_file}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          breakpoints={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
        >
          <Input
            id="claim-correspondence-file-name"
            name="claimCorrespondenceFileName"
            label="File Name"
            labelPosition="start"
            value={printValues.fileName}
            onChangeRawValue={(value) =>
              setPrintValues({ ...printValues, fileName: value })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-end"
          breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        >
          <Button
            id="claim-correspondence-email"
            name="claimCorrespondenceEmailButton"
            onClick={() => alert("Future US.")}
            isDisabled
            sx={{ marginRight: "5px" }}
          >
            EMAIL
          </Button>
          <PrintSplitButton printOptions={printOptions} />
        </Col>
      </Row>
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Box>
  );
};

export default ClaimCorrespondence;
