import { CSSProperties, forwardRef } from "react";
import styles from "../Styles/basegrid.module.css";
import { safeClassModuleBuilder } from "../../../utilities/cssFunctions";

type BaseGridStructureRowProperties = {
  id?: string;
  rowKey?: string;
  children?: any;
  style?: CSSProperties;
  onClick?: (e?: any) => any;
  isHighlighted?: boolean;
  isSelected?: boolean;
};

type BaseGridStructureRowRefType = HTMLDivElement;

const BaseGridStructureRow = forwardRef<
  BaseGridStructureRowRefType,
  BaseGridStructureRowProperties
>(
  (
    {
      id,
      rowKey,
      children,
      style,

      onClick,

      isHighlighted,
      isSelected,
    },
    ref
  ) => {
    return (
      <div
        id={id}
        ref={ref}
        onClick={(e) => {
          onClick?.(e);
        }}
        className={`${styles.true_base_grid_row} ${safeClassModuleBuilder([
          {
            moduleClass: styles.true_base_grid_row_highlight,
            condition: isHighlighted ?? false,
          },
          {
            moduleClass: styles.true_base_grid_row_select,
            condition: isSelected ?? false,
          },
        ])}`}
        style={style}
        data-row-uiid={rowKey}
      >
        {children}
      </div>
    );
  }
);
export default BaseGridStructureRow;
