import { atom } from "recoil";
import { AgencyTitleInformationDto } from "../../dtos/agency-title-information-dto";
import { AgencyLocationModalProps } from "./AgencyLocation/AgencyLocation";

const InformationTitleAtom = atom<AgencyTitleInformationDto | null>({
  key: "informationTitleSection",
  default: null,
});

const AgencyLocationModalAtom = atom<AgencyLocationModalProps | null>({
  key: "agencyLocationModalAtom",
  default: {
    agencyLocationId: -1,
    isOpen: false,
    isSaving: false,
    isDeleting: false,
    isNew: false,
    refreshTable: false,
  },
});

const RefreshAgencyInformationPageAtom = atom<boolean>({
  key: "RefreshAgencyInformationPage",
  default: false,
});

const AgencyLocationToFilterContactsAtom = atom<string | null>({
  key: "AgencyLocationToFilterContactsAtom",
  default: null,
});

export {
  InformationTitleAtom,
  AgencyLocationModalAtom,
  RefreshAgencyInformationPageAtom,
  AgencyLocationToFilterContactsAtom,
};
