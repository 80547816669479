import { FC } from "react";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  FormattingDateAndTime,
  getUniversalTime,
  isDateAfterOrEqualDate,
} from "../../../../utilities/dateFunctions";
import { Button, Font, FontBold, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { NoteHeaderProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import style from "./UnderwriterNotes.module.css";
import { usePermissions } from "../../../../hooks";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";

const NoteHeader: FC<NoteHeaderProps> = ({ tabKey, note, editEvent }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const policyQuote = getAtom()?.policyQuoteInformation?.policyQuote;
  const isInvalidAudit = isDateAfterOrEqualDate(
    getUniversalTime(),
    policyQuote?.expirationDate
  );
  const { hasPermission } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
    PermissionsEnums.AUDIT_VIEW,
  ]);
  const updateDetails = conditionHasValue(note.updateOn)
    ? `updated: ${FormattingDateAndTime(note.updateOn)} ${note.updateBy}`
    : "";

  return (
    <Row {...rowWithNoMarginNorGutter}>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <FontBold primaryColor>{note.narrativeDescription ?? ""}</FontBold>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-end">
        <Font whiteSpace="pre-wrap">{updateDetails}</Font>
        {hasPermission && !isInvalidAudit && (
          <Button
            stopClickPropagation
            className={style.underwriter_note_edit_button}
            onClick={() => {
              editEvent?.(note);
            }}
          >
            Edit
          </Button>
        )}
      </Row>
    </Row>
  );
};

export default NoteHeader;
