import { FC, useEffect, useState } from "react";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { SelectOptions } from "../../../dtos/select-options";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import ModalPortalUser from "./ModalPortalUser";
import { ModalInfoProps } from "./PortalUsersConst";
import ModalVerifyUser from "./AddNewPortalUser/ModalVerifyUser";
import ModalNewPortalUser from "./AddNewPortalUser/ModalNewPortalUser";

type InsuredUsersWrapperProps = {
  insuredId: number;
  insuredName: string;
};

const statusOptions: Partial<SelectOptions>[] = [
  {
    displayName: "All",
    stringValue: "all",
  },
  {
    displayName: "Active only",
    stringValue: "active",
  },
  {
    displayName: "Inactive",
    stringValue: "inactive",
  },
];
const tableName = "insured_users_table";

const InsuredUsersWrapper: FC<InsuredUsersWrapperProps> = ({
  insuredId,
  insuredName,
}) => {
  const [statusFilterSelected, setStatusFilterSelected] =
    useState<string>("active");
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    insuredName: insuredName,
    insuredId: insuredId,
  });

  useEffect(() => {
    if (modalInfo?.refreshUsersTable == true) {
      setModalInfo({ ...modalInfo, refreshUsersTable: false });
      mountGrid();
    }
  }, [modalInfo?.refreshUsersTable]);

  const tableIncidentsConfiguration = () => {
    return {
      name: tableName,
      getURL: `api/Insured/GetPortalUsersByInsuredId?insuredId=${insuredId}`,
      columnOptions: [
        { fieldName: "UserId", width: 7 },
        { fieldName: "UserName", width: 25 },
        { fieldName: "ShortName", width: 13 },
        { fieldName: "AdminPermission", width: 8 },
        { fieldName: "BillingPermission", width: 8 },
        { fieldName: "ClaimsPermission", width: 8 },
        { fieldName: "PolicyPermission", width: 8 },
        { fieldName: "LossControlPermission", width: 10 },
        { fieldName: "LastLogon", width: 13 },
        { fieldName: "UserStatus", width: 7 },
      ],
      filterOptions: [
        (options) =>
          BaseGridDropDownFilter({
            options,
            id: "insured-user-table-status-filter",
            optionsOverride: statusOptions,
            labelText: "Show:",
            columnNames: ["UserStatus"],
            defaultValue: statusFilterSelected,
            onChange(value) {
              mountGrid();
              setStatusFilterSelected(value);
            },
          }),
      ],
      orderDirection: "desc",
      orderByColumnIndex: 2,
      toolbarOptions: {
        showEditButton: false,
        showSaveButton: false,
        showSortFilter: false,
        showImportButton: false,
        showAddButton: true,
        addButtonText: "Invite new User",
      },
      advancedOptions: {
        optionsColumnConfiguration: {
          isHidden: false,
          optionType: "edit_override",
          onClick: (actionOptions) => {
            mountGrid();
            setModalInfo({
              ...modalInfo,
              userId: actionOptions?.row?.UserId,
              isModalOpen: true,
            });
          },
        },
      },
      events: {
        addEventOverrideCallback: () => {
          setModalInfo({ ...modalInfo, isVerifyUserModalOpen: true });
        },
      },
    } as BaseGridProperties;
  };

  const { mountGrid, unmountGrid } = useBaseGrid(tableIncidentsConfiguration());

  return (
    <>
      <BaseGrid name={tableName} />
      <ModalPortalUser modalInfo={modalInfo} setModalInfo={setModalInfo} />
      <ModalVerifyUser modalInfo={modalInfo} setModalInfo={setModalInfo} />
      <ModalNewPortalUser
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        unmountGrid={() => unmountGrid()}
      />
    </>
  );
};

export default InsuredUsersWrapper;
