import { FC } from "react";
import ExposureTable from "./ExposureTable/ExposureTable";
import PremiumTable from "./PremiumTable/PremiumTable";

type PolicyCalculatorProps = {
  tabKey: string;
  insuredId: number;
  quoteStateId?: number | null;
  stateCode: string;
  stateName: string;
  effectiveDate: Date;
  expirationDate: Date;
  policyEffectiveDate: Date;
  isEndorsementOrAudit?: boolean;
  readonly?: boolean;
};

const PolicyCalculator: FC<PolicyCalculatorProps> = ({
  tabKey,
  insuredId,
  quoteStateId,
  stateCode,
  stateName,
  effectiveDate,
  expirationDate,
  policyEffectiveDate,
  isEndorsementOrAudit,
  readonly,
}) => {
  return (
    <div
      id={`policy_calculator_container_${insuredId}_${stateCode}_${effectiveDate}_${expirationDate}`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <ExposureTable
        tabKey={tabKey}
        insuredId={insuredId}
        stateCode={stateCode}
        effectiveDate={effectiveDate}
        expirationDate={expirationDate}
        policyEffectiveDate={policyEffectiveDate}
        isEndorsementOrAudit={isEndorsementOrAudit}
        readonly={readonly}
      />
      <PremiumTable
        tabKey={tabKey}
        insuredId={insuredId}
        quoteStateId={quoteStateId}
        stateCode={stateCode}
        stateName={stateName}
        effectiveDate={effectiveDate}
        expirationDate={expirationDate}
        policyEffectiveDate={policyEffectiveDate}
        readOnly={readonly}
      />
    </div>
  );
};

export default PolicyCalculator;
