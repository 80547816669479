import { FC } from "react";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { Input } from "../../../TrueUI";
import { TableData } from "../../../TrueUI/Tables/BaseTableStructure";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import "./PaymentSchedule.module.css";

type MultiTableChildrenComponentProps = {
  detailData: any;
  scheduleTable: PolicyPaymentScheduleMultiTablePage;
};

const MultiTableChildrenComponent: FC<MultiTableChildrenComponentProps> = ({
  detailData,
  scheduleTable,
}) => {
  const foundChildrenById = scheduleTable?.rowChildren?.find(
    (child) => child.id.toString() === detailData.Id
  );
  return (
    <TableContainer className="payment_schedule_multi_table_children">
      <Table>
        <TableBody>
          {foundChildrenById?.rowData.map((row: string[], index: number) => (
            <TableRow
              key={`internal-row-payment-schedule-multi-table-${index}`}
              sx={{ display: "flex", width: "100%" }}
            >
              <TableData width="9%"></TableData>
              <TableData width="31%">
                <Input
                  id="id-state"
                  name="name-state"
                  value={row[0]}
                  readOnly
                />
              </TableData>
              <TableData width="20%">
                <Input
                  id="id-fees"
                  name="name-fees"
                  value={row[1]}
                  align="right"
                  readOnly
                />
              </TableData>
              <TableData width="20%">
                <Input
                  id="id-premium"
                  name="name-premium"
                  value={row[2]}
                  align="right"
                  readOnly
                />
              </TableData>
              <TableData width="20%">
                <Input
                  id="id-total"
                  name="name-total"
                  value={row[3]}
                  align="right"
                  readOnly
                />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MultiTableChildrenComponent;
