import { TaskDto } from "../../../../dtos/task-dto";
import { PrioritiesEnum } from "../../../../dtos/priorities-enum";
import { ContextEnums } from "../../../../dtos/context-enums";

export const getSaveButtonItems = (saveTaskWithStatus) => [
  {
    key: 1,
    option: "Save as Active",
    dropdownText: "Save Only",
    action: () => saveTaskWithStatus("Active"),
  },
  {
    key: 2,
    option: "Save as Complete",
    dropdownText: "Save as Complete",
    action: () => saveTaskWithStatus("Complete"),
  },
  {
    key: 3,
    option: "Save as Cancelled",
    dropdownText: "Cancel Task",
    action: () => saveTaskWithStatus("Cancelled"),
  },
  {
    key: 4,
    option: "Save as Deleted",
    dropdownText: "Deleted Task",
    action: () => saveTaskWithStatus("Deleted"),
  },
];

export const getTaskInBlank = (
  insuredId: number,
  taskStatus: number,
  currentUserId: number,
  contextType: number,
  adjusterId: number,
  claimNumber?: string
): TaskDto => {
  return {
    taskId: 0,
    insuredId: insuredId,
    claimNumber,
    claimId: 0,
    ownerUserId: currentUserId,
    assignedUserId:
      contextType === ContextEnums.CLAIMS ? adjusterId : currentUserId,
    taskStatus,
    subject: "",
    priority: PrioritiesEnum.MEDIUM,
    estTime: null,
    actualTime: null,
    comments: "",
  };
};
