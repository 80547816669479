import { TabStripTab } from "@progress/kendo-react-layout";
import { FC, ReactNode } from "react";

type TabPanelProperties = {
  title: string | ReactNode;
  children?: any;
  name?: string;
  disabled?: boolean;
};

const TabPanel: FC<TabPanelProperties> = ({
  title,
  children,
  name,
  disabled = false,
}) => {
  return (
    <TabStripTab
      title={title}
      disabled={disabled}
      true-element={`true-element-tab-element-${name}`}
    >
      {children}
    </TabStripTab>
  );
};

export default TabPanel;
