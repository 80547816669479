import { PolicyBlob } from "../../../../dtos/policy-blob";
import { ProgramPolicyEndorsementBlob } from "../../../../dtos/program-policy-endorsement-blob";
import { ProgramPolicyEndorsementDocPage } from "../../../../dtos/program-policy-endorsement-doc-page";
import { groupArrayByKey } from "../../../../utilities/arrayFunctions";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";

export const ERROR_TEMPLATE_NAME = "_ERROR_TEMPLATE_NO_FOUND_";

const isValidEndorsementType = (
  form: ProgramPolicyEndorsementBlob,
  endorsementTypeSelected: (string | null | undefined)[]
) =>
  form?.endorsementType === "All" ||
  endorsementTypeSelected?.some((endorsement) =>
    endorsement?.includes(form?.endorsementType ?? "")
  );

const isValidEndorsementDocument = (
  form: ProgramPolicyEndorsementBlob,
  currentDocIds: number[],
  endorsementTypeSelected: (string | null | undefined)[]
) =>
  isValidEndorsementType(form, endorsementTypeSelected) &&
  !currentDocIds.includes(form?.programDocId ?? 0);

export const getEndorsementFormsFromResponse = (
  responseData: ProgramPolicyEndorsementDocPage,
  policyQuote?: PolicyBlob | null
) => {
  const policyQuoteStatesList = policyQuote?.quote?.stateList?.split(",") ?? [];
  const evaluatedForms =
    responseData?.programEndorsementList
      ?.map((form) => {
        if (
          form.state === "99" ||
          policyQuoteStatesList.includes(form.stateCode ?? "")
        ) {
          return { ...form };
        } else {
          return {
            required: -1,
            addType: { value: -1, description: "", isChecked: false },
          };
        }
      })
      .filter((form) => form.required !== -1) ?? [];

  const endorsementForms = policyQuote?.endorsementForms;
  if (!isEmptyValue(endorsementForms)) {
    endorsementForms?.forEach((form) => {
      var foundIndex = evaluatedForms.findIndex(
        (f) => f.endorsementFormID === form.endorsementFormID
      );
      evaluatedForms[foundIndex] = form;
    });
  }

  const rightForms = groupArrayByKey(evaluatedForms, "stateCode");

  const endorsementTypeSelected =
    policyQuote?.quote?.endorsementTypeList
      ?.filter((doc) => doc.isChecked)
      ?.map((doc) => doc.description) ?? [];

  let currentDocIds: number[] = [];
  const leftForms = groupArrayByKey(
    responseData?.programPolicyEndorsementDocList?.filter((form) => {
      if (
        isValidEndorsementDocument(form, currentDocIds, endorsementTypeSelected)
      ) {
        currentDocIds.push(form?.programDocId ?? 0);
        return true;
      }
      return false;
    }) ?? [],
    "endorsementType"
  );

  return { leftForms, rightForms };
};

export const getFilteredAndSortedPacketDocuments = (
  packetDocuments?: ProgramPacketDocumentDto[],
  selectedPackets?: number[]
) =>
  packetDocuments
    ?.filter((document) => selectedPackets?.includes(document.packetID))
    .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)) ?? [];

export const getFilteredPolicyDocuments = (
  policyDocuments?: ProgramPolicyDocumentDto[],
  filteredDocuments?: ProgramPacketDocumentDto[]
) =>
  policyDocuments?.filter(
    (document) =>
      !filteredDocuments
        ?.map((doc) => doc.programPolicyDocument.programDocId)
        .includes(document.programDocId)
  ) ?? [];

export const getAllTemplateNamesToPrint = (
  selectedDocuments?: ProgramEndorsementBlob[],
  endorsementDocuments?: ProgramPolicyEndorsementBlob[]
) => {
  const templateNamesBySelectedDocument = selectedDocuments?.map(
    (document) => document.templateName ?? ERROR_TEMPLATE_NAME
  );

  const flattedEndorsementDocs = Object.values(
    endorsementDocuments as any
  ).flat() as ProgramPolicyEndorsementBlob[];

  const templateNamesByEndorsementDocument = flattedEndorsementDocs?.map(
    (document) => document.templateName ?? ERROR_TEMPLATE_NAME
  );

  const allTemplateNames =
    templateNamesByEndorsementDocument?.concat(
      templateNamesBySelectedDocument ?? []
    ) ?? [];

  const allTemplateNameFiltered = allTemplateNames.filter(
    (templateName) => templateName !== ""
  );

  const hasInvalidTemplateNames =
    allTemplateNameFiltered.includes(ERROR_TEMPLATE_NAME);

  return {
    hasInvalidTemplateNames,
    allTemplateNames: allTemplateNameFiltered.filter(
      (x) => x !== ERROR_TEMPLATE_NAME
    ),
  };
};

export const getDefaultFileName = (
  insuredName: string,
  selectedDocuments?: ProgramEndorsementBlob[]
) => {
  if (selectedDocuments?.length === 1) {
    return `${insuredName} ${selectedDocuments[0].formName}`;
  }
  return `${insuredName} Policy Documents`;
};

export const getAtomUpdatedByEndorsementDocumentChecked = (
  atomValue: GlobalInsuredAtomProperties | null,
  selectedRightForms?: ProgramEndorsementBlob[]
) => {
  const policyJSON = getPolicyQuote(atomValue);
  const updatedPolicyJSON = {
    ...policyJSON,
    endorsementForms: selectedRightForms,
  } as PolicyBlob;
  const newAtomValue = updatePolicyQuoteInformation(
    atomValue,
    "policyQuote",
    updatedPolicyJSON
  );
  return newAtomValue;
};
