import TextCell2 from "../GridCells/TextCell2";
import InputTaxCell2 from "../GridCells/InputTaxCell2";
import TextWithIconCell2 from "../GridCells/TextWithIconCell2";
import CheckBoxCell2 from "../GridCells/CheckBoxCell2";
import DatePickerCell2 from "../GridCells/DatePickerCell2";
import SelectCell2 from "../GridCells/SelectCell2";
import TagsCell2 from "../GridCells/TagsCell2";
import LinkCell2 from "../GridCells/LinkCell2";
import DownloadLinkCell2 from "../GridCells/DownloadLinkCell2";
import ScoreChipCell2 from "../GridCells/ScoreChipCell2";
import MultiSelectCell from "../GridCells/MultiSelectCell";
import IndicationCell from "../GridCells/IndicationCell";
import AutocompleteCell2 from "../GridCells/AutocompleteCell2";
import ConditionalRadioButtonCell from "../GridCells/ConditionalRadioButtonCell";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import PhoneCell2 from "../GridCells/PhoneCell2";
import OptionsCell2 from "../GridCells/OptionsCell2";
import {
  ChangedSingleGridCellInstanceCellKeysAtom,
  GridInstanceAtomFamily,
  SingleGridCellInstanceAtomFamily,
} from "../BaseGridAtoms";
import { useRecoilCallback } from "recoil";
import {
  castValueToType,
  getColumnByColumnIndex,
  getExternalDataOnly,
  hydrateAndCastDataSingleExternalColumnsOnly,
} from "../SupportFunctions/baseGridFunctions";
import {
  BaseGridInternalProperties,
  BaseGridProperties,
} from "../BaseGridProperties";

const BaseGridDynamicCells = (managedCell: any) => {
  const updateChangedCellKeys = useRecoilCallback(
    ({ snapshot, set }) =>
      (cell: {
        rowKey: string;
        value: string;
        cellKey: string;
        columnIndex: number;
      }) => {
        const instance = snapshot.getLoadable(
          GridInstanceAtomFamily(managedCell.uiid ?? "NO_UIID_FOUND")
        ).contents as {
          BaseGridProperties: BaseGridProperties;
          BaseGridInternalProperties: BaseGridInternalProperties;
        };

        // Here we triger the `onCellChange`event. BaseGridCellManagerUpdateProcessor will likely be deprecated in the future.
        const foundColumnOption =
          instance?.BaseGridProperties?.columnOptions?.find(
            (co) => co.fieldName === managedCell.column.fieldName
          ) ?? null;

        const foundColumn = getColumnByColumnIndex(
          managedCell.column._columnIndex ?? -1,
          instance?.BaseGridProperties?.columns ?? []
        );

        if (foundColumnOption !== undefined && foundColumnOption !== null) {
          foundColumnOption.onCellChange?.({
            columnName: managedCell.column.fieldName ?? "NO_COLUMN_NAME_FOUND",
            columnIndex: managedCell.column._columnIndex ?? -1,
            value: castValueToType(foundColumn, cell.value ?? "") ?? null,
            stringValue: cell.value ?? null,
            row: getExternalDataOnly(
              instance?.BaseGridProperties?.columns ?? [],
              managedCell?.row ?? []
            ),
            hydratedRow: hydrateAndCastDataSingleExternalColumnsOnly(
              instance?.BaseGridProperties?.columns ?? [],
              managedCell.row ?? []
            ),
          });
        }

        // We update the actual cell instance here

        set(SingleGridCellInstanceAtomFamily(cell.cellKey), cell);

        // We update the cellkey map here
        const cellKeys = snapshot.getLoadable(
          ChangedSingleGridCellInstanceCellKeysAtom
        ).contents as string[];

        if (cellKeys.includes(cell.cellKey)) {
          set(ChangedSingleGridCellInstanceCellKeysAtom, cellKeys);
        } else {
          const updatedCellKeys = [...cellKeys, cell.cellKey];
          set(ChangedSingleGridCellInstanceCellKeysAtom, updatedCellKeys);
        }
      }
  );

  const getApplicableList = (managedCell: any) => {
    if (
      managedCell.listDisplayOptions !== null &&
      (managedCell?.listDisplayOptions?.length ?? 0) > 0
    ) {
      return managedCell.listDisplayOptions;
    }

    return managedCell.column?.displayOptions;
  };

  const onChange = (e: any) => {
    updateChangedCellKeys({
      rowKey: managedCell.rowKey,
      value: e?.value ?? "",
      cellKey: managedCell.cellKey,
      columnIndex: managedCell.column._columnIndex,
    });
  };

  const mergedManagedCellProperties = {
    ...managedCell,
    onChange: onChange,
    onBlur: onChange,
  };

  switch (mergedManagedCellProperties.column.type) {
    case BaseTableInputType.TEXT:
      return (
        <TextCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"text"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
          refreshCell={mergedManagedCellProperties.refreshCellManager}
          inputFontType={
            mergedManagedCellProperties.isBold ? "BOLD_BODY" : "BODY"
          }
        />
      );
    case BaseTableInputType.NUMBER:
      return (
        <TextCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"number"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
          extraProps={{
            maxNumericValue: managedCell?.columnProps?.maxNumericValue ?? 0,
            minNumericValue: managedCell?.columnProps?.maxNumericValue ?? 0,
            decimalScale: 0,
            prefix: "",
            thousandSeparator:
              managedCell?.columnProps?.thousandSeparator ?? false,
          }}
          refreshCell={mergedManagedCellProperties.refreshCellManager}
        />
      );
    case BaseTableInputType.SSN:
      return (
        <InputTaxCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          isEdit={true}
        />
      );
    // TODO - caused some strange issues
    case BaseTableInputType.PHONE:
      return (
        <PhoneCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
        />
      );
    case BaseTableInputType.PHONE_AND_EXTENSION:
      return (
        <PhoneCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          phoneExt
        />
      );
    case BaseTableInputType.EMAIL:
      return (
        <TextCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"email"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
        />
      );
    case BaseTableInputType.CURRENCY:
      return (
        <TextCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"currency"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
          extraProps={{
            maxNumericValue:
              managedCell?.columnProps?.maxNumericValue ??
              Number.MAX_SAFE_INTEGER,
            minNumericValue:
              managedCell?.columnProps?.minNumericValue ??
              Number.MIN_SAFE_INTEGER,
            decimalScale: managedCell?.columnProps?.decimalScale ?? null,
            prefix: managedCell?.columnProps?.prefix ?? "",
            thousandSeparator:
              managedCell?.columnProps?.thousandSeparator ?? true,
            allowNegatives: managedCell?.columnProps?.allowNegatives ?? false,
          }}
          refreshCell={mergedManagedCellProperties.refreshCellManager}
          inputFontType={
            mergedManagedCellProperties.isBold ? "BOLD_BODY" : "BODY"
          }
        />
      );
    case BaseTableInputType.INPUT_WITH_ICON:
      return (
        <TextWithIconCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"currency"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
          extraProps={{
            maxNumericValue:
              mergedManagedCellProperties.columnProps.maxNumericValue,
            minNumericValue:
              mergedManagedCellProperties.columnProps.minNumericValue,
            decimalScale: mergedManagedCellProperties.columnProps.decimalScale,
            prefix: mergedManagedCellProperties.columnProps.prefix ?? "",
            thousandSeparator:
              mergedManagedCellProperties.columnProps.thousandSeparator,
            allowNegatives:
              mergedManagedCellProperties.columnProps.allowNegatives ?? false,
          }}
          refreshCell={mergedManagedCellProperties.refreshCellManager}
          inputFontType={
            mergedManagedCellProperties.isBold ? "BOLD_BODY" : "BODY"
          }
        />
      );
    case BaseTableInputType.DECIMAL:
      return (
        <TextCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          type={"currency"}
          maxLength={
            managedCell?.column?.maxLength ??
            managedCell?.columnProps?.maxLength ??
            250
          }
          align={managedCell?.columnProps?.align ?? "left"}
          extraProps={{
            maxNumericValue:
              mergedManagedCellProperties.columnProps.maxNumericValue,
            minNumericValue:
              mergedManagedCellProperties.columnProps.minNumericValue,
            decimalScale: mergedManagedCellProperties.columnProps.decimalScale,
            prefix: mergedManagedCellProperties.columnProps.prefix ?? "",
            thousandSeparator:
              mergedManagedCellProperties.columnProps.thousandSeparator,
          }}
          refreshCell={mergedManagedCellProperties.refreshCellManager}
        />
      );
    case BaseTableInputType.CHECKBOX:
      return (
        <CheckBoxCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          isEdit={true}
        />
      );
    case BaseTableInputType.READONLY_CHECKBOX:
      return (
        <CheckBoxCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          isDisabled
        />
      );
    case BaseTableInputType.DATE_PICKER:
      return (
        <DatePickerCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
        />
      );
    case BaseTableInputType.SELECT:
      if (mergedManagedCellProperties.isEdit) {
      }
      return (
        <SelectCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          dataOptions={getApplicableList(managedCell)}
          selectMaxHeight={managedCell?.columnProps?.selectMaxHeight ?? "100%"}
        />
      );
    case BaseTableInputType.READONLY_SELECT:
      return (
        <SelectCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
          dataOptions={getApplicableList(managedCell)}
          selectMaxHeight={managedCell?.columnProps?.selectMaxHeight ?? "100%"}
          isDisabled
        />
      );
    case BaseTableInputType.TAGS:
      return (
        <TagsCell2
          {...(mergedManagedCellProperties as any)}
          dataOptions={mergedManagedCellProperties.column?.displayOptions ?? []}
          isEdit={true}
        />
      );
    case BaseTableInputType.LINK:
      return (
        <LinkCell2
          {...(mergedManagedCellProperties as any)}
          alternateDisplayValues={
            mergedManagedCellProperties.column?.alternateDisplayValues ?? []
          }
        />
      );
    case BaseTableInputType.DOWNLOAD_LINK:
      return (
        <DownloadLinkCell2
          {...(mergedManagedCellProperties as any)}
          alternateDisplayValues={
            mergedManagedCellProperties.column?.alternateDisplayValues ?? []
          }
        />
      );
    case BaseTableInputType.CHIPS:
      return (
        <ScoreChipCell2
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
        />
      );
    case BaseTableInputType.MULTI_SELECTION:
      return (
        <MultiSelectCell
          rowKey={mergedManagedCellProperties.rowKey}
          uiid={mergedManagedCellProperties.uiid}
        />
      );
    case BaseTableInputType.INDICATION:
      return (
        <IndicationCell
          {...(mergedManagedCellProperties as any)}
          uiid={mergedManagedCellProperties.uiid}
          row={mergedManagedCellProperties.row}
          value={mergedManagedCellProperties.value ?? "none"}
        />
      );
    case BaseTableInputType.OPTIONS:
      return (
        <OptionsCell2
          uiid={mergedManagedCellProperties.uiid}
          {...(mergedManagedCellProperties as any)}
          optionType={
            mergedManagedCellProperties.advancedOptions
              ?.optionsColumnConfiguration?.optionType ?? "none"
          }
          contextMenuOptionRenderingCondition={null}
          optionsContextActions={null}
          rowIndex={mergedManagedCellProperties.rowIndex}
        />
      );
    case BaseTableInputType.AUTOCOMPLETE:
      return (
        <AutocompleteCell2
          {...(mergedManagedCellProperties as any)}
          dataOptions={getApplicableList(managedCell)}
        />
      );
    case BaseTableInputType.CONDITIONAL_RADIO_BUTTON:
      return (
        <ConditionalRadioButtonCell
          {...(mergedManagedCellProperties as any)}
          value={mergedManagedCellProperties.value}
        />
      );
    default:
      return <div>ERROR - NO CELL FOUND</div>;
  }
};

export default BaseGridDynamicCells;
