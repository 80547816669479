import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { WagesContainerDto } from "../../../../../../dtos/wages-container-dto";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { Collapse } from "../../../../../TrueUI";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import Link from "../../../../../TrueUI/Typography/Link";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import ClaimWagesContainerCollapsed from "./ClaimWagesContainerCollapsed";
import ClaimWagesContainerExpanded from "./ClaimWagesContainerExpanded";

type ClaimWagesContainerProps = {
  configuration?: DetailsConfigurationDto;
  claimWagesData?: WagesContainerDto;
  errorDetails?: any;
  insuredIdAtomKey?: string;
  hasClaimsManagementPermissions: boolean;
};

const ClaimWagesContainer: FC<ClaimWagesContainerProps> = ({
  claimWagesData,
  configuration,
  errorDetails,
  insuredIdAtomKey = "",
  hasClaimsManagementPermissions,
}) => {
  const [wagesUI, setWagesUI] = useState(claimWagesData ?? null);

  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const readOnly = !wagesUI?.isExpanded ?? false;

  const setWageData = (updatedWagesObject: any) => {
    const atomValue = getAtom();
    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "wagesContainerData",
      updatedWagesObject
    );
    setAtom(newAtomValue);
    setWagesUI(updatedWagesObject);
  };

  useEffect(() => {
    setWagesUI(claimWagesData ?? null);
  }, [claimWagesData]);

  const listenerWagesComponent = useRecoilValue(
    TriggerComponentUpdateAtom("wagesComponent")
  );

  useEffect(() => {
    if (listenerWagesComponent !== null) {
      const updatedAtom = getAtom();
      setWagesUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.wagesContainerData,
      });
    }
  }, [listenerWagesComponent]);

  useEffect(() => {
    if (conditionHasValue(errorDetails))
      setWageData({
        ...wagesUI,
        isExpanded: true,
      });
  }, [errorDetails]);

  return (
    <Collapse
      id="claim-details-wages-collapse"
      collapsedHeight={"225px"}
      hasContentOnReadOnly
      title={
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"Wages"}
          name="wagesCollapseTitleLink"
        ></Link>
      }
      content={
        readOnly ? (
          // Read Only Component
          <ClaimWagesContainerCollapsed
            wagesUI={wagesUI}
            readOnly={readOnly}
            configuration={configuration}
          />
        ) : (
          // Edit Mode Component
          <ClaimWagesContainerExpanded
            wagesUI={wagesUI}
            setWagesUI={setWagesUI}
            errorDetails={errorDetails}
            insuredIdAtomKey={insuredIdAtomKey}
            configuration={configuration}
            hasClaimsManagementPermissions={hasClaimsManagementPermissions}
          />
        )
      }
      expanded={!readOnly}
      onChange={(value) => {
        setWageData({
          ...wagesUI,
          isExpanded: value,
        });
        setComponentTriggers(["buttonComponent"]);
      }}
    />
  );
};

export default ClaimWagesContainer;
