import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PolicyInsuredContactBlob } from "../../../../dtos/policy-insured-contact-blob";
import { getDataIndexByColumnName } from "../../../TrueUI/Tables/tableFunctions";
import {
  phoneMask,
  splitPhoneNumberAndExtension,
} from "../../../../utilities/stringFunctions";
import { PolicyInsuredOfficerBlob } from "../../../../dtos/policy-insured-officer-blob";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";

export const getContactsByTableData = (
  data: string[][],
  columns: BaseTableColumn[]
): PolicyInsuredContactBlob[] => {
  const hydratedKeyIndex = getDataIndexByColumnName(columns, "rowKey");
  const dehydratedKeyIndex = getDataIndexByColumnName(columns, "_row_key");
  const rowKeyIndex =
    hydratedKeyIndex === -1 ? dehydratedKeyIndex : hydratedKeyIndex;
  const insuredIndex = getDataIndexByColumnName(columns, "InsuredId");
  const tenantIndex = getDataIndexByColumnName(columns, "TenantId");
  const contactIndex = getDataIndexByColumnName(columns, "ContactId");
  const insuredContactIndex = getDataIndexByColumnName(
    columns,
    "InsuredContactId"
  );
  const lastIndex = getDataIndexByColumnName(columns, "ContactLastName");
  const firstIndex = getDataIndexByColumnName(columns, "ContactFirstName");
  const titleIndex = getDataIndexByColumnName(columns, "ContactTitle");
  const emailIndex = getDataIndexByColumnName(columns, "Email");
  const phoneIndex = getDataIndexByColumnName(columns, "PrimaryPhone");
  const statusIndex = getDataIndexByColumnName(columns, "ContactStatus");
  const primaryIndex = getDataIndexByColumnName(columns, "PrimaryContact");
  const tagIndex = getDataIndexByColumnName(columns, "ContactTag");

  const newContactValues = data.map(
    (row) =>
      ({
        baseTableRowKey: row[rowKeyIndex],
        insuredId:
          row[insuredIndex] === "" ? null : parseInt(row[insuredIndex]),
        tenantId: row[tenantIndex] === "" ? null : parseInt(row[tenantIndex]),
        contactId:
          row[contactIndex] === "" ? null : parseInt(row[contactIndex]),
        insuredContactId:
          row[insuredContactIndex] === ""
            ? null
            : parseInt(row[insuredContactIndex]),
        contactLastName: row[lastIndex] === "" ? null : row[lastIndex],
        contactFirstName: row[firstIndex] === "" ? null : row[firstIndex],
        contactTitle: row[titleIndex] === "" ? null : row[titleIndex],
        primaryContact: row[primaryIndex] === "true" ? true : false,
        primaryPhone:
          row[phoneIndex] === ""
            ? null
            : phoneMask(
                splitPhoneNumberAndExtension(row[phoneIndex])?.[1] ?? ""
              ),
        primaryPhoneNoFormat:
          row[phoneIndex] === ""
            ? null
            : splitPhoneNumberAndExtension(row[phoneIndex])?.[1],
        primaryPhoneAdd:
          row[phoneIndex] === ""
            ? null
            : splitPhoneNumberAndExtension(row[phoneIndex])?.[2],
        email: row[emailIndex] === "" ? null : row[emailIndex],
        contactStatus:
          row[statusIndex] === "" ? null : parseInt(row[statusIndex]),
        contactTag: row[tagIndex] === "" ? null : row[tagIndex],
      } as PolicyInsuredContactBlob)
  );

  return newContactValues;
};

export const getContactsAsTableData = (
  contacts?: PolicyInsuredContactBlob[] | null
) => {
  return contacts
    ?.filter((contact) => contact.insuredContactStatus !== StatusEnums.DELETED)
    .map((contact) => [
      contact?.insuredId?.toString() ?? "",
      contact?.tenantId?.toString() ?? "",
      contact?.contactId?.toString() ?? "",
      contact?.insuredContactId?.toString() ?? "",
      contact?.contactLastName ?? "",
      contact?.contactFirstName ?? "",
      contact?.contactTitle ?? "",
      contact?.email ?? "",
      `${contact?.primaryPhone ?? ""}${contact?.primaryPhoneAdd ?? ""}`,
      "",
      contact?.contactStatus?.toString() ?? "",
      contact?.primaryContact ? "true" : "false",
      contact?.contactTag ?? "",
    ]);
};

export const getOfficersByTableData = (
  data: string[][],
  columns: BaseTableColumn[]
): PolicyInsuredOfficerBlob[] => {
  const hydratedKeyIndex = getDataIndexByColumnName(columns, "rowKey");
  const dehydratedKeyIndex = getDataIndexByColumnName(columns, "_row_key");
  const rowKeyIndex =
    hydratedKeyIndex === -1 ? dehydratedKeyIndex : hydratedKeyIndex;
  const officerIndex = getDataIndexByColumnName(columns, "OfficerId");
  const insuredIndex = getDataIndexByColumnName(columns, "InsuredId");
  const typeIndex = getDataIndexByColumnName(columns, "OfficerType");
  const nameIndex = getDataIndexByColumnName(columns, "OfficerName");
  const titleIndex = getDataIndexByColumnName(columns, "OfficerTitle");
  const ownIndex = getDataIndexByColumnName(columns, "OwnershipPct");
  const classCodeIndex = getDataIndexByColumnName(columns, "ClassCode");
  const inclExclIndex = getDataIndexByColumnName(columns, "OfficerInclExcl");
  const effectiveIndex = getDataIndexByColumnName(columns, "EffectiveDate");
  const expirationIndex = getDataIndexByColumnName(columns, "ExpirationDate");
  const statusIndex = getDataIndexByColumnName(columns, "OfficerStatus");

  const newOfficerValues = data.map(
    (row) =>
      ({
        baseTableRowKey: row[rowKeyIndex],
        officerID:
          row[officerIndex] === "" ? null : parseInt(row[officerIndex]),
        insuredId:
          row[insuredIndex] === "" ? null : parseInt(row[insuredIndex]),
        officerType: row[typeIndex] === "" ? null : row[typeIndex],
        officerName: row[nameIndex] === "" ? null : row[nameIndex],
        officerTitle: row[titleIndex] === "" ? null : row[titleIndex],
        ownershipPercent:
          row[ownIndex] === "" ? null : parseFloat(row[ownIndex]),
        classCode: row[classCodeIndex] === "" ? null : row[classCodeIndex],
        inclExcl: row[inclExclIndex] === "" ? null : row[inclExclIndex],
        effectiveDate:
          row[effectiveIndex] === "" ? null : new Date(row[effectiveIndex]),
        expirationDate:
          row[expirationIndex] === "" ? null : new Date(row[expirationIndex]),
        officerStatus: row[statusIndex] === "" ? null : row[statusIndex],
      } as PolicyInsuredOfficerBlob)
  );

  return newOfficerValues;
};

export const getOfficersAsTableData = (
  officers?: PolicyInsuredOfficerBlob[] | null
) => {
  return officers
    ?.filter((officer) => officer.insuredOfficerStatus !== StatusEnums.DELETED)
    ?.map((officer) => [
      "",
      officer?.officerID?.toString() ?? "",
      officer?.insuredId?.toString() ?? "",
      officer?.officerType ?? "",
      officer?.officerName ?? "",
      officer?.officerTitle ?? "",
      officer?.ownershipPercent?.toString() ?? "",
      officer?.classCode ?? "",
      officer?.inclExcl ?? "",
      FormattingDate(officer?.effectiveDate) ?? "",
      FormattingDate(officer?.expirationDate) ?? "",
      officer?.officerStatus?.toString() ?? "",
    ]);
};
