import { Theme } from "../../../media/themeTypes";

export const columnStyles = (
  theme: Theme,
  colHeight,
  borderLeftWidth,
  borderRightWidth,
  borderStyle
) => ({
  "&.MuiGrid-root.MuiGrid-item.true_col_grid_wrapper": {
    height: colHeight,
    borderLeftStyle: borderStyle ? borderStyle : "none",
    borderRightStyle: borderStyle ? borderStyle : "none",
    borderLeftColor: theme?.BORDER_COLOR,
    borderRightColor: theme?.BORDER_COLOR,
    borderLeftWidth: borderLeftWidth ? borderLeftWidth : 0,
    borderRightWidth: borderRightWidth ? borderRightWidth : 0,
  },
});
