import { FC } from "react";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureProperties = {
  children?: any;
};

const BaseGridStructure: FC<BaseGridStructureProperties> = ({ children }) => {
  return <div className={styles.true_base_grid}>{children}</div>;
};

export default BaseGridStructure;
