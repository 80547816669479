import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { CancellationStatusEnum } from "../../../dtos/cancellation-status-enum";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { useApiGet } from "../../../hooks";
import { CancellationTabConfigurationDto } from "../../../dtos/cancellation-tab-configuration-dto";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import CancelReasonFilter from "./CancelReasonFilter";

const CancellationTable: FC = () => {
  const [configurationState, setConfigurationState] =
    useState<CancellationTabConfigurationDto | null>(null);
  const [includeReason, setIncludeReason] = useState<string | null>(null);

  const getConfigQueryString = () => {
    if (includeReason === null) return "";
    if (includeReason.includes("-")) {
      const excludeCode = includeReason.replace("-", "");
      return `?excludeCode=${excludeCode}`;
    }
    return `?reasonId=${includeReason}`;
  };

  const configUrl = `api/Cancellation/GetCancellationTabConfiguration`;

  const { responseGet, dispatchGet } =
    useApiGet<CancellationTabConfigurationDto>(configUrl);

  const name = "cancellation_listing";
  const getURL = `api/Cancellation/GetCancellationListing${getConfigQueryString()}`;

  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  const actionsForRows = () => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() => alert("Waiting for instructions.")}
      >
        <OpenIcon sx={{ color: theme?.TEXT_COLOR }} />
      </IconButton>
    );
  };

  const statusOptions: Partial<SelectOptions>[] = FromEnumToArrayWithExceptions(
    CancellationStatusEnum,
    [CancellationStatusEnum.DELETED]
  ).map((type) => {
    return {
      displayName: type?.key,
      intValue: type?.value,
    };
  });

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status",
          filterOptions: actionOptions,
          optionsOverride: statusOptions,
          columnsNames: ["CancellationStatus"],
          labelText: "Status:",
          defaultValue: CancellationStatusEnum.PENDING,
        }),
      (actionOptions) =>
        CancelReasonFilter({
          actionOptions,
          configurationState,
          onChange: (value) => setIncludeReason(value),
        }),
    ],
    columnOptions: [
      { fieldName: "PolicyPeriod", width: 7 },
      { fieldName: "InsuredName", width: 35 },
      { fieldName: "Type", width: 10 },
      { fieldName: "Reason", width: 25 },
      { fieldName: "NotifyDate", width: 10 },
      { fieldName: "CancelDate", width: 10 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div id={"cancellation_listing_container"}>
      {configurationState !== null && <BaseTable2 name={name} />}
    </div>
  );
};

export default CancellationTable;
