import { FC, useEffect } from "react";
import {
  addQueryStrings,
  getQueryValue,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  INSURED_ATOM_KEY,
  PAYROLL_REPORT_DETAILS,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import PayrollReportDetail from "./PayrollReportDetail";

type PayrollReportWrapperProps = {
  insuredId: number;
  tabKey: string;
};

const PayrollReportWrapper: FC<PayrollReportWrapperProps> = ({
  insuredId,
  tabKey,
}) => {
  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );

  const getSectionId = () => {
    if (getQueryValue(PAYROLL_REPORT_DETAILS) !== "") {
      return parseInt(getQueryValue(PAYROLL_REPORT_DETAILS));
    }
    if (getAtom()?.billingInformation?.payrollSection) {
      return getAtom()?.billingInformation?.payrollSection ?? 1;
    }
    return 1;
  };

  const GetSection = () => {
    switch (getSectionId()) {
      case 1:
        return <PayrollReportDetail insuredId={insuredId} tabKey={tabKey} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const sectionId = getSectionId();
    addQueryStrings([
      { nameOfHash: PAYROLL_REPORT_DETAILS, valueOfHash: sectionId },
    ]);
    setAtom({
      ...getAtom(),
      insuredBodySection:
        SubSideNavItemDestinationsEnum.BILLING_PAYROLL_REPORT_DETAILS,
      billingInformation: {
        ...getAtom()?.billingInformation,
        payrollSection: sectionId,
      },
    });
  }, []);
  return GetSection();
};

export default PayrollReportWrapper;
