export function safeClassModuleBuilder(
  modulesList: {
    moduleClass: string;
    condition: boolean | null;
  }[]
) {
  if (modulesList.length === 0) {
    return "";
  }
  const result = modulesList
    .map((classAndCondition) => {
      if (classAndCondition.condition === null) {
        return classAndCondition.moduleClass;
      }
      if (classAndCondition.condition === true) {
        return classAndCondition.moduleClass;
      } else {
        return "";
      }
    })
    .join(" ");
  return result ?? "";
}

export function getColorFromAppVariables(cssVar: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
}
