import { useCallback, useEffect, useState } from "react";
import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { useSetRecoilState } from "recoil";
import {
  globalApplicationAlerts,
  globalIsDisabledElementAtom,
  globalIsLoading,
  globalIsSaving,
} from "../GlobalAtoms";
import { unstable_batchedUpdates } from "react-dom";
import axios from "axios";
// import {
//   responseDataReader,
//   useDynamicUpdateApplier,
// } from "./usePropertyUpdater";
import { ErrorResponse } from "../dtos/error-response";
import { isStillValid } from "../utilities/jwtFunctions";
import { useSecurity } from ".";
import {
  removeSystemLocalStorage,
  TRUE_ID_STORAGE,
} from "../utilities/localStorageFunctions";

export const API_URL_DESTINATION: any = process.env.REACT_APP_API_URL;
const controller = new AbortController();
const useApiAbort = () => controller.abort();

type GlobalInstanceRequestConfigurationOptions = {
  disableGlobalLoadingTrigger?: boolean;
  disablePropertyUpdaters?: boolean;
  isSecurityPostRequest?: boolean;
};

export type InstanceRequestConfigurationOptions<T = any> = {
  isLoading?: boolean;
  url?: string;
  urlOverride?: string | null | undefined;
  method?: Method;
  content?: any;
  config?: GlobalInstanceRequestConfigurationOptions | null;
  axiosResponse?: AxiosResponse<T> | null;
  responseData?: T | null;
  errorResponse: ErrorResponse | null;
  requestInstanceSuccessful?: boolean;
  hasAddedDataUpdaters?: boolean;
  metaData?: any;
};

// TODO - Future Development, add intellisense support

// type Generate<T> = {
//     [K in keyof T & string as T[K] extends Function ? `${K}Updater` : never]: () => void;
// } & T;

// type LetsGo<T> = {
//     [key in keyof typeof T]?: string
// }

// type InstanceResponseOptions<T> = {
//     data?: T | any;
//     response?: any;
//     isLoading?: boolean;
//     error?: any;
// };

function useRequestInstance<T = any | ErrorResponse>(
  url: string,
  method: Method,
  content?: any,
  config?: GlobalInstanceRequestConfigurationOptions | null
): {
  instanceConfiguration: InstanceRequestConfigurationOptions<T>;
  dispatchRequest: (urlRequestOverride?: string) => void;
  errorResponse: ErrorResponse | null;
} {
  // global state
  const setApplicationAlert = useSetRecoilState(globalApplicationAlerts);
  const setGlobalIsLoading = useSetRecoilState<boolean>(globalIsLoading);
  const setGlobalIsSaving = useSetRecoilState<boolean>(globalIsSaving);
  const setGlobalDisableElements = useSetRecoilState<boolean>(
    globalIsDisabledElementAtom
  );
  const { logout } = useSecurity();

  const [instanceConfiguration, setInstanceConfiguration] = useState<
    InstanceRequestConfigurationOptions<T>
  >({
    isLoading: false,
    method: method,
    url: url,
    urlOverride: null,
    content: content ?? null,
    config: config ?? null,
    axiosResponse: null,
    responseData: null,
    errorResponse: null,
    requestInstanceSuccessful: false,
    hasAddedDataUpdaters: false,
    metaData: null,
  });

  if (
    !isStillValid(localStorage.getItem(TRUE_ID_STORAGE) ?? "") &&
    localStorage.getItem(TRUE_ID_STORAGE) !== null
  ) {
    removeSystemLocalStorage();
    logout();
  }

  const token = `Bearer ${localStorage.getItem(TRUE_ID_STORAGE)}` ?? null; // if not token, eject out - not sure what oz has setup

  const headers: any = {
    authorization: token,
    "Content-Type": "application/json",
  };

  const axiosConfig: AxiosRequestConfig<T> = {
    url: API_URL_DESTINATION + url,
    method: method ?? "post",
    data: content ?? null,
    headers: headers,
    timeout: 30000,
    signal: controller.signal,
    // withCredentials: true
  };

  const dispatchRequest = useCallback(
    (urlRequestOverride?: string) => {
      // https://github.com/facebook/react/issues/16377 - flagged as safe to use by the React team.
      // will be updated when we update to react 18
      unstable_batchedUpdates(() => {
        setInstanceConfiguration({
          ...instanceConfiguration,
          responseData: null,
          urlOverride:
            urlRequestOverride === undefined
              ? axiosConfig.url ?? "NO_URL_FOUND"
              : API_URL_DESTINATION + urlRequestOverride,

          isLoading: true,
        });
        setGlobalIsLoading(true);
        setGlobalIsSaving(method === "post" || method === "put");
        setGlobalDisableElements(method === "post" || method === "put");
      });
    },
    [url]
  );

  const globalSetsFalse = useCallback(() => {
    // https://github.com/facebook/react/issues/16377 - flagged as safe to use by the React team.
    // will be updated when we update to react 18
    unstable_batchedUpdates(() => {
      setGlobalIsLoading(false);
      setGlobalIsSaving(false);
      setGlobalDisableElements(false);
    });
  }, []);

  // const [dynamicDataTrigger, setSourceObject] = useDynamicUpdateApplier();

  const initRequest = useCallback(
    async (urlRequestOverride?: string | null | undefined) => {
      try {
        const updatedAxiosConfig = {
          ...axiosConfig,
          url:
            urlRequestOverride !== undefined && urlRequestOverride !== null
              ? urlRequestOverride
              : axiosConfig.url ?? "NO_URL_FOUND",
        };

        axios.interceptors.request.use((config: any) => {
          config.metaData = { startTime: new Date() };
          return config;
        });
        axios.interceptors.response.use((response: any) => {
          response.config.metaData.endTime = new Date();
          response.config.metaData.duration =
            (response.config.metaData.endTime -
              response.config.metaData.startTime) /
            1000;
          return response;
        });
        const resp = await axios(updatedAxiosConfig);

        if (resp.status === 200) {
          setInstanceConfiguration({
            ...instanceConfiguration,
            axiosResponse: resp ?? null,
            responseData: resp?.data ?? null,
            errorResponse: null,
            isLoading: false,
            requestInstanceSuccessful: true,
            metaData: (resp?.config as any)?.metaData ?? null,
          });
          globalSetsFalse();
        }
      } catch (err: any) {
        if (err.response.status === 401) {
          setApplicationAlert(err.response.data);
        }

        setInstanceConfiguration({
          ...instanceConfiguration,
          axiosResponse: null,
          responseData: null,
          errorResponse: err?.response?.data ?? null,
          isLoading: false,
          requestInstanceSuccessful: false,
        });

        globalSetsFalse();
      }
    },
    [url, content]
  );

  useEffect(() => {
    if (!instanceConfiguration.isLoading) return;
    initRequest(instanceConfiguration?.urlOverride ?? null);
  }, [instanceConfiguration.isLoading]);

  return {
    instanceConfiguration,
    dispatchRequest,
    errorResponse: instanceConfiguration.errorResponse,
  };
}

function useApiAuthenticate(
  url: string,
  body?: any
): [number | null, () => void] {
  const uri = API_URL_DESTINATION + url;
  const [status, setStatus] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const axiosConfig: AxiosRequestConfig = {
    url: uri,
    method: "post",
    data: body,
    timeout: 5000,
  };
  const dispatchRequest = useCallback(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!isLoading) return;
    const initRequest = async () => {
      try {
        const resp = await axios(axiosConfig);
        setStatus(resp.status ?? null);
        localStorage.setItem(TRUE_ID_STORAGE, resp.data ?? null);
      } catch (err: any) {
        console.error(err);
      }
      setIsLoading(false);
    };

    initRequest();
  }, [isLoading]);

  return [status, dispatchRequest];
}

function useApiGet<T>(
  url: string,
  config?: GlobalInstanceRequestConfigurationOptions
): {
  responseGet: InstanceRequestConfigurationOptions<T>;
  dispatchGet: (urlRequestOverride?: string) => void;
  validatorErrorResponse?: ErrorResponse | null;
} {
  const { instanceConfiguration, dispatchRequest, errorResponse } =
    useRequestInstance<T>(url, "get", null, config);
  return {
    responseGet: instanceConfiguration,
    dispatchGet: dispatchRequest,
    validatorErrorResponse: errorResponse,
  };
}

function useApiPost<T>(
  url: string,
  body?: any,
  config?: GlobalInstanceRequestConfigurationOptions
): {
  responsePost: InstanceRequestConfigurationOptions<T>;
  dispatchPost: () => void;
  validatorErrorResponse?: ErrorResponse | null;
} {
  const { instanceConfiguration, dispatchRequest, errorResponse } =
    useRequestInstance<T>(url, "post", body, config);
  return {
    responsePost: instanceConfiguration,
    dispatchPost: dispatchRequest,
    validatorErrorResponse: errorResponse,
  };
}

function useApiPut<T>(
  url: string,
  body: any,
  config?: GlobalInstanceRequestConfigurationOptions
): {
  responsePut: InstanceRequestConfigurationOptions<T>;
  dispatchPut: () => void;
  validatorErrorResponse?: ErrorResponse | null;
} {
  // Untested as of 3/8/2022
  const { instanceConfiguration, dispatchRequest, errorResponse } =
    useRequestInstance<T>(url, "put", body, config);
  return {
    responsePut: instanceConfiguration,
    dispatchPut: dispatchRequest,
    validatorErrorResponse: errorResponse,
  };
}

function useApiDelete<T>(
  url: string,
  body: any,
  config?: GlobalInstanceRequestConfigurationOptions
): {
  responseDelete: InstanceRequestConfigurationOptions<T>;
  dispatchDelete: () => void;
  validatorErrorResponse?: ErrorResponse | null;
} {
  // Untested as of 3/8/2022
  const { instanceConfiguration, dispatchRequest, errorResponse } =
    useRequestInstance<T>(url, "delete", body, config);
  return {
    responseDelete: instanceConfiguration,
    dispatchDelete: dispatchRequest,
    validatorErrorResponse: errorResponse,
  };
}

export {
  useApiGet,
  useApiPost,
  useApiPut,
  useApiDelete,
  useApiAbort,
  useApiAuthenticate,
};
