import { FC } from "react";
import { CommonProps, ItemProps } from "./HierarchyList";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./HierarchyList.module.css";
import Switch from "../../Checkbox/Switch";
import Font from "../../Typography/Font";

type RegularItemProps = ItemProps &
  CommonProps & {
    isChild?: boolean;
  };

const RegularItem: FC<RegularItemProps> = ({
  displayName,
  isChild,
  isCheckboxList,
  onCheckboxChange,
  itemId,
  fontType,
  isChecked,
  isDisabled,
  description,
  showTooltip,
}) => {
  return (
    <div className={"regular_item_container"}>
      <div className={"item_title"}>
        {isChild ? <></> : <ArrowRightIcon />}
        {isCheckboxList ? (
          <Switch
            control={"checkbox"}
            inputWidth="auto"
            isDisabled={isDisabled}
            className="checkbox_item_list"
            onChangeIsChecked={(value) => onCheckboxChange?.(itemId, value)}
            isChecked={isChecked}
          />
        ) : null}
        <Font
          fontType={fontType}
          showTooltip={showTooltip}
          tooltipTitle={description}
          tooltipPosition="right"
          truncate
        >
          {displayName}
        </Font>
      </div>
    </div>
  );
};
export default RegularItem;
