import { FC } from "react";

type TableWrapperProperties = {
  children?: any;
  fullWidth?: boolean;
};

const TableWrapper: FC<TableWrapperProperties> = ({ children }) => {
  return <div className={`true_table_wrapper`}>{children}</div>;
};

export default TableWrapper;
