import { Acord130ConfigurationDto } from "../../../../dtos/acord130-configuration-dto";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { FormattingDate } from "../../../../utilities/dateFunctions";

const tableNameContacts = "insured_information_contacts_table";

const columnsContacts = (contactsConfiguration?: Acord130ConfigurationDto) => [
  {
    displayName: "InsuredId",
    fieldName: "InsuredId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "TenantId",
    fieldName: "TenantId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "ContactId",
    fieldName: "ContactId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "InsuredContactId",
    fieldName: "InsuredContactId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Last Name",
    fieldName: "ContactLastName",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    maxLength: 50,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "First Name",
    fieldName: "ContactFirstName",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    maxLength: 50,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Title",
    fieldName: "ContactTitle",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    maxLength: 50,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Email Address",
    fieldName: "Email",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    maxLength: 100,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Phone",
    fieldName: "PrimaryPhone",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.PHONE_AND_EXTENSION,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Fax",
    fieldName: "Fax",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Status",
    fieldName: "ContactStatus",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.SELECT,
    displayOptions: contactsConfiguration?.contactStatus,
    defaultValue: "1",
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Primary",
    fieldName: "PrimaryContact",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.CHECKBOX,
    defaultValueType: BaseTableInputType.SELECT,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Tags",
    fieldName: "ContactTag",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TAGS,
    displayOptions: contactsConfiguration?.contactTags,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
];

const tableNameOfficers = "insured_information_officers_table";

const columnsOfficers = (
  officersOptions: BaseTableDisplayOptions[],
  effectiveDate?: Date | null
) => [
  {
    displayName: "ContactId",
    fieldName: "ContactId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "OfficerId",
    fieldName: "OfficerId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "InsuredId",
    fieldName: "InsuredId",
    cellVariant: BaseTableInputType.READONLY,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Type",
    fieldName: "OfficerType",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.SELECT,
    displayOptions: officersOptions,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Name",
    fieldName: "OfficerName",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Title",
    fieldName: "OfficerTitle",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Ownership %",
    fieldName: "OwnershipPct",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.DECIMAL,
    defaultValueType: BaseTableInputType.CURRENCY,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Class Code",
    fieldName: "ClassCode",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Incl/Excl",
    fieldName: "OfficerInclExcl",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.SELECT,
    displayOptions: [
      {
        text: "Include",
        value: "Include",
      },
      {
        text: "Exclude",
        value: "Exclude",
      },
    ],
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Effective Date",
    fieldName: "EffectiveDate",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.DATE_PICKER,
    defaultValueType: BaseTableInputType.AUTOCOMPLETE,
    defaultValue: FormattingDate(effectiveDate),
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Expiration Date",
    fieldName: "ExpirationDate",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.DATE_PICKER,
    defaultValueType: BaseTableInputType.AUTOCOMPLETE,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
  {
    displayName: "Status",
    fieldName: "OfficerStatus",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.SELECT,
    displayOptions: [
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "2",
      },
    ],
    defaultValue: "1",
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: false,
    isFilterable: false,
    isSortable: true,
  },
];

const tableNameNamesAndAddresses =
  "insured_information_names_and_addresses_table";

const columnsNamesAndAddresses = [
  {
    displayName: "AddressId",
    fieldName: "AddressId",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "NameId",
    fieldName: "NameId",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Number",
    fieldName: "Number",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Type",
    fieldName: "LocationType",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: [
      {
        text: "None",
        value: null,
      },
      {
        text: "Physical",
        value: "Physical",
      },
      {
        text: "Location",
        value: "Location",
      },
      {
        text: "Billing",
        value: "Billing",
      },
      {
        text: "Primary",
        value: "Primary",
      },
    ],
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Name",
    fieldName: "LocationName",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Address",
    fieldName: "LocationAddress",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Address",
    fieldName: "CompleteAddress",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.TEXT,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "City",
    fieldName: "LocationCity",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "State",
    fieldName: "LocationState",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.READONLY,
    displayOptions: [
      {
        text: "AL",
        value: "AL",
      },
      {
        text: "AK",
        value: "AK",
      },
      {
        text: "AS",
        value: "AS",
      },
      {
        text: "AZ",
        value: "AZ",
      },
      {
        text: "AR",
        value: "AR",
      },
      {
        text: "CA",
        value: "CA",
      },
      {
        text: "CO",
        value: "CO",
      },
      {
        text: "CT",
        value: "CT",
      },
      {
        text: "DE",
        value: "DE",
      },
      {
        text: "DC",
        value: "DC",
      },
      {
        text: "FM",
        value: "FM",
      },
      {
        text: "FL",
        value: "FL",
      },
      {
        text: "GA",
        value: "GA",
      },
      {
        text: "GU",
        value: "GU",
      },
      {
        text: "HI",
        value: "HI",
      },
      {
        text: "ID",
        value: "ID",
      },
      {
        text: "IL",
        value: "IL",
      },
      {
        text: "IN",
        value: "IN",
      },
      {
        text: "IA",
        value: "IA",
      },
      {
        text: "KS",
        value: "KS",
      },
      {
        text: "KY",
        value: "KY",
      },
      {
        text: "LA",
        value: "LA",
      },
      {
        text: "ME",
        value: "ME",
      },
      {
        text: "MH",
        value: "MH",
      },
      {
        text: "MD",
        value: "MD",
      },
      {
        text: "MA",
        value: "MA",
      },
      {
        text: "MI",
        value: "MI",
      },
      {
        text: "MN",
        value: "MN",
      },
      {
        text: "MS",
        value: "MS",
      },
      {
        text: "MO",
        value: "MO",
      },
      {
        text: "MT",
        value: "MT",
      },
      {
        text: "NE",
        value: "NE",
      },
      {
        text: "NV",
        value: "NV",
      },
      {
        text: "NH",
        value: "NH",
      },
      {
        text: "NJ",
        value: "NJ",
      },
      {
        text: "NM",
        value: "NM",
      },
      {
        text: "NY",
        value: "NY",
      },
      {
        text: "NC",
        value: "NC",
      },
      {
        text: "ND",
        value: "ND",
      },
      {
        text: "MP",
        value: "MP",
      },
      {
        text: "OH",
        value: "OH",
      },
      {
        text: "OK",
        value: "OK",
      },
      {
        text: "OR",
        value: "OR",
      },
      {
        text: "PW",
        value: "PW",
      },
      {
        text: "PA",
        value: "PA",
      },
      {
        text: "PR",
        value: "PR",
      },
      {
        text: "RI",
        value: "RI",
      },
      {
        text: "SC",
        value: "SC",
      },
      {
        text: "SD",
        value: "SD",
      },
      {
        text: "TN",
        value: "TN",
      },
      {
        text: "TX",
        value: "TX",
      },
      {
        text: "UT",
        value: "UT",
      },
      {
        text: "VT",
        value: "VT",
      },
      {
        text: "VI",
        value: "VI",
      },
      {
        text: "VA",
        value: "VA",
      },
      {
        text: "WA",
        value: "WA",
      },
      {
        text: "WV",
        value: "WV",
      },
      {
        text: "WI",
        value: "WI",
      },
      {
        text: "WY",
        value: "WY",
      },
    ],
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Zip",
    fieldName: "LocationZip",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Status",
    fieldName: "LocationStatus",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.READONLY,
    displayOptions: [
      {
        text: "None",
        value: null,
      },
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "2",
      },
      {
        text: "Delete",
        value: "3",
      },
      {
        text: "Future",
        value: "4",
      },
    ],
    defaultValue: "ACTIVE",
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Eff Date",
    fieldName: "LocationEffDate",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.DATE_PICKER,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.AUTOCOMPLETE,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Exp Date",
    fieldName: "LocationExpDate",
    cellVariant: BaseTableInputType.READONLY,
    type: BaseTableInputType.DATE_PICKER,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.AUTOCOMPLETE,
    isHidden: null,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Phone",
    fieldName: "Phone",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "Fax",
    fieldName: "Fax",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "StoreNumber",
    fieldName: "StoreNumber",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "FullTimeEE",
    fieldName: "FullTimeEE",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "PartTimeEE",
    fieldName: "PartTimeEE",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.TAGS,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
  {
    displayName: "LocationAddress2",
    fieldName: "LocationAddress2",
    cellVariant: BaseTableInputType.READONLY,
    type: null,
    displayOptions: null,
    defaultValue: null,
    defaultValueType: BaseTableInputType.NUMBER,
    isHidden: true,
    maxLength: null,
    isFilterable: false,
    isSortable: true,
    alternateDisplayValues: null,
  },
] as any;

export {
  tableNameContacts,
  columnsContacts,
  tableNameOfficers,
  columnsOfficers,
  tableNameNamesAndAddresses,
  columnsNamesAndAddresses,
};
