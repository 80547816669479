import { FC, useState, useEffect } from "react";
import { DiaryConfigurationDto } from "../../../../dtos/diary-configuration-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useApiPost } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { RichEditor } from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { ContextProps } from "../InsuredDrawerConstants";
import DiaryHeader from "./DiaryHeader";
import { ContextEnums } from "../../../../dtos/context-enums";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import { removeASCIIFromText } from "../../../../utilities/stringFunctions";

type AddNoteProps = {
  context: ContextProps;
  diaryConfiguration: DiaryConfigurationDto;
  setRefreshConversation: (refresh: boolean) => void;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

const AddNote: FC<AddNoteProps> = ({
  diaryConfiguration,
  context,
  setRefreshConversation,
  expanded,
  setExpanded,
}) => {
  const typeOptions = diaryConfiguration?.diaryTypeOptions;
  const defaultTypeOption =
    context.type === ContextEnums.CLAIMS
      ? typeOptions.find((category) => category?.displayName === "Claims")
          ?.intValue
      : typeOptions.find((category) => category?.displayName === "General")
          ?.intValue;

  const defaultDiaryNote = {
    insuredId: context?.insuredId,
    claimNumber: context?.claimNumber,
    content: "",
    type: defaultTypeOption,
    status: StatusEnums.ACTIVE,
  };

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${context?.tabKey}`;
  const { setComponentTriggers, getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const prevDiaryNote = getAtom()?.drawerInformation?.currentNoteInformation;
  const initialNote =
    prevDiaryNote?.noteInformation != null
      ? {
          ...prevDiaryNote?.noteInformation,
          insuredId: context?.insuredId,
          claimNumber: context?.claimNumber,
        }
      : null;

  const [diaryNote, setDiaryNote] = useState<DiaryNoteDto | null>(
    initialNote ?? defaultDiaryNote
  );
  const [headerRefresh, setHeaderRefresh] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/DiaryNote/SaveDiaryNote",
    diaryNote
  );

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      const atomValue = getAtom();
      setDiaryNote(defaultDiaryNote);
      setRefreshConversation(true);
      setExpanded(false);
      setComponentTriggers(["activityLogComponent"]);
      setHeaderRefresh(true);
      setAtom({
        ...atomValue,
        drawerInformation: {
          ...atomValue?.drawerInformation,
          currentNoteInformation: null,
        },
      });
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  const onCancelEvent = () => {
    const atomValue = getAtom();
    setDiaryNote(defaultDiaryNote);
    setErrorDetails(null);
    setExpanded(false);
    setHeaderRefresh(true);
    setAtom({
      ...atomValue,
      drawerInformation: {
        ...atomValue?.drawerInformation,
        currentNoteInformation: null,
      },
    });
  };

  useEffect(() => {
    if (headerRefresh) {
      setHeaderRefresh(false);
    }
  }, [headerRefresh]);

  useEffect(() => {
    const atomInfo = getAtom();
    setAtom({
      ...atomInfo,
      drawerInformation: {
        ...atomInfo?.drawerInformation,
        currentNoteInformation: {
          ...atomInfo?.drawerInformation?.currentNoteInformation,
          noteInformation: diaryNote,
        },
      },
    });
  }, [diaryNote]);

  useEffect(() => {
    setHeaderRefresh(expanded);
  }, [expanded]);

  return (
    <>
      <RichEditor
        value={diaryNote?.content ?? ""}
        expanded={expanded}
        title="ADD NOTE"
        setExpanded={setExpanded}
        errorMessage={errorDetails?.content}
        headerComponent={
          <DiaryHeader
            context={context}
            diaryNote={diaryNote}
            headerRefresh={headerRefresh}
            setDairyNote={setDiaryNote}
            diaryConfiguration={diaryConfiguration}
            errorMessages={errorDetails}
            insuredIdAtomKey={insuredIdAtomKey}
          />
        }
        onChangeRawValue={(value) => {
          setDiaryNote({ ...diaryNote, content: removeASCIIFromText(value) });
        }}
        saveEvent={dispatchPost}
        cancelEvent={() =>
          diaryNote?.content != ""
            ? setIsDialogConfirmationOpen(true)
            : onCancelEvent()
        }
      />

      <DialogConfirmation
        id="CancelAddNoteConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={
          "Are you sure you want to cancel before save the note?"
        }
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setIsDialogConfirmationOpen(close);
          onCancelEvent();
        }}
      />
    </>
  );
};

export default AddNote;
