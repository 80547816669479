import { FC, useEffect } from "react";
import { Snapshot, useRecoilCallback, useRecoilState } from "recoil";
import {
  globalHashChangeTriggers,
  globalRequestingHashTriggerAtom,
  globalUnsavedChanges,
  hasLocationChanged,
} from "./GlobalAtoms";
import { useLocation } from "react-router";
import {
  ParameterTrackerProperties,
  getParamKeyList,
  urlHashQueryBuilder,
} from "./utilities/urlProcessingFunctions";

const AppLocationListener: FC<any> = () => {
  const [_hasLocationChanged, _setHasLocationChanged] =
    useRecoilState(hasLocationChanged);

  const [, setUnsavedChanges] = useRecoilState(globalUnsavedChanges);

  const [requestingHashTriggers, _setRequestingHashTriggers] = useRecoilState(
    globalRequestingHashTriggerAtom
  );

  const location = useLocation();

  const hasValueChanged = (newValue: any, oldValue: any) => {
    return newValue === oldValue ? false : true;
  };

  const updateHashTriggerAtomFamily = useRecoilCallback(
    ({ snapshot, set }) =>
      (
        kvp: ParameterTrackerProperties,
        hashEvent: Event | null,
        source: "url_hash" | "hook" | null
      ) => {
        // gets the given instance for a single url hash parameter
        const instance = snapshot.getLoadable(
          globalHashChangeTriggers(kvp?.parameterName ?? "NO_NAME_FOUND")
        ).contents as ParameterTrackerProperties;

        // for performance reasons, we check to see if the new incoming value has changed
        if (hasValueChanged(kvp.value, instance.value)) {
          // if it's changed, we update the instance with the new value and set it
          const updatedInstance = {
            ...instance,
            ...kvp,
            event: hashEvent ?? null,
            source: instance?.source === null ? source : instance?.source,
          };

          set(
            globalHashChangeTriggers(kvp?.parameterName ?? "NO_NAME_FOUND"),
            updatedInstance
          );
        }
      }
  );

  const listOfHashChangeTriggersAtomStateInstances = (
    hashTriggersToUpdate: string[],
    recoilSnapshot: Snapshot
  ): ParameterTrackerProperties[] => {
    return hashTriggersToUpdate.map((triggerName) => {
      return recoilSnapshot.getLoadable(
        globalHashChangeTriggers(triggerName ?? new Error("NO_NAME_FOUND"))
      ).contents as ParameterTrackerProperties;
    });
  };

  const setURLHash = (urlHashResult: string) => {
    if (window.location.hash !== urlHashResult) {
      window.location.hash = urlHashResult;
    }
  };

  const finalHashParamsKeyValuePair = (
    hashParamsFromURL: ParameterTrackerProperties[],
    listOfHashStatesWithAppendURL: ParameterTrackerProperties[]
  ): { name: string; value: any }[] => {
    const mergedParams = hashParamsFromURL.concat(
      listOfHashStatesWithAppendURL
    );

    const unqiueParams = [
      ...new Map(
        mergedParams.map((item) => [item["parameterName"], item])
      ).values(),
    ].filter((p) => p.forceDetachFromURL !== true);

    const kvpResult = unqiueParams.map((p) => ({
      name: p.parameterName ?? "NO_NAME_FOUND",
      value: p.value,
    }));

    return kvpResult;
  };

  const updateURLHashValues = useRecoilCallback(
    ({ snapshot, set }) =>
      (hashTriggersToUpdate: string[]) => {
        // gets all the has parameter key value pairs from url hash
        const hashParamsFromURL = getParamKeyList(window.location.hash);

        // gets the atom states of any registered hash parameters
        const listOfHashStates = listOfHashChangeTriggersAtomStateInstances(
          hashTriggersToUpdate,
          snapshot
        );

        // filters which has parameters we want to force on to the url hash
        const listOfHashStatesWithAppendURL = listOfHashStates.filter(
          (h) => h.forceAppendToURL
        );

        // creates a key value pair out of the atom state object
        const filteredHashParams = finalHashParamsKeyValuePair(
          hashParamsFromURL,
          listOfHashStatesWithAppendURL
        );

        // takes the key value pair and builds a hash query chain
        const constructedURLHash = urlHashQueryBuilder(filteredHashParams);

        // applies the hash query chain to the url
        setURLHash(constructedURLHash);

        // reset the globalRequestingHashTriggerAtom since we've worked all the requests
        set(globalRequestingHashTriggerAtom, []);
      },
    []
  );

  const updateHashTriggerListOnChange = (
    event: any,
    source: "url_hash" | "hook" | null
  ) => {
    // gets all the has parameter key value pairs from url hash
    const hashParamList = getParamKeyList(window.location.hash);

    // we loop through each key value pair to and update any found atom state to notify the useURLHashParameter hooks
    hashParamList.forEach((kvp) => {
      updateHashTriggerAtomFamily(kvp, event, source);
    });
  };

  const hashChangeListenerEvent = () => {
    window.addEventListener("hashchange", (e: Event) => {
      updateHashTriggerListOnChange(e, "url_hash");
    });
  };

  const requestingHashTriggerChangeListener = () => {
    if (requestingHashTriggers.length > 0) {
      updateURLHashValues(requestingHashTriggers);
    }
  };

  const fullURLChange = () => {
    if (location.pathname !== _hasLocationChanged?.pathname) {
      updateHashTriggerListOnChange(null, "url_hash");
      _setHasLocationChanged(location);
      setUnsavedChanges({ url: location.pathname, unsavedChanges: [] });
    }
  };

  useEffect(() => {
    // creates a single hashchange listener event
    hashChangeListenerEvent();
    // should only execute once, aka on mount
    updateHashTriggerListOnChange(null, "url_hash");
  }, []);

  useEffect(() => {
    // only triggers when a useURLHashParameter hook updates the globalRequestingHashTriggerAtom state with a new parameterName
    requestingHashTriggerChangeListener();
  }, [requestingHashTriggers]);

  useEffect(() => {
    // only triggers when there is a full URL change
    fullURLChange();
  }, [location]);

  return null;
};

export default AppLocationListener;
