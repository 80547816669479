import { Edit } from "@mui/icons-material";
import IconButton from "../../../../TrueUI/Buttons/IconButton";
import {
  BaseTable2Properties,
  ColumnOptionsProperties,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";

export const tableName = "listing_of_billing_options_by_policy";
//Values will be added in future PR
const columnOptionsForBillingOptionsTableConfiguration: ColumnOptionsProperties<any>[] =
  [
    { fieldName: "Effective", width: 17 },
    { fieldName: "AutoPay", width: 7 },
    { fieldName: "AutoPayDay", width: 5 },
    { fieldName: "InsuredAccount", width: 54 },
    { fieldName: "PreferredBillingMethod", width: 13 },
    { fieldName: "OPTIONS", width: 4 },
  ];

export const getBillingOptionsTableConfiguration = (
  addEventClickCallback,
  getURL,
  hasPermissionToAddNewRule
) =>
  ({
    name: tableName,
    getURL: getURL,
    columnOptions: columnOptionsForBillingOptionsTableConfiguration,
    toolbarOptions: {
      addButtonText: "New Billing Rule",
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      editButtonPrimary: false,
      showExcelButton: false,
      showPDFButton: false,
      showAddButton: hasPermissionToAddNewRule,
    },
    events: {
      addEventOverrideCallback: () => addEventClickCallback(),
    },
  } as BaseTable2Properties);

export const actionsForEditIcon = (
  rowData: any,
  editBillingRuleEventClickCallback: (rowData: any) => void,
  hasPermissionToEdit: boolean
) =>
  hasPermissionToEdit ? (
    <IconButton onClick={() => editBillingRuleEventClickCallback(rowData)}>
      <Edit fontSize="small" />
    </IconButton>
  ) : null;

export const modalBillingOptionsInitConfiguration = {
  isModalOpen: false,
  expirationDate: null,
  billingRuleStatus: null,
  uuid: crypto.randomUUID(),
  refreshTable: false,
  billingRuleId: null,
};

export const fieldToFilterForFormData = [
  "billingMethodOptions",
  "StatusDate",
  "CurrentPayTypeName",
  "StatusToDisplay",
];
