import { Dispatch, SetStateAction } from "react";
import { BaseTable } from "../../../../dtos/base-table";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyExposureBlob } from "../../../../dtos/policy-exposure-blob";
import { PolicyLimitDto } from "../../../../dtos/policy-limit-dto";
import { PolicyLimitsOption } from "../../../../dtos/policy-limits-option";
import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { PolicyPeriodDto } from "../../../../dtos/policy-period-dto";
import { PolicyRatingBlob } from "../../../../dtos/policy-rating-blob";
import { PolicyReferenceOption } from "../../../../dtos/policy-reference-option";
import { PolicyWaiverDto } from "../../../../dtos/policy-waiver-dto";
import { PriorPolicyDto } from "../../../../dtos/prior-policy-dto";
import { ProgramPayPlanDto } from "../../../../dtos/program-pay-plan-dto";
import { RateElementsConfigurationDto } from "../../../../dtos/rate-elements-configuration-dto";
import { RatingRateOption } from "../../../../dtos/rating-rate-option";
import { ScheduleRatingDto } from "../../../../dtos/schedule-rating-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { PolicyEnumValueCodeAndDescriptionBlob } from "../../../../dtos/policy-enum-value-code-and-description-blob";
import { PolicyInsuredNameBlob } from "../../../../dtos/policy-insured-name-blob";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { PolicyInsuredOfficerBlob } from "../../../../dtos/policy-insured-officer-blob";
import { ScheduleRatingsDto } from "../../../../dtos/schedule-ratings-dto";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { ProgramStateDto } from "../../../../dtos/program-state-dto";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import { PolicyPaymentSchedulePage } from "../../../../dtos/policy-payment-schedule-page";
import { PolicyPaymentScheduleMultiTableDto } from "../../../../dtos/policy-payment-schedule-multi-table-dto";
import { PolicyPayPlanBlob } from "../../../../dtos/policy-pay-plan-blob";
import { AlertProps } from "../../../TrueUI/Alerts/Alert";
import { PolicyCustomFieldBlob } from "../../../../dtos/policy-custom-field-blob";
import { ClassCodeWithRatesDto } from "../../../../dtos/class-code-with-rates-dto";
import { PolicyHistoryFilterOptionDto } from "../../../../dtos/policy-history-filter-option-dto";
import { AgencyQuoteConfiguration } from "../../../../dtos/agency-quote-configuration";

export type PolicyQuoteAtomProps = {
  policyId?: number | null;
  quoteId?: number | null;
  previousQuoteId?: number | null;
  policyQuote?: PolicyBlob | null;
  activeSection?: number | null;
  readOnly?: boolean;
  policyInformationErrors?: {
    [key: string]: any;
  } | null;
  policyValidations?: AlertProps[] | null;
  breadcrumbTargetSectionAfterSave?: number | null;
  programStates?: ProgramStateDto[] | null;
};

export type PolicyQuoteProps = {
  insuredId: number;
  tabKey: string;
};

export type PolicyInformationMainProps = {
  insuredId: number;
  tabKey: string;
  quoteStatus?: PolicyEnumValueDescriptionAndBooleanBlob;
  setActiveSection: (
    activeSection: number,
    isBreadcrumbNavigation?: boolean
  ) => void;
  activeSection?: number;
  readOnly?: boolean;
};

export type PolicyInformationProps = {
  insuredId: number;
  tabKey: string;
  assignedTo: SelectOptions[];
  readOnly?: boolean;
  errorDetails?: any;
};

export const POLICY_INFORMATION_SECTION = "policy-information";
export const EXPOSURE_PREMIUM_SECTION = "exposure-premium";
export const BIND_INSTRUCTIONS_SECTION = "bind-instructions";
export const POLICY_DOCS_SECTION = "policy-docs";
export const ENDORSEMENT_FORMS_SECTION = "endorsement-forms";
export const UNDERWRITING_NOTES_SECTION = "underwriting-notes";
export const LOSS_HISTORY_SECTION = "loss-history";

export type PolicyQuoteBottomButtonsProps = {
  insuredId: number;
  tabKey: string;
  quoteStatus?: PolicyEnumValueDescriptionAndBooleanBlob;
  policySection:
    | "policy-information"
    | "exposure-premium"
    | "bind-instructions"
    | "policy-docs"
    | "endorsement-forms"
    | "underwriting-notes"
    | "loss-history";
  setActiveSection?: (
    activeSection: number,
    isBreadcrumbNavigation?: boolean
  ) => void;
  saveEvent?: () => void;
};

export type PolicyQuoteInsuredAndActiveSetterProps = {
  insuredId: number;
  tabKey: string;
  setActiveSection?: (activeSection: number) => void;
  readOnly?: boolean;
  quoteStatus?: PolicyEnumValueDescriptionAndBooleanBlob;
};

export type PolicyQuoteInsuredAsProp = {
  insuredId: number;
  tabKey: string;
};

export type PolicyQuoteHistoryTableAsProp = {
  insuredId: number;
  tabKey: string;
  policyFilterOptions: PolicyHistoryFilterOptionDto;
};

export type PolicyQuoteInsuredAndStateAsProps = {
  insuredId: number;
  stateCode: string;
};

export type PolicyQuoteExposureTableProps = {
  insuredId: number;
  tabKey: string;
  quoteStateId?: number | null;
  stateCode: string;
  stateName: string;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  readOnly?: boolean;
};

export type PolicyInformationUIProps = {
  policyQuoteEffectiveDate?: Date | null;
  policyQuoteExpirationDate?: Date | null;
  policyPeriod?: PolicyPeriodDto | null;
  policyPeriodList?: PolicyPeriodDto[] | null;
  policyPeriodListAsOptions?: Partial<SelectOptions>[] | null;
  quoteName?: string | null;
  policyType?: PolicyEnumValueDescriptionAndBooleanBlob | null;
  policyTypeList?: SelectOptions[] | null;
  priorPolicyNumber?: string | null;
  priorPolicyList?: PriorPolicyDto[] | null;
  policyTerm?: PolicyEnumValueCodeAndDescriptionBlob | null;
  policyTermCodeList?: PolicyEnumValueCodeAndDescriptionBlob[];
  employeeLeasingType?: PolicyEnumValueCodeAndDescriptionBlob | null;
  employeeLeasingList?: PolicyEnumValueCodeAndDescriptionBlob[] | null;
  retrospectiveRating?: PolicyEnumValueCodeAndDescriptionBlob | null;
  retrospectiveRatingList?: PolicyEnumValueCodeAndDescriptionBlob[] | null;
  experienceRating?: PolicyEnumValueCodeAndDescriptionBlob | null;
  experienceRatingList?: PolicyEnumValueCodeAndDescriptionBlob[] | null;
  customerPolicyTypeLabel?: string | null;
  customerPolicyType?: PolicyEnumValueDescriptionAndBooleanBlob | null;
  customerPolicyList?: SelectOptions[] | null;
  policyLimits?: PolicyLimitDto | null;
  policyLimitList?: PolicyLimitDto[] | null;
  insuredNameList?: PolicyInsuredNameBlob[] | null;
  insuredAddressList?: PolicyInsuredAddressBlob[] | null;
  insuredOfficerList?: PolicyInsuredOfficerBlob[] | null;
  underwriterID?: number | null;
};

export type PolicyInformationErrorsUIProps = {
  effectiveDate?: string[] | null;
  expirationDate?: string[] | null;
  quoteName?: string[] | null;
};

export type PolicyStateInModalStateUIProps = {
  quoteStateId?: number | null;
  stateCode?: string | null;
  stateName?: string | null;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  expanded?: boolean | null;
  reportingBureau?: string | null;
  actionType?: PolicyEnumValueDescriptionAndBooleanBlob | null;
  reportCode?: string | null;
};

export type PolicyStateInModalStateAnyUIProps = {
  classCodeId?: number | null;
  classCode?: string | null;
  classSuffix?: string | null;
  classDescription?: string | null;
  classRates?: ClassCodeWithRatesDto;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  expanded?: boolean | null;
  alreadyStates?: string[];
  selectedStates?: string[];
};

export type PolicyStateCollectionUIProps = {
  states?: PolicyStateInModalStateUIProps[];
  stateCodeList?: string;
  allStates?: SelectOptions[];
  allProgramStates?: ProgramStateDto[];
  isModalOpen?: boolean;
  isModalAnyOpen?: boolean;
  initialStateValueWhenOpen?: PolicyStateInModalStateUIProps | null;
};

export type PolicyExposureLCMProps = {
  lcmValue: number;
  lcmOptions?: Partial<SelectOptions>[];
};

export type PolicyQuoteHeaderUIProps = {
  policyStatus: string;
  quoteStatus: string;
  policyDates: string;
  policyType: string;
  policyLimits: string;
};

export type ModalStateProps = {
  modalStatesUI: PolicyStateCollectionUIProps;
  saveEvent?: (
    newStates: PolicyStateInModalStateUIProps[],
    stateCodeList: string
  ) => void;
  policyEffectiveDate?: Date | null;
  policyExpirationDate?: Date | null;
  closeEvent?: () => void;
};

export type ModalStateAnyProps = {
  tabKey: string;
  modalStatesUI: PolicyStateCollectionUIProps;
  saveEvent?: (
    localModalStateUI?: PolicyStateInModalStateAnyUIProps | null
  ) => void;
  closeEvent?: () => void;
};

export type PolicyQuotePremiumTableProps = {
  insuredId: number;
  tabKey: string;
  policyEffectiveDate: Date;
  quoteStateId?: number | null;
  stateCode?: string | null;
  stateName?: string | null;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  readOnly?: boolean;
};

export type PremiumTableUIProps = {
  ratingsLoaded: boolean;
  ratings: PolicyRatingBlob[];
  ratingDependantCollection?: {
    rateElementId: number;
    dependantRatingTriggerComponentNames: string[];
  }[];
  configurationsPerRow?: RateElementsConfigurationDto | null;
};

export type PolicyExposureTableHeaderProps = {
  insuredId: number;
  tabKey: string;
  stateName: string;
  stateCode: string;
  effectiveDate: Date;
  expirationDate: Date;
  readOnly?: boolean;
  deleteState: (
    stateCode: string,
    effectiveDate: Date,
    expirationDate: Date
  ) => void;
};

export type PolicyExposureTableHeaderUIProps = {
  payroll: string;
  surchargesAndFees: string;
  premium: string;
  isGoverningState: boolean;
  classCodeWithHighestPremium: string;
  isExpanded: boolean;
};

export type PolicyExposurePremiumFooterUIProps = {
  payroll: string;
  surchargesAndFees: string;
  premium: string;
};

export type PremiumTableRowResultProps = {
  calculatedAmount: number;
  runningTotal: number;
  rate: number;
  optionalElement?: boolean | null;
  policyReference?: PolicyReferenceOption | null;
  rateOption?: RatingRateOption | null;
  policyLimits?: PolicyLimitsOption | null;
  scheduleRatings?: ScheduleRatingsDto[] | null;
};

export type PremiumTableRowProps = {
  tabKey: string;
  insuredId: number;
  quoteStateId?: number | null;
  stateCode: string;
  stateName: string;
  rateIndex: number;
  effectiveDate: Date;
  expirationDate: Date;
  exposures: PolicyExposureBlob[];
  rating: PolicyRatingBlob;
  nameElement: string;
  calculationsFinished: (() => void) | null;
  triggerDependantRatings: (() => void) | null;
  configurations?: RateElementsConfigurationDto | null;
  readOnly?: boolean;
};

type PolicyBindInstructionStateRatingsProps = {
  amount?: number | null;
  proRate?: boolean | null;
  runningTotal?: number | null;
  elementCategory?: string | null;
};

type PolicyBindInstructionStatesProps = {
  stateCode?: string | null;
  stateName?: string | null;
  ratings?: PolicyBindInstructionStateRatingsProps[] | null;
};

export type PolicyBindInstructionUIProps = {
  states?: PolicyBindInstructionStatesProps[] | null;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  firstPolicyDate?: Date | null;
  billedPremium?: number | null;
  billedFees?: number | null;
  billedDepositAmount?: number | null;
  billByLocation?: boolean | null;
  nonEarningAmount?: number | null;
  numberOfPayments?: number | null;
  installmentPaymentAmount?: number | null;
  depositTypeForDepositOverride?: string | null;
  billingMethod?: string | null;
  dueOnDay?: number | null;
  paymentScheduleURL?: string | null;
  payPlan?: PolicyPayPlanBlob | null;
  depositRate?: number | null;
  paymentScheduleTable?: PolicyPaymentSchedulePage | null;
  paymentScheduleMultiTable?: PolicyPaymentScheduleMultiTablePage | null;
};

export type BindInstructionPremiumBreakdownProp = {
  insuredId: number;
  tabKey: string;
  isActive?: boolean;
  readOnly?: boolean;
  bindInstructionUI?: PolicyBindInstructionUIProps;
  setBindInstructionUI?: Dispatch<
    SetStateAction<PolicyBindInstructionUIProps | undefined>
  >;
  tableURL?: string;
  paymentScheduleTable?:
    | PolicyPaymentSchedulePage
    | PolicyPaymentScheduleMultiTablePage
    | null;
  paymentScheduleUI?: PolicyPaymentScheduleMultiTableDto;
  updatePaymentScheduleTableDueDate?: (newData: string[][]) => void;
  updatePaymentScheduleMultiTableDueDate?: (
    rowIndex: number,
    newDueDate: Date
  ) => void;
};

export type BindInstructionPaymentPlanUIProps = {
  paymentPlan?: number | null;
  depositType?: string | null;
  depositRate?: number | null;
  billingMethod?: string | null;
  billByLocation?: boolean | null;
  dueOnDay?: number | null;
  numberOfPayments?: number | null;
  nonEarningAmount?: number | null;
  billedDepositAmount?: number | null;
  paymentPlans?: ProgramPayPlanDto[] | null;
  paymentPlanOptions?: SelectOptions[] | null;
};

export type NoteHeaderProps = {
  tabKey: string;
  note: PolicyNarrativeBlob;
  editEvent?: (note: PolicyNarrativeBlob) => any;
};

export type NoteProps = {
  tabKey: string;
  noteIndex: number;
  note: PolicyNarrativeBlob;
  collapseBottomMargin: boolean;
  isCollapsed: (value: boolean) => any;
  editEvent?: (note: PolicyNarrativeBlob) => any;
};

export type NoteContentProps = {
  expanded: boolean;
  formattedNarrativeText?: string;
};

export type DialogConfirmationConfigurationProps = {
  isOpen: boolean;
  text: string;
  option: "save-one" | "save-all" | "delete";
};

export type LossHistoryDisplayOptionsUIProps = {
  stateList: BaseTableDisplayOptions[];
  lossTypeList: BaseTableDisplayOptions[];
  claimStatusList: BaseTableDisplayOptions[];
};

export type LossHistoryTableProps = {
  insuredId: number;
  tabKey: string;
  quoteStatus?: PolicyEnumValueDescriptionAndBooleanBlob;
  tableDisplayOptions: LossHistoryDisplayOptionsUIProps;
};

export type ScheduleFormUIProps = {
  scheduleRating?: ScheduleRatingDto | null;
  isModalOpen?: boolean;
};

export type ScheduleRateItemsUIProps = {
  quoteStateScheduleID: number;
  scheduleRateItemID: number;
  minValue: number;
  maxValue: number;
  description: string;
  value?: number;
  reason?: string;
  createOn?: Date;
  createBy?: number;
};

export type ModalScheduleRatingProps = {
  state: string;
  scheduleFormUI?: ScheduleFormUIProps;
  saveErrorDetails?: any;
  saveEvent?: (localModalUI?: ScheduleRateItemsUIProps[] | null) => void;
  closeEvent?: () => void;
  readOnly: boolean;
};

export type AgencyInformationTableProps = {
  insuredId: number;
  tabKey: string;
  agencyList: AgencyQuoteConfiguration[];
  readOnly?: boolean;
};

export type PolicyInformationMainUIProps = {
  readyToRender: boolean;
  agencyList: AgencyQuoteConfiguration[];
  usersAndTeamsList: SelectOptions[];
  customFieldList: PolicyCustomFieldBlob[];
};

export type SpecificWaiverFormUIProps = {
  // TODO - Add more elements
  insuredId?: number;
  tabKey?: string;
  stateCode?: string | null;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  rate?: number | null;
  rounding?: number | null;
  minimumValue?: number | null;
  maximumValue?: number | null;
  policyId?: number | null;
  policyQuoteId?: number | null;
  policyWaiverTable?: BaseTable;
  policyQuoteDate?: Date | null;
  insuredAddress?: Partial<SelectOptions>[] | null;
};

export type ModalSpecificWaiverProps = {
  showModal: boolean;
  specificWaiverFormUI?: SpecificWaiverFormUIProps;
  closeEvent?: (tableTotal: number) => void;
  openFormEvent?: () => void;
  readOnly: boolean;
};

export type SpecificWaiverTableProps = {
  tableTotal: number;
  setTableTotal: (total: number) => void;
  openFormEvent: (() => void) | undefined;
  specificWaiverFormUI?: SpecificWaiverFormUIProps;
  readOnly: boolean;
};

export type ModalSpecificWaiverFormProps = {
  showModal: boolean;
  specificWaiverFormUI?: SpecificWaiverFormUIProps;
  closeEvent?: () => void;
  refreshTable?: () => void;
  saveDisabled?: boolean;
};

export type SpecificWaiverFormProps = {
  specificWaiverUI?: PolicyWaiverDto | null;
  setSpecificWaiverUI?: React.Dispatch<
    React.SetStateAction<PolicyWaiverDto | null | undefined>
  >;
  specificWaiverFormUI?: SpecificWaiverFormUIProps;
  errorDetails?: any;
};

export type PolicyQuoteEndorsementFormsProps = {
  insuredId?: number;
  forms: any;
  tabKey?: string;
  fileName?: string;
};

export type PolicyQuoteEndorsementOptionalFormsProps = {
  insuredId: number;
  tabKey: string;
  forms: any;
  setSelectedOptionalForms: (
    selectedOptionalForms: ProgramEndorsementBlob[]
  ) => void;
  readonly?: boolean;
};

export type PolicyQuoteDocumentSplitterProps = {
  insuredId: number;
  tabKey: string;
  programPackets: ProgramPacketDto[];
  programDocuments: ProgramPacketDocumentDto[];
  policyDocuments: ProgramPolicyDocumentDto[];
  selectedPackets: number[];
  selectedDocuments: number[];
  onSelectedCheckboxDocument: (
    checked: boolean,
    id: number,
    type: "packet" | "document"
  ) => void;
};

export type PolicyQuoteDocumentGroupProps = {
  programPackets: ProgramPacketDto[];
  selectedPackets: number[];
  onSelectedCheckboxDocument: (
    checked: boolean,
    packetId: number,
    type: "packet" | "document"
  ) => void;
};

export type PolicyQuoteDocumentPacketProps = {
  insuredId: number;
  tabKey: string;
  programDocuments: ProgramPacketDocumentDto[];
};

export type PolicyQuoteDocumentIndividualProps = {
  tabKey: string;
  insuredId: number;
  policyDocuments: ProgramPolicyDocumentDto[];
  selectedDocuments: number[];
  onSelectedCheckboxDocument: (
    checked: boolean,
    packetId: number,
    type: "packet" | "document"
  ) => void;
};

export type DocumentFileOptionsProps = {
  tabKey: string;
  insuredId: number;
  selectedPackets: number[];
  selectedDocuments: number[];
  programPackets: ProgramPacketDto[];
  programDocuments: ProgramPacketDocumentDto[];
  policyDocuments: ProgramPolicyDocumentDto[];
};

export type TotalsPayrollSurchargesAndPremiumProps = {
  totalPayroll: number;
  totalSurchargesAndFees: number;
  totalPremium: number;
};
