import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { FontBold, Input } from "../../../../TrueUI";
import { ColumnOptionsProperties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  TableData,
  TableRow,
} from "../../../../TrueUI/Tables/BaseTableStructure";
import BaseTableGridForm from "../../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import MultiAlignHeader from "../../../../TrueUI/Tables/MultiTable/MultiAlignHeader";

export const columnOptionsProps: ColumnOptionsProperties<any>[] = [
  {
    fieldName: "Line",
    width: 5,
  },
  {
    fieldName: "DueDate",
    width: 11.3,
  },
  {
    fieldName: "Number",
    width: 11.3,
  },
  {
    fieldName: "Type",
    width: 11.3,
  },
  {
    fieldName: "Description",
    width: 40,
  },
  {
    fieldName: "Paid",
    width: 7,
  },
  {
    fieldName: "Amount",
    width: 14,
    align: "right",
  },
];

export const getCellValue = (column, totalValue) => {
  switch (column) {
    case "Description":
      return <FontBold>{"Total of all invoices"}</FontBold>;
    case "Amount":
      return (
        <Input
          type={"fixedCurrency"}
          id={"total-invoices"}
          name={"total-invoices"}
          value={totalValue}
          inputFontType={"BOLD_BODY"}
          align="right"
          prefix=""
          readOnly
        />
      );
    default:
      return "";
  }
};

export const getColumnWidth = (columns, column) => {
  const selected = columnOptionsProps?.find((c) => c?.fieldName === column);
  if (conditionHasValue(selected)) {
    return `${selected?.width}%`;
  }
  return `${100 / (columns?.length ?? 1)}%`;
};

export const CustomFooterComponent = (rows) => {
  const totalValue = rows
    ?.map((row) => row?.Metadata?.Amount ?? 0)
    .reduce((x, y) => {
      return parseInt(x) + parseInt(y);
    }, 0);
  const rowBase = rows?.[0];

  return (
    <TableRow
      style={{
        width: "100%",
        display: "flex",
        fontWeight: "700",
        height: "48px",
      }}
    >
      {rowBase?.Columns.map((column, key) => (
        <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
          {getCellValue(column, totalValue)}
        </TableData>
      ))}
      <TableData width={"76px"}></TableData>
    </TableRow>
  );
};

export const MultiTablePatternComponent = (e) => {
  return (
    <BaseTableGridForm
      obj={e}
      urlPattern={
        "api/Invoice/GetInsuredInvoiceSub?invoiceId=null&insuredId=null"
      }
      columnOptions={columnOptionsProps}
      parameters={[
        {
          paramaterKey: "invoiceId",
          parameterPropertyValue: "InvoiceId",
        },
        {
          paramaterKey: "insuredId",
          parameterPropertyValue: "InsuredId",
        },
      ]}
    />
  );
};

export const MultiTableHeaderPatternComponent = (
  params,
  metaData,
  columnOptions
) => {
  const titleParams = params ?? [];
  return (
    <MultiAlignHeader
      headerParams={titleParams}
      metaData={metaData}
      columnOptions={columnOptions}
      contextConfiguration={{
        editRow: (e) => {
          console.log(e);
        },
      }}
    />
  );
};
