// import { useEffect } from "react";
// import { RecoilState, useRecoilValue } from "recoil";
// import { FixedSizeList as List } from "react-window";

// import { tableInstanceAtomFamily2 } from "../TableAtoms";
import BaseTable2RowManager from "./BaseTable2RowManager";
// import { TableInstanceType2 } from "./TableProperties";

const RowRenderType = ({ uiid, cellManagerInstance }) => {
  //   const tableInstance = useRecoilValue(
  //     tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  //   );

  // if (tableInstance?.sortedAndFilteredData?.length ?? 0) {
  //   if (tableInstance?.renderType === "virtualization") {
  //     return (
  //       <List
  //         className="virtual-body"
  //         height={40 * 10} // The 10 number should change to tableInstance.rowsPerPage
  //         itemCount={tableInstance.sortedAndFilteredData.length} //Total items in the list
  //         itemSize={40} // Get the height of the table row
  //         width={"100%"}
  //       >
  //         {Row}
  //       </List>
  //     );
  //   }
  //   return (tableInstance?._renderedCellManagerInstances ?? []).map((row) =>
  //     internalRow(row)
  //   );
  // }
  // return null;
  //   useEffect(() => {
  //     console.log(
  //       "tableInstance._renderedCellManagerInstances",
  //       tableInstance._renderedCellManagerInstances
  //     );
  //   }, [tableInstance._renderedCellManagerInstances]);

  const internalRow = (instance, style?) => {
    // const rowKey = getSafeRowKey(row);

    return (
      <BaseTable2RowManager
        key={crypto.randomUUID()}
        uiid={uiid}
        cellManagerInstance={instance}
        style={style}
      />
    );
  };

  return internalRow(cellManagerInstance);

  //   return tableInstance._renderedCellManagerInstances !== null &&
  //     tableInstance._renderedCellManagerInstances.length > 0 ? (
  //     <>
  //       {(tableInstance._renderedCellManagerInstances ?? []).map((row) => {
  //         return internalRow(row);
  //       })}
  //     </>
  //   ) : (
  //     <>Hi</>
  //   );
};

export default RowRenderType;
