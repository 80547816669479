import { Drawer as DrawerMU, IconButton } from "@mui/material";
import { FC } from "react";
import { Icon } from "@progress/kendo-react-common";
import "./notifications.module.css";
import { Font } from "../../../TrueUI";
import NotificationsGrid from "./NotificationsGrid";

type NotificationProperties = {
  open: boolean;
  onTopNavClose?: () => void;
  setUpdateNotifications: (boolean) => any;
};

const Notifications: FC<NotificationProperties> = ({
  open,
  onTopNavClose,
  setUpdateNotifications,
}) => {
  return (
    <DrawerMU
      anchor={"top"}
      open={open}
      onClose={() => onTopNavClose?.()}
      className={"notifications_drawer"}
    >
      <div className={"notifications_content_container"}>
        <div className={"notifications_header_content"}>
          <div id={"notifications_header"} className={"notifications_header"}>
            <Font fontType="HEADING_5">Notifications</Font>
          </div>
          <div id="notifications-close-icon-container">
            <IconButton onClick={onTopNavClose}>
              <Icon className={"notifications_close_icon"} name={"x"} />
            </IconButton>
          </div>
        </div>
        <NotificationsGrid setUpdateNotifications={setUpdateNotifications} />
      </div>
    </DrawerMU>
  );
};

export default Notifications;
