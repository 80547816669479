import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../../../../../TrueUI";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import BaseTableDropdownFilter from "../../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { StatusEnums } from "../../../../../../dtos/status-enums";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { useApiPost } from "../../../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";

type ClaimLienProps = {
  claimId: number;
  hasClaimsManagementPermissions: boolean;
};

const ClaimLien: FC<ClaimLienProps> = ({
  claimId,
  hasClaimsManagementPermissions,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [lienToDelete, setLienToDelete] = useState<any>(null);
  const [getUrlRefresh, setUrlRefresh] = useState<string>(crypto.randomUUID());

  const { dispatchPost, responsePost } = useApiPost(
    "api/ClaimLien/DeleteClaimLien",
    lienToDelete
  );

  const name = "claim_lien_grid";
  const getURL = `api/ClaimLien/GetClaimLienGrid?claimId=${claimId}&uiid=${getUrlRefresh}`;

  const onCancelDelete = () => {
    setLienToDelete(null);
    setDialogOpen(false);
  };

  const onDeleteClick = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    const lienId = actionOptions.row.LienId;
    if (conditionHasValue(lienId) && lienId > 0) {
      setLienToDelete(actionOptions.row);
      setDialogOpen(true);
    } else {
      tableInstanceMethods?.methods?.deleteRow({
        rowKey: actionOptions.row._row_key,
        hydratedData: actionOptions.row,
        deleteType: "hidden",
      });
    }
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      tableInstanceMethods?.methods?.deleteRow({
        rowKey: lienToDelete._row_key,
        hydratedData: lienToDelete,
        deleteType: "hidden",
      });
      setDialogOpen(false);
    }
  }, [responsePost]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      disabled={!hasClaimsManagementPermissions}
      aria-label="expand row"
      onClick={() => onDeleteClick(actionOptions)}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    postURL: `api/ClaimLien/SaveClaimLiens?claimId=${claimId}`,
    postTarget: "changedClaimLiens",
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showAddButton: hasClaimsManagementPermissions,
      showEditButton: hasClaimsManagementPermissions,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status",
          filterOptions: actionOptions,
          columnsNames: ["Status"],
          labelText: "Show:",
          defaultValue: StatusEnums.ACTIVE.toString(),
        }),
    ],
    columnOptions: [
      { fieldName: "OpenDate", width: 10 },
      { fieldName: "Status", width: 9 },
      { fieldName: "SatisfiedDate", width: 10 },
      { fieldName: "LienHolder", width: 15 },
      { fieldName: "LienNumber", width: 10 },
      { fieldName: "LienAmount", width: 10 },
      { fieldName: "Comments", width: 25 },
      { fieldName: "AddedBy", width: 8 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      overrideColumnDefaultValues: [
        {
          columnFieldName: "Status",
          value: StatusEnums.ACTIVE.toString(),
          propName: "defaultValue",
        },
      ],
    },
    events: {
      onDeleteRow: () => setLienToDelete(null),
      onDataToBeSavedChange: (dataToBeSaved) => {
        if (dataToBeSaved.length === 0) {
          setUrlRefresh(crypto.randomUUID());
        }
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const tableInstanceMethods = tableMethods[name];

  return (
    <div style={{ height: "100%" }}>
      <BaseTable2 name={name} />
      <DialogConfirmation
        id="id-delete-lien-confirmation"
        dialogDescriptionText="Are you sure you want to delete this lien?"
        open={dialogOpen}
        onOptionYesEvent={() => dispatchPost()}
        onOptionNoEvent={onCancelDelete}
        onCloseEvent={onCancelDelete}
      />
    </div>
  );
};

export default ClaimLien;
