import { FC, useEffect, useState } from "react";
import { BaseTable2 } from "../../../../../../TrueUI";
import { BaseTable2Properties } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { OPTION_COLUMN_FIELD_NAME } from "../../../../../../TrueUI/Tables/TableConstants";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";
import { IncidentBodyPartDto } from "../../../../../../../dtos/incident-body-part-dto";
import { IncidentBodyPartContainerDto } from "../../../../../../../dtos/incident-body-part-container-dto";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../../utilities/conditionalSupportFunctions";
import {
  deleteByHydratedRow,
  getDeletedBodyParts,
  onBodyPartChange,
  onChangeTableData,
  onDescriptionChange,
} from "./IncidentBodyPartsFieldUtils";

type IncidentBodyPartsProps = {
  incidentId?: number;
  readOnly?: boolean;
  incidentBodyPart?: IncidentBodyPartContainerDto;
  errorDetails?: any[] | null;
  onChangeValue?: (bodyParts: IncidentBodyPartDto[]) => void;
};

const IncidentBodyPartsField: FC<IncidentBodyPartsProps> = ({
  incidentId,
  readOnly,
  incidentBodyPart,
  errorDetails,
  onChangeValue,
}) => {
  const tableName = `incident_body_parts_container_table_${incidentId}`;

  const [tableErrors, setTableErrors] = useState<{} | null>(null);
  const [incidentBodyParts, setIncidentBodyParts] = useState<any[]>(
    incidentBodyPart?.tableData.data ?? []
  );
  const [deletedIncidentBodyParts, setDeletedIncidentBodyParts] =
    useState<IncidentBodyPartDto[]>();

  const tableConfiguration: BaseTable2Properties<IncidentBodyPartDto> = {
    name: tableName,
    columnsAndData: {
      data: incidentBodyParts,
      columns: incidentBodyPart?.tableData.columns ?? [],
    },
    toolbarOptions: {
      toolBarType: "compact",
      showAddButton: !readOnly,
      showEditButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    columnOptions: [
      { fieldName: "PrimaryInjury", width: 20 },
      { fieldName: "Side", width: 20 },
      {
        fieldName: "BodyPartId",
        width: 30,
        selectMaxHeight: "200px",
        computeOnChange: {
          conditionForCell: [() => onBodyPartChange()],
        },
      },
      {
        fieldName: "BodyPartName",
        width: 30,
        computeOnChange: {
          conditionForCell: [
            (options) =>
              onDescriptionChange(
                options,
                incidentBodyPart?.configuration.allBodyParts
              ),
          ],
        },
        computeOnInit: {
          conditionForCell: [
            (options) =>
              onDescriptionChange(
                options,
                incidentBodyPart?.configuration.allBodyParts
              ),
          ],
        },
      },
      { fieldName: OPTION_COLUMN_FIELD_NAME, width: 10 },
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: !readOnly,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: readOnly ? "none" : "edit_delete",
      },
      tableStyle: {
        headerStyle: "transparent",
        height: "200px",
      },
      tableErrors: (tableErrors as any[]) ?? [],
      disableOrderBy: true,
    },
    events: {
      onDataChange: (data, columns) =>
        onChangeTableData(
          data,
          columns,
          setIncidentBodyParts,
          errorDetails,
          setTableErrors
        ),
      onDeleteRow: ({ hydratedRow }) =>
        deleteByHydratedRow(
          hydratedRow,
          deletedIncidentBodyParts,
          setDeletedIncidentBodyParts
        ),
    },
  };

  const { tableMethods } = useBaseTable([tableConfiguration]);
  const tableInstanceMethods = tableMethods[tableName];

  useEffect(() => {
    if (!isEmptyValue(tableErrors)) {
      tableInstanceMethods.methods.refreshTable();
    }
  }, [tableErrors]);

  useEffect(() => {
    if (
      conditionHasValue(incidentBodyParts) ||
      conditionHasValue(deletedIncidentBodyParts)
    ) {
      const mergedBodyParts =
        getDeletedBodyParts(deletedIncidentBodyParts).length > 0
          ? incidentBodyParts?.concat(deletedIncidentBodyParts ?? [])
          : incidentBodyParts;
      onChangeValue?.(mergedBodyParts);
    }
  }, [incidentBodyParts, deletedIncidentBodyParts]);

  return <BaseTable2 name={tableName} />;
};

export default IncidentBodyPartsField;
