import { FC, useState } from "react";
import { Box } from "@mui/material";
import { Button, Col, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import ModalNewSubmission from "./ModalNewSubmission";
import style from "./quickActions.module.css";
import AddIncidentModal from "../../Claims/Claims/AddIncidentModal";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

const QuickActions: FC = () => {
  const [showNewSubmissionModal, setShowNewSubmissionModal] = useState(false);
  const [showAddIncidentModal, setShowAddIncidentModal] = useState(false);
  const { hasPermission: hasClaimPermissions } = usePermissions([
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]);

  const onCloseNewSubmission = () => setShowNewSubmissionModal(false);

  return (
    <Box className={style.quick_actions_container}>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col
          horizontalAlign="flex-start"
          breakpoints={{ md: 12, lg: 3, xl: 2 }}
          horizontalGutter="10px"
        >
          <Button
            name="quickActionsNewPayment"
            size="large"
            fullWidth={true}
            variantStyle="outlined"
            onClick={() => {}}
          >
            New Payment
          </Button>
        </Col>

        <Col
          horizontalAlign="flex-end"
          breakpoints={{ md: 12, lg: 3, xl: 2 }}
          horizontalGutter="10px"
        >
          <Button
            name="quickActionsNewClaim"
            size="large"
            fullWidth={true}
            variantStyle="outlined"
            isDisabled={!hasClaimPermissions}
            onClick={() => setShowAddIncidentModal(true)}
          >
            New Incident
          </Button>
        </Col>
        <Col
          horizontalAlign="flex-end"
          breakpoints={{ md: 12, lg: 3, xl: 2 }}
          horizontalGutter="10px"
        >
          <Button
            name="quickActionsNewSubmission"
            size="large"
            fullWidth={true}
            variantStyle="outlined"
            onClick={() => setShowNewSubmissionModal(true)}
          >
            New Submission
          </Button>
        </Col>
        <ModalNewSubmission
          showModal={showNewSubmissionModal}
          onClose={onCloseNewSubmission}
        />
        <AddIncidentModal
          showModal={showAddIncidentModal}
          setShowModal={setShowAddIncidentModal}
        />
      </Row>
    </Box>
  );
};
export default QuickActions;
