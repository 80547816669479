import { FC, useEffect, useState } from "react";
import {
  EndorsementGeneralProps,
  EndorsementScheduleTableFooterUI,
} from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useRecoilValue } from "recoil";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Col, FontBold, Row } from "../../../TrueUI";
import { calculateTotalsForScheduleMultiTable } from "./EndorsementBindInstructionsUtils";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import style from "../EndorsementForm/Endorsement.module.css";

const EndorsementScheduleMultiTableFooter: FC<EndorsementGeneralProps> = ({
  tabKey,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const listenerScheduleMultiTableFooter = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementScheduleMultiTableFooterComponent")
  );
  const [
    endorsementScheduleMultiTableFooterUI,
    setEndorsementScheduleMultiTableFooterUI,
  ] = useState<EndorsementScheduleTableFooterUI | null>(null);

  const updateFooterUIValuesByValuesChanged = () => {
    const atomValue = getAtom();
    const scheduleMultiTable =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.scheduleMultiTable;

    if (conditionHasValue(scheduleMultiTable)) {
      const newValuesForFooter =
        calculateTotalsForScheduleMultiTable(scheduleMultiTable);
      setEndorsementScheduleMultiTableFooterUI(newValuesForFooter);
    }
  };

  useEffect(() => {
    updateFooterUIValuesByValuesChanged();
  }, []);

  useEffect(() => {
    if (listenerScheduleMultiTableFooter !== null) {
      updateFooterUIValuesByValuesChanged();
    }
  }, [listenerScheduleMultiTableFooter]);

  return (
    <Row
      className={style.endorsement_schedule_multi_table_footer}
      {...rowWithNoMarginNorGutter}
      horizontalAlign="flex-end"
    >
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-start"
      >
        <FontBold>Total of all payments</FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {endorsementScheduleMultiTableFooterUI?.totalInvoiceAmount.toLocaleString()}
        </FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {endorsementScheduleMultiTableFooterUI?.totalEndorsement.toLocaleString()}
        </FontBold>
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        horizontalAlign="flex-end"
      >
        <FontBold>
          {endorsementScheduleMultiTableFooterUI?.totalPaymentDue.toLocaleString()}
        </FontBold>
      </Col>
    </Row>
  );
};

export default EndorsementScheduleMultiTableFooter;
