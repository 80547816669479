import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DiaryConfigurationDto } from "../../../dtos/diary-configuration-dto";
import { DiaryNoteDto } from "../../../dtos/diary-note-dto";
import { useApiPost, usePermissions } from "../../../hooks";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { ContextProps } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";
import DiaryHeader from "../../Insured/InsuredDrawer/Notes/DiaryHeader";
import ModalNewAlert from "../../Insured/InsuredDrawer/Notes/ModalNewAlert";
import Button from "../Buttons/Button";
import Modal from "../Modal/Modal";
import RichEditor from "../RichEditor/RichEditor";
import ShowFiles from "../Upload/ShowFiles";
import Upload from "../Upload/Upload";
import "./all_note_content.module.css";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

type ModalShowAllNoteContentProps = {
  id?: string;
  isOpen: boolean;
  diaryNote: DiaryNoteDto | null;
  errorDetails: any;
  context: ContextProps;
  diaryConfiguration: DiaryConfigurationDto;
  hasPermissionsToEdit?: boolean;
  saveEvent?: () => void;
  cancelEvent?: () => void;
  deleteEvent?: (diaryNote?: DiaryNoteDto) => void;
  setDiaryNote: (diaryNote: DiaryNoteDto | null) => void;
  setRefreshConversation: (refresh: boolean) => void;
};

const ModalShowAllNoteContent: FC<ModalShowAllNoteContentProps> = ({
  id = "",
  diaryNote,
  isOpen,
  errorDetails,
  diaryConfiguration,
  context,
  hasPermissionsToEdit,
  saveEvent,
  cancelEvent,
  deleteEvent,
  setDiaryNote,
  setRefreshConversation,
}) => {
  const [files, setFiles] = useState<{ [key: string]: number }>({});
  const [fileToDelete, setFileToDelete] = useState<number | null>(null);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState<boolean>(false);

  const { responsePost: responseDeleteFile, dispatchPost: dispatchDeleteFile } =
    useApiPost<number>(
      `api/DiaryNote/DeleteDiaryAttachment?diaryAttachmentId=${fileToDelete}`
    );

  const hasPermissionByRoles = usePermissions([7]);
  const hasPermissionByCreator = diaryNote?.isActiveUserCreator;

  const hasPermissionToSetAlert =
    hasPermissionByRoles || hasPermissionByCreator;

  const deleteFileFromNote = (fileId: number) => {
    const fileMatch = diaryNote?.attachments?.find((f) => f.fileId === fileId);
    setFileToDelete(fileMatch?.diaryAttachmentId ?? null);
  };

  const onSaveNote = () => {
    if (hasPermissionsToEdit) saveEvent?.();
    setFiles({});
  };

  useEffect(() => {
    if (conditionHasValue(files)) {
      const keys = Object.keys(files ?? []);
      const newFileIds = keys.map((key) => files?.[key]);
      setDiaryNote({ ...diaryNote, attachmentsIds: newFileIds });
    } else {
      setDiaryNote({ ...diaryNote, attachmentsIds: [] });
    }
  }, [files]);

  useEffect(() => {
    if (fileToDelete !== null) dispatchDeleteFile();
  }, [fileToDelete]);

  useEffect(() => {
    if (isAPITotallyComplete(responseDeleteFile)) {
      const updatedFiles = diaryNote?.attachments?.filter(
        (f) => f.diaryAttachmentId !== responseDeleteFile.responseData
      );
      setDiaryNote({ ...diaryNote, attachments: updatedFiles });
      setRefreshConversation(true);
    }
  }, [responseDeleteFile]);

  return (
    <>
      <Modal
        open={isOpen}
        id={id}
        title={"Edit Note"}
        size={"lg"}
        saveEvent={onSaveNote}
        cancelEvent={cancelEvent}
        deleteEvent={() => diaryNote !== null && deleteEvent?.(diaryNote)}
        deleteDisabled={!hasPermissionsToEdit}
        buttonRibbonNode={
          hasPermissionToSetAlert && (
            <Button
              variantStyle="outlined"
              onClick={() => setIsModalAlertOpen(true)}
            >
              Set Alert
            </Button>
          )
        }
        showCloseButton
        showCancelTextButton
        internalComponentsClassName="all_note_content"
      >
        <>
          <RichEditor
            contentStyle={{ height: 350 }}
            readOnly={!hasPermissionsToEdit}
            value={diaryNote?.content ?? ""}
            onChangeRawValue={(value) => {
              setDiaryNote({ ...diaryNote, content: value });
            }}
            headerComponent={
              <DiaryHeader
                context={context}
                errorMessages={errorDetails}
                diaryNote={diaryNote}
                setDairyNote={setDiaryNote}
                diaryConfiguration={diaryConfiguration}
                action={"edit"}
              />
            }
            disableCollapse
          />
          <Box display={"flex"} alignItems={"center"}>
            <Box sx={{ width: "auto", maxWidth: "50%" }}>
              <Upload
                readOnly={!hasPermissionsToEdit}
                name="upload-files"
                files={files}
                onUploadFileList={setFiles}
              />
            </Box>
            <ShowFiles
              files={diaryNote?.attachments ?? []}
              onDeleteFile={(fileId) => deleteFileFromNote(fileId)}
            />
          </Box>
        </>
      </Modal>
      <ModalNewAlert
        context={context}
        isOpen={isModalAlertOpen}
        setIsOpen={setIsModalAlertOpen}
        diaryNote={diaryNote}
        setRefreshConversation={setRefreshConversation}
      />
    </>
  );
};

export default ModalShowAllNoteContent;
