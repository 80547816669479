import { PaymentApprovalConfigurationDto } from "../../../../../dtos/payment-approval-configuration-dto";
import { PaymentValidationEnum } from "../../../../../dtos/payment-validation-enum";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import {
  getPaymentIdListByTableData,
  limitMessage,
} from "./PaymentsApprovalUtils";
import PaymentsApprovalFooter from "./PaymentsApprovalFooter";

export const tableName = "approval_payment_table";

export const getTableConfig = (
  configurationOptions: PaymentApprovalConfigurationDto,
  getUrl: string,
  showAddButton: boolean,
  editModalLinkEvent: (rowData?: any) => JSX.Element,
  setIsDialogConfirmationOpen: (show: boolean) => void,
  setFilteredPaymentIds: (rows: any[]) => void,
  setShowAddButton: (showAddButton: boolean) => void,
  setTotalPaid: (filteredData: string[][], columns: any[]) => void
) => {
  const config: BaseTable2Properties = {
    name: tableName,
    getURL: getUrl,
    toolbarOptions: {
      showImportButton: false,
      showEditButton: false,
      showSortFilter: false,
      addButtonText: "APPROVE",
      addButtonIsDisabled: !showAddButton,
    },
    footerOptions: {
      showCustomFooter: true,
      CustomFooterComponent: () => PaymentsApprovalFooter(),
    },
    columnOptions: [
      { fieldName: "ProgramCode", width: 5 },
      { fieldName: "CreateBy", width: 5 },
      { fieldName: "Comments", width: 15 },
      { fieldName: "OutstandingReserves", align: "right" },
      { fieldName: "PaidAmount", align: "right" },
      { fieldName: "OPTIONS", width: 2 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "typeId",
          filterOptions: actionOptions,
          columnsNames: ["TypeId"],
          optionsOverride: configurationOptions?.reserveType,
          labelText: "Type:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "adjusterId",
          filterOptions: actionOptions,
          columnsNames: ["Adjuster"],
          optionsOverride: configurationOptions?.adjusterOptions,
          labelText: "Adjuster:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "createId",
          filterOptions: actionOptions,
          columnsNames: ["CreateId"],
          optionsOverride: configurationOptions?.userOptions,
          labelText: "Added By:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "ClaimStatusId",
          filterOptions: actionOptions,
          columnsNames: ["ClaimStatus", "HiddenClaimStatus"],
          optionsOverride: configurationOptions?.claimStatusOptions,
          labelText: "Claim Status:",
          labelPosition: "start",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "Validation",
          filterOptions: actionOptions,
          columnsNames: ["Validation"],
          optionsOverride: configurationOptions?.validationOptions,
          labelText: "Validation:",
          defaultValue: 1,
          labelPosition: "start",
          onChange: (value) => {
            setShowAddButton(
              PaymentValidationEnum.ADDITIONAL_RESERVES_NEEDED !== value &&
                configurationOptions.userPaymentLimit > 0
            );
          },
        }),
      () => limitMessage(configurationOptions.userPaymentLimit),
    ],
    events: {
      onDataFilteredChange(data, columns) {
        setFilteredPaymentIds(
          getPaymentIdListByTableData(
            data,
            columns,
            configurationOptions.userPaymentLimit
          )
        );
        setTotalPaid(data, columns);
      },
      addEventOverrideCallback: () => setIsDialogConfirmationOpen(true),
    },
    advancedOptions: {
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          editModalLinkEvent?.(actionOptions.row) ?? null,
      },
    },
  };
  return config;
};
