import { FC, useEffect, useState } from "react";
import { ModalPortalUserProps } from "../PortalUsersConst";
import { Modal } from "../../../TrueUI";
import { PortalNewUserInformationPage } from "../../../../dtos/portal-new-user-information-page";
import NewUserInformationSection from "./NewUserInformationSection";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import NewUserAccessRightsSection from "./NewUserAccessRightsSection";
import NewUserPermissionsSection from "./NewUserPermissionsSection";
import { useRecoilState } from "recoil";
import { NewPortalInsuredUser } from "../UsersAtom";

const ModalNewPortalUser: FC<ModalPortalUserProps> = ({
  modalInfo,
  setModalInfo,
  unmountGrid,
}) => {
  const [newPortalInsuredUser, setNewPortalInsuredUser] =
    useRecoilState(NewPortalInsuredUser);
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { responseGet, dispatchGet } = useApiGet<PortalNewUserInformationPage>(
    `api/PortalUser/GetInformationToAddNewUser?userId=${modalInfo?.userId}`
  );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    `api/PortalUser/SaveNewPortalInsuredUser`,
    newPortalInsuredUser
  );

  const onCancelModal = () => {
    setModalInfo?.({
      ...modalInfo,
      isNewUserModalOpen: false,
      userId: null,
    });
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const userInfo = responseGet?.responseData?.portalUserInformation;

      const userEmail =
        userInfo?.userId != 0 ? userInfo?.userEmail : modalInfo?.userEmail;

      setNewPortalInsuredUser({
        portalUserInformation: {
          ...userInfo,
          userEmail: userEmail,
        },
        userId: userInfo?.userId,
        insuredId: modalInfo?.insuredId,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (modalInfo?.isNewUserModalOpen) {
      dispatchGet();
    }
  }, [modalInfo?.isNewUserModalOpen]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      if (validatorErrorResponse === null) {
        setErrorDetails(null);
        setModalInfo?.({
          ...modalInfo,
          userId: null,
          isModalOpen: false,
          isNewUserModalOpen: false,
          refreshUsersTable: true,
        });
        setNewPortalInsuredUser(null);
        unmountGrid?.();
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <Modal
      id={"portal_user_modal"}
      title={"Portal User"}
      open={modalInfo?.isNewUserModalOpen}
      cancelEvent={onCancelModal}
      size={"lg"}
      cancelButtonWithConfirmation
      showCloseButton
      showCancelTextButton
      saveEvent={() => dispatchPost()}
    >
      <NewUserInformationSection errorDetails={errorDetails} />
      <div style={{ display: "flex", alignItems: "stretch", gap: "20px" }}>
        <NewUserAccessRightsSection
          tableData={responseGet?.responseData?.portalUserAccessRights}
        />
        <NewUserPermissionsSection insuredName={modalInfo?.insuredName} />
      </div>
    </Modal>
  );
};
export default ModalNewPortalUser;
