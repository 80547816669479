import { FC, useEffect, useState } from "react";
import style from "../AuditForm/Audit.module.css";
import { Font, Switch } from "../../../TrueUI";
import { AuditDocumentsIndividualProps } from "../AuditForm/AuditTypes";
import Link from "../../../TrueUI/Typography/Link";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useFormRequest } from "../../../../hooks";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const AuditDocumentsIndividual: FC<AuditDocumentsIndividualProps> = ({
  tabKey,
  allProgramPolicyDocuments,
  selectedPacketDocuments,
  selectedDocuments,
  onChangeIndividualDocumentSelected,
}) => {
  const [
    documentsFilteredBySelectedPackets,
    setDocumentsFilteredBySelectedPackets,
  ] = useState<ProgramPolicyDocumentDto[]>(allProgramPolicyDocuments);
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      if (isEmptyValue(document.templateName)) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: [document.templateName],
          customFileName: `${policyMergeFields.insured?.insuredName ?? ""} ${
            document.docName ?? ""
          }`,
          printOrDownload: "download",
        });
      }
    }
  };

  const getCheckedValueForIndividualDocument = (
    programPolicyDocumentId: number
  ) =>
    selectedDocuments.find(
      (selectedDocument) =>
        selectedDocument.programDocId === programPolicyDocumentId
    ) !== undefined;

  useEffect(() => {
    const filteredDocuments = allProgramPolicyDocuments.filter(
      (programPolicyDocument) =>
        selectedPacketDocuments.find(
          (selectedPacketDocument) =>
            selectedPacketDocument.programDocId ===
            programPolicyDocument.programDocId
        ) === undefined
    );

    setDocumentsFilteredBySelectedPackets(filteredDocuments);
  }, [selectedPacketDocuments]);

  return (
    <div
      id="audit-document-packets-container-id"
      className={style.audit_document_divider_section_container}
    >
      <div className={style.divider_section_title_container}>
        <Font whiteSpace="normal" textAlign="start">
          INDIVIDUAL AUDIT DOCUMENTS
        </Font>
      </div>
      {documentsFilteredBySelectedPackets.map(
        (programPolicyDocument, index) => (
          <div
            key={`program-policy-document-container-${programPolicyDocument.programDocId}`}
            className={style.audit_single_document_container}
          >
            <Switch
              inputWidth="auto"
              key={`program-policy-document-checkbox-${programPolicyDocument.programDocId}`}
              id={`program-policy-document-checkbox-${programPolicyDocument.programDocId}`}
              tabIndex={index}
              control="checkbox"
              isChecked={getCheckedValueForIndividualDocument(
                programPolicyDocument.programDocId
              )}
              onChangeIsChecked={(checked) =>
                onChangeIndividualDocumentSelected(
                  programPolicyDocument,
                  checked
                )
              }
            />
            <Link
              key={`program-policy-document-link-${programPolicyDocument.programDocId}`}
              id={`program-policy-document-link-${programPolicyDocument.programDocId}`}
              linkFontType="BODY"
              displayValue={`${programPolicyDocument.docName}`}
              underline="hover"
              onClick={() => printIndividualDocument(programPolicyDocument)}
            />
          </div>
        )
      )}
      <DialogConfirmation
        id="no-template-message-id"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </div>
  );
};

export default AuditDocumentsIndividual;
