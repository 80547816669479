import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { DataTypeEnums } from "../../../../dtos/data-type-enums";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { MultiBaseTableGroupColumn } from "../../../../dtos/multi-base-table-group-column";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { TableGroupData } from "./InsuredInformationNamesAndAddresses";
import { columnsNamesAndAddresses } from "./InsuredInformationTableConfigurations";
import {
  einFormat,
  formatNumber,
  phoneMask,
  ssnFormat,
} from "../../../../utilities/stringFunctions";

const getCompleteAddress = (location: PolicyInsuredAddressBlob) => {
  return `${location?.address1} ${location?.address2} ${location?.addressCity}, ${location?.addressState} ${location?.addressPostalCode}`;
};
export const getColumnsAndDataForChildrenTable = (
  parentId,
  groupsAndContent
) => {
  const sourceObjectForSingleTable = groupsAndContent.find(
    (x) => x.name?.rowIndex === parentId
  );
  const dataForSingleTable =
    sourceObjectForSingleTable?.locations?.map(
      (location: PolicyInsuredAddressBlob) => {
        return [
          location?.addressID,
          location?.nameID,
          location?.locationNumber?.toString(),
          location?.addressType,
          location?.locationName,
          location?.address1,
          getCompleteAddress(location),
          location?.addressCity,
          location?.addressState,
          location?.addressPostalCode,
          location?.locationStatus?.toString(),
          FormattingDate(location?.effectiveDate),
          FormattingDate(location?.expirationDate),
          location?.addressPhoneNumber,
          location?.addressFaxNumber,
          location?.storeNumber,
          location?.fullTimeEE,
          location?.partTimeEE,
          location?.address2,
        ];
      }
    ) ?? [];

  return {
    columns: columnsNamesAndAddresses,
    data: dataForSingleTable,
  };
};

export const getGroupsForTable = (groupsAndContent: TableGroupData[]) =>
  groupsAndContent.map((group) => {
    return {
      columns: columnsNamesAndAddresses,
      groupUiidKey: group?.name?.rowIndex?.toString(),
      metaData: [
        {
          name: "ParentId",
          index: 0,
          dataType: DataTypeEnums.STRING,
          value: group?.name?.rowIndex?.toString(),
        },
      ] as BaseTableMetaData[],
      header: [
        {
          name: "NameLinkNumber",
          index: 0,
          dataType: DataTypeEnums.STRING,
          value: formatNumber(group?.name?.sequenceNumber ?? 0, 3),
        },
        {
          name: "BusinessType",
          index: 1,
          dataType: DataTypeEnums.STRING,
          value: group?.name?.businessType,
        },
        {
          name: "TaxID",
          index: 2,
          dataType: DataTypeEnums.STRING,
          value:
            group?.name?.taxIdType === "SSN"
              ? ssnFormat(group?.name?.taxID ?? "")
              : einFormat(group?.name?.taxID ?? ""),
        },
        {
          name: "LegalName",
          index: 3,
          dataType: DataTypeEnums.STRING,
          value: group?.name?.legalName,
        },
      ] as BaseTableMetaData[],
    };
  }) as MultiBaseTableGroupColumn[];

const getUpdatedLocations = (
  newLocationData?: PolicyInsuredAddressBlob,
  locations?: PolicyInsuredAddressBlob[]
) =>
  locations !== undefined
    ? locations.map((location) =>
        location.rowIndex !== newLocationData?.rowIndex
          ? location
          : newLocationData ?? {}
      )
    : [];

const getUpdatedLocationsForNewAddress = (
  newLocationData?: PolicyInsuredAddressBlob,
  locations?: PolicyInsuredAddressBlob[]
) => {
  const locationArray: PolicyInsuredAddressBlob[] =
    newLocationData !== undefined ? [newLocationData] : [];
  const currentLocations: PolicyInsuredAddressBlob[] =
    locations !== undefined ? locations : [];
  return [...currentLocations, ...locationArray];
};

export const getUpdatedGroupData = (
  tableGroupData: TableGroupData[],
  parentKey: number | null,
  newLocationData?: PolicyInsuredAddressBlob,
  isNewAddress?: boolean
) => {
  return (
    tableGroupData?.map((groupData) => {
      if (groupData.name?.rowIndex === parentKey) {
        if (isNewAddress) {
          groupData.locations = getUpdatedLocationsForNewAddress(
            newLocationData,
            groupData.locations
          );
        } else {
          groupData.locations = getUpdatedLocations(
            newLocationData,
            groupData.locations
          );
        }
      }
      return groupData;
    }) ?? []
  );
};

export const getUpdatedGroupDataAfterDeleteAddress = (
  tableGroupData: TableGroupData[],
  nameIndex: number,
  locationIndex: number
) => {
  const newTableGroupData = structuredClone(tableGroupData);
  newTableGroupData[nameIndex]?.locations?.splice(locationIndex, 1);
  newTableGroupData[nameIndex]?.locations?.forEach((location, index) => {
    location.rowIndex = index + 1;
  });
  return newTableGroupData;
};

export const getInsuredAddressDtoByRowData = (
  row: any,
  parentIndex: number,
  rowIndex: number
) =>
  ({
    addressID: row?.AddressId,
    nameID: row?.NameId,
    addressType: row?.LocationType,
    locationName: row?.LocationName,
    address1: row?.LocationAddress,
    address2: row?.LocationAddress2,
    addressCity: row?.LocationCity,
    addressState: row?.LocationState,
    addressPostalCode: row?.LocationZip,
    addressPhoneNumber: phoneMask(row?.Phone ?? ""),
    addressPhoneNumberNoFormat: row?.Phone,
    addressFaxNumber: phoneMask(row?.Fax ?? ""),
    addressFaxNumberNoFormat: row?.Phone,
    fullTimeEE: row?.FullTimeEE,
    partTimeEE: row?.PartTimeEE,
    locationNumber: row?.Number,
    storeNumber: row?.StoreNumber,
    locationStatus: row?.LocationStatus,
    effectiveDate: row?.LocationEffDate,
    expirationDate: row?.LocationExpDate,
    parentRowIndex: parentIndex,
    rowIndex: rowIndex,
  } as PolicyInsuredAddressBlob);
