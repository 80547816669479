import { atom, atomFamily } from "recoil";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import {
  ConditionForCellResponse,
  TableInstanceType2,
} from "./BaseTable2/TableProperties";

export const tableInstanceAtomFamily2 = atomFamily<
  TableInstanceType2 | null,
  string
>({
  key: "tableInstanceAtomFamily2",
  default: null,
});

export const tableCellInstanceAtomFamily = atomFamily<
  ConditionForCellResponse,
  string
>({
  key: "tabelCellInstanceAtomFamily",
  default: {
    cellKey: "",
    isEditable: false,
    errorMessage: null,
    typeId: null,
    variant: "standard",
    value: "",
    apiOptions: null,
  },
});

export type ConditionForCellResponse_TEMP = {
  rowKey: string | null;
  isVisible: boolean;
  cells: CellManagerCell[] | null;
};

export type CellManagerCell = {
  cellKey: string;
  currentValue: string | null;
  originalValue: string | null;
  previousValue: string | null;
  column: BaseTableColumn;
  isEditable: boolean;
  isRowHidden: boolean;
  listDisplayOptions: BaseTableDisplayOptions[] | null;
  typeId: BaseTableInputType | null;
  refreshCellManager: boolean;
};

export const tableCellManagerAtomFamily = atomFamily<
  ConditionForCellResponse_TEMP,
  string
>({
  key: "tableCellManagerAtomFamily",
  default: {
    rowKey: null,
    isVisible: true,
    cells: null,
  },
  // effects: [
  //   ({ onSet }) => {
  //     onSet((newID) => {
  //       console.debug("Current user ID:", newID);
  //     });
  //   },
  // ],
});

export const tableCellInstanceAtomFamilyIds = atom<
  { rowKey: string; fieldName: string; columnIndex: number; cellKey: string }[]
>({
  key: "tabelCellInstanceAtomFamilyIds",
  default: [],
});

export const tableCellManagerKeys = atom<string[]>({
  key: "tableCellManagerKeys",
  default: [],
});

// export const interceptedChangesAtom = atom<{cellChanges: any
// // {cellChanges: BaseTableCellSelectOnChangeProperties; responseType: any; // TODO - get rid of this}
//  | null}>({
//   key: "interceptedChangesAtom",
//   default: null,
// });

export const interceptedChangesAtom = atom<any | null>({
  key: "interceptedChangesAtom",
  default: null,
});

export const cellManagerCells = atomFamily<CellManagerCell[], string>({
  key: "cellManagerCells",
  default: [],
});
