import { FC } from "react";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { ScoreChip } from "../../TrueUI";
// import { ScoreChip } from "../..";
// import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

// export type BaseTableTextCellProperties2 = {
//   itemKey: string;
//   // data: any[];
//   // propName: string;
//   //onChange: (e?: any) => void;
//   tableUIID: string;
//   value: number;
// };

interface BaseTableTextCellProperties2 extends BaseTableCellSelectProperties {}

const ScoreChipCell2: FC<BaseTableTextCellProperties2> = ({ value }) => {
  return <ScoreChip value={value} />;
};

export default ScoreChipCell2;
