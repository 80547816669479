import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { PolicyPeriodEnums } from "../../../../dtos/policy-period-enums";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { BasePageContainer, BaseTable2 } from "../../../TrueUI";

import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { usePermissions } from "../../../../hooks";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { Box, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ProgramPolicyPeriodModal from "./ProgramPolicyPeriodModal";

const tableName = "program_policy_period_table";

type PolicyPeriodModalInfo = {
  isOpen: boolean;
  policyPeriodId: number;
};

const ProgramPolicyPeriod: FC = () => {
  const [policyPeriodModalInfo, setPolicyPeriodModalInfo] =
    useState<PolicyPeriodModalInfo>({ isOpen: false, policyPeriodId: -1 });

  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const { programId } = useParams();

  const addPolicyPeriod = () => {
    setPolicyPeriodModalInfo({ policyPeriodId: -1, isOpen: true });
  };

  useEffect(() => {
    if (refreshTable) {
      getTableMethods.methods.refreshTable();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        {["Current", "Future"].includes(actionOptions?.row?.Status ?? "") && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setPolicyPeriodModalInfo({
                policyPeriodId: actionOptions?.row?.PolicyPeriodId ?? 0,
                isOpen: true,
              })
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  const hasPermissionToAddAgency = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
    PermissionsEnums.TRUE_ADMIN,
  ]);
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PolicyPeriod/GetPolicyPeriodPage/?programId=${programId}`,
    refreshTable,
    advancedOptions: {
      overrideColumnDefaultValues: [
        {
          columnFieldName: "ProgramId",
          propName: "defaultValue",
          value: programId,
        },
      ],
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    filterOptions: [
      (actionOptions) => {
        return BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["StatusId"],
          optionsOverride: policyPeriodTypes,
        });
      },
    ],
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showExcelButton: false,
      showPDFButton: false,
      showEditButton: false,
      showAddButton: hasPermissionToAddAgency?.hasPermission ?? false,
    },
    events: { addEventOverrideCallback: addPolicyPeriod },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const getTableMethods = tableMethods[tableName];

  const policyPeriodTypes = [{ displayName: "All", stringValue: "all" }].concat(
    FromEnumToArrayWithExceptions(PolicyPeriodEnums, [
      PolicyPeriodEnums.DELETED,
    ]).map((enumType) => ({
      displayName: enumType?.key ?? "",
      stringValue: enumType?.value.toString(),
    }))
  );

  return (
    <>
      <BasePageContainer
        id="program-policy-period"
        sxContainer={{ mt: 5 }}
        maxWidth={false}
        data
      >
        <div style={{ overflowY: "auto", height: "84vh", width: "100%" }}>
          <BaseTable2 name={tableName} />
        </div>
      </BasePageContainer>
      <ProgramPolicyPeriodModal
        policyPeriodId={policyPeriodModalInfo.policyPeriodId}
        programId={programId}
        isOpen={policyPeriodModalInfo.isOpen}
        setIsOpen={(value) =>
          setPolicyPeriodModalInfo({ ...policyPeriodModalInfo, isOpen: value })
        }
        setRefreshTable={(value) => setRefreshTable(value)}
      />
    </>
  );
};

export default ProgramPolicyPeriod;
