import { AgencyContactDto } from "../../../../dtos/agency-contact-dto";
import { AgencyInformationDto } from "../../../../dtos/agency-information-dto";
import { AgencyLocationDto } from "../../../../dtos/agency-location-dto";
import { PolicyEnumValueCodeAndDescriptionBlob } from "../../../../dtos/policy-enum-value-code-and-description-blob";
import { PolicyInsuredBlob } from "../../../../dtos/policy-insured-blob";
import { PolicyLimitDto } from "../../../../dtos/policy-limit-dto";
import { PolicyProducerBlob } from "../../../../dtos/policy-producer-blob";
import { PolicyProgramBlob } from "../../../../dtos/policy-program-blob";
import { SelectOptions } from "../../../../dtos/select-options";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { phoneMask } from "../../../../utilities/stringFunctions";

export const getPolicyEnumValueCodeAndDescriptionAsOptions = (
  optionList: PolicyEnumValueCodeAndDescriptionBlob[]
) =>
  optionList?.map((option) => {
    return {
      displayName: option.description,
      intValue: option.value,
    } as SelectOptions;
  }) ?? [];

export const getPolicyEnumBySelectOption = (
  options: SelectOptions[],
  optionId?: number | null,
  code: string = "code"
) => ({
  value: optionId,
  [code]: optionId?.toString(),
  description: options.find((option) => option.intValue === optionId)
    ?.displayName,
});

export const getAgencyInformationAsOptions = (
  optionList: AgencyInformationDto[]
) =>
  [
    {
      displayName: "Direct",
      intValue: -1,
    } as SelectOptions,
  ].concat(
    optionList?.map((option) => {
      return {
        displayName: option.agencyName,
        intValue: option.agencyId,
      } as SelectOptions;
    }) ?? []
  );

export const getAgencyLocationAsOptions = (optionList: AgencyLocationDto[]) =>
  optionList?.map((option) => {
    return {
      displayName: option.agencyLocationName,
      intValue: option.agencyLocationId,
    } as SelectOptions;
  }) ?? [];

export const getAgencyContactAsOptions = (optionList: AgencyContactDto[]) =>
  optionList?.map((option) => {
    return {
      displayName: `${option.firstName} ${option.lastName}`,
      intValue: option.agencyContactId,
    } as SelectOptions;
  }) ?? [];

export const getProgramsAsOptions = (optionList: PolicyProgramBlob[]) =>
  optionList?.map((option) => {
    return {
      displayName: option.programName,
      intValue: option.programID,
    } as SelectOptions;
  }) ?? [];

export const getPolicyLimitsAsOptions = (optionList: PolicyLimitDto[]) =>
  optionList?.map((option) => {
    return {
      displayName: option.displayValue,
      intValue: option.id,
    } as SelectOptions;
  }) ?? [];

export const onChangeAndDispatchAgency = (
  agencyId: number,
  agencies: AgencyInformationDto[],
  setInsuredAgency?: (insuredAgency: PolicyProducerBlob) => void
) => {
  const agencyFound = agencies.find((options) => options.agencyId === agencyId);

  setInsuredAgency?.({
    agency: {
      agencyID: agencyFound?.agencyId ?? -1,
      agencyName: agencyFound?.agencyName ?? "Direct",
      agencyCode: agencyFound?.agencyCode,
      agencyAddress: agencyFound?.address1,
      agencyCity: agencyFound?.city,
      agencyStateCode: agencyFound?.state,
      agencyPostalCode: agencyFound?.postCode,
      agencyPhoneNumber: phoneMask(agencyFound?.phoneNumber ?? ""),
      agencyPhoneNumberNoFormat: agencyFound?.phoneNumber,
    },
  } as PolicyProducerBlob);
};

export const onChangeAndDispatchLocation = (
  locationId: number,
  locations?: AgencyLocationDto[],
  insuredAgency?: PolicyProducerBlob,
  setInsuredAgency?: (insuredAgency: PolicyProducerBlob) => void
) => {
  const locationFound = locations?.find(
    (options) => options.agencyLocationId === locationId
  );
  setInsuredAgency?.({
    agency: { ...insuredAgency?.agency },
    location: {
      locationID: locationFound?.agencyLocationId,
      locationName: locationFound?.agencyLocationName,
      locationNumber: locationFound?.agencyLocationNumber,
      locationAddress: locationFound?.address1,
      locationCity: locationFound?.city,
      locationStateCode: locationFound?.state,
      locationPostalCode: locationFound?.postCode,
      locationPhoneNumber: phoneMask(locationFound?.phoneNumber ?? ""),
      locationPhoneNumberNoFormat: locationFound?.phoneNumber,
      locationEffectiveDate: locationFound?.effectiveDate,
      locationExpirationDate: locationFound?.expirationDate,
    },
  } as PolicyProducerBlob);
};

export const onChangeProducer = (
  producerId: number,
  producers?: AgencyContactDto[],
  insuredAgency?: PolicyProducerBlob,
  setInsuredAgency?: (insuredAgency: PolicyProducerBlob) => void
) => {
  const producerFound = producers?.find(
    (options) => options.agencyContactId === producerId
  );
  setInsuredAgency?.({
    ...insuredAgency,
    agent: {
      agentID: producerFound?.agencyContactId,
      agentFirstName: producerFound?.firstName,
      agentLastName: producerFound?.lastName,
      agentPhoneNumber: phoneMask(producerFound?.agencyContactPhone ?? ""),
      agentPhoneNumberNoFormat: producerFound?.agencyContactPhone,
      agentEmail: producerFound?.agencyContactEmail,
    },
  } as PolicyProducerBlob);
};

export const onChangeCustomerId = (
  customerId: string,
  insuredAgency?: PolicyProducerBlob,
  setInsuredAgency?: (insuredAgency: PolicyProducerBlob) => void
) => {
  setInsuredAgency?.({
    ...insuredAgency,
    agency: { ...insuredAgency?.agency, agencyCustomerID: customerId },
  } as PolicyProducerBlob);
};

export const onChangeProgram = (
  programId: number,
  programs: PolicyProgramBlob[],
  setProgram: (program: PolicyProgramBlob) => void
) => {
  const programFound = programs.find(
    (options) => options.programID === programId
  );
  setProgram({
    programID: programFound?.programID,
    programName: programFound?.programName,
    programCode: programFound?.programCode,
    carrierCode: programFound?.carrierCode,
    issuingName: programFound?.issuingName,
    issuingAddress: programFound?.issuingAddress,
    issuingStateCode: programFound?.issuingStateCode,
    issuingPostalCode: programFound?.issuingPostalCode,
    issuingContact: programFound?.issuingContact,
    issuingEmail: programFound?.issuingEmail,
    issuingPhone: phoneMask(programFound?.issuingPhone ?? ""),
    issuingPhoneNoFormat: programFound?.issuingPhone,
    issuingFax: phoneMask(programFound?.issuingFax ?? ""),
    issuingFaxNoFormat: programFound?.issuingFax,
  });
};

export const getInsuredTaxId = (insured?: PolicyInsuredBlob | null) =>
  insured?.taxIDType === "SSN" ? insured.ssn : insured?.ein;

export const getTaxIdType = (taxIDType?: string | null) =>
  ["", "SSN"].includes(taxIDType ?? "") ? "SSN" : "FEIN";

export const onChangeInsuredTaxId = (
  value: string,
  type: string,
  insured?: PolicyInsuredBlob | null,
  setInsured?: (insured: PolicyInsuredBlob) => void
) => {
  if (!isEmptyValue(type)) {
    setInsured?.({
      ...insured,
      taxID: value,
      taxIDType: type,
      ssn: type === "SSN" ? value : null,
      ein: type === "FEIN" ? value : null,
    });
  } else {
    setInsured?.({
      ...insured,
      taxID: value,
      taxIDType: "",
    });
  }
};
