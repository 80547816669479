import { BaseTableColumn } from "../../../dtos/base-table-column";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { MultiBaseTableGroupColumn } from "../../../dtos/multi-base-table-group-column";
import { FormattingDate } from "../../../utilities/dateFunctions";
import {
  isJsonString,
  splitPhoneNumberAndExtension,
} from "../../../utilities/stringFunctions";
import { BaseTableChildrenData, TableType } from "./BaseTable2/TableProperties";
import {
  getTagsAsCommaSeparatedString,
  isNumericDataType,
} from "./tableFunctions";
import { WorkbookSheetRowCell, WorkbookSheetRow } from "@progress/kendo-ooxml";

export const formatColumnForExcelExport = (columns: BaseTableColumn[]) =>
  columns.map((column) => ({
    field: column?.fieldName,
    locked: true,
    width: 150,
  }));

const getAlternateDisplayValueForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) =>
  column.alternateDisplayValues?.find(
    (currentAlternateDisplayValue) =>
      currentAlternateDisplayValue.guid === tableValue
  )?.displayValuesOrFieldNames?.[0] ?? "";

const getTagOrOptionValueForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) => {
  if (isJsonString(tableValue)) {
    return getTagsAsCommaSeparatedString(
      tableValue,
      column?.displayOptions ?? []
    );
  } else {
    const columnOptionValues = column.displayOptions?.find(
      (x) => x.value === tableValue
    );

    return columnOptionValues?.text ?? "";
  }
};

const getFormattedPhoneNumberForExportToExcel = (tableValue: string) => {
  const match = splitPhoneNumberAndExtension(tableValue);
  const phoneNumber = match?.[1] ?? "";
  const extension = match?.[2] ? `-${match?.[2]}` : "";
  return `${phoneNumber}${extension}`;
};

const getCorrectValueByColumnTypeForExportToExcel = (
  column: BaseTableColumn,
  tableValue: string
) => {
  if (
    column.type === BaseTableInputType.LINK ||
    column.type === BaseTableInputType.DOWNLOAD_LINK
  ) {
    return getAlternateDisplayValueForExportToExcel(column, tableValue);
  }
  if (
    column.type === BaseTableInputType.SELECT ||
    column.type === BaseTableInputType.TAGS
  ) {
    return getTagOrOptionValueForExportToExcel(column, tableValue);
  }
  if (column.type === BaseTableInputType.PHONE_AND_EXTENSION) {
    return getFormattedPhoneNumberForExportToExcel(tableValue);
  }
  if (
    column.type === BaseTableInputType.DATE_PICKER &&
    column.defaultValueType === DataTypeEnums.DATE
  ) {
    return FormattingDate(tableValue as any);
  }

  return tableValue;
};

const getAlignedHeaderForExportToExcel = (
  group: MultiBaseTableGroupColumn,
  columns: BaseTableColumn[]
) => {
  return (
    columns.map((column) => {
      const headerProp = group.header.find(
        (param) => column.fieldName === param.name
      );
      return headerProp?.value ?? "";
    }, {}) ?? null
  );
};

const getHeaderTextForExportToExcel = (
  group: MultiBaseTableGroupColumn,
  columns: BaseTableColumn[]
) =>
  columns.map((_column, index) => {
    if (index === 0) {
      return group.header.map((param) => param.value).join(" ");
    }
    return "";
  });

const hydrateMultiTableDataWithConfigurationForExportExcel = (
  columns: BaseTableColumn[],
  groups: MultiBaseTableGroupColumn[],
  children: BaseTableChildrenData[],
  isAlignedHeader: boolean
) =>
  groups
    .map((group) => {
      const parentData = isAlignedHeader
        ? getAlignedHeaderForExportToExcel(group, columns)
        : getHeaderTextForExportToExcel(group, columns);
      const parentRow = hydrateDataWithConfigurationForExportExcel(
        columns,
        [parentData],
        true
      );
      const childTableData = children.find(
        (childTable) => childTable.groupId.toString() === group.groupUiidKey
      );
      const childrenRows = hydrateDataWithConfigurationForExportExcel(
        columns,
        childTableData?.data ?? []
      );
      const mergedData = parentRow.concat(childrenRows);
      return mergedData;
    })
    .flatMap((groupDataToExport) => {
      return groupDataToExport;
    });

const getTopHeaderRowForExcelExport = (columns: BaseTableColumn[]) =>
  [
    {
      cells: columns.map((column) => {
        return {
          value: column.displayName ?? "",
          format: "General",
          background: "#7A7A7A",
          color: "#FFFFFF",
          textAlign: "center",
          bold: true,
        } as WorkbookSheetRowCell;
      }),
    },
  ] as WorkbookSheetRow[];

const MULTI_TABLE_PARENT_DEFAULT_STYLE = {
  background: "#BFBFBF",
  color: "#000000",
  bold: true,
};

const getParentMultiTableStyle = (isProcessingMultiTableHeader?: boolean) =>
  isProcessingMultiTableHeader ? MULTI_TABLE_PARENT_DEFAULT_STYLE : {};

const hydrateDataWithConfigurationForExportExcel = (
  columns: BaseTableColumn[],
  data: string[][],
  isProcessingMultiTableHeader?: boolean
) =>
  data.map((row: string[]) => {
    return {
      cells: (columns ?? []).map((column, index) => {
        const columnIndex = isProcessingMultiTableHeader
          ? index
          : column._columnIndex;
        const correctTableValueFormat = isProcessingMultiTableHeader
          ? row[columnIndex]
          : getCorrectValueByColumnTypeForExportToExcel(
              column,
              row[columnIndex]
            );

        const isNumericColumn = isNumericDataType(
          column.defaultValueType ?? -1
        );
        const notANumberPattern = /[^0-9.,()]/g;
        // For now this "notANumberPattern" has to stay as a regex function because a number wrapped in a parenthesis is a valid negative format number
        // in the system atm and we need to support the actual cast and convertion to a negative number when exporting.

        const invalidNumberPattern = /[^0-9.]/g;
        // This is used to replace all the "," and "(", ")" that may be in the string as a representation of a string number using the replace function.

        const isNegativeStringNumber = correctTableValueFormat.includes("(");
        const isNumber = !notANumberPattern.test(correctTableValueFormat);

        if (
          correctTableValueFormat !== "" &&
          isNumber &&
          isNegativeStringNumber
        ) {
          //It's a negative number (wrapped in parenthesis).
          return {
            value:
              parseFloat(
                correctTableValueFormat.replaceAll(invalidNumberPattern, "")
              ) * -1,
            format: isNumericColumn ? "#,##0.0####" : null,
            ...getParentMultiTableStyle(isProcessingMultiTableHeader),
          } as WorkbookSheetRowCell;
        }
        if (
          correctTableValueFormat !== "" &&
          isNumber &&
          !isNegativeStringNumber
        ) {
          //it's a string representation of a number.
          return {
            value: parseFloat(
              correctTableValueFormat.replaceAll(invalidNumberPattern, "")
            ),
            format: isNumericColumn ? "#,##0.0####" : null,
            ...getParentMultiTableStyle(isProcessingMultiTableHeader),
          } as WorkbookSheetRowCell;
        }

        return {
          value: correctTableValueFormat,
          ...getParentMultiTableStyle(isProcessingMultiTableHeader),
        } as WorkbookSheetRowCell;
      }, {}),
    };
  }) as WorkbookSheetRow[];

export const getRowDataToExportToExcel = (
  tableType: TableType,
  filteredColumns: BaseTableColumn[],
  groups: MultiBaseTableGroupColumn[],
  childrenData: BaseTableChildrenData[],
  isAlignedHeader: boolean,
  regularTableData: string[][]
) => {
  const topHeaderRowWithColumnTitles =
    getTopHeaderRowForExcelExport(filteredColumns);

  if (tableType === "multi") {
    return topHeaderRowWithColumnTitles.concat(
      hydrateMultiTableDataWithConfigurationForExportExcel(
        filteredColumns,
        groups,
        childrenData,
        isAlignedHeader
      )
    );
  } else {
    return topHeaderRowWithColumnTitles.concat(
      hydrateDataWithConfigurationForExportExcel(
        filteredColumns,
        regularTableData
      )
    );
  }
};
