import { FC } from "react";
import { Font, Heading6 } from "..";
import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import { TaskBoardCardIconEnum } from "../../../dtos/task-board-card-icon-enum";
import { Icon as KendoIcon } from "@progress/kendo-react-common";
import style from "./taskBoard.module.css";
import { Avatar } from "@mui/material";
import { SelectedCardType } from "./TaskBoard";

type TaskBoardCardProps = {
  card: TaskBoardCardDto;
  isSelected: boolean;
  columnField: string;
  onClickCard: (card: SelectedCardType) => void;
};

const TaskBoardCard: FC<TaskBoardCardProps> = ({
  card,
  isSelected,
  columnField,
  onClickCard,
}) => {
  const {
    cardTitle,
    assignedUser,
    userInitials,
    userPreferenceColor,
    count,
    cardColor,
    iconType,
  } = card;

  const _onClickCard = () => {
    const cardInformation: SelectedCardType = {
      columnField: columnField,
      cardInformation: card,
    };
    onClickCard?.(cardInformation);
  };

  return (
    <div
      className={`${style.card} ${isSelected && style.selected_card}`}
      style={{ borderLeft: `3px solid ${cardColor}` }}
      onClick={_onClickCard}
    >
      <div className={style.title}>
        <Font truncate>{cardTitle}</Font>
        <Heading6>{count}</Heading6>
      </div>
      <div className={style.assigned_container}>
        {iconType !== null &&
          (iconType === TaskBoardCardIconEnum.TEAM ? (
            <KendoIcon className={style.team_icon} name={"tell-a-friend"} />
          ) : (
            <Avatar
              className={style.user_icon}
              sx={{ background: userPreferenceColor }}
            >
              {userInitials}
            </Avatar>
          ))}
        <Font>{assignedUser}</Font>
      </div>
    </div>
  );
};

export default TaskBoardCard;
