import { ApplicationQuestionDto } from "../../../../dtos/application-question-dto";
import { PolicyApplicationQuestionBlob } from "../../../../dtos/policy-application-question-blob";
import { getObjectWithPropertiesFiltered } from "../../../../utilities/objectFunctions";

export const getApplicationQuestionsAsBlob = (
  applicationQuestions: ApplicationQuestionDto[]
): PolicyApplicationQuestionBlob[] =>
  applicationQuestions.map((question) => {
    return {
      applicationQuestionId: question.applicationQuestionId,
      questionStatus: question.questionStatus,
      questionNumber: question.questionNumber,
      questionText: question.questionText,
      questionType: question.questionType,
      questionRequired: question.questionRequired,
      requireComment: question.requireComment,
      options: question.options,
      isChecked: false,
      comment: "",
    };
  });

export const getGeneralInformationWithQuestionUpdated = (
  applicationQuestions: PolicyApplicationQuestionBlob[],
  applicationQuestionToUpdate: PolicyApplicationQuestionBlob
): PolicyApplicationQuestionBlob[] =>
  applicationQuestions.map((question) => {
    if (
      question.applicationQuestionId ===
      applicationQuestionToUpdate.applicationQuestionId
    )
      return { ...applicationQuestionToUpdate };
    else return { ...question };
  });

export const updateApplicationQuestionValues = (
  checked: boolean,
  textboxValue: string,
  applicationQuestion: PolicyApplicationQuestionBlob
): PolicyApplicationQuestionBlob => ({
  ...applicationQuestion,
  isChecked: checked,
  comment: textboxValue,
});

export const updateApplicationQuestionErrors = (
  applicationQuestions: PolicyApplicationQuestionBlob[],
  errorDetails?: { [key: string]: Object }
): PolicyApplicationQuestionBlob[] =>
  applicationQuestions.map((question, index) => {
    const errors = errorDetails?.[`generalInformation[${index}]`] as string[];
    return { ...question, errorMessages: errors };
  });

export const removeAllApplicationQuestionErrors = (
  applicationQuestions: PolicyApplicationQuestionBlob[]
) =>
  applicationQuestions.map((question) => {
    return getObjectWithPropertiesFiltered(question, "errorMessages", true);
  });

export const getInsuredInformationErrors = (errorDetails?: {
  [key: string]: Object;
}) => {
  if (errorDetails !== undefined) {
    const newInsuredInformationErrors = getObjectWithPropertiesFiltered(
      errorDetails,
      "generalInformation",
      true
    ) as {
      [key: string]: Object;
    };

    return newInsuredInformationErrors;
  }
  return {};
};
