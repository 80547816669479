export const spreadDetailsContainersInformationWithTarget = (
  atomValue,
  targetContainer,
  targetValue
) => {
  return {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: {
          ...atomValue?.claimTabsInformation?.claimDetailsTab
            ?.detailsContainersInformation,
          [targetContainer]: targetValue,
        },
      },
    },
  };
};

export const spreadIsExpandedWithInformation = (
  atomValue,
  detailContainersInformationValue,
  isExpandedValue
) => {
  return {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: {
          ...detailContainersInformationValue,
          detailsContainerData: {
            ...detailContainersInformationValue?.detailsContainerData,
            isExpanded: isExpandedValue,
          },
          claimantContainerData: {
            ...detailContainersInformationValue?.claimantContainerData,
            isExpanded: isExpandedValue,
          },
          incidentContainerData: {
            ...detailContainersInformationValue?.incidentContainerData,
            isExpanded: isExpandedValue,
          },
          wagesContainerData: {
            ...detailContainersInformationValue?.wagesContainerData,
            isExpanded: isExpandedValue,
          },
          bodyPartContainerData: {
            ...detailContainersInformationValue?.bodyPartContainerData,
            isExpanded: isExpandedValue,
            isTableOnEditMode: false,
            changedBodyParts: [],
          },
          dependentsContainerData: {
            ...detailContainersInformationValue.dependentsContainerData,
            isExpanded: isExpandedValue,
            isTableOnEditMode: false,
          },
        },
      },
    },
  };
};

export const spreadDetailsContainersInformation = (
  atomValue,
  detailContainersInformationValue
) => {
  return {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: detailContainersInformationValue,
      },
    },
  };
};

export const spreadIsExpanded = (atomValue, isExpandedValue: boolean) => {
  return {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: {
          ...atomValue?.claimTabsInformation?.claimDetailsTab
            ?.detailsContainersInformation,
          detailsContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.detailsContainerData,
            isExpanded: isExpandedValue,
          },
          claimantContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.claimantContainerData,
            isExpanded: isExpandedValue,
          },
          incidentContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.incidentContainerData,
            isExpanded: isExpandedValue,
          },
          wagesContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.wagesContainerData,
            isExpanded: isExpandedValue,
          },
          bodyPartContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.bodyPartContainerData,
            isExpanded: isExpandedValue,
            isTableOnEditMode: false,
          },
          dependentsContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.dependentsContainerData,
            isExpanded: isExpandedValue,
            isTableOnEditMode: false,
          },
        },
      },
    },
  };
};

export const spreadChangedBodyParts = (atomValue, currentTableData) => {
  /**
   *
   * The changedBodyParts must inlcude the changed data edited/deleted in the table,
   * but only those that were edited/deleted or added
   * 1: Get deleted to add to changedBodyParts
   * 2: Get the new rows
   * 3: Get non deleted and not new present un the currentTableData
   *  3.1: Compare rows from point 3 with current changedBodyParts  using rowkey
   *   3.1.2: If are unchaged ommit from changedBodyParts
   *   3.1.3: If are different add to changedBodyParts
   */

  const deletedChangedBodyParts = //To form the new/updated changedBodyParts
    currentTableData?.filter(
      (changedBodyPart) => changedBodyPart?.isBaseTableRowDeleted === true
    ) ?? [];

  const nonDeletedChangedBodyPartsFromTable = //To look for new rows
    currentTableData?.filter(
      (changedBodyPart) => changedBodyPart?.isBaseTableRowDeleted === false
    ) ?? [];
  const currentAtomChangedBodyPartsRowKeys = //To get rows in Atom before update it, and contrast with current table data to get new rows
    atomValue?.claimTabsInformation?.claimDetailsTab?.detailsContainersInformation?.bodyPartContainerData?.changedBodyParts?.map(
      (changedBodyPart) => changedBodyPart?.baseTableRowKey
    ) ?? [];

  const newChangedBodyPartsFromTable = //To form the new/updated changedBodyParts
    nonDeletedChangedBodyPartsFromTable.filter(
      (tableRow) =>
        currentAtomChangedBodyPartsRowKeys.includes(
          tableRow?.baseTableRowKey
        ) === false
    );
  const possibleChangedBodyPartsFromTable = //To compare with current atom changedBodyParts those with matching row keys will be compared if are equal will be left out of new/updated changedBodyParts
    nonDeletedChangedBodyPartsFromTable.filter(
      (tableRow) =>
        currentAtomChangedBodyPartsRowKeys.includes(
          tableRow?.baseTableRowKey
        ) === true
    );

  const newAtomWithChangedBodyParts = {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: {
          ...atomValue?.claimTabsInformation?.claimDetailsTab
            ?.detailsContainersInformation,
          bodyPartContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.bodyPartContainerData,
            changedBodyParts: [
              ...newChangedBodyPartsFromTable,
              ...deletedChangedBodyParts,
              ...possibleChangedBodyPartsFromTable,
            ],
            isExpanded: true,
          },
        },
      },
    },
  };

  return newAtomWithChangedBodyParts;
};

export const spreadDependents = (atomValue, targetValue) => {
  return {
    ...atomValue,
    claimTabsInformation: {
      ...atomValue?.claimTabsInformation,
      claimDetailsTab: {
        ...atomValue?.claimTabsInformation?.claimDetailsTab,
        detailsContainersInformation: {
          ...atomValue?.claimTabsInformation?.claimDetailsTab
            ?.detailsContainersInformation,
          dependentsContainerData: {
            ...atomValue?.claimTabsInformation?.claimDetailsTab
              ?.detailsContainersInformation?.dependentsContainerData,
            changedClaimantDependent: targetValue,
            isExpanded: true,
          },
        },
      },
    },
  };
};

export const spreadSelectedForms = (atomValue, targetValue) => ({
  ...atomValue,
  claimTabsInformation: {
    ...atomValue?.claimTabsInformation,
    claimCorrespondenceTab: {
      ...atomValue?.claimTabsInformation?.claimCorrespondenceTab,
      selectedClaimForms: targetValue,
    },
  },
});

export const spreadSwitchJurisdiction = (atomValue, targetValue) => ({
  ...atomValue,
  claimTabsInformation: {
    ...atomValue?.claimTabsInformation,
    claimCorrespondenceTab: {
      ...atomValue?.claimTabsInformation?.claimCorrespondenceTab,
      switchJurisdiction: targetValue,
    },
  },
});
