import { SelectOptions } from "../../../../dtos/select-options";
import { Select } from "../../../TrueUI";
import { useBaseGridFilters } from "../../../BaseGrid/Hooks/useBaseGridFilters";
import { BaseGridFilterOptionsParameter } from "../../../BaseGrid/BaseGridProperties";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { usePermissions } from "../../../../hooks";
import { SEARCH_FILTER } from "../../../TrueUI/Tables/TableConstants";
import { useState } from "react";

type ToggleColumnsByOptionsProps = {
  options: string[];
  columnsToToggle: string[];
};

type BaseGridDropDownFilterProperties = {
  options: BaseGridFilterOptionsParameter;
  id: string;
  columnNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  toggleColumnsByOptions?: ToggleColumnsByOptionsProps[];
  usingExternalFiltering?: boolean;
  isStaticFilter?: boolean;
  validatingAgainstDisplayValue?: boolean;
  selectWidth?: string;
  onChange?: (e: any) => void;
  permissions?: PermissionsEnums[];
};

const CustomProgramAccessFilter: (
  properties: BaseGridDropDownFilterProperties
) => any = (props) => {
  const hasPermission = usePermissions(props?.permissions ?? []).hasPermission;
  const [localValue, setValue] = useState(props?.defaultValue);
  const filterHook = useBaseGridFilters(
    props?.options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: props?.id,
      filterName: SEARCH_FILTER,
      defaultValue: props?.defaultValue,
      columnNames: props?.columnNames,
      resetOnManuallyReload: true,
    },
    "BaseGridDropDownFilterAsSearch"
  );

  return (
    <Select
      id={`true-filter-for-table-${props?.id}`}
      name={`filter-dropdown-${props?.id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={props?.labelText ?? "Show:"}
      labelPosition={props?.labelPosition ?? "start"}
      options={props?.optionsOverride ?? []}
      value={localValue ?? "all"}
      type={"tableFilter"}
      inputWidth={props?.selectWidth ? props?.selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      data-base-grid-filter-column-names={props?.columnNames ?? []}
      onChange={(value) => {
        filterHook?.onChange?.(value);
        props?.onChange?.(value);
        setValue(value);
      }}
      disabled={!hasPermission}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
    />
  );
};

export default CustomProgramAccessFilter;
