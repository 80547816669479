import { FC, useEffect } from "react";
import { Col, Divider, FontBold, Row } from "../../../../../TrueUI";
import { ClaimCorrespondenceCategoriesProps } from "./typesAndConstants";
import CategoryCheckbox from "./CategoryCheckbox";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { ProgramClaimFormDto } from "../../../../../../dtos/program-claim-form-dto";
import { spreadSelectedForms } from "../spreadClaimTabsFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";

const ClaimCorrespondenceCategories: FC<ClaimCorrespondenceCategoriesProps> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  allForms,
  setForms,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    setForms(allForms);
  }, [allForms]);

  const updateAtomSavedForms = (
    activeForms: ProgramClaimFormDto[] | Partial<ProgramClaimFormDto>[]
  ) => {
    const activeFormsIds = activeForms.map(
      (activeForm: Partial<ProgramClaimFormDto>) => activeForm?.claimFormId
    );
    const atomValue = getAtom();
    const atomSavedFormsIds =
      atomValue?.claimTabsInformation?.claimCorrespondenceTab
        ?.selectedClaimForms ?? [];
    const checkedForms = atomSavedFormsIds?.filter((checkedForm) =>
      activeFormsIds.includes(checkedForm.claimFormId)
    );

    setAtom(spreadSelectedForms(atomValue, checkedForms));
  };

  const getActiveForms = (categories) => {
    const activeCategoriesValues = categories?.map(
      (category) => category.value1
    );
    const activeFormsByActiveCategories = allForms.filter((form) =>
      activeCategoriesValues?.includes(form?.formCategory ?? "")
    );
    return activeFormsByActiveCategories;
  };

  const setCategoriesAndActiveForms = (categories) => {
    const activeForms = getActiveForms(categories);
    updateAtomSavedForms(activeForms);
    setForms(activeForms);
    setSelectedCategories(categories);
  };

  const categorySelected = (category, selected) => {
    if (selected === true) {
      setCategoriesAndActiveForms([...(selectedCategories ?? []), category]);
    } else {
      setCategoriesAndActiveForms(
        selectedCategories?.filter(
          (_category) =>
            _category?.claimReferenceId !== category?.claimReferenceId
        ) ?? []
      );
    }
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        verticalGutter={"0px"}
        horizontalGutter={"0px"}
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
        displayFlex={false}
      >
        <FontBold name="correspondenceCategoriesTitle">
          DOCUMENT CATEGORIES
        </FontBold>
        <Divider height={25} />
      </Col>
      <Col
        verticalGutter={"0px"}
        horizontalGutter={"15px"}
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
        displayFlex={false}
      >
        {categories &&
          categories?.length > 0 &&
          categories.map((category) => (
            <CategoryCheckbox
              category={category}
              categorySelected={categorySelected}
              key={category?.claimReferenceId}
            />
          ))}
      </Col>
    </Row>
  );
};

export default ClaimCorrespondenceCategories;
