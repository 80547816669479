import { Box, Grow, LinearProgress, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./Loading.css";

type LoadingProperties = {
  isLoading?: boolean | undefined;
  showPaper?: boolean | undefined;
};

const Loading: FC<LoadingProperties> = ({ isLoading, showPaper }) => {
  const [_showLoader, _setShowLoader] = useState(false);
  useEffect(() => {
    if (isLoading) {
      _setShowLoader(true);
    }

    if (!isLoading) {
      setTimeout(() => {
        _setShowLoader(false);
      }, 2500);
    }
  }, [isLoading]);
  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: "flex",
        padding: 0,
        overflow: "visible",
      }}
    >
      <Box sx={{ width: 250 }}>
        <Grow
          in={isLoading ?? false}
          style={{ transformOrigin: "0 0 0" }}
          {...(true ? { timeout: 500 } : {})}
        >
          {showPaper === undefined || showPaper ? (
            <Paper sx={{ m: 1, width: 200, height: 20, p: 1 }} elevation={3}>
              <LinearProgress color="secondary" />
            </Paper>
          ) : (
            <div style={{ margin: "5px" }}>
              <LinearProgress color="secondary" />
            </div>
          )}
        </Grow>
      </Box>
    </Box>
    // <Grow
    //   in={_showLoader}
    //   timeout={{ enter: 1000, exit: 300 }}
    //   style={{ transformOrigin: "0 0 0" }}
    // >
    //   <Paper
    //     sx={{
    //       width: "125px",
    //       height: "125px",
    //       borderRadius: "50%",
    //       overflow: "hidden",
    //       backgroundColor: "white",
    //     }}
    //     elevation={3}
    //   >
    //     <img
    //       style={{
    //         width: "100%",
    //         height: "100%",
    //         position: "relative",
    //         top: "-8px",
    //       }}
    //       src={"../media/t_loading.gif"}
    //     />
    //   </Paper>
    // </Grow>
  );
};

export default Loading;
