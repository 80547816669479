import { FC } from "react";
import { BaseTable2 } from "../../TrueUI";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { GroupSectionIcon } from "../../TrueUI/Icons/CustomIcons";

type RelatedInsuredTableWrapperProps = {
  insuredId: number;
  setTableHasInsured: (tableHasInsured: boolean) => void;
};

const RelatedInsuredTableWrapper: FC<RelatedInsuredTableWrapperProps> = ({
  insuredId,
  setTableHasInsured,
}) => {
  const tableName = "related_insureds_table";
  const getURL = `api/InsuredRelationship/GetRelationshipsByInsuredId?insuredId=${insuredId}`;

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL,
    tableType: "compact",
    toolbarOptions: {
      hideToolbar: true,
      showSortFilter: false,
    },
    columnOptions: [
      { fieldName: "OPTIONS", width: "10px" },
      { fieldName: "InsuredName", width: 70 },
      { fieldName: "RelationshipType", width: 30 },
    ],
    advancedOptions: {
      paginate: false,
      hideRefreshFiltersButton: true,
      disableOrderBy: true,

      indicationColumnConfiguration: {
        isHidden: false,
        conditionalIcon: (row) => {
          return parseInt(row.InsuredId) === insuredId ? (
            <GroupSectionIcon />
          ) : (
            <></>
          );
        },
      },
    },
    events: {
      onDataChange: (data) => {
        setTableHasInsured((data?.length ?? 0) > 0);
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div id={"related_insureds_table_container"}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default RelatedInsuredTableWrapper;
