import { FC, useEffect, useState } from "react";
import {
  EndorsementGeneralProps,
  ExposureAndPremiumFooterAndHeaderUIProps,
} from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import style from "../EndorsementForm/Endorsement.module.css";
import { FontBold, Ticker } from "../../../TrueUI";
import { useRecoilValue } from "recoil";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import {
  getPayrollAndPremiumPercentage,
  getTotalPayrollSurchargesAndFeesAndPremium,
} from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import {
  updateBindInstructionsInPolicyQuote,
  updatePolicyQuoteMultiTargetAndQuoteMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { getPaymentPremiumLabel } from "./ExposureAndPremiumUtils";

const ExposureAndPremiumFooter: FC<EndorsementGeneralProps> = ({
  tabKey,
  isAudit = false,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [footerUI, setFooterUI] =
    useState<ExposureAndPremiumFooterAndHeaderUIProps>();

  const listenerExposureAndPremiumFooterComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementExposurePremiumFooterComponent")
  );

  const showWarningIcon = () =>
    conditionHasValue(footerUI) &&
    conditionHasValue(footerUI?.payrollPercentage) &&
    conditionHasValue(footerUI?.premiumPercentage) &&
    (footerUI?.payrollPercentage >= 10 || footerUI?.premiumPercentage >= 10);

  const getWarningTooltip = () => (
    <div>
      {"Exceeds 10% threshold:"}
      <br />
      {`Payroll increased/decreased by ${footerUI?.payrollPercentage}%`}
      <br />
      {`Premium increased/decreased by ${footerUI?.premiumPercentage}%`}
    </div>
  );

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const endorsedCalculations = getTotalPayrollSurchargesAndFeesAndPremium(
      policyQuote?.quote?.states ?? []
    );
    const previousCalculations = getTotalPayrollSurchargesAndFeesAndPremium(
      isAudit
        ? policyQuote?.auditConfiguration?.oldPolicyJSON?.quote?.states ?? []
        : policyQuote?.endorsementConfiguration?.oldPolicyJSON?.quote?.states ??
            []
    );
    const { payrollPercentage, premiumPercentage } =
      getPayrollAndPremiumPercentage(
        endorsedCalculations,
        previousCalculations
      );

    const endorsedPolicyTotal =
      endorsedCalculations.totalPremium +
      endorsedCalculations.totalSurchargesAndFees;

    const newAtomValue = updatePolicyQuoteMultiTargetAndQuoteMultiTarget(
      atomValue,
      ["totalPremium", "totalFees", "policyTotal"],
      [
        endorsedCalculations.totalPremium,
        endorsedCalculations.totalSurchargesAndFees,
        endorsedPolicyTotal,
      ],
      ["estimatedAnnualPremium", "totalFees"],
      [
        endorsedCalculations.totalPremium,
        endorsedCalculations.totalSurchargesAndFees,
      ]
    );

    const endorsementPremiumChange =
      endorsedCalculations.totalPremium - previousCalculations.totalPremium;

    const newAtomValueWithEndorsementPremiumChange =
      updateBindInstructionsInPolicyQuote(
        newAtomValue,
        "endorsementPremiumChange",
        endorsementPremiumChange
      );

    setAtom(newAtomValueWithEndorsementPremiumChange);
    setFooterUI({
      previousPayroll: previousCalculations.totalPayroll,
      endorsedPayroll: endorsedCalculations.totalPayroll,
      previousPremium: previousCalculations.totalPremium,
      endorsedPremium: endorsedCalculations.totalPremium,
      payrollPercentage: payrollPercentage,
      premiumPercentage: premiumPercentage,
    });
  }, [listenerExposureAndPremiumFooterComponent]);

  return (
    <div className={style.exposure_and_premium_footer_container}>
      <div className={style.footer_header_description_container}>
        <FontBold>Policy Total</FontBold>
      </div>
      <div className={style.footer_header_calculation_label_container}>
        <FontBold>{getPaymentPremiumLabel(isAudit, "PRE-PAY")}</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <FontBold name={"policy-footer-previous-payroll"}>
          {footerUI?.previousPayroll.toLocaleString() ?? ""}
        </FontBold>
      </div>
      <div
        className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
      >
        <FontBold>{getPaymentPremiumLabel(isAudit, "END-PAY")}</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <Ticker
          id="id-policy-footer-endorsed-payroll"
          name={"policy-footer-endorsed-payroll"}
          value={footerUI?.endorsedPayroll ?? -1}
          originalValue={footerUI?.previousPayroll ?? -1}
          showArrowOnly
          showWarningIcon={showWarningIcon()}
          warningTooltip={getWarningTooltip()}
        />
      </div>
      <div
        className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
      >
        <FontBold>{getPaymentPremiumLabel(isAudit, "PRE-PRE")}</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <FontBold name={"policy-footer-previous-premium"}>
          {footerUI?.previousPremium.toLocaleString() ?? ""}
        </FontBold>
      </div>
      <div
        className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
      >
        <FontBold>{getPaymentPremiumLabel(isAudit, "END-PRE")}</FontBold>
      </div>
      <div className={style.footer_header_calculation_container}>
        <Ticker
          id="id-policy-footer-endorsed-premium"
          name={"policy-footer-endorsed-premium"}
          value={footerUI?.endorsedPremium ?? -1}
          originalValue={footerUI?.previousPremium ?? -1}
          showWarningIcon={showWarningIcon()}
          warningTooltip={getWarningTooltip()}
        />
      </div>
    </div>
  );
};

export default ExposureAndPremiumFooter;
