import { atom } from "recoil";

const NavOpenAtom = atom<boolean>({
            key: 'NavOpenAtom',
            default: false
        });

const NavBottomOpenAtom = atom<boolean>({
            key: 'NavBottomOpenAtom',
            default: false
        });

export { NavOpenAtom, NavBottomOpenAtom };