import { FC, useEffect, useState } from "react";
import { Button, Col, FontBold, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { useApiGet } from "../../../../hooks";
import { AuditWorksheetPage } from "../../../../dtos/audit-worksheet-page";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { BaseTable } from "../../../../dtos/base-table";
import { MultiBaseTable } from "../../../../dtos/multi-base-table";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { SummaryClassCodeRateDto } from "../../../../dtos/summary-class-code-rate-dto";
import {
  dialogAfterUpdateAuditWorksheet,
  getNewExposuresUpdated,
  getNewStatesUpdatedWithExposures,
  getStateListUpdatedByExposures,
  getStatesUpdatedByExposures,
} from "./AuditWorksheetUtils";
import DetailTable from "./DetailTable";
import SummaryTable from "./SummaryTable";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  updatePolicyQuoteInformation,
  updateQuoteInPolicyQuoteMultipleTargets,
} from "../updatesPolicyQuoteFunctions";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import ModalAuditWorksheet from "./ModalAuditWorksheet";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import { useRecoilState } from "recoil";
import PolicyChangeStatusWrapper from "../PolicyChangeStatusModal/PolicyChangeStatusWrapper";

export type AuditWorksheetModalProps = {
  rowData: any;
  isAdding: boolean;
  isModalOpen: boolean;
  refreshTable: string;
};

const AuditWorksheet: FC<EndorsementGeneralProps> = ({ tabKey }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const [detailsTable, setDetailsTable] = useState<BaseTable | null>(null);
  const [summaryTable, setSummaryTable] = useState<MultiBaseTable | null>(null);
  const [classCodeRates, setClassCodeRates] =
    useState<SummaryClassCodeRateDto[]>();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const defaultDialogConfiguration = {
    onCloseEvent: () => setDialogConfiguration(null),
    onOptionYesEvent: () => setDialogConfiguration(null),
  };
  const [modalData, setModalData] = useState<AuditWorksheetModalProps>({
    rowData: null,
    isAdding: false,
    isModalOpen: false,
    refreshTable: crypto.randomUUID(),
  });

  const auditId =
    getAtom()?.policyQuoteInformation?.policyQuote?.auditConfiguration
      ?.auditValues?.auditId ?? 0;
  const effectiveDate = FormattingDate(
    new Date(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate ??
        Date.now()
    )
  );
  const { responseGet, dispatchGet } = useApiGet<AuditWorksheetPage>(
    `api/QuotePolicy/GetAuditWorksheet?effectiveDate=${effectiveDate}&auditId=${auditId}&refresh=${modalData.refreshTable}`
  );

  const updateAuditExposure = () => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const exposuresStates = getNewExposuresUpdated(
      summaryTable,
      classCodeRates
    );
    const newStates = getNewStatesUpdatedWithExposures(
      atomValue,
      summaryTable,
      exposuresStates
    );
    const newStateList = getStateListUpdatedByExposures(newStates, atomValue);

    const statesWithExposuresUpdated = getStatesUpdatedByExposures(
      newStates,
      policyQuote?.effectiveDate ?? new Date(),
      policyQuote?.expirationDate ?? new Date(),
      atomValue
    );

    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["states", "stateList"],
      [statesWithExposuresUpdated, newStateList]
    );

    const updatedAtomValue = updatePolicyQuoteInformation(
      newAtomValue,
      "breadcrumbTargetSectionAfterSave",
      AuditSectionEnum.EXPOSURE_AND_PREMIUM
    );

    setAtom(updatedAtomValue);
    setPolicyQuoteTriggers(["premiumTableByExposureComponent"]);
    setSaveQuoteAtom({ saveAction: CONTINUE_ACTION });

    const dialogValues = dialogAfterUpdateAuditWorksheet(
      defaultDialogConfiguration
    );
    setDialogConfiguration(dialogValues);
  };

  const detailAddAction = () => {
    setModalData?.({
      ...modalData,
      isAdding: true,
      isModalOpen: true,
      rowData: null,
    });
  };

  const detailEditAction = (rowData: any) => {
    setModalData?.({
      ...modalData,
      isAdding: false,
      isModalOpen: true,
      rowData: rowData?.row,
    });
  };

  useEffect(() => {
    dispatchGet();
    setDetailsTable(null);
    setSummaryTable(null);
  }, [modalData.refreshTable]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const auditWorksheetTables = responseGet.axiosResponse?.data;
      setDetailsTable(
        auditWorksheetTables?.auditWorksheetDetail.tableData ?? null
      );
      setSummaryTable(
        auditWorksheetTables?.auditWorksheetSummary.tableData ?? null
      );
      setClassCodeRates(
        auditWorksheetTables?.auditWorksheetSummary.classCodeRates
      );
    }
  }, [responseGet]);

  //#region Save Logic

  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveFinalAudit = () => {
    const atomValue = getAtom();
    const breadcrumbTargetSection =
      atomValue?.policyQuoteInformation?.breadcrumbTargetSectionAfterSave;

    if (
      atomValue?.policyQuoteInformation?.policyQuote !== undefined &&
      atomValue?.policyQuoteInformation?.policyQuote !== null
    ) {
      const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
        CONTINUE_ACTION,
        atomValue,
        atomValue?.policyQuoteInformation?.policyQuote,
        breadcrumbTargetSection ?? AuditSectionEnum.AUDIT_INFORMATION
      );

      setAtom(newAtomValue);
      setPolicyQuoteTriggers(["auditSectionChangedComponent"]);
      setSaveQuoteAtom(null);
    }
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      updateAtomAfterSaveFinalAudit();
    }
  }, [saveQuoteAtom]);

  //#endregion

  return (
    <div id="audit-information-container">
      <Row {...rowWithNoMarginNorGutter}>
        {conditionHasValue(summaryTable) && (
          <>
            <Col
              displayFlex={false}
              breakpoints={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
            >
              <FontBold>Summary</FontBold>
              <SummaryTable summaryTable={summaryTable} />
            </Col>
            <Col
              verticalAlignSelf="flex-end"
              breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            >
              <Button onClick={updateAuditExposure} size="large">
                UPDATE AUDIT EXPOSURE
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        rowDirection="column"
        horizontalAlign="flex-start"
      >
        {conditionHasValue(detailsTable) && (
          <>
            <Col
              horizontalAlign="flex-start"
              breakpoints={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
              <FontBold>Detail</FontBold>
            </Col>
            <Col breakpoints={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <DetailTable
                detailsTable={detailsTable}
                addAction={detailAddAction}
                editAction={detailEditAction}
              />
            </Col>
          </>
        )}
      </Row>
      <ModalAuditWorksheet
        tabKey={tabKey}
        auditId={auditId}
        modalData={modalData}
        setModalData={setModalData}
      />
      <DialogConfirmation
        id="audit-exposure-dialog-id"
        name="audit-exposure-dialog"
        {...dialogConfiguration}
      />
      <PolicyChangeStatusWrapper tabKey={tabKey} />
    </div>
  );
};

export default AuditWorksheet;
