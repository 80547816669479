import { FC, useEffect, useState } from "react";
import { Link } from "@mui/material";
import {
  Collapse,
  Input,
  InputDate,
  Select,
  MultiSelect,
  Row,
  Col,
} from "../../../TrueUI";
import { ProgramInformationTabDto } from "../../../../dtos/program-information-tab-dto";
import { ProgramInformationDto } from "../../../../dtos/program-information-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useRecoilValue } from "recoil";
import { ProgramOptionDto } from "../../../../dtos/program-option-dto";
import { RenewalTypesEnums } from "../../../../dtos/renewal-types-enums";
import { ProgramPolicyDto } from "../../../../dtos/program-policy-dto";
import { ProgramClaimDto } from "../../../../dtos/program-claim-dto";
import { FromEnumToArray } from "../../../../utilities/enumFunctions";
import { ProgramOptions } from "./ProgramOptions";
import { ProgramPolicy } from "./ProgramPolicy";
import { ProgramClaim } from "./ProgramClaim";
import { globalOptions } from "../../../../GlobalAtoms";
import { ProgramBilling } from "./ProgramBilling";
import { ProgramBillingDto } from "../../../../dtos/program-billing-dto";
import InputDateSelector from "../../../TrueUI/Inputs/InputDateSelector";
import { SelectOptions } from "../../../../dtos/select-options";
import { AdminProgramAtom, AdminProgramErrorAtom } from "../ProgramAtoms";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { usePermissions } from "../../../../hooks";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";

type ProgramInformationProps = {
  programInformationTabData: ProgramInformationTabDto | any;
  setProgramInformationTabData: any;
};

const getTitle = (title, url) => (
  <Link
    href={url}
    onClick={() => {
      alert("Waiting instructions");
    }}
    variant="body2"
  >
    {title}
  </Link>
);

const ProgramInformation: FC<ProgramInformationProps> = ({
  programInformationTabData,
  setProgramInformationTabData,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const programAdminAtom = useRecoilValue(AdminProgramAtom);
  const programAdminErrors = useRecoilValue(AdminProgramErrorAtom);
  const { hasPermission } = usePermissions([PermissionsEnums.PROGRAM_ADMIN]);
  const [lineTypesValue, setLineTypesValue] = useState<
    Partial<SelectOptions>[]
  >([]);

  const [programOptionsData, setProgramOptionsData] =
    useState<ProgramOptionDto>();
  const [programInformationData, setProgramInformationData] =
    useState<ProgramInformationDto>();
  const [programPolicyData, setProgramPolicyData] =
    useState<ProgramPolicyDto>();
  const [programClaimData, setProgramClaimData] = useState<ProgramClaimDto>();
  const [programBillingData, setProgramBillingData] =
    useState<ProgramBillingDto>();

  useEffect(() => {
    setProgramOptionsData(programInformationTabData?.programOption);
    setProgramInformationData(programInformationTabData?.programInformation);
    setProgramPolicyData(programInformationTabData?.programPolicy);
    setProgramClaimData(programInformationTabData?.programClaim);
    setProgramBillingData(programInformationTabData?.programBilling);
  }, []);

  useEffect(() => {
    if (programAdminAtom?.configuration !== null) {
      setCurrentCoverageLines(programAdminAtom?.configuration);
    }
  }, [programAdminAtom]);

  useEffect(() => {
    setProgramInformationTabData({
      programInformation: programInformationData,
      programOption: programOptionsData,
      programPolicy: programPolicyData,
      programClaim: programClaimData,
      programBilling: programBillingData,
    });
  }, [
    programOptionsData,
    programInformationData,
    programPolicyData,
    programClaimData,
    programBillingData,
  ]);

  const setCurrentCoverageLines = (linesOfCoverage) => {
    const tempLineTypesValue: Partial<SelectOptions>[] = [];
    programInformationTabData?.programInformation?.programLines?.map(
      (programLine) => {
        linesOfCoverage?.lineOfCoverage.map((obj) => {
          if (programLine === obj.lineType) {
            tempLineTypesValue.push({
              displayName: obj.description,
              stringValue: obj.lineType,
            });
          }
        });
      }
    );
    setLineTypesValue(tempLineTypesValue);
  };
  const renewalTypes = FromEnumToArray(RenewalTypesEnums).map((enumType) => ({
    displayName: enumType.key,
    stringValue: enumType.value,
  }));

  const statusTypes = FromEnumToArray(StatusEnums)
    .filter((enumType) => enumType.value !== StatusEnums.DELETED)
    .map((enumType) => ({
      displayName: enumType.key,
      stringValue: enumType.value,
    }));

  const lineTypes: Partial<SelectOptions>[] =
    programAdminAtom?.configuration?.lineOfCoverage.map((obj) => ({
      displayName: obj.description,
      stringValue: obj.lineType,
    }));

  const paymentProcessors: SelectOptions[] =
    programAdminAtom?.configuration?.paymentProcessors;

  return (
    <div style={{ overflow: "scroll" }}>
      {programInformationData && (
        <Row
          horizontalGutter="0px"
          horizontalMargin="0px"
          verticalGutter="0px"
          verticalAlign="flex-start"
          allowWrap
        >
          <Col {...colWithNoMarginNorGutter}>
            <Row
              {...rowWithNoMarginNorGutter}
              rowDirection="column"
              verticalAlign="flex-start"
            >
              <Col>
                <Input
                  tabIndex={1}
                  id="id-program-insured-name"
                  name="name-program-insured-name"
                  label="Program Name"
                  value={programInformationData?.programName}
                  maxLength={200}
                  readOnly={!hasPermission}
                  className={!hasPermission ? "true_information_disabled" : ""}
                  onChangeRawValue={(value) => {
                    setProgramInformationData({
                      ...programInformationData,
                      programName: value,
                    });
                  }}
                  errorMessage={
                    programAdminErrors?.errorDetails?.programInformation
                      ?.programName
                  }
                />
              </Col>
              <Row
                {...rowWithNoMarginNorGutter}
                horizontalAlign="space-between"
              >
                <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
                  <Input
                    tabIndex={2}
                    id="id-program-code"
                    name="name-program-code"
                    label="Program Code"
                    value={programInformationData?.programCode}
                    maxLength={10}
                    readOnly={!hasPermission}
                    className={
                      !hasPermission ? "true_information_disabled" : ""
                    }
                    onChangeRawValue={(value) => {
                      setProgramInformationData({
                        ...programInformationData,
                        programCode: value,
                      });
                    }}
                    errorMessage={
                      programAdminErrors?.errorDetails?.programInformation
                        ?.programCode
                    }
                  />
                </Col>
                <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                  <Select
                    tabIndex={3}
                    id="id-program-status"
                    name="name-program-status"
                    label="Status"
                    labelFontType="BOLD_CAPTION"
                    options={statusTypes as any}
                    value={programInformationData?.programStatus}
                    readOnly={!hasPermission}
                    onChange={(value) => {
                      setProgramInformationData({
                        ...programInformationData,
                        programStatus: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row
                {...rowWithNoMarginNorGutter}
                verticalAlign="flex-end"
                horizontalAlign="space-between"
              >
                <Col verticalAlignSelf="flex-end">
                  <Select
                    tabIndex={4}
                    id="id-program-policy-renewal-type"
                    name="name-program-policy-renewal-type"
                    label="Renewal Type"
                    labelFontType="BOLD_CAPTION"
                    options={renewalTypes as any}
                    value={programInformationData?.renewalType}
                    readOnly={!hasPermission}
                    onChange={(value) => {
                      setProgramInformationData({
                        ...programInformationData,
                        renewalType: value,
                      });
                    }}
                  />
                </Col>
                <Col verticalAlignSelf="flex-end">
                  <Row {...rowWithNoMarginNorGutter} rowDirection="column">
                    <Col verticalGutter="0px">
                      <InputDateSelector
                        id="id-policy-period-begins"
                        name="name-policy-period-begins"
                        labelMonth="Policy Period Begins (Month)"
                        labelDay="(Day)"
                        tabIndex={5}
                        value={`${new Date().getFullYear()}-${
                          programInformationData?.policyPeriodBeginMonth
                        }-${programInformationData?.policyPeriodBeginDay}`}
                        showMode="hideYear"
                        readOnly={!hasPermission}
                        onChangeRawString={(value) =>
                          setProgramInformationData({
                            ...programInformationData,
                            policyPeriodBeginDay: value.substr(8, 2),
                            policyPeriodBeginMonth: value.substr(5, 2),
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row {...rowWithNoMarginNorGutter}>
                <Col>
                  <InputDate
                    tabIndex={7}
                    id="id-program-start-date"
                    name="name-program-start-date"
                    label="Start Date"
                    readOnly={!hasPermission}
                    value={programInformationData?.programStartDate}
                    className={`${localOptions.themeRefresh}`}
                    onChangeRawValue={(e) => {
                      setProgramInformationData({
                        ...programInformationData,
                        programStartDate: e,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <InputDate
                    tabIndex={8}
                    id="id-program-end-date"
                    name="name-program-end-date"
                    label="End Date"
                    readOnly={!hasPermission}
                    value={programInformationData?.programEndDate}
                    className={`${localOptions.themeRefresh}`}
                    errorMessage={
                      programAdminErrors?.errorDetails?.programInformation
                        ?.programEndDate
                    }
                    onChangeRawValue={(e) => {
                      setProgramInformationData({
                        ...programInformationData,
                        programEndDate: e,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row {...rowWithNoMarginNorGutter}>
                <MultiSelect
                  tabIndex={9}
                  id="id-coverage-line"
                  name="name-coverage-line"
                  label="Coverage Line"
                  labelFontType="BOLD_CAPTION"
                  options={lineTypes as any}
                  values={lineTypesValue}
                  readOnly={!hasPermission}
                  onChange={(values) => {
                    setLineTypesValue(values);
                    setProgramInformationData({
                      ...programInformationData,
                      lineType: values[0]?.stringValue,
                      programLines: values.map((value) => value.stringValue),
                    });
                  }}
                  errorMessage={
                    programAdminErrors?.errorDetails?.programInformation
                      ?.programLines
                  }
                />
              </Row>
              <Row {...rowWithNoMarginNorGutter} verticalGutter="10px">
                <Input
                  tabIndex={10}
                  id="id-program-carrier-code"
                  name="name-program-carrier-code"
                  label="Carrier Code"
                  value={programInformationData?.carrierCode}
                  inputWidth="20%"
                  maxLength={5}
                  readOnly={!hasPermission}
                  className={!hasPermission ? "true_information_disabled" : ""}
                  onChangeRawValue={(value) => {
                    setProgramInformationData({
                      ...programInformationData,
                      carrierCode: value,
                    });
                  }}
                  errorMessage={
                    programAdminErrors?.errorDetails?.programInformation
                      ?.carrierCode
                  }
                />
              </Row>
              <Row {...rowWithNoMarginNorGutter}>
                <Input
                  tabIndex={11}
                  id="id-program-trade-association"
                  name="name-program-trade-association"
                  label="Trade Association (if any)"
                  value={programInformationData?.tradeAssociation}
                  maxLength={100}
                  inputWidth="99%"
                  readOnly={!hasPermission}
                  className={!hasPermission ? "true_information_disabled" : ""}
                  onChangeRawValue={(value) => {
                    setProgramInformationData({
                      ...programInformationData,
                      tradeAssociation: value,
                    });
                  }}
                  errorMessage={
                    programAdminErrors?.errorDetails?.programInformation
                      ?.tradeAssociation
                  }
                />
              </Row>
              <ProgramOptions
                programOptionsData={
                  programOptionsData ?? ({} as ProgramOptionDto)
                }
                setProgramOptionsData={setProgramOptionsData}
                paymentProcessors={paymentProcessors}
                readOnly={!hasPermission}
                programOptionErrors={
                  programAdminErrors?.errorDetails?.programOption
                }
              />
            </Row>
          </Col>
          <Col {...colWithNoMarginNorGutter}>
            <Row
              {...rowWithNoMarginNorGutter}
              rowDirection="column"
              verticalAlign="flex-end"
            >
              <Collapse
                id="1"
                title={getTitle("Policy Issuance", "#")}
                content={
                  <ProgramPolicy
                    programPolicyData={programPolicyData}
                    setProgramPolicyData={setProgramPolicyData}
                    readOnly={!hasPermission}
                    programPolicyErrors={
                      programAdminErrors?.errorDetails?.programPolicy
                    }
                  />
                }
              />
              <Collapse
                id="2"
                title={getTitle("Claim Processing", "#")}
                content={
                  <ProgramClaim
                    programClaimData={programClaimData}
                    setProgramClaimData={setProgramClaimData}
                    readOnly={!hasPermission}
                    programClaimErrors={
                      programAdminErrors?.errorDetails?.programClaim
                    }
                  />
                }
              />
              <Collapse
                id="3"
                title={getTitle("Billing", "#")}
                content={
                  <ProgramBilling
                    programBillingData={programBillingData}
                    setProgramBillingData={setProgramBillingData}
                    readOnly={!hasPermission}
                    programBillingErrors={
                      programAdminErrors?.errorDetails?.programBilling
                    }
                  />
                }
              />
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProgramInformation;
