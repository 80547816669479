import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import {
  CreditCardInfo,
  ModalCreditCardPaymentProps,
} from "./ModalCreditCardPaymentTypes";
import FormCreditCard from "./FormCreditCard";
import AccountAndBalanceSection from "../AccountAndBalanceSection";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { TransactionRequestDto } from "../../../../../../dtos/transaction-request-dto";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../utilities/conditionalSupportFunctions";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { TransactionResponseDto } from "../../../../../../dtos/transaction-response-dto";
import FontError from "../../../../../TrueUI/Typography/FontError";

const ModalCreditCardPayment: FC<ModalCreditCardPaymentProps> = ({
  insuredId,
  showModal,
  isOpenModal,
  accountTableData,
  onModalClose,
}) => {
  const [transactionRequest, setTransactionRequest] =
    useState<TransactionRequestDto>();
  const [creditCardInfo, setCreditCardInfo] =
    useState<Partial<CreditCardInfo> | null>({
      cardMonth: 1,
      emailFormatError: null,
    });
  const [billingTransactionData, setBillingTransactionData] = useState<
    BillingTransactionDataDto[]
  >([]);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<any>(`api/PaymentActivity/GetConfigurationNewPayment`);
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<TransactionResponseDto>(
      "api/AuthorizeNet/AuthorizeCreditCard",
      transactionRequest
    );

  const onSaveEvent = () => {
    setErrorDetails(null);
    setTransactionRequest({
      postcode: creditCardInfo?.cardBillingZipCode?.toString() ?? "",
      cardNumber: creditCardInfo?.cardNumber?.toString() ?? "",
      cardCode: creditCardInfo?.cardCvv?.toString() ?? "",
      month: creditCardInfo?.cardMonth?.toString() ?? "",
      year: creditCardInfo?.cardYear?.toString() ?? "",
      emailReceiptTo: creditCardInfo?.emailReceipt?.toString() ?? "",
      insuredID: insuredId,
      paymentData: billingTransactionData,
    });
  };

  const onCancelEvent = () => {
    setErrorDetails(null);
    isOpenModal(false);
    setCreditCardInfo({
      cardMonth: 1,
      emailFormatError: null,
    });
  };

  const hasTableError = (errorDetails: { [key: string]: Object }) => {
    const keys = Object.keys(errorDetails);
    const hasDataError = keys.find((key) => key.includes("paymentData"));
    return hasDataError ? true : false;
  };

  useEffect(() => {
    dispatchGetConfig();
  }, []);

  const onCloseEvent = () => {
    onModalClose?.();
    setCreditCardInfo({
      cardMonth: 1,
      emailFormatError: null,
    });
  };

  useEffect(() => {
    if (!isEmptyValue(transactionRequest)) {
      dispatchPost();
    }
  }, [transactionRequest]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponse) &&
      isEmptyValue(responsePost.axiosResponse?.data.errorCode)
    ) {
      const response = responsePost.axiosResponse?.data;
      if (!isEmptyValue(response?.transactionID)) {
        onCancelEvent();
        isOpenModal(false);
      }
    }
    if (
      conditionHasValue(validatorErrorResponse) &&
      !isEmptyValue(validatorErrorResponse?.errorDetails)
    ) {
      const tableError = hasTableError(validatorErrorResponse.errorDetails)
        ? {
            code: validatorErrorResponse?.status ?? 500,
            msg: "Review the table data, there are missing required fields",
          }
        : {};
      setErrorDetails({
        ...validatorErrorResponse?.errorDetails,
        ...tableError,
      });
    }
    if (
      conditionHasValue(validatorErrorResponse) &&
      isEmptyValue(validatorErrorResponse?.errorDetails)
    ) {
      setErrorDetails({
        code: validatorErrorResponse?.status ?? 500,
        msg:
          validatorErrorResponse?.message ?? validatorErrorResponse?.toString(),
      });
    }
    if (!isEmptyValue(responsePost.axiosResponse?.data.errorCode)) {
      setErrorDetails({
        code: responsePost.axiosResponse?.data.errorCode,
        msg: responsePost.axiosResponse?.data.errorText,
      });
    }
  }, [responsePost]);

  return (
    <Modal
      id={"add-credit-card-payment"}
      title={"Credit Card Payment"}
      open={showModal}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCloseEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <FormCreditCard
        insuredId={insuredId}
        creditCardInfo={creditCardInfo}
        setCreditCardInfo={setCreditCardInfo}
        errorDetails={errorDetails}
      />
      <AccountAndBalanceSection
        accountTableData={accountTableData}
        setBillingTransactionData={setBillingTransactionData}
        tranAccountOptions={
          responseGetConfig?.responseData?.tranAccountOptions ?? []
        }
      />
      {errorDetails && (
        <FontError display="inline-block" maxWidth="840px" truncate>
          {errorDetails.code}: {errorDetails.msg}
        </FontError>
      )}
    </Modal>
  );
};

export default ModalCreditCardPayment;
