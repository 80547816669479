import { Button, Modal } from "../../../../../TrueUI";
import { FC, useEffect, useState } from "react";
import style from "./ModalNewPaymentSelectorStyles.module.css";
import { ModalNewPaymentSelectorProps } from "./PaymentSelectorTypes";
import { useApiGet } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { ProcessorTypeEnum } from "../../../../../../dtos/processor-type-enum";
import { ProcessorFunctionEnum } from "../../../../../../dtos/processor-function-enum";
import { AccountTableDto } from "../../../../../../dtos/account-table-dto";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";

const ModalNewPaymentSelector: FC<ModalNewPaymentSelectorProps> = ({
  configuration,
  closeModal,
  openCheckModal,
  openCreditCardModal,
  openCreditCardRedirectModal,
  openAchModal,
  insuredId,
  setAccountTableData,
  hasSelectedPaymentOptionCallback,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [processorType, setProcessorType] = useState<number | null>(null);

  const { responseGet: responseBillingTran, dispatchGet: dispatchBillingTran } =
    useApiGet<AccountTableDto>(
      `api/BillingTransaction/GetAccountAndBalanceTable?insuredId=${insuredId}&processorType=${processorType}`
    );

  useEffect(() => {
    if (
      configuration.isOpen &&
      configuration.hasAch === false &&
      configuration.hasCreditCard === false &&
      processorType === null
    ) {
      onOpenCheckModal();
    }
  }, [configuration]);

  useEffect(() => {
    if (processorType !== null) {
      dispatchBillingTran();
    }
  }, [processorType]);

  useEffect(() => {
    if (isAPITotallyComplete(responseBillingTran)) {
      const accountTableData = responseBillingTran?.responseData;
      setAccountTableData(accountTableData);
      if (
        accountTableData?.paymentProcessorFunction ===
        ProcessorFunctionEnum.INTERNAL
      ) {
        if (processorType === ProcessorTypeEnum.CREDIT_CARD) {
          openCreditCardModal?.();
          setProcessorType(null);
        }
        if (processorType === ProcessorTypeEnum.ACH) {
          openAchModal?.();
          setProcessorType(null);
        }
      }
      if (
        accountTableData?.paymentProcessorFunction ===
        ProcessorFunctionEnum.REDIRECT
      ) {
        if (processorType === ProcessorTypeEnum.CREDIT_CARD) {
          openCreditCardRedirectModal?.();
          setProcessorType(null);
        }
      }
      if (
        processorType != ProcessorTypeEnum.CHECK &&
        accountTableData?.paymentProcessorFunction ===
          ProcessorFunctionEnum.NOT_FOUND
      ) {
        setIsDialogConfirmationOpen(true);
        setProcessorType(null);
      }
    }
    if (
      configuration.hasAch === false ||
      configuration.hasCreditCard === false
    ) {
      setProcessorType(null);
    }
  }, [responseBillingTran]);

  const onOpenCheckModal = () => {
    setProcessorType(ProcessorTypeEnum.CHECK);
    openCheckModal?.();
    closeModal();
    hasSelectedPaymentOptionCallback?.();
  };

  const onOpenCreditCardModal = () => {
    setProcessorType(ProcessorTypeEnum.CREDIT_CARD);
    hasSelectedPaymentOptionCallback?.();
  };

  const onOpenAchModal = () => {
    setProcessorType(ProcessorTypeEnum.ACH);
    hasSelectedPaymentOptionCallback?.();
  };

  return (
    <>
      <Modal
        id={"add-payment"}
        title={"New Payment"}
        open={configuration.isOpen}
        cancelEvent={closeModal}
        showCloseButton={false}
        size="xs"
      >
        <div className={style.new_payment_selector_button_container}>
          <Button onClick={onOpenCheckModal}>CHECK/WIRE</Button>
          {configuration.hasCreditCard && (
            <Button onClick={onOpenCreditCardModal}>CREDIT CARD</Button>
          )}
          {configuration.hasAch && (
            <Button onClick={onOpenAchModal}>BANK DRAFT</Button>
          )}
        </div>
        <div
          className={`${style.new_payment_selector_button_container} ${style.new_payment_selector_cancel_container}`}
        >
          <Button variantStyle="outlined" onClick={closeModal}>
            CANCEL
          </Button>
        </div>
      </Modal>
      <DialogConfirmation
        id="ProcessorFunctionConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="No valid payment processor found."
        onCloseEvent={(close) => setIsDialogConfirmationOpen(close)}
        onOptionYesEvent={(close) => setIsDialogConfirmationOpen(close)}
        optionYesOverrideLabel={"OK"}
      />
    </>
  );
};

export default ModalNewPaymentSelector;
