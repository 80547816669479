import { Theme } from "../../../../media/themeTypes";

export const claimLandingStyles = (theme: Theme) => ({
  margin: 0,
  padding: 0,
  height: "auto",
  "#financials_table .MuiPaper-root .MuiTableContainer-root table > tbody > tr:nth-last-of-type(2) > td:first-of-type input":
    {
      fontWeight: `900 !important`,
    },
  ".input-currency-claim-landing-banner .MuiInput-input.MuiInputBase-input": {
    padding: 0,
    margin: 0,
    transform: "translateY(-1.5px)",
  },
  ".input-currency-claim-landing-banner *": {
    padding: 0,
    margin: 0,
    lineHeight: "1 !important",
  },
  ".claim-landing-banner-container": {
    margin: "0",
    marginTop: "1%",
    width: "100%",
    marginBottom: "-5px",
  },
  ".claim-landing-banner-information": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  ".claim-landing-banner-section1": {
    width: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& svg": {
      transform: "rotate(90deg)",
      transition: "all 0.2s linear",
      color: theme?.PRIMARY,
      "&.pinned": {
        transform: "rotate(0)",
      },
    },
    "& .claim-number": {
      pl: 1,
    },
  },
  ".claim-landing-banner-column": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  ".claim-landing-banner-row": {
    display: "flex",
    justifyContent: "baseline",
    alignItems: "center",
    svg: {
      color: theme?.PRIMARY,
    },
  },
  ".left_margin_10": { marginLeft: "10px" },
  ".claim-landing-banner-row .true_input_helper_text": {
    marginTop: 0,
  },
  ".claim-landing-banner-section2": {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "15px",
  },

  ".claim-landing-banner-section3": {
    marginLeft: "2%",
    display: "flex",
    justifyContent: "space-around",
  },
  ".claim-landing-banner-actions": {
    display: "flex",
    alignItems: "baseline",
  },
  ".claim-landing-banner-tags": {
    marginLeft: "150px",
    display: "flex",
    alignItems: "center",
    height: "50%",
  },
  ".claim-landing-banner-tags > div": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  ".claim-landing-banner-section3 > .claim-landing-banner-row": {
    justifyContent: "space-between",
  },
  ".claim-landing-banner-section3 > .single-row": {
    height: "fit-content",
    paddingTop: "6px",
  },
  "#common_page_container_for_claim_contacts_tab .css-0": {
    width: "auto !important",
  },
  ".k-tabstrip-items-wrapper.k-hstack": {
    marginBottom: 50,
  },
});
export const claimLandingTabsStyles = () => ({
  height: "100%",
  pt: "10px",
  ".k-tabstrip-items-wrapper.k-hstack": {
    marginBottom: "15px",
  },
});

export const claimLandingTableStyles = () => ({
  padding: 0,
  height: "fit-content",

  "& .true_table_body": {
    overflowY: "hidden",
    "& .true_input_general_container": {
      background: "transparent !important",
    },
  },
});
