import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { DiaryAlertConfigurationDto } from "../../../../dtos/diary-alert-configuration-dto";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import { NewDiaryAlertDto } from "../../../../dtos/new-diary-alert-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import {
  Font,
  Input,
  InputDate,
  Modal,
  RichEditor,
  Select,
} from "../../../TrueUI";
import {
  GlobalInsuredAtomFamily,
  RefreshAlertComponent,
} from "../../InsuredAtoms";
import { ContextProps } from "../InsuredDrawerConstants";
import { modalNewAlertStyles } from "./NotesStyles";

type ModalNewAlertProps = {
  isOpen: boolean;
  diaryNote: DiaryNoteDto | null;
  context: ContextProps;
  setIsOpen: (isOpen: boolean) => void;
  setRefreshConversation: (refresh: boolean) => void;
};

const ModalNewAlert: FC<ModalNewAlertProps> = ({
  isOpen,
  setIsOpen,
  diaryNote,
  context,
  setRefreshConversation,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${context?.tabKey}`;
  const [alert, setAlert] = useState<Partial<NewDiaryAlertDto> | null>();
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [alertConfiguration, setAlertConfiguration] =
    useState<Partial<DiaryAlertConfigurationDto>>();

  const { responseGet, dispatchGet } = useApiGet<DiaryAlertConfigurationDto>(
    "api/DiaryNoteAlert/GetDiaryAlertConfiguration"
  );

  const {
    responsePost,
    dispatchPost: saveAlert,
    validatorErrorResponse,
  } = useApiPost<number>("api/DiaryNoteAlert/SaveDiaryAlert", alert);

  const setRefreshPage = useSetRecoilState(RefreshAlertComponent);
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(atomKey)
  );

  const cancelEvent = () => {
    setIsOpen(false);
    setErrorDetails(null);
  };

  const getAlertID = () => {
    //  In the case of editing without close the content modal
    if (diaryNote?.diaryNoteId == diaryNote?.alert?.alertId) {
      return alert?.alertId ?? null;
    } else {
      return diaryNote?.alert?.alertId ?? null;
    }
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    setAlert({
      ...diaryNote?.alert,
      diaryNoteId: diaryNote?.diaryNoteId ?? 0,
      insuredId: context?.insuredId,
      alertId: getAlertID(),
      effectiveDate: diaryNote?.alert?.effectiveDate ?? new Date(),
      reminderInterval: diaryNote?.alert?.reminderInterval ?? 1,
    });
  }, [isOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setAlertConfiguration(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful && !responsePost.isLoading) {
      setAlert({ ...alert, alertId: responsePost?.axiosResponse?.data });
      setIsOpen(false);
      setRefreshConversation(true);
      setErrorDetails(null);
      setComponentTriggers(["activityLogComponent"]);
      setRefreshPage((prev) => !prev);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  return (
    <Modal
      open={isOpen}
      id={"modal-note-alert"}
      title={"Alert Settings"}
      size={"lg"}
      cancelEvent={cancelEvent}
      saveEvent={saveAlert}
      showCancelTextButton
      showCloseButton
      cancelButtonWithConfirmation
    >
      <Box sx={modalNewAlertStyles()}>
        <div className={"alert-instructions"}>
          <Font>
            The following note will be set as an alert. Please note, only the
            first 2 lines can be displayed as the alert. The alert will link
            back to this note if more text is entered.
          </Font>
        </div>
        <RichEditor
          contentStyle={{ height: 350 }}
          readOnly
          value={diaryNote?.content ?? ""}
          disableCollapse
        />
        <div className={"alert-information"}>
          <Select
            id="alert-type"
            name="alertType"
            label={"Alert Type"}
            labelFontType={"BOLD_CAPTION"}
            inputWidth={"170px"}
            options={alertConfiguration?.alertTypeOptions ?? []}
            value={alert?.alertType}
            errorMessage={errorDetails?.alertType}
            onChange={(value) => setAlert({ ...alert, alertType: value })}
          />
          <InputDate
            label="Alert Begins On"
            labelFontType={"BOLD_CAPTION"}
            id={"id-input-begin-alert"}
            name={"name-input-begin-alert"}
            inputWidth={"170px"}
            readOnly={alert?.alertId ? true : false}
            value={alert?.effectiveDate}
            errorMessage={errorDetails?.effectiveDate}
            onChangeRawValue={(value) => {
              setAlert({
                ...alert,
                effectiveDate: value,
              });
            }}
            disablePast
          />
          <InputDate
            label="Ends on (leave blank if continuous)"
            labelFontType={"BOLD_CAPTION"}
            id={"id-input-end-alert"}
            name={"name-input-end-alert"}
            inputWidth={"170px"}
            value={alert?.expirationDate}
            errorMessage={errorDetails?.expirationDate}
            onChangeRawValue={(value) =>
              setAlert({
                ...alert,
                expirationDate: value,
              })
            }
            disablePast
          />
          <Input
            type={"number"}
            decimalScale={0}
            label={"Reminder Interval (in days)"}
            id={"id-input-interval-alert"}
            name={"name-input-interval-alert"}
            inputWidth={"170px"}
            minNumericValue={1}
            maxNumericValue={365}
            labelFontType={"BOLD_CAPTION"}
            value={alert?.reminderInterval}
            errorMessage={errorDetails?.reminderInterval}
            onChangeRawValue={(value) =>
              setAlert({ ...alert, reminderInterval: value })
            }
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ModalNewAlert;
