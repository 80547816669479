import { useEffect } from "react";
import { useSecurity } from "./hooks";


const Logout = () => {

    const { logout } = useSecurity();

    const LogoutHandler = () => {
        try {
            logout();
        } catch (err) {
            //TODO Catch handling
        }
    };

    useEffect(() => {
        LogoutHandler();

    }, []);

    return ( <div> </div>
    );
};

export default Logout;
