import { PolicyHistoryFilterOptionDto } from "../../../dtos/policy-history-filter-option-dto";
import { PolicyQuoteStatusEnum } from "../../../dtos/policy-quote-status-enum";
import { PolicyQuoteTypeEnum } from "../../../dtos/policy-quote-type-enum";
import { PolicyStatusEnums } from "../../../dtos/policy-status-enums";
import { SelectOptions } from "../../../dtos/select-options";
import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { PercentageType } from "../../../types/RangeNumberType";
import { OptionsContextActionProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { MultiAlignContextActionProps } from "../../TrueUI/Tables/MultiTable/MultiAlignHeader";
import { PolicyHistoryFilterProps } from "./PolicyHistoryTable";

export const policyHistoryChildContextMenu = (
  updateIsQuoteOpen: (
    policyId?: number,
    quoteId?: number,
    quoteType?: number,
    previousQuoteId?: number
  ) => void,
  updateDeleteEndorsementModal: (policyId: number, quoteId: number) => void,
  updateChangeStatusModal?: (
    isOpen: boolean,
    policyId: number | null,
    quoteId: number | null,
    currentStatus: number | null,
    isAudit: boolean
  ) => void
): OptionsContextActionProperties[] => [
  {
    displayName: "View Quote",
    actionName: "_viewQuote",
    rule: (ruleOptions) =>
      updateIsQuoteOpen(
        parseInt(ruleOptions.row.PolicyId),
        parseInt(ruleOptions.row.QuoteId),
        parseInt(ruleOptions.row.QuoteType),
        parseInt(ruleOptions.row.PreviousQuoteId)
      ),
  },
  {
    displayName: "Change Status",
    actionName: "_changeStatus",
    rule: (ruleOptions) => {
      updateChangeStatusModal?.(
        true,
        ruleOptions.row.PolicyId ?? null,
        ruleOptions.row.QuoteId ?? null,
        ruleOptions.row.AuditStatusEnum
          ? parseInt(ruleOptions.row.AuditStatusEnum)
          : null,
        true
      );
    },
  },
  {
    displayName: "Delete",
    actionName: "_deleteQuote",
    rule: (ruleOptions) =>
      updateDeleteEndorsementModal(
        parseInt(ruleOptions.row.PolicyId),
        parseInt(ruleOptions.row.QuoteId)
      ),
  },
];

export const getPolicyHistoryParentColoring = (
  colorParam?: string,
  colorPercentageParam?: string
) => ({
  colorConfiguration: [
    {
      color: getColorWithOpacityHexaDecimal(colorParam ?? "", 100),
      percentageOccupiedColor: parseInt(
        colorPercentageParam ?? "100"
      ) as PercentageType,
    },
    {
      color: getColorWithOpacityHexaDecimal(colorParam ?? "", 20),
      percentageOccupiedColor: 0 as PercentageType,
    },
  ],
});

export const POLICY_HISTORY_FILTER_DEFAULT_VALUES: PolicyHistoryFilterProps = {
  lineOfCoverage: "all",
  status: "active",
  dateSort: "newest-to-oldest",
};

export const getFilterListForMultiTable = (
  setFilterValues: (
    value: React.SetStateAction<PolicyHistoryFilterProps>
  ) => void,
  filterOptions?: PolicyHistoryFilterOptionDto
) => [
  (actionOptions) =>
    BaseTableDropdownFilter({
      id: "policy_history_active_filter",
      filterOptions: actionOptions,
      optionsOverride: filterOptions?.statusOptionList ?? [],
      labelText: "Show:",
      usingExternalFiltering: true,
      defaultValue: POLICY_HISTORY_FILTER_DEFAULT_VALUES.status,
      onChange: (value) =>
        setFilterValues((prev) => ({ ...prev, status: value })),
    }),
  (actionOptions) =>
    BaseTableDropdownFilter({
      id: "policy_history_sort_filter",
      filterOptions: actionOptions,
      optionsOverride: filterOptions?.sortOptionList ?? [],
      labelText: "Sort:",
      usingExternalFiltering: true,
      defaultValue: POLICY_HISTORY_FILTER_DEFAULT_VALUES.dateSort,
      onChange: (value) =>
        setFilterValues((prev) => ({ ...prev, dateSort: value })),
    }),
  (actionOptions) =>
    BaseTableDropdownFilter({
      id: "policy_history_line_filter",
      filterOptions: actionOptions,
      optionsOverride: filterOptions?.lineOfCoverageOptionList ?? [],
      labelText: "Line Of Coverage:",
      usingExternalFiltering: true,
      defaultValue: POLICY_HISTORY_FILTER_DEFAULT_VALUES.lineOfCoverage,
      onChange: (value) =>
        setFilterValues((prev) => ({ ...prev, lineOfCoverage: value })),
    }),
];

export type PolicyParentContextMenuByStatus = {
  statuses: number[];
  contextMenu: MultiAlignContextActionProps[];
};

export const getPolicyContextMenuOptions = (
  updateIsQuoteOpen: (
    policyId?: number,
    quoteId?: number,
    quoteType?: number,
    previousQuoteId?: number
  ) => void,
  setReviseAuditURL: (urlValue: string) => void,
  policyId?: number,
  writtenQuoteId?: number,
  currentQuoteId?: number,
  currentPolicyStatus?: number,
  updateChangeStatusModal?: (
    isOpen: boolean,
    policyId: number | null,
    quoteId: number | null,
    currentStatus: number | null,
    isAudit: boolean
  ) => void,
  updateAssignmentModal?: (isOpen: boolean, policyId: number | null) => void
) => [
  {
    displayName: "View Quote",
    actionName: "_viewQuote",
    rule: () =>
      updateIsQuoteOpen(policyId, writtenQuoteId, PolicyQuoteTypeEnum.INITIAL),
  },
  {
    displayName: "Change Status",
    actionName: "_changeStatus",
    rule: () =>
      updateChangeStatusModal?.(
        true,
        policyId ?? null,
        null,
        currentPolicyStatus ?? null,
        false
      ),
  },
  {
    displayName: "Add Audit",
    actionName: "_addAudit",
    rule: () =>
      updateIsQuoteOpen(
        policyId,
        0,
        PolicyQuoteTypeEnum.FINAL_AUDIT,
        currentQuoteId
      ),
  },
  {
    displayName: "Add Endorsement",
    actionName: "_addEndorsement",
    rule: () =>
      updateIsQuoteOpen(
        policyId,
        0,
        PolicyQuoteTypeEnum.ENDORSEMENT,
        currentQuoteId
      ),
  },
  {
    displayName: "Cancel Policy",
    actionName: "_cancelPolicy",
    rule: () => alert("Cancel Policy Selected"),
  },
  {
    displayName: "Revise Audit",
    actionName: "_reviseAudit",
    rule: () =>
      setReviseAuditURL(`api/QuotePolicy/ReviseAudit?policyId=${policyId}`),
  },
  {
    displayName: "Assign to User",
    actionName: "_assignToUser",
    rule: () => updateAssignmentModal?.(true, policyId ?? null),
  },
];

const validateContextMenuOptionRenderingCondition = (
  policyContextMenuPermissions: { actionName: string; statuses: number[] }[],
  dataToValidateRender: any,
  actionName: string
) => {
  const actionToValidate = policyContextMenuPermissions.find(
    (policyQuotePermission) => policyQuotePermission.actionName === actionName
  );
  return (
    !actionToValidate?.statuses?.includes(
      parseInt(dataToValidateRender?.statusId ?? -1)
    ) ?? true
  );
};

export const getContextMenuOptionRenderingCondition = (
  policyContextMenuPermissions: { actionName: string; statuses: number[] }[]
) => {
  return [
    {
      actionName: "_changeStatus",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_changeStatus"
          ),
        };
      },
    },
    {
      actionName: "_addAudit",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_addAudit"
          ),
        };
      },
    },
    {
      actionName: "_addEndorsement",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_addEndorsement"
          ),
        };
      },
    },
    {
      actionName: "_cancelPolicy",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_cancelPolicy"
          ),
        };
      },
    },
    {
      actionName: "_reviseAudit",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_reviseAudit"
          ),
        };
      },
    },
    {
      actionName: "_assignToUser",
      condition: (dataToValidateRender: any) => {
        return {
          isHidden: validateContextMenuOptionRenderingCondition(
            policyContextMenuPermissions,
            dataToValidateRender,
            "_assignToUser"
          ),
        };
      },
    },
  ];
};

export const policyContextMenuPermissions = [
  {
    actionName: "_changeStatus",
    statuses: [
      PolicyStatusEnums.NEW_SUBMISSION,
      PolicyStatusEnums.RENEWAL,
      PolicyStatusEnums.INDICATION,
      PolicyStatusEnums.WAITING_FOR_INFO,
      PolicyStatusEnums.QUOTED,
      PolicyStatusEnums.READY_TO_BIND,
    ],
  },
  {
    actionName: "_addAudit",
    statuses: [
      PolicyStatusEnums.ISSUED,
      PolicyStatusEnums.ENDORSED,
      PolicyStatusEnums.EXPIRED,
      PolicyStatusEnums.CANCELLED,
    ],
  },
  {
    actionName: "_addEndorsement",
    statuses: [
      PolicyStatusEnums.ISSUED,
      PolicyStatusEnums.ENDORSED,
      PolicyStatusEnums.EXPIRED,
      PolicyStatusEnums.CANCELLED,
    ],
  },

  {
    actionName: "_cancelPolicy",
    statuses: [PolicyStatusEnums.ISSUED, PolicyStatusEnums.ENDORSED],
  },
  {
    actionName: "_reviseAudit",
    statuses: [PolicyStatusEnums.AUDITED],
  },
  {
    actionName: "_assignToUser",
    statuses: [
      PolicyStatusEnums.NEW_SUBMISSION,
      PolicyStatusEnums.RENEWAL,
      PolicyStatusEnums.INDICATION,
      PolicyStatusEnums.WAITING_FOR_INFO,
      PolicyStatusEnums.QUOTED,
      PolicyStatusEnums.READY_TO_BIND,
    ],
  },
];

export const policyStatusesThanCanEdit = [
  PolicyStatusEnums.NEW_SUBMISSION,
  PolicyStatusEnums.RENEWAL,
  PolicyStatusEnums.INDICATION,
  PolicyStatusEnums.WAITING_FOR_INFO,
  PolicyStatusEnums.QUOTED,
  PolicyStatusEnums.READY_TO_BIND,
];

const validateChildContextMenuOptionRenderingCondition = (
  policyContextMenuPermissions: { actionName: string; statuses: number[] }[],
  dataToValidateRender: any,
  actionName: string
) => {
  const actionToValidate = policyContextMenuPermissions.find(
    (policyQuotePermission) => policyQuotePermission.actionName === actionName
  );
  return (
    !actionToValidate?.statuses?.includes(
      parseInt(dataToValidateRender?.QuoteStatusEnum ?? -1)
    ) ?? true
  );
};

const renderConditionChangeStatus = (
  row: any,
  auditStatusOptions?: SelectOptions[]
) => {
  if (
    parseInt(row?.QuoteType ?? "0") === PolicyQuoteTypeEnum.FINAL_AUDIT &&
    auditStatusOptions
      ?.map((a) => a.intValue)
      .includes(parseInt(row?.AuditStatusEnum ?? "0"))
  ) {
    return false;
  }
  return true;
};

export const getChildContextMenuOptionRenderingCondition = (
  policyContextMenuPermissions: { actionName: string; statuses: number[] }[],
  auditStatusOptions?: SelectOptions[]
) => [
  {
    actionName: "_viewQuote",
    condition: (dataToValidateRender: any) => ({
      isHidden: validateChildContextMenuOptionRenderingCondition(
        policyContextMenuPermissions,
        dataToValidateRender,
        "_viewQuote"
      ),
    }),
  },
  {
    actionName: "_changeStatus",
    condition: (dataToValidateRender: any) => ({
      isHidden:
        renderConditionChangeStatus(dataToValidateRender, auditStatusOptions) ||
        validateChildContextMenuOptionRenderingCondition(
          policyContextMenuPermissions,
          dataToValidateRender,
          "_changeStatus"
        ),
    }),
  },
  {
    actionName: "_deleteQuote",
    condition: (dataToValidateRender: any) => ({
      isHidden: validateChildContextMenuOptionRenderingCondition(
        policyContextMenuPermissions,
        dataToValidateRender,
        "_deleteQuote"
      ),
    }),
  },
];

export const policyChildContextMenuPermissions = [
  {
    actionName: "_viewQuote",
    statuses: [PolicyQuoteStatusEnum.IN_PROGRESS, PolicyQuoteStatusEnum.BOUND],
  },
  {
    actionName: "_changeStatus",
    statuses: [PolicyQuoteStatusEnum.IN_PROGRESS],
  },
  {
    actionName: "_deleteQuote",
    statuses: [PolicyQuoteStatusEnum.IN_PROGRESS],
  },
];
