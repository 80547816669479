import BaseTable2Header from "./BaseTable2Header";
import BaseTable2Body from "./BaseTable2Body";

import { FC, useEffect, useState } from "react";
import { Table, TableWrapper } from "../BaseTableStructure";

const BaseTable2Container: FC<any> = (props) => {
  const [hasMounted, setHasMounted] = useState(false);
  /////////////////////////
  //
  //  DEV NOTE: We are currently not allowed to put the tableInstance object in this component as it constantly changes which will cause this component to re-render.
  //
  /////////////////////////
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <TableWrapper>
      <Table>
        <BaseTable2Header uiid={props.uiid} />
        <BaseTable2Body uiid={props.uiid} />
      </Table>
    </TableWrapper>
  ) : null;
};
export default BaseTable2Container;
