import { FC } from "react";
import { DownloadFile } from "..";
import "./showFiles.module.css";

type ShowTmpFilesProps = {
  currentFileIds: number[];
  tmpFiles?: TmpFiles[];
  onDeleteFile?: (e?: any) => void;
};

export type TmpFiles = {
  fileArchiveId: number;
  fileName: string;
  fileUid: string;
};

const ShowTmpFiles: FC<ShowTmpFilesProps> = ({
  currentFileIds,
  tmpFiles,
  onDeleteFile,
}) => {
  const getNameOfFileById = (fileId) => {
    return (
      tmpFiles?.find((tmp) => tmp.fileArchiveId === fileId)?.fileName ?? ""
    );
  };
  return (
    <div className="true_show_files_scrollable_container">
      <div className="true_files_wrap_container">
        {currentFileIds.map((fileId) => (
          <DownloadFile
            fileName={getNameOfFileById(fileId)}
            fileId={fileId}
            key={fileId}
            showDeleteButton
            onDeleteFile={(id) => onDeleteFile?.(id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ShowTmpFiles;
