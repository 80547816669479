export const tasksStyle = () => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  mt: "5px",
  "& .MuiAccordionDetails-root": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: `fit-content !important`,
    height: `fit-content !important`,
  },
  "& .MuiAccordionSummary-content": {
    margin: `0 0 5px 0 !important`,
  },
  "& #add_task_link": {
    marginTop: `5px !important`,
  },
  "#true-rich-editor-footer": {
    ".MuiBox-root": {
      margin: `0px !important`,
    },
  },
  "#cancel-button": {
    padding: "5px 20px !important",
  },
});

export const tasksTableStyle = () => ({
  display: "flex",
  height: "100% !important",
  overflow: "hidden",
  mt: "5px",
});
