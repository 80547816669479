// import { Theme } from "../../../../media/themeTypes";

import { Theme } from "../../../media/themeTypes";

export const plusMinusStyles = (theme: Theme) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  ".plus-minus-icon-container": {
    padding: 0,
    margin: "0px",
    ".plus-action-button": {
      color: theme.TEXT_COLOR,
    },
    ".minus-action-button": {
      color: theme.TEXT_COLOR,
    },
  },
});
