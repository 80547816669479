export function conditionHasValue<T>(value: T | undefined | null): value is T {
  /* 
  note: value === null ? false : true - works exactly the same as the below code, 
  but we do this in the event javascript changes the rules on the 'undefined' 
  type as they have in the past. Used to be 'undefined' (string).
  */
  return <T>value === undefined || <T>value === null ? false : true;
}

export function isEmptyValue(value: any) {
  /* Checking empty values as ({} | [] | '' | undefined | null | NaN) */
  return (
    value === undefined ||
    value === null ||
    (typeof value === "number" && Number.isNaN(value)) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}
export const isValidEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};
