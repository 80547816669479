import { FC, useEffect, useState } from "react";
import {
  Col,
  Divider,
  FontBold,
  Input,
  Modal,
  Row,
  Select,
} from "../../../../TrueUI";
import {
  filterDefaultRatesByPolicyDates,
  ProducerDataProps,
  ProducerFormUI,
  ProducerModalProps,
} from "./ProducerChangeUtil";
import {
  clearEndorsementTypeList,
  endorsementEngineProducerChange,
} from "../EndorsementEngineUtils";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { updatePolicyQuoteInformation } from "../../updatesPolicyQuoteFunctions";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { useApiGet } from "../../../../../hooks";
import { AgencyLocationDto } from "../../../../../dtos/agency-location-dto";
import { AgencyContactDto } from "../../../../../dtos/agency-contact-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { getCustomObjectListAsOptions } from "../../../../../utilities/selectFunctions";
import { usePolicyQuoteTriggerComponent } from "../../hooks/usePolicyQuoteTriggerComponent";
import { EndorsementTypeEnum } from "../../../../../dtos/endorsement-type-enum";
import { getPolicyQuote } from "../../PolicyQuoteForm/PolicyQuoteUtils";

const defaultProducerUI = (currentProducerData?: ProducerDataProps | null) =>
  ({
    isFirstRender: null,
    newProducerData: {
      agencyId: currentProducerData?.agencyId,
      agencyName: null,
      locationId: currentProducerData?.locationId,
      locationName: null,
      agentContactId: currentProducerData?.agentContactId,
      agentName: null,
    },
    locations: [],
    agents: [],
  } as ProducerFormUI);

const ProducerChangeModal: FC<ProducerModalProps> = ({
  tabKey,
  modalConfiguration,
  readonly,
  setModalConfiguration,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [producerUI, setProducerUI] = useState<ProducerFormUI>(
    defaultProducerUI(modalConfiguration?.currentProducerData)
  );
  const { responseGet: locationResponse, dispatchGet: locationDispatch } =
    useApiGet<AgencyLocationDto[]>(
      `api/AgencyLocation/GetAgencyLocationsByAgencyId?agencyId=${producerUI?.newProducerData?.agencyId}`
    );
  const { responseGet: agentResponse, dispatchGet: agentDispatch } = useApiGet<
    AgencyContactDto[]
  >(
    `api/AgencyContact/GetAgencyContactsByAgencyIdAndAgencyLocationId?agencyId=${producerUI?.newProducerData?.agencyId}&agencyLocationId=${producerUI?.newProducerData?.locationId}`
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  useEffect(() => {
    setProducerUI({
      ...producerUI,
      isFirstRender: true,
    });
  }, []);

  useEffect(() => {
    if (
      modalConfiguration?.isOpen === true &&
      modalConfiguration?.currentProducerData?.agencyId !== null &&
      modalConfiguration?.currentProducerData?.agencyId !== undefined
    ) {
      setProducerUI({
        ...producerUI,
        newProducerData: {
          ...producerUI?.newProducerData,
          agencyId: producerUI.isFirstRender
            ? modalConfiguration?.currentProducerData?.agencyId
            : producerUI.newProducerData.agencyId,
        },
      });
    }
  }, [modalConfiguration?.isOpen]);

  useEffect(() => {
    if (
      producerUI?.newProducerData?.agencyId !== null &&
      producerUI?.newProducerData?.agencyId !== undefined
    ) {
      locationDispatch();
    }
  }, [producerUI?.newProducerData?.agencyId]);

  useEffect(() => {
    if (
      producerUI?.newProducerData?.locationId !== null &&
      producerUI?.newProducerData?.locationId !== undefined
    ) {
      agentDispatch();
    }
  }, [producerUI?.newProducerData?.locationId]);

  useEffect(() => {
    if (isAPITotallyComplete(locationResponse)) {
      const agencyLocations = (locationResponse.responseData ??
        []) as AgencyLocationDto[];
      setProducerUI({ ...producerUI, locations: agencyLocations });
    }
  }, [locationResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(agentResponse)) {
      const agencyProducers = agentResponse.axiosResponse?.data ?? [];
      setProducerUI({ ...producerUI, agents: agencyProducers });
    }
  }, [agentResponse]);

  useEffect(() => {
    if ((producerUI?.locations ?? []).length > 0) {
      setProducerUI({
        ...producerUI,
        newProducerData: {
          ...producerUI?.newProducerData,
          locationId: producerUI?.isFirstRender
            ? modalConfiguration?.currentProducerData?.locationId
            : producerUI?.newProducerData?.locationId,
        },
      });
    }
  }, [producerUI?.locations]);

  useEffect(() => {
    if ((producerUI?.agents ?? []).length > 0) {
      setProducerUI({
        ...producerUI,
        newProducerData: {
          ...producerUI?.newProducerData,
          agentContactId: producerUI?.isFirstRender
            ? modalConfiguration?.currentProducerData?.agentContactId
            : producerUI?.newProducerData?.agentContactId,
        },
      });
    }
  }, [producerUI?.agents]);

  const getProducerDefaultRatesList = () => {
    const atomValue = getAtom();
    const quote = getPolicyQuote(atomValue);
    const policyType = quote?.policyType?.value ?? 0;
    const allRates = quote?.endorsementConfiguration?.defaultRatesList ?? [];
    const defaultRates = filterDefaultRatesByPolicyDates(
      allRates,
      quote?.effectiveDate,
      quote?.expirationDate
    );
    return { policyType, defaultRates };
  };

  const onSave = () => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
    const { policyType, defaultRates } = getProducerDefaultRatesList();
    const updatedPolicyJSONByProducerChange = endorsementEngineProducerChange(
      policyJSON,
      producerUI?.newProducerData,
      producerUI?.locations ?? [],
      producerUI?.agents ?? [],
      policyType ?? 0,
      defaultRates
    );
    const newAtomValue = updatePolicyQuoteInformation(
      atomValue,
      "policyQuote",
      updatedPolicyJSONByProducerChange
    );
    setAtom(newAtomValue);
    setModalConfiguration({
      ...modalConfiguration,
      isOpen: false,
    });
    setPolicyQuoteTriggers(["endorsementProducerChangeSave"]);
  };

  const onCloseModal = () => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
    const policyJSONWithSpecificEndorsementTypesRemoved =
      clearEndorsementTypeList(policyJSON, EndorsementTypeEnum.PRODUCER_NAME);
    const newAtomValue = updatePolicyQuoteInformation(
      atomValue,
      "policyQuote",
      policyJSONWithSpecificEndorsementTypesRemoved
    );
    setAtom(newAtomValue);
    setProducerUI({
      ...producerUI,
      newProducerData: modalConfiguration?.currentProducerData,
    });
    setModalConfiguration({
      ...modalConfiguration,
      isOpen: false,
    });
    setPolicyQuoteTriggers(["endorsementProducerChangeSave"]);
  };

  return (
    <Modal
      id={"producer-change-endorsement"}
      title={"Broker of Record Change"}
      open={modalConfiguration.isOpen}
      showCloseButton
      showCancelTextButton
      saveEvent={onSave}
      cancelEvent={onCloseModal}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <div id="producer_change_form">
        <Row {...rowWithNoMarginNorGutter}>
          <Col horizontalAlign="flex-start">
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col horizontalAlign="flex-start">
                <FontBold>Current Producer</FontBold>
              </Col>
              <Col horizontalAlign="flex-start">
                <Input
                  id={"current-producer-agency"}
                  name={"currentProducerAgency"}
                  readOnly
                  labelPosition="top"
                  label="Agency"
                  labelFontType="BOLD_CAPTION"
                  value={modalConfiguration?.currentProducerData?.agencyName}
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Input
                  id={"current-producer-location"}
                  name={"currentProducerLocation"}
                  readOnly
                  labelPosition="top"
                  label="Location"
                  labelFontType="BOLD_CAPTION"
                  value={modalConfiguration?.currentProducerData?.locationName}
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Input
                  id={"current-producer-agent"}
                  name={"currentProducerAgent"}
                  readOnly
                  labelPosition="top"
                  label="Agent"
                  labelFontType="BOLD_CAPTION"
                  value={modalConfiguration?.currentProducerData?.agentName}
                />
              </Col>
            </Row>
          </Col>
          <Divider height={"215px"} showBorder borderSize={"1px"}></Divider>
          <Col horizontalAlign="flex-start">
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col horizontalAlign="flex-start">
                <FontBold>New Producer</FontBold>
              </Col>
              <Col horizontalAlign="flex-start">
                <Select
                  id={"new-producer-agency"}
                  name={"newProducerAgency"}
                  labelPosition="top"
                  label="Agency"
                  labelFontType="BOLD_CAPTION"
                  optionsMaxHeight="150px"
                  options={getCustomObjectListAsOptions(
                    modalConfiguration?.agencies ?? [],
                    ["agencyName"],
                    "agencyId"
                  )}
                  value={producerUI?.newProducerData?.agencyId}
                  onChange={(value) => {
                    setProducerUI({
                      ...producerUI,
                      newProducerData: {
                        ...producerUI?.newProducerData,
                        agencyId: value,
                        locationId: null,
                        agentContactId: null,
                      },
                      isFirstRender: false,
                    });
                  }}
                  firstOptionAsDefault={false}
                  readOnly={readonly}
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Select
                  id={"new-producer-location"}
                  name={"newProducerLocation"}
                  options={getCustomObjectListAsOptions(
                    producerUI?.locations ?? [],
                    ["agencyLocationName"],
                    "agencyLocationId"
                  )}
                  labelPosition="top"
                  label="Location"
                  labelFontType="BOLD_CAPTION"
                  optionsMaxHeight="150px"
                  value={producerUI?.newProducerData?.locationId}
                  onChange={(value) => {
                    setProducerUI({
                      ...producerUI,
                      newProducerData: {
                        ...producerUI?.newProducerData,
                        locationId: value,
                        agentContactId: null,
                      },
                      isFirstRender: false,
                    });
                  }}
                  firstOptionAsDefault={false}
                  readOnly={readonly}
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Select
                  id={"new-producer-agent"}
                  name={"newProducerAgent"}
                  options={getCustomObjectListAsOptions(
                    producerUI?.agents ?? [],
                    ["firstName", "lastName"],
                    "agencyContactId"
                  )}
                  labelPosition="top"
                  label="Agent"
                  labelFontType="BOLD_CAPTION"
                  optionsMaxHeight="150px"
                  value={producerUI?.newProducerData?.agentContactId}
                  onChange={(value) => {
                    setProducerUI({
                      ...producerUI,
                      newProducerData: {
                        ...producerUI?.newProducerData,
                        agentContactId: value,
                      },
                      isFirstRender: false,
                    });
                  }}
                  firstOptionAsDefault={false}
                  readOnly={readonly}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ProducerChangeModal;
