import { FC } from "react";
import "./HierarchyList.module.css";
import CollapseItem from "./CollapseItem";
import RegularItem from "./RegularItem";
import { KeysAsType } from "../../../../types/KeysAsAType";
import { FontsType } from "../../../../media/themeTypes";

export type ItemProps = {
  itemId?: string | number | boolean;
  displayName?: string;
  description?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  childrenItems?: ItemProps[];
};

export type CommonProps = {
  showTooltip?: boolean;
  isCheckboxList?: boolean;
  fontType?: KeysAsType<FontsType>;
  onCheckboxChange?: (itemId, isChecked) => void;
};

type HierarchyListProps = {
  items?: ItemProps[];
} & CommonProps;

const HierarchyList: FC<HierarchyListProps> = ({
  items,
  isCheckboxList,
  onCheckboxChange,
  fontType,
  showTooltip,
}) => {
  const commonProps = {
    isCheckboxList,
    onCheckboxChange,
    fontType,
    showTooltip,
  };
  return (
    <div className="hierarchy_list_container">
      {items?.map((item) => (
        <div
          className={"list_parent_item_container"}
          key={`item_container-${item?.itemId}`}
        >
          {(item?.childrenItems?.length ?? 0) > 0 ? (
            <CollapseItem
              {...item}
              {...commonProps}
              key={`collapse-item-${item?.itemId}`}
            />
          ) : (
            <RegularItem
              {...item}
              {...commonProps}
              key={`regular-item-${item?.itemId}`}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default HierarchyList;
