import { FC, useEffect } from "react";
import {
  BILLING_ACCOUNTS_ID,
  INSURED_ATOM_KEY,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  addQueryStrings,
  getQueryValue,
  removeQueryStrings,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import BillingAccountsTable from "./BillingAccountsTable";

type BillingAccountsWrapperProps = {
  insuredId: number;
  tabKey: string;
};

const BillingAccountsWrapper: FC<BillingAccountsWrapperProps> = ({
  insuredId,
  tabKey,
}) => {
  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );

  const getSectionId = () => {
    if (getQueryValue(BILLING_ACCOUNTS_ID) !== "") {
      return parseInt(getQueryValue(BILLING_ACCOUNTS_ID));
    }
    return 1;
  };

  const GetSection = () => {
    switch (getSectionId()) {
      case 1:
        return <BillingAccountsTable insuredId={insuredId} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const sectionId = getSectionId();
    addQueryStrings([
      { nameOfHash: BILLING_ACCOUNTS_ID, valueOfHash: sectionId },
    ]);

    setAtom({
      ...getAtom(),
      insuredBodySection: SubSideNavItemDestinationsEnum.BILLING_ACCOUNTS,
    });
    return () => {
      removeQueryStrings([BILLING_ACCOUNTS_ID]);
    };
  }, []);

  return GetSection();
};

export default BillingAccountsWrapper;
