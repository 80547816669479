import { Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { WagesContainerDto } from "../../../../../../dtos/wages-container-dto";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { Col, Input, InputDate, Row, Select } from "../../../../../TrueUI";
import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { RowProps } from "../../../../../TrueUI/Grids/Row";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import { formInputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../utilities/conditionalSupportFunctions";
import { ClassCodeDto } from "../../../../../../dtos/class-code-dto";

type ClaimWagesContainerExpandedProps = {
  wagesUI: WagesContainerDto | null;
  setWagesUI: (wagesData: WagesContainerDto) => void;
  errorDetails?: any;
  insuredIdAtomKey?: string;
  configuration?: DetailsConfigurationDto;
  hasClaimsManagementPermissions: boolean;
};

const rowConfiguration: RowProps = {
  verticalAlign: "flex-start",
  horizontalAlign: "flex-start",
  horizontalMargin: "0px",
  horizontalGutter: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",
  allowWrap: true,
};

const colConfiguration: ColProps = {
  breakpoints: { md: 3, lg: 3, xl: 3 },
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
};

const selectConfiguration = (
  id: string,
  name: string,
  label: string,
  readOnly: boolean
) => {
  return {
    id: id,
    name: name,
    label: label,
    readOnly: readOnly,
  };
};

const ClaimWagesContainerExpanded: FC<ClaimWagesContainerExpandedProps> = ({
  wagesUI,
  setWagesUI,
  configuration,
  errorDetails,
  insuredIdAtomKey = "",
}) => {
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const readOnly = !wagesUI?.isExpanded ?? false;

  const setWageData = (updatedWagesObject: any) => {
    const atomValue = getAtom();
    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "wagesContainerData",
      updatedWagesObject
    );
    setAtom(newAtomValue);
    setWagesUI(updatedWagesObject);
  };

  const getValuesByClassCode = (classCodesFound: ClassCodeDto[]) => {
    if (classCodesFound.length === 0) {
      return {
        classCodeId: -1,
        classCodeDescription: "",
        classCodeDescriptionErrorMessage: ["Invalid Class Code."],
      };
    }
    if (classCodesFound.length > 1) {
      return {
        classCodeId: -1,
        classCodeDescription: "",
        classCodeDescriptionErrorMessage: [
          "More than one Class Code coincidence, contact support to perform a data clean task.",
        ],
      };
    }
    return {
      classCodeId: classCodesFound[0].classCodeId ?? -1,
      classCodeDescription: classCodesFound[0].description ?? "",
      classCodeDescriptionErrorMessage: null,
    };
  };

  const setDescriptionByClassCode = (classCode: string) => {
    const classCodes: ClassCodeDto[] = configuration?.classCodeDtos ?? [];
    if (!isEmptyValue(classCode)) {
      const classCodesFound = classCodes.filter(
        (cc) => cc.classCode === classCode
      );
      const {
        classCodeId,
        classCodeDescription,
        classCodeDescriptionErrorMessage,
      } = getValuesByClassCode(classCodesFound);

      setWageData({
        ...wagesUI,
        classCode,
        classCodeId,
        classCodeDescription,
        classCodeDescriptionErrorMessage,
      });
    } else {
      setWageData({
        ...wagesUI,
        classCode,
        classCodeId: null,
        classCodeDescription: "",
        classCodeDescriptionErrorMessage: null,
      });
    }
  };

  const listenerWagesComponent = useRecoilValue(
    TriggerComponentUpdateAtom("wagesComponent")
  );

  useEffect(() => {
    if (listenerWagesComponent !== null) {
      const updatedAtom = getAtom();
      setWagesUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.wagesContainerData,
      });
    }
  }, [listenerWagesComponent]);

  useEffect(() => {
    if (conditionHasValue(errorDetails))
      setWageData({
        ...wagesUI,
        isExpanded: true,
      });
  }, [errorDetails]);

  return (
    <Stack width={"100%"} zIndex={99}>
      <Row {...rowConfiguration}>
        {wagesUI?.claimManualClassCode ? (
          <>
            <Col
              horizontalGutter="5px"
              horizontalAlign="flex-start"
              breakpoints={{ md: 3, lg: 3, xl: 3 }}
            >
              <Input
                tabIndex={66}
                id="id-class-code"
                name="wagesCollapseClassCode"
                label="Class Code"
                readOnly={readOnly}
                value={wagesUI?.classCode}
                labelFontType={"BOLD_CAPTION"}
                errorMessage={
                  wagesUI?.classCodeDescriptionErrorMessage ??
                  errorDetails?.classCode
                }
                inputFontType={"BODY"}
                onChangeRawValue={(value) =>
                  setWageData({
                    ...wagesUI,
                    classCode: value,
                  })
                }
                onBlur={(e) => setDescriptionByClassCode(e.target?.value ?? "")}
                maxLength={5}
              />
            </Col>
            <Col
              horizontalGutter="5px"
              horizontalAlign="flex-start"
              breakpoints={{ md: 9, lg: 9, xl: 9 }}
            >
              <Input
                tabIndex={66}
                id="id-class-code-description"
                name="wagesCollapseClassCodeDescription"
                label="Description"
                readOnly
                value={wagesUI?.classCodeDescription}
                labelFontType={"BOLD_CAPTION"}
                inputFontType={"BODY"}
              />
            </Col>
          </>
        ) : (
          <Col
            horizontalGutter="5px"
            horizontalAlign="flex-start"
            breakpoints={{ md: 9, lg: 9, xl: 9 }}
          >
            <Select
              {...selectConfiguration(
                "id-class-code",
                "wagesCollapseClassCode",
                "Class Code",
                readOnly
              )}
              tabIndex={67}
              options={configuration?.classCodes ?? []}
              value={wagesUI?.classCode}
              labelFontType={"CAPTION"}
              inputFontType={"BODY"}
              onChange={(value) =>
                setWageData({
                  ...wagesUI,
                  classCode: value,
                })
              }
            />
          </Col>
        )}
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            tabIndex={68}
            {...formInputConfiguration(
              "id-occupation",
              "wagesCollapseOccupation",
              "Occupation"
            )}
            value={wagesUI?.occupation}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                occupation: value,
              })
            }
            maxLength={50}
            errorMessage={errorDetails?.occupation}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={69}
            {...formInputConfiguration(
              "id-occupation-code",
              "wagesCollapseOccupationCode",
              "Occupation Code"
            )}
            value={wagesUI?.occupationCode}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                occupationCode: value,
              })
            }
            maxLength={20}
            errorMessage={errorDetails?.occupationCode}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={70}
            {...formInputConfiguration(
              "id-job-title",
              "wagesCollapseJobTitle",
              "Job Title"
            )}
            value={wagesUI?.jobTitle}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                jobTitle: value,
              })
            }
            maxLength={50}
            errorMessage={errorDetails?.jobTitle}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            tabIndex={71}
            {...formInputConfiguration(
              "id-department",
              "wagesCollapseDepartment",
              "Department"
            )}
            value={wagesUI?.department}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                department: value,
              })
            }
            maxLength={20}
            errorMessage={errorDetails?.department}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={72}
            {...formInputConfiguration(
              "id-supervisor",
              "wagesCollapseSupervisor",
              "Supervisor"
            )}
            value={wagesUI?.supervisor}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                supervisor: value,
              })
            }
            maxLength={50}
            errorMessage={errorDetails?.supervisor}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={73}
            {...formInputConfiguration(
              "id-security-id",
              "wagesCollapseSecurityId",
              "Security ID"
            )}
            value={wagesUI?.securityID}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                securityID: value,
              })
            }
            maxLength={20}
            errorMessage={errorDetails?.securityID}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={74}
            {...formInputConfiguration(
              "id-medicare-eligible",
              "wagesCollapseMedicareEligible",
              "Medicare"
            )}
            options={configuration?.yesNoOptions ?? []}
            value={wagesUI?.medicareEligible}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                medicareEligible: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            tabIndex={75}
            {...formInputConfiguration(
              "id-weekly-wage",
              "wagesCollapseWeeklyWage",
              "Weekly Wage (AWW) "
            )}
            type="currency"
            value={wagesUI?.weeklyWage}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                weeklyWage: value,
              })
            }
            maxLength={14}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={76}
            {...formInputConfiguration(
              "id-other-weekly-wage",
              "wagesCollapseOtherWeeklyWage",
              "Other AWW"
            )}
            type="currency"
            value={wagesUI?.otherWeeklyWage}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                otherWeeklyWage: value,
              })
            }
            maxLength={14}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={77}
            {...formInputConfiguration(
              "id-daily-comp-rate",
              "wagesCollapseDailyCompRate",
              "Daily Comp Rate"
            )}
            type="currency"
            value={wagesUI?.dailyCompRate}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                dailyCompRate: value,
              })
            }
            maxLength={14}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <InputDate
            tabIndex={78}
            {...formInputConfiguration(
              "id-date-hired",
              "wagesCollapseDateHired",
              "Date Hired"
            )}
            value={wagesUI?.dateHired}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                dateHired: value,
              })
            }
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={79}
            {...formInputConfiguration(
              "id-state-of-hire",
              "wagesCollapseStateOfHire",
              "State of Hire"
            )}
            optionsMaxHeight="150px"
            options={configuration?.statesOfHireOptions ?? []}
            value={wagesUI?.stateOfHire}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                stateOfHire: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={80}
            {...formInputConfiguration(
              "id-employment-type",
              "wagesCollapseEmploymentType",
              "Employment Type"
            )}
            options={configuration?.employmentTypeOptions ?? []}
            value={wagesUI?.employmentType}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                employmentType: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={81}
            {...formInputConfiguration(
              "id-employment-status",
              "wagesCollapseEmploymentStatus",
              "Employment Status"
            )}
            options={configuration?.employmentStatusOptions ?? []}
            value={wagesUI?.employmentStatus}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                employmentStatus: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <InputDate
            tabIndex={82}
            {...formInputConfiguration(
              "id-date-terminated",
              "wagesCollapseDateTerminated",
              "Date Terminated"
            )}
            value={wagesUI?.dateTerminated}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                dateTerminated: value,
              })
            }
          />
        </Col>
        <Col {...colConfiguration}>
          <InputDate
            tabIndex={83}
            {...formInputConfiguration(
              "id-last-day-worked",
              "wagesCollapseLastDayWorked",
              "Last Day Worked"
            )}
            value={wagesUI?.lastDayWorked}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                lastDayWorked: value,
              })
            }
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={84}
            {...formInputConfiguration(
              "id-paid-on-last-day",
              "wagesCollapsePaidOnLastDay",
              "Paid on Last Day?"
            )}
            options={configuration?.yesNoOptions ?? []}
            value={wagesUI?.paidOnLastDay}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                paidOnLastDay: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
        <Col {...colConfiguration}>
          <InputDate
            tabIndex={85}
            {...formInputConfiguration(
              "id-est-return-to-work",
              "wagesCollapseEstimateReturnToWork",
              "Est. Return To Work"
            )}
            value={wagesUI?.estimatedReturnToWork}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                estimatedReturnToWork: value,
              })
            }
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            tabIndex={86}
            {...formInputConfiguration(
              "id-wage-amount",
              "wagesCollapseWageAmount",
              "Wage Amount"
            )}
            type="currency"
            value={wagesUI?.wageAmount}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                wageAmount: value,
              })
            }
            maxLength={14}
            errorMessage={errorDetails?.wageAmount}
          />
        </Col>
        <Col {...colConfiguration}>
          <Select
            tabIndex={87}
            {...formInputConfiguration(
              "id-wage-period",
              "wagesCollapseWagePeriod",
              "Wage Period"
            )}
            options={configuration?.wagePeriodsOptions ?? []}
            value={wagesUI?.wagePeriod}
            onChange={(value) =>
              setWageData({
                ...wagesUI,
                wagePeriod: value,
              })
            }
            firstOptionAsDefault={false}
          />
        </Col>
      </Row>
      <Row {...rowConfiguration}>
        <Col {...colConfiguration}>
          <Input
            tabIndex={88}
            {...formInputConfiguration(
              "id-temp-disability-rate",
              "wagesCollapseTempDisabilityRate",
              "Temp. Disability Rate"
            )}
            type="currency"
            value={wagesUI?.tempDisabilityRate}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                tempDisabilityRate: value,
              })
            }
            maxLength={14}
            errorMessage={errorDetails?.tempDisabilityRate}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={89}
            {...formInputConfiguration(
              "id-vocational-rehab-rate",
              "wagesCollapseVocationalRehabRate",
              "Vocational Rehab Rate"
            )}
            type="currency"
            value={wagesUI?.vocationalRehabRate}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                vocationalRehabRate: value,
              })
            }
            maxLength={14}
            errorMessage={errorDetails?.vocationalRehabRate}
          />
        </Col>
        <Col {...colConfiguration}>
          <Input
            tabIndex={90}
            {...formInputConfiguration(
              "id-permanent-disability-rate",
              "wagesCollapsePermanentDisabilityRate",
              "Permanent Disability Rate"
            )}
            type="currency"
            value={wagesUI?.permanentDisabilityRate}
            onChangeRawValue={(value) =>
              setWageData({
                ...wagesUI,
                permanentDisabilityRate: value,
              })
            }
            maxLength={14}
            errorMessage={errorDetails?.permanentDisabilityRate}
          />
        </Col>
      </Row>
    </Stack>
  );
};

export default ClaimWagesContainerExpanded;
