import { DeleteDialogConfigurationProps } from "./AchTypes";

export const defaultDeleteTitle = "Are you sure you want to delete this batch?";
export const defaultDialogConfiguration: DeleteDialogConfigurationProps = {
  deleting: false,
  undoingTransaction: false,
  deleteTitle: defaultDeleteTitle,
  dispatchPost: false,
};

export const onDeleteAndUndoTransactionsYes = (
  dialogConfiguration,
  setDialogConfiguration
) => {
  if (
    dialogConfiguration.deleting === true &&
    dialogConfiguration.deleteTitle === defaultDeleteTitle
  ) {
    setDialogConfiguration({
      ...dialogConfiguration,
      deleteTitle: "Would you like to undo the scheduled transactions also?",
    });
  }
  if (
    dialogConfiguration.deleting === true &&
    dialogConfiguration.deleteTitle !== defaultDeleteTitle
  ) {
    setDialogConfiguration({
      ...dialogConfiguration,
      dispatchPost: true,
      undoingTransaction: true,
    });
  }
};

export const onDeleteAndUndoTransactionsNo = (
  dialogConfiguration,
  setDialogConfiguration
) => {
  if (
    dialogConfiguration.deleting === true &&
    dialogConfiguration.deleteTitle !== defaultDeleteTitle
  ) {
    setDialogConfiguration({
      ...dialogConfiguration,
      dispatchPost: true,
    });
  } else {
    setDialogConfiguration(defaultDialogConfiguration);
  }
};
