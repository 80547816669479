import { FC } from "react";
import { Heading6 } from "../../../../../TrueUI";

type ClaimFinancialHeaderProperties = {
  title: string;
};
const ClaimFinancialHeader: FC<ClaimFinancialHeaderProperties> = ({
  title,
}) => {
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     height: "10%",
    //     // flexGrow: 1,
    //     // alignItems: "end",
    //   }}
    // >
    <Heading6
      className={"financial-title"}
      whiteSpace="nowrap"
      textAlign="start"
      true-element="true-element-label-financial-title"
      display={"block"}
    >
      {title}
    </Heading6>
    // </div>
  );
};

export default ClaimFinancialHeader;
