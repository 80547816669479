import { FC, useEffect, useState } from "react";
import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { ProgramNarrativePage } from "../../../../dtos/program-narrative-page";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { Button, Loading } from "../../../TrueUI";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import {
  DialogConfirmationConfigurationProps,
  PolicyQuoteInsuredAndActiveSetterProps,
  UNDERWRITING_NOTES_SECTION,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
} from "../updatesPolicyQuoteFunctions";
import Note from "./Note";
import {
  getPolicyNarrativeListFromConfiguration,
  collapseAll,
  collapseItem,
} from "./UnderwriterNotesUtils";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import style from "./UnderwriterNotes.module.css";
import UnderwriterNoteModal from "./UnderwriterNoteModal";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { mergeObjectsAttributes } from "../../../../utilities/objectFunctions";

const UnderwriterNotes: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  quoteStatus,
  setActiveSection,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [underwriterNotesUI, setUnderwriterNotesUI] = useState<
    PolicyNarrativeBlob[] | null
  >();
  const [currentNote, setCurrentNote] = useState<PolicyNarrativeBlob | null>(
    null
  );
  const [dialogConfirmationConfiguration, setDialogConfirmationConfiguration] =
    useState<DialogConfirmationConfigurationProps>({
      isOpen: false,
      text: "",
      option: "save-one",
    });

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const { responseGet, dispatchGet } = useApiGet<ProgramNarrativePage>(
    "api/ProgramNarrative/GetUnderwriterNotesPage"
  );

  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${UNDERWRITING_NOTES_SECTION}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const isAllExpanded = underwriterNotesUI?.every((x) => x.expanded) ?? false;

  const isNotLastUnderwriterNote = (noteIndex: number) =>
    (underwriterNotesUI?.length ?? 0) - 1 != noteIndex;

  const saveUnderwriterNotes = () => {
    const atomValue = getAtom();
    const newAtomValueForPolicyQuote = updatePolicyQuote(
      atomValue,
      "narratives",
      underwriterNotesUI
    );
    setAtom(newAtomValueForPolicyQuote);
    setReadyToSave(true);
  };

  useEffect(() => {
    if (readyToSave === true) {
      dispatchPost();
    }
  }, [readyToSave]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setReadyToSave(false);
      setDialogConfirmationConfiguration({
        isOpen: true,
        text: "Underwriting note saved",
        option: "save-all",
      });
    }
  }, [responsePost]);

  const collapseAllEvent = () => {
    const totalOfExpandedNotes =
      underwriterNotesUI?.filter((x) => x.expanded)?.length ?? 0;
    const collapsedNotes = collapseAll(
      underwriterNotesUI ?? [],
      totalOfExpandedNotes === 0
    );
    setUnderwriterNotesUI(collapsedNotes);
  };

  const updateExpandedFieldItem = (value: boolean, noteIndex: number) => {
    const updatedNotes = collapseItem(
      underwriterNotesUI ?? [],
      noteIndex,
      value
    );
    setUnderwriterNotesUI(updatedNotes);
  };

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) {
      dispatchGet();
    } else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const policyNarratives = policyQuote?.narratives ?? [];
      const newPolicyNarrativeList = getPolicyNarrativeListFromConfiguration(
        responseGet?.axiosResponse?.data.programNarrativeConfiguration ?? []
      );
      const mergedNarratives = Object.values(
        mergeObjectsAttributes(newPolicyNarrativeList, policyNarratives)
      ) as PolicyNarrativeBlob[];
      setUnderwriterNotesUI(mergedNarratives);
    }
  }, [responseGet]);

  return (
    <>
      <div className={style.underwriter_notes_container}>
        <div className={style.button_container}>
          <Button
            variantStyle={"outlined"}
            onClick={() => {
              collapseAllEvent();
            }}
          >
            {`${isAllExpanded ? "COLLAPSE" : "EXPAND"} ALL`}
          </Button>
        </div>
        <div className={style.style_box_container}>
          {underwriterNotesUI ? (
            <StyleBox
              showBorder
              padding={5}
              display="block"
              className={style.style_box_border}
              overrideBorderColor="var(--t-border-color-30)"
            >
              {underwriterNotesUI.map((note, noteIndex) => (
                <Note
                  key={noteIndex}
                  tabKey={tabKey}
                  noteIndex={noteIndex}
                  note={note}
                  editEvent={(note) => {
                    setCurrentNote(note);
                  }}
                  isCollapsed={(value) => {
                    updateExpandedFieldItem(value, noteIndex);
                  }}
                  collapseBottomMargin={isNotLastUnderwriterNote(noteIndex)}
                />
              ))}
            </StyleBox>
          ) : (
            <Loading isLoading={true} />
          )}
        </div>
        <PolicyQuoteBottomButtons
          tabKey={tabKey}
          insuredId={insuredId}
          policySection={UNDERWRITING_NOTES_SECTION}
          quoteStatus={quoteStatus}
          saveEvent={saveUnderwriterNotes}
        />
      </div>
      {currentNote && (
        <UnderwriterNoteModal
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          underwriterNotesUI={underwriterNotesUI}
          setUnderwriterNotesUI={setUnderwriterNotesUI}
        />
      )}
      <DialogConfirmation
        id="underwriterNoteDialogConfirmation"
        open={dialogConfirmationConfiguration?.isOpen}
        dialogDescriptionText={dialogConfirmationConfiguration?.text}
        optionYesOverrideLabel="OK"
        onOptionYesEvent={(close) => {
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
        }}
      />
    </>
  );
};

export default UnderwriterNotes;
