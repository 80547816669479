import { useMsal } from "@azure/msal-react";
import { Divider, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isProgramSelected } from "../../../GlobalAtoms";
import { useApiGet, useApiAuthenticate } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import Font from "../../TrueUI/Typography/Font";

const ProgramSelector = () => {
  const navigate = useNavigate();
  const [_isProgramSelected, _setIsProgramSelected] =
    useRecoilState(isProgramSelected);

  const [availablePrograms, setAvailablePrograms] = useState<any>();
  const [availableCustomers, setAvailableCustomers] = useState<any>();
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [selectedProgramUserId, setSelectedProgramUserId] = useState(null);
  const { accounts } = useMsal();
  const { responseGet: responseCustomerGet, dispatchGet: dispatchCustomerGet } =
    useApiGet<any>(
      `api/landing/getcustomers?key=${accounts[0].localAccountId}`
    );

  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/landing/getprograms?key=${accounts[0].localAccountId}&tenantId=${selectedTenantId}`
  );

  const [authenticationStatus, dispatchProgramAuthentication] =
    useApiAuthenticate(`api/authentication/authenticateUser`, {
      tenantId: responseGet?.responseData?.user?.tenantId,
      programUserId: selectedProgramUserId,
      programId: selectedProgramId,
      userId: responseGet?.responseData?.user?.userId,
      timeZoneId: responseGet?.responseData?.preference?.timeZoneID,
      isInternalUser: responseGet?.responseData?.user?.isInternal ?? false,
    });

  useEffect(() => {
    if (
      responseCustomerGet?.responseData &&
      responseCustomerGet?.responseData?.length >= 1
    ) {
      if (responseCustomerGet?.responseData?.length > 1)
        setAvailableCustomers(responseCustomerGet?.responseData);
      else {
        setAvailableCustomers(responseCustomerGet?.responseData);
        setSelectedTenantId(responseCustomerGet?.responseData[0].tenantId);
      }
    }
  }, [responseCustomerGet]);

  useEffect(() => {
    dispatchCustomerGet();
  }, []);

  useEffect(() => {
    if (
      responseGet?.responseData &&
      responseGet?.responseData?.availablePrograms.length >= 1
    ) {
      if (responseGet?.responseData?.availablePrograms.length > 1)
        setAvailablePrograms(responseGet?.responseData?.availablePrograms);
      else {
        setSelectedProgramUserId(
          responseGet?.responseData?.availablePrograms[0].programUserId
        );
        setSelectedProgramId(
          responseGet?.responseData?.availablePrograms[0].programId
        );
        setAvailablePrograms(responseGet?.responseData?.availablePrograms);
      }
    }
  }, [responseGet]);
  useEffect(() => {
    if (selectedTenantId) dispatchGet();
  }, [selectedTenantId]);

  useEffect(() => {
    if (selectedProgramId !== null && selectedProgramUserId !== null) {
      dispatchProgramAuthentication();
    }
  }, [selectedProgramId, selectedProgramUserId]);

  useEffect(() => {
    if (authenticationStatus === 200) {
      _setIsProgramSelected(true);
    } else {
      _setIsProgramSelected(false);
    }
  }, [authenticationStatus]);

  return (
    <div id={"landing_login"}>
      <Font textAlign="center">
        {availablePrograms ? "Select a Program" : "Select a Customer"}
      </Font>
      <Divider className="divider" />
      {availableCustomers && availablePrograms == null && (
        <MenuList>
          {(availableCustomers ?? []).map((customer: any, i: number) => {
            return (
              <MenuItem
                className="program-item"
                key={"c" + i}
                onClick={() => {
                  setSelectedTenantId(customer.tenantId);
                }}
              >
                <ListItemText>{customer.customerName}</ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      )}
      <MenuList>
        {(availablePrograms ?? []).map((program: any, i: number) => {
          return (
            <MenuItem
              key={"u" + i}
              className="program-item"
              onClick={() => {
                setSelectedProgramUserId(program.programUserId);
                setSelectedProgramId(program.programId);
                navigate("/home");
              }}
            >
              <ListItemText>{program.programName}</ListItemText>
            </MenuItem>
          );
        })}
      </MenuList>
    </div>
  );
};

export default ProgramSelector;
