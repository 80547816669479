import { FC } from "react";
import style from "../Styles/basegridcells.module.css";

type DefaultPhoneExtCellProperties = {
  value: string;
};

const DefaultPhoneExtCell: FC<DefaultPhoneExtCellProperties> = ({ value }) => {
  const getPhone = value.length > 9 ? value.substring(0, 10) : value;
  const getExt = value.length > 9 ? value.substring(10, value.length) : "";
  const formatedPhone = getPhone
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  if (value === "") {
    return <div className={style.default_phone_render_only_cell}>{""}</div>;
  } else {
    return (
      <>
        <div className={style.default_phone_render_only_cell}>
          {formatedPhone}
        </div>
        <div className={style.default_phone_ext_render_only_cell}>Ext</div>
        <div
          className={style.default_phone_ext_value_render_only_cell}
          style={{ display: "inline-block" }}
        >
          {getExt}
        </div>
      </>
    );
  }
};

export default DefaultPhoneExtCell;
