import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Search, FilterAlt } from "@mui/icons-material";
import { Box } from "@mui/material";
import { globalOptions } from "../../../../GlobalAtoms";
import { VerticalStepper, StepsProps } from "../../../TrueUI/Steppers";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import { Font } from "../../../TrueUI";
import { getDateFromUtcToUserTimeZone } from "../../../../utilities/dateTimeZoneFunctions";
import ActivityItem from "../../../Activity/ActivityItem";
import ActivityIcon from "../../../Activity/ActivityIcon";
import "./AuditActivityFeedStyles.module.css";
import { ActivityLogDto } from "../../../../dtos/activity-log-dto";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

type AuditActivityFeedProps = {
  insuredId?: number | null;
  policyId?: number | null;
};

const AuditActivityFeed: FC<AuditActivityFeedProps> = ({
  insuredId,
  policyId,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const [activityList, setActivityList] = useState<ActivityLogDto[]>([]);
  const { responseGet, dispatchGet } = useApiGet<ActivityLogDto[]>(
    `api/ActivityLog/GetAuditActivityLog?insuredId=${insuredId}&policyId=${policyId}`
  );

  const activityData: StepsProps[] = (activityList ?? []).map((_activity) => ({
    stepIcon: <ActivityIcon category={_activity?.category} />,
    stepInfo: (
      <ActivityItem
        title={_activity?.description}
        date={FormattingDate(
          getDateFromUtcToUserTimeZone(
            _activity?.createOn,
            localOptions?.timeZoneID ?? null
          ),
          "M/D/YYYY h:mm a"
        )}
        user={_activity?.createByName}
      />
    ),
  }));

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setActivityList(responseGet.axiosResponse?.data ?? []);
    }
  }, [responseGet]);

  return (
    <Box className="activity-section-container">
      <InformationContainer
        className={"activity-container"}
        title={
          <Box className="activity-header">
            <Font name="activityLabel">Activity</Font>
            <Box className={"filters"}>
              <Search
                onClick={() => {
                  alert("Waiting instructions");
                }}
              />
              <FilterAlt
                onClick={() => {
                  alert("Waiting instructions");
                }}
              />
            </Box>
          </Box>
        }
      >
        <div className="activity-stepper">
          <VerticalStepper steps={activityData}></VerticalStepper>
        </div>
      </InformationContainer>
    </Box>
  );
};
export default AuditActivityFeed;
