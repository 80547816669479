import { atom } from "recoil";
import { UserAndPermissionsDto } from "../../../dtos/user-and-permissions-dto";

type UserViewInformation = {
  userId?: number;
  tenantId?: number;
  programId?: number;
  status?: number;
  //This will help on the new user functionality
  newUserName?: string;
};

const UserAndPermissionsEditModalAtom = atom<{
  modal: {
    isOpen: boolean;
    title: string;
    openState: "add" | "edit" | null;
    state: "reload_data" | null;
  };
  user: UserAndPermissionsDto;
}>({
  key: "UserAndPermissionsEditModalAtom",
  default: {
    modal: {
      isOpen: false,
      title: "",
      openState: "add",
      state: null,
    },
    user: {
      tenantId: null,
      longName: "",
      shortName: "",
      email: "",
      userId: null,
      defaultProgramId: null,
    },
  },
});

const DeleteUserConfirmationModalAtom = atom<{
  isOpen: boolean;
  status: "deleted" | "cancelled" | null;
}>({
  key: "DeleteUserConfirmationModalAtom",
  default: {
    isOpen: false,
    status: null,
  },
});

const UserViewInformationAtom = atom<UserViewInformation | null>({
  key: "UserViewInformationAtom",
  default: null,
});

const UserCustomerProgramSelectedAtom = atom<number | null>({
  key: "UserCustomerProgramSelectedKey",
  default: null,
});

const SimulateUserUpdatedAtom = atom<{
  userId: number;
  longName: string;
} | null>({
  key: "SimulateUserUpdatedAtomKey",
  default: null,
});

export {
  UserAndPermissionsEditModalAtom,
  DeleteUserConfirmationModalAtom,
  UserCustomerProgramSelectedAtom,
  UserViewInformationAtom,
  SimulateUserUpdatedAtom,
};
