import { useState, FC, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { Tabs, TabPanel, Button } from "../TrueUI";
import InsuredBodyWrapper from "./InsuredBodyWrapper";
import InsuredDrawerContainer from "./InsuredDrawer/InsuredDrawerContainer";
// import { addQueryStrings, getQueryValue } from "../../hooks/useURL";
import { CLAIM_NUMBER, INSURED_ID } from "../../utilities/queryStringsHash";
import InsuredTabsTitle from "./InsuredTabsTitle";
import { useNavigate } from "react-router-dom";
import { TabDto } from "./Insured";
import InsuredLeftSubNav from "./InsuredLeftSubNav/InsuredLeftSubNav";
import { removeOpenTabByTabKey } from "../../utilities/localStorageFunctions";
import { getByInsuredIdAndClaimNumber } from "../../utilities/localStorageFunctions";
import style from "./InsuredInstanceWrapper.module.css";
import {
  addQueryStrings,
  getQueryValue,
} from "../../utilities/URLUtilities_OBSOLETE";
type InsuredInstanceWrapperProps = {
  tabList: TabDto[];
  insuredId: number;
};

const InsuredInstanceWrapper: FC<InsuredInstanceWrapperProps> = ({
  tabList,
  insuredId,
}) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number | null>(null);
  const [removedTab, setRemovedTab] = useState<string | null>(null);
  const [insuredListToRender, setInsuredListToRender] = useState(tabList);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollLeft = () => {
    const container = tabsContainerRef.current;
    const ulElement: any = container?.querySelector(".k-tabstrip-items");
    if (ulElement != null) {
      ulElement.scrollLeft -= 150;
    }
  };

  const handleScrollRight = () => {
    const container = tabsContainerRef.current;
    const ulElement: any = container?.querySelector(".k-tabstrip-items");
    if (ulElement != null) {
      ulElement.scrollLeft += 150;
    }
  };

  const checkToHideButton = () => {
    const container = tabsContainerRef.current;
    const ulElement: any = container?.querySelector(".k-tabstrip-items");
    const scrollLeft = ulElement?.scrollLeft;
    const scrollWidth = ulElement?.scrollWidth;
    const clientWidth = ulElement?.clientWidth;
    const isOverflowing = scrollWidth > clientWidth;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(isOverflowing && scrollLeft < scrollWidth - clientWidth);
  };

  const selectTab = () => {
    if (removedTab != null) {
      setSelected(insuredListToRender.length - 1);
      setRemovedTab(null);
      addQueryStrings(
        [
          {
            nameOfHash: INSURED_ID,
            valueOfHash:
              insuredListToRender[insuredListToRender.length - 1]?.information
                ?.insuredInformation?.insuredId,
          },
        ],
        true
      );
    } else {
      const existentTab = getByInsuredIdAndClaimNumber(
        insuredId,
        getQueryValue(CLAIM_NUMBER)
      );
      let indexOfInsuredTab = insuredListToRender.findIndex(
        (tab) => tab?.tabKey === existentTab?.tabKey
      );
      const newSelectedTab = indexOfInsuredTab != -1 ? indexOfInsuredTab : 0;
      setSelected(newSelectedTab);
    }
    lastActions();
  };

  const onTabClick = (e) => {
    addQueryStrings(
      [
        {
          nameOfHash: INSURED_ID,
          valueOfHash:
            insuredListToRender[e.selected]?.information?.insuredInformation
              ?.insuredId,
        },
      ],
      true
    );
    setSelected(e.selected);
  };

  useEffect(() => {
    if (isReadyToRender) {
      const container = tabsContainerRef.current;
      const ulElement: any = container?.querySelector(".k-tabstrip-items");
      checkToHideButton();
      ulElement.addEventListener("scroll", checkToHideButton);
      window.addEventListener("resize", checkToHideButton);
    } else {
      selectTab();
    }
  }, [isReadyToRender]);

  useEffect(() => {
    if (
      insuredListToRender !== null &&
      insuredListToRender.length > 0 &&
      removedTab !== null
    ) {
      removeOpenTabByTabKey(removedTab);

      setInsuredListToRender(
        insuredListToRender.filter((item: TabDto) => {
          return item?.tabKey !== removedTab;
        })
      );
    } else {
      if (insuredListToRender.length === 0) {
        navigate("/home");
      }
    }
  }, [removedTab]);

  const lastActions = () => {
    setIsReadyToRender(true);
    checkToHideButton();
  };

  useEffect(() => {
    setInsuredListToRender(tabList);
  }, [tabList]);

  useEffect(() => {
    setIsReadyToRender(false);
  }, [insuredListToRender]);

  useEffect(() => {
    selectTab();
  }, []);

  return (
    <>
      {isReadyToRender && (
        <Box ref={tabsContainerRef} className={style.container_tabs}>
          <div id="tabArrowButtons" className={style.controls}>
            {showLeftArrow && (
              <Button
                className={`${style.tabs_button} ${style.left_button}`}
                onClick={handleScrollLeft}
              >
                <KeyboardArrowLeft />
              </Button>
            )}
            {showRightArrow && (
              <Button
                className={`${style.tabs_button} ${style.right_button}`}
                onClick={handleScrollRight}
              >
                <KeyboardArrowRight />
              </Button>
            )}
          </div>
          <Tabs
            name={"insured-wrapper-tmp"}
            className={style.insured_instance_wrapper_tabs}
            onSelect={onTabClick}
            selected={selected!!}
            themeOverride={"square"}
            stateControlled
            disableQueryString
          >
            {insuredListToRender?.map((tab: TabDto, index) => {
              return (
                <TabPanel
                  title={
                    <InsuredTabsTitle tab={tab} setRemovedTab={setRemovedTab} />
                  }
                  key={`panel-${tab?.tabKey} ${index}`}
                >
                  <Box id={"insured_wrapper"} className={style.insured_wrapper}>
                    <InsuredLeftSubNav
                      insuredTabContent={tab?.information}
                      tabKey={`${tab?.tabKey}`}
                    />
                    <Box
                      id={"right_insured_wrapper"}
                      className={`customScrollBar ${style.right_insured_wrapper}`}
                    >
                      <div
                        id={"insured_body_wrapper"}
                        className={style.insured_body_wrapper}
                      >
                        <InsuredBodyWrapper insuredTabContent={tab} />
                      </div>
                    </Box>
                    <Box id={"right_tool_wrapper"}>
                      <InsuredDrawerContainer
                        tabKey={`${tab?.tabKey}`}
                        insuredId={
                          tab?.information?.insuredInformation?.insuredId
                        }
                        insuredCode={
                          tab?.information?.insuredInformation?.insuredCode
                        }
                      />
                    </Box>
                  </Box>
                </TabPanel>
              );
            })}
          </Tabs>
        </Box>
      )}
    </>
  );
};

export default InsuredInstanceWrapper;
