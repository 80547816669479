import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { Divider, Font, Select } from "../../../TrueUI";
import { useRecoilState } from "recoil";
import { UserViewInformationAtom } from "../AdminSecurityAtoms";
import UsersTableWrapper from "./UsersTableWrapper";

const UsersAndPermissions: FC = () => {
  const [customerOptions, setCustomerOptions] = useState<SelectOptions[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<number>();

  const { dispatchGet, responseGet } = useApiGet<SelectOptions[]>(
    "api/AdminTools/Security/UserListing/GetCustomersForUser"
  );

  const [userInformationView, setUserInformationView] = useRecoilState(
    UserViewInformationAtom
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCustomerOptions(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    setUserInformationView({
      ...userInformationView,
      tenantId: selectedCustomer,
    });
  }, [selectedCustomer]);

  return (
    <div
      id={"users_and_permissions_container"}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "20px",
        marginBottom: "65px",
      }}
    >
      <Font fontType="HEADING_6" textAlign="start">
        Admin Tools - Security
      </Font>
      <Divider width={"90%"} showBorder borderSize={"1px"} height={"10px"} />
      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Select
          id={"customer-select"}
          name={"customer-select"}
          options={customerOptions}
          inputWidth={"500px"}
          label={"Customer"}
          labelPosition={"start"}
          labelFontType={"BOLD_CAPTION"}
          onChange={(value) => setSelectedCustomer(value)}
        />
      </div>
      <UsersTableWrapper selectedCustomer={selectedCustomer} />
    </div>
  );
};
export default UsersAndPermissions;
