import { Theme } from "../../../media/themeTypes";

export const insuredPanelStyles = (theme: Theme) => {
  return {
    "& .MuiBox-root": {
      background: theme?.PRIMARY_BACKGROUND,
    },
    background: theme?.PRIMARY_BACKGROUND,
    display: "flex",
    width: "100%",
    py: 1,
    "& .left-container": {
      width: "115px",
      pl: 1,
      textAlign: "left",
      "& .top-row": {
        display: "flex",
        alignItems: "center",
        "& svg": {
          transform: "rotate(90deg)",
          transition: "all 0.2s linear",
          color: theme?.PRIMARY,
          "&.pinned": {
            transform: "rotate(0)",
          },
        },
        "& span": {
          pl: 1,
        },
      },
    },
    "& .right-container": {
      width: "calc(100% - 115px)",
      textAlign: "left",
      borderLeft: 1,
      borderColor: theme?.BORDER_COLOR,
      pl: 2,
      "& .insured-name": {
        mb: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "span[true-element='true-element-label-insured-dba']": {
        display: "block",
        textAlign: "left",
        height: "21px",
      },
    },
  };
};
