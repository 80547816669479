import { BaseTableDisplayOptions } from "../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../dtos/cell-variant-enums";
import {
  ConditionForCellBaseAPIOptions,
  ConditionForCellResponse,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { ConditionForCellResponseType } from "../BaseGridProperties";

export const createConditionForCellResponse = (response: {
  cellKey: string;
  rowKey: string;
  // defaultValue: any,
  value: any;
  isEdit: boolean;
  typeId: BaseTableInputType | null;
  variant: string;
  targetFieldName: string;
  triggerComputes: string[];
  requestingColumn: string | null;
  responseType: ConditionForCellResponseType;
  apiRequestOptions: ConditionForCellBaseAPIOptions | null;
  listDisplayOptions: BaseTableDisplayOptions[] | null;
  isColumnsDisabled: string[] | null;
  refreshCell: boolean;
}) => {
  return {
    cellKey: response.cellKey ?? null,
    targetFieldName: response.targetFieldName ?? null,
    isEditable: response.isEdit ?? false,
    typeId: response.typeId ?? null,
    variant: response.variant ?? "standard",
    // defaultValue: defaultValue ?? null,
    value: response.value ?? "",
    preserveTargetValue: false,
    triggerComputeFieldNames: response.triggerComputes ?? [],
    requestingColumn: response.requestingColumn ?? null,
    responseType: response.responseType,
    apiRequestOptions: response.apiRequestOptions,
    listDisplayOptions: response.listDisplayOptions,
    isColumnsDisabled: response.isColumnsDisabled ?? null,
    refreshCell: response.refreshCell,
    _originalValue: response.value ?? "",
    _rowKey: response.rowKey,
  } as ConditionForCellResponse;
};

export const getCellVariant = (variant?: number | null) => {
  switch (variant) {
    case CellVariantEnums.STANDARD:
      return "standard";
    case CellVariantEnums.OUTLINED:
      return "outlined";
    case CellVariantEnums.FILLED:
    default:
      return "filled";
  }
};
