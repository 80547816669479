import { FC } from "react";

type DefaultHTMLCellProperties = {
  value: string;
};
const DefaultHTMLCell: FC<DefaultHTMLCellProperties> = ({ value }) => {
  return (
    <div
      style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      title={value}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};
export default DefaultHTMLCell;
