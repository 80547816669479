import { FC, useEffect } from "react";
import { useRecoilState } from "recoil";
import { GridCellManagerInstanceAtomFamily } from "../BaseGridAtoms";
import { useComputeProcessor } from "../Hooks/useComputeProcessor_LEGACY";

const BaseGridCellComputeInitProcessor: FC<any> = ({
  uiid,
  rowKey,
  row,
  listOfComputeFunctions,
}) => {
  const [cellAtomInstance, setCellAtomInstance] = useRecoilState(
    GridCellManagerInstanceAtomFamily(rowKey)
  );

  const { processComputes, isComplete } = useComputeProcessor(
    uiid,
    rowKey,
    row,
    listOfComputeFunctions,
    "computeOnInit"
  );

  const managerPreProcessors: {
    processName: string;
    process: any;
    status: any;
  }[] = [
    {
      processName: "processComputes",
      process: processComputes,
      status: isComplete,
    },
  ];

  useEffect(() => {
    if (
      listOfComputeFunctions.length > 0 &&
      cellAtomInstance.hasInitOnComputeTriggered === false &&
      cellAtomInstance.readyToRender === false &&
      managerPreProcessors.length > 0
    ) {
      if (managerPreProcessors.every((p) => p.status === true)) {
        setCellAtomInstance({
          ...cellAtomInstance,
          readyToRender: true,
          hasCompletedOperations: true,
          isNextManagerToProcess: false,
          hasInitOnComputeTriggered: true,
        });
      }
    }
  }, [managerPreProcessors]);

  useEffect(() => {
    if (
      listOfComputeFunctions.length > 0 &&
      cellAtomInstance.hasInitOnComputeTriggered === false
    ) {
      managerPreProcessors.map((p) => {
        p.process();
      });
    }
  }, [cellAtomInstance.isNextManagerToProcess]);
  return null;
};

export default BaseGridCellComputeInitProcessor;
