import { useEffect } from "react";
import { Select } from "../..";
import { SelectOptions } from "../../../../dtos/select-options";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import { SPECIAL_SORT_ORDER } from "../TableConstants";

type BaseTableSortFilterProps = {
  id: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  selectWidth?: string;
  onChange?: (e: any) => void;
};

const BaseTableSortFilter: (properties: BaseTableSortFilterProps) => any = ({
  id,
  filterOptions,
  columnsNames,
  defaultValue,
  labelText,
  labelPosition,
  selectWidth,
  onChange,
}) => {
  const defaultSortOptions: Partial<SelectOptions>[] = [
    { displayName: "Newest to Oldest", stringValue: "asc" },
    { displayName: "Oldest to Newest", stringValue: "desc" },
  ];

  const setStatus = (val: string) => {
    filterOptions?.setFilterInclude?.([
      {
        filterName: SPECIAL_SORT_ORDER,
        columnNames: columnsNames,
        filterValue: val,
      },
    ]);
  };

  useEffect(() => {
    setStatus(defaultValue?.toString() ?? "all");
  }, []);

  return (
    <Select
      id={`true-filter-for-table-${id}`}
      name={`filter-dropdown-${id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={labelText ?? "Show:"}
      labelPosition={labelPosition ?? "start"}
      options={defaultSortOptions}
      value={defaultValue ?? "all"}
      type={"tableFilter"}
      inputWidth={selectWidth ? selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        setStatus(value);
        onChange?.(value);
      }}
    />
  );
};

export default BaseTableSortFilter;
