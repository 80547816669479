import { FC, useEffect, useState } from "react";
import { Box, Link as MuiLink } from "@mui/material";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { groupArrayByKey } from "../../../../utilities/arrayFunctions";
import {
  getJSONDataWithoutConfigurations,
  getPolicyQuoteEndorsements,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  Caption,
  Col,
  Font,
  FontBold,
  Loading,
  Row,
  SmallTitle,
  Switch,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { PolicyQuoteEndorsementOptionalFormsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import {
  endorsementFormsRowStyles,
  endorsementStatesRowStyles,
} from "./EndorsementFormStyles";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import Link from "../../../TrueUI/Typography/Link";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";

const OptionalForms: FC<PolicyQuoteEndorsementOptionalFormsProps> = ({
  forms,
  tabKey,
  setSelectedOptionalForms,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [localForms, setLocalForms] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const setOptionalLocalForms = (
    checked: boolean,
    endorsementForm: ProgramEndorsementBlob
  ) => {
    const updatedEndorsementForms = localForms[
      endorsementForm?.stateCode ?? ""
    ]?.map((form: ProgramEndorsementBlob) => {
      return form.endorsementFormID === endorsementForm.endorsementFormID
        ? { ...form, checked: checked }
        : form;
    });
    setLocalForms({
      ...localForms,
      [endorsementForm?.stateCode ?? ""]: updatedEndorsementForms,
    });
  };

  const setOptionalFormsFromAtom = (
    atomEndorsements?: ProgramEndorsementBlob[] | null
  ) => {
    const groupedEndorsements = groupArrayByKey(
      atomEndorsements ?? [],
      "stateCode"
    );
    Object.keys(groupedEndorsements).map((key) => {
      const updatedEndorsementForms = forms?.[key]?.map(
        (form: ProgramEndorsementBlob) => {
          const endorsementFound = groupedEndorsements?.[key]?.find(
            (endorsement) =>
              endorsement.endorsementFormID === form.endorsementFormID
          );

          return endorsementFound
            ? { ...form, checked: endorsementFound.checked }
            : form;
        }
      );
      if (updatedEndorsementForms)
        setLocalForms((prev) => ({
          ...prev,
          [key]: updatedEndorsementForms,
        }));
    });
  };

  const checkedAllFormsInState = (state: string) => {
    const formsLength = localForms[state]?.length ?? 0;
    const formsCheckedLength =
      localForms[state]?.filter((form) => form.checked)?.length ?? 0;

    setLocalForms({
      ...localForms,
      [state]: localForms[state].map((form) => ({
        ...form,
        checked: formsLength !== formsCheckedLength,
      })),
    });
  };

  const { sendMergeFormRequest } = useFormRequest();

  const printCheckedForm = (form: ProgramEndorsementBlob) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (
      policyMergeFields !== undefined &&
      policyMergeFields !== null &&
      !isEmptyValue(form.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.POLICY_FORM,
        jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
          getAtom()?.policyQuoteInformation
        ),
        templateNames: [form.templateName ?? ""],
        customFileName: `${
          policyMergeFields.insured?.insuredName ?? ""
        } endorsement form ${form.formNumber ?? ""}`,
        printOrDownload: "download",
      });
    }
    if (isEmptyValue(form.templateName)) {
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    setLocalForms(forms);
    const atomValue = getAtom();
    const atomEndorsements = getPolicyQuoteEndorsements(atomValue);
    if (
      atomEndorsements &&
      atomEndorsements !== null &&
      atomEndorsements?.length > 0
    ) {
      setOptionalFormsFromAtom(atomEndorsements);
    }
  }, [forms]);

  useEffect(() => {
    if (localForms) {
      const endorsementForms = Object.values(
        localForms
      ).flat() as ProgramEndorsementBlob[];
      const checkedForms = endorsementForms.filter(
        (form) => form?.checked ?? false
      );
      setSelectedOptionalForms(checkedForms);
    }
  }, [localForms]);

  return localForms ? (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        {...colWithNoMarginNorGutter}
        {...getConfigurationForColumn(12, "flex-start", "center")}
        displayFlex={false}
      >
        <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
          <Font>OPTIONAL FORMS</Font>
        </Col>
      </Col>
      {localForms &&
        Object.keys(localForms)
          .sort()
          .map((key) => (
            <Box
              sx={endorsementStatesRowStyles(theme)}
              key={`optional-states-box-${key}`}
            >
              <Col
                key={`optional-forms-col-${key}`}
                horizontalGutter="5px"
                {...getConfigurationForColumn(12, "flex-start", "center")}
                displayFlex={false}
              >
                <MuiLink
                  variant="subtitle2"
                  onClick={() => checkedAllFormsInState(key)}
                >
                  <FontBold>
                    {localForms?.[key]?.[0]?.stateName.toUpperCase()}
                  </FontBold>
                </MuiLink>
                {localForms?.[key]?.map((form: ProgramEndorsementBlob) => (
                  <Box
                    sx={endorsementFormsRowStyles()}
                    key={`optional-forms-box-${form.endorsementFormID}`}
                  >
                    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
                      <Col
                        {...colWithNoMarginNorGutter}
                        {...getConfigurationForColumn(
                          5,
                          "flex-start",
                          "center"
                        )}
                      >
                        <Box display="flex" alignItems="center" width="150px">
                          <Switch
                            control="checkbox"
                            isChecked={form.checked}
                            onChangeIsChecked={(checked) =>
                              setOptionalLocalForms(checked, form)
                            }
                            primaryLabelColor
                            labelFontType={"BODY"}
                          />
                          <Link
                            linkFontType="BODY"
                            displayValue={`${form.formNumber}`}
                            onClick={() => printCheckedForm(form)}
                          />
                        </Box>
                        <SmallTitle>
                          {form.formName}{" "}
                          <Caption>({FormattingDate(form.formDate)})</Caption>
                        </SmallTitle>
                      </Col>
                    </Row>
                  </Box>
                ))}
              </Col>
            </Box>
          ))}
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Row>
  ) : (
    <Loading isLoading={true} />
  );
};

export default OptionalForms;
