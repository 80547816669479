import { Theme } from "../../../../media/themeTypes";

export const insuredInformationInputAddressStyles = () => ({
  display: "contents",
  ".main-row-input-address": {
    ".true_col_grid_wrapper": { paddingInline: "0px" },
    ".true_row_grid_wrapper": {
      ".true_col_grid_wrapper": {
        "&:nth-of-type(2)": { paddingInline: "25px" },
      },
    },
  },
});

export const insuredInformationNamesAndAddressesTableStyles = () => ({
  width: "-webkit-fill-available",
  ".MuiBox-root": {
    ".true_ui_base_table_container": {
      ".true_table_wrapper": {
        ".true_ui_table": {
          ".true_table_head": {
            ".true_table_row": {
              ".cell-collapse": {
                width: "auto",
                mr: "10px",
              },
            },
          },
        },
      },
    },
  },
});

export const errorMessageStyle = (theme: Theme) => ({
  color: theme?.DANGER,
  fontSize: theme?.SMALL_TITLE?.SIZE,
  letterSpacing: "0.03333em",
  textAlign: "left",
  margin: 0,
  display: "block",
});
