import { FC, useEffect, useState } from "react";
import {
  getPolicyEffectiveDayByPolicyId,
  getPolicyExpirationDayByPolicyId,
  ModalAddCancellationContentProps,
  getSplitButtonOptions,
  getPolicyNumberByPolicyId,
  getPolicyDatesByPolicyNumber,
  showFieldByReasonCancelCode,
  getPropertyByCancelReasonId,
} from "./CancellationUtil";
import {
  Button,
  Col,
  Font,
  Input,
  InputDate,
  InputMemo,
  Row,
  Select,
  SplitButton,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import Link from "../../../TrueUI/Typography/Link";
import { useApiGet, useFormRequest } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { CancellationStatusEnum } from "../../../../dtos/cancellation-status-enum";
import { getKeyFromEnumValue } from "../../../../utilities/enumFunctions";
import { CancellationConfigurationDto } from "../../../../dtos/cancellation-configuration-dto";
import { MergeFieldsAndTemplateNameDto } from "../../../../dtos/merge-fields-and-template-name-dto";
import { CancellationJSON } from "../../../../dtos/cancellation-json";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";

const CANCELLATION_TYPE = "cancellation";
const NOTIFICATION_TYPE = "notification";

const nonPaymentOfPremiumCancelCode = "05";
const saveAsCancellationIndex = 0;
const saveOnlyIndex = 1;

const ModalAddCancellationContent: FC<ModalAddCancellationContentProps> = ({
  insuredId,
  cancellationId,
  onSaveCancellationEvent,
  onReinstateOrRescindEvent,
  cancellationData,
  setCancellationData,
  errorMessages,
  onCancelEvent,
  onDeleteEvent,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentSelectionIndexSplitBtn, setCurrentSelectionIndexSplitBtn] =
    useState<number>(0);
  const { responseGet, dispatchGet } = useApiGet<CancellationConfigurationDto>(
    `api/Cancellation/GetCancellationConfiguration?insuredId=${insuredId}&cancellationId=${cancellationId}`
  );
  const [printType, setPrintType] = useState<string | null>(null);
  const { sendMergeFormRequest } = useFormRequest();
  const {
    responseGet: responseCancellationOrNotificationToPrint,
    dispatchGet: dispatchNotificationOrCancellationPrint,
  } = useApiGet<MergeFieldsAndTemplateNameDto<CancellationJSON>>(
    `api/Cancellation/GetCancellationOrNotificationToPrint?cancellationId=${cancellationId}&type=${printType}`
  );

  const [cancellationConfig, setCancellationConfig] =
    useState<CancellationConfigurationDto>();

  const printCancellationOrNotification = () => {
    const cancellationResponse =
      responseCancellationOrNotificationToPrint.axiosResponse?.data;
    if (
      cancellationResponse !== undefined &&
      cancellationResponse.templateName !== ""
    ) {
      const fileTypeName =
        printType === CANCELLATION_TYPE ? "Cancellation" : "Notification";
      sendMergeFormRequest({
        formType: FormTypeEnum.CANCELLATION_FORM,
        jsonObjectWithMergeFields: cancellationResponse.mergeFields,
        templateNames: [cancellationResponse.templateName],
        customFileName: `${cancellationResponse.mergeFields.insuredName} ${fileTypeName}`,
      });
    }
    if (
      cancellationResponse !== undefined &&
      cancellationResponse.templateName === ""
    ) {
      setDialogOpen(true);
    }
    setPrintType(null);
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCancellationConfig(responseGet.axiosResponse?.data);
      setCancellationData?.(responseGet.axiosResponse?.data?.cancellationData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(printType)) {
      dispatchNotificationOrCancellationPrint();
    }
  }, [printType]);

  useEffect(() => {
    if (isAPITotallyComplete(responseCancellationOrNotificationToPrint)) {
      printCancellationOrNotification();
    }
  }, [responseCancellationOrNotificationToPrint]);

  useEffect(() => {
    const selectedSplitBtnIndex =
      conditionHasValue(cancellationData?.cancelCode) &&
      cancellationData?.cancelCode === nonPaymentOfPremiumCancelCode
        ? saveOnlyIndex
        : saveAsCancellationIndex;
    setCurrentSelectionIndexSplitBtn(selectedSplitBtnIndex);
  }, [cancellationData?.cancelCode]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Input
            tabIndex={4}
            id="id-insured-name"
            name="insured-name"
            label="Insured Name"
            type="text"
            labelFontType="BOLD_CAPTION"
            value={cancellationData?.insuredName}
            readOnly
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            {...selectConfiguration(
              "id-policy-number",
              "policy-number",
              "Policy Number",
              false
            )}
            readOnly={cancellationData?.isFormReadOnly}
            firstOptionAsDefault={false}
            labelFontType="BOLD_CAPTION"
            options={cancellationConfig?.policyNumberList ?? []}
            value={cancellationData?.policyId}
            errorMessage={errorMessages?.policyId}
            onChange={(value) => {
              setCancellationData?.({
                ...cancellationData,
                policyId: value,
                policyNumber: getPolicyNumberByPolicyId(
                  value,
                  cancellationConfig?.policyNumberList ?? []
                ),
                policyEffectiveDate: getPolicyEffectiveDayByPolicyId(
                  value,
                  cancellationConfig?.policyDates ?? []
                ),
                policyExpirationDate: getPolicyExpirationDayByPolicyId(
                  value,
                  cancellationConfig?.policyDates ?? []
                ),
              });
            }}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={4}
            id="id-policy-date"
            name="policy-date"
            labelFontType="BOLD_CAPTION"
            label="Policy Date"
            type="text"
            value={getPolicyDatesByPolicyNumber(
              cancellationData?.policyEffectiveDate,
              cancellationData?.policyExpirationDate
            )}
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            {...selectConfiguration(
              "id-cancel-type",
              "cancel-type",
              "Cancel Type",
              false
            )}
            readOnly={cancellationData?.isFormReadOnly}
            labelFontType="BOLD_CAPTION"
            options={cancellationConfig?.cancelTypeList ?? []}
            value={cancellationData?.cancelTypeId}
            errorMessage={errorMessages?.cancelTypeId}
            onChange={(value) => {
              setCancellationData?.({
                ...cancellationData,
                cancelTypeId: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            id="id-cancellation-date"
            name="cancellation-date"
            label="Cancellation Date"
            labelFontType="BOLD_CAPTION"
            readOnly={cancellationData?.isFormReadOnly}
            value={cancellationData?.cancellationDate}
            errorMessage={errorMessages?.cancellationDate}
            onChangeRawValue={(dateValue) =>
              setCancellationData?.({
                ...cancellationData,
                cancellationDate: dateValue,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            id="id-notification-date"
            name="notification-date"
            label="Notification Date"
            labelFontType="BOLD_CAPTION"
            readOnly={cancellationData?.isFormReadOnly}
            value={cancellationData?.notificationDate}
            errorMessage={errorMessages?.notificationDate}
            onChangeRawValue={(dateValue) =>
              setCancellationData?.({
                ...cancellationData,
                notificationDate: dateValue,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={4}
            id="id-cancellation-status"
            name="cancellation-status"
            labelFontType="BOLD_CAPTION"
            label="Status"
            type="text"
            readOnly
            value={getKeyFromEnumValue(
              CancellationStatusEnum,
              cancellationData?.cancellationStatusId ?? 0
            )}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Select
            {...selectConfiguration(
              "id-cancel-reason",
              "cancel-reason",
              "Cancel Reason",
              false
            )}
            firstOptionAsDefault={false}
            readOnly={cancellationData?.isFormReadOnly}
            labelFontType="BOLD_CAPTION"
            options={cancellationConfig?.cancelReasonList ?? []}
            value={cancellationData?.cancelReasonId}
            errorMessage={errorMessages?.cancelReasonId}
            onChange={(value) => {
              setCancellationData?.({
                ...cancellationData,
                cancelReasonId: value,
                cancelCode: getPropertyByCancelReasonId(
                  value,
                  cancellationConfig?.cancelReasons ?? [],
                  "cancelCode"
                ),
                cancelReasonDescription: getPropertyByCancelReasonId(
                  value,
                  cancellationConfig?.cancelReasons ?? [],
                  "description"
                ),
                shortRateApplies: getPropertyByCancelReasonId(
                  value,
                  cancellationConfig?.cancelReasons ?? [],
                  "shortRate"
                ),
              });
            }}
            optionsMaxHeight="200px"
          />
        </Col>
        {showFieldByReasonCancelCode(
          cancellationConfig?.cancelReasons ?? [],
          cancellationData?.cancelReasonId
        ) && (
          <>
            <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
              <Input
                tabIndex={4}
                id="id-invoice-number"
                name="invoice-number"
                label="Invoice Number"
                type="text"
                maxLength={30}
                readOnly={cancellationData?.isFormReadOnly}
                labelFontType="BOLD_CAPTION"
                value={cancellationData?.invoiceNumber}
                errorMessage={errorMessages?.invoiceNumber}
                onChangeRawValue={(value) => {
                  setCancellationData?.({
                    ...cancellationData,
                    invoiceNumber: value,
                  });
                }}
              />
            </Col>
            <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
              <Input
                tabIndex={4}
                id="id-balance-due"
                name="balance-due"
                label="Balance Duo"
                readOnly={cancellationData?.isFormReadOnly}
                value={cancellationData?.balanceDue}
                errorMessage={errorMessages?.balanceDue}
                maxNumericValue={99999999.99}
                labelFontType="BOLD_CAPTION"
                allowNegatives={false}
                type={"fixedCurrency"}
                onChangeRawValue={(value) => {
                  setCancellationData?.({
                    ...cancellationData,
                    balanceDue: value,
                  });
                }}
              />
            </Col>
          </>
        )}
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputMemo
            id="id-cancellation-comments"
            name="cancellation-comments"
            label="Comments"
            labelFontType="BOLD_CAPTION"
            readOnly={cancellationData?.isFormReadOnly}
            value={cancellationData?.comments}
            errorMessage={errorMessages?.comments}
            onChangeRawValue={(value) =>
              setCancellationData?.({
                ...cancellationData,
                comments: value,
              })
            }
            resize={true}
            rows={2}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          horizontalAlign="flex-start"
        >
          <Font
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_CAPTION"
            display="inline"
          >
            {"Print"}
          </Font>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-start">
          <Link
            linkFontType={"BOLD_CAPTION"}
            displayValue={"Notification Letter"}
            name="incidentCollapseTitle"
            onClick={() =>
              cancellationData?.cancellationStatusId !==
                CancellationStatusEnum.PENDING &&
              setPrintType(NOTIFICATION_TYPE)
            }
          />
        </Col>
        <Col
          breakpoints={{ md: 10, lg: 10, xl: 10 }}
          horizontalAlign="flex-start"
        >
          <Link
            linkFontType={"BOLD_CAPTION"}
            displayValue={"Cancellation Letter"}
            name="incidentCollapseTitle"
            onClick={() =>
              cancellationData?.cancellationStatusId !==
                CancellationStatusEnum.PENDING &&
              setPrintType(CANCELLATION_TYPE)
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign={"flex-start"}>
        <Col
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          horizontalAlign="flex-start"
        >
          <Font
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
          >
            {"Processing Information"}
          </Font>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign={"flex-start"}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            id="id-processed-on"
            name="processed-on"
            label="Cancel Processed On"
            readOnly={!cancellationData?.showProcessingInformation}
            labelFontType="BOLD_CAPTION"
            onChangeRawValue={(dateValue) =>
              setCancellationData?.({
                ...cancellationData,
                processedOn: dateValue,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            {...selectConfiguration(
              "id-by-processed",
              "by-processed",
              "By",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={[]}
            value={0}
            readOnly={!cancellationData?.showProcessingInformation}
            onChange={(value) =>
              setCancellationData?.({
                ...cancellationData,
                processedBy: value,
              })
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign={"flex-start"}>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            id="id-rescinded-on"
            name="rescinded-on"
            label={`${
              cancellationData?.rescindOrReinstateOnLabel ?? "Rescinded"
            } On`}
            labelFontType="BOLD_CAPTION"
            readOnly={!cancellationData?.showProcessingInformation}
            value={cancellationData?.rescindOrReinstateOn}
            onChangeRawValue={(dateValue) =>
              setCancellationData?.({
                ...cancellationData,
                rescindOrReinstateOn: dateValue,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={4}
            id="id-rescind-or-reinstate-by"
            name="rescind-or-reinstate-by"
            label={"By"}
            type="text"
            readOnly={!cancellationData?.showProcessingInformation}
            labelFontType="BOLD_CAPTION"
            value={cancellationData?.rescindOrReinstateBy}
            onChangeRawValue={(value) =>
              setCancellationData?.({
                ...cancellationData,
                rescindOrReinstateBy: value,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            tabIndex={4}
            id="id-rescind-reason"
            name="rescind-reason"
            label={`${
              cancellationData?.isRescindOrReinstate ?? "Rescinded"
            } Reason`}
            type="text"
            maxLength={200}
            readOnly={!cancellationData?.showReinstateOrRescindComponents}
            labelFontType="BOLD_CAPTION"
            value={cancellationData?.rescindOrReinstateByReason}
            onChangeRawValue={(value) =>
              setCancellationData?.({
                ...cancellationData,
                rescindOrReinstateByReason: value,
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
          {cancellationData?.showReinstateOrRescindComponents && (
            <Button
              onClick={() => {
                onReinstateOrRescindEvent();
              }}
              tabIndex={-1}
              sx={{ marginLeft: "20px", marginTop: "13px" }}
            >
              {cancellationData?.isRescindOrReinstate}
            </Button>
          )}
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign={"flex-end"}
        verticalMargin="20px"
      >
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} horizontalAlign="flex-start">
          {cancellationData?.showDeleteButton && (
            <Button
              variantStyle={"outlined"}
              onClick={() => {
                onDeleteEvent();
              }}
              tabIndex={-1}
            >
              {"Delete"}
            </Button>
          )}
        </Col>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} horizontalAlign="flex-end">
          {cancellationData?.showSplitSaveButton && (
            <SplitButton
              items={getSplitButtonOptions(onSaveCancellationEvent)}
              tabIndex={36}
              showLoading
              currentSelectionIndex={currentSelectionIndexSplitBtn}
            />
          )}
          <Button
            variantStyle={"outlined"}
            onClick={() => {
              onCancelEvent(true);
            }}
            tabIndex={-1}
            sx={{ marginLeft: "20px" }}
          >
            {"Cancel"}
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </>
  );
};

export default ModalAddCancellationContent;
