import { AddPaymentSelectsOptionsDto } from "../../../../../../dtos/add-payment-selects-options-dto";
import { BaseTable } from "../../../../../../dtos/base-table";
import { CategoryOrTypeOption } from "../../../../../../dtos/category-or-type-option";
import { ClaimFlagsDataDto } from "../../../../../../dtos/claim-flags-data-dto";
import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import { PayeeDto } from "../../../../../../dtos/payee-dto";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { PaymentStatusEnum } from "../../../../../../dtos/payment-status-enum";
import { PaymentTableDto } from "../../../../../../dtos/payment-table-dto";
import { ReserveItemDto } from "../../../../../../dtos/reserve-item-dto";
import { ReserveSummaryTableDto } from "../../../../../../dtos/reserve-summary-table-dto";
import { ReserveTableDto } from "../../../../../../dtos/reserve-table-dto";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { formatToCurrency } from "../../../../../../utilities/stringFunctions";
import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { DeletedPaymentProps, PaymentType } from "./typesAndConstants";

export type ClaimFinancialProps = {
  claimId?: number;
};

export type ClaimsTableSelectFiltersOptionsProps = {
  typeOptions: Partial<SelectOptions>[];
  categoryOrTypeOptions: Partial<CategoryOrTypeOption>[];
  statusOptions: Partial<CategoryOrTypeOption>[];
  renderClaimFinancial: boolean;
  reserveTable: Partial<ReserveTableDto>;
  paymentTable: Partial<PaymentTableDto>;
  reserveSummaryTable: Partial<ReserveSummaryTableDto>;
  paymentOptions: AddPaymentSelectsOptionsDto;
  claimFlagsData?: ClaimFlagsDataDto;
};
export const getPayeeData = (
  payeeData?: ClaimLandingClaimantDto | PayeeDto
) => {
  const { fullName, address, state, city, zip } = payeeData || {};
  const fullAddress = [state, city, zip].filter(Boolean).join(", ");

  const resultArray: string[] = [];

  if (fullName) resultArray.push(fullName);
  if (address) resultArray.push(` - ${address}`);
  if (fullAddress) resultArray.push(`(${fullAddress})`);

  return resultArray.join(" ");
};
export const optionAll: Partial<SelectOptions>[] = [
  {
    displayName: "All",
    stringValue: "all",
  },
];
type PaymentTableRow = {
  PaymentStatus?: string;
  PaymentCategory?: string;
};

export type UpdatedPaymentRowData = {
  paymentData?: PaymentDto | Partial<PaymentDto> | null;
  rowKey?: string;
  recurringPayments?: Partial<PaymentDto>[] | null;
};
export type FinancialTableProps = {
  claimId: number | undefined;
  typesTableOptions: Partial<SelectOptions>[] | SelectOptions[];
  categoriesOrTypesTableOptions: Partial<SelectOptions>[] | SelectOptions[];
  statusOptions: Partial<SelectOptions>[] | SelectOptions[];
  tableTypeOptionsChange: (value: any) => void;
  editModalLinkEvent?: (rowData?: any) => JSX.Element;
  newPaymentRowData?:
    | (PaymentDto & PaymentTableRow)[]
    | Partial<PaymentDto & PaymentTableRow>[]
    | null;
  updatedPaymentData?: UpdatedPaymentRowData;
  setNewPaymentRowData?: (
    payment:
      | (PaymentDto & PaymentTableRow)[]
      | Partial<PaymentDto & PaymentTableRow>[]
      | null
  ) => void;
  setUpdatedPaymentRowData?: (
    updatedPaymentData?: UpdatedPaymentRowData | null
  ) => void;
  deletedPaymentRowData?: DeletedPaymentProps | null;
  selectedRowData?: any;
  setRefreshSummaryTable?: (value: boolean) => void;
  tableData: BaseTable;
};

export type ModalClaimFinancialReserveProps = {
  reserveId: number;
  claimId?: number;
  reserveInformation?: ReserveItemDto;
  errorDetails?: any;
  setReserveInformation?: (reserveInformation?: ReserveItemDto) => void;
  setSaveDisabled?: (isDisabled: boolean) => void;
  userReserveLimit?: number;
  checkLimit?: boolean;
};
export const colConfiguration: ColProps = {
  breakpoints: { md: 4, lg: 4, xl: 4 },
  horizontalGutter: "5px",
  horizontalAlign: "flex-start",
};

export const getOptionsByTableType = (
  allOptions: Partial<CategoryOrTypeOption>[],
  financialType: number
) => {
  const selectOptionList = allOptions
    .filter((x) => x.type === financialType)
    .map((item) => {
      const option: SelectOptions = {
        displayName: item.displayName ?? "",
        intValue: null,
        stringValue: item.displayName ?? "",
        decimalValue: null,
        dateValue: null,
        booleanValue: null,
      };
      return option;
    });
  return optionAll.concat(selectOptionList);
};

export const checkInitialReadOnly = (
  defaultData?: PaymentDto | Partial<PaymentDto> | null,
  paymentType?: PaymentType
) => {
  if (
    [
      PaymentStatusEnum.PAID,
      PaymentStatusEnum.DENIED,
      PaymentStatusEnum.VOID,
    ].includes(defaultData?.paymentStatus ?? 0)
  ) {
    return true;
  }
  if (conditionHasValue(defaultData)) {
    if (
      defaultData?.paymentStatus === PaymentStatusEnum.PENDING ||
      defaultData?.paymentStatus === PaymentStatusEnum.PENDING_APPROVAL ||
      paymentType === "manual-payment"
    ) {
      {
        if (
          defaultData?.paymentId &&
          defaultData.paymentId !== 0 &&
          defaultData?.paymentStatus === PaymentStatusEnum.PENDING_APPROVAL &&
          defaultData?.paidAmount &&
          defaultData?.outstandingReserves &&
          defaultData?.paidAmount > defaultData?.outstandingReserves
        ) {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const parseReserveObj = (reserve) => {
  return {
    ReserveId: reserve.reserveId,
    ReserveDate: reserve.date,
    Amount:
      reserve.amount > 0
        ? formatToCurrency(Math.abs(reserve.amount))
        : `(${formatToCurrency(Math.abs(reserve.amount))})`,
    ReserveStatus: reserve.statusName,
    ReserveStatusId: reserve.statusId,
    ReserveType: reserve.typeName,
    Comments: reserve.comments,
    UpdateBy: reserve.updatedBy,
  };
};

export function getIntValueByStringNameFromClaimReference(
  key: string,
  referenceOptions: ClaimReferenceDto[]
) {
  const result =
    referenceOptions.length > 0
      ? referenceOptions.filter(
          (reference) => reference?.value1?.toLowerCase() === key?.toLowerCase()
        )
      : [];
  const value = result.length > 0 ? result[0].valueN ?? null : null;
  return value;
}

export type ModalEditReservesInfo = {
  readOnly: boolean;
  reallocateReservesDisabled: boolean;
  reallocateReservesError: boolean;
  showWorksheet: boolean;
  worksheetIsOpen: boolean;
};
