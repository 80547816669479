import ScheduledTransactionModalContent from "./ScheduledTransactionModalContent";
import { Modal } from "../../../../TrueUI";
import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost, usePermissions } from "../../../../../hooks";
import { ScheduledTransactionConfigurationDto } from "../../../../../dtos/scheduled-transaction-configuration-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { BillingScheduledTransactionDto } from "../../../../../dtos/billing-scheduled-transaction-dto";
import { BillingScheduledTransactionsStatusEnum } from "../../../../../dtos/billing-scheduled-transactions-status-enum";

export type ScheduledTransactionModalConfig = {
  isOpen: boolean;
  isOpenAchPaymentModal: boolean;
  isOpenDialogConfirmation: boolean;
  transactionId?: number | null;
  insuredId?: number | null;
  rowKey?: string | null;
  rowData?: any;
};

type ScheduledTransactionModalProps = {
  configuration: ScheduledTransactionModalConfig;
  closeModal: (close: boolean) => void;
  onSaveSuccessful?: () => void;
  onDeleteSuccessful?: () => void;
};

export const defaultData: BillingScheduledTransactionDto = {
  policyId: 0,
  scheduleStatus: 1,
  insuredAccountId: 0,
  accountNickname: "",
  createBy: "",
  invoiceId: 0,
  insuredName: "",
  scheduledDate: new Date(),
  scheduleId: 0,
  tranAccountId: 0,
  transactionAmount: 0,
};

const ScheduledTransactionModal: FC<ScheduledTransactionModalProps> = ({
  configuration: { isOpen, transactionId, insuredId },
  closeModal,
  onSaveSuccessful,
  onDeleteSuccessful,
}) => {
  const [configurationState, setConfigurationState] =
    useState<ScheduledTransactionConfigurationDto | null>(null);
  const [data, setData] = useState<BillingScheduledTransactionDto>(defaultData);
  const [errorDetails, setErrorDetails] = useState<any>();

  const hasEditPermissions = usePermissions([2, 9, 10, 11]);
  const readOnly =
    !hasEditPermissions ||
    data?.scheduleStatus !== BillingScheduledTransactionsStatusEnum.PENDING;

  const { responseGet, dispatchGet } =
    useApiGet<ScheduledTransactionConfigurationDto>(
      `api/BillingTransaction/GetScheduledTransactionsConfiguration?insuredId=${
        insuredId ?? 0
      }`
    );

  const { responsePost, validatorErrorResponse, dispatchPost } = useApiPost(
    `api/BillingTransaction/SaveScheduledTransaction`,
    data
  );

  useEffect(() => {
    setConfigurationState(null);
    if (conditionHasValue(insuredId)) {
      dispatchGet();
    }
  }, [insuredId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      if (
        data.scheduleStatus === BillingScheduledTransactionsStatusEnum.DELETED
      ) {
        onDeleteSuccessful?.();
      } else {
        onSaveSuccessful?.();
      }
      setData(defaultData);
      onCloseEvent(false);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  const onCloseEvent = (close: boolean) => {
    closeModal(close);
    setErrorDetails(null);
  };

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchPost();
  };

  const onDeleteEvent = () => {
    setData({
      ...data,
      scheduleStatus: BillingScheduledTransactionsStatusEnum.DELETED,
    });
    setErrorDetails(null);

    dispatchPost();
  };

  return (
    <div>
      <Modal
        id={"edit_scheduled_transaction"}
        title={"Scheduled Transaction"}
        open={isOpen}
        showCloseButton={true}
        deleteEvent={onDeleteEvent}
        showCancelTextButton={true}
        saveEvent={onSaveEvent}
        cancelEvent={() => onCloseEvent(false)}
        closeEvent={onCloseEvent}
        cancelButtonWithConfirmation
        deleteButtonWithConfirmation
        saveDisabled={readOnly}
        deleteDisabled={readOnly}
      >
        {configurationState !== null && (
          <ScheduledTransactionModalContent
            configuration={configurationState}
            transactionId={transactionId ?? 0}
            insuredId={insuredId ?? 0}
            formData={data}
            setFormData={(newData) => setData(newData)}
            errorDetails={errorDetails}
            readOnly={readOnly}
          />
        )}
      </Modal>
    </div>
  );
};

export default ScheduledTransactionModal;
