import { FC, useEffect, useRef, useState } from "react";

const initialScrollHeight = 9;
const rowHeight = 1.7;
const scrollHeightByRow = 23;

const InternalTextArea: FC<any> = (inputProps) => {
  const numberOfRows = inputProps?.rows ?? 1;
  const textAreaRef = useRef<any>(null);
  const [styleDynamicProps, setStyleDynamicProps] = useState({
    WebkitLineClamp: numberOfRows,
    height: `${rowHeight}em`,
  });

  const calculateTextAreaHeight = (scrollHeight: number) => {
    const rowsNumberByValue =
      (scrollHeight - initialScrollHeight) / scrollHeightByRow;
    const maxHeight = numberOfRows * rowHeight;
    const maxValueHeight = rowsNumberByValue * rowHeight;
    const newTextAreaHeight =
      rowsNumberByValue > numberOfRows ? maxHeight : maxValueHeight;
    setStyleDynamicProps({
      ...styleDynamicProps,
      height: `${newTextAreaHeight}em`,
    });
  };

  useEffect(() => {
    if (textAreaRef !== null && textAreaRef.current !== null) {
      calculateTextAreaHeight(textAreaRef.current.scrollHeight);
    }
  }, [inputProps?.value]);
  
  useEffect(() => {
    if (inputProps.autoFocus && textAreaRef?.current) {
      textAreaRef.current.focus();
    }
  }, [inputProps.autoFocus]);
  

  return (
    <textarea
      {...inputProps}
      ref={textAreaRef}
      style={{ ...inputProps?.style, ...styleDynamicProps }}
    />
  );
};

export default InternalTextArea;
