import { FC } from "react";
import style from "./ReallocateTableFooter.module.css";
import { Font } from "../../../../../../TrueUI";

type ReallocateTableFooterProps = {
  totalOutstandingReserves: number;
  totalReallocateReserves: number;
};

const ReallocateTableFooter: FC<ReallocateTableFooterProps> = ({
  totalOutstandingReserves,
  totalReallocateReserves,
}) => {
  const formatTotal = (total: number) =>
    total?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) ?? "0.00";

  return (
    <>
      <div className={style.table_footer}>
        <div className={style.footer_column_left}>
          <Font
            name="warning-label"
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
            textAlign="center"
          >
            {formatTotal(totalOutstandingReserves)}
          </Font>
        </div>
        <div className={style.footer_column_right}>
          <Font
            name="warning-label"
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
            textAlign="center"
            isErrorFont={totalReallocateReserves !== 0}
          >
            {formatTotal(totalReallocateReserves)}
          </Font>
        </div>
      </div>
      {totalReallocateReserves !== 0 && (
        <div className={style.footer_error_message}>
          <Font
            name="warning-label"
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
            textAlign="center"
            isErrorFont
          >
            {"The reallocated total must be $0.00"}
          </Font>
        </div>
      )}
    </>
  );
};

export default ReallocateTableFooter;
