import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import {
  currencyFormat,
  decimalStringToFloat,
} from "../../../../../utilities/currencyFunctions";
import { parseFloatValueIfExist } from "../../../../../utilities/stringFunctions";
import { Font } from "../../../../TrueUI";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../TrueUI/Tables/tableFunctions";

export const getPaymentIdListByTableData = (
  data: string[][],
  columns: BaseTableColumn[],
  userPaymentLimit: number
) => {
  const paymentIdIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "PaymentId"
  );
  const paymentAmountIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "PaidAmount"
  );

  const filteredPaymentsToApprove = data.filter(
    (row) =>
      (parseFloatValueIfExist(decimalStringToFloat(row[paymentAmountIndex])) ??
        0) <= userPaymentLimit
  );
  const paymentIdList = filteredPaymentsToApprove.map(
    (row) => row[paymentIdIndex]
  );
  return paymentIdList;
};

export const limitMessage = (limit: number) => (
  <div style={{ alignSelf: "center", padding: "0 5px" }}>
    <Font isErrorFont>{`User Payment Limit: ${currencyFormat(
      limit,
      true
    )}`}</Font>
  </div>
);
