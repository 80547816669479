import React, { FC } from "react";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import BaseGridDynamicCells from "./BaseGridDynamicCells";
import { useRecoilValue } from "recoil";
import {
  BaseGridStructureData,
  BaseGridStructureRow,
} from "../BaseGridStructure";
import { BaseGridAdvancedProperties, GridDataRow } from "../BaseGridProperties";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { Skeleton } from "@mui/material";
import StyleBox from "../../TrueUI/StyleBox/StyleBox";
import { useGridInstance } from "../Hooks/useGridInstance";
import BaseGridStaticCells from "./BaseGridStaticCells";
import {
  createCellKey,
  getWidthOfCells,
  styleBuilder,
} from "../SupportFunctions/baseGridFunctions";
import { useCellManagerInstance } from "../Hooks/useCellManagerInstance";
import { hydrateAndCastDataSingle } from "../SupportFunctions/OLD_baseGridFunctions";
import { getCellVariant } from "../SupportFunctions/baseGridCellFunctions";

// try again

export type BaseGridCellTypeProperties<T = any> = {
  uiid: string;
  rowKey: string;
  rowIndex?: number;
  index?: number;
  isEditable?: boolean;
  columnInputType?: BaseTableInputType | null;
  value?: any;
  key?: string;
  refreshCellManager?: any;
  typeId?: any;
  cellKey?: any;
  onChange?: (e?: any) => any;
  onBlur?: (e?: any) => any;
  column?: any;
  isEdit?: any;
  isComputeDisabled?: any;
  // type;
  variant?: any;
  errorMessage?: any;
  extraProps?: any;
  listDisplayOptions?: any;
  columnProps?: any;
  isBold?: any;
  row?: any;
  columns?: any;
  data?: any;
  advancedOptions: BaseGridAdvancedProperties<T> | null;
};

type BaseGridCellManagerCellSelectorProperties<T = any> = {
  uiid: string;
  row: GridDataRow;
  rowKey: string;
  rowIndex: number;
  columns: BaseTableColumn[];
  advancedOptions: BaseGridAdvancedProperties<T> | null;
  columnProps?: any;
  style?: React.CSSProperties;
  enableRowHighlighting?: boolean;
};

const BaseGridCellManagerCellSelector: FC<
  BaseGridCellManagerCellSelectorProperties
> = (managedCell) => {
  const {
    instanceSelector,
    instanceInternalSelector,
    // setInternalInstance - temp disable
  } = useGridInstance(managedCell.uiid, "BaseGridCellManagerCellSelector");

  const { instanceCellManager } = useCellManagerInstance(managedCell.uiid);
  const cellInstance = useRecoilValue(instanceCellManager(managedCell.rowKey));
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const rowId = `row_${managedCell.rowKey}`;
  const getColumn = (index) => managedCell.columns[index] ?? null;

  ///////// temp disable
  // const setIsRowSelected = () => {
  //   if (instance.styling?.rowStyles?.enableRowSelectHighlighting === true) {
  //     return instanceInternal.selectedRow === null ||
  //       instanceInternal.selectedRow !== managedCell?.rowKey
  //       ? managedCell?.rowKey
  //       : null;
  //   }
  //   return null;
  // };

  ///////// temp disable
  // const setIsCellSelected = (cellKey: string) => {
  //   if (instance.styling?.cellStyles?.enableCellSelectHighlighting === true) {
  //     return instanceInternal.selectedCell === null ||
  //       instanceInternal.selectedCell !== cellKey
  //       ? cellKey
  //       : null;
  //   }
  //   return null;
  // };

  const filteredColumns = () => {
    return instance.columns?.filter((c) => c.isHidden === false) ?? [];
  };

  return (
    <BaseGridStructureRow
      id={rowId}
      key={managedCell.rowKey}
      style={styleBuilder(
        { columns: instance?.columns ?? [], row: managedCell.row },
        null,
        instance.styling?.rowStyles?.progressFunction,
        (styleValues) => ({
          backgroundImage: `linear-gradient(to right, ${styleValues.color} ${
            styleValues?.percent
          }%, ${styleValues.color + 20} ${styleValues?.percent}%)`,
          backgroundColor: "#ecedee",
        })
      )}
      isHighlighted={managedCell?.enableRowHighlighting ?? false}
      isSelected={
        instanceInternal.selectedRow === managedCell.rowKey ? true : false
      }
      onClick={(e) => {
        instance?.events?.onRowClick?.({
          rowKey: managedCell.rowKey,
          hydratedData: hydrateAndCastDataSingle(
            instance?.columns ?? [],
            managedCell?.row ?? []
          ),
          syntheticEvent: e,
          isDeselected: false,
        });
        ///////// temp disable
        // if (
        //   instance.styling?.cellStyles?.enableCellSelectHighlighting !== true
        // ) {
        //   setInternalInstance({
        //     ...instanceInternal,
        //     selectedRow: setIsRowSelected(),
        //   });
        // }
      }}
    >
      {!cellInstance.readyToRender ? (
        <BaseGridStructureData key={crypto.randomUUID()}>
          <StyleBox margin={5} padding={5}>
            <Skeleton variant="rounded" width={"100%"} height={15} />
          </StyleBox>
        </BaseGridStructureData>
      ) : (
        cellInstance.row?.length > 0 &&
        cellInstance.row?.map((value, index) => {
          const column = getColumn(index) ?? null;

          if (column !== null && column?.isHidden) {
            return null;
          }

          const cellKey = createCellKey(
            managedCell.rowKey,
            column._columnIndex
          );
          const columnOptions =
            instance?.columnOptions?.find(
              (columnOption) => columnOption.fieldName === column.fieldName
            ) ?? [];
          const managedCellWitColumnProps = {
            ...managedCell,
            columnProps: columnOptions,
            cellKey: cellKey,
          };
          return (
            <BaseGridStructureData
              key={`${managedCell.rowIndex}_${
                managedCell.uiid
              }_${crypto.randomUUID()}`}
              width={getWidthOfCells(
                instance?.columnOptions ?? [],
                filteredColumns(),
                column.fieldName
              )}
              style={styleBuilder(
                { columns: instance?.columns ?? [], row: managedCell.row },
                column.fieldName,
                instance.styling?.cellStyles?.fullColorFunction,
                (styleValues) => ({
                  backgroundColor: styleValues?.color ?? "none",
                })
              )}
              isSelected={
                instanceInternal.selectedCell === cellKey ? true : false
              }
              ///////// temp disable
              // onClick={() => {
              //   const selectedCell = setIsCellSelected(cellKey);
              //   setInternalInstance({
              //     ...instanceInternal,
              //     selectedCell: selectedCell,
              //   });
              // }}
            >
              <>
                {cellInstance.isEditable || column.isEditable ? (
                  <BaseGridDynamicCells
                    {...managedCellWitColumnProps}
                    variant={getCellVariant(column?.cellVariant)}
                    column={column}
                    value={value}
                    advancedOptions={managedCell.advancedOptions ?? null}
                  />
                ) : (
                  <BaseGridStaticCells
                    {...managedCellWitColumnProps}
                    column={column}
                    value={value}
                    advancedOptions={managedCell?.advancedOptions ?? null}
                  />
                )}
              </>
            </BaseGridStructureData>
          );
        })
      )}
    </BaseGridStructureRow>
  );
};

export default BaseGridCellManagerCellSelector;
