import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AgencyTitleInformationDto } from "../../dtos/agency-title-information-dto";
import { Modal, ScoreChip } from "../TrueUI";
import { grey } from "@mui/material/colors";
import AgencyScorecard from "./Scorecard/AgencyScorecard";

type AgencyPanelProps = {
  agencyInformationTitle: AgencyTitleInformationDto | null;
};

const AgencyPanel: FC<AgencyPanelProps> = ({ agencyInformationTitle }) => {
  // const [agencyInformationTitle] = useState(agencyTitleInformation);

  const colorGrey500 = grey[500];
  const colorGrey600 = grey[600];

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <Box display={"flex"} justifyContent={"left"} alignItems={"center"}>
      {agencyInformationTitle?.showScorecard && (
        <ScoreChip
          value={agencyInformationTitle?.score ?? 0}
          color={"darkGreen"}
          size={"big"}
          clickable
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
      )}
      <Modal
        open={isModalOpen}
        id={"agencyScoreCardModal"}
        title={"Agency Scorecard"}
        size={"md"}
        closeEvent={(isOpen) => {
          setIsModalOpen(isOpen);
        }}
      >
        <AgencyScorecard agencyInfo={agencyInformationTitle} />
      </Modal>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 400,
          mb: 0,
          mr: 2,
          px: 0.5,
          borderRight: "1px solid",
          borderColor: colorGrey500,
          color: colorGrey500,
        }}
      >
        {agencyInformationTitle?.agencyCode}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        sx={{ fontWeight: 400, mb: 0, color: colorGrey600 }}
      >
        {agencyInformationTitle?.agencyName}
      </Typography>
    </Box>
  );
};

export default AgencyPanel;
