import { LossCostMultiplierDto } from "../../../../../dtos/loss-cost-multiplier-dto";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { PolicyStateBlob } from "../../../../../dtos/policy-state-blob";
import { SelectOptions } from "../../../../../dtos/select-options";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { isDateEqualDate } from "../../../../../utilities/dateFunctions";
import { customRound } from "../../../../../utilities/stringFunctions";
import { GlobalInsuredAtomProperties } from "../../../InsuredAtoms";
import { getPolicyQuoteStates } from "../../PolicyQuoteForm/PolicyQuoteUtils";

export const isDuplicatedClassCode = (
  alreadyExistingExposures: PolicyExposureBlob[],
  exposureJSON: PolicyExposureBlob
) => {
  return conditionHasValue(
    alreadyExistingExposures.find(
      (exposure) =>
        exposure.classCode === exposureJSON.classCode &&
        exposure.classSuffix === exposureJSON.classSuffix &&
        exposure.locationNumber === exposureJSON.locationNumber &&
        exposure.uniqueKey !== exposureJSON.uniqueKey
    )
  );
};

export const getClassCodeAndSuffix = (value: string) => {
  if ((value?.length ?? 0) > 4) {
    const classCode = value.slice(0, 4);
    const classSuffix = value.slice(4);

    return { classCode, classSuffix };
  } else {
    const classCode = value;
    const classSuffix = "";

    return { classCode, classSuffix };
  }
};

export const getPremiumValueCalculated = (
  rateBasis: string,
  exposureAmount: number,
  modRate: number
) => {
  switch (rateBasis.toLocaleLowerCase()) {
    case "payroll":
      return customRound(((exposureAmount / 100) * modRate).toString(), 0);
    case "per annum":
      return customRound((exposureAmount * modRate ?? 1).toString(), 0);

    default:
      return 0;
  }
};

export const getRateValidated = (rate: number) => {
  if (rate > 500) return 500;
  if (rate < 0) return 0;
  return rate;
};

export const getExposureValidated = (exposureAmount: number) => {
  if (exposureAmount > 1000000000) return 1000000000;
  if (exposureAmount < 0) return 0;
  return exposureAmount;
};

export const getMax999Min1ValueValidated = (value: number) => {
  if (value > 999) return 999;
  if (value < 1) return 1;
  return value;
};

export const getStatesUpdatedByExposures = (
  exposures: PolicyExposureBlob[],
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  lcmValue: number,
  lcmApplies: boolean,
  atomValue: GlobalInsuredAtomProperties | null
): PolicyStateBlob[] => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        lossCostMultiplier: lcmValue,
        lcmApplies,
        exposures: exposures,
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getLCMListAsSelectOptions = (lcmList: LossCostMultiplierDto[]) =>
  lcmList.map((lcm) => {
    return {
      displayName: `${lcm.displayValue} - ${lcm.elementRate}`,
      decimalValue: lcm.elementRate,
    };
  }) as Partial<SelectOptions>[];

export const areAllClassCodesValid = (exposures: PolicyExposureBlob[]) => {
  return (
    exposures.length > 0 &&
    exposures.every(
      (exposure) =>
        exposure.manualPremiumAtMod !== null &&
        exposure.manualPremiumAtMod !== undefined &&
        !exposure.hasErrors
    )
  );
};

export const getHazardGroupOfHighestManualPremiumItem = (
  exposureList: PolicyExposureBlob[]
): string => {
  if (exposureList.length > 0) {
    const maximum = exposureList.reduce((prevItem, currentItem) => {
      return (prevItem.manualPremiumAtMod ?? 0) >
        (currentItem.manualPremiumAtMod ?? 0)
        ? prevItem
        : currentItem;
    });
    return maximum.hazardGroup ?? "";
  }
  return "";
};
