import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../../utilities/apiFunctions";
import ModalAchPaymentContent from "./ModalAchPaymentContent";
import { AchModalProps, defaultAchPaymentData } from "./ModalAchPaymentUtil";
import { AchPaymentDto } from "../../../../../../dtos/ach-payment-dto";
import AccountAndBalanceSection from "../AccountAndBalanceSection";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { AchPaymentConfigurationDto } from "../../../../../../dtos/ach-payment-configuration-dto";
import FontError from "../../../../../TrueUI/Typography/FontError";

const ModalAchPayment: FC<AchModalProps> = ({
  insuredId,
  showModal,
  isOpenModal,
  onSaveSuccessful,
  accountTableData,
}) => {
  const [achPaymentConfiguration, setAchPaymentConfiguration] =
    useState<AchPaymentConfigurationDto | null>(null);
  const [achFormData, setAchFormData] = useState<AchPaymentDto | null>(
    defaultAchPaymentData(insuredId)
  );
  const [errorDetails, setErrorDetails] = useState<any>();
  const [refreshConfiguration, setRefreshConfiguration] = useState<string>("");
  const [billingTransactionData, setBillingTransactionData] = useState<
    null | BillingTransactionDataDto[]
  >();

  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<AchPaymentConfigurationDto>(
      `api/PaymentActivity/GetACHPaymentConfiguration?insuredId=${insuredId}`
    );

  useEffect(() => {
    if (showModal) {
      dispatchGetConfig();
    }
  }, [showModal]);

  useEffect(() => {
    setAchFormData({
      ...achFormData,
      billingTransactionDataList: billingTransactionData,
    });
  }, [billingTransactionData]);

  const {
    responsePost: responseSaveData,
    dispatchPost: dispatchSaveData,
    validatorErrorResponse,
  } = useApiPost<any>(`api/PaymentActivity/SaveAchPayment`, achFormData);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse.errorDetails);
    }
    if (isAPITotallyCompleteNoContentResponse(responseSaveData)) {
      const data = defaultAchPaymentData(
        insuredId,
        responseGetConfig?.responseData?.processorBankAccountId,
        responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue,
        achFormData
      );
      setAchFormData(data);
      onSaveSuccessful?.();
      isOpenModal(false);
    }
  }, [responseSaveData]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetConfig)) {
      setAchPaymentConfiguration(responseGetConfig?.responseData ?? null);
      const data = defaultAchPaymentData(
        insuredId,
        responseGetConfig?.responseData?.processorBankAccountId,
        responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue,
        achFormData
      );
      setAchFormData(data);
    }
  }, [responseGetConfig]);

  useEffect(() => {
    if (refreshConfiguration !== "") {
      dispatchGetConfig();
    }
  }, [refreshConfiguration]);

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchSaveData();
  };

  const onCancelEvent = () => {
    isOpenModal(false);
  };

  const onCloseEvent = () => {
    setErrorDetails(null);
    setAchFormData(
      defaultAchPaymentData(
        insuredId,
        responseGetConfig?.responseData?.processorBankAccountId,
        responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue
      )
    );
  };

  return (
    <Modal
      id={"ach-payment"}
      title={"Schedule Bank Draft (ACH)"}
      open={showModal}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCloseEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <ModalAchPaymentContent
        achFormData={achFormData}
        setAchFormData={setAchFormData}
        configuration={achPaymentConfiguration}
        errorMessages={errorDetails}
        insuredId={insuredId}
        setRefreshConfiguration={setRefreshConfiguration}
      />
      <AccountAndBalanceSection
        accountTableData={accountTableData}
        setBillingTransactionData={setBillingTransactionData}
        tranAccountOptions={
          responseGetConfig?.responseData?.tranAccountOptions ?? []
        }
        errorDetails={errorDetails}
      />
      {errorDetails?.billingTransactionDataList && (
        <FontError display="inline-block" maxWidth="840px" truncate>
          {errorDetails?.billingTransactionDataList}
        </FontError>
      )}
    </Modal>
  );
};

export default ModalAchPayment;
