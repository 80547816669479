import { FC } from "react";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTableDisplayOptions } from "../../../dtos/base-table-display-options";
import { SelectOptions } from "../../../dtos/select-options";
// import Select_NEW from "../../TrueUI/Selects_NEW/Select_NEW";
import { Select } from "../../TrueUI";
// import { Select } from "../..";
// import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
// import { SelectOptions } from "../../../../dtos/select-options";
// import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTableDropDownCellProperties
  extends BaseTableCellSelectProperties {
  dataOptions: BaseTableDisplayOptions[];
  selectMaxHeight?: string;
  // isDisabled?: boolean;
}

// TODO - complete dropdown
const SelectCell2: FC<BaseTableDropDownCellProperties> = ({
  rowKey,
  dataOptions,
  cellKey,
  column,
  value,
  // isEdit,
  onChange,
  variant,
  errorMessage,
  selectMaxHeight,
  // isDisabled = false,
}) => {
  const dropdownValues: Partial<SelectOptions>[] = dataOptions?.map(
    (options) => {
      return { displayName: options.text, stringValue: options.value };
    }
  );

  return (
    // <Select_NEW
    //   id={`${column.fieldName}-${cellKey}`}
    //   name={`name-${column.fieldName}-${cellKey}`}
    //   className="base_grid_cell_input"
    //   options={dropdownValues}
    //   onChange={(val) => {
    //     onChange?.({
    //       cellKey: cellKey,
    //       rowKey: rowKey,
    //       column: column,
    //       value: val,
    //     });
    //   }}
    // />
    <Select
      id={`${column.fieldName}-${cellKey}`}
      name={`name-${column.fieldName}-${cellKey}`}
      className="base_grid_cell_input"
      type={"tableCell"}
      key={`table_select_${cellKey}`}
      value={value ?? ""}
      readOnly={false}
      options={dropdownValues}
      onChange={(val) => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: val,
        });
      }}
      variant={variant}
      optionsMaxHeight={selectMaxHeight}
      errorMessage={errorMessage}
      firstOptionAsDefault={false}
    />
  );
};

export default SelectCell2;
