import { FC } from "react";
import { InputDate, Modal } from "../../../TrueUI";

type EndorsementModalAddNewInvoiceProps = {
  isOpen: boolean;
  insuredId: number;
  policyId?: number | null;
  newDueDate: Date | null;
  setNewDueDate: (value: Date) => void;
  getNewInvoiceNumber: () => void;
  setIsOpen: (value: boolean) => void;
};
const EndorsementModalAddNewInvoice: FC<EndorsementModalAddNewInvoiceProps> = ({
  isOpen,
  insuredId,
  policyId,
  newDueDate,
  setNewDueDate,
  getNewInvoiceNumber,
  setIsOpen,
}) => {
  return (
    <Modal
      id={`id-modal-new-invoice-${insuredId}-${policyId}`}
      title="ADD INVOICE"
      open={isOpen}
      saveEvent={() => getNewInvoiceNumber()}
      closeEvent={() => setIsOpen(false)}
      cancelEvent={() => setIsOpen(false)}
      showCloseButton
      sxInternalComponentsStyles={{ width: "350px" }}
    >
      <InputDate
        id={`id-new-due-date-${insuredId}-${policyId}`}
        name={`name-new-due-date-${insuredId}-${policyId}`}
        label="Due Date"
        value={newDueDate}
        onChangeRawValue={(value) => setNewDueDate(value)}
      />
    </Modal>
  );
};

export default EndorsementModalAddNewInvoice;
