import { FC } from "react";
import { TabPanel, Tabs } from "../TrueUI";
import AgencyContactForm from "./AgencyContactForm";
import { AgencyContactModalConfiguration } from "./AgencyContacts";
import AgencyContactLicensesForm from "./AgencyContactLicenses";

type AgencyContactModalContentProps = {
  agencyId?: string;
  contactModalConfiguration: AgencyContactModalConfiguration;
  setContactModalConfiguration?: (
    value: AgencyContactModalConfiguration
  ) => void;
};

const AgencyContactModalContent: FC<AgencyContactModalContentProps> = ({
  agencyId,
  contactModalConfiguration,
  setContactModalConfiguration,
}) => {
  return (
    <Tabs name={"contactFormTabs"} themeOverride={"material"}>
      <TabPanel title={"Information"}>
        <AgencyContactForm
          agencyId={agencyId}
          contactModalConfiguration={contactModalConfiguration}
          setContactModalConfiguration={setContactModalConfiguration}
        />
      </TabPanel>
      <TabPanel
        title={"Licenses"}
        disabled={contactModalConfiguration.agencyContactId === 0}
      >
        <AgencyContactLicensesForm
          contactModalConfiguration={contactModalConfiguration}
          setContactModalConfiguration={setContactModalConfiguration}
        />
      </TabPanel>
    </Tabs>
  );
};

export default AgencyContactModalContent;
