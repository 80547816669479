import { useState } from "react";
import { InputDate } from "../../TrueUI";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { BaseGridInputDateFilterProps } from "../../BaseGrid/BaseGridCommonFilters/BaseGridInputDateFilter";

interface CustomInputDateFilterProps extends BaseGridInputDateFilterProps {
  isDisabled?: boolean;
}

const CustomInputDateFilter: (properties: CustomInputDateFilterProps) => any = (
  props
) => {
  const [_value, _setValue] = useState<Date>(props.defaultValue ?? new Date());

  const setDateValueFilter = (value: Date) => {
    const dateValue = FormattingDate(value);
    _setValue(value);
    props?.onChange?.(dateValue);
  };

  return (
    <InputDate
      id={`true-filter-input-date-${props.id}`}
      name={`filter-input-date-${props.id}`}
      value={_value}
      label={props.label}
      labelPosition={"start"}
      labelFontType={"TITLE"}
      readOnly={props.isDisabled}
      onChangeRawValue={setDateValueFilter}
    />
  );
};

export default CustomInputDateFilter;
