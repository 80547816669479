import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { TableInstanceType2 } from "../BaseTable2/TableProperties";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import {
  DELETED_COLUMN_FIELD_NAME,
  ROW_KEY_COLUMN_FIELD_NAME,
} from "../TableConstants";
import {
  getDataIndexByColumnName,
  hydratePreSetValuesForAdd,
} from "../tableFunctions";
import "./plusMinusStyles.module.css";

type PlusMinusActionProps = {
  uiid: string;
  rowKey: string;
  hydratedRow: any;
};

const isFirstRow = (
  data: string[][],
  rowKeyIndex: number,
  deletedIndex: number,
  rowKey: string
) => {
  const firstNonDeletedRow = data.find((row) => row[deletedIndex] === "false");

  const firstNonDeletedRowKey =
    firstNonDeletedRow !== undefined && firstNonDeletedRow.length > rowKeyIndex
      ? firstNonDeletedRow[rowKeyIndex]
      : "";

  return firstNonDeletedRowKey === rowKey;
};

const isSingleRow = (data: string[][], deletedIndex: number) => {
  const nonDeletedRows = data.filter((row) => row[deletedIndex] === "false");

  return nonDeletedRows.length > 1;
};

const PlusMinusAction: FC<PlusMinusActionProps> = ({
  uiid,
  rowKey,
  hydratedRow,
}) => {
  const [showPlus, setShowPlus] = useState<boolean>(false);
  const [showMinus, setShowMinus] = useState<boolean>(true);

  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );
  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  useEffect(() => {
    const rowKeyIndex = getDataIndexByColumnName(
      tableInstance.columns,
      ROW_KEY_COLUMN_FIELD_NAME
    );
    const deletedIndex = getDataIndexByColumnName(
      tableInstance.columns,
      DELETED_COLUMN_FIELD_NAME
    );

    const isPlusConditionallyRendered =
      tableInstance.advancedOptions?.optionsColumnConfiguration?.conditionallyRenderPlusAction?.(
        hydratedRow
      ) ?? true;

    setShowPlus(
      isFirstRow(tableInstance.data, rowKeyIndex, deletedIndex, rowKey) &&
        isPlusConditionallyRendered
    );

    const isMinusConditionallyRendered =
      tableInstance.advancedOptions?.optionsColumnConfiguration?.conditionallyRenderMinusAction?.(
        hydratedRow
      ) ?? true;
    setShowMinus(
      isSingleRow(tableInstance.data, deletedIndex) &&
        isMinusConditionallyRendered
    );
  }, [tableInstance.data]);

  const addRow = () => {
    if (tableInstance?.methods?.addEventOverrideCallback) {
      tableInstance?.methods?.addEventOverrideCallback?.(null);
      setTableInstance({
        ...tableInstance,
        isAdd: false,
      });
    } else {
      const preSetHydratedRow = hydratePreSetValuesForAdd(
        tableInstance.advancedOptions?.optionsColumnConfiguration
          ?.optionalPropertyValueData
      );

      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        _accessors: {
          ...tableInstance._accessors,
          _addRow: {
            hydratedData: preSetHydratedRow,
            isEditableAfterAdd: true,
          },
        },
      });
    }
  };

  const deleteRow = () => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance._accessors,
        _deleteRow: {
          rowKey: rowKey,
          hydratedData: hydratedRow,
          deleteType: "hidden",
        },
      },
    });
  };

  return (
    <Box
      id={`id-plus-minus-cell-${rowKey}`}
      className="name-plus-minus-cell-container"
    >
      {showPlus && (
        <IconButton
          className="plus-minus-icon-container"
          onClick={addRow}
          true-element={"option-plus-icon"}
        >
          <AddOutlined className="plus-action-button" />
        </IconButton>
      )}
      {showMinus && (
        <IconButton
          className="plus-minus-icon-container"
          onClick={deleteRow}
          true-element={"option-minus-icon"}
        >
          <RemoveOutlined className="minus-action-button" />
        </IconButton>
      )}
    </Box>
  );
};

export default PlusMinusAction;
