import { FC } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Avatar } from "@mui/material";
import Font from "../Typography/Font";
import FontBold from "../Typography/FontBold";
import "./dotStyles.module.css";
import "./stepperAvatar.module.css";
import "./stepper.module.css";

type ProgressStepperProps = {
  dots: string[];
  active: number;
  lastSavedSection: number;
  allDotsAvailable: boolean;
  onClickDot: (clickedDot: number) => void;
};

const isActive = (idx: number, active: number) => idx === active;

const isPreviousDot = (idx: number, finishedSection: number) =>
  idx <= finishedSection;

// const isAfterTheFinishedDot = (idx: number, finishedSection: number) =>
//   idx >= finishedSection + 1;

const isNextDotAfterTheFinishedDot = (idx: number, finishedSection: number) => {
  return idx === finishedSection + 1;
};

const onDotClick = (
  idx: number,
  lastSavedSection: number,
  allDotsAvailable: boolean,
  onClickDot: any
) => {
  if (allDotsAvailable) {
    return onClickDot(idx);
  }
  return isPreviousDot(idx, lastSavedSection) ||
    isNextDotAfterTheFinishedDot(idx, lastSavedSection)
    ? onClickDot(idx)
    : false;
};

const MUI_IS_A_VERY_BAD_LIBRARY = {} as any; // In order to suppress a poor warning, this is required. Needs more review.

const ProgressStepper: FC<ProgressStepperProps> = ({
  dots,
  active,
  lastSavedSection,
  allDotsAvailable,
  onClickDot,
}) => {
  return (
    <Timeline
      {...MUI_IS_A_VERY_BAD_LIBRARY}
      className={`true_stepper_container`}
      placeholder={""}
    >
      {dots.map((dot, idx: number) => (
        <TimelineItem key={`${dot} ${idx}`}>
          <TimelineSeparator>
            <TimelineDot
              onClick={() =>
                onDotClick(idx, lastSavedSection, allDotsAvailable, onClickDot)
              }
              variant="outlined"
              className={`${
                isPreviousDot(idx, active) ? "true_prev_dot" : "true_dot"
              } ${isActive(idx, active) ? "dot_active" : ""} ${
                !onClickDot ? "prev_dot_active" : ""
              }`}
            >
              <Avatar
                className={`true_stepper_avatar ${
                  isActive(idx, active) || isPreviousDot(idx, lastSavedSection)
                    ? "avatar_active"
                    : ""
                }`}
              >
                {idx + 1}
              </Avatar>
            </TimelineDot>
            {dots.length > 1 && idx < dots.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            {isActive(idx, active) ? (
              <FontBold>{dot}</FontBold>
            ) : (
              <Font>{dot}</Font>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ProgressStepper;
