import { Heading4, Select } from "../TrueUI";
import { SelectOptions } from "../../dtos/select-options";
import { FC, useEffect, useState } from "react";
import AgencyPanel from "../Agency/AgencyPanel";
import { useRecoilValue } from "recoil";
import { SelectedAgencyCommissionAtom } from "./Commissions/CommissionsAtoms";
import { useApiGet } from "../../hooks";
import { AgencyTitleInformationDto } from "../../dtos/agency-title-information-dto";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";

type BillingHeaderProps = {
  programOptions: Partial<SelectOptions>[];
  selectedProgram: number | null;
  setSelectedProgram: (program: number) => void;
};

const BillingHeader: FC<BillingHeaderProps> = ({
  selectedProgram,
  setSelectedProgram,
  programOptions,
}) => {
  const [agencyInformation, setAgencyInformation] =
    useState<AgencyTitleInformationDto | null>(null);

  const transaction = useRecoilValue(SelectedAgencyCommissionAtom);

  const { responseGet, dispatchGet } = useApiGet<AgencyTitleInformationDto>(
    `api/agency/GetAgencyTitleInformation?agencyId=${transaction?.agencyId}`
  );
  
  useEffect(() => {
    if (transaction !== null) {
      dispatchGet();
    } else {
      setAgencyInformation(null);
    }
  }, [transaction?.tranId]);
  
  useEffect(() => {
    if(isAPITotallyComplete(responseGet)) {
      setAgencyInformation(responseGet.responseData ?? null);
    }
  }, [responseGet]);
  
  return (
    <div>
      {agencyInformation !== null ? (
        <AgencyPanel agencyInformationTitle={agencyInformation} />
      ) : (
        <>
          <Heading4 textAlign="start">
            Billing
          </Heading4>
          <div className="select-program-container">
            <Select
              id="billing-main-program"
              name="program"
              readOnly // TODO: Select is read only for now, until we implement the multi program
              label="Program"
              labelPosition="start"
              labelFontType="BODY"
              inputWidth="300px"
              optionsMaxHeight="400px"
              variant="standard"
              options={programOptions}
              value={selectedProgram}
              firstOptionAsDefault={false}
              onChange={(value) => setSelectedProgram(value)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BillingHeader;
