import { SelectOptions } from "../../../../../../dtos/select-options";
import {
  conditionHasValue,
  isValidEmail,
} from "../../../../../../utilities/conditionalSupportFunctions";
import {
  AbbrMonthsProps,
  getCardProviderMaxLength,
} from "../../../../../../utilities/staticDataFunctions";
import { CreditCardInfo } from "./ModalCreditCardPaymentTypes";

export const getAbbrMonthsSelectOptions = (monthsList: AbbrMonthsProps[]) => {
  return monthsList?.map((month) => ({
    displayName: month.abbreviation,
    intValue: month.value,
  }));
};

const getUpdatedCvv = (cvvMaxLength: number, currentCvv?: number) =>
  (currentCvv?.toString()?.length ?? 0) > cvvMaxLength
    ? parseInt(currentCvv?.toString()?.substring(0, cvvMaxLength) ?? "")
    : currentCvv;

export const evaluateCardNumber = (
  cardNumber: number,
  setCreditCardInfo: (credirCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const cardNumberMaxLength = getCardProviderMaxLength(cardNumber)?.pop();
  const cvvMaxLength = cardNumberMaxLength === 15 ? 4 : 3;
  const currentCvv = creditCardInfo?.cardCvv;
  const updatedCurrentCvv = getUpdatedCvv(cvvMaxLength, currentCvv);
  setCreditCardInfo({
    ...creditCardInfo,
    cardNumber: cardNumber,
    cardNumberMaxLength: conditionHasValue(cardNumber)
      ? cardNumberMaxLength
      : 16,
    cardCvvMaxLength: cvvMaxLength,
    cardCvv: updatedCurrentCvv,
  });
};
export const evaluateMonthToSetDefaultYear = (
  setCreditCardInfo: (credirCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const currentYear = new Date().getUTCFullYear();
  const nextYear =
    creditCardInfo?.cardMonth !== 1 ? currentYear + 1 : currentYear;

  setCreditCardInfo({
    ...creditCardInfo,
    cardYear: nextYear,
  });
};

function loopWithoutLet(counter, numberOfFutureYears, futureYears) {
  if (counter < numberOfFutureYears) {
    futureYears.push({
      displayName: `${new Date().getFullYear() + counter}`,
      intValue: new Date().getFullYear() + counter,
    });
    loopWithoutLet(counter + 1, numberOfFutureYears, futureYears);
  }
  return futureYears;
}

export const getCardYearsSelectOptions = (numberOfFutureYears: number) => {
  const futureYears: Partial<SelectOptions>[] = [];
  return loopWithoutLet(0, numberOfFutureYears, futureYears);
};

export const evaluateEmailReceipt = (
  email: string,
  setCreditCardInfo: (creditCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const errorMessage: { value: string | null } = { value: null };
  if (isValidEmail(email) === false) {
    errorMessage.value = "Email with wrong format.";
  }
  setCreditCardInfo({
    ...creditCardInfo,
    emailReceipt: email ?? "",
    emailFormatError: errorMessage.value,
  });
};
