import { Box } from "@mui/material";
import { FC } from "react";

type TagProps = {
  label: string;
  backgroundColor?: string;
  textColor?: string;
  size?: "small" | "medium" | "big";
  margin?: number;
  name?: string;
};

const Tag: FC<TagProps> = ({
  label,
  backgroundColor = "#37b400",
  textColor = "#fff !important",
  size = "small",
  margin = 0,
  name = "",
}) => {
  const getFontSize = (size) => {
    switch (size) {
      case "small":
      default:
        return "12px";
      case "medium":
        return "14px";
      case "big":
        return "16px";
    }
  };
  return (
    <Box
      true-element={`true-element-tag-${name}`}
      sx={{
        backgroundColor: `${backgroundColor} !important`,
        color: textColor,
        fontSize: getFontSize(size),
        padding: "1px 4px 0 4px",
        borderRadius: "20px",
        margin: `${margin}px`,
        WebkitTextFillColor: textColor,
      }}
    >
      {label}
    </Box>
  );
};

export default Tag;
