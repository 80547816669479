import { FC } from "react";
import AgencyCommissionsGrid from "./AgencyCommissionsGrid";
import CommissionDetailsGrid from "./CommissionDetailsGrid";
import { useRecoilValue } from "recoil";
import { SelectedAgencyCommissionAtom } from "./CommissionsAtoms";

const CommissionsTabContentWrapper: FC = () => {
  const transaction = useRecoilValue(SelectedAgencyCommissionAtom);

  return (
    <div style={{height: "100%"}}>
      {transaction !== null ? (
        <CommissionDetailsGrid tranId={transaction.tranId} />
      ) : (
        <AgencyCommissionsGrid />
      )}
    </div>
  );
};

export default CommissionsTabContentWrapper;
