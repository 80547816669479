import "./header.module.css";
import { NavLink } from "react-router-dom";
import { FC } from "react";

type HeaderProps = {
  size?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "main_title"
    | "extra_large"
    | "large"
    | "medium"
    | "small"
    | "subtitle1";
  children: JSX.Element | string;
  href?: string;
  alignment?: "center" | "right" | "left";
  marginTop?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  marginLeft?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  marginBottom?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  marginRight?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  display?: "inline" | "inline-block" | "block";
  className?: string;
  border?: boolean;
};

const Header: FC<HeaderProps> = ({
  size = "medium",
  children = "Header Title",
  href,
  alignment = "left",
  marginTop = "0px",
  marginLeft = "0px",
  marginBottom = "25px",
  marginRight = "0px",
  display = "block",
  className,
  border = false,
}) => {
  const getTag = (tag_size) => {
    switch (tag_size) {
      case "h1":
      case "main_title":
        return "h1";

      case "h2":
      case "extra_large":
        return "h2";

      case "h3":
      case "large":
        return "h3";

      case "h4":
      case "medium":
        return "h4";

      case "h5":
      case "small":
        return "h5";

      case "h6":
      case "subtitle1":
        return "h6";

      default:
        return "h4";
    }
  };
  const HeaderTag = getTag(size);

  return (
    <HeaderTag
      className={`true_header_container ${className} ${
        border ? "header_border" : ""
      }`}
      style={{
        textAlign: alignment,
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        display: display,
        width: display === "block" ? "100%" : "auto",
      }}
    >
      {href !== undefined && href !== null && href !== "" ? (
        <NavLink to={href}>{children}</NavLink>
      ) : (
        children
      )}
    </HeaderTag>
  );
};

export default Header;
