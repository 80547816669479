import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { BaseTable2 } from "../TrueUI";
import {
  OptionsContextActionParameterProperties,
  BaseTable2Properties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSwitchStatusFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableSwitchStatusFilter";
import { AgencyLocationToFilterContactsAtom } from "./AgencyAtoms";
import { Modal } from "../TrueUI";
import { AgencyContactDto } from "../../dtos/agency-contact-dto";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useBaseTable } from "../../hooks/useBaseTable";
import AgencyContactModalContent from "./AgencyContactModalContent";

const tableName = "contacts_table";

type ContactsProps = {
  agencyId?: string;
  isNewAgency: boolean;
};

export type AgencyContactModalConfiguration = {
  isOpen: boolean;
  agencyContactId: number;
  refreshTable: boolean;
};

const contactDefaultValues: AgencyContactModalConfiguration = {
  isOpen: false,
  agencyContactId: 0,
  refreshTable: false,
};

const Contacts: FC<ContactsProps> = ({ agencyId, isNewAgency }) => {
  const agencyLocationToFilterContactsAtomValue = useRecoilValue<string | null>(
    AgencyLocationToFilterContactsAtom
  );
  const [contactModalConfiguration, setContactModalConfiguration] =
    useState<AgencyContactModalConfiguration>(contactDefaultValues);

  const AgencyContactEditModalLink = (
    actionOptions: OptionsContextActionParameterProperties<AgencyContactDto>
  ) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setContactModalConfiguration({
            isOpen: true,
            agencyContactId: Number(actionOptions?.row?.AgencyContactId),
            refreshTable: false,
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const validateGetURL = () =>
    (agencyId ?? "") !== "new" &&
    (agencyId ?? "") !== "" &&
    agencyLocationToFilterContactsAtomValue !== null
      ? `api/AgencyContact/GetAgencyContactsPageByAgencyIdAndAgencyLocationId?agencyId=${agencyId}&agencyLocationId=${agencyLocationToFilterContactsAtomValue}`
      : `api/AgencyContact/GetAgencyContactsPageByAgencyIdAndAgencyLocationId?agencyId=${agencyId}`;

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: validateGetURL(),
    filterOptions: [
      (actionOptions) =>
        BaseTableSwitchStatusFilter({
          filterOptions: actionOptions,
          columnsNames: ["ContactStatus"],
          checkedLabelText: "Show All?",
          labelFontType: "BODY",
          labelPosition: "start",
        }),
    ],
    toolbarOptions: {
      toolBarType: "compact",
      compactTitle: "Agency Contacts",
      addButtonIsDisabled: isNewAgency,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          AgencyContactEditModalLink(actionOptions),
      },
    },
    events: {
      addEventOverrideCallback: () => {
        setContactModalConfiguration({
          isOpen: true,
          agencyContactId: 0,
          refreshTable: false,
        });
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const getTableMethods = tableMethods[tableName];

  useEffect(() => {
    if (contactModalConfiguration.refreshTable) {
      getTableMethods.methods.refreshTable();
      setContactModalConfiguration({
        ...contactModalConfiguration,
        refreshTable: false,
      });
    }
  }, [contactModalConfiguration.refreshTable]);

  return (
    <>
      <BaseTable2 name={tableName} />
      <Modal
        id="agencyLocationModal"
        title="Agency Contact"
        open={contactModalConfiguration.isOpen}
        cancelButtonWithConfirmation
        closeEvent={() =>
          setContactModalConfiguration({
            ...contactModalConfiguration,
            isOpen: false,
            agencyContactId: 0,
          })
        }
      >
        <AgencyContactModalContent
          agencyId={agencyId}
          contactModalConfiguration={contactModalConfiguration}
          setContactModalConfiguration={setContactModalConfiguration}
        />
      </Modal>
    </>
  );
};

export default Contacts;
