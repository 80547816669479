import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BatchMergeFieldsAndTemplateNameDto } from "../../../../dtos/batch-merge-fields-and-template-name-dto";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import { useApiGet, useFormRequest } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getQueryStringsToPaymentBatch } from "../../../../utilities/queryStringsHashFunctions";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";

type PrintBatchPaymentProps = {
  paymentIds?: string | null;
  batchId: number;
  dispatchPrint?: boolean;
  setDispatchPrint?: (dispatchPrint: boolean) => void;
};

const PrintBatchPayment: FC<PrintBatchPaymentProps> = ({
  paymentIds,
  batchId,
  dispatchPrint,
  setDispatchPrint,
}) => {
  const navigate = useNavigate();
  const { sendMergeFormRequest } = useFormRequest();

  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const {
    responseGet: responseGetBatchToPrint,
    dispatchGet: dispatchGetBatchToPrint,
  } = useApiGet<BatchMergeFieldsAndTemplateNameDto>(
    `api/PaymentBatch/GetPaymentBatchPaymentToPrint?paymentBatchId=${batchId}&groupOfPaymentsIds=${paymentIds}`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGetBatchToPrint)) {
      printBatch(responseGetBatchToPrint.axiosResponse?.data);
    }
  }, [responseGetBatchToPrint]);

  useEffect(() => {
    if (dispatchPrint) {
      dispatchGetBatchToPrint();
      setDispatchPrint?.(false);
    }
  }, [dispatchPrint]);

  const printBatch = (
    templateNameAndBatchJSON?: BatchMergeFieldsAndTemplateNameDto
  ) => {
    if (
      templateNameAndBatchJSON !== undefined &&
      templateNameAndBatchJSON !== null &&
      !isEmptyValue(templateNameAndBatchJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.BATCH_FORM,
        jsonObjectWithMergeFields: templateNameAndBatchJSON.batchJSON,
        templateNames: [templateNameAndBatchJSON.templateName],
        customFileName: `Check Batch ${batchId}`,
        printOrDownload: "download",
      });
      navigate(`/claims${getQueryStringsToPaymentBatch(batchId)}`);
    }
    if (isEmptyValue(templateNameAndBatchJSON?.templateName)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
        onOptionYesEvent: () => {
          setDialogConfiguration(null);
          navigate(`/claims${getQueryStringsToPaymentBatch(batchId)}`);
        },
        optionYesOverrideLabel: "OK",
      });
    }
  };

  return (
    <>
      <DialogConfirmation
        id="dialog-for-modal-add-batch"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </>
  );
};
export default PrintBatchPayment;
