import { BaseTableColumn } from "../../dtos/base-table-column";
import { BaseTableInputType } from "../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../dtos/data-type-enums";
import { InternalColumnTypes } from "./BaseGridColumnFactory";
// import {
//   // DEFAULT_ROW_INDEX,
//   DELETED_COLUMN_FIELD_NAME,
//   // INDICATION_COLUMN_FIELD_NAME,
//   IS_NEW_ROW_COLUMN_FIELD_NAME,
//   MULTI_SELECTION_COLUMN_FIELD_NAME,
//   // OPTION_COLUMN_FIELD_NAME,
//   ROW_KEY_COLUMN_FIELD_NAME,
// } from "./BaseGridConstants";

export const BaseGridInternalColumnDefaultConfiguration = {
  _rowKeyColumn: {
    // internal
    _columnIndex: 0,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: InternalColumnTypes.ROW_KEY,
    fieldName: InternalColumnTypes.ROW_KEY,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: null, // investigate creating a default key using crypto.randomUIID()
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  // _defaultRowIndexColumn: {
  //   // internal
  //   _columnIndex: 1,
  //   _visibleColumnIndex: null,
  //   _headerSortOrder: null,
  //   _isInternal: true,

  //   // external
  //   displayName: DEFAULT_ROW_INDEX,
  //   fieldName: DEFAULT_ROW_INDEX,
  //   cellVariant: CellVariantEnums.STANDARD,
  //   type: BaseTableInputType.READONLY,
  //   displayOptions: [],
  //   defaultValue: null, // investigate creating a default key using crypto.randomUIID()
  //   defaultValueType: DataTypeEnums.STRING,
  //   isHidden: true,
  //   alternateDisplayValues: [],
  //   isFilterable: false,
  //   isSortable: false,
  // } as BaseTableColumn,
  _multiSelectionColumn: {
    // internal
    _columnIndex: 2,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,
    // external
    displayName: " ",
    fieldName: InternalColumnTypes.MULTI_ROW_SELECT,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.MULTI_SELECTION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _indicationColumn: {
    // internal
    _columnIndex: 3,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName: InternalColumnTypes.INDICATION,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.INDICATION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _optionsColumn: {
    // internal
    _columnIndex: 4,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName: InternalColumnTypes.OPTIONS,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.OPTIONS,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowDeletedColumn: {
    // internal
    _columnIndex: 5,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: InternalColumnTypes.IS_DELETED,
    fieldName: InternalColumnTypes.IS_DELETED,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowNewColumn: {
    // internal
    _columnIndex: 6,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: InternalColumnTypes.IS_NEW,
    fieldName: InternalColumnTypes.IS_NEW,
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
};
