import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../../../hooks";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { ClaimFinancialDto } from "../../../../../../dtos/claim-financial-dto";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { CategoryOrTypeOption } from "../../../../../../dtos/category-or-type-option";
import { ReserveTableDto } from "../../../../../../dtos/reserve-table-dto";
import { PaymentTableDto } from "../../../../../../dtos/payment-table-dto";
import { ReserveSummaryTableDto } from "../../../../../../dtos/reserve-summary-table-dto";
import { AddPaymentSelectsOptionsDto } from "../../../../../../dtos/add-payment-selects-options-dto";
import ReservesGrid from "./ReservesGrid";
import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  ClaimFinancialDataAtom,
  ClaimFinancialGetAPIRequesterAtom,
  ClaimFlagsDataAtom,
  ReloadPaymentsAndReservesGridAtom,
  ReloadReservesSubGridAtom,
  // RequestClaimFinancialTablesAPIAtom,
} from "./ClaimFinancialAtoms";
import PaymentsAndReservesGrid from "./PaymentsAndReservesGrid";

export type ClaimsTableSelectFiltersOptionsProps = {
  typeOptions: Partial<SelectOptions>[];
  categoryOrTypeOptions: Partial<CategoryOrTypeOption>[];
  statusOptions: Partial<CategoryOrTypeOption>[];
  renderClaimFinancial: boolean;
  reserveTable: ReserveTableDto | null;
  paymentTable: PaymentTableDto | null;
  reserveSummaryTable: ReserveSummaryTableDto | null;
  paymentOptions: AddPaymentSelectsOptionsDto | null;
};

export type FinancialProps = {
  tabKey: string;
  insuredId?: number;
  claimId?: number;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  hasClaimsManagementPermissions: boolean;
};

const ClaimFinancial: FC<FinancialProps> = ({
  claimId,
  claimLandingClaimant,
  tabKey,
  hasClaimsManagementPermissions,
}) => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const setReloadPaymentsAndReservesGrid = useSetRecoilState(
    ReloadPaymentsAndReservesGridAtom
  );
  const [_reloadReservesSubGrid, setReloadReservesSubGrid] = useRecoilState(
    ReloadReservesSubGridAtom
  );

  const [getApiRequester, setGetApiRequester] = useRecoilState(
    ClaimFinancialGetAPIRequesterAtom
  );

  const [financialData, setFinancialData] = useRecoilState(
    ClaimFinancialDataAtom
  );
  const setClaimFlagsDataAtom = useSetRecoilState(ClaimFlagsDataAtom);

  const { responseGet, dispatchGet } = useApiGet<ClaimFinancialDto>(
    `api/Financial/GetClaimFinancialTables?claimId=${claimId}`
  );

  useEffect(() => {
    if (getApiRequester.isRequested) {
      dispatchGet();
      setGetApiRequester({
        ...getApiRequester,
        isRequested: false,
        isCompleted: false,
      });
    }
  }, [getApiRequester]);

  useEffect(() => {
    // Reset on dismount
    return () => {
      setFinancialData(null);
      setGetApiRequester({
        ...getApiRequester,
        isRequested: true,
        isCompleted: false,
      });
    };
  }, []);

  const [_dataReset, setDataReset] = useState<boolean>(false);

  const setGetResponseData = () => {
    if (
      conditionHasValue(responseGet?.responseData) &&
      !responseGet?.isLoading
    ) {
      setFinancialData({
        reserveTable: responseGet?.responseData?.reserveTable ?? null,
        paymentTable: responseGet?.responseData?.paymentTable ?? null,
        quickSearchOption: responseGet?.responseData?.quickSearchOption ?? null,
        reserveSummaryTable:
          responseGet?.responseData?.reserveSummaryTable ?? null,
        paymentOptions: responseGet?.responseData?.paymentOptions ?? null,
      });
      setClaimFlagsDataAtom(responseGet?.responseData?.claimFlagsData ?? null);
      setGetApiRequester({
        ...getApiRequester,
        isRequested: false,
        isCompleted: true,
      });

      setDataReset(true);
    }
  };

  useEffect(() => {
    if (hasMounted === false) {
      setGetResponseData();
      setHasMounted(true);
    }
  }, [responseGet]);

  useEffect(() => {
    if (hasMounted === true) {
      setGetResponseData();
      setReloadReservesSubGrid(true);
      setReloadPaymentsAndReservesGrid(true);
    }
  }, [responseGet]);
  const refreshReservesFinancialTable = (refresh: boolean) => {
    if (refresh === true) {
      dispatchGet();
    }
  };

  return (
    <>
      <div
        id={"claim_financial_tab"}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <>
          {financialData && (
            <PaymentsAndReservesGrid
              claimId={claimId}
              tabKey={tabKey}
              claimLandingClaimant={claimLandingClaimant}
              hasClaimsManagementPermissions={hasClaimsManagementPermissions}
            />
          )}
          <ReservesGrid
            claimId={claimId}
            tabKey={tabKey}
            refreshReservesFinancialTable={refreshReservesFinancialTable}
            hasClaimsManagementPermissions={hasClaimsManagementPermissions}
          />
        </>
      </div>
    </>
  );
};

export default ClaimFinancial;
