import { FC } from "react";
import { EndorsementGeneralProps } from "./EndorsementTypes";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import EndorsementInformation from "../EndorsementInformation/EndorsementInformation";
import ExposureAndPremium from "../EndorsementExposurePremium/ExposureAndPremium";
import EndorsementBindInstructions from "../EndorsementBindInstructions/EndorsementBindInstructions";
import EndorsementDocumentsMain from "../EndorsementDocuments/EndorsementDocumentsMain";

const EndorsementBodySelector: FC<EndorsementGeneralProps> = (props) => {
  switch (props.activeSection) {
    case EndorsementSectionEnum.ENDORSEMENT_INFORMATION:
      return <EndorsementInformation {...props} />;
    case EndorsementSectionEnum.EXPOSURE_AND_PREMIUM:
      return <ExposureAndPremium {...props} />;
    case EndorsementSectionEnum.BIND_INSTRUCTIONS:
      return <EndorsementBindInstructions {...props} />;
    case EndorsementSectionEnum.POLICY_DOCS:
      return <EndorsementDocumentsMain {...props} />;
    default:
      return <EndorsementInformation {...props} />;
  }
};

export default EndorsementBodySelector;
