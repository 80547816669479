import { useState, useEffect, FC } from "react";

import { Box } from "@mui/material";
import { useApiGet, usePermissions } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import IconButton from "../../../TrueUI/Buttons/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import MailRoomCategoryFilter from "../../../FileUpload/FileUploadFilters/MailRoomCategoryFilter";
import BaseTableSearchFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import {
  EditFileConfiguration,
  defaultEditConfiguration,
} from "../../../FileUpload/FileUploadUtils";
import EditFileModal from "../../../FileUpload/EditFileModal";
import { FilesTabConfigurationDto } from "../../../../dtos/files-tab-configuration-dto";
import "./AssignedFiles.module.css";
import { useRecoilState } from "recoil";
import { TypeFilterAtom } from "../../../FileUpload/MailRoomAtoms";
import { SelectOptions } from "../../../../dtos/select-options";
import { addAllOption } from "../../../../utilities/selectFunctions";
import { FromEnumToArray } from "../../../../utilities/enumFunctions";
import { InsuredFileTypesEnums } from "../../../../dtos/insured-file-types-enums";
import { claimPermissions } from "../../../../utilities/claimPermissions";

const tableName = "assigned_files";
type AssignedFilesCardContentProps = {
  updateWorkCards?: () => void;
};
const AssignedFilesCardContent: FC<AssignedFilesCardContentProps> = ({
  updateWorkCards,
}) => {
  const GET_URL = "api/Dashboard/GetAssignedFilesTableData";
  const { responseGet, dispatchGet } = useApiGet<FilesTabConfigurationDto>(
    `api/Dashboard/GetAssignedFilesFiltersConfig`
  );
  const [editConfig, setEditConfig] = useState<Partial<EditFileConfiguration>>(
    defaultEditConfiguration
  );
  const [configurationState, setConfigurationState] =
    useState<FilesTabConfigurationDto | null>(null);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [filesType, setFilesType] = useRecoilState(TypeFilterAtom);
  const hasUserClaimPermission = usePermissions(claimPermissions);
  const typeOptions: Partial<SelectOptions>[] = addAllOption(
    FromEnumToArray(InsuredFileTypesEnums).map((option) => {
      return {
        displayName: option.key,
        stringValue: option.key,
      };
    })
  );

  const setDefaultFilesType = () => {
    const defaultFilesType = hasUserClaimPermission.hasPermission
      ? "Claim"
      : "Insured";
    setFilesType(defaultFilesType);
  };
  useEffect(() => {
    setDefaultFilesType();
    dispatchGet();
  }, []);

  useEffect(() => {
    if (refreshTable) {
      getTableMethods.methods.refreshTable();
      setRefreshTable(false);
      updateWorkCards?.();
    }
  }, [refreshTable]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      onClick={() => openEditModal(actionOptions.row)}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const openEditModal = (row: any) => {
    const insuredId = conditionHasValue(row?.TextInsuredId)
      ? parseInt(row?.TextInsuredId)
      : null;
    const claimId = conditionHasValue(row?.TextClaimId)
      ? parseInt(row?.TextClaimId)
      : null;
    setEditConfig({
      fileId: row?.FileId,
      category: row?.Category,
      fileDisplayName: row?.FileDisplayName,
      downloadedFileName: row?.DownloadedFileName,
      type: row?.FileType,
      claimNumber: row?.TextClaimNumber,
      claimId: claimId,
      insuredId: insuredId,
      insuredName: row?.InsuredName,
      comments: row?.Comments,
      status: row?.FileStatus,
      isEditing: true,
      rowKey: row?._row_key,
      addedDate: row?.CreateAt,
      userShortName: row?.CreateBy,
      assignedTo: row?.AssignedUserId,
      dateReviewed: row?.DateReviewed,
      serviceFrom: row?.ServiceFrom,
      serviceTo: row?.ServiceTo,
      insuredCategoryOptions: configurationState?.insuredCategoryOptions ?? [],
      claimCategoryOptions: configurationState?.claimCategoryOptions ?? [],
      userOptions: configurationState?.userOptions,
      searchNextIdList: configurationState?.searchNextIdList ?? [],
    });
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: GET_URL,
    refreshTable,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showExcelButton: true,
      showPDFButton: false,
      showSaveButton: false,
    },
    columnOptions: [
      { fieldName: "FileName", width: 15 },
      { fieldName: "InsuredOrClaim", width: 8 },
      { fieldName: "Name", width: 10 },
      { fieldName: "FileStatusName", width: 8 },
      { fieldName: "ServiceFrom", width: 7 },
      { fieldName: "ServiceTo", width: 7 },
      { fieldName: "AssignedTo", width: 8 },
      { fieldName: "Category", width: 8 },
      { fieldName: "Comments", width: 8 },
      { fieldName: "DateReviewed", width: 7 },
      { fieldName: "DateAdded", width: 7 },
      { fieldName: "FileSize", width: 7 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: false,
      hideRefreshFiltersButton: true,
      indicationColumnConfiguration: {
        iconColor: "red",
        isHidden: false,
        conditionalIcon: (row) => {
          if (row?.FileType === "Insured") return "insured";
          if (row?.FileType === "Claim") return "claim";
          return "custom";
        },
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "type",
          filterOptions: actionOptions,
          optionsOverride: typeOptions,
          columnsNames: ["Type"],
          labelText: "Type:",
          defaultValue: filesType,
          onChange: (value) => {
            setFilesType(value);
          },
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "assigned_to",
          filterOptions: actionOptions,
          optionsOverride: [
            { displayName: "All", stringValue: "all" },
            ...(configurationState?.userOptions ?? []),
          ],
          columnsNames: ["AssignedToId"],
          labelText: "Assigned To:",
          defaultValue: configurationState?.currentUserId ?? "all",
        }),
      (actionOptions) =>
        MailRoomCategoryFilter({
          actionOptions,
          configurationState,
        }),
      (actionOptions) => {
        return BaseTableSearchFilter(
          actionOptions,
          ["File Name"],
          ["FileName"]
        );
      },
    ],
    events: {
      onDataFilteredChange: (data) => {
        setConfigurationState({
          ...configurationState,
          searchNextIdList:
            data
              ?.filter((row) => row[0] !== undefined && row[0] !== null)
              .map((row) => parseInt(row[0], 10)) || [],
          categoryOptions: configurationState?.categoryOptions || [],
          policyOptions: configurationState?.policyOptions || [],
          userOptions: configurationState?.userOptions || [],
          currentUserId: configurationState?.currentUserId || "",
          fileTypes: configurationState?.fileTypes || [],
          insuredCategoryOptions:
            configurationState?.insuredCategoryOptions || [],
          claimCategoryOptions: configurationState?.claimCategoryOptions || [],
          allowedExtensions: configurationState?.allowedExtensions || [],
        });
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const getTableMethods = tableMethods[tableName];

  const closeEditModal = (e: boolean) => {
    if (e === false) {
      setEditConfig({ ...defaultEditConfiguration, isEditing: e });
    }
  };

  const onUpdatedFile = () => {
    setRefreshTable(true);
  };

  return (
    <>
      <Box id={"assigned_files-table-wrapper"} sx={{ height: "100%" }}>
        {configurationState && <BaseTable2 name={tableName} />}
        <EditFileModal
          configuration={editConfig}
          closeModal={closeEditModal}
          saveSuccessful={onUpdatedFile}
          setConfiguration={setEditConfig}
        />
      </Box>
    </>
  );
};

export default AssignedFilesCardContent;
