import { FC, useEffect, useState } from "react";
import { TaskBoardColumnDto } from "../../../dtos/task-board-column-dto";
import { TaskBoardColumnEnum } from "../../../dtos/task-board-column-enum";
import TaskBoardColumn from "./TaskBoardColumn";
import style from "./taskBoard.module.css";
import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import TaskBoardShowContainer from "./TaskBoardShowContainer";

type TaskBoardProps = {
  columns: TaskBoardColumnDto[];
  slideContent?: boolean;
  currentCard?: SelectedCardType;
  showHideContentSection?: boolean;
  columnButtonText?: string;
  InternalComponent?: () => JSX.Element;
  onColumnButtonClick?: () => void;
  onClickCard?: (cardInformation: any) => void;
  startLeftPanelOpen?: boolean;
};

export type SelectedCardType = {
  columnField: string;
  cardInformation: TaskBoardCardDto;
};

const TaskBoard: FC<TaskBoardProps> = ({
  columns,
  slideContent = true,
  showHideContentSection = true,
  currentCard,
  InternalComponent,
  columnButtonText = "New",
  onColumnButtonClick,
  onClickCard,
  startLeftPanelOpen,
}) => {
  const [open, setOpen] = useState<boolean>(startLeftPanelOpen ?? false);
  const [selectedCard, setSelectedCard] = useState<SelectedCardType>();

  const mineColumn: TaskBoardColumnDto =
    columns?.find(
      (column) => column.columnType === TaskBoardColumnEnum.MY_WORK
    ) ?? columns?.[0];

  const otherColumns = columns?.filter(
    (column) => column.columnField !== mineColumn?.columnField
  );

  const _onClickCard = (cardInformation: SelectedCardType) => {
    onClickCard?.(cardInformation);
    if (slideContent) {
      setSelectedCard(cardInformation);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedCard(undefined);
    }
  }, [open]);

  useEffect(() => {
    setSelectedCard(currentCard);
  }, [currentCard]);

  return (
    <>
      <div className={style.columns_container}>
        <TaskBoardColumn
          column={mineColumn}
          onClickCard={_onClickCard}
          selectedCard={selectedCard}
          key={`task-board-my-work-column`}
        />
        <div
          className={`${open ? style?.hide_component : style?.show_component} ${
            style.other_columns_container
          }`}
        >
          {otherColumns?.map((column, index) => (
            <TaskBoardColumn
              column={column}
              onClickCard={_onClickCard}
              selectedCard={selectedCard}
              textButton={columnButtonText}
              onClickButton={onColumnButtonClick}
              key={`task-board-column-${index}`}
            />
          ))}
        </div>
        {slideContent && conditionHasValue(selectedCard) && (
          <TaskBoardShowContainer
            open={open}
            setOpen={setOpen}
            showHideContentSection={showHideContentSection}
            InternalComponent={InternalComponent}
          />
        )}
      </div>
    </>
  );
};

export default TaskBoard;
