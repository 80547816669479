import { FC, useEffect, useState } from "react";
import {
  DeleteAchBatchButonProps,
  DeleteDialogConfigurationProps,
} from "./AchTypes";
import { Button } from "../../TrueUI";
import { useApiPost } from "../../../hooks";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import {
  defaultDialogConfiguration,
  onDeleteAndUndoTransactionsNo,
  onDeleteAndUndoTransactionsYes,
} from "./AchUtilsAndConsts";

const DeleteAchBatchButon: FC<DeleteAchBatchButonProps> = ({
  selectedBatchId,
  setBatchDeleted,
}) => {
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DeleteDialogConfigurationProps>(defaultDialogConfiguration);

  const { responsePost, dispatchPost } = useApiPost(
    `api/BillingPaymentBatch/DeleteBillingAchPaymentBatch`,
    {
      billingBatchId: selectedBatchId,
      undoTransactions: dialogConfiguration?.undoingTransaction ?? false,
    }
  );

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setDialogConfiguration(defaultDialogConfiguration);
      setBatchDeleted(true);
    }
  }, [responsePost]);

  useEffect(() => {
    if (dialogConfiguration.dispatchPost === true) {
      dispatchPost();
    }
  }, [dialogConfiguration]);

  return (
    <>
      <Button
        onClick={() =>
          setDialogConfiguration({
            ...dialogConfiguration,
            deleting: true,
          })
        }
        variantStyle={"outlined"}
        fullWidth
      >
        Delete
      </Button>
      <DialogConfirmation
        id="achNotTransactionsDialogNotification"
        open={
          dialogConfiguration.deleting || dialogConfiguration.undoingTransaction
        }
        dialogDescriptionText={dialogConfiguration?.deleteTitle ?? ""}
        onCloseEvent={() => setDialogConfiguration(defaultDialogConfiguration)}
        onOptionNoEvent={() =>
          onDeleteAndUndoTransactionsNo(
            dialogConfiguration,
            setDialogConfiguration
          )
        }
        onOptionYesEvent={() =>
          onDeleteAndUndoTransactionsYes(
            dialogConfiguration,
            setDialogConfiguration
          )
        }
        optionYesOverrideLabel="Yes"
      />
    </>
  );
};

export default DeleteAchBatchButon;
