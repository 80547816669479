import { FC, useState, useEffect } from "react";
import {
  ModalChangeClaimStatusProps,
  modalStatusProps,
} from "./ModalChangeProps";
import {
  Button,
  Col,
  Modal,
  Row,
  SaveButton,
  TabPanel,
  Tabs,
} from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Col";
import { Badge, Box } from "@mui/material";
import {
  modalChangeClaimStatusStyle,
  openTasksTabTitle,
} from "./ModalChangeClaimStyles";
import { useApiGet } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import ModalChangeClaimOpenTasks from "./ModalChangeClaimOpenTasks";
import ClaimStatusHistoryTab from "./ClaimStatusHistoryTab";
import { ClaimStatusChangeInfoDto } from "../../../../../dtos/claim-status-change-info-dto";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import ModalChangeClaimStatusTab from "./StatusTab/ModalChangeClaimStatusPrincipalTab";

const ModalChangeClaimStatus: FC<ModalChangeClaimStatusProps> = ({
  setAllModalConfiguration,
  allModalConfiguration,
  claimId,
  insuredId,
}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [save, setSave] = useState(false);
  //TODO: This hooks will be used when the tabs validations are implemented
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [openTasksCount, setOpenTasksCount] = useState(0);
  const [claimStatusInfo, setClaimStatusInfo] =
    useState<ClaimStatusChangeInfoDto | null>(null);

  const { responseGet: responseGetTasks, dispatchGet: dispatchGetTasks } =
    useApiGet<number>(`api/Task/GetClaimOpenTasksCount?claimId=${claimId}`);

  const {
    responseGet: responseGetStatusInfo,
    dispatchGet: dispatchGetStatusInfo,
  } = useApiGet<ClaimStatusChangeInfoDto>(
    `api/Claims/GetClaimStatusChangeInfo?claimId=${claimId}`
  );
  const closeButtonClicked = (e) => {
    setAllModalConfiguration({
      ...allModalConfiguration,
      openModalChangeClaimStatus: e,
    });
  };
  const cancelButtonClicked = () => {
    setAllModalConfiguration({
      ...allModalConfiguration,
      openModalChangeClaimStatus: false,
    });
  };

  const saveButtonClicked = () => {
    setSave(true);
  };

  const emailButtonClicked = () => {
    if (conditionHasValue(claimStatusInfo)) {
      if (claimStatusInfo?.userEmail) {
        const email = claimStatusInfo?.userEmail;
        const claimNumber = claimStatusInfo?.claimNumber;
        window.location.href = `mailto:${email}?subject=RE:%20Claim%20Number%20${claimNumber}`;
      } else alert("No email for this Insured.");
    }
  };

  const onChangeTab = (e) => {
    setCurrentTab(e.selected);
    if (e.selected === 0) setShowSaveButton(true);
    else setShowSaveButton(false);
  };

  const openTasksTab = (
    <Badge badgeContent={openTasksCount} color="error" sx={openTasksTabTitle}>
      <span>Open Tasks</span>
    </Badge>
  );

  useEffect(() => {
    if (allModalConfiguration.openModalChangeClaimStatus) {
      dispatchGetStatusInfo();
      dispatchGetTasks();
    }
  }, [allModalConfiguration.openModalChangeClaimStatus]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetTasks)) {
      setOpenTasksCount(responseGetTasks.responseData ?? 0);
    }
  }, [responseGetTasks]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetStatusInfo)) {
      setClaimStatusInfo(responseGetStatusInfo.responseData ?? null);
    }
  }, [responseGetStatusInfo]);

  return (
    <Modal
      {...{
        ...modalStatusProps,
        open: allModalConfiguration.openModalChangeClaimStatus,
        closeEvent: closeButtonClicked,
        size: "sm",
      }}
    >
      <Row {...rowWithNoMarginNorGutter} rowDirection="column">
        <Col {...colWithNoMarginNorGutter}>
          <Tabs
            name={"claims-change-status-modal-tabs"}
            selected={currentTab}
            onSelect={(e) => onChangeTab(e)}
            stateControlled
            disableQueryString
            hasBadge
          >
            <TabPanel title="Status" key={"status-tab"}>
              <ModalChangeClaimStatusTab
                claimId={claimId ?? 0}
                insuredId={insuredId}
                claimInfo={claimStatusInfo}
                save={save}
                setSave={setSave}
                setAllModalConfiguration={setAllModalConfiguration}
                allModalConfiguration={allModalConfiguration}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              />
            </TabPanel>
            <TabPanel title={openTasksTab} key={"open-tasks-tab"}>
              <ModalChangeClaimOpenTasks
                claimId={claimId ?? 0}
                setAllModalConfiguration={setAllModalConfiguration}
                allModalConfiguration={allModalConfiguration}
              />
            </TabPanel>
            <TabPanel title="History" key={"history-tab"}>
              <ClaimStatusHistoryTab claimId={claimId ?? 0} />
            </TabPanel>
          </Tabs>
        </Col>
        <Col>
          <Box sx={modalChangeClaimStatusStyle()}>
            <Button
              variantStyle="outlined"
              onClick={emailButtonClicked}
              name="emailInsuredButton"
            >
              Email Insured
            </Button>
            <Box className={"right-buttons-container"}>
              {showSaveButton && (
                <SaveButton
                  onClick={saveButtonClicked}
                  disabled={isSaveButtonDisabled}
                />
              )}
              <Button
                variantStyle="outlined"
                onClick={cancelButtonClicked}
                name="cancelButton"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalChangeClaimStatus;
