import { SxProps, Theme } from "@mui/material";
import { FC } from "react";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import {
  FormattingDate,
  GettingDateWithoutTime,
  getDateObject,
  getTimeUntilNow,
} from "../../../utilities/dateFunctions";
import Input from "./Input";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

export type inputDateProps = {
  id: string;
  name: string;
  inputKey?: string;
  label?: string;
  errorMessage?: string[] | null;
  value?: Date | null;
  className?: string;
  readOnly?: boolean;
  focus?: boolean;
  variant?: "filled" | "standard" | "outlined";
  labelPosition?: "start" | "end" | "top" | "bottom";
  align?: "left" | "center" | "right";
  helperText?: string;
  placeholder?: string;
  includeTime?: boolean;
  inputWidth?: string;
  isInputIdent?: boolean;
  permissions?: number[];
  sx?: SxProps<Theme>;
  formatDate?: string;
  onChange?: (e) => void;
  onChangeRawValue?: (e?: any) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  withElapsedTime?: boolean;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  minNumericValue?: number;
  maxNumericValue?: number;
  tabIndex?: number;
  isAutoComplete?: boolean;
};

const InputDate: FC<inputDateProps> = ({
  id,
  name,
  //inputKey,
  label,
  errorMessage,
  value,
  className,
  readOnly,
  focus,
  variant,
  labelPosition,
  align,
  helperText,
  placeholder,
  includeTime,
  inputWidth,
  isInputIdent,
  permissions,
  sx,
  formatDate,
  onChange,
  onChangeRawValue,
  disableFuture,
  disablePast,
  withElapsedTime,
  inputFontType,
  labelFontType,
  minNumericValue,
  maxNumericValue,
  tabIndex,
  isAutoComplete = true,
}) => {
  const getFormatDate = formatDate
    ? formatDate
    : includeTime
    ? "M/d/yyyy h:mm a"
    : "M/d/yyyy";

  const dateType = includeTime ? "internalDateTime" : "internalDate";

  const internalDateChange = (e) => {
    const value = e.value;
    const dateWithoutTimeValidated = conditionHasValue(value)
      ? GettingDateWithoutTime(value)
      : null;
    const dateWithTimeValidated = conditionHasValue(value) ? value : null;
    const dateByType =
      dateType === "internalDateTime"
        ? dateWithTimeValidated
        : dateWithoutTimeValidated;

    onChange?.(e);
    onChangeRawValue?.(dateByType);
  };

  const getElapsedTime = (date) => {
    const elapsedTime =
      withElapsedTime && conditionHasValue(date)
        ? ` (${getTimeUntilNow(getDateObject(date), "years")} years)`
        : "";

    return elapsedTime;
  };

  const getInputValue = (date) => {
    const formattedDate = conditionHasValue(date)
      ? FormattingDate(getDateObject(date))
      : "";

    return withElapsedTime
      ? `${formattedDate}${getElapsedTime(date)}`
      : formattedDate;
  };

  return (
    <Input
      tabIndex={tabIndex}
      label={label}
      id={readOnly ? `$true_input_date_with_elapsed_time` : id}
      name={name}
      className={`${
        readOnly ? "true_input_date_with_elapsed_time" : ""
      } ${className}`}
      type={readOnly ? "text" : dateType}
      errorMessage={errorMessage}
      formatDate={getFormatDate}
      readOnly={readOnly}
      value={readOnly ? getInputValue(value) : value}
      onChange={internalDateChange}
      focus={focus}
      variant={variant}
      labelPosition={labelPosition}
      align={align}
      helperText={helperText}
      placeholder={placeholder}
      inputWidth={inputWidth}
      isInputIdent={isInputIdent}
      permissions={permissions}
      sx={sx}
      onChangeRawValue={onChangeRawValue}
      disableFuture={disableFuture}
      disablePast={disablePast}
      inputFontType={inputFontType}
      labelFontType={labelFontType}
      minNumericValue={minNumericValue}
      maxNumericValue={maxNumericValue}
      isAutoComplete={isAutoComplete}
    />
  );
};

export default InputDate;
