import { FC, useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import LaunchIcon from "@mui/icons-material/Launch";
import { useApiGet } from "../../../hooks";
import { LateFeePage } from "../../../dtos/late-fee-page";
import { BaseTable } from "../../../dtos/base-table";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { SelectOptions } from "../../../dtos/select-options";

const tableName = "late_fees_table";

type FilterOptions = {
  statusOptionList?: Partial<SelectOptions>[] | SelectOptions[];
  monthAndYearList?: Partial<SelectOptions>[] | SelectOptions[];
};

const LateFeesTableWrapper: FC = () => {
  const { responseGet, dispatchGet } = useApiGet<LateFeePage>(
    "api/LateFee/GetLateFeesTable"
  );

  const [tableData, setTableData] = useState<BaseTable>();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    statusOptionList: [],
  });

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTableData(responseGet.axiosResponse?.data.tableData);
      setFilterOptions({
        statusOptionList: responseGet.axiosResponse?.data.statusOptions ?? [],
        monthAndYearList:
          responseGet.axiosResponse?.data.monthAndYearOptions ?? [],
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(tableData)) {
      manuallyReloadParameters();
    }
  }, [tableData]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            //TODO: later user story
            console.log({ actionOptions });
          }}
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration = () => {
    return {
      name: tableName,
      useManuallyReloadParameters: true,
      disableInitialize: true,
      columnsAndData: tableData,
      columnOptions: [
        { fieldName: "Insured", width: 30 },
        { fieldName: "Policy", width: 10 },
        { fieldName: "Date", width: 10 },
        { fieldName: "Status", width: 20 },
        { fieldName: "Balance", width: 10, align: "right" },
        { fieldName: "FeeAmount", width: 10, align: "right" },
        { fieldName: "WaivedReason", width: 10 },
        { fieldName: "OPTIONS", width: 3 },
      ],
      toolbarOptions: {
        showEditButton: false,
        showSaveButton: false,
        showSortFilter: false,
        showImportButton: false,
        showAddButton: false,
      },
      filterOptions: [
        (options) =>
          BaseGridDropDownFilter({
            id: "late-fee-status-filter",
            columnNames: ["Status"],
            labelText: "Status:",
            options,
            optionsOverride: filterOptions?.statusOptionList,
          }),
        (options) =>
          BaseGridDropDownFilter({
            id: "late-fee-date-filter",
            columnNames: ["MonthYear"],
            labelText: "Select Month:",
            defaultValue:
              filterOptions?.monthAndYearList?.[0]?.stringValue ?? "",
            options,
            optionsOverride: filterOptions?.monthAndYearList,
          }),
      ],

      advancedOptions: {
        optionsContextActions: {
          contextComponent: actionsForRows,
        },
        optionsColumnConfiguration: {
          isHidden: false,
          optionType: "component",
        },
      },
    } as BaseGridProperties;
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration());
  return <BaseGrid name={tableName} />;
};

export default LateFeesTableWrapper;
