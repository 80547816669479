import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DiaryConfigurationDto } from "../../../../dtos/diary-configuration-dto";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import { DiaryPage } from "../../../../dtos/diary-page";
import { globalOptions } from "../../../../GlobalAtoms";
import { useApiGet, usePermissions } from "../../../../hooks";
import themes from "../../../../media/TrueTheme";
import { ContextProps } from "../InsuredDrawerConstants";
import AddNote from "./AddNote";
import FilterTabsNotes from "./FilterTabsNotes";
import { noteStyles } from "./NotesStyles";
import { ContextEnums } from "../../../../dtos/context-enums";

type NotesWrapperProps = {
  context: ContextProps;
};

const claimsIncludePermissions = [2, 13, 14, 15];
const insuredExcludePermissions = [3];

const NotesWrapper: FC<NotesWrapperProps> = ({ context }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [expanded, setExpanded] = useState<boolean>(true);
  const [diaryNotes, setDiaryNotes] = useState<DiaryNoteDto[]>([]);
  const [refreshConversation, setRefreshConversation] = useState(false);
  const [diaryConfiguration, setDiaryConfiguration] =
    useState<DiaryConfigurationDto | null>(null);
  const contextPermissions =
    context.type === ContextEnums.CLAIMS
      ? claimsIncludePermissions
      : insuredExcludePermissions;
  const hasPermission = usePermissions(
    contextPermissions,
    context.type === ContextEnums.CLAIMS //Claims Include, Insured Exclude
  );

  const url =
    context.type === ContextEnums.CLAIMS
      ? `api/DiaryNote/GetDiaryPageByClaimNumber?claimNumber=${context.claimNumber}`
      : `api/DiaryNote/GetDiaryPageByInsuredId?insuredId=${context.insuredId}`;

  const { responseGet, dispatchGet } = useApiGet<DiaryPage>(url);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (refreshConversation) {
      dispatchGet();
      setRefreshConversation(false);
    }
  }, [refreshConversation]);

  useEffect(() => {
    if (responseGet?.responseData && !responseGet?.isLoading) {
      setDiaryConfiguration(responseGet?.responseData.diaryConfigurationDto);
      setDiaryNotes(responseGet?.responseData.diaryNotes);
    }
  }, [responseGet]);

  return (
    <>
      {diaryConfiguration && (
        <Box className="diaryContainer" sx={noteStyles(theme)}>
          {hasPermission && (
            <Box className={"add_diary"}>
              <AddNote
                context={context}
                diaryConfiguration={diaryConfiguration}
                setRefreshConversation={setRefreshConversation}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </Box>
          )}
          <Box className={"diary-entries"}>
            <FilterTabsNotes
              expanded={expanded}
              diaryNotes={diaryNotes}
              context={context}
              diaryConfiguration={diaryConfiguration}
              setRefreshConversation={setRefreshConversation}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default NotesWrapper;
