import { FC } from "react";
import { TaskBoardCardDto } from "../../../dtos/task-board-card-dto";
import style from "./taskBoard.module.css";
import { SelectedCardType } from "./TaskBoard";

type TaskBoardWithComponentCardProps = {
  card: TaskBoardCardDto;
  isSelected: boolean;
  columnField: string;
  onClickCard: (card: SelectedCardType) => void;
  children: JSX.Element | JSX.Element[];
};

const TaskBoardWithComponentCard: FC<TaskBoardWithComponentCardProps> = ({
  card,
  isSelected,
  columnField,
  onClickCard,
  children
}) => {
  const {
    cardColor,
  } = card;

  const _onClickCard = () => {
    const cardInformation: SelectedCardType = {
      columnField: columnField,
      cardInformation: card,
    };
    onClickCard?.(cardInformation);
  };

  return (
    <div
      className={`${style.card} ${style.card_with_custom_content} ${isSelected && style.selected_card}`}
      style={{ borderLeft: `3px solid ${cardColor}` }}
      onClick={_onClickCard}
    >
      {children}
    </div>
  );
};

export default TaskBoardWithComponentCard;
