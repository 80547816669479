import { FC } from "react";

type TableBodyProperties = {
  id?: string;
  children?: any;
};

const TableBody: FC<TableBodyProperties> = ({ id, children }) => {
  return (
    <div id={id} className="true_table_body">
      {children}
    </div>
  );
};

export default TableBody;
