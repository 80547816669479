import { FC, useState } from "react";
import { Button, ExportJSONButton, SplitButton } from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { EndorsementBottomButtonsProps } from "./EndorsementTypes";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { updateInsuredAtom } from "../updatesPolicyQuoteFunctions";
import {
  getJSONDataWithoutConfigurations,
  policyInformationReset,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import { getQueryStringsURL } from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { useNavigate } from "react-router";
import style from "./Endorsement.module.css";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import { useSetRecoilState } from "recoil";
import {
  CONTINUE_ACTION,
  REVISE_AUDIT_ACTION,
  SAVE_ONLY_ACTION,
} from "./EndorsementUtils";

const EndorsementBottomButtons: FC<EndorsementBottomButtonsProps> = ({
  tabKey,
  activeSection,
  readonly,
  isFinalAudit = false,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const navigate = useNavigate();
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const setSaveQuoteAtom = useSetRecoilState(SaveQuoteAtom);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const splitButtonOptions = [
    {
      key: 1,
      option: "continue",
      dropdownText: "CONTINUE",
      action: () => setSaveQuoteAtom({ saveAction: CONTINUE_ACTION }),
    },
    {
      key: 2,
      option: "SAVE ONLY",
      dropdownText: "SAVE ONLY",
      action: () => setSaveQuoteAtom({ saveAction: SAVE_ONLY_ACTION }),
    },
  ];

  const returnToHistoryTable = () => {
    const atomValue = getAtom();
    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      policyInformationReset
    );
    setAtom(newAtomValue);
    setDialogOpen(false);

    const historyHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: SubSideNavItemDestinationsEnum.HISTORY,
      },
    ]);
    navigate(historyHashes);
  };

  const onClickRecheck = () =>
    setPolicyQuoteTriggers(["endorsementInformationComponent"]);

  const getExportJSONData = () => {
    const atomValue = getAtom();
    const jsonData = getJSONDataWithoutConfigurations(
      atomValue?.policyQuoteInformation
    );
    return JSON.stringify(jsonData, null, 2);
  };

  const getSaveButton = () => {
    return isFinalAudit &&
      activeSection === AuditSectionEnum.AUDIT_RESULTS_AND_DOCS ? (
      <Button
        id="complete-audit-button-id"
        name="complete-audit-button"
        isDisabled={readonly}
        onClick={() => setSaveQuoteAtom({ saveAction: SAVE_ONLY_ACTION })}
      >
        COMPLETE AUDIT
      </Button>
    ) : activeSection === AuditSectionEnum.AUDIT_UNDERWRITING_NOTES ? (
      <Button
        id="id-audit-save-only-button"
        name="name-audit-SaveOnlyButton"
        size="medium"
        onClick={() => setSaveQuoteAtom({ saveAction: SAVE_ONLY_ACTION })}
      >
        SAVE ONLY
      </Button>
    ) : (
      <SplitButton items={splitButtonOptions} disabled={readonly} />
    );
  };

  return (
    <>
      <div
        id="endorsement-bottom-buttons-container"
        className={style.endorsement_bottom_buttons_container}
      >
        <div
          id="endorsement-bottom-buttons-left-column"
          className={style.endorsement_bottom_buttons_left_column}
        >
          <div
            id="endorsement-export-json-container"
            className={style.endorsement_bottom_buttons_right_margin}
          >
            <ExportJSONButton
              name="endorsement-export-json"
              fileName="policy-json"
              getDataOnClick={getExportJSONData}
            />
          </div>
          {activeSection === EndorsementSectionEnum.ENDORSEMENT_INFORMATION &&
            !isFinalAudit && (
              <div id="endorsement-re-check-for-changes-container">
                <Button
                  id="re-check-for-changes-id"
                  name="re-check-for-changes"
                  variantStyle="outlined"
                  onClick={() => onClickRecheck()}
                  isDisabled={readonly}
                >
                  RE-CHECK FOR CHANGES
                </Button>
              </div>
            )}
          {isFinalAudit && readonly === true && (
            <div id="revise-audit-container">
              <Button
                id="revise-audit-id"
                name="revise-audit"
                variantStyle="outlined"
                onClick={() =>
                  setSaveQuoteAtom({ saveAction: REVISE_AUDIT_ACTION })
                }
              >
                REVISE AUDIT
              </Button>
            </div>
          )}
        </div>
        <div
          id="endorsement-bottom-buttons-right-column"
          className={style.endorsement_bottom_buttons_right_column}
        >
          <div
            id="endorsement-split-continue-button-container"
            className={style.endorsement_bottom_buttons_right_margin}
          >
            {getSaveButton()}
          </div>
          <div id="endorsement-cancel-button-container">
            <Button
              id="id-policy-cancel-button"
              name="namePolicyCancelButton"
              variantStyle="outlined"
              size="medium"
              onClick={() => setDialogOpen(true)}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <DialogConfirmation
        id="endorsement-cancel-dialog-id"
        name="endorsement-cancel-dialog"
        dialogDescriptionText="Are you sure you want to cancel?"
        open={dialogOpen}
        onCloseEvent={setDialogOpen}
        onOptionNoEvent={setDialogOpen}
        onOptionYesEvent={returnToHistoryTable}
      />
    </>
  );
};

export default EndorsementBottomButtons;
