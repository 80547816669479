import { FC } from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BaseTable2, Heading4 } from "../../TrueUI";
import { useNavigate } from "react-router";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { StatusEnums } from "../../../dtos/status-enums";
import { formattingKey } from "../../../utilities/enumFunctions";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/Launch";

const tableName = "program_main_instance_wrapper_table";

const greyColor = grey[200];

const ProgramMainInstanceWrapper: FC = () => {
  const navigate = useNavigate();
  const addNewProgram = () => {
    navigate("/admin-tools/program/new");
  };
  const redirectToViewProgram = (programId: number) => {
    navigate(`/admin-tools/program/${programId}`);
  };

  const ViewItemLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          redirectToViewProgram(rowData.ProgramId);
        }}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const hasPermission = usePermissions(
    [PermissionsEnums.TRUE_ADMIN],
    true
  ).hasPermission;

  const tableConfiguration: BaseTable2Properties = {
    getURL: "api/AdminTools/Program/GetProgramMainPage",
    name: tableName,
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          columnsNames: ["ProgramStatus"],
          defaultValue: formattingKey(StatusEnums[StatusEnums.ACTIVE]),
          validatingAgainstDisplayValue: true,
        }),
    ],
    events: { addEventOverrideCallback: addNewProgram },
    toolbarOptions: {
      addButtonText: "ADD NEW PROGRAM",
      showAddButton: hasPermission,
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showExcelButton: false,
      showPDFButton: false,
    },
    advancedOptions: {
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          ViewItemLink?.(actionOptions.row) ?? null,
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <Box
      sx={{
        padding: "0 20px",
        borderTop: `1px solid ${greyColor}`,
        minHeight: "100%",
        height: "fit-content",
        width: "100%"
      }}
    >
      <Heading4>Programs</Heading4>
      <Box sx={{ overflowY: "auto", height: "85vh" }}>
        <BaseTable2 name={tableName} />
      </Box>
    </Box>
  );
};

export default ProgramMainInstanceWrapper;
