import { BaseGridColumnFilterExtensions } from "../../../dtos/base-grid-column-filter-extensions";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { DataTypeEnums } from "../../../dtos/data-type-enums";

export const ColumnFilterExtensionFilterGateFor = <
  T extends keyof BaseGridColumnFilterExtensions
>(
  dataTypes: DataTypeEnums[][],
  column: BaseTableColumn,
  filterName?: T
): boolean => {
  /* 
      The columnFilterExtensionOptions allows us to override indiviual filters on a "Need-To-Have" bases. By default, if "showColumnFilterExtension" is true, 
      all filters will show, but the moment we start to configure "columnFilterExtensionOptions", all configurations for that column are false unless 
      otherwise specificed in columnFilterExtensionOptions. 
  */
  if (
    column.columnFilterExtensionOptions !== undefined &&
    column.columnFilterExtensionOptions !== null &&
    filterName !== undefined
  ) {
    const configuredFilterColumnExtensionBooleanValue =
      column.columnFilterExtensionOptions[filterName];
    if (configuredFilterColumnExtensionBooleanValue === false) {
      return false;
    }
  }

  // This filters out by type based on the data type of the column
  const dataTypeSpread = dataTypes.flat();
  return (
    column?.defaultValueType !== null &&
    [...dataTypeSpread].includes(column?.defaultValueType)
  );
};
