import { FC } from "react";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { RadioButtonEnum } from "../../../dtos/radio-button-enum";
import { Switch } from "../../TrueUI";
// import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";
// import Switch from "../../Checkbox/Switch";
// import { RadioButtonEnum } from "../../../../dtos/radio-button-enum";

interface BaseTableCheckBocCellProperties
  extends BaseTableCellSelectProperties {
  isDisabled?: boolean;
}
// This is a readOnly Cell if you need that this change depending
// on the state, you need to use the radio cell, not this.
const ConditionalRadioButtonCell: FC<BaseTableCheckBocCellProperties> = ({
  cellKey,
  value,
  column,
}) => {
  const getPropertiesByValue = () => {
    switch (parseInt(value ?? "0") ?? 0) {
      case RadioButtonEnum.INVALID:
        return { isChecked: false, isInvalid: true };
      case RadioButtonEnum.SELECTED:
        return { isChecked: true };
      case RadioButtonEnum.UNSELECTED:
        return { isChecked: false };
      default:
        return { isChecked: false };
    }
  };

  return (
    <>
      {value != "" && (
        <Switch
          key={cellKey}
          name={`${column.fieldName}-${cellKey}`}
          id={`${column.fieldName}-${cellKey}`}
          control={"radio"}
          readOnly={true}
          isTableCell
          {...getPropertiesByValue()}
        />
      )}
    </>
  );
};

export default ConditionalRadioButtonCell;
