import { FC, useEffect, useState } from "react";
import { FormInvoiceItemWrapperProps } from "./TypesForModalAddInvoiceItem";
import FormInvoiceItem from "./FormInvoiceItem";
import { useApiGet } from "../../../../../../hooks";
import { InsuredInvoiceItemFormOptionsDto } from "../../../../../../dtos/insured-invoice-item-form-options-dto.ts";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options.ts";
import { InvoiceItemDto } from "../../../../../../dtos/invoice-item-dto.ts";

const FormInvoiceItemWrapper: FC<FormInvoiceItemWrapperProps> = ({
  insuredId,
  invoiceSubId,
  invoiceItem,
  invoiceDetailId,
  setInvoiceItem,
  errorDetails,
}) => {
  const { responseGet, dispatchGet } =
    useApiGet<InsuredInvoiceItemFormOptionsDto>(
      `api/Invoice/GetInsuredOptionForInvoiceItem?insuredId=${insuredId}&invoiceSubId=${
        invoiceSubId ?? -1
      }&invoiceDetailId=${invoiceDetailId ?? -1}`
    );

  const [formSelectOptions, setFormSelectOptions] = useState<{
    locations: Partial<SelectOptions>[] | [];
    tranAccounts: Partial<SelectOptions>[] | [];
    tranAccountDescriptions: Partial<SelectOptions>[] | [];
  } | null>();

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const invoiceItemFormOptions = responseGet?.responseData;
      setFormSelectOptions({
        locations: invoiceItemFormOptions?.locations ?? [],
        tranAccounts: invoiceItemFormOptions?.tranAccounts ?? [],
        tranAccountDescriptions:
          invoiceItemFormOptions?.tranAccountsDescriptions ?? [],
      });
      const invoiceItemData: { data: Partial<InvoiceItemDto> | null } = {
        data: {
          ...invoiceItem,
          addressId:
            (invoiceItem?.invoiceDetailId ?? 0) !== 0
              ? invoiceItem?.addressId ?? null
              : invoiceItemFormOptions?.locations?.[0]?.intValue ??
                invoiceItem?.addressId ??
                null,
        },
      };
      if (invoiceItemFormOptions?.invoiceItemDto !== null) {
      }
      invoiceItemData.data = {
        ...invoiceItemData.data,
        ...(invoiceItemFormOptions?.invoiceItemDto ?? {}),
      };
      setInvoiceItem(invoiceItemData.data);
    }
  }, [responseGet]);

  return (
    <>
      {formSelectOptions !== null && (
        <FormInvoiceItem
          locations={formSelectOptions?.locations ?? []}
          tranAccounts={formSelectOptions?.tranAccounts ?? []}
          tranAccountDescriptions={
            formSelectOptions?.tranAccountDescriptions ?? []
          }
          invoiceItem={invoiceItem}
          setInvoiceItem={setInvoiceItem}
          errorDetails={errorDetails}
        />
      )}
    </>
  );
};
export default FormInvoiceItemWrapper;
