import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import { ModalInsuredContactsProps } from "./TypesInsuredContacts";
import FormInsuredContact from "./FormInsuredContact";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";

const ModalInsuredContacts: FC<ModalInsuredContactsProps> = ({
  modalConfiguration,
  setModalConfiguration,
}) => {
  const [contact, setContact] = useState<any | null>(null);
  const [formSelectOptions, setFormSelectOptions] = useState<{
    tags: Partial<SelectOptions>[];
  }>({ tags: [] });
  const { dispatchGet, responseGet } = useApiGet<Partial<SelectOptions>[]>(
    `api/insuredinformation/GetModalInsuredInformationContactOptions?insuredId=${
      modalConfiguration?.insuredId ?? 0
    }`
  );

  const { dispatchPost, responsePost, validatorErrorResponse } =
    useApiPost<any>(
      `api/insuredinformation/SaveInformationContactsFromModal`,
      contact
    );
  const [errorDetails, setErrorDetails] = useState<any>();

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setModalConfiguration({
        ...modalConfiguration,
        open: false,
        refreshTable: true,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (modalConfiguration?.open === true) {
      dispatchGet();
    }
  }, [modalConfiguration?.open]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormSelectOptions({ tags: responseGet?.responseData ?? [] });
    }
  }, [responseGet]);

  useEffect(() => {
    setContact(modalConfiguration?.contact ?? null);
  }, [modalConfiguration?.contact]);

  const getExt = (phone) => {
    if (phone && phone.length > 10) {
      return phone.substring(10);
    } else {
      return "";
    }
  };
  const getPhone = (phone) => {
    if (!phone) {
      return "";
    } else {
      if (phone.length > 10) {
        return phone.substring(0, 10);
      } else {
        return phone;
      }
    }
  };

  useEffect(() => {
    if (
      contact?.contactId !== 0 &&
      (contact?.primaryPhone ?? 0) !== 0 &&
      (contact?.primaryPhoneAdd ?? 0) === 0
    ) {
      setContact({
        ...contact,
        primaryPhone: getPhone(contact.primaryPhone),
        primaryPhoneAdd: getExt(contact.primaryPhone),
      });
    }
  }, [contact]);

  useEffect(() => {
    if ((contact?.contactStatus ?? 0) === StatusEnums.DELETED) {
      dispatchPost();
    }
  }, [contact]);

  useEffect(() => {
    if (modalConfiguration?.open === false) {
      setErrorDetails(null);
    }
  }, [modalConfiguration?.open]);

  useEffect(() => {
    if (
      contact?.contactStatus === StatusEnums.INACTIVE &&
      contact?.primaryContact === true
    ) {
      setModalConfiguration({
        ...modalConfiguration,
        showInactivePrimaryContactConfirmation: true,
      });
      setContact({
        ...contact,
        contactStatus: StatusEnums.ACTIVE,
      });
    }
  }, [contact?.contactStatus]);

  return (
    <>
      <Modal
        id="modal-insured-contacts"
        title={
          modalConfiguration.action === "add" ? "Add Contact" : "Edit Contact"
        }
        open={modalConfiguration?.open}
        cancelEvent={() =>
          setModalConfiguration({ ...modalConfiguration, open: false })
        }
        showCloseButton
        showCancelTextButton
        cancelButtonWithConfirmation
        deleteEvent={
          (contact?.primaryContact ?? false) === false
            ? () =>
                setContact({ ...contact, contactStatus: StatusEnums.DELETED })
            : () => {
                setModalConfiguration({
                  ...modalConfiguration,
                  showDeletePrimaryContactConfirmation: true,
                });
              }
        }
        deleteDisabled={modalConfiguration?.action === "add"}
        hideDeleteButton={modalConfiguration?.action === "add"}
        saveEvent={() => dispatchPost()}
        deleteButtonWithConfirmation={
          (contact?.primaryContact ?? false) === false
        }
        deleteButtonConfirmationText={
          "Are you sure you want to delete this item?"
        }
      >
        {(formSelectOptions?.tags?.length ?? 0) > 0 && (
          <FormInsuredContact
            contact={contact}
            setContact={setContact}
            error={errorDetails}
            tags={formSelectOptions?.tags ?? []}
          />
        )}
      </Modal>
      <DialogConfirmation
        id="insured-contacts-inactive-primary-contact-confirmation"
        open={modalConfiguration?.showInactivePrimaryContactConfirmation}
        dialogDescriptionText={
          "Primary contact cannot be inactive. Please select a new primary contact first."
        }
        onCloseEvent={(close) => {
          setModalConfiguration({
            ...modalConfiguration,
            showInactivePrimaryContactConfirmation: close,
          });
        }}
        optionYesOverrideLabel="Ok"
        onOptionYesEvent={(close) => {
          setModalConfiguration({
            ...modalConfiguration,
            showInactivePrimaryContactConfirmation: close,
          });
        }}
      />
      <DialogConfirmation
        id="insured-contacts-delete-primary-contact-confirmation"
        open={modalConfiguration?.showDeletePrimaryContactConfirmation}
        dialogDescriptionText={
          "You cannot delete the primary contact. Please select a new primary contact first."
        }
        onCloseEvent={(close) => {
          setModalConfiguration({
            ...modalConfiguration,
            showDeletePrimaryContactConfirmation: close,
          });
        }}
        optionYesOverrideLabel="Ok"
        onOptionYesEvent={(close) => {
          setModalConfiguration({
            ...modalConfiguration,
            showDeletePrimaryContactConfirmation: close,
          });
        }}
      />
    </>
  );
};

export default ModalInsuredContacts;
