import { FC, useEffect, useState } from "react";
import { InputTax } from "../../TrueUI";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
// import { InputTax } from "../..";
// import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

const InputTaxCell2: FC<BaseTableCellSelectProperties> = ({
  cellKey,
  rowKey,
  value,
  onChange,
  column,
  isEdit,
  variant,
  errorMessage,
  align,
  // rowIndex,
}) => {
  const [_value, _setValue] = useState(value ?? "");

  useEffect(() => {
    if (value !== "") {
      _setValue(value);
    }
  }, [value]);

  return (
    <InputTax
      id={`id-${column.fieldName}-${cellKey}`}
      name={`name-${column.fieldName}-${cellKey}`}
      key={cellKey}
      readOnly={!isEdit}
      value={_value.replace(/\-/g, "")}
      variant={variant}
      initialType="SSN"
      onBlur={() => {
        onChange?.({
          cellKey: cellKey,
          rowKey: rowKey,
          column: column,
          value: _value,
        });
      }}
      onChange={(e) => {
        _setValue(e?.target?.value ?? "");
      }}
      errorMessage={errorMessage}
      align={align}
    />
  );
};

export default InputTaxCell2;
