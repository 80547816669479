import { atom, atomFamily, useRecoilCallback } from "recoil";
import { PolicyStatusModalConfig } from "../PolicyChangeStatusModal/PolicyStatusUtils";

type SaveQuoteAtomProps = {
  saveAction: "continue" | "save-only" | "revise-audit";
};

export type PolicyQuoteTriggerComponentNames =
  | "policyQuoteHeaderComponent"
  | "exposurePremiumFooterComponent"
  | "exposureTableHeaderComponent"
  | "premiumTableByExposureComponent"
  | "agencyInformationTableComponent"
  | "generalInformationComponent"
  | "policyQuoteReadOnlyComponent"
  | "policyCustomFieldChangedComponent"
  | "policyNetRateChangedComponent"
  | "validationError"
  | "endorsementCheckItem"
  | "endorsementComponent"
  | "endorsementHeaderComponent"
  | "endorsementInformationComponent"
  | "endorsementExposureTableHeaderComponent"
  | "endorsementExposurePremiumFooterComponent"
  | "endorsementPolicyKeyFieldsSave"
  | "endorsementProducerChangeSave"
  | "endorsementChildTableComponent"
  | "endorsementScheduleMultiTableFooterComponent"
  | "auditCustomFieldChangedComponent"
  | "auditSectionChangedComponent"
  | "paymentScheduleTableComponent";

export const TriggerPolicyQuoteUpdateAtom = atomFamily<
  string | null,
  PolicyQuoteTriggerComponentNames
>({
  key: "TriggerPolicyQuoteUpdateAtom",
  default: null,
});

export const SaveQuoteAtom = atom<SaveQuoteAtomProps | null>({
  key: "SaveQuoteAtom",
  default: null,
});

export const AuditStatusChangedModalAtom = atom<PolicyStatusModalConfig | null>(
  {
    key: "AuditStatusChangedModalAtom",
    default: null,
  }
);

export function usePolicyQuoteTriggerComponent(): {
  setPolicyQuoteTriggers: (
    componentsKeysList: PolicyQuoteTriggerComponentNames[]
  ) => void;
  clearPolicyQuoteTriggers: (
    componentsKeysList: PolicyQuoteTriggerComponentNames[]
  ) => void;
} {
  const setPolicyQuoteTriggers = useRecoilCallback(
    ({ set }) =>
      (componentsKeysList: PolicyQuoteTriggerComponentNames[]) => {
        componentsKeysList.map((key) => {
          const newRefresh = crypto.randomUUID();
          set(TriggerPolicyQuoteUpdateAtom(key as any), newRefresh);
        });
      },
    []
  );

  const clearPolicyQuoteTriggers = useRecoilCallback(
    ({ set }) =>
      (componentsKeysList: PolicyQuoteTriggerComponentNames[]) => {
        componentsKeysList.map((key) => {
          set(TriggerPolicyQuoteUpdateAtom(key as any), null);
        });
      },
    []
  );

  return { setPolicyQuoteTriggers, clearPolicyQuoteTriggers };
}

// Premium Table Rows

export const POLICY_LIMITS_ROW_NAME = "policyLimitsRowComponent";
export const OPTIONAL_ROW_NAME = "optionalRowComponent";
export const SELECTION_ROW_NAME = "selectionRowComponent";
export const X_MOD_ROW_NAME = "xModRowComponent";
export const RATING_ROW_NAME = "ratingRowComponent";
export const ENTERED_RATE_ROW_NAME = "enteredRateRowComponent";
export const SCHEDULE_FORM_ROW_NAME = "scheduleFormRowComponent";
export const MIN_PREM_ROW_NAME = "minPremRowComponent";
export const DISCOUNT_ROW_NAME = "discountRowComponent";
export const EXPENSE_CONSTANT_ROW_NAME = "expenseConstantRowComponent";
export const REQUIRED_ROW_NAME = "requiredRowComponent";
export const LCM_ROW_NAME = "lcmRowComponent";
export const BALANCE_MIN_ROW_NAME = "balanceToMinRowComponent";
export const ENTERED_AMOUNT_ROW_NAME = "enteredAmountRowComponent";
export const SPECIFIC_WAIVER_FORM_ROW_NAME = "specificWaiverFormRowComponent";
export const AGENCY_DISCOUNT_ROW_NAME = "agencyDiscountRowComponent";
export const DISCOUNT_EDITABLE_ROW_NAME = "discountEditableRowComponent";
export const REGION_ROW_NAME = "agencyDiscountRowComponent";

export const TriggerPolicyQuotePremiumRowUpdateAtom = atomFamily<
  string | null,
  string
>({
  key: "TriggerPolicyQuotePremiumRowUpdateAtom",
  default: null,
});

export function usePolicyQuotePremiumRowTriggerComponent(): {
  setPolicyQuotePremiumRowTriggers: (componentsKeysList: string[]) => void;
} {
  const setPolicyQuotePremiumRowTriggers = useRecoilCallback(
    ({ set }) =>
      (componentsKeysList: string[]) => {
        componentsKeysList.map((key) => {
          const newRefresh = crypto.randomUUID();
          set(TriggerPolicyQuotePremiumRowUpdateAtom(key as any), newRefresh);
        });
      },
    []
  );
  return { setPolicyQuotePremiumRowTriggers };
}
