import { FC } from "react";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
import styles from "../Styles/basegrid.module.css";
import { safeClassModuleBuilder } from "../../../utilities/cssFunctions";

type BaseGridStructureDataProperties = {
  children?: any;
  cellType?: BaseTableInputType | null;
  width?: string;
  style?: React.CSSProperties;
  isSelected?: boolean;
  onClick?: (e) => void;
};

const BaseGridStructureData: FC<BaseGridStructureDataProperties> = ({
  children,
  width,
  style,
  isSelected,
  onClick,
}) => {
  return (
    <div
      onClick={(e) => {
        onClick?.(e);
      }}
      className={`${styles.true_base_grid_body_data} ${safeClassModuleBuilder([
        {
          moduleClass: styles.true_base_grid_cell_select,
          condition: isSelected ?? false,
        },
      ])}`}
      style={{
        ...style,
        width: width,
      }}
    >
      {children}
    </div>
  );
};

export default BaseGridStructureData;
