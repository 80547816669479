import { FC, useEffect, useState } from "react";
import { BaseTable2 } from "../../../TrueUI";
import {
  columnsNamesAndAddresses,
  tableNameNamesAndAddresses,
} from "./InsuredInformationTableConfigurations";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { IconButton } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { ModalAddressInformationProps } from "./ModalAcord130Address";
import { ModalNameInformationProps } from "./ModalAcord130Name";
import BaseTableGridForm from "../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import MultiTitleHeader from "../../../TrueUI/Tables/MultiTable/MultiHeader";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { PolicyInsuredNameBlob } from "../../../../dtos/policy-insured-name-blob";
import {
  getColumnsAndDataForChildrenTable,
  getGroupsForTable,
  getInsuredAddressDtoByRowData,
} from "./InsuredInformationMultiTableUtils";

export type groupsAndContent = {
  name?: PolicyInsuredNameBlob | null;
  locations?: PolicyInsuredAddressBlob[];
};

type NameNamesAndAddressesTableProps = {
  groupsAndContent: groupsAndContent[];
  setModalAddressInformation: (
    modalAddressInfo: ModalAddressInformationProps
  ) => void;
  setModalNameInformation: (modalNameInfo: ModalNameInformationProps) => void;
  editNameEvent: (metaDataObject: any) => void;
  modalAddressInformation: ModalAddressInformationProps;
};

const NameNamesAndAddressesTable: FC<NameNamesAndAddressesTableProps> = ({
  groupsAndContent,
  setModalAddressInformation,
  setModalNameInformation,
  editNameEvent,
}) => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const CustomOptionsForChildBaseTable = (actionOptions, parentId) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            setModalAddressInformation({
              rowKey: actionOptions?.rowIndex,
              parentKey: parseInt(parentId ?? "0"),
              address: getInsuredAddressDtoByRowData(
                actionOptions?.row,
                parseInt(parentId ?? "0"),
                actionOptions?.rowIndex + 1
              ),
              isOpen: true,
              isNewAddress: false,
            });
          }}
        >
          <ModeEditOutlineIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableNameNamesAndAddresses,
    tableType: "multi",
    columnsAndGroups: {
      columns: columnsNamesAndAddresses,
      groups: getGroupsForTable(groupsAndContent),
    },
    refreshTable: refreshTable,
    advancedOptions: {
      paginate: false,
      multiTableProperties: {
        MultiTablePatternComponent: (e) => {
          return (
            <BaseTableGridForm
              obj={e}
              columnsAndData={getColumnsAndDataForChildrenTable(
                parseInt(e.ParentId ?? "0"),
                groupsAndContent
              )}
              childEditComponent={(actionOptions) =>
                CustomOptionsForChildBaseTable(actionOptions, e.ParentId)
              }
            />
          );
        },
        MultiTableHeaderPatternComponent: (params, metaData) => {
          const titleParams = params ?? [];
          const isPrimaryName = metaData.ParentId === "1";
          const currentParentId = parseInt(metaData?.ParentId ?? "0");
          return (
            <MultiTitleHeader
              headerParams={titleParams}
              actions={{
                addRow: () => {
                  setModalAddressInformation({
                    parentKey: currentParentId,
                    isNewAddress: true,
                    isOpen: true,
                  });
                },
                editRow: isPrimaryName
                  ? null
                  : () =>
                      editNameEvent(
                        groupsAndContent?.[currentParentId - 1] ?? []
                      ),
              }}
            />
          );
        },
      },
      tableStyle: {
        multiTableHeaderStyle: "normal",
      },
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showPDFButton: false,
      showExcelButton: false,
      showEditButton: false,
      addButtonText: "ADD NAMED INSURED",
    },
    events: {
      addEventOverrideCallback: () => {
        setModalNameInformation({
          isNewName: true,
          isOpen: true,
        });
      },
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const hooks = tableMethods[tableNameNamesAndAddresses];

  useEffect(() => {
    if (refreshTable) {
      hooks.methods.refreshTable();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  return <BaseTable2 name={tableNameNamesAndAddresses} />;
};

export default NameNamesAndAddressesTable;
