import { Box } from "@mui/material";
import { TabPanel, Tabs } from "../../../TrueUI";
import { FC, useEffect } from "react";
import { BILLING_ACTIVITY_TABS } from "../../../../utilities/queryStringsHash";
import { BillingActivityTabsProps } from "../BillingConstants";
import { useInternalInsuredTabs } from "../../../../hooks/useInternalInsuredTabs";
import ActivityContent from "./ActivityTab/ActivityTabContent";
import InvoiceTabContent from "./InvoicesTab/InvoiceTabContent";
import BillingOptionsTabContent from "./BillingOptionsTab/BillingOptionsTabContent";
import InsuredPaymentsContainer from "./PaymentsTab/InsuredPaymentsContainer";

const tabName = BILLING_ACTIVITY_TABS;

const BillingActivityTabs: FC<BillingActivityTabsProps> = ({
  insuredId,
  tabKey,
  insuredInfo,
}) => {
  const { getTabSelected, setTabValue } = useInternalInsuredTabs(tabKey);

  const tabSelected = getTabSelected(tabName);

  const onTabClick = (e) => {
    setTabValue(e.selected, tabName);
  };

  useEffect(() => {
    setTabValue(tabSelected, tabName);
  }, []);

  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <Tabs
        name={tabName}
        selected={tabSelected}
        onSelect={onTabClick}
        stateControlled
      >
        <TabPanel title={"Activity"} key={"billingActivityTab"}>
          <ActivityContent
            insuredId={insuredId}
            tabKey={tabKey}
            hasAch={insuredInfo?.hasAch}
            hasCreditCard={insuredInfo?.hasCreditCard}
          />
        </TabPanel>
        <TabPanel title={"Invoices"} key={"billingInvoicesTab"}>
          <InvoiceTabContent insuredId={insuredId} tabKey={tabKey} />
        </TabPanel>
        <TabPanel title={"Payments"} key={"billingPaymentsTab"}>
          <InsuredPaymentsContainer
            insuredId={insuredId}
            hasAch={insuredInfo?.hasAch}
            hasCreditCard={insuredInfo?.hasCreditCard}
            tabKey={tabKey}
          />
        </TabPanel>
        <TabPanel title={"Billing Options"} key={"billingOptionsTab"}>
          <BillingOptionsTabContent insuredId={insuredId} tabKey={tabKey} />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default BillingActivityTabs;
