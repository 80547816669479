import { InsuredBillingRuleFormDataDto } from "../../../../../../dtos/insured-billing-rule-form-data-dto";
import { InsuredBillingRuleViewDto } from "../../../../../../dtos/insured-billing-rule-view-dto";
import { StatusEnums } from "../../../../../../dtos/status-enums";

export const checkReadOnly = (userPermissions, modalBillingOptionsConfig) => {
  const expirationDate = modalBillingOptionsConfig?.expirationDate ?? null;
  const isActiveByBillingRuleInfo =
    modalBillingOptionsConfig.billingRuleStatus === StatusEnums.ACTIVE &&
    (expirationDate === null ||
      expirationDate.getTime() > new Date().getTime());

  return userPermissions.hasPermission && isActiveByBillingRuleInfo
    ? false
    : true;
};

export const filterBillingRuleData = (
  attributesToOmmit: string[],
  billingRuleData: InsuredBillingRuleViewDto | null
) => {
  if (billingRuleData === null) {
    return null;
  }
  const objectWithFilteredAttributes: {
    data: Partial<InsuredBillingRuleViewDto>;
  } = { data: {} };
  Object.keys(billingRuleData).forEach((key) => {
    if (attributesToOmmit.includes(key) === false) {
      objectWithFilteredAttributes.data[key] = billingRuleData[key];
    }
  });
  return objectWithFilteredAttributes.data;
};

export const getBillingRuleFormData = (
  billingRuleData: Partial<InsuredBillingRuleViewDto> | null
) => {
  const attributesToGet = [
    "billingRuleId",
    "showDepositOnPortal",
    "preferredBillingMethod",
    "autoPayDay",
    "autoPayOn",
    "insuredAccountId",
    "excludeComments",
    "excludeFromAuditCancel",
    "excludeFromAuditInvoice",
    "excludeFromAuditOrder",
    "excludeFromCancel",
    "excludeFromInvoiceBatch",
    "excludeFromLateFee",
    "excludeFromLateNotice",
  ];
  if (billingRuleData === null) {
    return null;
  }
  const objectWithFilteredAttributes: {
    data: Partial<InsuredBillingRuleFormDataDto>;
  } = { data: {} };
  Object.keys(billingRuleData).forEach((key) => {
    if (attributesToGet.includes(key) === true) {
      objectWithFilteredAttributes.data[key] = billingRuleData[key];
    }
  });
  return objectWithFilteredAttributes.data as InsuredBillingRuleFormDataDto | null;
};

const hasObjectsEqualAttributesByListOfAttributes = (
  object1,
  object2,
  attributesList: string[]
) => {
  return (
    attributesList.filter((key) => object1[key] === object2[key]).length ===
    attributesList.length
  );
};

export const billingAndAutoPayOptionsChanged = (object1, object2) => {
  const attributesToCheck = [
    "billingRuleId",
    "showDepositOnPortal",
    "preferredBillingMethod",
    "autoPayDay",
    "autoPayOn",
    "insuredAccountId",
  ];
  return hasObjectsEqualAttributesByListOfAttributes(
    object1,
    object2,
    attributesToCheck
  )
    ? false
    : true;
};

export const processingOptionsChanged = (object1, object2) => {
  const attributesToCheck = [
    "excludeComments",
    "excludeFromAuditCancel",
    "excludeFromAuditInvoice",
    "excludeFromAuditOrder",
    "excludeFromCancel",
    "excludeFromInvoiceBatch",
    "excludeFromLateFee",
    "excludeFromLateNotice",
  ];
  return hasObjectsEqualAttributesByListOfAttributes(
    object1,
    object2,
    attributesToCheck
  )
    ? false
    : true;
};
