import { Box, IconButton } from "@mui/material";
import { Icon } from "@progress/kendo-react-common";
import { FC } from "react";
import { DRAWER_TABS } from "../../../utilities/queryStringsHash";
import TabPanel from "../../TrueUI/Tabs/TabPanel";
import Tabs from "../../TrueUI/Tabs/Tabs";
import FilesWrapper from "./Files/FilesWrapper";
import { ContextProps } from "./InsuredDrawerConstants";
import NotesWrapper from "./Notes/NotesWrapper";
import TaskWrapper from "./TasksTable/TasksWrapper";
import { insuredDrawerContentStyle } from "./InsuredDrawerStyles";

type DrawerComponentProps = {
  currentTab: number;
  context: ContextProps;
  onCloseDrawer: () => void;
  onSelectTab: (tab: number) => void;
};

const InsuredDrawerContent: FC<DrawerComponentProps> = ({
  currentTab,
  onCloseDrawer,
  context,
  onSelectTab,
}) => {
  return (
    <Box
      className={"true_drawer_content_container"}
      sx={insuredDrawerContentStyle()}
      key={context.type}
    >
      <div
        id={"insured-true_drawer_content_container"}
        className={"true_drawer_content"}
        style={{
          display: "flex",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Tabs
          name={DRAWER_TABS}
          themeOverride={"material"}
          selected={currentTab}
          onSelect={(e) => onSelectTab?.(e.selected)}
        >
          <TabPanel title={"Notes"} key={"notesTab"}>
            <NotesWrapper context={context} />
          </TabPanel>
          <TabPanel title={"Tasks"} key={"tasksTab"}>
            <TaskWrapper context={context} />
          </TabPanel>
          <TabPanel title={"Files"} key={"filesTab"}>
            <FilesWrapper context={context} />
          </TabPanel>
        </Tabs>
      </div>
      <div id="drawer-exit-icon-container">
        <IconButton onClick={onCloseDrawer}>
          <Icon className={"true_drawer_icon"} name={"x"} />
        </IconButton>
      </div>
    </Box>
  );
};

export default InsuredDrawerContent;
