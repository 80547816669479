import { useCallback, useEffect, useState } from "react";
import {
  BaseGridComputeForCellParameters,
  BaseGridConditionForCellResponse,
  CellKey,
} from "../BaseGridProperties";

import { useGridInstance } from "./useGridInstance";
import { useApiGet } from "../../../hooks";
import { createConditionForCellResponse } from "../SupportFunctions/baseGridCellFunctions";
import {
  NO_COLUMN_FIELD_NAME_FOUND_ERROR,
  NO_ROW_KEY_ERROR,
  WARN_TRIGGER_COMPUTE_OVERLOAD,
} from "../BaseGridConstants";
import {
  getCellKey,
  getColumnByFieldName,
  hydrateDataSingle,
  rehydrateGridDataRowToType,
} from "../SupportFunctions/baseGridFunctions";
import {
  BaseTableCellSelectOnChangeProperties,
  ConditionForCellResponse,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  getColumnByColumnIndex,
  hydrateAndCastDataSingle,
} from "../SupportFunctions/OLD_baseGridFunctions";
import { useRecoilValue } from "recoil";
import { BaseGridColumnOptionsProperties } from "../BaseGridProperties";

const useComputeProcessor_LEGACY = (
  uiid: string,
  rowKey: string,
  row: string[],
  listOfComputeFunctions: BaseGridColumnOptionsProperties<any>[],
  computeType: "computeOnInit" | "computeOnChange"
): {
  processComputes: (cellKey: CellKey, columnIndex: number) => void;
  isComplete: boolean;
  outRow: string[];
  resetIsCompleteStatus: () => void;
} => {
  const { instanceSelector, instanceInternalSelector } = useGridInstance(
    uiid,
    "BaseCellComputeProcessor"
  );
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const [initAPIComputes, _setInitAPIComputes] = useState<
    { columnIndex: number; computeAPIURL: string }[]
  >([]);

  const [inProcessRow, _setInProcessRow] = useState<string[]>([]);

  const { responseGet, dispatchGet } = useApiGet<any>(
    null ?? "NO_URL_PROVIDED_ERROR"
  );

  const filteredComputes = (listOfComputeFunctions ?? []).map((c) => {
    const column = getColumnByFieldName(c.fieldName, instance?.columns ?? []);
    const cellKey = getCellKey(rowKey, column?._columnIndex ?? -1);
    if (computeType === c.computeOnInit?.name) {
      return {
        cellKey: cellKey,
        fieldName: c.fieldName,
        columnIndex: column?._columnIndex,
        fn: c.computeOnInit,
      };
    } else {
      return {
        cellKey: cellKey,
        fieldName: c.fieldName,
        columnIndex: column?._columnIndex,
        fn: c.computeOnChange,
      };
    }
  });

  const getApiDataByHiddenColumnStatus = (
    changedCell: BaseTableCellSelectOnChangeProperties,
    isComputeForHiddenColumn: boolean
  ) => {
    const resolvedAPIData = instanceInternal?.computeAPIData.find(
      (c) =>
        c.rowKey === changedCell.rowKey &&
        c.targetFieldNames.includes(
          isComputeForHiddenColumn
            ? changedCell.computeTargetName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
            : changedCell?.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
        )
    );

    return {
      data: resolvedAPIData?.data ?? null,
      errorMessage: resolvedAPIData?.errorResponse?.["title"] ?? null,
      errorResponse: resolvedAPIData?.errorResponse,
    };
  };

  const hydratedRow = hydrateDataSingle(instance?.columns ?? [], row);

  const hydratedAndCastedRow = hydrateAndCastDataSingle(
    instance.columns ?? [],
    row
  );

  const processCellComputes = (
    _triggeringCellKey: CellKey,
    tiggeringColumnIndex: number,
    changedCell: BaseTableCellSelectOnChangeProperties,
    hydratedRow: any,
    castedRow: any,
    isComputeForHiddenColumn: boolean
  ): BaseGridConditionForCellResponse[] => {
    const lastColumnFieldComputed = getColumnByColumnIndex(
      tiggeringColumnIndex,
      instance?.columns ?? []
    );

    const getApiDataByDataNameAndHiddenColumnStatus = (dataName: string) => {
      const resolvedAPIData = instanceInternal?.computeAPIData.find(
        (c) =>
          c.rowKey === changedCell.rowKey &&
          c.targetFieldNames.includes(
            isComputeForHiddenColumn
              ? changedCell.computeTargetName ??
                  NO_COLUMN_FIELD_NAME_FOUND_ERROR
              : changedCell?.column?.fieldName ??
                  NO_COLUMN_FIELD_NAME_FOUND_ERROR
          ) &&
          c.getDataName === dataName
      );

      return {
        data: resolvedAPIData?.data ?? null,
        errorMessage: resolvedAPIData?.errorResponse?.["title"] ?? null,
        errorResponse: resolvedAPIData?.errorResponse,
      };
    };

    const processedComputes =
      filteredComputes
        ?.map((cellCompute) => {
          const currentValue = row[cellCompute?.columnIndex ?? -1] ?? "";
          const computedValue =
            (cellCompute?.fn?.({
              rowKey: rowKey,
              cellKey: cellCompute.cellKey,
              currentValue: currentValue,
              // changedCell.value ??
              // tryCurrentValue(changedCell.computeTargetIndex ?? -1),
              row: hydratedRow,
              castedRow: castedRow,
              originalRow: rehydrateGridDataRowToType(
                instance?.columns ?? [],
                row
              ),
              currentApiData: getApiDataByHiddenColumnStatus(
                changedCell,
                isComputeForHiddenColumn
              ),
              getApiDataByDataName: getApiDataByDataNameAndHiddenColumnStatus,
              computeTriggerSource:
                lastColumnFieldComputed?.fieldName ?? "NO_FIELD_COLUMN",
              responseType: null,
            } as BaseGridComputeForCellParameters<any>) as ConditionForCellResponse) ??
            null;

          if (computedValue === undefined || computedValue === null) {
            // this only excutes when compute functions are void
            return null;
          }

          // const computeValueCellKey = changedCell.cellKey ?? NO_CELL_KEY_ERROR;

          if (
            (computedValue?.triggerComputeFieldNames?.length ?? 0) > 0 &&
            (computedValue?.apiOptions?.triggerComputes?.length ?? 0) > 0
          ) {
            console.warn(WARN_TRIGGER_COMPUTE_OVERLOAD(cellCompute?.fieldName));
          }

          const safeAPIOptions = () => {
            if (computedValue?.apiOptions?.getURL !== undefined) {
              return computedValue?.apiOptions;
            }
            return null;
          };

          const getAllTriggeredComputes = (): string[] => [
            ...new Set(
              (computedValue?.triggerComputeFieldNames ?? []).concat(
                computedValue.apiOptions?.triggerComputes ?? []
              ) ?? []
            ),
          ];

          return createConditionForCellResponse({
            cellKey: cellCompute.cellKey,
            rowKey: rowKey ?? NO_ROW_KEY_ERROR,
            value: computedValue?.value?.toString() ?? changedCell.value,
            isEdit: false, //tableInstance?.isEdit ?? false,
            typeId: computedValue.typeId ?? null,
            variant: "standard",
            targetFieldName:
              computedValue?.targetFieldName ??
              cellCompute?.fieldName ??
              changedCell.column?.fieldName ??
              "",
            triggerComputes: getAllTriggeredComputes(),
            requestingColumn: null,
            responseType: "cell_compute",
            apiRequestOptions: safeAPIOptions(),
            listDisplayOptions: computedValue.listDisplayOptions ?? null,
            isColumnsDisabled: computedValue.isColumnsDisabled ?? null,
            refreshCell: computedValue.refreshCell ?? false,
          });
        })
        .filter((c) => c !== null) ?? [];
    return (processedComputes ?? []) as BaseGridConditionForCellResponse[];
  };

  const [computes, setComputes] = useState<any[]>([]);

  const processComputes = () => {
    const x = processCellComputes(
      "PLACEHOLDER_KEY_UNTIL_WE_CAN_REFACTOR",
      -1,
      {} as any,
      hydratedRow,
      hydratedAndCastedRow,
      false
    );
    setComputes(x);
  };

  useEffect(() => {
    if (computes.length > 0) {
      setIsComplete(true);
    }
  }, [computes]);

  // TODO - the below code should be part of the new useComputeProcessor process

  // const processComputes = useCallback(
  //   (cellKey: CellKey, columnIndex: number) => {

  //     const processingRow = [...row];
  //     const foundAPIs: { columnIndex: number; computeAPIURL: string }[] = [];
  //     // console.log("before - ", { processingRow });
  //     for (let i = 0; i < listOfComputeFunctions.length; i++) {
  //       const compute = listOfComputeFunctions[i];
  //       console.log("processComputes", { listOfComputeFunctions, compute });

  //       // for (let ic = 0; ic < compute.fns.length; ic++) {
  //       const fn = compute.fn;
  //       console.log({ fn });
  //       if (fn !== undefined) {
  //         const result = fn({
  //           value: processingRow[compute.columnIndex],
  //           row: processingRow,
  //         }) as BaseGridConditionForCellResponse;
  //         // console.log("yyyyyy - ", { result });
  //         if (
  //           result !== null &&
  //           result !== undefined &&
  //           result.apiOptions !== null &&
  //           result.apiOptions !== undefined &&
  //           result.apiOptions.getURL !== null &&
  //           result.apiOptions.getURL !== undefined
  //         ) {
  //           // setContainsUnprocessedAPIRequest(true);
  //           // console.count("gottem");
  //           foundAPIs.push({
  //             columnIndex: compute.columnIndex,
  //             computeAPIURL: result.apiOptions.getURL,
  //           });
  //           console.log("processComputes completed", listOfComputeFunctions);
  //           setInternalInstance({
  //             ...internalInstance,
  //             inProcessComputeQueue: [
  //               ...(internalInstance?.inProcessComputeQueue ?? []),
  //               {
  //                 rowKey: rowKey,
  //                 cellKey: "TODO - add the cellKey here",
  //                 computeTargetName: null,
  //                 computeTargetIndex: null,
  //               },
  //             ],
  //           });
  //         }
  //       }
  //       // }
  //     }
  //     setInitAPIComputes(foundAPIs);
  //   },
  //   []
  // );

  useEffect(() => {
    if (initAPIComputes.length > 0) {
      const [next] = initAPIComputes;
      dispatchGet(next.computeAPIURL);
    }
  }, [initAPIComputes]);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful) {
      setIsComplete(true);
    }
  }, [responseGet]);

  const resetIsCompleteStatus = useCallback(() => {
    setIsComplete(false);
  }, []);

  return {
    processComputes,
    isComplete,
    outRow: inProcessRow,
    resetIsCompleteStatus,
  };
};

export { useComputeProcessor_LEGACY as useComputeProcessor };
