import { DataTypeEnums } from "../../../dtos/data-type-enums";

export const otherTypes = [
  DataTypeEnums.STRING,
  DataTypeEnums.NULLABLE_STRING,
  DataTypeEnums.CHAR,
  DataTypeEnums.NULLABLE_CHAR,
  DataTypeEnums.BOOLEAN,
  DataTypeEnums.NULLABLE_BOOLEAN,
  DataTypeEnums.OBJECT,
  DataTypeEnums.NULLABLE_OBJECT,
  DataTypeEnums.DYNAMIC,
  DataTypeEnums.NULLABLE_DYNAMIC,
];

export const numericTypes = [
  DataTypeEnums.INT,
  DataTypeEnums.NULLABLE_INT,
  DataTypeEnums.DECIMAL,
  DataTypeEnums.NULLABLE_DECIMAL,
  DataTypeEnums.FLOAT,
  DataTypeEnums.NULLABLE_FLOAT,
  DataTypeEnums.LONG,
  DataTypeEnums.NULLABLE_LONG,
  DataTypeEnums.DOUBLE,
  DataTypeEnums.NULLABLE_DOUBLE,
  DataTypeEnums.BYTE,
  DataTypeEnums.NULLABLE_BYTE,
  DataTypeEnums.SHORT,
  DataTypeEnums.NULLABLE_SHORT,
];

export const dateTypes = [
  DataTypeEnums.DATE,
  DataTypeEnums.NULLABLE_DATE,
  DataTypeEnums.DATETIME,
  DataTypeEnums.NULLABLE_DATETIME,
  DataTypeEnums.DATETIME2,
  DataTypeEnums.NULLABLE_DATETIME2,
  DataTypeEnums.TIMESPAN,
  DataTypeEnums.NULLABLE_TIMESPAN,
];
