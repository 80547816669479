import { AddPaymentBatchDto } from "../../../../../dtos/add-payment-batch-dto";

export type ModalAddBatchProps = {
  setOpenModal: (open: boolean) => void;
  openModal: boolean;
};
export const modalProps = {
  id: "modal-add-payment-batch",
  title: "Add Batch & Process Payments",
  deleteEvent: undefined,
  showCloseButton: true,
  showCancelTextButton: true,
  saveOverrideLabel: "PROCESS",
};

export const defaultAddBatchObject: AddPaymentBatchDto = {
  paymentMethod: "",
  referenceNo: null,
};
