import moment from "moment";
import { PayrollReportDataDto } from "../../../../dtos/payroll-report-data-dto";
import { InsertedPayrollReport } from "../../../../dtos/inserted-payroll-report";

export type PayrollReportProps = {
  isOpen: boolean;
  action: "add";
};

export const defaultPayrollReport: PayrollReportProps = {
  isOpen: false,
  action: "add",
};

export type PayrollReportModalProps = {
  insuredId: number;
  payrollReportModal: PayrollReportProps;
  setPayrollReportModal: (value: PayrollReportProps) => void;
  updateRowIntoTable: (
    action: "add",
    value: InsertedPayrollReport | null
  ) => void;

};

export type PayrollReportModalContentProps = {
  insuredId: number;
  payrollReportData?: PayrollReportDataDto | null;
  setPayrollReportData: (value: PayrollReportDataDto) => void;
  errorMessages?: any;
};

export const getDefaultFromDateAndToDateValues = (policyDateList, policyId: number) => {
  
  const currentDate = moment(new Date());
  const firstDayOfNextMonth = currentDate.clone().add(1, 'months').startOf('month').format("M/D/YYYY");
  const lastDayOfNextMonth = currentDate.clone().add(1, 'months').endOf('month').format("M/D/YYYY");
  const defaultFromDate = new Date(firstDayOfNextMonth);
  const defaultToDate = new Date(lastDayOfNextMonth);

  const policyDates = policyDateList.find(x => x.policyId === policyId);
  const effectiveDate = new Date(policyDates?.effectiveDate ?? new Date());
  const expirationDate = new Date(policyDates?.expirationDate ?? new Date());
  
  const fromDate = defaultFromDate < effectiveDate ? effectiveDate: defaultFromDate;
  const toDate = defaultToDate > expirationDate ? expirationDate: defaultToDate;

  return {
    fromDate, toDate
  }
};
