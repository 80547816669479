import {
  // ElementType,
  FC,
  useEffect,
  useState,
} from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { filterComputesOnInitByName, getSafeRowKey } from "../tableFunctions";
import BaseTable2CellManager from "./BaseTable2CellManager";

import RowRenderType from "./RowRenderType";
import { TableInstanceType2 } from "./TableProperties";
// import Collapse from "../../Collapse/Collapse";
// import { TableHead, TableRow } from "@mui/material";
// import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { TableBody } from "../BaseTableStructure";
// import MultiCollapse from "../MultiTable/MultiCollapse";
import MultiCollapse from "../MultiTable/MultiCollapse";
import { MultiBaseTableGroupColumn } from "../../../../dtos/multi-base-table-group-column";

type BaseTable2Body_RESTRUCTUREProperties = {
  uiid: string;
};

export type BaseTableCellSelectProperties_TEMP = {
  uiid: string;
  rowKey: string;
  row: string[];
  rowIndex: number;
};

type RenderInstanceObject = {
  rowKey: string;
  rowIndex: number;
  renderedInstance: any;
};

const BaseTable2Body: FC<BaseTable2Body_RESTRUCTUREProperties> = ({ uiid }) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const [previousOrder, setPreviousOrder] = useState({
    order: tableInstance.order ?? "asc",
    orderBy: tableInstance.orderBy ?? null,
  });

  const [preloadedInstances, setPreloadedInstance] = useState<
    RenderInstanceObject[]
  >([]);
  const [bodyContent, setBodyContent] = useState<any[]>([]);

  const createCellManager = (row, rowIndex, rowKey) => {
    const config: BaseTableCellSelectProperties_TEMP = {
      uiid: tableInstance?.uiid ?? "",
      rowKey: rowKey,
      row: row,
      rowIndex: rowIndex,
    };
    return <BaseTable2CellManager key={rowKey} {...config} />;
  };

  const createCells = (row, rowIndex) => {
    if (tableInstance?.tableType === "multi") {
      // return row[1].map((r, i) => {
      //   const innerRowKey = getSafeRowKey(r);
      //   return createCellManager(r, i, innerRowKey);
      // });
    }
    const rowKey = getSafeRowKey(row);
    return createCellManager(row, rowIndex, rowKey);
  };

  const getNewRow = () => {
    if (preloadedInstances.length === 0) {
      return null;
    }
    const [foundRow] =
      tableInstance.sortedAndFilteredData.filter(
        (r) =>
          !preloadedInstances.map((i) => i.rowKey).includes(getSafeRowKey(r))
      ) ?? null;

    if (foundRow) {
      const foundIndex = tableInstance.sortedAndFilteredData.findIndex(
        (r) => getSafeRowKey(r) === getSafeRowKey(foundRow)
      );
      const rowKey = getSafeRowKey(foundRow);

      const renderedInstance = createCellManager(foundRow, foundIndex, rowKey);
      const updatedPreloadedInstances = [
        {
          rowKey: rowKey,
          rowIndex: foundIndex,
          renderedInstance: renderedInstance,
        },
        ...preloadedInstances,
      ];
      /* Note: This might look strange because we're returning the updated instance(s) in 
      addition to setting their state with the same return object. We do this because doing the 
      `renderedCellAndRowManagers` cycle, the `preloadedInstances` state would be scoped locked 
      during the execution and won't have the most reset set state. */
      setPreloadedInstance(updatedPreloadedInstances);
      return updatedPreloadedInstances;
    }
    return null;
  };

  const renderedCellAndRowManagers = () => {
    const hasNewCellManagerInstance = getNewRow();

    const rowsToRender = tableInstance.sortedAndFilteredData.map((row) => {
      const rowKey = getSafeRowKey(row);
      const reactRenderedInstance = (
        hasNewCellManagerInstance ?? preloadedInstances
      ).find((i) => i.rowKey === rowKey);

      if (!conditionHasValue(reactRenderedInstance?.renderedInstance)) {
        return null;
      }

      return (
        <RowRenderType
          key={crypto.randomUUID()}
          uiid={uiid}
          cellManagerInstance={reactRenderedInstance?.renderedInstance}
        />
      );
    });

    setBodyContent(rowsToRender);
  };

  const sortRenderedContent = (content: any[]) => {
    const reorganizedKeys =
      tableInstance.sortedAndFilteredData.map((r) => getSafeRowKey(r)) ?? [];
    const resortedContent = content.slice().sort(function (a, b) {
      return (
        reorganizedKeys.indexOf(a.props.cellManagerInstance.key) -
        reorganizedKeys.indexOf(b.props.cellManagerInstance.key)
      );
    });
    return resortedContent;
  };

  const renderSingularInstance = () => {
    const [row] = tableInstance.sortedAndFilteredData;

    setPreloadedInstance([
      {
        rowKey: getSafeRowKey(row),
        rowIndex: 0,
        renderedInstance: createCells(row, 0),
      },
    ]);
  };

  useEffect(() => {
    if (
      tableInstance.isPreProcessingComplete &&
      tableInstance.data.length > 0
    ) {
      const instances = tableInstance.data.map((row, index) => {
        return {
          rowKey: getSafeRowKey(row),
          rowIndex: index,
          renderedInstance: createCells(row, index),
        };
      });

      setPreloadedInstance(instances);
    }
  }, [tableInstance.isPreProcessingComplete]);

  useEffect(() => {
    if (
      tableInstance.sortedAndFilteredData.length > 0 &&
      (tableInstance.order !== previousOrder.order ||
        tableInstance.orderBy !== previousOrder.orderBy)
    ) {
      const content = sortRenderedContent(bodyContent);

      setBodyContent(content);
      setPreviousOrder({
        order: tableInstance.order,
        orderBy: tableInstance.orderBy,
      });
    }
  }, [
    tableInstance.sortedAndFilteredData,
    tableInstance.order,
    tableInstance.orderBy,
  ]);

  useEffect(() => {
    if (
      tableInstance.sortedAndFilteredData.length > 0 &&
      bodyContent.length === 0 &&
      preloadedInstances.length > 0 &&
      tableInstance._recentlyUpdatedData.length === 0
    ) {
      renderedCellAndRowManagers();
    }

    if (
      tableInstance.sortedAndFilteredData.length > 0 &&
      bodyContent.length > 0 &&
      tableInstance._inProcessComputesQueue.length === 0 &&
      tableInstance._recentlyUpdatedData.length === 0
    ) {
      renderedCellAndRowManagers();
    }
  }, [tableInstance.sortedAndFilteredData, preloadedInstances]);

  useEffect(() => {
    if (
      tableInstance.isPreProcessingComplete &&
      tableInstance.sortedAndFilteredData.length > 0 &&
      preloadedInstances.length === 0
    ) {
      renderSingularInstance();
    }
  }, [tableInstance.sortedAndFilteredData]);

  useEffect(() => {
    if (tableInstance.sortedAndFilteredData.length === 0) {
      setBodyContent([]);
    }
  }, [tableInstance.sortedAndFilteredData]);

  useEffect(() => {
    if (tableInstance.groups.length > 0) {
      const x = tableInstance.groups.map((group: MultiBaseTableGroupColumn) => {
        return (
          <MultiCollapse
            key={crypto.randomUUID()}
            parentUiid={uiid}
            // uiid={`temp_uiid_${crypto.randomUUID()}`}
            tableName={group.groupUiidKey ?? "NO_GROUP_KEY_FOUND"}
            // isOpen={isOpen}
            header={group.header}
            component={
              tableInstance?.advancedOptions?.multiTableProperties
                ?.MultiTablePatternComponent ?? null
            }
            content={group}
          />
        );
      });
      setBodyContent(x);
    }
  }, [tableInstance.groups]);

  useEffect(() => {
    if (bodyContent.length > 0) {
      /* Review if there is a compute on init implemented and set the process on init compute queue */
      const hasOnInitCompute = tableInstance.columns.find((column) => {
        return (
          filterComputesOnInitByName(
            "conditionForCell",
            column.fieldName ?? "NO_FIELD_NAME_FOUND",
            tableInstance.columnOptions ?? []
          ).length === 1
        );
      });

      if (hasOnInitCompute) {
        const rowKeysOnly = tableInstance.data.map((row) => {
          return { rowKey: getSafeRowKey(row) };
        });
        setTableInstance({
          ...(tableInstance as TableInstanceType2),
          _inProcessOnInitComputesQueue: rowKeysOnly,
        });
      }
    }
  }, [bodyContent]);

  return (
    <TableBody>
      {bodyContent.length > 0 ? (
        bodyContent
      ) : (
        <div className={"true_table_no_results_found"}>
          {tableInstance.advancedOptions?.noResultsOverrideMessage ??
            "No results found."}
        </div>
      )}
    </TableBody>
  );
};

export default BaseTable2Body;
