import { FC } from "react";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { getClaimDetailsTabURL } from "../../../../utilities/queryStringsHashFunctions";
import Link from "../../../TrueUI/Typography/Link";
import { ClaimDetailsTabEnums } from "../../../../dtos/claim-details-tab-enums";
import { Box } from "@mui/material";
import { claimLandingTableStyles } from "./ClaimLandingStyles";
import { useNavigate } from "react-router";

const tableName = "claim_landing_contact_table";

type ClaimLandingContactTableProps = {
  claimNumber?: string;
  insuredId: number;
};

const ClaimLandingContactTable: FC<ClaimLandingContactTableProps> = ({
  claimNumber,
  insuredId,
}) => {
  const navigate = useNavigate();
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/claims/GetClaimContactTableData?ClaimNumber=${claimNumber}`,
    columnOptions: [
      { fieldName: "FullName", width: 22 },
      { fieldName: "Company", width: 20 },
      { fieldName: "EmailAddress", width: 27 },
      { fieldName: "Phone1", width: 15 },
      { fieldName: "ContactType", width: 20 },
    ],
    advancedOptions: {
      tableStyle: {
        headerStyle: "transparent",
        height: "auto",
      },
      paginate: false,
      disableOrderBy: true,
    },
    toolbarOptions: { hideToolbar: true },
  };
  useBaseTable(tableConfiguration);

  return (
    <InformationContainer
      title={
        <Link
          displayValue={"Contacts"}
          linkFontType={"TITLE"}
          name="claimContactsTitle"
          onClick={() =>
            navigate(
              `/insured/${insuredId}${getClaimDetailsTabURL(
                ClaimDetailsTabEnums.CONTACTS,
                claimNumber
              )}`
            )
          }
        />
      }
    >
      <Box sx={claimLandingTableStyles()}>
        <BaseTable2 name={tableName} />
      </Box>
    </InformationContainer>
  );
};

export default ClaimLandingContactTable;
