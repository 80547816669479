import { Box } from "@mui/material";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Input } from "../../../TrueUI";
import { ColumnOptionsProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { PayrollReportDetailRowDto } from "../../../../dtos/payroll-report-detail-row-dto";
import { formatToCurrency } from "../../../../utilities/stringFunctions";
import { sumInt, sumFloat } from "../../../../utilities/arrayFunctions";

export const columnOptionsProps: ColumnOptionsProperties<any>[] = [
  { fieldName: "PayrollReportId", width: 0 },
  { fieldName: "PayrollReportDetailId", width: 0 },
  { fieldName: "NameId", width: 0 },
  { fieldName: "State", width: 5 },
  { fieldName: "ClassCode", width: 5 },
  { fieldName: "Description", width: 40 },
  { fieldName: "NumEE", width: 10, align: "right" },
  { fieldName: "NetPayroll", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "BaseRate", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "ModifiedRate", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "Amount", width: 10, align: "right", decimalScale: 2 },
];

export const getColumnWidth = (columns, column) => {
  const selected = columnOptionsProps?.find((c) => c?.fieldName === column);
  if (conditionHasValue(selected)) {
    return `${selected?.width}%`;
  }
  return `${100 / (columns?.length ?? 1)}%`;
};

export const getCellValueRowOne = (
  column,
  totalEmployees,
  totalPayroll,
  totalAmount
) => {
  switch (column) {
    case "Description":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"total"}
            name={"total"}
            value={"Totals"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </Box>
      );
    case "NumEE":
      return (
        <Box sx={{ width: "100%", paddingRight: "24px" }}>
          <Input
            type={"number"}
            id={"total-employees"}
            name={"total-employees"}
            value={totalEmployees}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
            key={totalEmployees}
          />
        </Box>
      );
    case "NetPayroll":
      return (
        <Box sx={{ width: "100%", paddingRight: "20px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-payroll"}
            name={"total-payroll"}
            value={totalPayroll}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalPayroll}
          />
        </Box>
      );
    case "Amount":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-amount"}
            name={"total-amount"}
            value={totalAmount}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalAmount}
          />
        </Box>
      );
    default:
      return null;
  }
};

export const getCellValueRowTwo = (column, totalPaid) => {
  switch (column) {
    case "ModifiedRate":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"paid"}
            name={"paid"}
            value={"Paid"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </Box>
      );
    case "Amount":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-paid"}
            name={"total-paid"}
            value={totalPaid}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalPaid}
          />
        </Box>
      );
    default:
      return "";
  }
};

export const getCellValueRowThree = (column, totalAmount, totalPaid) => {
  switch (column) {
    case "ModifiedRate":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"balance"}
            name={"balance"}
            value={"Balance"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </Box>
      );
    case "Amount":
      return (
        <Box sx={{ width: "100%", paddingRight: "12px" }}>
          <Input
            id={"balance"}
            name={"balance"}
            value={
              totalAmount - totalPaid < 0
                ? `(${formatToCurrency(Math.abs(totalAmount - totalPaid))})`
                : `${formatToCurrency(totalAmount - totalPaid)}`
            }
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalAmount - totalPaid}
          />
        </Box>
      );
    default:
      return "";
  }
};

const updateReferenceObjectByKeyValue = (referenceObject: any, key: string) =>
  Object.values(referenceObject).reduce(
    (accumulator: number, obj: any) => accumulator + obj?.[key],
    0
  );

export const getUpdatedTotals = (
  data: PayrollReportDetailRowDto[],
  changedData: PayrollReportDetailRowDto[],
  rows: any
) => {
  if (changedData.length > 0) {
    // Step 1: Create a reference object from the initial data array
    const referenceObject = data.reduce((obj, item) => {
      obj[item.payrollReportDetailId] = item;
      return obj;
    }, {});
    // Step 2: Iterate through changedData and update the values in the reference object
    changedData.forEach((changedItem) => {
      const { payrollReportDetailId, numEE, netPayroll, amount } = changedItem;
      if (referenceObject[payrollReportDetailId] !== undefined) {
        referenceObject[payrollReportDetailId].numEE = numEE;
        referenceObject[payrollReportDetailId].netPayroll = netPayroll;
        referenceObject[payrollReportDetailId].amount = amount;
      }
    });

    // Step 3: Calculate the updated sum of the 'value' property from the reference object
    const updatedNumEE = updateReferenceObjectByKeyValue(
      referenceObject,
      "numEE"
    );

    const updatedNetPayroll = updateReferenceObjectByKeyValue(
      referenceObject,
      "netPayroll"
    );

    const updatedAmount = updateReferenceObjectByKeyValue(
      referenceObject,
      "amount"
    );
    return {
      totalEmployees: updatedNumEE,
      totalPayroll: updatedNetPayroll,
      totalAmount: updatedAmount,
    };
  } else {
    return {
      totalEmployees: sumInt(
        rows?.map((row) => row?.Metadata?.TotalEmployees ?? 0)
      ),
      totalPayroll: sumFloat(
        rows?.map((row) => row?.Metadata?.TotalPayroll ?? 0)
      ),
      totalAmount: sumFloat(
        rows?.map((row) => row?.Metadata?.TotalAmount ?? 0)
      ),
    };
  }
};

export const getUpdatedTotalsDetail = (rows: any[]) => {
  return {
    totalEmployees: sumInt(rows?.map((row) => row?.NumEE ?? 0)),
    totalPayroll: sumFloat(rows?.map((row) => row?.NetPayroll ?? 0)),
    totalAmount: sumFloat(rows?.map((row) => row?.Amount ?? 0)),
  };
};
