import { isArrayEqual } from "./arrayFunctions";
import { conditionHasValue } from "./conditionalSupportFunctions";
import { FormattingDate } from "./dateFunctions";

export const toLowerCaseKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.charAt(0).toLowerCase() + key.substring(1)] = obj[key];
    return acc;
  }, {});
export const toUpperCaseKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.charAt(0).toUpperCase() + key.substring(1)] = obj[key];
    return acc;
  }, {});
export const areObjectsEqual = (originalObject: any, compareObject: any) => {
  if (originalObject !== null && compareObject !== null) {
    if (JSON.stringify(originalObject) === JSON.stringify(compareObject)) {
      return true;
    }
  }
  return false;
};

export const replaceEmptyStringWithNullForPost = (
  obj: any,
  propNamesToKeepEmptyString?: string[]
) => {
  const objectWithNullInsteadOfEmptyString = JSON.parse(
    JSON.stringify(obj, function (key, value) {
      return value === "" && !propNamesToKeepEmptyString?.includes(key)
        ? null
        : value;
    })
  );
  return objectWithNullInsteadOfEmptyString;
};

export const mergeObjectsAttributes = (referenceObject, valuesObject) => {
  const newObjectToReturn = {};
  const referenceKeys = Object.keys(referenceObject);
  referenceKeys?.forEach((key) => {
    if (valuesObject[key] !== undefined) {
      newObjectToReturn[key] = valuesObject[key];
    } else {
      newObjectToReturn[key] = referenceObject[key];
    }
  });

  return newObjectToReturn;
};

export const mergeObjectsWithMatchingAttributes = (
  referenceObject,
  valuesObject
) => {
  const newObjectToReturn = {};
  const referenceKeys = Object.keys(referenceObject);
  referenceKeys?.forEach((key) => {
    if (valuesObject[key] !== undefined) {
      newObjectToReturn[key] = valuesObject[key];
    }
  });

  return newObjectToReturn;
};

export const castObjectValuesToString = (postResponseDto) => {
  Object.keys(postResponseDto).forEach((key) => {
    switch (typeof postResponseDto[key]) {
      case "number":
        return (postResponseDto[key] = postResponseDto[key].toString());
      default:
        if (postResponseDto[key] instanceof Date) {
          return (postResponseDto[key] = FormattingDate(postResponseDto[key]));
        }
        return ""; // Needs review, flawed on inception.
    }
  });
  return postResponseDto;
};

export const getObjectWithPropertiesFiltered = (
  originalObject,
  stringToMatch: string,
  notInclude?: boolean
) => {
  return Object.fromEntries(
    Object.entries(originalObject).filter(([key]) =>
      notInclude ? !key.includes(stringToMatch) : key.includes(stringToMatch)
    )
  );
};

const normalizeKeys = (obj) => {
  const normalizedObj = {};
  for (const key in obj) {
    normalizedObj[key.toLowerCase()] = obj[key];
  }
  return normalizedObj;
};

export const isEqualValue = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return isArrayEqual(value1, value2);
  } else if (
    typeof value1 === "object" &&
    value1 !== null &&
    typeof value2 === "object" &&
    value2 !== null
  ) {
    return areObjectsEqual(value1, value2);
  } else {
    return value1 === value2;
  }
};

export const areObjectsEqualByValues = (
  obj1,
  obj2,
  objectKeysToOmmit: string[] = []
) => {
  const normalizedObj1 = normalizeKeys(obj1);
  const normalizedObj2 = normalizeKeys(obj2);

  const keys1 = Object.keys(normalizedObj1);
  const keys2 = Object.keys(normalizedObj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (objectKeysToOmmit.includes(key) === false) {
      if (normalizedObj2.hasOwnProperty(key) === false) {
        return false;
      }
      if (isEqualValue(normalizedObj1[key], normalizedObj2[key]) === false) {
        return false;
      }
    }
  }

  return true;
};

export const removeObjectProperty = (obj, propertyToRemove) => {
  const { [propertyToRemove]: removedProperty, ...rest } = obj;
  return rest;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const areValuesOfObjectEmptyNullOrUndefined = (obj) => {
  return Object.values(obj).every((val) =>
    val !== "" ? !conditionHasValue(val) : true
  );
};

// Remove all falsy values ( "", 0, false, null, undefined )
export const removeFalsyValues = (obj) =>
  Object.entries(obj).reduce(
    (accumulator, [key, value]) =>
      value ? ((accumulator[key] = value), accumulator) : accumulator,
    {}
  );

export const getObjectWithUpperCaseKeysAndFallbackNullValues = (obj) => {
  if (Array.isArray(obj)) {
    if (obj.length === 0) return null;
    return obj.map(getObjectWithUpperCaseKeysAndFallbackNullValues);
  } else if (obj instanceof Date && obj !== null && obj !== undefined) {
    return obj.toISOString();
  } else if (typeof obj === "object" && obj !== null && obj !== undefined) {
    return Object.keys(obj).reduce((acc, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.substring(1);
      acc[capitalizedKey] = getObjectWithUpperCaseKeysAndFallbackNullValues(
        obj[key] ?? null
      );
      return acc;
    }, {});
  }
  return obj;
};
