import { FC, useEffect, useState } from "react";
import { Font } from "../../../../../TrueUI";
import { ReserveItemDto } from "../../../../../../dtos/reserve-item-dto";
import { parseFloatValueIfExist } from "../../../../../../utilities/stringFunctions";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../../TrueUI/Tables/tableFunctions";
import { BaseTableColumn } from "../../../../../../dtos/base-table-column";

type ReserveGridFooterProps = {
  reservesData: Partial<ReserveItemDto>[];
  tableData: string[][];
  clearMessages: boolean;
  tableColumns: BaseTableColumn[];
  setClearMessages: (clear: boolean) => void;
};

const ReserveGridFooter: FC<ReserveGridFooterProps> = ({
  reservesData,
  tableData,
  tableColumns,
  clearMessages,
  setClearMessages,
}) => {
  const [errorMessages, setErrorMessages] = useState<string[]>();

  const getColumnIndex = (fieldName) => {
    return getDataIndexByColumnNameWithoutExternal(tableColumns, fieldName);
  };
  const reserveTypeIdIndex = getColumnIndex("ReserveTypeId");
  const reserveTypeNameIndex = getColumnIndex("ReserveType");
  const reserveRuleLimitIndex = getColumnIndex("ReservesRuleLimit");
  const totalReservesRuleLimitIndex = getColumnIndex("TotalReserveRuleLimit");
  const totalReservesAmountIndex = getColumnIndex("TotalReservesAmount");

  const getReserveTypeErrorMessages = () => {
    return tableData
      ?.map((row) => {
        const reserveTypeId = parseInt(row[reserveTypeIdIndex ?? 0] ?? 0);
        const reserveTypeName = row[reserveTypeNameIndex ?? 0] ?? 0;
        const reserveAmount =
          reservesData?.find((reserve) => reserve.typeId === reserveTypeId)
            ?.amount ?? 0;
        const reserveRuleLimit =
          parseFloatValueIfExist(row[reserveRuleLimitIndex ?? 0] ?? 0) ?? 0;
        if (
          reserveRuleLimit > 0 &&
          reserveAmount > 0 &&
          reserveAmount > reserveRuleLimit
        ) {
          return `Reserve type ${reserveTypeName} amount exceeded`;
        } else return "";
      })
      ?.filter((message) => message !== "");
  };

  const getTotalReserveError = () => {
    const addedReserveAmount =
      reservesData
        ?.map((reserve) => {
          return reserve.amount ?? 0;
        })
        .reduce((partialSum, a) => partialSum + a, 0) ?? 0;

    const defaultRow = tableData?.[0] ?? [];
    const totalReservesRuleLimit =
      parseFloatValueIfExist(
        defaultRow[totalReservesRuleLimitIndex ?? 0] ?? 0
      ) ?? 0;
    const totalReservesAmount =
      parseFloatValueIfExist(defaultRow[totalReservesAmountIndex ?? 0] ?? 0) ??
      0;
    if (addedReserveAmount > 0 && totalReservesRuleLimit > 0)
      return addedReserveAmount + totalReservesAmount > totalReservesRuleLimit;
    return false;
  };

  useEffect(() => {
    const mappedMessages = getReserveTypeErrorMessages();

    if (getTotalReserveError()) {
      const totalError = "Total reserve amount exceeded";
      setErrorMessages([...mappedMessages, totalError]);
    } else setErrorMessages(mappedMessages);
  }, [reservesData]);

  useEffect(() => {
    if (clearMessages) {
      setErrorMessages([]);
      setClearMessages(false);
    }
  }, [clearMessages]);

  return (
    <div
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          flexWrap: "wrap",
        }}
      >
        {errorMessages?.map((errorMessage, index) => (
          <Font isErrorFont key={`reserve_error_message_${index}`}>
            {errorMessage}
          </Font>
        ))}
      </div>
    </div>
  );
};

export default ReserveGridFooter;
