import { FC } from "react";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BindInstructionPremiumBreakdownProp } from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getBaseTableProperties,
  getColumnsAndDataBaseTable,
  getColumnsAndDataMultiTable,
} from "./PaymentScheduleUtils";
import { PolicyPaymentSchedulePage } from "../../../../dtos/policy-payment-schedule-page";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import "./PaymentSchedule.module.css";

const PaymentScheduleTable: FC<BindInstructionPremiumBreakdownProp> = ({
  readOnly,
  insuredId,
  paymentScheduleTable,
  paymentScheduleUI,
  bindInstructionUI,
  updatePaymentScheduleTableDueDate,
  updatePaymentScheduleMultiTableDueDate,
}) => {
  const TABLE_NAME = `policy_payment_schedule_table_${insuredId}`;
  const isBillByLocation = bindInstructionUI?.billByLocation ?? false;
  const tableConfiguration = getBaseTableProperties(
    TABLE_NAME,
    isBillByLocation,
    paymentScheduleTable,
    paymentScheduleUI,
    bindInstructionUI,
    updatePaymentScheduleTableDueDate,
    updatePaymentScheduleMultiTableDueDate,
    readOnly
  ) as BaseTable2Properties;

  if (isBillByLocation) {
    tableConfiguration.columnsAndGroups = getColumnsAndDataMultiTable(
      paymentScheduleTable as PolicyPaymentScheduleMultiTablePage
    );
    tableConfiguration.footerOptions =
      (tableConfiguration.columnsAndGroups.groups?.length ?? 0) <= 0
        ? { CustomFooterComponent: () => <></> }
        : tableConfiguration.footerOptions;
  } else {
    tableConfiguration.columnsAndData = getColumnsAndDataBaseTable(
      paymentScheduleTable as PolicyPaymentSchedulePage
    );
    tableConfiguration.footerOptions =
      (tableConfiguration.columnsAndData.data?.length ?? 0) <= 0
        ? { CustomFooterComponent: () => <></> }
        : tableConfiguration.footerOptions;
  }

  useBaseTable(tableConfiguration);

  return (
    <div className="payment_schedule_table">
      <BaseTable2 name={TABLE_NAME} />
    </div>
  );
};

export default PaymentScheduleTable;
