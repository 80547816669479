import { FC, useEffect, useState } from "react";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { ColumnOptionsProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { TableRow } from "@mui/material";
import { TableData } from "../../../TrueUI/Tables/BaseTableStructure";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Input } from "../../../TrueUI";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { getScheduleMultiTableUpdatedByEndorsementManualChange } from "./EndorsementBindInstructionsUtils";
import { usePolicyQuoteTriggerComponent } from "../hooks/usePolicyQuoteTriggerComponent";
import { unFormatLocalString } from "../../../../utilities/stringFunctions";

type EndorsementScheduleMultiTableParentComponentProps = {
  tabKey: string;
  insuredId: number;
  policyId: number;
  metaData: any;
  headerParams: BaseTableMetaData[];
  columnOptions?: ColumnOptionsProperties<any>[];
  readonly: boolean;
};

const EndorsementScheduleMultiTableParentComponent: FC<
  EndorsementScheduleMultiTableParentComponentProps
> = ({
  tabKey,
  insuredId,
  policyId,
  metaData,
  headerParams,
  columnOptions = [],
  readonly,
}) => {
  const endorsementFromHeader = headerParams.find(
    (headerData) => headerData.name === "Endorsement"
  )?.value;
  const totalFromHeader =
    headerParams.find((headerData) => headerData.name === "Total")?.value ??
    "0";
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const [endorsementValue, setEndorsementValue] = useState<number | null>(
    endorsementFromHeader !== "" ? parseInt(endorsementFromHeader ?? "0") : 0
  );
  const [totalPaymentDueValue, setTotalPaymentDueValue] = useState<
    string | null
  >(totalFromHeader);

  const getColumnWidth = (columnOption?: ColumnOptionsProperties<any>) => {
    if (conditionHasValue(columnOption)) {
      return `${columnOption?.width}%`;
    }
    return `${100 / (headerParams?.length ?? 1)}%`;
  };

  useEffect(() => {
    const atomValue = getAtom();
    const newAtomValue = getScheduleMultiTableUpdatedByEndorsementManualChange(
      metaData.Id,
      endorsementValue,
      atomValue
    );
    setAtom(newAtomValue);
    const invoiceAmount = headerParams.find(
      (headerData) => headerData.name === "InvoiceAmount"
    )?.value;
    const invoiceAmountParsed =
      invoiceAmount !== "" ? unFormatLocalString(invoiceAmount ?? "0") : 0;
    const newTotalValue = (
      invoiceAmountParsed + (endorsementValue ?? 0)
    ).toLocaleString();
    setTotalPaymentDueValue(newTotalValue);

    setPolicyQuoteTriggers([
      "endorsementChildTableComponent",
      "endorsementScheduleMultiTableFooterComponent",
    ]);
  }, [endorsementValue]);

  return (
    <TableRow
      key={`parent-row-schedule-multi-table-${insuredId}-${policyId}-${metaData.Id}`}
      style={{ width: "100%", display: "flex" }}
    >
      {headerParams?.map((param, index) => {
        const columnOption = columnOptions.find(
          (columnOption) => columnOption.fieldName === param.name
        );

        return (
          <TableData
            width={getColumnWidth(columnOption)}
            key={`${param.name.toLowerCase()}-${index}`}
          >
            {param.name === "Endorsement" && param.value !== "" ? (
              <Input
                id={`id-${param.name.toLowerCase()}}`}
                name={`name-${param.name.toLowerCase()}`}
                type="number"
                value={endorsementValue}
                readOnly={readonly}
                onChangeRawValue={(value) => setEndorsementValue(value)}
                minNumericValue={0}
                align="right"
              />
            ) : (
              <Input
                id={`id-${param.name.toLowerCase()}`}
                name={`name-${param.name.toLowerCase()}`}
                value={
                  param?.name === "Total" ? totalPaymentDueValue : param?.value
                }
                align={
                  param?.name === "InvoiceAmount" || param?.name === "Total"
                    ? "right"
                    : "left"
                }
                readOnly
              />
            )}
          </TableData>
        );
      })}
    </TableRow>
  );
};

export default EndorsementScheduleMultiTableParentComponent;
