import { getColorWithOpacityHexaDecimal } from "../../../../../media/themeConstants";
import { Theme } from "../../../../../media/themeTypes";

export const newIncidentStyles = (theme: Theme) => ({
  "#header_wrapper": {
    border: `1px solid ${getColorWithOpacityHexaDecimal(
      theme?.BORDER_COLOR,
      30
    )}`,
    padding: "5px 10px",
  },
  ".add_incident_fields_container": {
    p: 0,
    width: "100%",
    minHeight: "calc(80vh - 97px);",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexDirection: "row",
    "#left_incident_wrapper": {
      overflowY: "auto",
      minWidth: "210px",
      p: 1,
    },
    "#right_incident_wrapper": {
      p: 1,
      flexGrow: 1,
      alignItems: "auto",
      ".incident-actions-and-fields": {
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ".incident-field-container": {
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          overflow: "scroll",
          height: "calc(100vh - 320px)",
        },
        ".incident-fields-content": {
          flexGrow: 1,
        },
      },
      ">.MuiButtonBase-root": {
        mr: 2,
        float: "right",
        svg: { color: theme?.TEXT_COLOR, fontSize: theme?.TITLE?.SIZE },
      },
      ".incident-checkbox": {
        alignSelf: "flex-end",
        pl: 0,
        mt: 1.5,
      },
      ".instructions": {
        color: theme?.TEXT_COLOR,
      },
      ".finish_instructions": {
        overflowY: "auto",
        maxHeight: "125px",
        paddingRight: "10px",
      },
    },
  },
});
