import {
  Box,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Popover,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useApiGet } from "../../../hooks";
import { useRecoilValue } from "recoil";

import {
  menuStyles,
  SearchIconWrapper,
  SearchStyle,
  StyledInputBaseFullWidth,
  searchStyleInputForm,
} from "../../Nav/TopNav/TopNavStyles";
import themes from "../../../media/TrueTheme";
import { globalOptions } from "../../../GlobalAtoms";
import Font from "../Typography/Font";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import { getFormattedValue } from "./SearchTextUtil";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

type SearchProperties = {
  saveSelectedResult: (result: any) => void;
  searchTextKeys: string[];
  searchTextKeysToShowExtraDataIntoInput?: string[];
  labelText?: string;
  placeholderText?: string;
  url: string;
  errorMessage?: string[] | null;
  labelFontType?: KeysAsType<FontsType>;
  value?: string;
  focus?: boolean;
  minCharacters?: number;
  tabIndex?: number;
  name?: string;
  additionalParams?: string;
  menuPadding?: string;
  readOnly?: boolean;
  onRawChange?: (value) => void;
  CustomComponent?: (listItems: any[]) => JSX.Element;
  setValidSelection?: (isValid: boolean) => void;
  searchOnEnterOnly?: boolean;
};

type ResultsState = {
  isOpen: boolean;
  noSearchResults: boolean;
  currentResults: any[] | null;
};

const SearchText: FC<SearchProperties> = ({
  saveSelectedResult,
  searchTextKeys,
  searchTextKeysToShowExtraDataIntoInput,
  labelText,
  placeholderText,
  url,
  errorMessage,
  labelFontType = "BOLD_BODY",
  value,
  focus = true,
  minCharacters = 3,
  tabIndex = 0,
  name,
  additionalParams = "",
  menuPadding = "0",
  CustomComponent,
  setValidSelection,
  readOnly,
  onRawChange,
  searchOnEnterOnly = true,
}) => {
  const [searchTextState, setSearchTextState] = useState<any>({
    searchText: value ?? "",
    resultSelected: false,
    isSearching: false,
  });
  const [resultsState, setResultsState] = useState<ResultsState>({
    isOpen: false,
    noSearchResults: false,
    currentResults: null,
  });
  const [inputWidth, setInputWidth] = useState<any>("45ch");
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (searchOnEnterOnly) {
      setResultsState({ ...resultsState, isOpen: false, currentResults: null });
    }
    setValidSelection?.(false);
    setSearchTextState({
      ...searchTextState,
      searchText: e.target.value,
      resultSelected: false,
      isSearching: !searchOnEnterOnly,
    });
    setAnchorEl(e.currentTarget);
    if (e.target.value.trim() === "") {
      saveSelectedResult(null);
      setValidSelection?.(true);
    }
  };
  useEffect(() => {
    const inputContainer: any = document.querySelector(
      "div.true_search_text_input "
    );
    const inputSearch: any = document.querySelector(
      ".true_search_text_input >div.MuiInputBase-root input"
    );
    setInputWidth(inputContainer.offsetWidth);
    inputSearch.setSelectionRange(0, 0);
  }, []);

  useEffect(() => {
    setSearchTextState({
      ...searchTextState,
      searchText: value,
      resultSelected: false,
      isSearching: false,
    });
  }, [value]);

  const { responseGet, dispatchGet } = useApiGet<any>(
    `${url}${encodeURIComponent(
      `${searchTextState.searchText}${additionalParams}`
    )}`,
    {
      disablePropertyUpdaters: true,
    }
  );

  const searchOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.repeat) return;
    const enterCodes = ["Enter", "NumpadEnter"];
    if (searchOnEnterOnly) {
      if (
        enterCodes.includes(e.code) &&
        searchTextState.searchText.trim() !== ""
      ) {
        dispatchGet();
      }
    }
  };

  useEffect(() => {
    if (
      !searchOnEnterOnly &&
      searchTextState.searchText.trim() !== "" &&
      searchTextState.searchText.trim().length >= minCharacters &&
      searchTextState.resultSelected === false &&
      searchTextState.isSearching === true
    ) {
      dispatchGet();
    }
  }, [searchTextState]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (responseGet?.responseData?.length > 0) {
        setResultsState({
          ...resultsState,
          isOpen: true,
          noSearchResults: false,
          currentResults: responseGet.responseData,
        });
      } else {
        setResultsState({
          ...resultsState,
          isOpen: false,
          noSearchResults: true,
          currentResults: [],
        });
      }
    }
  }, [responseGet]);

  const resultSelected = (result: any) => {
    setResultsState({ ...resultsState, isOpen: false });
    const searchText = getFormattedValue(
      result,
      searchTextKeys,
      searchTextKeysToShowExtraDataIntoInput
    );
    setSearchTextState({
      ...searchTextState,
      searchText: searchText,
      resultSelected: true,
      isSearching: false,
    });
    saveSelectedResult(result ?? null);
    setValidSelection?.(true);
  };

  const inputBaseProps = {
    placeholder: placeholderText ?? "Search…",
    inputProps: {
      "aria-label": "search",
      "true-element": `true-search-input-${name}`,
      tabIndex,
      className: TABBABLE_CLASS_NAME,
      readOnly: readOnly,
      sx: { cursor: readOnly ? "not-allowed" : "auto" },
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        onRawChange?.(e.target.value),
      onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => searchOnEnter(e),
    },
    onChange: handleSearchTextChange,
    autoFocus: focus,
    value: searchTextState?.searchText ?? "",
    disabled: readOnly,
    sx: {
      fontSize: 16,
      lineHeight: 1.5,
      height: 27,
    },
  };
  const onSearchIconClick = () => {
    if (searchTextState.searchText !== "") {
      dispatchGet();
    }
  };

  return (
    <>
      {labelText && <Font fontType={labelFontType}>{labelText}</Font>}
      <SearchStyle
        sx={{
          ...searchStyleInputForm(theme, inputWidth),
          margin: "0 !important",
          height: 27,
          marginBottom: errorMessage != null ? `20px !important` : ``,
          "& span.true_input_error_txt": {
            color: theme?.DANGER,
            fontSize: "0.75rem",
            letterSpacing: "0.03333em",
            textAlign: "left",
            marginTop: "3px",
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            display: "block",
          },
          "&.with-errors": {
            borderColor: theme?.DANGER,
          },
          "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall": {
            opacity: 0.4,
          },
        }}
        className={`true_search_text_input ${
          errorMessage != null ? "with-errors" : ""
        }`}
      >
        <StyledInputBaseFullWidth {...inputBaseProps} />
        <SearchIconWrapper>
          <SearchIcon
            htmlColor={theme?.PRIMARY}
            fontSize="small"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onSearchIconClick();
            }}
          />
        </SearchIconWrapper>
        {errorMessage != null && (
          <span className="true_input_error_txt">
            {errorMessage?.join(" ")}
          </span>
        )}
      </SearchStyle>
      {resultsState.currentResults &&
        (searchOnEnterOnly ||
          searchTextState?.searchText?.trim()?.length >= minCharacters) && (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            id={"primary-notifications-menu"}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={3}
            open={resultsState.isOpen}
            onClose={() => setResultsState({ ...resultsState, isOpen: false })}
            sx={menuStyles(theme, menuPadding)}
            disableAutoFocus
            TransitionComponent={Grow as any}
          >
            <Box className={"true_container_list"}>
              {resultsState.currentResults.length >= 50 && (
                <Typography>Please refine your search criteria</Typography>
              )}
              {resultsState.currentResults.length > 0 &&
              conditionHasValue(CustomComponent) ? (
                CustomComponent?.(resultsState.currentResults)
              ) : (
                <List dense>
                  {resultsState.currentResults.map((result, i) => {
                    return (
                      <ListItemButton
                        key={i}
                        onClick={() => {
                          resultSelected(result);
                        }}
                      >
                        <ListItemText
                          className={"true_first_row_typography"}
                          primary={getFormattedValue(
                            result,
                            searchTextKeys,
                            searchTextKeysToShowExtraDataIntoInput
                          )}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              )}
            </Box>
          </Menu>
        )}

      {resultsState.currentResults?.length === 0 && (
        <Popover
          id={"true_not_found_results_message"}
          anchorEl={anchorEl}
          open={resultsState.noSearchResults}
          onClose={() =>
            setResultsState({ ...resultsState, noSearchResults: false })
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            mt: 6,
          }}
          elevation={3}
          keepMounted
          disableAutoFocus
          TransitionComponent={Grow as any}
        >
          <Typography sx={{ p: 2 }}>
            Sorry... We couldn't find any matches for{" "}
            <b>{searchTextState?.searchText ?? ""}</b>
          </Typography>
        </Popover>
      )}
    </>
  );
};

export default SearchText;
