import { Box } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import style from "./multiSelect.module.css";

type SelectTagProps = {
  name?: string;
  label: string;
  type?: "greenTags" | "grayTags";
  hideRemoveButton?: boolean;
  onRemove?: (e: any) => any;
};

const SelectTag: FC<SelectTagProps> = ({
  name = "",
  label,
  type = "grayTags",
  hideRemoveButton = false,
  onRemove,
}) => {
  return (
    <Box
      true-element={`true-element-select-tag-${name}`}
      className={`${style.select_tag} ${
        type === "grayTags" ? style.gray_tags : style.green_tags
      }`}
    >
      <p>{label}</p>
      {!hideRemoveButton && (
        <button onClick={(e) => onRemove?.(e)}>
          <CloseIcon />
        </button>
      )}
    </Box>
  );
};

export default SelectTag;
