import { RecoilState, useRecoilValue } from "recoil";
import { TableInstanceType2 } from "./TableProperties";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { FC } from "react";
import { hydrateAllGroups, hydrateData2 } from "../tableFunctions";

const BaseTableFooterSubComponent: FC<any> = (props) => {
  const { uiid } = props;
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );
  const dataToExpose = tableInstance.footerOptions
    ?.getPaginatedRowsOnCustomFooter
    ? tableInstance?.sortedAndFilteredData
    : hydrateData2(tableInstance?.columns ?? [], tableInstance?.data ?? []);

  const multiDataToExpose = hydrateAllGroups(tableInstance.groups);

  const exposeCorrectData =
    tableInstance.tableType !== "multi" ? dataToExpose : multiDataToExpose;

  return (
    <>
      <div className={"base_table_sub_footer_component"}>
        {tableInstance?.footerOptions?.CustomFooterComponent?.(
          exposeCorrectData
        ) ?? null}
      </div>
    </>
  );
};

export default BaseTableFooterSubComponent;
