import { FC, useEffect } from "react";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { Col, Input, InputDate, Row, Select } from "../../../../TrueUI";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../utilities/inputPropsConfigurationFunctions";
import { ScheduledTransactionConfigurationDto } from "../../../../../dtos/scheduled-transaction-configuration-dto";
import { getKeyFromEnumValue } from "../../../../../utilities/enumFunctions";
import { useApiGet } from "../../../../../hooks";
import InvoiceNumberDropdown from "./InvoiceNumberDropdown";
import { BillingScheduledTransactionDto } from "../../../../../dtos/billing-scheduled-transaction-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import PolicyDropdown from "../../../Policy/PolicyDropdown";
import { defaultData } from "./ScheduledTransactionModal";
import { BillingScheduledTransactionsStatusEnum } from "../../../../../dtos/billing-scheduled-transactions-status-enum";

type ScheduledTransactionModalContentProps = {
  configuration: ScheduledTransactionConfigurationDto;
  formData: BillingScheduledTransactionDto;
  setFormData: (newData: BillingScheduledTransactionDto) => void;
  readOnly?: boolean;
  errorDetails?: any;
  transactionId: number;
  insuredId: number;
};

const ScheduledTransactionModalContent: FC<
  ScheduledTransactionModalContentProps
> = ({
  configuration,
  transactionId,
  insuredId,
  formData,
  setFormData,
  readOnly = false,
  errorDetails,
}) => {
  const status = getKeyFromEnumValue(
    BillingScheduledTransactionsStatusEnum,
    formData.scheduleStatus ?? 0
  );

  const { responseGet, dispatchGet } =
    useApiGet<BillingScheduledTransactionDto>(
      `api/BillingTransaction/GetScheduledTransactionById?scheduledTransactionId=${transactionId}`
    );
  
  const updatePolicyId = (value: number | null) => {
    if (responseGet?.responseData?.policyId !== value)
      setFormData({...formData, policyId: value, invoiceId: null});
    else
      setFormData({...formData, policyId: value});
  }

  useEffect(() => {
    dispatchGet();
  }, [transactionId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormData(responseGet.responseData ?? defaultData);
    }
  }, [responseGet]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-insured-name",
              "insured-name",
              "Insured Name",
              300,
              true
            )}
            value={formData.insuredName}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <PolicyDropdown
            insuredId={insuredId}
            name={"policyId"}
            id={"id-policy"}
            label={"Policy"}
            labelFontType={"BOLD_CAPTION"}
            optionsMaxHeight={"400px"}
            defaultValue={formData.policyId ?? -1}
            policySelectedOnChange={updatePolicyId}
            readOnly={readOnly}
            errorMessage={errorDetails?.policyId}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Select
            {...selectConfiguration(
              "id-pay-from-account",
              "pay-from-account",
              "Pay From Account",
              readOnly
            )}
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            options={configuration.accountOptions ?? []}
            optionsMaxHeight={"400px"}
            value={formData.insuredAccountId ?? -1}
            onChange={(value) =>
              setFormData({ ...formData, insuredAccountId: value })
            }
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-pay-account-type",
              "pay-account-type",
              "Pay Account Type",
              true
            )}
            labelFontType="BOLD_CAPTION"
            options={configuration?.accountTypes ?? []}
            value={formData.insuredAccountId}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Select
            {...selectConfiguration(
              "id-deposit-account-number",
              "deposit-account-number",
              "Deposit Account Number",
              readOnly
            )}
            labelFontType="BOLD_CAPTION"
            optionsMaxHeight={"400px"}
            options={configuration.depositAccountOptions ?? []}
            value={formData.tranAccountId}
            onChange={(value) =>
              setFormData({ ...formData, tranAccountId: value })
            }
            errorMessage={errorDetails?.tranAccountId}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-deposit-bank-account",
              "deposit-bank-account",
              "Deposit Bank Account",
              200,
              true
            )}
            value={formData.accountNickname}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <InvoiceNumberDropdown
            policyId={formData.policyId ?? 0}
            insuredId={insuredId}
            value={formData.invoiceId ?? -1}
            onChange={(value) => setFormData({ ...formData, invoiceId: value })}
            readOnly={readOnly}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration("id-status", "status", "Status", 20, true)}
            value={status}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-amount",
              "amount",
              "Amount",
              22,
              readOnly
            )}
            type={"fixedCurrency"}
            value={formData.transactionAmount}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, transactionAmount: value })
            }
            maxNumericValue={9999999999999.99}
            errorMessage={errorDetails?.transactionAmount}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={8}
            id="id-scheduled-date"
            name={"scheduled-date"}
            label="Scheduled Date"
            value={formData.scheduledDate ?? new Date()}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, scheduledDate: value })
            }
            disablePast
            readOnly={readOnly}
            errorMessage={errorDetails?.scheduledDate}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-created-by",
              "created-by",
              "Created by/on",
              50
            )}
            type={"text"}
            value={formData.createBy}
            readOnly
          />
        </Col>
      </Row>
    </>
  );
};

export default ScheduledTransactionModalContent;
