import { Box } from "@mui/material";
import { FC } from "react";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { ColumnOptionsProperties } from "../BaseTable2/TableProperties";
import { TableData, TableRow } from "../BaseTableStructure";
import { getCellManager, getHeaderColor } from "./MultiAlignHeaderUtils";
import { PercentageType } from "../../../../types/RangeNumberType";
import MultiAlignHeaderContextMenu from "./MultiAlignHeaderContextMenu";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

export type MultiAlignContextActionProps = {
  displayName: string;
  actionName: string;
  rule: () => any;
};

export type MultiAlignHeaderActions = {
  contextMenu?: MultiAlignContextActionProps[];
  contextMenuOptionRenderingCondition?: {
    actionName: string;
    condition: (row: any) => { isHidden: boolean };
  }[];
  editRow?: ((e?: any) => void) | null;
};

export type ColorConfigurationProps = {
  color: string;
  percentageOccupiedColor?: PercentageType;
};

export type BackgroundLinearGradientProps = {
  direction?: string;
  colorConfiguration: ColorConfigurationProps[];
};

type MultiAlignHeaderProperties = {
  headerParams?: BaseTableMetaData[];
  metaData?: any;
  contextConfiguration?: MultiAlignHeaderActions;
  columnOptions?: ColumnOptionsProperties<any>[] | null;
  backgroundColor?: BackgroundLinearGradientProps;

  // needs the ability to filter out title icon/actions
};

const MultiAlignHeader: FC<MultiAlignHeaderProperties> = ({
  headerParams,
  metaData,
  contextConfiguration,
  columnOptions,
  backgroundColor,
}) => {
  const params = headerParams ?? [];
  const getColumnWidth = (param) => {
    const selected = columnOptions?.find((c) => c?.fieldName === param.name);
    if (conditionHasValue(selected)) {
      return `${selected?.width}%`;
    }
    return `${100 / (params?.length ?? 1)}%`;
  };

  return (
    <Box
      id="multi_align_header_container"
      sx={{
        width: "100%",
        background: `${getHeaderColor(backgroundColor)} !important`,
        ".MuiBox-root": { background: "unset !important" },
      }}
    >
      <TableRow style={{ width: "100%" }}>
        {params.map((param, key) => (
          <TableData key={key} width={getColumnWidth(param)}>
            {getCellManager(param, columnOptions)}
          </TableData>
        ))}

        {conditionHasValue(contextConfiguration?.contextMenu) && (
          <MultiAlignHeaderContextMenu
            contextConfiguration={contextConfiguration}
            metaData={metaData}
          />
        )}
        {conditionHasValue(contextConfiguration?.editRow) && (
          <div
            style={{ display: "flex", marginRight: "15px" }}
            className={"multi_action_edit"}
          >
            <div>
              {contextConfiguration?.editRow && (
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    contextConfiguration?.editRow?.();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </TableRow>
    </Box>
  );
};
export default MultiAlignHeader;
