import { FC } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import {
  endorsementOnChangeCompute,
  endorsementOnInitCompute,
  totalDuePaymentOnChangeCompute,
} from "./EndorsementScheduleTableComputes";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTable } from "../../../../dtos/base-table";
import { PolicyBindInstructionsBlob } from "../../../../dtos/policy-bind-instructions-blob";
import { updatePolicyQuote } from "../updatesPolicyQuoteFunctions";
import style from "../EndorsementForm/Endorsement.module.css";
import EndorsementScheduleTableFooter from "./EndorsementScheduleTableFooter";
import { getColumnIndexByColumnName } from "../../../TrueUI/Tables/tableFunctions";

const EndorsementScheduleTable: FC<EndorsementGeneralProps> = ({
  tabKey,
  insuredId,
  policyId,
  readonly,
}) => {
  const tableName = `endorsement_schedule_table_${insuredId}_${policyId}`;
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const scheduleTable =
    getAtom()?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.scheduleTable;
  const columnsAndData = {
    columns: scheduleTable?.columns ?? [],
    data: scheduleTable?.data ?? [],
  };

  const updateEndorsementScheduleTable = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    const atomValue = getAtom();
    const endorsementScheduleTable =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.scheduleTable;
    const invoiceIdIndex = getColumnIndexByColumnName("InvoiceId", columns);
    const totalPaymentDueIndex = getColumnIndexByColumnName("Total", columns);
    const updatedEndorsementScheduleTable = {
      ...endorsementScheduleTable,
      data: data.map((row) =>
        row.slice(invoiceIdIndex, totalPaymentDueIndex + 1)
      ),
      columns: columns.filter((column) => !column._isInternal),
    } as BaseTable;

    const bindInstructionsUpdated = {
      ...atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions,
      scheduleTable: updatedEndorsementScheduleTable,
    } as PolicyBindInstructionsBlob;

    const newAtomValue = updatePolicyQuote(
      atomValue,
      "bindInstructions",
      bindInstructionsUpdated
    );

    setAtom(newAtomValue);
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData,
    events: { onDataChange: updateEndorsementScheduleTable },
    advancedOptions: { paginate: false, isEditMode: true },
    columnOptions: [
      {
        fieldName: "InvoiceAmount",
        align: "right",
      },
      {
        fieldName: "Endorsement",
        align: "right",
        allowNegatives: true,
        computeOnInit: {
          conditionForCell: [
            (options) => endorsementOnInitCompute(options, readonly ?? false),
          ],
        },
        computeOnChange: { conditionForCell: [endorsementOnChangeCompute] },
      },
      {
        fieldName: "Total",
        align: "right",
        computeOnChange: { conditionForCell: [totalDuePaymentOnChangeCompute] },
      },
    ],
    footerOptions: {
      CustomFooterComponent: (rows) => (
        <EndorsementScheduleTableFooter rows={rows} />
      ),
    },
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showExcelButton: readonly,
      showPDFButton: readonly,
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div className={style.endorsement_schedule_bind_instructions_table}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default EndorsementScheduleTable;
