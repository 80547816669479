import { BaseTableDisplayOptions } from "../../../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../../../dtos/base-table-input-type";
import { unFormatLocalString } from "../../../../../utilities/stringFunctions";
import {
  ConditionForCellResponse,
  ComputeForCellParameters,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";

export const paymentCellInitCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  return {
    value:
      parseInt(options?.currentValue ?? "0") > 0 ? options?.currentValue : 0.0,
    typeId: BaseTableInputType.CURRENCY,
    isEditable: true,
  };
};

export const paymentCellIOnChangeCondition = (
  options: ComputeForCellParameters<any>,
  withValidation: boolean = true
): ConditionForCellResponse | void => {
  const maxLength = 99999999.99;
  return {
    value:
      withValidation && Number(options.currentValue) > maxLength
        ? maxLength
        : Number(options.currentValue),
    typeId: BaseTableInputType.CURRENCY,
    isEditable: true,
  };
};

export const policyCellInitCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.PolicyId,
      typeId: BaseTableInputType.SELECT,
      isEditable: (options?.currentValue ?? "") === "" ? false : true,
    };
  }
};

export const tranAccountCellInitCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.TranAccountId,
      typeId: BaseTableInputType.SELECT,
      isEditable: true,
    };
  }
};

export const policyNumberCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.currentValue,
      typeId: BaseTableInputType.SELECT,
      triggerComputeFieldNames: ["PolicyId"],
    };
  }
};

export const policyIdCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.PolicyNumber,
    };
  }
};

export const tranAccountIdCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.Account,
    };
  }
};

export const accountCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.currentValue,
      typeId: BaseTableInputType.SELECT,
      triggerComputeFieldNames: ["Description", "TranAccountId"],
    };
  }
};

export const descriptionCellOnChangeCondition = (
  options: ComputeForCellParameters<any>,
  tranAccountOptions: BaseTableDisplayOptions[]
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    const newValue = tranAccountOptions.find(
      (x) => x.value === options.row?.Account
    );
    return {
      value: newValue?.text ?? "",
      typeId: BaseTableInputType.READONLY,
      isEditable: false,
    };
  }
};

export const getRowValueByIndex = (row: any, rowIndex: number) => {
  return row[rowIndex] === ""
    ? null
    : Number(unFormatLocalString(row[rowIndex]));
};

export const getTextOfSelectedItem = (
  idValue: number | null,
  displayOptions: BaseTableDisplayOptions[] | null
) => {
  return idValue === null
    ? null
    : getTextFromDisplayOptionsItemByValue(idValue, displayOptions ?? []);
};

const getTextFromDisplayOptionsItemByValue = (
  value: number | null,
  displayOptions: BaseTableDisplayOptions[]
): string => {
  if (value === null) return "";
  return displayOptions.find((x) => Number(x.value) === value)?.text ?? "";
};
