import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options.ts";
import { FormBillingItemWrapperProps } from "./TypesBillingItem.ts";
import { InsuredBillingItemFormOptionsDto } from "../../../../../../dtos/insured-billing-item-form-options-dto.ts";
import FormBillingItem from "./FormBillingtem.tsx";
import { Col, Row } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row.tsx";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col.tsx";

const FormBillingItemWrapper: FC<FormBillingItemWrapperProps> = ({
  insuredId,
  transactionId,
  billingItem,
  setBillingItem,
  errorDetails,
  activityType,
}) => {
  const { responseGet, dispatchGet } =
    useApiGet<InsuredBillingItemFormOptionsDto>(
      `api/Activity/GetInsuredOptionForBillingItem?insuredId=${insuredId}&transactionId=${transactionId}`
    );

  const [formSelectOptions, setFormSelectOptions] = useState<{
    locations: Partial<SelectOptions>[] | [];
    tranAccounts: Partial<SelectOptions>[] | [];
    tranAccountDescriptions: Partial<SelectOptions>[] | [];
    policies: Partial<SelectOptions>[] | [];
  } | null>();

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const invoiceItemFormOptions = responseGet?.responseData;
      setFormSelectOptions({
        locations: invoiceItemFormOptions?.locations ?? [],
        tranAccounts: invoiceItemFormOptions?.tranAccounts ?? [],
        tranAccountDescriptions:
          invoiceItemFormOptions?.tranAccountsDescriptions ?? [],
        policies: invoiceItemFormOptions?.policyOptions ?? [],
      });
      const billingItem = invoiceItemFormOptions?.billingItemDto ?? null;
      const addressId =
        (billingItem?.transactionId ?? 0) !== 0
          ? billingItem?.addressId ?? null
          : invoiceItemFormOptions?.locations?.[0]?.intValue ??
            billingItem?.addressId ??
            null;
      const transactionDate = billingItem?.transactionDate ?? new Date();
      setBillingItem({
        ...billingItem,
        policyId:
          billingItem?.policyId ??
          invoiceItemFormOptions?.currentPolicyId ??
          null,
        addressId,
        transactionDate,
      });
    }
  }, [responseGet]);

  return (
    <>
      {formSelectOptions !== null && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
            displayFlex={false}
          >
            <FormBillingItem
              locations={formSelectOptions?.locations ?? []}
              tranAccounts={formSelectOptions?.tranAccounts ?? []}
              tranAccountDescriptions={
                formSelectOptions?.tranAccountDescriptions ?? []
              }
              policies={formSelectOptions?.policies ?? []}
              billingItem={billingItem}
              setBillingItem={setBillingItem}
              errorDetails={errorDetails}
              activityType={activityType}
              insuredId={insuredId ?? 0}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default FormBillingItemWrapper;
