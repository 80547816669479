import { useMsal } from "@azure/msal-react";
import { removeSystemLocalStorage } from "../utilities/localStorageFunctions";

export const useSecurity = () => {
  const { instance } = useMsal();

  const logout = () => {
    removeSystemLocalStorage();
    instance.logout();
  };

  return { logout };
};
