import { FC, cloneElement, useEffect, useState } from "react";
import {
  RecoilState,
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import {
  BaseGridKeyNameMapAtom,
  BaseGridKeyNameMapProperty,
  GridInstanceAtomFamily,
} from "./BaseGridAtoms";
import {
  BaseGridInternalProperties,
  BaseGridProperties,
} from "./BaseGridProperties";
import BaseGridRegistrationGateMountError from "./BaseGridRegistrationGateMountError";

type BaseGridRegistrationGateProperties = {
  name: string;
  disableInitialize: boolean;
  children: any;
};

const BaseGridRegistrationGate: FC<BaseGridRegistrationGateProperties> = ({
  name,
  disableInitialize,
  children,
}) => {
  const keyMap = useRecoilValue(BaseGridKeyNameMapAtom);

  const [renderChildren, setRenderChildren] = useState<JSX.Element | null>(
    null
  );

  const removeGridInstance = useResetRecoilState(BaseGridKeyNameMapAtom);

  const registerNewKeyNameMapInstance = (
    gridName: string,
    set: <T>(
      recoilVal: RecoilState<T>,
      valOrUpdater: T | ((currVal: T) => T)
    ) => void
  ) => {
    const keyNameMap = {
      uiid: crypto.randomUUID(),
      name: gridName,
      disableInitialize: disableInitialize ?? false,
      hasHookRegistered: "unregistered",
    } as BaseGridKeyNameMapProperty;
    set(BaseGridKeyNameMapAtom, (keyName) => [...keyName, keyNameMap]);
  };

  const nameKeySynchronizer = useRecoilCallback(
    ({ snapshot, set }) =>
      (gridName: string): BaseGridKeyNameMapProperty | null => {
        const keyMapInstance = snapshot.getLoadable(BaseGridKeyNameMapAtom)
          .contents as BaseGridKeyNameMapProperty[];

        if (keyMapInstance.length > 0) {
          const foundGridKeyRegisteration =
            keyMapInstance.find((keyMap) => keyMap.name === gridName) ?? null;

          if (foundGridKeyRegisteration !== null) {
            return foundGridKeyRegisteration;
          } else {
            registerNewKeyNameMapInstance(name, set);
          }
        } else {
          registerNewKeyNameMapInstance(name, set);
        }
        return null;
      },
    []
  );

  const getGridMountCondition = useRecoilCallback(
    ({ snapshot }) =>
      (uiid: string | null) => {
        if (uiid !== null) {
          const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
            .contents as {
            BaseGridProperties: BaseGridProperties;
            BaseGridInternalProperties: BaseGridInternalProperties;
          };

          if (instance === null) {
            return "unmount";
          }
          if (instance !== null) {
            return (
              instance?.BaseGridInternalProperties?.lastMountAction ?? "unmount"
            );
          }
          return "unmount";
        }

        return "unmount";
      },
    []
  );

  useEffect(() => {
    // This just creates the instance between the name and the key
    nameKeySynchronizer(name);

    // This destorys the entire grid instance map (some meta data lingers due to Recoil design). In the future, if there is any need to preseve
    // instance(s), we'll need to change this to an atomFamily instead to only destory a single instance map versus the entire set of maps.
    return () => {
      removeGridInstance();
    };
  }, []);

  const getCurrentKeyMapInstance = (keyMap: BaseGridKeyNameMapProperty[]) => {
    if (keyMap.length === 0) return null;
    return keyMap.find((key) => key.name === name) ?? null;
  };

  const renderRegisteredAndMountedGrid = (
    keyMapInstance: BaseGridKeyNameMapProperty | null,
    wasMountedCorrectly: "unmount" | "mount" | undefined
  ) => {
    if (
      disableInitialize === undefined ||
      (!disableInitialize &&
        keyMapInstance !== null &&
        keyMapInstance?.uiid !== null &&
        keyMapInstance.hasHookRegistered === "registered")
    ) {
      if (wasMountedCorrectly === "mount") {
        const wrapperElement = cloneElement(children, {
          uiid: keyMapInstance?.uiid,
          name: keyMapInstance?.name,
        });
        setRenderChildren(wrapperElement);
      }
    }
  };

  const renderUnregisteredAndUnmountedErrorResponse = (
    keyMapInstance: BaseGridKeyNameMapProperty | null,
    wasMountedCorrectly: "unmount" | "mount" | undefined
  ) => {
    if (
      keyMapInstance !== null &&
      keyMapInstance?.uiid !== null &&
      keyMapInstance.hasHookRegistered === "unregistered" &&
      wasMountedCorrectly === "unmount"
    ) {
      setRenderChildren(<BaseGridRegistrationGateMountError name={name} />);
    }
  };

  useEffect(() => {
    const keyMapInstance = getCurrentKeyMapInstance(keyMap);
    const wasMountedCorrectly = getGridMountCondition(
      keyMapInstance?.uiid ?? null
    );
    renderRegisteredAndMountedGrid(keyMapInstance, wasMountedCorrectly);
    // or
    renderUnregisteredAndUnmountedErrorResponse(
      keyMapInstance,
      wasMountedCorrectly
    );
  }, [keyMap]);

  return renderChildren ? renderChildren : null;
};

export default BaseGridRegistrationGate;
