import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { RadioButtonEnum } from "../../../../dtos/radio-button-enum";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { StringToDate } from "../../../../utilities/dateFunctions";
import Switch from "../../Checkbox/Switch";
import Input from "../../Inputs/Input";
import InputDate from "../../Inputs/InputDate";
import { ColumnOptionsProperties } from "../BaseTable2/TableProperties";
import {
  BackgroundLinearGradientProps,
  ColorConfigurationProps,
} from "./MultiAlignHeader";

const getPropertiesByValue = (value) => {
  switch (parseInt(value ?? "0") ?? 0) {
    case RadioButtonEnum.INVALID:
      return { isChecked: false, isInvalid: true };
    case RadioButtonEnum.SELECTED:
      return { isChecked: true };
    case RadioButtonEnum.UNSELECTED:
      return { isChecked: false };
    default:
      return { isChecked: false };
  }
};

const getColumnOptions = (param, columnOptions) => {
  const selected = columnOptions?.find((c) => c?.fieldName === param.name);
  if (conditionHasValue(selected)) {
    return selected;
  }
  return null;
};

//Add the type of cell you need
export const getCellManager = (
  param: BaseTableMetaData,
  columnOptions?: ColumnOptionsProperties<any>[] | null
) => {
  //Add the props that you need
  const columnOption: ColumnOptionsProperties<any> = getColumnOptions(
    param,
    columnOptions
  );

  const commonProps = {
    id: `id-${param.name}`,
    name: `name-${param.name}`,
    readOnly: true,
    isInputIdent: false,
    ...columnOption,
    value: param?.value,
    align: columnOption?.align ?? "left",
  };

  switch (param.inputType) {
    case BaseTableInputType.NUMBER:
      return <Input {...commonProps} type={"number"} />;
    case BaseTableInputType.CURRENCY:
      return <Input {...commonProps} type={"currency"} />;
    case BaseTableInputType.DATE_PICKER:
      return <InputDate {...commonProps} value={StringToDate(param.value)} />;
    case BaseTableInputType.CONDITIONAL_RADIO_BUTTON:
      return (
        <Switch
          {...commonProps}
          control={"radio"}
          isTableCell
          {...getPropertiesByValue(param.value)}
        />
      );
    default:
      return <Input {...commonProps} type={"text"} />;
  }
};

export const getColorSectionsForLinearGradient = (
  colors: ColorConfigurationProps[]
) => {
  return colors.map((color) => {
    return `,${color.color} ${color.percentageOccupiedColor ?? 100}%`;
  });
};

export const getHeaderColor = (
  backgroundColor?: BackgroundLinearGradientProps
) => {
  if (backgroundColor !== undefined) {
    return `linear-gradient(${
      backgroundColor.direction ?? "0.25turn"
    }${getColorSectionsForLinearGradient(
      backgroundColor?.colorConfiguration ?? []
    ).join("")})`;
  }
  return "inherit";
};
