import { FC } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { Select, Switch } from "../../../TrueUI";

type ReassignClaimsModalContentProps = {
  assigneeOptions: Partial<SelectOptions>[];
  newAdjusterOptions: Partial<SelectOptions>[];
  formData: any;
  setFormData: (data: any) => void;
  errorDetails: any;
};

const ReassignClaimsModalContent: FC<ReassignClaimsModalContentProps> = ({
  assigneeOptions,
  newAdjusterOptions,
  formData,
  setFormData,
  errorDetails,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div>
        <Select
          {...selectConfiguration(
            "id-current-adjuster",
            "currentAdjuster",
            "Current Adjuster"
          )}
          tabIndex={1}
          labelFontType="BOLD_CAPTION"
          firstOptionAsDefault={false}
          options={assigneeOptions}
          optionsMaxHeight={"400px"}
          value={""}
          onChange={(value) => {
            setFormData({ ...formData, currentAdjuster: value });
          }}
          errorMessage={errorDetails?.currentAdjuster}
        />
      </div>
      <div>
        <Select
          {...selectConfiguration(
            "id-new-adjuster",
            "newAdjuster",
            "New Adjuster"
          )}
          tabIndex={2}
          labelFontType="BOLD_CAPTION"
          firstOptionAsDefault={false}
          options={newAdjusterOptions}
          optionsMaxHeight={"400px"}
          value={""}
          onChange={(value) => {
            setFormData({ ...formData, newAdjuster: value });
          }}
          errorMessage={errorDetails?.newAdjuster}
        />
      </div>
      <div>
        <Switch
          tabIndex={3}
          control={"checkbox"}
          isChecked={formData?.reassignActiveTasks}
          label={"Reassign Active Tasks?"}
          labelPlacement={"end"}
          onChangeIsChecked={() =>
            setFormData({
              ...formData,
              reassignActiveTasks: !formData.reassignActiveTasks,
            })
          }
          labelFontType={"BODY"}
          name={"reassignActiveTasks"}
        />
      </div>
    </div>
  );
};

export default ReassignClaimsModalContent;
