import { FC, useEffect, useState } from "react";
import { InsuredNameDto } from "../../../../dtos/insured-name-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet, useApiPost, usePermissions } from "../../../../hooks";
import {
  Col,
  Input,
  InputDate,
  InputTax,
  Modal,
  Row,
  Select,
  Switch,
} from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { InsuredNameModalDto } from "../../../../dtos/insured-name-modal-dto";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { formatNumber } from "../../../../utilities/stringFunctions";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";

export type ModalNameInformationProps = {
  insuredId?: number;
  nameId?: number;
  isNewName?: boolean;
  isOpen?: boolean;
};

type ModalNamesProps = {
  modalInformation: ModalNameInformationProps;
  onCancelEvent?: () => void;
  onSaveEvent?: () => void;
};

type ModalConfigurationProps = {
  businessTypes?: SelectOptions[];
  relationshipTypes?: SelectOptions[];
  errorDetails?: any | null;
};

const textFieldVariant = "standard";

const ModalName: FC<ModalNamesProps> = ({
  modalInformation,
  onSaveEvent,
  onCancelEvent,
}) => {
  const [insuredNameData, setInsuredNameData] = useState<InsuredNameDto>();
  const [modalConfiguration, setModalConfiguration] =
    useState<ModalConfigurationProps>({});
  const [isActiveLocationDialogOpen, setIsActiveLocationDialogOpen] =
    useState<boolean>(false);

  const { insuredId, nameId, isNewName, isOpen } = modalInformation;
  const { errorDetails, businessTypes, relationshipTypes } = modalConfiguration;
  const setInsuredNameMultipleData = (fieldNames: string[], values: any[]) => {
    const newValues = fieldNames.map((targetProperty, index) => ({
      [targetProperty]: values[index],
    }));
    const targetJoined = Object.assign({}, ...newValues);
    setInsuredNameData({
      ...insuredNameData,
      ...targetJoined,
    });
  };
  const permissions = [
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ];
  const { hasPermission } = usePermissions(permissions);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/InsuredInformation/SaveInsuredName",
    insuredNameData
  );

  const paramNameId = isNewName ? "&nameId" : `&nameId=${nameId}`;
  const { responseGet, dispatchGet } = useApiGet<InsuredNameModalDto>(
    `api/InsuredInformation/GetInformationNameById?insuredId=${insuredId}${paramNameId}`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const responseData = responseGet.axiosResponse?.data;
      const lastNumberName = (responseData?.lastNumberName ?? 0) + 1;
      const name = responseData?.insuredName;
      setInsuredNameData({
        ...name,
        insuredId: isNewName ? insuredId : name?.insuredId,
        nameNumber: isNewName ? lastNumberName : name?.nameNumber,
        companyType: isNewName
          ? responseData?.companyTypesOptions?.[0]?.stringValue ?? ""
          : name?.companyType,
        nameType: isNewName
          ? responseData?.nameTypesOptions?.[0]?.stringValue ?? ""
          : name?.nameType,
      });
      setModalConfiguration({
        businessTypes: responseData?.companyTypesOptions,
        relationshipTypes: responseData?.nameTypesOptions,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      onSaveEvent?.();
    } else {
      setModalConfiguration({
        ...modalConfiguration,
        errorDetails: validatorErrorResponse?.errorDetails,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    const activeLocations = errorDetails?.[""];
    if (activeLocations != null) {
      setIsActiveLocationDialogOpen(true);
    }
  }, [errorDetails]);

  useEffect(() => {
    if (isOpen) {
      dispatchGet();
    } else {
      setInsuredNameData({});
    }
  }, [isOpen]);

  const onCancelModal = () => {
    setModalConfiguration({ errorDetails: null });
    onCancelEvent?.();
  };

  return (
    <>
      <Modal
        id={"new_insured_name_modal"}
        title={"Named Insured"}
        open={isOpen}
        cancelEvent={onCancelModal}
        permissionsToSaveButton={permissions}
        saveEvent={dispatchPost}
        cancelButtonWithConfirmation={hasPermission}
      >
        <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 8, lg: 8, xl: 8 }}
          >
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Input
                  tabIndex={1}
                  id="id-insured-name-input"
                  name="insuredNameInput"
                  label="Name"
                  maxLength={300}
                  variant={textFieldVariant}
                  value={insuredNameData?.legalName}
                  errorMessage={errorDetails?.legalName}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      legalName: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  tabIndex={2}
                  id="id-insured-name-dba-input"
                  name="insuredDbaInput"
                  label="DBA"
                  maxLength={300}
                  variant={textFieldVariant}
                  value={insuredNameData?.nameDBA}
                  errorMessage={errorDetails?.nameDBA}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      nameDBA: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputTax
                  tabIndex={3}
                  id="id-insured-name-tax-id-input"
                  name="insuredTaxIdInput"
                  label="Tax ID"
                  initialType={
                    insuredNameData?.nameTaxIdType === "FEIN" ? "FEIN" : "SSN"
                  }
                  variant={textFieldVariant}
                  value={insuredNameData?.nameTaxId}
                  errorMessage={errorDetails?.nameTaxId}
                  readOnly={!hasPermission}
                  onChangeRaw={({ value, type }) =>
                    setInsuredNameMultipleData(
                      ["nameTaxId", "nameTaxIdType"],
                      [value, type]
                    )
                  }
                  key={insuredNameData?.nameId}
                />
              </Col>
              <Col horizontalAlign="flex-start">
                <Switch
                  tabIndex={4}
                  id="id-insured-name-billed-independently-input"
                  name="insuredBilledIndependentlyInput"
                  label={"Billed Independently"}
                  isChecked={insuredNameData?.billedIndependently}
                  labelPlacement={"end"}
                  control={"checkbox"}
                  readOnly={!hasPermission}
                  onChangeIsChecked={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      billedIndependently: value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 4, lg: 4, xl: 4 }}
          >
            <Row {...rowWithNoMarginNorGutter} rowDirection="column">
              <Col>
                <Input
                  tabIndex={5}
                  id="id-insured-name-link-number-input"
                  name="insuredLinkNumberInput"
                  label="Name Link Number"
                  variant={textFieldVariant}
                  readOnly={true}
                  value={formatNumber(insuredNameData?.nameNumber ?? 0, 3)}
                />
              </Col>
              <Col>
                <Select
                  tabIndex={6}
                  id="id-insured-name-relationship-type-select"
                  name="insuredRelationshipTypeSelect"
                  label={"Relationship Type"}
                  options={relationshipTypes ?? []}
                  variant={textFieldVariant}
                  value={insuredNameData?.nameType}
                  errorMessage={errorDetails?.nameType}
                  readOnly={!hasPermission}
                  onChange={(value) =>
                    setInsuredNameData({
                      ...insuredNameData,
                      nameType: value,
                    })
                  }
                />
              </Col>
              <Col>
                <Select
                  tabIndex={7}
                  id="id-insured-name-business-type-select"
                  name="insuredBusinessTypeSelect"
                  label={"Business Type"}
                  options={businessTypes ?? []}
                  variant={textFieldVariant}
                  optionsMaxHeight="145px"
                  errorMessage={errorDetails?.companyType}
                  value={insuredNameData?.companyType}
                  readOnly={!hasPermission}
                  onChange={(value) =>
                    setInsuredNameData({
                      ...insuredNameData,
                      companyType: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={8}
                  id="id-insured-name-effective-date-input"
                  name="insuredEffectiveDateInput"
                  label="Effective Date"
                  variant={textFieldVariant}
                  errorMessage={errorDetails?.nameEffDate}
                  value={insuredNameData?.nameEffDate}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      nameEffDate: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={9}
                  id="id-insured-name-expiration-date-input"
                  name="insuredExpirationDateInput"
                  label="Expiration Date"
                  errorMessage={errorDetails?.nameExpDate}
                  variant={textFieldVariant}
                  value={insuredNameData?.nameExpDate}
                  readOnly={!hasPermission}
                  onChangeRawValue={(value) => {
                    setInsuredNameData({
                      ...insuredNameData,
                      nameExpDate: value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <DialogConfirmation
        name="activeLocationDialog"
        id="modalActiveLocationDialogConfirmation"
        open={isActiveLocationDialogOpen}
        dialogDescriptionText={"Please deactivate all locations first."}
        onCloseEvent={(close) => {
          setModalConfiguration({ errorDetails: null });
          setIsActiveLocationDialogOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setModalConfiguration({ errorDetails: null });
          setIsActiveLocationDialogOpen(close);
        }}
        optionYesOverrideLabel={"OK"}
      />
    </>
  );
};

export default ModalName;
