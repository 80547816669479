import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { IncidentOpenFromEnum } from "../../../../../dtos/incident-open-from-enum";
import { formattingKey } from "../../../../../utilities/enumFunctions";
import { statusToHideDeleteIncidentAsKeyString } from "./IncidentFunctions";
import { SubSideNavParentsIndexEnum } from "../../../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../../../../dtos/sub-side-nav-item-destinations-enum";
import {
  INCIDENT_ID,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  INCIDENT_BODY_SECTION,
  OUT_OF_INSURED,
} from "../../../../../utilities/queryStringsHash";
import { getQueryStringsURL } from "../../../../../utilities/URLUtilities_OBSOLETE";
import { IncidentBodySectionEnums } from "../../../../../dtos/incident-body-section-enums";
import { IncidentPropsForContextMenu } from "../Incident";
//Rules Declaration.
export const contextViewIncident = (
  row,
  isFromOutSideInsured,
  navigate: any
) => {
  const queryStrings = [
    {
      nameOfHash: INSURED_PARENT_ITEM,
      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
    },
    {
      nameOfHash: INSURED_CHILD_ITEM,
      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
    },
    {
      nameOfHash: INSURED_BODY_SECTION,
      valueOfHash: SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
    },
    { nameOfHash: INCIDENT_ID, valueOfHash: row.IncidentId },
    {
      nameOfHash: INCIDENT_BODY_SECTION,
      valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
    },
  ];

  if (isFromOutSideInsured) {
    queryStrings.push({
      nameOfHash: OUT_OF_INSURED,
      valueOfHash: IncidentOpenFromEnum.CLAIMS_TOP_LEVEL.toString(),
    });
  }

  const url = getQueryStringsURL(queryStrings);
  navigate(`/insured/${row.InsuredId}${url}`);
};

export const contextCreateClaim = (
  row,
  setIncidentContextMenuValues: (context: IncidentPropsForContextMenu) => void
) => {
  setIncidentContextMenuValues({
    incidentId: row.IncidentId,
    incidentStatus: undefined,
    insuredId: row.InsuredId,
    runCreateClaimProcess: true,
    dialogConfirmationOpen: true,
  });
};

export const contextChangeIncidentStatus = (
  row,
  incidentStatus,
  setIncidentContextMenuValues: (context: IncidentPropsForContextMenu) => void
) => {
  setIncidentContextMenuValues({
    row: row,
    incidentId: row.IncidentId,
    incidentStatus: incidentStatus,
    dialogConfirmationOpen: true,
  });
};

//Actions For The Menu.

export const contextActionsForIncident = (
  setIncidentContextMenuValues: any,
  isFromOutSideInsured: boolean,
  navigate: any
) => [
  {
    displayName: "View Incident",
    actionName: "_viewIncident",
    rule: (ruleOptions) =>
      contextViewIncident(ruleOptions.row, isFromOutSideInsured, navigate),
  },
  {
    displayName: "In Progress",
    actionName: "_inProgress",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.IN_PROGRESS,
        setIncidentContextMenuValues
      ),
  },
  {
    displayName: "Under Review",
    actionName: "_underReview",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.UNDER_REVIEW,
        setIncidentContextMenuValues
      ),
  },
  {
    displayName: "Create Claim",
    actionName: "_createClaim",
    rule: (ruleOptions) =>
      contextCreateClaim(ruleOptions.row, setIncidentContextMenuValues),
  },
  {
    displayName: "Report Only",
    actionName: "_reportOnly",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.REPORT_ONLY,
        setIncidentContextMenuValues
      ),
  },
  {
    displayName: "Denied",
    actionName: "_denied",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.DENIED,
        setIncidentContextMenuValues
      ),
  },
  {
    displayName: "First Aid",
    actionName: "_firstAid",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.FIRST_AID,
        setIncidentContextMenuValues
      ),
  },
  {
    displayName: "Delete Incident",
    actionName: "_deleteIncident",
    rule: (ruleOptions) =>
      contextChangeIncidentStatus(
        ruleOptions.row,
        IncidentStatusEnums.DELETED,
        setIncidentContextMenuValues
      ),
  },
];

const hideContextOptionForIncidents = (row) => {
  return {
    isHidden:
      row.IncidentStatus !==
        formattingKey(IncidentStatusEnums[IncidentStatusEnums.SUBMITTED]) &&
      row.IncidentStatus !==
        formattingKey(IncidentStatusEnums[IncidentStatusEnums.UNDER_REVIEW]),
  };
};

//Conditional Hiding For Actions.

export const contextActionsRenderingConditionsForIncidents = [
  {
    actionName: "_inProgress",
    condition: (row) => hideContextOptionForIncidents(row),
  },
  {
    actionName: "_underReview",
    condition: (row) => {
      return {
        isHidden:
          row.IncidentStatus !==
          formattingKey(IncidentStatusEnums[IncidentStatusEnums.SUBMITTED]),
      };
    },
  },
  {
    actionName: "_createClaim",
    condition: (row) => {
      return {
        isHidden: hideContextOptionForIncidents(row).isHidden,
      };
    },
  },
  {
    actionName: "_reportOnly",
    condition: (row) => hideContextOptionForIncidents(row),
  },
  {
    actionName: "_denied",
    condition: (row) => hideContextOptionForIncidents(row),
  },
  {
    actionName: "_firstAid",
    condition: (row) => hideContextOptionForIncidents(row),
  },
  {
    actionName: "_deleteIncident",
    condition: (row) => {
      return {
        isHidden: !statusToHideDeleteIncidentAsKeyString.includes(
          row.IncidentStatus
        ),
      };
    },
  },
];
