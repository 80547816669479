import { ExcelExport } from "@progress/kendo-react-excel-export";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ElementType, FC, useEffect, useRef } from "react";
import { useGridInstance } from "./Hooks/useGridInstance";
import { INTERNAL_TABLE_COLUMNS } from "./BaseGridConstants";
import { useRecoilValue } from "recoil";
import DialogControlled from "../TrueUI/Dialogs/DialogControlled";
import PreparingExcelExportIndicator from "./SupportComponents/PreparingExecelExportIndicator";
import PDFTemplateWrapper from "../TrueUI/LayoutPDF/PDFTemplateWrapper";
import DefaultTableTemplate from "../TrueUI/LayoutPDF/DefaultTableTemplate";
import { WorkbookOptions } from "@progress/kendo-ooxml";
import {
  formatColumnForExcelExport,
  getRowDataToExportToExcel,
} from "./SupportFunctions/baseGridExportToExcelFunctions";

const BaseGridDataExportManager: FC<any> = ({ uiid }) => {
  const { instanceSelector, instanceInternalSelector, setInstance } =
    useGridInstance(uiid, "BaseGridDataExportManager");
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const excelExportComponent = useRef<ExcelExport>(null);
  const pdfExportComponent = useRef<PDFExport>(null);

  const exportToExcel = () => {
    if (excelExportComponent.current) {
      const filteredColumns = (instance.columns ?? []).filter(
        (column) =>
          !column?.isHidden &&
          !column._isInternal &&
          !INTERNAL_TABLE_COLUMNS.includes(column.fieldName)
      );
      const formattedColumns = formatColumnForExcelExport(filteredColumns);
      const rowDataToExportToExcel = getRowDataToExportToExcel(
        filteredColumns,
        instanceInternal?.sortedAndFilteredData ?? []
      );
      const options = {
        sheets: [
          {
            name: "Sheet 1",
            rows: rowDataToExportToExcel,
            columns: formattedColumns,
          },
        ],
      } as WorkbookOptions;
      excelExportComponent.current.save(options);
    }
  };

  // TODO - PDF reporting needs a refactor.
  const pdfExportProps = {
    fileName: "True Grid PDF",
    paperSize: "Letter",
    margin: {
      left: "1cm",
      right: "1cm",
      top: "3cm",
      bottom: "3cm",
    },
    ref: pdfExportComponent,
    pageTemplate: PDFTemplateWrapper,
    landscape: true,
    repeatHeaders: true,
  };

  const exportToPDF = () => {
    // TODO - PDF reporting needs a refactor.
    if (pdfExportComponent.current) {
      if (
        (instanceInternal.sortedAndFilteredData?.length ?? 0) > 0
        // || (tableInstance?.groups?.length ?? 0) > 0
      )
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save(() => {
            setInstance({
              hasToggleExportPDF: false,
            });
          });
        }
    }
  };

  // TODO - PDF reporting needs a refactor.

  const TemplatePDF = instance?.advancedOptions?.TemplatePDF as ElementType;

  const getDataByType = () => {
    return instanceInternal.sortedAndFilteredData ?? [];
  };

  useEffect(() => {
    if (instance.hasToggleExportPDF) {
      exportToPDF();
    }
  }, [instance.hasToggleExportPDF]);

  useEffect(() => {
    if (instance.hasToggleExportExcel) {
      exportToExcel();
    }
  }, [instance.hasToggleExportExcel]);

  return (
    <>
      <DialogControlled
        isOpen={
          (instance?.hasToggleExportExcel || instance?.hasToggleExportPDF) ??
          false
        }
        title={"Processing File..."}
        element={
          <PreparingExcelExportIndicator
            itemLength={instanceInternal.sortedAndFilteredData.length ?? 0}
          />
        }
      />
      {instance.hasToggleExportExcel && (
        <>
          <ExcelExport
            fileName={"True Grid Excel.xlsx"} // TODO future - needs to have dynamic friendly name support
            ref={excelExportComponent}
            onExportComplete={() =>
              setInstance({
                hasToggleExportExcel: false,
              })
            }
          />
        </>
      )}
      {instance.hasToggleExportPDF && (
        <div
          style={{
            position: "absolute",
            left: "-1000px",
            top: 0,
          }}
        >
          <PDFExport {...pdfExportProps}>
            <div
              style={{
                width: "100%",
              }}
            >
              {((instanceInternal.sortedAndFilteredData ?? []).length > 0 &&
                TemplatePDF === undefined) ||
              TemplatePDF === null ? (
                <DefaultTableTemplate
                  columns={instance?.columns ?? []}
                  data={getDataByType()}
                />
              ) : (
                <TemplatePDF
                  columns={instance?.columns ?? []}
                  data={getDataByType()}
                />
              )}
            </div>
          </PDFExport>
        </div>
      )}
    </>
  );
};

export default BaseGridDataExportManager;
