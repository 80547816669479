import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import {
  TableInstanceType2,
  ToolbarConfigurationProperties,
} from "./TableProperties";

import { ElementType, useEffect, useState, useRef, FC } from "react";
import { Box, IconButton } from "@mui/material";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import Button from "../../Buttons/Button";
import SaveButton from "../../Buttons/SaveButton";
import {
  castFooterToExports,
  castHeaderToExports,
  createNewDataItemWithDefaults,
  dehydrateData,
  getDataIndexByColumnName,
  getDataOfChildren,
  getExternalColumnsOnly,
  hydrateDataSingle,
} from "../tableFunctions";
import AddIcon from "@mui/icons-material/Add";
import { SelectOptions } from "../../../../dtos/select-options";
import PDFTemplateWrapper from "../../LayoutPDF/PDFTemplateWrapper";
import { PDFExport } from "@progress/kendo-react-pdf";
import DefaultTableTemplate from "../../LayoutPDF/DefaultTableTemplate";
import { INTERNAL_TABLE_COLUMNS } from "../TableConstants";
import { FontBold, Select, SplitButton } from "../..";
import { usePermissions } from "../../../../hooks";
import SettingsIcon from "@mui/icons-material/Settings";
import { WorkbookOptions } from "@progress/kendo-ooxml";
import {
  formatColumnForExcelExport,
  getRowDataToExportToExcel,
} from "../baseTableExportToExcelFunctions";

export const defaultConfigurationToolBar: ToolbarConfigurationProperties = {
  hideToolbar: false,
  showAddButton: true,
  addButtonText: "Add",
  addButtonIsDisabled: false,
  showEditButton: true,
  editButtonText: "Edit",
  editButtonPrimary: false,
  showSaveButton: true,
  saveButtonText: "Save",
  showSortFilter: true,
  showImportButton: true,
  showExcelButton: true,
  showPDFButton: true,
};

const BaseTable2Toolbar: FC<any> = (props) => {
  const { uiid } = props;
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );
  const excelExportComponent = useRef<ExcelExport>(null);
  const pdfExportComponent = useRef<PDFExport>(null);
  const hasAddPermission = usePermissions(
    tableInstance.permissions?.addPermissions ?? []
  );
  const hasEditPermission = usePermissions(
    tableInstance.permissions?.editPermissions ?? []
  );
  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const addMultiTable = () => {
    if (tableInstance?.methods?.addEventOverrideCallback) {
      const callbackResponse =
        tableInstance?.methods?.addEventOverrideCallback?.(
          Object.freeze({
            groupUiidKey: crypto.randomUUID(),
            columns: [],
            title: "New Row",
            metaDataDescription: [],
            metaData: [],
          })
        );
      if (callbackResponse) {
        // TODO - restore this or review it
        // const updatedGroups = [callbackResponse].concat([
        //   ...tableInstance.groups,
        // ]);
      }
    } else {
      const updatedGroups = [
        {
          groupUiidKey: crypto.randomUUID(),
          columns: [],
          title: "New Row",
          metaDataDescription: [],
          metaData: [],
        },
      ].concat([...tableInstance.groups]);

      setTableInstance({
        ...tableInstance,
        groups: updatedGroups,
      });
    }
  };

  const addStandardTable = () => {
    if (tableInstance?.methods?.addEventOverrideCallback) {
      const externalColumnsOnly = getExternalColumnsOnly(
        tableInstance?.columns ?? []
      );
      const defaults = createNewDataItemWithDefaults(externalColumnsOnly);
      const hydratedBaseObject = hydrateDataSingle(
        externalColumnsOnly,
        defaults
      );

      const callbackResponse =
        tableInstance?.methods?.addEventOverrideCallback?.(hydratedBaseObject);

      const newRowKey = crypto.randomUUID();

      if (callbackResponse !== undefined && callbackResponse !== null) {
        const mergedNewRow = { ...hydratedBaseObject, ...callbackResponse };

        setTableInstance({
          ...tableInstance,
          _accessors: {
            ...tableInstance._accessors,
            _addRow: { hydratedData: mergedNewRow, newRowKey } as any,
          },
        });
      } else {
        setTableInstance({
          ...tableInstance,
          isAdd: false,
        });
      }
    } else {
      const hydratedData = [];
      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        _accessors: {
          ...tableInstance._accessors,
          _addRow: { hydratedData, isEditableAfterAdd: true },
        },
      });
    }
  };

  const addRow = () => {
    if (tableInstance.tableType === "multi") {
      addMultiTable();
    } else {
      addStandardTable();
    }
  };

  const onCancel = () => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      isEdit: false,
      toggleEditModeState: false,
      selectedEditRows: [],
      validationErrors: [],
      data: tableInstance?.data,
    });
    tableInstance?.methods?.onEditClick?.(tableInstance?.isEdit);
  };

  const onEdit = () => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      isEdit: true,
    });
    tableInstance?.methods?.onEditClick?.(tableInstance?.isEdit);
  };

  const initialFilterState = new Array(
    tableInstance?.filterOptions?.length ?? 0
  )
    .fill({})
    .map((_, i) => ({
      columnIndexes: [-1],
      filterValue: "all",
      filterIndex: i,
      columnNames: [],
      filterType: "",
      filterName: "",
      isStatic: false,
    }));

  const [filterColumnNames, setFilterColumnNames] = useState<string[]>([]);
  const [filterDataValues, setFilterDataValues] = useState<
    {
      columnIndexes: number[];
      filterValue: string;
      filterIndex: number;
      columnNames: string[];
      filterName: string;
      isStatic: boolean;
    }[]
  >(initialFilterState);

  const [incomingFilters, setIncomingFilters] = useState<any>(
    new Array(tableInstance?.filterOptions?.length ?? 0)
      .fill({})
      .map((_, i) => {
        return {
          columnIndexes: [-1],
          filterValue: "all",
          filterIndex: i,
          columnNames: [],
          filterType: "",
          filterName: "",
          isStatic: false,
        };
      })
  );

  const onExportToExcel = () => {
    if (excelExportComponent.current) {
      const filteredColumns = (tableInstance.columns ?? []).filter(
        (column) =>
          !column?.isHidden &&
          !column._isInternal &&
          !INTERNAL_TABLE_COLUMNS.includes(column.fieldName)
      );
      const formattedColumns = formatColumnForExcelExport(filteredColumns);
      const rowDataToExportToExcel = getRowDataToExportToExcel(
        tableInstance.tableType,
        filteredColumns,
        tableInstance.groups ?? [],
        tableInstance.childrenData ?? [],
        tableInstance.advancedOptions?.headerExportType === "aligned",
        tableInstance.sortedAndFilteredDataWithOutPaginate ?? []
      );
      const options = {
        sheets: [
          {
            name: "Sheet 1",
            rows: rowDataToExportToExcel,
            columns: formattedColumns,
          },
        ],
      } as WorkbookOptions;
      excelExportComponent.current.save(options);
    }
  };

  const pdfExportProps = {
    fileName: "True Table PDF",
    paperSize: "Letter",
    margin: {
      left: "1cm",
      right: "1cm",
      top: "3cm",
      bottom: "3cm",
    },
    ref: pdfExportComponent,
    pageTemplate: PDFTemplateWrapper,
    landscape: true,
    repeatHeaders: true,
  };

  const onExportToPDF = () => {
    if (pdfExportComponent.current) {
      if (
        (tableInstance?.data.length ?? 0) > 0 ||
        (tableInstance?.groups?.length ?? 0) > 0
      )
        pdfExportComponent.current.save();
    }
  };

  const getToolBarType = () => {
    switch (
      tableInstance?.toolbarOptions?.toolBarType ??
      tableInstance?.tableType
    ) {
      case "compact":
        return "compact";
      default:
        return "base";
    }
  };

  const _setFilterExclude = (
    values: {
      filterIndex: number;
      filterType: string;
      columnName: string | string[] | null;
      filterValue: string;
    }[],
    filterIndex: number
  ) => {
    setIncomingFilters({ filterIndex, values });
  };

  const _setFilterInclude = (
    values: {
      filterIndex: number;
      filterType: string;
      columnName: string | string[] | null;
      filterValue: string;
      filterName: string;
    }[],
    filterIndex: number
  ) => {
    setIncomingFilters((filter) =>
      filter.map((v, i) => {
        if (filterIndex === i) {
          return { ...v, ...values[0] };
        }
        return v;
      })
    );
  };

  const _setIsStaticFilter = (filterIndex: number, isStatic: boolean) => {
    setIncomingFilters((filter) =>
      filter.map((v, i) => {
        if (filterIndex === i) {
          return { ...v, isStatic: isStatic };
        }
        return v;
      })
    );
  };

  useEffect(() => {
    if (incomingFilters !== null) {
      updateFilterDataValues(incomingFilters);
    }
  }, [incomingFilters]);

  useEffect(() => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      searchValue: filterDataValues,
    });
  }, [filterDataValues]);

  const updateFilterDataValues = (
    values: {
      filterIndex: number;
      filterType: string;
      columnNames: string[];
      filterValue: string;
      filterName: string;
      isStatic: boolean;
    }[]
  ) => {
    const updatedFilters = values.map((filter) => {
      const indexes =
        filter?.columnNames?.map((name) => {
          return getDataIndexByColumnName(tableInstance?.columns ?? [], name);
        }) ?? [];

      return {
        ...filter,
        columnIndexes: indexes,
        filterValue: filter?.filterValue?.toString() ?? "all",
      };
    });

    const updatedFilterDataValues = filterDataValues.map(
      (filterDataValue, i) => {
        const foundFilterDataValue =
          updatedFilters.find((y) => y.filterIndex === i) ?? null;
        if (foundFilterDataValue !== null) {
          return { ...foundFilterDataValue };
        }
        return filterDataValue;
      }
    );

    setFilterDataValues(updatedFilterDataValues);
  };

  const InnerToggleColumnFilters = (columnNames: string[]) => {
    const updatedTableColumns = (tableInstance?.columns ?? []).map((col) => {
      if (columnNames.includes(col.fieldName)) {
        return { ...col, isHidden: !col.isHidden };
      }
      return col;
    });
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      columns: updatedTableColumns,
    });
  };

  const filterFunctions = (filterIndex: number) => {
    return {
      setFilterExclude: (
        values: {
          filterIndex: number;
          filterType: string;
          columnName: string | string[] | null;
          filterValue: string;
        }[]
      ) => {
        // TODO - add support for this function in the filter process
        new Error("This filter function is not ready for use.");
        const i = filterIndex;
        const filterType = "exclude";
        function InnerSetFilterExclude() {
          const updatedFilterParams = values.map((f) => {
            return {
              ...f,
              filterIndex: filterIndex,
              filterType: filterType,
            };
          });
          _setFilterExclude(updatedFilterParams, i);
        }

        InnerSetFilterExclude();
      },
      setFilterInclude: (
        values: {
          filterIndex: number;
          filterType: string;
          columnName: string | string[] | null;
          filterValue: string;
          filterName: string;
        }[]
      ) => {
        const i = filterIndex;
        const filterType = "include";
        function InnerSetFilterInclude() {
          const updatedFilterParams = values.map((f) => {
            return {
              ...f,
              filterIndex: filterIndex,
              filterType: filterType,
            };
          });

          _setFilterInclude(updatedFilterParams, i);
        }

        InnerSetFilterInclude();
      },
      toggleColumnFilters: (columnNames: string[]) => {
        setFilterColumnNames(columnNames);
      },
      isStaticFilter: (isStatic: boolean = false) => {
        const i = filterIndex;
        function InnerSetIsStaticFilter() {
          _setIsStaticFilter(i, isStatic);
        }

        InnerSetIsStaticFilter();
      },
    };
  };

  const cachedFilters = () => {
    return (
      tableInstance.isPreProcessingComplete &&
      (tableInstance?.columns.length ?? 0) > 0 &&
      tableInstance?.filterOptions !== null &&
      tableInstance?.filterOptions?.map((filterOption, filterIndex) => {
        const Jsx = filterOption as ElementType;
        const filters = filterFunctions(filterIndex);
        return <Jsx {...filters} key={filterIndex} />;
      })
    );
  };

  const defaultSortOptions: Partial<SelectOptions>[] = [
    // TODO - actually implement this
    { displayName: "NEWEST", stringValue: "newest" },
    { displayName: "OLDEST", stringValue: "oldest" },
  ];

  const [renderedFilters, setRenderedFilters] = useState<any>(null);

  const resetFilters = () => {
    setRenderedFilters(null);
    setFilterDataValues(initialFilterState);
  };

  const getExportButton = () => {
    if (
      tableInstance?.toolbarOptions?.showExcelButton &&
      tableInstance?.toolbarOptions?.showPDFButton
    ) {
      return (
        <SplitButton
          triggerEventOnSelect
          optionsMinWidth="105px"
          items={[
            {
              option: "EXCEL",
              action: onExportToExcel,
            },
            {
              option: "PDF",
              action: onExportToPDF,
            },
          ]}
        />
      );
    }
    if (tableInstance?.toolbarOptions?.showExcelButton) {
      return (
        <div>
          <Button
            fullWidth
            variantStyle="contained"
            onClick={() => onExportToExcel?.()}
            name={"exportToExcel"}
          >
            EXCEL
          </Button>
        </div>
      );
    }
    if (tableInstance?.toolbarOptions?.showPDFButton) {
      return (
        <div>
          <Button
            fullWidth
            variantStyle="contained"
            onClick={() => onExportToPDF?.()}
            name={"exportToPDF"}
          >
            PDF
          </Button>
        </div>
      );
    }
    return <></>;
  };

  const getToolBar = () => {
    switch (getToolBarType()) {
      case "compact":
        return (
          <Box
            className={`compact-toolbar ${tableInstance?.advancedOptions?.tableStyle?.headerStyle}`}
          >
            <FontBold className={"title"}>
              {tableInstance?.toolbarOptions?.compactTitle}
            </FontBold>
            <Box className={"filters-container"}>
              <>{renderedFilters}</>
              {tableInstance?.toolbarOptions?.showAddButton ? (
                <IconButton
                  onClick={() => addRow()}
                  className="addNewButton"
                  disabled={
                    tableInstance?.toolbarOptions?.addButtonIsDisabled ?? false
                  }
                  {...{ "true-element": `true-element-button-compactAddRow` }}
                >
                  <AddIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ height: "40px" }}
                  className="hiddenAddNewButton"
                  disabled={!tableInstance?.toolbarOptions?.showAddButton}
                />
              )}
            </Box>
          </Box>
        );
      default:
        return (
          <div
            // className={`base_table_toolbar ${tableInstance?.advancedOptions?.tableStyle?.headerStyle}`}
            className={`true_ui_base_table_toolbar`} // BASETABLE TODO - re-review this, the headerStyle value that was being set kept returning 'undefined' which makes me think theres no value in having this here (at least as far as the standard table layout goes).
          >
            <div className={"toolbar_container_actions"}>
              <div className={"toolbar_filters"}>
                <>{renderedFilters}</>
                {tableInstance?.advancedOptions?.hideRefreshFiltersButton !==
                  true &&
                  tableInstance?.filterOptions !== undefined &&
                  tableInstance?.filterOptions?.length >= 2 && (
                    <div
                      className={"reset_table_filters"}
                      title={"Reset Filters"}
                    >
                      <IconButton onClick={() => resetFilters()}>
                        <span
                          className="k-icon k-i-unsort"
                          style={{ fontSize: "24px" }}
                        ></span>
                      </IconButton>
                    </div>
                  )}
              </div>
              <div className={"toolbar_buttons"}>
                {hasAddPermission &&
                  tableInstance?.toolbarOptions?.showAddButton && (
                    <div>
                      <Button
                        fullWidth
                        onClick={
                          () => addRow()
                          // setTableInstance({
                          //   ...tableInstance,
                          //   isAdd: true,
                          // })
                        }
                        isDisabled={
                          tableInstance?.toolbarOptions?.addButtonIsDisabled ??
                          false
                        }
                        name={"toolbarAddNew"}
                      >
                        {tableInstance?.toolbarOptions?.addButtonText}
                      </Button>
                    </div>
                  )}

                {hasEditPermission &&
                  tableInstance?.toolbarOptions?.showEditButton && (
                    <div>
                      {tableInstance.isEdit ||
                      tableInstance.toggleEditModeState ? (
                        <Button
                          fullWidth
                          onClick={() => onCancel()}
                          variantStyle={
                            tableInstance?.toolbarOptions?.editButtonPrimary
                              ? "contained"
                              : "outlined"
                          }
                          name={"toolbarCancel"}
                        >
                          CANCEL
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          onClick={() => onEdit()}
                          variantStyle={
                            tableInstance?.toolbarOptions?.editButtonPrimary
                              ? "contained"
                              : "outlined"
                          }
                          isDisabled={
                            tableInstance?.toolbarOptions
                              ?.editButtonIsDisabled ?? false
                          }
                          name={"toolbarEdit"}
                        >
                          {tableInstance?.toolbarOptions?.editButtonText}
                        </Button>
                      )}
                    </div>
                  )}

                {tableInstance?.toolbarOptions?.showSaveButton &&
                  (tableInstance?.isEdit ||
                    tableInstance?.toggleEditModeState) && (
                    <div>
                      <SaveButton
                        text={tableInstance?.toolbarOptions?.saveButtonText}
                        fullWidth
                        onClick={() =>
                          setTableInstance({
                            ...tableInstance,
                            isSave: true,
                          })
                        }
                        name="toolbarSaveButton"
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className={"toolbar_exports"}>
              {tableInstance?.toolbarOptions?.showSortFilter && (
                <div>
                  <Select
                    id={`true-select-sort-for-table-${props.uiid}`}
                    label="SORT:"
                    isCustomArrow
                    type={"tableFilter"}
                    labelFontType="BOLD_TITLE"
                    labelPosition="start"
                    inputWidth="100%"
                    options={defaultSortOptions}
                    name={"sortRows"}
                  />
                </div>
              )}

              {tableInstance?.toolbarOptions?.showImportButton && (
                <div>
                  <Button
                    fullWidth
                    variantStyle="outlined"
                    onClick={() => tableInstance?.methods?.onImportClick?.()}
                    name={"toolbarImport"}
                  >
                    IMPORT
                  </Button>
                </div>
              )}
              {getExportButton()}
              {tableInstance?.toolbarOptions?.showSettingsIcon === true && (
                <div>
                  <IconButton
                    aria-label="expand row"
                    onClick={() => {
                      alert("Waiting Info");
                    }}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        );
    }
  };

  const dehydrateGroupsWithChildren = () => {
    return tableInstance?.groups
      ?.map((group) => {
        const header = castHeaderToExports(
          group,
          tableInstance?.columns,
          tableInstance?.advancedOptions?.headerExportType
        );
        const dehydratedHeader = dehydrateData(header);
        const dehydratedChildren = getDataOfChildren(
          group,
          tableInstance?.columns,
          tableInstance?.childrenData
        );
        const mergedData = [dehydratedHeader, ...dehydratedChildren];
        return mergedData;
      })
      .reduce((merged, array) => {
        return merged.concat(array);
      }, []) as any[];
  };

  const TemplatePDF = tableInstance?.advancedOptions
    ?.TemplatePDF as ElementType;

  const getDataByType = (withTemplate?: boolean) => {
    if (tableInstance?.tableType === "multi") {
      const headersAndChildren = dehydrateGroupsWithChildren();
      const footerValues = castFooterToExports(
        tableInstance?.parentMetaData,
        tableInstance?.columns,
        tableInstance?.advancedOptions?.headerExportType
      );
      const dehydratedFooter = dehydrateData(footerValues);
      return headersAndChildren.concat([dehydratedFooter]);
    }
    return withTemplate
      ? tableInstance?.data
      : tableInstance?.sortedAndFilteredDataWithOutPaginate;
  };

  //tableInstance?.toolbarOptions?.hideToolbar; -- it looks like a duplicate -- ask antonio
  const hasDataOrGroups =
    (tableInstance?.data?.length ?? 0) > 0 ||
    (tableInstance?.groups?.length ?? 0) > 0;

  useEffect(() => {
    if (
      tableInstance.isPreProcessingComplete &&
      (tableInstance?.columns?.length ?? 0) > 0 &&
      renderedFilters === null
    ) {
      setRenderedFilters(cachedFilters);
    }
  }, [renderedFilters, tableInstance]);

  useEffect(() => {
    setFilterDataValues(initialFilterState);
  }, []);

  useEffect(() => {
    if (tableInstance?.isAdd) {
      setRenderedFilters(null);
    }
  }, [tableInstance?.isAdd]);

  useEffect(() => {
    if (filterColumnNames.length > 0) {
      InnerToggleColumnFilters(filterColumnNames);
      setFilterColumnNames([]);
    }
  }, [filterColumnNames]);

  return !tableInstance?.toolbarOptions?.hideToolbar ? (
    <>
      {getToolBar()}
      {hasDataOrGroups && (
        <>
          <ExcelExport
            fileName={"True Table Excel.xlsx"} // TODO future - needs to have dynamic friendly name support
            ref={excelExportComponent}
          />
          <div className="true_table_pdf_toolbar_container">
            <PDFExport {...pdfExportProps}>
              <div style={{ width: "100%" }}>
                {(hasDataOrGroups && TemplatePDF === undefined) ||
                TemplatePDF === null ? (
                  <DefaultTableTemplate
                    columns={tableInstance?.columns ?? []}
                    data={getDataByType()}
                  />
                ) : (
                  <TemplatePDF
                    columns={tableInstance?.columns}
                    data={getDataByType(true)}
                  />
                )}
              </div>
            </PDFExport>
          </div>
        </>
      )}
      {tableInstance?.advancedOptions?.CustomHeaderComponent && (
        <div
          style={{
            padding: "15px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderWidth: "1px 1px",
          }}
          className="base_table_custom_header"
        >
          {tableInstance?.advancedOptions?.CustomHeaderComponent()}
        </div>
      )}
    </>
  ) : null;
};

export default BaseTable2Toolbar;
