import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../hooks";
import { ApplicationQuestionDto } from "../../../../dtos/application-question-dto";
import GeneralQuestions from "./GeneralQuestions";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { getApplicationQuestionsAsBlob } from "./GeneralInformationUtils";
import { updateGeneralQuestions } from "../updateGlobalPolicyQuoteFunctions";
import { PolicyApplicationQuestionBlob } from "../../../../dtos/policy-application-question-blob";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { useRecoilValue } from "recoil";
import { TriggerPolicyQuoteUpdateAtom } from "../../Policy/hooks/usePolicyQuoteTriggerComponent";

const GeneralInformationMain: FC = () => {
  const { responseGet, dispatchGet } = useApiGet<ApplicationQuestionDto[]>(
    "api/ApplicationQuestion/GetApplicationQuestionList"
  );
  const { getAtom, setAtom } = useAtomFamily(acord130FormProperties);
  const [applicationQuestions, setApplicationQuestions] =
    useState<PolicyApplicationQuestionBlob[]>();
  const listenerGeneralInformationComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("generalInformationComponent")
  );

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(atomValue?.generalInformation))
      setApplicationQuestions(atomValue?.generalInformation ?? []);
    else dispatchGet();
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(atomValue?.generalInformation))
      setApplicationQuestions(atomValue?.generalInformation ?? []);
  }, [listenerGeneralInformationComponent]);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newGeneralInformation = getApplicationQuestionsAsBlob(
        responseGet.axiosResponse?.data ?? []
      );
      const newAtomValue = updateGeneralQuestions(
        atomValue,
        newGeneralInformation
      );

      setAtom(newAtomValue);
      setApplicationQuestions(newGeneralInformation);
    }
  }, [responseGet]);

  return applicationQuestions !== undefined &&
    applicationQuestions.length > 0 ? (
    <GeneralQuestions applicationQuestions={applicationQuestions} />
  ) : (
    <>LOADING...</>
  );
};

export default GeneralInformationMain;
