import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Font, Input, Modal } from "../../../TrueUI";
import { ModalPortalUserProps } from "../PortalUsersConst";
import { VerifyUserDto } from "../../../../dtos/verify-user-dto";

const ModalVerifyUser: FC<ModalPortalUserProps> = ({
  modalInfo,
  setModalInfo,
}) => {
  const [email, setEmail] = useState<string | null>();
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { responseGet, dispatchGet, validatorErrorResponse } =
    useApiGet<VerifyUserDto>(
      `api/PortalUser/VerifyUser?insuredId=${modalInfo?.insuredId}&userEmail=${email}`
    );

  const onCancelModal = () => {
    setModalInfo?.({
      ...modalInfo,
      isVerifyUserModalOpen: false,
      userId: null,
    });
  };

  useEffect(() => {
    if (
      responseGet.requestInstanceSuccessful &&
      conditionHasValue(responseGet?.axiosResponse?.data) &&
      !responseGet?.isLoading
    ) {
      if (validatorErrorResponse === null) {
        setErrorDetails(null);
        setModalInfo?.({
          ...modalInfo,
          isVerifyUserModalOpen: false,
          userId: responseGet?.responseData?.userId ?? 0,
          isModalOpen: responseGet?.responseData?.hasUserAccessToInsured,
          isNewUserModalOpen:
            !responseGet?.responseData?.hasUserAccessToInsured,
          userEmail: email ?? "",
        });
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responseGet]);

  useEffect(() => {
    setEmail(null);
  }, [modalInfo?.isVerifyUserModalOpen]);

  return (
    <Modal
      id={"portal__new_user_modal"}
      title={"Add User"}
      open={modalInfo?.isVerifyUserModalOpen}
      cancelEvent={onCancelModal}
      size={"md"}
      cancelButtonWithConfirmation
      saveEvent={() => dispatchGet()}
      showCloseButton
      showCancelTextButton
    >
      <Font whiteSpace="normal">
        Enter the email address of the user you wish to add. If the email is
        already being used, you will have the option to add access for the user,
        or try again with a different email.
      </Font>
      <div style={{ marginTop: "15px" }}>
        <Input
          label="Email Address"
          labelPosition="top"
          labelFontType="BOLD_CAPTION"
          id={"new-user-email"}
          name={"new-user-email"}
          value={email}
          onChangeRawValue={(value) => setEmail(value)}
          errorMessage={errorDetails?.userEmail}
          inputWidth="500px"
          maxLength={100}
        />
      </div>
    </Modal>
  );
};
export default ModalVerifyUser;
