import { FC } from "react";
import Font, { FontProperties } from "./Font";

const FontSuccess: FC<FontProperties> = (props) => {
  return (
    <Font textAlign="start" fontType={"BOLD_TITLE"} isSuccessFont {...props} />
  );
};

export default FontSuccess;
