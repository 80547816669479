import { PageIDEnums } from "../../../dtos/page-id-enums";
export function getPageId() {
  const path = window.location.pathname.split("/");
  switch (path[1]) {
    case "home":
      return PageIDEnums.HOME;

    case "user-permissions":
      return PageIDEnums.USER_PERMISSIONS;

    case "user-preferences":
      return PageIDEnums.USER_PREFERENCES;

    case "insured":
      return PageIDEnums.INSURED;

    case "admin-tools":
      return PageIDEnums.ADMIN_TOOLS;

    case "agency":
      return PageIDEnums.AGENCY;

    case "underwriting":
      return PageIDEnums.UNDERWRITING;

    case "billing":
      return PageIDEnums.BILLING;

    case "sandbox":
      return PageIDEnums.SANDBOX;

    case "style-guide":
      return PageIDEnums.STYLE_GUIDE;

    case "claims":
      return PageIDEnums.CLAIMS;

    default:
      return PageIDEnums.DEFAULT;
  }
}
