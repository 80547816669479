import { FC } from "react";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureHeadProperties = {
  children?: any;
};

const BaseGridStructureHead: FC<BaseGridStructureHeadProperties> = ({
  children,
}) => {
  return <div className={styles.true_base_grid_head}>{children}</div>;
};

export default BaseGridStructureHead;
