import { FC, useEffect } from "react";
import { usePermissions, useApiGet } from "../../../../../../hooks";
import {
  checkReadOnly,
  filterBillingRuleData,
  getBillingRuleFormData,
} from "./ModalBillingOptionsUtils";
import { ModalContentBillingOptionProps } from "../TypesBillingOptions";
import { PermissionsEnums } from "../../../../../../dtos/permissions-enums";
import ModalSectionCurrentPaymentPlan from "./ModalSectionCurrentPaymentPlan";
import { InsuredBillingRuleViewDto } from "../../../../../../dtos/insured-billing-rule-view-dto";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import ModalSectionBillingOptions from "./ModalSectionBillingOptions";
import ModalSectionAutoPayOptions from "./ModalSectionAutoPayOptions";
import ModalSectionAddProcessingOptions from "./ModalSectionAddProcessingOptions";

const ModalContentBillingOption: FC<ModalContentBillingOptionProps> = ({
  modalBillingOptionsConfig,
  billingContentConfig,
  setBillingContentConfig,
  billingRuleFormData,
  setBillingRuleFormData,
}) => {
  const userPermissions = usePermissions([
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
    PermissionsEnums.BILLING_USER,
    PermissionsEnums.BILLING_ADMIN,
  ]);
  const isReadOnly = checkReadOnly(userPermissions, modalBillingOptionsConfig);

  const { dispatchGet, responseGet } = useApiGet<InsuredBillingRuleViewDto>(
    `api/InsuredBillingRule/GetInsuredBillingRuleViewDataById?billingRuleId=${modalBillingOptionsConfig?.billingRuleId}`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const billingRuleDataFromRequest = responseGet?.responseData ?? null;
      setBillingContentConfig({
        isReadOnly: isReadOnly,
        billingRuleBillingMethodOptions:
          billingRuleDataFromRequest?.billingMethodOptions ?? [],
        initialBillingRuleData: filterBillingRuleData(
          ["billingMethodOptions", "accountNumbers"],
          billingRuleDataFromRequest
        ),
        accountNumbers: billingRuleDataFromRequest?.accountNumbers ?? [],
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (billingContentConfig?.initialBillingRuleData !== null) {
      setBillingRuleFormData(
        getBillingRuleFormData(
          billingContentConfig?.initialBillingRuleData ?? null
        )
      );
    }
  }, [billingContentConfig?.initialBillingRuleData]);

  useEffect(() => {
    if (modalBillingOptionsConfig?.isModalOpen === false) {
      setBillingRuleFormData(null);
      setBillingContentConfig(null);
    }
  }, [modalBillingOptionsConfig?.isModalOpen]);

  return (
    <>
      <ModalSectionCurrentPaymentPlan
        billingContentConfig={billingContentConfig}
      />
      <ModalSectionBillingOptions
        billingRuleFormData={billingRuleFormData}
        setBillingRuleFormData={setBillingRuleFormData}
        billingContentConfig={billingContentConfig}
        setBillingContentConfig={setBillingContentConfig}
      />
      {billingRuleFormData && (
        <ModalSectionAutoPayOptions
          billingRuleFormData={billingRuleFormData}
          setBillingRuleFormData={setBillingRuleFormData}
          billingContentConfig={billingContentConfig}
          setBillingContentConfig={setBillingContentConfig}
        />
      )}
      {billingRuleFormData && (
        <ModalSectionAddProcessingOptions
          billingRuleFormData={billingRuleFormData}
          setBillingRuleFormData={setBillingRuleFormData}
          billingContentConfig={billingContentConfig}
          setBillingContentConfig={setBillingContentConfig}
        />
      )}
    </>
  );
};

export default ModalContentBillingOption;
