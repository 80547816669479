import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../../../../hooks";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";
import ReallocateTableFooter from "./ReallocateTableFooter";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../../../TrueUI/Tables/tableFunctions";
import {
  BaseTable2Properties,
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../../../../../../TrueUI";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";
import { FooterTotalsProps } from "./AddReserveReallocateModal";
import { validateMaxAndMinNumberValue } from "../../../../../../../utilities/mathFunctions";

type ReallocateTableWrapperProps = {
  claimId?: number;
  reallocateDataList: NewReserveProps[];
  footerTotals: FooterTotalsProps;
  setReallocateDataList: (reallocateReserves: NewReserveProps[]) => void;
  setFooterTotals: (footerTotals: FooterTotalsProps) => void;
};

type ReallocateGridEditableColumns = "reallocate" | "comments";

type PropToEditEditableColumns = {
  property: ReallocateGridEditableColumns;
};

export type NewReserveProps = {
  reserveTypeId: number;
  reallocate: number | null;
  comments: string | null;
};

const reallocateGridName = "reallocateGridName";
const ReallocateTableWrapper: FC<ReallocateTableWrapperProps> = ({
  claimId,
  reallocateDataList,
  setReallocateDataList,
  setFooterTotals,
  footerTotals,
}) => {
  const { responseGet, dispatchGet } = useApiGet(
    `api/Financial/GetClaimReallocateReservesTable?claimId=${claimId}`
  );
  const [isReadyToRender, setIsReadyToRender] = useState<boolean>(false);
  const [reallocateTable, setReallocateTable] = useState<any>();
  const [reallocateDataItem, setReallocateDataItem] = useState<
    (NewReserveProps & PropToEditEditableColumns) | null
  >(null);

  useEffect(() => {
    if (claimId !== null) {
      dispatchGet();
    }
  }, [claimId]);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful && !responseGet.isLoading) {
      setReallocateTable(responseGet?.responseData);
    }
  }, [responseGet]);

  const setDefaultAllReallocateReserves = () => {
    const reserveTypeIdColumnIndex =
      getColumnIndexByFieldNameFromTable("ReserveTypeId");
    const allReserves = reallocateTable?.tableData?.data.map((row) => {
      return {
        reserveTypeId: row[reserveTypeIdColumnIndex],
        reallocate: 0,
        comments: "",
      };
    });
    setReallocateDataList(allReserves);
  };

  useEffect(() => {
    if (conditionHasValue(reallocateTable)) {
      setDefaultAllReallocateReserves();
      getTotalOutstandingReserves();
      setIsReadyToRender(true);
    }
  }, [reallocateTable]);

  useEffect(() => {
    if (reallocateDataList.length > 0) {
      getTotalReallocateReserves();
    }
  }, [reallocateDataList]);

  useEffect(() => {
    if (reallocateDataItem !== null) {
      const newReserves = reallocateDataList.map((reserve) => {
        if (
          Number(reserve.reserveTypeId) === reallocateDataItem.reserveTypeId
        ) {
          return {
            reserveTypeId: reserve.reserveTypeId,
            reallocate:
              reallocateDataItem.property === "reallocate"
                ? reallocateDataItem.reallocate
                : reserve.reallocate,
            comments:
              reallocateDataItem.property === "comments"
                ? reallocateDataItem.comments
                : reserve.comments,
          };
        }
        return reserve;
      });

      setReallocateDataList(newReserves);
    }
  }, [reallocateDataItem]);

  const getAddedCommentsOrtReallocateData = (
    options: ComputeForCellParameters<any>,
    property: ReallocateGridEditableColumns
  ) => {
    const reserveTypeId = Number(options?.row?.ReserveTypeId);
    const reallocate =
      property === "reallocate"
        ? validateMaxAndMinNumberValue(
            Number(options?.currentValue ?? 0),
            9999999.99,
            -9999999.99
          )
        : null;
    const comments = property === "comments" ? options.currentValue : null;
    setReallocateDataItem({
      ...reallocateDataItem,
      reserveTypeId,
      reallocate,
      comments,
      property,
    });
    return {
      value: reallocate,
    } as Partial<ConditionForCellResponse>;
  };

  const getColumnIndexByFieldNameFromTable = (fieldName: string) =>
    getDataIndexByColumnNameWithoutExternal(
      reallocateTable?.tableData?.columns ?? [],
      fieldName
    );

  const getTotalOutstandingReserves = () => {
    const outstandingReservesColumnIndex = getColumnIndexByFieldNameFromTable(
      "UnformattedOutstanding"
    );
    const totalSum = reallocateTable?.tableData?.data
      .map((subArray) => subArray[outstandingReservesColumnIndex])
      .filter((value) => value !== "" && value !== null)
      .reduce((sum, value) => sum + Number(value), 0);

    setFooterTotals({ ...footerTotals, totalOutstanding: totalSum });
    return totalSum;
  };

  const getTotalReallocateReserves = () => {
    const totalReallocate = reallocateDataList
      .filter((reserve) => reserve.reallocate !== null)
      .reduce(
        (sum, reserve) =>
          sum +
          (typeof reserve.reallocate === "number" &&
          !Number.isNaN(reserve.reallocate)
            ? reserve.reallocate
            : 0),
        0
      );

    setFooterTotals({ ...footerTotals, totalsReallocate: totalReallocate });

    return totalReallocate;
  };

  const customFooter = () => {
    return (
      <ReallocateTableFooter
        totalOutstandingReserves={footerTotals.totalOutstanding}
        totalReallocateReserves={footerTotals.totalsReallocate}
      />
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: reallocateGridName,
    columnsAndData: {
      columns: reallocateTable?.tableData?.columns ?? [],
      data: reallocateTable?.tableData?.data ?? [],
    },
    tableType: "standard",
    toolbarOptions: {
      hideToolbar: true,
      showExcelButton: false,
      showPDFButton: false,
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
    },
    columnOptions: [
      { fieldName: "ReserveType", width: 25 },
      {
        fieldName: "Outstanding",
        width: 25,
        align: "right",
      },
      {
        fieldName: "ReallocateReserves",
        width: 25,
        allowNegatives: true,
        align: "right",
        computeOnChange: {
          conditionForCell: [
            (options) =>
              getAddedCommentsOrtReallocateData(options, "reallocate"),
          ],
        },
      },
      {
        fieldName: "Comments",
        width: 25,
        computeOnChange: {
          conditionForCell: [
            (options) => getAddedCommentsOrtReallocateData(options, "comments"),
          ],
        },
      },
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
      },
    },
    footerOptions: {
      CustomFooterComponent: () => customFooter(),
    },
  };

  useBaseTable(tableConfiguration);

  return <>{isReadyToRender && <BaseTable2 name={reallocateGridName} />}</>;
};

export default ReallocateTableWrapper;
