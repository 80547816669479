import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { Input } from "../../TrueUI";

type BaseGridReadOnlyInputProps = {
  id: string;
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  selectWidth?: string;
  inputWidth?: string;
  labelFontType?: KeysAsType<FontsType>;
  inputFontType?: KeysAsType<FontsType>;
};

const BaseGridReadOnlyInput: (properties: BaseGridReadOnlyInputProps) => any = (
  props
) => {
  return (
    <div style={{ marginTop: "3px" }}>
      <Input
        labelFontType={props?.labelFontType}
        inputFontType={props?.inputFontType}
        id={`true-filter-for-table-${props?.id}`}
        name={`filter-dropdown-${props?.id}`}
        variant={"filled"}
        label={props?.labelText ?? "Show:"}
        value={props.defaultValue ?? ""}
        readOnly={true}
        labelPosition={props?.labelPosition ?? "start"}
        className={`true-filter-for-table-class-${props?.id}`}
        inputWidth={props?.selectWidth ? props?.selectWidth : "fit-content"}
      />
    </div>
  );
};

export default BaseGridReadOnlyInput;
