import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { SelectOptions } from "../../../../dtos/select-options";
import { usePermissions } from "../../../../hooks";
import { Select } from "../../../TrueUI";

type SRecurringPaymentsTableFilterProps = {
  options: Partial<SelectOptions>[] | SelectOptions[];
  id: string;
  value?: any;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  selectWidth?: string;
  onChange?: (e: any) => void;
  permissions?: PermissionsEnums[];
};

const RecurringPaymentsTableFilter: (
  properties: SRecurringPaymentsTableFilterProps
) => any = (props) => {
  const hasPermission = usePermissions(props?.permissions ?? []).hasPermission;

  return (
    <Select
      id={`true-filter-for-table-${props?.id}`}
      name={`filter-dropdown-${props?.id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={props?.labelText ?? "Show:"}
      labelPosition={props?.labelPosition ?? "start"}
      options={props?.options ?? []}
      value={props?.value ?? "all"}
      type={"tableFilter"}
      inputWidth={props?.selectWidth ? props?.selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        props?.onChange?.(value);
      }}
      disabled={!hasPermission}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
    />
  );
};

export default RecurringPaymentsTableFilter;
