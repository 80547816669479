import { FC, useEffect, useState } from "react";
import { Col, Input, InputDate, Modal, Row, Select } from "../../TrueUI";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { AgencyAppointmentModalProps } from "./Appointments";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { AgencyProgramDto } from "../../../dtos/agency-program-dto";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { StatusEnums } from "../../../dtos/status-enums";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { useApiGet, usePermissions } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type ModalAgencyAppointmentProps = {
  errorDetails: any;
  appointmentModalValues: AgencyAppointmentModalProps | null;
  setAppointmentModalValues: (value: AgencyAppointmentModalProps) => void;
};

const statusOptions: Partial<SelectOptions>[] = FromEnumToArrayWithExceptions(
  StatusEnums,
  [StatusEnums.DELETED]
).map((enumType) => ({
  displayName: enumType?.key ?? "",
  stringValue: enumType?.value,
}));

const ModalAgencyAppointment: FC<ModalAgencyAppointmentProps> = ({
  errorDetails,
  appointmentModalValues,
  setAppointmentModalValues,
}) => {
  const { hasPermission } = usePermissions([
    PermissionsEnums.AGENCY_ADMIN,
    PermissionsEnums.TRUE_ADMIN,
  ]);
  const [agencyProgramValues, setAgencyProgramValues] =
    useState<AgencyProgramDto>();
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [programOptions, setProgramOptions] = useState<SelectOptions[]>();

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    "api/program/GetProgramsByEndDate"
  );

  const setDefaultValues = () => {
    const appointment = appointmentModalValues?.agencyAppointment;
    if (isEmptyValue(appointment?.agencyProgramId))
      setAgencyProgramValues({
        agencyId: appointment?.agencyId,
        status: StatusEnums.ACTIVE,
        effectiveDate: new Date(),
        expirationDate: null,
        defaultRateNew: 0,
        discountOverride: 0,
        defaultRateRenewal: 0,
      } as AgencyProgramDto);
    else
      setAgencyProgramValues({
        status: appointment?.status,
        agencyId: appointment?.agencyId,
        programId: appointment?.programId,
        effectiveDate: appointment?.effectiveDate,
        expirationDate: appointment?.expirationDate,
        defaultRateNew: appointment?.defaultRateNew,
        agencyProgramId: appointment?.agencyProgramId,
        discountOverride: appointment?.discountOverride,
        defaultRateRenewal: appointment?.defaultRateRenewal,
      } as AgencyProgramDto);
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setDefaultValues();
      setProgramOptions(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  return (
    <>
      <Modal
        id="agencyAppointmentModal"
        title="Agency Appointment"
        open={appointmentModalValues?.isOpen}
        showCloseButton
        showCancelTextButton
        cancelButtonWithConfirmation
        closeEvent={(e) =>
          setAppointmentModalValues({
            ...appointmentModalValues,
            isOpen: e,
          })
        }
        saveDisabled={!hasPermission}
        saveEvent={() =>
          setAppointmentModalValues({
            ...appointmentModalValues,
            agencyAppointment: agencyProgramValues,
            isSaving: true,
          })
        }
        deleteButtonWithConfirmation
        deleteDisabled={
          !hasPermission ||
          isEmptyValue(
            appointmentModalValues?.agencyAppointment?.agencyProgramId
          )
        }
        deleteEvent={() => {
          setAppointmentModalValues({
            ...appointmentModalValues,
            agencyAppointment: {
              ...(agencyProgramValues as AgencyProgramDto),
              status: StatusEnums.DELETED,
            },
            isDeleting: true,
          });
        }}
      >
        {!isEmptyValue(agencyProgramValues) && (
          <div>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 13, lg: 13, xl: 13 }}>
                <Select
                  tabIndex={20}
                  key={"programName"}
                  id="id-program-name"
                  name="name-program-name"
                  label="Program Name"
                  labelFontType="BOLD_CAPTION"
                  options={programOptions ?? []}
                  optionsMaxHeight="250px"
                  value={agencyProgramValues?.programId}
                  onChange={(option) =>
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      programId: option,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <Select
                  tabIndex={21}
                  key={"status"}
                  id="id-status"
                  name="name-status"
                  label="Status"
                  labelFontType="BOLD_CAPTION"
                  options={statusOptions}
                  value={agencyProgramValues?.status}
                  onChange={(option) =>
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      status: option,
                    })
                  }
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
                <Input
                  tabIndex={22}
                  key={"defaultRateNew"}
                  id="id-default-rate-new"
                  name="name-default-rate-new"
                  label="Default Commission Rate - New Business"
                  type="number"
                  minNumericValue={0}
                  maxNumericValue={999.99}
                  value={agencyProgramValues?.defaultRateNew}
                  errorMessage={errorDetails?.defaultRateNew}
                  onChangeRawValue={(value) =>
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      defaultRateNew: value,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <InputDate
                  tabIndex={23}
                  id="id-effective-date"
                  name="name-effective-date"
                  key={"effectiveDate"}
                  label="Effective Date"
                  value={agencyProgramValues?.effectiveDate}
                  errorMessage={errorDetails?.effectiveDate}
                  onChangeRawValue={(dateValue) => {
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      effectiveDate: dateValue,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={20}
              horizontalAlign="space-between"
            >
              <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
                <Input
                  tabIndex={24}
                  key={"defaultRateRenewal"}
                  id="id-default-rate-renewal"
                  name="name-default-rate-renewal"
                  label="Default Commission Rate - Renewals"
                  type="number"
                  minNumericValue={0}
                  maxNumericValue={999.99}
                  value={agencyProgramValues?.defaultRateRenewal}
                  errorMessage={errorDetails?.defaultRateRenewal}
                  onChangeRawValue={(value) =>
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      defaultRateRenewal: value,
                    })
                  }
                />
              </Col>
              <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
                <InputDate
                  tabIndex={25}
                  id="id-expiration-date"
                  name="name-expiration-date"
                  key={"expirationDate"}
                  label="Expiration Date"
                  value={agencyProgramValues?.expirationDate}
                  errorMessage={errorDetails?.expirationDate}
                  onChangeRawValue={(dateValue) => {
                    setAgencyProgramValues({
                      ...(agencyProgramValues as AgencyProgramDto),
                      expirationDate: dateValue,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <DialogConfirmation
        id="agencyAppointmentDialogConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this appointment?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          setAppointmentModalValues({
            ...appointmentModalValues,
            isDeleting: true,
            isOpen: false,
          });
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default ModalAgencyAppointment;
