import { Box } from "@mui/material";
import { FC, useState } from "react";
import { AlertEnums } from "../../../dtos/alert-enums";
import style from "./alert.module.css";

export type AlertProps = {
  message: string;
  type?: number;
  showCloseIcon?: boolean;
  onClickMessage?: () => void;
  onClickClose?: () => void;
  modalTitle?: string;
  modalContent?: string | JSX.Element | null;
  modalHeight?: number;
};

const Alert: FC<AlertProps> = ({
  type = AlertEnums.INFORMATION,
  message,
  showCloseIcon = true,
  onClickMessage,
  onClickClose,
}) => {
  const [display, setDisplay] = useState<boolean>(true);

  const _onClickClose = () => {
    setDisplay(!display);
    onClickClose?.();
  };

  return (
    <>
      {display && (
        <Box
          className={`${style.true_alert_container} ${
            type === AlertEnums.WARNING
              ? style.true_alert_warning
              : style.true_alert_information
          }`}
        >
          <Box className={style.true_alert_icon}>
            {type === AlertEnums.WARNING ? (
              <span
                className={`${style.true_alert_icon_span} k-icon k-i-exclamation-circle`}
              ></span>
            ) : (
              <span
                className={`${style.true_alert_icon_span} k-icon k-i-info-circle`}
              ></span>
            )}
          </Box>
          <Box className={style.true_alert_message} onClick={onClickMessage}>
            {message}
          </Box>
          {showCloseIcon && (
            <Box
              className={style.true_alert_close_icon}
              onClick={_onClickClose}
            >
              <span
                className={`${style.true_alert_close_icon_span} k-icon k-i-x-outline`}
              ></span>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Alert;
