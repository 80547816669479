import { Theme } from "../../../../../../media/themeTypes";

export const modalScheduleRatingHeaderStyles = (theme: Theme) => ({
  backgroundColor: theme?.SECONDARY_BACKGROUND,
  ".MuiGrid-item .MuiTypography-body1": { color: theme?.PRIMARY_BACKGROUND },
});

export const modalScheduleRatingRowStyles = (theme: Theme) => ({
  backgroundColor: theme?.PRIMARY_BACKGROUND,
  ".schedule-rating-modal-text": {
    paddingTop: "5px",
  },
});
