import { Paper } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";

export type StyleBoxPoints =
  | 0
  | 0.5
  | 1
  | 2
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50;

export type ElevationPoints = 0 | 1 | 2 | 5;

type StyleBoxProps = {
  className?: string;
  elevation?: ElevationPoints;
  width?: string;
  height?: string;
  heightFillAvailable?: boolean;
  //margin
  margin?: StyleBoxPoints;
  marginRight?: StyleBoxPoints;
  marginLeft?: StyleBoxPoints;
  marginTop?: StyleBoxPoints;
  marginBottom?: StyleBoxPoints;
  //padding
  padding?: StyleBoxPoints;
  paddingRight?: StyleBoxPoints;
  paddingLeft?: StyleBoxPoints;
  paddingTop?: StyleBoxPoints;
  paddingBottom?: StyleBoxPoints;
  //display
  display?: "flex" | "inline-flex" | "block" | "inline" | "none";
  position?: "static" | "relative" | "fixed" | "absolute" | "sticky";
  right?: StyleBoxPoints;
  left?: StyleBoxPoints;
  top?: StyleBoxPoints;
  bottom?: StyleBoxPoints;
  flexWrap?: "nowrap" | "wrap" | "unset";
  overflowY?: "auto" | "scroll" | "hidden";
  verticalAlign?: "start" | "center" | "end";
  horizontalAlign?: "start" | "center" | "end" | "space-between";
  //border
  showBorder?: boolean;
  borderRadius?: StyleBoxPoints;
  overrideBorderColor?: string;
  overrideBackgroundColor?: string;
  overrideTextColor?: string;
  secondary?: boolean;
  children?: any;
};

const StyleBox: FC<StyleBoxProps> = ({
  className,
  elevation = 0,
  width = "100%",
  height,
  heightFillAvailable,
  margin = 1,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  padding = 1,
  paddingRight,
  paddingLeft,
  paddingTop,
  paddingBottom,
  display = "flex",
  flexWrap = "unset",
  overflowY = "auto",
  position,
  right,
  left,
  top,
  bottom,
  verticalAlign = "center",
  horizontalAlign = "center",
  showBorder = false,
  borderRadius = 0,
  overrideBorderColor,
  overrideBackgroundColor,
  overrideTextColor,
  secondary = false,
  children,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const getValue = (value) => (value ? `${value}px` : undefined);

  const getSize = (
    value?: string,
    fitContent?: boolean,
    fillAvailable: boolean = false
  ) =>
    value
      ? value
      : fillAvailable
      ? "-webkit-fill-available"
      : fitContent
      ? "fit-content"
      : undefined;

  return (
    <Paper
      className={`true_style_box ${className}`}
      elevation={elevation}
      sx={{
        width: getSize(width, true),
        height: getSize(height, true, heightFillAvailable),
        //margin
        margin: getValue(margin),
        marginRight: getValue(marginRight),
        marginLeft: getValue(marginLeft),
        marginTop: getValue(marginTop),
        marginBottom: getValue(marginBottom),
        //padding
        padding: getValue(padding),
        paddingRight: getValue(paddingRight),
        paddingLeft: getValue(paddingLeft),
        paddingTop: getValue(paddingTop),
        paddingBottom: getValue(paddingBottom),
        //display
        display: display,
        position: position,
        right: getValue(right),
        left: getValue(left),
        top: getValue(top),
        bottom: getValue(bottom),
        alignItems: verticalAlign,
        justifyContent: horizontalAlign,
        alignSelf: "center",
        justifySelf: "center",
        overflowWrap: "anywhere",
        flexWrap: flexWrap,
        overflowY: overflowY,
        //border
        borderWidth: showBorder ? "1px" : 0,
        borderStyle: showBorder ? "solid" : "none",
        borderRadius: `${borderRadius}px`,
        //colors
        borderColor: `${overrideBorderColor ?? theme?.BORDER_COLOR} !important`,
        backgroundColor: `${
          overrideBackgroundColor ??
          (secondary ? theme?.SECONDARY_BACKGROUND : theme?.PRIMARY_BACKGROUND)
        } !important`,
        color: `${overrideTextColor ?? theme?.TEXT_COLOR} !important`,
      }}
    >
      {children}
    </Paper>
  );
};

export default StyleBox;
