import { TimeZoneOffsetsEnums } from "../dtos/time-zone-offsets-enums";
import { getUniversalTime } from "./dateFunctions";
const dstStartEndsByYear = [
  { year: 2023, monthStart: 3, monthEnd: 11, dayStart: 12, dayEnd: 5 },
  { year: 2024, monthStart: 3, monthEnd: 11, dayStart: 10, dayEnd: 3 },
  { year: 2025, monthStart: 3, monthEnd: 11, dayStart: 9, dayEnd: 2 },
  { year: 2026, monthStart: 3, monthEnd: 11, dayStart: 8, dayEnd: 1 },
];

const getDstForCurrentYear = () =>
  dstStartEndsByYear?.find((dst) => dst?.year === new Date().getUTCFullYear());

export const getOffsetHoursWithDST = (timeZone: string | null) => {
  if (timeZone === null) return 0;

  if (timeZone.endsWith("_DT") || timeZone.endsWith("_ST")) {
    return parseInt(TimeZoneOffsetsEnums[timeZone]);
  } else {
    const dstData = getDstForCurrentYear() ?? dstStartEndsByYear[1];
    const currentDate = new Date();
    const month = currentDate.getUTCMonth() + 1; // JavaScript months are 0-based

    const day = currentDate.getUTCDate();

    // Use Cases
    if (month === dstData.monthStart) {
      if (day >= dstData.dayStart) {
        return parseInt(TimeZoneOffsetsEnums[timeZone + "_DT"]);
      }
    }

    if (month === dstData.monthEnd) {
      if (day <= dstData.dayEnd) {
        return parseInt(TimeZoneOffsetsEnums[timeZone + "_DT"]);
      }
    }

    if (month > dstData.monthStart && month < dstData.monthEnd) {
      return parseInt(TimeZoneOffsetsEnums[timeZone + "_DT"]);
    }

    return parseInt(TimeZoneOffsetsEnums[timeZone + "_ST"]);
  }
};

export const getDateFromUtcToUserTimeZone = (
  date: string | Date,
  timeZone: string
) => {
  const currentDate = new Date(date);
  const offsetHours = getOffsetHoursWithDST(timeZone);
  currentDate.setHours(currentDate.getHours() + offsetHours);
  return currentDate;
};

export const getCurrentDateByTimeZone = (timeZone: string) => {
  const utc = getUniversalTime();
  const offsetHours = getOffsetHoursWithDST(timeZone);
  utc.setHours(utc.getHours() + offsetHours);
  return utc;
};
