import { FC, useState } from "react";
import { CommonProps, ItemProps } from "./HierarchyList";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./HierarchyList.module.css";
import RegularItem from "./RegularItem";
import Switch from "../../Checkbox/Switch";
import Font from "../../Typography/Font";

type CollapseItemProps = ItemProps & CommonProps;

const CollapseItem: FC<CollapseItemProps> = (props) => {
  const {
    displayName,
    description,
    childrenItems,
    isCheckboxList,
    onCheckboxChange,
    itemId,
    fontType,
    isChecked,
    isDisabled,
    showTooltip,
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={"parent_item_container_collapse"}>
      <div className={"item_title"} onClick={toggleCollapse}>
        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        {isCheckboxList ? (
          <Switch
            control={"checkbox"}
            isDisabled={isDisabled}
            inputWidth="auto"
            className="checkbox_item_list"
            isChecked={isChecked}
            onChangeIsChecked={(value) => onCheckboxChange?.(itemId, value)}
          />
        ) : null}
        <Font
          fontType={fontType}
          showTooltip={showTooltip}
          tooltipTitle={description}
          tooltipPosition="right"
          truncate
        >
          {displayName}
        </Font>
      </div>
      <div className={`parent_item_content ${isOpen ? "open" : ""}`}>
        {childrenItems?.map((child) => (
          <RegularItem
            {...child}
            isCheckboxList={isCheckboxList}
            onCheckboxChange={onCheckboxChange}
            fontType={fontType}
            showTooltip={showTooltip}
            isChild
            key={`regular-item-${child?.itemId}`}
          />
        ))}
      </div>
    </div>
  );
};
export default CollapseItem;
