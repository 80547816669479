import { FC, ReactNode, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Alert, { AlertColor } from "@mui/material/Alert";
import { AlertTitle, IconButton } from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SiteNotification.css";
import { useSiteNotificationProps } from "../../../hooks";
import { SiteNotificationTypeEnums } from "../../../dtos/site-notification-type-enums"

export type SiteNotificationProperties = {
  notificationTitle?: string;
  align?: "left" | "center" | "right";
  componentMessage: ReactNode | string;
  actionClose?: () => void;
  isOpen: boolean;
  expireTime: number;
  notificationType?: SiteNotificationTypeEnums;
};

const SiteNotification: FC = () => {
  const { notificationProps, setNotificationOpenValue } =
  useSiteNotificationProps();

  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    
    if (notificationProps?.isOpen) {
      const timeOut = setTimeout(() => {
        setNotificationOpenValue(false);
      }, notificationProps?.expireTime);
      setTimer(timeOut);
    }
  }, [notificationProps?.isOpen]);

  const onCloseNotification = () => {
    clearTimeout(timer);
    notificationProps?.actionClose?.();
    setNotificationOpenValue(false);
  };

  const componentMessageAlignment = () => {
    switch (notificationProps?.align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "flex-start";
    }
  };

  const notificationType = () => {
    switch (notificationProps?.notificationType) {
      case SiteNotificationTypeEnums.ERROR:
        return "error";
      case SiteNotificationTypeEnums.WARNING:
        return "warning";
      case SiteNotificationTypeEnums.INFO:
        return "info";
      case SiteNotificationTypeEnums.SUCCESS:
        return "success";
      default:
        return "warning";
    }
  };

  return (
    <Drawer
      variant="temporary"
      ModalProps={{ hideBackdrop: true }}
      open={notificationProps?.isOpen}
      anchor="top"
      PaperProps={{ elevation: 5, sx: { backgroundColor: "transparent" } }}
      sx={{ zIndex: 10000, height: "fit-content" }}
    >
      <Alert
        severity={notificationType() as AlertColor}
        sx={{
          borderRadius: 0,
          alignItems: "center"
        }}
        action={
          <IconButton onClick={() => onCloseNotification()}>
            <CloseIcon />
          </IconButton>
        }
      >
        {notificationProps?.notificationTitle && (
          <AlertTitle>{notificationProps?.notificationTitle}</AlertTitle>
        )}
        <Box
          className="siteNotification"
          sx={{
            justifyContent: componentMessageAlignment(),
          }}
        >
          {notificationProps?.componentMessage}
        </Box>
      </Alert>
    </Drawer>
  );
};

export default SiteNotification;
