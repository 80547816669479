import { FC, useEffect, useState } from "react";
import "../PortalUser.module.css";
import { PermissionInfoDto } from "../../../../dtos/permission-info-dto";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { Input, Switch, TitleBold } from "../../../TrueUI";
import { NewUserPermissionsSectionProps } from "../PortalUsersConst";
import { globalOptions } from "../../../../GlobalAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { NewPortalInsuredUser } from "../UsersAtom";

const NewUserPermissionsSection: FC<NewUserPermissionsSectionProps> = ({
  insuredName,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const [permissionList, setPermissionList] = useState<
    PermissionInfoDto[] | null
  >();
  const [newPortalInsuredUser, setNewPortalInsuredUser] =
    useRecoilState(NewPortalInsuredUser);

  const { responseGet, dispatchGet } = useApiGet<PermissionInfoDto[]>(
    `api/PortalUser/GetPermissionsDescription`
  );

  const onCheckChange = (permissionId, isChecked) => {
    if (isChecked) {
      const allPermissions = [
        ...(newPortalInsuredUser?.permissions ?? []),
        { permissionId: permissionId, hasPermission: isChecked },
      ];
      setNewPortalInsuredUser({
        ...newPortalInsuredUser,
        permissions: allPermissions ?? [],
      });
    } else {
      const otherPermissions = newPortalInsuredUser?.permissions?.filter(
        (p) => p.permissionId !== permissionId
      );
      setNewPortalInsuredUser({
        ...newPortalInsuredUser,
        permissions: otherPermissions ?? [],
      });
    }
  };

  const reviewIfChecked = (permissionId): boolean => {
    const selectedPermissions = newPortalInsuredUser?.permissions?.find(
      (p) => p.permissionId === permissionId
    );
    return selectedPermissions?.hasPermission ?? false;
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPermissionList(responseGet?.responseData);
    }
  }, [responseGet]);

  return (
    <div className={"permissions_container"}>
      <div className={"permissions_title"}>
        <TitleBold display="block" textAlign="start">
          PERMISSIONS
        </TitleBold>
      </div>
      <div className={"permissions_section"}>
        <Input
          id={"permissions-program-name"}
          name={"permissions-program-name"}
          label={"Program"}
          labelPosition={"top"}
          value={localOptions?.selectedProgram?.programName}
          readOnly
        />
        <Input
          id={"permissions-insured-name"}
          name={"permissions-insured-name"}
          label={"Insured"}
          labelPosition={"top"}
          value={insuredName}
          readOnly
        />
        <div className={"permissions_checks"}>
          {permissionList?.map((permission) => {
            return (
              <Switch
                key={`permission-id-${permission.permissionId}`}
                label={permission?.permissionDescription}
                labelPlacement={"end"}
                control={"checkbox"}
                labelFontType={"BODY"}
                isChecked={reviewIfChecked(permission?.permissionId)}
                onChangeIsChecked={(isChecked) => {
                  onCheckChange(permission?.permissionId, isChecked);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default NewUserPermissionsSection;
