export const TABBABLE_CLASS_NAME = "tabbable_element";
export const MODAL_CONTENT_CONTAINER_ID = "modal_content_container";

export const focusNextElement = (id: string) => {
  const tabbableInputs = [
    ...(document.getElementsByClassName(TABBABLE_CLASS_NAME) ?? []),
  ] as HTMLElement[];

  const elementIndex = tabbableInputs.findIndex((i) => i.id === id);

  if (elementIndex > -1 && elementIndex !== tabbableInputs?.length - 1) {
    const firstMatch = tabbableInputs?.at(elementIndex + 1);
    firstMatch?.focus();
  }
};

export const focusFirstChildElement = (id: string) => {
  const parentElement = document.getElementById(id);

  const firstInput = parentElement
    ?.getElementsByClassName(TABBABLE_CLASS_NAME)
    .item(0) as HTMLElement;

  if (firstInput !== null && firstInput !== undefined) {
    firstInput.focus();
  }
};

export const focusElement = (id: string) => {
  const element = document.getElementById(id);

  if (element !== null && element !== undefined) {
    element.focus();
  }
};
