import { FC, useEffect, useState } from "react";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import Font from "../Typography/Font";
import style from "./ticker.module.css";
import WarningIndicator from "./WarningIndicator";

export type TickerProps = {
  id: string;
  name: string;
  className?: string;
  value: number;
  valueFontType?: KeysAsType<FontsType>;
  originalValue: number;
  valueDifferenceFontType?: KeysAsType<FontsType>;
  arrowFontType?: KeysAsType<FontsType>;
  showArrowOnly?: boolean;
  showWarningIcon?: boolean;
  addIconSpace?: boolean;
  disableThousandSeparator?: boolean;
  warningTooltip?: string | React.ReactNode;
};

const Ticker: FC<TickerProps> = ({
  id,
  name,
  className,
  value,
  valueFontType,
  originalValue,
  valueDifferenceFontType,
  arrowFontType,
  showArrowOnly,
  disableThousandSeparator,
  addIconSpace = false,
  showWarningIcon = false,
  warningTooltip,
}) => {
  const [valueDifference, setValueDifference] = useState<number>(0);

  const getIndicatorDisplay = () => {
    if (valueDifference < 0) return <SouthIcon fontSize="inherit" />;
    return <NorthIcon fontSize="inherit" />;
  };

  const getValueDifferenceDisplay = () => {
    if (valueDifference < 0) {
      const absValue = Math.abs(valueDifference);
      return `(${
        !disableThousandSeparator ? absValue.toLocaleString() : absValue
      })`;
    }

    return !disableThousandSeparator
      ? valueDifference.toLocaleString()
      : valueDifference;
  };

  useEffect(() => {
    setValueDifference(value - originalValue);
  }, [value, originalValue]);

  return (
    <div id={id} className={`${style.ticker_container} ${className}`}>
      <div
        id="ticker-value-container"
        className={`${style.ticker_inner_container} ${style.ticker_value_container}`}
      >
        <Font
          fontType={valueFontType ?? "BOLD_BODY"}
          trueElement={`${name}-value`}
        >
          {!disableThousandSeparator ? value.toLocaleString() : value}
        </Font>
      </div>
      {valueDifference !== 0 && (
        <>
          <div
            id="ticker-indicator-container"
            className={`${style.ticker_inner_container} ${
              !showArrowOnly && style.ticker_indicator_container_margin_right
            }`}
          >
            <Font
              fontType={arrowFontType ?? "BOLD_BODY"}
              display="flex"
              trueElement={`${name}-ticker-indicator`}
              isSuccessFont={valueDifference >= 0}
              isErrorFont={valueDifference < 0}
            >
              {getIndicatorDisplay()}
            </Font>
            {addIconSpace && (
              <NorthIcon
                sx={{ fontSize: "20px", color: "transparent !important" }}
              />
            )}
            {showWarningIcon && (
              <WarningIndicator name={name} tooltipTitle={warningTooltip} />
            )}
          </div>
          {!showArrowOnly && (
            <div
              id="ticker-value-difference-container"
              className={style.ticker_inner_container}
            >
              <Font
                fontType={valueDifferenceFontType ?? "BOLD_BODY"}
                trueElement={`${name}-value-difference`}
                isSuccessFont={valueDifference > 0}
                isErrorFont={valueDifference < 0}
              >
                {getValueDifferenceDisplay()}
              </Font>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Ticker;
