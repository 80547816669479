import { FC, useState } from "react";
import { Col, Modal, Row } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { ModalSpecificWaiverProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import WaiverTable from "./WaiverTable";

const ModalSpecificWaiverTable: FC<ModalSpecificWaiverProps> = ({
  showModal,
  specificWaiverFormUI,
  closeEvent,
  openFormEvent,
  readOnly,
}) => {
  const [tableTotal, setTableTotal] = useState<number>(0.0);

  const closeModalEvent = () => {
    closeEvent?.(tableTotal);
  };

  return (
    <Modal
      size="lg"
      id={"modal-specific-waiver-table"}
      title="Specific Waiver of Subrogation"
      open={showModal}
      showCloseButton={true}
      cancelEvent={closeModalEvent}
    >
      <Row {...rowWithNoMarginNorGutter}>
        <Col {...colWithNoMarginNorGutter}>
          {showModal && specificWaiverFormUI?.policyWaiverTable ? (
            <WaiverTable
              readOnly={readOnly}
              tableTotal={tableTotal}
              setTableTotal={setTableTotal}
              openFormEvent={openFormEvent}
              specificWaiverFormUI={specificWaiverFormUI}
            />
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSpecificWaiverTable;
