import { FC } from "react";
import style from "./ExposureTable.module.css";

type ExposureTableHeaderProps = {
  isEndorsementOrAudit?: boolean;
  showNetRateInQuote?: boolean | null;
};

const ExposureTableHeader: FC<ExposureTableHeaderProps> = ({
  isEndorsementOrAudit,
  showNetRateInQuote,
}) => {
  const getDescriptionClassName = () => {
    if (isEndorsementOrAudit) {
      return `${style.exposure_table_column_header} ${style.exposure_table_width_10}`;
    }
    if (showNetRateInQuote) {
      return `${style.exposure_table_column_header} ${style.exposure_table_width_25}`;
    }

    return `${style.exposure_table_column_header} ${style.exposure_table_width_30}`;
  };

  return (
    <div className={style.exposure_table_column_header_container}>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      >
        <span className={style.exposure_table_column_header_text}>Loc #</span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
      >
        <span className={style.exposure_table_column_header_text}>
          Class Code
        </span>
      </div>
      <div className={getDescriptionClassName()}>
        <span className={style.exposure_table_column_header_text}>
          Description
        </span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      >
        <span className={style.exposure_table_column_header_text}>Hazard</span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
      >
        <span className={style.exposure_table_column_header_text}>
          Rate Basis
        </span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      >
        <span className={style.exposure_table_column_header_text}>EEs #</span>
      </div>
      {isEndorsementOrAudit && (
        <div
          className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
        >
          <span className={style.exposure_table_column_header_text}>
            Previous Exposure
          </span>
        </div>
      )}
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
      >
        <span className={style.exposure_table_column_header_text}>
          Exposure
        </span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      >
        <span className={style.exposure_table_column_header_text}>
          Base Rate
        </span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      >
        <span className={style.exposure_table_column_header_text}>Rate</span>
      </div>
      {!isEndorsementOrAudit && showNetRateInQuote && (
        <div
          className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
        >
          <span className={style.exposure_table_column_header_text}>
            Net Rate
          </span>
        </div>
      )}
      {isEndorsementOrAudit && (
        <div
          className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
        >
          <span className={style.exposure_table_column_header_text}>
            Previous Premium
          </span>
        </div>
      )}
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_10}`}
      >
        <span className={style.exposure_table_column_header_text}>
          {isEndorsementOrAudit ? "Endorsed Premium" : "Premium"}
        </span>
      </div>
      <div
        className={`${style.exposure_table_column_header} ${style.exposure_table_width_5}`}
      ></div>
    </div>
  );
};

export default ExposureTableHeader;
