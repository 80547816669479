export const modalChangeClaimStatusStyle = () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "25px",
  ".right-buttons-container": {
    display: "flex",
    columnGap: "25px",
  },
});

export const changeStatusModal = () => ({
  marginTop: "5px",
  "#OpenTasksTable": {
    height: "215px",
    ".true_ui_base_table_container": {
      height: "100%",
    },
  },
  "#ClaimStatusHistoryTable": {
    height: "215px",
    ".true_ui_base_table_container": {
      height: "100%",
    },
  },
});

export const openTasksContainer = {
  marginTop: "20px",
  marginBottom: "20px",
};

export const openTasksTabTitle = {
  pr: "15px",
};

export const openTasksTableTitleContainer = {
  ml: "10px",
  mb: "10px",
  "#OpenTasksTable": {
    height: "auto",
  },
};

export const openTasksLinkContainer = {
  my: "5px",
  mx: "35px",
  display: "flex",
  justifyContent: "flex-end",
  width: "auto",
  a: {
    textDecoration: "underline",
  },
};
