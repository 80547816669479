import { FC } from "react";
import { ApplicationQuestionTypeEnum } from "../../../../dtos/application-question-type-enum";
import { QuestionInputYesNo } from "../../../TrueUI/Inputs/QuestionInputs";
import { PolicyApplicationQuestionBlob } from "../../../../dtos/policy-application-question-blob";

export type GeneralQuestionSelector = {
  applicationQuestion: PolicyApplicationQuestionBlob;
  onChangeQuestion: (
    checked: boolean,
    textBoxValue: string,
    applicationQuestion: PolicyApplicationQuestionBlob
  ) => void;
  index?: number;
};

const GeneralQuestionSelector: FC<GeneralQuestionSelector> = ({
  applicationQuestion,
  onChangeQuestion,
  index,
}) => {
  switch (applicationQuestion.questionType) {
    case ApplicationQuestionTypeEnum.MULTIPLE_CHOICE:
      return <></>;
    case ApplicationQuestionTypeEnum.TEXTBOX:
      return <></>;
    case ApplicationQuestionTypeEnum.TRUE_FALSE:
      return <></>;
    default:
      return (
        <QuestionInputYesNo
          tabIndex={index}
          switchIdName={`general-information-yes-no-switch-${applicationQuestion.questionNumber}`}
          textBoxIdName={`general-information-yes-no-input-${applicationQuestion.questionNumber}`}
          switchIsChecked={applicationQuestion.isChecked ?? false}
          textBoxValue={applicationQuestion.comment ?? ""}
          switchLabel={`${applicationQuestion.questionNumber}. ${applicationQuestion.questionText}`}
          errorMessage={applicationQuestion.errorMessages}
          onChangeRawValue={({ checked, textBoxValue }) =>
            onChangeQuestion(checked, textBoxValue, applicationQuestion)
          }
        />
      );
  }
};

export default GeneralQuestionSelector;
