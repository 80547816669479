import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { CaptionBold, Font } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  PolicyQuoteHeaderUIProps,
  PolicyQuoteInsuredAsProp,
} from "./PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { Box } from "@mui/material";
import { policyQuoteHeaderStyles } from "./PolicyQuoteStyles";

const defaultPolicyQuoteHeaderUI = {
  policyStatus: "",
  quoteStatus: "",
  policyDates: "",
  policyType: "",
  policyLimits: "",
};

const PolicyQuoteHeader: FC<PolicyQuoteInsuredAsProp> = ({ tabKey }) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const [policyQuoteHeaderUI, setPolicyQuoteHeaderUI] =
    useState<PolicyQuoteHeaderUIProps>(defaultPolicyQuoteHeaderUI);

  const listenerPolicyQuoteHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteHeaderComponent")
  );

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = atomValue?.policyQuoteInformation?.policyQuote;
    const quote = atomValue?.policyQuoteInformation?.policyQuote?.quote;
    const newPolicyQuoteHeaderUI: PolicyQuoteHeaderUIProps = {
      policyStatus: policyQuote?.policyStatus?.description ?? "NEW SUBMISSION",
      quoteStatus: quote?.quoteStatus?.description ?? "NEW",
      policyDates: `${FormattingDate(quote?.effectiveDate)} - ${FormattingDate(
        quote?.expirationDate
      )}`,
      policyType: policyQuote?.policyType?.description ?? "",
      policyLimits: quote?.policyLimits?.displayValue ?? "",
    };

    setPolicyQuoteHeaderUI(newPolicyQuoteHeaderUI);
  }, [listenerPolicyQuoteHeaderComponent]);

  return (
    <Box
      className="policy-quote-header-container"
      sx={{ ...policyQuoteHeaderStyles() }}
    >
      <Box className="policy-quote-header-pair-text-container">
        <Box className="policy-quote-header-text-container">
          <CaptionBold>Policy Status</CaptionBold>
        </Box>
        <Box className="policy-quote-header-text-container">
          <Font name="policy-status">{policyQuoteHeaderUI?.policyStatus}</Font>
        </Box>
      </Box>
      <Box className="policy-quote-header-pair-text-container">
        <Box className="policy-quote-header-text-container">
          <CaptionBold>Quote Status</CaptionBold>
        </Box>
        <Box className="policy-quote-header-text-container">
          <Font name="quote-status">{policyQuoteHeaderUI?.quoteStatus}</Font>
        </Box>
      </Box>
      <Box className="policy-quote-header-pair-text-container">
        <Box className="policy-quote-header-text-container">
          <CaptionBold>Policy Dates</CaptionBold>
        </Box>
        <Box className="policy-quote-header-text-container">
          <Font name="policy-dates">{policyQuoteHeaderUI?.policyDates}</Font>
        </Box>
      </Box>
      <Box className="policy-quote-header-pair-text-container">
        <Box className="policy-quote-header-text-container">
          <CaptionBold>Type</CaptionBold>
        </Box>
        <Box className="policy-quote-header-text-container">
          <Font name="policy-type">{policyQuoteHeaderUI?.policyType}</Font>
        </Box>
      </Box>
      <Box className="policy-quote-header-pair-text-container">
        <Box className="policy-quote-header-text-container">
          <CaptionBold>Policy Limits</CaptionBold>
        </Box>
        <Box className="policy-quote-header-text-container">
          <Font name="policy-limits">{policyQuoteHeaderUI?.policyLimits}</Font>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicyQuoteHeader;
