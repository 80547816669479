import { Stack } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { ClaimContactDto } from "../../../../../../dtos/claim-contact-dto";
import { globalOptions } from "../../../../../../GlobalAtoms";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import themes from "../../../../../../media/TrueTheme";
import { BaseTable2, Header } from "../../../../../TrueUI";
import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDependents } from "../spreadClaimTabsFunctions";
import { getColorWithOpacityHexaDecimal } from "../../../../../../media/themeConstants";
import StyleBox from "../../../../../TrueUI/StyleBox/StyleBox";
import Link from "../../../../../TrueUI/Typography/Link";
import { OPTION_COLUMN_FIELD_NAME } from "../../../../../TrueUI/Tables/TableConstants";

const tableName = "claim_claimant_dependents_container_table";

type ClaimantDependentsContainerProps = {
  insuredIdAtomKey: string;
  claimId?: number;
  errorDetails?: any;
  hasClaimsManagementPermissions: boolean;
};

const ClaimantDependentsContainer: FC<ClaimantDependentsContainerProps> = ({
  insuredIdAtomKey,
  claimId,
  errorDetails,
  hasClaimsManagementPermissions,
}) => {
  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  useRecoilValue(TriggerComponentUpdateAtom("dependentsComponent"));

  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const isTableEditMode =
    getAtom()?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.dependentsContainerData
      ?.isTableOnEditMode;

  const tableConfiguration: BaseTable2Properties<ClaimContactDto> = {
    name: tableName,
    tableType: "compact",
    getURL: `api/claims/GetClaimantDependentsData?claimId=${claimId}`,
    columnOptions: [
      { fieldName: "ContactName", width: 50 },
      { fieldName: "RelationshipToClaimant", width: 25 },
      { fieldName: "DateOfBirth", width: 25 },
      { fieldName: OPTION_COLUMN_FIELD_NAME, width: 10 },
    ],
    toolbarOptions: {
      toolBarType: "compact",
      showEditButton: false,
      showSaveButton: false,
      showAddButton: hasClaimsManagementPermissions,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      paginate: false,
      isEditMode: isTableEditMode,
      tableStyle: {
        headerStyle: "transparent",
        height: "200px",
      },
      tableErrors: errorDetails ?? [],
      overrideColumnDefaultValues: [
        {
          columnFieldName: "ClaimContactId",
          propName: "defaultValue",
          value: 0,
        },
      ],
      optionsColumnConfiguration: {
        isHidden: !hasClaimsManagementPermissions,
        optionType: "edit_delete",
      },
      disableOrderBy: true,
    },
    events: {
      onChangeDataListener: (changedData) => {
        const atomValue = getAtom();
        setAtom(spreadDependents(atomValue, changedData));
        setComponentTriggers(["buttonComponent"]);
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <StyleBox
      showBorder
      borderRadius={5}
      overrideBorderColor={getColorWithOpacityHexaDecimal(
        theme?.BORDER_COLOR,
        100
      )}
      padding={10}
      margin={0}
      marginBottom={1}
      width={"100%"}
      height={"100%"}
      verticalAlign={"start"}
    >
      <Stack sx={{ width: "100%" }}>
        <>
          <Header marginBottom="10px">
            <Link
              linkFontType={"BOLD_TITLE"}
              displayValue={"Claimant Dependents"}
              name="dependentsTitleLink"
            ></Link>
          </Header>
          <BaseTable2 name={tableName} />
        </>
      </Stack>
    </StyleBox>
  );
};

export default ClaimantDependentsContainer;
