import { FC, useEffect, useState } from "react";
import { Divider, Heading4, TabPanel, Tabs } from "../../TrueUI";
import InsuredInformationMain from "./InsuredInformation/InsuredInformationMain";
import GeneralInformationMain from "./GeneralInformation/GeneralInformationMain";
import { useApiPost } from "../../../hooks";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { acord130FormProperties } from "../../../GlobalAtoms";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getInsuredInformationErrors,
  removeAllApplicationQuestionErrors,
  updateApplicationQuestionErrors,
} from "./GeneralInformation/GeneralInformationUtils";
import { updateGeneralQuestions } from "./updateGlobalPolicyQuoteFunctions";
import Acord130BottomButtons, {
  CONTINUE_ACTION,
} from "./Acord130BottomButtons";
import { usePolicyQuoteTriggerComponent } from "../Policy/hooks/usePolicyQuoteTriggerComponent";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  CHILD_ITEM,
  INSURED_BODY_SECTION,
  PARENT_ITEM,
  POLICY_ID,
  QUOTE_ID,
} from "../../../utilities/queryStringsHash";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { PolicyBlob } from "../../../dtos/policy-blob";
import { useNavigate, useParams } from "react-router";
import style from "./Acord130.module.css";

const TABS = {
  INSURED_INFORMATION: 0,
  GENERAL_INFORMATION: 1,
};

const defaultAtomValue: PolicyBlob = {
  insured: {},
  producers: [],
  quote: {},
};

const Acord130Main: FC = () => {
  const { newSubmissionForm } = useParams();

  const [tabSelected, setTabSelected] = useState<number>(
    TABS.INSURED_INFORMATION
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { getAtom, setAtom } = useAtomFamily(acord130FormProperties);
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const {
    responsePost: responsePostGeneralInformation,
    validatorErrorResponse: validatorErrorResponseGeneralInformation,
    dispatchPost: dispatchPostGeneralInformation,
  } = useApiPost<PolicyBlob>(
    "api/QuotePolicy/SaveGeneralInformation",
    getAtom()
  );
  const {
    responsePost: responsePostInsuredInformation,
    validatorErrorResponse: validatorErrorResponseInsuredInformation,
    dispatchPost: dispatchPostInsuredInformation,
  } = useApiPost<any>("api/QuotePolicy/SaveInsuredInformation", getAtom());
  const [insuredInformationErrors, setInsuredInformationErrors] = useState<{
    [key: string]: Object;
  }>();
  const navigate = useNavigate();
  const [bottomButtonAction, setBottomButtonAction] =
    useState<string>(CONTINUE_ACTION);

  const saveGeneralInformation = (action: string) => {
    setBottomButtonAction(action);
    dispatchPostGeneralInformation();
  };
  const saveInsuredInformation = (action: string) => {
    setBottomButtonAction(action);
    dispatchPostInsuredInformation();
  };

  const updateGeneralInformation = (hasErrors: boolean) => {
    const atomValue = getAtom();
    const newGeneralInformation = !hasErrors
      ? removeAllApplicationQuestionErrors(atomValue?.generalInformation ?? [])
      : updateApplicationQuestionErrors(
          atomValue?.generalInformation ?? [],
          validatorErrorResponseGeneralInformation?.errorDetails
        );

    const newAtomValue = updateGeneralQuestions(
      atomValue,
      newGeneralInformation
    );

    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["generalInformationComponent"]);

    if (hasErrors) {
      const onlyInsuredInformationErrors = getInsuredInformationErrors(
        validatorErrorResponseGeneralInformation?.errorDetails
      );
      if (Object.keys(onlyInsuredInformationErrors).length > 0)
        setIsDialogOpen(true);
      setInsuredInformationErrors(onlyInsuredInformationErrors);
    }
  };

  const navigateToNewQuote = (policyJSON?: PolicyBlob) => {
    setAtom(null);
    const quoteHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS,
      },
      {
        nameOfHash: PARENT_ITEM,
        valueOfHash: SubSideNavParentsIndexEnum.POLICY,
      },
      {
        nameOfHash: CHILD_ITEM,
        valueOfHash: SubSideNavChildrenIndexEnum.POLICY_HISTORY,
      },
      {
        nameOfHash: POLICY_ID,
        valueOfHash: policyJSON?.policyID?.toString() ?? "0",
      },
      {
        nameOfHash: QUOTE_ID,
        valueOfHash: policyJSON?.quote?.quoteID?.toString() ?? "0",
      },
    ]);

    navigate(`/insured/${policyJSON?.insured?.insuredID}${quoteHashes}`);
  };

  useEffect(() => {
    const atomValue = getAtom();
    if (atomValue === null || atomValue === undefined)
      setAtom(defaultAtomValue);

    return () => {
      setAtom(null);
    };
  }, []);

  useEffect(() => {
    if (
      responsePostGeneralInformation.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponseGeneralInformation)
    ) {
      updateGeneralInformation(false);
      if (bottomButtonAction === CONTINUE_ACTION) {
        navigateToNewQuote(responsePostGeneralInformation.axiosResponse?.data);
      }
    }
    if (conditionHasValue(validatorErrorResponseGeneralInformation)) {
      updateGeneralInformation(true);
    }
  }, [responsePostGeneralInformation]);

  useEffect(() => {
    if (
      responsePostInsuredInformation.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponseInsuredInformation)
    ) {
      setInsuredInformationErrors({});
      const policyBlob =
        responsePostInsuredInformation.axiosResponse?.data ?? null;
      setAtom({
        ...policyBlob,
        insured: {
          ...policyBlob?.insured,
          dBA: policyBlob?.insured?.dba,
          sIC: policyBlob?.insured?.sic,
          eIN: policyBlob?.insured?.ein,
          sSN: policyBlob?.insured?.ssn,
          nAICS: policyBlob?.insured?.naics,
          taxID: policyBlob?.insured?.taxID,
        },
      });
      if (bottomButtonAction === CONTINUE_ACTION) {
        setTabSelected(TABS.GENERAL_INFORMATION);
      }
    }
    if (conditionHasValue(validatorErrorResponseInsuredInformation)) {
      setInsuredInformationErrors(
        validatorErrorResponseInsuredInformation?.errorDetails
      );
      setIsDialogOpen(true);
    }
  }, [responsePostInsuredInformation]);

  return (
    <div className={style.focus_locked_acord_130_main_container}>
      <div
        id="acord130-main-title-container"
        style={{ height: "60px", overflow: "hidden", paddingRight: "10px" }}
      >
        <Heading4 textAlign="start">NEW SUBMISSION</Heading4>
        <Divider height={"1px"} width={"100%"} borderSize={"1px"} showBorder />
      </div>
      <div
        id="acord130-main-tabs-container"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Tabs
          name="acord130-tabs"
          selected={tabSelected}
          onSelect={(e) => setTabSelected(e.selected)}
        >
          <TabPanel title="Insured Information" key="insured-information-tab">
            <div
              id="acord130-main-insured-information-container"
              style={{
                height: "100%",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              <InsuredInformationMain
                newSubmissionForm={JSON.parse(newSubmissionForm ?? "")}
                errorDetails={insuredInformationErrors}
              />
              <Acord130BottomButtons saveFunction={saveInsuredInformation} />
            </div>
          </TabPanel>
          <TabPanel title="General Information" key="general-information-tab">
            <div
              id="acord130-main-general-information-container"
              style={{
                height: "100%",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              <GeneralInformationMain />
              <Acord130BottomButtons saveFunction={saveGeneralInformation} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <DialogConfirmation
        open={isDialogOpen}
        dialogDescriptionText="There are issues on the page.  Please correct any missing information"
        onOptionYesEvent={setIsDialogOpen}
        optionYesOverrideLabel="OK"
      />
    </div>
  );
};

export default Acord130Main;
