import { FormGroup } from "@mui/material";
import { FC, useState } from "react";
import {
  CaptionBold,
  Col,
  Input,
  Modal,
  RichEditor,
  Row,
  Select,
  Switch,
} from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import Link from "../../../../TrueUI/Typography/Link";
import { PortalSettings } from "./PortalSettings";
import {
  backgroundTypeOptions,
  defaultThemeOptions,
  fontType,
  onChangeSettingField,
} from "./PortalSettingUtils";

const ProgramInfoContainer: FC<PortalSettings> = ({
  settings,
  setPortalSettings,
  errorDetails,
}) => {
  const [modalInfo, setModalInfo] = useState({
    title: "",
    content: "",
    isOpen: false,
    field: "",
  });
  const commonFunction = (value, target) => {
    onChangeSettingField(settings, target, value, setPortalSettings);
  };

  return (
    <>
      <Input
        id="program-url"
        name="programURL"
        labelFontType={fontType}
        inputFontType={"BODY"}
        label="URL"
        value={settings?.portalUrl}
        onChangeRawValue={(value) => commonFunction(value, "portalUrl")}
        errorMessage={errorDetails?.portalUrl}
        maxLength={100}
      />
      <Input
        id="logo-image"
        name="logoImage"
        labelFontType={fontType}
        inputFontType={"BODY"}
        label="Logo Image"
        value={settings?.logoImage}
        onChangeRawValue={(value) => commonFunction(value, "logoImage")}
        errorMessage={errorDetails?.logoImage}
        maxLength={200}
      />
      <Input
        id="background-image"
        name="BackgroundImage"
        labelFontType={fontType}
        inputFontType={"BODY"}
        label="Background Image"
        value={settings?.backgroundImage}
        errorMessage={errorDetails?.backgroundImage}
        onChangeRawValue={(value) => commonFunction(value, "backgroundImage")}
        maxLength={200}
      />
      <Row {...rowWithNoMarginNorGutter} verticalAlign={"flex-start"}>
        <Col
          breakpoints={{ xs: 12, sm: 12, md: 5.8, lg: 5.8, xl: 5.8 }}
          horizontalAlign="flex-start"
          horizontalGutter="0px"
        >
          <Select
            id="background-type"
            name="background-type"
            label="BackgroundType"
            options={backgroundTypeOptions ?? []}
            labelFontType={fontType}
            inputFontType={"BODY"}
            value={settings?.backgroundType}
            errorMessage={errorDetails?.backgroundType}
            onChange={(value) => commonFunction(value, "backgroundType")}
          />
        </Col>
        <Col
          breakpoints={{ xs: 12, sm: 12, md: 5.8, lg: 5.8, xl: 5.8 }}
          horizontalGutter="0px"
          horizontalAlign="flex-start"
        >
          <Select
            id="theme"
            name="programTheme"
            label="Theme"
            options={defaultThemeOptions ?? []}
            labelFontType={fontType}
            inputFontType={"BODY"}
            value={settings?.theme}
            errorMessage={errorDetails?.theme}
            onChange={(value) => commonFunction(value, "theme")}
          />
        </Col>
      </Row>
      <Input
        id="login-logo-image"
        name="LoginLogoImage"
        labelFontType={fontType}
        inputFontType={"BODY"}
        label="Login Logo Image"
        value={settings?.loginLogoImage}
        errorMessage={errorDetails?.loginLogoImage}
        onChangeRawValue={(value) => commonFunction(value, "loginLogoImage")}
        maxLength={200}
      />
      <Row {...rowWithNoMarginNorGutter} verticalAlign={"flex-start"}>
        <Col
          breakpoints={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          horizontalAlign="flex-start"
          horizontalGutter="0px"
        >
          <CaptionBold>Legalese </CaptionBold>
        </Col>
        <Col
          breakpoints={{ xs: 12, sm: 12, md: 9, lg: 9, xl: 9 }}
          horizontalGutter="0px"
          horizontalAlign="flex-start"
        >
          <div>
            <Link
              displayValue="Terms of Use"
              linkFontType="SMALL_TITLE"
              display="block"
              textAlign="start"
              onClick={() =>
                setModalInfo({
                  title: "Terms of Use",
                  content: settings?.termsOfUse ?? "",
                  field: "termsOfUse",
                  isOpen: true,
                })
              }
            />
            <Link
              displayValue="Privacy Notice"
              linkFontType="SMALL_TITLE"
              display="block"
              textAlign="start"
              onClick={() =>
                setModalInfo({
                  title: "Privacy Notice",
                  content: settings?.privacyNotice ?? "",
                  field: "privacyNotice",
                  isOpen: true,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} verticalAlign={"flex-start"}>
        <Col
          breakpoints={{ xs: 12, sm: 12, md: 12 }}
          horizontalGutter="0px"
          horizontalAlign="flex-start"
        >
          <FormGroup>
            <Switch
              label={"Invite User on Bind?"}
              labelFontType={fontType}
              labelPlacement={"start"}
              inputWidth={"100%"}
              isChecked={settings?.inviteUserOnBind}
              onChangeIsChecked={(value) =>
                commonFunction(value, "inviteUserOnBind")
              }
            />
            <Switch
              label={"Show Claim Notes?"}
              labelFontType={fontType}
              labelPlacement={"start"}
              isChecked={settings?.showClaimNotes}
              onChangeIsChecked={(value) =>
                commonFunction(value, "showClaimNotes")
              }
            />
            <Switch
              label={"Loss Control?"}
              labelFontType={fontType}
              labelPlacement={"start"}
              isChecked={settings?.lossControl}
              onChangeIsChecked={(value) => {
                if (value === true) {
                  setPortalSettings?.({
                    ...settings,
                    lossControlLabel: null,
                    lossControl: value,
                  } as any);
                } else {
                  commonFunction(value, "lossControl");
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {settings?.lossControl === true && (
        <Input
          id="loss-control-label"
          name="lossControl"
          labelPosition={"start"}
          labelFontType={fontType}
          inputFontType={"BODY"}
          label="Lost Control Label"
          value={settings?.lossControlLabel}
          onChangeRawValue={(value) =>
            commonFunction(value, "lossControlLabel")
          }
          errorMessage={errorDetails?.lossControlLabel}
          maxLength={50}
        />
      )}
      <Modal
        id={"legalese-modal"}
        title={modalInfo?.title}
        open={modalInfo?.isOpen}
        cancelEvent={() => setModalInfo({ ...modalInfo, isOpen: false })}
        showCloseButton
      >
        <RichEditor
          value={modalInfo?.content}
          id={"content"}
          disableCollapse
          contentStyle={{ height: 300 }}
          onChangeRawValue={(value) => {
            setModalInfo({ ...modalInfo, content: value });
            commonFunction(value, `${modalInfo?.field}`);
          }}
          errorMessage={errorDetails?.[modalInfo?.field]}
        />
      </Modal>
    </>
  );
};

export default ProgramInfoContainer;
