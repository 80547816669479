import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  AUDIT_SECTION,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";

export const getAuditSectionAndIds = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const auditSection = getQueryValue(AUDIT_SECTION);
  const policyId = getQueryValue(POLICY_ID);
  const quoteId = getQueryValue(QUOTE_ID);
  const previousQuoteId = getQueryValue(PREVIOUS_QUOTE_ID);

  const validatedQuoteSection =
    auditSection !== ""
      ? parseInt(auditSection)
      : atomValue?.policyQuoteInformation?.activeSection ??
        AuditSectionEnum.AUDIT_INFORMATION;
  const validatedPolicyId =
    policyId !== ""
      ? parseInt(policyId)
      : atomValue?.policyQuoteInformation?.policyId ?? null;
  const validatedQuoteId =
    quoteId !== ""
      ? parseInt(quoteId)
      : atomValue?.policyQuoteInformation?.quoteId ?? null;
  const validatedPreviousQuoteId =
    quoteId !== ""
      ? parseInt(previousQuoteId)
      : atomValue?.policyQuoteInformation?.previousQuoteId ?? null;

  return {
    auditSection: validatedQuoteSection,
    policyId: validatedPolicyId,
    quoteId: validatedQuoteId,
    previousQuoteId: validatedPreviousQuoteId,
  };
};
