import { FC, useEffect, useState, useCallback, useRef } from "react";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Icon as KendoIcon } from "@progress/kendo-react-common";
import themes from "../../../media/TrueTheme";
import { globalOptions } from "../../../GlobalAtoms";
import { usePermissions } from "../../../hooks";
import Font from "../Typography/Font";
import { SelectProperties } from "./Select";
import {
  getCoordsForOptionContainer,
  getPositionTopByOptionsPosition,
  isAcceptKey,
  isCancelKey,
} from "./SelectUtils";
import { getVariant } from "../Inputs/InputUtil";
import { SelectOptions } from "../../../dtos/select-options";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  TABBABLE_CLASS_NAME,
  focusNextElement,
} from "../../../utilities/tabFunctions";
import style from "./selectWithIcon.module.css";

interface SelectWithIconProps extends SelectProperties {
  overrideIcon?: JSX.Element;
  overrideIconColor?: string;
}

const SelectWithIcon: FC<SelectWithIconProps> = ({
  id = "",
  name = "",
  trueElement,
  label,
  className,
  errorMessage,
  labelPosition = "top",
  value,
  options,
  readOnly,
  disabled = false,
  variant,
  permissions = [1, 2, 3], // TODO: implement permissions @ELara
  helperText,
  inputWidth = "100%",
  optionsMaxHeight = "auto",
  inputFontType = "BODY",
  labelFontType = "BOLD_BODY",
  labelTextAlign = "start",
  firstOptionAsDefault = true,
  isCustomArrow = false,
  optionsContainerPosition = "bottom",
  type = "standard",
  onChange,
  tabIndex = 0,
  overrideIcon,
  overrideIconColor,
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [focusedElement, setFocusedElement] = useState<number>(-1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isThereError, setIsThereError] = useState(false);
  const hasPermission = usePermissions(permissions);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [optionContainerWidth, setOptionContainerWidth] = useState(0);

  const isDisabled = disabled || !hasPermission;

  const SELECT_ITEM_CLASS = `select_item_option_${id}`;

  useEffect(() => {
    function setOptionsWidthByWindowsResize() {
      setOptionContainerWidth(selectRef?.current?.clientWidth ?? 0);
    }

    window.addEventListener("resize", setOptionsWidthByWindowsResize);

    return () => {
      window.removeEventListener("resize", setOptionsWidthByWindowsResize);
    };
  }, []);

  const getValueFromOption = (option) => {
    return (
      (option.intValue ||
        option.stringValue ||
        option.decimalValue ||
        option.dateValue ||
        option.booleanValue) ??
      null
    );
  };

  const getSelectedValue = useCallback(
    (_value, _options: Partial<SelectOptions>[]) => {
      if (_value !== undefined && _value !== null) {
        const selectedOption = _options?.find(
          (x: Partial<SelectOptions>) =>
            x.intValue === _value ||
            x.stringValue === _value ||
            x.decimalValue === _value ||
            x.dateValue === _value ||
            x.booleanValue === _value
        );

        return selectedOption === undefined ? null : _value;
      }
      return firstOptionAsDefault
        ? getValueFromOption(_options?.[0] ?? [])
        : null;
    },
    [firstOptionAsDefault]
  );
  const [selectedValue, setSelectedValue] = useState<any>(
    getSelectedValue(value, options)
  );

  const getErrorHelper = useCallback(() => {
    if (errorMessage != null) {
      setIsThereError(true);
    } else {
      setIsThereError(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    getErrorHelper();
  }, [errorMessage, getErrorHelper]);

  useEffect(() => {
    if (value !== selectedValue) {
      const _selectedValue = getSelectedValue(value, options);
      setSelectedValue(_selectedValue);
      if (firstOptionAsDefault) {
        onChange?.(_selectedValue);
      }
    }
  }, [value]);

  useEffect(() => {
    if (isOpen === true) {
      setOptionContainerWidth(selectRef?.current?.clientWidth ?? 0);
      setFocusedElement(0);
    }
    if (isOpen === false) setFocusedElement(-1);
  }, [isOpen]);

  useEffect(() => {
    const itemOptions = getItemOptions();
    if (focusedElement >= 0 && focusedElement < itemOptions.length) {
      const currentFocusedElement = itemOptions.at(focusedElement);
      currentFocusedElement?.focus();
    }
  }, [focusedElement]);

  const getItemOptions = () => {
    return [
      ...(document.getElementsByClassName(SELECT_ITEM_CLASS) ?? []),
    ] as HTMLElement[];
  };

  const getSelectedOption = (_value, _options: Partial<SelectOptions>[]) => {
    if (_value !== undefined && _value !== null) {
      const selectedOption = _options?.find(
        (x: Partial<SelectOptions>) =>
          x.intValue === _value ||
          x.stringValue === _value ||
          x.decimalValue === _value ||
          x.dateValue === _value ||
          x.booleanValue === _value
      );

      return selectedOption;
    }
    return firstOptionAsDefault ? _options?.[0] ?? [] : null;
  };

  const onSelectedOption = (option) => {
    onChange?.(getValueFromOption(option));
    setSelectedValue(getValueFromOption(option));
    setIsOpen(!isOpen);
  };

  const onOptionKeyPressEvent = (event, option) => {
    if (isAcceptKey(event) && isOpen === true) onSelectedOption(option);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
      setIsOpen(false);
      focusNextElement(id);
    }
    if (event.code === "ArrowDown" && isOpen === true)
      setFocusedElement(focusedElement + 1);
    if (event.code === "ArrowUp" && isOpen === true)
      setFocusedElement(focusedElement - 1);
  };

  const onContainerKeyPressEvent = (event) => {
    if (isAcceptKey(event)) setIsOpen(!isOpen);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
    }
    if (event.code === "ArrowDown" && isOpen === false)
      if (isOpen === false) setIsOpen(true);
  };

  const inputProps = {
    id: id,
    name: name,
    "true-element": trueElement
      ? `true-select-with-icon-options-${trueElement}`
      : `true-select-with-icon-options-${name}`,
  };

  const inputSize = theme?.[inputFontType].SIZE;
  const labelSize = label ? theme?.[labelFontType].SIZE : "0px";

  const getStyleType = () => {
    switch (type) {
      case "tableFilter":
        return style.true_selectwith_icon__options_container_table_filter;
      case "tableCell":
        return style.true_selectwith_icon__options_container_table_cell;
      default:
        return "";
    }
  };

  const GetStyleLabelPosition = () => {
    switch (labelPosition) {
      case "start":
        return style.true_input_container_start;
      case "top":
        return style.true_input_container_top;
      case "end":
        return style.true_input_container_end;
      case "bottom":
        return style.true_input_container_bottom;
      default:
        return "";
    }
  };

  const getOptionContainer = () => {
    const { top, left } = getCoordsForOptionContainer(selectRef.current);

    return isOpen && !readOnly && !isDisabled ? (
      <div
        className={style.select_with_icon_option_container_paper}
        onClick={isOpen ? () => setIsOpen(!isOpen) : () => {}}
      >
        <div
          className={`${
            style.true_select_with_icon_options_container
          } ${getStyleType()}`}
          style={{
            transform: `translateY(calc(${inputSize} + ${labelSize} + ${
              label ? "23px" : "17px"
            }))`,
            maxHeight: optionsMaxHeight,
            width: `${optionContainerWidth}px`,
            top: getPositionTopByOptionsPosition(
              top,
              options?.length ?? 0,
              optionsContainerPosition,
              labelPosition,
              conditionHasValue(label),
              labelRef?.current?.offsetHeight ?? 0,
              optionsMaxHeight.includes("px")
                ? parseInt(optionsMaxHeight.split("px")[0])
                : null
            ),
            left: left,
          }}
        >
          {options?.map((option) => (
            <button
              tabIndex={tabIndex}
              className={`${
                style.true_select_with_icon_option
              } ${SELECT_ITEM_CLASS} ${
                getValueFromOption(option) === selectedValue
                  ? style.true_select_with_icon_option_is_selected
                  : ""
              } ${
                option?.displayName === ""
                  ? style.true_select_with_icon_option_empty_option
                  : ""
              }`}
              key={`true_select_with_icon_option${getValueFromOption(option)}`}
              onClick={() => onSelectedOption(option)}
              onKeyUp={(e) => onOptionKeyPressEvent(e, option)}
              title={option?.displayName}
            >
              <div className={style.closed}>
                {overrideIcon ?? (
                  <KendoIcon
                    style={{
                      color: `${
                        overrideIconColor ? overrideIconColor : "black"
                      }`,
                    }}
                    className={"true_drawer_icon"}
                    name={option.iconName}
                  />
                )}
              </div>
              <Font fontType={inputFontType}>{option?.displayName}</Font>
            </button>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Box
        className={style.true_input_general_container}
        width={`${
          labelPosition === "start" || labelPosition === "end"
            ? "fit-content"
            : inputWidth
        } !important`}
      >
        <Box
          className={GetStyleLabelPosition()}
          width={`${
            labelPosition === "start" || labelPosition === "end"
              ? "fit-content"
              : inputWidth.includes("%")
              ? "100%"
              : inputWidth
          } !important`}
        >
          {label != null && (
            <div
              className={style.true_input_label_container_for_select_with_icon}
              ref={labelRef}
            >
              <Font
                name={name}
                trueElement={
                  trueElement
                    ? trueElement
                    : `true-element-select-with-icon-${name}`
                }
                className={`${style.label_for_select_with_icon} ${
                  labelFontType === undefined ? "withoutSize" : ""
                }`}
                fontType={labelFontType}
                textAlign={labelTextAlign}
              >
                {label}
              </Font>
            </div>
          )}
          <div
            {...inputProps}
            tabIndex={tabIndex}
            className={`${
              style.true_input_select_with_icon_container
            }  ${TABBABLE_CLASS_NAME} ${
              getVariant(readOnly, variant) === "filled" ? style.filled : ""
            } ${errorMessage != null ? "true_input_error" : ""} ${
              readOnly
                ? style.true_input_select_with_icon_container_read_only
                : ""
            } ${
              isDisabled
                ? style.true_input_select_with_icon_container_is_disabled
                : ""
            } ${
              isThereError
                ? style.true_input_select_with_icon_container_has_error
                : ""
            } ${className} ${localOptions?.themeRefresh} ${
              isOpen && !readOnly && !isDisabled
                ? style.true_input_select_with_icon_container_open
                : "closed"
            } ${getStyleType()}`}
            style={{
              width: inputWidth.includes("%") ? "100%" : inputWidth,
            }}
            onClick={() => !readOnly && setIsOpen(!isOpen)}
            onKeyUp={(e) => onContainerKeyPressEvent(e)}
            ref={selectRef}
          >
            <div className={style.closed}>
              {overrideIcon ?? (
                <KendoIcon
                  style={{
                    color: `${overrideIconColor ? overrideIconColor : "black"}`,
                  }}
                  className={"true_drawer_icon"}
                  name={
                    getSelectedOption(selectedValue, options)?.iconName ?? ""
                  }
                />
              )}
            </div>
            <Font
              name={name}
              trueElement={
                trueElement
                  ? trueElement
                  : `true-element-select-with-icon-${name}`
              }
              fontType={inputFontType}
              className={`${style.select_with_icon_displayed_option}  ${
                isDisabled
                  ? style.select_with_icon_displayed_option_is_disabled
                  : ""
              }`}
            >
              {getSelectedOption(selectedValue, options)?.displayName}
            </Font>
            <div className={style.select_with_icon_arrow_container}>
              {!readOnly &&
                !isDisabled &&
                (isCustomArrow ? (
                  <KeyboardArrowDownIcon
                    className={`${style.select_with_icon_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_with_icon_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className={`${style.select_with_icon_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_with_icon_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ))}
            </div>
          </div>
          {errorMessage != null && !isOpen && (
            <span className="true_input_error_txt">
              {errorMessage?.join(" ")}
            </span>
          )}
        </Box>
        {helperText && (
          <span className="true_input_helper_text">{helperText}</span>
        )}
        {getOptionContainer()}
      </Box>
    </>
  );
};

export default SelectWithIcon;
