import { FC, useEffect, useState } from "react";
import { Button, Col, Input, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";
import { inputConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { usePermissions } from "../../../../hooks";
import { buttonStyles } from "./DocumentStyles";
import { DocumentFileOptionsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  getAllTemplateNamesToPrint,
  getDefaultFileName,
} from "./DocumentsUtils";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { ActivityCategoryEnums } from "../../../../dtos/activity-category-enums";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const columnProps = (
  columns: number,
  equalWidth: boolean,
  displayFlex: boolean
) => ({
  equalWidth: equalWidth,
  displayFlex: displayFlex,
  breakpoints: {
    xs: columns,
    sm: columns,
    md: columns,
    lg: columns,
    xl: columns,
  },
});

const DocumentFileOptions: FC<DocumentFileOptionsProps> = ({
  insuredId,
  tabKey,
  selectedPackets,
  selectedDocuments,
  programPackets,
  programDocuments,
  policyDocuments,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);
  const [fileName, setFileName] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const insuredName =
      getAtom()?.policyQuoteInformation?.policyQuote?.insured?.insuredName ??
      "";
    const defaultFileName = getDefaultFileName(
      insuredName,
      selectedPackets,
      selectedDocuments,
      programPackets,
      policyDocuments
    );
    setFileName(defaultFileName);
  }, [selectedPackets, selectedDocuments]);

  const printDocuments = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(
          selectedPackets,
          selectedDocuments,
          programDocuments,
          policyDocuments,
          policyMergeFields.endorsementForms ?? []
        );

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: allTemplateNames,
          customFileName: fileName,
          printOrDownload: "download",
        });
      }
    }
  };

  const printAndSaveDocuments = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(
          selectedPackets,
          selectedDocuments,
          programDocuments,
          policyDocuments,
          policyMergeFields.endorsementForms ?? []
        );

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: allTemplateNames,
          customFileName: fileName,
          configurationToSaveFile: {
            saveFileToInsured: true,
            fileCategory: "Policy Documents",
            insuredId,
            claimId: null,
            policyId: policyMergeFields.policyID ?? -1,
            activityLogCategory:
              ActivityCategoryEnums.POLICY_POLICY_DOC_PACKET_PRINTED,
            activityLogDescription: !isEmptyValue(
              policyMergeFields.policyNumber
            )
              ? `The policy documents for policy number ${policyMergeFields.policyNumber} were generated`
              : `The policy documents for policy ID ${policyMergeFields.policyID} were generated`,
          },
          printOrDownload: "download",
        });
      }
    }
  };

  const mailDocument = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(
          selectedPackets,
          selectedDocuments,
          programDocuments,
          policyDocuments,
          policyMergeFields.endorsementForms ?? []
        );

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          jsonObjectWithMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNames: allTemplateNames,
          customFileName: fileName,
          printOrDownload: "mail",
        });
      }
    }
  };

  return (
    <StyleBox
      margin={0}
      padding={0}
      width={"100%"}
      showBorder
      overrideBorderColor={getColorWithOpacityHexaDecimal(
        theme.BORDER_COLOR,
        50
      )}
    >
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        numberOfColumns={24}
      >
        <Col {...columnProps(17, true, false)} verticalAlignSelf="center">
          <Input
            {...inputConfiguration("file-name", "fileName", "File Name", 100)}
            value={fileName}
            onChangeRawValue={setFileName}
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            variant="filled"
            readOnly={!hasPermission}
            fileNameValidation
          />
        </Col>
        <Col {...columnProps(7, false, true)}>
          <Button
            id="policy-doc-email"
            name="email"
            onClick={mailDocument}
            sx={buttonStyles()}
          >
            EMAIL
          </Button>
          <Button
            id="policy-doc-print-save"
            name="printSave"
            onClick={printAndSaveDocuments}
            sx={buttonStyles()}
          >
            SAVE AND PRINT
          </Button>
          <Button
            id="policy-doc-print"
            name="print"
            onClick={printDocuments}
            sx={buttonStyles()}
          >
            PRINT
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </StyleBox>
  );
};

export default DocumentFileOptions;
