import { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Font from "../Typography/Font";

type WarningIndicatorPros = {
  name: string;
  tooltipTitle?: string | React.ReactNode;
};

const WarningIndicator: FC<WarningIndicatorPros> = ({ name, tooltipTitle }) => (
  <Font
    display="flex"
    trueElement={`${name}-ticker-warning-indicator`}
    isWarning={true}
    truncate
    showTooltip
    tooltipTitle={tooltipTitle}
  >
    <ErrorOutlineIcon fontSize="small" />
  </Font>
);

export default WarningIndicator;
