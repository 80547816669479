import { FC } from "react";
import { usePermissions } from "../../hooks";
import QuickActions from "./QuickActions/QuickActions";
import style from "./dashboard.module.css";
import DashboardBottomContent from "./RightContent/DashboardRightContent";
const Dashboard: FC = () => {
  const hasPermission = usePermissions([5, 6, 7]);

  return (
    <>
      <div className={style.dashboard_container}>
        {hasPermission ? <QuickActions /> : null}
        <DashboardBottomContent />
      </div>
    </>
  );
};
export default Dashboard;
