import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { Font, FontBold } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  PolicyExposureTableHeaderProps,
  PolicyExposureTableHeaderUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader } from "./ExposurePremiumUtils";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { isCurrentStateTheGoverningState } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import Link from "../../../TrueUI/Typography/Link";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import style from "../PolicyQuoteForm/PolicyQuote.module.css";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";

const ExposurePremiumStateHeader: FC<PolicyExposureTableHeaderProps> = ({
  tabKey,
  stateName,
  stateCode,
  effectiveDate,
  expirationDate,
  readOnly,
  deleteState,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const [stateHeaderUI, setStateHeaderUI] =
    useState<PolicyExposureTableHeaderUIProps>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const listenerStateHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("exposureTableHeaderComponent")
  );

  useEffect(() => {
    const currentStates =
      getAtom()?.policyQuoteInformation?.policyQuote?.quote?.states ?? [];

    const calculations =
      getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader(
        stateCode ?? "",
        effectiveDate,
        expirationDate,
        currentStates
      );

    const { isGoverningState, classCodeWithHighestPremium } =
      isCurrentStateTheGoverningState(
        stateCode,
        effectiveDate,
        expirationDate,
        currentStates
      );

    setStateHeaderUI({
      payroll: calculations.totalPayroll.toLocaleString(),
      surchargesAndFees: calculations.totalSurchargesAndFees.toLocaleString(),
      premium: calculations.totalPremium.toLocaleString(),
      isGoverningState,
      classCodeWithHighestPremium,
      isExpanded: calculations.isExpanded,
    });
  }, [listenerStateHeaderComponent]);

  return (
    <>
      <div
        className={
          stateHeaderUI?.isExpanded
            ? style.exposure_table_header_container_expanded
            : style.exposure_table_header_container_collapsed
        }
      >
        <div
          className={
            stateHeaderUI?.isExpanded
              ? style.exposure_header_description_expanded
              : style.footer_header_description_container
          }
        >
          <Link
            linkFontType={
              stateHeaderUI?.isGoverningState ? "BOLD_HEADING_6" : "HEADING_6"
            }
            primaryFontColor
            displayValue={stateName}
            containerWidth="auto"
            underline="hover"
          />
          {stateHeaderUI?.isGoverningState && (
            <div
              className={style.footer_header_calculation_label_container}
              style={{ paddingLeft: "10px" }}
            >
              <Font>{`Gov. CC: ${stateHeaderUI?.classCodeWithHighestPremium}`}</Font>
            </div>
          )}
        </div>
        {stateHeaderUI?.isExpanded === true ? (
          <div className={style.footer_header_calculation_label_container}>
            <Font>
              {`${FormattingDate(effectiveDate)} - ${FormattingDate(
                expirationDate
              )}`}
            </Font>
          </div>
        ) : (
          <>
            <div className={style.footer_header_calculation_label_container}>
              <FontBold>PAYROLL:</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <FontBold
                name={`exposure-header-payroll-${stateCode}-${effectiveDate}-${expirationDate}`}
              >
                {stateHeaderUI?.payroll ?? ""}
              </FontBold>
            </div>
            <div
              className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
            >
              <FontBold>SURCHARGES AND FEES:</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <FontBold
                name={`exposure-header-surcharges-fees-${stateCode}-${effectiveDate}-${expirationDate}`}
              >
                {stateHeaderUI?.surchargesAndFees ?? ""}
              </FontBold>
            </div>
            <div
              className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
            >
              <FontBold>PREMIUM:</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <FontBold
                name={`exposure-header-premium-${stateCode}-${effectiveDate}-${expirationDate}`}
              >
                {stateHeaderUI?.premium ?? ""}
              </FontBold>
            </div>
          </>
        )}
      </div>
      <div className={style.header_delete_state_container}>
        <IconButton
          aria-label="save"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsDialogOpen(true);
          }}
          true-element={"option-delete-icon"}
          title="Delete"
          disabled={readOnly}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogConfirmation
        id="id_state_delete_confirmation"
        name="state_delete_confirmation"
        dialogDescriptionText="Are you sure you want to delete this state?"
        open={isDialogOpen}
        onOptionYesEvent={() => {
          deleteState(stateCode, effectiveDate, expirationDate);
          setIsDialogOpen(false);
        }}
        onOptionNoEvent={() => setIsDialogOpen(false)}
        onCloseEvent={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExposurePremiumStateHeader;
