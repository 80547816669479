import { useState, useEffect } from "react";
import { Box, Drawer, IconButton } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Row, Col } from "../../TrueUI";
import { NavBottomOpenAtom } from "../NavAtoms";
import { useApiGet } from "../../../hooks";
import { CustomerHelpDto } from "../../../dtos/customer-help-dto";
import { getPageId } from "./BottomNavFunctions";
import { PageIDEnums } from "../../../dtos/page-id-enums";
import { bottomNavStyles } from "./BottomNavStyles";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";

const BottomNav = () => {
  const [pageHelpId, setPageHelpId] = useState<number>(0);
  const [pageHelpMessage, setPageHelpMessage] = useState<string | null>();
  const { responseGet, dispatchGet } = useApiGet<CustomerHelpDto[]>(
    `api/Help/GetCustomerHelpByPageId/?pageId=${pageHelpId}`
  );
  const [isNavBottomOpen, setIsNavBottomOpen] =
    useRecoilState(NavBottomOpenAtom);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  useEffect(() => {
    if (isNavBottomOpen === true) {
      setPageHelpId(getPageId());
    } else {
      setPageHelpId(PageIDEnums.DEFAULT);
    }
  }, [isNavBottomOpen]);

  useEffect(() => {
    if (pageHelpId !== null) {
      dispatchGet();
    }
  }, [pageHelpId]);

  useEffect(() => {
    if (pageHelpId !== null && responseGet.requestInstanceSuccessful === true) {
      if (
        responseGet.axiosResponse?.data.length === 1 &&
        responseGet.axiosResponse?.data[0].pageId === 0
      ) {
        setPageHelpMessage(responseGet.axiosResponse?.data[0].message);
      } else {
        const sectionsHelp = responseGet.axiosResponse?.data?.filter(
          (helpInfo) => window.location.hash.includes(helpInfo.path)
        );
        if (sectionsHelp?.length === 0) {
          const pageHelpMessage =
            responseGet?.axiosResponse?.data?.filter(
              (helpInfo) => !helpInfo?.path.includes("#")
            )?.[0]?.message ?? null;
          if (pageHelpMessage !== null) {
            setPageHelpMessage(pageHelpMessage);
          } else {
            setPageHelpId(PageIDEnums.DEFAULT);
          }
        } else {
          setPageHelpMessage(sectionsHelp?.[0]?.message);
        }
      }
    }
  }, [responseGet]);

  return (
    <Drawer
      anchor={"bottom"}
      open={isNavBottomOpen}
      onClose={() => setIsNavBottomOpen(false)}
      PaperProps={{
        sx: { ...bottomNavStyles(theme) },
      }}
    >
      <Box
        sx={{ height: 300 }}
        role="presentation"
        id="help"
        className="help-drawer"
      >
        <Row>
          <Col
            equalWidth={false}
            verticalGutter="15px"
            horizontalGutter="15px"
            verticalAlign="flex-start"
          >
            <IconButton onClick={() => setIsNavBottomOpen(false)}>
              <ExpandMoreOutlinedIcon className={"icon-bottom-name"} />
            </IconButton>
          </Col>
          <Col
            verticalGutter="15px"
            horizontalGutter="15px"
            verticalAlign="flex-start"
            horizontalAlign="flex-start"
          >
            <>
              {isNavBottomOpen === true && (
                <div
                  dangerouslySetInnerHTML={{ __html: pageHelpMessage ?? "" }}
                />
              )}
            </>
          </Col>
        </Row>
      </Box>
    </Drawer>
  );
};

export default BottomNav;
