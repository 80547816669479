import { FC } from "react";
import { ModalSectionsProps } from "../TypesBillingOptions";
import { Divider, FontBold, Row, Select, Switch } from "../../../../../TrueUI";
import Col, { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";

const ModalSectionBillingOptions: FC<ModalSectionsProps> = ({
  billingContentConfig,
  billingRuleFormData,
  setBillingRuleFormData,
}) => {
  return (
    <div>
      <Divider height={15} />
      <FontBold>BILLING OPTIONS</FontBold>
      <Row verticalAlign={"flex-start"} {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          verticalGutter={"5px"}
        >
          <Select
            {...selectConfiguration(
              "insuredBillinOptionPreferredBillingMethod",
              "insuredBillingOptionPreferredBillingMethod",
              "Preferred Billing Method"
            )}
            readOnly={billingContentConfig?.isReadOnly}
            options={
              billingContentConfig?.billingRuleBillingMethodOptions ?? []
            }
            value={billingRuleFormData?.preferredBillingMethod}
            labelFontType={"BOLD_CAPTION"}
            onChange={(value) => {
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                preferredBillingMethod: value,
              });
            }}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
        ></Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
          verticalGutter={"5px"}
          horizontalAlign={"flex-start"}
        >
          <Switch
            id="insuredBillinOptionShowDepositOnPortal"
            name="insuredBillinOptionShowDepositOnPortal"
            label="Show Deposit Bill on Portal?"
            isChecked={billingRuleFormData?.showDepositOnPortal ?? false}
            labelFontType={"BODY"}
            readOnly={billingContentConfig?.isReadOnly}
            onChangeIsChecked={(checked) =>
              setBillingRuleFormData?.({
                ...billingRuleFormData,
                showDepositOnPortal: checked,
              })
            }
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 1, lg: 1, xl: 1 }}
        ></Col>
      </Row>
    </div>
  );
};

export default ModalSectionBillingOptions;
