import { Theme } from "../../../../media/themeTypes";

export const noteStyles = (theme: Theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "> *": {
    width: "100%",
  },
  ".add_diary": {
    mt: 1,
    ".MuiButtonBase-root": {
      minHeight: "40px",
      ".MuiAccordionSummary-content": {
        margin: "10px 0",
      },
    },
    ".MuiCheckbox-root": {
      minHeight: "auto",
      padding: "5px",
    },
  },
  ".k-editor-toolbar": {
    padding: 0,
    border: "none",
  },
  ".diary-entries": {
    ".search-notes-input": {
      mt: 2,
      ml: 2,
    },
    ".filters-container": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "flex-end",
      borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
      ".selects-container": {
        display: "flex",
        justifyContent: "space-evenly",
        flexGrow: 1,
        ".first-select": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    ".note_controls_container": {
      display: "flex",
      ".note_controls_column": {
        width: "50%",
        display: "flex",
        alignItems: "flex-end",
      },
      ".note_right_align": {
        justifyContent: "flex-end",
        "#id-note-export-to-excel": { marginRight: "10px" },
      },
    },
  },
});

export const modalNewAlertStyles = () => ({
  ".alert-instructions": {
    m: "10px 0",
  },
  ".alert-information": {
    display: "flex",
    gap: "10px",
  },
});
