/**
 * @deprecated The method should not be used
 */
export type queryStrings = {
  nameOfHash: string;
  valueOfHash: string | number;
};

/**
 * @deprecated The method should not be used
 */
function queryOrder(a, b) {
  if (a.nameOfHash < b.nameOfHash) {
    return -1;
  }
  if (a.nameOfHash > b.nameOfHash) {
    return 1;
  }
  return 0;
}

/**
 * @deprecated The method should not be used
 */
function buildNewHashes(newHashes: queryStrings[], refresh) {
  let newURL: string = refresh ? "" : window.location.hash;
  let oldQueries = convertPreviousHashes(newURL);
  let clearHashes = clearAndOrderQueries(newHashes, oldQueries);

  clearHashes.forEach((value) => {
    let newURLWithOutRepeated = removeExistingHash(value, newURL);
    newURL = newURLWithOutRepeated.concat(
      `#${value.nameOfHash}=${value.valueOfHash}`
    );
  });
  return newURL;
}

/**
 * @deprecated The method should not be used
 */
function clearAndOrderQueries(newQueries, oldQueries) {
  const names = newQueries.map((o) => o.nameOfHash);
  const filtered = oldQueries.filter(
    (query) => !names.includes(query.nameOfHash)
  );
  const clearQueries = newQueries.concat(filtered).sort(queryOrder);
  return clearQueries;
}

/**
 * @deprecated The method should not be used
 */
function convertPreviousHashes(previousHashes: string): queryStrings[] {
  const previous: queryStrings[] = [];
  if (previousHashes !== "") {
    previousHashes.split("#").forEach((query) => {
      if (query !== "") {
        const hashName = query.split("=")[0];
        const hashValue = query.split("=")[1];
        previous.push({ nameOfHash: hashName, valueOfHash: hashValue });
      }
    });
    return previous;
  }
  return [];
}

/**
 * @deprecated The method should not be used
 */
function getHashValue(hash) {
  const allURLHash = window.location.hash;
  let hashValue = "";
  if (allURLHash !== "") {
    allURLHash.split("#").forEach((query) => {
      if (query !== "") {
        if (query.split("=")[0] === hash) {
          hashValue = query.split("=")[1];
        }
      }
    });
  }
  return hashValue;
}

/**
 * @deprecated The method should not be used
 */
function removeExistingHash(value: queryStrings, newURL: string) {
  let newURLWithOutRepeated = "";
  if (newURL !== "") {
    newURL.split("#").forEach((query) => {
      if (query !== "" && query.split("=")[0] !== value.nameOfHash) {
        newURLWithOutRepeated = newURLWithOutRepeated.concat(`#${query}`);
      }
    });
  }
  return newURLWithOutRepeated;
}

/**
 * @deprecated The method should not be used
 */
export const addQueryStrings = (
  queryStrings: queryStrings[],
  refresh?: boolean
) => {
  const newHashes = buildNewHashes(queryStrings, refresh);
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search + newHashes
  );
};

/**
 * @deprecated The method should not be used
 */
export function getQueryStringsURL(hashStrings: queryStrings[]): string {
  return buildNewHashes(hashStrings, true);
}

/**
 * @deprecated The method should not be used
 */
export const removeQueryStrings = (queryStrings: string[]) => {
  const currentQueryStrings = window.location.hash.split("#");
  const newQueryStrings = currentQueryStrings.filter(
    (queryString) =>
      !queryStrings.find((oldQueryString) =>
        queryString.match(`^${oldQueryString}=`)
      )
  );

  window.history.pushState(
    "",
    document.title,
    window.location.pathname +
      window.location.search +
      newQueryStrings.join("#")
  );
};

/**
 * @deprecated The method should not be used
 */
export const getQueryValue = (name: string) => {
  return getHashValue(name);
};
