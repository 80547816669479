import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AgencyLocationDto } from "../../../dtos/agency-location-dto";
import {
  Input,
  InputDate,
  Select,
  InputPhone,
  Row,
  Col,
  InputAddress,
} from "../../TrueUI";
import { getStates } from "../../../utilities/staticDataFunctions";
import { SelectOptions } from "../../../dtos/select-options";
import { useApiGet, useApiPost } from "../../../hooks";
import { FromEnumToArrayWithExceptions } from "../../../utilities/enumFunctions";
import { StatusEnums } from "../../../dtos/status-enums";
import { InsuredReferenceDto } from "../../../dtos/insured-reference-dto";
import { AgencyLocationModalAtom } from "../AgencyAtoms";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";

const states: Partial<SelectOptions>[] = getStates().map((state) => ({
  displayName: state.abbreviation,
  stringValue: state.abbreviation,
}));

const status: Partial<SelectOptions>[] = FromEnumToArrayWithExceptions(
  StatusEnums,
  [StatusEnums.DELETED]
).map((enumType) => ({
  displayName: enumType?.key ?? "",
  stringValue: enumType?.value,
}));

export type AgencyLocationModalProps = {
  agencyLocationId?: number;
  isOpen?: boolean;
  isSaving?: boolean;
  isDeleting?: boolean;
  isNew?: boolean;
  refreshTable?: boolean;
};

type AgencyLocationProps = {
  agencyLocationId?: number;
  agencyId?: number;
  allLocationNumbers: string[];
  setDisableSaveButton: (disable: boolean) => void;
};

const AgencyLocation: FC<AgencyLocationProps> = ({
  agencyLocationId,
  agencyId,
  allLocationNumbers,
  setDisableSaveButton,
}) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [regions, setRegions] = useState<Partial<SelectOptions>[]>([]);
  const [isReadyForRender, setIsReadyForRender] = useState<boolean>(false);
  const [prevLocationNumber, setPrevLocationNumber] = useState<string>();
  const [agencyLocationValues, setAgencyLocationValues] =
    useState<AgencyLocationDto>();

  const [agencyLocationModalValues, setAgencyLocationModalValues] =
    useRecoilState(AgencyLocationModalAtom);

  const { responseGet, dispatchGet } = useApiGet<AgencyLocationDto>(
    `api/AgencyLocation/GetAgencyLocationAndRegionsById${
      agencyLocationModalValues?.isNew
        ? `?agencyId=${agencyId}`
        : `?agencyLocationId=${agencyLocationId}`
    }`
  );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/AgencyLocation/UpdateInsertAgencyLocation",
    agencyLocationValues
  );

  const setDefaultValues = () => {
    setAgencyLocationValues({
      ...(agencyLocationValues as AgencyLocationDto),
      state: agencyLocationValues?.state ?? states[0].stringValue ?? "",
      status: agencyLocationValues?.status ?? 1,
      effectiveDate: agencyLocationValues?.effectiveDate,
      expirationDate: agencyLocationValues?.expirationDate,
      agencyId: agencyId ?? -1,
      region: agencyLocationValues?.region ?? regions[0]?.stringValue ?? "",
      agencyLocationNumber: agencyLocationValues?.agencyLocationNumber ?? "1",
    });
  };

  const getFilteredLocationNumbers = () => {
    if (agencyLocationModalValues?.isNew) {
      return allLocationNumbers;
    }
    return allLocationNumbers.filter((locNum) => locNum !== prevLocationNumber);
  };

  const validLocationNumber = (newLocationNumber: string | null) => {
    setAgencyLocationValues({
      ...(agencyLocationValues as AgencyLocationDto),
      agencyLocationNumber: newLocationNumber ?? "",
    });
    const filteredLocationNumbers = getFilteredLocationNumbers();
    setDisableSaveButton(
      filteredLocationNumbers.includes(newLocationNumber ?? "")
    );
    setErrorDetails({
      ...errorDetails,
      agencyLocationNumber: filteredLocationNumbers.includes(
        newLocationNumber ?? ""
      )
        ? ["It is not a valid location number."]
        : null,
    });
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful) {
      const agencyLocationData: AgencyLocationDto | undefined =
        responseGet?.axiosResponse?.data;
      setPrevLocationNumber(agencyLocationData?.agencyLocationNumber);
      setAgencyLocationValues(agencyLocationData);
      mapRegions(responseGet?.axiosResponse?.data?.allRegions);
    }
  }, [responseGet]);

  useEffect(() => {
    if (regions.length > 0) {
      setDefaultValues();
      setIsReadyForRender(true);
    }
  }, [regions]);

  useEffect(() => {
    if (agencyLocationModalValues?.isSaving) {
      dispatchPost();
      setAgencyLocationModalValues({
        ...agencyLocationModalValues,
        isSaving: false,
      });
    }
  }, [agencyLocationModalValues?.isSaving]);

  useEffect(() => {
    if (agencyLocationModalValues?.isDeleting) {
      agencyLocationDelete();
      setAgencyLocationModalValues({
        ...agencyLocationModalValues,
        isDeleting: false,
      });
    }
  }, [agencyLocationModalValues?.isDeleting]);

  useEffect(() => {
    if (!responsePost.isLoading) {
      if (
        responsePost.requestInstanceSuccessful &&
        validatorErrorResponse === null
      ) {
        setAgencyLocationModalValues({
          ...agencyLocationModalValues,
          refreshTable: true,
          isOpen: false,
        });
      }
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  const mapRegions = (allRegions?: InsuredReferenceDto[]) => {
    const regionsTemp =
      allRegions?.map((insuredReference) => {
        return {
          displayName: insuredReference.value1 ?? "",
          stringValue: insuredReference.value1 ?? "",
        };
      }) ?? [];
    setRegions(regionsTemp);
  };

  const agencyLocationDelete = () => {
    if (agencyLocationValues !== undefined) {
      setAgencyLocationValues({
        ...(agencyLocationValues as AgencyLocationDto),
        status: 3,
      });
      dispatchPost();
    }
  };

  return isReadyForRender && agencyLocationValues !== undefined ? (
    <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
      <Row {...rowWithNoMarginNorGutter} rowDirection="column">
        <Col>
          <Input
            focus
            tabIndex={19}
            id="id-name-or-dba"
            name="name-name-or-dba"
            key={"nameOrDBA"}
            label="Name or DBA"
            type="text"
            errorMessage={errorDetails?.agencyLocationName}
            value={agencyLocationValues?.agencyLocationName ?? ""}
            maxLength={100}
            onChangeRawValue={(value) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                agencyLocationName: value,
              });
            }}
          />
        </Col>
        <Col {...colWithNoMarginNorGutter}>
          <InputAddress
            tabIndex={20}
            id="id-agency-location-address"
            label="Address"
            labelFontType="BOLD_CAPTION"
            readOnly={false}
            showSingleLabel={false}
            initValueAddress1={agencyLocationValues?.address1 ?? ""}
            initValueAddress2={agencyLocationValues?.address2 ?? ""}
            initValueCity={agencyLocationValues?.city ?? ""}
            initValueStateCode={agencyLocationValues?.state ?? ""}
            initValuePostCode={agencyLocationValues?.postCode ?? ""}
            address1ErrorMessage={errorDetails?.address1}
            address2ErrorMessage={errorDetails?.address2}
            cityErrorMessage={errorDetails?.city}
            postCodeErrorMessage={errorDetails?.postCode}
            stateCodeErrorMessage={errorDetails?.state}
            onChangeValueAddress1={(value) => {
              setAgencyLocationValues({
                ...agencyLocationValues,
                address1: value,
              });
            }}
            onChangeValueAddress2={(value) => {
              setAgencyLocationValues({
                ...agencyLocationValues,
                address2: value,
              });
            }}
            onChangeValueCity={(value) => {
              setAgencyLocationValues({
                ...agencyLocationValues,
                city: value,
              });
            }}
            onChangeStateCode={(value) => {
              setAgencyLocationValues({
                ...agencyLocationValues,
                state: value,
              });
            }}
            onChangeValuePostCode={(value) => {
              setAgencyLocationValues({
                ...agencyLocationValues,
                postCode: value,
              });
            }}
          />
        </Col>
        <Col>
          <InputPhone
            tabIndex={26}
            hasExtension
            id="id-phone-number"
            name="name-phone-number"
            key={"phoneNumber"}
            phoneLabel="Phone Number"
            errorMessagePhone={errorDetails?.phoneNumber}
            phoneValue={agencyLocationValues?.phoneNumber ?? ""}
            extValue={agencyLocationValues?.phoneAdd ?? ""}
            onChangePhoneValue={(phone) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                phoneNumber: phone,
              });
            }}
            onChangeExtensionValue={(value) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                phoneAdd: value,
              });
            }}
          />
        </Col>
        <Col>
          <InputPhone
            tabIndex={28}
            id="id-fax-number"
            name="name-fax-number"
            key={"faxNumber"}
            phoneLabel="Fax Number"
            errorMessagePhone={errorDetails?.faxNumber}
            phoneValue={agencyLocationValues?.faxNumber ?? ""}
            onChangePhoneValue={(phone) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                faxNumber: phone,
              });
            }}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} rowDirection="column">
        <Col>
          <Input
            tabIndex={29}
            id="id-location-number"
            name="name-location-number"
            key={"locationNumber"}
            label="Location Number"
            type="stringNumber"
            errorMessage={errorDetails?.agencyLocationNumber}
            value={agencyLocationValues?.agencyLocationNumber ?? ""}
            onChangeRawValue={(value) => {
              validLocationNumber(value);
            }}
          />
        </Col>
        <Col>
          <Select
            tabIndex={30}
            id="id-regions"
            name="name-regions"
            key={"regions"}
            label="Region"
            labelFontType="BOLD_CAPTION"
            options={regions}
            errorMessage={errorDetails?.region}
            value={agencyLocationValues?.region}
            onChange={(select) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                region: select,
              });
            }}
          />
        </Col>
        <Col>
          <Select
            tabIndex={31}
            id="id-status"
            name="name-status"
            key={"status"}
            label="Status"
            labelFontType="BOLD_CAPTION"
            options={status}
            errorMessage={errorDetails?.status}
            value={agencyLocationValues?.status}
            onChange={(select) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                status: select,
              });
            }}
          />
        </Col>
        <Col>
          <InputDate
            tabIndex={32}
            id="id-effective-date"
            name="name-effective-date"
            key={"effectiveDate"}
            label="Effective Date"
            value={agencyLocationValues?.effectiveDate}
            errorMessage={errorDetails?.effectiveDate}
            onChangeRawValue={(dateValue) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                effectiveDate: dateValue,
              });
            }}
          />
        </Col>
        <Col>
          <InputDate
            tabIndex={33}
            id="id-expiration-date"
            name="name-expiration-date"
            key={"expirationDate"}
            label="Expiration Date"
            value={agencyLocationValues?.expirationDate}
            errorMessage={errorDetails?.expirationDate}
            onChangeRawValue={(dateValue) => {
              setAgencyLocationValues({
                ...(agencyLocationValues as AgencyLocationDto),
                expirationDate: dateValue,
              });
            }}
          />
        </Col>
      </Row>
    </Row>
  ) : (
    <div>...LOADING</div>
  );
};

export default AgencyLocation;
