import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import { ModalRelatedInsuredWrapperProps } from "../RelatedInsuredTypes";
import { AddInsuredParentRelationshipDto } from "../../../../dtos/add-insured-parent-relationship-dto";
import { InsuredRelationshipTypeEnums } from "../../../../dtos/insured-relationship-type-enums";
import RelatedInsuredForm from "./RelatedInsuredForm";
import RelatedInsuredTableWrapper from "./RelatedInsuredTableWrapper";

const ModalRelatedInsuredWrapper: FC<ModalRelatedInsuredWrapperProps> = ({
  modalConfig,
  setModalConfig,
  insuredId,
}) => {
  const [insuredRelationshipData, setInsuredRelationshipData] =
    useState<AddInsuredParentRelationshipDto>({
      parentInsuredId: null,
      insuredId: insuredId,
      relationshipTypeId: InsuredRelationshipTypeEnums.COMMON_OWNERSHIP,
      onlyRemoveRelationship: false,
    });
  useEffect(() => {
    if (modalConfig?.isModalOpen === false) {
      setInsuredRelationshipData({
        parentInsuredId: null,
        insuredId: insuredId,
        relationshipTypeId: InsuredRelationshipTypeEnums.COMMON_OWNERSHIP,
        onlyRemoveRelationship: false,
      });
    }
  }, [modalConfig?.isModalOpen]);

  useEffect(() => {
    if (insuredRelationshipData?.relationshipCreated === true) {
      setModalConfig({
        ...modalConfig,
        isModalOpen: false,
        refreshRelatedTable: true,
      });
    }
  }, [insuredRelationshipData?.relationshipCreated]);

  useEffect(() => {
    if (insuredRelationshipData?.parentRelationshipRemoved === true) {
      setModalConfig({
        ...modalConfig,
        refreshRelatedTable: true,
      });
    }
  }, [insuredRelationshipData?.parentRelationshipRemoved]);
  const closeCancelEvent = () => {
    setModalConfig({
      ...modalConfig,
      refreshRelatedTable: false,
      isModalOpen: false,
    });
  };
  return (
    <div className={`modal_related_insureds`}>
      <Modal
        id={`modalInsuredRelationships`}
        open={modalConfig?.isModalOpen}
        title="Relationships"
        cancelButtonConfirmationText="Are you sure you want to cancel, your work will be lost?"
        cancelEvent={() => closeCancelEvent()}
        closeEvent={() => closeCancelEvent()}
        showCancelTextButton
        cancelButtonWithConfirmation
        showCloseButton
        extraButtons={[
          {
            textOnButton: "Remove current parent",
            onClick: () => {
              setInsuredRelationshipData({
                ...insuredRelationshipData,
                parentInsuredId: null,
                onlyRemoveRelationship: true,
              });
            },
          },
        ]}
        showExtraButtons
      >
        <RelatedInsuredForm
          insuredId={insuredId}
          insuredRelationshipData={insuredRelationshipData}
          setInsuredRelationshipData={setInsuredRelationshipData}
        />
        <RelatedInsuredTableWrapper insuredId={insuredId} key={modalConfig.relatedInsuredCollapseTableKey}/>
      </Modal>
    </div>
  );
};

export default ModalRelatedInsuredWrapper;
