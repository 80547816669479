import { FC, useEffect, useState } from "react";
import { Link } from "@mui/material";
import Font from "../../../../../TrueUI/Typography/Font";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPreviousRunningTotal,
  getRunningTotalByPremiumBasisRow,
  getStatesUpdatedByRowResult,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
  ScheduleFormUIProps,
  ScheduleRateItemsUIProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import ModalScheduleRating from "../PremiumTableRowSupportComponents/ModalScheduleRating";
import { getTotalPreviousAndValue } from "../../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import { ScheduleRatingsDto } from "../../../../../../dtos/schedule-ratings-dto";
import {
  customRound,
  getNumberAsStringWithComas,
} from "../../../../../../utilities/stringFunctions";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { useRecoilValue } from "recoil";
import {
  SCHEDULE_FORM_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { getStateByStateCodeAndDates } from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import { ScheduleRateItemDto } from "../../../../../../dtos/schedule-rate-item-dto";
import style from "./PremiumTableRows.module.css";

const getRunningTotalAndCalculatedAmount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  scheduleRatingUI: ScheduleRatingsDto[] | null,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    2
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: rate,
    scheduleRatings: scheduleRatingUI,
  };
  return rowResult;
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  scheduleRatingUI: ScheduleRatingsDto[] | null,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const calculationResults = getRunningTotalAndCalculatedAmount(
    stateCode,
    effectiveDate,
    expirationDate,
    rate,
    rateIndex,
    rating,
    scheduleRatingUI,
    atomValue
  );
  const statesUpdatedByCalculations = getStatesUpdatedByRowResult(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    atomValue
  );
  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const ScheduleFormRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [scheduleRatingUI, setScheduleRatingUI] =
    useState<ScheduleRatingsDto[]>();
  const [scheduleFormUI, setScheduleFormUI] = useState<ScheduleFormUIProps>();
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );

  const listenerScheduleFormRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${SCHEDULE_FORM_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  const runCalculations = () => {
    const atomValue = getAtom();
    const scheduleRatings = props.rating.scheduleRatings ?? [];
    const updatedValues = getStatesUpdatedByCalculations(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      rowResult.rate,
      props.rateIndex,
      props.rating,
      scheduleRatingUI ?? scheduleRatings,
      atomValue
    );
    setAtom(updatedValues.newAtomValue);
    setRowResult(updatedValues.rowResult);
    props.calculationsFinished?.();
  };

  const openEvent = () => {
    setScheduleFormUI({
      ...scheduleFormUI,
      isModalOpen: true,
    });
  };

  const saveEvent = (localModalUI?: ScheduleRateItemsUIProps[] | null) => {
    setScheduleRatingUI(
      localModalUI?.map((local) => ({
        scheduleRateItemID: local.scheduleRateItemID,
        value: local.value,
        reason: local.reason,
        description: local.description,
        maxValue: local.maxValue,
        minValue: local.minValue,
      }))
    );

    setRowResult({
      ...rowResult,
      rate: parseFloat(getTotalPreviousAndValue(localModalUI)),
    });
  };

  const closeEvent = () =>
    setScheduleFormUI({ ...scheduleFormUI, isModalOpen: false });

  const findScheduleRateItem = (
    rateItems?: ScheduleRateItemDto[],
    rateItemId?: number | null
  ) => rateItems?.find((item) => item.scheduleRateItemID === rateItemId);

  const getScheduleRateItems = (scheduleRatings: ScheduleRatingsDto[]) =>
    scheduleRatings.map((rating) => {
      const item = findScheduleRateItem(
        props.configurations?.scheduleRatingInformation.scheduleRateItems,
        rating?.scheduleRateItemID
      );
      return {
        ...rating,
        description: item?.description ?? "",
        maxValue: item?.maxValue,
        minValue: item?.minValue,
      };
    }) as ScheduleRateItemDto[];

  const updateScheduleRatings = () => {
    const scheduleRatings =
      scheduleRatingUI ?? props.rating.scheduleRatings ?? [];

    const updatedScheduleRatings =
      scheduleRatings.length > 0
        ? getScheduleRateItems(scheduleRatings)
        : props.configurations?.scheduleRatingInformation?.scheduleRateItems;

    setScheduleFormUI({
      isModalOpen: false,
      scheduleRating: {
        ...props.configurations?.scheduleRatingInformation,
        scheduleRateItems: updatedScheduleRatings,
      },
    });
  };

  useEffect(() => {
    if (scheduleRatingUI) {
      updateScheduleRatings();
    }
  }, [scheduleRatingUI]);

  useEffect(() => {
    if (!props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [rowResult.rate, scheduleRatingUI]);

  useEffect(() => {
    if (listenerScheduleFormRowComponent !== null && !props.readOnly) {
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerScheduleFormRowComponent]);

  useEffect(() => {
    updateScheduleRatings();
  }, []);

  return (
    <>
      <div className={style.premium_row_container}>
        <div className={style.premium_row_name_cell_5}>
          <Link
            href="#"
            onClick={openEvent}
            true-element={`${getNameToExposureTableColumns(props, 0)}`}
          >
            {props.rating.elementName ?? ""}
          </Link>
        </div>
        <div className={style.premium_row_rate_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
            {getNumberAsStringWithComas(rowResult.rate)}
          </Font>
        </div>
        <div className={style.premium_row_calculated_amount_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
            {getNumberAsStringWithComas(rowResult.calculatedAmount)}
          </Font>
        </div>
        {hasQuotedRunningTotal && (
          <div className={style.premium_row_previous_running_total_cell}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
              {getNumberAsStringWithComas(
                props.rating.previousRunningTotal ?? 0
              )}
            </Font>
          </div>
        )}
        <div
          className={
            hasQuotedRunningTotal
              ? style.premium_row_running_total_cell_10
              : style.premium_row_running_total_cell_7
          }
        >
          <Font
            trueElement={`${getNameToExposureTableColumns(
              props,
              hasQuotedRunningTotal ? 4 : 3
            )}`}
          >
            {getNumberAsStringWithComas(rowResult.runningTotal)}
          </Font>
        </div>
      </div>
      {scheduleFormUI ? (
        <ModalScheduleRating
          state={props.stateName}
          scheduleFormUI={scheduleFormUI}
          saveEvent={saveEvent}
          closeEvent={closeEvent}
          readOnly={props.readOnly ?? false}
        />
      ) : null}
    </>
  );
};

export default ScheduleFormRow;
