import { FC, useState } from "react";
import { KeysAsType } from "../../../../types/KeysAsAType";
import { FontsType } from "../../../../media/themeTypes";
import Switch from "../../Checkbox/Switch";
import Input from "../Input";
import { Box } from "@mui/material";
import { questionInputYesNoStyles } from "./QuestionInputStyles";
import Font from "../../Typography/Font";

export type QuestionInputYesNoProps = {
  switchIdName: string;
  textBoxIdName: string;
  switchClassName?: string;
  textBoxClassName?: string;
  switchIsChecked?: boolean;
  textBoxValue?: string;
  switchLabel?: string;
  switchLabelFontType?: KeysAsType<FontsType>;
  inputFontType?: KeysAsType<FontsType>;
  textBoxVariant?: "filled" | "standard" | "outlined";
  textBoxMaxLength?: number;
  readonly?: boolean;
  errorMessage?: string[];
  onChange?: (e: any) => void;
  onChangeRawValue?: (e: { checked: boolean; textBoxValue: string }) => void;
  tabIndex?: number;
};

const QuestionInputYesNo: FC<QuestionInputYesNoProps> = ({
  switchIdName,
  textBoxIdName,
  switchClassName,
  textBoxClassName,
  switchIsChecked,
  textBoxValue,
  switchLabel,
  switchLabelFontType,
  inputFontType,
  textBoxVariant,
  textBoxMaxLength,
  readonly,
  errorMessage,
  onChange,
  onChangeRawValue,
  tabIndex = 0,
}) => {
  const [value, setValue] = useState<{
    checked: boolean;
    textBoxValue: string;
  }>({ checked: switchIsChecked ?? false, textBoxValue: textBoxValue ?? "" });

  const onChangeSwitchValue = (checked: boolean) => {
    const newValue = {
      checked,
      textBoxValue: value.textBoxValue,
    };
    onChangeRawValue?.(newValue);
    setValue(newValue);
  };

  const onChangeTextBoxValue = (textBoxValue: string) => {
    const newValue = {
      checked: value.checked,
      textBoxValue,
    };
    onChangeRawValue?.(newValue);
    setValue(newValue);
  };

  return (
    <Box sx={questionInputYesNoStyles()}>
      <div style={{ width: 100, display: "flex", alignItems: "center" }}>
        <Switch
          tabIndex={tabIndex}
          id={switchIdName}
          name={switchIdName}
          className={`true_question_yes_no_switch ${switchClassName}`}
          readOnly={readonly}
          control="checkbox"
          isChecked={switchIsChecked}
          onChange={onChange}
          onChangeIsChecked={onChangeSwitchValue}
        />
        <Font fontType={switchLabelFontType}>{switchLabel}</Font>
      </div>
      {switchIsChecked && (
        <div className="true_question_yes_no_textbox_container">
          <Input
            tabIndex={tabIndex}
            id={textBoxIdName}
            name={textBoxIdName}
            className={`true_question_yes_no_textbox ${textBoxClassName}`}
            inputFontType={inputFontType}
            readOnly={readonly}
            variant={textBoxVariant}
            maxLength={textBoxMaxLength}
            errorMessage={errorMessage}
            value={textBoxValue}
            onChange={onChange}
            onChangeRawValue={onChangeTextBoxValue}
          />
        </div>
      )}
    </Box>
  );
};

export default QuestionInputYesNo;
