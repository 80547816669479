import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PayrollReportDetailInfoDto } from "../../../../dtos/payroll-report-detail-info-dto";
import { PayrollReportDetailRowDto } from "../../../../dtos/payroll-report-detail-row-dto";
import { PayrollReportPayInlineDto } from "../../../../dtos/payroll-report-pay-inline-dto";
import { PayrollReportStatusEnum } from "../../../../dtos/payroll-report-status-enum";
import { TableData, TableRow } from "../../../TrueUI/Tables/BaseTableStructure";
import {
  getCellValueRowOne,
  getCellValueRowThree,
  getCellValueRowTwo,
  getColumnWidth,
  getUpdatedTotals,
  getUpdatedTotalsDetail,
} from "./PayrollReportDetailUtils";
import PayrollReportPayInlineForm from "./PayrollReportPayInlineForm";

export const CustomFooterComponentGeneralPayrollReport = (
  rows: any,
  isCheckPaymentDisabled: boolean,
  payrollReportDetailInfo: Partial<PayrollReportDetailInfoDto> | null,
  editedDetailRows: PayrollReportDetailRowDto[],
  payInlineData: PayrollReportPayInlineDto | null,
  setPayInlineData: (value: PayrollReportPayInlineDto | null) => void,
  errorDetails: any
) => {
  const rowBase = rows?.[0];
  const { totalEmployees, totalPayroll, totalAmount } = getUpdatedTotals(
    payrollReportDetailInfo?.referenceDetailsRows ?? [],
    editedDetailRows ?? [],
    rows
  );

  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowOne(
              column,
              totalEmployees,
              totalPayroll,
              totalAmount
            )}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowTwo(column, payrollReportDetailInfo?.amountPaid)}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowThree(
              column,
              totalAmount,
              payrollReportDetailInfo?.amountPaid
            )}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      {payrollReportDetailInfo?.statusId === PayrollReportStatusEnum.ACTIVE && (
        <TableRow
          style={{
            width: "100%",
            display: "flex",
            fontWeight: "700",
          }}
        >
          <PayrollReportPayInlineForm
            allColumns={rowBase?.Columns}
            totalAmount={totalAmount}
            payInlineData={payInlineData}
            setPayInlineData={setPayInlineData}
            errorDetails={errorDetails}
            isCheckPaymentDisabled={isCheckPaymentDisabled}
          />
        </TableRow>
      )}
    </>
  );
};

export const CustomFooterComponentDetail = (
  rows: any,
  columns: BaseTableColumn[]
) => {
  const { totalEmployees, totalPayroll, totalAmount } =
    getUpdatedTotalsDetail(rows);
  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {columns.map((column, key) => (
          <TableData
            key={key}
            width={getColumnWidth(columns, column.fieldName)}
          >
            {getCellValueRowOne(
              column.fieldName,
              totalEmployees,
              totalPayroll,
              totalAmount
            )}
          </TableData>
        ))}
        <TableData width={"35px"}></TableData>
      </TableRow>
    </>
  );
};
