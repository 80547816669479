import { FC, useEffect, useState } from "react";
import {
  BaseTable2Properties,
  FontStyleProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import IconButton from "../../TrueUI/Buttons/IconButton";
import { BaseTable2 } from "../../TrueUI";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { globalOptions } from "../../../GlobalAtoms";
import BaseTableSearchFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import { TasksCardConfigDto } from "../../../dtos/tasks-card-config-dto";
import { useNavigate } from "react-router";
import OpenIcon from "@mui/icons-material/Launch";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  DRAWER_TABS,
  DRAWER_TASK_ID,
  OPEN_DRAWER,
} from "../../../utilities/queryStringsHash";
import { DrawerActionsEnums } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";

import {
  FormattingDate,
  isExpiredDate,
} from "../../../utilities/dateFunctions";
import color from "../../../media/colors";
import { INTERNAL_TABLE_COLUMNS_OBJ } from "../../TrueUI/Tables/TableConstants";
import { useRecoilState, useRecoilValue } from "recoil";
import { TaskBoardItemsAtom } from "../../TrueUI/TaskBoard/TaskBoardAtoms";
import AssignedTasksCategoryFilter from "./AssignedTasksCategoryFilter";
import { TasksTypeFilterAtom } from "./AssignedTasksAtoms";
import BaseTableSwitchStatusFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSwitchStatusFilter";
import { TaskBoardCardEnum } from "../../../dtos/task-board-card-enum";
import { usePermissions } from "../../../hooks";
import { claimPermissions } from "../../../utilities/claimPermissions";

type AssignedTasksTableProps = {
  filterType: number;
  cardId: string;
  tasksConfig: TasksCardConfigDto | null;
};

const AssignedTasksTable: FC<AssignedTasksTableProps> = ({
  filterType,
  cardId,
  tasksConfig,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [tasksTypeFilter, setTasksTypeFilter] =
    useRecoilState(TasksTypeFilterAtom);
  const hasUserClaimPermission = usePermissions(claimPermissions);
  const { getAtom } = useAtomFamily(globalOptions);
  const currentUser = getAtom().userId;
  const navigate = useNavigate();

  const taskItemsAtom = useRecoilValue(TaskBoardItemsAtom);

  const dueDate = taskItemsAtom[cardId];
  const stringDate = FormattingDate(dueDate, "YYYY-MM-DD");

  const name = "assigned_tasks_table";
  const getURL = `api/Dashboard/GetTasksByFilter?filter=${filterType}&dueDate=${stringDate}&showAllActive=${showAll}`;

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() =>
          openEditTask(rowData.row?.InsuredOrClaimantLink, rowData.row?.TaskId)
        }
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  useEffect(() => {
    setDefaultTasksType();
  }, []);

  const setDefaultTasksType = () => {
    const defaultTasksType = hasUserClaimPermission.hasPermission
      ? "claim"
      : "insured";
    setTasksTypeFilter(defaultTasksType);
  };

  const openEditTask = (taskUrl: string, taskId: number) => {
    const drawerHashes = getQueryStringsURL([
      {
        nameOfHash: OPEN_DRAWER,
        valueOfHash: 1,
      },
      {
        nameOfHash: DRAWER_TABS,
        valueOfHash: DrawerActionsEnums.TASKS,
      },
      {
        nameOfHash: DRAWER_TASK_ID,
        valueOfHash: taskId,
      },
    ]);

    navigate(taskUrl + drawerHashes);
  };

  const overdueTaskStyle = (rowData: any): FontStyleProperties => {
    return {
      fontColor: isExpiredDate(rowData.DueDate) ? color.DARK_RED : "inherit",
    };
  };

  const showAllActiveFilterByCardType =
    filterType === TaskBoardCardEnum.TASKS
      ? [
          (actionOptions) =>
            BaseTableSwitchStatusFilter({
              filterOptions: actionOptions,
              checkedLabelText: "Show All Active:",
              labelPosition: "start",
              labelFontType: "TITLE",
              usingExternalFiltering: true,
              onChange: (val) => {
                console.log({ val });
                setShowAll(val);
              },
              defaultValue: showAll,
            }),
        ]
      : [];

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "assigned_to",
          filterOptions: actionOptions,
          optionsOverride: addAllOption(tasksConfig?.assignedOptions ?? []),
          columnsNames: ["AssignedToId"],
          labelText: "Assigned:",
          defaultValue: currentUser,
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "type",
          filterOptions: actionOptions,
          optionsOverride: tasksConfig?.typeOptions ?? [],
          columnsNames: ["TaskType"],
          labelText: "Type:",
          defaultValue: tasksTypeFilter,
          onChange: setTasksTypeFilter,
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "priority",
          filterOptions: actionOptions,
          optionsOverride: addAllOption(tasksConfig?.priorityOptions ?? []),
          columnsNames: ["Priority"],
          labelText: "Priority:",
          defaultValue: "all",
        }),
      (actionOptions) =>
        AssignedTasksCategoryFilter({
          actionOptions,
          configurationState: tasksConfig,
        }),
      ...showAllActiveFilterByCardType,
      (actionOptions) =>
        BaseTableSearchFilter(
          actionOptions,
          ["InsuredOrClaimNumber", "InsuredOrClaimantName"],
          ["claim/insured # or claimant/insured name"]
        ),
    ],
    columnOptions: [
      {
        fieldName: INTERNAL_TABLE_COLUMNS_OBJ.INDICATION_COLUMN_FIELD_NAME,
        width: 4,
        displayNameOverride: "Type",
      },
      { fieldName: "InsuredOrClaim", width: 11 },
      { fieldName: "Name", width: 24 },
      { fieldName: "AssignedTo", width: 8 },
      { fieldName: "Subject", width: 14 },
      { fieldName: "Category", width: 15 },
      { fieldName: "Priority", width: 8 },
      { fieldName: "DueDate", width: 8 },
      { fieldName: "Status", width: 8 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: true,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      indicationColumnConfiguration: {
        conditionalIcon: (row) => row.TaskType,
        isHidden: false,
      },
      tableStyle: {
        rowStyle: {
          applyTo: "row",
          fontStyle: overdueTaskStyle,
        },
      },
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={name} />;
};
export default AssignedTasksTable;
