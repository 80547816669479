import { FC } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { ClaimStatusEnums } from "../../../../dtos/claim-status-enums";
import { formattingKey } from "../../../../utilities/enumFunctions";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { globalOptions } from "../../../../GlobalAtoms";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";

const tableName = "claims_table";

type ClaimsTableSelectFiltersOptionsProps = {
  claimTypes: Partial<SelectOptions>[];
  claimStatuses: Partial<SelectOptions>[];
  assignees: Partial<SelectOptions>[];
  renderInsuredClaims: boolean;
};
type ClaimsTableProps = {
  searchFilters: ClaimsTableSelectFiltersOptionsProps;
  insuredId: number;
};

const ClaimsTable: FC<ClaimsTableProps> = ({ searchFilters, insuredId }) => {
  const { getAtom } = useAtomFamily(globalOptions);
  const getDefaultAssignee = () =>
    searchFilters?.assignees.findIndex(
      (assignee) => assignee.intValue === getAtom().userId
    ) === -1
      ? "all"
      : getAtom().userId;
  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `api/InsuredClaims/GetInsuredClaimsTable?insuredId=${insuredId}`,
    columnOptions: [
      { fieldName: "ClaimNumberLink", width: 8 },
      { fieldName: "ProgramCode", width: 6 },
      { fieldName: "LineType", width: 5 },
      { fieldName: "Jurisdiction", width: 5 },
      { fieldName: "Claimant", width: 20 },
      { fieldName: "ClaimType", width: 10 },
      { fieldName: "DateOfIncident", width: 12 },
      { fieldName: "DateReported", width: 10 },
      { fieldName: "ClaimStatus", width: 5 },
      { fieldName: "Assignee", width: 15 },
      { fieldName: "DOB", width: 5 },
      {
        fieldName: "TotalIncurred",
        width: 8,
        align: "right",
        // decimalScale: 2,
        // allowNegatives: true,
      },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "claimType",
          columnNames: ["ClaimType"],
          optionsOverride: searchFilters?.claimTypes,
          labelText: "Claim Type:",
          labelPosition: "start",
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "claimStatus",
          columnNames: ["ClaimStatus", "HiddenClaimStatus"],
          optionsOverride: searchFilters?.claimStatuses,
          labelText: "Status:",
          labelPosition: "start",
          defaultValue: formattingKey(ClaimStatusEnums[ClaimStatusEnums.OPEN]),
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "claimAssignee",
          columnNames: ["AssignedUserId"],
          optionsOverride: searchFilters?.assignees,
          labelText: "Assigned To:",
          labelPosition: "start",
          defaultValue: getDefaultAssignee(),
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
  };

  useBaseGrid(tableConfiguration);
  return <BaseGrid name={tableName} />;
};

export default ClaimsTable;
