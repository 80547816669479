import { FC, useEffect } from "react";
import { NavItemDto } from "../../../dtos/nav-item-dto";
import { NavSubItemDto } from "../../../dtos/nav-sub-item-dto";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import {
  getQueryStringsURL,
  getQueryValue,
} from "../../../utilities/URLUtilities_OBSOLETE";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
} from "../../../utilities/queryStringsHash";
import SubSideNav from "../../TrueUI/SubSideNav/SubSideNav";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../InsuredAtoms";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AssignmentsView from "./InsuredAssignments/AssignmentsView";
import style from "./insuredLeftSubNav.module.css";
import { InsuredTabDto } from "../../../dtos/insured-tab-dto";

type InsuredLeftSubNavProps = {
  insuredTabContent: InsuredTabDto;
  tabKey: string;
};

const getParentSelected = (queryName, getAtom) => {
  const queryValue = getQueryValue(queryName);
  const parentRecoilValue = getAtom()?.navConfiguration?.parentItemSelected;
  return queryValue !== "" ? parseInt(queryValue) : parentRecoilValue ?? 0;
};

const getChildSelected = (queryName, getAtom) => {
  const queryValue = getQueryValue(queryName);
  const parentRecoilValue = getAtom()?.navConfiguration?.childItemSelected;
  return queryValue !== "" ? parseInt(queryValue) : parentRecoilValue ?? 0;
};

export const setParentValue = (parent, getAtom, setAtom) => {
  const atomNavConfiguration = {
    ...getAtom(),
    navConfiguration: {
      ...getAtom()?.navConfiguration,
      parentItemSelected: parent,
    },
  };

  setAtom(atomNavConfiguration);
};

export const setBothValues = (parent, child, getAtom, setAtom) => {
  const atomNavConfiguration = {
    ...getAtom(),
    navConfiguration: {
      parentItemSelected: parent,
      childItemSelected: child,
    },
  };

  setAtom(atomNavConfiguration);
};

const InsuredLeftSubNav: FC<InsuredLeftSubNavProps> = ({
  insuredTabContent,
  tabKey,
}) => {
  const navigate = useNavigate();
  useRecoilValue(TriggerComponentUpdateAtom("navComponent"));

  const insuredId = insuredTabContent?.insuredInformation?.insuredId;
  const navItems = insuredTabContent?.subSideNav?.navItems;

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(`${INSURED_ATOM_KEY} ${tabKey}`)
  );

  const parentSelected = getParentSelected(INSURED_PARENT_ITEM, getAtom);
  const childSelected = getChildSelected(INSURED_CHILD_ITEM, getAtom);

  const onParentItemClick = (parent?: NavItemDto) => {
    setParentValue(parent?.index, getAtom, setAtom);
  };

  const onChildItemClick = (parent: NavItemDto, child: NavSubItemDto) => {
    setBothValues(parent.index, child.index, getAtom, setAtom);
    const newURL = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: child.section,
      },
    ]);
    navigate(newURL);
  };

  useEffect(() => {
    setBothValues(parentSelected, childSelected, getAtom, setAtom);
  }, []);

  return (
    <div id={"left_insured_wrapper"} className={style.left_insured_wrapper}>
      <div className={style.sub_side_nav_wrapper}>
        <SubSideNav
          navItems={navItems}
          onParentItemClick={onParentItemClick}
          onChildItemClick={onChildItemClick}
          parentSelected={parentSelected}
          childSelected={childSelected}
          parentQueryString={INSURED_PARENT_ITEM}
          childQueryString={INSURED_CHILD_ITEM}
        />
      </div>
      <AssignmentsView insuredId={insuredId} />
    </div>
  );
};

export default InsuredLeftSubNav;
