import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { Font, Input } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useApiGet } from "../../../../hooks";
import { BaseTable } from "../../../../dtos/base-table";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import EndorsementPayrollReportTable from "./EndorsementPayrollReportTable";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { getUpdatedPayrollReportTable } from "./EndorsementBindInstructionsUtils";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { getNumberAsStringWithComas } from "../../../../utilities/stringFunctions";

const EndorsementReportingBindInstructions: FC<EndorsementGeneralProps> = (
  props
) => {
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { responseGet, dispatchGet } = useApiGet<BaseTable>(
    `api/PayrollReport/GetPayrollReportTableForEndorsementBindInstructions?insuredId=${props.insuredId}&policyId=${props.policyId}`
  );
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);
  const endorsementPremiumChange =
    getAtom()?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.endorsementPremiumChange ?? 0;
  const endorsementPremiumChangeLabel =
    endorsementPremiumChange < 0
      ? `(${getNumberAsStringWithComas(Math.abs(endorsementPremiumChange))})`
      : getNumberAsStringWithComas(endorsementPremiumChange);

  const updatePayrollReportTable = (payrollReportTable: BaseTable) => {
    const atomValue = getAtom();
    const newAtomValue = getUpdatedPayrollReportTable(
      payrollReportTable,
      atomValue
    );

    setAtom(newAtomValue);
    setReadyToRender(true);
  };

  useEffect(() => {
    const atomValue = getAtom();
    const payrollReportTableInAtom =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.payrollReportTable;
    if (
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteStatus
        ?.value === PolicyQuoteStatusEnum.IN_PROGRESS
    )
      dispatchGet();
    else if (conditionHasValue(payrollReportTableInAtom)) {
      updatePayrollReportTable(payrollReportTableInAtom);
    }
  }, []);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      responseGet.axiosResponse?.data !== undefined
    )
      updatePayrollReportTable(responseGet.axiosResponse?.data);
  }, [responseGet]);

  useEffect(() => {
    if (props.readonly === true) setShowTable(false);
  }, [props.readonly]);

  useEffect(() => {
    if (showTable === false) setShowTable(true);
  }, [showTable]);

  return (
    <div className={style.endorsement_bind_instructions_body_container}>
      <Input
        id="id-endorsement-premium-change"
        name="endorsement-premium-change"
        label="Endorsement Premium Change"
        inputWidth="250px"
        readOnly
        value={endorsementPremiumChangeLabel}
      />
      <Font
        className={
          style.endorsement_informative_reporting_bind_instructions_text
        }
        textAlign="start"
      >
        This policy is on payroll reporting. Any changes in premium will be
        reflected in new modified rates going forward.
      </Font>
      <Font
        className={
          style.endorsement_informative_reporting_bind_instructions_text
        }
        textAlign="start"
      >
        Payroll reports listed below (if any) are currently active. Place a
        check next to any you would like to have updated with the new rates.
      </Font>
      {readyToRender && showTable && (
        <EndorsementPayrollReportTable {...props} />
      )}
    </div>
  );
};

export default EndorsementReportingBindInstructions;
