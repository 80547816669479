import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AgencyPoliciesFilterEnum } from "../../dtos/agency-policies-filter-enum";
import { SelectOptions } from "../../dtos/select-options";
import { FromEnumToArray } from "../../utilities/enumFunctions";
import { useBaseGrid } from "../BaseGrid/Hooks/useBaseGrid";
import BaseGridDropDownFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import BaseGrid from "../BaseGrid/BaseGrid";

const tableName = "agency_policy_filter_options_table";

const agencyPolicyFilterOptions: Partial<SelectOptions>[] = [
  { displayName: "All", intValue: -1 },
].concat(
  FromEnumToArray(AgencyPoliciesFilterEnum).map((option) => {
    return {
      displayName: option.key,
      intValue: option.value,
    };
  })
);

const AgencyPolicy: FC = () => {
  const { agencyId } = useParams();

  const [optionSelected, setOptionSelected] = useState<number>(
    AgencyPoliciesFilterEnum.ACTIVE
  );

  const { reloadGridDataSource } = useBaseGrid({
    name: tableName,
    getURL: `api/PolicyAgency/GetAgencyPolicyPage?AgencyId=${agencyId}&filterOption=${optionSelected}`,
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "agency-policy-filter",
          optionsOverride: agencyPolicyFilterOptions,
          defaultValue: optionSelected,
          usingExternalFiltering: true,
          onChange: (value) => setOptionSelected(value),
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
  });

  useEffect(() => {
    reloadGridDataSource();
  }, [optionSelected]);

  return (
    <Box sx={{ maxHeight: "100%" }}>
      <BaseGrid name={tableName} />
    </Box>
  );
};

export default AgencyPolicy;
