import { FC, useEffect, useState } from "react";
import { Link } from "@mui/material";
import Font from "../../../../../TrueUI/Typography/Font";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPreviousRunningTotal,
  getStatesUpdatedByRowResultAndWaivers,
  getValidatedCalculatedAmountByMinMax,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
  SpecificWaiverFormUIProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  customRound,
  getNumberAsStringWithComas,
} from "../../../../../../utilities/stringFunctions";
import ModalSpecificWaiverTable from "../PremiumTableRowSupportComponents/ModalSpecificWaiverTable";
import ModalSpecificWaiverForm from "../PremiumTableRowSupportComponents/ModalSpecificWaiverForm";
import { useApiGet } from "../../../../../../hooks";
import { PolicyWaiverPage } from "../../../../../../dtos/policy-waiver-page";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../../../InsuredAtoms";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import { updateQuoteInPolicyQuote } from "../../../updatesPolicyQuoteFunctions";
import { useRecoilValue } from "recoil";
import {
  SPECIFIC_WAIVER_FORM_ROW_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { PolicyInsuredAddressBlob } from "../../../../../../dtos/policy-insured-address-blob";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { getSpecificWaiverTableData } from "../PremiumTableRowSupportComponents/ModalSpecificWaiverFormUtils";
import style from "./PremiumTableRows.module.css";
import { getStateByStateCodeAndDates } from "../../../PolicyQuoteForm/PolicyQuoteUtils";

const getRunningTotalAndCalculatedAmount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  calculatedValue: number,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const validatedCalculatedAmount = getValidatedCalculatedAmountByMinMax(
    calculatedValue,
    rating
  );
  const newRunningTotal = customRound(
    (validatedCalculatedAmount + previousRunningTotal).toString(),
    rating.rounding ?? 0
  );

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: validatedCalculatedAmount,
    rate: rating.rate ?? 0,
  };

  return rowResult;
};

const getStatesUpdatedByCalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  calculatedValue: number,
  policyWaiverTable: string[][] | null,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const calculationResults = getRunningTotalAndCalculatedAmount(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    rating,
    calculatedValue,
    atomValue
  );
  const statesUpdatedByCalculations = getStatesUpdatedByRowResultAndWaivers(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    calculationResults,
    policyWaiverTable,
    atomValue
  );
  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedByCalculations
  );
  return {
    rowResult: calculationResults,
    newAtomValue: newAtomValue,
  };
};

const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

const SpecificWaiverFormRow: FC<PremiumTableRowProps> = (props) => {
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props?.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalTableOpen, setIsModalTableOpen] = useState(false);
  const [totalCalculated, setTotalCalculated] = useState<number>(0);
  const [specificWaiverFormUI, setSpecificWaiverFormUI] =
    useState<SpecificWaiverFormUIProps>();
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );
  const { responseGet, dispatchGet } = useApiGet<PolicyWaiverPage>(
    `${QUOTE_POLICY_API_PATH}/GetPolicyWaiverPage?policyId=${specificWaiverFormUI?.policyId}&policyQuoteId=${specificWaiverFormUI?.policyQuoteId}&stateCode=${specificWaiverFormUI?.stateCode}`
  );

  const runCalculations = () => {
    const atomValue = getAtom();
    const updatedValues = getStatesUpdatedByCalculations(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      props.rateIndex,
      props.rating,
      totalCalculated,
      specificWaiverFormUI?.policyWaiverTable?.data ?? null,
      atomValue
    );
    setAtom(updatedValues.newAtomValue);
    setRowResult(updatedValues.rowResult);
    props.calculationsFinished?.();
  };

  const openEvent = () => {
    dispatchGet();
  };

  const closeEvent = (tableTotal: number) => {
    setIsModalTableOpen(false);
    if (tableTotal) {
      setTotalCalculated(tableTotal);
    }
  };

  const openFormEvent = () => {
    setIsModalFormOpen(true);
    setIsModalTableOpen(false);
  };

  const refreshTable = () => {
    dispatchGet();
    setSpecificWaiverFormUI({
      ...specificWaiverFormUI,
      policyWaiverTable: undefined,
    });
  };

  const getLocationsAddress = (
    insuredAddress: PolicyInsuredAddressBlob[] | null
  ) =>
    insuredAddress
      ?.filter((address) =>
        ["Location", "Primary"].includes(address?.addressType ?? "")
      )
      .map((filtered) => ({
        displayName: filtered.locationNumber?.toString(),
        intValue: filtered.addressID,
      })) as Partial<SelectOptions>[];

  const isSaveDisabled = () => {
    const atomValue = getAtom();
    setSaveDisabled(
      (getSpecificWaiverTableData(atomValue, specificWaiverFormUI)?.length ??
        0) === 0
    );
  };

  const listenerRowComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(
      `${props.insuredId}_${SPECIFIC_WAIVER_FORM_ROW_NAME}_${props.rating.rateElementID}`
    )
  );

  useEffect(() => {
    const atomValue = getAtom();
    setSpecificWaiverFormUI({
      insuredId: props.insuredId,
      stateCode: props.stateCode,
      effectiveDate: props.effectiveDate,
      expirationDate: props.expirationDate,
      rate: props.rating.rate,
      rounding: props.rating.rounding ?? 0,
      minimumValue: props.rating.minimumValue,
      maximumValue: props.rating.maximumValue,
      insuredAddress: getLocationsAddress(
        atomValue?.policyQuoteInformation?.policyQuote?.insured?.addresses ?? []
      ),
      policyId: atomValue?.policyQuoteInformation?.policyQuote?.policyID,
      policyQuoteId:
        atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteID,
      policyQuoteDate:
        atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteDate ??
        new Date(new Date(Date.now())),
      tabKey: props?.tabKey,
    });
  }, []);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data) {
      setSpecificWaiverFormUI({
        ...specificWaiverFormUI,
        policyWaiverTable: responseGet?.axiosResponse?.data?.tableData,
      });
      setIsModalTableOpen(true);
      setIsModalFormOpen(false);
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      isModalFormOpen === true &&
      isModalTableOpen === false &&
      specificWaiverFormUI?.policyWaiverTable
    ) {
      openEvent();
    }
  }, [specificWaiverFormUI?.policyWaiverTable]);

  useEffect(() => {
    if (!props.readOnly) {
      isSaveDisabled();
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [totalCalculated]);

  useEffect(() => {
    if (listenerRowComponent !== null && !props.readOnly) {
      isSaveDisabled();
      runCalculations();
      props.triggerDependantRatings?.();
    }
  }, [listenerRowComponent]);

  return (
    <>
      <div className={style.premium_row_container}>
        <div className={style.premium_row_name_cell_5}>
          <Link
            href="#"
            onClick={openEvent}
            true-element={`${getNameToExposureTableColumns(props, 0)}`}
          >
            {props.rating.elementName ?? ""}
          </Link>
        </div>
        <div className={style.premium_row_rate_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
            {customRound(rowResult.rate.toLocaleString(), 2)}
          </Font>
        </div>
        <div className={style.premium_row_calculated_amount_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
            {getNumberAsStringWithComas(rowResult.calculatedAmount)}
          </Font>
        </div>
        {hasQuotedRunningTotal && (
          <div className={style.premium_row_previous_running_total_cell}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
              {getNumberAsStringWithComas(
                props.rating.previousRunningTotal ?? 0
              )}
            </Font>
          </div>
        )}
        <div
          className={
            hasQuotedRunningTotal
              ? style.premium_row_running_total_cell_10
              : style.premium_row_running_total_cell_7
          }
        >
          <Font
            trueElement={`${getNameToExposureTableColumns(
              props,
              hasQuotedRunningTotal ? 4 : 3
            )}`}
          >
            {getNumberAsStringWithComas(rowResult.runningTotal)}
          </Font>
        </div>
      </div>
      <ModalSpecificWaiverTable
        showModal={isModalTableOpen}
        specificWaiverFormUI={specificWaiverFormUI}
        closeEvent={closeEvent}
        openFormEvent={openFormEvent}
        readOnly={props.readOnly ?? false}
      />
      <ModalSpecificWaiverForm
        showModal={isModalFormOpen}
        specificWaiverFormUI={specificWaiverFormUI}
        closeEvent={openEvent}
        refreshTable={refreshTable}
        saveDisabled={saveDisabled}
      />
    </>
  );
};

export default SpecificWaiverFormRow;
