import { FC } from "react";

type TableHeaderProperties = {
  children?: any;
  width?: any;
  className?: string;
  onClick?: () => any;
};

const TableHeader: FC<TableHeaderProperties> = ({
  children,
  width,
  className,
  onClick,
}) => {
  return (
    <div
      className={
        className === "multi_cell_collapse"
          ? `multi_cell_collapse`
          : `true_table_header_data ${className}`
      }
      style={{ width: width }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TableHeader;
