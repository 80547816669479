import { FC, useEffect } from "react";
import "./AgencyScoreCardStyles.ts";
import { Box, Paper } from "@mui/material";
import { Heading5, Heading6, ScoreBar, ScoreChip } from "../../TrueUI";
import { AgencyTitleInformationDto } from "../../../dtos/agency-title-information-dto";
import { useApiGet } from "../../../hooks";
import { AgencyScorecardDto } from "../../../dtos/agency-scorecard-dto";
import { currencyFormat } from "../../../utilities/currencyFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { AgencyScorecardPage } from "../../../dtos/agency-scorecard-page";
import themes from "../../../media/TrueTheme";
import {
  agencyScoreCardStyles,
  scoreItemsStyles,
} from "./AgencyScoreCardStyles";

type ScorecardProps = {
  agencyInfo?: AgencyTitleInformationDto | null;
};

const Scoredcard: FC<ScorecardProps> = ({ agencyInfo }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const { responseGet, dispatchGet } = useApiGet<AgencyScorecardPage>(
    `api/AgencyScorecard/GetAgencyScorecard?agencyId=${agencyInfo?.agencyId}`
  );
  useEffect(() => {
    dispatchGet();
  }, []);
  const scorecardItems: any[] =
    responseGet?.responseData?.scorecardItemList ?? [];
  const AgencyScoreCardInfo: AgencyScorecardDto | null =
    responseGet?.responseData?.agencyScorecard ?? null;
  // TODO: for now this is here needs mapping and own dto in the future
  const ScoreInfo = [
    {
      comparison: `(${currencyFormat(
        AgencyScoreCardInfo?.sizeAgency,
        true
      )} / ${currencyFormat(AgencyScoreCardInfo?.sizeProgram, true)})`,
      percentage: AgencyScoreCardInfo?.sizePct ?? 0,
    },
    {
      comparison: `(${AgencyScoreCardInfo?.closingAgency} / ${AgencyScoreCardInfo?.closingProgram})`,
      percentage: AgencyScoreCardInfo?.closingPct ?? 0,
    },
    {
      comparison: `(${AgencyScoreCardInfo?.renewalsAgency} / ${AgencyScoreCardInfo?.renewalsProgram})`,
      percentage: AgencyScoreCardInfo?.renewalsPct ?? 0,
    },
    {
      comparison: `(${AgencyScoreCardInfo?.cancelsAgency} / ${AgencyScoreCardInfo?.cancelsProgram})`,
      percentage: AgencyScoreCardInfo?.cancelsPct ?? 0,
    },
    {
      comparison: `(${AgencyScoreCardInfo?.lossRatioAgency})`,
      percentage: AgencyScoreCardInfo?.lossRatioScore ?? 0,
    },
  ];

  return (
    <Box id={"scoreCardContent"}>
      <Paper elevation={0} sx={agencyScoreCardStyles(theme)}>
        <Box className={"container-score-chip"}>
          <ScoreChip
            value={agencyInfo?.score ?? 0}
            color={"darkGreen"}
            size={"medium"}
          />
          <Heading6>{agencyInfo?.agencyCode}</Heading6>
          <Heading5>{agencyInfo?.agencyName}</Heading5>
        </Box>
      </Paper>
      <Box sx={scoreItemsStyles(theme)}>
        {scorecardItems.map((scorecardItem) => (
          <ScoreBar
            scorecardItem={scorecardItem}
            key={scorecardItem.scoreId}
            info={ScoreInfo.shift()}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Scoredcard;
