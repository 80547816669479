import { FC } from "react";
import { Button, Col } from "../../../../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import { usePermissions } from "../../../../../../../hooks";
import { PaymentStatusEnum } from "../../../../../../../dtos/payment-status-enum";
import { ButtonRefundContainerProps } from "./TypesModalRefundPayment";

const ButtonRefundContainer: FC<ButtonRefundContainerProps> = ({
  payment,
  closeAddPaymentModal,
  setIsVoidedRefundOrMoveModalConfig,
  paymentType,
  reserveTypes,
}) => {
  const hasPermissionToRefund = usePermissions([13, 14, 15]);

  const onRefund = () => {
    closeAddPaymentModal(false);
    setIsVoidedRefundOrMoveModalConfig?.({
      refundPaymentData: payment,
      refund: true,
      voided: false,
      reserveTypes,
      refreshTable: false,
      paymentId: -1,
      movePayment: false,
      movePaymentData: null,
    });
  };
  return (
    <>
      {hasPermissionToRefund &&
        paymentType !== "manual-payment" &&
        payment?.paymentStatus === PaymentStatusEnum.PAID &&
        payment?.refund === false && (
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 6, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Button
              tabIndex={22}
              variantStyle="outlined"
              onClick={() => onRefund()}
              name="paymentFormRefundButton"
            >
              REFUND
            </Button>
          </Col>
        )}
    </>
  );
};

export default ButtonRefundContainer;
