import TaskForm from "./TaskForm";
import { FC, useEffect } from "react";
import { ContextProps } from "../InsuredDrawerConstants";
import { TasksConfigurationDto } from "../../../../dtos/tasks-configuration-dto";
import { TaskDto } from "../../../../dtos/task-dto";
import { useApiGet } from "../../../../hooks";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { useSetRecoilState } from "recoil";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import { DRAWER_TASK_ID } from "../../../../utilities/queryStringsHash";
import { TASK_ATOM_KEY, TaskAtomFamily } from "./TaskAtoms";

type TaskFormContainerProps = {
  context: ContextProps;
  taskConfiguration: TasksConfigurationDto | null;
  setRefreshTable: any;
};

const TaskFormContainer: FC<TaskFormContainerProps> = ({
  context,
  taskConfiguration,
  setRefreshTable,
}) => {
  const taskId = getQueryValue(DRAWER_TASK_ID);

  const formStateAtomKey = `${TASK_ATOM_KEY} ${context.tabKey}`;
  const { responseGet, dispatchGet } = useApiGet<TaskDto>(
    `api/task/GetTaskById?taskId=${taskId}`
  );
  const setFormState = useSetRecoilState(TaskAtomFamily(formStateAtomKey));

  useEffect(() => {
    if (!isEmptyValue(taskId)) {
      dispatchGet();
    }
  }, [taskId, taskConfiguration]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormState({
        editData: {
          ...({ ...responseGet?.responseData, taskAttachmentsIds: [] } ?? null),
        },
        isAddOrEditExpanded: true,
      });
    }
  }, [responseGet]);

  return !isEmptyValue(taskConfiguration) ? (
    <TaskForm
      context={context}
      taskConfiguration={taskConfiguration}
      setRefreshTable={setRefreshTable}
    />
  ) : null;
};

export default TaskFormContainer;
