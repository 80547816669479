import { FC } from "react";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import { BaseTable2, Font } from "../../../TrueUI";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import "./performance.module.css";

type PerformanceProps = {
  insuredId: number;
};

const Performance: FC<PerformanceProps> = ({ insuredId }) => {
  const tableName = "insured_performance_table";
  const config: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Insured/GetInsuredPerformanceByInsuredId?insuredId=${insuredId}`,
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
    },
    columnOptions: [
      { fieldName: "Premium", align: "right" },
      { fieldName: "EarnedPrem", align: "right" },
      { fieldName: "Paid", align: "right" },
      { fieldName: "Incurred", align: "right", width: 8 },
      { fieldName: "LossRatio", align: "right", width: 8 },
      { fieldName: "Payroll", align: "right" },
      { fieldName: "ExpMod", width: 6 },
      { fieldName: "Freq", width: 6 },
      { fieldName: "ClaimsNumber", width: 5 },
    ],
  };

  useBaseTable(config);
  return (
    <InformationContainer title={<Font>Performance</Font>}>
      <div className={"performance_container"}>
        <BaseTable2 name={tableName} />
      </div>
    </InformationContainer>
  );
};

export default Performance;
