import { FC, useState } from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { globalOptions } from "../../../GlobalAtoms";
import { NavBottomOpenAtom, NavOpenAtom } from "../NavAtoms";
import {
  UnderwritingIcon,
  BillingIcon,
  ClaimsIcon,
  AdminToolsIcon,
  HelpIcon,
  CalculatorIcon,
  FileGraphIcon,
  LayersIcon,
  AgencyIcon,
} from "../../TrueUI/Icons/CustomIcons";
import themes from "../../../media/TrueTheme";
import {
  divStyle,
  Drawer,
  listItemIconSx,
  listItemTextIconSx,
} from "./LeftNavStyles";

type LeftNavProperties = {
  isInitLoading: boolean;
};

const mockNavOptions = [
  {
    name: "Policy",
    icon: <UnderwritingIcon />,
    route: "/underwriting",
  },
  {
    name: "Billing",
    icon: <BillingIcon />,
    route: "/billing",
  },
  {
    name: "Claims",
    icon: <ClaimsIcon />,
    route: "/claims",
  },
  {
    name: "Audits",
    icon: <CalculatorIcon />,
    route: "/audit",
  },
  {
    name: "Agency",
    icon: <AgencyIcon />,
    route: "/agency",
  },
  {
    name: "Reports",
    icon: <FileGraphIcon />,
    route: "https://app.powerbi.com/",
  },
  {
    name: "Mailroom",
    icon: <LayersIcon />,
    route: "/mailroom",
  },
  {
    name: "Admin",
    icon: <AdminToolsIcon />,
    route: "/admin-tools",
  },
];

const LeftNav: FC<LeftNavProperties> = () => {
  const localOptions = useRecoilValue(globalOptions);
  const currentTheme = themes[localOptions?.themeRefresh];
  const isNavOpen = useRecoilValue(NavOpenAtom);
  const [isNavBottomOpen, setIsNavBottomOpen] =
    useRecoilState(NavBottomOpenAtom);
  const [activeSection, setActiveSection] = useState<String | null>(null);

  return (
    <Drawer
      variant="permanent"
      open={isNavOpen}
      PaperProps={{
        sx: {
          backgroundColor: `${currentTheme?.CONTRAST_BACKGROUND} !important`,
        },
      }}
    >
      <>
        <IconButton
          true-element={`true-element-button-home`}
          component={Link}
          to={"/home"}
          onClick={() => {
            setActiveSection(null);
          }}
        >
          {isNavOpen ? (
            <img
              src={currentTheme?.ICON_PATH_EXPANDED}
              style={{ height: "45px" }}
            />
          ) : (
            <img
              src={currentTheme?.ICON_PATH_COLLAPSED}
              style={{ height: "45px" }}
            />
          )}
        </IconButton>
        <List>
          {mockNavOptions.map((navOption) => (
            <ListItem
              button
              true-element={`true-element-button-${navOption.name}`}
              key={navOption.name}
              component={Link as any}
              to={navOption.route}
              target={navOption.route.includes("http") ? "_blank" : "_self"}
              onClick={() => {
                setActiveSection(navOption.name);
              }}
            >
              <ListItemIcon
                sx={listItemIconSx(
                  currentTheme,
                  activeSection === navOption.name
                )}
              >
                {navOption.icon}
              </ListItemIcon>
              <ListItemText
                primary={navOption.name}
                sx={listItemTextIconSx(currentTheme)}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={divStyle(currentTheme)}>
          <IconButton onClick={() => setIsNavBottomOpen(!isNavBottomOpen)}>
            <HelpIcon />
          </IconButton>
        </Box>
      </>
    </Drawer>
  );
};

export default LeftNav;
