import { FC } from "react";
import { Col, Input, InputMemo, Row, Select, Switch } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { CustomFieldTypeEnum } from "../../../../../dtos/custom-field-type-enum";
import { selectOptionsByEnumValues } from "../../../../../utilities/enumFunctions";
import { StatusEnums } from "../../../../../dtos/status-enums";
import { CustomFieldDataTypeEnum } from "../../../../../dtos/custom-field-data-type-enum";
import { CustomFieldControlTypeEnum } from "../../../../../dtos/custom-field-control-type-enum";
import { CustomFieldControlSizeEnum } from "../../../../../dtos/custom-field-control-size-enum";
import { SelectOptions } from "../../../../../dtos/select-options";
import { addAllOption } from "../../../../../utilities/selectFunctions";
import { CustomFieldDefinitionDto } from "../../../../../dtos/custom-field-definition-dto";
import { ListSourceTypeEnum } from "../../../../../dtos/list-source-type-enum";

type CustomFieldFormProps = {
  customField?: CustomFieldDefinitionDto;
  setCustomField: (customFieldData?: CustomFieldDefinitionDto) => void;
  programs: SelectOptions[];
  fieldTypeFilter: number;
  errorDetails: any;
  showInHeaderList: number[];
  programsWithShowInHeader?: { [key: number]: number[] };
};

const CustomFieldForm: FC<CustomFieldFormProps> = ({
  customField,
  setCustomField,
  fieldTypeFilter,
  programs,
  errorDetails,
  showInHeaderList,
  programsWithShowInHeader,
}) => {
  const isDropDownControlType = (controlType?: number | null) =>
    controlType === CustomFieldControlTypeEnum.DROPDOWN;

  const clearListSourceValues = (controlType?: number) => ({
    listSource: isDropDownControlType(controlType)
      ? customField?.listSource
      : null,
    listSourceType: isDropDownControlType(controlType)
      ? customField?.listSourceType
      : null,
  });

  const isDecimalDataType = (dataType?: number | null) =>
    dataType === CustomFieldDataTypeEnum.DECIMAL;

  const clearDataPrecision = (dataType?: number | null) => ({
    dataPrecision: isDecimalDataType(dataType) ? customField?.dataPrecision : 2,
  });

  const disableShowInHeader = () => {
    if (showInHeaderList.includes(customField?.customFieldDefinitionId ?? -1)) {
      return false;
    }

    const programSelected = customField?.program ?? -1;
    const fieldTypeSelected = customField?.fieldType ?? fieldTypeFilter;
    const allShowInHeader = Object.values(
      programsWithShowInHeader ?? {}
    ).flatMap((v) => v);

    if (
      programSelected !== -1 &&
      !programsWithShowInHeader?.[programSelected]?.includes(fieldTypeSelected)
    ) {
      return false;
    }

    if (
      programSelected === -1 &&
      !allShowInHeader?.includes(fieldTypeSelected)
    ) {
      return false;
    }

    return true;
  };

  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} allowWrap={true}>
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 10, lg: 10, xl: 10 }}
      >
        <Select
          id={"id-program"}
          name={"name-program"}
          label={"Program"}
          labelFontType={"BOLD_CAPTION"}
          optionsMaxHeight="250px"
          options={addAllOption(programs, false, "Applies to All", null)}
          value={customField?.program}
          onChange={(program) =>
            setCustomField({
              ...customField,
              program,
            })
          }
          errorMessage={errorDetails?.program}
        />
      </Col>
      <Col breakpoints={{ md: 5, lg: 5, xl: 5 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Select
          id={"id-field-type"}
          name={"name-field-type"}
          label={"Field Type"}
          labelFontType={"BOLD_CAPTION"}
          options={selectOptionsByEnumValues(CustomFieldTypeEnum)}
          value={customField?.fieldType ?? fieldTypeFilter}
          onChange={(fieldType) =>
            setCustomField({
              ...customField,
              fieldType,
            })
          }
          errorMessage={errorDetails?.fieldType}
        />
      </Col>
      <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} /> {/* End 1st row */}
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 13, lg: 13, xl: 13 }}
      >
        <Input
          id="id-field-label"
          name="name-field-label"
          label="Field Label"
          labelFontType="BOLD_CAPTION"
          maxLength={50}
          value={customField?.fieldLabel}
          onChangeRawValue={(fieldLabel) =>
            setCustomField({
              ...customField,
              fieldLabel,
            })
          }
          errorMessage={errorDetails?.fieldLabel}
        />
      </Col>
      <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 6, lg: 6, xl: 6 }}>
        <Select
          id={"id-status"}
          name={"name-status"}
          label={"Status"}
          labelFontType={"BOLD_CAPTION"}
          options={selectOptionsByEnumValues(StatusEnums).filter(
            (o) => o.intValue !== StatusEnums.DELETED
          )}
          value={customField?.fieldStatus}
          onChange={(fieldStatus) =>
            setCustomField({
              ...customField,
              fieldStatus,
            })
          }
          errorMessage={errorDetails?.fieldStatus}
        />
      </Col>
      <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} /> {/* End 2nd row */}
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 10, lg: 10, xl: 10 }}
      >
        <Select
          id={"id-data-type"}
          name={"name-data-type"}
          label={"Data Type"}
          labelFontType={"BOLD_CAPTION"}
          options={selectOptionsByEnumValues(CustomFieldDataTypeEnum)}
          value={customField?.dataType}
          onChange={(dataType) =>
            setCustomField({
              ...customField,
              ...clearDataPrecision(customField?.dataType),
              dataType,
            })
          }
          errorMessage={errorDetails?.dataType}
        />
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 3, lg: 3, xl: 3 }}>
        <Input
          id="id-data-size"
          name="name-data-size"
          label="Data Size"
          labelFontType="BOLD_CAPTION"
          type="number"
          minNumericValue={1}
          maxNumericValue={300}
          value={customField?.dataSize}
          onChangeRawValue={(dataSize) =>
            setCustomField({
              ...customField,
              dataSize,
            })
          }
          errorMessage={errorDetails?.dataSize}
        />
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 3, lg: 3, xl: 3 }}>
        {isDecimalDataType(customField?.dataType) && (
          <Input
            id="id-precision"
            name="name-precision"
            label="Precision"
            labelFontType="BOLD_CAPTION"
            type="number"
            minNumericValue={0}
            maxNumericValue={10}
            value={customField?.dataPrecision}
            onChangeRawValue={(dataPrecision) =>
              setCustomField({
                ...customField,
                dataPrecision,
              })
            }
            errorMessage={errorDetails?.dataPrecision}
          />
        )}
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} /> {/* End 3th row */}
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 10, lg: 10, xl: 10 }}
      >
        <Select
          id={"id-control-type"}
          name={"name-control-type"}
          label={"Control Type"}
          labelFontType={"BOLD_CAPTION"}
          options={selectOptionsByEnumValues(CustomFieldControlTypeEnum)}
          value={customField?.controlType}
          onChange={(controlType) =>
            setCustomField({
              ...customField,
              ...clearListSourceValues(controlType),
              controlType,
            })
          }
          errorMessage={errorDetails?.controlType}
        />
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 3, lg: 3, xl: 3 }}>
        <Select
          id={"id-control-size"}
          name={"name-control-size"}
          label={"Control Size"}
          labelFontType={"BOLD_CAPTION"}
          options={selectOptionsByEnumValues(CustomFieldControlSizeEnum)}
          value={customField?.controlSize}
          onChange={(controlSize) =>
            setCustomField({
              ...customField,
              controlSize,
            })
          }
          errorMessage={errorDetails?.controlSize}
        />
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col horizontalAlign="flex-start" breakpoints={{ md: 3, lg: 3, xl: 3 }}>
        <Input
          id="id-control-order"
          name="name-control-order"
          label="Control Order"
          labelFontType="BOLD_CAPTION"
          type="number"
          minNumericValue={0}
          maxNumericValue={1000}
          value={customField?.controlOrder}
          onChangeRawValue={(controlOrder) =>
            setCustomField({
              ...customField,
              controlOrder,
            })
          }
          errorMessage={errorDetails?.controlOrder}
        />
      </Col>
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} /> {/* End 4th row */}
      <Col
        horizontalAlign="flex-start"
        breakpoints={{ md: 24, lg: 24, xl: 24 }}
      >
        <Switch
          tabIndex={15}
          id={"id-show-in-header"}
          name={"name-show-in-header"}
          label={"Show In Header"}
          labelPlacement="end"
          labelFontType="BOLD_CAPTION"
          control="checkbox"
          isChecked={customField?.showInHeader ?? false}
          onChangeIsChecked={(showInHeader) =>
            setCustomField({
              ...customField,
              showInHeader,
            })
          }
          readOnly={disableShowInHeader()}
        />
      </Col>
      {/* End 5th row */}
      {isDropDownControlType(customField?.controlType) && (
        <>
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Select
              id={"id-list-source-type"}
              name={"name-list-source-type"}
              label={"List Source Type"}
              labelFontType={"BOLD_CAPTION"}
              options={selectOptionsByEnumValues(ListSourceTypeEnum, [
                ListSourceTypeEnum.SQL,
              ])}
              value={customField?.listSourceType}
              onChange={(listSourceType) =>
                setCustomField({
                  ...customField,
                  listSourceType,
                })
              }
              errorMessage={errorDetails?.listSourceType}
            />
          </Col>
          <Col breakpoints={{ md: 21, lg: 21, xl: 21 }} /> {/* End 6th row */}
          <Col
            horizontalAlign="flex-start"
            breakpoints={{ md: 24, lg: 24, xl: 24 }}
          >
            <InputMemo
              id="id-list-source"
              name="name-list-source"
              label="List Source"
              value={customField?.listSource}
              onChangeRawValue={(listSource) =>
                setCustomField({
                  ...customField,
                  listSource,
                })
              }
              rows={5}
              resize={true}
              errorMessage={errorDetails?.listSource}
            />
          </Col>
          {/* End 7th row */}
        </>
      )}
    </Row>
  );
};

export default CustomFieldForm;
