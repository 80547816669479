import { FC } from "react";
import { Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import Alert, { AlertProps } from "../../../TrueUI/Alerts/Alert";

type ProgramValidationForAlertsProps = {
  validationResults: AlertProps[];
  insuredId: number;
  policyId?: number | null;
};

const ProgramValidationForAlerts: FC<ProgramValidationForAlertsProps> = ({
  validationResults,
  insuredId,
  policyId,
}) => {
  return (
    <div>
      {validationResults.map((alert, index) => (
        <Row
          {...rowWithNoMarginNorGutter}
          rowDirection="column"
          verticalAlign="flex-start"
        >
          <Alert
            key={`alert-atom-modal-key-${insuredId}-${policyId}-${index}`}
            type={alert?.type}
            message={alert?.message}
            showCloseIcon={false}
          />
        </Row>
      ))}
    </div>
  );
};

export default ProgramValidationForAlerts;
