import {
  addDays,
  FormattingDate,
  GettingDateWithoutTime,
  StringToDate,
  subtractDays,
} from "../../../utilities/dateFunctions";
import { FC, useState } from "react";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import {
  BaseTable2Properties,
  DeleteRowParameters,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableInputDateFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableInputDateFilter";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { PayAccountTypeEnum } from "../../../dtos/pay-account-type-enum";
import { SelectOptions } from "../../../dtos/select-options";
import ScheduledTransactionModal, {
  ScheduledTransactionModalConfig,
} from "../../Insured/Billing/Scheduled/TransactionModal/ScheduledTransactionModal";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type ScheduledTransactionsGridProps = {
  programId: number;
};

const ScheduledTransactionsGrid: FC<ScheduledTransactionsGridProps> = ({
  programId,
}) => {
  const today = GettingDateWithoutTime(new Date());
  const defaultFromDate = FormattingDate(subtractDays(today, 1), "YYYY-MM-DD");
  const defaultToDate = FormattingDate(addDays(today, 6), "YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [modalConfiguration, setModalConfiguration] =
    useState<ScheduledTransactionModalConfig>({
      isOpen: false,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
    });

  const name = "billing_scheduled_transactions_grid";
  const getURL = `api/BillingTransaction/GetBillingScheduledTransactionsGrid?programId=${programId}&fromDate=${fromDate}&toDate=${toDate}`;

  const onEditTransaction = (rowData: any) => {
    setModalConfiguration({
      isOpen: true,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
      transactionId: rowData.TransactionId,
      insuredId: parseInt(rowData.InsuredId),
      rowKey: rowData._row_key,
      rowData: rowData,
    });
  };

  const onSaveSuccessful = () => {
    hooks?.methods?.refreshTable();
  };

  const onDeleteSuccessful = () => {
    if (conditionHasValue(modalConfiguration.transactionId)) {
      const deletedRow: DeleteRowParameters = {
        rowKey: modalConfiguration.rowKey ?? "",
        hydratedData: { ...modalConfiguration.rowData },
        deleteType: "hidden",
      };
      hooks?.methods?.deleteRow(deletedRow);
    }
  };

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() => onEditTransaction(rowData.row)}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const typeOptions: Partial<SelectOptions>[] = FromEnumToArray(
    PayAccountTypeEnum
  ).map((type) => ({
    displayName: type.key,
    intValue: type.value,
  }));

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showAddButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "accountType",
          filterOptions: actionOptions,
          optionsOverride: addAllOption(typeOptions),
          columnsNames: ["AccountTypeValue"],
          labelText: "Type:",
          defaultValue: "all",
        }),
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "Show Records From:",
          inputWidth: "336px",
          defaultValue: StringToDate(defaultFromDate),
          isStaticFilter: true,
          id: "fromDate",
          onChangeRawValue: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setFromDate(formattedNewDate);
          },
        }),
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "To:",
          inputWidth: "210px",
          defaultValue: StringToDate(defaultToDate),
          isStaticFilter: true,
          id: "toDate",
          onChangeRawValue: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setToDate(formattedNewDate);
          },
        }),
    ],
    columnOptions: [
      { fieldName: "ScheduledDate", width: 15 },
      { fieldName: "AccountType", width: 20 },
      { fieldName: "InsuredName", width: 30 },
      { fieldName: "TransactionAmount", width: 15 },
      { fieldName: "CreateByAt", width: 17 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: false,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      addEventOverrideCallback: () => alert("Waiting for instructions"),
    },
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const hooks = tableMethods[name];

  return (
    <div id={"notes_grid_container"} style={{ height: "100%" }}>
      <BaseTable2 name={name} />
      <ScheduledTransactionModal
        configuration={modalConfiguration}
        closeModal={(close) =>
          setModalConfiguration({
            ...modalConfiguration,
            isOpen: close,
            transactionId: null,
            insuredId: null,
          })
        }
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
      />
    </div>
  );
};

export default ScheduledTransactionsGrid;
