import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, TabPanel, Title } from "../TrueUI";
import AgencyPanel from "./AgencyPanel";
import AgencyInformation from "./AgencyInformation";
import AgencyPolicy from "./AgencyPolicy";
import CommissionsContentWrapper from "./AgencyCommission/CommissionsContentWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  InformationTitleAtom,
  RefreshAgencyInformationPageAtom,
} from "./AgencyAtoms";
import { useApiGet } from "../../hooks";
import { AgencyTitleInformationDto } from "../../dtos/agency-title-information-dto";
import { AGENCY_TABS } from "../../utilities/queryStringsHash";
import { SelectedInsuredCommissionAtom } from "./AgencyCommission/CommissionsAtoms";

const AgencyInstanceWrapper: FC = () => {
  const { agencyId } = useParams();
  const isNewAgency = agencyId === "new";
  const newAgencyInformationTitle: AgencyTitleInformationDto = {
    agencyId: -1,
    agencyName: "",
    agencyCode: "",
    score: 0,
    showScorecard: false,
  };
  const setInsuredTran = useSetRecoilState(SelectedInsuredCommissionAtom);
  const [agencyInformationTitle, setAgencyInformationTitle] =
    useRecoilState(InformationTitleAtom);
  const { responseGet, dispatchGet } = useApiGet<AgencyTitleInformationDto>(
    "api/agency/GetAgencyTitleInformation?agencyId=" + agencyId
  );

  useEffect(() => {
    if (!isNewAgency) dispatchGet();
    else setAgencyInformationTitle(newAgencyInformationTitle);
  }, []);

  const [refreshPage] = useRecoilState(RefreshAgencyInformationPageAtom);
  useEffect(() => {
    if (!isNewAgency) dispatchGet();
    else setAgencyInformationTitle(newAgencyInformationTitle);
  }, [refreshPage]);

  useEffect(() => {
    if (responseGet?.responseData && !isNewAgency) {
      setAgencyInformationTitle(responseGet.responseData);
    }
  }, [responseGet]);

  return (
    <>
      {agencyInformationTitle ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AgencyPanel agencyInformationTitle={agencyInformationTitle} />
          <Tabs name={AGENCY_TABS} themeOverride={"material"}>
            <TabPanel title={"Information"}>
              <AgencyInformation />
            </TabPanel>
            <TabPanel title={"Policies"} disabled={isNewAgency}>
              <AgencyPolicy />
            </TabPanel>
            <TabPanel
              title={
                <div onClick={() => setInsuredTran(null)}>
                  <Title>Commissions</Title>
                </div>
              }
              disabled={isNewAgency}
            >
              <CommissionsContentWrapper />
            </TabPanel>
          </Tabs>
        </div>
      ) : (
        <div> Loading ...</div>
      )}
    </>
  );
};

export default AgencyInstanceWrapper;
