import { FC, useEffect, useState } from "react";
import { tableName, getTableConfig } from "./PaymentsApprovalConstants";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DeletedPaymentProps,
  FinancialModalProps,
} from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/typesAndConstants";
import { toUpperCaseKeys } from "../../../../../utilities/objectFunctions";
import { UpdatedPaymentRowData } from "../../../../Insured/Claim/ClaimLanding/ClaimTabs/ClaimFinancialComponents/ClaimFinancialUtil";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { PaymentDto } from "../../../../../dtos/payment-dto";
import { FormattingDate } from "../../../../../utilities/dateFunctions";
import {
  formatToCurrency,
  getNumberFromCurrencyFormat,
} from "../../../../../utilities/stringFunctions";
import { PaymentStatusEnum } from "../../../../../dtos/payment-status-enum";
import { BaseTable2 } from "../../../../TrueUI";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { PaymentApprovalConfigurationDto } from "../../../../../dtos/payment-approval-configuration-dto";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { useApiPost } from "../../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../utilities/apiFunctions";
import ModalEditPaymentApproval from "./ModalEditPaymentApproval";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../TrueUI/Tables/tableFunctions";
import { useRecoilState } from "recoil";
import { paymentsApprovalAtoms } from "./PaymentsApprovalAtoms";

type PaymentsApprovalTableProps = {
  getUrl: string;
  configurationOptions: PaymentApprovalConfigurationDto;
};

const UPDATE_PAYMENTS_URL = "api/Payment/UpdatePaymentsToPending";

const PaymentsApprovalTable: FC<PaymentsApprovalTableProps> = ({
  getUrl,
  configurationOptions,
}) => {
  const [getUrlWithKey, setGetUrlWithKey] = useState(
    `${getUrl}/?key=${crypto.randomUUID()}`
  );
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [showAddButton, setShowAddButton] = useState<any>(
    configurationOptions.userPaymentLimit > 0
  );
  const [filteredPaymentIds, setFilteredPaymentIds] = useState<string[] | null>(
    null
  );
  const [modalProps, setModalProps] = useState<Partial<FinancialModalProps>>({
    open: false,
    title: "Modal title",
  });
  const [, setTotalPaid] = useRecoilState(paymentsApprovalAtoms);

  const { responsePost: responseApprove, dispatchPost: dispatchApprove } =
    useApiPost(UPDATE_PAYMENTS_URL, filteredPaymentIds);

  const closeModal = (isOpen: boolean, refreshTable = true) => {
    setModalProps({ ...modalProps, open: isOpen, refreshTable });
  };

  useEffect(() => {
    return () => {
      setTotalPaid(0);
    };
  }, []);

  useEffect(() => {
    if (!modalProps.open) {
      if (modalProps?.refreshTable === true) {
        setGetUrlWithKey(`${getUrl}/?key=${crypto.randomUUID()}`);
        setModalProps({ ...modalProps, refreshTable: false });
      }
    }
  }, [modalProps]);
  const PaymentEditModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setSelectedRowData(rowData);
          setModalProps({
            open: true,
            title: "Edit Payment",
            option: "view-payment",
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const fromPaymentDtoToTableRow = (
    paymentDto?: PaymentDto | Partial<PaymentDto> | null
  ) => {
    return {
      ClaimId: selectedRowData?.ClaimId,
      PaymentId: paymentDto?.paymentId?.toString(),
      ProgramCode: selectedRowData?.ProgramCode,
      ClaimNumber: selectedRowData?.ClaimNumber,
      InvoiceDate: FormattingDate(paymentDto?.invoiceDate),
      PaymentCategory: paymentDto?.paymentCategory,
      PayeeName: paymentDto?.payeeName,
      Comments: paymentDto?.comments,
      PaidAmount: formatToCurrency(paymentDto?.paidAmount),
      CreateBy: selectedRowData?.CreateBy,
    };
  };

  const onDeleteEvent = (
    deletedPaymentRowData: DeletedPaymentProps | null | undefined
  ) => {
    if (deletedPaymentRowData !== null) {
      tableInstanceMethods?.methods?.deleteRow({
        rowKey: deletedPaymentRowData?.rowKey ?? "",
        hydratedData: {
          ...deletedPaymentRowData,
          ...toUpperCaseKeys(deletedPaymentRowData),
        },
        deleteType: "hidden",
      });
      setSelectedRowData(null);
      setTableConfig({
        ...tableConfig,
        refreshTable: !tableConfig.refreshTable,
        getURL: "",
      });
    }
  };

  const onUpdateEvent = (
    updatedPaymentRowData: UpdatedPaymentRowData | null | undefined
  ) => {
    if (
      conditionHasValue(updatedPaymentRowData) &&
      conditionHasValue(updatedPaymentRowData?.paymentData)
    ) {
      if (
        PaymentStatusEnum.PENDING_APPROVAL ===
        updatedPaymentRowData?.paymentData?.paymentStatus
      ) {
        const updatedRow = {
          rowKey: updatedPaymentRowData?.rowKey ?? "",
          hydratedData: {
            ...selectedRowData,
            ...fromPaymentDtoToTableRow(updatedPaymentRowData?.paymentData),
          },
        };
        tableInstanceMethods?.methods?.updateRow(updatedRow);
      } else {
        onDeleteEvent({
          deletedPayment: null,
          rowKey: updatedPaymentRowData?.rowKey ?? "",
        });
      }
      setSelectedRowData(null);
      setTableConfig({
        ...tableConfig,
        refreshTable: !tableConfig.refreshTable,
        getURL: "",
      });
    }
  };

  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const updateTotalPaid = (filteredData: string[][], columns: any[]) => {
    const paidAmountIndex = getDataIndexByColumnNameWithoutExternal(
      columns,
      "PaidAmount"
    );

    const paidAmountTotal = filteredData.reduce(
      (acc, cur) => acc + getNumberFromCurrencyFormat(cur[paidAmountIndex]),
      0
    );

    setTotalPaid(paidAmountTotal);
  };

  const getDialogMessageForApproval =
    (filteredPaymentIds?.length ?? 0) > 0
      ? `Are you sure you want to approve ${filteredPaymentIds?.length} payments?`
      : `There are no payments to approve.`;
  const showDialogButtonsForApproval = (filteredPaymentIds?.length ?? 0) > 0;

  const [tableConfig, setTableConfig] = useState<BaseTable2Properties>(
    getTableConfig(
      configurationOptions,
      getUrlWithKey,
      showAddButton,
      PaymentEditModalLink,
      setIsDialogConfirmationOpen,
      setFilteredPaymentIds,
      setShowAddButton,
      updateTotalPaid
    )
  );

  const { tableMethods } = useBaseTable(tableConfig);
  const tableInstanceMethods = tableMethods[tableName];

  useEffect(() => {
    setTableConfig({ ...tableConfig, getURL: getUrlWithKey });
  }, [getUrlWithKey]);

  useEffect(() => {
    const newConfig = getTableConfig(
      configurationOptions,
      getUrlWithKey,
      showAddButton,
      PaymentEditModalLink,
      setIsDialogConfirmationOpen,
      setFilteredPaymentIds,
      setShowAddButton,
      updateTotalPaid
    );

    setTableConfig(newConfig);
  }, [showAddButton]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseApprove)) {
      setTableConfig({
        ...tableConfig,
        refreshTable: !tableConfig.refreshTable,
        getURL: `${getUrl}/?key=${crypto.randomUUID()}`,
      });
    }
  }, [responseApprove]);

  return (
    <>
      <BaseTable2 name={tableName} />;
      <ModalEditPaymentApproval
        claimId={selectedRowData?.ClaimId}
        modalProps={modalProps}
        closeModal={closeModal}
        paymentId={selectedRowData?.PaymentId}
        setUpdatedPaymentRowData={onUpdateEvent}
        setDeletedPaymentRowData={onDeleteEvent}
        rowKey={selectedRowData?._row_key}
        paymentSelectsOptions={null as any}
      />
      <DialogConfirmation
        id="paymentsApprovalDialogConfirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={getDialogMessageForApproval}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        optionNoOverrideLabel={showDialogButtonsForApproval ? "NO" : "CLOSE"}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={
          showDialogButtonsForApproval
            ? (close) => {
                dispatchApprove();
                setIsDialogConfirmationOpen(close);
              }
            : undefined
        }
      />
    </>
  );
};

export default PaymentsApprovalTable;
