import { FC, useEffect, useState } from "react";
import {
  Upload as KeUpload,
  UploadFileInfo,
  // UploadFileStatus,
  UploadOnAddEvent,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
  UploadHttpHeaders,
} from "@progress/kendo-react-upload";
import { useApiPost } from "../../../hooks";
import { FileArchiveDeleteDto } from "../../../dtos/file-archive-delete-dto";
import "./Upload.css";
import { GetFileSizeWithUnits, filterEmptyFiles } from "./UploadFileUtils";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import Font from "../Typography/Font";
import { Box, LinearProgress } from "@mui/material";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../utilities/apiFunctions";
import { API_URL_DESTINATION, useApiGet } from "../../../hooks/useApi";
import {
  conditionHasValue,
  // isEmptyValue,
} from "../../../utilities/conditionalSupportFunctions";
import { FileArchiveResponseDto } from "../../../dtos/file-archive-response-dto";
import "./upload.module.css";
import { TRUE_ID_STORAGE } from "../../../utilities/localStorageFunctions";
import FontError from "../Typography/FontError";
import Modal from "../Modal/Modal";
import { removeObjectProperty } from "../../../utilities/objectFunctions";
import FontSuccess from "../Typography/FontSuccess";

type UploadProps = {
  name: string;
  saveUrl?: string;
  removeUrl?: string;
  allowedExtensions?: string[];
  files?: { [key: string]: number };
  readOnly?: boolean;
  clearFiles?: boolean;
  label?: string;
  labelFontType?: KeysAsType<FontsType>;
  errorMessages?: string[];
  maxSizeMB?: number;
  maxFileCount?: number;
  autoUpload?: boolean;
  tabIndex?: number;
  isInProgress?: (inProgress: boolean) => void;
  onUploadFileList: (files: any | null, response?: any) => void;
  resetClearFiles?: (clear: boolean) => void;
  onBeforeUploadFileList?: (event?: UploadOnBeforeUploadEvent) => void;
  onRemovedFile?: (
    files: any,
    response?: FileArchiveResponseDto | null
  ) => void;
  onComplete?: (isComplete: boolean) => void;
};

const MultiUpload_TEMP: FC<UploadProps> = ({
  name,
  files = new Map(),
  onUploadFileList,
  saveUrl = "api/FileArchive/UploadMultiple",
  removeUrl = "api/FileArchive/Remove",
  allowedExtensions,
  readOnly,
  clearFiles = false,
  label,
  labelFontType = "BOLD_BODY",
  errorMessages,
  maxSizeMB = 500,
  maxFileCount = 50,
  autoUpload = false,
  tabIndex = 0,
  isInProgress,
  resetClearFiles,
  onBeforeUploadFileList,
  onRemovedFile,
  onComplete,
}) => {
  const [_errorMessages, _setErrorMessages] = useState<string[]>([]);
  const [localFiles, setLocalFiles] = useState<Array<UploadFileInfo>>([]);
  const [fileToDelete, setFileToDelete] =
    useState<FileArchiveDeleteDto | null>();
  const [isEmptyFileMsgOpen, setIsEmptyFileMsgOpen] = useState(false);
  const [localAllowedExtensions, setLocalAllowedExtensions] = useState<
    string[] | undefined
  >(allowedExtensions);
  const removeResponse = useApiPost<FileArchiveResponseDto>(
    removeUrl,
    fileToDelete
  );
  const { responseGet, dispatchGet } = useApiGet<string[]>(
    `api/InsuredFile/GetFileExtensions`
  );
  const [disabled, setDisabled] = useState<boolean>(readOnly ?? false);
  const [uploadFileList, setUploadFileList] = useState<any[]>([]);
  const MAX_SIZE_BYTES = maxSizeMB * 1024 * 1024;

  const [uploadCompleteResponse, setUploadCompleteResponse] = useState<
    { response: any; iteration: number }[]
  >([]);
  const [showMessage, setShowMessage] = useState<
    "success" | "partial_success" | "in_progress" | null
  >(null);
  const [unsuccessfulFiles, setUnsuccessfulFiles] = useState<any[]>([]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const calculateProgress = () => {
    const section = 100 / localFiles.length;
    const updatedProgress = currentProgress + section;
    setCurrentProgress(updatedProgress);
  };

  const onRemoveRequest = (
    filesInfo: UploadFileInfo[]
  ): Promise<{ uid: string }> => {
    const currentFile = filesInfo[0] as UploadFileInfo;
    const uid = currentFile?.uid;
    const removeRequestPromise = new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
      setFileToDelete({
        fileArchiveUID: uid,
        fileArchiveId: files[uid],
        fileName: currentFile.name,
      });
      removeResponse.dispatchPost();
    });

    return removeRequestPromise;
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (showMessage === null) {
      onComplete?.(false);
      setIsComplete(false);
      setShowMessage("in_progress");
      setDisabled(true);
    }

    if (event.response) {
      const updatedUploadCompleteResponse = [
        ...uploadCompleteResponse,
        {
          response: event?.response?.response ?? null,
          iteration: uploadCompleteResponse.length + 1,
        },
      ];
      setUploadCompleteResponse(updatedUploadCompleteResponse);
      calculateProgress();
    } else {
    }
  };

  const validateRestrictions = (fileList: any) => {
    const allowedExtensionsArray: string[] =
      localAllowedExtensions?.map((ext) =>
        ext.startsWith(".") ? ext.toString() : `.${ext}`
      ) ?? [];
    const invalidFiles = fileList.filter((file) => {
      const fileExtension = file.extension as string;
      return !allowedExtensionsArray.includes(fileExtension);
    });

    const { totalSize, filesCount } = [...fileList].reduce(
      (result, currentFile) => {
        return {
          totalSize: result.totalSize + (currentFile.size ?? 0),
          filesCount: result.filesCount + 1,
        };
      },
      { totalSize: 0.0, filesCount: 0 }
    );

    const fileTypeErrorMsg =
      invalidFiles.length > 0
        ? `The file type \"${
            invalidFiles?.[0]?.extension ?? ""
          }\" is not allowed. `
        : "";
    const FileSizeErrorMsg =
      totalSize > MAX_SIZE_BYTES
        ? `Total size must not be greater than ${GetFileSizeWithUnits(
            MAX_SIZE_BYTES
          )}. `
        : "";
    const fileCountErrorMsg =
      filesCount > maxFileCount
        ? `Number of selected files must not be greater than ${maxFileCount}.`
        : "";
    const allErrorMessages =
      fileTypeErrorMsg + FileSizeErrorMsg + fileCountErrorMsg;

    if (allErrorMessages !== "") {
      _setErrorMessages([allErrorMessages]);
    } else {
      _setErrorMessages([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    const addedFiles = event.newState;
    const filterAddedFiles = filterEmptyFiles(addedFiles);
    if (filterAddedFiles.length !== addedFiles.length) {
      setIsEmptyFileMsgOpen(true);
    }
    validateRestrictions(filterAddedFiles);
    setLocalFiles(filterAddedFiles);
  };

  const onRemove = (event) => {
    const files = event.newState;
    validateRestrictions(files);
    setLocalFiles(files);
  };

  const onBeforeUpload = (event) => {
    const token = `Bearer ${localStorage.getItem(TRUE_ID_STORAGE)}` ?? null;
    const headers: UploadHttpHeaders = {
      authorization: token,
    };
    event.headers = headers;
    const currentUploadedFile = event.files?.[0] as UploadFileInfo;
    event.additionalData.fileUid = currentUploadedFile?.uid;
    onBeforeUploadFileList?.();
  };

  const isUploadButtonVisible = () =>
    _errorMessages && _errorMessages?.length === 0;

  useEffect(() => {
    if (!conditionHasValue(allowedExtensions)) {
      dispatchGet();
    }
    // default paramter - on mount
    isInProgress?.(false);
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setLocalAllowedExtensions(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    _setErrorMessages(errorMessages ?? []);
  }, [errorMessages]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(removeResponse.responsePost)) {
      onUploadFileList(
        removeObjectProperty(files, fileToDelete?.fileArchiveUID)
      );
      onRemovedFile?.(files, removeResponse?.responsePost?.responseData);
      setFileToDelete(null);
    }
  }, [removeResponse.responsePost]);

  useEffect(() => {
    if (clearFiles === true) {
      setLocalFiles([]);
      setUploadCompleteResponse([]);
      resetClearFiles?.(false);
      setShowMessage(null);
      setUnsuccessfulFiles([]);
      setCurrentProgress(0);
      _setErrorMessages([]);
      onComplete?.(false);
      setIsComplete(false);
    }
  }, [clearFiles]);

  useEffect(() => {
    if (
      uploadCompleteResponse.length > 0 &&
      uploadCompleteResponse.length === localFiles.length
    ) {
      setIsComplete(true);
    } else {
      if (localFiles.length > 0 && uploadCompleteResponse.length > 0) {
        isInProgress?.(true);
      }
    }
  }, [localFiles, uploadCompleteResponse]);

  useEffect(() => {
    if (isComplete) {
      onUploadFileList(uploadFileList);
      setLocalFiles([]);
      setUploadCompleteResponse([]);
      setUnsuccessfulFiles([]);
      setCurrentProgress(0);
      isInProgress?.(false);
      setDisabled(false);
      if (unsuccessfulFiles.length > 0) {
        setShowMessage("partial_success");
      } else {
        setShowMessage("success");
      }
      setTimeout(() => {
        setShowMessage(null);
      }, 5000);
      setUploadFileList([]);
      onComplete?.(true);
      setIsComplete(false);
    }
  }, [isComplete]);

  return (
    <>
      <Box
        width={"100%"}
        className={`true_upload_container ${
          isUploadButtonVisible() ? "upload_button_visible" : ""
        }`}
      >
        <Font fontType={labelFontType}>{label}</Font>

        <KeUpload
          tabIndex={tabIndex}
          className="true_upload_file"
          disabled={disabled}
          id={`true-input-upload-${name}`}
          batch={false}
          multiple={true}
          files={localFiles}
          withCredentials={false}
          saveUrl={API_URL_DESTINATION + saveUrl}
          removeUrl={onRemoveRequest}
          onStatusChange={onStatusChange}
          onAdd={onAdd}
          onRemove={onRemove}
          restrictions={{
            allowedExtensions: localAllowedExtensions,
            maxFileSize: MAX_SIZE_BYTES,
          }}
          onBeforeUpload={onBeforeUpload}
          autoUpload={autoUpload}
        />
        {showMessage === "in_progress" && (
          <>
            <LinearProgress variant="determinate" value={currentProgress} />
            <Font primaryColor fontType={"BOLD_TITLE"}>
              Uploading Files
            </Font>
          </>
        )}

        <FontError>{[errorMessages ?? _errorMessages].join(" ")}</FontError>
        {showMessage === "success" && (
          <FontSuccess>Upload Successful</FontSuccess>
        )}
        {showMessage === "partial_success" && (
          <>
            <FontSuccess>
              Upload Partially Successful - The following files did not upload:
            </FontSuccess>
            <div>
              <ul>
                {unsuccessfulFiles.map((x) => {
                  return <li>{x.name}</li>;
                })}
              </ul>
            </div>
          </>
        )}
      </Box>
      <Modal
        id="empty-file-message"
        title={"Empty File"}
        size={"xs"}
        open={isEmptyFileMsgOpen}
        closeEvent={setIsEmptyFileMsgOpen}
        showCloseButton
      >
        <p>
          Please note, files without any content cannot be uploaded. Make sure
          the files you select contain information.
        </p>
      </Modal>
    </>
  );
};

export default MultiUpload_TEMP;
