import {
  BaseTable2Properties,
  ColumnOptionsProperties,
  OptionsContextActionProperties,
} from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { MultiAlignContextActionProps } from "../../../../TrueUI/Tables/MultiTable/MultiAlignHeader";

export type ActivityTabContentProps = {
  insuredId: number;
  tabKey: string;
  hasAch?: boolean;
  hasCreditCard?: boolean;
};
export const tableName = "billing_activity_activity_table"; //Is double activity for the Sub nav activity and the tab activity

export const childContextMenu: OptionsContextActionProperties[] = [
  {
    displayName: "Option 1",
    actionName: "_option_one",
    rule: (ruleOptions) => {
      console.log({ ruleOptions });
      alert(`Option 1 selected`);
    },
  },
];

export const parentContextMenu: MultiAlignContextActionProps[] = [
  {
    displayName: "Option Parent 1",
    actionName: "_option_parent_one",
    rule: () => alert("Option Parent 1"),
  },
  {
    displayName: "Option Parent 2",
    actionName: "_option_parent_two",
    rule: () => alert("Option Parent 2"),
  },
];

const columnOptionsForParentTable: ColumnOptionsProperties<any>[] = [
  { fieldName: "Line", width: 5 },
  { fieldName: "TransactionDate", width: 15 },
  { fieldName: "InvoiceNumber", width: 15 },
  { fieldName: "Description", width: 35 },
  { fieldName: "Amount", width: 15, align: "right" },
  { fieldName: "Balance", width: 15, align: "right" },
];
export const columnOptionsForParentTableRowCells: ColumnOptionsProperties<any>[] =
  [
    { fieldName: "Line", width: 5 },
    { fieldName: "TransactionDate", width: 15.5 },
    { fieldName: "InvoiceNumber", width: 15.5 },
    { fieldName: "Description", width: 36 },
    { fieldName: "Amount", width: 15, align: "right" },
    { fieldName: "Balance", width: 13, align: "right" },
  ];
export const getActivityTableConfiguration = (parentURL) =>
  ({
    name: tableName,
    tableType: "multi",
    getURL: parentURL,
    columnOptions: columnOptionsForParentTable,
    toolbarOptions: {
      addButtonText: "NEW PAYMENT",
      editButtonText: "NEW ENTRY",
      showAddButton: false,
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      editButtonPrimary: true,
    },
  } as BaseTable2Properties);
