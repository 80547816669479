import { FC } from "react";
import { BaseTable2, Divider, FontMedium } from "../../../../../../TrueUI";
import {
  API_PATH_CONTACTS,
  TableProps,
  tableNameContacts,
} from "../typesAndConstants";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";

const ContactsTable: FC<TableProps> = ({
  representationId,
  formState,
  setFormState,
  setRowData,
  setTableInstanceMethods,
  caseNumber,
  hasClaimsManagementPermissions,
}) => {
  const rowClickEvent = (rowTableData) => {
    if (conditionHasValue(tableInstanceMethods)) {
      setTableInstanceMethods(tableInstanceMethods);
    }
    setRowData(rowTableData?.isDeselected === false ? rowTableData : null);
    setFormState({
      ...formState,
      title:
        rowTableData?.isDeselected === false
          ? `CONTACT DETAILS FOR CASE NUMBER ${caseNumber}`
          : "DETAILS",
      mode: "readonly",
      rowTableClicked:
        rowTableData?.isDeselected === false ? "contact" : "case",
    });
  };

  const addRowEventHandler = () => {
    if (conditionHasValue(tableInstanceMethods)) {
      setTableInstanceMethods(tableInstanceMethods);
    }
    setRowData(null);
    setFormState({
      mode: "add",
      title: `ADD CASE CONTACT`,
      rowTableClicked: "contact",
    });
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableNameContacts,
    getURL: `${API_PATH_CONTACTS}?representationId=${representationId}`,
    columnOptions: [
      { fieldName: "ContactName", width: 30 },
      { fieldName: "ContactType", width: 20 },
      { fieldName: "EmailAddress", width: 50 },
    ],
    toolbarOptions: {
      toolBarType: "compact",
      showAddButton: hasClaimsManagementPermissions,
    },
    events: {
      addEventOverrideCallback: addRowEventHandler,
      onRowClick: (e) => rowClickEvent(e),
    },
    advancedOptions: {
      paginate: false,
      tableStyle: {
        height: "233px",
        headerStyle: "transparent",
      },
    },
  };
  var { tableMethods } = useBaseTable(tableConfiguration);
  var tableInstanceMethods = tableMethods[tableNameContacts];
  setTableInstanceMethods(tableInstanceMethods);
  return (
    <>
      <Divider height={15} />
      <FontMedium name="litigationContactsTableTitle">
        CONTACTS FOR CASE NUMBER {caseNumber}
      </FontMedium>
      {representationId && <BaseTable2 name={tableNameContacts} />}
    </>
  );
};
export default ContactsTable;
