import { selectConfiguration } from "../../utilities/inputPropsConfigurationFunctions";
import { Select } from "../TrueUI";
import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../dtos/select-options";
import { useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";

type DiaryNoteCategoryDropdownProps = {
  value: any;
  onChange?: (value: string) => void;
  errorMessage?: any;
};

const DiaryNoteCategoryDropdown: FC<DiaryNoteCategoryDropdownProps> = ({
  value,
  onChange,
  errorMessage,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/DiaryNote/GetDiaryNoteCategoryOptions`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCategoryOptions(responseGet.responseData ?? []);
    }
  }, [responseGet]);

  return (
    <Select
      {...selectConfiguration(
        "id-diary-note-category",
        "diaryNoteCategory",
        "Note Category"
      )}
      labelFontType="BOLD_CAPTION"
      optionsMaxHeight={"400px"}
      firstOptionAsDefault={false}
      options={categoryOptions}
      value={value}
      errorMessage={errorMessage}
      onChange={(value) => {
        onChange?.(value);
      }}
    />
  );
};

export default DiaryNoteCategoryDropdown;
