import { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import RecentsHelp from "./RecentsHelp";
import RecentsMainTable from "./RecentsMainTable";
import helpComponentAtom from "../Site/SiteAtoms";
import { NotImplementedError } from "../../utilities/errorHandlerHelper";
import { TabPanel, Tabs } from "../TrueUI";

const RecentsContent: FC<any> = ({ recents }) => {
  const [value, setValue] = useState(0);
  const setHelper = useSetRecoilState(helpComponentAtom);

  useEffect(() => {
    setHelper(<RecentsHelp />);
    return () => {
      setHelper(null);
    };
  }, []);

  const onTabClick = (event) => {
    NotImplementedError(event);
    setValue(event.selected);
  };

  const filter = (propName: any, value: any) => {
    if (propName === null) return recents;
    return (
      recents.filter((row: any) => {
        if (row[propName] === value) {
          return row;
        }
      }) ?? []
    );
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Tabs
        name={"recents-tabs"}
        themeOverride={"material"}
        selected={value}
        onSelect={onTabClick}
      >
        <TabPanel title={"All"} name="all">
          <RecentsMainTable filteredRecents={filter(null, null)} />
        </TabPanel>
        <TabPanel title={"Recent"} name="recents">
          <RecentsMainTable filteredRecents={filter("isPinned", false)} />
        </TabPanel>
        <TabPanel title={"Pinned"} name="pinned">
          <RecentsMainTable filteredRecents={filter("isPinned", true)} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
export default RecentsContent;
