import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "../../TrueUI";
import { useApiGet, useApiPost } from "../../../hooks";
import NewSubmissionForm from "./NewSubmissionForm";
import NewSubmissionTable from "./NewSubmissionTable";
import NewSubmissionOFAC from "./NewSubmissionOFAC";
import { BaseTable } from "../../../dtos/base-table";
import { InsuredFoundPage } from "../../../dtos/insured-found-page";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../TrueUI/Dialogs/DialogConfirmation";
import { modalNewSubmissionBodyStyle } from "./ModalNewSubmissionStyles";
import { OfacSanctionDto } from "../../../dtos/ofac-sanction-dto";
import NewSubmissionImportAcord from "./NewSubmissionImportAcord";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { acord130FormProperties, globalIsSaving } from "../../../GlobalAtoms";
import { PolicyBlob } from "../../../dtos/policy-blob";
import { PolicyInsuredContactBlob } from "../../../dtos/policy-insured-contact-blob";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { useSetRecoilState } from "recoil";
import { StatusEnums } from "../../../dtos/status-enums";

type NewSubmissionProps = {
  showModal: boolean;
  onClose: () => void;
};

export type NewSubmissionFormUIProps = {
  companyName?: string;
  taxId?: string;
  taxIdType?: string;
  lastName?: string;
  firstName?: string;
  effectiveDate?: Date;
};

const initialNewSubmissionForm = {
  companyName: "",
  taxId: "",
  taxIdType: "",
  lastName: "",
  firstName: "",
};

const ModalNewSubmission: FC<NewSubmissionProps> = ({ showModal, onClose }) => {
  const navigate = useNavigate();
  const { getAtom, setAtom } = useAtomFamily(acord130FormProperties);
  const setGlobalIsSaving = useSetRecoilState<boolean>(globalIsSaving);
  const [showModalBody, setShowModalBody] = useState<
    "form" | "table" | "ofac" | "insured" | "import-acord"
  >("form");

  const [similarityScore, setSimilarityScore] = useState<number | null>(null);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [newSubmissionFormUI, setNewSubmissionFormUI] =
    useState<NewSubmissionFormUIProps | null>(initialNewSubmissionForm);
  const [newSubmissionTableUI, setNewSubmissionTableUI] =
    useState<BaseTable | null>(null);
  const [fileIdForImportAcord, setFileIdForImportAcord] = useState<number[]>(
    []
  );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<InsuredFoundPage>(
      `api/Insured/GetInsuredListByTaxIdOrName`,
      newSubmissionFormUI
    );

  const { responseGet, dispatchGet } = useApiGet<OfacSanctionDto[]>(
    `api/OfacSanction/GetOfacSanctionList?insuredName=${newSubmissionFormUI?.companyName}&ownerContactName=${newSubmissionFormUI?.firstName}, ${newSubmissionFormUI?.lastName}`
  );

  const {
    responseGet: responseGetExtractedAcord,
    dispatchGet: dispatchGetExtractedAcord,
  } = useApiGet<PolicyBlob>(
    `api/Policy/GetPolicyJSONFromExtractedSensibleAcord130?fileId=${fileIdForImportAcord[0]}`
  );

  const closeEvent = () => {
    if (showModalBody !== "import-acord") {
      onClose();
      setNewSubmissionFormUI(null);
    }
    setErrorDetails(null);
    setShowModalBody("form");
    setSimilarityScore(null);
    setNewSubmissionTableUI(null);
    setDialogConfiguration(null);
  };

  const cancelEvent = () => {
    setNewSubmissionFormUI(null);
    setErrorDetails(null);
    setShowModalBody("form");
    setSimilarityScore(null);
    setNewSubmissionTableUI(null);
    setDialogConfiguration(null);
    setAtom(null);
    onClose();
  };

  const importAcordWithSensible = () => {
    if (
      fileIdForImportAcord.length === 1 &&
      fileIdForImportAcord[0] !== 0 &&
      fileIdForImportAcord[0] !== undefined
    ) {
      dispatchGetExtractedAcord();
      setGlobalIsSaving(true);
    } else {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "A single file is needed in order to execute the import.",
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  const updatePrimaryContactAndNavigate = () => {
    const atomValue = getAtom();
    const primaryContactFound = atomValue?.insured?.contacts?.find(
      (contact) => contact.primaryContact === true
    );
    const defaultPrimaryContact = [
      {
        contactFirstName: newSubmissionFormUI?.firstName,
        contactLastName: newSubmissionFormUI?.lastName,
        primaryContact: true,
        contactStatus: StatusEnums.ACTIVE,
      } as PolicyInsuredContactBlob,
    ];

    const contactsUpdated = primaryContactFound
      ? atomValue?.insured?.contacts?.map((contact) => {
          if (contact.primaryContact)
            return {
              ...contact,
              contactFirstName: newSubmissionFormUI?.firstName,
              contactLastName: newSubmissionFormUI?.lastName,
              primaryContact: true,
            };
          return contact;
        })
      : defaultPrimaryContact;

    setAtom({
      ...atomValue,
      insured: {
        ...atomValue?.insured,
        contacts: contactsUpdated,
      },
    });
    navigate(`/acord130/${JSON.stringify(newSubmissionFormUI)}`);
  };

  const handleOkContinueEvent = () => {
    setErrorDetails(null);
    switch (showModalBody) {
      case "form":
        dispatchPost();
        setNewSubmissionTableUI(null);
        break;
      case "table":
        dispatchGet();
        break;
      case "ofac":
        updatePrimaryContactAndNavigate();
        break;
      case "import-acord":
        importAcordWithSensible();
        break;
    }
  };

  const insuredFoundMatches = (table: BaseTable | null) =>
    (table?.data?.length ?? 0) > 0;

  const apiCompleted = (response: BaseTable | null) => {
    if (insuredFoundMatches(response)) {
      setShowModalBody("table");
      setNewSubmissionTableUI(response);
    } else {
      dispatchGet();
    }
  };

  const getModalBody = () => {
    switch (showModalBody) {
      case "form":
        return (
          <NewSubmissionForm
            newSubmissionFormUI={newSubmissionFormUI}
            setNewSubmissionFormUI={setNewSubmissionFormUI}
            errorDetails={errorDetails}
          />
        );
      case "table":
        return (
          <NewSubmissionTable newSubmissionTableUI={newSubmissionTableUI} />
        );
      case "ofac":
        return <NewSubmissionOFAC similarityScore={similarityScore} />;
      case "import-acord":
        return (
          <NewSubmissionImportAcord setFileIds={setFileIdForImportAcord} />
        );
      default:
        return null;
    }
  };

  const errorMessageForExtractedAcord = (extractedPolicyJSON: PolicyBlob) => {
    if (
      !conditionHasValue(extractedPolicyJSON.insured?.insuredName) &&
      !conditionHasValue(extractedPolicyJSON.insured?.legalName) &&
      !conditionHasValue(extractedPolicyJSON.insured?.mailingCity) &&
      !conditionHasValue(extractedPolicyJSON.insured?.taxID) &&
      !conditionHasValue(extractedPolicyJSON.insured?.underwriterID) &&
      !conditionHasValue(extractedPolicyJSON.insured?.phone) &&
      !conditionHasValue(extractedPolicyJSON.insured?.naics) &&
      !conditionHasValue(extractedPolicyJSON.insured?.sic) &&
      !conditionHasValue(extractedPolicyJSON.insured?.websiteURL) &&
      !conditionHasValue(extractedPolicyJSON.insured?.mailingAddress1) &&
      !conditionHasValue(extractedPolicyJSON.insured?.mailingState) &&
      !conditionHasValue(extractedPolicyJSON.insured?.mailingPostalCode) &&
      (extractedPolicyJSON.insured?.addresses?.length ?? 0) === 0 &&
      (extractedPolicyJSON.insured?.officers?.length ?? 0) === 0 &&
      (extractedPolicyJSON.insured?.contacts?.length ?? 0) === 0 &&
      (extractedPolicyJSON.producers?.length ?? 0) === 0
    ) {
      return "No information was extracted from the provided document, make sure the format of this is correct.";
    } else return null;
  };

  useEffect(() => {
    return () => {
      setGlobalIsSaving(false);
    };
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      apiCompleted(responsePost?.axiosResponse?.data?.tableData ?? null);
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const matches = responseGet?.axiosResponse?.data;
      if ((matches?.length ?? 0) > 0) {
        const maxSimilarityScore = Math.max(
          ...(matches?.map((data) =>
            data.scoreOwner >= data.scoreInsured
              ? data.scoreOwner
              : data.scoreInsured
          ) ?? [])
        );
        setSimilarityScore(maxSimilarityScore);
        setShowModalBody("ofac");
      } else {
        updatePrimaryContactAndNavigate();
      }
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGetExtractedAcord) &&
      responseGetExtractedAcord.axiosResponse?.data !== undefined
    ) {
      const extractedPolicyJSON = responseGetExtractedAcord.axiosResponse?.data;
      const primaryContact = extractedPolicyJSON.insured?.contacts?.find(
        (contact) => contact.primaryContact === true
      );
      setGlobalIsSaving(false);
      setAtom(extractedPolicyJSON);
      setNewSubmissionFormUI({
        ...newSubmissionFormUI,
        companyName:
          extractedPolicyJSON?.insured?.insuredName ??
          newSubmissionFormUI?.companyName,
        taxId:
          extractedPolicyJSON?.insured?.taxID ?? newSubmissionFormUI?.taxId,
        firstName:
          primaryContact?.contactFirstName ?? newSubmissionFormUI?.firstName,
        lastName:
          primaryContact?.contactLastName ?? newSubmissionFormUI?.lastName,
        effectiveDate:
          extractedPolicyJSON?.effectiveDate ??
          newSubmissionFormUI?.effectiveDate,
      });
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          errorMessageForExtractedAcord(extractedPolicyJSON) ??
          "Acord form extraction succeeded",
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => closeEvent(),
      });
    }
  }, [responseGetExtractedAcord]);

  return (
    <>
      <Modal
        size="sm"
        id={"modal-new-submission"}
        title={"New Submission"}
        open={showModal}
        showCloseButton={true}
        showCancelTextButton={true}
        saveEvent={handleOkContinueEvent}
        saveOverrideLabel={
          showModalBody === "form" || showModalBody === "import-acord"
            ? "Ok"
            : "Continue"
        }
        cancelEvent={() =>
          setDialogConfiguration({
            open: true,
            dialogDescriptionText:
              "Are you sure you want to close before saving the changes?",
            onOptionNoEvent: () => setDialogConfiguration(null),
            onOptionYesEvent: () => cancelEvent(),
          })
        }
        buttonRibbonNode={
          showModalBody === "form" ? (
            <Button
              variantStyle="outlined"
              onClick={() => setShowModalBody("import-acord")}
            >
              IMPORT ACORD 130 FORM
            </Button>
          ) : null
        }
        sxInternalComponentsStyles={modalNewSubmissionBodyStyle(
          showModalBody === "form"
        )}
      >
        {getModalBody()}
      </Modal>
      <DialogConfirmation
        id="newSubmissionDialogConfirmation"
        {...dialogConfiguration}
        onCloseEvent={() => {
          setDialogConfiguration(null);
        }}
      />
    </>
  );
};

export default ModalNewSubmission;
