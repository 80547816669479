import { FC } from "react";
import {
  Paid,
  BeachAccess,
  Search,
  Security,
  PeopleAlt,
  Build,
  LibraryBooks,
} from "@mui/icons-material";
import { BillingIcon } from "../TrueUI/Icons/CustomIcons";

type ActivityIconProps = {
  category: string;
};

const ActivityIcon: FC<ActivityIconProps> = ({ category }) => {
  switch (category) {
    case "Insured":
      return <Search />;
    case "Security":
      return <Security />;
    case "Agency":
      return <PeopleAlt />;
    case "Admin":
      return <Build />;
    case "Claim":
      return <BeachAccess />;
    case "Quote":
      return <Paid />;
    case "Policy":
      return <LibraryBooks />;
    case "Payment":
    case "Reserve":
      return <BillingIcon />;
    default:
      return (
        <Paid
          sx={{
            width: "14px",
            height: "14px",
            position: "relative",
            left: "-3px",
            bottom: "3px",
          }}
        />
      );
  }
};
export default ActivityIcon;
