import { FC } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  BaseTable2Properties,
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTable } from "../../../../dtos/base-table";
import { updatePolicyQuote } from "../updatesPolicyQuoteFunctions";
import { PolicyBindInstructionsBlob } from "../../../../dtos/policy-bind-instructions-blob";
import style from "../EndorsementForm/Endorsement.module.css";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";

const EndorsementPayrollReportTable: FC<EndorsementGeneralProps> = ({
  tabKey,
  insuredId,
  policyId,
  readonly,
}) => {
  const tableName = `endorsement_payroll_report_table_${insuredId}_${policyId}`;
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const payrollReportTable =
    getAtom()?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.payrollReportTable;
  const columnsAndData = {
    columns: payrollReportTable?.columns ?? [],
    data: payrollReportTable?.data ?? [],
  };

  const updatePayrollReportTable = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    const atomValue = getAtom();
    const payrollReportTable =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.payrollReportTable;
    const updatedPayrollReportTable = {
      ...payrollReportTable,
      data,
      columns,
    } as BaseTable;

    const bindInstructionsUpdated = {
      ...atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions,
      payrollReportTable: updatedPayrollReportTable,
    } as PolicyBindInstructionsBlob;

    const newAtomValue = updatePolicyQuote(
      atomValue,
      "bindInstructions",
      bindInstructionsUpdated
    );

    setAtom(newAtomValue);
  };

  const computeOnInitReadOnlyUpdateRates = (
    options: ComputeForCellParameters<any>
  ) => {
    return {
      value: options.currentValue,
      typeId:
        readonly === true
          ? BaseTableInputType.READONLY_CHECKBOX
          : BaseTableInputType.CHECKBOX,
    } as ConditionForCellResponse;
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData,
    events: { onDataChange: updatePayrollReportTable },
    toolbarOptions: { hideToolbar: true },
    advancedOptions: { paginate: false, isEditMode: true },
    columnOptions: [
      {
        fieldName: "ReportingPeriod",
        width: 40,
      },
      {
        fieldName: "Status",
        width: 35,
      },
      {
        fieldName: "UpdateRates",
        width: 25,
        computeOnInit: { conditionForCell: [computeOnInitReadOnlyUpdateRates] },
      },
    ],
  };

  useBaseTable(tableConfiguration);

  return (
    <div className={style.endorsement_reporting_bind_instructions_table}>
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default EndorsementPayrollReportTable;
