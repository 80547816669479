import { FC, useEffect, useState } from "react";
import { AuditTableConfigurationDto } from "../../dtos/audit-table-configuration-dto";
import { useApiGet } from "../../hooks";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import BaseTableSortFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableSortFilter";
import { AuditAssignTypeEnum } from "../../dtos/audit-assign-type-enum";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2 } from "../TrueUI";

type AuditAggregateTableProps = {
  defaultCardType: number;
  defaultAssignedToValue: string;
  defaultAssignedType: number;
};

const AuditAggregateTable: FC<AuditAggregateTableProps> = ({
  defaultCardType,
  defaultAssignedToValue,
  defaultAssignedType,
}) => {
  const [tableInformation, setTableInformation] =
    useState<AuditTableConfigurationDto>();
  const { responseGet, dispatchGet } = useApiGet<AuditTableConfigurationDto>(
    "api/Audit/GetAuditTableForCards"
  );
  const tableName = "audit-aggregate-table";
  const defaultColumnNameForAssignedToFilter =
    defaultAssignedType === AuditAssignTypeEnum.USER
      ? ["UserId"]
      : defaultAssignedType === AuditAssignTypeEnum.TEAM
      ? ["TeamId"]
      : ["AssignedTo"];

  const getAssignedTypeForSelectedOptions = (value: string): number => {
    if (value.includes("_USER_TYPE")) return AuditAssignTypeEnum.USER;
    if (value.includes("_TEAM_TYPE")) return AuditAssignTypeEnum.TEAM;
    return AuditAssignTypeEnum.COMPANY;
  };

  const newFilterColumns = (value: string) => {
    const assignedType = getAssignedTypeForSelectedOptions(value);
    if (assignedType === AuditAssignTypeEnum.TEAM) {
      return [
        {
          columnNames: ["TeamId"],
          filterValue: value,
          filterName: "dropdown_filter",
        },
      ];
    }
    if (assignedType === AuditAssignTypeEnum.USER) {
      return [
        {
          columnNames: ["UserId"],
          filterValue: value,
          filterName: "dropdown_filter",
        },
      ];
    }
    return [
      {
        columnNames: ["AssignedTo"],
        filterValue: value,
        filterName: "dropdown_filter",
      },
    ];
  };

  const openComponent = () => (
    <IconButton
      aria-label="expand row"
      onClick={() => alert("Redirecting to future audit quote view.")}
    >
      <OpenInNewIcon fontSize="small" />
    </IconButton>
  );

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: tableInformation?.tableData?.columns ?? [],
      data: tableInformation?.tableData?.data ?? [],
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          filterOptions: actionOptions,
          optionsOverride: tableInformation?.statusList,
          columnsNames: ["CardType"],
          labelText: "Status:",
          defaultValue: defaultCardType,
          id: "audit-status-filter",
        }),
      (actionOptions) =>
        BaseTableSortFilter({
          filterOptions: actionOptions,
          columnsNames: ["UpdateOn"],
          labelText: "Sort:",
          defaultValue: "asc",
          id: "audit-newest-oldest-filter",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          filterOptions: actionOptions,
          optionsOverride: tableInformation?.assignedToList,
          columnsNames: defaultColumnNameForAssignedToFilter,
          labelText: "Assigned To:",
          defaultValue: defaultAssignedToValue,
          id: "audit-assigned-to-filter",
          onChange: (value) => {
            if (value !== null && value != "all") {
              actionOptions?.setFilterInclude?.(newFilterColumns(value));
            }
          },
        }),
    ],
    advancedOptions: {
      paginate: false,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: openComponent,
      },
    },
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showAddButton: false,
      showEditButton: false,
      showSettingsIcon: true,
    },
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTableInformation(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  useBaseTable(tableConfiguration);

  return (
    <>
      {tableInformation !== undefined && tableInformation !== null && (
        <BaseTable2 name={tableName} />
      )}
    </>
  );
};

export default AuditAggregateTable;
