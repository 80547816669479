import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../../../dtos/data-type-enums";

export const getLossHistoryTableColumns = (
  stateOptions: BaseTableDisplayOptions[],
  typeOptions: BaseTableDisplayOptions[],
  statusOptions: BaseTableDisplayOptions[]
): BaseTableColumn[] => [
  {
    _columnIndex: 0,
    _visibleColumnIndex: 0,
    displayName: "PolicyLossHistoryId",
    fieldName: "PolicyLossHistoryId",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.INT,
    isHidden: true,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 1,
    _visibleColumnIndex: 1,
    displayName: "Year",
    fieldName: "PolicyYear",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.INT,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 2,
    _visibleColumnIndex: 2,
    displayName: "Claim Number",
    fieldName: "ClaimNumber",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.TEXT,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
    maxLength: 20,
  },
  {
    _columnIndex: 3,
    _visibleColumnIndex: 3,
    displayName: "State",
    fieldName: "State",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.SELECT,
    displayOptions: stateOptions,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 4,
    _visibleColumnIndex: 4,
    displayName: "Type",
    fieldName: "Type",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.SELECT,
    displayOptions: typeOptions,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 5,
    _visibleColumnIndex: 5,
    displayName: "Status",
    fieldName: "Status",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.SELECT,
    displayOptions: statusOptions,
    defaultValue: "",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 6,
    _visibleColumnIndex: 6,
    displayName: "Paid",
    fieldName: "Paid",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.INT,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 7,
    _visibleColumnIndex: 7,
    displayName: "Incurred",
    fieldName: "Incurred",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.INT,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 8,
    _visibleColumnIndex: 8,
    displayName: "Recovery",
    fieldName: "Recovery",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.NUMBER,
    displayOptions: null,
    defaultValue: "0",
    defaultValueType: DataTypeEnums.NULLABLE_INT,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
  },
  {
    _columnIndex: 9,
    _visibleColumnIndex: 9,
    displayName: "Comments",
    fieldName: "Comments",
    cellVariant: CellVariantEnums.FILLED,
    type: BaseTableInputType.TEXT,
    displayOptions: null,
    defaultValue: "",
    defaultValueType: DataTypeEnums.NULLABLE_STRING,
    isHidden: false,
    isFilterable: false,
    alternateDisplayValues: null,
    isSortable: false,
    maxLength: 300,
  },
];
