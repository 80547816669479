import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { Modal, Select } from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { UserAccessRightsInteractProps } from "./UserAccessRights";

type AddProgramModalProps = {
  tenantId?: number;
  userId?: number;
  componentActions?: UserAccessRightsInteractProps;
  setComponentActions?: (value: UserAccessRightsInteractProps) => void;
};

const AddProgramModal: FC<AddProgramModalProps> = ({
  tenantId,
  userId,
  componentActions,
  setComponentActions,
}) => {
  const [programOptions, setProgramOptions] = useState<SelectOptions[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedProgram, setSelectedProgram] = useState<number>();

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/AdminTools/Security/UserListing/GetAvailableProgramsForUser?userId=${userId}&tenantId=${tenantId}`
  );

  const { responsePost, dispatchPost } = useApiPost(
    `api/AdminTools/Security/UserListing/SaveProgramUser?userId=${userId}&tenantId=${tenantId}&programId=${selectedProgram}`
  );

  useEffect(() => {
    if (componentActions?.isAddProgramModalOpen === true) {
      dispatchGet();
    }
  }, [componentActions?.isAddProgramModalOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const programOptions = responseGet?.responseData ?? [];
      if ((programOptions?.length ?? 0) == 0) {
        setIsConfirmationOpen(true);
      }
      setProgramOptions(programOptions ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setComponentActions?.({
        ...componentActions,
        isAddProgramModalOpen: false,
        isTableRefresh: true,
      });
    }
  }, [responsePost]);

  const onCancelModal = () => {
    setComponentActions?.({
      ...componentActions,
      isAddProgramModalOpen: false,
    });
  };

  return (
    <Modal
      id={"new_program_user_modal"}
      title={"Add Program"}
      open={componentActions?.isAddProgramModalOpen}
      cancelEvent={onCancelModal}
      size={"sm"}
      saveEvent={() => dispatchPost()}
      showCloseButton
      showCancelTextButton
    >
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Select
          id={"program-select"}
          name={"program-select"}
          options={programOptions}
          label={"Select Program"}
          labelPosition={"top"}
          labelFontType={"BOLD_CAPTION"}
          optionsMaxHeight="200px"
          onChange={(value) => setSelectedProgram(value)}
        />
      </div>
      <DialogConfirmation
        open={isConfirmationOpen}
        dialogDescriptionText={
          "No additional programs are available for this user"
        }
        onOptionYesEvent={() => {
          setIsConfirmationOpen(false);
          setComponentActions?.({
            ...componentActions,
            isAddProgramModalOpen: false,
          });
        }}
        optionYesOverrideLabel="Close"
      />
    </Modal>
  );
};
export default AddProgramModal;
