import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import { ModalAddInvoiceItemProps } from "./TypesForModalAddInvoiceItem";
import { InvoiceItemDto } from "../../../../../../dtos/invoice-item-dto";
import FormInvoiceItemWrapper from "./FormInvoiceItemWrapper";
import { useApiPost } from "../../../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../../utilities/apiFunctions";
import { StatusEnums } from "../../../../../../dtos/status-enums";

const ModalInvoiceItem: FC<ModalAddInvoiceItemProps> = ({
  setInvoiceItemModalConfig,
  invoiceItemModalConfig,
  invoiceData,
  setInvoiceData,
  errorFromCreatingInvoice,
}) => {
  const [invoiceItem, setInvoiceItem] =
    useState<Partial<InvoiceItemDto> | null>(null);

  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { dispatchPost, validatorErrorResponse, responsePost } = useApiPost(
    "api/Invoice/SaveInvoiceItem",
    invoiceItem
  );

  useEffect(() => {
    if (errorFromCreatingInvoice !== null) {
      setErrorDetails(errorFromCreatingInvoice);
    }
  }, [errorFromCreatingInvoice]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setInvoiceItemModalConfig({
        ...invoiceItemModalConfig,
        refreshDetailsTable: true,
        showItemModal: false,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (invoiceItemModalConfig?.showItemModal === true) {
      if (invoiceData?.invoiceItemBackUpForError !== null) {
        setInvoiceItem({
          ...(invoiceData?.invoiceItemBackUpForError ?? {}),
          insuredId: invoiceItemModalConfig.insuredId ?? 0,
          invoiceId: invoiceItemModalConfig.invoiceId ?? 0,
          invoiceSubId: invoiceItemModalConfig?.invoiceSubId ?? 0,
          invoiceDetailId: invoiceItemModalConfig?.invoiceDetailId ?? 0,
        });
      } else {
        setInvoiceItem({
          quantity: 1,
          priceEach: 0,
          lineNumber:
            (invoiceItemModalConfig?.invoiceDetailId ?? 0) === 0
              ? 1
              : (invoiceItemModalConfig?.maxLineNumber ?? 1) + 1,
          insuredId: invoiceItemModalConfig.insuredId ?? 0,
          invoiceId: invoiceItemModalConfig.invoiceId ?? 0,
          invoiceSubId: invoiceItemModalConfig?.invoiceSubId ?? 0,
          invoiceDetailId: invoiceItemModalConfig?.invoiceDetailId ?? 0,
        });
      }
    }
  }, [invoiceItemModalConfig?.showItemModal]);

  const closeModalResetInvoiceItem = (isOpen?: boolean) => {
    setInvoiceItem({
      quantity: 1,
      lineNumber: 1,
    });

    setInvoiceItemModalConfig({
      ...invoiceItemModalConfig,
      showItemModal: isOpen ?? false,
      maxLineNumber: 1,
    });
    setErrorDetails(null);
  };

  useEffect(() => {
    if (invoiceItem?.detailStatus === StatusEnums.DELETED) {
      dispatchPost();
    }
  }, [invoiceItem?.detailStatus]);

  const saveInvoiceItem = () => {
    if ((invoiceItemModalConfig?.invoiceId ?? 0) !== 0) {
      dispatchPost();
    }

    if (
      (invoiceItemModalConfig?.invoiceId ?? 0) === 0 &&
      invoiceItem !== null
    ) {
      const noPartialInvoiceItem = invoiceItem as InvoiceItemDto;
      setInvoiceData({
        ...invoiceData,
        invoiceItems:
          invoiceData?.invoiceItemBackUpForError === null
            ? [...(invoiceData?.invoiceItems ?? []), noPartialInvoiceItem]
            : [invoiceItem as InvoiceItemDto],
      });
    }
  };

  return (
    <Modal
      id="add-invoice-item-modal"
      title="Invoice Item"
      open={invoiceItemModalConfig.showItemModal}
      cancelEvent={() => closeModalResetInvoiceItem()}
      closeEvent={(isOpen) => closeModalResetInvoiceItem(isOpen)}
      deleteEvent={() =>
        setInvoiceItem({
          ...invoiceItem,
          detailStatus: StatusEnums.DELETED,
        })
      }
      deleteDisabled={(invoiceItemModalConfig?.invoiceDetailId ?? 0) === 0}
      saveEvent={() => saveInvoiceItem()}
      cancelButtonWithConfirmation
      showCloseButton
      showCancelTextButton
      cancelButtonConfirmationText={
        "Are you sure you want to cancel and lose your work?"
      }
      deleteButtonWithConfirmation
      deleteButtonConfirmationText={
        "Are you sure you want to delete this item?"
      }
    >
      <FormInvoiceItemWrapper
        insuredId={invoiceItemModalConfig.insuredId}
        invoiceId={invoiceItemModalConfig.invoiceId}
        invoiceSubId={invoiceItemModalConfig.invoiceSubId}
        invoiceDetailId={invoiceItemModalConfig.invoiceDetailId}
        invoiceItem={invoiceItem}
        setInvoiceItem={setInvoiceItem}
        errorDetails={errorDetails}
      />
    </Modal>
  );
};

export default ModalInvoiceItem;
