import { FC, useEffect, useState } from "react";
import {
  ModalBillingOptionsProps,
  billingContentConfigProps,
} from "../TypesBillingOptions";
import { Modal } from "../../../../../TrueUI";
import ModalContentBillingOption from "./ModalContentBillingOption";
import { InsuredBillingRuleFormDataDto } from "../../../../../../dtos/insured-billing-rule-form-data-dto";
import {
  billingAndAutoPayOptionsChanged,
  getBillingRuleFormData,
  processingOptionsChanged,
} from "./ModalBillingOptionsUtils";
import { areObjectsEqual } from "../../../../../../utilities/objectFunctions";
import { useApiPost } from "../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";

const ModalBillingOption: FC<ModalBillingOptionsProps> = ({
  modalBillingOptionsConfig,
  setModalBillingOptionsConfig,
}) => {
  const [billingRuleFormData, setBillingRuleFormData] =
    useState<Partial<InsuredBillingRuleFormDataDto> | null>(null);
  const [billingContentConfig, setBillingContentConfig] =
    useState<billingContentConfigProps | null>(null);

  const { responsePost, dispatchPost } = useApiPost(
    `api/InsuredBillingRule/SaveInsuredBillingRuleFromModal`,
    billingRuleFormData
  );

  const onSaveEvent = () => {
    const initialData = getBillingRuleFormData(
      billingContentConfig?.initialBillingRuleData ?? null
    );
    const formDataNotChanged = areObjectsEqual(
      billingRuleFormData,
      initialData
    );
    if (formDataNotChanged === true) {
      setModalBillingOptionsConfig({
        ...modalBillingOptionsConfig,
        isModalOpen: false,
      });
    }

    const billingAndAutoPayHasChanges = billingAndAutoPayOptionsChanged(
      billingRuleFormData,
      initialData
    );
    const processingOptionsHasChanges = processingOptionsChanged(
      billingRuleFormData,
      initialData
    );
    if (
      billingAndAutoPayHasChanges === true ||
      processingOptionsHasChanges === true
    ) {
      setBillingRuleFormData({
        ...billingRuleFormData,
        billingAndAutoPayOptionsChanged: billingAndAutoPayHasChanges,
        processingOptionsChanged: processingOptionsHasChanges,
      });
    }
  };

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      if (responsePost?.responseData === true) {
        setModalBillingOptionsConfig({
          ...modalBillingOptionsConfig,
          isModalOpen: false,
          refreshTable: true,
        });
      }
    }
  }, [responsePost]);

  useEffect(() => {
    if (
      billingRuleFormData?.billingAndAutoPayOptionsChanged === true ||
      billingRuleFormData?.processingOptionsChanged === true
    ) {
      dispatchPost();
    }
  }, [
    billingRuleFormData?.billingAndAutoPayOptionsChanged,
    billingRuleFormData?.processingOptionsChanged,
  ]);

  const onCancelEvent = () => {
    setModalBillingOptionsConfig({
      ...modalBillingOptionsConfig,
      isModalOpen: false,
    });
  };

  const onCloseEvent = () => {
    setModalBillingOptionsConfig({
      ...modalBillingOptionsConfig,
      isModalOpen: false,
    });
  };

  return (
    <>
      <Modal
        id={"insured_billing_rule_options"}
        title={"Billing Options"}
        open={modalBillingOptionsConfig?.isModalOpen}
        showCloseButton={true}
        deleteEvent={undefined}
        showCancelTextButton={true}
        saveEvent={onSaveEvent}
        cancelEvent={onCancelEvent}
        closeEvent={onCloseEvent}
        cancelButtonWithConfirmation
      >
        <ModalContentBillingOption
          modalBillingOptionsConfig={modalBillingOptionsConfig}
          billingContentConfig={billingContentConfig}
          setBillingContentConfig={setBillingContentConfig}
          billingRuleFormData={billingRuleFormData}
          setBillingRuleFormData={setBillingRuleFormData}
        />
      </Modal>
    </>
  );
};
export default ModalBillingOption;
