import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { PolicyExposureBlob } from "../../../../dtos/policy-exposure-blob";
import { PolicyRatingBlob } from "../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../dtos/policy-state-blob";
import { ProgramStateDto } from "../../../../dtos/program-state-dto";
import { QuoteRatingTypeEnum } from "../../../../dtos/quote-rating-type-enum";
import { QuoteStateActionTypeEnum } from "../../../../dtos/quote-state-action-type-enum";
import { ScheduleRatingDto } from "../../../../dtos/schedule-rating-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import {
  isDateEqualDate,
  isDatesRangesOverlap,
} from "../../../../utilities/dateFunctions";
import { customRound } from "../../../../utilities/stringFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import {
  PolicyStateCollectionUIProps,
  PolicyStateInModalStateAnyUIProps,
  PolicyStateInModalStateUIProps,
  ScheduleRateItemsUIProps,
  TotalsPayrollSurchargesAndPremiumProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getPolicyQuote,
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
  getStateByStateCodeAndDatesByStates,
} from "../PolicyQuoteForm/PolicyQuoteUtils";

export const getInitialValueForStateCollection: PolicyStateCollectionUIProps = {
  states: [],
  stateCodeList: "",
  allStates: [],
  isModalOpen: false,
};

export const getDefaultStateWithDates = (
  actionTypeList: PolicyEnumValueDescriptionAndBooleanBlob[],
  initialStates?: SelectOptions | null,
  effectiveDate?: Date | null,
  expirationDate?: Date | null
): PolicyStateInModalStateUIProps => ({
  stateCode: initialStates?.stringValue ?? "",
  stateName: initialStates?.displayName.slice(5),
  effectiveDate: effectiveDate,
  expirationDate: expirationDate,
  expanded: true,
  actionType: actionTypeList.find(
    (actionType) => actionType.value === QuoteStateActionTypeEnum.ADD
  ),
});

const getStatesListCode = (
  arrayStates?: PolicyStateInModalStateUIProps[] | null
) => {
  const statesCodes = arrayStates?.map((state) => state?.stateCode);
  const filteredArr = statesCodes?.filter((item, index) =>
    statesCodes.indexOf(item) === index ? item : null
  );
  return filteredArr?.join();
};

export const getStatesAndListCode = (
  currentStates?: PolicyStateInModalStateUIProps[] | null,
  newState?: PolicyStateInModalStateUIProps | null
) => {
  const newStates = [...(currentStates ?? [])];

  newStates.push(newState ?? {});

  return { newStates, listCodes: getStatesListCode(newStates) };
};

export const verifyStateUnique = (
  currentStates?: (PolicyStateInModalStateUIProps | null)[],
  newState?: PolicyStateInModalStateUIProps | null
) =>
  currentStates?.find(
    (state) =>
      state?.stateCode === newState?.stateCode &&
      isDatesRangesOverlap(
        new Date(newState?.effectiveDate ?? ""),
        new Date(newState?.expirationDate ?? ""),
        new Date(state?.effectiveDate ?? ""),
        new Date(state?.expirationDate ?? ""),
        true
      )
  );

export const getStateName = (
  stateCode: string,
  allStates?: SelectOptions[] | null
) =>
  allStates
    ?.find((state) => state.stringValue === stateCode)
    ?.displayName.slice(5);

export const getStateReportingBureauAndReportCode = (
  stateCode: string,
  allStates?: ProgramStateDto[] | null
) => {
  const stateFound = allStates?.find((state) => state.stateCode === stateCode);

  return {
    reportingBureau: stateFound?.reportingBureau,
    reportCode: stateFound?.reportCode,
  };
};

export const getNewStatesByModalStatesObject = (
  atomValue: GlobalInsuredAtomProperties | null,
  currentStates?: PolicyStateInModalStateUIProps[] | null
) => {
  const newStates = currentStates?.map((state) => {
    const currentValueInAtom = getStateByStateCodeAndDates(
      state.stateCode ?? "",
      state.effectiveDate ?? new Date(),
      state.expirationDate ?? new Date(),
      atomValue
    );

    return { ...currentValueInAtom, ...state };
  });

  return newStates;
};

export const getAllStatesCollapsedOrExpanded = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);
  const statesCollapsed = currentStates.reduce(
    (acc, state) => (!state.expanded ? acc + 1 : acc),
    0
  );
  const statesExpanded = currentStates.reduce(
    (acc, state) => (state.expanded ? acc + 1 : acc),
    0
  );
  return {
    allCollapsed: statesCollapsed === currentStates.length,
    allExpanded: statesExpanded === currentStates.length,
  };
};

export const getStatesUpdatedByExpanded = (
  expanded: boolean,
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return { ...state, expanded: expanded };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getStatesUpdatedAsCollapsed = (
  atomValue: GlobalInsuredAtomProperties | null,
  expanded: boolean
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    return { ...state, expanded: expanded };
  });

  return newStates;
};

export const sumExposuresAmount = (exposures: PolicyExposureBlob[]) => {
  if (exposures.length > 0) {
    const sumOfExposureAmount = exposures
      .map((exposure) => exposure?.exposureAmount ?? 0)
      .reduce((a, b) => (a ?? 0) + (b ?? 0));

    return sumOfExposureAmount;
  } else return 0;
};

export const sumExposuresManualPremium = (exposures: PolicyExposureBlob[]) => {
  if (exposures.length > 0) {
    const sumOfManualPremium = exposures
      .map((exposure) => exposure?.manualPremiumAtMod ?? 0)
      .reduce((a, b) => (a ?? 0) + (b ?? 0));

    return sumOfManualPremium;
  } else return 0;
};

export const getTotalPayroll = (states: PolicyStateBlob[]) => {
  const totalPayroll = states
    ?.map((state) => sumExposuresAmount(state.exposures ?? []))
    .reduce((a, b) => (a ?? 0) + (b ?? 0));

  return totalPayroll;
};

export const getSumOfTotalSurchargesAndFeesByState = (
  ratings: PolicyRatingBlob[]
) => {
  if (ratings.length > 0) {
    return Object.values(ratings).reduce((prevValue, rating) => {
      const isSurchargesOrFees =
        ["Fee", "Tax", "Surcharge"].includes(rating.elementCategory ?? "") ??
        false;
      return prevValue + (isSurchargesOrFees ? rating.amount ?? 0 : 0);
    }, 0);
  }
  return 0;
};

export const getSumOfTotalSurchargesAndFees = (states: PolicyStateBlob[]) => {
  if (states.length > 0) {
    const fees = states
      .map((state: PolicyStateBlob) => {
        return getSumOfTotalSurchargesAndFeesByState(state?.ratings ?? []);
      })
      .reduce(
        (a, value) =>
          (a ?? 0) + (value = !null && value != undefined ? value : 0)
      ) as number;
    return customRound(fees.toString(), 0);
  }
  return 0;
};

export const getTotalSurchargesAndFees = (states: PolicyStateBlob[]) => {
  return getSumOfTotalSurchargesAndFees(states);
};

export const getRunningTotalByState = (state: PolicyStateBlob) => {
  const ratings = state.ratings;

  if (ratings && ratings.length > 0) {
    return ratings[ratings.length - 1]?.runningTotal ?? 0;
  }
  return 0;
};

export const sumRunningTotal = (states: PolicyStateBlob[]) => {
  if (states.length > 0) {
    return states
      .map((state) => getRunningTotalByState(state))
      .reduce((a, b) => (a ?? 0) + (b ?? 0));
  }
  return 0;
};

export const getTotalPayrollSurchargesAndFeesAndPremium = (
  states: PolicyStateBlob[]
) => {
  if (states && states.length > 0) {
    const totalPayroll = getTotalPayroll(states);
    const totalSurchargesAndFees = getTotalSurchargesAndFees(states);
    const sumOfRunningTotals = sumRunningTotal(states) ?? 0;
    const totalPremium = sumOfRunningTotals - totalSurchargesAndFees;
    const totalPremiumRounded = customRound(totalPremium.toString(), 0);

    return {
      totalPayroll,
      totalSurchargesAndFees,
      totalPremium: totalPremiumRounded,
    };
  }
  return {
    totalPayroll: 0,
    totalSurchargesAndFees: 0,
    totalPremium: 0,
  } as TotalsPayrollSurchargesAndPremiumProps;
};

export const getCalculationsForState = (state: PolicyStateBlob) => {
  if (
    state.ratings &&
    state.ratings.length > 0 &&
    state.exposures &&
    state.exposures.length > 0
  ) {
    const totalPayroll = sumExposuresAmount(state.exposures);
    const totalSurchargesAndFees = getSumOfTotalSurchargesAndFeesByState(
      state.ratings ?? []
    );
    const runningTotal = getRunningTotalByState(state);
    const totalPremium = runningTotal - totalSurchargesAndFees;
    const totalManualPremium = sumExposuresManualPremium(state.exposures);

    return {
      totalPayroll,
      totalSurchargesAndFees,
      totalPremium,
      totalManualPremium,
    };
  }
  return {
    totalPayroll: 0,
    totalSurchargesAndFees: 0,
    totalPremium: 0,
    totalManualPremium: 0,
  };
};

export const getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  if (
    currentState &&
    currentState.ratings &&
    currentState.ratings.length > 0 &&
    currentState.exposures &&
    currentState.exposures.length > 0
  ) {
    const totalPayroll = sumExposuresAmount(currentState.exposures ?? []);
    const totalSurchargesAndFees = customRound(
      getSumOfTotalSurchargesAndFeesByState(
        currentState.ratings ?? []
      ).toString(),
      0
    );
    const runningTotal = customRound(
      getRunningTotalByState(currentState).toString(),
      0
    );
    const totalPremium = runningTotal - totalSurchargesAndFees;

    return {
      totalPayroll,
      totalSurchargesAndFees,
      totalPremium,
      isExpanded: currentState.expanded ?? false,
    };
  }
  return {
    totalPayroll: 0,
    totalSurchargesAndFees: 0,
    totalPremium: 0,
    isExpanded: currentState?.expanded ?? false,
  };
};

export const getRateItemErrors = (localModalUI?: ScheduleRateItemsUIProps[]) =>
  localModalUI?.map((rateItem, _rateIndex) =>
    rateItem?.value !== 1.0 && (rateItem?.reason ?? "") === ""
      ? ["Please add a Reason/Basis"]
      : [""]
  );

export const hasRateItemErrors = (errorDetails?: string[][]) =>
  errorDetails?.some((error) => error[0] !== "");

export const hasRateItemErrorByIndex = (
  rateIndex: number,
  errorDetails?: string[][]
) => (errorDetails?.[rateIndex][0] ?? "") !== "";

export const getTotalMinMaxRange = (
  scheduleRatingUI?: ScheduleRatingDto | null
) => {
  const minValue = scheduleRatingUI?.minValue ?? 1;
  const maxValue = scheduleRatingUI?.maxValue ?? 1;
  const percentageMin = customRound(((1 - minValue) * 100).toString(), 2);
  const percentageMax = customRound(((maxValue - 1) * 100).toString(), 2);
  return `+${percentageMax}/${percentageMin}%`;
};

export const getTotalPreviousAndValue = (
  localModalUI?: ScheduleRateItemsUIProps[] | null
) => {
  const totalValue =
    localModalUI?.reduce(
      (acc, rateItem) =>
        rateItem?.value !== 1.0 ? acc + (rateItem?.value ?? 1) : acc,
      0
    ) ?? 0;
  const totalRows =
    localModalUI?.reduce(
      (acc, rateItem) => (rateItem?.value !== 1.0 ? acc + 1 : acc),
      0
    ) ?? 0;
  const subTotal = totalRows - totalValue;
  const total = customRound((1 - subTotal).toString(), 2);
  return total === 0 ? "1.00" : total.toFixed(2);
};

const getNetRateValue = (rateFactor: number, modRate: number) =>
  customRound((rateFactor * modRate).toString(), 5);

const getNewExposures = (
  rateFactor: number,
  exposures?: PolicyExposureBlob[] | null
) =>
  exposures?.map(
    (exposure) =>
      ({
        ...exposure,
        netRate: getNetRateValue(rateFactor, exposure?.modRate ?? 0),
      } as PolicyExposureBlob)
  );

export const getStatesWithGeneralPropsUpdated = (states: PolicyStateBlob[]) =>
  states.map((state) => {
    const calculations = getCalculationsForState(state);
    const stateModRate =
      calculations.totalManualPremium > 0
        ? calculations.totalPremium / calculations.totalManualPremium
        : 0;
    const modRateFactor = customRound(stateModRate.toString(), 5);
    const xModRating = state.ratings?.find(
      (rating) => rating.elementType === QuoteRatingTypeEnum.X_MOD
    );
    const expenseConstantRating = state.ratings?.find(
      (rating) => rating.elementType === QuoteRatingTypeEnum.EXPENSE_CONSTANT
    );
    const standardPremiumRating = state.ratings?.find(
      (rating) => rating.elementType == QuoteRatingTypeEnum.STANDARD_PREMIUM
    );
    const premiumDiscountRating = state.ratings?.find(
      (rating) => rating.elementType == QuoteRatingTypeEnum.DISCOUNT_TABLE
    );
    const newExposures = getNewExposures(modRateFactor, state?.exposures);

    return {
      ...state,
      xMod: xModRating?.rate ?? null,
      modTypeCode:
        xModRating?.policyReference?.policyReferenceID.toString() ?? null,
      modTypeDescription: xModRating?.policyReference?.optionDisplay ?? null,
      expenseConstant: expenseConstantRating?.amount ?? null,
      stateStandardPremium: standardPremiumRating?.runningTotal ?? null,
      premiumDiscount: premiumDiscountRating?.amount ?? null,
      modRateFactor: modRateFactor,
      estimatedAnnualPremium: customRound(
        calculations.totalPremium.toString(),
        0
      ),
      totalFees: customRound(calculations.totalSurchargesAndFees.toString(), 0),
      exposures: newExposures,
    } as PolicyStateBlob;
  });

export const getPayrollAndPremiumPercentage = (
  endorsedCalculations: TotalsPayrollSurchargesAndPremiumProps,
  previousCalculations: TotalsPayrollSurchargesAndPremiumProps
) => {
  const payrollPercentage = customRound(
    Math.abs(
      ((endorsedCalculations.totalPayroll - previousCalculations.totalPayroll) /
        previousCalculations.totalPayroll) *
        100
    ).toString(),
    2
  );
  const premiumPercentage = customRound(
    Math.abs(
      ((endorsedCalculations.totalPremium - previousCalculations.totalPremium) /
        previousCalculations.totalPremium) *
        100
    ).toString(),
    2
  );

  return { payrollPercentage, premiumPercentage };
};

export const statesUpdatedWithExposures = (
  atomValue: GlobalInsuredAtomProperties | null,
  newExposures: Map<string, PolicyExposureBlob[]>,
  localModalStateUI?: PolicyStateInModalStateAnyUIProps | null
) => {
  const newStates: PolicyStateBlob[] = [];
  const policyQuote = getPolicyQuote(atomValue);
  const states = policyQuote?.configuration?.stateList;
  newExposures.forEach((value, key) => {
    const state = states?.find((state) => state.stateCode === key);
    newStates.push({
      stateCode: state?.stateCode,
      stateName: state?.longName,
      reportCode: state?.nCCICode,
      reportingBureau: state?.reportingBureau,
      lossCostMultiplier: 1,
      effectiveDate: localModalStateUI?.effectiveDate,
      expirationDate: localModalStateUI?.expirationDate,
      exposures: value,
      expanded: true,
    } as PolicyStateBlob);
  });
  return newStates;
};

export const exposuresUpdated = (
  localModalStateUI?: PolicyStateInModalStateAnyUIProps | null
): Map<string, PolicyExposureBlob[]> => {
  const stateExposureMap = new Map<string, PolicyExposureBlob[]>();
  localModalStateUI?.selectedStates?.forEach((stateCode) => {
    const classCodeRate = localModalStateUI?.classRates?.classCodeRates.find(
      (ccr) => ccr.stateCode === stateCode
    );
    const newExposure = {
      uniqueKey: crypto.randomUUID(),
      classCode: localModalStateUI?.classRates?.classCode,
      classCodeID: classCodeRate?.classCodeId,
      classSuffix: localModalStateUI?.classRates?.classSuffix,
      description: classCodeRate?.description,
      hazardGroup: classCodeRate?.hazardGroup,
      rateBasis: classCodeRate?.premiumBasis,
      exposureAmount: 0,
      baseRate: classCodeRate?.baseRate ?? 0,
      modRate: classCodeRate?.rate ?? 0,
      manualPremiumAtMod: 0,
    } as PolicyExposureBlob;
    if (stateExposureMap.get(stateCode)) {
      const prevExposures = stateExposureMap.get(stateCode) ?? [];
      stateExposureMap.set(stateCode, prevExposures.concat(newExposure));
    } else {
      stateExposureMap.set(stateCode, [newExposure]);
    }
  });
  return stateExposureMap;
};
