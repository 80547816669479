import { FC, useEffect, useState, useRef } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Badge, IconButton, Menu, MenuItem, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

type MobileMenuProperties = {
  open: boolean;
};

const MobileMenu: FC<MobileMenuProperties> = ({ open }) => {
  const mobileMenuRef = useRef(null);
  const [mobileMenuPositionAnchor, setMobileMenuPositionAnchor] = useState<null | HTMLElement>(mobileMenuRef.current);
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setMobileMenuPositionAnchor(mobileMenuRef.current);
  }, []);

  useEffect(() => {
    setIsOpen(open)
  }, [open]);
  
  return (
    <Box ref={mobileMenuRef}>
      <Menu
        anchorEl={mobileMenuPositionAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={"mobile_menu"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 7,
        }}
      >
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(true)}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MobileMenu;
