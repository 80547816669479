import { FC, useEffect, useState } from "react";
import { AddPayeeFormProps } from "./typesAndConstants";
import {
  Col,
  Input,
  InputAddress,
  Row,
  Select,
  Switch,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import Button from "../../../../../TrueUI/Buttons/Button";
import Divider from "../../../../../TrueUI/Divider/Divider";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { StatusEnums } from "../../../../../../dtos/status-enums";
import { FromEnumToArray } from "../../../../../../utilities/enumFunctions";
import { getStates } from "../../../../../../utilities/staticDataFunctions";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { PayeeDto } from "../../../../../../dtos/payee-dto";
import { PaymentStatusEnum } from "../../../../../../dtos/payment-status-enum";
import InputTaxAutoformat from "../../../../../TrueUI/Inputs/InputTaxAutoformat";

const BinarySelectOptions: Partial<SelectOptions>[] = [
  { displayName: "No", booleanValue: false },
  { displayName: "Yes", booleanValue: true },
];

const StatusTypes: Partial<SelectOptions>[] = FromEnumToArray(StatusEnums).map(
  (enumType) => ({
    displayName: enumType.key,
    stringValue: enumType.value,
  })
);

const StatesList: Partial<SelectOptions>[] = getStates().map((state) => ({
  displayName: state.abbreviation,
  stringValue: state.abbreviation,
}));

const setInitialPayee: PayeeDto = {
  payeeId: 0,
  payeeName: "",
  provider: false,
  payeeType: 1,
  payViaAch: false,
  payeeStatus: 1,
  taxId: "",
  taxIdType: "",
  require1099: false,
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  fax: "",
  bankRoutingNumber: "",
  bankAccountNo: "",
  payeeTypeOptions: [],
};

const AddPayeeForm: FC<AddPayeeFormProps> = ({
  paymentFormData,
  setPaymentFormData,
  closeModal,
  setCurrentTab,
  setExternalSave,
  externalErrorDetails,
  claimLandingClaimant,
}) => {
  const isReadOnly = [PaymentStatusEnum.PAID, PaymentStatusEnum.VOID].includes(
    paymentFormData?.paymentStatus ?? -1
  );
  const isReadOnlyByOverridePayee =
    isReadOnly || paymentFormData?.overridePayee;

  const [payeeInfo, setPayeeInfo] = useState<PayeeDto>(setInitialPayee);

  const [payeeTypes, setPayeeTypes] = useState<Partial<SelectOptions>[]>([]);

  const [errorDetails, setErrorDetails] = useState<any>();

  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  const { responseGet: responseGetPayee, dispatchGet: dispatchGetPayee } =
    useApiGet<PayeeDto>(
      `api/Payee/GetPayeePageById?payeeId=${paymentFormData?.payeeId}`
    );

  const {
    responseGet: responseGetPayeeType,
    dispatchGet: dispatchGetPayeeType,
  } = useApiGet<Partial<SelectOptions>[]>(`api/Payee/PayeeTypeList`);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/Payee/SavePayee",
    {
      ...payeeInfo,
      paymentId: paymentFormData?.paymentId ?? 0,
    }
  );

  const updatePayeeInfo = (field: string, value: any) => {
    setPayeeInfo((prev) => ({ ...prev, [field]: value }));
  };

  const updateOverridePayeeInfo = (field: string, value: any) => {
    setPaymentFormData({ ...paymentFormData, [field]: value });
  };

  const updateMultiplePayeeInfo = (fieldNames: string[], values: any[]) => {
    const newValues = fieldNames.map((targetProperty, index) => ({
      [targetProperty]: values[index],
    }));
    const targetJoined = Object.assign({}, ...newValues);
    setPayeeInfo({
      ...payeeInfo,
      ...targetJoined,
    });
  };

  useEffect(() => {
    if (conditionHasValue(paymentFormData?.payeeId)) {
      dispatchGetPayee();
    } else setReadyToRender(true);
    dispatchGetPayeeType();
  }, []);

  useEffect(() => {
    if (
      conditionHasValue(responseGetPayee.responseData) &&
      !responseGetPayee.isLoading
    ) {
      setPayeeInfo(responseGetPayee.responseData as PayeeDto);
      updateOverridePayeeInfo(
        "payeeCompanyName",
        (paymentFormData?.payeeCompanyName ?? "") !== ""
          ? paymentFormData?.payeeCompanyName
          : responseGetPayee.responseData?.payeeName
      );
      setReadyToRender(true);
    }
  }, [responseGetPayee]);

  useEffect(() => {
    if (conditionHasValue(responseGetPayeeType.responseData))
      setPayeeTypes(
        responseGetPayeeType.responseData as Partial<SelectOptions>[]
      );
  }, [responseGetPayeeType]);

  useEffect(() => {
    if (
      responsePost?.errorResponse === null &&
      responsePost?.isLoading === false &&
      responsePost?.responseData !== null
    ) {
      setErrorDetails(undefined);
      setPayeeInfo(setInitialPayee);
      setCurrentTab(0);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  const onSave = () => {
    if (paymentFormData?.overridePayee) setExternalSave(true);
    else dispatchPost();
  };

  useEffect(() => {
    if (paymentFormData?.overridePayee === true) {
      updateOverridePayeeInfo(
        "payeeCompanyName",
        paymentFormData?.payeeCompanyName
      );
    } else {
      const companyNameFromPaymentOrPayee =
        (paymentFormData?.payToClaimant ?? false) === false &&
        (paymentFormData?.payeeCompanyName ?? "") !== ""
          ? paymentFormData?.payeeCompanyName ?? null
          : payeeInfo?.payeeName ?? null;
      const companyPayeeName =
        (paymentFormData?.payToClaimant ?? false) === true
          ? claimLandingClaimant?.fullName ?? ""
          : companyNameFromPaymentOrPayee ?? payeeInfo?.payeeName;
      updateOverridePayeeInfo("payeeCompanyName", companyPayeeName);
    }
  }, [paymentFormData?.overridePayee]);

  return readyToRender ? (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        verticalGutter={"10px"}
        spacing={1}
        rowWidth="85%"
      >
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 9, lg: 9, xl: 9 }}
        >
          <Input
            tabIndex={1}
            {...inputConfiguration(
              "id-payee-name",
              "payee-name",
              "Payee Name",
              200,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.payeeName}
            onChangeRawValue={(value) => updatePayeeInfo("payeeName", value)}
            errorMessage={errorDetails?.payeeName}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Select
            tabIndex={2}
            {...selectConfiguration(
              "id-payee-provider",
              "payee-provider",
              "Provider",
              isReadOnlyByOverridePayee
            )}
            options={BinarySelectOptions}
            labelFontType={"CAPTION"}
            value={payeeInfo?.provider}
            onChange={(value) => updatePayeeInfo("provider", value)}
            errorMessage={errorDetails?.provider}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="85%">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Select
            tabIndex={3}
            {...selectConfiguration(
              "id-payee-type",
              "payee-type",
              "Payee Type",
              isReadOnlyByOverridePayee
            )}
            options={payeeTypes}
            labelFontType={"CAPTION"}
            optionsMaxHeight="390px"
            value={payeeInfo?.payeeType}
            onChange={(value) => updatePayeeInfo("payeeType", value ?? 1)}
            errorMessage={errorDetails?.payeeType}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <InputTaxAutoformat
            tabIndex={4}
            {...inputConfiguration(
              "id-payee-taxID",
              "payee-taxID",
              "Tax ID",
              12,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.taxId}
            taxIdType={"FEIN"} // When the user adds or edits a Payee, the type will always be set to FEIN
            onChange={(value) => {
              updateMultiplePayeeInfo(["taxId", "taxIdType"], [value, "FEIN"]);
            }}
            errorMessage={errorDetails?.taxId}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Select
            tabIndex={5}
            {...selectConfiguration(
              "id-payee-pay-via-ach",
              "payee-pay-via-ach",
              "Pay via ACH",
              isReadOnlyByOverridePayee
            )}
            options={BinarySelectOptions}
            labelFontType={"CAPTION"}
            value={payeeInfo?.payViaAch}
            onChange={(value) => updatePayeeInfo("payViaAch", value)}
            errorMessage={errorDetails?.payViaAch}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Select
            tabIndex={6}
            {...selectConfiguration(
              "id-payee-require1099",
              "payee-require1099",
              "Require 1099",
              isReadOnlyByOverridePayee
            )}
            options={BinarySelectOptions}
            labelFontType={"CAPTION"}
            value={payeeInfo?.require1099}
            onChange={(value) => updatePayeeInfo("require1099", value)}
            errorMessage={errorDetails?.require1099}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="85%">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
        >
          <Input
            tabIndex={7}
            {...inputConfiguration(
              "id-payee-address",
              "payee-address",
              "Address",
              100,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.address}
            onChangeRawValue={(value) => updatePayeeInfo("address", value)}
            errorMessage={errorDetails?.address}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            tabIndex={8}
            type="email"
            {...inputConfiguration(
              "id-payee-email",
              "payee-email",
              "Email",
              100,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.emailAddress}
            onChangeRawValue={(value) => updatePayeeInfo("emailAddress", value)}
            errorMessage={errorDetails?.emailAddress}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            tabIndex={9}
            type="internalPhone"
            {...inputConfiguration(
              "id-payee-phone",
              "payee-phone",
              "Phone",
              14,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.phone}
            onChangeRawValue={(value) => updatePayeeInfo("phone", value)}
            errorMessage={errorDetails?.phone}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="85%">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            tabIndex={10}
            {...inputConfiguration(
              "id-payee-city",
              "payee-city",
              "City",
              30,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.city}
            onChangeRawValue={(value) => updatePayeeInfo("city", value)}
            errorMessage={errorDetails?.city}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
        >
          <Select
            tabIndex={11}
            {...selectConfiguration(
              "id-payee-state",
              "payee-state",
              "State",
              isReadOnlyByOverridePayee
            )}
            options={StatesList}
            optionsMaxHeight="260px"
            labelFontType={"CAPTION"}
            value={payeeInfo?.state}
            onChange={(value) => updatePayeeInfo("state", value)}
            errorMessage={errorDetails?.state}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            tabIndex={12}
            type="postalCode"
            {...inputConfiguration(
              "id-payee-postal-code",
              "payee-postal-code",
              "Postal Code",
              10,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.zip}
            onChangeRawValue={(value) => updatePayeeInfo("zip", value)}
            errorMessage={errorDetails?.zip}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            tabIndex={13}
            type="internalPhone"
            {...inputConfiguration(
              "id-payee-fax",
              "payee-fax",
              "Fax",
              14,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.fax ?? ""}
            onChangeRawValue={(value) => updatePayeeInfo("fax", value)}
            errorMessage={errorDetails?.fax}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="64%">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            tabIndex={14}
            {...inputConfiguration(
              "id-payee-bank-account-number",
              "payee-bank-account-number",
              "Bank Account Number",
              30,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.bankAccountNo}
            onChangeRawValue={(value) =>
              updatePayeeInfo("bankAccountNo", value)
            }
            errorMessage={errorDetails?.bankAccountNo}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            tabIndex={15}
            {...inputConfiguration(
              "id-payee-routing-number",
              "payee-routing-number",
              "Routing Number",
              10,
              isReadOnlyByOverridePayee
            )}
            labelFontType={"CAPTION"}
            value={payeeInfo?.bankRoutingNumber}
            onChangeRawValue={(value) =>
              updatePayeeInfo("bankRoutingNumber", value)
            }
            errorMessage={errorDetails?.bankRoutingNumber}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Select
            tabIndex={16}
            {...selectConfiguration(
              "id-payee-status",
              "payee-status",
              "Status",
              isReadOnlyByOverridePayee
            )}
            options={StatusTypes}
            labelFontType={"CAPTION"}
            value={payeeInfo?.payeeStatus}
            onChange={(value) => updatePayeeInfo("payeeStatus", value)}
            errorMessage={errorDetails?.payeeStatus}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="85%">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Switch
            tabIndex={17}
            control="checkbox"
            id="id-overrride-payee"
            name="override-payee"
            label="Override Payee"
            labelFontType="BODY"
            isChecked={paymentFormData?.overridePayee ?? false}
            onChangeIsChecked={(value) =>
              updateOverridePayeeInfo("overridePayee", value)
            }
            readOnly={isReadOnly}
          />
        </Col>
        {paymentFormData?.overridePayee && (
          <Col
            colDirection="column"
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 9, lg: 9, xl: 9 }}
          >
            <Row
              horizontalMargin={"0px"}
              verticalMargin={"0px"}
              horizontalGutter={"5px"}
              verticalGutter={"5px"}
              spacing={1}
              rowWidth="100%"
            >
              <Input
                tabIndex={18}
                {...inputConfiguration(
                  "id-payee-name",
                  "payee-name",
                  "Payee Name",
                  200
                )}
                labelFontType={"CAPTION"}
                value={paymentFormData?.payeeName ?? ""}
                onChangeRawValue={(value) =>
                  updateOverridePayeeInfo("payeeName", value)
                }
                errorMessage={externalErrorDetails?.payeeName}
                readOnly={isReadOnly}
              />
            </Row>
            <Row
              horizontalMargin={"0px"}
              verticalMargin={"0px"}
              horizontalGutter={"5px"}
              verticalGutter={"5px"}
              spacing={1}
              rowWidth="100%"
            >
              <Input
                tabIndex={18}
                {...inputConfiguration(
                  "id-company-name",
                  "company-name",
                  "Company Name",
                  100
                )}
                labelFontType={"CAPTION"}
                value={paymentFormData?.payeeCompanyName ?? ""}
                onChangeRawValue={(value) =>
                  updateOverridePayeeInfo("payeeCompanyName", value)
                }
                errorMessage={externalErrorDetails?.payeeCompanyName}
                readOnly={isReadOnly}
              />
            </Row>
            <Row {...rowWithNoMarginNorGutter} spacing={1} rowWidth="100%">
              <InputAddress
                tabIndex={19}
                labelFontType="CAPTION"
                readOnly={isReadOnly}
                showSingleLabel={false}
                initValueAddress2={paymentFormData?.payeeAddress2 ?? ""}
                initValueAddress1={paymentFormData?.payeeAddress1 ?? ""}
                initValueCity={paymentFormData?.payeeCity ?? ""}
                initValuePostCode={paymentFormData?.payeeZip ?? ""}
                initValueStateCode={paymentFormData?.payeeStateCode ?? ""}
                onChangeValueAddress1={(value) =>
                  updateOverridePayeeInfo("payeeAddress1", value)
                }
                onChangeValueAddress2={(value) =>
                  updateOverridePayeeInfo("payeeAddress2", value)
                }
                onChangeValueCity={(value) =>
                  updateOverridePayeeInfo("payeeCity", value)
                }
                onChangeValuePostCode={(value) =>
                  updateOverridePayeeInfo("payeeZip", value)
                }
                onChangeStateCode={(value) =>
                  updateOverridePayeeInfo("payeeStateCode", value)
                }
                address1ErrorMessage={externalErrorDetails?.payeeAddress1}
                cityErrorMessage={externalErrorDetails?.payeeCity}
                postCodeErrorMessage={externalErrorDetails?.payeeZip}
                stateCodeErrorMessage={externalErrorDetails?.payeeStateCode}
              />
            </Row>
          </Col>
        )}
      </Row>
      <Divider height={paymentFormData?.overridePayee ? 157 : 288} />
      <Row
        {...rowWithNoMarginNorGutter}
        verticalGutter="15px"
        horizontalAlign="flex-end"
      >
        <Col
          {...colWithNoMarginNorGutter}
          horizontalGutter="15px"
          horizontalAlign="flex-end"
          breakpoints={{ md: 2, lg: 2, xl: 2 }}
        >
          <Button onClick={() => onSave()} tabIndex={24}>
            SAVE
          </Button>
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 2, lg: 1, xl: 1 }}
        >
          <Button
            variantStyle="outlined"
            onClick={() => {
              closeModal(false, false);
            }}
            tabIndex={25}
          >
            CANCEL
          </Button>
        </Col>
      </Row>
    </>
  ) : (
    <>Loading...</>
  );
};

export default AddPayeeForm;
