import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import ReassignClaimsModalContent from "./ReassignClaimsModalContent";
import { useApiGet, useApiPost, usePermissions } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { ReassignClaimsModalConfigDto } from "../../../../dtos/reassign-claims-modal-config-dto";
import { ReassignClaimsDto } from "../../../../dtos/reassign-claims-dto";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";

type ReassignClaimsModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onClaimReassignment: () => void;
};

const ReassignClaimsModal: FC<ReassignClaimsModalProps> = ({
  isOpen,
  closeModal,
  onClaimReassignment,
}) => {
  const [modalConfiguration, setModalConfiguration] =
    useState<ReassignClaimsModalConfigDto | null>();
  const [formData, setFormData] = useState<ReassignClaimsDto>({
    currentAdjuster: 0,
    newAdjuster: 0,
    reassignActiveTasks: false,
  });
  const [errorDetails, setErrorDetails] = useState<any>();

  const hasPermission = usePermissions([
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ]).hasPermission;

  const currentAdjusterClaimCount =
    modalConfiguration?.assignedClaims.find(
      (x) => x.assigneeId === formData.currentAdjuster
    )?.assignedClaimsCount ?? 0;
  const reassignConfirmationMessage =
    currentAdjusterClaimCount > 0
      ? `Are you sure you want to reassign ${currentAdjusterClaimCount} claims to a new adjuster?`
      : "There aren't any claims to reassign for the current adjuster";

  const { responseGet, dispatchGet } = useApiGet<ReassignClaimsModalConfigDto>(
    "api/Claims/GetReassignClaimsConfiguration"
  );

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    `api/Claims/ReassignClaims`,
    formData
  );

  const resetForm = () => {
    setErrorDetails(null);
    setFormData({
      currentAdjuster: 0,
      newAdjuster: 0,
      reassignActiveTasks: false,
    });
  }

  useEffect(() => {
    if (isOpen) {
      dispatchGet();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setModalConfiguration(responseGet.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      onClaimReassignment();
      onCloseEvent();
    }
  }, [responsePost]);

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchPost();
  };

  const onCloseEvent = () => {
    resetForm();
    closeModal();
  };

  return (
    <Modal
      id={"reassign_claims_modal"}
      title={"Reassign Claims"}
      open={isOpen}
      size={"xs"}
      showCloseButton={true}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={() => onCloseEvent()}
      closeEvent={() => onCloseEvent()}
      saveDisabled={
        !hasPermission ||
        formData.currentAdjuster === formData.newAdjuster
      }
      saveButtonWithConfirmation
      saveButtonConfirmationText={reassignConfirmationMessage}
    >
      {modalConfiguration && (
        <ReassignClaimsModalContent
          assigneeOptions={modalConfiguration.assignees}
          newAdjusterOptions={modalConfiguration.newAdjusterOptions}
          formData={formData}
          setFormData={setFormData}
          errorDetails={errorDetails}
        />
      )}
    </Modal>
  );
};

export default ReassignClaimsModal;
