import { FC } from "react";
import { Button, Select } from "../../../TrueUI";
import { SelectOptions } from "../../../../dtos/select-options";

type ClassCodesRatesProps = {
  programsList?: SelectOptions[];
  selectedProgram?: number;
  setIsImportFormOpen?: (isOpen: boolean) => void;
  setSelectedProgram?: (program: number) => void;
};
const ClassCodesRates: FC<ClassCodesRatesProps> = ({
  setIsImportFormOpen,
  setSelectedProgram,
  selectedProgram,
  programsList,
}) => {
  return (
    <>
      <Select
        id="program-id"
        name="program-name"
        label="Program"
        labelPosition="top"
        labelFontType="BOLD_CAPTION"
        value={selectedProgram}
        options={programsList ?? []}
        onChange={(e) => {
          setSelectedProgram?.(e);
        }}
      />
      <Button onClick={() => setIsImportFormOpen?.(true)}>Import</Button>
    </>
  );
};
export default ClassCodesRates;
