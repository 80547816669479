import { atom } from "recoil";

const TypeFilterAtom = atom<string>({
  key: "mailRoomTypeFilter",
  default: "all",
});

const ShowMailRoomDateFiltersAtom = atom<boolean>({
  key: "showMailRoomDateFiltersAtom",
  default: false,
});

export { TypeFilterAtom, ShowMailRoomDateFiltersAtom };
