import { FC } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BaseTable2 } from "../../TrueUI";
import { useBaseTable } from "../../../hooks/useBaseTable";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableSwitchStatusFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSwitchStatusFilter";
import { AgencyCoverageDto } from "../../../dtos/agency-coverage-dto";
import { AgencyCoverageModalProps } from "./Coverages";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

const tableName = "coverages_table";

type CoveragesTableProps = {
  agencyId?: string;
  isNewAgency: boolean;
  coverageModalValues: AgencyCoverageModalProps | null;
  setCoverageModalValues: (value: AgencyCoverageModalProps) => void;
};

const CoveragesTable: FC<CoveragesTableProps> = ({
  agencyId,
  isNewAgency,
  coverageModalValues,
  setCoverageModalValues,
}) => {
  const getAgencyCoverageByRow = (row: any) =>
    ({
      agencyId: row.AgencyId,
      coverageType: row.CoverageType,
      status: row.AgencyCoverageStatus,
      coverageNumber: row.CoverageNumber,
      coverageLimits: row.CoverageLimits,
      coverageCarrier: row.CoverageCarrier,
      agencyCoverageId: row.AgencyCoverageId,
      coverageEffectiveDate: new Date(row.CoverageEffectiveDate),
      coverageExpirationDate: conditionHasValue(row.CoverageExpirationDate)
        ? new Date(row.CoverageExpirationDate)
        : null,
    } as AgencyCoverageDto);

  const AgencyCoverageEditModalLink = (
    actionOptions: OptionsContextActionParameterProperties<AgencyCoverageDto>
  ) => {
    return (
      <IconButton
        aria-label="edit"
        size="small"
        onClick={() =>
          setCoverageModalValues({
            ...coverageModalValues,
            isOpen: true,
            agencyCoverage: getAgencyCoverageByRow(actionOptions.row),
          })
        }
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Coverage/GetAgencyCoverages?agencyId=${agencyId}`,
    columnOptions: [
      { fieldName: "CoverageType", width: 60 },
      { fieldName: "CoverageEffectiveDate", width: 20 },
      { fieldName: "CoverageExpirationDate", width: 20 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTableSwitchStatusFilter({
          filterOptions: actionOptions,
          columnsNames: ["AgencyCoverageStatus"],
          checkedLabelText: "Show All?",
          labelFontType: "BODY",
          labelPosition: "start",
        }),
    ],
    toolbarOptions: {
      toolBarType: "compact",
      compactTitle: "Agency Coverages",
      addButtonIsDisabled: isNewAgency,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          AgencyCoverageEditModalLink(actionOptions),
      },
    },
    events: {
      addEventOverrideCallback: () =>
        setCoverageModalValues({
          ...coverageModalValues,
          agencyCoverage: {
            agencyId: parseInt(agencyId ?? "-1"),
          } as AgencyCoverageDto,
          isOpen: true,
        }),
    },
  };

  useBaseTable(tableConfiguration);

  return <BaseTable2 name={tableName} />;
};

export default CoveragesTable;
