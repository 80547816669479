import { Theme } from "../../media/themeTypes";
import { Box, styled } from "@mui/material";
import { getColorWithOpacityHexaDecimal } from "../../media/themeConstants";

export const Item = styled(Box)(() => ({
  padding: "3px",
  textAlign: "left",
}));

export const insuredLandingStyles = (theme: Theme) => {
  return {
    ".landing-right-container, .landing-left-container": {
      paddingTop: 0,
    },
    ".landing-left-container": {
      width: "30%",
      display: "flex",
      flexDirection: "column",
      rowGap: "16px",
      "& .insured-activity-container": {
        height: "100%",
        "& .activity-container": {
          margin: 0,
        },
      },
    },
    ".landing-container": {
      marginTop: 2,
      display: "flex",
      flexDirection: "column",
      rowGap: "16px",
      ".insured-grid, .policy-grid, .prior-policy-grid, .current-billing": {
        paddingTop: 0.3,
        paddingLeft: 1,
        justifyContent: "space-between",
        "& a": {
          color: theme?.PRIMARY,
          fontWeight: 400,
          textDecoration: "none",
        },
        "& .MuiSvgIcon-root": {
          color: theme?.TEXT_COLOR,
          width: "16px",
          height: "16px",
        },
        "& .insured-tax-id-container": {
          height: "fit-content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .insured-tax-id-show-button": {
            height: "fit-content",
            margin: 0,
            padding: 0,
            paddingLeft: "8px",
          },
        },
        "& .insured-info-item": {
          textOverflow: "ellipsis",
          overflow: "hidden",
          margin: 0,
          padding: 0,
          height: "27px",
          "& .true_input": {
            height: "27px !important",
          },
        },
      },
      ".insured-prior-policy-stepper": {
        width: "100%",
        p: 2,
        border: `1px solid ${theme?.BORDER_COLOR}`,
        borderRadius: 1,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTop: "none",
        padding: 0,
      },
      ".mobile-stepper": {
        background: `${getColorWithOpacityHexaDecimal(
          theme?.SECONDARY_BACKGROUND,
          50
        )} !important`,
        textAlign: "left",
        fontWeight: 500,
        height: "32px",
        paddingLeft: "0px",
        textTransform: "capitalize",
        border: "none",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        WebkitBoxPack: "initial",
        WebkitJustifyContent: "flex-start",
        "& .true_mobile_stepper_button_next": {
          left: "200px",
        },
        "& .true_mobile_stepper_buttons": {
          paddingLeft: "0px",
          paddingBottom: "7px",
          minWidth: "5px",
          "&: hover": {
            background: "none",
          },
          color: theme?.PRIMARY,
        },
        "& .true_mobile_disable_stepper_buttons": {
          padding: "0",
          visibility: "hidden",
        },
      },
      ".true_mobile_stepper_title": {
        marginTop: "-28px",
        paddingBottom: "5px",
        textAlign: "left",
        marginLeft: "35px",
        backgroundColor: "transparent",
        "& a": {
          color: theme?.PRIMARY,
          fontWeight: 400,
          textDecoration: "none",
        },
      },
      ".true_disable_mobile_stepper_title": {
        marginLeft: "6px",
        marginBottom: "0px",
        "& a": {
          cursor: "default",
        },
      },
      ".performance-container": {
        p: "0px",
        "& th, & td": {
          fontWeight: 400,
          borderLeft: "1px solid",
          borderColor: theme?.BORDER_COLOR,
          color: theme?.TEXT_COLOR,
          p: "2px 2px 2px 4px",
          "&:first-of-type": {
            borderLeft: "none",
          },
        },
        "& td": {
          borderBottom: "none",
        },
      },
      ".charts-container": {
        mt: 2,
        ".chart-box-container": {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ".chart-box": {
            background: theme?.PRIMARY_BACKGROUND,
            height: "230px",
            width: "97%",
            paddingTop: "10px",
            marginTop: "10px",
          },
        },
      },
    },
    "& .contact-list": {
      overflow: "auto",
      maxHeight: 280,
      paddingTop: 0,
      paddingBottom: "1px",
      border: `1px solid ${theme?.BORDER_COLOR}`,
      borderTop: "0",
      "& .contact-item": {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
        background: theme?.PRIMARY_BACKGROUND,
        borderBottom: `1px solid ${theme?.BORDER_COLOR}`,
        "&:last-child": {
          borderBottom: "none",
        },
        "& .right-column": {
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-start",
          alignItems: "flex-start",
          "& .email-text": {
            lineHeight: "20px",
            textDecorationLine: "underline",
            "&: hover": {
              cursor: "pointer",
            },
          },
          "& input": {
            color: `${theme?.TEXT_COLOR} !important`,
            padding: "0 !important",
            height: "fit-content",
            lineHeight: "20px",
          },
          "& .true_phone_container, .insured-contact-name": {
            lineHeight: "20px",
            height: "20px",
          },
          "& .true_input_container .true_input.standard": {
            height: "fit-content",
          },
        },
        "& .left-column": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignSelf: "flex-end",
          alignItems: "flex-end",
          paddingBottom: "10px",
          paddingRight: "10px",
          marginLeft: "10px",
          columnGap: "4px",
          rowGap: "5px",
          justifyContent: "flex-end",
        },
      },
    },
  };
};
