import { Theme } from "../../../../../../media/themeTypes";

export const modalSpecificWaiverFormStyles = () => ({
  ".base_table_toolbar": {
    display: "none",
  },
  ".base_table_custom_header": {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
});

export const modalSpecificWaiverErrorMessageStyles = (theme: Theme) => ({
  ".modal-waiver-error": { color: theme.DANGER },
});

export const modalSpecificWaiverTotalsRowStyles = (theme: Theme) => ({
  ".waiver-totals-row": { backgroundColor: theme.PRIMARY_BACKGROUND },
});

export const modalSpecificWaiverInputAddressStyles = () => ({
  ".main-row-input-address": {
    ".true_col_grid_wrapper": { paddingInline: "0px" },
    ".true_row_grid_wrapper": {
      ".true_col_grid_wrapper": {
        "&:nth-of-type(2)": { paddingInline: "10px" },
      },
    },
  },
});
