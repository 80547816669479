import { ClaimReferenceDto } from "../dtos/claim-reference-dto";
import { OrderByEnums } from "../dtos/order-by-enums";
import { SelectOptions } from "../dtos/select-options";
import { FromEnumToArray } from "./enumFunctions";

export const yesNoBlankOptions: Partial<SelectOptions>[] = [
  { displayName: "", booleanValue: null },
  { displayName: "Yes", booleanValue: true },
  { displayName: "No", booleanValue: false },
];

export function getValueByOptionName(
  key: any,
  selectOptions: SelectOptions[] | Partial<SelectOptions>[]
) {
  const result =
    selectOptions.length > 0
      ? selectOptions.filter(
          (option) => option?.displayName?.toLowerCase() === key?.toLowerCase()
        )
      : [];
  const value =
    result.length > 0
      ? (result[0].intValue ||
          result[0].stringValue ||
          result[0].decimalValue ||
          result[0].dateValue ||
          result[0].booleanValue) ??
        null
      : null;
  return value;
}

export function getDisplayNameByOptionId(
  key: number,
  selectOptions: SelectOptions[] | Partial<SelectOptions>[]
) {
  const result = selectOptions.find((option) => option.intValue === key);
  return result?.displayName ?? "";
}

export function getSelectOptionsFromClaimReferences(
  referenceFieldForValue: "value1" | "value2" | "valueN",
  optionValueField: "stringValue" | "intValue",
  data?: ClaimReferenceDto[]
): Partial<SelectOptions>[] {
  return (
    (data ?? []).map((reference) => ({
      displayName: reference?.value1,
      [optionValueField]: reference?.[referenceFieldForValue] as any,
    })) ?? []
  );
}

export const optionOrderTypes = FromEnumToArray(OrderByEnums).map(
  (enumType) => {
    return {
      displayName:
        enumType.key !== "Newest First"
          ? "Oldest to Newest"
          : "Newest to Oldest",
      stringValue: enumType.value,
    };
  }
);

export const addEmptyOption = (
  selectOptions: SelectOptions[] | Partial<SelectOptions>[],
  overrideDisplayName?: string
) => {
  const emptyOption: Partial<SelectOptions>[] = [
    { displayName: overrideDisplayName ?? "", intValue: -1 },
  ];
  return emptyOption.concat(selectOptions);
};

export const addAllOption = (
  selectOptions: SelectOptions[] | Partial<SelectOptions>[],
  upperCase: boolean = false,
  overrideAllDisplayName?: string,
  overrideAllStringValue?: string | null
) => {
  const emptyOption: Partial<SelectOptions>[] = [
    {
      displayName: overrideAllDisplayName ?? "All",
      stringValue:
        overrideAllStringValue === undefined ? "all" : overrideAllStringValue,
    },
  ];
  const emptyOptionUpperCase: Partial<SelectOptions>[] = [
    {
      displayName: overrideAllDisplayName ?? "ALL",
      stringValue:
        overrideAllStringValue === undefined ? "all" : overrideAllStringValue,
    },
  ];
  return upperCase
    ? emptyOptionUpperCase.concat(selectOptions)
    : emptyOption.concat(selectOptions);
};

export const addCustomOption = (
  selectOptions: SelectOptions[] | Partial<SelectOptions>[],
  customOption: Object
) => {
  const listOptions = [customOption];
  return listOptions.concat(selectOptions);
};

const allSelect = { displayName: "All", intValue: -1 };

export const changeNoneOrEmptyToAll = (
  stringToChange,
  selectOptions: SelectOptions[] | Partial<SelectOptions>[]
) => {
  return selectOptions?.map((option) =>
    option.displayName === stringToChange ? allSelect : option
  );
};

export const getCustomObjectListAsOptions = (
  CustomObjectList: any[],
  displayNameProperties: string[],
  intValueProperty: string
) => {
  if (
    CustomObjectList === null ||
    CustomObjectList === undefined ||
    Array.isArray(CustomObjectList) === false
  ) {
    return [];
  }

  return CustomObjectList.map((customObj) => {
    const displayName = displayNameProperties
      .map((prop) => customObj[prop])
      .join(" ");
    return {
      displayName: displayName,
      intValue: customObj[intValueProperty],
    } as SelectOptions;
  });
};
