import { FC } from "react";

type TableProperties = {
  children?: any;
};

const Table: FC<TableProperties> = ({ children }) => {
  return <div className="true_ui_table">{children}</div>;
};

export default Table;
