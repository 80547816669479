import { getFormattedValue } from "../../../../../../TrueUI/Search/SearchTextUtil";

export const payeeSearchTextKeys = ["payeeName"];
export const payeeSearchTextKeysToShowExtraDataIntoInput = [
  "address",
  "city",
  "state",
  "zip",
  "taxId",
];
export const getPaymentFormDataFromPayee = (
  paymentFormData,
  payee,
  payeeSearchTextKeys,
  payeeSearchTextKeysToShowExtraDataIntoInput
) => {
  return {
    ...paymentFormData,
    payeeId: payee?.payeeId && payee?.payeeId !== "" ? payee?.payeeId : null,
    payeeName:
      payee?.payeeName && payee?.payeeName !== "" ? payee?.payeeName : null,
    payeeNameAndAddress: getFormattedValue(
      payee,
      payeeSearchTextKeys,
      payeeSearchTextKeysToShowExtraDataIntoInput
    ),
    payeeAddress1: payee?.address,
    payeeAddress2: null,
    payeeCity: payee?.city,
    payeeStateCode: payee?.state,
    payeeZip: payee?.zip,
  };
};
