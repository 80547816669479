import { FC, useEffect, useState } from "react";
import { BasePageContainer } from "../../TrueUI";
import { useParams } from "react-router";
import ProgramInstanceWrapper from "./ProgramInstanceWrapper";
import { ProgramPage } from "../../../dtos/program-page";
import { useApiGet } from "../../../hooks";
import { useRecoilState } from "recoil";
import { AdminProgramAtom } from "./ProgramAtoms";
import { NavItemDto } from "../../../dtos/nav-item-dto";

const Program: FC = () => {
  const { programId } = useParams();
  const isNewProgram = programId ? false : true;
  const { responseGet, dispatchGet } = useApiGet<ProgramPage>(
    `api/AdminTools/Program/GetProgramPage?programId=${programId ?? 0}`
  );
  const [navItems, setNavItems] = useState<NavItemDto[]>([]);
  const [programConfiguration, setProgramConfiguration] =
    useRecoilState(AdminProgramAtom);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.responseData) {
      setNavItems(responseGet.axiosResponse?.data?.subSideNav?.navItems ?? []);
      setProgramConfiguration({
        programName: isNewProgram
          ? "New Program"
          : responseGet.axiosResponse?.data?.programName,
        configuration: responseGet.axiosResponse?.data?.configuration,
      });
    }
  }, [responseGet]);

  return (
    <BasePageContainer
      id={"insurance_container"}
      maxWidth={false}
      sxWrapper={{ height: "100%" }}
      sxBody={{ height: "100%" }}
      sxContainer={{
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}
      data={programConfiguration}
    >
      <ProgramInstanceWrapper isNewProgram={isNewProgram} navItems={navItems} />
    </BasePageContainer>
  );
};

export default Program;
