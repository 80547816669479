import { FC, useEffect, useState } from "react";
import { BaseTable2, Heading6 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useApiGet } from "../../../../hooks";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import BaseTableSearchFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import { PaymentBatchHistorySummaryDto } from "../../../../dtos/payment-batch-history-summary-dto";
import ModalAddBatchPreview from "./AddBatch/ModalAddBatchPreview";

const tableName = "payment_batch_history";

const PaymentBatchHistory: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [paymentBatchInformation, setPaymentBatchInformation] =
    useState<PaymentBatchHistorySummaryDto>();

  const { responseGet, dispatchGet } = useApiGet<PaymentBatchHistorySummaryDto>(
    `api/PaymentBatch/GetPaymentBatchHistorySummary`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setPaymentBatchInformation(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  const config: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: paymentBatchInformation?.paymentBatchTable?.tableData
        ?.columns as any,
      data: paymentBatchInformation?.paymentBatchTable?.tableData?.data as any,
    },
    tableType: "standard",
    toolbarOptions: {
      showImportButton: false,
      addButtonText: "Add Batch",
      showEditButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "processes-by-filter",
          filterOptions: actionOptions,
          optionsOverride:
            paymentBatchInformation?.pageOptions?.processedByOptions ?? [],
          labelText: "Processed By:",
          columnsNames: ["ProcessedById"],
          defaultValue: "all",
        }),
      (actionOptions) =>
        BaseTableSearchFilter(
          actionOptions,
          ["ReferenceNumbersToSearch", "ReferenceNumbers"],
          ["check no."]
        ),
    ],
    events: {
      addEventOverrideCallback: () => {
        setOpenModal(true);
      },
    },
  };

  useBaseTable(config);

  return (
    <div
      id={"payment_batch_history_wrapper"}
      style={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <div id={"payment_batch_history_title_container"}>
        <Heading6 textAlign="start" display="block">
          PAYMENT BATCH HISTORY
        </Heading6>
      </div>
      {paymentBatchInformation && (
        <div
          id={"payment_batch_history_table_container"}
          style={{
            display: "flex",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <BaseTable2 name={tableName} />
        </div>
      )}
      <ModalAddBatchPreview setOpenModal={setOpenModal} openModal={openModal} />
    </div>
  );
};

export default PaymentBatchHistory;
