import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../dtos/select-options";
import { SystemNumberPolicyGeneratorDto } from "../../dtos/system-number-policy-generator-dto";
import { SystemNumberDto } from "../../dtos/system-number-dto";
import { useApiGet } from "../../hooks";
import { Col, Row, Select, Button, Input } from "../TrueUI";
import { Typography } from "@mui/material";
import color from "../../media/colors";
import Autocomplete from "../TrueUI/Autocomplete/Autocomplete";

const systemNumberOptions: Partial<SelectOptions>[] = [
  { displayName: "Insured Number", stringValue: "InsuredNumber" },
  { displayName: "Policy Number", stringValue: "PolicyNumber" },
  { displayName: "Claim Number", stringValue: "ClaimNumber" },
];

interface ParametersValues {
  claimOrPolicyId?: number;
  policyPeriodId?: number;
  insuredId?: number | null;
}

const SystemNumberTryOut: FC = () => {
  const [errorMessages, setErrorMessages] = useState<{
    idError?: string;
    insuredError?: string;
  } | null>();
  const [systemNumberSelected, setSystemNumberSelected] =
    useState<string>("PolicyNumber");
  const [systemNumberGenerated, setSystemNumberGenerated] = useState<string>();
  const [parametersValues, setParametersValues] = useState<ParametersValues>();
  const [policyOptions, setPolicyOptions] =
    useState<Partial<SelectOptions>[]>();

  const { responseGet: responseGetOptions, dispatchGet: dispatchGetOptions } =
    useApiGet<SystemNumberPolicyGeneratorDto>(
      "api/SystemNumber/GetOptionsForPolicyGeneration"
    );
  const {
    responseGet: responseGetPolicyNumber,
    dispatchGet: dispatchGetPolicyNumber,
  } = useApiGet<SystemNumberDto>(
    `api/SystemNumber/GeneratePolicyNumber?policyPeriodId=${parametersValues?.policyPeriodId}&insuredId=${parametersValues?.insuredId}&policyId=${parametersValues?.claimOrPolicyId}`
  );
  const {
    responseGet: responseGetClaimNumber,
    dispatchGet: dispatchGetClaimNumber,
  } = useApiGet<SystemNumberDto>(
    `api/SystemNumber/GenerateClaimNumber?insuredId=${parametersValues?.insuredId}&claimId=${parametersValues?.claimOrPolicyId}`
  );
  const {
    responseGet: responseGetInsuredNumber,
    dispatchGet: dispatchGetInsuredNumber,
  } = useApiGet<SystemNumberDto>("api/SystemNumber/GenerateInsuredNumber");

  useEffect(() => {
    dispatchGetOptions();
  }, []);

  useEffect(() => {
    if (responseGetOptions?.requestInstanceSuccessful) {
      const optionsResult = (
        responseGetOptions?.axiosResponse
          ?.data as SystemNumberPolicyGeneratorDto
      )?.policyPeriodIds.map((id) => {
        return { displayName: id.toString(), intValue: id };
      });
      setPolicyOptions(optionsResult);
      console.log({ optionsResult });
    }
  }, [responseGetOptions]);

  useEffect(() => {
    if (responseGetPolicyNumber?.requestInstanceSuccessful) {
      const numberGenerated = (
        responseGetPolicyNumber?.axiosResponse?.data as SystemNumberDto
      )?.numberGenerated;

      if (numberGenerated !== undefined)
        setSystemNumberGenerated(numberGenerated);
    }
  }, [responseGetPolicyNumber]);

  useEffect(() => {
    if (responseGetClaimNumber?.requestInstanceSuccessful) {
      const numberGenerated = (
        responseGetClaimNumber?.axiosResponse?.data as SystemNumberDto
      )?.numberGenerated;

      if (numberGenerated !== undefined)
        setSystemNumberGenerated(numberGenerated);
    }
  }, [responseGetClaimNumber]);

  useEffect(() => {
    if (responseGetInsuredNumber?.requestInstanceSuccessful) {
      const numberGenerated = (
        responseGetInsuredNumber?.axiosResponse?.data as SystemNumberDto
      )?.numberGenerated;

      if (numberGenerated !== undefined)
        setSystemNumberGenerated(numberGenerated);
    }
  }, [responseGetInsuredNumber]);

  useEffect(() => {
    if (policyOptions !== undefined) {
      setParametersValues({
        ...parametersValues,
        policyPeriodId: policyOptions[0].intValue ?? -1,
      });
    }
  }, [policyOptions]);

  useEffect(() => {
    setErrorMessages(null);
  }, [parametersValues, systemNumberSelected]);

  const generateNumber = () => {
    if (systemNumberSelected !== "InsuredNumber") {
      if (
        parametersValues?.insuredId === undefined ||
        parametersValues?.insuredId === null
      )
        setErrorMessages({
          ...errorMessages,
          insuredError: "Please search and pick an insured.",
        });
      if (
        parametersValues?.claimOrPolicyId === undefined ||
        parametersValues?.claimOrPolicyId === null
      )
        setErrorMessages({
          ...errorMessages,
          idError: "Please type in a numeric ID.",
        });
      if (
        parametersValues?.insuredId !== undefined &&
        parametersValues?.insuredId !== null &&
        parametersValues.claimOrPolicyId !== undefined &&
        parametersValues.claimOrPolicyId !== null
      ) {
        if (systemNumberSelected === "PolicyNumber") dispatchGetPolicyNumber();
        if (systemNumberSelected === "ClaimNumber") dispatchGetClaimNumber();
      }
    } else {
      dispatchGetInsuredNumber();
    }
  };

  return responseGetOptions?.axiosResponse?.data ? (
    <Row rowDirection="column" verticalMargin="0px" horizontalMargin="0px">
      <Row
        verticalMargin="0px"
        horizontalMargin="0px"
        horizontalAlign="flex-start"
      >
        <Col
          breakpoints={
            systemNumberSelected !== "PolicyNumber"
              ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
              : undefined
          }
        >
          <Typography textAlign={"center"}>Number Type</Typography>
        </Col>
        {systemNumberSelected !== "InsuredNumber" && (
          <>
            <Col
              breakpoints={
                systemNumberSelected === "ClaimNumber"
                  ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
                  : undefined
              }
            >
              <Typography
                textAlign={"center"}
                color={errorMessages?.idError ? color.COLOR_RED_500 : ""}
              >
                {errorMessages?.idError ? errorMessages.idError : "ID"}
              </Typography>
            </Col>
            <Col
              breakpoints={
                systemNumberSelected === "ClaimNumber"
                  ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
                  : undefined
              }
            >
              <Typography
                textAlign={"center"}
                color={errorMessages?.insuredError ? color.COLOR_RED_500 : ""}
              >
                {errorMessages?.insuredError
                  ? errorMessages.insuredError
                  : "Insured"}
              </Typography>
            </Col>
          </>
        )}
        {systemNumberSelected === "PolicyNumber" && (
          <Col>
            <Typography textAlign={"center"}>Policy Period ID</Typography>
          </Col>
        )}
      </Row>
      <Row
        verticalMargin="0px"
        horizontalMargin="0px"
        verticalAlign="flex-end"
        horizontalAlign="flex-start"
      >
        <Col
          breakpoints={
            systemNumberSelected !== "PolicyNumber"
              ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
              : undefined
          }
        >
          <Select
            id="select-number-options"
            name="select-number-options"
            options={systemNumberOptions}
            value={systemNumberSelected}
            onChange={(select) => setSystemNumberSelected(select)}
          />
        </Col>
        {systemNumberSelected !== "InsuredNumber" && (
          <>
            <Col
              breakpoints={
                systemNumberSelected === "ClaimNumber"
                  ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
                  : undefined
              }
            >
              <Input
                id="id-claimOrPolicyId-input"
                name="name-claimOrPolicyId-input"
                type="number"
                value={parametersValues?.claimOrPolicyId}
                onChangeRawValue={(value) =>
                  setParametersValues({
                    ...parametersValues,
                    claimOrPolicyId: value ? parseInt(value) : undefined,
                  })
                }
              />
            </Col>
            <Col
              breakpoints={
                systemNumberSelected === "ClaimNumber"
                  ? { xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }
                  : undefined
              }
            >
              <Autocomplete
                getURL={`api/Insured/GetInsuredListByName?insuredName={0}`}
                onChangeRawValue={(selectedValue) =>
                  setParametersValues({
                    ...parametersValues,
                    insuredId: selectedValue.intValue,
                  })
                }
              />
            </Col>
          </>
        )}
        {systemNumberSelected === "PolicyNumber" && (
          <Col>
            <Select
              id="select-policy"
              name="select-policy"
              options={policyOptions ?? []}
              value={parametersValues?.policyPeriodId}
              onChange={(select) =>
                setParametersValues({
                  ...parametersValues,
                  policyPeriodId: select,
                })
              }
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => {
              generateNumber();
            }}
          >
            Generate Number
          </Button>
        </Col>
      </Row>
      <Row verticalMargin="0px" horizontalMargin="0px">
        <Col>
          <Typography
            sx={{
              m: 1,
              flexGrow: 1,
              textAlign: "center",
              color: color.COLOR_GRAY_500,
              fontSize: "25px",
            }}
          >
            {`GENERATED NUMBER IS:  ${
              systemNumberGenerated ? systemNumberGenerated : ""
            }`}
          </Typography>
        </Col>
      </Row>
    </Row>
  ) : (
    <div>LOADING...</div>
  );
};

export default SystemNumberTryOut;
