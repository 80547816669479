import { Component, FC, Fragment, useEffect, useState } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import BaseTableCollapseRow from "../BaseTableRowOptions/BaseTableCollapseRow";
import BaseTableMultiRow from "../BaseTableRowOptions/BaseTableMultiRow";
import { InternalTableColumns } from "../InternalTableColumns";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
// import { DELETED_COLUMN_FIELD_NAME } from "../TableConstants";
import {
  // getColumnIndexByColumnName,
  getErrorsByRow,
  getExternalColumnsOnly,
  getExternalDataOnly,
  getRowByRowKey,
  getSafeRowKey,
  hydrateDataSingle,
} from "../tableFunctions";
import { getStylingRow } from "../TableStyles";
import { TableInstanceType2 } from "./TableProperties";
import { TableRow } from "../BaseTableStructure";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";

type BaseTable2RowManagerProps = {
  uiid: string;
  cellManagerInstance: Component<any>;
  style: any;
};

const BaseTable2RowManager: FC<BaseTable2RowManagerProps> = ({
  uiid,
  cellManagerInstance,
  style,
}) => {
  const { rowKey, row } = cellManagerInstance.props;

  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const rowClickEventObject = (
    syntheticClickEvent: any
  ): {
    rowKey: string;
    hydratedData: any;
    syntheticEvent: any;
    isDeselected: boolean;
  } => {
    const mostRecentRowData = getRowByRowKey(rowKey, tableInstance.data);
    const externalColumns = getExternalColumnsOnly(tableInstance.columns);
    const externalData = getExternalDataOnly(
      tableInstance.columns,
      mostRecentRowData
    );
    const hydratedData = hydrateDataSingle(externalColumns, externalData);

    setTableInstance({
      ...tableInstance,
      selectedRow: tableInstance?.selectedRow === rowKey ? null : rowKey,
      rowClickTriggered: true,
    });
    return {
      rowKey: rowKey,
      hydratedData: hydratedData,
      syntheticEvent: syntheticClickEvent,
      isDeselected: tableInstance?.selectedRow === rowKey,
    };
  };

  useEffect(() => {
    if (tableInstance?.rowClickTriggered === true) {
      setTableInstance({
        ...tableInstance,
        rowClickTriggered: false,
      });
    }
  }, [tableInstance?.rowClickTriggered]);

  const [isRowHidden, setIsRowHidden] = useState(false);

  useEffect(() => {
    const hasKey = tableInstance.sortedAndFilteredData.find(
      (r) => getSafeRowKey(r) === rowKey
    );
    if (hasKey) {
      setIsRowHidden(false);
    } else {
      setIsRowHidden(true);
    }
  }, [tableInstance.sortedAndFilteredData]);

  const tableRowSelector = () => {
    const errorsByRow = getErrorsByRow(
      getSafeRowKey(row),
      tableInstance?.validationErrors
    );
    switch (tableInstance?.tableType) {
      case "multi":
        const headerTitle = row[0] ?? "NO TITLE FOUND";
        return (
          <BaseTableMultiRow
            key={`row_multi_${rowKey}`}
            uiid={uiid}
            rowKey={rowKey}
            row={row}
            headerTitle={headerTitle} // TODO - make this better
            renderedCells={cellManagerInstance as any}
          />
        );
      case "collapse":
        return (
          <BaseTableCollapseRow
            key={`row_collapse_${rowKey}`}
            uiid={uiid}
            rowKey={rowKey}
            row={row}
            renderedCells={cellManagerInstance as any}
          />
        );
      default:
        const localStyle = getStylingRow(
          tableInstance?.columns ?? [],
          row,
          tableInstance?.advancedOptions?.tableStyle?.rowStyle
        );

        const recentRowData = getRowByRowKey(rowKey, tableInstance.data);
        const deletedRow = {
          isDeleted:
            recentRowData[
              InternalTableColumns._isRowDeletedColumn._columnIndex
            ] === "true",
          deletedType:
            tableInstance?._accessors?._deleteRow?.deleteType ?? "none",
        };
        const isSelectedRow = tableInstance?.selectedRow === rowKey;
        const withFontStyleClassName = !isEmptyValue(
          tableInstance?.advancedOptions?.tableStyle?.rowStyle?.fontStyle
        )
          ? "with_font_style"
          : "";
        const className = isSelectedRow
          ? `selected ${withFontStyleClassName}`
          : withFontStyleClassName;
        return !isRowHidden ? (
          <Fragment key={rowKey}>
            <TableRow
              rowKey={rowKey}
              deletedRow={deletedRow}
              onClick={(e) =>
                tableInstance.isEdit === false &&
                tableInstance?.events?.onRowClick?.(rowClickEventObject(e))
              }
              className={className}
              style={{ ...localStyle, ...style }}
              errorsByRow={errorsByRow}
            >
              {cellManagerInstance}
            </TableRow>
          </Fragment>
        ) : null;
    }
  };
  return <>{tableRowSelector()}</>;
};

export default BaseTable2RowManager;
