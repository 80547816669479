import { FC, useEffect, useState } from "react";
import { Button, Input, Modal, Switch } from "../../../../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../../../../hooks";
import { ReserveWorksheetPage } from "../../../../../../../dtos/reserve-worksheet-page";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import { Stack } from "@mui/material";
import AddReserveWorksheetTable from "./AddReserveWorksheetTable";
import CancelButton from "../../../../../../TrueUI/Buttons/CancelButton";
import { SaveReserveWorksheetPage } from "../../../../../../../dtos/save-reserve-worksheet-page";
import { ReserveTypeWithPaymentsDto } from "../../../../../../../dtos/reserve-type-with-payments-dto";
import { GlobalInsuredAtomFamily } from "../../../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../../../utilities/queryStringsHash";
import { getReserveStatus } from "./ReserveWorksheetTableTableUtils";
import { ReservesModalsConfigProps } from "../ReservesGrid";

type AddReserveWorksheetModalProps = {
  claimId?: number;
  reservesModalsConfig: ReservesModalsConfigProps;
  setReservesModalsConfig: (
    reservesModalsConfig: ReservesModalsConfigProps
  ) => void;
  tabKey?: string;
};
const AddReserveWorksheetModal: FC<AddReserveWorksheetModalProps> = ({
  claimId,
  reservesModalsConfig,
  setReservesModalsConfig,
  tabKey,
}) => {
  const { responseGet, dispatchGet } = useApiGet<ReserveWorksheetPage>(
    `api/ReserveWorksheet/GetReserveWorksheetPage?claimId=${claimId}`
  );

  const [reserveWorksheetPage, setReserveWorksheetPage] =
    useState<ReserveWorksheetPage | null>(null);

  const [saveReserveWorksheetInfo, setSaveReserveWorksheetInfo] =
    useState<SaveReserveWorksheetPage>({
      claimId: reserveWorksheetPage?.claimId ?? 0,
      comments: "",
      addNote: false,
      reserveTypes: [],
      approved: true,
    });

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/ReserveWorksheet/SaveReserveWorksheetPage",
    saveReserveWorksheetInfo
  );

  const [errorDetails, setErrorDetails] = useState<any>(null);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [processingAndSaveFlags, setProcessingAndSaveFlags] = useState({
    isProcessingReserves: false,
    isTryingToSave: false,
  });

  const updateInformationToSave = (updatedInfo: ReserveTypeWithPaymentsDto) => {
    setProcessingAndSaveFlags({
      ...processingAndSaveFlags,
      isProcessingReserves: true,
    });

    const existingReserves = saveReserveWorksheetInfo.reserveTypes.filter(
      (reserveType) => reserveType.reserveTypeId !== updatedInfo.reserveTypeId
    );
    const updatedReserveTypes = [...existingReserves, updatedInfo];

    const totalReserves = updatedReserveTypes
      .map((reserveType) => reserveType.reserveTotal)
      .reduce((sum, value) => sum + value);

    setSaveReserveWorksheetInfo({
      ...saveReserveWorksheetInfo,
      reserveTypes: updatedReserveTypes,
      approved: getReserveStatus(
        totalReserves,
        reserveWorksheetPage?.reserveAmountLimit,
        reserveWorksheetPage?.totalReserveAmountLimit,
        reserveWorksheetPage?.totalClaimReserves
      ),
    });
  };

  useEffect(() => {
    if (saveReserveWorksheetInfo?.reserveTypes.length > 0) {
      setProcessingAndSaveFlags({
        ...processingAndSaveFlags,
        isProcessingReserves: false,
      });
    }
  }, [saveReserveWorksheetInfo?.reserveTypes]);

  useEffect(() => {
    setSaveReserveWorksheetInfo({
      ...saveReserveWorksheetInfo,
      reserveTypes: [],
      comments: "",
      addNote: false,
    });
    setErrorDetails(null);
    reservesModalsConfig.isWorksheetModalOpen && dispatchGet();
  }, [reservesModalsConfig.isWorksheetModalOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setReserveWorksheetPage(responseGet.responseData ?? null);
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setProcessingAndSaveFlags({
        isProcessingReserves: false,
        isTryingToSave: false,
      });
      if (validatorErrorResponse === null) {
        setComponentTriggers([
          "activityLogComponent",
          "claimBanner",
          "financialTableClaimLandingPageComponent",
        ]);
        setReservesModalsConfig({
          ...reservesModalsConfig,
          refresh: true,
          isWorksheetModalOpen: false,
        });
      }
    }
  }, [responsePost]);

  useEffect(() => {
    setErrorDetails(validatorErrorResponse?.errorDetails);
  }, [validatorErrorResponse]);

  useEffect(() => {
    setSaveReserveWorksheetInfo({
      ...saveReserveWorksheetInfo,
      claimId: reserveWorksheetPage?.claimId ?? 0,
    });
  }, [reserveWorksheetPage]);

  useEffect(() => {
    if (
      processingAndSaveFlags.isProcessingReserves === false &&
      processingAndSaveFlags.isTryingToSave === true
    ) {
      dispatchPost();
    }
  }, [processingAndSaveFlags]);

  return (
    <Modal
      id="add-reserve-worksheet-modal"
      title="Add Reserve Worksheet"
      open={reservesModalsConfig.isWorksheetModalOpen}
      closeEvent={(value) =>
        setReservesModalsConfig({
          ...reservesModalsConfig,
          isWorksheetModalOpen: value,
        })
      }
      cancelEvent={() =>
        setReservesModalsConfig({
          ...reservesModalsConfig,
          isWorksheetModalOpen: false,
        })
      }
      cancelButtonWithConfirmation
    >
      <Stack>
        <div
          style={{
            padding: "0 5px 10px 5px",
            display: "flex",
            columnGap: "30px",
          }}
        >
          <Input
            id="reserve_worksheet_comments"
            name="reserve_worksheet_comments"
            label="Comments"
            multiline
            rows={3}
            variant="standard"
            inputWidth="100%"
            value={saveReserveWorksheetInfo.comments}
            onChangeRawValue={(v) =>
              setSaveReserveWorksheetInfo({
                ...saveReserveWorksheetInfo,
                comments: v,
              })
            }
            errorMessage={errorDetails?.comments}
          />
          <Switch
            id="reserve_worksheet_add_note"
            name="reserve_worksheet_add_note"
            label="Add Note"
            labelFontType="BOLD_CAPTION"
            control="checkbox"
            labelPlacement="top"
            isChecked={saveReserveWorksheetInfo.addNote}
            onChangeIsChecked={(e) =>
              setSaveReserveWorksheetInfo({
                ...saveReserveWorksheetInfo,
                addNote: e,
              })
            }
          />
        </div>
        {reserveWorksheetPage?.reserveTypeTableList?.map((reserveTable) => (
          <AddReserveWorksheetTable
            key={`reserve_table_${reserveTable.reserveTypeId}`}
            tableInfo={reserveTable}
            reserveLimit={reserveWorksheetPage?.reserveAmountLimit}
            totalReserveLimit={reserveWorksheetPage?.totalReserveAmountLimit}
            totalClaimReserves={reserveWorksheetPage?.totalClaimReserves}
            setUpdate={(info) => updateInformationToSave(info)}
            editMode
          />
        ))}
        <div
          style={{
            paddingBottom: "15px",
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "10px",
          }}
        >
          <Button
            onClick={() => {
              setProcessingAndSaveFlags({
                ...processingAndSaveFlags,
                isTryingToSave: true,
              });
            }}
          >
            ADD RESERVE
          </Button>
          <CancelButton
            onClick={() =>
              setReservesModalsConfig({
                ...reservesModalsConfig,
                isWorksheetModalOpen: false,
              })
            }
            withConfirmation
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default AddReserveWorksheetModal;
