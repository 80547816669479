import { FC, useState } from "react";
import { AchFormProps } from "./ModalAchPaymentUtil";
import { Button, Col, InputDate, Row, Select } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import BillingAccountModal from "../../../Accounts/BillingAccountModal";
import {
  BillingAccountProps,
  defaultBillingAccount,
} from "../../../Accounts/BillingAccountsUtil";

const ModalAchPaymentContent: FC<AchFormProps> = ({
  insuredId,
  configuration,
  achFormData,
  setAchFormData,
  setRefreshConfiguration,
  errorMessages,
}) => {
  const [billingAccount, setBillingAccount] = useState<BillingAccountProps>(
    defaultBillingAccount
  );

  const displayAddAccountForm = () => {
    setBillingAccount({
      isOpen: true,
      action: "add",
      rowData: null,
      modalData: {
        insuredId: insuredId,
        insuredAccountId: 0,
      },
    });
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Select
            {...selectConfiguration(
              "id-insured-account",
              "insured-account",
              "Select Account to Draw From",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={configuration?.insuredAccountList ?? []}
            value={achFormData?.insuredAccountId}
            onChange={(value) => {
              setAchFormData({ ...achFormData, insuredAccountId: value });
            }}
            errorMessage={errorMessages?.insuredAccountId}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-end">
          <Button
            variantStyle={"outlined"}
            onClick={displayAddAccountForm}
            sx={{ marginTop: "12px" }}
          >
            {"Add Account"}
          </Button>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-payment-date"
            name="payment-date"
            label="Payment Date"
            labelFontType="BOLD_CAPTION"
            value={achFormData?.paymentDate}
            onChangeRawValue={(dateValue) =>
              setAchFormData?.({
                ...achFormData,
                paymentDate: dateValue,
              })
            }
            errorMessage={errorMessages?.paymentDate}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-date-received"
            name="name-date-received"
            label="Date Received"
            labelFontType="BOLD_CAPTION"
            value={achFormData?.dateReceived}
            onChangeRawValue={(dateValue) =>
              setAchFormData?.({
                ...achFormData,
                dateReceived: dateValue,
              })
            }
            errorMessage={errorMessages?.dateReceived}
          />{" "}
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} verticalGutter="25px">
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <BillingAccountModal
        billingAccount={billingAccount}
        setBillingAccount={setBillingAccount}
        setRefresh={setRefreshConfiguration}
      />
    </>
  );
};

export default ModalAchPaymentContent;
