import { FC, useEffect, useState } from "react";
import { ListItemButton, ListItemText, Box, ListItemIcon } from "@mui/material";
import { NavItemDto } from "../../../dtos/nav-item-dto";
import { NavSubItemDto } from "../../../dtos/nav-sub-item-dto";
import {
  addQueryStrings,
  removeQueryStrings,
} from "../../../utilities/URLUtilities_OBSOLETE";
import { globalOptions } from "../../../GlobalAtoms";
import { useRecoilValue } from "recoil";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./subSideNav.module.css";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type SubSideNavProps = {
  navItems?: NavItemDto[];
  parentSelected?: number;
  childSelected?: number;
  parentQueryString?: string;
  childQueryString?: string;
  onParentItemClick?: (parent?: NavItemDto) => void;
  onChildItemClick?: (parent: NavItemDto, child: NavSubItemDto) => void;
};

const SubSideNav: FC<SubSideNavProps> = ({
  navItems,
  parentSelected,
  childSelected,
  parentQueryString,
  childQueryString,
  onParentItemClick,
  onChildItemClick,
}) => {
  const [parentItemIndex, setParentItemIndex] = useState(parentSelected);

  const userOptions = useRecoilValue(globalOptions);
  const userPermissions: PermissionsEnums[] = userOptions.permissions;

  const reviewIfHasPermission = (permissionsIds: number[]) => {
    if (userPermissions.includes(PermissionsEnums.TRUE_ADMIN)) return true;
    if (permissionsIds && permissionsIds.length > 0) {
      const availablePermissions = permissionsIds.filter((permission) =>
        userPermissions.includes(permission)
      );
      return availablePermissions.length > 0;
    }
    return true;
  };

  const _onParentItemClick = (index?: number) => {
    if (parentQueryString && childQueryString) {
      if (index !== undefined) {
        addQueryStrings([
          {
            nameOfHash: parentQueryString,
            valueOfHash: index,
          },
        ]);
        removeQueryStrings([childQueryString]);
      } else {
        removeQueryStrings([parentQueryString, childQueryString]);
      }
    }
  };

  const _onChildItemClick = (parentIndex: number, childIndex: number) => {
    if (parentQueryString && childQueryString) {
      addQueryStrings([
        {
          nameOfHash: parentQueryString,
          valueOfHash: parentIndex,
        },
        {
          nameOfHash: childQueryString,
          valueOfHash: childIndex,
        },
      ]);
    }
  };

  useEffect(() => {
    setParentItemIndex(parentSelected);
    if (parentQueryString && childQueryString) {
      addQueryStrings([
        {
          nameOfHash: parentQueryString,
          valueOfHash: parentSelected ?? "0",
        },
        {
          nameOfHash: childQueryString,
          valueOfHash: childSelected ?? "0",
        },
      ]);
    }
  }, [parentSelected, childSelected]);

  return (
    <Box className={"true_sub_side_nav"}>
      {navItems?.map(
        (navItem) =>
          reviewIfHasPermission(navItem?.permissionsIds) && (
            <div key={navItem.name + navItem.index}>
              <Box
                className={`true_parent_container ${
                  navItem.index === parentItemIndex
                    ? "true_subside_nav_parent_selected"
                    : ""
                }`}
              >
                <ListItemButton
                  true-element={`true-element-subside-nav-parent-${navItem.name}`}
                  onClick={() => {
                    const parentItemValue =
                      navItem.index !== parentItemIndex
                        ? navItem.index
                        : undefined;
                    _onParentItemClick?.(parentItemValue);
                    onParentItemClick?.(navItem);
                    setParentItemIndex(parentItemValue);
                  }}
                >
                  <ListItemText primary={navItem.name} />
                  {navItem.hasOwnProperty("subList") &&
                    navItem.subList?.length > 0 && (
                      <ListItemIcon>
                        {navItem.index === parentItemIndex ? (
                          <ArrowDropDownIcon className="true_subside_nav_arrow" />
                        ) : (
                          <ArrowRightIcon className="true_subside_nav_arrow" />
                        )}
                      </ListItemIcon>
                    )}
                </ListItemButton>
              </Box>
              <Box
                className={`true_subside_nav_children_container ${
                  navItem.index === parentItemIndex
                    ? "true_subside_nav_parent_selected"
                    : ""
                }`}
              >
                {navItem.subList?.map(
                  (subNavItem) =>
                    reviewIfHasPermission(subNavItem?.permissionsIds) && (
                      <Box
                        className={`true_subside_nav_child_item ${
                          subNavItem.index === childSelected &&
                          navItem.index === parentSelected
                            ? "true_subside_nav_child_selected"
                            : ""
                        }`}
                        key={subNavItem.name + subNavItem.index}
                      >
                        <ListItemButton
                          true-element={`true-element-subside-nav-child-${subNavItem.name}`}
                          onClick={() => {
                            _onChildItemClick?.(
                              navItem.index,
                              subNavItem.index
                            );
                            onChildItemClick?.(navItem, subNavItem);
                          }}
                        >
                          <ListItemText
                            primary={subNavItem.name}
                            className={"true_subside_nav_child_text"}
                          />
                        </ListItemButton>
                      </Box>
                    )
                )}
              </Box>
            </div>
          )
      )}
    </Box>
  );
};

export default SubSideNav;
