import { useRef } from "react";
// import "./App.css";

import Layout from "./Layout";
import AppRoutes from "./AppRoutes";

import { PDFContainerProvider } from "./providers/PDFContainerProvider";
import AppLocationListener from "./AppLocationListener";

const App = () => {
  const pdfExportContainer = useRef(null);

  return (
    <>
      <AppLocationListener />
      <div id={"pdf_container"} ref={pdfExportContainer}></div>
      <PDFContainerProvider.Provider value={pdfExportContainer}>
        <div className="App">
          <Layout>{AppRoutes}</Layout>
        </div>
      </PDFContainerProvider.Provider>
    </>
  );
};

export default App;
