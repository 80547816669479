import { Col, FontBold, Row } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { useRecoilValue } from "recoil";
// import { reservesApprovalAtoms } from "./ReservesApprovalAtoms";
import { currencyFormat } from "../../../../../utilities/currencyFunctions";
import { paymentsApprovalAtoms } from "../Payments/PaymentsApprovalAtoms";

const ReservesApprovalFooter = () => {
  const totalReserves = useRecoilValue(paymentsApprovalAtoms);
  
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={2} rowHeight="50px">
      <Col breakpoints={{ md: 6, lg: 6, xl: 6 }} horizontalAlign={"flex-start"}>
        Total Reserves
      </Col>
      <Col
        horizontalMargin="20px"
        breakpoints={{ md: 6, lg: 6, xl: 6 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(totalReserves, false, 2)}</FontBold>
      </Col>
    </Row>
  );
};

export default ReservesApprovalFooter;
