import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isProgramSelected } from "./GlobalAtoms";
import { isStillValid } from "./utilities/jwtFunctions";
import App from "./App";
import { Landing } from "./components/Landing";

const AppTokenListener: FC<any> = () => {
  const [completedInitialProcess, setCompletedInitialProcess] = useState(false);
  const [_isProgramSelected, _setIsProgramSelected] =
    useRecoilState(isProgramSelected);

  const listenForTokenRemoval = (storageEvent: StorageEvent) => {
    if (storageEvent.key === "true_id" && storageEvent.newValue === null) {
      _setIsProgramSelected(false);
    } else {
      const isValid = isStillValid(storageEvent.newValue ?? "");

      if (isValid) {
        _setIsProgramSelected(true);
      } else {
        _setIsProgramSelected(false);
      }
    }
  };

  useEffect(() => {
    const tokenValue = localStorage.getItem("true_id");
    // initializeSignalRConnection();
    if (tokenValue !== null) {
      _setIsProgramSelected(true);
    }
    window.addEventListener("storage", (e) => listenForTokenRemoval(e));
    setCompletedInitialProcess(true);
  }, []);

  return completedInitialProcess ? (
    _isProgramSelected ? (
      <App />
    ) : (
      <Landing />
    )
  ) : null;
};

export default AppTokenListener;
