import { BaseTableAlternativeDisplayValues } from "../../../dtos/base-table-alternative-display-values";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getYearAsLiteralInt,
  isDateAfterDate,
} from "../../../utilities/dateFunctions";
import {
  dateDataTypeEnums,
  numberDataTypeEnums,
} from "../../../utilities/staticArraysByEnumTypes";
import {
  FILTER_BY_RENDER_ROW_KEY_WORD,
  SEARCH_FILTER,
} from "../../TrueUI/Tables/TableConstants";
import {
  DROPDOWN_FILTER,
  MULTI_DROPDOWN_FILTER,
  SPECIAL_SORT_ORDER,
} from "../BaseGridConstants";
import {
  COLUMN_EXTENSION_COMPARE_IDENTIFIER,
  COLUMN_EXTENSION_COMPARE_IDENTIFIER_NULLABLE_ARRAY,
} from "../BaseGridEnums";
import { GridData, GridDataRow } from "../BaseGridProperties";
import {
  dateTypes,
  numericTypes,
  otherTypes,
} from "../ColumnHeaderFilters/BaseGridColumnFilterConstantsAndTypes";
import { BaseGridFilterParameters } from "../Hooks/useBaseGridFilters";
import { ColumnFilterExtensionFilterGateFor } from "./baseGridColumnFilterFunctions";
import { getColumnByColumnIndex } from "./OLD_baseGridFunctions";

const sortLinks = (
  valueA: string,
  valueB: string,
  alternateDisplayValues: BaseTableAlternativeDisplayValues[]
) => {
  const optionA =
    alternateDisplayValues
      .find((x) => x.guid === valueA)
      ?.displayValuesOrFieldNames[0]?.trim()
      .toLocaleLowerCase() ?? "";
  const optionB =
    alternateDisplayValues
      .find((x) => x.guid === valueB)
      ?.displayValuesOrFieldNames[0]?.trim()
      .toLocaleLowerCase() ?? "";
  return sortStrings(optionA, optionB);
};

const getNumericABS = (val: string) => {
  if (val.match(/[()]/g)) {
    return -Math.abs(parseFloat(val.replace(/[()]/g, "").replace(/,/g, "")));
  } else {
    return parseFloat(val.replace(/,/g, ""));
  }
};

const sortNumbers = (valueA: string, valueB: string) => {
  const parsedValueA = getNumericABS(valueA);
  const parsedValueB = getNumericABS(valueB);
  return parsedValueA - parsedValueB;
};

const sortStrings = (valueA: string, valueB: string) => {
  if (valueA === null || valueA === "") return 1;
  if (valueB === null || valueB === "") return -1;
  if (valueB < valueA) return 1;
  if (valueB > valueA) return -1;
  return 0;
};

const sortDates = (valueA: string, valueB: string) => {
  if (valueA === null || valueA === "") {
    return 1;
  }
  if (valueB === null || valueB === "") {
    return -1;
  }

  if (getYearAsLiteralInt(valueA) < 1900) {
    return -1;
  }

  if (getYearAsLiteralInt(valueB) < 1900) {
    return 1;
  }

  if (isDateAfterDate(valueB, valueA)) {
    return -1;
  }
  if (isDateAfterDate(valueA, valueB)) {
    return 1;
  }
  return 0;
};

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: number,
  columns: BaseTableColumn[]
) {
  const orderByType =
    columns[orderBy]?.defaultValueType ?? DataTypeEnums.STRING;
  const isLink = columns[orderBy]?.alternateDisplayValues ? true : false;
  const valueA = a[orderBy] as string;
  const valueB = b[orderBy] as string;

  if (numberDataTypeEnums.includes(orderByType)) {
    return sortNumbers(valueA, valueB);
  }
  if (dateDataTypeEnums.includes(orderByType)) {
    return sortDates(valueA, valueB);
  }
  if (isLink) {
    return sortLinks(
      valueA,
      valueB,
      columns[orderBy]?.alternateDisplayValues ?? []
    );
  }
  return sortStrings(
    valueA?.trim().toLocaleLowerCase(),
    valueB?.trim().toLocaleLowerCase()
  );
}

export const getComparator = (
  order: any,
  orderBy: any,
  columns: BaseTableColumn[]
) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, columns)
    : (a, b) => -descendingComparator(a, b, orderBy, columns);
};

export const sortOrderBy = (
  data: string[][],
  order: string,
  orderBy: number,
  columns: BaseTableColumn[]
) => {
  const result = data.slice().sort(getComparator(order, orderBy, columns));
  return result;
};

const getMultiSelectFiltersAsString = (filterValue: any) => {
  // TODO - I really hate this function. fix it or replace it
  return filterValue?.reduce((acc, item) => {
    if (item.stringValue !== undefined) {
      if (acc) {
        return (acc += ",");
      }
      return (acc += item.stringValue);
    }
    if (item.intValue !== undefined) {
      if (acc) {
        return (acc += ",");
      }
      return (acc += item.intValue.toString());
    }
    return acc;
  }, "");
};

const getSafeTopLevelFilter = (
  filterParameters: BaseGridFilterParameters[]
): BaseGridFilterParameters[] => {
  return filterParameters
    .filter(
      (filter) =>
        filter.ignore === false &&
        (filter.filterCompareIdentifier === undefined ||
          filter.filterCompareIdentifier === null) &&
        (filter.filterGroupId === undefined || filter.filterGroupId === null)
    )
    .map((filter) => {
      const filterValue = Array.isArray(filter.filterValue)
        ? filter?.filterValue ?? []
        : filter?.filterValue?.toString() ?? "";

      return {
        ...filter,
        filterValue: filterValue,
      };
    });
};

const getSafeColumnLevelFilters = (
  filterParameters: BaseGridFilterParameters[]
): BaseGridFilterParameters[] => {
  return filterParameters
    .filter(
      (filter) =>
        filter.ignore === false &&
        filter.filterCompareIdentifier !== undefined &&
        filter.filterCompareIdentifier !== null &&
        filter.filterGroupId !== undefined &&
        filter.filterGroupId !== null
    )
    .map((filter) => {
      const filterValue = Array.isArray(filter.filterValue)
        ? filter?.filterValue ?? []
        : filter?.filterValue?.toString() ?? "";

      return {
        ...filter,
        filterValue: filterValue,
      };
    });
};

enum BASE_GRID_FILTER_STATUS {
  PASS = 1,
  FAIL = 2,
}

const SearchByMulitipleColumnIndexes = (
  row: GridDataRow,
  filter: BaseGridFilterParameters
): BASE_GRID_FILTER_STATUS => {
  if ((filter?.columnIndexes?.length ?? 0) > 1) {
    const hasValueInColumnByIndexes = (filter?.columnIndexes ?? []).map(
      (columnIndex) => {
        const val = row[columnIndex ?? -1];

        if (
          val !== undefined &&
          val !== null &&
          val
            .toLowerCase()
            .trim()
            .search(`${""}${filter.filterValue.toLowerCase().trim()}`) !== -1
        ) {
          return BASE_GRID_FILTER_STATUS.PASS;
        }

        return BASE_GRID_FILTER_STATUS.FAIL;
      }
    );

    const result = hasValueInColumnByIndexes.some(
      (valueResult) => valueResult === BASE_GRID_FILTER_STATUS.PASS
    )
      ? BASE_GRID_FILTER_STATUS.PASS
      : BASE_GRID_FILTER_STATUS.FAIL;

    return result;
  }
  return BASE_GRID_FILTER_STATUS.PASS;
};

const SingleColumnCompare = (
  row: GridDataRow,
  filter: BaseGridFilterParameters
) => {
  if (
    (filter?.columnIndexes?.length ?? 0) === 1 &&
    (filter?.columnIndexes ?? [])[0] !== -1
  ) {
    // if columnIndex > -1, search only that row cell value
    const val = row[(filter?.columnIndexes ?? [])[0] ?? -1];

    // Search Filter
    // This should not be the same filter that the user type, this is a %LIKE%
    if (filter.filterName === SEARCH_FILTER) {
      if (
        conditionHasValue(val) &&
        val
          .toLowerCase()
          .trim()
          .search(`${""}${filter?.filterValue?.toLowerCase().trim()}`) !== -1
      ) {
        return BASE_GRID_FILTER_STATUS.PASS;
      }
    }
    if (
      filter.filterName === MULTI_DROPDOWN_FILTER &&
      filter?.filterValue !== "string"
    ) {
      const stringFilterValues = getMultiSelectFiltersAsString(
        filter?.filterValue
      );
      if (
        filter?.filterValue?.includes("all") === true ||
        filter?.filterValue?.length === 0
      ) {
        return BASE_GRID_FILTER_STATUS.PASS;
      }
      if (
        conditionHasValue(val?.trim()) &&
        val?.trim() !== "" &&
        stringFilterValues?.trim()?.includes(val?.toLowerCase()?.trim()) ===
          true
      ) {
        return BASE_GRID_FILTER_STATUS.PASS;
      }
    }
    // Dropdown Filter
    // This option should be the same value that the filter has
    if (
      conditionHasValue(val) &&
      val.toLowerCase().trim() === filter?.filterValue?.toLowerCase().trim()
    ) {
      return BASE_GRID_FILTER_STATUS.PASS;
    }
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const SearchEntireRow = (
  row: GridDataRow,
  filter: BaseGridFilterParameters
) => {
  if (
    (filter?.columnIndexes?.length ?? 0) === 0 ||
    ((filter?.columnIndexes?.length ?? 0) === 1 &&
      (filter?.columnIndexes ?? [])[0] === -1)
  ) {
    // if columnIndex == -1, search all row cell values
    return row
      .map((value) => {
        if (value !== undefined && value !== null) {
          const result = value
            .toLowerCase()
            .search(`${""}${filter.filterValue.toLowerCase().trim()}`);
          return result === -1
            ? BASE_GRID_FILTER_STATUS.FAIL
            : BASE_GRID_FILTER_STATUS.PASS;
        }
        return BASE_GRID_FILTER_STATUS.FAIL;
      })
      .some((rowMatch) => rowMatch === BASE_GRID_FILTER_STATUS.PASS)
      ? BASE_GRID_FILTER_STATUS.PASS
      : BASE_GRID_FILTER_STATUS.FAIL;
  }
  return BASE_GRID_FILTER_STATUS.PASS;
};

const getSafeRowValueByFilterIndex = (
  row: GridDataRow,
  filter: BaseGridFilterParameters,
  column: BaseTableColumn | null
): string | number | null => {
  const value = row[filter?.columnIndexes?.[0] ?? 0] ?? null;

  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([otherTypes], column)
  ) {
    return value?.trim().toLowerCase() ?? null;
  }

  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([numericTypes], column)
  ) {
    return parseInt(value ?? "0");
  }

  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([dateTypes], column)
  ) {
    const valueDate = new Date(value);
    return getYearAsLiteralInt(valueDate);
  }

  return null;
};

const getSafeFilterValueByDataType = (
  filterValue: string,
  column: BaseTableColumn | null
): string | number | null => {
  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([otherTypes], column)
  ) {
    return filterValue ?? "";
  }

  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([numericTypes], column)
  ) {
    return parseInt(filterValue ?? "0");
  }

  if (
    column !== null &&
    ColumnFilterExtensionFilterGateFor([dateTypes], column)
  ) {
    const filterValueDate = new Date(filterValue);
    return getYearAsLiteralInt(filterValueDate);
  }

  return null;
};

// Column Extension Filters

const removeEmptyValueResults = (value: string | number | Date) => {
  if (value === null || value === "") {
    return BASE_GRID_FILTER_STATUS.FAIL;
  }
  return BASE_GRID_FILTER_STATUS.PASS;
};

const betweenColumnCompare = (
  filter: BaseGridFilterParameters,
  column: BaseTableColumn | null,
  value: string | number | Date
) => {
  const startFilterValue = getSafeFilterValueByDataType(
    filter.filterValue?.[0] ?? null,
    column
  );

  const endFilterValue = getSafeFilterValueByDataType(
    filter.filterValue?.[1] ?? null,
    column
  );

  if (
    value !== "" &&
    value !== null &&
    startFilterValue !== null &&
    endFilterValue !== null &&
    value >= startFilterValue &&
    value <= endFilterValue
  ) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const containsColumnCompare = (
  filter: BaseGridFilterParameters,
  value: string
) => {
  if (
    value !== "" &&
    value.toLowerCase().includes(filter.filterValue.toLowerCase())
  ) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const equalsColumnCompare = (
  filter: BaseGridFilterParameters,
  value: string | number
) => {
  if (filter.filterValue.toLowerCase() === value) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const greaterThanColumnCompare = (
  filter: BaseGridFilterParameters,
  column: BaseTableColumn | null,
  value: string | number
) => {
  const filterValue = getSafeFilterValueByDataType(
    filter.filterValue ?? null,
    column
  );
  if (
    value !== null &&
    value !== "" &&
    filterValue !== null &&
    value >= filterValue
  ) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const lessThanColumnCompare = (
  filter: BaseGridFilterParameters,
  column: BaseTableColumn | null,
  value: string | number
) => {
  const filterValue = getSafeFilterValueByDataType(
    filter.filterValue ?? null,
    column
  );
  if (
    value !== null &&
    value !== "" &&
    filterValue !== null &&
    value <= filterValue
  ) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const beginsWithColumnCompare = (
  filter: BaseGridFilterParameters,
  value: string
) => {
  if (value !== "" && value.startsWith(filter.filterValue.toLowerCase())) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const endsWidthColumnCompare = (
  filter: BaseGridFilterParameters,
  value: string
) => {
  if (value !== "" && value.endsWith(filter.filterValue.toLowerCase())) {
    return BASE_GRID_FILTER_STATUS.PASS;
  }
  return BASE_GRID_FILTER_STATUS.FAIL;
};

const filterGateIn = (
  filterIdentifier: COLUMN_EXTENSION_COMPARE_IDENTIFIER[] | null,
  filter: BaseGridFilterParameters
): boolean => {
  if (
    filterIdentifier !== null &&
    filter?.filterCompareIdentifier !== undefined &&
    filter?.filterCompareIdentifier !== null &&
    filterIdentifier.includes(filter?.filterCompareIdentifier ?? null)
  ) {
    return true;
  }
  return false;
};

const filterGateNotIn = (filter: BaseGridFilterParameters): boolean => {
  if (
    filter?.filterCompareIdentifier === undefined ||
    filter?.filterCompareIdentifier === null ||
    !COLUMN_EXTENSION_COMPARE_IDENTIFIER_NULLABLE_ARRAY.includes(
      filter?.filterCompareIdentifier ?? null
    )
  ) {
    return true;
  }
  return false;
};

export const filterResultSetBy_v5 = (
  data: GridData,
  filterParameters: BaseGridFilterParameters[],
  columns: BaseTableColumn[]
): string[][] => {
  const topLevelFilters = getSafeTopLevelFilter(filterParameters);
  const columnLevelFilters = getSafeColumnLevelFilters(filterParameters);

  const resultingRowData = data.filter((row) => {
    // Top Level Filters
    const topLevelFilterResults = topLevelFilters.map((filter) => {
      // If no column names are found in the filter, we automatically pass the row
      if (filter.columnNames?.length === 0) return true;
      // If the filterValue property contains the "all" keyword, we automatically pass the row
      if (filter.filterValue === FILTER_BY_RENDER_ROW_KEY_WORD) return true;
      // If the filterValue property contains the "special_sort_order" keyword, we automatically pass the row
      if (filter.filterName === SPECIAL_SORT_ORDER) return true;

      const filterComparisons: BASE_GRID_FILTER_STATUS[] = [
        filterGateNotIn(filter) && filter.filterName === SEARCH_FILTER
          ? SearchByMulitipleColumnIndexes(row, filter)
          : BASE_GRID_FILTER_STATUS.PASS,
        filterGateNotIn(filter)
          ? SearchEntireRow(row, filter)
          : BASE_GRID_FILTER_STATUS.PASS,
        filterGateNotIn(filter) && filter.filterName === DROPDOWN_FILTER
          ? SingleColumnCompare(row, filter)
          : BASE_GRID_FILTER_STATUS.PASS,
      ];

      return (
        filterComparisons.every((v) => v === BASE_GRID_FILTER_STATUS.PASS) ??
        false
      );
    });

    // Column Level Filters
    const columnLevelFilterResults = columnLevelFilters.map((filter) => {
      const filterComparisons: BASE_GRID_FILTER_STATUS[] = [];
      const targetFilterColumn = getColumnByColumnIndex(
        filter?.columnIndexes?.[0] ?? -1,
        columns
      );
      const rowValue = getSafeRowValueByFilterIndex(
        row,
        filter,
        targetFilterColumn
      );
      if (rowValue === null) {
        return BASE_GRID_FILTER_STATUS.PASS;
      }

      // MAINTENANCE NOTE: When adding new filters for the column filters, order does not matter.
      filterComparisons.push(
        // Sort of special (Lee wanted it). If a column (ext filter) value has an empty result on any cell in that column, we want to exclude the whole row from the result.
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_REMOVE_EMPTY_RESULT],
          filter
        )
          ? removeEmptyValueResults(rowValue)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN],
          filter
        )
          ? betweenColumnCompare(filter, targetFilterColumn, rowValue)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_CONTAINS],
          filter
        )
          ? containsColumnCompare(filter, rowValue as string)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_EQUALS],
          filter
        )
          ? equalsColumnCompare(filter, rowValue)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_GREATER_THAN],
          filter
        )
          ? greaterThanColumnCompare(
              filter,
              targetFilterColumn,
              rowValue as string
            )
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_LESS_THAN],
          filter
        )
          ? lessThanColumnCompare(filter, targetFilterColumn, rowValue)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BEGINS_WITH],
          filter
        )
          ? beginsWithColumnCompare(filter, rowValue as string)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      filterComparisons.push(
        filterGateIn(
          [COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_ENDS_WITH],
          filter
        )
          ? endsWidthColumnCompare(filter, rowValue as string)
          : BASE_GRID_FILTER_STATUS.PASS
      );
      return (
        filterComparisons.every((v) => v === BASE_GRID_FILTER_STATUS.PASS) ??
        false
      );
    });

    const finalFilterResult = [
      ...topLevelFilterResults,
      ...columnLevelFilterResults,
    ];

    if (finalFilterResult?.every((v) => v === true)) {
      return row;
    }

    return null;
  });

  return resultingRowData;
};
