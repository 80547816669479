import { FC, Key, useEffect, useState } from "react";
import { CustomFieldControlSizeEnum } from "../../../../../../dtos/custom-field-control-size-enum";
import { CustomFieldControlTypeEnum } from "../../../../../../dtos/custom-field-control-type-enum";
import { CustomFieldDataTypeEnum } from "../../../../../../dtos/custom-field-data-type-enum";
import { CustomFieldDto } from "../../../../../../dtos/custom-field-dto";
import { SelectOptions } from "../../../../../../dtos/select-options";
import {
  StringToDate,
  FormattingDate,
} from "../../../../../../utilities/dateFunctions";
import {
  InputDate,
  Col,
  Row,
  Select,
  MultiSelect,
  Input,
  Switch,
} from "../../../../../TrueUI";
import { CustomFieldsContainerDto } from "../../../../../../dtos/custom-fields-container-dto";

type CustomFieldsProps = {
  claimCustomFieldsData: CustomFieldsContainerDto;
  setClaimCustomFieldsData?: (
    value: CustomFieldsContainerDto | null | undefined
  ) => void;
  readOnly: boolean;
  errorDetails?: any;
};

type ColumnComponents = {
  firstColumnComponents: CustomFieldDto[];
  secondColumnComponents: CustomFieldDto[];
  thirdColumnComponents: CustomFieldDto[];
};
const componentsByColumnDefaultValues: ColumnComponents = {
  firstColumnComponents: [],
  secondColumnComponents: [],
  thirdColumnComponents: [],
};

const ClaimsCustomFields: FC<CustomFieldsProps> = ({
  claimCustomFieldsData,
  setClaimCustomFieldsData,
  readOnly,
  errorDetails,
}) => {
  const [componentsByColumns, setComponentsByColumns] =
    useState<ColumnComponents>(componentsByColumnDefaultValues);

  useEffect(() => {
    if (claimCustomFieldsData?.customFields?.length > 0) {
      setPositionCustomFields();
    }
  }, [claimCustomFieldsData]);

  const setPositionCustomFields = () => {
    const customFieldsList = claimCustomFieldsData?.customFields;
    const firstColumnComponents = customFieldsList.filter(
      (customField) =>
        customField.controlSize === CustomFieldControlSizeEnum.LARGE
    );
    const secondColumnComponents = customFieldsList.filter(
      (customField) =>
        customField.controlSize === CustomFieldControlSizeEnum.MEDIUM
    );
    const thirdColumnComponents = customFieldsList.filter(
      (customField) =>
        customField.controlSize === CustomFieldControlSizeEnum.SMALL
    );

    setComponentsByColumns({
      firstColumnComponents,
      secondColumnComponents,
      thirdColumnComponents,
    });
  };

  const updatedFieldValue = (
    field: CustomFieldDto,
    fieldKeyFromValuesObj: string,
    newValue: string | number | null
  ) => {
    const customFieldsList = claimCustomFieldsData?.customFields;

    const customFieldListUpdated = customFieldsList.map(
      (customFieldDefinitionItem) => {
        if (
          customFieldDefinitionItem.customFieldDefinitionID ===
          field.customFieldDefinitionID
        ) {
          return {
            ...customFieldDefinitionItem,
            values: {
              ...customFieldDefinitionItem?.values,
              [fieldKeyFromValuesObj]: newValue,
            },
          };
        }
        return customFieldDefinitionItem;
      }
    );

    setClaimCustomFieldsData?.({
      ...claimCustomFieldsData,
      customFields: customFieldListUpdated,
    });
  };

  const renderFieldComponentByType = (field: CustomFieldDto) => {
    if (field.dataType === CustomFieldDataTypeEnum.TEXT) {
      if (field.controlType === CustomFieldControlTypeEnum.DROPDOWN) {
        return (
          <Select
            labelFontType="CAPTION"
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            firstOptionAsDefault={false}
            readOnly={readOnly}
            options={field.listSource ?? []}
            value={field.values.fieldValueString}
            onChange={(value) =>
              updatedFieldValue(field, "fieldValueString", value)
            }
          />
        );
      } else if (field.controlType === CustomFieldControlTypeEnum.MULTISELECT) {
        const options = field.listSource ?? [];
        const values = field.values.fieldValueString?.split("|") ?? [];
        const selectedValues = options.filter((x) =>
          values.includes(x.stringValue ?? "")
        );
        return (
          <MultiSelect
            labelFontType="CAPTION"
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            options={options}
            values={selectedValues}
            readOnly={readOnly}
            onChange={(values: Partial<SelectOptions>[]) => {
              const stringValues = values.flatMap((x) => x.stringValue ?? "");
              updatedFieldValue(
                field,
                "fieldValueString",
                stringValues.join("|")
              );
            }}
          />
        );
      } else {
        return (
          <Input
            labelFontType="CAPTION"
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            value={field.values.fieldValueString ?? ""}
            readOnly={readOnly}
            className={readOnly ? "true_information_disabled" : ""}
            maxLength={field.dataSize}
            onChangeRawValue={(value) => {
              updatedFieldValue(field, "fieldValueString", value);
            }}
            errorMessage={errorDetails?.[field.errorField]}
          />
        );
      }
    } else if (field.dataType === CustomFieldDataTypeEnum.NUMBER) {
      if (field.controlType === CustomFieldControlTypeEnum.DROPDOWN) {
        return (
          <Select
            labelFontType="CAPTION"
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            readOnly={readOnly}
            firstOptionAsDefault={false}
            options={field.listSource ?? []}
            value={field?.values?.fieldValueNumber?.toString()}
            onChange={(value) =>
              updatedFieldValue(field, "fieldValueNumber", value)
            }
          />
        );
      } else {
        return (
          <Input
            labelFontType={"CAPTION"}
            name={field.fieldLabel}
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            value={field.values.fieldValueNumber ?? ""}
            readOnly={readOnly}
            onChangeRawValue={(value) =>
              updatedFieldValue(field, "fieldValueNumber", value)
            }
            type={"number"}
            allowNegatives={false}
            maxLength={field.dataSize}
            thousandSeparator={false}
            errorMessage={errorDetails?.[field.errorField]}
          />
        );
      }
    } else if (field.dataType === CustomFieldDataTypeEnum.DECIMAL) {
      return (
        <Input
          labelFontType={"CAPTION"}
          name={field.fieldLabel}
          id={`id-${field.fieldLabel}`}
          label={field.fieldLabel}
          value={field.values.fieldValueNumber ?? ""}
          readOnly={readOnly}
          onChangeRawValue={(value) =>
            updatedFieldValue(field, "fieldValueNumber", value)
          }
          type={"currency"}
          allowNegatives={false}
          maxLength={field.dataSize + field.dataPrecision + 1}
          decimalScale={field.dataPrecision}
          thousandSeparator={false}
          prefix={""}
          errorMessage={errorDetails?.[field.errorField]}
        />
      );
    } else if (field.dataType === CustomFieldDataTypeEnum.BOOLEAN) {
      return (
        <Switch
          labelFontType={"CAPTION"}
          id={`id-${field.fieldLabel}`}
          label={field.fieldLabel}
          control="checkbox"
          name={field.fieldLabel}
          isChecked={field.values.fieldValueNumber === 1}
          readOnly={readOnly}
          labelPlacement={"top"}
          onChangeIsChecked={(value) =>
            updatedFieldValue(field, "fieldValueNumber", value ? 1 : 0)
          }
        />
      );
    } else if (field.dataType === CustomFieldDataTypeEnum.DATE) {
      return (
        <InputDate
          labelFontType={"CAPTION"}
          name={field.fieldLabel}
          id={`id-${field.fieldLabel}`}
          label={field.fieldLabel}
          value={StringToDate(field.values.fieldValueString)}
          readOnly={readOnly}
          onChangeRawValue={(value) =>
            updatedFieldValue(
              field,
              "fieldValueString",
              FormattingDate(value, "YYYY-MM-DD")
            )
          }
          errorMessage={errorDetails?.[field.errorField]}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderColumnComponents = (
    columnComponents: CustomFieldDto[],
    numberOfColumn: number
  ) => {
    const percentage =
      numberOfColumn === 1 ? "50%" : numberOfColumn === 2 ? "33%" : "17%";
    return (
      <div style={{ width: percentage }}>
        {columnComponents.map((value: CustomFieldDto, index: Key) => (
          <Col key={index} horizontalAlign="flex-start">
            {renderFieldComponentByType(value)}{" "}
          </Col>
        ))}
      </div>
    );
  };

  return (
    <>
      <Row
        verticalMargin="0px"
        horizontalMargin="0px"
        verticalGutter="10px"
        horizontalGutter="0px"
        verticalAlign="flex-start"
      >
        {renderColumnComponents(componentsByColumns.firstColumnComponents, 1)}
        {renderColumnComponents(componentsByColumns.secondColumnComponents, 2)}
        {renderColumnComponents(componentsByColumns.thirdColumnComponents, 3)}
      </Row>
    </>
  );
};

export default ClaimsCustomFields;
