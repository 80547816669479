import { BaseTable } from "../../../../dtos/base-table";
import {
  getColumnIndexByColumnName,
  getDataIndexByColumnName,
  getDataIndexByColumnNameWithoutExternal,
} from "../../../TrueUI/Tables/tableFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import {
  updateBindInstructionsInPolicyQuote,
  updatePolicyQuote,
} from "../updatesPolicyQuoteFunctions";
import {
  customRound,
  getNumberFromCurrencyFormat,
  unFormatLocalString,
  unFormatNegativeLocalString,
} from "../../../../utilities/stringFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { PolicyBindInstructionsBlob } from "../../../../dtos/policy-bind-instructions-blob";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PolicyPaymentScheduleMultiTableChild } from "../../../../dtos/policy-payment-schedule-multi-table-child";
import { BaseTableChildrenData } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { SystemNumberDto } from "../../../../dtos/system-number-dto";
import { MultiBaseTableGroupColumn } from "../../../../dtos/multi-base-table-group-column";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { DataTypeEnums } from "../../../../dtos/data-type-enums";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import {
  FormattingDate,
  GettingDateWithoutTime,
} from "../../../../utilities/dateFunctions";
import { MultiBaseTable } from "../../../../dtos/multi-base-table";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyPaymentSchedulePage } from "../../../../dtos/policy-payment-schedule-page";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import { InvoiceTypeDto } from "../../../../dtos/invoice-type-dto";
import { PolicyPayPlanBlob } from "../../../../dtos/policy-pay-plan-blob";
import { PolicyInvoiceBlob } from "../../../../dtos/policy-invoice-blob";
import { StatusEnums } from "../../../../dtos/status-enums";
import { PolicyInvoiceDetailBlob } from "../../../../dtos/policy-invoice-detail-blob";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";
import { PolicyInvoiceSubBlob } from "../../../../dtos/policy-invoice-sub-blob";
export const getUpdatedPayrollReportTable = (
  payrollReportTable: BaseTable,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentPayrollReportTable =
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.payrollReportTable;

  if (
    currentPayrollReportTable !== null &&
    currentPayrollReportTable !== undefined &&
    currentPayrollReportTable.data.length > 0 &&
    currentPayrollReportTable.columns.length > 0
  ) {
    const payrollReportIdIndex = getDataIndexByColumnNameWithoutExternal(
      payrollReportTable.columns,
      "PayrollReportId"
    );
    const currentPayrollReportIdIndex = getDataIndexByColumnName(
      currentPayrollReportTable.columns,
      "PayrollReportId"
    );
    const updateRatesIndex = getDataIndexByColumnNameWithoutExternal(
      payrollReportTable.columns,
      "UpdateRates"
    );
    const currentUpdateRatesIndex = getDataIndexByColumnName(
      currentPayrollReportTable.columns,
      "UpdateRates"
    );

    const updatedPayrollReportTableData = payrollReportTable.data.map((row) => {
      const foundRow = currentPayrollReportTable.data.find((foundRow) => {
        if (
          row.length > payrollReportIdIndex &&
          foundRow.length > currentPayrollReportIdIndex &&
          foundRow.length > currentUpdateRatesIndex
        ) {
          return (
            foundRow[currentPayrollReportIdIndex] ===
              row[payrollReportIdIndex] &&
            foundRow[currentUpdateRatesIndex] === "true"
          );
        }
        return false;
      });

      if (foundRow !== undefined) {
        const rowUpdated = row.map((columnValue, index) => {
          if (index === updateRatesIndex) return "true";
          else return columnValue;
        });

        return rowUpdated;
      }

      return row;
    });

    const updatePayrollReportTable = {
      ...payrollReportTable,
      data: updatedPayrollReportTableData,
    } as BaseTable;

    const newAtomValue = updateBindInstructionsInPolicyQuote(
      atomValue,
      "payrollReportTable",
      updatePayrollReportTable
    );

    return newAtomValue;
  } else {
    const newAtomValue = updateBindInstructionsInPolicyQuote(
      atomValue,
      "payrollReportTable",
      payrollReportTable
    );

    return newAtomValue;
  }
};

export const getScheduleMultiTableUpdatedByEndorsementManualChange = (
  id: string,
  endorsementValue: number | null,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const bindInstructions =
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions;
  const endorsedScheduleMultiTable =
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.scheduleMultiTable;
  const endorsedScheduleMultiTableChildren =
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
      ?.multiTableRowChildren;
  if (
    conditionHasValue(endorsementValue) &&
    conditionHasValue(bindInstructions) &&
    conditionHasValue(endorsedScheduleMultiTable) &&
    conditionHasValue(endorsedScheduleMultiTableChildren)
  ) {
    // Get groups updated with new endorsement value for endorsement column.
    const groupsUpdated = endorsedScheduleMultiTable.groups.map((group) => {
      if (group.groupUiidKey === id) {
        const headerUpdated = group.header.map((headerData) => {
          if (headerData.name === "Endorsement") {
            return { ...headerData, value: endorsementValue.toString() };
          } else if (headerData.name === "Total") {
            const invoiceAmount = group.header.find(
              (headerData) => headerData.name === "InvoiceAmount"
            )?.value;
            const invoiceAmountParsed =
              invoiceAmount !== ""
                ? unFormatLocalString(invoiceAmount ?? "0")
                : 0;
            const newTotalValue = (
              invoiceAmountParsed + (endorsementValue ?? 0)
            ).toLocaleString();
            return { ...headerData, value: newTotalValue.toLocaleString() };
          } else {
            return headerData;
          }
        });
        return { ...group, header: headerUpdated };
      } else {
        return group;
      }
    });

    // Get number of child rows related to the group that is being updated.
    const childTableListForGroup = endorsedScheduleMultiTableChildren.find(
      (childTable) => childTable.id.toString() === id
    );
    const numberOfChildRows = childTableListForGroup?.rowData.length ?? 0;

    // Calculate new default values for endorsement columns in child rows.
    const defaultValueForEndorsement =
      numberOfChildRows > 0
        ? customRound((endorsementValue / numberOfChildRows).toString(), 0)
        : 0;
    const defaultValueForEndorsementFirstInvoice =
      endorsementValue -
      defaultValueForEndorsement * numberOfChildRows +
      defaultValueForEndorsement;

    // Index for child rows.
    const locationNameIndex = 0,
      invoiceOldTotalAmountIndex = 1,
      invoiceDetailFeesIndex = 4,
      invoiceIdIndex = 5,
      invoiceSubIdIndex = 6,
      invoiceDetailIdIndex = 7;

    // Update child table data setting the new endorsement ant total values per child.
    const childTableDataUpdated = endorsedScheduleMultiTableChildren.map(
      (childTable) => {
        if (childTable.id.toString() === id) {
          const childRowDataUpdated = childTable.rowData.map((row, index) => {
            const valueForEndorsementByPosition =
              index === 0
                ? defaultValueForEndorsementFirstInvoice
                : defaultValueForEndorsement;

            const oldTotalAmountParsed =
              row[invoiceOldTotalAmountIndex] !== ""
                ? unFormatLocalString(row[invoiceOldTotalAmountIndex])
                : 0;
            const newTotalAmount =
              oldTotalAmountParsed + valueForEndorsementByPosition;

            return [
              row[locationNameIndex],
              row[invoiceOldTotalAmountIndex],
              valueForEndorsementByPosition !== 0
                ? valueForEndorsementByPosition.toLocaleString()
                : "",
              newTotalAmount.toLocaleString(),
              row[invoiceDetailFeesIndex],
              row[invoiceIdIndex],
              row[invoiceSubIdIndex],
              row[invoiceDetailIdIndex],
            ];
          });
          return { ...childTable, rowData: childRowDataUpdated };
        } else {
          return childTable;
        }
      }
    );

    const bindInstructionsUpdated = {
      ...bindInstructions,
      scheduleMultiTable: {
        ...bindInstructions.scheduleMultiTable,
        groups: groupsUpdated,
      },
      multiTableRowChildren: childTableDataUpdated,
    } as PolicyBindInstructionsBlob;

    const newAtomValue = updatePolicyQuote(
      atomValue,
      "bindInstructions",
      bindInstructionsUpdated
    );

    return newAtomValue;
  }
  return atomValue;
};

export const getChildrenData = (
  columns: BaseTableColumn[],
  childrenData: PolicyPaymentScheduleMultiTableChild[]
): BaseTableChildrenData[] =>
  childrenData.map(
    (children) =>
      ({
        groupId: children.id,
        columns: columns,
        data: children.rowData.map((row) => [
          "",
          "",
          row[0],
          row[1],
          row[2],
          row[3],
        ]),
      } as BaseTableChildrenData)
  ) ?? [];

const getNewHeaderDataForInvoiceAdded = (
  newDueDate: Date,
  systemNumber: SystemNumberDto
) =>
  [
    {
      name: "DueDate",
      index: 0,
      dataType: DataTypeEnums.DATETIME,
      inputType: BaseTableInputType.DATE_PICKER,
      value: FormattingDate(newDueDate),
    },
    {
      name: "InvoiceNumber",
      index: 1,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: systemNumber.numberGenerated,
    },
    {
      name: "Description",
      index: 2,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: "",
    },
    {
      name: "InvoiceAmount",
      index: 3,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: "",
    },
    {
      name: "Endorsement",
      index: 4,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: "",
    },
    {
      name: "Total",
      index: 5,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: "",
    },
  ] as BaseTableMetaData[];

const getNewMetadataForInvoiceAdded = (
  groupId: number,
  systemNumber: SystemNumberDto
) =>
  [
    {
      name: "Id",
      index: 0,
      dataType: DataTypeEnums.INT,
      inputType: BaseTableInputType.TEXT,
      value: groupId.toString(),
    },
    {
      name: "InvoiceId",
      index: 1,
      dataType: DataTypeEnums.INT,
      inputType: BaseTableInputType.TEXT,
      value: "",
    },
    {
      name: "InvoiceNumber",
      index: 2,
      dataType: DataTypeEnums.STRING,
      inputType: BaseTableInputType.TEXT,
      value: systemNumber.numberGenerated.toString(),
    },
  ] as BaseTableMetaData[];

export const addNewInvoiceByBillByLocationToAtom = (
  systemNumber: SystemNumberDto,
  newDueDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const bindInstructions =
    atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions;
  if (bindInstructions?.billByLocation === true) {
    // Add new invoice to multi table.
    const scheduleMultiTable = bindInstructions.scheduleMultiTable;
    const currentGroups = scheduleMultiTable?.groups ?? [];
    const newInvoiceGroup: MultiBaseTableGroupColumn = {
      groupUiidKey: currentGroups.length.toString(),
      columns: scheduleMultiTable?.columns ?? [],
      header: getNewHeaderDataForInvoiceAdded(newDueDate, systemNumber),
      metaData: getNewMetadataForInvoiceAdded(
        currentGroups.length,
        systemNumber
      ),
    };

    const groupListUpdated = [...currentGroups, newInvoiceGroup];

    const scheduleMultiTableUpdated = {
      ...bindInstructions.scheduleMultiTable,
      groups: groupListUpdated,
    } as PolicyBindInstructionsBlob;

    const newAtomValue = updateBindInstructionsInPolicyQuote(
      atomValue,
      "scheduleMultiTable",
      scheduleMultiTableUpdated
    );

    return newAtomValue;
  } else {
    // Add new invoice to regular table.
    const scheduleTable = bindInstructions?.scheduleTable;
    const currentData = scheduleTable?.data ?? [];

    const newRow = [
      "",
      "",
      "",
      "",
      FormattingDate(newDueDate),
      systemNumber.numberGenerated,
      "",
      "",
      "",
    ];

    const dataUpdated = [...currentData, newRow];

    const scheduleTableUpdated = {
      ...bindInstructions?.scheduleTable,
      data: dataUpdated,
    } as PolicyBindInstructionsBlob;

    const newAtomValue = updateBindInstructionsInPolicyQuote(
      atomValue,
      "scheduleTable",
      scheduleTableUpdated
    );

    return newAtomValue;
  }
};

export const calculateTotalsForScheduleMultiTable = (
  scheduleMultiTable: MultiBaseTable
) =>
  scheduleMultiTable.groups.reduce(
    (acc, group) => {
      const invoiceAmount = group.header.find(
        (headerData) => headerData.name === "InvoiceAmount"
      )?.value;
      const invoiceAmountParsed = unFormatLocalString(
        invoiceAmount !== "" ? invoiceAmount ?? "0" : "0"
      );

      const endorsement = group.header.find(
        (headerData) => headerData.name === "Endorsement"
      )?.value;
      const endorsementParsed = unFormatLocalString(
        endorsement !== "" ? endorsement ?? "0" : "0"
      );

      const totalPaymentDue = group.header.find(
        (headerData) => headerData.name === "Total"
      )?.value;
      const totalPaymentDueParsed = unFormatLocalString(
        totalPaymentDue !== "" ? totalPaymentDue ?? "0" : "0"
      );

      return {
        totalInvoiceAmount: acc.totalInvoiceAmount + invoiceAmountParsed,
        totalEndorsement: acc.totalEndorsement + endorsementParsed,
        totalPaymentDue: acc.totalPaymentDue + totalPaymentDueParsed,
      };
    },
    { totalInvoiceAmount: 0, totalEndorsement: 0, totalPaymentDue: 0 }
  );

export const calculateTotalsForScheduleTable = (rows: any[]) =>
  rows.reduce(
    (acc, row) => {
      const invoiceAmountParsed = getNumberFromCurrencyFormat(
        row.InvoiceAmount
      );
      const endorsementParsed = getNumberFromCurrencyFormat(row.Endorsement);
      const totalPaymentDueParsed = getNumberFromCurrencyFormat(row.Total);

      return {
        totalInvoiceAmount: acc.totalInvoiceAmount + invoiceAmountParsed,
        totalEndorsement: acc.totalEndorsement + endorsementParsed,
        totalPaymentDue: acc.totalPaymentDue + totalPaymentDueParsed,
      };
    },
    { totalInvoiceAmount: 0, totalEndorsement: 0, totalPaymentDue: 0 }
  );

export const validateEndorsementPremiumChangeBeforeBind = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;

  if (
    policyJSON?.bindInstructions !== undefined &&
    policyJSON.bindInstructions !== null &&
    policyJSON.bindInstructions.billByLocation === true &&
    conditionHasValue(policyJSON.bindInstructions.scheduleMultiTable) &&
    conditionHasValue(policyJSON.bindInstructions.endorsementPremiumChange)
  ) {
    const result = policyJSON.bindInstructions.scheduleMultiTable.groups.reduce(
      (acc, group) => {
        const endorsement = group.header.find(
          (headerData) => headerData.name === "Endorsement"
        )?.value;
        const endorsementParsed = unFormatLocalString(
          endorsement !== "" ? endorsement ?? "0" : "0"
        );

        return {
          totalEndorsement: acc.totalEndorsement + endorsementParsed,
        };
      },
      { totalEndorsement: 0 }
    );

    return (
      result.totalEndorsement ===
      policyJSON.bindInstructions.endorsementPremiumChange
    );
  }
  if (
    policyJSON?.bindInstructions !== undefined &&
    policyJSON.bindInstructions !== null &&
    !policyJSON?.bindInstructions?.billByLocation &&
    conditionHasValue(policyJSON?.bindInstructions?.scheduleTable) &&
    conditionHasValue(policyJSON?.bindInstructions?.endorsementPremiumChange)
  ) {
    const endorsementIndex = getColumnIndexByColumnName(
      "Endorsement",
      policyJSON.bindInstructions.scheduleTable.columns
    );

    const result = policyJSON.bindInstructions.scheduleTable.data.reduce(
      (acc, row) => {
        const endorsement =
          row.length > endorsementIndex ? row[endorsementIndex] : "";
        const endorsementParsed =
          endorsement !== "" ? unFormatNegativeLocalString(endorsement) : 0;

        return {
          totalEndorsement: acc.totalEndorsement + endorsementParsed,
        };
      },
      { totalEndorsement: 0 }
    );

    return (
      result.totalEndorsement ===
      policyJSON.bindInstructions.endorsementPremiumChange
    );
  }

  return false;
};

const getInvoiceDetails = (
  isDeposit: boolean,
  description: string,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number,
  invoiceSubId: number,
  invoiceDetailFeesId: number,
  invoiceDetailId: number
) => {
  if (isDeposit) {
    return [
      {
        invoiceDetailID: invoiceDetailFeesId,
        invoiceSubID: invoiceSubId,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 2,
        tranAccountID: feesTranAccountId,
        quantity: 1,
        description,
        priceEach: fees,
        extended: fees,
      },
      {
        invoiceDetailID: invoiceDetailId,
        invoiceSubID: invoiceSubId,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 1,
        tranAccountID: premiumTranAccountId,
        quantity: 1,
        description,
        priceEach: premium,
        extended: premium,
      },
    ] as PolicyInvoiceDetailBlob[];
  } else {
    return [
      {
        invoiceDetailID: invoiceDetailId,
        invoiceSubID: invoiceSubId,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 1,
        tranAccountID: premiumTranAccountId,
        quantity: 1,
        description,
        priceEach: premium,
        extended: premium,
      },
    ] as PolicyInvoiceDetailBlob[];
  }
};

const getInvoiceSubs = (
  isDeposit: boolean,
  invoiceId: number,
  invoiceSubId: number,
  invoiceDetailFeesId: number,
  invoiceDetailId: number,
  description: string,
  sequenceNumber: number,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number,
  childData: string[][] | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) => {
  if (childData !== null) {
    return childData.map((row, index) => {
      const locationNameIndex = 0;
      const premiumIndex = 3;
      const invoiceDetailFeesIndex = 4;
      const invoiceSubIdIndex = 6;
      const invoiceDetailIdIndex = 7;
      const locationAndStateArray = row[locationNameIndex].split(" ");
      const addressFound = addresses?.find(
        (address) =>
          address.locationNumber?.toString() === locationAndStateArray[0]
      );
      const premium =
        row[premiumIndex] !== "" &&
        row[premiumIndex] !== undefined &&
        row[premiumIndex] !== null
          ? unFormatNegativeLocalString(row[premiumIndex])
          : 0;
      const invoiceDetailDescription = `${row[locationNameIndex]} ${description}`;
      const invoiceSubId =
        row[invoiceSubIdIndex] !== "" &&
        row[invoiceSubIdIndex] !== undefined &&
        row[invoiceSubIdIndex] !== null
          ? unFormatNegativeLocalString(row[invoiceSubIdIndex])
          : 0;
      const invoiceDetailFeesId =
        row[invoiceDetailFeesIndex] !== "" &&
        row[invoiceDetailFeesIndex] !== undefined &&
        row[invoiceDetailFeesIndex] !== null
          ? unFormatNegativeLocalString(row[invoiceDetailFeesIndex])
          : 0;
      const invoiceDetailId =
        row[invoiceDetailIdIndex] !== "" &&
        row[invoiceDetailIdIndex] !== undefined &&
        row[invoiceDetailIdIndex] !== null
          ? unFormatNegativeLocalString(row[invoiceDetailIdIndex])
          : 0;

      return {
        invoiceSubID: invoiceSubId,
        invoiceID: invoiceId,
        nameID: addressFound?.nameID ?? primaryAddress?.nameID ?? null,
        addressID: addressFound?.addressID ?? primaryAddress?.addressID ?? null,
        sequence: (sequenceNumber + (index + 1)).toString(),
        invoiceDetails: getInvoiceDetails(
          isDeposit,
          invoiceDetailDescription,
          0,
          premium,
          premiumTranAccountId,
          feesTranAccountId,
          invoiceSubId,
          invoiceDetailFeesId,
          invoiceDetailId
        ),
      } as PolicyInvoiceSubBlob;
    });
  }

  return [
    {
      invoiceSubID: invoiceSubId,
      invoiceID: invoiceId,
      nameID: primaryAddress?.nameID ?? null,
      addressID: primaryAddress?.addressID ?? null,
      sequence: (sequenceNumber + 1).toString(),
      invoiceDetails: getInvoiceDetails(
        isDeposit,
        description,
        fees,
        premium,
        premiumTranAccountId,
        feesTranAccountId,
        invoiceSubId,
        invoiceDetailFeesId,
        invoiceDetailId
      ),
    },
  ] as PolicyInvoiceSubBlob[];
};

const getInvoiceJSON = (
  isDeposit: boolean,
  invoiceId: number,
  invoiceSubId: number,
  invoiceDetailFeesId: number,
  invoiceDetailId: number,
  invoiceNumber: string,
  invoiceTypeID: number,
  payPlanId: number,
  dueDate: Date,
  description: string,
  sequenceNumber: number,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number,
  childData: string[][] | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) =>
  ({
    invoiceID: invoiceId,
    invoiceNumber: invoiceNumber,
    invoiceTypeID,
    invoiceStatus: StatusEnums.ACTIVE,
    payPlanID: payPlanId,
    invoiceDate: dueDate,
    dueDate: dueDate,
    invoiceDescription: description,
    invoiceTotal: fees + premium,
    invoiceSubs: getInvoiceSubs(
      isDeposit,
      invoiceId,
      invoiceSubId,
      invoiceDetailFeesId,
      invoiceDetailId,
      description,
      sequenceNumber,
      fees,
      premium,
      premiumTranAccountId,
      feesTranAccountId,
      childData,
      primaryAddress,
      addresses
    ),
  } as PolicyInvoiceBlob);

export const getInvoicesFromStandardScheduleTable = (
  scheduleTable: PolicyPaymentSchedulePage,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null,
  invoiceTypeDeposit?: InvoiceTypeDto,
  invoiceTypePremium?: InvoiceTypeDto,
  payPlan?: PolicyPayPlanBlob | null
) => {
  const regularScheduleTable = scheduleTable.tableData;
  const columns = regularScheduleTable?.columns ?? [];

  const InvoiceIdIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "InvoiceId"
  );
  const InvoiceSubIdIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "InvoiceSubId"
  );
  const InvoiceDetailFeesIdIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "InvoiceDetailFeesId"
  );
  const InvoiceDetailIdIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "InvoiceDetailId"
  );
  const InvoiceNumberIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "InvoiceNumber"
  );
  const dueDateIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "DueDate"
  );
  const descriptionIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "Description"
  );
  const feesIndex = getDataIndexByColumnNameWithoutExternal(columns, "Fees");
  const premiumIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "Total"
  );

  const data = regularScheduleTable?.data ?? [];

  const newInvoices = data.map((row, index) => {
    const invoiceId =
      row[InvoiceIdIndex] !== "" &&
      row[InvoiceIdIndex] !== undefined &&
      row[InvoiceIdIndex] !== null
        ? unFormatNegativeLocalString(row[InvoiceIdIndex])
        : 0;
    const invoiceSubId =
      row[InvoiceSubIdIndex] !== "" &&
      row[InvoiceSubIdIndex] !== undefined &&
      row[InvoiceSubIdIndex] !== null
        ? unFormatNegativeLocalString(row[InvoiceSubIdIndex])
        : 0;
    const invoiceDetailFeesId =
      row[InvoiceDetailFeesIdIndex] !== "" &&
      row[InvoiceDetailFeesIdIndex] !== undefined &&
      row[InvoiceDetailFeesIdIndex] !== null
        ? unFormatNegativeLocalString(row[InvoiceDetailFeesIdIndex])
        : 0;
    const invoiceDetailId =
      row[InvoiceDetailIdIndex] !== "" &&
      row[InvoiceDetailIdIndex] !== undefined &&
      row[InvoiceDetailIdIndex] !== null
        ? unFormatNegativeLocalString(row[InvoiceDetailIdIndex])
        : 0;
    const dueDate = GettingDateWithoutTime(new Date(row[dueDateIndex] ?? ""));
    const invoiceTypeID =
      index === 0
        ? invoiceTypeDeposit?.invoiceTypeId ?? null
        : invoiceTypePremium?.invoiceTypeId ?? null;
    const fees =
      row[feesIndex] !== "" &&
      row[feesIndex] !== undefined &&
      row[feesIndex] !== null
        ? unFormatNegativeLocalString(row[feesIndex])
        : 0;
    const premium =
      row[premiumIndex] !== "" &&
      row[premiumIndex] !== undefined &&
      row[premiumIndex] !== null
        ? unFormatNegativeLocalString(row[premiumIndex])
        : 0;

    return getInvoiceJSON(
      index == 0,
      invoiceId,
      invoiceSubId,
      invoiceDetailFeesId,
      invoiceDetailId,
      row[InvoiceNumberIndex],
      invoiceTypeID ?? 0,
      payPlan?.payPlanID ?? 0,
      dueDate,
      row[descriptionIndex] ?? "",
      index,
      fees,
      premium,
      payPlan?.premiumTranAccountId ?? 0,
      payPlan?.assessmentTranAccountID ?? 0,
      null,
      primaryAddress,
      addresses
    );
  });

  return newInvoices;
};

export const getInvoicesFromMultiTableSchedule = (
  multiTableSchedulePage: PolicyPaymentScheduleMultiTablePage,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null,
  invoiceTypeDeposit?: InvoiceTypeDto,
  invoiceTypePremium?: InvoiceTypeDto,
  payPlan?: PolicyPayPlanBlob | null
) => {
  const multiTableSchedule = multiTableSchedulePage.tableData;
  const groups = multiTableSchedule?.groups ?? [];

  const newInvoices = groups.map((group, index) => {
    const invoiceIdInData = group.metaData.find(
      (header) => header.name === "InvoiceId"
    )?.value;
    const invoiceId =
      invoiceIdInData !== undefined &&
      invoiceIdInData !== "" &&
      invoiceIdInData !== null
        ? parseInt(invoiceIdInData)
        : 0;
    const invoiceNumberInHeader =
      group.header.find((header) => header.name === "InvoiceNumber")?.value ??
      "";
    const dueDateInHeader = group.header.find(
      (header) => header.name === "DueDate"
    )?.value;
    const feesInHeader = group.header.find(
      (header) => header.name === "Fees"
    )?.value;
    const premiumInHeader = group.header.find(
      (header) => header.name === "Premium"
    )?.value;
    const descriptionInHeader =
      group.header.find((header) => header.name === "Description")?.value ?? "";

    const dueDate = GettingDateWithoutTime(new Date(dueDateInHeader ?? ""));
    const invoiceTypeID =
      index === 0
        ? invoiceTypeDeposit?.invoiceTypeId ?? null
        : invoiceTypePremium?.invoiceTypeId ?? null;
    const fees =
      feesInHeader !== "" && feesInHeader !== undefined && feesInHeader !== null
        ? unFormatNegativeLocalString(feesInHeader)
        : 0;
    const premium =
      premiumInHeader !== "" &&
      premiumInHeader !== undefined &&
      premiumInHeader !== null
        ? unFormatNegativeLocalString(premiumInHeader)
        : 0;

    const childData =
      multiTableSchedulePage.rowChildren?.find(
        (child) => child.id.toString() === group.groupUiidKey
      )?.rowData ?? [];
    const sequenceNumber = index * (childData?.length ?? 0);

    return getInvoiceJSON(
      index == 0,
      invoiceId,
      0,
      0,
      0,
      invoiceNumberInHeader,
      invoiceTypeID ?? 0,
      payPlan?.payPlanID ?? 0,
      dueDate,
      descriptionInHeader,
      sequenceNumber,
      fees,
      premium,
      payPlan?.premiumTranAccountId ?? 0,
      payPlan?.assessmentTranAccountID ?? 0,
      childData,
      primaryAddress,
      addresses
    );
  });

  return newInvoices;
};

export const getScheduleTableAsInvoicesForEndorsementQuote = (
  readOnly: boolean,
  policyJSON?: PolicyBlob
) => {
  const billByLocation = policyJSON?.bindInstructions?.billByLocation ?? false;
  const invoices = policyJSON?.invoices;
  const invoiceTypeList =
    policyJSON?.endorsementConfiguration?.invoiceTypeList ?? [];
  const invoiceTypeDeposit = invoiceTypeList.find(
    (it) => it.description === "Deposit"
  );
  const invoiceTypePremium = invoiceTypeList.find(
    (it) => it.description === "Premium"
  );
  const payPlan = policyJSON?.payPlan;
  const primaryAddress = policyJSON?.insured?.primaryAddress;
  const addresses = policyJSON?.insured?.addresses;

  const scheduleTable = billByLocation
    ? ({
        tableData: policyJSON?.bindInstructions?.scheduleMultiTable,
        rowChildren: policyJSON?.bindInstructions?.multiTableRowChildren,
      } as PolicyPaymentScheduleMultiTablePage)
    : ({
        tableData: policyJSON?.bindInstructions?.scheduleTable,
      } as PolicyPaymentSchedulePage);

  if (
    scheduleTable !== undefined &&
    scheduleTable !== null &&
    !readOnly &&
    !billByLocation
  ) {
    return getInvoicesFromStandardScheduleTable(
      scheduleTable as PolicyPaymentSchedulePage,
      primaryAddress,
      addresses,
      invoiceTypeDeposit,
      invoiceTypePremium,
      payPlan
    );
  }
  if (scheduleTable !== undefined && scheduleTable !== null && !readOnly) {
    return getInvoicesFromMultiTableSchedule(
      scheduleTable as PolicyPaymentScheduleMultiTablePage,
      primaryAddress,
      addresses,
      invoiceTypeDeposit,
      invoiceTypePremium,
      payPlan
    );
  }

  return invoices;
};
