import { FC, ElementType, ReactNode } from "react";
import {
  MobileStepper,
  Box,
  Button,
  useTheme,
  SxProps,
  Theme,
} from "@mui/material";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

type MobileStepperTrueProps = {
  stepsInfo: any[];
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  BodyStepper: ElementType;
  variant?: "text" | "custom" | "dots" | "progress";
  variantText?: ReactNode;
  sxMobileStepper?: SxProps<Theme>;
  sxButtons?: SxProps<Theme>;
};

const MobileTrueStepper: FC<MobileStepperTrueProps> = ({
  stepsInfo,
  handleBack,
  handleNext,
  BodyStepper,
  variant,
  variantText,
  activeStep,
  sxMobileStepper = {},
  sxButtons = {},
}) => {
  const theme = useTheme();

  const maxSteps = stepsInfo.length;
  const localVariant = variant !== "custom" ? variant : "dots";
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MobileStepper
        variant={localVariant as any}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={
          variant === "custom"
            ? { "> div": { display: "none" }, ...sxMobileStepper }
            : sxMobileStepper
        }
        className={"mobile-stepper"}
        nextButton={
          <Button
            disableRipple
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className={`true_mobile_stepper_buttons true_mobile_stepper_button_next ${
              maxSteps === 0 ? "true_mobile_disable_stepper_buttons" : ""
            }`}
            sx={sxButtons}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRightRounded />
            ) : (
              <ArrowRightIcon />
            )}
          </Button>
        }
        backButton={
          <Button
            disableRipple
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className={`true_mobile_stepper_buttons true_mobile_stepper_button_back ${
              maxSteps === 0 ? "true_mobile_disable_stepper_buttons" : ""
            }`}
            sx={sxButtons}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeftRounded />
            ) : (
              <ArrowLeftIcon />
            )}
          </Button>
        }
      />
      {variant === "custom" && (
        <Box
          className={`true_mobile_stepper_title ${
            maxSteps === 0 ? "true_disable_mobile_stepper_title" : ""
          }`}
        >
          {variantText}
        </Box>
      )}
      <BodyStepper />
    </Box>
  );
};

export default MobileTrueStepper;
