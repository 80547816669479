import { FC } from "react";
import { Drawer as DrawerMU } from "@mui/material";
import "./drawer.module.css";

type DrawerProps = {
  anchor?: "right" | "left" | "top" | "bottom";
  open?: boolean;
  children?: any;
  onClose?: () => void;
};

const Drawer: FC<DrawerProps> = ({
  anchor = "right",
  open = false,
  children,
  onClose,
}) => {
  return (
    <DrawerMU
      anchor={anchor}
      open={open}
      onClose={() => onClose?.()}
      className={"true_drawer_modal_container"}
    >
      {children}
    </DrawerMU>
  );
};

export default Drawer;
