import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Select, Switch, Upload } from "../../../TrueUI";
import { DiaryConfigurationDto } from "../../../../dtos/diary-configuration-dto";
import { ContextProps } from "../InsuredDrawerConstants";
import {
  getDisplayNameByOptionId,
  getValueByOptionName,
} from "../../../../utilities/selectFunctions";
import { ContextEnums } from "../../../../dtos/context-enums";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import ShowTmpFiles from "../../../TrueUI/Upload/ShowTmpFiles";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { isEmptyObject } from "../../../../utilities/objectFunctions";

type HeaderComponentProps = {
  context: ContextProps;
  diaryNote: DiaryNoteDto | null;
  diaryConfiguration: DiaryConfigurationDto;
  errorMessages: any;
  action?: "add" | "edit";
  headerRefresh?: boolean;
  insuredIdAtomKey?: string;
  setDairyNote: (diaryNote: DiaryNoteDto | null) => void;
};

const DiaryHeader: FC<HeaderComponentProps> = ({
  context,
  diaryNote,
  errorMessages,
  diaryConfiguration,
  setDairyNote,
  action = "add",
  insuredIdAtomKey = "",
  headerRefresh = false,
}) => {
  const categoryOptions = diaryConfiguration?.diaryCategoryOptions;
  const typeOptions = diaryConfiguration?.diaryTypeOptions;
  const policyOptions = diaryConfiguration?.diaryPolicyOptions;
  const defaultCategory = getValueByOptionName(
    diaryNote?.category ?? "",
    categoryOptions
  );

  const [clear, setClear] = useState<boolean>(false);
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const prevNote = getAtom()?.drawerInformation?.currentNoteInformation;
  const [files, setFiles] = useState<{ [key: string]: number }>(
    prevNote?.files ?? {}
  );

  useEffect(() => {
    setClear(headerRefresh);
  }, [headerRefresh]);

  useEffect(() => {
    if (!isEmptyObject(files)) {
      const keys = Object.keys(files ?? []);
      const newFileIds = keys.map((key) => files?.[key]);
      setDairyNote({ ...diaryNote, attachmentsIds: newFileIds });
    } else {
      setDairyNote({ ...diaryNote, attachmentsIds: [] });
    }
  }, [files]);

  useEffect(() => {
    if (prevNote?.noteInformation?.attachmentsIds?.length ?? 0 > 0) {
      const prevDrawer = getAtom();
      setAtom({
        ...getAtom(),
        drawerInformation: {
          ...prevDrawer?.drawerInformation,
          currentNoteInformation: {
            ...prevDrawer?.drawerInformation?.currentNoteInformation,
            showTmpFiles: true,
          },
        },
      });
    }
  }, []);

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box width={"60%"} display={"flex"} mb={"2px"}>
        <Box sx={{ mr: "10px" }}>
          <Select
            id="diary-note-type"
            name="noteType"
            label={"Type"}
            options={typeOptions}
            value={diaryNote?.type}
            errorMessage={errorMessages?.type}
            variant={"filled"}
            inputWidth={"150px"}
            labelFontType={"BOLD_CAPTION"}
            optionsMaxHeight={"150px"}
            onChange={(value) => {
              setDairyNote({
                ...diaryNote,
                type: value,
              });
            }}
          />
        </Box>
        <Box sx={{ width: "30%", mr: "10px" }}>
          <Select
            id="diary-note-category"
            name="noteCategory"
            label={"Category"}
            options={categoryOptions}
            value={defaultCategory}
            errorMessage={errorMessages?.category}
            variant={"filled"}
            optionsMaxHeight={"150px"}
            labelFontType={"BOLD_CAPTION"}
            firstOptionAsDefault={false}
            onChange={(value) => {
              setDairyNote({
                ...diaryNote,
                category: getDisplayNameByOptionId(value, categoryOptions),
              });
            }}
          />
        </Box>
        <Box sx={{ width: "30%" }}>
          {context.type !== ContextEnums.CLAIMS && (
            <Select
              id="diary-note-policy"
              name="notePolicy "
              label={"Policy"}
              options={policyOptions}
              errorMessage={errorMessages?.policy}
              value={diaryNote?.policy}
              variant={"filled"}
              labelFontType={"BOLD_CAPTION"}
              optionsMaxHeight={"150px"}
              onChange={(value) => {
                setDairyNote({
                  ...diaryNote,
                  policy: value,
                });
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", ml: "25px" }}>
          <Switch
            id="diary-note-private"
            control="checkbox"
            label="Private"
            labelFontType={"BOLD_CAPTION"}
            labelAlignment="right"
            isChecked={diaryNote?.isPrivate}
            onChangeIsChecked={(value) => {
              setDairyNote({ ...diaryNote, isPrivate: value });
            }}
          />
        </Box>
      </Box>
      {action === "add" && (
        <Box width={"30%"}>
          <Upload
            name="upload-files"
            files={files}
            onUploadFileList={(f, resp) => {
              setFiles(f);
              setAtom({
                ...getAtom(),
                drawerInformation: {
                  ...getAtom()?.drawerInformation,
                  currentNoteInformation: {
                    ...getAtom()?.drawerInformation?.currentNoteInformation,
                    tmpFiles: [
                      ...(resp ?? []),
                      ...(getAtom()?.drawerInformation?.currentNoteInformation
                        ?.tmpFiles ?? []),
                    ],
                    files: f,
                  },
                },
              });
            }}
            clearFiles={clear}
            resetClearFiles={setClear}
          />
          {getAtom()?.drawerInformation?.currentNoteInformation
            ?.showTmpFiles && (
            <ShowTmpFiles
              currentFileIds={diaryNote?.attachmentsIds ?? []}
              tmpFiles={
                getAtom()?.drawerInformation?.currentNoteInformation?.tmpFiles
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DiaryHeader;
