import React from "react";
import { Button as MaButton } from "@mui/material";
import { usePermissions } from "../../../hooks";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import "./button.module.css";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

interface ButtonProperties
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ignoreGlobalDisableOverride?: boolean | undefined;
  name?: string;
  isDisabled?: boolean;
  fullWidth?: boolean;
  sx?: any;
  variantStyle?: "contained" | "outlined" | "text";
  permissions?: PermissionsEnums[];
  size?: "small" | "medium" | "large";
  onClick?: (event) => void;
  id?: string;
  stopClickPropagation?: boolean;
  tabIndex?: number;
  children?: any | string;
  className?: string;
  autoFocus?: boolean;
}

const Button: React.FC<ButtonProperties> = (props) => {
  const hasPermission = usePermissions(
    props?.permissions ?? []
  ).hasPermission;
  const toShow = hasPermission ? (props.isDisabled ? false : true) : false;
  const customClickEvent = (event) => {
    props.stopClickPropagation && event.stopPropagation();
    props.onClick && props.onClick(event);
  };

  const getClassName = () => {
    return `true_button_${props.variantStyle ?? "contained"} ${
      props.isDisabled ? "disabled" : ""
    } ${props.className ?? ""} ${TABBABLE_CLASS_NAME}`;
  };

  return (
    <MaButton
      tabIndex={props.tabIndex ?? 0}
      id={props.id}
      autoFocus={props.autoFocus ?? false}
      true-element={`true-element-button-${props.name ?? ""}`}
      fullWidth={props.fullWidth ?? false}
      variant={!toShow ? "contained" : props?.variantStyle ?? "contained"}
      disabled={!toShow}
      title={
        hasPermission ? "" : `You don't have permission to use this feature.`
      }
      // disabled={isDisabled}
      onClick={customClickEvent}
      size={props.size ?? "small"}
      disableElevation
      className={`${getClassName()} true_button_container`}
      sx={{ ...props.sx, height: props.sx?.height ?? "35px" }}
    >
      {props.children}
    </MaButton>
  );
};

export default Button;
