import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ContextEnums } from "../../../../dtos/context-enums";
import { SelectOptions } from "../../../../dtos/select-options";
import { TaskAttachmentDto } from "../../../../dtos/task-attachment-dto";
import { TaskDto } from "../../../../dtos/task-dto";
import { TaskTypeEnums } from "../../../../dtos/task-type-enums";
import { TasksConfigurationDto } from "../../../../dtos/tasks-configuration-dto";
import { globalOptions } from "../../../../GlobalAtoms";
import { useApiGet, useApiPost } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { removeQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { isEmptyObject } from "../../../../utilities/objectFunctions";
import {
  DRAWER_TASK_ID,
  INSURED_ATOM_KEY,
} from "../../../../utilities/queryStringsHash";
import {
  Button,
  Col,
  Input,
  InputDate,
  RichEditor,
  Row,
  Select,
  SplitButton,
  Upload,
} from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import ShowFiles from "../../../TrueUI/Upload/ShowFiles";
import ShowTmpFiles, { TmpFiles } from "../../../TrueUI/Upload/ShowTmpFiles";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { ContextProps } from "../InsuredDrawerConstants";
import { TaskAtomFamily, TASK_ATOM_KEY } from "./TaskAtoms";
import TaskCategoryDropdown from "./TaskCategoryDropdown";
import { getSaveButtonItems, getTaskInBlank } from "./TaskFunctions";
import TaskCalendar from "./TaskCalendar";

type TaskFormProps = {
  context: ContextProps;
  taskConfiguration: TasksConfigurationDto | null;
  setRefreshTable: any;
};

export type FilesInfo = {
  files: { [key: string]: number };
  savedFiles: TaskAttachmentDto[];
  tmpFiles?: TmpFiles[];
  fileIds: number[];
  fileToDelete?: TaskAttachmentDto;
  clearFiles: boolean;
  showTmpFiles?: boolean;
};

type ComponentInfo = {
  readyToSave: boolean;
  errorDetails: any;
  isDialogConfirmationOpen: boolean;
};

const TaskForm: FC<TaskFormProps> = ({
  context,
  taskConfiguration,
  setRefreshTable,
}) => {
  const [currenSplitButtonOption, setCurrentSplitButtonOption] = useState(0);
  const formStateAtomKey = `${TASK_ATOM_KEY} ${context.tabKey}`;
  const { getAtom } = useAtomFamily(globalOptions);
  const [formState, setFormState] = useRecoilState(
    TaskAtomFamily(formStateAtomKey)
  );

  const [componentInfo, setComponentInfo] = useState<ComponentInfo>({
    readyToSave: false,
    errorDetails: null,
    isDialogConfirmationOpen: false,
  });

  const [filesInfo, setFilesInfo] = useState<FilesInfo>(
    formState.filesInfo ?? {
      files: {},
      savedFiles: [],
      fileIds: [],
      tmpFiles: [],
      fileToDelete: undefined,
      clearFiles: false,
    }
  );

  const {
    responsePost: responsePostTask,
    dispatchPost: dispatchPostTask,
    validatorErrorResponse: validatorErrorResponseTask,
  } = useApiPost<TaskDto>(`api/task/SaveTask`, formState.editData);

  const { responseGet, dispatchGet } = useApiGet<TaskAttachmentDto[]>(
    `api/TaskAttachment/GetFilesByTaskId?taskId=${formState.editData?.taskId}`
  );
  const { responsePost: responseDeleteFile, dispatchPost: dispatchDeleteFile } =
    useApiPost<number>(
      `api/TaskAttachment/DeleteTaskAttachment?taskAttachmentId=${filesInfo.fileToDelete?.taskAttachmentId}`
    );

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${context?.tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const taskType =
    context.type === ContextEnums.CLAIMS
      ? TaskTypeEnums.CLAIM
      : TaskTypeEnums.POLICY;

  const getValueByDisplayValueFromSelectOptions = (
    key: any,
    selectOptions: SelectOptions[] | Partial<SelectOptions>[]
  ) => {
    const activeStatus = selectOptions.find(
      (status) => status.displayName === key
    );

    return activeStatus?.intValue ?? 0;
  };
  const getNewTaskData = () => {
    const activeStatusId = getValueByDisplayValueFromSelectOptions(
      "Active",
      taskConfiguration?.taskStatusList ?? []
    );
    const currentUserId = getAtom().userId;
    const insuredId = context.insuredId ?? 0;
    return getTaskInBlank(
      insuredId,
      activeStatusId,
      currentUserId,
      context.type,
      taskConfiguration?.currentClaimAdjusterId ?? 0,
      context.claimNumber
    );
  };
  const createNewTask = (statusList: Partial<SelectOptions>[]) => {
    const activeStatusId = getValueByDisplayValueFromSelectOptions(
      "Active",
      statusList
    );
    const currentUserId = getAtom().userId;
    const insuredId = context.insuredId ?? 0;
    const newTask = getTaskInBlank(
      insuredId,
      activeStatusId,
      currentUserId,
      context.type,
      taskConfiguration?.currentClaimAdjusterId ?? 0,
      context.claimNumber
    );

    const initialTask =
      formState?.editData != null
        ? {
            ...formState?.editData,
            insuredId: insuredId,
            claimNumber: context?.claimNumber,
          }
        : null;
    setFormState({ ...formState, editData: initialTask ?? newTask });
  };

  const updateTaskData = (field: string, value: string | number) => {
    if (formState.editData !== null)
      setFormState({
        ...formState,
        editData: {
          ...(formState.editData as any),
          [field]: value,
        },
      });
  };

  const saveTaskWithStatus = (statusName: string) => {
    setCurrentSplitButtonOption(-1);
    const statusId = getValueByDisplayValueFromSelectOptions(
      statusName,
      taskConfiguration?.taskStatusList ?? []
    );
    updateTaskData("taskStatus", statusId);
    setComponentInfo({ ...componentInfo, readyToSave: true });
  };

  const handleCancel = (close: boolean) => {
    clearTaskForm();
    setComponentInfo({ ...componentInfo, isDialogConfirmationOpen: close });
  };

  const SaveButtonItems = getSaveButtonItems(saveTaskWithStatus);

  const displaySelectedTaskData = () => {
    setComponentInfo({ ...componentInfo, errorDetails: null });
  };

  const clearTaskForm = () => {
    setCurrentSplitButtonOption(0);
    setFormState({ editData: getNewTaskData(), isAddOrEditExpanded: false });
    setRefreshTable();
    removeQueryStrings([DRAWER_TASK_ID]);
    setFilesInfo({ ...filesInfo, files: {}, fileIds: [], clearFiles: true });
    setComponentTriggers(["claimLandingPageComponent", "activityLogComponent"]);
  };

  const deleteFileFromTask = (fileId: number) => {
    const fileMatch = [...filesInfo.savedFiles].find(
      (f) => f.fileId === fileId
    );

    const newTaskAttachments = formState.editData?.taskAttachmentsIds?.filter(
      (id) => id != fileId
    );
    setFormState({
      ...formState,
      editData: {
        ...formState.editData,
        taskAttachmentsIds: newTaskAttachments,
      },
    });
    setFilesInfo({ ...filesInfo, fileToDelete: fileMatch });
  };

  useEffect(() => {
    if (
      conditionHasValue(formState.editData?.taskId) &&
      formState.editData?.taskId != 0
    ) {
      dispatchGet();
    }
    if (
      formState.editData?.taskId !== undefined &&
      formState.editData.taskId > 0
    )
      displaySelectedTaskData();
    if (
      formState.editData?.taskId === undefined ||
      formState.editData.taskId <= 0
    )
      createNewTask(taskConfiguration?.taskStatusList ?? []);
  }, [formState.editData?.taskId]);

  useEffect(() => {
    if (!isEmptyObject(filesInfo.files)) {
      const keys = Object.keys(filesInfo.files ?? []);
      const newFileIds = keys.map((key) => filesInfo.files?.[key]);
      setFilesInfo({ ...filesInfo, fileIds: [...newFileIds] });
      setFormState({
        ...formState,
        editData: {
          ...formState.editData,
          taskAttachmentsIds: [...newFileIds],
        },
        filesInfo: { ...filesInfo, fileIds: [...newFileIds] },
      });
    }
  }, [filesInfo.files]);

  useEffect(() => {
    if (componentInfo.readyToSave) {
      setComponentInfo({ ...componentInfo, readyToSave: false });
      dispatchPostTask();
      setCurrentSplitButtonOption(0);
    }
  }, [componentInfo.readyToSave]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFilesInfo({
        ...filesInfo,
        savedFiles: responseGet.responseData ?? [],
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePostTask)) {
      clearTaskForm();
    }
  }, [responsePostTask]);

  useEffect(() => {
    setComponentInfo({
      ...componentInfo,
      errorDetails: validatorErrorResponseTask?.errorDetails ?? null,
    });
  }, [validatorErrorResponseTask]);

  useEffect(() => {
    if (conditionHasValue(filesInfo.fileToDelete)) dispatchDeleteFile();
  }, [filesInfo.fileToDelete]);

  useEffect(() => {
    if (isAPITotallyComplete(responseDeleteFile)) {
      const tempFiles = [...filesInfo.savedFiles].filter(
        (f) => f.taskAttachmentId !== responseDeleteFile?.responseData
      );
      setFilesInfo({ ...filesInfo, savedFiles: tempFiles });
    }
  }, [responseDeleteFile]);

  useEffect(() => {
    if (formState.filesInfo?.tmpFiles?.length ?? 0 > 0) {
      setFilesInfo({ ...filesInfo, showTmpFiles: true });
    }
  }, []);

  const updateTaskToACopy = () => {
    setFormState({
      ...formState,
      editData: {
        ...formState.editData,
        taskId: 0,
        assignedUserId:
          context.type === ContextEnums.CLAIMS
            ? taskConfiguration?.currentClaimAdjusterId
            : formState.editData?.assignedUserId,
        taskAttachmentsIds: [
          ...(formState?.editData?.taskAttachmentsIds ?? []),
          ...(filesInfo?.savedFiles?.map((file) => file.fileId) ?? []),
        ],
      },
    });
  };

  return (
    <>
      <Row className={"form-calendar-container"} {...rowWithNoMarginNorGutter}>
        <Row
          {...rowWithNoMarginNorGutter}
          verticalAlign={"flex-start"}
          selfAlign={"flex-start"}
          rowDirection={"column"}
          rowWidth={"79%"}
        >
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <Select
                id="task-form-owner"
                tabIndex={1}
                name="owner"
                label="Owner"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                options={taskConfiguration?.ownerList ?? []}
                value={formState.editData?.ownerUserId}
                onChange={(value) => {
                  updateTaskData("ownerUserId", value);
                }}
                errorMessage={componentInfo.errorDetails?.ownerUserId}
                optionsMaxHeight="300px"
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <Select
                id="task-form-assigned-to"
                tabIndex={2}
                name="assigned-to"
                label="Assigned To"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                options={
                  context.type === ContextEnums.CLAIMS
                    ? taskConfiguration?.taskAdjusters ?? []
                    : taskConfiguration?.assignedToList ?? []
                }
                value={
                  context.type === ContextEnums.CLAIMS &&
                  formState?.editData?.taskId === 0
                    ? taskConfiguration?.currentClaimAdjusterId
                    : formState.editData?.assignedUserId
                }
                firstOptionAsDefault={false}
                onChange={(value) => {
                  updateTaskData("assignedUserId", value);
                }}
                errorMessage={componentInfo.errorDetails?.assignedUserId}
                optionsMaxHeight="300px"
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <TaskCategoryDropdown
                type={taskType}
                value={formState.editData?.category}
                onChange={(value) => {
                  updateTaskData("category", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <Select
                id="task-form-priority"
                tabIndex={3}
                name="priority"
                label="Priority"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                options={taskConfiguration?.priorityList ?? []}
                value={formState.editData?.priority}
                onChange={(value) => {
                  updateTaskData("priority", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
              <Input
                tabIndex={4}
                id="subject-id"
                name="subject"
                label="Subject"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.subject}
                maxLength={100}
                onChangeRawValue={(value) => {
                  updateTaskData("subject", value);
                }}
                errorMessage={componentInfo.errorDetails?.subject}
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <InputDate
                tabIndex={5}
                id="due-date-id"
                name="due-date"
                label="Due Date"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.dateDue}
                onChangeRawValue={(value) => {
                  updateTaskData("dateDue", value);
                }}
                errorMessage={componentInfo.errorDetails?.dateDue}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <InputDate
                tabIndex={6}
                id="date-started-id"
                name="date-started"
                label="Date Started"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.dateStart}
                onChangeRawValue={(value) => {
                  updateTaskData("dateStart", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <InputDate
                tabIndex={6}
                id="date-scheduled-id"
                name="date-scheduled"
                label="Date Scheduled"
                variant="filled"
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.dateScheduled}
                onChangeRawValue={(value) => {
                  updateTaskData("dateScheduled", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <Input
                tabIndex={7}
                id="estimated-time-id"
                name="estimated-time"
                label="Estimated Time"
                variant="filled"
                type="fixedCurrency"
                decimalScale={1}
                prefix=""
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.estTime ?? ""}
                maxNumericValue={365}
                onChangeRawValue={(value) => {
                  updateTaskData("estTime", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <Input
                tabIndex={8}
                id="actual-time-id"
                name="actual-time"
                label="Actual Time"
                variant="filled"
                type="fixedCurrency"
                decimalScale={1}
                prefix=""
                labelFontType="BOLD_CAPTION"
                value={formState.editData?.actualTime ?? ""}
                maxNumericValue={365}
                onChangeRawValue={(value) => {
                  updateTaskData("actualTime", value);
                }}
              />
            </Col>
            <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
              <></>
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
            <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
              <RichEditor
                tabIndex={9}
                disableCollapse
                value={formState.editData?.comments}
                onChangeRawValue={(value) => {
                  updateTaskData("comments", value);
                }}
              />
            </Col>
          </Row>
        </Row>
        <Row
          {...rowWithNoMarginNorGutter}
          horizontalMargin={"10px"}
          className={"calendar-container"}
          verticalMargin={"5px"}
          selfAlign={"flex-start"}
          verticalAlign={"flex-start"}
          rowDirection={"column"}
          rowWidth={"21%"}
        >
          <TaskCalendar
            userList={
              context.type === ContextEnums.CLAIMS
                ? taskConfiguration?.taskAdjusters ?? []
                : taskConfiguration?.assignedToList ?? []
            }
            selectedUser={
              context.type === ContextEnums.CLAIMS
                ? taskConfiguration?.currentClaimAdjusterId ?? null
                : null
            }
            context={context}
          />
        </Row>
      </Row>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ p: "5px 5px" }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ width: "auto", maxWidth: "50%" }}
        >
          <Upload
            tabIndex={10}
            name="upload-files"
            files={filesInfo.files}
            onUploadFileList={(f, resp) => {
              if (!isEmptyObject(f)) {
                setFilesInfo({
                  ...filesInfo,
                  files: f,
                  tmpFiles: [...(resp ?? []), ...(filesInfo.tmpFiles ?? [])],
                });
              } else {
                setFormState({
                  ...formState,
                  editData: {
                    ...formState.editData,
                    taskAttachmentsIds: [],
                  },
                  filesInfo: { ...filesInfo, fileIds: [] },
                });
              }
            }}
            clearFiles={filesInfo.clearFiles}
            resetClearFiles={(value) =>
              setFilesInfo({ ...filesInfo, clearFiles: value })
            }
          />
          {filesInfo.savedFiles?.length > 0 && (
            <ShowFiles
              files={filesInfo.savedFiles}
              onDeleteFile={(id) => deleteFileFromTask(id)}
            />
          )}
          {filesInfo.showTmpFiles && (
            <ShowTmpFiles
              currentFileIds={filesInfo.fileIds}
              tmpFiles={filesInfo.tmpFiles}
              onDeleteFile={(id) => deleteFileFromTask(id)}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", width: "auto" }}>
          {formState.editData?.taskId !== undefined &&
            formState.editData?.taskId !== 0 && (
              <Button
                tabIndex={12}
                id="cancel-button"
                name="copy-task"
                onClick={() => {
                  updateTaskToACopy();
                }}
                variantStyle="outlined"
                sx={{ mr: 2, p: "5px !important" }}
              >
                {"Copy"}
              </Button>
            )}

          <SplitButton
            tabIndex={11}
            overrideLabel="SAVE"
            items={SaveButtonItems}
            triggerEventOnSelect
            showLoading
            currentSelectionIndex={currenSplitButtonOption}
          />
          <Button
            tabIndex={12}
            id="cancel-button"
            name="cancel"
            onClick={() =>
              setComponentInfo({
                ...componentInfo,
                isDialogConfirmationOpen: true,
              })
            }
            variantStyle="outlined"
            sx={{ ml: 2, p: "5px !important" }}
          >
            {"Cancel"}
          </Button>
        </Box>
      </Box>
      <DialogConfirmation
        id="taskCancelDialogConfirmation"
        open={componentInfo.isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to cancel? You will lose any unsaved changes."
        onCloseEvent={(close) => {
          setComponentInfo({
            ...componentInfo,
            isDialogConfirmationOpen: close,
          });
          setFormState({ ...formState, filesInfo: null });
        }}
        onOptionNoEvent={(close) =>
          setComponentInfo({
            ...componentInfo,
            isDialogConfirmationOpen: close,
          })
        }
        onOptionYesEvent={(close) => handleCancel(close)}
      />
    </>
  );
};

export default TaskForm;
