import { FC, useEffect, useState } from "react";
import {
  defaultSubscriptionData,
  SubscriptionModalProps,
} from "../SubscriptionUtils";
import { Modal } from "../../../TrueUI";
import SubscriptionModalContent from "./SubscriptionModalContent";
import { SubscriptionModalConfigurationDto } from "../../../../dtos/subscription-modal-configuration-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { UserSubscriptionDto } from "../../../../dtos/user-subscription-dto";

const SubscriptionModal: FC<SubscriptionModalProps> = ({
  modalConfig,
  closeModal,
  onSaveSuccessful,
}) => {
  const [subscriptionConfig, setSubscriptionConfig] =
    useState<SubscriptionModalConfigurationDto | null>(null);
  const [formData, setFormData] = useState<UserSubscriptionDto>(
    defaultSubscriptionData
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const isEditingSubscription = (modalConfig.subscriptionId ?? 0) > 0;
  const subIdParam = isEditingSubscription
    ? `subscriptionId=${modalConfig.subscriptionId}&`
    : null;
  const { responseGet, dispatchGet } =
    useApiGet<SubscriptionModalConfigurationDto>(
      `api/Subscription/GetSubscriptionModalConfiguration?${
        subIdParam ?? ""
      }categoryId=${modalConfig.categoryId}`
    );

  const { dispatchPost, responsePost, validatorErrorResponse } = useApiPost(
    `api/Subscription/SaveSubscription`,
    formData
  );

  const {
    dispatchPost: dispatchUnsubscribe,
    responsePost: unsubscribeResponse,
  } = useApiPost(`api/Subscription/Unsubscribe`, formData);

  const {
    responseGet: subscriptionResponse,
    dispatchGet: subscriptionDispatch,
  } = useApiGet<UserSubscriptionDto | null>(
    `api/Subscription/GetSubscriptionById?${subIdParam}`
  );

  const onSaveSubscription = () => {
    dispatchPost();
  };

  const onCloseModal = () => {
    setFormData(defaultSubscriptionData);
    closeModal();
  };

  useEffect(() => {
    if (modalConfig.categoryId && modalConfig.categoryId > 0) {
      setSubscriptionConfig(null);
      dispatchGet();
    }
  }, [modalConfig.categoryId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormData({
        ...formData,
        activityCategoryId: responseGet.responseData?.activityCategoryId,
      });
      setSubscriptionConfig(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isEditingSubscription) {
      subscriptionDispatch();
    }
  }, [modalConfig.subscriptionId]);

  useEffect(() => {
    if (isAPITotallyComplete(subscriptionResponse)) {
      setFormData(subscriptionResponse.responseData ?? defaultSubscriptionData);
    }
  }, [subscriptionResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      onSaveSuccessful();
      onCloseModal();
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse);
    }
  }, [validatorErrorResponse]);
  
  useEffect(() => {
    if(isAPITotallyCompleteNoContentResponse(unsubscribeResponse)) {
      onSaveSuccessful();
      onCloseModal();
    }
  }, [unsubscribeResponse]);
  
  
  const onUnsubscribe = () => {
    setErrorDetails(null);
    dispatchUnsubscribe();
  }

  return (
    <Modal
      id="user-subscription"
      title="Subscription Options"
      size="md"
      open={modalConfig.isOpen}
      saveEvent={onSaveSubscription}
      closeEvent={onCloseModal}
      showCloseButton
      showCancelTextButton
      cancelButtonWithConfirmation
      deleteOverrideLabel={"Unsubscribe"}
      deleteEvent={isEditingSubscription ? () => onUnsubscribe() : undefined}
      deleteButtonWithConfirmation
      deleteButtonConfirmationText={"Are you sure you want to unsubscribe?"}
    >
      {subscriptionConfig && (
        <SubscriptionModalContent
          subscriptionConfig={subscriptionConfig}
          formData={formData}
          setFormData={setFormData}
          errorDetails={errorDetails}
        />
      )}
    </Modal>
  );
};

export default SubscriptionModal;
