import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { StatusEnums } from "../../../../dtos/status-enums";
import { UsersByCustomerDto } from "../../../../dtos/users-by-customer-dto";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { addAllOption } from "../../../../utilities/selectFunctions";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { UserViewInformationAtom } from "../AdminSecurityAtoms";
import CustomProgramAccessFilter from "./CustomProgramAccessFilter";

type UsersTableProps = {
  selectedCustomer?: number;
  addEventOverrideCallback?: () => void;
  openEditModal?: (
    options: OptionsContextActionParameterProperties<any>
  ) => JSX.Element;
};

type PropsRefresh = {
  propName: string;
  value: string;
  refresh: boolean;
};

const TABLE_NAME = "users-by-customer-table";
const UsersTable: FC<UsersTableProps> = ({
  selectedCustomer,
  openEditModal,
  addEventOverrideCallback,
}) => {
  const [tableConfigurationInfo, setTableConfigurationInfo] =
    useState<UsersByCustomerDto | null>();
  const [refreshProps, setRefreshProps] = useState<PropsRefresh | null>();

  const { dispatchGet, responseGet } = useApiGet<UsersByCustomerDto>(
    `api/AdminTools/Security/UserListing/GetConfigurationAndTableUsersByTenantId?customerId=${selectedCustomer}`
  );

  const [userInfo, setUserInformationView] = useRecoilState(
    UserViewInformationAtom
  );

  const statusOptions = addAllOption(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName:
          enumType?.value == StatusEnums.ACTIVE
            ? "Active Only"
            : enumType?.key ?? "",
        intValue: enumType?.value ?? "",
      })
    ),
    false
  );

  useEffect(() => {
    if (refreshProps?.refresh === true) {
      setUserInformationView({
        ...userInfo,
        [refreshProps?.propName]: refreshProps?.value,
      });

      setRefreshProps(null);
    }
  }, [refreshProps?.refresh]);

  const tableConfig = () => {
    return {
      name: TABLE_NAME,
      columnsAndData: {
        columns: tableConfigurationInfo?.tableData?.columns ?? [],
        data: tableConfigurationInfo?.tableData?.data ?? [],
      },
      useManuallyReloadParameters: true,
      columnOptions: [
        { fieldName: "EmailAddress", width: 35 },
        { fieldName: "FirstName", width: 10 },
        { fieldName: "LastName", width: 10 },
        { fieldName: "ProgramAccess", width: 35 },
        { fieldName: "Status", width: 8 },
        { fieldName: "OPTIONS", width: 2 },
      ],
      toolbarOptions: {
        showAddButton: true,
        showEditButton: false,
        showExcelButton: true,
        showPDFButton: true,
        showImportButton: false,
        showSaveButton: false,
        showSortFilter: false,
        addButtonText: "New User",
      },
      filterOptions: [
        (options) =>
          BaseGridDropDownFilter({
            id: "user-status-filter",
            columnNames: ["StatusHidden"],
            labelText: "Show:",
            optionsOverride: statusOptions,
            options,
            onChange: (value) => {
              setRefreshProps({
                propName: "status",
                value: value,
                refresh: true,
              });
            },
          }),
        (options) =>
          CustomProgramAccessFilter({
            id: "program-filter",
            columnNames: ["ProgramAccessHidden"],
            labelText: "Select a Program:",
            optionsOverride: addAllOption(
              tableConfigurationInfo?.programs ?? []
            ),
            options,
            onChange: (value) => {
              setRefreshProps({
                propName: "programId",
                value: value,
                refresh: true,
              });
            },
          }),
      ],
      advancedOptions: {
        optionsColumnConfiguration: {
          isHidden: false,
          optionType: "component",
        },
        optionsContextActions: {
          contextComponent: (actionOptions) => openEditModal?.(actionOptions),
        },
      },
      events: {
        addEventOverrideCallback: addEventOverrideCallback,
      },
    } as BaseGridProperties;
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTableConfigurationInfo(responseGet?.responseData);
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(selectedCustomer)) {
      dispatchGet();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (conditionHasValue(tableConfigurationInfo?.tableData)) {
      manuallyReloadParameters();
    }
  }, [tableConfigurationInfo]);

  const { manuallyReloadParameters } = useBaseGrid(tableConfig());

  return <BaseGrid name={TABLE_NAME} />;
};
export default UsersTable;
