import { SelectOptions } from "../../../../dtos/select-options";

export type PolicyStatusModalConfig = {
  isOpen: boolean;
  policyId: number | null;
  quoteId: number | null;
  currentStatus: number | null;
  isAudit: boolean;
};

export type PolicyAssignmentModalConfig = {
  isOpen: boolean;
  policyId: number | null;
};

export type PolicyChangeStatusModalProps = {
  tabKey: string;
  modalConfiguration: PolicyStatusModalConfig;
  closeModal: () => void;
  onSaveSuccessful?: () => void;
  auditStatusOptions: SelectOptions[];
};

export type PolicyStatusFormData = {
  policyId: number | null;
  quoteId: number | null;
  status: number;
  description: string | null;
  comments: string;
};

export const defaultFormData = {
  policyId: null,
  quoteId: null,
  status: -1,
  description: null,
  comments: "",
};
