const NotImplementedError = (errorObject: any) => {
  console.warn(
    "An variable, object or function has not implemented. See error details",
    errorObject
  );
};

export {
  NotImplementedError,
};
