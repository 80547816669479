import { FC } from "react";
import SplitButton from "./SplitButton";

type PrintSplitButtonProps = {
  printOptions: {
    key: number;
    option: string;
    dropdownText: string;
    action: () => void;
  }[];
};

const PrintSplitButton: FC<PrintSplitButtonProps> = ({ printOptions }) => {
  return <SplitButton items={printOptions} />;
};

export default PrintSplitButton;
