import { ElementType, FC, useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";
// import Button from "../../Buttons/Button";
import { useRecoilValue } from "recoil";
import { useGridInstance } from "../Hooks/useGridInstance";
// import { tableInstanceAtomFamily2 } from "../TableAtoms";
import PlusMinusAction from "./PlusMinusAction";
import {
  // BaseTableCellSelectProperties,
  DeleteRowParameters,
  OptionsContextActionParameterProperties,
  OptionsContextActionProperties,
  // TableInstanceType2,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
// import { tableInstanceAtomFamily2 } from "../../TrueUI/Tables/TableAtoms";
import {
  // addOrRemoveKeyRow,
  addRowKeyWithOutRepeat,
} from "../../TrueUI/Tables/tableFunctions";
import { Button } from "../../TrueUI";
import {
  getRowByRowKey,
  hydrateAndCastDataSingle,
} from "../SupportFunctions/OLD_baseGridFunctions";
import { useGridCellManager } from "../Hooks/useGridCellManager";
import { BaseGridOptionCellType } from "../BaseGridProperties";
import { usePermissions } from "../../../hooks";

interface BaseTableOptionCellProperties {
  uiid: string;
  rowKey: string;
  rowIndex: number;
  optionType: BaseGridOptionCellType;
  hydratedRow?: any;
  contextMenuOptionRenderingCondition?:
    | {
        actionName: string;
        condition: (row: any) => { isHidden: boolean };
      }[]
    | null;
  optionsContextActions:
    | OptionsContextActionProperties[]
    | ((row?: any) => JSX.Element)
    | null;
}

const OptionsCell2: FC<BaseTableOptionCellProperties> = ({
  uiid,
  rowKey,
  rowIndex,
  optionType,
}) => {
  const { instanceSelector, instanceInternalSelector, setInternalInstance } =
    useGridInstance(uiid, "OptionsCell2");
  const { cellInstance, setCellInstance } = useGridCellManager({ rowKey });
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());
  const contextActions =
    instance?.advancedOptions?.optionsContextActions?.contextComponent ??
    instance?.advancedOptions?.optionsContextActions?.contextMenu ??
    null;
  const hydratedRow = hydrateAndCastDataSingle(
    instance?.columns ?? [],
    getRowByRowKey(rowKey, instance?.data ?? [])
  );

  const popupState = usePopupState({
    variant: "popper",
    popupId: crypto.randomUUID(),
  });
  const [_, setOptionTypeValue] = useState(optionType);
  const hasPermissions =
    usePermissions(instance?.permissions?.optionEdit ?? []).hasPermission ??
    false;

  const contextRowInteract = (hydratedRow) => {
    console.log("contextRowInteract", hydratedRow);
    popupState.close();
  };

  const saveTable = () => {
    console.log("TODO - create a hook to save table data");
  };

  const refreshTable = () => {
    console.log("TODO - create a hook to refresh table data");
  };

  const _actionEvents = {
    closeMenu: popupState.close,
    saveTable: saveTable,
    refreshTable: refreshTable,
  };

  const autmaticallyGetOptionType = () => {
    if (contextActions === null || contextActions === undefined) {
      setOptionTypeValue("none");
    } else {
      if (Array.isArray(contextActions)) {
        setOptionTypeValue("context");
      } else {
        setOptionTypeValue("component");
      }
    }
  };

  const Jsx = contextActions as ElementType;

  useEffect(() => {
    autmaticallyGetOptionType();
  }, [contextActions]);

  const popperConfiguration: any = {
    transition: true,
    sx: { minWidth: "150px", zIndex: 99 },
  };

  const editRow = (rowKey: string) => {
    // alert(`hiiii_${rowKey}`);
    // const cellsInEditMode = cellInstance.cells.map((c) => ({
    //   ...c,
    //   isEditable: !c.isEditable,
    // }));
    console.log("click!", { rowKey, cellInstance });
    setCellInstance({
      ...cellInstance,
      isEditable: !cellInstance.isEditable,
      // cells: cellsInEditMode,
    });
  };

  const saveRow = (rowKey: string) => {
    if (instanceInternal?.selectedEditRows?.includes(rowKey)) {
      setInternalInstance({ ...instanceInternal, selectedEditRows: null });
    } else {
      setInternalInstance({
        // ...(instance as TableInstanceType2),
        selectedEditRows: addRowKeyWithOutRepeat(
          instanceInternal?.selectedEditRows ?? [],
          rowKey
        ),
      });
    }
  };

  const deleteRow = () => {
    const [propValue] =
      instance?.advancedOptions?.optionsColumnConfiguration
        ?.optionalPropertyValueData ?? [];
    const updatedHydratedData = {
      ...hydratedRow,
      [propValue?.property]: propValue?.value,
    };
    const deletedRow: DeleteRowParameters = {
      rowKey: rowKey,
      hydratedData: updatedHydratedData,
      deleteType: "hidden",
    };

    setInternalInstance({
      // ...(instance as TableInstanceType2),
      accessors: {
        ...instanceInternal?.accessors,
        _deleteRowInternal: deletedRow,
      },
    });
  };

  const _actionRules = {
    interact: contextRowInteract,
    update: editRow,
    delete: deleteRow,
    add: () => {}, // TODO - create context to RowAdd
  };

  const getOptionType = () => {
    switch (optionType) {
      case "delete":
        return hasPermissions ? (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() => deleteRow()}
            true-element={"option-delete-icon"}
            title="Delete"
          >
            <DeleteIcon fontSize="small" />{" "}
          </IconButton>
        ) : (
          <IconButton
            aria-label="save"
            size="small"
            true-element={"option-delete-icon"}
            title="Delete"
          >
            <DeleteIcon color={"disabled"} fontSize="small" />{" "}
          </IconButton>
        );
      case "edit":
        return hasPermissions ? (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() => editRow(rowKey)}
            true-element={"option-edit-icon"}
            title="Edit"
          >
            <EditIcon fontSize="small" />{" "}
          </IconButton>
        ) : (
          <IconButton
            aria-label="save"
            size="small"
            true-element={"option-edit-icon"}
            title="Edit"
          >
            <EditIcon color={"disabled"} fontSize="small" />{" "}
          </IconButton>
        );
      case "edit_override":
        return hasPermissions ? (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() =>
              instance?.advancedOptions?.optionsColumnConfiguration?.onClick?.({
                row: hydrateAndCastDataSingle(
                  instance?.columns ?? [],
                  getRowByRowKey(rowKey, instance?.data ?? [])
                ),
                actionRules: _actionRules,
                actionEvents: _actionEvents,
                optionType: "component",
                rowIndex: rowIndex,
                metaData: {
                  isTableInEdit: instance?.hasToggledEdit ?? false,
                },
              } as OptionsContextActionParameterProperties<any>)
            }
            true-element={"option-edit-icon"}
            title="Edit"
          >
            <EditIcon fontSize="small" />{" "}
          </IconButton>
        ) : (
          <IconButton
            aria-label="save"
            size="small"
            true-element={"option-edit-icon"}
            title="Edit"
          >
            <EditIcon color={"disabled"} fontSize="small" />{" "}
          </IconButton>
        );
      case "edit_save":
        return hasPermissions ? (
          <div>
            {instanceInternal?.selectedEditRows?.includes(rowKey) ? (
              <Button
                onClick={() => saveRow(rowKey)}
                true-element={"option-save-icon"}
                title="Save"
              >
                SAVE ROW
              </Button>
            ) : (
              <Button
                onClick={() => editRow(rowKey)}
                true-element={"option-edit-icon"}
                title="Edit"
              >
                EDIT ROW
              </Button>
            )}
          </div>
        ) : (
          <div>
            {instanceInternal?.selectedEditRows?.includes(rowKey) ? (
              <Button true-element={"option-save-icon"} title="Save" disabled>
                SAVE ROW
              </Button>
            ) : (
              <Button true-element={"option-edit-icon"} title="Edit" disabled>
                EDIT ROW
              </Button>
            )}
          </div>
        );
      case "plus_minus":
        return (
          <PlusMinusAction
            uiid={uiid}
            rowKey={rowKey}
            hydratedRow={hydratedRow}
            isDisabled={hasPermissions}
          />
        );
      case "context":
        return hasPermissions ? (
          <div onMouseLeave={() => popupState.close()}>
            <MoreHorizIcon {...bindToggle(popupState)} />
            <Popper {...bindPopper(popupState)} {...popperConfiguration}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={4}>
                    <List sx={{ pt: 0 }}>
                      {(
                        (contextActions as OptionsContextActionProperties[]) ??
                        []
                      ).map((action) => {
                        // TODO - clean this up
                        const actionCondition = (
                          instance?.advancedOptions?.optionsContextActions
                            ?.contextMenuOptionRenderingCondition ?? null
                        )?.find((x) => {
                          if (x.actionName === action.actionName) {
                            return x;
                          }
                          return null;
                        });
                        const isRendered =
                          actionCondition?.condition(hydratedRow).isHidden ??
                          false;
                        return (
                          !isRendered && (
                            <ListItem
                              button
                              onClick={() =>
                                action.rule({
                                  row: hydratedRow,
                                  actionRules: _actionRules,
                                  actionEvents: _actionEvents,
                                  optionType: "context",
                                  rowIndex: rowIndex,
                                  metaData: {
                                    isTableInEdit:
                                      instance?.hasToggledEdit ?? false,
                                  },
                                })
                              }
                              key={crypto.randomUUID()}
                            >
                              <ListItemText
                                sx={{ fontSize: "18px" }}
                                disableTypography
                              >
                                {action.displayName}
                              </ListItemText>
                            </ListItem>
                          )
                        );
                      })}
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        ) : (
          <div>
            <MoreHorizIcon {...bindToggle(popupState)} />
            <Popper {...bindPopper(popupState)} {...popperConfiguration}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={4}>
                    <div
                      style={{
                        margin: "10px",
                        padding: "10px",
                        width: "250px",
                      }}
                    >
                      You do not have permission to access this feature.
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        );
      case "component":
        // TODO - add support for permission.
        return (
          <div>
            <>
              <Jsx
                {...({
                  row: hydrateAndCastDataSingle(
                    instance?.columns ?? [],
                    getRowByRowKey(rowKey, instance?.data ?? [])
                  ),
                  actionRules: _actionRules,
                  actionEvents: _actionEvents,
                  optionType: "component",
                  rowIndex: rowIndex,
                  metaData: {
                    isTableInEdit: instance?.hasToggledEdit ?? false,
                  },
                } as OptionsContextActionParameterProperties<any>)}
              />
            </>
          </div>
        );
      case "edit_delete":
        // TODO - add support for permission.
        return (
          <Box>
            <IconButton
              aria-label="save"
              size="small"
              onClick={() => editRow(rowKey)}
              true-element={"option-edit-icon"}
            >
              <EditIcon fontSize="small" />{" "}
            </IconButton>
            <IconButton
              aria-label="save"
              size="small"
              onClick={() => deleteRow()}
              true-element={"option-delete-icon"}
            >
              <DeleteIcon fontSize="small" />{" "}
            </IconButton>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={"options_action_container"}
      style={{ width: "100%" }}
    >
      {getOptionType()}
    </div>
  );
};

export default OptionsCell2;
