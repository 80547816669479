import { Theme } from "../../../media/themeTypes";

export const agencyScoreCardStyles = (theme: Theme) => ({
  p: 1,
  textAlign: "left",
  mr: 1,
  background: theme?.PRIMARY_BACKGROUND,
  ".container-score-chip": {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    "> .MuiTypography-root": {
      ml: 2,
    },
  },
});

export const scoreItemsStyles = (theme: Theme) => ({
  ".listItem": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start !important",
    justifyContent: "flex-start !important",
    width: "100%",
  },
  ".listText": {
    width: "100%",
    display: "flex",
    rowGap: "5px",
    justifyContent: "flex-start",
    alignItems: "baseline !important",
  },
  ".listText > *": {
    color: theme?.TEXT_COLOR,
    marginRight: "8px",
  },
  ".listText > .circle": {
    fontSize: "8px",
  },

  ".listText > .text": {
    fontSize: theme?.SMALL_TITLE.SIZE,
  },

  ".barsArea": {
    marginLeft: "16px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
  },

  ".listBars": {
    width: "100%",
    display: "flex",
    columnGap: "2px",
    justifyContent: "flex-start !important",
  },
  ".barBox": {
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
  },
  ".bar": {
    marginRight: "1px",
    color: theme?.TEXT_COLOR,
    textAlign: "center",
    fontsize: theme?.CAPTION.SIZE,
    width: "100%",
  },
  ".percentageBox": {
    display: "flex",
  },
  ".percentage": {
    fontSize: `${theme?.CAPTION.SIZE} !important`,
    color: theme?.TEXT_COLOR,
  },
  ".indicator": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    rowGap: 0,
    height: "22px",
  },
  ".indicatorPercentage": {
    transform: "translateX(9px)",
    fontSize: `${theme?.CAPTION.SIZE} !important`,
    color: theme?.TEXT_COLOR,
  },
  ".arrowIcon": {
    margin: 0,
    transform: "translate(15px, -50%)",
    color: theme?.TEXT_COLOR,
  },

  ".range1": {
    width: "100%",
    overflow: "visible",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    transform: "translateX(-5px)",
  },
  ".r1": {
    transform: "translateX(98%) !important",
  },
  ".rx": {
    alignSelf: "flex-end",
    transform: "translateX(50%)",
  },
});
