import { SelectOptions } from "../../../dtos/select-options";
import { NotificationMethodEnums } from "../../../dtos/notification-method-enums";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { NotificationFrequencyEnums } from "../../../dtos/notification-frequency-enums";
import { UserSubscriptionDto } from "../../../dtos/user-subscription-dto";

export type UserSubscriptionState = {
  isOpen: boolean;
  categoryId?: number | null;
  subscriptionId?: number | null;
};

export type SubscriptionModalProps = {
  modalConfig: UserSubscriptionState;
  closeModal: () => void;
  onSaveSuccessful: () => void;
};

export const subscriptionOptions: Partial<SelectOptions>[] = [
  {
    displayName: "In App",
    intValue: NotificationMethodEnums.APPLICATION,
  },
  {
    displayName: "SMS",
    intValue: NotificationMethodEnums.SMS,
  },
  {
    displayName: "Email",
    intValue: NotificationMethodEnums.EMAIL,
  },
];

export const notificationFrequencyOptions: Partial<SelectOptions>[] = FromEnumToArray(
  NotificationFrequencyEnums
).map((frequency) => ({
  displayName: frequency.key,
  stringValue: frequency.value,
}));

export const defaultSubscriptionData: UserSubscriptionDto = {
  userSubscriptionId: 0,
  activityCategoryId: 0,
  notificationMethod: null,
  frequency: NotificationFrequencyEnums.INSTANT,
  summary: false,
  assignedToUserOnly: false,
  filterToPinned: false,
};