import { atom } from "recoil";
import { AgencyCommissionTranEnum } from "../../../dtos/agency-commission-tran-enum";

type SelectedAgencyCommission = {
  tranId: number;
  agencyId: number;
};

const SelectedAgencyCommissionAtom = atom<SelectedAgencyCommission | null>({
  key: "SelectedAgencyCommission",
  default: null,
});

const AgencyTranStatusFilter = atom<number | null>({
  key: "TranStatusFilter",
  default: AgencyCommissionTranEnum.UNPAID,
});

const AgencyPaidMonthFilter = atom<string | null>({
  key: "PaidMonthFilter",
  default: null,
});

export { SelectedAgencyCommissionAtom, AgencyTranStatusFilter, AgencyPaidMonthFilter };
