export const modalStyle = (
  height,
  width,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth
) => ({
  height: height,
  width: width,
  minHeight: minHeight,
  minWidth: minWidth,
  maxHeight: maxHeight,
  maxWidth: maxWidth,
  zIndex: 100,
  "#draggable-dialog-title": {
    cursor: "move",
  },
});
